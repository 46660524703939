import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';
import { usePermissionContext } from 'contexts/PermissionContext';

import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    FilterProps,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, valueToText } from '../utils';

const FIELD = 'prospectexport#target';

export type TValues = { [FIELD]: string | undefined };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.prospect_export_crm_system,
    label: 'CRM system',
    defaultOperator: FilterOperator.EQ,
    initValue: {
        [FilterOperator.EQ]: {
            [FIELD]: undefined,
        },
    },
};

export const crmSystemFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.crm,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <CRMSystem {...props} />,
        valueToText: valueToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.EQ, `${FilterOperator.NOT}${FilterOperator.EQ}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);
export const CRMSystem: React.VFC<FilterProps<TValues>> = ({ filter, uuid, groupUUID, removeFilter }) => {
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);
    const { productPermissions } = usePermissionContext();
    const { crmOptions } = useFilterOptionsContext();

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const selectableOptions = crmOptions.filter(
        (crm) =>
            productPermissions.includes(crm.label.toLowerCase()) ||
            // both salesforce and salesforce sandbox should be enabled
            (productPermissions.includes('salesforce') && crm.value === 'SalesforceSandbox'),
    );

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-select-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;

                    const selectedOption = selectableOptions.find((crm) => crm.value === value) || null;

                    return (
                        <Select
                            placeholder="Select"
                            multiple={false}
                            width="100%"
                            options={selectableOptions}
                            value={selectedOption}
                            onValueChange={(option) => onChange((option as SelectOption)?.value)}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
