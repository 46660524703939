export function getCategory(tag: number): string {
    if (
        [8000040, 8000010, 8000025, 6967635, 8000024, 8000037, 4507212, 8000018, 8000049, 8000016, 6931739].includes(
            tag,
        )
    ) {
        return 'financial_development';
    }
    if ([8000039, 8000038, 77884, 43536, 6893088, 8000034, 43535].includes(tag)) {
        return 'personnel_changes';
    }
    if (
        [
            6067468, 2940230, 8000056, 8000053, 8000055, 8000052, 8000054, 8000051, 6944439, 8000068, 8000013, 7200000,
            8000081, 8000029, 2943080,
        ].includes(tag)
    ) {
        return 'legalities_and_controversy';
    }
    if ([8000022, 8000085, 43543, 8000077, 43544, 77883, 8000050, 8000015, 8000080, 8000072, 8000069].includes(tag)) {
        return 'company_direction';
    }
    if (
        [
            8000060, 2999389, 8000084, 3325073, 8000076, 43545, 8000071, 43551, 8000086, 2932622, 4507192, 8000002,
            8000061,
        ].includes(tag)
    ) {
        return 'operations';
    }
    if (
        [
            8000064, 8000075, 8000066, 8000063, 8000062, 8000059, 8000074, 8000073, 8000065, 4467311, 8000058, 8000021,
            8000019, 8000020, 8000083,
        ].includes(tag)
    ) {
        return 'investments_and_upgrades';
    }
    return 'other';
}
