import { useState, useEffect } from 'react';

/**
 * A hook that returns the user-input value once after a debounce delay.
 * Use the returned value in useEffect to trigger a debounced action, e.g. init a search.
 *
 * Note: This hook does not work on objects, as object references given to the hook would always
 * trigger a new useEffect and would cause infinite rerendering. It only works with primitive types.
 * @param value The current user-input value.
 * @param delay The debounce delay, defaults to 500ms.
 * @param callback A callback that is called after the debounce triggers. Wrap the callback with useCallback
 * to prevent it from called twice. Can be used to trigger actions in case the user input the same string again.
 * @returns The final user-input value.
 */
export default function useDebounce<T extends string | number | bigint | boolean | symbol | null | undefined>(
    value: T,
    delay = 500,
    callback?: (value: T) => void,
) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = window.setTimeout(() => {
            setDebouncedValue(value);
            callback?.(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        return () => {
            window.clearTimeout(handler);
        };
    }, [value, delay, callback]);

    return debouncedValue;
}
