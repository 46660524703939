import { Box, Skeleton } from '@mui/material';

import Frame from 'components/tokens/Frame';
import Typography from 'components/tokens/Typography';

export type HighlightBoxProps = {
    title?: string;
    description?: string;
    icon: React.ReactNode;
};

const boxHeight = 112;

const HighlightBox: React.VFC<HighlightBoxProps> = ({ title, description, icon }) => (
    <Frame variant="lightgrey" elevation={0} border sx={{ display: 'flex', padding: '16px 12px', height: boxHeight }}>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 45,
                minWidth: 45,
                height: 45,
                marginRight: 1,
                backgroundColor: 'common.white',
                borderRadius: 22.5,
                border: '1px solid',
                borderColor: 'border',
                overflow: 'hidden',
                '& > img': {
                    width: '100%',
                },
            }}
        >
            {icon}
        </Box>
        <Box>
            <Typography paragraph weight="semibold" sx={{ marginBottom: 1 }}>
                {title}
            </Typography>
            <Typography
                paragraph
                variant="tiny"
                color="subtle"
                sx={{
                    margin: 0,
                    height: 52,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                }}
            >
                {description}
            </Typography>
        </Box>
    </Frame>
);

export default HighlightBox;

export const HighlightBoxSkeleton = () => <Skeleton variant="rounded" height={boxHeight} />;
