import { LinearProgress, styled } from '@mui/material';

export const BorderLinearProgress = styled(LinearProgress)(({ theme: { palette } }) => ({
    '&.MuiLinearProgress-root': {
        height: '6px',
        borderRadius: '3px',
    },
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: palette.brandColors.subtleLight,
    },
    '&.MuiLinearProgress-colorSecondary': {
        backgroundColor: palette.brandColors.subtleLight,
    },
    '&.MuiLinearProgress-dashedColorPrimary': {
        backgroundImage: 'none',
        animation: 'none',
    },
    '&.MuiLinearProgress-dashedColorSecondary': {
        backgroundImage: 'none',
        animation: 'none',
    },
    '&.MuiLinearProgress-bar': {
        borderRadius: '3px',
        backgroundColor: '#1a90ff',
    },
    '&.MuiLinearProgress-barColorPrimary': {
        backgroundColor: palette.brandColors.vainuBlack,
    },
    '&.MuiLinearProgress-barColorSecondary': {
        backgroundColor: palette.brandColors.magenta,
    },
    '&.MuiLinearProgress-bar2Buffer': {
        backgroundColor: palette.brandColors.orange,
    },
}));
