import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Excel2019: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <defs>
            <linearGradient
                id="paint0_linear_1572:563"
                x1="2.95536"
                y1="5.87183"
                x2="10.3005"
                y2="18.5932"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18884F" />
                <stop offset="0.5" stopColor="#117E43" />
                <stop offset="1" stopColor="#0B6631" />
            </linearGradient>
        </defs>
        <path
            d="M14.814 11.7209L6.11633 10.186V21.5273C6.11633 22.0452 6.5362 22.4651 7.05414 22.4651H22.0622C22.5802 22.4651 23.0001 22.0452 23.0001 21.5273V17.3488L14.814 11.7209Z"
            fill="#185C37"
        />
        <path
            d="M14.814 2H7.05414C6.5362 2 6.11633 2.41987 6.11633 2.93781V7.11628L14.814 12.2326L19.4187 13.7674L23.0001 12.2326V7.11628L14.814 2Z"
            fill="#21A366"
        />
        <path d="M6.11633 7.11633H14.814V12.2326H6.11633V7.11633Z" fill="#107C41" />
        <path
            opacity="0.1"
            d="M12.3412 6.09302H6.11633V18.8837H12.3412C12.8584 18.882 13.2773 18.4631 13.279 17.9459V7.03083C13.2773 6.51359 12.8584 6.0947 12.3412 6.09302Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M11.8297 6.60461H6.11633V19.3953H11.8297C12.3469 19.3936 12.7658 18.9747 12.7675 18.4575V7.54243C12.7658 7.02519 12.3469 6.6063 11.8297 6.60461Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M11.8297 6.60461H6.11633V18.3721H11.8297C12.3469 18.3704 12.7658 17.9515 12.7675 17.4342V7.54243C12.7658 7.02519 12.3469 6.6063 11.8297 6.60461Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M11.3181 6.60461H6.11633V18.3721H11.3181C11.8353 18.3704 12.2542 17.9515 12.2559 17.4342V7.54243C12.2542 7.02519 11.8353 6.6063 11.3181 6.60461Z"
            fill="black"
        />
        <path
            d="M1.93781 6.60461H11.318C11.8359 6.60461 12.2558 7.02449 12.2558 7.54243V16.9226C12.2558 17.4405 11.8359 17.8604 11.318 17.8604H1.93781C1.41987 17.8604 1 17.4406 1 16.9226V7.54243C1 7.02449 1.41987 6.60461 1.93781 6.60461Z"
            fill="url(#paint0_linear_1572:563)"
        />
        <path
            d="M3.90454 15.2808L5.87738 12.2238L4.0698 9.18372H5.52384L6.51026 11.1279C6.60133 11.3126 6.66375 11.4497 6.69752 11.5403H6.71031C6.77511 11.3929 6.84333 11.2498 6.91496 11.111L7.96942 9.18474H9.30426L7.45063 12.2069L9.35134 15.2808H7.93106L6.79166 13.1468C6.73799 13.056 6.69245 12.9606 6.65557 12.8618H6.63869C6.60531 12.9586 6.56103 13.0513 6.50669 13.1381L5.33353 15.2808H3.90454Z"
            fill="white"
        />
        <path d="M22.0622 2H14.814V7.11628H23V2.93781C23 2.41987 22.5801 2 22.0622 2Z" fill="#33C481" />
        <path d="M14.814 12.2325H23V17.3488H14.814V12.2325Z" fill="#107C41" />
    </SvgIcon>
);

export default Excel2019;
