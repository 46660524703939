import React from 'react';

import { FilterOperator } from 'api/types/FilterOperators';

import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterProps,
} from '../../FilterTypes';
import { useChipInputFilter } from '../common/useChipInputFilter';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, makeFullPath, valueArrayToText } from '../utils';

const FIELD = 'domain';

export type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.website_url,
    label: 'URL',
    defaultOperator: FilterOperator.STARTSWITH,
    initValue: {
        [FilterOperator.STARTSWITH]: {
            [FIELD]: [],
        },
    },
};

export const websiteUrlFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.web_profile,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        render: (props: FilterProps<TValues>) => <WebsiteUrl {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const customFormatOperatorValue = (value: OperatorValue) => {
    return formatOperatorValue(value);
};

const operators: Operator[] = [
    FilterOperator.STARTSWITH,
    FilterOperator.ENDSWITH,
    `${FilterOperator.NOT}${FilterOperator.CONTAINS}`,
].map((value) => customFormatOperatorValue(value as OperatorValue));

export const WebsiteUrl: React.FC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const props = useChipInputFilter({
        filter,
        sameFiltersState,
        uuid,
        groupUUID,
        removeFilter,
        operators,
        placeholder: 'website URL...',
        formatOperatorValue: customFormatOperatorValue,
        valuePath: makeFullPath(filter, true),
    });

    return <FilterTemplate {...props} />;
};
