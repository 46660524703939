import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MapMarker: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 16 20">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99748 15.9986C13.4492 15.1114 16 11.9786 16 8.25C16 3.83172 12.4183 0.25 8 0.25C3.58172 0.25 0 3.83172 0 8.25C0 11.9784 2.55054 15.1111 6.00202 15.9985L7.99958 18.9951L9.99748 15.9986Z"
        />
        <circle cx="8" cy="8.25" r="5.5" fill="white" />
    </SvgIcon>
);

export default MapMarker;
