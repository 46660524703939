import React from 'react';

import DynamicsSvgAlt from '../svg/DynamicsSvgAlt';
import HubspotSvgAlt from '../svg/HubspotSvgAlt';
import PipedriveSvgAlt from '../svg/PipedriveSvgAlt';
import SalesforceSandboxSvg from '../svg/SalesforceSandboxSvg';
import SalesforceSvgAlt from '../svg/SalesforceSvgAlt';
import { CrmName } from '../types/Crm';

// why do we have 2 icons for HS, 2 for Pipedrive, etc??
// 1 icon in /tokens/Icon and 1 more in /connectors/svg
// todo: use 1 svg and <Icon />, like SF case
// check also integrationUIProps() in App.tsx for duplicates and wrong usages
export const CRMLogoAlt: React.FC<{ name: CrmName } & React.SVGProps<SVGSVGElement>> = ({ name, ...props }) => {
    switch (name) {
        case 'dynamics':
            return <DynamicsSvgAlt {...props} />;
        case 'pipedrive':
            return <PipedriveSvgAlt {...props} />;
        case 'salesforce':
            return <SalesforceSvgAlt {...props} />;
        case 'salesforcesandbox':
            return <SalesforceSandboxSvg {...props} />;
        case 'hubspot':
            return <HubspotSvgAlt {...props} />;
        default:
            return null;
    }
};
