import React from 'react';

import { Box, SxProps } from '@mui/material';
import CompaniesEmptyState from 'src/images/companies-empty-state.svg';
import ContactsEmptyState from 'src/images/contacts-empty-state.svg';

import Typography from 'components/tokens/Typography';

export type NoFilterResultsProps = {
    sx?: SxProps;
    type?: 'companies' | 'contacts';
};
export const NoFilterResults: React.FC<NoFilterResultsProps> = ({ sx, type = 'companies' }) => (
    <Box
        sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx,
        }}
    >
        <Box
            sx={{
                width: 250,
                height: 250,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <img src={type === 'companies' ? CompaniesEmptyState : ContactsEmptyState} alt="" />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h5" color="dark">
                Sorry, no results...
            </Typography>
            <Typography variant="small" color="subtle">
                Try a different search
            </Typography>
        </Box>
    </Box>
);

export default NoFilterResults;
