import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import RangeSelect, { Values } from 'components/templates/RangeSelect';

import {
    FilterConfig,
    FilterDefinition,
    FilterID,
    FilterProps,
    GroupValues,
    Operator,
    OperatorValue,
} from '../../../FilterTypes';
import FilterTemplate from '../../FilterTemplate';
import { formatOperatorValue, rangeValueToText } from '../../utils';
import { WebsiteRankDisclaimer } from './TrafficRankDisclaimer';

const FIELD = 'website_traffic_data#ranking';
export type TValues = { [FIELD]: Values };

const rangeOptions = [
    1, 100, 1000, 10000, 100000, 500000, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000,
];
const defaultRange = [1, 500000];

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.website_traffic_ranking,
    label: 'Website traffic rank',
    defaultOperator: FilterOperator.RANGE,
    initValue: {
        [FilterOperator.RANGE]: {
            [FIELD]: defaultRange,
        },
    },
};

/**
 * **This filter should only be selected once.**
 */
export const websiteTrafficFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.web_profile,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <WebsiteTraffic {...props} />,
        valueToText: rangeValueToText(config.fields[0], rangeOptions),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.RANGE].map((value) => formatOperatorValue(value as OperatorValue));

export const WebsiteTraffic: React.FC<FilterProps<TValues>> = ({ filter, uuid, groupUUID, removeFilter }) => {
    const { control } = useFormContext();

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;
    const operator = config.defaultOperator;

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ...fieldProps } = field;
                    return (
                        <RangeSelect
                            label={config.label}
                            options={rangeOptions}
                            value={value || defaultRange}
                            onRangeChange={onChange}
                            additionalData={<WebsiteRankDisclaimer />}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
