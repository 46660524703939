import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PipedriveLeadSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M10.7577 1.66663V3.21966C12.2961 3.39077 13.7306 4.0803 14.8252 5.17487C15.9197 6.26945 16.6093 7.70391 16.7804 9.24238H18.3334V10.7575H16.7804C16.6093 12.296 15.9197 13.7305 14.8252 14.825C13.7306 15.9196 12.2961 16.6092 10.7577 16.7803V18.3333H9.24251V16.7803C7.70403 16.6092 6.26957 15.9196 5.17499 14.825C4.08042 13.7305 3.39089 12.296 3.21978 10.7575H1.66675V9.24238H3.21978C3.39089 7.70391 4.08042 6.26945 5.17499 5.17487C6.26957 4.0803 7.70403 3.39077 9.24251 3.21966V1.66663H10.7577ZM10.0001 4.69693C9.30368 4.69693 8.61409 4.8341 7.9707 5.1006C7.32731 5.3671 6.7427 5.75772 6.25027 6.25015C5.75784 6.74258 5.36722 7.32718 5.10072 7.97058C4.83422 8.61397 4.69705 9.30356 4.69705 9.99996C4.69705 10.6964 4.83422 11.3859 5.10072 12.0293C5.36722 12.6727 5.75784 13.2573 6.25027 13.7498C6.7427 14.2422 7.32731 14.6328 7.9707 14.8993C8.61409 15.1658 9.30368 15.303 10.0001 15.303C11.4065 15.303 12.7554 14.7443 13.7499 13.7498C14.7444 12.7553 15.3031 11.4064 15.3031 9.99996C15.3031 8.59351 14.7444 7.24466 13.7499 6.25015C12.7554 5.25564 11.4065 4.69693 10.0001 4.69693ZM10.0001 6.96966C10.8038 6.96966 11.5745 7.28892 12.1428 7.85721C12.7111 8.4255 13.0304 9.19627 13.0304 9.99996C13.0304 10.8036 12.7111 11.5744 12.1428 12.1427C11.5745 12.711 10.8038 13.0303 10.0001 13.0303C9.1964 13.0303 8.42563 12.711 7.85733 12.1427C7.28904 11.5744 6.96978 10.8036 6.96978 9.99996C6.96978 9.19627 7.28904 8.4255 7.85733 7.85721C8.42563 7.28892 9.1964 6.96966 10.0001 6.96966V6.96966Z" />
    </SvgIcon>
);
export default PipedriveLeadSvg;
