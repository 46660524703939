import React, { CSSProperties } from 'react';

import { styled, darken, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import {
    XAxis,
    YAxis,
    Bar,
    Rectangle,
    BarChart as RechartsBarChart,
    LabelList,
    CartesianGrid,
    Tooltip,
    BarProps,
} from 'recharts';

import { ChartDataPoint, isYearData } from '..';
import { ChartTooltip, formatTooltipValue } from '../ChartTooltip';
import ResponsiveContainer from '../utils/ResponsiveContainer';

export type ChartNumberStyle = 'decimal' | 'percent' | 'currency';

export type ChartDataField = keyof ChartDataPoint;

export type BarChartProps = {
    data: ChartDataPoint[];
    numberStyle?: ChartNumberStyle;
    currency?: string;
    highlightLargest?: boolean;
    layout?: 'horizontal' | 'vertical';
    order?: 'default' | 'descending' | 'ascending';
    max?: number;
    cutFromStart?: boolean;
    height?: number;
    radius?: BarProps['radius'];
    barDefaultColor?: CSSProperties['fill'];
};

const StyledRectangle = styled(Rectangle)(({ fill }) => ({
    transition: 'all 200ms ease',
    '&:hover': {
        fill: fill ? darken(fill, 0.2) : undefined,
    },
}));

function findLargestValue(data: ChartDataPoint[]) {
    return data.reduce<number | undefined>((acc, { value }) => {
        if (acc !== undefined) {
            if (value !== undefined) {
                return Math.max(acc, value);
            }
            return acc;
        }
        if (value !== undefined) {
            return value;
        }
        return undefined;
    }, undefined);
}

const sortAscending = (a: ChartDataPoint, b: ChartDataPoint) =>
    a.value ? (b.value ? a.value - b.value : a.value) : b.value ? b.value : 0;
const sortDescending = (a: ChartDataPoint, b: ChartDataPoint) =>
    a.value ? (b.value ? b.value - a.value : a.value) : b.value ? b.value : 0;

export const BarChart: React.VFC<BarChartProps> = ({
    data,
    numberStyle = 'decimal',
    currency,
    highlightLargest,
    order = 'default',
    max,
    cutFromStart = false,
    height = 180,
    radius,
    barDefaultColor,
}) => {
    const theme = useTheme();
    const intl = useIntl();

    if (data.length === 0) {
        return <div style={{ height }} />;
    }

    const largest = findLargestValue(data);

    const defaultColor = barDefaultColor ?? theme.palette.chart.default;
    const commonColor = theme.palette.chart.grey1;
    const highlightColor = theme.palette.chart.default;
    const axisColor = theme.palette.chart.axis;

    const allPositive = !data.some((point) => point.value !== undefined && point.value < 0);
    const tickCount = allPositive ? 4 : 3;
    const xLabelKey = isYearData(data[0]) ? 'year' : 'label';

    const formatValue = (value: number) =>
        intl.formatNumber(value, {
            notation: 'compact',
            style: numberStyle,
            currency,
        });

    const sortedData = data.slice();
    if (order !== 'default') {
        sortedData.sort(order === 'descending' ? sortDescending : sortAscending);
    }
    if (max) {
        if (cutFromStart) {
            sortedData.splice(0, sortedData.length - max);
        } else {
            sortedData.splice(max);
        }
    }

    return (
        <div>
            <ResponsiveContainer debounce={300} width="100%" height={height}>
                <RechartsBarChart
                    data={sortedData}
                    width={400}
                    height={300}
                    margin={{ left: 4, top: 12, right: 0, bottom: 0 }}
                >
                    <CartesianGrid vertical={false} stroke={axisColor} />
                    <XAxis
                        dataKey={xLabelKey}
                        interval={0}
                        axisLine={false}
                        tickLine={false}
                        tick={{ ...theme.typography.chartXAxisLabel }}
                        tickFormatter={(value) => (value === undefined ? '' : value)}
                    />
                    <YAxis
                        dataKey="value"
                        width={24}
                        stroke={axisColor}
                        tickLine={false}
                        tick={{ ...theme.typography.chartYAxisLabel }}
                        tickFormatter={formatValue}
                        tickCount={tickCount}
                    />
                    <Bar
                        type="linear"
                        dataKey="value"
                        barSize={9}
                        radius={radius ?? [10, 10, 0, 0]}
                        fill={defaultColor}
                        isAnimationActive={false}
                        shape={
                            largest && highlightLargest
                                ? ({ fill, value, ...props }) => (
                                      <StyledRectangle
                                          fill={value >= largest ? highlightColor : commonColor}
                                          value={value}
                                          {...props}
                                      />
                                  )
                                : undefined
                        }
                    >
                        <LabelList
                            formatter={formatValue}
                            position="top"
                            style={{ ...theme.typography.chartValueLabel }}
                        />
                    </Bar>
                    <Tooltip
                        cursor={false}
                        content={<ChartTooltip labelKey={xLabelKey} />}
                        formatter={formatTooltipValue(numberStyle, currency, numberStyle === 'percent' ? 2 : 0, intl)}
                        isAnimationActive={false}
                    />
                </RechartsBarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChart;
