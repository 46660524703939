import React, { useState } from 'react';

type Props = {
    target: React.RefObject<Element>;
    root?: React.RefObject<Element> | null;
    threshold?: number;
    rootMargin?: string;
    enabled?: boolean;
};

export default function useIntersectionObserver({
    target,
    root = null,
    threshold = 1.0,
    rootMargin = '0px',
    enabled = true,
}: Props) {
    const [isIntersecting, setIntersecting] = useState(false);
    React.useEffect(() => {
        if (!enabled) {
            return;
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
            },
            {
                root: root && root.current,
                rootMargin,
                threshold,
            },
        );

        const el = target && target.current;

        if (!el) {
            return;
        }

        observer.observe(el);
        // eslint-disable-next-line consistent-return
        return () => {
            observer.unobserve(el);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [target.current, enabled]);
    return isIntersecting;
}
