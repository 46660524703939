import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TriggersUpgrade = (props) => (
    <SvgIcon {...props}>
        <path d="M8.04155 21.9989C7.88554 21.9994 7.73252 21.9561 7.59991 21.8739C7.44012 21.7747 7.31857 21.6244 7.25503 21.4474C7.19149 21.2704 7.18969 21.0771 7.24994 20.899L9.38314 14.4993H4.70842C4.54904 14.4997 4.39289 14.4544 4.25849 14.3688C4.12409 14.2831 4.01707 14.1607 3.95014 14.016C3.88339 13.8711 3.85969 13.71 3.88185 13.5519C3.90401 13.3938 3.97109 13.2455 4.07513 13.1244L13.2412 2.29175C13.3612 2.15099 13.5245 2.05413 13.7055 2.01642C13.8866 1.9787 14.075 2.00228 14.2412 2.08343C14.4105 2.16536 14.5471 2.30218 14.6288 2.47163C14.7105 2.64108 14.7325 2.83319 14.6912 3.0167L13.2496 9.49964H18.8742C19.0387 9.50047 19.1993 9.54996 19.3358 9.6419C19.4722 9.73384 19.5783 9.86411 19.6409 10.0163C19.7047 10.168 19.7221 10.3353 19.691 10.4969C19.6598 10.6586 19.5815 10.8074 19.4659 10.9246L8.63318 21.7572C8.55532 21.8345 8.46298 21.8956 8.36146 21.937C8.25993 21.9785 8.15122 21.9995 8.04155 21.9989ZM6.50831 12.8328H10.5414C10.6736 12.8334 10.8038 12.8654 10.9211 12.9263C11.0385 12.9872 11.1397 13.0751 11.2164 13.1828C11.2938 13.2896 11.3448 13.4133 11.365 13.5437C11.3853 13.6741 11.3743 13.8074 11.333 13.9327L9.94977 18.0991L16.866 11.1829H12.208C12.0835 11.1833 11.9605 11.1558 11.848 11.1025C11.7355 11.0491 11.6364 10.9712 11.558 10.8746C11.4785 10.7768 11.4222 10.6623 11.3933 10.5396C11.3644 10.4169 11.3638 10.2892 11.3914 10.1663L12.3246 5.99986L6.50831 12.8328Z" />
        <path d="M20.5714 4.57143V7.42857C20.5714 7.74416 20.3156 8 20 8C19.6844 8 19.4286 7.74416 19.4286 7.42857V4.57143H16.5714C16.2558 4.57143 16 4.31559 16 4C16 3.68441 16.2558 3.42857 16.5714 3.42857H19.4286V0.571428C19.4286 0.255837 19.6844 0 20 0C20.3156 0 20.5714 0.255837 20.5714 0.571428V3.42857H23.4286C23.7442 3.42857 24 3.68441 24 4C24 4.31559 23.7442 4.57143 23.4286 4.57143H20.5714Z" />
    </SvgIcon>
);

export default TriggersUpgrade;
