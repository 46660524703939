import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, ClickAwayListener, Divider, Paper } from '@mui/material';
import { cloneDeep } from 'lodash';
import { FieldValues, get, set, useFormContext, UseFormGetValues } from 'react-hook-form';

import { CombineOperation, FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import { createIntercomContactFilterEvent } from 'utilities/createIntercomEvent';
import { getFirstKey } from 'utilities/objectUtils';

import { mergeKeys } from '../../common/utils';
import { filterDefinitions } from '../../filters/filterDefinitions';
import { getInnerMostValueInFilterState, getSameFiltersState } from '../../filters/utils';
import {
    ContactFilterProps,
    FilterGroup,
    FilterGroupProps,
    FilterID,
    FILTERS_IDS,
    GetFiltersFnResult,
    GroupOption,
    GroupValues,
    MappedFilter,
    NodeValue,
} from '../../FilterTypes';
import { useFilterGroupOptions } from '../common/groupUtils';
import { useGroupActions, useGroupNodes } from '../common/useGroup';

export const contactOptions: GroupOption[] = [
    {
        value: FilterID.contact_is_decision_maker,
        label: 'Decision maker',
    },
    {
        value: FilterID.contact_function,
        label: 'Function',
    },
    {
        value: FilterID.contact_title_keywords,
        label: 'Function',
    },
    {
        value: FilterID.contact_info,
        label: 'Contact info',
    },
];

const createNewGroup = (value: NodeValue) =>
    ({
        [FilterOperator.MATCH]: {
            [GroupValues.contacts]: {
                [FilterOperator.ALL]: [value],
            },
        },
    }) as NodeValue;

const createNewComboGroup = (value: NodeValue[]) =>
    ({
        [FilterOperator.MATCH]: {
            [GroupValues.contacts]: {
                [FilterOperator.ALL]: value,
            },
        },
    }) as NodeValue;

const config: FilterGroup = {
    group: GroupValues.contacts,
    label: 'Contacts',
    icon: 'UserCircle',
    options: contactOptions,
    filterUUIDs: [],
    logic: {},
    render: (props: FilterGroupProps) => <ContactsGroup {...props} />,
    getFilters: getFiltersById,
    createNewGroup,
};

export const contactsGroupDefinition: Record<GroupValues.contacts, FilterGroup> = {
    [GroupValues.contacts]: config,
};

export const ContactsGroup: React.FC<FilterGroupProps> = ({
    applyChanges,
    clearFilters,
    removeGroup,
    ids = [],
    addNewGroup,
    updateGroup,
    updateGroups,
}) => {
    const [activeFilter, setActiveFilter] = useState<FilterID | undefined>(undefined);
    const filterGroups = useGroupNodes(ids);
    const { getValues } = useFormContext();

    const { activeFilters, filteredOptions } = useFilterGroupOptions({ filterGroups, options: contactOptions });
    const { handleAddFilter, handleFilterRemove, keys, handleAddFilters } = useGroupActions({
        applyChanges,
        removeGroup,
        addNewGroup,
        updateGroup,
        updateGroups,
        createNewGroup,
        filterGroups,
        options: filteredOptions,
    });

    const rendered = useMemo(() => {
        const renderedIds: string[] = [];
        const filters: React.ReactElement<ContactFilterProps<unknown>>[] = [];

        ids.forEach((id) => {
            const group = getFiltersById(id, getValues);

            group?.filters.forEach((filter, index) => {
                const filterDefinition = filter.definition;
                const sameFiltersState = getSameFiltersState(activeFilters, filter);

                if (filterDefinition) {
                    renderedIds.push(filterDefinition.id);
                    filters.push(
                        filterDefinition.render({
                            key: keys[mergeKeys(id, filter.id)],
                            uuid: filterDefinition.id,
                            filter,
                            groupUUID: config.group,
                            removeFilter: () => handleFilterRemove(id, index),
                            sameFiltersState,
                        }),
                    );
                }
            });
        });

        return {
            filters: filters.sort((a, b) => {
                const aIdx = contactOptions.findIndex((opt) => opt.value === a.props.filter.id);
                const bIdx = contactOptions.findIndex((opt) => opt.value === b.props.filter.id);
                return aIdx - bIdx;
            }),
            ids: renderedIds,
        };
    }, [activeFilters, getValues, handleFilterRemove, ids, keys]);

    const handleApplyChanges = useCallback(() => {
        const updatedGroups: Record<string, NodeValue | undefined> = {};
        ids.forEach((id) => {
            const value = getValues(id);
            const filterGroup = getFiltersById(id, getValues);
            const group = filterGroups[id];

            const filteredIds: number[] = [];
            filterGroup?.filters.forEach((r, index) => {
                const fieldValue = r.valuePath ? get(r.value, r.valuePath) : r.value;
                createIntercomContactFilterEvent(r.id, r.value);
                if (!fieldValueValidator(fieldValue, r.id)) {
                    filteredIds.push(index);
                }
            });
            if (filteredIds.length === 0) {
                applyChanges();
                return;
            }

            if (filterGroup && filteredIds.length < filterGroup?.filters.length) {
                const values: NodeValue[] = getValues(group.path);

                const updatedArray = values.filter((_, i) => {
                    return !filteredIds.includes(i);
                });
                const updatedValue = cloneDeep(value);
                set(updatedValue, group.nodePath, updatedArray);
                updatedGroups[id] = updatedValue;
            } else {
                updatedGroups[id] = undefined;
            }
        });

        updateGroups?.(updatedGroups);
        applyChanges();
    }, [applyChanges, filterGroups, getValues, ids, updateGroups]);

    useEffect(() => {
        const alwaysShownFilters = contactOptions.map((option) => option.value) as FilterID[];
        if (rendered.ids.length === 0) {
            handleAddFilters(alwaysShownFilters, createNewComboGroup);
        } else {
            alwaysShownFilters.forEach((filter) => {
                if (!rendered.ids.includes(filter)) {
                    handleAddFilter(filter);
                }
            });
        }
    }, [handleAddFilter, handleAddFilters, rendered.ids]);

    return (
        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleApplyChanges}>
            <Paper
                sx={{ padding: 1, margin: '0.5em 0 0 0.5em', width: 240, border: '1px solid', borderColor: 'grey.50' }}
                elevation={4}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {React.Children.map(rendered.filters, (f, idx) => {
                        if (React.isValidElement(f)) {
                            return (
                                <React.Fragment key={idx}>
                                    <Box onMouseEnter={() => setActiveFilter(f.props.filter.id)}>
                                        {React.cloneElement(f, { activeFilter })}
                                    </Box>

                                    {idx === 2 && <Divider />}
                                </React.Fragment>
                            );
                        }
                        return null;
                    })}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1, marginTop: 2 }}>
                    <Button variant="flat" size="small" onClick={clearFilters}>
                        Clear
                    </Button>

                    <Button variant="primary" size="small" onClick={handleApplyChanges}>
                        Apply
                    </Button>
                </Box>
            </Paper>
        </ClickAwayListener>
    );
};

export function getFiltersById(id: string, getValues: UseFormGetValues<FieldValues>): GetFiltersFnResult {
    const value = getValues(id);

    if (!value) {
        return;
    }

    const path = '?MATCH.contacts.?ALL';
    const filters: NodeValue[] = get(value, path) || [];

    return {
        filters: filters
            .map((filter, idx) => {
                const mappedFilter = getMappedFilter(filter);

                if (!mappedFilter) {
                    return false;
                }

                return {
                    value: filter,
                    definition: filterDefinitions[mappedFilter.id],
                    id: mappedFilter.id,
                    path: `${path}.${idx}`,
                    groupPath: id,
                    valuePath: mappedFilter.path,
                };
            })
            .filter(Boolean) as MappedFilter<unknown>[],
        path: id,
        nodePath: '',
    };
}

const getMappedFilter = (operation: NodeValue, path: string = ''): { id: FilterID; path: string } | undefined => {
    if (!operation) {
        return;
    }

    for (const operator of Object.keys(operation)) {
        const newPath = path ? `${path}.${operator}` : operator;
        if (FILTERS_IDS.includes(operator as FilterID)) {
            return {
                path: newPath,
                id: operator as FilterID,
            };
        }

        if (['phone', 'email', 'web_profiles#type'].includes(operator)) {
            return {
                path: '',
                id: FilterID.contact_info,
            };
        }

        return getMappedFilter(operation[operator as keyof NodeValue], newPath);
    }
};

const fieldValueValidator = (fieldValue: unknown, filterId: FilterID) => {
    if (filterId === FilterID.contact_is_decision_maker) {
        return fieldValue === true;
    }

    if (filterId === FilterID.contact_info) {
        const value = fieldValue as CombineOperation;
        const operator = getFirstKey(value);
        return value[operator]?.some((val) => getInnerMostValueInFilterState(val, ''));
    }

    if (fieldValue == null) {
        return false;
    } else if (Array.isArray(fieldValue) && !fieldValue.length) {
        return false;
    }

    return true;
};
