import React from 'react';

import { Box, GridProps } from '@mui/material';

import { TabProps } from './types';

type TabContentChild = React.ReactElement<TabProps> | undefined | false | null;
export type TabContentProps = Omit<GridProps, 'children'> & {
    children: TabContentChild | TabContentChild[];
};

export const TabContent = ({ children, sx, ...props }: TabContentProps): JSX.Element => (
    <Box
        sx={{
            display: 'grid',
            rowGap: 5,
            gridTemplateColumns: 'minmax(0, 1fr)',
            ...sx,
        }}
        {...props}
    >
        {children}
    </Box>
);

export default TabContent;
