import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FlagNorway: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            d="M22 12.4583C22 17.9812 17.5228 22.4583 12 22.4583C6.47715 22.4583 2 17.9812 2 12.4583C2 6.93547 6.47715 2.45831 12 2.45831C17.5228 2.45831 22 6.93547 22 12.4583Z"
            fill="#C60C31"
        />
        <path
            d="M9.91415 10.7049V2.67617L9.66583 2.73219L9.4022 2.79906L9.14256 2.87251L8.90134 2.94767L8.68564 3.02065L8.41434 3.12038L8.15957 3.22233L7.90428 3.33283L7.65746 3.44786L7.38961 3.58219L6.91459 3.84611L6.44839 4.13962L6.25465 4.27248L6.06864 4.40648V10.7049H2.15322L2.08799 11.1259L2.06115 11.3461L2.03766 11.5844L2.02191 11.791L2.00995 12.0081L2.00234 12.2399L2 12.4583L2.00234 12.6767L2.00913 12.8895L2.02089 13.1099L2.03766 13.3322L2.05937 13.5543L2.08418 13.7618L2.11762 13.9968L2.15213 14.2056H6.5395V20.8372L6.71949 20.952L6.92731 21.078L7.13101 21.195L7.34495 21.311L7.56499 21.4235L7.77022 21.5223L7.98609 21.6201L8.20556 21.7133L8.40308 21.7919L8.59882 21.865L8.82465 21.9436L9.031 22.0102L9.28009 22.084L9.52661 22.1501L9.77909 22.2108L9.94096 22.2461V22.1019L9.91415 14.2056H21.8479L21.9172 13.7509L21.9634 13.3198L21.9913 12.8795L22 12.4583L21.99 12.0082L21.9592 11.5493L21.911 11.1181L21.8468 10.7049H9.91415Z"
            fill="#023262"
        />
        <path d="M9.86218 2.67704L10.361 2.57941V10.6182H9.86218V2.67704Z" fill="white" />
        <path d="M9.86218 10.6182L21.839 10.6182L21.911 11.1181L9.86218 11.1182V10.6182Z" fill="white" />
        <path d="M9.84728 13.7114H21.9343L21.8599 14.2114H9.84728V13.7114Z" fill="white" />
        <path d="M9.84729 14.2048H10.3473V22.3255L10.0867 22.285L9.84729 22.2372V14.2048Z" fill="white" />
        <path d="M6.04395 4.41167L6.54395 4.05592V11.1181H6.04395V4.41167Z" fill="white" />
        <path d="M2.14233 10.7003H6.04395V11.1181L2.07886 11.1181L2.14233 10.7003Z" fill="white" />
        <path d="M6.14343 13.7075H6.54394V20.8411L6.27026 20.6575L6.11792 20.5521L6.14343 13.7075Z" fill="white" />
        <path d="M2.07886 13.7075H6.14844V14.2048H2.14289L2.07886 13.7075Z" fill="white" />
    </SvgIcon>
);

export default FlagNorway;
