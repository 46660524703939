import { Box, SxProps } from '@mui/material';

export type PageGridContentColumnProps = {
    sx?: SxProps;
    innerSx?: SxProps;
    children?: React.ReactNode;
};

export const PageGridContentColumn: React.FC<PageGridContentColumnProps> = ({ children, sx, innerSx }) => (
    <Box
        sx={{
            gridColumn: { xs: '1 / 5', profileSidebar: '2 / 5' },
            ...sx,
        }}
    >
        <Box
            sx={{
                display: 'grid',
                rowGap: 5,
                ...innerSx,
            }}
        >
            {children}
        </Box>
    </Box>
);

export default PageGridContentColumn;
