import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Judge: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M7.16182 9.04412C6.88555 8.76785 6.7474 8.404 6.7474 8.04043C6.7474 7.67685 6.88555 7.313 7.16182 7.03673L10.3841 3.81442C10.6604 3.53814 11.0242 3.39999 11.3878 3.39999C11.7514 3.39999 12.1152 3.53816 12.3915 3.81444C12.6678 4.09072 12.806 4.45457 12.806 4.81815C12.806 5.18172 12.6678 5.54556 12.3915 5.82184L9.16924 9.04415C8.89297 9.32042 8.52912 9.45857 8.16554 9.45857C7.80197 9.45857 7.43809 9.32039 7.16182 9.04412Z" />
        <path d="M13.0753 5.66875L16.0088 8.60223C16.0479 8.64138 16.0831 8.68301 16.1144 8.72664C15.9382 8.81034 15.7758 8.92416 15.6347 9.06521L12.4124 12.2875C12.2714 12.4286 12.1576 12.591 12.0739 12.7672C12.0302 12.7359 11.9886 12.7007 11.9495 12.6616L9.01598 9.72809C8.97682 9.68893 8.94162 9.6473 8.91034 9.60367C9.08654 9.51998 9.24897 9.40617 9.39002 9.26512L12.6123 6.04281C12.7534 5.90174 12.8672 5.73932 12.9509 5.56311C12.9945 5.59439 13.0362 5.62959 13.0753 5.66875Z" />
        <path d="M12.6369 14.5192C12.9127 14.7933 13.2751 14.9303 13.6373 14.9303C14.0009 14.9303 14.3647 14.7922 14.641 14.5159L17.8633 11.2936C18.1395 11.0173 18.2777 10.6535 18.2777 10.2899C18.2777 9.92952 18.1419 9.56891 17.8705 9.29354L17.8561 9.27914C17.5807 9.0076 17.22 8.87177 16.8595 8.87177C16.496 8.87177 16.1321 9.00992 15.8558 9.2862L12.6335 12.5085C12.3573 12.7848 12.2191 13.1486 12.2191 13.5122C12.2191 13.8758 12.3573 14.2396 12.6335 14.5159L12.6369 14.5192Z" />
        <path d="M8.88595 14.3316L11.0317 12.1858L9.46505 10.6191L7.3193 12.7649C7.14964 12.6486 6.9516 12.5905 6.7537 12.5905C6.49757 12.5905 6.24115 12.6879 6.04638 12.8827L2.29219 16.6369C2.09743 16.8316 2 17.0881 2 17.3442C1.99998 17.6011 2.09821 17.8576 2.29217 18.0515L3.59932 19.3587C3.79408 19.5535 4.05051 19.6509 4.30665 19.6509C4.5628 19.6509 4.8192 19.5535 5.01399 19.3587L8.76818 15.6045C8.96294 15.4097 9.06037 15.1533 9.06037 14.8972C9.06037 14.6993 9.00222 14.5012 8.88595 14.3316Z" />
        <path d="M12.4234 18.0834H21.6875C21.8601 18.0834 22 18.2233 22 18.3959V20.2069C22 20.3795 21.8601 20.5194 21.6875 20.5194H12.4234C12.2508 20.5194 12.1109 20.3795 12.1109 20.2069V18.3959C12.1109 18.2233 12.2508 18.0834 12.4234 18.0834Z" />
        <path d="M13.7131 16.5849V17.7709H20.398V16.5849C20.398 16.4123 20.2581 16.2724 20.0855 16.2724H14.0256C13.853 16.2724 13.7131 16.4123 13.7131 16.5849Z" />
    </SvgIcon>
);

export default Judge;
