import React from 'react';

import { Box } from '@mui/material';

import ObjectSvgIcon from '../common/ObjectIcon';
import { CrmName, CRMObject } from '../types/Crm';

type Props = {
    crm?: CrmName;
    icon?: React.ReactNode;
    object?: CRMObject;
    text?: string | React.ReactNode;
    style?: React.CSSProperties;
    noIcon?: boolean;
};

const SelectIconTextRenderer: React.FC<Props> = ({ crm, icon, object, text = '', style, noIcon }) => {
    const SvgIcon = object && crm ? <ObjectSvgIcon crm={crm} object={object} style={style} /> : '';
    const prefix = noIcon || icon || SvgIcon || undefined;
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: `${prefix && 'auto'} 1fr min-content`,
                gap: '0 5px',
                alignItems: 'center',
                padding: prefix ? '0 6px' : '0',
                minHeight: '26px',
            }}
        >
            {prefix}
            <Box
                sx={{
                    display: 'contents',
                    fontSize: '12px',
                    lineHeight: 1,
                }}
            >
                {text || object}
            </Box>
        </Box>
    );
};

export default SelectIconTextRenderer;
