import { FieldValues, get, UseFormGetValues } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { FilterOperator } from 'api/types/FilterOperators';
import { mergeKeys } from 'components/features/Filters/common/utils';
import { filterDefinitions } from 'components/features/Filters/filters/filterDefinitions';
import { getSameFiltersState } from 'components/features/Filters/filters/utils';
import { Filter, FilterID, MappedFilter } from 'components/features/Filters/FilterTypes';

import { GroupWithPath, GetFiltersResult } from '../../types';

export function isVainuCustomIndustryFilter(filterGroup: GroupWithPath) {
    const filterSample = filterGroup?.filters[0];

    if (!filterSample) {
        return false;
    }

    if (filterSample.definition.id === FilterID.vainu_custom_industry) {
        return true;
    }

    return false;
}

interface RenderFiltersProps {
    filterGroup: GroupWithPath;
    activeFilters: MappedFilter[];
    groupUUID: string;
    removeFilter: (id: string) => void;
    id: string;
}

export const renderVCIFilters = ({ id, filterGroup, activeFilters, groupUUID, removeFilter }: RenderFiltersProps) => {
    const filterSample = filterGroup.filters[0];

    if (!filterSample) {
        return null;
    }

    const filterDefinition = filterSample.definition;

    const sameFiltersState = getSameFiltersState(activeFilters, filterSample);

    return filterDefinition
        ? filterDefinition.render({
              key: filterSample.uuid,
              uuid: filterDefinition.id,
              filter: filterSample,
              groupUUID,
              removeFilter: () => removeFilter(id),
              sameFiltersState: sameFiltersState,
          })
        : null;
};

export function getFiltersById(id: string, getValues: UseFormGetValues<FieldValues>): GroupWithPath | undefined {
    const value = getValues(id);

    if (!value) {
        return;
    }

    const valuePath = mergeKeys(
        FilterOperator.MATCH,
        'vainu_custom_industry',
        FilterOperator.ALL,
        0,
        FilterOperator.EQ,
        'fact_vid',
    );

    return {
        filters: [
            {
                value: value,
                definition: filterDefinitions[FilterID.vainu_custom_industry] as Filter<unknown>,
                id: FilterID.vainu_custom_industry,
                path: id,
                uuid: uuid(),
                groupPath: '',
                valuePath: '',
            },
        ],
        path: id,
        nodePath: '',
        isUndefined:
            !get(value, mergeKeys(FilterOperator.ANY, 0, valuePath)) &&
            !get(value, mergeKeys(FilterOperator.ALL, 0, FilterOperator.NOT, valuePath)),
    };
}

export const getNodes = (ids: string[], getValues: UseFormGetValues<FieldValues>) => {
    const filteredIds: string[] = [];
    const filters: GetFiltersResult = {};
    ids.forEach((id) => {
        const result = getFiltersById(id, getValues);
        if (result) {
            filteredIds.push(id);
            filters[id] = result;
        }
    });
    return { filteredIds, filters };
};
