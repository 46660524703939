import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowShortDown = (props) => (
    <SvgIcon {...props}>
        <path d="M11 16.17L7.41 12.59L6 14L12 20L18 14L16.59 12.59L13 16.17V4H11V16.17Z" />
    </SvgIcon>
);

export default ArrowShortDown;
