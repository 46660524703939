import { FilterOperator } from 'api/types/FilterOperators';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';

import { FilterID, FilterConfig, FilterProps } from '../../../FilterTypes';
import { TechnologyFilterTemplate, getFilterDefinition } from './TechnologyFilterTemplate';

const FIELD = 'social_media_urls#urls_web_tags';
export type TValues = { [FIELD]: number[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.technology_social_media,
    label: 'Technology (Social media)',
    defaultOperator: FilterOperator.IN,
    initValue: {
        [FilterOperator.IN]: {
            [FIELD]: [],
        },
    },
};

export const TechnologySocialMedia: React.FC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const { webTags } = useFilterOptionsContext();
    const someOptions = webTags[FilterID.social_media_channels];

    const props = {
        filter,
        uuid,
        groupUUID,
        removeFilter,
        options: someOptions,
        dbField: config.fields[0] as (typeof config.fields)[0],
        sameFiltersState,
    };
    return <TechnologyFilterTemplate {...props} />;
};

export const technologySocialMediaFilterDefinition = getFilterDefinition<TValues>(config, TechnologySocialMedia);
