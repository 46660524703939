import { Alert } from '@mui/material';

const DirectMarketingDenied = () => {
    return (
        <Alert severity="warning" icon={false}>
            Direct marketing denied
        </Alert>
    );
};

export default DirectMarketingDenied;
