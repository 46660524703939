import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DynamicsAnnotationSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 2H17V3.25H3V2ZM3 5.74976H17V6.99976H3V5.74976ZM16.6433 9.49951L15.5123 10.7495H3V9.49951H16.6433ZM3 13.2493H13.2507L12.1197 14.4993H3V13.2493ZM3 16.9991H9.85792L8.72696 18.2491H3V16.9991ZM11.9417 17.8544L18.0704 11.6134C18.5197 11.2179 19.0959 11 19.6923 11C20.2887 11 20.8648 11.2179 21.3141 11.6134C21.5315 11.8319 21.704 12.0915 21.8217 12.3773C21.9394 12.6632 22 12.9697 22 13.2792C22 13.5887 21.9394 13.8952 21.8217 14.181C21.704 14.4669 21.5315 14.7265 21.3141 14.945L15.1705 21.186L11 22L11.9417 17.8544ZM14.4978 19.7991L20.3126 13.8746L20.3276 13.8445C20.4837 13.6863 20.5713 13.4722 20.5713 13.249C20.5713 13.0259 20.4837 12.8118 20.3276 12.6536C20.1602 12.5178 19.9519 12.4437 19.7371 12.4437C19.5223 12.4437 19.314 12.5178 19.1467 12.6536L13.3169 18.578L13.003 20.0855L14.4978 19.7991Z"
        />
    </SvgIcon>
);
export default DynamicsAnnotationSvg;
