import React from 'react';

import { Button, styled } from '@mui/material';

import StyledTooltip from '../common/StyledTooltip';
import { RenderWithTooltipProps } from './TextWithTooltip';

type TooltipConfirmationProps = {
    open: boolean;
    header: string;
    buttonConfirmText?: string;
    buttonCancelText?: string;
    subheader: React.ReactNode;
    buttonsAlignment?: 'left' | 'right';
    inverseButtonStyles?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    onOpen?: () => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
} & Pick<RenderWithTooltipProps, 'placement'>;

const TooltipConfirmation: React.FC<TooltipConfirmationProps> = ({
    open,
    header,
    subheader,
    buttonConfirmText = 'Confirm',
    buttonCancelText = 'Cancel',
    buttonsAlignment = 'right',
    inverseButtonStyles = false,
    onConfirm,
    onCancel,
    onOpen,
    placement,
    children,
    style = {},
}) => {
    return (
        <div style={style}>
            <StyledTooltip
                open={open}
                title={
                    <TooltipWithConfirmationComponent
                        primaryBtnText={inverseButtonStyles ? buttonCancelText : buttonConfirmText}
                        secondaryBtnText={inverseButtonStyles ? buttonConfirmText : buttonCancelText}
                        header={header}
                        subheader={subheader}
                        buttonsAlignment={buttonsAlignment}
                        onPrimaryBtn={inverseButtonStyles ? onCancel : onConfirm}
                        onSecondaryBtn={inverseButtonStyles ? onConfirm : onCancel}
                    />
                }
                placement={placement}
                arrow
                onOpen={() => {
                    if (onOpen) {
                        onOpen();
                    }
                }}
            >
                <div>{children}</div>
            </StyledTooltip>
        </div>
    );
};

export default TooltipConfirmation;

type TooltipWithConfirmationComponentProps = {
    primaryBtnText: string;
    secondaryBtnText: string;
    header: string;
    subheader: React.ReactNode;
    buttonsAlignment: 'left' | 'right';
    onPrimaryBtn: () => void;
    onSecondaryBtn: () => void;
};

const TooltipWithConfirmationComponent = ({
    header,
    subheader,
    primaryBtnText,
    secondaryBtnText,
    buttonsAlignment,
    onPrimaryBtn,
    onSecondaryBtn,
}: TooltipWithConfirmationComponentProps) => {
    const primary = (
        <PrimaryBtn color="primary" variant="text" onClick={onPrimaryBtn}>
            {primaryBtnText}
        </PrimaryBtn>
    );
    const secondary = <SecondaryBtn onClick={onSecondaryBtn}>{secondaryBtnText}</SecondaryBtn>;
    return (
        <Main>
            <Header>{header}</Header>
            <Subheader>{subheader}</Subheader>
            <CtaWrapper alignment={buttonsAlignment}>
                {buttonsAlignment === 'left' && (
                    <>
                        {primary}
                        {secondary}
                    </>
                )}
                {buttonsAlignment === 'right' && (
                    <>
                        {secondary}
                        {primary}
                    </>
                )}
            </CtaWrapper>
        </Main>
    );
};

const Main = styled('div')`
    padding: 8px;
`;

const CtaWrapper = styled('div')<{ alignment: string }>`
    margin-top: 16px;
    text-align: ${(props) => props.alignment};
`;

const Header = styled('p')(({ theme }) => ({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    color: theme.palette.common.white,
}));

const Subheader = styled('p')(({ theme }) => ({
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    color: theme.palette.grey[50],
    paddingTop: '8px',
}));

const PrimaryBtn = styled(Button)(({ theme }) => ({
    background: `${theme.palette.grey[200]}`,
    color: `${theme.palette.text.strong}`,
    borderRadius: '4px',
    padding: '9px 8px',
}));

const SecondaryBtn = styled(Button)(({ theme }) => ({
    background: 'transparent',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'center',
    color: `${theme.palette.common.white}`,
    marginRight: '6px',
}));
