import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SalesforceTaskSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M9.50922 5.99366C9.50922 5.62193 9.82967 5.32059 10.225 5.32059H15.9509C16.3462 5.32059 16.6667 5.62193 16.6667 5.99366V6.66673C16.6667 7.03845 16.3462 7.3398 15.9509 7.3398H10.225C9.82967 7.3398 9.50922 7.03845 9.50922 6.66673V5.99366Z" />
        <path d="M8.07773 10.0321C8.07773 9.66035 8.39818 9.359 8.79347 9.359H15.9509C16.3462 9.359 16.6667 9.66035 16.6667 10.0321V10.7051C16.6667 11.0769 16.3462 11.3782 15.9509 11.3782H8.79347C8.39818 11.3782 8.07773 11.0769 8.07773 10.7051V10.0321Z" />
        <path d="M8.07773 14.0705C8.07773 13.6988 8.39818 13.3974 8.79347 13.3974H15.9509C16.3462 13.3974 16.6667 13.6988 16.6667 14.0705V14.7436C16.6667 15.1153 16.3462 15.4166 15.9509 15.4166H8.79347C8.39818 15.4166 8.07773 15.1153 8.07773 14.7436V14.0705Z" />
        <path d="M3.78325 10.0321C3.78325 9.66035 4.1037 9.359 4.499 9.359H5.21474C5.61004 9.359 5.93049 9.66035 5.93049 10.0321V10.7051C5.93049 11.0769 5.61004 11.3782 5.21474 11.3782H4.499C4.1037 11.3782 3.78325 11.0769 3.78325 10.7051V10.0321Z" />
        <path d="M3.78325 14.0705C3.78325 13.6988 4.1037 13.3974 4.499 13.3974H5.21474C5.61004 13.3974 5.93049 13.6988 5.93049 14.0705V14.7436C5.93049 15.1153 5.61004 15.4166 5.21474 15.4166H4.499C4.1037 15.4166 3.78325 15.1153 3.78325 14.7436V14.0705Z" />
        <path d="M4.99064 8.02396C4.85088 8.15539 4.62429 8.15539 4.48453 8.02396L2.60482 6.25633C2.46506 6.12491 2.46506 5.91182 2.60482 5.7804L3.15931 5.25897C3.2992 5.12742 3.52607 5.12757 3.66577 5.25931L4.73758 6.27007L8.19521 3.01531C8.33495 2.88378 8.56164 2.88372 8.70145 3.01519L9.25616 3.53684C9.39592 3.66826 9.39592 3.88134 9.25616 4.01277L4.99064 8.02396Z" />
    </SvgIcon>
);
export default SalesforceTaskSvg;
