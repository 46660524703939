import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';

import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterProps,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, getInnerMostValueInFilterState, valueArrayToText } from '../utils';

const FIELD = 'social_media_urls#urls_web_tags';

export type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.social_media_channels,
    label: 'Social media channels',
    defaultOperator: FilterOperator.IN,
    initValue: { [FilterOperator.IN]: { [FIELD]: [] } },
};

export const socialMediaChannelsFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.social_media,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        render: (props: FilterProps<TValues>) => <SocialMediaChannels {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

const SocialMediaChannels: React.VFC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);
    const { webTags } = useFilterOptionsContext();

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const disabledFilterOptions = sameFiltersState.flatMap((sameState) =>
        getInnerMostValueInFilterState(sameState, filter.valuePath),
    );

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;

                    const selectedOptions = webTags[FilterID.social_media_channels].filter((option) =>
                        value.includes(option.value),
                    );
                    const disabledOptions = webTags[FilterID.social_media_channels].filter((option) =>
                        disabledFilterOptions.includes(option.value),
                    );

                    return (
                        <Select
                            placeholder={selectedOptions?.length ? 'Or...' : 'Select'}
                            multiple={true}
                            width="100%"
                            options={webTags[FilterID.social_media_channels]}
                            disabledOptions={disabledOptions}
                            value={selectedOptions}
                            onValueChange={(options) => onChange((options as SelectOption[]).map((o) => o.value))}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
