import { memo } from 'react';

import { Box, Skeleton } from '@mui/material';

import { GroupStructureRoot, Owner } from 'api/types/groupStructure';
import { ProfileDisplayMode } from 'components/modules/profiles/Profile';
import Frame from 'components/tokens/Frame';
import Typography from 'components/tokens/Typography';
import { useListsContext } from 'contexts/ListsContext';
import { formatDate } from 'utilities/date';

import GroupStructureTreeContent from './GroupStructureTreeContent';

export type GroupStructureTreeProps = {
    group_structure?: GroupStructureRoot[];
    profileBusinessId?: string;
    owners?: Owner[];
    last_checked?: string;
    mode: ProfileDisplayMode;
    skeleton?: boolean;
};

export const GroupStructureTree: React.FC<GroupStructureTreeProps> = memo(
    ({ group_structure, profileBusinessId, last_checked, mode, skeleton = false }) => {
        const { database } = useListsContext();

        if (skeleton) {
            return (
                <Frame title="Group structure">
                    <Skeleton
                        variant="rounded"
                        width={287}
                        height={63}
                        sx={{ marginTop: '72px', marginLeft: '12px' }}
                    />
                    <Skeleton variant="rounded" width={287} height={63} sx={{ marginTop: 2, marginLeft: '60px' }} />
                    <Skeleton variant="rounded" width={287} height={63} sx={{ marginTop: 2, marginLeft: '104px' }} />
                    <Skeleton variant="rounded" width={287} height={63} sx={{ marginTop: 2, marginLeft: '104px' }} />
                    <Skeleton variant="rounded" width={287} height={63} sx={{ marginTop: 2, marginLeft: '104px' }} />
                </Frame>
            );
        }

        if (!group_structure) {
            return null;
        }

        return (
            <Frame padding="large">
                <Box>
                    <Box
                        sx={{
                            marginBottom: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body1" weight="bold" style={{ color: 'text.strong' }}>
                            Group structure
                        </Typography>
                        {last_checked && (
                            <Typography variant="tiny" weight="normal" color="subtle" sx={{ fontStyle: 'italic' }}>
                                Last checked: <span style={{ fontWeight: 500 }}>{formatDate(last_checked)}</span>
                            </Typography>
                        )}
                    </Box>
                    <Typography variant="small" color="subtle">
                        {database === 'FI'
                            ? 'Source: Statistics Finland'
                            : 'Disclaimer: This is a beta feature. The results are generated using state-of-the-art AI technology. The accuracy of the results is high and will improve with time.'}
                    </Typography>
                </Box>
                <Box sx={{ padding: '18px 12px' }}>
                    <GroupStructureTreeContent
                        group_structure={group_structure}
                        profileBusinessId={profileBusinessId}
                        mode={mode}
                    />
                </Box>
            </Frame>
        );
    },
);

export default GroupStructureTree;
