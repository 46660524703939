import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronBigLeft: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M15.535 3.51501L7.05005 12L15.535 20.485L16.95 19.071L9.87805 12L16.95 4.92901L15.535 3.51501Z" />
    </SvgIcon>
);

export default ChevronBigLeft;
