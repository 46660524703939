import { useCallback, useRef, useState } from 'react';

import { useProfileDataWithQuery } from 'api/profileData';
import { isDomainData } from 'api/types/Organization';
import { EmbeddedDomainProfile } from 'components/modules/profiles/Profile/DomainProfile';
import { TabSlug, defaultTab, isValidTabSlug } from 'components/modules/profiles/Profile/tabs';
import { EmbeddedQuery } from 'contexts/EmbeddedQueryContext';

export type EmbeddedProfileProps = {
    query: EmbeddedQuery;
    token: string;
    scrollRef?: React.RefObject<HTMLElement>;
};

export const EmbeddedProfile: React.FC<EmbeddedProfileProps> = ({ query, token }) => {
    const { data, isLoading, isSuccess } = useProfileDataWithQuery(query, token);
    const [tab, setTab] = useState(defaultTab);
    const scrollRef = useRef<HTMLDivElement>(null);

    const domainData = isDomainData(data) ? data : undefined;

    const handleTabChange = useCallback(
        (tab: TabSlug, scrollTop?: boolean) => {
            if (isValidTabSlug(tab)) {
                if (scrollTop && scrollRef.current) {
                    scrollRef.current.scrollTop = 0;
                }
                setTab(tab);
            }
        },
        [scrollRef],
    );

    // Will later also made to handle business id profiles (organizations)

    return (
        <div ref={scrollRef}>
            <EmbeddedDomainProfile
                data={domainData}
                isLoading={isLoading}
                isSuccess={isSuccess}
                activeTab={tab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default EmbeddedProfile;
