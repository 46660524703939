import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// eslint-disable-next-line @typescript-eslint/naming-convention
const PipedriveDeal: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.68003 16.38C10.08 16.64 10.61 16.86 11.28 16.96V18H11.27C11.27 18.1989 11.349 18.3897 11.4897 18.5303C11.6303 18.671 11.8211 18.75 12.02 18.75C12.2189 18.75 12.4097 18.671 12.5504 18.5303C12.691 18.3897 12.77 18.1989 12.77 18V16.92C14.19 16.62 15.34 15.57 15.34 14.12C15.34 13.71 15.26 13.32 15.06 12.96C14.86 12.61 14.58 12.34 14.27 12.09C13.6881 11.6462 13.1646 11.4747 12.5939 11.2878L12.57 11.28L12.3 11.2L11.83 11.07L11.75 11.05C11.58 11.01 11.45 10.97 11.32 10.92C11.16 10.86 10.98 10.76 10.82 10.66L10.62 10.56L10.58 10.53C10.39 10.37 10.29 10.24 10.24 10.14C10.18 10.03 10.14 9.9 10.14 9.68C10.14 9.09 10.78 8.34 12 8.29H12.12C12.81 8.33 13.24 8.51 13.49 8.68C13.76 8.85 13.89 9.04 13.96 9.16C14.18 9.5 14.65 9.62 15 9.4C15.34 9.18 15.46 8.71 15.24 8.36C15.09 8.14 14.82 7.75 14.32 7.42C13.92 7.16 13.41 6.95 12.75 6.85V6C12.75 5.59 12.41 5.25 12 5.25C11.59 5.25 11.25 5.59 11.25 6V6.88C9.83003 7.19 8.65003 8.22 8.65003 9.68C8.64179 10.0788 8.73104 10.4735 8.91003 10.83C9.10003 11.19 9.37003 11.48 9.70003 11.73C9.82058 11.8193 9.94759 11.8995 10.08 11.97C10.27 12.08 10.53 12.21 10.78 12.31C11 12.4 11.21 12.46 11.38 12.5L11.48 12.52L11.85 12.62L12.13 12.72L12.1991 12.7422C12.7417 12.9167 13.0044 13.0012 13.35 13.27C13.58 13.45 13.69 13.58 13.75 13.7C13.81 13.8 13.85 13.92 13.85 14.12C13.85 14.74 13.24 15.47 12.01 15.51H11.88C11.19 15.47 10.76 15.29 10.51 15.13C10.24 14.95 10.11 14.77 10.04 14.65C9.98794 14.5655 9.91958 14.4921 9.83893 14.4342C9.75827 14.3763 9.66692 14.335 9.57018 14.3127C9.47343 14.2904 9.37321 14.2875 9.27534 14.3042C9.17747 14.3209 9.08389 14.3568 9.00003 14.41C8.66003 14.62 8.54003 15.09 8.76003 15.44C8.91003 15.67 9.18003 16.05 9.68003 16.38Z"
        />
    </SvgIcon>
);

export default PipedriveDeal;
