import JobProgress from 'components/templates/JobProgress';

type Props = {
    progress?: number | null;
    companyCount?: number;
    onCancel?: () => void;
};

const AnalysisProgress: React.FC<Props> = ({ progress, companyCount, onCancel }) => {
    return (
        <JobProgress
            sx={{ minWidth: '100%' }}
            title="Generating Insights…"
            determinateProcessMessage="companies processed"
            status={progress == null ? 'init' : 'processing'}
            progress={progress}
            total={companyCount}
            onCancel={onCancel}
        />
    );
};

export default AnalysisProgress;
