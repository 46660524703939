/**
 * Also can be referred as FORM_OF_COMPANIES_FULL_NAME
 */
export const LEGAL_ENTITIES_FULL_NAME: Record<string, string> = {
    AYH: 'Aatteellinen yhdistys (AYH)',
    AY: 'Avoin yhtiö (AY)',
    AOY: 'Asunto-osakeyhtiö (AOY)',
    AHVE: 'Ahvenanmaan maakunta ja sen virastot (AHVE)',
    ASY: 'Asumisoikeusyhdistys (ASY)',
    ELSYH: 'Erityislainsäädäntöön perustuva yhdistys (ELSYH)',
    ESÄÄ: 'Eläkesäätiö (ESÄÄ)',
    EVLUT: 'Ev.lut.kirkko (EVLUT)',
    EYHT: 'Elinkeinoyhtymä (EYHT)',
    KY: 'Kommandiittiyhtiö (KY)',
    KP: 'Kuolinpesä (KP)',
    KONK: 'Konkurssipesä (KONK)',
    MUYP: 'Muu yhteisvast.pidätysvelvollinen (MUYP)',
    KKOY: 'Keskinäinen kiinteistöosakeyhtiö (KKOY)',
    KUNTYHT: 'Kuntayhtymä (KUNTYHT)',
    KUNT: 'Kunta (KUNT)',
    KUNTLL: 'Kunnallinen liikelaitos (KUNTLL)',
    KVAKYH: 'Keskinäinen vahinkovak.yhdistys (KVAKYH)',
    KVY: 'Keskinäinen vakuutusyhtiö (KVY)',
    LIY: 'Laivanisännistöyhtiö (LIY)',
    lyh_Paliskunta: 'Paliskunta',
    MYH: 'Muu yhdistys (MYH)',
    MHY: 'Metsänhoitoyhdistys (MHY)',
    MOHLÖ: 'Muut oikeushenkilöt (MOHLÖ)',
    MJUO: 'Muu julkisoik. oikeushenkilö (MJUO)',
    MSÄÄ: 'Muu säätiö (MSÄÄ)',
    MTYH: 'Muu taloudellinen yhdistys (MTYH)',
    MUUKOY: 'Muu kiinteistöosakeyhtiö (MUUKOY)',
    MUU: 'Muu yhtiö (MUU)',
    MUVE: 'Muu verotuksen yksikkö (MUVE)',
    Osakeyhtiö: 'Osakeyhtiö (OY)',
    OYJ: 'Julkinen osakeyhtiö (OYJ)',
    OP: 'Osuuspankki (OP)',
    OK: 'Osuuskunta (OK)',
    ORTO: 'Ortodoksinen kirkko (ORTO)',
    RUSY: 'Rek. uskonnollinen yhdyskunta (RUSY)',
    RY: 'Rekisteröity yhdistys (RY)',
    SP: 'Säästöpankki (SP)',
    SL: 'Ulkomaisen elinkeinonharjoittajan sivuliike (SL)',
    SÄÄ: 'Säätiö (SÄÄ)',
    TEKA: 'Työeläkekassa (TEKA)',
    TYKA: 'Työttömyyskassa (TYKA)',
    ULKO: 'Ulkomainen yhteisö (ULKO)',
    VALT: 'Valtio ja sen laitokset (VALT)',
    VALTLL: 'Valtion liikelaitos (VALTLL)',
    VAKK: 'Vakuutuskassa (VAKK)',
    VEYHT: 'Verotusyhtymä (VEYHT)',
    VOY: 'Vakuutusosakeyhtiö (VOY)',
    VY: 'Vakuutusyhdistys (VY)',
    YO: 'Ylioppilaskunta tai osakunta (YO)',
    YEH: 'Yksityinen elinkeinonharjoittaja (YEH)',
    YHTE: 'Yhteisetuudet (YHTE)',
    YHME: 'Yhteismetsä (YHME)',
    AAFY: 'AAFY (Ikke næringsdrivende virksomhet)',
    ADOS: 'ADOS (Administrativ enhet -offentlig sektor)',
    ANNA: 'ANNA (Annen juridisk person)',
    ANS: 'ANS (Ansvarlig selskap med solidarisk ansvar)',
    AS: 'AS (Aksjeselskap)',
    ASA: 'ASA (Allmennaksjeselskap)',
    BA: 'BA (Selskap med begrenset ansvar)',
    BBL: 'BBL (Boligbyggelag)',
    BEDR: 'BEDR (Bedrift)',
    BO: 'BO (Andre bo)',
    BRL: 'BRL (Borettslag)',
    DA: 'DA (Ansvarlig selskap med delt ansvar)',
    ENK: 'ENK (Enkeltpersonforetak)',
    EØFG: 'EØFG (Europeisk økonomisk foretaksgruppe)',
    ESEK: 'ESEK (Eierseksjonssameie)',
    FKF: 'FKF (Fylkeskommunalt foretak)',
    FLI: 'FLI (Forening/lag/innretning)',
    FYLK: 'FYLK (Fylkeskommune)',
    GFS: 'GFS (Gjensidig forsikringsselskap)',
    IKJP: 'IKJP (Andre ikke-juridiske personer)',
    IKS: 'IKS (Interkommunalt selskap)',
    KBO: 'KBO (Konkursbo)',
    KF: 'KF (Kommunalt foretak)',
    KIRK: 'KIRK (Den norske kirke)',
    KOMM: 'KOMM (Kommune)',
    KS: 'KS (Kommandittselskap)',
    KTRF: 'KTRF (Kontorfellesskap)',
    NUF: 'NUF (Norskregistrert utenlandsk foretak)',
    OPMV: 'OPMV (Særskilt oppdelt enhet, jf. mval. § 2-2)',
    ORGL: 'ORGL (Organisasjonsledd)',
    PERS: 'PERS (Andre enkeltpersoner som registreres i tilknyttet register)',
    PK: 'PK (Pensjonskasse)',
    PRE: 'PRE (Partrederi)',
    SA: 'SA (Samvirkeforetak)',
    SÆR: 'SÆR (Annet foretak ifølge særskilt lov)',
    SAM: 'SAM (Tingsrettslig sameie)',
    SE: 'SE (Europeisk selskap)',
    SF: 'SF (Statsforetak)',
    SPA: 'SPA (Sparebank)',
    STAT: 'STAT (Staten)',
    STI: 'STI (Stiftelse)',
    TVAM: 'TVAM (Tvangsregistrert for MVA)',
    UTLA: 'UTLA (Utenlandsk enhet)',
    VPFO: 'VPFO (Verdipapirfond)',
};

export function getLegalEntityFullName(shortForm: string) {
    return LEGAL_ENTITIES_FULL_NAME[shortForm] ?? shortForm;
}
