import { OptionMapping, Options } from './types';

export const getOptionMapping = (options: Options | undefined): OptionMapping => {
    const filterValues = new Map();
    const rangeValues = new Map();
    options?.forEach((value, key) => {
        filterValues.set(value, key);
        rangeValues.set(key, value);
    });
    return [filterValues, rangeValues];
};

/**
 * This is like getting the value of item in an array based on index.
 */
export const rangeToFilterValue = (value: number, optionMapping: OptionMapping) => {
    const askedValue = optionMapping[1].get(value);
    if (askedValue === undefined) {
        throw new Error('getValueFromOption got undefined value');
    }
    return askedValue;
};

/**
 * This is like finding index of the item in an array
 */
export const filterToRangeValue = (value: number, optionMapping: OptionMapping): number => {
    if (!optionMapping[0].size) {
        return value;
    }
    const askedValue = optionMapping[0].get(value);

    if (askedValue == null) {
        let closest;
        for (const [key, _value] of Array.from(optionMapping[1])) {
            if (value > _value) {
                closest = key;
            }
        }
        return closest || 0;
    }

    return askedValue;
};

export const toRangeValues = (filterValues: number[], optionMapping: OptionMapping) => {
    const low = filterToRangeValue(filterValues[0], optionMapping);
    const high = filterToRangeValue(filterValues[1], optionMapping);
    return [low, high];
};
