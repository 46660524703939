import { createContext, useContext, FC, ReactNode } from 'react';

import useCRMIntegrationConfig, { CrmState, Dispatch } from '../hooks/useCRMIntegrationConfig';

const CRMIntegConfigStateContext = createContext<CrmState>({} as CrmState);
const CRMIntegConfigDispatchContext = createContext<Dispatch>({} as Dispatch);

type CRMIntegConfigProviderProps = {
    children?: ReactNode;
};

const CRMIntegConfigProvider: FC<CRMIntegConfigProviderProps> = ({ children }) => {
    const [state, dispatch] = useCRMIntegrationConfig();

    return (
        <CRMIntegConfigStateContext.Provider value={state}>
            <CRMIntegConfigDispatchContext.Provider value={dispatch}>{children}</CRMIntegConfigDispatchContext.Provider>
        </CRMIntegConfigStateContext.Provider>
    );
};

function useCRMIntegConfigState() {
    return useContext(CRMIntegConfigStateContext);
}

function useCRMIntegConfigDispatch() {
    return useContext(CRMIntegConfigDispatchContext);
}

function useCRMIntegConfig() {
    return [useCRMIntegConfigState(), useCRMIntegConfigDispatch()] as const;
}

export { CRMIntegConfigProvider, useCRMIntegConfig, useCRMIntegConfigState, useCRMIntegConfigDispatch };
