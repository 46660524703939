import { add, differenceInMonths, startOfMonth, sub } from 'date-fns';

import { ExternalReferencesStatsPayload } from 'api/externalReferences';
import { TargetReqParam } from 'components/modules/connectors/utils/CRMTargetText';
import { formatDate } from 'utilities/date';
import { getObjectKeys } from 'utilities/objectUtils';

const endDate = add(startOfMonth(new Date()), { months: 1 });
const formatedEndDate = formatDate(endDate);
const startDate = sub(endDate, { months: 12 });
const formatedStartDate = formatDate(startDate);

const getGraphBuckets = (dateField: string) => {
    const diff = differenceInMonths(endDate, startDate);
    const buckets = new Array(diff).fill(null).map((_, idx) => {
        const date = add(startDate, { months: idx });
        const formated = formatDate(date);
        return {
            meta: { date: formated },
            query: {
                '?ALL': [
                    {
                        '?GTE': {
                            [dateField]: {
                                datetime: formated,
                            },
                        },
                    },
                    {
                        '?LT': {
                            [dateField]: {
                                datetime: formatDate(add(date, { months: 1 })),
                            },
                        },
                    },
                ],
            },
        };
    });
    return buckets;
};

const graphBuckets = getGraphBuckets('object_created_date');

const getBusinessImpactPayload = (queryUid: string, target: TargetReqParam, isDomainConnector: boolean) => {
    return {
        query: {
            '?ALL': [
                {
                    '?EQ': {
                        target,
                    },
                },
                {
                    '?IN': {
                        target_object: ['Account', 'Opportunity'],
                    },
                },
            ],
        },
        limit: 2000000,
        order: null,
        query_uid: queryUid,
        stats: [
            {
                id: 'created_objects',
                '?AGGREGATE': [
                    {
                        '?FILTER': {
                            query: {
                                '?ANY': [
                                    {
                                        '?EQ': {
                                            created_by_vainu: true,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ALL': [
                                    {
                                        '?GTE': {
                                            object_created_date: {
                                                datetime: formatedStartDate,
                                            },
                                        },
                                    },
                                    {
                                        '?LT': {
                                            object_created_date: {
                                                datetime: formatedEndDate,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?GROUP': {
                            path: 'external_object',
                        },
                    },
                ],
            },
            {
                id: 'open_deals_revenue',
                '?AGGREGATE': [
                    {
                        '?FILTER': {
                            query: {
                                '?EQ': {
                                    external_object: 'Opportunity',
                                },
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ANY': [
                                    {
                                        '?MATCH': {
                                            related_accounts: {
                                                '?ANY': [
                                                    {
                                                        '?EQ': {
                                                            created_by_vainu: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    },
                                    {
                                        '?EQ': {
                                            created_by_vainu: true,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ANY': [
                                    {
                                        '?EQ': {
                                            deal_status: 'open',
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ALL': [
                                    {
                                        '?GTE': {
                                            object_created_date: {
                                                datetime: formatedStartDate,
                                            },
                                        },
                                    },
                                    {
                                        '?LT': {
                                            object_created_date: {
                                                datetime: formatedEndDate,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?GROUP': {
                            path: 'revenue',
                            group_by: ['currency_code'],
                            sum_by: 'value',
                        },
                    },
                ],
            },
            {
                id: 'won_deals_revenue',
                '?AGGREGATE': [
                    {
                        '?FILTER': {
                            query: {
                                '?EQ': {
                                    external_object: 'Opportunity',
                                },
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ANY': [
                                    {
                                        '?MATCH': {
                                            related_accounts: {
                                                '?ANY': [
                                                    {
                                                        '?EQ': {
                                                            created_by_vainu: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    },
                                    {
                                        '?EQ': {
                                            created_by_vainu: true,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ANY': [
                                    {
                                        '?EQ': {
                                            deal_status: 'won',
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ALL': [
                                    {
                                        '?GTE': {
                                            close_date: {
                                                datetime: formatedStartDate,
                                            },
                                        },
                                    },
                                    {
                                        '?LT': {
                                            close_date: {
                                                datetime: formatedEndDate,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?GROUP': {
                            path: 'revenue',
                            group_by: ['currency_code'],
                            sum_by: 'value',
                        },
                    },
                ],
            },
            {
                id: 'new_deals_monthly',
                '?AGGREGATE': [
                    {
                        '?FILTER': {
                            query: {
                                '?EQ': {
                                    external_object: 'Opportunity',
                                },
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ANY': [
                                    {
                                        '?EQ': {
                                            created_by_vainu: true,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?GROUP': {
                            buckets: graphBuckets,
                        },
                    },
                ],
            },
            {
                id: 'new_companies_monthly',
                '?AGGREGATE': [
                    {
                        '?FILTER': {
                            query: {
                                '?EQ': {
                                    external_object: 'Account',
                                },
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ANY': [
                                    {
                                        '?EQ': {
                                            created_by_vainu: true,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?GROUP': {
                            buckets: graphBuckets,
                        },
                    },
                ],
            },

            {
                id: 'won_revenue_monthly',
                '?AGGREGATE': [
                    {
                        '?FILTER': {
                            query: {
                                '?EQ': {
                                    external_object: 'Opportunity',
                                },
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ANY': [
                                    {
                                        '?MATCH': {
                                            related_accounts: {
                                                '?ANY': [
                                                    {
                                                        '?EQ': {
                                                            created_by_vainu: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    },
                                    {
                                        '?EQ': {
                                            created_by_vainu: true,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?FILTER': {
                            query: {
                                '?ANY': [
                                    {
                                        '?EQ': {
                                            deal_status: 'won',
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        '?GROUP': {
                            buckets: getGraphBuckets('close_date'),
                            sum_by: 'revenue.value',
                        },
                    },
                ],
            },
            ...getObjectKeys(isDomainConnector ? crmFieldToVainuFieldMap : crmFieldToVainuCountryDataFieldMap).map(
                (field) => getCoverageStatsDefinition(field, isDomainConnector),
            ),
        ],
    } as ExternalReferencesStatsPayload;
};

export default getBusinessImpactPayload;

type ImpactCrmField =
    | 'industry'
    | 'revenue'
    | 'staff_number'
    | 'country'
    | 'technographic_data'
    | 'recruitment_keywords'
    | 'website_keywords'
    | 'website_traffic';

const vainuFieldsNotInCrm: Partial<Record<ImpactCrmField, string>> = {
    recruitment_keywords: 'recruitment_keywords.*.keyword',
    website_keywords: 'website_data.keywords',
    website_traffic: 'website_data.website_traffic_data.ranking',
};
const crmFieldToVainuFieldMap: Partial<Record<ImpactCrmField, string>> = {
    industry: 'vainu_custom_industries',
    staff_number: 'organization_size_indicators',
    country: 'business_units.*.address.country',
    technographic_data: 'technology_data',
    ...vainuFieldsNotInCrm,
};
const crmFieldToVainuCountryDataFieldMap: Partial<Record<ImpactCrmField, string>> = {
    revenue: 'financial_data.revenue',
    ...crmFieldToVainuFieldMap,
    industry: 'official_industries',
    country: 'visiting_address.country',
    ...vainuFieldsNotInCrm,
};

const labels: Record<ImpactCrmField, string> = {
    industry: 'Industry',
    revenue: 'Annual revenue',
    staff_number: 'Employee count',
    country: 'Country',
    technographic_data: 'Technographics',
    recruitment_keywords: 'Recruitment keywords',
    website_keywords: 'Website keywords',
    website_traffic: 'Website traffic',
};

const getCoverageStatsDefinition = (crmField: ImpactCrmField, isDomainConnector: boolean) => {
    return {
        id: `coverage:${crmField}`,
        label: labels[crmField],
        '?AGGREGATE': [
            {
                '?FILTER': {
                    query: {
                        '?EQ': {
                            external_object: 'Account',
                        },
                    },
                },
            },
            {
                '?EXISTS_IN': {
                    path: 'data_coverage',
                    value: crmField,
                    target: `${crmField}_exists_in_crm`,
                },
            },
            {
                '?BOOL': {
                    path: `${isDomainConnector ? 'domain_match' : 'organization_match'}.${
                        (isDomainConnector ? crmFieldToVainuFieldMap : crmFieldToVainuCountryDataFieldMap)[crmField]
                    }`,
                    target: `${crmField}_exists_in_vainu`,
                },
            },
            {
                '?GROUP': {
                    group_by: [`${crmField}_exists_in_crm`, `${crmField}_exists_in_vainu`],
                },
            },
        ],
    };
};
