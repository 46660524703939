import React, { FC } from 'react';

import { Box } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';

import OverflowContainer from 'components/layout/OverflowContainer';
import Button from 'components/tokens/Button';
import Typography from 'components/tokens/Typography';

import { AddRowGrid, BottomGrid, BottomWrapper, FiltersGrid, TopWrapper, Wrapper } from '../../FilterGroupTemplate';
import { useFilterGroupTemplateScroll } from '../../useFilterGroupTemplateScroll';

export interface LocationFilterGroupTemplateProps {
    label: string;
    isFilterAdded: boolean;
    setIsFilterAdded: (value: boolean) => void;
    applyChanges: () => void;
    undoChanges: () => void;
    clearFilters: () => void;
    rows: React.ReactNode;
    disabled?: boolean;
}

const LocationFilterGroupTemplate: React.FC<LocationFilterGroupTemplateProps> = (props) => {
    const { label, isFilterAdded, setIsFilterAdded, applyChanges, undoChanges, clearFilters, rows } = props;
    const { topWrapperRef, containerEl } = useFilterGroupTemplateScroll({ isFilterAdded, setIsFilterAdded });

    return (
        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={applyChanges}>
            <WrapperStyled
                role="presentation"
                id="paper"
                sx={{ position: 'relative', overflow: 'hidden', border: '1px solid', borderColor: 'grey.50' }}
                elevation={4}
            >
                {props.disabled && (
                    <Box
                        sx={{
                            position: 'absolute',
                            inset: 0,
                            zIndex: 1,
                            opacity: 0.1,
                            background: 'repeating-linear-gradient(-45deg, #000, #000 10px, #fff 10px, #fff 20px)',
                        }}
                    />
                )}
                <div style={{ height: '32px' }}>
                    <Typography component="p" variant="small">
                        {label}
                    </Typography>
                </div>
                <TopWrapperStyled>
                    <div ref={topWrapperRef}>
                        <OverflowContainer mode="vertical" headerPadding={0} containerEl={containerEl} />
                        {rows}
                    </div>
                </TopWrapperStyled>
                <BottomWrapperStyled>
                    <BottomGrid>
                        <Button sx={{ gridArea: 'clear' }} variant="flat" size="small" onClick={clearFilters}>
                            Clear
                        </Button>
                        {undoChanges ? (
                            <Button
                                sx={{ gridArea: 'undo', fontStyle: 'italic', color: 'text.secondary' }}
                                variant="flat"
                                size="small"
                                onClick={undoChanges}
                            >
                                Undo changes
                            </Button>
                        ) : null}
                        <Button
                            data-testid="e2e-submit-button"
                            sx={{ gridArea: 'save' }}
                            variant="primary"
                            size="small"
                            onClick={applyChanges}
                        >
                            Apply
                        </Button>
                    </BottomGrid>
                </BottomWrapperStyled>
            </WrapperStyled>
        </ClickAwayListener>
    );
};

export default LocationFilterGroupTemplate;

export const FiltersGridStyled = styled(FiltersGrid)`
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
`;

const TopWrapperStyled = styled(TopWrapper)`
    height: auto;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
`;

const BottomWrapperStyled = styled(BottomWrapper)`
    flex-grow: 0;
`;

const WrapperStyled = styled(Wrapper)`
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const SlotRemoveGroup = styled('div')`
    display: inline-flex;
    justify-content: flex-end;
    grid-area: clear;
`;

interface SlotGridRowProps {
    filters?: React.ReactNode[];
    addRowAction?: React.ReactNode;
    clearAction?: React.ReactNode;
}
export const SlotGridRow: FC<SlotGridRowProps> = ({ filters, addRowAction, clearAction }) => {
    const filtersLength = filters?.length ?? 0;
    return (
        <FiltersGridStyled rows={filtersLength > 0 ? 'true' : undefined}>
            {filters}
            {(addRowAction || clearAction) && (
                <AddRowGrid>
                    {addRowAction}
                    <SlotRemoveGroup>{clearAction}</SlotRemoveGroup>
                </AddRowGrid>
            )}
        </FiltersGridStyled>
    );
};
