import React from 'react';

import { styled } from '@mui/material';

const Dot = styled('span')<{ delay: number }>`
    animation-delay: ${(props) => props.delay}s !important;
`;

const Container = styled('span')<{ cycle: number }>`
    & > span {
        opacity: 0;
        animation: ellipsis-dot ${(props) => props.cycle}ms infinite;
    }

    @keyframes ellipsis-dot {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

type AnimatedEllipsisProps = {
    cycleMs?: number;
};

const AnimatedEllipsis = ({ cycleMs = 1000 }: AnimatedEllipsisProps) => (
    <Container cycle={cycleMs}>
        {[...Array(3)].map((_, i) => (
            <Dot delay={((i + 1) * cycleMs) / 10000}>.</Dot>
        ))}
    </Container>
);

export default AnimatedEllipsis;
