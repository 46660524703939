import { useMemo } from 'react';

// import { format, parseISO } from 'date-fns';
import { LatLngExpression } from 'leaflet';
import { IntlShape } from 'react-intl';
import { assertArray, isNotEmptyObject } from 'utilities';

import { BusinessUnitsData } from 'api/profileData';
import { BusinessUnit } from 'api/types/Organization';

import { FlatAddress } from './types';

const locationPriorities: Record<string, number> = {
    registry_main_location: 10,
    registry_hq: 9,
    hq: 8,
    registry_business_unit: 7,
    'registered address': 6,
    branchoffice: 5,
};

export const getLocationPriority = (address: FlatAddress): number =>
    address.types?.reduce((max, type) => Math.max(locationPriorities[type] ?? 0, max), 0) ?? 0;

export const addressCompareFn =
    (field: keyof FlatAddress, locale: string = 'en') =>
    (a: FlatAddress, b: FlatAddress): number =>
        String(a[field]).localeCompare(String(b[field]), locale);

export function formatPopupAddress({ street, postal_code, city }: FlatAddress) {
    const rows = [];
    if (street) {
        rows.push(street);
    }
    if (city || postal_code) {
        if (postal_code) {
            rows.push(`${postal_code} ${city}`);
        } else {
            rows.push(city);
        }
    }

    return rows;
}

type BusinessUnitWithCountry = BusinessUnit & {
    visiting_address: {
        country: string;
    };
};
export function getCountriesFromBusinessUnits(business_units: BusinessUnit[] | undefined): string[] {
    if (!business_units || !Array.isArray(business_units)) {
        return [];
    }
    return [
        ...new Set(
            business_units
                .filter((unit): unit is BusinessUnitWithCountry => !!unit.visiting_address?.country)
                .map((unit) => unit.visiting_address.country),
        ),
    ];
}

export const useCountriesFromBusinessUnits = (business_units: BusinessUnit[] | undefined): string[] =>
    useMemo(() => getCountriesFromBusinessUnits(business_units), [business_units]);

export function getLastModifiedDate(addresses: FlatAddress[]): string {
    // TODO: readd this when modified date becomes available in the API
    return 'n/a';

    // const dates = addresses
    //     .filter((address) => address.modified)
    //     .map((address) => parseISO(address.modified!).getTime());
    // const lastMod = dates.reduce((largest, current) => Math.max(current, largest), 0);
    // if (lastMod === 0) {
    //     return '';
    // }
    // return formatDate(lastMod, 'long');
}

export function getHQLocation({ country, city }: FlatAddress, intl: IntlShape): string | undefined {
    if (city) {
        return `in ${formatCity(city, country)}`;
    }
    if (country) {
        if (Intl.DisplayNames) {
            return `in ${intl.formatDisplayName(country, { type: 'region' }) ?? country}`;
        }
        return `in ${country}`;
    }
    return undefined;
}

export function getHQAddress({ street, postal_code }: FlatAddress): string | undefined {
    return [street, postal_code].join(', ');
}

export function formatAddressCountry({ country }: FlatAddress, intl: IntlShape): string {
    if (!Intl.DisplayNames) {
        return country || '';
    }
    return country ? intl.formatDisplayName(country, { type: 'region' }) ?? '' : '';
}

const hqKeysPrioritized = ['registry_hq', 'registry_main_location', 'hq'];

/**
 * Finds the location that is the headquarters.
 */
export function getHQ<AddressType extends FlatAddress | BusinessUnit>(
    addresses: AddressType[],
): AddressType | undefined {
    // Try the HQ type keys in order. First find each address for the first key, then for the second key, etc.
    for (const key of hqKeysPrioritized) {
        const hqCandidate = addresses.find((address) => address.types?.includes(key));
        if (hqCandidate) return hqCandidate;
    }
}

export function getHQCountry<AddressType extends FlatAddress | BusinessUnit>(addresses: AddressType[]): string {
    const hq = getHQ(addresses);
    if (hq) {
        if ('visiting_address' in hq) {
            return hq.visiting_address?.country ?? '';
        } else if ('country' in hq) {
            return hq.country ?? '';
        }
    }
    return '';
}

// These words in cities should not be capitalized
const lowercaseWords = [
    // Netherlands, Belgium
    'op',
    'aan',
    'den',
    'bij',
    'en',
    'de',
    'van',
    // France, Belgium
    "l'",
    'l’',
    'le',
    'la',
    'les',
    'lès',
    'du',
    'des',
    'à',
    'aux',
    'sur',
    'sous',
    // UK
    'on',
    'of',
    'upon',
    'the',
    'next',
    'cum',
    'by',
    'in',
    'with',
    'under',
    'and',
    // Germany
    'am',
    'an',
    'der',
    'ob',
    // Finland
    'as',
    'kk',
    'aho',
];

/**
 * Capitalizes the first letter of the part (a word separated with dash or space),
 * except when it's a known exception.
 */
const capitalizePart = (country?: string) => (part: string, index: number) => {
    // Return the delimiters as they are
    if (part === ' ' || part === '-') {
        return part;
    }
    if ((country === 'NL' || country === undefined) && part.startsWith('IJ')) {
        // In the Netherlands the digraph IJ is spelled with capital J.
        return part.substring(0, 2).toUpperCase() + part.substring(2).toLowerCase();
    }
    const partLC = part.toLowerCase();
    // These Dutch specialties are always lowercase, even at the beginning of the name.
    if (partLC === "'s" || partLC === '’s' || partLC === "'t" || partLC === '’t') {
        return partLC;
    }
    // Do not capitalize these exceptions, unless they're at the beginning of the name.
    if (index > 0 && lowercaseWords.includes(partLC)) {
        return partLC;
    }
    return part.charAt(0).toUpperCase() + part.substring(1).toLowerCase();
};

function splitCity(city: string): string[] {
    const splitParts: string[] = [];
    let remainingString = city;
    let i = 0;
    while (remainingString.length) {
        const char = remainingString[i];
        if (char === undefined) {
            splitParts.push(remainingString);
            remainingString = '';
        } else if (char === ' ' || char === '-') {
            splitParts.push(remainingString.substring(0, i));
            splitParts.push(char);
            remainingString = remainingString.substring(i + 1);
            i = 0;
        } else {
            i++;
        }
    }
    return splitParts;
}

/**
 * This function tries its best to transform the uppercase city names to properly capitalized names.
 * It's not 100% accurate, but at least 99.9% of the cases it can handle.
 * Sources used to make the list accurate, with visual browsing to find the exceptions
 * to the "capitalize each word" rule:
 * http://www.geonames.org/
 * https://fi.wikipedia.org/wiki/Luettelo_Suomen_postinumeroista_kunnittain
 */
export const formatCity = (city: string, country?: string): string =>
    splitCity(city).map(capitalizePart(country)).join('');

export function formatCityWithMunicipality(city?: string, municipality?: string, country?: string) {
    if (!city) {
        if (municipality) {
            formatCity(municipality, country);
        }
        return '';
    }
    if (
        municipality &&
        municipality !== city &&
        !municipality.startsWith('GEMEENTE ') &&
        municipality.substring(9) !== city
    ) {
        return `${formatCity(city, country)} (${formatCity(municipality, country)})`;
    }
    return formatCity(city, country);
}

export function getLatLng(address: FlatAddress): LatLngExpression | null {
    if (hasCoordinates(address)) {
        return [address.coordinates.latitude, address.coordinates.longitude];
    }
    return null;
}

export function formatLocationType(address: FlatAddress, hq?: FlatAddress) {
    if (address.uid === hq?.uid) {
        return 'Headquarters';
    }
    if (address.types?.includes('registry_business_unit')) {
        return 'Office';
    }
    return 'Other';
}

const locales: Record<string, string> = {
    FI: 'fi',
    SE: 'sv',
    DK: 'da',
    NO: 'no',
    NL: 'nl',
};
const defaultLocale = 'en';

export function getLocaleFromCountry(country?: string): string {
    return country ? locales[country] ?? defaultLocale : defaultLocale;
}

/**
 * This extracts either of the addresses (visiting or postal) from the business unit's nested structure,
 * to be shown in a table component that requires a non-nested structure.
 * It picks the visiting address, unless it's completely empty, in case it picks the postal address.
 * The type of the address is marked with a new injected field 'addressType'.
 */
export function getAddressesFromBusinessUnits(data: BusinessUnitsData | undefined): FlatAddress[] {
    return assertArray(data?.business_units).map((unit) => {
        const useVisitingAddress = isNotEmptyObject(unit.visiting_address, true);
        return {
            uid: unit.uid,
            types: unit.types,
            ...(useVisitingAddress ? unit.visiting_address : unit.address),
            addressType: useVisitingAddress ? 'visiting_address' : 'postal_address',
        };
    });
}

export const useAddressesFromBusinessUnits = (data: BusinessUnitsData | undefined): FlatAddress[] =>
    useMemo(() => getAddressesFromBusinessUnits(data), [data]);

/**
 * Returns true if the address has coordinates and they're valid.
 * Valid coordinates: both longitude and latitude are not null or undefined, and at least either of them is nonzero.
 * Thus, [0, 0] are not considered valid coordinates (https://en.wikipedia.org/wiki/Null_Island), but e.g. [51.478, 0] are.
 */
export function hasCoordinates(
    address: FlatAddress | undefined,
): address is FlatAddress & { coordinates: { latitude: number; longitude: number } } {
    return !!(
        address?.coordinates &&
        address.coordinates.latitude !== null &&
        address.coordinates.latitude !== undefined &&
        address.coordinates.longitude !== null &&
        address.coordinates.longitude !== undefined &&
        (address.coordinates.latitude || address.coordinates?.longitude)
    );
}
