import React, { useContext, useMemo, useRef } from 'react';

import { FormControlLabel, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { isNotEmpty } from 'utilities';

import { List } from 'api/types/List';
import { ProfileType } from 'api/types/Organization';
import { extractDomainName, formatURL } from 'components/features/BasicInfo/utils';
import { getIndustryTagsFromVCI, getIndustryTagsFromIndustriesVerbose } from 'components/features/Industries/utils';
import { ContactsCount } from 'components/features/lists/ContactsCount/ContactsCount';
import { CrmMenu, useCRMExport, CRMExport } from 'components/modules/connectors/crm-export';
import { getProfilePath } from 'components/modules/profiles/Profile';
import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import Button from 'components/tokens/Button';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import { Tag, TagProps } from 'components/tokens/Tag';
import TextOnTruncate from 'components/tokens/TextOnTruncate';
import Typography from 'components/tokens/Typography';
import { isUploaded, useListsContext } from 'contexts/ListsContext';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { CountryDatabase } from 'contexts/types/databases';
import { OrganizationResult, ProfileResult } from 'contexts/types/FilterCompanies';
import useAddToStaticList from 'hooks/useAddToStaticList';

import CompanyCardPriorityFields from '../CompanyCardPriorityFields';
import ListMenu from '../ListMenu';
import Tags from '../Tags';
import AdditionalTargetListInfo from './AdditionalTargetListInfo';
import CrmOwnerIndicator from './CrmOwnerIndicator';
import { ButtonContainer, Card, Image, ImageFrame, LogoAndOwner } from './styled';

export type CompanyCardProps = {
    id: string;
    profileType: ProfileType;
    levelInGroup?: number | null;
    companyName?: string;
    prospect: ProfileResult;
    image: string;
    logo?: string;
    description?: string | null;
    location?: string;
    domain?: string;
    parent?: boolean;
    subsidiariesCount?: number;
    priorityFields: string[];
    selected: boolean;
    loading?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    onSelect?: (value: string, isShiftClick: boolean) => void;
    onUnselect?: (value: string, isShiftClick: boolean) => void;
    onRemove?: (domain: string) => void;
    vainuSegment?: string;
    calculatedCardWidth?: number;
    hideActions?: boolean;
    hideCrmIndicator?: boolean;
    /** If the card is rendered as a part of list results, give the list id to exclude the list from the selection
     * of static lists where to add the company. */
    currentListId?: List['id'];
};

export const CompanyCard: React.FC<CompanyCardProps> = ({
    prospect,
    id,
    profileType,
    levelInGroup,
    companyName,
    image,
    logo,
    location,
    domain,
    description,
    parent = false,
    subsidiariesCount,
    priorityFields,
    selected,
    loading = false,
    onClick,
    onSelect,
    onUnselect,
    onRemove,
    vainuSegment,
    calculatedCardWidth = 280,
    hideActions,
    hideCrmIndicator,
    currentListId,
}) => {
    const { integrations } = useContext(PermissionContext);
    const linkRef = useRef<HTMLAnchorElement>(null);
    const { database, selectedList } = useListsContext();
    const { staticListOptions, addToList } = useAddToStaticList({ excludedListId: currentListId });

    const [exportState, { handleOpenExportDialog, handleCloseExportDialog }] = useCRMExport();

    const isOrganization = profileType === 'organization';

    const industryTags = useMemo(() => {
        return isOrganization
            ? getIndustryTagsFromIndustriesVerbose(prospect as OrganizationResult)
            : getIndustryTagsFromVCI(prospect);
    }, [isOrganization, prospect]);

    const tagLimit = isOrganization ? 1 : calculatedCardWidth > 320 ? 3 : 2;

    const handleClick = (event: React.MouseEvent) => {
        if (linkRef.current && (event.ctrlKey || event.metaKey)) {
            linkRef.current.dispatchEvent(new MouseEvent('click', { ctrlKey: event.ctrlKey, metaKey: event.metaKey }));
        } else if (onClick) {
            onClick(event);
        }
    };

    return (
        <>
            <Card
                padding="none"
                elevation={2}
                is_parent={parent ? 'true' : undefined}
                onClick={handleClick}
                sx={{ height: hideActions ? 330 : null }}
            >
                <Box component="a" sx={{ display: 'none' }} ref={linkRef} href={getProfilePath(profileType, id)}>
                    Open profile {companyName ?? '[name not set]'}
                </Box>
                <ImageFrame>
                    <Image
                        image={loading ? '' : image}
                        is_parent={parent ? 'true' : undefined}
                        className="company-card-background-image"
                    >
                        <svg width="0" height="0">
                            <defs>
                                <clipPath id="daughterClip" clipPathUnits="objectBoundingBox">
                                    <path d="M-0.125263 -0.587708L1.07458 -0.357745L1.00984 0.765506C1.00239 0.89473 0.939444 0.988577 0.869242 0.975124L-0.203491 0.769524L-0.125263 -0.587708Z" />
                                </clipPath>
                            </defs>
                        </svg>
                        <svg width="0" height="0">
                            <defs>
                                <clipPath id="parentClip" clipPathUnits="objectBoundingBox">
                                    <path d="M0.400957 -1.52869L1.30686 -0.204399L0.968975 0.731332C0.930098 0.838994 0.855203 0.862862 0.801694 0.784639L-0.00732422 -0.398012L0.400957 -1.52869Z" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Image>
                    <LogoAndOwner is_parent={parent ? 'true' : undefined}>
                        <CompanyLogo
                            size={parent ? 'larger' : 'large'}
                            logo={logo}
                            skeleton={loading && !logo}
                            company_name={companyName ?? '?'}
                            sx={{ outline: parent ? 'none' : '4px solid #ffffff' }}
                        />
                        {!hideCrmIndicator && (
                            <Box sx={{ marginLeft: -1, alignSelf: 'flex-end', display: 'flex' }}>
                                <CrmOwnerIndicator id={id} profileType={profileType} logo={logo} name={companyName} />
                            </Box>
                        )}
                        <AdditionalTargetListInfo companyIdentifier={id} />
                    </LogoAndOwner>
                </ImageFrame>
                <div
                    style={{
                        flexGrow: 1,
                        flexShrink: 0,
                        padding: '20px 20px 24px',
                        position: 'relative',
                    }}
                >
                    <TextOnTruncate
                        variant="body1"
                        weight="bold"
                        content={companyName ?? '[name not set]'}
                        arrow={false}
                        maxLength={30}
                        sx={{ marginBottom: 1 }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', marginY: 0.5 }}>
                        {!!location && (
                            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                                <Icon
                                    type="LocationOutline"
                                    color="text.subtle"
                                    fontSize="mini"
                                    sx={{ marginRight: 0.5 }}
                                />
                                <Typography
                                    variant="mini"
                                    weight="semibold"
                                    color="subtle"
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box !important',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                >
                                    {location}
                                </Typography>
                            </Box>
                        )}
                        {!!domain && (
                            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '150px' }}>
                                <Icon type="Globe" color="text.subtle" fontSize="mini" sx={{ marginRight: 0.5 }} />
                                <Link
                                    href={formatURL(domain)}
                                    toNewTab="nofollow"
                                    disablePropagation
                                    variant="mini"
                                    underline="always"
                                    fontWeight="500"
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box !important',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                >
                                    {extractDomainName(domain)}
                                </Link>
                            </Box>
                        )}
                        {
                            <ContactsCount
                                businessId={id}
                                color="#3F3F3F"
                                logo={logo}
                                country={database as CountryDatabase}
                                companyName={companyName}
                                sx={{
                                    '.MuiButton-startIcon': {
                                        marginRight: '4px',
                                    },
                                }}
                            />
                        }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                        <GroupLevelTag level={levelInGroup} />

                        {!!subsidiariesCount && parent && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Icon
                                    type="GroupStructure"
                                    color="text.subtle"
                                    fontSize="mini"
                                    sx={{ marginRight: 0.5 }}
                                />
                                <Typography variant="mini" weight="semibold" color="dark">
                                    {subsidiariesCount} subsidiaries
                                </Typography>
                            </Box>
                        )}
                        {industryTags && (
                            <Tags tags={industryTags} cutoff={tagLimit} sx={{ justifyContent: 'flex-start' }} />
                        )}
                    </Box>

                    <Typography
                        variant="tiny"
                        sx={{
                            marginTop: 2,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box !important',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'normal',
                            height: 34,
                        }}
                        color="default"
                    >
                        {description}
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                        {parent && (
                            <Typography variant="mini" weight="semibold" color="default" marginBottom="8px">
                                Group figures:
                            </Typography>
                        )}
                        <CompanyCardPriorityFields
                            prospect={prospect}
                            priorityFields={priorityFields}
                            loading={loading}
                        />
                    </Box>
                </div>

                {hideActions ? null : loading ? (
                    <Box sx={{ minHeight: 58 }} />
                ) : (
                    <ButtonContainer direction="row" spacing={1} onClick={(e) => e.stopPropagation()}>
                        <Box
                            sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
                            className={selected ? undefined : 'card-select-box-diminished'}
                        >
                            <FormControlLabel
                                label={
                                    <Typography component="span" color="subtle" variant="small">
                                        Select{selected && 'ed'}
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={selected}
                                        onClick={(e) => {
                                            selected ? onUnselect?.(id, e.shiftKey) : onSelect?.(id, e.shiftKey);
                                        }}
                                    />
                                }
                            />
                        </Box>

                        {onRemove &&
                            selectedList &&
                            isUploaded(selectedList) &&
                            !window.location.pathname.includes('/search/') && (
                                <Tooltip title="Remove">
                                    <Button
                                        onClick={() => onRemove(id || '')}
                                        size="small"
                                        variant="flat"
                                        startIcon={<Icon fontSize="mini" type="OffOutlineClose" color="text.subtle" />}
                                        className="card-action"
                                    />
                                </Tooltip>
                            )}
                        <Tooltip title="Add to custom list">
                            <ListMenu
                                anchorElement={
                                    <Button
                                        onClick={(e) => {}}
                                        size="small"
                                        variant="flat"
                                        startIcon={<Icon fontSize="mini" type="ListPlus" color="text.subtle" />}
                                        className="card-action"
                                    />
                                }
                                items={staticListOptions || []}
                                staticOptions={[{ action: 'createNewList', label: 'New custom list', icon: 'Plus' }]}
                                searchable
                                onClick={(action) => addToList(action, [id])}
                                disablePortal={false}
                            />
                        </Tooltip>
                        {isNotEmpty(integrations) && database && (
                            <Tooltip title="Send to">
                                <CrmMenu
                                    anchorButton={
                                        <Button
                                            onClick={(e) => {}}
                                            size="small"
                                            variant="flat"
                                            startIcon={<Icon fontSize="mini" type="Export" color="text.subtle" />}
                                            className="card-action"
                                        />
                                    }
                                    handleClick={(crmName) => {
                                        handleOpenExportDialog({
                                            selectionType: 'selection',
                                            exportType: 'company',
                                            crm: crmName,
                                            database,
                                            companyIds: [prospect.id],
                                            defaultVainuTags: vainuSegment
                                                ? [vainuSegment]
                                                : selectedList
                                                  ? [selectedList?.name]
                                                  : [],
                                        });
                                    }}
                                />
                            </Tooltip>
                        )}
                        {/* <Tooltip title="Download">
                                <Button
                                    onClick={(e) => {
                                    }}
                                    size="small"
                                    variant="flat"
                                    startIcon={<Icon fontSize="mini" type="Download" color="text.subtle" />}
                                    className="card-action"
                                />
                            </Tooltip>
                            <Tooltip title="Send to">
                                <ListMenu
                                    anchorElement={
                                        <Button
                                            onClick={(e) => {
                                            }}
                                            size="small"
                                            variant="flat"
                                            startIcon={<Icon fontSize="mini" type="Export" color="text.subtle" />}
                                            className="card-action"
                                        />
                                    }
                                    items={[
                                        { action: 'createNewList', label: 'New List', icon: 'Plus' },
                                        { action: 'uploadFile', label: 'Upload a list', icon: 'Excel' },
                                    ]}
                                    onClick={onSendMenuAction}
                                    disablePortal={false}
                                />
                            </Tooltip> */}
                    </ButtonContainer>
                )}
            </Card>
            <CRMExport {...exportState} onClose={handleCloseExportDialog} />
        </>
    );
};

export const GroupLevelTag: React.FC<Omit<TagProps, 'label'> & { level: number | null | undefined }> = ({
    level,
    onClick,
}) => {
    if (level === null) return null;

    return (
        <Tag
            variant="black"
            sx={{
                marginRight: '8px',
                maxHeight: '20px',

                '& > span': {
                    display: 'flex',
                    alignItems: 'center',
                },
            }}
            label={
                <>
                    <Icon type="GroupStructure" sx={{ width: '12px', height: '12px', marginRight: '4px' }} />
                    {level === 1 ? 'PARENT' : 'SUBSIDIARY'}
                </>
            }
            onClick={onClick}
        />
    );
};

export default CompanyCard;
