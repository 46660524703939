import React, { useState } from 'react';

import { Box } from '@mui/material';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';

type ExpandableRowProps = {
    rows: React.ReactNode[];
    cutoff?: number;
};

const ExpandableRow: React.VFC<ExpandableRowProps> = ({ rows, cutoff = 4 }) => {
    const [expanded, setExpanded] = useState(false);

    if (rows.length <= cutoff) {
        return <div>{rows}</div>;
    }

    const shownRows = expanded ? rows : rows.slice(0, cutoff);
    const moreCount = rows.length - cutoff;

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
            }}
        >
            <div>{shownRows}</div>
            <Button
                variant="flat"
                size="small"
                endIcon={<Icon type={expanded ? 'ChevronUp' : 'ChevronDown'} />}
                onClick={() => setExpanded(!expanded)}
            >
                {expanded ? 'Show less' : `${moreCount} more`}
            </Button>
        </Box>
    );
};

export default ExpandableRow;
