import React, { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import Dialog from 'components/tokens/Dialog';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import { FilterGroup } from '../FilterTypes';

export type LabelToRender = {
    valueToRender: string;
    operator: string;
};

type FilterChipContentProps = {
    uuid: string;
    activeFilterGroup: FilterGroup;
    labels: LabelToRender[];
    onClearFilters: (uuid: string) => void;
    hideClear?: boolean;
    showAll?: boolean;
};

export const FilterChipContent = ({
    uuid,
    activeFilterGroup,
    labels,
    onClearFilters,
    hideClear = false,
    showAll = false,
}: FilterChipContentProps) => {
    const [isWarning, setIsWarning] = useState(false);

    const handleClearFilters = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClearFilters(uuid);
        setIsWarning(false);
    };

    const title = activeFilterGroup.icon ? (
        <Icon type={activeFilterGroup.icon} fontSize="mini" />
    ) : (
        `${activeFilterGroup.label}:`
    );

    const getLabelForSingleSelectedFilter = () => {
        return (
            <ChipLabel>
                {labels.map((label, idx) => (
                    <React.Fragment key={idx}>
                        <Typography
                            component="span"
                            weight="semibold"
                            sx={{
                                color: label.operator?.includes('?NOT') ? 'error.main' : 'success.main',
                                fontSize: 'inherit',
                            }}
                        >
                            {label.operator?.includes('?NOT') && <span>&#8800;</span>} {label.valueToRender}
                        </Typography>
                        {idx !== labels.length - 1 && <Typography component="span">,&nbsp;</Typography>}
                    </React.Fragment>
                ))}
                {!hideClear && (
                    <IconButton
                        aria-label={`Clear ${title} filter`}
                        sx={{
                            padding: '2px',
                            marginLeft: '6px',
                            fontSize: 16,
                        }}
                        onClick={handleClearFilters}
                    >
                        <Icon type="CloseBig" fontSize="inherit" />
                    </IconButton>
                )}
            </ChipLabel>
        );
    };

    const getLabelForMultipleSelectedFilters = () => {
        return (
            <>
                <ChipLabel>
                    <Typography component="span" weight="semibold" sx={{ color: 'success.main', fontSize: 'inherit' }}>
                        {labels.length} filters
                    </Typography>
                    {!hideClear && (
                        <IconButton
                            aria-label={`Clear ${title} filters`}
                            sx={{
                                padding: '2px',
                                marginLeft: '6px',
                                fontSize: 16,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsWarning(true);
                            }}
                        >
                            <Icon type="CloseBig" fontSize="inherit" />
                        </IconButton>
                    )}
                </ChipLabel>
                <Dialog
                    PaperProps={{
                        sx: {
                            minWidth: 438,
                        },
                    }}
                    open={isWarning}
                    title={`Clear ${labels.length} filters?`}
                    message="All filters in this group will be cleared."
                    confirmLabel="Clear"
                    onClick={(e) => e.stopPropagation()}
                    onClose={() => setIsWarning(false)}
                    onSubmit={handleClearFilters}
                />
            </>
        );
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {title}
            <ChipLabel>
                {labels.length ? (
                    labels.length > 1 && !showAll ? (
                        getLabelForMultipleSelectedFilters()
                    ) : (
                        getLabelForSingleSelectedFilter()
                    )
                ) : (
                    <Typography component="span" weight="semibold" color="subtle" sx={{ fontSize: 'inherit' }}>
                        Any
                    </Typography>
                )}
            </ChipLabel>
        </Box>
    );
};

const ChipLabel = styled('div')`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
`;
