import { memo } from 'react';

import { Box } from '@mui/material';

import { Signal } from 'api/types/signals/signals';
import { TabChangeHandler } from 'components/modules/profiles/Profile';
import { TabSlug } from 'components/modules/profiles/Profile/tabs';
import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import { formatSignalDate } from '../Card/utils';
import SignalsGrid from '../Grid/SignalsGrid';

export type SignalsOverviewProps = {
    signals: Signal[];
    currentOrganizationBusinessId: string;
    skeleton?: boolean;
    onGoToClick?: TabChangeHandler;
};

export const SignalsOverview: React.VFC<SignalsOverviewProps> = memo(
    ({ signals, currentOrganizationBusinessId, skeleton = false, onGoToClick }) => {
        if (!signals?.length && !skeleton) {
            return null;
        }
        const latestSignal =
            signals.length > 0
                ? signals.reduce(
                      (acc, curr) => ((curr.vainu_date ?? '0') > (acc.vainu_date ?? '0') ? curr : acc),
                      signals[0],
                  )
                : undefined;
        const updatedString = latestSignal ? formatSignalDate(latestSignal) : '';

        return (
            <Frame padding="large" sx={{ height: '100%', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
                    <Typography variant="body1" weight="bold" component="h2" sx={{ lineHeight: '40px' }}>
                        Events
                    </Typography>
                    {!skeleton && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {updatedString && (
                                <Typography variant="tiny" color="subtle" sx={{ fontStyle: 'italic' }}>
                                    {'Last event: '}
                                    <Box component="span" sx={{ fontWeight: 500 }}>
                                        {updatedString}
                                    </Box>
                                </Typography>
                            )}
                            {onGoToClick && (
                                <Button
                                    sx={{ marginLeft: 1 }}
                                    variant="tertiary"
                                    startIcon={<Icon type="CircleRight" />}
                                    onClick={() => onGoToClick(TabSlug.Events, true)}
                                />
                            )}
                        </Box>
                    )}
                </Box>

                <SignalsGrid
                    signals={signals}
                    currentOrganizationBusinessId={currentOrganizationBusinessId}
                    variant="outlined"
                    loading={skeleton}
                />
            </Frame>
        );
    },
);

export default SignalsOverview;
