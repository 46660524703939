import React from 'react';

import { styled } from '@mui/material/styles';
import Joyride, { CallBackProps, Step, TooltipRenderProps } from 'react-joyride';

import Button from 'components/tokens/Button';
import { SelectOption } from 'components/tokens/select-components/Select';
import { brandPalette } from 'design/theme/colors';

interface Props {
    show: boolean;
    onNext: () => void;
    onFinish: () => void;
    firstSelectedIndustry?: SelectOption;
    step: number;
}

const VciOnboarding: React.FC<Props> = ({ show, onFinish, onNext, firstSelectedIndustry, step }) => {
    const steps: Step[] = [
        {
            target: '#industry-picker',
            title: 'Want to be more specific?',
            content: (
                <span>
                    You can set a confidence score for <span style={{ color: brandPalette.errorMagenta }}>{'{'}</span>
                    {firstSelectedIndustry?.label}
                    <span style={{ color: brandPalette.errorMagenta }}>{'}'}</span>. The higher the score, the more
                    likely companies are focused on it ✨
                </span>
            ),
            placement: 'top',
            hideCloseButton: true,
            disableBeacon: true,
        },
        {
            target: '#range-slider',
            title: 'Set the accuracy 👌',
            content:
                "We recommend high scores so you get the best results. But if you'd like a wider pool of companies, feel free to lower the score.",
            placement: 'right',
            hideCloseButton: true,
            disableBeacon: true,
            spotlightPadding: 0,
        },
    ];

    const handleJoyrideAction = (e: CallBackProps) => {
        if (e.action === 'next') {
            onNext();
        }

        if (e.action === 'close' || e.status === 'finished' || e.status === 'skipped') {
            onFinish();
        }
    };

    return (
        <Joyride
            steps={steps}
            run={show}
            stepIndex={step}
            callback={handleJoyrideAction}
            tooltipComponent={TooltipComponent}
            showSkipButton
            continuous
            hideBackButton
            styles={{
                options: {
                    arrowColor: 'brandPalette.onyx',
                    zIndex: 1500,
                },
                buttonNext: {
                    backgroundColor: 'brandPalette.subtleLight',
                    color: 'brandPalette.onyx',
                },
            }}
        />
    );
};

export default VciOnboarding;

const TooltipComponent = ({ isLastStep, step, primaryProps, tooltipProps, skipProps }: TooltipRenderProps) => {
    const { title, content } = step;

    return (
        <StyledTooltip {...tooltipProps}>
            <StyledTooltipTitle>{title}</StyledTooltipTitle>
            <StyledTooltipContent>{content}</StyledTooltipContent>
            <StyledActions>
                <Button variant="tertiary" size="small" onClick={primaryProps.onClick}>
                    {isLastStep ? 'Gotcha!' : 'Show me how'}
                </Button>
                {isLastStep ? null : <StyledSkip onClick={skipProps.onClick}>Skip tutorial</StyledSkip>}
            </StyledActions>
        </StyledTooltip>
    );
};

const StyledTooltip = styled('div')`
    padding: 16px;
    background-color: ${brandPalette.onyx};
    width: 310px;
    min-height: 150px;
    border-radius: 8px;
    color: ${brandPalette.white};
`;

const StyledTooltipTitle = styled('div')`
    font-weight: 700;
    font-size 16px;
    line-height 22px;
    padding-bottom: 8px;
`;

const StyledTooltipContent = styled('div')`
    font-size: 14px;
    line-height: 140%;
    padding-bottom: 16px;
    min-height: 57px;
`;

const StyledActions = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
`;

const StyledSkip = styled('div')`
    margin-left: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    width: 70px;
    cursor: pointer;
`;
