import { CrmName } from 'components/modules/connectors/types/Crm';

import Icon, { IconProps, IconType } from '../Icon';

export type CRMIconCRMName = CrmName | 'multiple';
export type CRMIconType = 'normal' | 'inCrm' | 'export';
export type CRMIconProps = {
    crm: CRMIconCRMName;
    type?: CRMIconType;
} & Omit<IconProps, 'type'>;

const crmIcons: Record<CRMIconCRMName, Record<CRMIconType, IconType>> = {
    dynamics: {
        normal: 'Dynamics',
        inCrm: 'InCrmDynamics',
        export: 'ExportDynamics',
    },
    hubspot: {
        normal: 'HubSpot',
        inCrm: 'InCrmHubSpot',
        export: 'ExportHubSpot',
    },
    pipedrive: {
        normal: 'Pipedrive',
        inCrm: 'InCrmPipedrive',
        export: 'ExportPipedrive',
    },
    salesforce: {
        normal: 'Salesforce',
        inCrm: 'InCrmSalesforce',
        export: 'ExportSalesforce',
    },
    salesforcesandbox: {
        normal: 'Salesforce',
        inCrm: 'InCrmSalesforce',
        export: 'ExportSalesforce',
    },
    multiple: {
        normal: 'Connectors',
        inCrm: 'InCrmMore',
        export: 'Export',
    },
};

export const CRMIcon: React.FC<CRMIconProps> = ({ crm, type = 'normal', ...props }) => {
    const iconType = crmIcons[crm][type];
    return <Icon type={iconType} {...props} />;
};

export default CRMIcon;
