import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// eslint-disable-next-line @typescript-eslint/naming-convention
const HubSpotDeal: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.28908 7.43893C3.40199 7.44125 3.4847 7.40652 3.56992 7.33619C5.1818 6.0064 6.79719 4.68102 8.40813 3.35017C8.80188 3.0248 9.23564 2.89405 9.71436 3.09671C10.5463 3.44889 10.6776 4.51793 9.96186 5.08344C8.96416 5.87163 8.02556 6.72477 7.12061 7.61659C6.19507 8.52881 6.03568 10.0239 6.77408 11.0917C7.2643 11.8006 7.94718 12.2025 8.81507 12.2734C9.63059 12.3401 10.335 12.0884 10.9403 11.543C11.3712 11.1547 11.8053 10.7701 12.238 10.3839C12.4285 10.2139 12.4296 10.2128 12.6043 10.3935C13.5235 11.3438 17.5904 15.5531 18.7051 16.7071C19.3069 17.3302 19.1782 18.2636 18.4396 18.6354C17.9749 18.8693 17.4258 18.7678 17.0349 18.3647C16.1582 17.4606 15.2844 16.5535 14.4114 15.6457C14.2113 15.4376 13.9806 15.3326 13.6849 15.3588C13.1352 15.4076 12.7458 16.1195 13.1748 16.6481C13.3782 16.8989 14.4486 17.9748 14.7365 18.2988C15.0876 18.6941 15.1291 19.2745 14.8621 19.7295C14.5155 20.3202 13.6919 20.4507 13.1492 19.9873C12.9274 19.798 10.5833 17.3664 9.61552 16.3613C9.39465 16.1319 9.1373 16.0549 8.83379 16.1209C8.28868 16.2396 8.05696 16.8896 8.37893 17.3472C8.52984 17.5616 8.67233 17.7933 8.76044 18.0381C8.91976 18.4811 8.73193 19.0396 8.38219 19.2895C7.95949 19.5917 7.39448 19.6067 6.99407 19.293C6.7614 19.1107 6.55862 18.8886 6.35251 18.6746C5.92478 18.2306 5.50622 17.7777 5.0768 17.3353C5.02756 17.2845 4.93336 17.2539 4.85982 17.2536C3.61582 17.2487 2.37176 17.25 1.1277 17.2502C0.687602 17.2502 0.329581 17.0883 0.123096 16.6885C0.0479245 16.5429 0.0265726 16.3643 0.00559755 16.1977C-0.0110444 16.0646 0.0147663 11.0677 0.0143267 8.70585C0.0142639 8.38959 0.077252 8.09625 0.293785 7.84995C0.511323 7.60258 0.781173 7.45438 1.10962 7.4432C1.48026 7.43064 2.93382 7.4317 3.28908 7.43893Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 16.2084C24.0022 16.4737 23.8941 16.6738 23.7573 16.8812C23.5428 17.2064 23.2183 17.2571 22.8723 17.2708C21.9229 17.3082 21.1345 16.9674 20.4776 16.2838C17.9829 13.6877 12.7988 8.30565 12.7052 8.20724C12.5612 8.05583 12.5573 8.05194 12.3943 8.19368C11.6139 8.87223 9.72754 10.5315 9.53682 10.6295C9.0439 10.8826 8.40925 10.7038 8.07195 10.2453C7.75544 9.81498 7.77817 9.17674 8.18028 8.79392C8.8648 8.14237 12.8254 4.76902 14.097 3.7084C14.4683 3.3988 15.0962 3.33248 15.4841 3.60641C16.0887 4.03339 19.3955 6.54877 20.4625 7.35417C20.5267 7.40259 20.6217 7.43204 20.7027 7.4328C21.377 7.43895 22.0525 7.4615 22.7252 7.42872C23.3169 7.39989 24.0085 7.89387 23.999 8.69978C23.9841 9.96934 23.989 14.9753 24 16.2084Z"
        />
    </SvgIcon>
);

export default HubSpotDeal;
