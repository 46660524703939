import { Collapse, styled } from '@mui/material';

import { boxPadding } from './GroupStructureLeaf.styled';

/**
 *
 * Types for styling only
 */
type PanelProps = { isGroupRoot?: boolean; isParent?: boolean };
type InnerPanelProps = { isGroupRoot?: boolean };
type CompanyContainerProps = { isGroupRoot?: boolean };
type ConnectionLineProps = { rounded?: boolean };

export const connectionRounding = 20;

export const Subtree = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isGroupRoot' && prop !== 'isParent',
})<PanelProps>(({ isGroupRoot, isParent, theme }) => ({
    width: '100%',
    position: 'relative',
    ...(!isGroupRoot && {
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 1,
            top: isParent ? -18 : 0,
            bottom: 0,
            borderLeft: `1px solid ${theme.palette.border}`,
        },
        '&:last-child::before': {
            display: 'none',
        },
        '&:last-child > .company-container::before': {
            content: '""',
            position: 'absolute',
            width: 1,
            top: isParent ? -18 : 0,
            bottom: `calc(50% + ${connectionRounding - boxPadding / 2}px)`,
            left: 0,
            borderLeft: `1px solid ${theme.palette.border}`,
        },
    }),
}));

export const InnerPanel = styled(Collapse, { shouldForwardProp: (prop) => prop !== 'isGroupRoot' })<InnerPanelProps>(
    ({ isGroupRoot }) => ({
        paddingLeft: isGroupRoot ? 28 : 28 + boxPadding,
    }),
);

export const CompanyContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isGroupRoot',
})<CompanyContainerProps>(({ isGroupRoot }) => ({
    position: 'relative',
    display: 'flex',
    paddingTop: isGroupRoot ? 0 : boxPadding,
    paddingLeft: isGroupRoot ? 0 : connectionRounding,
}));

export const ConnectionLine = styled('div', { shouldForwardProp: (prop) => prop !== 'rounded' })<ConnectionLineProps>(
    ({ theme, rounded }) => ({
        height: connectionRounding,
        width: connectionRounding,
        position: 'absolute',
        bottom: `calc(50% - ${boxPadding / 2}px)`,
        left: 0,
        borderLeft: '1px solid',
        borderBottom: '1px solid',
        borderColor: theme.palette.border,
        borderBottomLeftRadius: rounded ? connectionRounding : 0,
    }),
);
