import { TableCell } from '@mui/material';

import Typography from 'components/tokens/Typography';

export type HeaderCellProps = {
    children?: React.ReactNode;
};

export const HeaderCell: React.FC<HeaderCellProps> = ({ children }) => (
    <TableCell sx={{ textAlign: 'right', paddingTop: 2, paddingX: 2 }}>
        <Typography variant="tiny" weight="semibold">
            {children}
        </Typography>
    </TableCell>
);

export default HeaderCell;
