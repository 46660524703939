import { FilterTag } from 'api/types/Tag';

import { getCategory } from './getSignalCategory';

type Type = 'data' | 'other' | 'event';

type InputType = 'select' | 'input' | 'include-exclude' | null;

export type Option<T> = {
    value: T;
    title: string;
    group?: string;
};

type Child<T> = {
    type?: string;
    group?: string; // regeg group
    value?: T;
};

type Value<T> = {
    title: string;
    default?: boolean;
    value: string;
    child?: Child<T> | null;
};

export type Category = {
    value: string;
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operators?: Value<any>[];
    options?: Option<string | number>[];
    valueRequired?: boolean;
    group?: string;
    childrenType: InputType;
    selectableOnce?: boolean;
};

export type MainCategory = {
    value: string;
    title: string;
    permissions: string[] | null;
    type: Type;
    children: Category[];
    group?: string;
    selectableOnce?: boolean;
    childrenType: InputType;
    comingSoon?: boolean;
    disable?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const anyChangeOperator: Value<any> = {
    title: 'Any change',
    value: '~',
    child: null,
    default: true,
};

const percentIncreaseOperator: Value<number> = {
    title: 'Increase %',
    value: '+%',
    child: { type: 'number', value: 0 },
};

const percentDecreaseOperator: Value<number> = {
    title: 'Decrease %',
    value: '-%',
    child: { type: 'number', value: 0 },
};
// eslint-disable-next-line
const percentChangeOperator: Value<number> = {
    title: 'Change %',
    value: '%',
    child: { type: 'number', value: 0 },
};

const increaseOverValueOperator: Value<number> = {
    title: 'Increase over',
    value: '>',
    child: { type: 'number', value: 0 },
};
// eslint-disable-next-line
const increaseOverEqualValueOperator: Value<number> = {
    title: 'Increase over or qual',
    value: '>=',
    child: { type: 'number', value: 0 },
};
// eslint-disable-next-line
const decreaseUnderEqualValueOperator: Value<number> = {
    title: 'Decrease user or qual',
    value: '<=',
    child: { type: 'number', value: 0 },
};

const decreaseUnderValueOperator: Value<number> = {
    title: 'Decrease under',
    value: '<',
    child: { type: 'number', value: 0 },
};

const increaseAmountOperator: Value<number> = {
    title: 'Increase amount',
    value: '+',
    child: { type: 'number', value: 0 },
};

const decreaseAmountOperator: Value<number> = {
    title: 'Decrease amount',
    value: '-',
    child: { type: 'number', value: 0 },
};
// eslint-disable-next-line
const equalNumberOperator: Value<number> = {
    title: 'Equal',
    value: '==',
    child: { type: 'number', value: 0 },
};
// eslint-disable-next-line
const equalStringOperator: Value<number> = {
    title: 'Equal',
    value: '==',
    child: { type: 'string', value: 0 },
};
// eslint-disable-next-line
const equalStringArrayOperator: Value<string[]> = {
    title: 'Equal',
    value: '==',
    child: { type: 'string-array', value: [] },
};
// eslint-disable-next-line
const equalNumberArrayOperator: Value<number[]> = {
    title: 'Equal',
    value: '==',
    child: { type: 'number-array', value: [] },
};
// eslint-disable-next-line
const arrayStringInOperator: Value<string[]> = {
    title: 'Value in',
    value: '?in',
    child: { type: 'string-array', value: [] },
};
// eslint-disable-next-line
const arrayNumberInOperator: Value<number[]> = {
    title: 'Value in',
    value: '?in',
    child: { type: 'number-array', value: [] },
};
// eslint-disable-next-line
const arrayStringNotInOperator: Value<string[]> = {
    title: 'Value not in',
    value: '?nin',
    child: { type: 'string-array', value: [] },
};
// eslint-disable-next-line
const arrayNumberNotInOperator: Value<number[]> = {
    title: 'Value not in',
    value: '?nin',
    child: { type: 'number-array', value: [] },
};
// eslint-disable-next-line
const hasStringOperator: Value<string> = {
    title: 'List includes',
    value: '?has',
    child: { type: 'string', value: '' },
};
// eslint-disable-next-line
const HasNotStringOperator: Value<string> = {
    title: 'List does not include',
    value: '?hasnot',
    child: { type: 'string', value: '' },
};
// eslint-disable-next-line
const HasNumberOperator: Value<number> = {
    title: 'List includes',
    value: '?has',
    child: { type: 'number', value: 0 },
};
// eslint-disable-next-line
const HasNotNumberOperator: Value<number> = {
    title: 'List does not include',
    value: '?hasnot',
    child: { type: 'number', value: 0 },
};
// eslint-disable-next-line
const regexOperator: Value<string> = {
    title: 'Regex',
    value: '?regex',
    child: { type: 'regex', value: '', group: '' },
};

export const dataCategories: MainCategory[] = [
    {
        value: 'basic_data',
        title: 'Basic data',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        group: 'Data',
        childrenType: 'select',
        disable: false,
        children: [
            /*
            {
                value: 'address',
                title: 'Postal address',
                childrenType: 'input',
                operators: [anyChangeOperator],
            },
            */
            {
                value: 'visiting_address',
                title: 'Visiting address',
                childrenType: 'input',
                operators: [anyChangeOperator],
            },
            {
                value: 'visiting_city',
                title: 'Visiting city',
                childrenType: 'input',
                operators: [anyChangeOperator],
            },
            {
                value: 'visiting_postal',
                title: 'Visiting postal',
                childrenType: 'input',
                operators: [anyChangeOperator],
            },
            {
                value: 'phone',
                childrenType: 'input',
                title: 'Company phone',
                operators: [anyChangeOperator],
            },
            {
                value: 'domain',
                childrenType: 'input',
                title: 'Domain',
                operators: [anyChangeOperator],
            },
            {
                value: 'company_name',
                childrenType: 'input',
                title: 'Company name',
                operators: [anyChangeOperator],
            },
            {
                value: 'staff_number',
                childrenType: 'input',
                title: 'Employee count',
                operators: [
                    anyChangeOperator,
                    percentIncreaseOperator,
                    percentDecreaseOperator,
                    increaseOverValueOperator,
                    decreaseUnderValueOperator,
                    increaseAmountOperator,
                    decreaseAmountOperator,
                ],
            },
        ],
    },
    /*
    {
        value: 'basic_data_plus',
        title: 'Basic data +',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        childrenType: 'select',
        group: 'Data',
        disable: true,
        children: [],
    },
    */
    {
        value: 'financial_data',
        title: 'Financial data',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        childrenType: 'select',
        group: 'Data',
        disable: false,
        children: [
            {
                value: 'turn_over',
                childrenType: 'input',
                title: 'Revenue',
                operators: [
                    anyChangeOperator,
                    percentIncreaseOperator,
                    percentDecreaseOperator,
                    increaseOverValueOperator,
                    decreaseUnderValueOperator,
                ],
            },
        ],
    },
    /*{
        value: 'technology_data',
        title: 'Technologies',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        childrenType: 'select',
        group: 'Data',
        disable: false,
        children: [
            {
                value: 'technologies',
                childrenType: 'input',
                title: 'technology',
                operators: [anyChangeOperator, arrayNumberInOperator],
                options: [
                    { title: 'HubSpot CRM', value: 8086853, group: 'CRM' },
                    { title: 'Salesforce', value: 25783, group: 'CRM' },
                ],
            },
        ],
    },
    {
        value: 'website_data',
        title: 'Website data',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        childrenType: 'select',
        group: 'Data',
        disable: true,
        children: [],
    },
    {
        value: 'vehicle_data',
        title: 'Vehicle data',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        childrenType: 'select',
        group: 'Data',
        disable: true,
        children: [],
    },
    {
        value: 'corporate_structure_data',
        title: 'Corporate structure data',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        childrenType: 'select',
        group: 'Data',
        disable: true,
        children: [],
    },
    {
        value: 'business_unit_data',
        title: 'Business unit data',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        childrenType: 'select',
        group: 'Data',
        disable: true,
        children: [],
    },
    {
        value: 'social_media_data',
        title: 'Social media data',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        childrenType: 'select',
        group: 'Data',
        disable: true,
        children: [],
    },
    {
        value: 'contact_data',
        title: 'Contact data',
        permissions: ['triggers_full', 'triggers_2021_beta'],
        type: 'data',
        childrenType: 'select',
        group: 'Data',
        disable: true,
        children: [],
    },
    */
];

export const dataCategoriesValues = dataCategories.map((i) => i.value);

type Groups = {
    [str: string]: string;
};

const defaultEventSignals = [
    77883, 77884, 6893088, 8000034, 8000038, 8000039, 43536, 43535, 43545, 43543, 6967635, 8000019, 8000020, 8000021,
    8000060, 8000069, 8000037, 8000049, 3325073, 8000058, 2940230, 6944439, 8000056, 8000039,
];

export function getMainCategories(
    eventTags: FilterTag[],
    countryFilter: string[],
    includeAllEvents: boolean,
): MainCategory[] {
    const groups: Groups = {
        company_direction: 'Company direction',
        financial_development: 'Financial development',
        investments_and_upgrades: 'Investments and upgrades',
        legalities_and_controversy: 'Legalities',
        operations: 'Operations',
        other: 'Other',
        personnel_changes: 'Personnel changes',
    };
    const eventCategories: MainCategory[] = [
        {
            value: 'any',
            title: 'Any event',
            childrenType: null,
            permissions: [],
            type: 'event',
            children: [],
            group: 'Any event',
            selectableOnce: true,
        },
        ...Object.keys(groups).map((key) => ({
            value: key,
            title: groups[key],
            childrenType: null,
            permissions: [],
            type: 'event' as Type,
            children: [],
            group: key,
            selectableOnce: true,
        })),
    ];

    for (const tag of eventTags) {
        const [value, title, _cat, validForCountriesArray] = tag;
        const subCategory = getCategory(value);
        if (
            (includeAllEvents || defaultEventSignals.includes(value)) &&
            validForCountriesArray.some((i) => countryFilter.includes(i))
        ) {
            eventCategories
                .find((category) => category.group === subCategory)
                ?.children.push({
                    value: value.toString(),
                    title,
                    childrenType: 'include-exclude',
                    operators: [],
                    group: subCategory,
                    selectableOnce: true,
                });
        }
    }
    return [
        // ...dataCategories,
        ...eventCategories.filter((category) => category.children.length || category.value === 'any'),
    ];
}
