import { TableCell } from '@mui/material';

export type ValueCellProps = {
    total?: boolean;
    children?: React.ReactNode;
};

export const ValueCell: React.FC<ValueCellProps> = ({ total, children }) => (
    <TableCell sx={{ width: 132, textAlign: 'right', paddingX: 2, fontWeight: total ? 'medium' : 'normal' }}>
        {children}
    </TableCell>
);

export default ValueCell;
