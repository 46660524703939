import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SalesforceLeadSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M16.813 7.9603C17.1924 7.9603 17.5 8.27363 17.5 8.66015C17.5 8.88446 17.3945 9.09517 17.2162 9.22681L13.8162 11.7371C13.5748 11.9153 13.4738 12.2318 13.5661 12.5205L14.8653 16.5831C14.9828 16.9506 14.7856 17.3456 14.4248 17.4653C14.2158 17.5347 13.9869 17.498 13.8089 17.3665L10.4033 14.8523C10.1628 14.6747 9.83749 14.6747 9.59697 14.8522L6.19057 17.3668C5.88333 17.5936 5.45376 17.5237 5.23111 17.2108C5.10213 17.0295 5.0661 16.7962 5.13418 16.5833L6.43344 12.5205C6.52577 12.2318 6.42478 11.9153 6.18342 11.7371L2.78385 9.22678C2.47664 8.99993 2.40813 8.56234 2.63083 8.2494C2.76005 8.0678 2.9669 7.9603 3.18708 7.9603H16.813ZM10 2.5C11.2183 2.5 12.2059 3.50603 12.2059 4.74704C12.2059 5.98804 11.2183 6.99407 10 6.99407C8.78173 6.99407 7.79412 5.98804 7.79412 4.74704C7.79412 3.50603 8.78173 2.5 10 2.5Z" />
    </SvgIcon>
);
export default SalesforceLeadSvg;
