import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { useAxiosContext } from 'contexts/AxiosContext';

import { Vehicle } from './types/company';

export const getVehicles = async (axios: AxiosInstance, businessId: string, country: string) =>
    (await axios.get(`/mapi/get_vehicles/${businessId}/${country}`)).data?.vehicles;

export function useVehicles(businessId: string, country: string) {
    const axios = useAxiosContext();
    return useQuery<Vehicle[]>({
        queryKey: ['vehicles', businessId, country],
        queryFn: () => getVehicles(axios, businessId, country),
        retry: 1,
    });
}
