import React from 'react';

import { useWatch } from 'react-hook-form';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';

import { filterDefinitions } from '../../../filters/filterDefinitions';
import { getSameFiltersState } from '../../../filters/utils';
import { FilterGroup, FilterGroupProps, FilterID, GroupValues, MappedFilter, NodeValue } from '../../../FilterTypes';
import { useCreateFilterByDefaultSelfGroup } from '../../common/useCreateFilterByDefaultSelfGroup';
import FilterGroupTemplate from '../../FilterGroupTemplate';
import { useVainuCustomIndustryGroup } from './useVainuCustomIndustryGroup';
import { getFiltersById } from './utils';

const createNewGroup = (value: NodeValue) => value;

const config: FilterGroup = {
    group: GroupValues.vainu_custom_industry,
    label: 'Vainu Custom Industries',
    options: [],
    filterUUIDs: [],
    logic: {},
    match: true,
    render: (props: FilterGroupProps) => <VainuCustomIndustryGroup {...props} />,
    getFilters: getFiltersById,
    createNewGroup,
};

export const vainuCustomIndustryGroupDefinition: Record<GroupValues.vainu_custom_industry, FilterGroup> = {
    [GroupValues.vainu_custom_industry]: config,
};

export const VainuCustomIndustryGroup: React.VFC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    addNewGroup,
    removeGroup,
    updateGroups,
    ids = [],
    disabled = false,
}) => {
    useWatch({ name: ids });

    const { handleFilterRemove, filteredIds, filters, handleAddFilter, handleApplyChanges } =
        useVainuCustomIndustryGroup({
            ids,
            applyChanges,
            addNewGroup,
            updateGroups,
            removeGroup,
        });

    const activeFilters = filteredIds.flatMap((id) => filters[id].filters);

    const handleAddFilterOverride = () => {
        handleAddFilter();
        setIsFilterAdded(true);
    };

    const renderFilters = () => {
        const filterDefinition = filterDefinitions[FilterID.vainu_custom_industry];
        return filteredIds.map((id) => {
            const filter: MappedFilter = filters[id]?.filters[0];

            if (!filter) {
                return null;
            }

            const sameFiltersState = getSameFiltersState(activeFilters, filter);

            return filterDefinition
                ? filterDefinition.render({
                      key: filter.uuid,
                      uuid: filterDefinition.id,
                      filter: filter,
                      groupUUID: config.group,
                      removeFilter: () => handleFilterRemove(id),
                      sameFiltersState: sameFiltersState,
                  })
                : null;
        });
    };

    const { setIsFilterAdded, isFilterAdded } = useCreateFilterByDefaultSelfGroup(
        FilterID.vainu_custom_industry,
        filteredIds,
        handleAddFilter,
    );

    const addRowButton = (
        <Button
            variant="tertiary"
            size="small"
            onClick={handleAddFilterOverride}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            AND
        </Button>
    );

    const props = {
        label: 'Vainu Custom Industries',
        filters: renderFilters(),
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        addRowSelect: undefined,
        addRowButton,
        addRow: false,
        isFilterAdded,
        setIsFilterAdded,
        disabled,
    };
    return <FilterGroupTemplate {...props} />;
};
