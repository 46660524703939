import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Label = (props) => (
    <SvgIcon {...props}>
        <path d="M15.5 19L4.5 18.99C3.39932 18.99 2.5055 18.1007 2.5 17V7C2.5055 5.89934 3.39932 5.00999 4.5 5.01L15.5 5C16.1472 4.99975 16.7546 5.31274 17.13 5.84L21.5 12L17.13 18.16C16.7546 18.6873 16.1472 19.0002 15.5 19ZM4.5 7V17H15.5L19.05 12L15.5 7H4.5Z" />
    </SvgIcon>
);

export default Label;
