import React, { useState } from 'react';

import { Box, Skeleton, SxProps, styled } from '@mui/material';

import { Button } from 'components/tokens/Button';
import { Icon } from 'components/tokens/Icon';
import { TextTruncateTooltip } from 'components/tokens/TextTruncate';
import Typography from 'components/tokens/Typography';

import { TileBase, TileData } from '../types';
import BarTileDialog from './BarTileDialog';
import SingleDatapoint from './SingleDatapoint';
import Tile from './Tile';

interface BarTileProps extends TileBase {
    // tab label for `data`
    tabLabel?: string;
    extraData?: {
        [key: string]: {
            label: string;
            data?: TileData[];
        };
    };
    dialogTitle?: string;
    firstColumn?: {
        label: string;
        width?: number;
    };
    lastColumn?: {
        label: string;
        width?: number;
    };
    hidePercent?: boolean;
    highlightIndex?: number;
    percentHeaderLabel?: string;
    showCount?: boolean;
}

const BarTile: React.FC<BarTileProps> = ({
    title,
    data,
    total,
    limit = 10,
    sx,
    dialogTitle,
    firstColumn,
    lastColumn,
    tabLabel,
    extraData,
    hidePercent = false,
    highlightIndex = 0,
    percentHeaderLabel,
    showCount,
    onRowClick,
    ...props
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <Tile title={showCount && data.length > 1 ? `${title} (${data.length})` : title} sx={sx} {...props}>
            {data.length === 1 ? (
                <SingleDatapoint data={data[0]} onDetailsClick={onRowClick ? () => onRowClick(data[0]) : undefined} />
            ) : (
                <table width="100%" style={{ fontSize: 14, marginTop: 16, borderCollapse: 'collapse' }}>
                    <tbody>
                        {data.slice(0, limit).map((row, idx) => {
                            const percent = row.value ? (row.value / total) * 100 : (10 - idx) * 10;
                            return (
                                <TableRow
                                    key={idx}
                                    sx={{
                                        fontWeight: idx === highlightIndex ? 500 : 400,
                                        '&:hover': onRowClick
                                            ? {
                                                  cursor: 'pointer',
                                                  backgroundColor: 'grey.200',
                                                  '& > td': {
                                                      '&:first-of-type': {
                                                          borderRadius: '4px 0 0 4px',
                                                      },
                                                      '&:last-of-type': {
                                                          borderRadius: '0 4px 4px 0',
                                                      },
                                                  },
                                              }
                                            : undefined,
                                    }}
                                    onClick={onRowClick ? () => onRowClick(row) : undefined}
                                >
                                    <td
                                        style={{
                                            whiteSpace: 'nowrap',
                                            textAlign: 'right',
                                            width: firstColumn?.width || 100,
                                            paddingLeft: 4,
                                        }}
                                    >
                                        {row.label == null ? (
                                            <Skeleton width={80} sx={{ display: 'inline-block' }} />
                                        ) : (
                                            <TextTruncateTooltip
                                                text={row.label}
                                                showTooltip
                                                weight={idx === highlightIndex ? 'semibold' : 'normal'}
                                            />
                                        )}
                                    </td>
                                    {row.secondaryLabel && (
                                        <td style={{ width: 0, paddingLeft: '4px' }}>
                                            <Typography variant="inherit" color="subtle" component="div">
                                                {row.secondaryLabel}
                                            </Typography>
                                        </td>
                                    )}
                                    <td
                                        style={{
                                            padding: '4px 16px',
                                        }}
                                    >
                                        <ProgressBar
                                            value={percent}
                                            skeleton={row.value == null}
                                            highlight={idx === highlightIndex}
                                        />
                                    </td>
                                    {!hidePercent && (
                                        <td style={{ whiteSpace: 'nowrap', textAlign: 'right', width: 24 }}>
                                            {row.value != null ? <>{Math.round(percent)} %</> : <Skeleton width={40} />}
                                        </td>
                                    )}
                                    {row.valueLabel && (
                                        <td
                                            style={{
                                                whiteSpace: 'nowrap',
                                                textAlign: 'right',
                                                width: 0,
                                                paddingRight: 4,
                                            }}
                                        >
                                            {row.valueLabel}
                                        </td>
                                    )}
                                </TableRow>
                            );
                        })}
                    </tbody>
                </table>
            )}

            {data.length !== 0 && data.length > limit && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                        variant="flat"
                        size="small"
                        startIcon={<Icon type="ChevronBigDown" />}
                        onClick={() => setIsDialogOpen(true)}
                    />
                </Box>
            )}

            <BarTileDialog
                onClose={() => setIsDialogOpen(false)}
                title={dialogTitle || title}
                open={isDialogOpen}
                data={data}
                tabLabel={tabLabel}
                extraData={extraData}
                total={total}
                firstColumn={firstColumn}
                lastColumn={lastColumn}
                hidePercent={hidePercent}
                percentHeaderLabel={percentHeaderLabel}
            />
        </Tile>
    );
};

export default BarTile;

export const ProgressBar = ({
    value,
    skeleton,
    highlight,
    sx,
}: {
    value: number;
    skeleton?: boolean;
    highlight?: boolean;
    sx?: SxProps;
}) => (
    <Box
        sx={{
            backgroundColor: skeleton ? 'common.white' : 'brandColors.secondaryPurple100',
            borderRadius: 1,
            height: 20,
            overflow: 'hidden',
            ...sx,
        }}
    >
        {skeleton ? (
            <Skeleton
                variant="rectangular"
                sx={{
                    height: '100%',
                    width: `${value}%`,
                    borderRadius: 1,
                }}
            />
        ) : (
            <Box
                sx={{
                    backgroundColor: highlight ? 'brandColors.secondaryPurple700' : 'brandColors.secondaryPurple',
                    height: '100%',
                    width: `${value}%`,
                    borderRadius: 1,
                    transition: 'width 300ms',
                }}
            />
        )}
    </Box>
);

const TableRow = styled('tr')({});
