import React from 'react';

import { Box, Skeleton, Tooltip } from '@mui/material';
import { range } from 'utilities';

import { isDomainData } from 'api/types/Organization';
import NumberDataPoint from 'components/tokens/NumberDataPoint';
import { ProfileResult } from 'contexts/types/FilterCompanies';

import { formatDomainPriorityFields, formatPriorityFields } from './utils';

export type CompanyCardPriorityFieldsProps = {
    priorityFields: string[];
    prospect: ProfileResult;
    loading?: boolean;
};

export const CompanyCardPriorityFields: React.FC<CompanyCardPriorityFieldsProps> = ({
    priorityFields,
    prospect,
    loading = false,
}) => {
    // Until use of V3 API for business ids, we will
    const formattedPriorityFields = isDomainData(prospect)
        ? priorityFields.map((field) => formatDomainPriorityFields(prospect, field))
        : priorityFields.map((field) => formatPriorityFields(prospect, field));

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(3, 1fr)`,
                height: 36,
                gap: 2,
            }}
        >
            {loading
                ? range(formattedPriorityFields.length).map(() => <Skeleton variant="rounded" width={72} height={36} />)
                : formattedPriorityFields.map((formatted) => {
                      // const ceasedCompany = prospect.nstatus && prospect.nstatus >= 1;
                      // const isHugeFinnishVehiclesCount =
                      //     prospect.country === 'FI' &&
                      //     formatted.field === 'vehicles_count' &&
                      //     prospect.vehicles_count &&
                      //     prospect.vehicles_count > 10000;

                      /** TODO: Use real data when we get data from V3 API */
                      const ceasedCompany = false;
                      const isHugeFinnishVehiclesCount =
                          formatted.style === 'range' && formatted.field === 'vehicles_count';

                      return ceasedCompany ? (
                          <NumberDataPoint
                              key={formatted.field}
                              value={0}
                              label={formatted.label}
                              variant="small"
                              labelPosition="bottom"
                              align="left"
                          />
                      ) : isHugeFinnishVehiclesCount ? (
                          <Tooltip
                              key={formatted.field}
                              title={`${formatted.value[0]}-${formatted.value[1]}`}
                              placement="top"
                          >
                              <NumberDataPoint
                                  label={formatted.label}
                                  value={formatted.value[0]}
                                  isEstimation
                                  style="decimal"
                                  variant="small"
                                  labelPosition="bottom"
                                  align="left"
                              />
                          </Tooltip>
                      ) : (
                          <NumberDataPoint
                              key={formatted.field}
                              variant="small"
                              labelPosition="bottom"
                              align="left"
                              {...formatted}
                          />
                      );
                  })}
        </Box>
    );
};

export default CompanyCardPriorityFields;
