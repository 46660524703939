import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowDropDown: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 48 48">
        <path d="m24 30-10-9.95h20Z" />
    </SvgIcon>
);

export default ArrowDropDown;
