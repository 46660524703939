import { isArray, isEqual } from 'lodash';
import { get } from 'react-hook-form';

import { getValue } from 'components/features/Filters/filters/utils';
import { FilterID, FilterIDValues, isMappedFilterLike } from 'components/features/Filters/FilterTypes';
import { getFirstKey } from 'utilities/objectUtils';

import { getOfficeTypeValueFromFilter } from '../utils/helpers';

// TODO: Add error message
interface MatcherOptionsBase {
    message?: string; // Shown if rule is valid
    // error?: string // Shown if rule is not valid (needed for validateSlot)
    priority?: number;
}

export type MatcherValue = Record<string | number, unknown> | Array<Record<string | number, unknown>>;
export type MatcherResult = { answer?: unknown; valid: boolean | null } & MatcherOptionsBase;

export type Matcher = (matchData: MatcherValue) => MatcherResult;

interface MatcherOptions extends MatcherOptionsBase {
    field?: string;
}

export function matchNot(matcher: Matcher, options: MatcherOptions = {}): Matcher {
    return (matchData) => {
        const { valid, answer } = matcher(matchData);
        const newValid = !valid;
        return { valid: newValid, message: newValid ? options.message : undefined, answer: answer };
    };
}

interface ItemsValidProps {
    values: MatcherValue;
    options?: MatcherOptions;
    matcher: Matcher;
    operator: 'some' | 'each';
}

function isItemsValid({ values: _values, options = {}, matcher, operator }: ItemsValidProps) {
    let values: unknown = _values;

    if (options.field) {
        const field = options.field;
        if (field in _values) {
            values = _values[field as keyof typeof _values];
        }
    }

    if (!isArray(values)) {
        return { valid: false };
    }

    let isValid = operator === 'some' ? false : true;

    const result = values.map((value: MatcherValue) => {
        const res = matcher(value);

        if (res.valid === null) {
            return res;
        } else if (operator === 'some' && res.valid) {
            isValid = true;
        } else if (operator === 'each' && !res.valid) {
            isValid = false;
        }

        return res;
    });

    const message = isValid ? options.message : undefined;

    return { valid: isValid, answer: result, message, priority: options.priority };
}
export function matchSome(matcher: Matcher, options: MatcherOptions = {}): Matcher {
    return function matchSome(values: MatcherValue) {
        return isItemsValid({
            values,
            options,
            matcher,
            operator: 'some',
        });
    };
}

export function matchEach(matcher: Matcher, options: MatcherOptions = {}): Matcher {
    return function matchEach(values: MatcherValue) {
        return isItemsValid({
            values,
            options,
            matcher,
            operator: 'each',
        });
    };
}

const getItemByPriority = (item1: MatcherResult, item2: MatcherResult) => {
    const p1 = item1.priority ?? -1;
    const p2 = item2.priority ?? -1;
    if (item1.valid && p1 > p2) {
        return item1;
    }

    if (p1 === -1 && p2 === -1) {
        return item2.message ? item2 : item1;
    }
    return item2;
};

const or = (a: unknown, b: unknown) => Boolean(a || b);
const and = (a: unknown, b: unknown) => {
    if (a === null) {
        return b as boolean;
    }

    if (b === null) {
        return a as boolean;
    }

    return Boolean(a && b);
};
interface MatchersValidProps {
    matchData: MatcherValue;
    options?: MatcherOptions;
    matchers: Matcher[];
    operator: 'or' | 'and';
}

const getMergedAnswer = (answer1: unknown, answer2: unknown, valid: boolean) => {
    const _answer1 = answer1 as MatcherResult;
    const _answer2 = answer2 as MatcherResult;
    const item = getItemByPriority(_answer1, _answer2);
    return { ...item, message: valid ? item.message : undefined, valid: valid };
};

function isMatchersValid({ matchData, options = {}, matchers, operator }: MatchersValidProps) {
    let answer: unknown = null;
    let isValid = operator === 'or' ? false : true;

    const isValidValue = operator === 'or' ? or : and;
    matchers.forEach((matcher) => {
        const data = matcher(matchData);

        const testData = data.answer ?? data;

        if (isArray(testData)) {
            if (!answer) {
                answer = testData;
            } else if (isArray(answer)) {
                const mergedAnswer = answer.map((a, index) => {
                    const valid = isValidValue(a.valid, testData[index].valid);
                    return getMergedAnswer(answer, a, valid);
                });

                answer = mergedAnswer;
            } else {
                const mergedAnswer = testData.map((a, index) => {
                    const valid = isValidValue(a.valid, (answer as MatcherResult).valid);
                    return getMergedAnswer(answer, a, valid);
                });

                answer = mergedAnswer;
            }
        } else {
            if (!answer) {
                answer = data;
            } else {
                answer = getMergedAnswer(answer, data, isValidValue((answer as MatcherResult).valid, data.valid));
            }
        }

        if (data.valid === null) {
            return;
        }

        if (operator === 'or' && data.valid) {
            isValid = true;
        } else if (operator === 'and' && !data.valid) {
            isValid = false;
        }
    });

    let message = undefined;
    if (isValid) {
        message = options.message ?? (answer as MatcherResult)?.message;
    }

    return { valid: isValid, answer, message };
}

export function matchOr(matchers: Matcher[], options: MatcherOptions = {}): Matcher {
    return (matchData) => {
        return isMatchersValid({ matchers, options, matchData, operator: 'or' });
    };
}

export function matchAnd(matchers: Matcher[], options: MatcherOptions = {}): Matcher {
    return (matchData) => {
        return isMatchersValid({ matchers, options, matchData, operator: 'and' });
    };
}

type MatchPropertyValue = string | number;
type MatchPropertyOperator = 'eq' | 'gte' | 'lte';
type MatchPropertyProps = { key: MatchPropertyValue; value: MatchPropertyValue; operator?: MatchPropertyOperator };
export function matchProperty(props: MatchPropertyProps, options: MatcherOptions = {}): Matcher {
    return (object: unknown) => {
        const result = isPropertyMatch(props)(object);
        const answer = result ? options.message : undefined;

        return { valid: result, answer, priority: options.priority, message: answer };
    };
}

export function isPropertyMatch(props: MatchPropertyProps) {
    return (object: unknown) => {
        if (typeof object !== 'object' || object === null) {
            return false;
        }

        const { operator = 'eq', key: keyRaw, value } = props;

        const key = keyRaw.toString();

        const objectValue = key.includes('.') ? get(object, key) : (object as Record<typeof key, unknown>)[key];

        if (operator === 'eq') {
            return value === objectValue;
        } else if (operator === 'gte') {
            return objectValue > value;
        } else {
            return objectValue < value;
        }
    };
}

export function matchOfficeType(expectedValue: string[] | string, options: MatcherOptions = {}): Matcher {
    return (filter: unknown) => {
        if (isMappedFilterLike(filter)) {
            let valid = false;

            if (filter.id === FilterID.location_type) {
                const currentValue = getOfficeTypeValueFromFilter(filter);

                valid = isEqual(currentValue, expectedValue);
            }

            const answer = valid ? options.message : undefined;
            return { valid, answer };
        }

        return { valid: false };
    };
}

type CustomMatcher = (matchData: MatcherValue, options?: MatcherOptions) => MatcherResult;

export function matchCustom(customMatcher: CustomMatcher, options: MatcherOptions = {}): Matcher {
    return (matchData: MatcherValue) => {
        return customMatcher(matchData, options);
    };
}

interface FilterValueProps {
    id?: string;
    value: unknown;
    defaultValue?: string; // replace undefined to defaultValue
}

export function matchFilterValue(props: FilterValueProps, options: MatcherOptions = {}): Matcher {
    return (filter: unknown) => {
        if (isMappedFilterLike(filter)) {
            const { id: filterId, value: expectedValue } = props;
            let valid = false;

            if (!filterId || filter.id === filterId) {
                const currentValue = getValue(filter.id as FilterIDValues, filter.value);

                valid = isEqual(currentValue, expectedValue);
            }

            const answer = valid ? options.message : undefined;

            return { valid, answer };
        }

        return { valid: false };
    };
}

export function createMatcher(_rule: Rule, _options: MatcherOptions = {}): Matcher {
    const { rule: ruleProp, message, filter, priority, field } = _rule as ExtendedRule<Rule>;
    //console.log('_rule', _rule);
    const options: MatcherOptions = {};

    options.message = message ?? _options.message;
    options.priority = priority ?? _options.priority;
    options.field = field ?? _options.field;

    const actualRule: Rule = (ruleProp ?? _rule) as Rule;

    if (ruleProp) {
        if (filter) {
            return (matchData: unknown) => {
                if (isArray(matchData)) {
                    const filterMatcher = createMatcher(filter, options);
                    const filteredData = matchData.filter((d) => filterMatcher(d).valid) as unknown as MatcherValue;

                    return createMatcher(ruleProp as Rule, options)(filteredData);
                }
                const filterMatcher = createMatcher(filter, options);
                const result1 = filterMatcher(matchData as MatcherValue);

                if (!result1.valid) {
                    return { valid: null, answer: undefined };
                }
                return createMatcher(ruleProp as Rule, options)(matchData as MatcherValue);
            };
        }
        return createMatcher(ruleProp as Rule, options);
    }

    const ruleKey = getFirstKey(actualRule);

    const ruleValue = actualRule[ruleKey as keyof typeof actualRule] as BasicRule;

    switch (ruleKey) {
        case 'and':
            return matchAnd(
                (ruleValue as unknown as Rule[]).map((r) => {
                    return createMatcher(r, options);
                }),
                options,
            );

        case 'or':
            return matchOr(
                (ruleValue as unknown as Rule[]).map((r) => createMatcher(r, options)),
                options,
            );

        // officeType can be removed and replaced by filterValue instead
        case 'officeType': {
            return matchOfficeType(ruleValue as unknown as string, options);
        }
        case 'filterValue': {
            return matchFilterValue(ruleValue as unknown as FilterValueProps, options);
        }
        case 'not': {
            return matchNot(createMatcher(ruleValue, options), options);
        }

        case 'each': {
            const { field, ...other } = ruleValue as ExtendedRuleValue<RuleEachSome> & MatcherOptions;

            return matchEach(createMatcher(other, options), { ...options, field });
        }
        case 'some': {
            const { field, ...other } = ruleValue as ExtendedRuleValue<RuleEachSome> & MatcherOptions;

            return matchSome(createMatcher(other, options), { ...options, field });
        }
        case 'property':
            return matchProperty(ruleValue as unknown as MatchPropertyProps, options);
        case 'custom':
            return matchCustom(ruleValue as unknown as CustomMatcher, options);
    }

    throw new Error(`Unsupported rule type: ${ruleKey.toString()}`);
}

interface ExtendedRule<T> extends MatcherOptions {
    rule: T | ExtendedRule<T>;
    filter?: Rule;
}
type ExtendedRuleValue<T> = ExtendedRule<T> | T;

type RuleProperty = {
    property: MatchPropertyProps;
};
type RuleOfficeType = { officeType: string };

type RuleCustom = { custom: CustomMatcher };
type RuleFilterValue = { filterValue: FilterValueProps };
type BasicRuleValue =
    | ExtendedRuleValue<RuleProperty>
    | ExtendedRuleValue<RuleOfficeType>
    | ExtendedRuleValue<RuleFilterValue>
    | ExtendedRuleValue<RuleCustom>;

type CombinedRuleValue =
    | BasicRuleValue
    | ExtendedRuleValue<BasicRuleValue>
    | ExtendedRuleValue<RuleNot>
    | ExtendedRuleValue<RuleEachSome>
    | ExtendedRuleValue<RuleOrAnd>;
type RuleNot = { not: BasicRuleValue | RuleNot };
type RuleEachSome = { each: CombinedRuleValue } | { some: CombinedRuleValue };
type RuleOrAnd = { or: CombinedRuleValue[] } | { and: CombinedRuleValue[] };

type BasicRule = CombinedRuleValue | RuleNot | RuleEachSome | RuleOrAnd | BasicRuleValue;

export type Rule<T extends BasicRule = BasicRule> = ExtendedRule<T> | T;
