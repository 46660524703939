import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Link } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { startOfDay, sub } from 'date-fns';
import { flatten, last, uniqBy } from 'lodash';
import triggerNewCompanySvg from 'src/images/trigger-new-company.svg';

import { createMultipleMessageQueueItems, generateMessageQueueObject, sendMessageQueueItem } from 'api/message_queue';
import { Contact } from 'api/types/company/contacts';
import { MessageQueue } from 'api/types/MessageQueue';
import { destinationTypeToTarget, WorkFlowDestination } from 'api/types/Trigger';
import { ContactsUpgradeDialog } from 'components/features/Employees/Table/ContactsUpgradeDialog';
import { ViewType } from 'components/templates/ResultViewHeader/types';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useListsContext } from 'contexts/ListsContext';
import { useMessageQueueContext } from 'contexts/MessageQueueContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import { useTrigger, CompanyPreview, LeadPreview } from 'contexts/TriggerContext';
import { enabledCRMs, formatMessageQueueAsLeads, pickMessageQueueType } from 'utilities/triggers';

import { CRMObject } from '../connectors/types/Crm';
import TriggerExportModal from './TriggerExportModal';
import TriggerGrid from './TriggerGrid/TriggerGrid';
import TriggerGroupSubMenu from './TriggerGroupSubMenu';
import TableComponent from './TriggerTable/TableComponent';
import { getNextDeliveryLabel } from './utils';

const formatLead = (lead: LeadPreview): LeadPreview => {
    const { vainu_date, organizations = [], isValid = true, endOfResults = false } = lead;
    return {
        companies:
            organizations?.map((i) => ({
                id: 0,
                company_name: i.name || undefined,
                business_id: i.business_id || undefined,
                logo: i.logo_url || undefined,
            })) || [],
        company: organizations?.[0]
            ? {
                  id: 0,
                  company_name: organizations?.[0].name || undefined,
                  business_id: organizations?.[0].business_id || undefined,
                  logo: organizations?.[0].logo_url || undefined,
              }
            : undefined,
        contact: {},
        lead,
        vainu_date,
        isValid,
        preview: undefined,
        id: lead.id,
        endOfResults,
    };
};

const sortByDescendingVainuDate = (leads: LeadPreview[]) =>
    leads.sort((a, b) => b.vainu_date.localeCompare(a.vainu_date));

const fetchLeads = async (
    axios: AxiosInstance,
    pageParam: string,
    queryKeys: [string, string, string, Date[], string],
): Promise<LeadPreview[]> => {
    const [_queryKey, query, sourceID, timeSpan, triggerID] = queryKeys;
    if (!sourceID) {
        return Promise.resolve([]);
    }
    if (pageParam && new Date(pageParam) < timeSpan[0]) {
        return Promise.resolve([{ vainu_date: pageParam, isValid: false, endOfResults: true, id: '' }]);
    }

    const queryObject = JSON.parse(query || '{}');
    const queries: string[] =
        queryObject['?ANY']?.map((queryBlock: object) => JSON.stringify({ '?ANY': [queryBlock] })) || [];

    const responses: { data: LeadPreview[] }[] = await Promise.all(
        queries.flatMap((splitQuery) => [
            axios.get(buildQuery(splitQuery, sourceID, pageParam, false, timeSpan, triggerID)),
            ...(splitQuery.includes('leads.title')
                ? []
                : [axios.get(buildQuery(splitQuery, sourceID, pageParam, true, timeSpan, triggerID))]),
        ]),
    );

    const items: LeadPreview[] = [];

    responses.forEach(({ data = [] }: { data: LeadPreview[] }) => {
        items.push(...data);
    });

    if (items.length === 0) {
        const before = sub(new Date(pageParam ?? null), {
            days: 7,
        });
        return [{ vainu_date: before.toISOString(), isValid: false, id: '' }];
    }

    return sortByDescendingVainuDate(items.map(formatLead));
};

const getDateSlice = (vainuDate: string, timeSpan: Date[]) => {
    const before = new Date(vainuDate);

    let since = sub(before, {
        days: 7,
    });

    if (since < timeSpan[0]) {
        since = timeSpan[0];
    }

    return `&since=${since.toISOString()}&before=${before.toISOString()}`;
};

const buildQuery = (
    query: string,
    listId: string | undefined,
    pageParams: string,
    generatedLeadQuery: boolean,
    timeSpan: Date[],
    triggerID?: string,
): string => {
    const type = generatedLeadQuery ? 'data-changes' : 'signals';
    const beforeSince = getDateSlice(pageParams, timeSpan);
    return `/api/v3/list/${listId}${triggerID ? `/trigger/${triggerID}` : ''}/${type}/?query=${encodeURIComponent(
        query,
    )}${beforeSince}`;
};

type SelectedContacts = {
    [id: string]: Partial<Contact>;
};

type SelectedCompanies = {
    [id: string]: CompanyPreview;
};

type Props = {
    selectedRowIds: Record<string, boolean>;
    setSelectedRowIds: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    onboardingActive: boolean;
    activateTrigger: () => void;
    scrollRef?: React.RefObject<HTMLElement>;
};

const TriggerPreview: React.FC<Props> = ({
    selectedRowIds,
    setSelectedRowIds,
    onboardingActive,
    activateTrigger,
    scrollRef,
}) => {
    const [state] = useTrigger();
    const axios = useAxiosContext();
    const {
        items: messageQueue,
        setItems: setMessageQueue,
        fetchMoreMessageQueueItems,
        messageQueueFetching,
    } = useMessageQueueContext();
    const { database = 'DOMAIN_DATA_BASIC' } = useListsContext();
    const [selectedContacts, setSelectedContacts] = useState<SelectedContacts>();
    const [selectedCompanies, setSelectedCompanies] = useState<SelectedCompanies>({});
    const [timeSpan, setTimeSpan] = useState([startOfDay(sub(new Date(), { years: 2 }))]);
    const [showExportModal, setShowExportModal] = useState(false);
    const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
    const [exportDestination, setExportDestination] = useState<string>('');
    const [reDeliverID, setReDeliverID] = useState<string | null>(null);
    const [viewType, setViewType] = useState<ViewType>('grid');

    const { hasProductPermission } = usePermissionContext();
    const hasContactDataPermission = hasProductPermission(Permission.ContactData);

    const {
        query,
        source,
        id: triggerID,
        trackChanges,
        trackingLogic,
        destinations,
        logicChanged,
        created: triggerCreated,
        isActive,
        deliveryTime,
        notify,
    } = state;

    const hasCRMdestination = destinations.some((i) => enabledCRMs.includes(i.system));
    const selectContact = (id: string, contact: Partial<Contact>) => {
        setSelectedContacts({ ...selectedContacts, [id]: contact });
    };

    const selectCompany = (id: string, company: CompanyPreview) => {
        setSelectedCompanies({ ...selectedCompanies, [id]: company });
    };

    const openExportModal = (destination: string) => {
        setShowExportModal(true);
        setExportDestination(destination);
    };

    const openUpgradeDialog = useCallback(() => setUpgradeDialogOpen(true), []);

    const isNewCompanyTrigger = (!query || query === '{"?ANY":[]}') && trackChanges.includes('new_company');

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching: isFetchingLeads,
    } = useInfiniteQuery({
        queryKey: ['trigger-items', query, source, timeSpan, triggerID] as [string, string, string, Date[], string],
        queryFn: ({ pageParam, queryKey }) => fetchLeads(axios, pageParam, queryKey),
        initialPageParam: (timeSpan[1] || new Date()).toISOString(),
        getNextPageParam: (lastPage) => {
            if (last(lastPage)?.endOfResults) {
                return undefined;
            }
            return last(lastPage)?.vainu_date;
        },
        refetchOnWindowFocus: false,
        enabled: !!source && !isNewCompanyTrigger && !!timeSpan.length,
    });

    useEffect(() => {
        setSelectedRowIds({});
    }, [query, timeSpan, triggerID, setSelectedRowIds]);

    const isFetching = isFetchingLeads || ['initial', 'loadingMore'].includes(messageQueueFetching);

    const items = React.useMemo(() => {
        const messageQueueItems = formatMessageQueueAsLeads(
            messageQueue,
            pickMessageQueueType(trackChanges, trackingLogic),
            selectedContacts,
        );

        const leadData = uniqBy(flatten(data?.pages || []), 'id').map((item) => {
            if (!item.isValid) {
                return item;
            }
            if (selectedContacts?.[item.id]) {
                item.contact = selectedContacts[item.id];
            }
            const leadType = item.lead?.dynamic_values?.length ? 'GeneratedLead' : 'Lead';
            item.messages = [
                ...(item.lead?.messages || []),
                ...messageQueue.filter(
                    (mq) => item.lead?.vid?.toString() === mq.lead?.toString() && mq?.lead_type === leadType,
                ),
            ];
            item.messages.sort((a, b) => a.processing_timestamp?.localeCompare(b.processing_timestamp || '') || 0);
            const pickMainCompany = () => {
                if (selectedCompanies?.[item.id]) {
                    item.messages = (item.messages || []).filter(
                        (mq) => mq.relation.key === selectedCompanies[item.id].business_id,
                    );
                    return selectedCompanies[item.id];
                }
                const deliveredRelations = (item.messages || [])
                    .filter((mqi) => mqi.state === 'delivered')
                    .map((mqi) => mqi.relation);
                const intersectionArray = item?.companies?.filter((obj) =>
                    deliveredRelations.some((relation) => relation.key === obj.business_id),
                );
                return intersectionArray?.length ? intersectionArray[0] : item.companies?.[0];
            };
            // msq states "processing", "delivered", "delivering", "rejected_by_user", "error", "pending_query_changed"
            item.company = pickMainCompany();
            item.delivered = !!item.messages?.some(
                (deliveredMQ) =>
                    deliveredMQ.state === 'delivered' && deliveredMQ.relation.key === item?.company?.business_id,
            );
            item.olderThanTrigger = !!triggerCreated && item.vainu_date < triggerCreated;
            item.olderThanTriggerChanged = !!logicChanged && item.vainu_date < logicChanged;
            return item;
        });
        return sortByDescendingVainuDate([...leadData, ...messageQueueItems]);
    }, [
        messageQueue,
        trackChanges,
        trackingLogic,
        data?.pages,
        selectedContacts,
        triggerCreated,
        logicChanged,
        selectedCompanies,
    ]);

    const exportPreviewRows = useMemo(() => {
        if (reDeliverID) {
            return items.filter((i) => i.messages?.some((mq) => mq.id === reDeliverID));
        }
        return Object.keys(selectedRowIds)
            .filter((key) => selectedRowIds[key])
            .map((key) => items.find((item) => item.id === key))
            .filter(Boolean) as LeadPreview[];
    }, [reDeliverID, selectedRowIds, items]);

    useEffect(() => {
        /*
            trigger fetch more MQ objects if the current leads are older than fetched
            MQ objects.
        */
        if (messageQueueFetching === 'idle') {
            const lastMQTimestamp = last(messageQueue)?.created;
            const lastLeadTimestamp = last(items)?.vainu_date;
            if (lastMQTimestamp && lastLeadTimestamp && lastMQTimestamp > lastLeadTimestamp) {
                fetchMoreMessageQueueItems();
            }
        }
    }, [messageQueueFetching, fetchMoreMessageQueueItems, messageQueue, items]);

    const readyToFetchNextPage = !!hasNextPage && !isFetchingLeads;
    const readyToFetchMq = messageQueueFetching === 'idle';

    const fetchMore = useCallback(() => {
        if (readyToFetchNextPage) {
            fetchNextPage();
        }
        if (readyToFetchMq) {
            fetchMoreMessageQueueItems();
        }
    }, [fetchMoreMessageQueueItems, fetchNextPage, readyToFetchMq, readyToFetchNextPage]);

    if (error && !isFetchingLeads) {
        return <Typography weight="semibold">Unknown error occured</Typography>;
    }

    if (!source && !onboardingActive) {
        return null;
    }

    const exportMessages = async (destinationObjectArray: WorkFlowDestination[]) => {
        const messages: MessageQueue[] = [];
        Object.keys(selectedRowIds).forEach((id) => {
            const row = items.find((item) => item.id === id);
            if (!row || !triggerID) {
                return;
            }
            const { company, contact } = row;

            const contactData = contact?.uid ? { uid: contact.uid } : contact;

            const lead = row?.lead;
            if (company && lead) {
                messages.push(
                    generateMessageQueueObject(company, lead, triggerID, contactData, database) as MessageQueue,
                );
            }
        });
        if (!messages.length) {
            return;
        }
        const responses = await createMultipleMessageQueueItems(axios, triggerID || '', messages);
        const messageQueueIDs = responses.map((message) => message.id || '').filter(Boolean);
        await sendMessageQueueItem(axios, {
            message_queue_ids: messageQueueIDs,
            trigger: triggerID || '',
            destinations: destinationObjectArray,
            allow_resend: true,
        });

        // naive set state delivered, if delivery fails not correct
        setMessageQueue((items) => [
            ...items,
            ...responses.map((i) => ({
                ...i,
                state: 'delivered',
                // slice the Z to match backend
                processing_timestamp: new Date().toISOString().slice(0, -1),
                linked_prospect_exports: [
                    {
                        id: '',
                        origin: 'WebExport',
                        owner_email: null,
                        target: destinationTypeToTarget[destinationObjectArray[0].destination_type],
                        target_fk: '',
                        target_object: destinationObjectArray[0].targets[0].target as CRMObject,
                    },
                ],
            })),
        ]);
    };

    const reDeliverMessage = async (destinationObjectArray: WorkFlowDestination[]) => {
        if (reDeliverID) {
            //const { data: jobID } =
            await sendMessageQueueItem(axios, {
                message_queue_ids: [reDeliverID],
                trigger: triggerID || '',
                destinations: destinationObjectArray,
                allow_resend: true,
            });
            setReDeliverID(null);
        }
    };

    const nextDeliveryLabel = getNextDeliveryLabel(deliveryTime, notify);

    const validItems = items.filter((item) => item.isValid);
    const triggerCount = validItems.length;
    const selectedTriggersCount = Object.values(selectedRowIds).filter(Boolean).length;
    const allTriggersSelected = selectedTriggersCount === triggerCount;

    return (
        <div id="trigger-results">
            <TriggerGroupSubMenu
                triggerCount={triggerCount}
                selectedTriggersCount={selectedTriggersCount}
                toggleSelectAllTriggers={() => {
                    setSelectedRowIds((ids) => {
                        if (allTriggersSelected) {
                            return {};
                        }

                        return items.reduce((prev, next) => {
                            if (!next.isValid) {
                                return prev;
                            }
                            return {
                                ...prev,
                                [next.id]: true,
                            };
                        }, ids);
                    });
                }}
                isLoading={isFetching}
                onViewChange={(type) => setViewType(type)}
                allTriggersSelected={allTriggersSelected}
                timeSpan={timeSpan}
                setTimeSpan={setTimeSpan}
                openExportModal={openExportModal}
            />

            {isNewCompanyTrigger && !isFetching && !items.length && !onboardingActive && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1,
                        marginTop: 10,
                        textAlign: 'center',
                    }}
                >
                    <img src={triggerNewCompanySvg} alt="" />

                    <Typography variant="h5" color="dark">
                        {isActive ? (
                            'We’re tracking new target companies'
                        ) : (
                            <>
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        activateTrigger();
                                    }}
                                    href="#"
                                >
                                    Activate
                                </Link>{' '}
                                the trigger to start tracking new target companies
                            </>
                        )}
                    </Typography>
                    <Typography variant="body2" color="subtle">
                        As soon as one is detected, it will show up here
                    </Typography>
                </Box>
            )}
            {viewType === 'grid' ? (
                <TriggerGrid
                    data={onboardingActive ? getPreviewItems() : items}
                    isFetching={isFetching}
                    selectCompany={selectCompany}
                    selectedRowIds={selectedRowIds}
                    setSelectedRowIds={setSelectedRowIds}
                    fetchMore={fetchMore}
                    scrollRef={scrollRef}
                />
            ) : (
                <TableComponent
                    hasContactDataPermission={hasContactDataPermission}
                    data={onboardingActive ? getPreviewItems() : items}
                    isFetching={isFetching}
                    selectCompany={selectCompany}
                    selectContact={selectContact}
                    hasCRMdestination={hasCRMdestination}
                    selectedRowIds={selectedRowIds}
                    setReDeliverID={setReDeliverID}
                    setShowExportModal={setShowExportModal}
                    openExportModal={openExportModal}
                    openUpgradeDialog={openUpgradeDialog}
                    setSelectedRowIds={setSelectedRowIds}
                    nextDeliveryLabel={nextDeliveryLabel}
                    hiddenColumns={database === 'DOMAIN_DATA_BASIC' ? ['contact'] : undefined}
                    fetchMore={fetchMore}
                    scrollRef={scrollRef}
                />
            )}

            <TriggerExportModal
                open={showExportModal}
                exportMessages={exportMessages}
                exportPreviewRows={exportPreviewRows}
                massExport={false}
                reDeliver={!!reDeliverID}
                reDeliverMessage={reDeliverMessage}
                exportDestination={exportDestination}
                onClose={() => {
                    setShowExportModal(false);
                    setReDeliverID(null);
                }}
            />
            <ContactsUpgradeDialog open={upgradeDialogOpen} onClose={() => setUpgradeDialogOpen(false)} />
        </div>
    );
};

export default TriggerPreview;

const getPreviewItems = () => {
    const vainu_date = new Date().toISOString().slice(0, -1);
    return new Array(5).fill(null).map((_, idx) => ({
        id: idx.toString(),
        vainu_date,
        isValid: true,
        company: {
            company_name: 'Company',
            id: 0,
            logo: '',
            business_id: '',
            domain: '',
        },
        companies: [],
        lead: {
            title: 'New company',
            tags: [{ id: 1, value: 'New company' }],
            vainu_date,
        },
        contact: undefined,
        delivered: true,
        olderThanTrigger: true,
    })) as LeadPreview[];
};
