import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronBigDown: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M20.485 15.535L12 7.04999L3.51499 15.535L4.92899 16.95L12 9.87799L19.071 16.95L20.485 15.535Z" />
    </SvgIcon>
);

export default ChevronBigDown;
