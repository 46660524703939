import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

export const StyledMuiTableContainer = styled(TableContainer)(({ theme }) => ({
    marginBottom: 8,
}));

export const StyledMuiTable = styled(Table)({
    padding: 0,
});

export const StyledMuiTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-head': {
        minWidth: 130,
        color: theme.palette.text.subtle,
        borderBottom: `1px solid ${theme.palette.border}`,
        ':first-child': {
            minWidth: 250,
        },
    },
    '& .MuiTableCell-body': {
        minWidth: 120,
        height: 48,
        overflow: 'hidden',
        color: theme.palette.grey['800'],
        borderBottom: `1px solid ${theme.palette.border}`,
        backgroundColor: theme.palette.background.paper,
        fontSize: 12,
        a: {
            color: 'inherit',
        },
    },
    '&:last-child td': {
        borderBottom: 'none',
    },
    '&:hover .MuiTableCell-body': {
        cursor: 'pointer',
        background: theme.palette.background.default,
    },
}));

export const StyledMuiTableSortLabel = styled(TableSortLabel)({
    '& .MuiTableSortLabel-icon': {
        display: 'none',
    },
});

export const StyledMuiTablePagination = styled(TablePagination)({
    height: 60,
    '& .MuiTablePagination-actions': {
        transform: 'rotate(90deg)',
        height: 55,
        margin: '0!important',
        button: {
            padding: 2,
        },
        '& .Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
        },
    },
}) as typeof TablePagination;
// https://github.com/mui-org/material-ui/issues/13921#issuecomment-484133463
