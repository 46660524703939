import { Box } from '@mui/material';

const Dot = () => (
    <Box
        sx={{
            padding: '0 4px',
            color: 'text.subtle',
        }}
    >
        &middot;
    </Box>
);

export default Dot;
