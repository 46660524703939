import React, { useState, useEffect } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    Tabs,
    Tab,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    styled,
} from '@mui/material';
import { groupBy } from 'lodash';

import { AsyncProcessMetaDataCRMBulkExport } from 'api/types/UserAsyncProcess';

import ChevronLeftDuoSvg from '../../svg/ChevronLeftDuoSvg';
import ChevronRightDuoSvg from '../../svg/ChevronRightDuoSvg';
import { getCRMName } from '../../utils';
import { getCompanyLikeObjectForCrm } from '../../utils/CRMTargetText';
import MassExportErrorIcon from './MassExportErrorWithCircle';

type Props = {
    notificationMetadata: AsyncProcessMetaDataCRMBulkExport;
};
type SupportedErrorType = 'DUPLICATE_FOUND' | 'BAD_REQUEST' | 'others';
type ErrorObjectType = Partial<Record<SupportedErrorType, AsyncProcessMetaDataCRMBulkExport['error_messages']>>;

const supportedErrorList = ['DUPLICATE_FOUND', 'BAD_REQUEST', 'others'];
const helpGuideLink = `https://help.vainu.app/en/articles/145627-crm-integration-error-messages`;

const BulkExportFailedDialog: React.FC<Props> = ({ notificationMetadata }) => {
    const [open, setOpen] = useState(false);
    const [showCompanyList, setShowCompanyList] = useState(false);
    const crm = notificationMetadata?.crm;
    const errorMessages = notificationMetadata?.error_messages ?? [];
    const failedCount = errorMessages.length ?? 0;

    const errorObjectByType: ErrorObjectType = groupBy(errorMessages, (each) => {
        return typeof each === 'object' && Array.isArray(each)
            ? supportedErrorList?.includes(each?.error)
                ? each?.error
                : 'others'
            : 'others';
    });
    const errorTypes = Object.keys(errorObjectByType) as SupportedErrorType[];
    const isDuplicateIssueOnly = errorTypes?.length === 1 && errorTypes?.[0] === 'DUPLICATE_FOUND';

    const handleOpen = (e: Event | React.SyntheticEvent) => {
        e.preventDefault();
        setOpen(true);
    };
    const handleClose = (e: Event | React.SyntheticEvent) => {
        e.preventDefault();
        setOpen(false);
    };
    return (
        <div>
            <CTAButton
                variant="text"
                disableRipple
                disableFocusRipple
                disableElevation
                size="small"
                onClick={handleOpen}
            >
                Show details
            </CTAButton>
            <Dialog
                PaperProps={{
                    sx: {
                        padding: '0px',
                        textAlign: 'left',
                        borderRadius: 8,
                        width: 615,
                    },
                }}
                fullWidth
                open={open}
            >
                {handleClose && (
                    <CloseBannerContainer>
                        <StyledCloseIcon onClick={handleClose} />
                    </CloseBannerContainer>
                )}

                <StyledDialogTitle>
                    <MassExportErrorIcon />
                    <div>
                        <span>{failedCount}</span> companies couldn’t be exported
                    </div>
                </StyledDialogTitle>

                <StyledDialogContent>
                    <StyledDialogContentText onClick={() => setShowCompanyList(!showCompanyList)}>
                        {showCompanyList ? (
                            <>
                                <ChevronLeftDuoSvg className="svg-arrow-left" /> Go back
                            </>
                        ) : (
                            <>
                                View companies and errors <ChevronRightDuoSvg className="svg-arrow-right" />
                            </>
                        )}
                    </StyledDialogContentText>
                    {showCompanyList ? (
                        <FailedCompanyList errorList={errorObjectByType} errorTypes={errorTypes} />
                    ) : (
                        <TextWrapper>
                            {isDuplicateIssueOnly ? (
                                <>
                                    This can happen if the duplicate rules are too strict in {getCRMName(crm)}. For
                                    example, we recommend removing rules that prevent {getCompanyLikeObjectForCrm(crm)}{' '}
                                    having the same website. Read more from{' '}
                                    <b
                                        style={{
                                            textDecoration: 'underline',
                                            fontWeight: 'normal',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => window.open(helpGuideLink, '_blank')}
                                    >
                                        Help Center
                                    </b>{' '}
                                    or if the problem persists, get in touch with support.
                                </>
                            ) : (
                                <>
                                    This can happen if the duplicate rules are too strict in {getCRMName(crm)} or if
                                    there is an issue with some data fields you have mapped. Read more from{' '}
                                    <b
                                        style={{
                                            textDecoration: 'underline',
                                            fontWeight: 'normal',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => window.open(helpGuideLink, '_blank')}
                                    >
                                        Help Center
                                    </b>{' '}
                                    or if the problem persists, get in touch with support.
                                </>
                            )}
                        </TextWrapper>
                    )}
                </StyledDialogContent>

                <DialogActions disableSpacing sx={{ margin: '0px 32px 40px 32px' }}>
                    <OpenChatButton disableRipple onClick={() => window.HubSpotConversations?.widget?.open()}>
                        Open chat
                    </OpenChatButton>

                    <SubmitButton onClick={handleClose} variant="outlined">
                        Understood
                    </SubmitButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default BulkExportFailedDialog;

type FailedCompanyListProps = {
    errorList: ErrorObjectType;
    errorTypes: SupportedErrorType[];
};
const FailedCompanyList: React.FC<FailedCompanyListProps> = ({ errorList, errorTypes }) => {
    const [currentTab, setCurrentTab] = useState<SupportedErrorType | undefined>(undefined);

    useEffect(() => {
        const firstErrObject = errorTypes?.[0];
        if (firstErrObject) {
            setCurrentTab(firstErrObject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <StyledTabs
                value={currentTab}
                onChange={(e, value) => {
                    e.preventDefault();
                    setCurrentTab(value);
                }}
                textColor="primary"
                indicatorColor="primary"
            >
                {errorTypes?.map((each) => {
                    const errorCount = errorList?.[each]?.length ?? 0;
                    return (
                        <StyledTab
                            disableFocusRipple
                            disableRipple
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {errorTypes?.length > 1 && <ErrorCount>{errorCount}</ErrorCount>}
                                    {getErrorTypeLabel(each)}
                                </div>
                            }
                            value={each}
                            key={each}
                            disabled={!errorCount}
                            sx={{
                                width: `calc(100%/${supportedErrorList.length})`,
                            }}
                        />
                    );
                })}
            </StyledTabs>
            <CompanyWrapper>
                {currentTab
                    ? errorList?.[currentTab]?.map((each) => {
                          if (typeof each === 'string') {
                              return null;
                          }
                          const isErrorExists = !!(each?.error_msg || each?.duplicate_name || each?.duplicate);
                          return (
                              <EachCompany>
                                  <Accordion
                                      elevation={0}
                                      variant="elevation"
                                      disabled={!isErrorExists}
                                      sx={{
                                          '&.Mui-disabled ': {
                                              background: 'transparent',
                                          },
                                      }}
                                  >
                                      <AccordionSummary
                                          sx={{
                                              display: 'inline-flex',
                                              padding: 0,
                                              fontWeight: 'normal',
                                              fontSize: '12px',
                                              color: '#3F3F3F',
                                              minHeight: 0,
                                              maxHeight: '35px',
                                              '&.Mui-expanded': {
                                                  minHeight: 0,
                                                  height: 'auto',
                                              },
                                              '& .MuiAccordionSummary-content.Mui-expanded': {
                                                  margin: 'auto',
                                              },
                                              '&.Mui-disabled ': {
                                                  opacity: 1,
                                                  userSelect: 'all',
                                              },
                                          }}
                                          expandIcon={isErrorExists && <ArrowDropDownIcon htmlColor="#0A0A0A" />}
                                      >
                                          {each?.prospect_name}
                                      </AccordionSummary>
                                      {isErrorExists && (
                                          <AccordionDetails sx={{ padding: '8px 0px' }}>
                                              <AccordionDetailsContent>
                                                  <div>
                                                      <b>Duplicate company:</b>{' '}
                                                      {each?.duplicate_name ?? each?.duplicate}
                                                  </div>
                                                  <div className="issue">
                                                      <b>Issue</b> {each?.error_msg}
                                                  </div>
                                              </AccordionDetailsContent>
                                          </AccordionDetails>
                                      )}
                                  </Accordion>
                              </EachCompany>
                          );
                      })
                    : null}
            </CompanyWrapper>
        </>
    );
};
const getErrorTypeLabel = (value: string) => {
    switch (value) {
        case 'DUPLICATE_FOUND':
            return 'Duplicate issue';
        case 'BAD_REQUEST':
            return 'Mapping issue';
        default:
            return 'Others';
    }
};

const CTAButton = styled(Button)({
    color: '#5A6A78',
    fontWeight: 500,
    fontSize: 12,
    background: 'transparent',
    padding: '12px 0px',
    display: 'block',
    '&:hover': {
        background: 'transparent',
    },
});

const StyledDialogTitle = styled(DialogTitle)({
    color: '#0A0A0A',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 0,
    marginLeft: 32,
    marginRight: 32,
    '& svg': {
        margin: '73px auto',
        marginBottom: 48,
    },
    '& div': {
        textAlign: 'left',
        '& span': {
            color: '#DF2664',
        },
    },
});

const StyledDialogContent = styled(DialogContent)({
    padding: 0,
    margin: '17px 32px 24px 32px',
    overflow: 'hidden',
});

const StyledDialogContentText = styled(DialogContentText)({
    color: '#2F80ED',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& .svg-arrow-right': {
        marginLeft: 8,
    },
    '& .svg-arrow-left': {
        marginRight: 8,
    },
});

const TextWrapper = styled(DialogContentText)({
    color: '#3F3F3F',
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'left',
    marginTop: '17px',
});

const SubmitButton = styled(Button)({
    border: '1px solid #000000',
    padding: '8px 12px',
    fontSize: '14px',
    background: '#000000',
    fontWeight: 500,
    borderRadius: '4px',
    color: '#fff',
    '&:hover': {
        background: '#000000',
    },
    marginLeft: '15px',
});

const OpenChatButton = styled(Button)({
    color: '#0A0A0A',
    fontSize: 14,
    '&:hover': {
        background: '#fff',
    },
});

const CloseBannerContainer = styled('div')({
    textAlign: 'right',
    position: 'relative',
    marginRight: 15,
    marginTop: 15,
});

const StyledCloseIcon = styled(CloseIcon)({
    color: '#898989',
    fontSize: '14px',
    cursor: 'pointer',
    position: 'absolute',
    top: '15px',
    right: '15px',
});

const CompanyWrapper = styled('div')({
    backgroundColor: '#FFF',
    borderRadius: '8px',
    border: '1px solid #E1E1E1',
    padding: '16px',
    marginTop: '8px',
    maxHeight: '157px',
    overflowY: 'scroll',
});

const EachCompany = styled('div')({
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    color: '#3F3F3F',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0px',
});

const AccordionDetailsContent = styled('div')({
    margin: '-16px 0px 0px 0px',
    background: '#F5F5F5',
    color: '#898989',
    fontStyle: 'normal',
    padding: '4px',
    fontWeight: 'normal',
    fontSize: '11px',
    borderRadius: '4px',
    display: 'block',
    '& .issue': {
        marginTop: '8px',
    },
});

const ErrorCount = styled('div')({
    marginRight: '4px',
    padding: '1px 5px 0px 5px',
    background: '#F5F5F5',
    border: '1px solid #E1E1E1',
    borderRadius: '33px',
    height: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '9px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#3F3F3F',
    opacity: 0.8,
    display: 'flex',
    alignItems: 'center',
});

const StyledTabs = styled(Tabs)({
    '.MuiTabs-indicator': {
        backgroundColor: '#0A0A0A',
        marginBottom: '4px',
        height: '5px',
    },
});

const StyledTab = styled(Tab)({
    '&.MuiTab-root': {
        minWidth: 0,
        maxWidth: 'unset',
        fontSize: '14px',
        textTransform: 'none',
        fontWeight: 'normal',
        color: '#898989',
    },
    '&.Mui-selected': {
        color: '#0A0A0A !important',
        backgroundColor: 'transparent',
        fontWeight: 'bold',
    },
});
