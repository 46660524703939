import React from 'react';

import { Box } from '@mui/material';
import { format } from 'date-fns';

import { Trigger } from 'api/types/Trigger';
import MainListItem from 'components/tokens/MainListItem';
import { isValidTrigger } from 'utilities/triggers';

import { TriggerAction } from '../Triggers';
import TriggerListMenu from './TriggerListMenu';

type TriggerListItemProps = {
    trigger: Trigger;
    isSelected: boolean;
    onClick?: (id?: string) => void;
    onMenuAction?: (id: string, action: TriggerAction) => void;
};

export const TriggerListItem: React.FC<TriggerListItemProps> = ({ trigger, isSelected, onClick, onMenuAction }) => {
    return (
        <MainListItem
            data-testid={`e2e-triggers-menu-item-${trigger.id}`}
            key={trigger.id}
            selected={isSelected}
            onClick={() => onClick?.(trigger.id)}
            tooltip={
                <Box sx={{ fontWeight: 'normal' }}>
                    Last modified:{' '}
                    {trigger.modified
                        ? format(new Date(trigger.modified), 'PP')
                        : trigger.created
                          ? format(new Date(trigger.created), 'PP')
                          : ''}
                </Box>
            }
            overflowInTooltip
            endIcon={
                <TriggerListMenu
                    data-testid={`e2e-triggers-menu-item-control-${trigger.id}`}
                    onClick={onMenuAction ? (action) => onMenuAction(trigger.id || '', action) : () => {}}
                    isActive={trigger.status === 'active'}
                    isValid={isValidTrigger(trigger)[0]}
                />
            }
            endIconVisibleOnHover
        >
            {trigger.name}
        </MainListItem>
    );
};
