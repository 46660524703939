import React from 'react';

import { SvgIconProps } from '@mui/material';

import { iconChoices, IconType } from './icons';

export type { IconType } from './icons';

export type IconProps = Omit<SvgIconProps, 'type' | 'color'> & {
    type: IconType;
    color?: string;
};

export const Icon: React.FC<IconProps> = ({ type, color, sx, ...props }) => {
    if (!(type in iconChoices)) {
        throw new Error(`Invalid icon type: ${type}`);
    }
    const SelectedIcon = iconChoices[type];
    const iconSx = color ? { ...sx, color } : sx;
    return <SelectedIcon sx={iconSx} {...props} />;
};

export default Icon;
