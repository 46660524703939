import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useCompaniesSearchResult } from 'api/search_companies';
import CompanySearch from 'components/features/CompanySearch';
import { useListsContext } from 'contexts/ListsContext';
import useDebounce from 'hooks/useDebounce';

import { MainPath } from '../../Router/routePaths';

const NavSearch = () => {
    const { database } = useListsContext();
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const trimmedSearchTerm = searchTerm.trim();
    const debouncedSearchTerm = useDebounce(trimmedSearchTerm, 400);
    const {
        data = [],
        isLoading,
        isFetched,
    } = useCompaniesSearchResult({
        companyName: debouncedSearchTerm,
        database,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(database === 'DOMAIN_DATA_BASIC' ? e.target.value.trim() : e.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            handleClearForm();
        }
    };

    const handleClearForm = () => {
        setSearchTerm('');
    };

    const handleSubmit = (event?: React.FormEvent) => {
        event?.preventDefault();
        if (searchTerm.length > 2) {
            setSearchTerm('');
            navigate(`/${MainPath.Search}/?q=${encodeURIComponent(trimmedSearchTerm)}`);
        }
    };

    return (
        <CompanySearch
            searchTerm={searchTerm}
            debouncedSearchTerm={debouncedSearchTerm}
            suggestions={data}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onSubmit={handleSubmit}
            onClearForm={handleClearForm}
            isLoading={isLoading}
            isFetched={isFetched}
        />
    );
};

export default NavSearch;
