import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChatSupport: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 4C18 2.89543 17.1046 2 16 2H4C2.89543 2 2 2.89543 2 4V13.1765C2 14.281 2.89543 15.1765 4 15.1765V4H18ZM11.3333 19.1765L14 22L16.6667 19.1765H20C21.1046 19.1765 22 18.281 22 17.1765V8C22 6.89543 21.1046 6 20 6H8C6.89543 6 6 6.89543 6 8V17.1765C6 18.281 6.89543 19.1765 8 19.1765H11.3333ZM20 17.1765H15.8046L14 19.0872L12.1954 17.1765H8V8H20V17.1765Z"
        />
    </SvgIcon>
);

export default ChatSupport;
