import { useCallback, useState } from 'react';

import { AxiosInstance } from 'axios';
import { isNil, uniq } from 'lodash';

import { Operation } from 'api/types/FilterOperators';
import { AsyncProcessState, GenericAsyncProcess, AsyncProcessType } from 'api/types/UserAsyncProcess';
import { DraggableListItemType } from 'components/templates/DragAndDrop';
import { useAxiosContext } from 'contexts/AxiosContext';
import { getContactGroups } from 'contexts/FilterCompaniesContext/utils';
import { Database } from 'contexts/ListsContext';
import useAsyncProcess from 'hooks/useAsyncProcess';
import { formatDate } from 'utilities/date';

import { LABELS_BY_ITEM_ID, NORDIC_URL_PARAMS_BY_ITEM_ID, GLOBAL_URL_PARAMS_BY_ITEM_ID } from './constants';
import { MIN_EXTRA_FILE_COUNT_TO_ZIP } from './ListExportDialog';

type ExportQueryParams = Record<string, string | number | Operation | undefined>;

export const exportList = async ({
    axios,
    listId,
    query,
    order,
    format,
    limit,
    isDomainDb,
    async = true,
    encoding = 'iso-8859-1',
    preview = false,
    count,
    database,
    isContactExport = false,
    filename,
}: {
    axios: AxiosInstance;
    listId?: string;
    query?: Operation;
    order: DraggableListItemType[];
    format: string;
    limit: number | undefined;
    isDomainDb: boolean;
    async?: boolean;
    encoding?: string;
    preview?: boolean;
    count?: number;
    database?: Database;
    isContactExport?: boolean;
    filename?: string;
}) => {
    const urlParamMap = isDomainDb ? GLOBAL_URL_PARAMS_BY_ITEM_ID : NORDIC_URL_PARAMS_BY_ITEM_ID;
    const fields: string[] = order.map(({ id }) => urlParamMap[id as keyof typeof urlParamMap]);
    const labels: string[] = order.map(
        ({ id, name }) => LABELS_BY_ITEM_ID[id as keyof typeof LABELS_BY_ITEM_ID] || name,
    );

    const url = `/api/v3/${isDomainDb ? 'domains' : 'organizations'}${async ? '/async' : ''}/`;

    const unwindingFields = uniq(order.map((field) => field.args).filter((args) => !isNil(args)));

    const isMultiFile = !preview && unwindingFields.length >= MIN_EXTRA_FILE_COUNT_TO_ZIP;

    const requestFormat = isMultiFile && format === 'csv' ? 'csvzip' : format;

    const contactGroups = query && getContactGroups(query);

    const params: ExportQueryParams = {
        list: listId,
        query,
        fields: fields.join(','),
        limit,
        encoding,
        csv_header: format === 'csv' ? labels.join(',') : undefined,
        fields_to_files: isMultiFile ? unwindingFields.join(',') : undefined,
        field_to_rows: !isMultiFile ? unwindingFields.join(',') : undefined,
        progress_max: count ? Math.min(count, limit ?? Infinity) : undefined,
        use_filters_v2: 'true',
        database,
        result_file_name: filename
            ? `Vainu ${filename} ${formatDate(new Date(), 'short-datetime')}.${
                  requestFormat === 'csvzip' ? 'zip' : format
              }`
            : undefined,
        ...(isContactExport
            ? {
                  unwind_subdocument: 'contacts',
                  unwind_subdocument_query: contactGroups,
              }
            : {}),
    };

    // format needs to be in url params, progress_max in post body
    const { data } = await axios.post(url, params, {
        params: {
            format: async ? undefined : requestFormat,
            async_format: async ? requestFormat : undefined,
        },
    });

    return data;
};

interface ListDownloadResponse {
    created: string;
    duration: string;
    download_link: string | null;
    finished: string | null;
    job_id: string;
    link: string;
    state: AsyncProcessState;
}

interface UseGetCsvQueryProps {
    listId?: string;
    query?: Operation;
    order: DraggableListItemType[];
    onError?: (error: Error) => void;
    onPollUpdate?: (currentProcess: GenericAsyncProcess) => void;
    format?: string;
    encoding?: string;
    limit?: number;
    isDomainDb: boolean;
    count?: number;
    database?: Database;
    isContactExport?: boolean;
    filename?: string;
}

export const useGetListExportQuery = ({
    listId,
    query,
    order,
    onPollUpdate,
    onError,
    format = 'csv',
    encoding = 'iso-8859-1',
    limit,
    isDomainDb,
    count,
    database,
    isContactExport,
    filename,
}: UseGetCsvQueryProps) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const [error, setError] = useState<string | undefined>(undefined);
    const axios = useAxiosContext();

    const [_, { pollUserAsyncProcess }] = useAsyncProcess();

    const asyncProcessType = isDomainDb
        ? AsyncProcessType.ListDomainsAsyncAPI
        : AsyncProcessType.ListOrganizationsAsyncAPI;

    const downloadCSV = useCallback(async () => {
        try {
            setIsDownloading(true);
            const data = await exportList({
                axios,
                listId,
                query,
                order,
                format,
                limit,
                isDomainDb,
                async: true,
                encoding,
                count,
                database,
                isContactExport,
                filename,
            });

            // use temporary polling implementation to wait until csv file can be downloaded
            const {
                data: { job_id },
            } = await axios.get<ListDownloadResponse>(data.link);

            if (job_id) {
                pollUserAsyncProcess({ jobId: job_id, type: asyncProcessType, onUpdate: onPollUpdate });
            }

            return { download_link: null };
        } catch (error) {
            setError((error as Error)?.message ?? error);
            onError && onError(error as Error);
        } finally {
            setIsDownloading(false);
        }
    }, [
        axios,
        listId,
        query,
        order,
        format,
        limit,
        isDomainDb,
        encoding,
        count,
        database,
        isContactExport,
        pollUserAsyncProcess,
        asyncProcessType,
        onPollUpdate,
        onError,
        filename,
    ]);

    return { downloadCSV, isDownloading, error };
};
