import React from 'react';

import { CompanyFact } from 'api/types/company';
import { VainuCustomIndustry } from 'api/types/Organization';
import { CustomIndustryLink } from 'components/features/Industries';
import { sortAndFilterCustomIndustries, isB2BB2C, getVCIScore } from 'components/features/Industries/utils';

import ExpandableRow from './ExpandableRow';

type CustomIndustriesProps = {
    customIndustries: VainuCustomIndustry[] | CompanyFact[];
};

const hasShowableConfidenceScore = (fact: VainuCustomIndustry | CompanyFact): fact is VainuCustomIndustry =>
    'confidence_score' in fact && !isB2BB2C(fact);

const CustomIndustries: React.VFC<CustomIndustriesProps> = ({ customIndustries }) => {
    const filteredAndSortedCustomIndustries = sortAndFilterCustomIndustries(customIndustries);

    return (
        <ExpandableRow
            rows={filteredAndSortedCustomIndustries.map((fact, index) =>
                fact.value ? (
                    <CustomIndustryLink
                        key={index}
                        value={fact.value}
                        score={hasShowableConfidenceScore(fact) ? getVCIScore(fact) : undefined}
                    />
                ) : null,
            )}
        />
    );
};

export default CustomIndustries;
