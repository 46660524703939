import { useEffect, useState } from 'react';

import { Box, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import NotFound from 'components/templates/NotFound';
import Button from 'components/tokens/Button';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';
import { useAuthContext } from 'contexts/AuthContext';
import { usePermissionContext } from 'contexts/PermissionContext';

const renderError = (error: unknown): string => {
    if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
            return 'The user was not found.';
        }
        const data = error.response?.data;
        if (data && typeof data === 'object') {
            if ('detail' in data && typeof data.detail === 'string') {
                return data.detail;
            }
            return Object.entries(data)
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ');
        } else {
            return error.message;
        }
    }
    return String(error);
};

const reasonOptions = [
    {
        label: 'Written consent',
        value: 'written',
    },
    {
        label: 'Verbal consent',
        value: 'verbal',
    },
    {
        label: 'Necessary for troubleshooting',
        value: 'troubleshooting',
    },
    {
        label: 'Other',
        value: 'other',
    },
];

const needsExtraNotes = (value: string) => value === 'troubleshooting' || value === 'other';

export const Impersonate: React.FC = () => {
    const { isVainuAdmin, isAccountLoading } = usePermissionContext();
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [notesCategory, setNotesCategory] = useState('');
    const [errorState, setErrorState] = useState<[email: string, error: unknown] | null>(null);
    const { doImpersonation } = useAuthContext();
    const [searchParams] = useSearchParams();

    const handleSubmitClick = async () => {
        setErrorState(null);
        if (email && notesCategory) {
            const notesCategoryTitle = reasonOptions.find((reason) => reason.value === notesCategory)?.label ?? '';
            const combinedNotes = needsExtraNotes(notesCategory)
                ? `${notesCategoryTitle}: ${notes}`
                : notesCategoryTitle;
            try {
                await doImpersonation(email, combinedNotes);
            } catch (error) {
                setErrorState([email, error]);
            }
        }
    };

    const emailParam = searchParams.get('email');
    const notesParam = searchParams.get('notes');

    useEffect(() => {
        (async () => {
            if (isVainuAdmin && emailParam && notesParam && !errorState) {
                try {
                    setEmail(emailParam);
                    const foundNotesCategory = reasonOptions.find((option) => notesParam.startsWith(option.label));
                    if (foundNotesCategory) {
                        const notesExtra = notesParam.split(': ')[1];
                        if (notesExtra) {
                            setNotes(notesExtra);
                        }
                        setNotesCategory(foundNotesCategory.value);
                    }
                    await doImpersonation(emailParam, notesParam);
                } catch (error) {
                    setErrorState([emailParam, error]);
                }
            }
        })();
    }, [isVainuAdmin, emailParam, notesParam, errorState, doImpersonation]);

    if (isAccountLoading) {
        return null;
    }

    if (!isVainuAdmin) {
        return <NotFound />;
    }

    if (!errorState && (emailParam || notesParam)) {
        return null;
    }

    const isFormValid = !!(email && notesCategory && (!needsExtraNotes(notesCategory) || notes));

    return (
        <Box
            sx={{
                minHeight: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'background.paper',
            }}
        >
            <Box sx={{ minHeight: 432, padding: 4 }}>
                <Typography variant="h4" sx={{ marginBottom: 2 }}>
                    Log in as a user
                </Typography>
                {errorState && (
                    <>
                        <Typography variant="h5" sx={{ marginBottom: 1 }}>
                            Error{' '}
                        </Typography>
                        <Typography paragraph variant="small" sx={{ marginBottom: 4 }}>
                            Could not log in as a user{' '}
                            <Typography variant="small" weight="semibold">
                                {errorState[0]}
                            </Typography>
                            .<br />
                            The error was:{' '}
                            <Typography variant="small" weight="semibold">
                                {renderError(errorState[1])}
                            </Typography>
                        </Typography>
                    </>
                )}
                <Box sx={{ width: '100%', maxWidth: 360 }}>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <Typography variant="small">Please select a reason for logging in as this user</Typography>
                    <Select
                        fullWidth
                        sx={{ marginBottom: 1 }}
                        value={notesCategory}
                        onChange={(event) => setNotesCategory(event.target.value)}
                    >
                        {reasonOptions.map(({ label, value }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                    {needsExtraNotes(notesCategory) && (
                        <TextField
                            value={notes}
                            onChange={(event) => setNotes(event.target.value)}
                            label={
                                notesCategory === 'troubleshooting'
                                    ? 'Please give the issue #'
                                    : notesCategory === 'other'
                                      ? 'Please type in the reason'
                                      : 'Notes'
                            }
                            sx={{ marginBottom: 2 }}
                        />
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type="submit" disabled={!isFormValid} onClick={handleSubmitClick}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Impersonate;
