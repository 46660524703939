import React from 'react';

import { Avatar, styled } from '@mui/material';

import { getLogo } from '../../utils/';
import LetterLogo from './LetterLogo';

const StyledAvatar = styled(Avatar)({
    size: '28px',
    borderRadius: '4px',
});

const StyledLetterLogo = styled(LetterLogo)({
    size: '32px',
    borderRadius: '4px',
});

type ProspectLogoProps = {
    company: {
        company_name: string;
        logo: string | undefined;
        id: string | number;
        form_of_company?: string;
        industry_codes_local?: string[];
    };
};
/**
 *
 * @description Generalized prospect logo, fallback to Letter logo
 */
export const ProspectLogo: React.FC<ProspectLogoProps> = ({ company }) => {
    return company.logo ? <StyledAvatar src={getLogo(company) || ''} /> : <StyledLetterLogo company={company} />;
};
