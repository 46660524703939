import React, { memo, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { assertArray } from 'utilities';

import { getUserNotifications, setNotificationDismissed, setNotificationSeen } from 'api/sitewide_notifications';
import { SitewideNotification } from 'api/types/Notification';
import {
    AsyncProcessType,
    asyncProcessTypeCRMBulkExport,
    asyncProcessTypeCRMImport,
    asyncProcessTypeImportList,
    asyncProcessTypeListDataV3,
    isAsyncProcess,
    UserAsyncProcess,
} from 'api/types/UserAsyncProcess';
import NotificationDrawer from 'components/features/Notifications/NotificationDrawer';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useNotification } from 'contexts/NotificationsContext';
import useAsyncProcess from 'hooks/useAsyncProcess';
import {
    filteredCrmFullExportProcesses,
    filteredCrmImportProcesses,
    filteredCrmMassExportProcesses,
} from 'store/asyncProcessAtomSelectors';

type NotificationsAPIProps = {
    anchorElement: React.ReactElement;
};

const NotificationsWrapper: React.VFC<NotificationsAPIProps> = memo(({ anchorElement }) => {
    const {
        sitewideNotifications,
        setSitewideNotifications,
        setNotificationSeen: contextNotificationSeen,
        setNotificationDismiss: contextNotificationDismiss,
    } = useNotification();
    const axios = useAxiosContext();

    const latestCRMImportProcess = useRecoilValue(filteredCrmImportProcesses({})) as UserAsyncProcess[];
    const latestCRMFullExportProcess = useRecoilValue(filteredCrmFullExportProcesses({})) as UserAsyncProcess[];
    const latestCRMMassExportProcess = useRecoilValue(filteredCrmMassExportProcesses({})) as UserAsyncProcess[];
    const [{ asyncProcesses }, { pollMultipleUserAsyncProcesses, markAsyncProcessSeen }] = useAsyncProcess();

    const { setNotificationDrawerOpen, isNotificationDrawerOpen } = useNotification();

    const { data: sitewideNotificationData } = useQuery({
        queryKey: ['sitewide-notifications'],
        queryFn: () => getUserNotifications(axios),
    });

    useEffect(() => {
        const sitewideNotifs = assertArray(sitewideNotificationData?.data).map<SitewideNotification>(
            (notification) => ({
                ...notification,
                type: 'sitewide_notification',
                created: notification.meta_data.start_date,
            }),
        );
        setSitewideNotifications(sitewideNotifs);
    }, [sitewideNotificationData, setSitewideNotifications]);

    const fileProcesses = useMemo(
        () =>
            asyncProcesses
                .filter(isAsyncProcess)
                .filter((notification) =>
                    [
                        ...asyncProcessTypeImportList,
                        ...asyncProcessTypeListDataV3,
                        AsyncProcessType.TargetGroupCSV,
                    ].includes(notification.type),
                ),
        [asyncProcesses],
    );

    useEffect(() => {
        pollMultipleUserAsyncProcesses([
            ...asyncProcessTypeCRMImport,
            ...asyncProcessTypeCRMBulkExport,
            ...asyncProcessTypeImportList,
            ...asyncProcessTypeListDataV3,
            AsyncProcessType.TargetGroupCSV,
        ]);
    }, [pollMultipleUserAsyncProcesses]);

    const handleNotificationSeen = async (notificationId: string) => {
        try {
            await setNotificationSeen(axios, notificationId);
            contextNotificationSeen(notificationId);
        } catch (error) {
            console.error('Error in setting notification as seen');
        }
    };

    const handleAsyncProcessSeen = async (jobId: string) => {
        try {
            if (jobId) {
                await markAsyncProcessSeen(jobId);
            }
        } catch (error) {
            console.error('Error in setting async process as seen');
        }
    };

    const handleNotificationDismissed = async (notificationId: string) => {
        try {
            await setNotificationDismissed(axios, notificationId);
            contextNotificationDismiss(notificationId);
        } catch (error) {
            console.error('Error in dismissing notification');
        }
    };

    const getSetNavigationDrawerCallback = (value: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setNotificationDrawerOpen(value);
    };

    return (
        <>
            <anchorElement.type {...anchorElement.props} onClick={getSetNavigationDrawerCallback(true)} />
            <NotificationDrawer
                notifications={[
                    ...sitewideNotifications,
                    ...latestCRMImportProcess,
                    ...latestCRMFullExportProcess,
                    ...latestCRMMassExportProcess,
                    ...fileProcesses,
                ]}
                onClick={handleNotificationSeen}
                handleNotificationSeen={handleNotificationSeen}
                handleAsyncProcessSeen={handleAsyncProcessSeen}
                onCloseClick={getSetNavigationDrawerCallback(false)}
                isOpen={isNotificationDrawerOpen}
                onNotificationDismiss={handleNotificationDismissed}
            />
        </>
    );
});

NotificationsWrapper.displayName = 'NotificationsWrapper';

export default NotificationsWrapper;
