import React, { PropsWithChildren } from 'react';

import {
    Box,
    ClickAwayListener,
    InputBase,
    Popper,
    PopperProps,
    Select,
    selectClasses,
    styled,
    SxProps,
} from '@mui/material';
import Autocomplete, { autocompleteClasses, AutocompleteCloseReason } from '@mui/material/Autocomplete';
import { last } from 'lodash';

import Chip from 'components/tokens/Chip';
import Icon, { IconType } from 'components/tokens/Icon';

export type ChipMultiSelectProps<OptionT> = {
    options: OptionT[];
    selectedOptions?: OptionT[];
    onChange: (options: OptionT[]) => void;
    getOptionLabel: (key: OptionT | string) => string;

    title?: string;
    creatable?: (value: string) => OptionT;
    iconString?: (o: OptionT) => IconType | undefined;
    noOptionsText?: React.ReactNode;
    defaultLabel?: React.ReactNode;
    iconComponent?: React.ElementType;
    closeIfGivenOptionSelected?: (value: OptionT) => boolean;
    selectSx?: SxProps;
};

export const ChipMultiSelect = <OptionT,>({
    options = [],
    selectedOptions = [],
    onChange,
    getOptionLabel,
    title,
    creatable,
    iconString,
    defaultLabel,
    noOptionsText = 'No results',
    iconComponent,
    closeIfGivenOptionSelected,
    selectSx,
}: PropsWithChildren<ChipMultiSelectProps<OptionT>>) => {
    const handleCreate = (createdValue: string) => {
        !!creatable && handleSelect([...selectedOptions, creatable(createdValue)]);
    };
    const handleDelete = (index: number) => {
        handleSelect(selectedOptions.filter((_, idx) => idx !== index));
    };
    const handleSelect = (values: OptionT[]) => {
        onChange(values);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'chip-multiselect' : undefined;

    const iconSx = { width: 17, height: 17, opacity: 0.6, mr: '5px', ml: '-2px' };

    return (
        <div>
            <h3>{title}</h3>
            <Select
                aria-describedby={id}
                onClick={handleClick}
                multiple
                value={selectedOptions}
                renderValue={(selected: OptionT[]) => {
                    if (!selected.length) {
                        return <Label>{defaultLabel}</Label>;
                    }
                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((option, i) => {
                                const iconType: IconType | undefined = !!iconString ? iconString(option) : undefined;
                                return (
                                    <Chip
                                        key={getOptionLabel(option)}
                                        label={getOptionLabel(option)}
                                        onDelete={() => {
                                            handleDelete(i);
                                        }}
                                        onMouseDown={(e) => e.stopPropagation()}
                                        icon={iconType}
                                    />
                                );
                            })}
                        </Box>
                    );
                }}
                open={false}
                sx={{
                    width: 400,
                    [`& .${selectClasses.select}`]: {
                        padding: '10px',
                    },
                    ...selectSx,
                }}
                displayEmpty
                IconComponent={iconComponent}
            ></Select>
            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Autocomplete
                            open
                            multiple
                            freeSolo={!!creatable}
                            onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            value={selectedOptions}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    (event as React.KeyboardEvent).key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }

                                if (reason === 'createOption') {
                                    handleCreate(last(newValue) as string);
                                    return;
                                }
                                handleSelect(newValue as OptionT[]);
                                if (reason === 'selectOption') {
                                    closeIfGivenOptionSelected &&
                                        closeIfGivenOptionSelected(last(newValue) as OptionT) &&
                                        handleClose();
                                }
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            isOptionEqualToValue={(a, b) => getOptionLabel(a) === getOptionLabel(b)}
                            noOptionsText={noOptionsText}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props}>
                                        <Box
                                            component={() => (
                                                <Icon
                                                    type="CheckBig"
                                                    sx={{
                                                        visibility: selected ? 'visible' : 'hidden',
                                                        ...iconSx,
                                                    }}
                                                />
                                            )}
                                        />
                                        {!!iconString && (
                                            <Box
                                                component={() => {
                                                    const iconType: IconType | undefined = !!iconString
                                                        ? iconString(option)
                                                        : undefined;

                                                    return !!iconType ? (
                                                        <Icon type={iconType} sx={{ mr: '5px' }} />
                                                    ) : (
                                                        <></>
                                                    );
                                                }}
                                            ></Box>
                                        )}
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                '& span': {
                                                    color: '#8b949e',
                                                },
                                            }}
                                        >
                                            {getOptionLabel(option)}
                                        </Box>
                                        <Box
                                            component={() => (
                                                <Icon
                                                    type="CloseSmall"
                                                    sx={{
                                                        visibility: selected ? 'visible' : 'hidden',
                                                        ...iconSx,
                                                    }}
                                                />
                                            )}
                                        />
                                    </li>
                                );
                            }}
                            options={options}
                            getOptionLabel={getOptionLabel}
                            renderInput={(params) => (
                                <StyledInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder={!!creatable ? 'Search or create new' : `Search`}
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </div>
    );
};

interface PopperComponentProps {
    anchorEl?: PopperProps['anchorEl'];
    disablePortal?: boolean;
    open: boolean;
}

const StyledAutocompletePopper = styled('div')(() => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: '#fff',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'center',
            padding: 8,
            borderBottom: `1px solid #eaecef`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
                backgroundColor: 'grey',
            },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

const PopperComponent = (props: PopperComponentProps) => {
    const { disablePortal: _disablePortal, anchorEl: _anchorEl, open: _open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid #e1e4e8`,
    boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
    borderRadius: 6,
    width: 400,
    fontSize: 13,
    color: '#24292e',
    backgroundColor: '#fff',
    // Type issue. Should fix later.
    zIndex: theme.zIndex.modal,
}));

const StyledInput = styled(InputBase)(() => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid #eaecef`,
    '& input': {
        borderRadius: 4,
        backgroundColor: '#fff',
        padding: 8,
        border: `1px solid #eaecef`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px rgba(3, 102, 214, 0.3)`,
            borderColor: '#0366d6',
        },
    },
}));

const Label = styled('div')`
    color: #808080;
    font-size: 14px;
`;
