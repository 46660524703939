import React from 'react';

import { useIntl, FormattedNumber, FormatNumberOptions, FormattedDate } from 'react-intl';

import { formatKr } from './utils';

export type FormattedValueProps = {
    /** Number of fraction digits to show. Defaults to 1, but ignored if <strong>style</strong> is set to 'integer'. */
    decimals?: number;
    abbreviate?: boolean;
    /** Allows to give the value of number formats as a parsable strings.
     * Uses parseFloat on styles decimal, percent and currency, and parseInt on integer.
     * The range style never allows strings. The date style always allows a string.
     * Has no effect if the style is string.
     * TODO: On by default for now. It should possibly be changed to be explicitly set. */
    acceptString?: boolean;
    /** Also replaces the Nordic currencies' official codes with their unofficial symbol "kr". */
    useKr?: boolean;
} & (
    | {
          value: number | string;
          /** Style of the number. If undefined, defaults to 'decimal', or if currency is set, defaults to 'currency'. */
          style?: 'decimal' | 'percent' | 'currency' | 'integer';
          currency?: string;
      }
    | {
          value: [min: number, max?: number | null];
          style: 'range';
      }
    | {
          value: string;
          style: 'string';
      }
    | {
          value: string | number;
          style: 'date';
      }
);

export const useFormattedValue = (props: FormattedValueProps): React.ReactNode => {
    const { value, style, decimals = 1, abbreviate = false, useKr = false, acceptString = true } = props;

    const intl = useIntl();

    if (style === 'date') {
        if (!value) {
            return '-';
        }
        return <FormattedDate value={new Date(String(value))} year="numeric" />;
    } else if (style === 'string') {
        return value;
    } else if (style === 'range') {
        if (!Array.isArray(value)) {
            return null;
        }
        const [min, max] = value;
        if (min === 0 && !max) {
            return '-';
        } else {
            const opts: FormatNumberOptions = {
                style: 'decimal',
                notation: abbreviate ? 'compact' : undefined,
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            };
            return `${intl.formatNumber(min, opts)}${max && max > min ? `–${intl.formatNumber(max, opts)}` : '+'}`;
        }
    } else if (typeof value === 'number' || (typeof value === 'string' && acceptString)) {
        const { currency } = props;
        const parsedValue =
            typeof value === 'number' ? value : style === 'integer' ? parseInt(value) : parseFloat(value);
        if (parsedValue === 0) {
            return '-';
        }
        let selectedStyle = style;
        if (style === undefined) {
            selectedStyle = currency ? 'currency' : 'decimal';
        }
        return (
            <FormattedNumber
                value={parsedValue}
                notation={abbreviate ? 'compact' : undefined}
                style={selectedStyle !== 'integer' ? selectedStyle : 'decimal'}
                currency={currency}
                maximumFractionDigits={selectedStyle === 'integer' ? 0 : decimals}
                minimumFractionDigits={selectedStyle === 'integer' ? 0 : decimals}
            >
                {formatKr(useKr, currency)}
            </FormattedNumber>
        );
    } else {
        throw new Error(`A style ${style} was selected, but the value is of type ${typeof value}`);
    }
};

export const FormattedValue: React.FC<FormattedValueProps> = (props) => <>{useFormattedValue(props)}</>;

export default FormattedValue;
