import { alpha, darken } from '@mui/material/styles';

import { palette } from './palette';

declare module '@mui/material/styles' {
    interface Theme {
        params: typeof params;
    }
    interface ThemeOptions {
        params: typeof params;
    }
}

const params = {
    navPanelWidth: 64,
    appBarHeight: 64,
    maxContentWidth: 1360,
    maxSlimContentWidth: 912,
    pageContentSpacing: 80,
    brandFontFamily: 'Aeroport, Roboto, sans-serif',
    skeletonBackground: {
        background: `${palette.field.background} linear-gradient(
            270deg, 
            ${alpha(palette.field.background, 0.8)}, 
            ${darken(palette.field.background, 0.035)}, 
            ${alpha(palette.field.background, 0.8)}
        ) repeat scroll 0% 0% / 600% 600%`,
        animation: '2.5s ease-in-out 0s infinite alternate none running skeletonGradient',
    },
    skeletonBackgroundKeyframes: {
        '@keyframes skeletonGradient': {
            from: { backgroundPosition: '0% 51%' },
            to: { backgroundPosition: '100% 50%' },
        },
    },
};

export default params;
