import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';

import VehiclesHeader from 'components/features/Vehicles/VehiclesHeader';
import { BarChart, HorizontalBarChart, PieChart, ProgressChart } from 'components/templates/charts';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import BigNumber from './BigNumber';
import DataFrame from './DataFrame';

export type DataPoint = {
    label: string;
    value: number;
};

export type VehiclesProps = {
    changeIndex?: number;
    leasing?: number;
    types: DataPoint[];
    brands: DataPoint[];
    age: DataPoint[];
    owningCompany: DataPoint[];
    powerSource: DataPoint[];
    emissions: DataPoint[];
    owner: DataPoint[];
    gearing: DataPoint[];
};

const vehicleTypeIcons = {
    all: undefined,
    moto: 'VehicleMoto',
    cars: 'VehicleCar',
    vans: 'VehicleVan',
    trucks: 'VehicleTruck',
    buses: 'VehicleBus',
    tractors: 'VehicleTractor',
    trailers: 'VehicleTrailer',
    others: 'VehicleOther',
} as const;

const vehiclesFilters = ['all', 'moto', 'cars', 'vans', 'trucks', 'buses', 'tractors', 'trailers', 'others'] as const;
export type VehiclesFilter = (typeof vehiclesFilters)[number];
export const isVehiclesFilter = (filter: string): filter is VehiclesFilter =>
    vehiclesFilters.includes(filter as VehiclesFilter);

export const Vehicles: React.VFC<VehiclesProps> = ({
    changeIndex,
    leasing,
    types,
    brands,
    age,
    owningCompany,
    powerSource,
    emissions,
    owner,
    gearing,
}) => {
    const intl = useIntl();
    const [filter, setFilter] = useState<VehiclesFilter>('all');

    const totalCars = age.reduce((acc, curr) => acc + curr.value, 0);
    const newCars = age.find((datum) => datum.label === '< 1')?.value ?? 0;
    const usedCars = totalCars - newCars;
    const usedCarsRatio = usedCars / totalCars;

    const sortedTypes = types.slice().sort((a, b) => b.value - a.value);
    const activeFilters: VehiclesFilter[] = sortedTypes
        .map((type) => type.label.toLowerCase())
        .filter(isVehiclesFilter);
    const activeType = types.find((type) => type.label.toLowerCase() === filter);
    const filteredTotal = activeType?.value ?? 0;
    const filteredLabel = activeType?.label ?? '';

    const vehicleTypeIcon = vehicleTypeIcons[filter];

    const maxBarsNormal = 4;
    const maxBarsWide = 5;
    const maxBarsPopup = 15;
    const maxHorizontalBarsNormal = 4;

    return (
        <Grid container spacing={2} rowSpacing={5}>
            <Grid item xs={12}>
                <VehiclesHeader selectedFilter={filter} onFilterChange={setFilter} activeFilters={activeFilters} />
            </Grid>

            {types.length > 0 && (
                <Grid item xs={12} sm={6}>
                    {filter === 'all' ? (
                        <DataFrame
                            title="Types"
                            popup={
                                types.length > maxBarsWide && (
                                    <BarChart data={types} highlightLargest order="descending" max={maxBarsPopup} />
                                )
                            }
                            popupWidth={types.length * 64 + 96}
                            popupTooltip={`Show all ${types.length}`}
                        >
                            <BarChart data={types} highlightLargest order="descending" max={maxBarsWide} />
                        </DataFrame>
                    ) : (
                        <Frame title="Types">
                            <BigNumber
                                value={filteredTotal}
                                label={filteredLabel}
                                icon={
                                    vehicleTypeIcon && (
                                        <Icon
                                            sx={{ fontSize: 64, marginTop: -8, marginBottom: 2 }}
                                            color="grey.200"
                                            type={vehicleTypeIcon}
                                        />
                                    )
                                }
                            />
                        </Frame>
                    )}
                </Grid>
            )}

            {brands.length > 0 && (
                <Grid item xs={12} sm={6}>
                    <DataFrame
                        title="Brands"
                        popup={
                            brands.length > maxBarsWide && (
                                <BarChart data={brands} highlightLargest order="descending" max={maxBarsPopup} />
                            )
                        }
                        popupWidth={brands.length * 64 + 96}
                        popupTooltip={`Show all ${brands.length}`}
                    >
                        <BarChart data={brands} highlightLargest order="descending" max={maxBarsWide} />
                    </DataFrame>
                </Grid>
            )}

            {usedCars !== undefined && (
                <Grid item xs={12} sm={6} md={4}>
                    <Frame title="Used cars">
                        <ProgressChart
                            value={usedCars}
                            total={totalCars}
                            valueLabel="Cars 1 years old or more"
                            restLabel="Cars less than 1 years old"
                            height={160}
                        />
                        <Typography
                            component="div"
                            variant="tiny"
                            color="subtle"
                            textAlign="center"
                            sx={{ height: 20 }}
                        >
                            {intl.formatNumber(usedCarsRatio, { style: 'percent' })} of the cars are 1 year old or more
                        </Typography>
                    </Frame>
                </Grid>
            )}

            {changeIndex !== undefined && (
                <Grid item xs={12} sm={6} md={4}>
                    <Frame title="Change index">
                        <BigNumber
                            value={changeIndex}
                            options={{ style: 'percent' }}
                            label="Less than 12mo owned cars (pcs) / total vehicle amount (pcs)"
                        />
                    </Frame>
                </Grid>
            )}

            {age.length > 0 && (
                <Grid item xs={12} sm={6} md={4}>
                    <Frame title="Age">
                        <BarChart data={age} highlightLargest />
                    </Frame>
                </Grid>
            )}

            {owningCompany.length > 0 && (
                <Grid item xs={12} sm={6} md={4}>
                    <DataFrame
                        title="Owning company"
                        popup={
                            owningCompany.length > maxHorizontalBarsNormal && (
                                <HorizontalBarChart
                                    data={owningCompany}
                                    highlightLargest
                                    labelWidth={160}
                                    order="descending"
                                />
                            )
                        }
                        popupWidth={480 + 48}
                        popupTooltip={`Show all ${owningCompany.length}`}
                    >
                        <HorizontalBarChart
                            data={owningCompany}
                            highlightLargest
                            labelWidth={72}
                            order="descending"
                            max={maxHorizontalBarsNormal}
                        />
                    </DataFrame>
                </Grid>
            )}

            {powerSource.length > 0 && (
                <Grid item xs={12} sm={6} md={4}>
                    <DataFrame
                        title="Power source"
                        popup={
                            powerSource.length > maxBarsNormal && (
                                <BarChart data={powerSource} highlightLargest order="descending" max={maxBarsPopup} />
                            )
                        }
                        popupWidth={powerSource.length * 64 + 96}
                        popupTooltip={`Show all ${powerSource.length}`}
                    >
                        <BarChart data={powerSource} highlightLargest order="descending" max={maxBarsNormal} />
                    </DataFrame>
                </Grid>
            )}

            {emissions.length > 0 && (
                <Grid item xs={12} sm={6} md={4}>
                    <Frame title="Emissions">
                        <HorizontalBarChart data={emissions} highlightLargest labelWidth={56} />
                    </Frame>
                </Grid>
            )}

            {owner.length > 0 && (
                <Grid item xs={12} sm={6} md={4}>
                    <Frame title="Owner">
                        <PieChart data={owner} donut order="descending" />
                    </Frame>
                </Grid>
            )}

            {leasing !== undefined && (
                <Grid item xs={12} sm={6} md={4}>
                    <Frame title="Leasing">
                        <ProgressChart
                            value={leasing}
                            total={totalCars}
                            valueLabel="Leasing"
                            labelAsNumber
                            restLabel="Others"
                        />
                    </Frame>
                </Grid>
            )}

            {gearing.length > 0 && (
                <Grid item xs={12} sm={6} md={4}>
                    <Frame title="Gearing">
                        <PieChart data={gearing} anticlockwise donut order="descending" />
                    </Frame>
                </Grid>
            )}
        </Grid>
    );
};

export default Vehicles;
