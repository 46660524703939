import { useState } from 'react';

import { Box, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

import { VehiclesFilter, isVehiclesFilter } from 'components/features/Vehicles';
import Button, { ButtonProps } from 'components/tokens/Button';
import ButtonTabs from 'components/tokens/ButtonTabs';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

export type VehiclesHeaderProps = {
    selectedFilter: VehiclesFilter;
    onFilterChange?: (filter: VehiclesFilter) => void;
    activeFilters?: VehiclesFilter[];
    showInfo?: boolean;
};

const filterIcons = {
    all: undefined,
    moto: 'VehicleMoto',
    cars: 'VehicleCar',
    vans: 'VehicleVan',
    trucks: 'VehicleTruck',
    buses: 'VehicleBus',
    tractors: 'VehicleTractor',
    trailers: 'VehicleTrailer',
    others: 'VehicleOther',
} as const;

const filterLabels = {
    all: 'All vehicles',
    moto: 'Motorcycles',
    cars: 'Cars',
    vans: 'Vans',
    trucks: 'Trucks',
    buses: 'Buses',
    tractors: 'Tractors',
    trailers: 'Trailers',
    others: 'Others',
} as const;

export const VehiclesHeader: React.VFC<VehiclesHeaderProps> = ({
    selectedFilter,
    onFilterChange,
    activeFilters = [],
    showInfo = false,
}) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleTabChange = (filter: string) => {
        if (isVehiclesFilter(filter) && onFilterChange) {
            onFilterChange(filter);
        }
    };

    const handleMenuOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClick = (filter: string) => {
        setMenuAnchorEl(null);
        handleTabChange(filter);
    };

    const splitPoint = 4;
    const allFilters: VehiclesFilter[] = ['all', ...activeFilters];
    let visibleFilters = allFilters;
    let menuFilters: VehiclesFilter[] = [];
    if (allFilters.length > splitPoint) {
        visibleFilters = allFilters.slice(0, splitPoint);
        menuFilters = allFilters.slice(splitPoint);
    }
    const activeInMenu = menuFilters.includes(selectedFilter);

    const buttons: (VehiclesFilter | 'menu')[] = visibleFilters;
    if (menuFilters.length > 0) {
        buttons.push('menu');
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {showInfo && (
                <Box sx={{ display: 'flex', marginBottom: 3 }}>
                    <Icon sx={{ marginRight: 1 }} type="InfoCircleOutline" />
                    <Typography variant="tiny" color="subtle">
                        Displaying the specific vehicle data is restricted by law so you can only see vehicles that
                        match your search. In case your search didn't contain vehicle parameters, you can check detailed
                        vehicle info by first selecting a single vehicle class below and then selecting a single brand.{' '}
                        <Link fontWeight={500} toNewTab href="//traficom.fi" color="text.primary">
                            Source: Traficom
                        </Link>
                    </Typography>
                </Box>
            )}
            <ButtonTabs selected={selectedFilter} onChange={handleTabChange}>
                {buttons.map<React.ReactElement<ButtonProps>>((filter) =>
                    filter === 'menu' ? (
                        <Button
                            value={activeInMenu ? selectedFilter : undefined}
                            startIcon={<Icon type="MoreHorizontal" />}
                            onClick={handleMenuOpenClick}
                        />
                    ) : (
                        <Button
                            value={filter}
                            startIcon={
                                filter !== 'all' && (
                                    <Icon fontSize="medium" color="text.subtle" type={filterIcons[filter]} />
                                )
                            }
                        >
                            {filterLabels[filter]}
                        </Button>
                    ),
                )}
            </ButtonTabs>
            {menuFilters.length > 0 && (
                <Menu open={Boolean(menuAnchorEl)} anchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(null)}>
                    {menuFilters.map((filter) => (
                        <MenuItem onClick={() => handleMenuClick(filter)}>
                            {filter !== 'all' && (
                                <ListItemIcon
                                    sx={{ justifyContent: 'flex-end', marginRight: 1, '&&': { minWidth: 24 } }}
                                >
                                    <Icon color="text.subtle" type={filterIcons[filter]} />
                                </ListItemIcon>
                            )}
                            <ListItemText disableTypography>
                                <Typography variant="buttonMedium">{filterLabels[filter]}</Typography>
                            </ListItemText>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </Box>
    );
};

export default VehiclesHeader;
