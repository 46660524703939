import { Box } from '@mui/material';

import { ProfileExportedToCRM } from 'components/modules/connectors/types';
import { getCRMName } from 'components/modules/connectors/utils';
import { Dialog } from 'components/tokens/Dialog';
import { FlagEmoji } from 'components/tokens/FlagEmoji';
import { Link } from 'components/tokens/Link';
import Tag from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';

import { CompanyLogo } from '../avatars/CompanyLogo';
import { SortableTable, SortableTableColumn } from '../SortableTable';
import NoValue from '../SortableTable/NoValue';
import { CRMBasedDealTooltipTitle } from './CompanyCardCRM';

const HEADER_HEIGHT = 41;
const ROW_HEIGHT = 49;

type Props = {
    handleDialogClose: () => void;
    data: ProfileExportedToCRM[];
    name: string;
    country?: string;
    logo?: string;
};

const ExportedProfilesDialog = ({ handleDialogClose, data, name, country, logo }: Props) => {
    const { crmTarget } = data[0];

    return (
        <Dialog
            open
            onClose={handleDialogClose}
            title={
                <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                    <CompanyLogo logo={logo} company_name={name} />
                    <Typography variant="h4">{name}</Typography>
                    {country && <FlagEmoji code={country} sx={{ marginBottom: 2, fontSize: 16 }} />}
                </Box>
            }
        >
            <Typography variant="h4" color="dark" sx={{ marginBottom: 2 }}>
                Accounts matched
            </Typography>

            <SortableTable<ProfileExportedToCRM>
                data={data}
                rowKeyField="id"
                tableHeight={Math.min(HEADER_HEIGHT + ROW_HEIGHT * data.length, 280)}
                sx={{ marginTop: 1 }}
                defaultSortColumn="accountName"
            >
                <SortableTableColumn<ProfileExportedToCRM>
                    label={`${getCRMName(crmTarget.toLowerCase())} ID`}
                    field="id"
                    width={120}
                    sx={{ direction: 'rtl' }}
                >
                    {(row) => (
                        <Link toNewTab href={row.linkToCRM} underline="always">
                            {row.id}
                        </Link>
                    )}
                </SortableTableColumn>
                <SortableTableColumn<ProfileExportedToCRM> label="Name" field="accountName">
                    {(row) => (
                        <Link toNewTab href={row.linkToCRM} underline="hover">
                            <b>{row.accountName}</b>
                        </Link>
                    )}
                </SortableTableColumn>
                <SortableTableColumn<ProfileExportedToCRM> label="Owner" field="ownerEmail">
                    {(row) => row.ownerEmail}
                </SortableTableColumn>
                <SortableTableColumn<ProfileExportedToCRM>
                    label={CRMBasedDealTooltipTitle[crmTarget.toLowerCase()]}
                    field="openDealCount"
                >
                    {(row) =>
                        !row.lostDealCount && !row.openDealCount && !row.wonDealCount ? (
                            <NoValue />
                        ) : (
                            <Box sx={{ display: 'flex', gap: '6px' }}>
                                {row.lostDealCount !== 0 && (
                                    <Tag label={`${row.lostDealCount} lost`} variant={'orange'} />
                                )}
                                {row.openDealCount !== 0 && (
                                    <Tag label={`${row.openDealCount} open`} variant={'blue'} />
                                )}
                                {row.wonDealCount !== 0 && <Tag label={`${row.wonDealCount} won`} variant={'green'} />}
                            </Box>
                        )
                    }
                </SortableTableColumn>
            </SortableTable>
        </Dialog>
    );
};

export default ExportedProfilesDialog;
