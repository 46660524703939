import React from 'react';

import { styled } from '@mui/material';

type Props = {
    WrapperComponent?: React.ElementType;
    text: React.ReactNode;
    emoji?: React.ReactNode;
    componentProps?: Object;
    padding?: string;
};

const NoOptionFoundWithEmoji: React.FC<Props> = ({
    WrapperComponent = 'div',
    text,
    emoji,
    componentProps,
    padding,
}) => {
    return (
        <Container padding={padding ?? '0px 16px'}>
            <WrapperComponent {...componentProps}>
                {emoji || (
                    <span role="img" aria-label="thinking_face" style={{ marginRight: 5 }}>
                        🤔
                    </span>
                )}{' '}
                {text}
            </WrapperComponent>
        </Container>
    );
};

const Container = styled('div')<{ padding: string }>(({ padding, theme }) => ({
    '& > *': {
        fontWeight: 'normal',
        fontSize: 14,
        color: theme.palette.grey[500],
        cursor: 'default',
        padding: padding,
        background: theme.palette.common.white,
    },
}));

export default NoOptionFoundWithEmoji;
