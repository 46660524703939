import { format as dateFnsFormat, formatISO, parseISO } from 'date-fns';

/**
 * Parses date correctly as UTC date from string. Use for parsing backend datetime strings.
 * e.g. "2022-04-28T09:38:38.683000"
 *
 * Equivalent to `moment.utc(<dateStr>)`
 *
 * Useful because backend datetime strings do not specify timezone, which should be UTC.
 *
 * JS `new Date(<string>)` assumes given date string as local time.
 *
 * @param {string} dateStr Date in string format e.g. ISO 8601 in UTC time
 * @return {Date} Date object
 */
export const toDateUTC = (dateStr: string): Date => new Date(`${dateStr}Z`);

export const parseISOUTC = (dateStr: string): Date => parseISO(`${dateStr}Z`);

/**
 * Get date string (ISO 8601) reprsenting current time.
 *
 * Equivalent to `moment().format()`
 *
 * @return {string} ISO 8601 date string
 */
export const getNowString = (): string => formatISO(new Date());

enum DateFormat {
    short = 'yyyy-MM-dd',
    long = 'MMM d, yyyy',
    'short-datetime' = 'yyyy-MM-dd HH:mm',
    'long-datetime' = 'MMM d yyyy, H:mm',
}

/**
 * Formats the date to the commonly used formats
 * @param date the date as a ISO date string, number or Date object, passed to date-fns/format().
 * If a string is passed, first parses the date using date-fns/parseISO().
 * @param format short (2023-01-03), long (Jan 3, 2023), short-datetime (2023-01-03 13:47) or long-datetime (Jan 3 2023, 13:47)
 */

export const formatDate = (date: string | number | Date, format: keyof typeof DateFormat = 'short') =>
    typeof date === 'string'
        ? dateFnsFormat(parseISO(date), DateFormat[format])
        : dateFnsFormat(date, DateFormat[format]);
