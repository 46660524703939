import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { NotFound } from 'components/templates/NotFound';
import { EmbeddedQuery, useEmbeddedQueryContext } from 'contexts/EmbeddedQueryContext';
import { parseTokenData } from 'contexts/utilities/authToken';

import { MainPath } from '../Platform/Router/routePaths';

export const EmbeddedTokenReceiver: React.FC = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const { setQuery } = useEmbeddedQueryContext();
    const [notFound, setNotFound] = useState(false);

    const token = URLSearchParams ? new URLSearchParams(search).get('token') : undefined;

    useEffect(() => {
        if (token) {
            try {
                const tokenData = parseTokenData(token) ?? {};
                const query = tokenData.api as EmbeddedQuery;
                setQuery(query, token);
                const path = `/${MainPath.EmbeddedView}/`;
                navigate(path, { replace: true });
            } catch (error) {
                setNotFound(true);
            }
        }
        setNotFound(true);
    }, [token, navigate, setQuery]);

    if (notFound) {
        return <NotFound />;
    }
    return null;
};

export default EmbeddedTokenReceiver;
