import { Box, Tooltip } from '@mui/material';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import useDialogMount from 'hooks/useDialogMount';

import { ListExportDialog, ListExportDialogProps } from './ListExportDialog';

export type ListExportDialogWrapperProps = Pick<
    ListExportDialogProps,
    | 'listId'
    | 'companyIds'
    | 'totalCount'
    | 'loadingCount'
    | 'query'
    | 'usageCount'
    | 'loadingUsageCount'
    | 'scheduledCount'
    | 'filename'
> & { disabled?: boolean; disabledTooltip?: string; buttonText: React.ReactNode };

export const ListExportDialogWrapper: React.FC<ListExportDialogWrapperProps> = ({
    listId,
    companyIds,
    usageCount,
    loadingUsageCount,
    totalCount,
    loadingCount,
    query,
    disabled,
    disabledTooltip = '',
    buttonText,
    scheduledCount,
    filename,
}) => {
    const { open, mount, handleOpen, handleClose } = useDialogMount();

    const { hasProductPermission } = usePermissionContext();
    const isExportDisabled = hasProductPermission(Permission.ExportDisabled);

    return (
        <>
            <Tooltip title={isExportDisabled ? 'Contact sales to upgrade' : disabledTooltip}>
                <Box>
                    <Button
                        startIcon={<Icon type="Download" />}
                        variant="tertiary"
                        size="small"
                        data-testid="buttonOpen"
                        onClick={handleOpen}
                        disabled={disabled || isExportDisabled}
                    >
                        {buttonText}
                    </Button>
                </Box>
            </Tooltip>
            {mount && (
                <ListExportDialog
                    open={open}
                    listId={listId}
                    companyIds={companyIds}
                    totalCount={totalCount}
                    loadingCount={loadingCount}
                    scheduledCount={scheduledCount}
                    usageCount={usageCount}
                    loadingUsageCount={loadingUsageCount}
                    onClose={handleClose}
                    query={query}
                    filename={filename}
                />
            )}
        </>
    );
};

export default ListExportDialogWrapper;
