import { Routes, Route, Navigate } from 'react-router-dom';

import Impersonate from 'components/features/Impersonate';
import { Connectors, Triggers, Settings } from 'components/modules';
import { ProfilesRouter } from 'components/modules/profiles';
import { ProfileRouter } from 'components/modules/profiles/Profile';
import { SearchRouter } from 'components/modules/search';
import NotFound from 'components/templates/NotFound';

import { MainPath } from './routePaths';

export type RouterProps = {
    scrollRef?: React.RefObject<HTMLElement>;
};

const Router: React.FC<RouterProps> = ({ scrollRef }) => (
    <Routes>
        <Route path={MainPath.Root} element={<Navigate replace to={MainPath.Profiles} />} />
        <Route path={`${MainPath.Profiles}/*`} element={<ProfilesRouter scrollRef={scrollRef} />} />
        <Route path={`${MainPath.Domain}/*`} element={<ProfileRouter type="domain" scrollRef={scrollRef} />} />
        <Route
            path={`${MainPath.Organization}/*`}
            element={<ProfileRouter type="organization" scrollRef={scrollRef} />}
        />
        <Route path={`${MainPath.Company}/*`} element={<ProfileRouter type="company-legacy" />} />
        <Route path={`${MainPath.Search}/*`} element={<SearchRouter />} />
        <Route path={`${MainPath.Triggers}/*`} element={<Triggers scrollRef={scrollRef} />} />
        <Route path={`${MainPath.Connectors}/*`} element={<Connectors />} />
        <Route path={`${MainPath.Settings}/*`} element={<Settings />} />
        <Route path={`${MainPath.Impersonate}/*`} element={<Impersonate />} />
        <Route path="*" element={<NotFound />} />
    </Routes>
);

export default Router;
