import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Copy = (props) => (
    <SvgIcon {...props}>
        <path d="M13.9999 22H3.99995C3.46402 22.0186 2.94442 21.8139 2.56524 21.4347C2.18605 21.0555 1.98132 20.5359 1.99995 20V10C1.98132 9.46408 2.18605 8.94448 2.56524 8.5653C2.94442 8.18611 3.46402 7.98138 3.99995 8.00001H7.99995V4.00001C7.98132 3.46408 8.18605 2.94448 8.56524 2.5653C8.94442 2.18611 9.46402 1.98138 9.99995 2.00001H19.9999C20.5359 1.98138 21.0555 2.18611 21.4347 2.5653C21.8138 2.94448 22.0186 3.46408 21.9999 4.00001V14C22.0183 14.5358 21.8134 15.0553 21.4343 15.4344C21.0552 15.8135 20.5358 16.0183 19.9999 16H15.9999V20C16.0183 20.5358 15.8134 21.0553 15.4343 21.4344C15.0552 21.8135 14.5358 22.0183 13.9999 22ZM3.99995 10V20H13.9999V16H9.99995C9.46411 16.0183 8.94468 15.8135 8.56557 15.4344C8.18645 15.0553 7.98162 14.5358 7.99995 14V10H3.99995ZM9.99995 4.00001V14H19.9999V4.00001H9.99995Z" />
    </SvgIcon>
);

export default Copy;
