import React from 'react';

import { Box, darken, styled, useTheme } from '@mui/material';
import { FormatNumberOptions, useIntl } from 'react-intl';
import { Cell, Pie, PieChart as RechartsPieChart, Sector, Tooltip } from 'recharts';

import Typography, { TypographyProps } from 'components/tokens/Typography';

import { ChartTooltip, formatTooltipValue } from '../ChartTooltip';
import ResponsiveContainer from '../utils/ResponsiveContainer';

export type ChartNumberStyle = 'decimal' | 'percent' | 'currency';

export type ProgressChartProps = {
    /** The number to calculate the dividend of the ratio. */
    value: number;
    /** The number to calculate the divisor of the ratio. */
    total: number;
    valueLabel?: string;
    restLabel?: string;
    height?: number;
    anticlockwise?: boolean;
    /** Style of the number in the hover label. */
    style?: ChartNumberStyle;
    /** Currency of the number in the hover label. */
    currency?: string;
    labelAsNumber?: boolean;
    formatOptions?: FormatNumberOptions;
    color?: string;
    unfilledColor?: string;
    hideTooltips?: boolean;
    TypographyProps?: TypographyProps;
};

const StyledSector = styled(Sector)(({ fill }) => ({
    transition: 'all 200ms ease',
    '&:hover': {
        fill: fill ? darken(fill, 0.2) : undefined,
    },
}));

export const ProgressChart: React.FC<ProgressChartProps> = ({
    value,
    valueLabel,
    total,
    restLabel,
    height = 180,
    anticlockwise = false,
    style = 'decimal',
    currency,
    labelAsNumber = false,
    formatOptions,
    color,
    unfilledColor,
    hideTooltips = false,
    TypographyProps,
}) => {
    const theme = useTheme();
    const intl = useIntl();

    const outerRadius = height * 0.4;
    const innerRadius = outerRadius * 0.75;

    const startAngle = 90;
    const endAngle = anticlockwise ? 450 : -270;
    const data = [
        { label: valueLabel, value },
        { label: restLabel, value: total - value },
    ];
    const ratio = value / total;
    // For some dummy reason Recharts' pie chart doesn't support a custom rendering component like the other charts,
    // but instead just a custom renderer for the active sector. This array is needed to set all the sectors as active,
    // which essentially makes all the sectors use the custom renderer.
    const sectorList = [0, 1];

    return (
        <Box sx={{ position: 'relative', overflow: 'visible' }}>
            <Typography
                component="div"
                variant="number"
                sx={{
                    position: 'absolute',
                    inset: 0,
                    pointerEvents: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                textAlign="center"
                {...TypographyProps}
            >
                {intl.formatNumber(labelAsNumber ? value : ratio, {
                    style: labelAsNumber ? style : 'percent',
                    currency,
                    currencyDisplay: 'narrowSymbol',
                    notation: 'compact',
                    ...formatOptions,
                })}
            </Typography>
            <ResponsiveContainer debounce={300} width="100%" height={height}>
                <RechartsPieChart width={400} height={300}>
                    <Pie
                        data={data}
                        dataKey="value"
                        isAnimationActive={false}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        outerRadius={outerRadius}
                        innerRadius={innerRadius}
                        label={false}
                        labelLine={false}
                        stroke=""
                        activeIndex={sectorList}
                        activeShape={!hideTooltips ? (props) => <StyledSector {...props} /> : undefined}
                    >
                        <Cell fill={color ?? theme.palette.chart.default} />
                        <Cell fill={unfilledColor ?? theme.palette.chart.grey5} />
                    </Pie>
                    {!hideTooltips && (
                        <Tooltip
                            content={<ChartTooltip labelKey="label" />}
                            formatter={formatTooltipValue(style, currency, style === 'percent' ? 2 : 0, intl)}
                            isAnimationActive={false}
                        />
                    )}
                </RechartsPieChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default ProgressChart;
