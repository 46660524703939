import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ExternalLink = (props) => (
    <SvgIcon {...props}>
        <path d="M17.001 20H6.001C4.89643 20 4.001 19.1046 4.001 18V7C4.001 5.89543 4.89643 5 6.001 5H10.001V7H6.001V18H17.001V14H19.001V18C19.001 19.1046 18.1056 20 17.001 20ZM11.701 13.707L10.291 12.293L16.584 6H13.001V4H20.001V11H18.001V7.415L11.701 13.707Z" />
    </SvgIcon>
);

export default ExternalLink;
