import { useEffect } from 'react';

import { FilterID } from 'components/features/Filters/FilterTypes';
import { useFilterState } from 'contexts/FilterContext';

import { Slot, isSelfGroup } from './utils/helpers';
import { isShowAddOfficeTypeButton } from './utils/validation';

interface UseActiveFilterParams {
    skip?: boolean;
    slots: Slot[];
    locationOptionsCount?: number;
    onAddFilter: (filterId: FilterID, slotId: number | undefined) => void;
}
export function useActiveFilter({ skip, slots, onAddFilter, locationOptionsCount }: UseActiveFilterParams) {
    const { activeFilter, setActiveFilter } = useFilterState();

    useEffect(() => {
        if (skip || !activeFilter) {
            return;
        }

        if (activeFilter === FilterID.location_type) {
            setActiveFilter(undefined);

            if (isShowAddOfficeTypeButton(slots, locationOptionsCount)) {
                onAddFilter(activeFilter, undefined);
            }
            return;
        }

        if (isSelfGroup(activeFilter)) {
            const slot = slots.find((s) => s.type === activeFilter);

            if (!slot || slot?.values.length === 0) {
                onAddFilter(activeFilter, slot?.id);
                setActiveFilter(undefined);
            }
        } else {
            const locationSlots = slots.filter((s) => s.type === 'location');

            if (locationSlots.length === 1) {
                onAddFilter(activeFilter, locationSlots[0]?.id);
                setActiveFilter(undefined);
            }
        }
    }, [activeFilter, slots, onAddFilter, setActiveFilter, skip, locationOptionsCount]);
}
