import React, { useEffect, useCallback, useRef } from 'react';

const options = {
    chrome: 'nofooter',
    tweetLimit: '5',
    /**
     * Custom language code. Supported codes here: https://developer.twitter.com/en/docs/twitter-for-websites/twitter-for-websites-supported-languages/overview.html
     */
    lang: 'en',
    height: 700,
    dnt: true,
};

const callbacks: (() => void)[] = [];

// https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/set-up-twitter-for-websites.html
const initializeTwitter = (cb: () => void) => {
    if (callbacks.length === 0) {
        callbacks.push(cb);
        const js = document.createElement('script');
        js.id = 'twitter-wjs';
        js.src = '//platform.twitter.com/widgets.js';
        js.onload = () => callbacks.forEach((callback) => callback());
        document.body.appendChild(js);
    } else {
        callbacks.push(cb);
    }
};

export type PluginProps = {
    link: string;
    onLinkError: (socialNetworkName: string, value: boolean) => void;
};

const TwitterPlugin: React.VFC<PluginProps> = ({ link, onLinkError }) => {
    const twitterRef = useRef<HTMLDivElement | null>(null);

    const loadTimeline = useCallback(
        (timelineUrl: string) => {
            const dataSource = {
                sourceType: 'url',
                url: timelineUrl,
            };
            const renderTimeline = () => {
                const { twttr } = window;
                twttr.ready().then(({ widgets }: { widgets: { createTimeline: Function } }) => {
                    // Clear previously rendered timeline before rendering the updated timeline
                    if (twitterRef.current) {
                        twitterRef.current.innerHTML = '';
                    }
                    widgets.createTimeline(dataSource, twitterRef.current, options).then((twitterElem: Element) => {
                        if (!twitterElem) {
                            onLinkError('twitter', true);
                        }
                    });
                });
            };

            const { twttr } = window;
            if (!(twttr && twttr.ready)) {
                initializeTwitter(renderTimeline);
            } else {
                renderTimeline();
            }
        },
        [onLinkError],
    );

    const handleTwitterWidgetError = useCallback(
        (error: PromiseRejectionEvent) => {
            error.preventDefault();
            /**
             * Twitter widget doesn't handle username with dot e.g. https://twitter.com/vainu.io
             * So it breaks the component.
             */
            if (error.reason.fileName.includes('platform.twitter.com')) {
                onLinkError('twitter', true);
            }
        },
        [onLinkError],
    );

    useEffect(() => {
        window.addEventListener('unhandledrejection', handleTwitterWidgetError);
        loadTimeline(link);
        return () => {
            window.removeEventListener('unhandledrejection', handleTwitterWidgetError);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [link, loadTimeline]);

    return <div id="twitter" ref={twitterRef} aria-label="Twitter widget" className="twitter-timeline" />;
};

export default TwitterPlugin;
