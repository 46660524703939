import React from 'react';

import { styled } from '@mui/material';

import Typography from 'components/tokens/Typography';

import ContactSupportDialog from '../common/ContactSupportDialog';
import HeaderWithDescription from './HeaderWithDescription';

const RevertCard = () => {
    return (
        <Wrapper>
            <HeaderWithDescription
                header="Revert updated data"
                description={
                    <Typography fontSize="14px">
                        In case you are planning to revert the data back to how it was before you turned on automatic
                        updates, please ask{' '}
                        <ContactSupportDialog
                            callToAction={<SupportTeamText>your sales contact</SupportTeamText>}
                            header="Reach out to us"
                            subHeader="If you’re interested in reverting the data Vainu has updated to your CRM, please contact us and we’ll help you forward."
                        />{' '}
                        for help or chat with our{' '}
                        <SupportTeamText onClick={() => window.HubSpotConversations?.widget?.open()}>
                            support team
                        </SupportTeamText>
                        .
                    </Typography>
                }
            />
        </Wrapper>
    );
};

const Wrapper = styled('div')(({ theme }) => ({
    padding: '24px',
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 8,
}));

const SupportTeamText = styled('u')(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.text.primary,
    fontWeight: 500,
}));

export default RevertCard;
