import React, { useRef } from 'react';

import { Box, useTheme, TypographyPropsVariantOverrides } from '@mui/material';

import OverflowContainer from 'components/layout/OverflowContainer';
import Typography, { TypographyProps } from 'components/tokens/Typography';

import { useTextTruncation, TextTruncationProps } from './hooks/useTextTruncation';

type TextTruncateScrollableProps = Omit<TextTruncationProps, 'showTooltip' | 'showArrow' | 'textContainerRef'> &
    TypographyProps;

export const TextTruncateScrollable: React.VFC<TextTruncateScrollableProps> = ({
    text,
    line = 1,
    isScrollable,
    textTruncateChild,
    variant = 'small',
    sx,
    ...props
}) => {
    const theme = useTheme();
    const containerRef = useRef<HTMLElement | null>(null);
    const [renderedText, { remainingDisplayLine }] = useTextTruncation({
        text,
        line,
        isScrollable,
        textTruncateChild,
        textContainerRef: containerRef,
    });

    const fontSize = theme.typography[variant as keyof TypographyPropsVariantOverrides].fontSize ?? 14;
    const cssFontSize = typeof fontSize === 'number' ? `${fontSize}px` : fontSize;
    const lineHeight = theme.typography[variant as keyof TypographyPropsVariantOverrides].lineHeight ?? 1.4;
    const cssLineHeight = lineHeight.toString();
    const lineCount = line - remainingDisplayLine;
    const boxHeight = `calc(${cssFontSize} * ${cssLineHeight} * ${lineCount})`;

    return (
        <Typography
            ref={containerRef}
            variant={variant}
            sx={{ display: 'inline-block', width: '100%', height: 'inherit', ...sx }}
            {...props}
        >
            {isScrollable ? (
                <OverflowContainer
                    mode="vertical"
                    sx={{
                        height: boxHeight,
                        display: 'inherit',
                        '& > div': {
                            paddingRight: 0.5,
                        },
                    }}
                    verticalSize={30}
                >
                    <Box component="span">{renderedText}</Box>
                </OverflowContainer>
            ) : (
                renderedText
            )}
        </Typography>
    );
};
