import { useRef } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { isNotEmpty } from 'utilities';

import Button, { ButtonProps } from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Select from 'components/tokens/select-components/Select';
import Typography from 'components/tokens/Typography';
import { useTextCutOff } from 'hooks/useTextCutOff';

import { Filter, Operator, OperatorValue } from '../FilterTypes';
import { formatOperatorValue as commonFormatOperatorValue } from './utils';

type StyledGridProps = {
    isMapFilter: boolean;
};

type StyledButtonProps = ButtonProps & StyledGridProps;

export type FilterTemplateProps<T> = {
    filter: Filter<T>;
    uuid: string;
    groupUUID: string;
    clearFilter?: () => void;
    removeFilter: (uuid: string, groupUUID: string) => void;
    operators?: Operator[];
    operator?: OperatorValue;
    children: React.ReactNode;
    formatOperatorValue?: (value: OperatorValue) => Operator;
    updateOperator?: (newValue: Operator) => void;
    operatorDisabled?: boolean;
    isLocationTypeFilter?: boolean;
    isMapFilter?: boolean;
};

const FilterTemplate = <T,>({
    filter,
    uuid,
    groupUUID,
    removeFilter,
    operators,
    operator = '?EQ',
    operatorDisabled,
    isLocationTypeFilter,
    children,
    formatOperatorValue = commonFormatOperatorValue,
    clearFilter,
    updateOperator,
    isMapFilter = false,
}: FilterTemplateProps<T>) => {
    const valueRef = useRef<HTMLElement | null>(null);
    const [isTextCutOff, onRefresh] = useTextCutOff(valueRef);

    return (
        <>
            <Grid key={uuid} isMapFilter={isMapFilter}>
                {isMapFilter ? (
                    <Box
                        sx={{
                            padding: '8px',
                            backgroundColor: 'common.white',
                            border: '1px solid',
                            borderColor: 'grey.200',
                            color: 'rgba(0,0,0, 0.38)',
                            borderRadius: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography component="p" variant="small">
                            {filter.label}
                        </Typography>
                        {children}
                    </Box>
                ) : (
                    <>
                        <Box
                            key={`${uuid}-select-constant`}
                            sx={{
                                padding: '0 8px',
                                backgroundColor: 'common.white',
                                border: '1px solid',
                                borderColor: 'grey.200',
                                color: 'rgba(0,0,0, 0.38)',
                                borderRadius: 1,
                                height: 38,
                                display: 'flex',
                                alignItems: 'center',
                                /** Even though width is set by its parent grid container to be 1fr,
                                 * inherited width doesn't seem to work with `textOverflow` */
                                width: 175,
                            }}
                        >
                            <Tooltip
                                open={isTextCutOff}
                                onOpen={() => onRefresh(true)}
                                onClose={() => onRefresh(false)}
                                title={filter.label}
                                arrow={false}
                            >
                                <Typography
                                    ref={valueRef}
                                    variant="small"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {filter.label}
                                </Typography>
                            </Tooltip>
                        </Box>
                        {/* It was decided that the filter name is unchangeable and with the need to use tooltip,
                `<Typography />` was chosen over `<Select />`. */}
                        {/* <Select
                key={`${uuid}-select-constant`}
                placeholder="Select"
                disabled
                multiple={false}
                width="100%"
                options={[]}
                value={{ value: 'types', label: filter.label }}
                onValueChange={(value) => {}}
            /> */}
                        {isNotEmpty(operators) && (
                            <Select
                                key={`${uuid}-select-operator`}
                                placeholder="Select"
                                multiple={false}
                                width="100%"
                                options={operators}
                                value={formatOperatorValue(operator)}
                                disabled={operatorDisabled}
                                onValueChange={(v) => updateOperator?.(v as Operator)}
                            />
                        )}
                        {children}
                    </>
                )}
                {isLocationTypeFilter ? (
                    <Tooltip
                        title={
                            <div style={{ padding: 8 }}>
                                <Typography variant="small">
                                    Location type defines this group so it can’t be removed.
                                </Typography>
                            </div>
                        }
                        placement="top"
                    >
                        <StyledButton
                            key={`${uuid}-button`}
                            variant="flat"
                            startIcon={<Icon type="InfoCircleOutline" fontSize="small" />}
                            aria-label={`info ${filter?.label}`}
                            isMapFilter={isMapFilter}
                        />
                    </Tooltip>
                ) : (
                    <StyledButton
                        key={`${uuid}-button`}
                        variant="flat"
                        onClick={clearFilter ?? (() => removeFilter(uuid, groupUUID))}
                        startIcon={<Icon type="TrashFull" fontSize="small" />}
                        aria-label={`Remove ${filter?.label}`}
                        isMapFilter={isMapFilter}
                    />
                )}
            </Grid>
        </>
    );
};

export default FilterTemplate;

const Grid = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isMapFilter',
})<StyledGridProps>(({ isMapFilter }) => ({
    display: 'grid',
    gridTemplateColumns: isMapFilter ? '1fr 2em' : '1fr 0.9fr 1.5fr 2em',
    gridGap: 5,
}));

const StyledButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'isMapFilter' })<StyledButtonProps>(
    ({ theme: { palette }, isMapFilter }) => ({
        minWidth: 16,
        padding: 0,
        '& .MuiSvgIcon-root': {
            color: palette.text.subtle,
        },
        '&:hover .MuiSvgIcon-root': {
            color: palette.button.background,
            transitionProperty: 'fill, color',
        },
        ...(isMapFilter && {
            height: 40,
        }),
    }),
);
