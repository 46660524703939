import { TabContentGridSection } from 'components/modules/profiles/Profile/tabs';
import HeaderBox from 'components/templates/HeaderBox';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';

import { ValidFinancialStatement } from '../types';
import { formatStatementPeriod } from './utils';

export interface AvailableStatementsProps {
    statementsWithPdf?: ValidFinancialStatement[];
    skeleton?: boolean;
    onClick?: (statement: ValidFinancialStatement) => void;
}

export const AvailableStatements: React.FC<AvailableStatementsProps> = ({
    statementsWithPdf,
    skeleton = false,
    onClick,
}) => {
    if (skeleton) {
        return (
            <TabContentGridSection columns={3}>
                <HeaderBox skeleton />
                <HeaderBox skeleton />
                <HeaderBox skeleton />
            </TabContentGridSection>
        );
    }
    if (!statementsWithPdf) {
        return null;
    }

    return (
        <TabContentGridSection columns={3}>
            {statementsWithPdf.map((statement, index) => (
                <HeaderBox
                    key={statement.accounting_period.end}
                    title={formatStatementPeriod(statement)}
                    label="Statement"
                    number={
                        <Button
                            size="small"
                            variant="tertiary"
                            startIcon={<Icon type="Pdf" />}
                            onClick={() => onClick?.(statement)}
                        >
                            Open
                        </Button>
                    }
                    variant="flat-grey"
                />
            ))}
        </TabContentGridSection>
    );
};

export default AvailableStatements;
