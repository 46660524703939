import { useState } from 'react';

import clsx from 'clsx';
import { FormattedNumber } from 'react-intl';

import { GroupStructureEntity } from 'api/types/groupStructure';
import CountryFlag from 'components/features/Locations/CountryFlag';
import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import { getStaffNumber } from '../utils';
import Dot from './Dot';
import {
    LeafContent,
    CompanyInfoContainer,
    boxWidth,
    nameWidth,
    logoWidth,
    flagMargin,
} from './GroupStructureLeaf.styled';

export type GroupStructureLeafProps = {
    company: GroupStructureEntity;
    active?: boolean;
    showFlags: boolean;
};

export const GroupStructureLeaf: React.VFC<GroupStructureLeafProps> = ({ company, active, showFlags }) => {
    const [expanded, setExpanded] = useState(false);

    const { company_name, logo, turn_over, country, daughters, is_concern } = company;
    const business_id = is_concern ? '' : company.business_id;

    const staffNumber = getStaffNumber(company);

    const isGroupRoot = is_concern;
    const borderless = is_concern;
    const normal = !borderless && !active;
    const hasInfo = !!turn_over || !!staffNumber || !!daughters;
    const shownLogo = isGroupRoot && !logo ? daughters?.[0]?.logo : logo;

    return (
        <LeafContent
            in={expanded}
            orientation="horizontal"
            collapsedSize={boxWidth}
            className={clsx({ borderless, active, normal, expanded })}
            onMouseEnter={() => setExpanded(true)}
            onMouseLeave={() => setExpanded(false)}
        >
            <CompanyLogo
                variant="round"
                border
                size={isGroupRoot ? 'small' : 'tiny'}
                company_name={company_name}
                logo={shownLogo}
                sx={{ minWidth: isGroupRoot ? 32 : logoWidth }}
            />
            <CompanyInfoContainer>
                <div>
                    <Typography
                        variant={isGroupRoot ? 'body1' : 'small'}
                        weight={isGroupRoot ? 'bold' : 'semibold'}
                        className="company-name"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: expanded ? 'none' : nameWidth,
                        }}
                    >
                        {company_name}
                    </Typography>
                    {showFlags && country && (
                        <CountryFlag country={country} sx={{ marginLeft: `${flagMargin}px`, marginRight: 0 }} />
                    )}
                    <Typography
                        variant="mini"
                        color="subtle"
                        sx={{
                            fontStyle: 'italic',
                            opacity: expanded ? 1 : 0,
                            maxWidth: expanded ? 'none' : 0,
                            transition: 'opacity 300ms ease',
                            marginLeft: `${flagMargin}px`,
                        }}
                    >
                        {!isGroupRoot && business_id && `(${business_id})`}
                    </Typography>
                </div>
                {hasInfo && (
                    <div>
                        {!!turn_over && (
                            <Typography variant="mini" color="subtle">
                                Revenue:{' '}
                                <Typography variant="mini" color="default" weight="semibold">
                                    <FormattedNumber
                                        value={turn_over}
                                        style="currency"
                                        notation="compact"
                                        currency="EUR"
                                    />
                                </Typography>
                            </Typography>
                        )}
                        {!!staffNumber && (
                            <>
                                {!!turn_over && <Dot />}
                                <Typography variant="mini" color="subtle">
                                    Employees:{' '}
                                    <Typography variant="mini" color="default" weight="semibold">
                                        <FormattedNumber value={staffNumber} notation="compact" />
                                    </Typography>
                                </Typography>
                            </>
                        )}
                        {!!daughters && (
                            <>
                                {!!(turn_over || staffNumber) && <Dot />}
                                <Icon
                                    sx={{
                                        height: 12,
                                        width: 12,
                                        marginRight: '4px',
                                        color: 'primary.main',
                                    }}
                                    type="GroupStructure"
                                />
                                <Typography variant="mini">
                                    <Typography variant="mini" color="default" weight="semibold">
                                        <FormattedNumber value={daughters.length} notation="compact" />
                                    </Typography>
                                </Typography>
                            </>
                        )}
                    </div>
                )}
            </CompanyInfoContainer>
        </LeafContent>
    );
};

export default GroupStructureLeaf;
