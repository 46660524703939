import { styled } from '@mui/material/styles';

import Button from 'components/tokens/Button';

import { Route } from './MarketingBanner';

type MarketingStyleProps = {
    route: Route;
};

export const StyledMarketingContainer = styled('div')<MarketingStyleProps>(({ theme: { palette }, route }) => ({
    position: 'relative',
    borderRadius: 16,
    overflow: 'hidden',
    color: palette.text.strong,
    ...(route === 'profiles' && {
        background: palette.banner.profiles,
    }),
    ...(route === 'triggers' && {
        background: palette.banner.triggers,
    }),
    ...(route === 'connectors' && {
        background: palette.banner.connectors,
    }),
}));

export const GridContainer = styled('div')<MarketingStyleProps>(({ theme: { palette }, route }) => ({
    display: 'grid',
    gridTemplate: `
        'header   visual'
        'text     visual'
        'features visual'
        'cta      visual'
        / minmax(auto, 800px) max-content`,
    gap: '1em',
    justifyContent: 'center',
    padding: '40px',
    minHeight: 350,
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 1,
    '@media (max-width: 1350px)': {
        gridTemplate: `
            'header'
            'text'
            'features'
            'visual' 26vw
            'details'
            'cta' / 1fr`,
        padding: 40,
        '& .marketing-banner-visual': {
            '& > img': {
                height: '26vw',
                width: '45vw',
            },
        },
        '& .marketing-banner-button': {
            justifySelf: 'auto',
        },
        '& .marketing-banner-details': {
            justifyContent: 'center',
        },
    },
}));

export const GridVisual = styled('div')<MarketingStyleProps>(({ theme: { palette }, route }) => ({
    gridArea: 'visual',
    justifySelf: 'center',
    alignSelf: 'start',
    '& > img': {
        height: 'min(330px, 16vw)',
        width: 'min(560px, 27vw)',
        boxShadow: '0px 2px 4px rgba(138, 138, 138, 0.05), 0px 2px 4px rgba(10, 10, 10, 0.05)',
        borderRadius: 16,
        border: '8px solid',
        ...(route === 'profiles' && {
            borderColor: palette.banner.profiles,
        }),
        ...(route === 'triggers' && {
            borderColor: palette.banner.triggers,
        }),
        ...(route === 'connectors' && {
            borderColor: palette.banner.connectors,
        }),
    },
}));

export const GridButton = styled(Button)`
    grid-area: cta;
    justify-self: start;
    min-width: 200px;
    height: 48px;
`;

export const StyledHeaderWrapper = styled('div')`
    grid-area: header;
    display: flex;
    align-items: center;
`;

export const StyledIconWrapper = styled('div')`
    position: relative;
    margin-right: 10px;
`;

export const StyledFeaturesWrapper = styled('div')`
    grid-area: features;
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    padding: 16px 0;
`;

export const StyledBoxesContainer = styled('div')<MarketingStyleProps>(({ route }) => ({
    width: '100%',
    left: 16,
    top: 24,
    position: 'absolute',
    ...(route === 'profiles' && {
        '& svg': {
            fill: '#B8B3A6',
        },
    }),
    ...(route === 'connectors' && {
        '& svg': {
            fill: '#D8C097',
        },
    }),
    ...(route === 'triggers' && {
        '& svg': {
            fill: '#B8B3A6',
        },
    }),
}));

export const StyledLeftBoxesContainer = styled('div')`
    position: absolute;
`;

export const StyledRightBoxesContainer = styled('div')`
    position: absolute;
    right: 100px;
    top: 12px;
`;
