import { isNotEmpty } from 'utilities';

import { domainProfileType } from 'api/types/Organization';
import BasicInfo from 'components/features/BasicInfo';
import { useBasicDataFromProfileData } from 'components/features/BasicInfo/utils';
import LocationsMap from 'components/features/Locations/Map';
import { useAddressesFromBusinessUnits, useCountriesFromBusinessUnits } from 'components/features/Locations/utils';
import { WebProfileTechnologiesList } from 'components/features/WebProfile';
import Descriptions from 'components/templates/Descriptions';
import { useDescriptionsFromDomainData } from 'components/templates/Descriptions/utils';

import { TabContent, EmbeddedDomainTabProps } from '..';

export const OverviewTab: React.FC<EmbeddedDomainTabProps> = ({ data, loading = false, onTabChange }) => {
    const basicDataProps = useBasicDataFromProfileData(data);
    const descriptions = useDescriptionsFromDomainData(data);
    const addresses = useAddressesFromBusinessUnits(data);
    const addressCountries = useCountriesFromBusinessUnits(data?.business_units);
    const technologiesUpdatedDate = data.metadata?.crawled?.web_technologies;

    return (
        <TabContent>
            <BasicInfo
                loading={loading}
                loadingMode={domainProfileType}
                {...basicDataProps}
                onGoToClick={onTabChange}
            />
            {(loading || isNotEmpty(descriptions)) && <Descriptions skeleton={loading} descriptions={descriptions} />}
            {(isNotEmpty(data?.business_units) || loading) && (
                <LocationsMap
                    skeleton={loading}
                    company_name={data.name}
                    countries={addressCountries}
                    addresses={addresses}
                    onGoToClick={onTabChange}
                />
            )}
            {(isNotEmpty(data?.technology_data) && data?.website) || loading ? (
                <WebProfileTechnologiesList
                    skeleton={loading}
                    technology_data={data?.technology_data}
                    website={data?.website}
                    updatedDate={technologiesUpdatedDate}
                    disableTable
                    onGoToClick={onTabChange}
                />
            ) : null}
        </TabContent>
    );
};

export default OverviewTab;
