import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

export const StyledGrid = styled('div')`
    display: grid;
    grid-template: 100px auto / 2fr auto 2fr;
    gap: 5px;
    align-items: center;
    position: relative;
`;

export const StyledSlider = styled(Slider)(({ theme }) => ({
    gridColumn: '1 / -1',
    color: theme.palette.brandColors.onyx,
    height: 8,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.brandColors.subtleLight}`,
        '&:hover': {
            boxShadow: `0 0 0 4px ${theme.palette.brandColors.subtleLight}`,
        },
        pointerEvents: 'auto !important', // fix issue: Slider handle outer half does not respond to clicks when moved to extreme sides
    },
    '& .MuiSlider-track': {
        height: 8,
        color: theme.palette.brandColors.onyx,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.brandColors.subtleLight,
        opacity: 1,
        height: 8,
    },
}));
