import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CloseSmall = (props) => (
    <SvgIcon {...props}>
        <path d="M15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z" />
    </SvgIcon>
);

export default CloseSmall;
