import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronRight: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M15.713 12L9.70196 5.98999L8.28796 7.40399L12.888 12.004L8.28796 16.597L9.70196 18.011L15.713 12Z" />
    </SvgIcon>
);

export default ChevronRight;
