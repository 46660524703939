import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { debounce } from 'lodash';
import { assertArray, isNotEmpty } from 'utilities';

import { List } from 'api/types/List';
import { isDomainData, ProfileData, ProfileType } from 'api/types/Organization';
import { getHQ } from 'components/features/Locations/utils';
import CompanyCard, { skeletonCards } from 'components/templates/CompanyCard';
import { getFullLocation, getThumbnailImage } from 'components/templates/CompanyCard/utils';
import { getPriorityFieldsToDisplay } from 'components/templates/CompanyCardPriorityFields/utils';
import { DomainDataResult, OrganizationResult } from 'contexts/types/FilterCompanies';
import { combineSx } from 'utilities/mui';

import { GridViewWrapper } from './GridViewWrapper';
import NoFilterResults from './NoFilterResults';

export type CompanyGridViewProps = {
    selectedCompanies?: Array<string | number>;
    unselectedCompanies?: Array<string | number>;
    allCompaniesSelected?: boolean;
    loading?: boolean;
    companies: DomainDataResult[] | OrganizationResult[] | ProfileData[];
    sx?: SxProps;
    onOpenProfile?: (profileType: ProfileType, profileId: string | undefined) => void;
    onSelectProfile?: (value: string, isShiftClick: boolean) => void;
    onUnselectProfile?: (value: string, isShiftClick: boolean) => void;
    onRemoveProfile?: (domain: string) => void;
    vainuSegment?: string;
    hideActions?: boolean;
    hideCrmIndicator?: boolean;
    /** If the grid is showing list results, give the list id to exclude the list from the selection
     * of static lists where to add the company. */
    currentListId?: List['id'];
};

export const CompanyGridView: React.FC<CompanyGridViewProps> = ({
    companies,
    selectedCompanies = [],
    unselectedCompanies = [],
    allCompaniesSelected = false,
    loading = false,
    sx,
    onOpenProfile,
    onSelectProfile,
    onUnselectProfile,
    onRemoveProfile,
    vainuSegment,
    hideActions,
    hideCrmIndicator,
    currentListId,
}) => {
    const gridRef = useRef<HTMLDivElement>(null);
    const [calculatedCardWidth, setCalculatedCardWidth] = useState<number | undefined>();

    useEffect(() => {
        const calcWidth = debounce(() => {
            setCalculatedCardWidth((gridRef.current?.firstChild as HTMLDivElement)?.clientWidth);
        }, 300);

        calcWidth();

        window.addEventListener('resize', calcWidth);
        return () => window.removeEventListener('resize', calcWidth);
    }, [companies.length]);

    if (loading && (!companies || companies.length === 0)) {
        return <GridViewWrapper sx={sx}>{skeletonCards()}</GridViewWrapper>;
    }
    if (isNotEmpty(companies)) {
        return (
            <GridViewWrapper sx={sx} ref={gridRef}>
                {companies.map((profile) => (
                    <CompanyCard
                        key={profile.id}
                        id={profile.id}
                        profileType={profile.profileType}
                        // level_in_group is implemented only for Nordic DB
                        levelInGroup={'level_in_group' in profile ? profile.level_in_group : null}
                        prospect={profile}
                        companyName={profile.name}
                        description={profile.description}
                        location={getFullLocation(getHQ(assertArray(profile.business_units)))}
                        domain={profile.website}
                        image={getThumbnailImage(profile)}
                        logo={profile.logo_url}
                        priorityFields={getPriorityFieldsToDisplay({
                            isDomainDb: isDomainData(companies),
                            priorityFields:
                                'group_data' in profile && profile.group_data
                                    ? ['staff_number', 'turn_over', 'group_data']
                                    : ['staff_number', 'turn_over'],
                        })}
                        selected={
                            allCompaniesSelected
                                ? !unselectedCompanies.includes(profile.id)
                                : selectedCompanies.includes(profile.id)
                        }
                        loading={loading}
                        onClick={onOpenProfile && (() => onOpenProfile(profile.profileType, profile.id))}
                        onSelect={onSelectProfile}
                        onUnselect={onUnselectProfile}
                        onRemove={onRemoveProfile}
                        vainuSegment={vainuSegment}
                        calculatedCardWidth={calculatedCardWidth}
                        hideActions={hideActions}
                        hideCrmIndicator={hideCrmIndicator}
                        currentListId={currentListId}
                    />
                ))}
            </GridViewWrapper>
        );
    }
    return (
        <Box sx={combineSx({ height: '50vh' }, sx)}>
            <NoFilterResults />
        </Box>
    );
};

export default CompanyGridView;
