import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronDuoRight: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            d="M7.41401 13.01L5.99901 11.597L10.599 6.99699L5.99901 2.39699L7.41401 0.98999L13.424 6.99999L7.41501 13.01H7.41401ZM1.98901 13.01L0.575012 11.597L5.17501 6.99699L0.575012 2.40399L1.98901 0.98999L8.00001 6.99999L1.99001 13.01H1.98901Z"
            fill="#0A0A0A"
        />
    </SvgIcon>
);

export default ChevronDuoRight;
