import React, { Fragment } from 'react';

import { styled } from '@mui/material/styles';

import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import { GroupLevelTag } from 'components/templates/CompanyCard';
import FlagEmoji from 'components/tokens/FlagEmoji';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

type SearchSuggestionCompanyCardProps = {
    logo?: string;
    companyName?: string;
    country?: string;
    fullAddress?: string;
    domain?: string;
    businessId?: string;
    levelInGroup?: number | null;
};

const SearchSuggestionCompanyCard: React.VFC<SearchSuggestionCompanyCardProps> = ({
    logo,
    companyName,
    country,
    fullAddress,
    domain,
    businessId,
    levelInGroup,
}) => {
    return (
        <Company>
            <CompanyLogo variant="round" size="tiny" logo={logo} company_name={companyName} />
            <CompanyInfoContainer>
                <div>
                    <div style={{ flexGrow: 1 }}>
                        <Typography variant="small" weight="semibold">
                            {companyName ?? '[name not set]'}
                        </Typography>
                        {!!country && <FlagEmoji code={country} sx={{ paddingLeft: '4px' }} />}
                    </div>

                    {!!businessId && (
                        <Typography variant="mini" color="subtle">
                            {businessId}
                        </Typography>
                    )}
                </div>
                <div>
                    <GroupLevelTag level={levelInGroup} />
                    {!!fullAddress && (
                        <Fragment>
                            <Icon
                                sx={{
                                    height: 14,
                                    width: 14,
                                    marginRight: '2px',
                                    color: 'text.subtle',
                                }}
                                type="LocationOutline"
                            />
                            <Typography variant="mini" marginRight={1}>
                                <Typography variant="mini" color="subtle" weight="semibold">
                                    {fullAddress}
                                </Typography>
                            </Typography>
                        </Fragment>
                    )}
                    {!!domain && (
                        <Fragment>
                            <Icon
                                sx={{
                                    height: 14,
                                    width: 14,
                                    marginRight: '2px',
                                    color: 'text.subtle',
                                }}
                                type="Globe"
                            />
                            <Typography variant="mini">
                                <Typography variant="mini" color="subtle" weight="semibold">
                                    {domain}
                                </Typography>
                            </Typography>
                        </Fragment>
                    )}
                </div>
            </CompanyInfoContainer>
        </Company>
    );
};

export default SearchSuggestionCompanyCard;

const Company = styled('li')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    marginBottom: 1,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 1px 0px ${theme.palette.border}`,
    '&:hover': {
        backgroundColor: theme.palette.field.hover,
    },
}));

const CompanyInfoContainer = styled('div')({
    display: 'flex',
    marginLeft: 12,
    flexDirection: 'column',
    flexGrow: 1,
    '&> div': {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
});
