import { styled, MenuItem } from '@mui/material';

export const StyledMenuItem = styled(MenuItem)(({ theme: { palette } }) => ({
    color: palette.primary.main,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    '&:hover': {
        outline: '1px solid',
        outlineColor: palette.border,
        outlineOffset: -1,
    },
    '& .MuiSvgIcon-root': {
        marginRight: 8,
    },
    // This is to solve the issue of type mismatch when styling with `styled`
    // See https://github.com/mui/material-ui/issues/13921#issuecomment-484133463
})) as typeof MenuItem;
