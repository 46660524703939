import React from 'react';

const PointingHand = (props) => (
    <svg width={16} height={16} {...props} viewBox="0 0 16 16">
        <defs>
            <path id="prefix__a" d="M0 0h16v16H0z" />
            <path id="prefix__c" d="M0 0h16v16H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <mask id="prefix__b" fill="#fff">
                    <use xlinkHref="#prefix__a" />
                </mask>
                <path
                    d="M3.3 12.4c-.3-.4-.6-1.1-1.2-2C1.8 9.9.9 8.9.6 8.5c-.2-.4-.2-.6-.1-1 .1-.6.7-1.1 1.4-1.1.5 0 1 .4 1.4.7.2.2.5.6.7.8.2.2.2.3.4.5.2.3.3.5.2.1-.1-.5-.2-1.3-.4-2.1-.1-.6-.2-.7-.3-1.1-.1-.5-.2-.8-.3-1.3-.1-.3-.2-1.1-.3-1.5-.1-.5-.1-1.4.3-1.8.3-.3.9-.4 1.3-.2.5.3.8 1 .9 1.3.2.5.4 1.2.5 2 .2 1 .5 2.5.5 2.8 0-.4-.1-1.1 0-1.5.1-.3.3-.7.7-.8.3-.1.6-.1.9-.1.3.1.6.3.8.5.4.6.4 1.9.4 1.8.1-.4.1-1.2.3-1.6.1-.2.5-.4.7-.5.3-.1.7-.1 1 0 .2 0 .6.3.7.5.2.3.3 1.3.4 1.7 0 .1.1-.4.3-.7.4-.6 1.8-.8 1.9.6v2.3c0 .4-.1 1.3-.2 1.7-.1.3-.4 1-.7 1.4 0 0-1.1 1.2-1.2 1.8-.1.6-.1.6-.1 1s.1.9.1.9-.8.1-1.2 0c-.4-.1-.9-.8-1-1.1-.2-.3-.5-.3-.7 0-.2.4-.7 1.1-1.1 1.1-.7.1-2.1 0-3.1 0 0 0 .2-1-.2-1.4l-1.1-1.1-1.1-.7z"
                    fill="#FFF"
                    fillRule="nonzero"
                    mask="url(#prefix__b)"
                />
            </g>
            <g>
                <mask id="prefix__d" fill="#fff">
                    <use xlinkHref="#prefix__c" />
                </mask>
                <path
                    d="M3.3 12.4c-.3-.4-.6-1.1-1.2-2C1.8 9.9.9 8.9.6 8.5c-.2-.4-.2-.6-.1-1 .1-.6.7-1.1 1.4-1.1.5 0 1 .4 1.4.7.2.2.5.6.7.8.2.2.2.3.4.5.2.3.3.5.2.1-.1-.5-.2-1.3-.4-2.1-.1-.6-.2-.7-.3-1.1-.1-.5-.2-.8-.3-1.3-.1-.3-.2-1.1-.3-1.5-.1-.5-.1-1.4.3-1.8.3-.3.9-.4 1.3-.2.5.3.8 1 .9 1.3.2.5.4 1.2.5 2 .2 1 .5 2.5.5 2.8 0-.4-.1-1.1 0-1.5.1-.3.3-.7.7-.8.3-.1.6-.1.9-.1.3.1.6.3.8.5.4.6.4 1.9.4 1.8.1-.4.1-1.2.3-1.6.1-.2.5-.4.7-.5.3-.1.7-.1 1 0 .2 0 .6.3.7.5.2.3.3 1.3.4 1.7 0 .1.1-.4.3-.7.4-.6 1.8-.8 1.9.6v2.3c0 .4-.1 1.3-.2 1.7-.1.3-.4 1-.7 1.4 0 0-1.1 1.2-1.2 1.8-.1.6-.1.6-.1 1s.1.9.1.9-.8.1-1.2 0c-.4-.1-.9-.8-1-1.1-.2-.3-.5-.3-.7 0-.2.4-.7 1.1-1.1 1.1-.7.1-2.1 0-3.1 0 0 0 .2-1-.2-1.4l-1.1-1.1-1.1-.7z"
                    stroke="#000"
                    strokeWidth={0.75}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    mask="url(#prefix__d)"
                />
            </g>
            <path
                d="M11.6 12.7V9.3M9.6 12.7l-.1-3.4M7.6 9.3v3.4"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth={0.75}
            />
        </g>
    </svg>
);

export default PointingHand;
