import { useEmbeddedQueryContext } from 'contexts/EmbeddedQueryContext';

import EmbeddedProfile from './EmbeddedProfile';

export const EmbeddedProfileSelector: React.FC = () => {
    const { query, token } = useEmbeddedQueryContext();

    if (query && token) {
        return <EmbeddedProfile query={query} token={token} />;
    }

    return null;
};
