import React, { useState } from 'react';

import { FieldValues, UseFormGetValues } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Select from 'components/tokens/select-components/Select';

import { mergeKeys } from '../../../common/utils';
import { getSameFiltersState } from '../../../filters/utils';
import { FilterGroup, FilterGroupProps, FilterID, GroupOption, GroupValues, NodeValue } from '../../../FilterTypes';
import { getGroupFilters } from '../../common/groupUtils';
import FilterGroupTemplate from '../../FilterGroupTemplate';
import {
    getFiltersById as getVCIFiltersById,
    isVainuCustomIndustryFilter,
    renderVCIFilters,
} from '../VainuCustomIndustryGroup/utils';
import { useIndustryGroup } from './useIndustryGroup';

const options: GroupOption[] = [
    {
        value: FilterID.industry_official,
        label: 'Industry (official)',
    },
    {
        value: FilterID.vainu_custom_industry,
        label: 'Vainu Custom Industry',
        databases: ['FI', 'NO', 'DK', 'SE'],
    },
];

const createNewGroup = (value: NodeValue) =>
    ({
        [FilterOperator.MATCH]: {
            [GroupValues.industry]: { [FilterOperator.ALL]: [value] },
        },
    }) as NodeValue;

const config: FilterGroup = {
    group: GroupValues.industry,
    label: 'Industry',
    options,
    filterUUIDs: [],
    logic: {},
    render: (props: FilterGroupProps) => <IndustryGroup {...props} />,
    getFilters: getGroupFiltersCombined,
    groupValues: [GroupValues.vainu_custom_industry],
    createNewGroup,
};

export const industryGroupDefinition: Record<GroupValues.industry, FilterGroup> = {
    [GroupValues.industry]: config,
};

export const IndustryGroup: React.FC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    removeGroup,
    ids = [],
    addNewGroup,
    updateGroup,
    updateGroups,
    disabled = false,
}) => {
    const [addRow, setAddRow] = useState(false);
    const [isFilterAdded, setIsFilterAdded] = useState(false);

    const {
        handleAddFilter,
        handleFilterRemove,
        handleApplyChanges,
        handleFilterRemoveVCI,
        keys,
        activeFilters,
        filteredOptions,
        filterGroups,
    } = useIndustryGroup({
        ids,
        applyChanges,
        removeGroup,
        addNewGroup,
        updateGroup,
        updateGroups,
        createNewGroup,
        options,
    });

    const renderFilters = () => {
        return ids.map((id) => {
            const filterGroup = filterGroups[id];

            if (!filterGroup) {
                return null;
            }

            if (isVainuCustomIndustryFilter(filterGroup)) {
                return renderVCIFilters({
                    filterGroup,
                    activeFilters,
                    groupUUID: config.group,
                    removeFilter: handleFilterRemoveVCI,
                    id,
                });
            }

            return filterGroup.filters.map((filter, index) => {
                const filterDefinition = filter.definition;
                const sameFiltersState = getSameFiltersState(activeFilters, filter);

                return filterDefinition
                    ? filterDefinition.render({
                          key: keys[mergeKeys(id, filter.id)],
                          uuid: filterDefinition.id,
                          filter,
                          groupUUID: config.group,
                          removeFilter: () => handleFilterRemove(id, index),
                          sameFiltersState,
                      })
                    : null;
            });
        });
    };

    const addRowSelect = (
        <Select
            placeholder="Select"
            width="100%"
            multiple={false}
            value={null}
            autoFocus
            openOnFocus
            sx={{ gridArea: 'add' }}
            options={filteredOptions || []}
            onValueChange={(value) => {
                if (Array.isArray(value)) {
                    throw new Error('should not be array value');
                }
                if (typeof value === 'number') {
                    throw new Error('should not be number value');
                }
                if (typeof value === 'string') {
                    throw new Error('should not be string value');
                }
                if (!value) {
                    return;
                }
                handleAddFilter(value.value as FilterID);
                setAddRow(false);
                setIsFilterAdded(true);
            }}
        />
    );

    const addRowButton = (
        <Button
            variant="tertiary"
            size="small"
            onClick={() => setAddRow(true)}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            AND
        </Button>
    );

    const renderedFilters = renderFilters();

    const props = {
        label: config.label,
        filters: renderedFilters,
        addFilter: handleAddFilter,
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        addRowSelect,
        addRowButton,
        addRow,
        isFilterAdded,
        setIsFilterAdded,
        disabled,
    };
    return <FilterGroupTemplate {...props} />;
};

function getGroupFiltersCombined(id: string, getValues: UseFormGetValues<FieldValues>) {
    const vciFilters = getVCIFiltersById(id, getValues);

    const basicFilters = getGroupFilters(id, getValues);

    if (!vciFilters?.isUndefined) {
        return vciFilters;
    } else {
        return basicFilters;
    }
}
