import React, { createContext, memo, useContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { uniq } from 'lodash';
import { initializeObjectWithArrayValues } from 'utilities';

import { getAuditValues, getRecruitmentKeywords, getWebTags } from 'api/filter_values';
import { WebTag } from 'api/types/Tag';
import { FilterID } from 'components/features/Filters/FilterTypes';
import { SelectOption } from 'components/tokens/select-components/Select';
import { Database, isUploaded, useListsContext } from 'contexts/ListsContext';
import { getObjectKeys } from 'utilities/objectUtils';
import {
    VCIOptions,
    companySizeIndicatorOptions,
    countriesGlobalOptions,
    countriesRegistryOptions,
    crmOptions,
    locationTypeCountryOptions,
    locationTypeGlobalOptions,
} from 'utilities/profiles/constants';

import { useAxiosContext } from '../AxiosContext';
import { CountryBasedLegalEntities, useAddressesData, useLegalEntities } from './api';

type CategorizedWebTags = {
    [key in FilterID]: SelectOption[];
};

const filterIdOptionIdmap: Record<string, FilterID | 'hidden'> = {
    all: FilterID.technology_all,
    advertising: FilterID.technology_advertising,
    analytics: FilterID.technology_analytics,
    ats: FilterID.technology_ats,
    cms: FilterID.technology_cms,
    crm: FilterID.technology_crm,
    chat: FilterID.technology_customer_service,
    nameserver: FilterID.technology_domain_server,
    webstore: FilterID.technology_e_commerce,
    mailserver: FilterID.technology_email_server,
    marketing: FilterID.technology_marketing,
    payment: FilterID.technology_payment,
    reservation: FilterID.technology_reservation,
    server: FilterID.technology_server,
    some: FilterID.social_media_channels,
    other: FilterID.technology_web_tech,
    language: FilterID.website_language,
    hidden: 'hidden',
};

/**
 * This context can be expanded to store all the static values
 * i.e. the response of `filter.vainu.io/*`
 */
export type FilterOptionsContextValue = {
    webTags: CategorizedWebTags;
    VCIOptions: SelectOption[];
    locationTypeOptions: SelectOption[];
    countriesOptions: SelectOption[];
    companySizeIndicatorOptions: SelectOption[];
    selectListFilterOptions: SelectOption[];
    legalEntities: CountryBasedLegalEntities;
    countryAreaOptions: SelectOption[];
    municipalitiesOptions: SelectOption[];
    citiesOptions: SelectOption[];
    crmOptions: SelectOption[];
    auditorOptions: SelectOption[];
    recruitmentKeywordsOptions: string[];
};

export const FilterOptionsContext = createContext<FilterOptionsContextValue>({} as FilterOptionsContextValue);
const EMPTY_SELECT_OPTIONS: SelectOption[] = [];
const EMPTY_WEB_TAGS: WebTag[] = [];

const fetchWebTags = async (axios: AxiosInstance, database: Database | undefined) => {
    if (!database) {
        return;
    }
    let key: string = database;
    if (database === 'DOMAIN_DATA_BASIC') {
        key = 'global_data';
    }
    return (await getWebTags(axios, key)).data[key];
};

type FilterOptionsProviderProps = {
    children?: React.ReactNode;
};

export const FilterOptionsProvider: React.FC<FilterOptionsProviderProps> = memo(({ children }) => {
    const axios = useAxiosContext();
    const { dbLists, database } = useListsContext();
    const { data: webTags = EMPTY_WEB_TAGS } = useQuery({
        queryKey: [`webtags-${database}`],
        queryFn: () => fetchWebTags(axios, database),
        enabled: Boolean(database),
    });

    const { municipalitiesOptions, citiesOptions, countryAreaOptions } = useAddressesData(database);

    const locationTypeOptions = useMemo(
        () => (database === 'DOMAIN_DATA_BASIC' ? locationTypeGlobalOptions : locationTypeCountryOptions),
        [database],
    );

    const countriesOptions = useMemo(() => {
        return database === 'DOMAIN_DATA_BASIC' ? countriesGlobalOptions : countriesRegistryOptions;
    }, [database]);

    const { data: auditorOptions = EMPTY_SELECT_OPTIONS } = useQuery({
        queryKey: [`auditor-${database}`],
        queryFn: () => getAuditValues(axios, database),
        enabled: Boolean(database && database !== 'DOMAIN_DATA_BASIC'),
    });

    const { data: recruitmentKeywordsOptions = [] } = useQuery({
        queryKey: [`recruitment-keywords`],
        queryFn: () =>
            getRecruitmentKeywords(axios).then((keywords) =>
                uniq(getObjectKeys(keywords).flatMap((category) => keywords[category])).sort(),
            ),
    });

    const selectListFilterOptions = useMemo(() => {
        // DB lists are being mutated probably more than needed, needs to check
        return dbLists.filter((list) => isUploaded(list)).map((list) => ({ value: list.id, label: list.name }));
    }, [dbLists]);

    const { countryBasedLegalEntities } = useLegalEntities();

    const categorizedWebTags = useMemo(() => {
        const newFilterValues: { [key: string]: SelectOption[] } = initializeObjectWithArrayValues(
            FilterID.technology_all,
            FilterID.technology_advertising,
            FilterID.technology_analytics,
            FilterID.technology_ats,
            FilterID.technology_cms,
            FilterID.technology_crm,
            FilterID.technology_customer_service,
            FilterID.technology_domain_server,
            FilterID.technology_e_commerce,
            FilterID.technology_email_server,
            FilterID.technology_marketing,
            FilterID.technology_payment,
            FilterID.technology_reservation,
            FilterID.technology_server,
            FilterID.technology_web_tech,
            FilterID.website_language,
            FilterID.social_media_channels,
        );

        if (!!webTags.length) {
            for (const tag of webTags) {
                const type: string = tag[2];
                const category = filterIdOptionIdmap[type];

                if (category !== 'hidden') {
                    if (!newFilterValues.hasOwnProperty(category)) {
                        newFilterValues[category] = [];
                    }
                    const tempTag = { label: tag[1], value: tag[0] };
                    newFilterValues[category].push(tempTag);
                    if (category !== FilterID.website_language) {
                        newFilterValues[FilterID.technology_all].push(tempTag);
                    }
                }
            }
            for (const key of Object.keys(newFilterValues)) {
                newFilterValues[key].sort((a, b) => a.label.localeCompare(b.label));
            }
        }
        return newFilterValues as CategorizedWebTags;
    }, [webTags]);

    const value = useMemo(
        () => ({
            webTags: categorizedWebTags,
            VCIOptions,
            companySizeIndicatorOptions,
            countriesOptions,
            locationTypeOptions,
            selectListFilterOptions,
            legalEntities: countryBasedLegalEntities,
            countryAreaOptions,
            municipalitiesOptions,
            citiesOptions,
            crmOptions,
            auditorOptions,
            recruitmentKeywordsOptions,
        }),
        [
            auditorOptions,
            categorizedWebTags,
            citiesOptions,
            countriesOptions,
            countryAreaOptions,
            countryBasedLegalEntities,
            locationTypeOptions,
            municipalitiesOptions,
            selectListFilterOptions,
            recruitmentKeywordsOptions,
        ],
    );

    return <FilterOptionsContext.Provider value={value}>{children}</FilterOptionsContext.Provider>;
});

export const useFilterOptionsContext = () => useContext(FilterOptionsContext);

export default useFilterOptionsContext;
