import { useState } from 'react';

import { Box, FormControlLabel, useTheme } from '@mui/material';

import Button from 'components/tokens/Button';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';
import { useAuthContext } from 'contexts/AuthContext';
import { useAxiosContext } from 'contexts/AxiosContext';
import { getAccountIdFromToken } from 'contexts/utilities/authToken';

const TermsOfService = () => {
    const { authState, doRefresh } = useAuthContext();
    const theme = useTheme();
    const axios = useAxiosContext();
    const [checked, setChecked] = useState(false);

    const acceptTerms = async () => {
        const accountId = getAccountIdFromToken(authState?.access);
        await axios.post(`/mapi/account/${accountId}/accept_terms/`);
        await doRefresh();
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 5,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 2,
                    maxHeight: 580,
                    flex: 1,
                }}
            >
                <Icon type="VainuText" sx={{ width: 221, height: 80 }} />

                <Typography variant="h4" weight="semibold" color="dark">
                    Powerful company data at your fingertips
                </Typography>

                <span style={{ flex: 1 }} />

                <Typography
                    weight="semibold"
                    sx={{
                        marginTop: 2,
                    }}
                >
                    To get started,
                    <br /> please agree to our{' '}
                    <Link
                        href="https://www.vainu.com/legal-stuff/free-trial-agreement/"
                        color={theme.palette.terms.secondary}
                        toNewTab
                    >
                        Terms of Service
                    </Link>
                </Typography>

                <Box
                    sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3,
                        backgroundColor: 'terms.box',
                        borderRadius: '74px',
                        padding: 5,
                    }}
                >
                    <FormControlLabel
                        label={<Typography weight="semibold">I agree to the Terms of Service</Typography>}
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(_, checked) => setChecked(checked)}
                                color={theme.palette.terms.secondary}
                                size="medium"
                                inheritBorder
                            />
                        }
                    />

                    <Button
                        size="large"
                        disabled={!checked}
                        onClick={acceptTerms}
                        sx={{
                            backgroundColor: 'terms.primary',
                            textTransform: 'none',
                            fontSize: 26,
                            padding: '16px 60px',
                            borderRadius: '74px',
                            color: 'text.strong',
                            '&&:disabled': {
                                color: 'text.strong',
                                background: 'terms.tertiary',
                                opacity: 0.3,
                            },
                            '&&:hover': {
                                backgroundColor: 'terms.secondary',
                            },
                        }}
                        fullWidth
                    >
                        Get started
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default TermsOfService;
