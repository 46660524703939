import { Route, Routes } from 'react-router-dom';

import { CommonProviders } from './CommonProviders';
import { EmbeddedProfileSelector, EmbeddedTokenReceiver } from './embedded';
import { Platform } from './Platform';
import { MainPath } from './Platform/Router/routePaths';

export const SuperRouter = () => (
    <CommonProviders>
        <Routes>
            <Route path={`/${MainPath.EmbeddedView}/`} element={<EmbeddedProfileSelector />} />
            <Route path={`/${MainPath.EmbeddedToken}/`} element={<EmbeddedTokenReceiver />} />
            <Route path="*" element={<Platform />} />
        </Routes>
    </CommonProviders>
);

export default SuperRouter;
