import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Server: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 5.25C2 3.61396 3.47732 2.5 5 2.5H19C20.5227 2.5 22 3.61396 22 5.25V8.75C22 10.386 20.5227 11.5 19 11.5H5C3.47732 11.5 2 10.386 2 8.75V5.25ZM5 4.5C4.32268 4.5 4 4.96104 4 5.25V8.75C4 9.03896 4.32268 9.5 5 9.5H19C19.6773 9.5 20 9.03896 20 8.75V5.25C20 4.96104 19.6773 4.5 19 4.5H5ZM5.5 7C5.5 7.83 6.18 8.5 7 8.5C7.83 8.5 8.5 7.82 8.5 7C8.5 6.18 7.82 5.5 7 5.5C6.18 5.5 5.5 6.17 5.5 7ZM2 15.25C2 13.614 3.47732 12.5 5 12.5H19C20.5227 12.5 22 13.614 22 15.25V18.75C22 20.386 20.5227 21.5 19 21.5H5C3.47732 21.5 2 20.386 2 18.75V15.25ZM5 14.5C4.32268 14.5 4 14.961 4 15.25V18.75C4 19.039 4.32268 19.5 5 19.5H19C19.6773 19.5 20 19.039 20 18.75V15.25C20 14.961 19.6773 14.5 19 14.5H5ZM5.5 17C5.5 17.83 6.18 18.5 7 18.5C7.83 18.5 8.5 17.83 8.5 17C8.5 16.17 7.82 15.5 7 15.5C6.18 15.5 5.5 16.17 5.5 17Z"
        />
    </SvgIcon>
);

export default Server;
