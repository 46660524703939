import React, { memo, useMemo, useState } from 'react';

import { SxProps } from '@mui/system';
import { FieldValues, UseFieldArrayAppend, UseFormGetValues } from 'react-hook-form';

import NestedSelect, { ChangeValue } from 'components/tokens/select-components/NestedSelect';
import { getRootNode } from 'components/tokens/select-components/NestedSelect/utils';
import { useFilterState } from 'contexts/FilterContext';
import { Database, useListsContext } from 'contexts/ListsContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';

import { ContactsUpgradeButton } from '../Employees';
import { ContactsUpgradeDialog } from '../Employees/Table/ContactsUpgradeDialog';
import { filterDefinitions } from './filters/filterDefinitions';
import { FilterID, GroupIDValue, GroupValues, MappedGroup, NodeValue } from './FilterTypes';
import { getFilteredOptions } from './groups/common/groupUtils';
import { FilterGroupDefinition, filterGroupDefinitions } from './groups/groupDefinitions';

type Props = {
    sortedGroupIDS: GroupIDValue[];
    updateGroup: (id: string, group: NodeValue) => void;
    append: UseFieldArrayAppend<FieldValues, string>;
    mappedGroups: Map<string, MappedGroup>;
    getValues: UseFormGetValues<FieldValues>;
    disabled?: boolean;
    onOpen?: () => false | undefined;
};

interface GetFilterOptionsProps {
    sortedGroupIDS: GroupIDValue[];
    database: Database | undefined;
    productPermissions: string[];
    definitions: FilterGroupDefinition;
}

const CONTACTS_PROMO_FILTER = {
    label: 'Contacts',
    value: 'Contacts',
    endAdornment: <ContactsUpgradeButton />,
};

export function getFilterOptions({ sortedGroupIDS, database, productPermissions, definitions }: GetFilterOptionsProps) {
    const groupMappedOptions = new Map<GroupIDValue, FilterID>();

    const filterOptions = sortedGroupIDS.map((groupId) => {
        const group = definitions[groupId];

        const options = getFilteredOptions({ options: group.options ?? [], database, productPermissions });

        if (options.length === 1) {
            groupMappedOptions.set(groupId, options[0].value as FilterID);

            return {
                label: group.label,
                value: groupId,
            };
        }

        return {
            label: group.label,
            value: groupId,
            options: options,
        };
    });

    return { filterOptions, groupMappedOptions };
}

export const AddFiltersButton: React.FC<Props> = memo(({ sortedGroupIDS, disabled, onOpen }) => {
    const { productPermissions, hasProductPermission } = usePermissionContext();
    const { database } = useListsContext();

    const hasContactDataPermission = hasProductPermission(Permission.ContactData);

    const [isUpgradeDialogOpen, setUpgradeDialogOpen] = useState(false);

    const { filterOptions, groupMappedOptions } = useMemo(
        () =>
            getFilterOptions({
                sortedGroupIDS,
                productPermissions,
                database,
                definitions: filterGroupDefinitions,
            }),
        [sortedGroupIDS, productPermissions, database],
    );

    const { setActiveGroup, setActiveFilter, activeGroup } = useFilterState();

    const handleGroupChange = (value: ChangeValue) => {
        if (value.value === CONTACTS_PROMO_FILTER.value) {
            setUpgradeDialogOpen(true);
            return;
        }

        const groupName = getRootNode(value).value as GroupValues;
        if (!groupName) {
            return;
        }

        const filterId: FilterID = groupMappedOptions.get(groupName) ?? (value.value as FilterID);
        const definition = filterDefinitions[filterId];

        if (!definition) {
            return;
        }

        setActiveGroup(groupName);
        setActiveFilter(filterId);
    };

    const options = useMemo(
        () =>
            !hasContactDataPermission && database !== 'DOMAIN_DATA_BASIC'
                ? [...filterOptions, CONTACTS_PROMO_FILTER]
                : filterOptions,
        [hasContactDataPermission, database, filterOptions],
    );

    return (
        <>
            <NestedSelect
                forgetSelected
                placeholder="+"
                disabled={disabled}
                width={30}
                height={30}
                containerSx={{ gridArea: 'add' }}
                nestedOptions={options}
                openInitialDefaultSelectionOnMenuOpen={false}
                slimInactiveBorder
                onChange={handleGroupChange}
                selectSx={selectSx}
                inputProps={{
                    sx: {
                        width: 30,
                        height: 30,
                    },
                }}
                onOpen={onOpen}
                onClose={() => setActiveGroup(undefined)}
                aria-label="Add filter"
                forceOpen={activeGroup === 'plus-button'}
            />
            <ContactsUpgradeDialog open={isUpgradeDialogOpen} onClose={() => setUpgradeDialogOpen(false)} />
        </>
    );
});

const selectSx: SxProps = {
    '.MuiSelect-icon': {
        display: 'none',
    },
    '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
        display: 'flex',
        padding: '0px',
        paddingRight: 0,
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        height: 30,
    },

    borderRadius: 30,
};
