import { GroupStructureRoot } from 'api/types/groupStructure';
import { ProfileDisplayMode } from 'components/modules/profiles/Profile';

import { getCountries } from '../utils';
import GroupStructureSubtree from './GroupStructureSubtree';

export type GroupStructureTreeContentProps = {
    group_structure: GroupStructureRoot[];
    profileBusinessId?: string;
    mode: ProfileDisplayMode;
};

export const GroupStructureTreeContent: React.VFC<GroupStructureTreeContentProps> = ({
    group_structure,
    profileBusinessId,
    mode,
}) => {
    const uniqueCountries = getCountries(group_structure);
    const showFlags = uniqueCountries.length > 1;

    return (
        <GroupStructureSubtree
            company={group_structure[0]}
            showFlags={showFlags}
            profileBusinessId={profileBusinessId}
            mode={mode}
        />
    );
};

export default GroupStructureTreeContent;
