import BarTile from 'components/features/CRMInsights/Tiles/BarTile';
import { DEFAULT_DATA, defaultMinWidth } from 'components/features/CRMInsights/utils';
import Icon from 'components/tokens/Icon';

import { TileProps } from '../NewVehicles';
import { VehicleCategoryTop, vehicleCategoryTopLabels, vehicleTypeIcons } from '../vehicleTypes';

const CategoryTile: React.FC<TileProps> = ({ data, valueRange, valueField, onDetailsClick }) => {
    const categoryData = data?.find((row) => row.id === 'category');

    return (
        <BarTile
            data-testid="class-tile"
            title="Class"
            data={
                categoryData?.result[0].buckets
                    .filter((bucket) => bucket.value)
                    .map((bucket) => {
                        const category = bucket.value as VehicleCategoryTop;
                        return {
                            value: bucket[valueField],
                            label: vehicleCategoryTopLabels[category],
                            secondaryLabel: vehicleTypeIcons[category] && <Icon type={vehicleTypeIcons[category]} />,
                            valueLabel: valueRange ? `${bucket.count_min}-${bucket.count_max}` : bucket[valueField],
                            bucket,
                        };
                    }) || DEFAULT_DATA
            }
            total={(categoryData?.result[0].buckets[0]?.[valueField] as number) || 0}
            sx={{ minWidth: defaultMinWidth, maxWidth: 'max(600px, 50%)' }}
            firstColumn={{ label: 'Class', width: 100 }}
            lastColumn={{ label: 'Vehicles' }}
            hidePercent
            percentHeaderLabel=""
            // backend doesn't support filtering by category_top yet
            // onRowClick={(row) => {
            //     if (!row.bucket?.value) {
            //         return;
            //     }
            //     onDetailsClick({
            //         title: row.label || '',
            //         query: {
            //             '?ALL': [
            //                 {
            //                     '?EQ': {
            //                         category_top: row.bucket.value,
            //                     },
            //                 },
            //             ],
            //         } as Operation,
            //     });
            // }}
        />
    );
};

export default CategoryTile;
