import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import type { EInvoiceAddress } from 'api/types/company';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';

export type EInvoiceAddressesProps = {
    addresses: EInvoiceAddress[];
};

export const EInvoiceAddresses: React.VFC<EInvoiceAddressesProps> = ({ addresses }) => (
    <Frame title="E-invoice addresses">
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Operator address</TableCell>
                    <TableCell>Operator id</TableCell>
                    <TableCell>Operator</TableCell>
                    <TableCell>Receives</TableCell>
                    <TableCell>Sends</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {addresses.map(
                    ({ invoice_address_name, e_invoice_address, operator_id, operator_name, receive, send }, index) => (
                        <TableRow key={index}>
                            {/* index used as a key as the list only changes with total rerenders */}
                            <TableCell>{invoice_address_name}</TableCell>
                            <TableCell>{e_invoice_address}</TableCell>
                            <TableCell>{operator_id}</TableCell>
                            <TableCell>{operator_name}</TableCell>
                            <TableCell>
                                <Icon
                                    sx={{
                                        fontSize: 16,
                                        color: receive ? 'success.main' : 'error.main',
                                    }}
                                    type={receive ? 'CheckBig' : 'CloseBig'}
                                />
                            </TableCell>
                            <TableCell>
                                <Icon
                                    sx={{ fontSize: 16, color: send ? 'success.main' : 'error.main' }}
                                    type={send ? 'CheckBig' : 'CloseBig'}
                                />
                            </TableCell>
                        </TableRow>
                    ),
                )}
            </TableBody>
        </Table>
    </Frame>
);

export default EInvoiceAddresses;
