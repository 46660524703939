import React, { useState } from 'react';

import { Tooltip } from '@mui/material';

import Button, { ButtonProps } from 'components/tokens/Button';
import Dialog from 'components/tokens/Dialog';
import { useListsContext } from 'contexts/ListsContext';

type MigrateButtonProps = {
    label?: string;
    ButtonProps?: ButtonProps;
};

const MigrateButton: React.FC<MigrateButtonProps> = ({ label = 'Migrate', ButtonProps }) => {
    const { selectedList, migrateLegacyList } = useListsContext();

    const [isMigrating, setIsMigrating] = useState(false);
    const [showMigrationConfirm, setShowMigrationConfirm] = useState(false);

    const isOwner = selectedList?.privileges.current === 'owner';

    const migrate = async () => {
        if (!selectedList) {
            return;
        }
        setIsMigrating(true);
        try {
            await migrateLegacyList(selectedList);
        } catch (e) {}
        setIsMigrating(false);
    };

    return (
        <>
            <Tooltip title={!isOwner ? 'Only list owners can migrate lists' : ''}>
                <div>
                    <Button
                        size="small"
                        disabled={!isOwner || isMigrating}
                        onClick={async () => {
                            if (selectedList) {
                                if (selectedList.converted_query?.errors) {
                                    setShowMigrationConfirm(true);
                                } else {
                                    migrate();
                                }
                            }
                        }}
                        {...ButtonProps}
                    >
                        {label}
                    </Button>
                </div>
            </Tooltip>

            {showMigrationConfirm && (
                <Dialog
                    open
                    title="Some of the filters can't be migrated"
                    onClose={() => setShowMigrationConfirm(false)}
                    confirmLabel="Migrate"
                    onSubmit={() => {
                        migrate();
                        setShowMigrationConfirm(false);
                    }}
                >
                    Are you sure you want to migrate? Some filters will be lost.
                </Dialog>
            )}
        </>
    );
};

export default MigrateButton;
