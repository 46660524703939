import { uniq, get } from 'lodash';
import { assertArray } from 'utilities';

import { formatCity, getCountriesFromBusinessUnits, getHQ } from 'components/features/Locations/utils';
import { getShortTrafficRanking } from 'components/features/WebProfile/Header/utils';
import { NumberDataPointProps } from 'components/tokens/NumberDataPoint';
import { DomainDataResult, OrganizationResult } from 'contexts/types/FilterCompanies';

import { getCountryNameFromCode } from '../CompanyCard/utils';
import numericFields from './numericFields';

type QueryFilter = {
    queryField: string;
    value: string[];
};

export type FormattedPriorityField = {
    field: string;
} & NumberDataPointProps & { skeleton?: never };

const NUMERIC_FIELD_COUNT_MIN = 3;

const categories = ['industry_codes_local', 'form_of_company'];
const locationFields = ['visiting_city', 'visiting_postal', 'visiting_address', 'visiting_region', 'coordinates__geo'];
const defaultDisplayFields = ['staff_number', 'hq'];

export const fieldLabel: Record<string, string> = {
    turn_over: 'Revenue',
    profit: 'Profit',
    staff_number: 'Employees',
    staff_number_estimate: 'Employees(web)',
    development_of_turnover: 'Change of revenue',
    equity_to_assets_ratio: 'Equity ratio',
    facebook_engagement_rate: 'FB engagement rate',
    digitality: 'Digital index',
    sociality: 'SoMe index',
    building_type: 'Building type',
    energy_class: 'Energy class',
    building_year: 'Building year',
    building_apartment_count: 'Apartment count',
    facebook_posts_per_day: 'FB posts / day',
    avg_num_videos_per_month: 'Youtube avg videos per month',
    vehicles_count: 'Number of vehicles',
    facebook_likes: 'Facebook likes',
    twitter_followers_count: 'Twitter followers',
    facebook_ptat: 'FB PTAT',
    youtube_video_count: 'Youtube videos',
    youtube_subscriber_num: 'Youtube subscribers',
    youtube_view_count: 'Youtube view count',
    num_subscribers_per_1000_views: 'Youtube subscribers per 1000 views',
    youtube_comment_count: 'Youtube comments',
    career_page_job_count: 'Career page: Open vacancies (count)',
    // fields for table
    name: 'Company Name',
    location: 'Location',
    website: 'URL',
    founded: 'Founded',
    registration_date: 'Founded',
    hq: 'Headquarters',
    organization_size: 'Employees',
    operation_country: 'Countries of operations',
    website_ranking: 'Traffic ranking',
};

// ****************** Priority fields extraction section based on selected filter(s) ************************

/**
 * Return the prioritized query fields based on selected filters
 */
export function getPriorityFieldsFromQuery(query: '') {
    const filters = query
        .split('&') // remove empty strings
        .filter((filter) => filter)
        .map((filter) => {
            const split = filter.split('=');
            return {
                queryField: split[0],
                value: split[1].split(','),
            };
        })
        .filter((filter) => filter.queryField && filter.value);

    // basic fields are returned by default. Do we need to pass them as query option?
    const locationOrCategory = getLocationOrCategoryField(filters);
    const fields = getFields(filters);
    const queryFields = fields.concat(locationOrCategory);

    const countries = filters
        .filter((filter) => filter.queryField.includes('country__exact') && filter.value)
        .map((filter) => filter.value[0]);

    if (countries?.includes('US')) {
        queryFields.push('visiting_region');
    }

    if (countries?.includes('FI_realestate')) {
        queryFields.push('real_estate__building_year');
        queryFields.push('real_estate__building_type');
        queryFields.push('real_estate__building_apartment_count');
    }
    return { fields, queryFields };
}

/**
 * Fields extracted from query string
 */
function getFields(filters: QueryFilter[]) {
    let fields = ['turn_over', 'profit'];
    const defaultContent = [
        'staff_number,field_metadata__staff_number__lower_limit,field_metadata__staff_number__upper_limit',
    ];

    if (filters.some((queryFilter) => queryFilter.queryField.includes('vehicle'))) {
        fields.push('vehicles_count');
    }

    for (const numeric of numericFields) {
        // check if queryField matches even partially
        if (filters.find((queryFilter) => queryFilter.queryField.includes(numeric.field))) {
            fields.push(numeric.field);
        }
    }

    if (fields.length < NUMERIC_FIELD_COUNT_MIN) {
        fields = fields.concat(defaultContent);
    }

    fields = uniq(fields);
    fields = fields.slice(0, NUMERIC_FIELD_COUNT_MIN);

    return fields;
}

function getLocationOrCategoryField(filters: QueryFilter[]) {
    const locationFilter = filters.some((filter) => {
        return locationFields.some((location) => filter.queryField.includes(location));
    });

    if (locationFilter) {
        return 'visiting_city';
    }

    // if only one industry, selected show something else
    const singleFilter = categories.find((category) => !singleQueryItem(filters, category));

    return singleFilter ? singleFilter : categories[0];
}

function singleQueryItem(filters: QueryFilter[], field: string) {
    const matches = filters.filter((filter) => filter.queryField.includes(field));

    if (matches.length === 1) {
        return matches[0].value && matches[0].value.length === 1;
    }

    return false;
}

// *********************** Section to get fields to show either default or extracted from filters *****************************

/** Fields to display either passing priority fields or based on selected database or using default values   */
export function getPriorityFieldsToDisplay({
    formOfCompany,
    priorityFields,
    isRealEstateDb,
    isDomainDb,
    viewType = 'grid',
}: {
    formOfCompany?: string;
    priorityFields?: string[];
    isRealEstateDb?: boolean;
    isDomainDb?: boolean;
    viewType?: 'list' | 'grid';
}) {
    let display: string[] = [];

    const real_estate = formOfCompany === 'AOY';

    display = priorityFields || defaultDisplayFields;

    if (real_estate && isRealEstateDb) {
        display = ['real_estate__building_year', 'real_estate__building_type', 'real_estate__building_apartment_count'];
    } else if (isDomainDb) {
        display = ['organization_size', 'website_ranking', 'hq'];
    }

    if (viewType === 'list') {
        return display.filter((field) => {
            return field !== 'hq';
        });
    }

    return display;
}

// ********************************* Formatting section *********************************

/** Function to format priority fields (business ids).
 * Need to do make sure this works when business ids are available
 * */
export function formatPriorityFields(prospect: OrganizationResult, field: string): FormattedPriorityField {
    let value: number | string | undefined;
    const isParentGroup = prospect.level_in_group === 1;

    switch (field) {
        case 'hq': {
            const hq = getHQ(assertArray(prospect.business_units));
            const city = hq?.visiting_address?.city;
            const countryCode = hq?.visiting_address?.country;
            const countryName = getCountryNameFromCode(countryCode);
            const countryLabel = countryName.length > 8 ? countryCode : countryName;
            return {
                field,
                value: city && countryCode ? formatCity(city, countryCode) : countryLabel || '-',
                label: 'Headquarters',
                style: 'string',
            };
        }
        case 'turn_over':
            const revenue = getRevenue(prospect);

            return {
                field,
                value: revenue,
                label: isParentGroup ? 'Group revenue' : 'Revenue',
                currency: prospect?.financial_data?.currency || '',
                style: 'currency',
                useKr: true,
                isEstimation: false, // prospect.is_parent
                abbreviate: true,
            };
        case 'profit':
            value = getProfit(prospect) / 100;

            return { field, value, label: 'Profit', style: 'percent' };
        case 'staff_number':
        case 'staff_number,field_metadata__staff_number__lower_limit,field_metadata__staff_number__upper_limit':
            value = getStaffNumber(prospect);

            return {
                field,
                value,
                label: isParentGroup ? 'Group employees' : 'Employees',
                isEstimation: false,
                style: 'integer',
            };
        case 'development_of_turnover':
        case 'equity_to_assets_ratio':
        case 'real_estate__building_type':
        case 'real_estate__energy_class':
        case 'real_estate__building_year':
        case 'real_estate__building_apartment_count':
            let [val, label] = getValueLabel(field, prospect);

            return {
                field,
                value: val || 0,
                label: fieldLabel[label],
                style: label === 'building_year' ? 'date' : 'decimal',
            };
        // no rounding
        case 'facebook_posts_per_day':
        case 'avg_num_videos_per_month':
        case 'career_page_job_count':
            [val, label] = getValueLabel(field, prospect);

            return { field, value: val || 0, label: fieldLabel[label] };
        // case 'registration_date':
        //     return {
        //         field,
        //         value: prospect?.basic_data_plus?.employer_registration_date || 0,
        //         label: fieldLabel[field],
        //         style: 'date',
        //     };
        case 'organization_size':
            const { lowerLimit, upperLimit } = getOrganizationSize(prospect);
            return {
                field,
                value: [lowerLimit || 0, upperLimit],
                label: 'Employees',
                style: 'range',
                abbreviate: true,
            };
        case 'group_data':
            if (!prospect.group_data) return { field, value: 0, label: field };

            return {
                field,
                value: isParentGroup
                    ? String(prospect.group_data.subsidiary_count)
                    : prospect.group_data.parent_organization.name,
                label: isParentGroup ? 'Subsidiaries' : 'Parent',
                style: 'string',
            };
        default:
            return { field, value: 0, label: field };
    }
}

// ************** Utility functions for formatting fields (business ids) **************

function getValueLabel(field: string, prospect: OrganizationResult): [number | undefined, string] {
    const pathList = field.split('__');
    return [get(prospect, pathList), pathList[pathList.length - 1]];
}

export function getRevenue(prospect: OrganizationResult) {
    if (prospect.group_data && prospect.level_in_group === 1) {
        return prospect.group_data.financial_data.revenue.value || 0;
    }

    return prospect?.financial_data?.revenue || 0;
}

function getProfit(prospect: OrganizationResult) {
    return prospect?.financial_data?.profit || 0;
}

function getStaffNumber(prospect: OrganizationResult) {
    return prospect.level_in_group === 1 && prospect.group_data
        ? prospect.group_data.financial_data.employees.absolute_count || 0
        : prospect.financial_data?.employee_count || 0;
}

/*
function getFinnishVehiclesCountRange(vehicles_count: number, step: number) {
    if (vehicles_count < 6) {
        return { lowerValue: 1, upperValue: 5 };
    }

    const upperLimit = Math.round(vehicles_count / step) * step;

    if (upperLimit < vehicles_count) {
        return { lowerValue: upperLimit, upperValue: upperLimit + step };
    }
    return { lowerValue: upperLimit - step, upperValue: upperLimit };
}

function getFinnishVehiclesCount(fi_vehicles_count: number) {
    if (fi_vehicles_count <= 100) {
        return getFinnishVehiclesCountRange(fi_vehicles_count, 5);
    }
    if (fi_vehicles_count <= 1000) {
        return getFinnishVehiclesCountRange(fi_vehicles_count, 50);
    }
    if (fi_vehicles_count <= 10000) {
        return getFinnishVehiclesCountRange(fi_vehicles_count, 100);
    }
    return getFinnishVehiclesCountRange(fi_vehicles_count, 500);
}
*/

/**
 *
 *
 *
 * Format et. al based on domain
 */
export function formatDomainPriorityFields(domainCompany: DomainDataResult, field: string): FormattedPriorityField {
    switch (field) {
        case 'founded':
            return {
                field,
                value: domainCompany.founded || '',
                label: 'Founded',
                style: 'date',
                abbreviate: true,
            };
        case 'organization_size':
            const { lowerLimit, upperLimit } = getOrganizationSize(domainCompany);
            return {
                field,
                value: [lowerLimit || 0, upperLimit],
                label: 'Employees',
                style: 'range',
                abbreviate: true,
            };
        case 'hq': {
            const hqCountryCode = getHQ(assertArray(domainCompany.business_units))?.visiting_address?.country ?? '';
            const hqCountryName = getCountryNameFromCode(hqCountryCode) || '-';
            const label = 'Headquarters';
            const style = 'string';
            if (hqCountryName.length > 8) {
                return {
                    field,
                    value: hqCountryCode,
                    tooltip: hqCountryName,
                    label,
                    style,
                };
            }
            return {
                field,
                value: hqCountryName,
                label,
                style,
            };
        }
        case 'operation_country': {
            const countryCount = getCountriesFromBusinessUnits(domainCompany.business_units).length;
            return {
                field,
                value: countryCount,
                label: 'Countries',
                style: 'integer',
            };
        }
        case 'website_ranking': {
            const trafficClass = domainCompany.website_data?.website_traffic_data?.ranking
                ? getShortTrafficRanking(domainCompany.website_data.website_traffic_data.ranking)
                : '-';
            return {
                field,
                value: trafficClass,
                label: 'Traffic ranking',
                style: 'string',
            };
        }
        default:
            return { field, value: 0, label: field };
    }
}

function getOrganizationSize(company: DomainDataResult | OrganizationResult): {
    lowerLimit?: number;
    upperLimit?: number;
} {
    const indicator = company.organization_size_indicators?.find(
        (i) => i.indicator_type === 'company_size_indicator_model',
    );
    const lowerLimit = indicator?.value_range?.lower_limit;
    const upperLimit = indicator?.value_range?.upper_limit;

    return { lowerLimit, upperLimit };
}
