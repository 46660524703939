import React from 'react';

import { SendMessageQueueItemsAsyncProcess, UserAsyncProcess } from 'api/types/UserAsyncProcess';
import Icon from 'components/tokens/Icon';

import { AsyncProcessNotificationProps } from '../NotificationItem';
import NotificationItemContent from '../NotificationItemContent';

const getStateBasedContent = (notification: UserAsyncProcess) => {
    switch (notification.state) {
        case 'process':
            return {
                content: 'Trigger data export is in progress',
                date: notification.created,
                hoverText: 'Exporting trigger data',
            };
        case 'completed':
            return {
                content: 'Trigger data exporting has completed',
                date: notification.finished,
                hoverText: 'Trigger export complete',
            };

        default:
            return {
                content: 'Exporting trigger data failed',
                date: notification.finished,
                hoverText: 'Trigger export failed',
                error: true,
            };
    }
};
const TriggerManualSend: React.FC<AsyncProcessNotificationProps<SendMessageQueueItemsAsyncProcess>> = ({
    notification,
    handleMarkAsSeen,
}) => {
    const additionalData = getStateBasedContent(notification);

    if (!notification.state || !notification.meta_data) return null;

    return (
        <NotificationItemContent
            icon={<Icon type="Triggers" />}
            title={`"${notification.meta_data.trigger_name}"`}
            seen={notification.seen}
            state={notification.state}
            {...additionalData}
            handleMarkAsSeen={handleMarkAsSeen}
        />
    );
};

export default TriggerManualSend;
