import { FilterConfigInitValues, FilterID, FilterIDValues, OperatorValue } from './FilterTypes';

export const filtersConfig = {
    [FilterID.visiting_city]: {
        label: 'Visiting city',
    },
    [FilterID.location_type]: {
        fields: ['types'],
        label: 'Location type',
    },
    [FilterID.country]: {
        label: 'Country',
    },
    [FilterID.prospect_address_count]: {
        label: 'Location count (Total)',
    },
    [FilterID.country_count]: {
        label: 'Countries of operation',
    },
    [FilterID.address]: {
        label: 'Street Address',
    },
    [FilterID.postal]: {
        label: 'Postal Code',
    },
    [FilterID.municipality]: {
        label: 'Municipality',
    },
    [FilterID.country_area]: {
        label: 'Province',
    },
    [FilterID.coordinates]: {
        label: 'Map',
    },
    [FilterID.company_name]: {
        label: 'Company name',
    },
    [FilterID.legal_entity]: {
        label: 'Legal entity',
    },
    [FilterID.employer_registration_date]: {
        label: 'Employer registration',
    },
    [FilterID.company_registration_date]: {
        label: 'Registration',
    },
    [FilterID.foundation]: {
        label: 'Foundation',
    },
} as const;

type Config = typeof filtersConfig;

type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };
type ExcludePropertiesWithoutField<T> = {
    [KEY in keyof T]-?: T[KEY] extends { fields: readonly [string] } ? KEY : never;
};

type FilteredConfig = ExcludePropertiesWithoutField<Config>;
type KeysWithFields = FilteredConfig[keyof FilteredConfig];
type ConfigWithFieldsOnly = Pick<Config, KeysWithFields>;

type Temp = keyof Config;
type OtherConfig<T extends FilterIDValues> = Omit<
    { [key in Temp]: { label: string; fields: [`${T}`] } },
    `${KeysWithFields}`
>;
type MergedConfig<T extends FilterIDValues> = DeepWriteable<ConfigWithFieldsOnly> & OtherConfig<T>;

type GetFilterConfigResult<T extends keyof Config> = MergedConfig<T>[T] & { id: T };

export function getFilterConfig<T extends keyof Config>(id: T) {
    const config = filtersConfig[id];

    const fields = 'fields' in config ? config.fields : [id];
    const baseConfig = { id, ...config, fields } as GetFilterConfigResult<T>;

    type Field = GetFilterConfigResult<T>['fields'][0];

    const getInitValueConfigDefault = <TValues extends { [Key in Field]: unknown }>(
        defaultOperator: OperatorValue,
        value: TValues[Field],
    ): FilterConfigInitValues<TValues> => {
        return {
            defaultOperator,
            initValue: {
                [defaultOperator]: {
                    [fields[0]]: value,
                },
            },
        };
    };

    return { baseConfig, getInitValueConfigDefault };
}

export function getFilterLabel(id: string) {
    return filtersConfig[id as keyof Config]?.label || '';
}

export type TValuesType<C extends { fields: [string] }, T> = { [Key in C['fields'][0]]: T };
