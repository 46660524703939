import { useRef, useEffect } from 'react';

interface MutableRefObject<T> {
    current: T;
}

type ForwardedRef<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

export const useForwardedRef: <T>(ref: ForwardedRef<T>) => MutableRefObject<Element | null> = <T,>(
    ref: ForwardedRef<T>,
): MutableRefObject<Element | null> => {
    const innerRef = useRef(null);
    useEffect(() => {
        if (!ref) return;
        if (typeof ref === 'function') {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return innerRef;
};
