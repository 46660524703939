import { memo } from 'react';

import { useIntl } from 'react-intl';
import { isNotEmpty } from 'utilities';

import { TabContentGridSection } from 'components/modules/profiles/Profile/tabs';
import HeaderBox from 'components/templates/HeaderBox';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import CountryFlag from '../CountryFlag';
import { FlatAddress } from '../types';
import { getHQ, getHQAddress, getHQLocation } from '../utils';

export interface LocationsHeaderProps {
    countries?: string[];
    addresses: FlatAddress[];
    skeleton?: boolean;
}

export const LocationsHeader: React.VFC<LocationsHeaderProps> = memo(({ countries, addresses, skeleton = false }) => {
    const intl = useIntl();

    if (skeleton) {
        return (
            <TabContentGridSection columns={3}>
                <HeaderBox skeleton />
                <HeaderBox skeleton />
                <HeaderBox skeleton />
            </TabContentGridSection>
        );
    }

    const hq = getHQ(addresses);
    const hqLocation = hq ? getHQLocation(hq, intl) : undefined;
    const hqAddress = hq ? getHQAddress(hq) : undefined;

    return (
        <TabContentGridSection columns={3}>
            {hq && (
                <HeaderBox
                    icon={
                        <Typography variant="small" weight="bold">
                            HQ
                        </Typography>
                    }
                    title={hqLocation}
                    label={hqAddress}
                />
            )}
            <HeaderBox icon={<Icon type="Profiles" />} title="Offices" number={addresses.length} />

            {isNotEmpty(countries) && (
                <HeaderBox
                    icon={<Icon type="LocationOutline" />}
                    title="Countries with operations"
                    label={countries.map((country) => (
                        <CountryFlag key={country} country={country} />
                    ))}
                    number={countries.length}
                />
            )}
        </TabContentGridSection>
    );
});

export default LocationsHeader;
