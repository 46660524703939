import { FilterGroup, FilterGroupProps, GroupIDValue, GroupValues } from '../FilterTypes';
import { basicGroupDefinition } from './groups/BasicGroup';
import { boardOfDirectorsGroupDefinition } from './groups/BoardOfDirectorsGroup';
import { contactsGroupDefinition } from './groups/ContactsGroup';
import { crmGroupDefinition } from './groups/CRMGroup';
import { financialGroupDefinition } from './groups/FinancialGroup';
import { groupStructureGroupDefinition } from './groups/GroupStructureGroup';
import { industryGroupDefinition } from './groups/IndustryGroup/IndustryGroup';
import { listsGroupDefinition } from './groups/ListsGroup';
import { locationGroupDefinition } from './groups/LocationGroup/LocationGroup';
import { organizationSizeIndicatorsGroupDefinition } from './groups/OrganizationSizeIndicatorsGroup';
import { socialMediaGroupDefinition } from './groups/SocialMediaGroup';
import { UploadListGroup } from './groups/UploadListGroup/UploadListGroup';
import { vainuCustomIndustryGroupDefinition } from './groups/VainuCustomIndustryGroup/VainuCustomIndustryGroup';
import { webProfileGroupDefinition } from './groups/WebProfileGroup';

export type FilterGroupDefinition = Record<GroupIDValue, FilterGroup>;

export const filterGroupDefinitions: FilterGroupDefinition = {
    ...locationGroupDefinition,
    ...vainuCustomIndustryGroupDefinition,
    ...industryGroupDefinition,
    ...webProfileGroupDefinition,
    ...groupStructureGroupDefinition,
    ...organizationSizeIndicatorsGroupDefinition,
    ...socialMediaGroupDefinition,
    ...boardOfDirectorsGroupDefinition,
    [GroupValues.upload_list]: {
        group: GroupValues.upload_list,
        label: 'Upload a list',
        options: [],
        filterUUIDs: [],
        logic: {},
        render: (props: FilterGroupProps) => <UploadListGroup {...props} />,
    },
    ...listsGroupDefinition,
    ...basicGroupDefinition,
    ...crmGroupDefinition,
    ...financialGroupDefinition,
    ...contactsGroupDefinition,
};
