import React from 'react';

import { Box, Tooltip } from '@mui/material';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import { EventKeywordType, EventKeywords } from 'contexts/TriggerContext';

import TriggerFilterRow from './TriggerFilterRow';

type Props = {
    rows: EventKeywords[];
    setRows: React.Dispatch<React.SetStateAction<EventKeywords[]>>;
    label: string;
    placeholder: string;
    type: EventKeywordType;
    eventName: string;
};

const TriggerFilterKeywordGroup: React.FC<Props> = ({ rows, setRows, label, type, eventName, placeholder }) => {
    const onAddRow = () => {
        setRows((rows: EventKeywords[]) => [
            ...rows,
            {
                operator: FilterOperator.ANY,
                values: [],
                type,
            },
        ]);
    };

    return rows.length ? (
        <Box
            sx={{
                backgroundColor: 'grey.50',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1,
                alignSelf: 'stretch',
                borderRadius: 1,
                padding: 1,
            }}
        >
            {rows.map((eventRow, idx) => (
                <TriggerFilterRow
                    key={idx}
                    label={label}
                    placeholder={placeholder}
                    operator={eventRow.operator}
                    onOperatorChange={(newOperator) => {
                        setRows((rows) =>
                            rows.map((row, rIdx) => {
                                if (rIdx === idx) {
                                    return {
                                        ...row,
                                        operator: newOperator,
                                    };
                                }
                                return row;
                            }),
                        );
                    }}
                    values={eventRow.values}
                    onValuesChange={(newValues) => {
                        setRows((rows) =>
                            rows.map((row, rIdx) => {
                                if (rIdx === idx) {
                                    return {
                                        ...row,
                                        values: newValues,
                                    };
                                }
                                return row;
                            }),
                        );
                    }}
                    onRemove={() => {
                        setRows((rows) => rows.filter((row, rIdx) => rIdx !== idx));
                    }}
                />
            ))}

            <Button variant="flat" size="small" onClick={() => onAddRow()}>
                + {label}
            </Button>
        </Box>
    ) : (
        <Tooltip title={`Add keywords to narrow down the type of {${eventName}}`}>
            <Button variant="tertiary" size="small" onClick={() => onAddRow()}>
                + {label}
            </Button>
        </Tooltip>
    );
};

export default TriggerFilterKeywordGroup;
