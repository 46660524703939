import React from 'react';

import { Box } from '@mui/material';

import { TabContentGridSection } from 'components/modules/profiles/Profile/tabs';

import Frame from '../../tokens/Frame';
import PageGrid from './PageGrid';
import PageGridContentColumn from './PageGridContentColumn';
import PageGridSidebarColumn from './PageGridSidebarColumn';

export type CompanyProfilePageLayoutProps = {
    backgroundImage?: string;
    superheader?: React.ReactNode;
    header?: React.ReactNode;
    sidebar?: React.ReactNode;
    sidebarExtra?: React.ReactNode;
    tabs?: React.ReactNode;
    content?: React.ReactNode;
    crm?: React.ReactNode;
    mode?: 'page' | 'popup';
};

export const CompanyProfilePageLayout: React.VFC<CompanyProfilePageLayoutProps> = ({
    backgroundImage,
    superheader,
    header,
    sidebar,
    sidebarExtra,
    tabs,
    content,
    crm,
    mode = 'page',
}) => {
    return mode === 'popup' ? (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {superheader && (
                <Box
                    sx={{
                        backgroundColor: 'background.paper',
                        width: '100%',
                        height: 0,
                        paddingX: { xs: 3, md: 2, lg: 3 },
                        paddingTop: 3,
                        paddingBottom: 0,
                        marginBottom: { xs: 0, md: -3 },
                    }}
                >
                    {superheader}
                </Box>
            )}
            {(header || tabs) && (
                <Frame
                    elevation={1}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 0,
                        width: '100%',
                        boxShadow: '0px 1px 0px #e1e1e1',
                        padding: 0,
                    }}
                >
                    <PageGrid sx={{ paddingTop: 3 }}>
                        <PageGridSidebarColumn hiding />
                        <PageGridContentColumn innerSx={{ rowGap: 1 }}>
                            {header}
                            {tabs}
                        </PageGridContentColumn>
                    </PageGrid>
                </Frame>
            )}
            <PageGrid sx={{ padding: 5 }}>
                <PageGridSidebarColumn hiding>
                    <Box sx={{ display: 'grid', rowGap: 5 }}>
                        {sidebar}
                        <Box sx={{ display: { xs: 'none', profileSidebar: 'grid' }, gap: 3 }}>
                            {crm}
                            {sidebarExtra}
                        </Box>
                    </Box>
                </PageGridSidebarColumn>
                <PageGridContentColumn>
                    <Box sx={{ display: 'grid', rowGap: 5 }}>
                        {content}
                        <Box sx={{ display: { xs: 'block', profileSidebar: 'none' } }}>
                            <TabContentGridSection columns={2}>{sidebarExtra}</TabContentGridSection>
                        </Box>
                    </Box>
                </PageGridContentColumn>
            </PageGrid>
        </Box>
    ) : (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: 5,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: 152,
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    marginBottom: -4,
                }}
            />
            <PageGrid>
                <PageGridSidebarColumn>
                    <Box sx={{ display: 'grid', rowGap: 5 }}>
                        {sidebar}
                        <Box sx={{ display: { xs: 'none', profileSidebar: 'grid' }, gap: 3 }}>
                            {crm}
                            {sidebarExtra}
                        </Box>
                    </Box>
                </PageGridSidebarColumn>
                <PageGridContentColumn>
                    {tabs}
                    {content}
                    <Box sx={{ display: { xs: 'block', profileSidebar: 'none' } }}>
                        <TabContentGridSection columns={2}>{sidebarExtra}</TabContentGridSection>
                    </Box>
                </PageGridContentColumn>
            </PageGrid>
        </Box>
    );
};

export default CompanyProfilePageLayout;
