import { formatISO, sub } from 'date-fns';
import { getLegacyBusinessId, isNotEmpty } from 'utilities';

import { useGroupStructure } from 'api/groupStructure';
import {
    useLocations,
    useFinancialStatements,
    useEmployees,
    useRegisters,
    useWebProfileData,
    useSocialNetworks,
    useVehicleCount,
} from 'api/profileData';
import { useSignals } from 'api/signals';
import { organizationProfileType } from 'api/types/Organization';
import { isValidStatement } from 'components/features/Financials/types';
import { ProfileTab } from 'components/features/ProfileTabs';
import { useListsContext } from 'contexts/ListsContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';

import { organizationTabs as tabs, TabSlug } from '../tabs';

/** Makes queries for all the profiles tabs and determines which tabs contain data.
 * For caching to work, the queries should be same as on the actual tabs.
 */
const useVisibleTabs = (businessId: string, activeTab: TabSlug): ProfileTab[] => {
    const params = {
        key: businessId,
        type: organizationProfileType,
    } as const;

    const { database } = useListsContext();
    const { hasProductPermission } = usePermissionContext();
    const hasContactDataPermission = hasProductPermission(Permission.ContactData);
    const hasVehiclePermission = hasProductPermission(Permission.FeatureFlagVehiclesP2);

    const { data: locationsData, isLoading: isLoadingLocationsData } = useLocations(params);
    const { data: statementData, isLoading: isLoadingStatementData } = useFinancialStatements(params);
    const { data: employeesData, isLoading: isLoadingEmployeesData } = useEmployees(params, hasContactDataPermission);
    const { data: registersData, isLoading: isLoadingRegistersData } = useRegisters(params);
    const { data: webProfileData, isLoading: isLoadingWebProfileData } = useWebProfileData(params);
    const { data: socialNetworksData, isLoading: isLoadingSocialNetworksData } = useSocialNetworks(params);
    const { data: groupStructureData, isLoading: isLoadingGroupStructureData } = useGroupStructure(
        getLegacyBusinessId(params.key),
    );
    const { data: vehiclesData, isLoading: isLoadingVehiclesData } = useVehicleCount(
        { ...params, database },
        hasVehiclePermission,
    );
    const numSignals = 3;
    const signalAgeLimit = formatISO(sub(new Date(), { years: 2 }), { representation: 'date' });

    const { data: signalsNews, isLoading: isLoadingSignalsNews } = useSignals({
        ...params,
        signalType: 'news',
        afterDate: signalAgeLimit,
        pageSize: numSignals,
    });
    const { data: signalsDataChanges, isLoading: isLoadingSignalsDataChanges } = useSignals({
        ...params,
        signalType: 'data-changes',
        afterDate: signalAgeLimit,
        pageSize: numSignals,
    });

    return tabs
        .filter((tab) => {
            if (tab.name === activeTab) {
                return true;
            }
            switch (tab.name) {
                case TabSlug.Events:
                    return (
                        isLoadingSignalsNews ||
                        isLoadingSignalsDataChanges ||
                        isNotEmpty(signalsNews) ||
                        isNotEmpty(signalsDataChanges)
                    );
                case TabSlug.Locations:
                    return isLoadingLocationsData || isNotEmpty(locationsData?.business_units);
                case TabSlug.Financials:
                    return (
                        isLoadingStatementData ||
                        isNotEmpty(statementData?.financial_statements?.filter(isValidStatement))
                    );
                case TabSlug.Employees:
                    return isLoadingEmployeesData || isNotEmpty(employeesData?.contacts);
                case TabSlug.Registers:
                    return isLoadingRegistersData || isNotEmpty(registersData?.registers);
                case TabSlug.WebProfile:
                    return isLoadingWebProfileData || isNotEmpty(webProfileData?.technology_data);
                case TabSlug.SocialNetworks:
                    return isLoadingSocialNetworksData || isNotEmpty(socialNetworksData?.social_media);
                case TabSlug.GroupStructure:
                    return isLoadingGroupStructureData || isNotEmpty(groupStructureData);
                case TabSlug.Vehicles:
                    return isLoadingVehiclesData || vehiclesData?.vehicles_count;
                default:
                    return true;
            }
        })
        .map((tab) => {
            if (tab.name === activeTab) {
                return tab;
            }
            switch (tab.name) {
                case TabSlug.Events:
                    return {
                        ...tab,
                        skeleton: isLoadingSignalsNews || isLoadingSignalsDataChanges,
                    };
                case TabSlug.Locations:
                    return {
                        ...tab,
                        skeleton: isLoadingLocationsData,
                    };
                case TabSlug.Financials:
                    return {
                        ...tab,
                        skeleton: isLoadingStatementData,
                    };
                case TabSlug.Employees:
                    return {
                        ...tab,
                        skeleton: isLoadingEmployeesData,
                    };
                case TabSlug.Registers:
                    return {
                        ...tab,
                        skeleton: isLoadingRegistersData,
                    };
                case TabSlug.WebProfile:
                    return {
                        ...tab,
                        skeleton: isLoadingWebProfileData,
                    };
                case TabSlug.SocialNetworks:
                    return {
                        ...tab,
                        skeleton: isLoadingSocialNetworksData,
                    };
                case TabSlug.GroupStructure:
                    return {
                        ...tab,
                        skeleton: isLoadingGroupStructureData,
                    };
                case TabSlug.Vehicles:
                    return {
                        ...tab,
                        skeleton: isLoadingVehiclesData,
                    };
                default:
                    return tab;
            }
        });
};

export default useVisibleTabs;
