import React from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box } from '@mui/material';
import { countBy, groupBy, keys, map } from 'lodash';

import StyledTooltip from '../common/StyledTooltip';
import { CrmName, CRMObject, FieldMapping } from '../types/Crm';
import SelectIconTextRenderer from './SelectIconTextRenderer';

type DatamappingSummaryTooltipProps = {
    isTooltip: boolean;
    crm: CrmName;
    fieldMappingFromSelectedObjs: FieldMapping<CRMObject>[];
    selectMappedObject: (obj: CRMObject) => void;
    children: React.ReactNode;
};
type FieldMappedObjectType = { key: CRMObject; value: string[] }[];

const DatamappingSummaryTooltip: React.FC<DatamappingSummaryTooltipProps> = ({
    isTooltip,
    crm,
    fieldMappingFromSelectedObjs,
    selectMappedObject,
    children,
}) => {
    const groupedFieldMappingByObject = map(
        groupBy(fieldMappingFromSelectedObjs, (each) => each?.target_object),
        (value, key: CRMObject) => {
            const uniqueMappedFieldsList = keys(
                countBy(value, (each) => {
                    return each.target_field;
                }),
            );
            return {
                key,
                value: uniqueMappedFieldsList,
            };
        },
    ) as unknown as FieldMappedObjectType;

    const totalFieldsMapped = groupedFieldMappingByObject.reduce((acc, current) => {
        return acc + current.value.length;
    }, 0);

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                fontSize: '14px',
            }}
        >
            <b>{totalFieldsMapped}</b>&nbsp;data fields included:
            {isTooltip ? (
                <StyledTooltip
                    componentsProps={{
                        tooltip: {
                            sx: {
                                fontSize: '12px',
                                backgroundColor: 'common.white',
                                padding: '8px',
                                boxShadow: '0px 1px 2px rgba(10, 10, 10, 0.1), 0px 4px 12px rgba(10, 10, 10, 0.15)',
                                borderRadius: '8px',
                                border: '1px solid',
                                borderColor: 'grey.50',
                            },
                        },
                        arrow: {
                            sx: {
                                color: 'common.white',
                            },
                        },
                    }}
                    arrow
                    placement="top"
                    title={
                        <TooltipComponent
                            crm={crm}
                            mappedObjects={groupedFieldMappingByObject}
                            selectMappedObject={(obj) => selectMappedObject(obj)}
                        />
                    }
                >
                    <HelpOutlineIcon
                        fontSize="small"
                        sx={{ color: 'icon.subtle', cursor: 'pointer', margin: '1px 0px 0px 4px' }}
                    />
                </StyledTooltip>
            ) : (
                children
            )}
        </Box>
    );
};
export default DatamappingSummaryTooltip;

const TooltipComponent: React.FC<{
    crm: CrmName;
    mappedObjects: FieldMappedObjectType;
    selectMappedObject: (obj: CRMObject) => void;
}> = ({ crm, mappedObjects = [], selectMappedObject }) => {
    return (
        <Box sx={{ padding: '15px 12px', color: 'text.primary' }}>
            <Box component="p" sx={{ fontSize: '16px' }}>
                Mapped fields:
            </Box>
            {mappedObjects.map(({ key, value }) => {
                return (
                    <Box
                        onClick={() => selectMappedObject(key)}
                        sx={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            textDecoration: 'underline',
                            textDecorationSkipInk: 'none',
                        }}
                    >
                        <SelectIconTextRenderer
                            object={key}
                            crm={crm}
                            text={
                                <span style={{ textTransform: 'capitalize' }}>
                                    {key} (<b>{value?.length}</b>)
                                </span>
                            }
                            style={{ marginRight: 0 }}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};
