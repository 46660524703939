import { useCallback, useEffect, useRef } from 'react';

import { Route, Routes } from 'react-router';

import Login from 'components/beta/Login';
import SsoHandler from 'components/beta/SsoHandler/SsoHandler';
import { ImpersonateHeader, useShowImpersonateHeader } from 'components/features/Impersonate';
import TermsOfService from 'components/features/TermsOfService';
import AppLayout from 'components/layout/AppLayout';
import { useAuthContext } from 'contexts/AuthContext';
import { getUserEmailFromToken } from 'contexts/utilities/authToken';
import { useIntercomUser } from 'hooks/useIntercomUser';
import { usePlanhatOwnersData } from 'utilities/planhatOwnersData';

import { DataProviders } from '../DataProviders';
import Header from '../Header';
import Navigation from '../Navigation';
import Router from '../Router';
import { MainPath } from '../Router/routePaths';
import { SoftBlock } from './PlatformBlock';

import 'src/intercom.js';

const HUBSPOT_CHATBOX_SRC = '//js.hs-scripts.com/1797693.js';

const Core = () => {
    const { authStatus, authState, impersonateAuthState } = useAuthContext();
    const accountType = useIntercomUser(authStatus === 'logged-in' && !impersonateAuthState);
    const scrollRef = useRef<HTMLDivElement>(null);
    usePlanhatOwnersData();

    const embedHubspotChat = (email: string) => {
        // Hubspot user tracking
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push(['identify', { email }]);

        // Create script component
        const script = document.createElement('script');
        script.src = HUBSPOT_CHATBOX_SRC;
        script.async = true;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
            window._hsq = [];
        };
    };

    useEffect(() => {
        if (authStatus === 'logged-in' && authState?.access) {
            const email = getUserEmailFromToken(authState.access);
            if (email) {
                return embedHubspotChat(email);
            }
        }
    }, [authStatus, authState]);

    const showImpersonateHeader = useShowImpersonateHeader();

    const getComponent = useCallback(() => {
        if (authStatus === 'logged-in') {
            return (
                <DataProviders>
                    <AppLayout
                        scrollRef={scrollRef}
                        superheader={showImpersonateHeader && <ImpersonateHeader />}
                        navigation={<Navigation />}
                        header={<Header />}
                    >
                        {accountType === 'LEGACY_CUSTOMER' ? <SoftBlock /> : <Router scrollRef={scrollRef} />}
                    </AppLayout>
                </DataProviders>
            );
        }
        if (authStatus === 'accept-terms') {
            return <TermsOfService />;
        }
        if (authStatus !== 'initial') {
            return <Login />;
        }
        return null;
    }, [authStatus, showImpersonateHeader, accountType]);

    return (
        <Routes>
            <Route path={`/${MainPath.SSO}/:provider/`} element={<SsoHandler />} />
            <Route path="*" element={getComponent()} />
        </Routes>
    );
};

export default Core;
