export const Fallback = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',

            width: '100%',
            height: '100%',
            padding: 40,
            fontFamily: 'Roboto, sans-serif',
        }}
    >
        <h3 style={{ marginBottom: 20 }}>Error! My only weakness!</h3>
        <p style={{ marginBottom: 20 }}>
            It seems you’ve found a bug in our system. We are very sorry for this occurrence. Good news is that we’ve
            logged the error and are onto fixing it.
        </p>
        {/* eslint-disable */}
        <p style={{ marginBottom: 20 }}>
            {'Meanwhile, you could try '}
            <a href="#" onClick={() => window.location.reload()}>
                refreshing the page
            </a>
            {' or '}
            <a target="_blank" href="https://help.vainu.app/">
                contacting the support
            </a>
            .
        </p>
    </div>
);
