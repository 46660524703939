import React, { useEffect, useState } from 'react';

import { ClickAwayListener } from '@mui/base';
import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Popper from '@mui/material/Popper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { produce } from 'immer';
import { anchorRef, bindPopper, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { useTrigger, ActionType, DeliveryTime, DeliveryType } from 'contexts/TriggerContext';
import { brandPalette } from 'design/theme/colors';

import TriggerButton from './TriggerButton';

function getTimeZoneOffsetString() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const activeChipStyle = {
    fontSize: 14,
    fontWeight: 500,
    color: brandPalette.white,
    backgroundColor: brandPalette.onyx,
    border: `1px solid ${brandPalette.onyx}`,
    borderRadius: '19px',
    marginLeft: '3px',
    '&:focus': {
        backgroundColor: `${brandPalette.onyx} !important`,
    },
    '&:checked': {
        backgroundColor: `${brandPalette.onyx} !important`,
    },
    '&:hover': {
        backgroundColor: `${brandPalette.onyx} !important`,
    },
};

const chipStyle = {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: '#F2F2F2',
    border: '1px solid #E0E0E0',
    borderRadius: '19px',
    marginLeft: '3px',
};

function formatTimeNumberToString(timeInteger: number): string {
    const s = timeInteger.toString();
    if (s.length === 1) {
        return `0${s}`;
    }
    return s;
}

const initialDeliveryTime = {
    week_days: [],
    start_hour: 8,
    end_hour: 16,
    start_min: 0,
    end_min: 0,
    timezone_name: getTimeZoneOffsetString(),
    timezone_offset: '',
};

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const SelectDeliveryTimePopper: React.FC = () => {
    const popupState = usePopupState({ variant: 'popper', popupId: 'select-delivery-time-popper' });
    const [state, dispatch, { activePopup, setActivePopup }] = useTrigger();
    const [showErrorSnackbarMessage, setShowErrorSnackbarMessage] = useState('');
    const { deliveryTime = initialDeliveryTime, notify = null as DeliveryType } = state;
    const [editedDeliveryTime, setEditedDeliveryTime] = useState<DeliveryTime>(deliveryTime || initialDeliveryTime);
    const [editedNotifyValue, setEditedNotifyValue] = useState<DeliveryType>(notify);
    const anchor = anchorRef(popupState);

    const {
        week_days: weekDays,
        start_hour: startHour,
        start_min: startMin,
        end_hour: endHour,
        end_min: endMin,
    } = editedDeliveryTime;

    useEffect(() => {
        if (popupState.isOpen) {
            setActivePopup(popupState.popupId);
        }
    }, [popupState, setActivePopup]);

    useEffect(() => {
        if (activePopup && popupState.popupId !== activePopup && popupState.isOpen) {
            popupState.close();
        }
        if (activePopup && popupState.popupId === activePopup && !popupState.isOpen) {
            popupState.open();
        }
    }, [activePopup, popupState]);

    useEffect(() => {
        if (deliveryTime) {
            setEditedDeliveryTime(deliveryTime);
        }
        setEditedNotifyValue(notify);
    }, [deliveryTime, notify]);

    const startTimeString = `${formatTimeNumberToString(startHour)}:${formatTimeNumberToString(startMin)}`;
    const endTimeString = `${formatTimeNumberToString(endHour)}:${formatTimeNumberToString(endMin)}`;

    const changeType = (type: DeliveryType) => {
        setEditedNotifyValue(type);
    };

    const toggleWeekDays = (value: number) => {
        setEditedDeliveryTime(
            produce(editedDeliveryTime, (draft) => {
                if (draft.week_days.includes(value)) {
                    draft.week_days = draft.week_days.filter((i) => i !== value);
                } else {
                    draft.week_days.push(value);
                }
            }),
        );
    };

    const setStartTime = (timeString: string) => {
        const [hour, minutes] = timeString.split(':');
        setEditedDeliveryTime(
            produce(editedDeliveryTime, (draft) => {
                draft.start_hour = parseInt(hour, 10);
                draft.start_min = parseInt(minutes, 10);
            }),
        );
    };

    const setStartAndEndTime = (timeString: string) => {
        const [hour, minutes] = timeString.split(':');
        setEditedDeliveryTime(
            produce(editedDeliveryTime, (draft) => {
                draft.start_hour = parseInt(hour, 10);
                draft.start_min = parseInt(minutes, 10);
                draft.end_hour = parseInt(hour, 10);
                draft.end_min = parseInt(minutes, 10);
            }),
        );
    };

    const setEndTime = (timeString: string) => {
        const [hour, minutes] = timeString.split(':');
        setEditedDeliveryTime(
            produce(editedDeliveryTime, (draft) => {
                draft.end_hour = parseInt(hour, 10);
                draft.end_min = parseInt(minutes, 10);
            }),
        );
    };

    const getChipStyle = (weekDayNumber: number) => {
        return weekDays.includes(weekDayNumber) ? activeChipStyle : chipStyle;
    };

    const onCancel = () => {
        if (deliveryTime) {
            setEditedDeliveryTime(deliveryTime);
        }
        setActivePopup(undefined);
        popupState.close();
    };

    const onSave = () => {
        const { week_days } = editedDeliveryTime;
        if (['specific', 'office_hours'].includes(editedNotifyValue || '') && !week_days.length) {
            return setShowErrorSnackbarMessage('Select delivery days');
        }
        dispatch({ type: ActionType.EDIT_DELIVERY_TIME, deliveryTime: editedDeliveryTime, notify: editedNotifyValue });
        setActivePopup(undefined);
        return popupState.close();
    };

    const getDeliveryFieldText = () => {
        /*
            requires string generation from values
        */
        if (editedNotifyValue === 'immediately') {
            return 'Send immediately';
        }
        if (editedNotifyValue === 'office_hours') {
            return 'Office hours';
        }
        if (editedNotifyValue === 'specific') {
            return `At ${startTimeString}`;
        }
        if (editedNotifyValue === 'none') {
            return 'Manual';
        }
        return 'Schedule';
    };
    const deliveryFieldText = getDeliveryFieldText();

    return (
        <div id="trigger-schedule" ref={anchor}>
            <TriggerButton {...bindToggle(popupState)} startIcon={<Icon type="Schedule" />}>
                {deliveryFieldText}
            </TriggerButton>
            <Popper
                {...bindPopper(popupState)}
                placement="bottom-start"
                transition
                popperOptions={{
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: true,
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 4],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={onSave}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Card sx={{ width: 500, display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ padding: 2, paddingTop: 3, overflowY: 'auto', flex: 1 }}>
                                    <Grid container direction="column">
                                        <RadioGroup
                                            value={editedNotifyValue}
                                            onChange={(e) => changeType(e.target.value as DeliveryType)}
                                        >
                                            {/*<FormControlLabel
                                            value="immediately"
                                            control={<Radio color="default" className={classes.radio} />}
                                            classes={{ label: classes.radioLabel }}
                                            disabled
                                            label={intl.formatMessage({
                                                defaultMessage: 'When the event occurs',
                                            })}
                                        />*/}
                                            <FormControlLabel
                                                value="specific"
                                                control={<StyledRadio color="default" />}
                                                sx={{
                                                    fontSize: 14,
                                                    lineHeight: '16px',
                                                    color: '#4F4F4F',
                                                    padding: '3px 0px 3px 13px',
                                                    fontStyle: 'normal',
                                                }}
                                                label="At a specified time"
                                            />
                                            {editedNotifyValue === 'specific' ? (
                                                <>
                                                    <Grid container>
                                                        {days.map((day, idx) => (
                                                            <Chip
                                                                key={idx}
                                                                color="default"
                                                                sx={getChipStyle(idx)}
                                                                label={day}
                                                                variant="outlined"
                                                                clickable
                                                                onClick={() => {
                                                                    toggleWeekDays(idx);
                                                                }}
                                                            />
                                                        ))}
                                                    </Grid>
                                                    <Grid container sx={{ paddingTop: '10px', paddingLeft: '40px' }}>
                                                        <TextField
                                                            id="time"
                                                            label="time"
                                                            type="time"
                                                            data-testid="time-input"
                                                            value={startTimeString}
                                                            onChange={(e) => {
                                                                const timeString = e.target.value;
                                                                setStartAndEndTime(timeString);
                                                            }}
                                                            sx={{ width: 140 }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                step: 3600,
                                                                type: 'time',
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                            <FormControlLabel
                                                value="office_hours"
                                                control={<StyledRadio color="default" />}
                                                sx={{
                                                    fontSize: 14,
                                                    lineHeight: '16px',
                                                    color: '#4F4F4F',
                                                    padding: '3px 0px 3px 13px',
                                                    fontStyle: 'normal',
                                                }}
                                                label="During work hours"
                                            />
                                            {editedNotifyValue === 'office_hours' ? (
                                                <>
                                                    <Grid container>
                                                        {days.map((day, idx) => (
                                                            <Chip
                                                                key={idx}
                                                                color="default"
                                                                sx={getChipStyle(idx)}
                                                                label={day}
                                                                variant="outlined"
                                                                clickable
                                                                onClick={() => {
                                                                    toggleWeekDays(idx);
                                                                }}
                                                            />
                                                        ))}
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        sx={{ paddingTop: '10px', paddingLeft: 0 }}
                                                        justifyContent="space-around"
                                                    >
                                                        <TextField
                                                            id="time"
                                                            value={startTimeString}
                                                            label="from"
                                                            type="time"
                                                            onChange={(e) => {
                                                                const timeString = e.target.value;
                                                                setStartTime(timeString);
                                                            }}
                                                            sx={{ width: 140 }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                step: 300, // 5 min
                                                            }}
                                                        />
                                                        <TextField
                                                            id="time"
                                                            value={endTimeString}
                                                            label="to"
                                                            type="time"
                                                            onChange={(e) => {
                                                                const timeString = e.target.value;
                                                                setEndTime(timeString);
                                                            }}
                                                            sx={{ width: 140 }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                step: 300, // 5 min
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                            <FormControlLabel
                                                value="none"
                                                control={<StyledRadio color="default" />}
                                                sx={{
                                                    fontSize: 14,
                                                    lineHeight: '16px',
                                                    color: '#4F4F4F',
                                                    padding: '3px 0px 3px 13px',
                                                    fontStyle: 'normal',
                                                }}
                                                label="Don’t send automatically"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ padding: 2 }}>
                                    <Grid container justifyContent="flex-end" spacing={1}>
                                        <Grid item>
                                            <Button variant="flat" onClick={() => onCancel()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => onSave()}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    open={!!showErrorSnackbarMessage}
                                    autoHideDuration={3000}
                                    onClose={() => setShowErrorSnackbarMessage('')}
                                    message={<span>{showErrorSnackbarMessage}</span>}
                                />
                            </Card>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </div>
    );
};

export default SelectDeliveryTimePopper;

const StyledRadio = styled(Radio)({
    padding: '16px',
    color: brandPalette.onyx,
    '&:checked': {
        color: brandPalette.onyx,
    },
});
