import { Point } from '../types';

// Calculates the cross product of (p2, p3) and (p2, p1). Used to determine if the points make a clockwise turn.
const cross = (p1: Point, p2: Point, p3: Point) => (p1.x - p3.x) * (p2.y - p3.y) - (p1.y - p3.y) * (p2.x - p3.x);

// Calculates the upper or lower hull of the points
const getHalfHull = (points: Readonly<Point[]>): Point[] => {
    const hull: Point[] = [];
    points.forEach((point) => {
        while (hull.length >= 2 && cross(hull[hull.length - 2], hull[hull.length - 1], point) <= 0) {
            hull.pop();
        }
        hull.push(point);
    });
    hull.pop();
    return hull;
};

export const sortPointsFn = (a: Point, b: Point) => (a.x === b.x ? a.y - b.y : a.x - b.x);

/**
 * Determines a convex hull of the set of points, using Andrew's monotone chain algorithm.
 */
export function makeConvexHull(points: Readonly<Point[]>): Point[] {
    if (points.length <= 1) {
        // This is just a crude trick to draw an almost-a-circle around one point.
        return [points[0], { x: points[0].x + 0.01, y: points[0].y }];
    }

    if (points.length === 2) {
        return [points[0], points[1]];
    }

    const sortedPoints = points.slice().sort(sortPointsFn);

    const upperHull = getHalfHull(sortedPoints);
    const lowerHull = getHalfHull(sortedPoints.reverse());
    if (
        upperHull.length === 1 &&
        lowerHull.length === 1 &&
        upperHull[0].x === lowerHull[0].x &&
        upperHull[0].y === lowerHull[0].y
    ) {
        return upperHull;
    }
    return [...upperHull, ...lowerHull];
}
