import { useContext } from 'react';

import { Dispatch, TriggerContextValue, TriggerDispatchContext, TriggerStateContext } from './TriggerContext';
import { State } from './TriggerContextTypes';

export * from './TriggerContextTypes';
export * from './triggerUtils';
export { TriggerProvider } from './TriggerContext';
export function useTriggerState() {
    const context = useContext(TriggerStateContext);
    if (context === undefined) {
        throw new Error('useTriggerState must be used within a TriggerProvider');
    }
    return context;
}
export function useTriggerDispatch() {
    const context = useContext(TriggerDispatchContext);
    if (context === undefined) {
        throw new Error('useTriggerDispatch must be used within a TriggerProvider');
    }
    return context;
}

export function useTrigger(): [State, Dispatch, TriggerContextValue] {
    const state = useTriggerState();
    return [state.old, useTriggerDispatch(), state.new];
}
