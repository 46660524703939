import { FC } from 'react';

import { useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { Bar, BarChart as RechartsBarChart, Tooltip, YAxis } from 'recharts';

import ChartTooltip, { formatTooltipValue } from 'components/templates/charts/ChartTooltip';
import ResponsiveContainer from 'components/templates/charts/utils/ResponsiveContainer';

import { HistogramData } from '../../types';

export interface HistogramProps {
    data: HistogramData | undefined;
    withTooltip?: boolean;
}

export const Histogram: FC<HistogramProps> = ({ data, withTooltip }) => {
    const theme = useTheme();
    const intl = useIntl();

    const defaultColor = theme.palette.grey[200];

    const numberStyle = 'decimal';

    const xLabelKey = 'label';

    if (data?.length === 0) {
        return <div style={{ height: '46px' }} />;
    }

    return (
        <ResponsiveContainer debounce={300} height={46} id="ResponsiveContainer">
            <RechartsBarChart data={data} width={400} height={400} margin={{ left: 4, top: 12, right: 0, bottom: 0 }}>
                <Bar
                    type="linear"
                    dataKey="value"
                    barSize={20}
                    fill={defaultColor}
                    stroke={defaultColor}
                    isAnimationActive={false}
                />
                <YAxis scale="sqrt" hide />
                {withTooltip && (
                    <Tooltip
                        cursor={false}
                        content={<ChartTooltip labelKey={xLabelKey} />}
                        formatter={formatTooltipValue(numberStyle, undefined, 0, intl)}
                        isAnimationActive={false}
                    />
                )}
            </RechartsBarChart>
        </ResponsiveContainer>
    );
};
