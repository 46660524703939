import React from 'react';

import { styled } from '@mui/material/styles';

import Icon from 'components/tokens/Icon';

export interface SourceToDestinationProps {
    target: string;
    source?: string;
}

export function getTargetSvg(target: String, props = {}) {
    switch (target?.toLowerCase()) {
        case 'pipedrive':
            return <Icon type="Pipedrive" {...props} />;
        case 'hubspot':
            return <Icon type="HubSpot" {...props} />;
        case 'dynamics':
            return <Icon type="Dynamics" {...props} />;
        case 'salesforcesandbox':
            return <Icon type="Salesforce" {...props} />;
        case 'salesforce':
            return <Icon type="Salesforce" {...props} />;
        case 'slack':
            return <Icon type="Slack" />;
        case 'email':
            return <Icon type="Gmail" />;
        case 'vainu':
            return <Icon type="Vainu" />;
        case 'trigger':
            return <Icon type="Triggers" />;
        default:
            return <Icon type="Vainu" />;
    }
}

const SourceToDestination: React.FC<SourceToDestinationProps> = ({ target, source = 'vainu' }) => {
    const targetSVG = getTargetSvg(target);
    const sourceSVG = getTargetSvg(source);
    return (
        <StyledContainer>
            <StyledCircle>{sourceSVG}</StyledCircle>
            <Icon type="ChevronRight" />
            <StyledCircle>{targetSVG}</StyledCircle>
        </StyledContainer>
    );
};

export default SourceToDestination;

const StyledContainer = styled('div')`
    display: flex;
    width: 130px;
    height: 52px;
    align-items: center;
    justify-content: space-between;
`;

const StyledCircle = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    border-style: solid;
    border-width: 3px;
    border: 1px solid rgba(47, 128, 237, 0.1);
    border-radius: 81px;
`;
