import { useRef } from 'react';

import CompanyProfilePopup from 'components/layout/CompanyProfilePopup';
import { ProfileSelector } from 'components/modules/profiles/Profile';

import useCompanyProfileContext from './contexts/CompanyProfileContext';

export const CompanyProfilePopupWrapper = () => {
    const { id, type, setProfile, initialData } = useCompanyProfileContext();
    const scrollRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        setProfile({ id: undefined, type: undefined });
    };

    return id && type ? (
        <CompanyProfilePopup open scrollRef={scrollRef} onClose={handleClose}>
            <ProfileSelector id={id} type={type} scrollRef={scrollRef} initialData={initialData} />
        </CompanyProfilePopup>
    ) : null;
};
