import React, { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { Trigger } from 'api/types/Trigger';
import { CRMNameAPIResponse, IntegrationSetting } from 'components/modules/connectors/types/Crm';
import SourceToDestination from 'components/templates/SourceToDestination';
import Button from 'components/tokens/Button';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import { Typography } from 'components/tokens/Typography';
import { useListsContext } from 'contexts/ListsContext';
import { usePermissionContext } from 'contexts/PermissionContext';
import { ActionType, useTrigger, Destination } from 'contexts/TriggerContext';
// useInitNewTrigger.test.tsx is failing
// when import getDefaultNewTrigger from TriggerContext by some reason, needs investigation
import { getDefaultNewTrigger } from 'contexts/TriggerContext/triggerUtils';
import { stateDestinationToDBdestination } from 'utilities/triggers/mapStateToTrigger';

import { CRMDestinationType, EmailDestinationType, SlackDestinationType } from './TriggerDestination';
import { getDestinationLabel } from './TriggerSettings/SelectDestinationPopper';

type ExampleID = 'newCeo' | 'newFinancialStatement' | 'newCompanyInTargetGroup';
type TargetProp = 'crm' | 'email' | 'slack';
type TargetSystem = 'email' | 'slack' | CRMNameAPIResponse;

interface Props {
    exampleID: ExampleID;
    target: TargetProp;
}

const emailDestination: EmailDestinationType = {
    system: 'email',
    emails: [],
    active: true,
};

const slackDestination: SlackDestinationType = {
    system: 'slack',
    slackChannel: [],
    active: true,
};

const getDestination = (targetSystem: string, email: string): Destination => {
    if (targetSystem === 'email') {
        return { ...emailDestination, emails: email ? [email] : [] };
    }
    if (targetSystem === 'slack') {
        return slackDestination;
    }
    return {
        system: targetSystem?.toLowerCase(),
        crm_objects: [],
        active: true,
    } as CRMDestinationType;
};

const TriggerExample: React.FC<Props> = ({ exampleID, target }) => {
    const { dbLists } = useListsContext();
    const [, dispatch, { activeCRMIntegrationSettings, setActivePopup }] = useTrigger();
    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const [selectedList, setSelectedList] = useState<SelectOption | null>(null);
    const { integrations, profile } = usePermissionContext();
    let targetSystem: TargetSystem;
    const crmSupported = isCrmSupported(integrations);
    const slackSupported = isSlackSupported(integrations);
    if (target === 'slack' && !slackSupported) {
        targetSystem = 'email';
    } else if (target === 'crm') {
        if (!crmSupported) {
            targetSystem = 'email';
        } else {
            targetSystem = getCRMName(activeCRMIntegrationSettings);
        }
    } else {
        targetSystem = target;
    }
    const [destination, setDestination] = useState(getDestination(targetSystem, profile.userName));

    useEffect(() => {
        setDestination(getDestination(targetSystem, profile.userName));
    }, [targetSystem, profile.userName]);

    const example = getExample(exampleID, targetSystem);
    const selectTemplate = () => {
        if (!selectedList) {
            return;
        }
        navigate('/triggers/new');
        dispatch({
            type: ActionType.SELECT_TRIGGER,
            trigger: getTrigger(exampleID, destination, selectedList.value as string),
        });
        if (destination.crm_objects?.length === 0) {
            setActivePopup('select-destination-popper');
        }
    };

    return (
        <Card
            elevation={active ? 6 : 2}
            raised={active}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            sx={{ width: 300, minHeight: 250, padding: 2, marginBottom: 3, borderRadius: 2 }}
        >
            <SourceToDestination target={targetSystem} />
            <Typography variant="h5" color="dark" sx={{ marginTop: 2 }}>
                {example.title}
            </Typography>
            <Typography variant="subtitle1" weight="normal" sx={{ marginTop: 1, '& span': { color: 'text.strong' } }}>
                {example.content}
            </Typography>
            <Select
                value={selectedList}
                onValueChange={(list) => setSelectedList(list as SelectOption)}
                options={dbLists.map((list) => ({ label: list.name, value: list.id }))}
                multiple={false}
                placeholder="Choose list"
                sx={{ marginTop: 2 }}
                width="100%"
            />
            <StyledButtonContainer>
                {active ? (
                    <Button variant="tertiary" size="small" onClick={selectTemplate} disabled={!selectedList}>
                        Use as a template
                    </Button>
                ) : null}
            </StyledButtonContainer>
        </Card>
    );
};

export default TriggerExample;

const isCrmSupported = (integrations: string[]): boolean => {
    const regex = RegExp(/pipedrive|salesforce|dynamics|hubspot/);
    return integrations.some((value) => regex.test(value.toLowerCase()));
};

const isSlackSupported = (integrations: string[]): boolean => {
    const regex = RegExp(/slack/);
    return integrations.some((value) => regex.test(value.toLowerCase()));
};

const getCRMName = (activeCRMIntegrationSettings: IntegrationSetting<unknown>[]) => {
    return activeCRMIntegrationSettings.filter((i) =>
        ['Salesforce', 'Hubspot', 'Pipedrive', 'Dynamics', 'SalesforceSandbox'].includes(i.integration_target),
    )?.[0]?.integration_target;
};

const getTrigger = (exampleID: ExampleID, destination: Destination, sourceListId: string): Trigger => {
    const trigger = examples?.[exampleID] || getDefaultNewTrigger();
    const dst = stateDestinationToDBdestination(destination);
    if (dst) {
        trigger.workflow_settings.destinations = [dst];
    }
    trigger.workflow_settings.target_group_source = sourceListId;
    return trigger;
};

const examples = {
    newCeo: {
        ...getDefaultNewTrigger(),
        name: 'New CEO trigger',
        query: '{"?ANY":[{"?ALL":[{"?IN":{"leads.tags":["8000038"]}}]}]}',
    },
    newFinancialStatement: {
        ...getDefaultNewTrigger(),
        name: 'New financial statement trigger',
        query: '{"?ANY":[{"?ALL":[{"?IN":{"leads.tags":["8000037"]}}]}]}',
    },
    newCompanyInTargetGroup: {
        ...getDefaultNewTrigger(),
        name: 'New company trigger',
        track_changes: ['new_company'],
    },
};

const getExample = (exampleID: ExampleID, target?: string) => {
    let action = 'Notify me in';
    if (target === 'email') {
        action = 'Send me an';
    }

    const targetLabel = getDestinationLabel(target?.toLowerCase());

    switch (exampleID) {
        case 'newCeo':
            return {
                title: 'Meet new decision makers',
                content: (
                    <>
                        {action} <span>{targetLabel}</span> everytime a company announces that they have{' '}
                        <span>A New CEO</span> in my list:
                    </>
                ),
            };
        case 'newFinancialStatement':
            return {
                title: 'Automate research',
                content: (
                    <>
                        {action} <span>{targetLabel}</span> everytime a company announces that they have{' '}
                        <span>A New Financial Statement</span> in my list:
                    </>
                ),
            };
        case 'newCompanyInTargetGroup':
            return {
                title: 'Prospect on autopilot',
                content: (
                    <>
                        {action} <span>{targetLabel}</span> everytime <span>A New Company</span> appears in my list:
                    </>
                ),
            };
        default:
            throw new Error(`type not supported: ${exampleID}`);
    }
};

const StyledButtonContainer = styled('div')`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
`;
