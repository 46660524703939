import { useCallback, useState } from 'react';

import { useTagObjects, useTagsFilteredByCountry } from 'api/filter_values';
import { useSignals } from 'api/signals';
import { organizationProfileType } from 'api/types/Organization';
import SignalsView from 'components/features/Signals/SignalsView';
import { useCombinedAndSortedSignals } from 'components/features/Signals/utils';

import { TabContent, OrganizationTabProps } from '..';

export const SignalsTab: React.VFC<OrganizationTabProps> = ({ data, businessId }) => {
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const [pages, setPages] = useState(0);
    const pageSize = 20;

    const {
        data: news,
        fetchNextPage: fetchNextPageNews,
        isLoading: isLoadingNews,
        isFetchingNextPage: isFetchingNextPageNews,
    } = useSignals({
        type: organizationProfileType,
        key: businessId,
        signalType: 'news',
        tags: selectedTags.length > 0 ? selectedTags : undefined,
    });
    const {
        data: dataChanges,
        fetchNextPage: fetchNextPageDataChanges,
        isLoading: isLoadingDataChanges,
        isFetchingNextPage: isFetchingNextPageDataChanges,
    } = useSignals({
        type: organizationProfileType,
        key: businessId,
        signalType: 'data-changes',
        tags: selectedTags.length > 0 ? selectedTags : undefined,
    });
    const signals = useCombinedAndSortedSignals(news, dataChanges, (pages + 1) * pageSize);

    const { data: tags, isLoading: isLoadingTags } = useTagObjects();
    const countryTags = useTagsFilteredByCountry(tags, data.country);

    const handleTagsChange = useCallback((tags: number[]) => setSelectedTags([...tags]), []);

    const handleLoadMoreClick = useCallback(() => {
        setPages((pages) => {
            fetchNextPageNews();
            fetchNextPageDataChanges();
            return pages + 1;
        });
    }, [fetchNextPageNews, fetchNextPageDataChanges]);

    return (
        <TabContent>
            <SignalsView
                signals={signals}
                loading={isLoadingNews || isLoadingDataChanges || isLoadingTags}
                loadingNextPage={isFetchingNextPageNews || isFetchingNextPageDataChanges}
                tags={countryTags}
                currentOrganizationBusinessId={businessId}
                onFilterChange={handleTagsChange}
                onLoadMoreClick={handleLoadMoreClick}
            />
        </TabContent>
    );
};

export default SignalsTab;
