import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowShortRight = (props) => (
    <SvgIcon {...props}>
        <path d="M16.17 13L12.59 16.59L14 18L20 12L14 6L12.59 7.41L16.17 11H4V13H16.17Z" />
    </SvgIcon>
);

export default ArrowShortRight;
