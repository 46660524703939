import React from 'react';

import Box from '@mui/system/Box';

import { CompanyFact } from 'api/types/company';
import Frame from 'components/tokens/Frame';
import Tag from 'components/tokens/Tag';

import { getRegisters } from './utils';

type RegisterTagsProps = {
    tags: CompanyFact[];
};

const RegisterTags: React.VFC<RegisterTagsProps> = ({ tags }) => {
    const registers = getRegisters(tags);
    return (
        <Frame title="Other registers">
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {registers.map((register: CompanyFact) => (
                    <Tag size="big" key={register.id} label={register.value} />
                ))}
            </Box>
        </Frame>
    );
};

export default RegisterTags;
