import React from 'react';

import { Box, Popover, Tooltip, styled, SxProps } from '@mui/material';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Tag from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';

export const WebsiteRankDisclaimer = () => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'website-rank-disclaimer' });

    return (
        <Box sx={{ height: 40, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Typography variant="small" color="subtle" weight="semibold">
                Source: Company websites from
            </Typography>
            <Link
                variant="small"
                sx={{
                    marginLeft: 0.5,
                    color: '#0a0a0a!important',
                    textDecorationColor: '#0a0a0a',
                }}
                {...bindTrigger(popupState)}
            >
                Tranco
            </Link>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                elevation={3}
                PaperProps={{
                    sx: {
                        height: 200,
                        width: 310,
                        padding: 2,
                        borderRadius: 2,
                    },
                }}
            >
                <Typography weight="bold" sx={{ marginBottom: 0.5 }}>
                    Only company websites are used
                </Typography>
                <Typography variant="small" color="subtle">
                    Your results will leave out websites from{' '}
                    <Link href="https://tranco-list.eu/" toNewTab="nofollow" color="text.primary">
                        Tranco traffic data
                    </Link>{' '}
                    that we haven’t recognized as companies.
                </Typography>
                <TagContainer
                    sx={{
                        marginTop: 1.5,
                        marginBottom: 1,
                    }}
                >
                    <Icon type="CheckBig" color="success.main" fontSize="mini" />
                    <Tooltip
                        arrow
                        title={
                            <Box>
                                <Typography variant="tiny" weight="bold" sx={{ color: 'white' }}>
                                    ✅ Company websites
                                </Typography>
                                <RenderList list={['microsoft.com', 'google.com', 'sanoma.fi']} />
                            </Box>
                        }
                    >
                        <Tag label="Company websites" variant="green" size="medium" />
                    </Tooltip>
                </TagContainer>
                <TagContainer>
                    <Icon type="CloseSmall" color="text.subtle" fontSize="mini" />
                    <Tooltip
                        arrow
                        title={
                            <Box sx={{ width: 170 }}>
                                <Typography variant="tiny" weight="bold" sx={{ color: 'white' }}>
                                    ❌ Product/Service websites
                                </Typography>
                                <RenderList
                                    list={[
                                        'office.com',
                                        'microsoftonline.com',
                                        'windowsupdate.com',
                                        'office365.com',
                                        'googleanalytics.com',
                                        'myshopify.com',
                                    ]}
                                    sx={{ marginBottom: 2 }}
                                />
                                <Typography variant="tiny" weight="bold" sx={{ color: 'white' }}>
                                    ✅ Their corporate websites are used instead
                                </Typography>
                                <RenderList list={['microsoft.com', 'google.com', 'shopify.com']} />
                            </Box>
                        }
                    >
                        <Tag label="Product/Service websites" size="medium" />
                    </Tooltip>
                    <Tooltip
                        arrow
                        title={
                            <Box sx={{ width: 170 }}>
                                <Typography variant="tiny" weight="bold" sx={{ color: 'white' }}>
                                    ❌ Media websites
                                </Typography>
                                <RenderList
                                    list={['newyorker.com', 'newyorktimes.com', 'hs.fi']}
                                    sx={{ marginBottom: 2 }}
                                />
                                <Typography variant="tiny" weight="bold" sx={{ color: 'white' }}>
                                    ✅ Their corporate websites are used instead
                                </Typography>
                                <RenderList list={['advance.com', 'nytco.com', 'sanoma.fi']} />
                            </Box>
                        }
                    >
                        <Tag label="Media websites" size="medium" />
                    </Tooltip>
                </TagContainer>
            </Popover>
        </Box>
    );
};

/**
 *
 * **************************Render company names*******************************
 * */

type RenderListProps = {
    list: string[];
    sx?: SxProps;
};

const RenderList = ({ list, sx }: RenderListProps) => (
    <StyledUl sx={{ ...sx }}>
        {list.map((company) => (
            <li>{company}</li>
        ))}
    </StyledUl>
);

/**
 * ****************styling**********************
 * */
const TagContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
});

const StyledUl = styled('ul')({
    marginLeft: 16,
    '&> li': {
        fontSize: 12,
        lineHeight: '140%',
        fontWeight: 400,
    },
});
