import React from 'react';

import { Box, Paper, SxProps } from '@mui/material';

import Typography from 'components/tokens/Typography';

interface TileProps {
    title: React.ReactNode;
    cta?: React.ReactNode;
    children: React.ReactNode;
}

const Tile: React.FC<TileProps> = ({ title, cta, children }) => {
    return (
        <Paper sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Typography variant="h6" color="dark">
                    {title}
                </Typography>
                {cta}
            </Box>
            {children}
        </Paper>
    );
};

interface TileContentProps {
    sx?: SxProps;
    children: React.ReactNode;
}

const TileContent: React.FC<TileContentProps> = ({ children, sx }) => {
    return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, ...sx }}>{children}</Box>;
};

export { Tile, TileContent };
