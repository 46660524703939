import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import CompanyGridView from 'components/features/lists/CompanyGridView';

export const SkeletonResult = () => (
    <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
            <Skeleton animation={false} variant="rectangular" width={276} height={35} sx={{ borderRadius: 1 }} />
            <Stack spacing={1} direction="row">
                <Skeleton animation={false} variant="rectangular" width={72} height={32} sx={{ borderRadius: 1 }} />
                <Skeleton animation={false} variant="rectangular" width={72} height={32} sx={{ borderRadius: 1 }} />
                <Skeleton animation={false} variant="rectangular" width={72} height={32} sx={{ borderRadius: 1 }} />
            </Stack>
        </Box>
        <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
            <Skeleton animation={false} variant="rectangular" width={110} height={36} sx={{ borderRadius: 4 }} />
            <Skeleton animation={false} variant="rectangular" width={110} height={36} sx={{ borderRadius: 4 }} />
            <Skeleton animation={false} variant="rectangular" width={110} height={36} sx={{ borderRadius: 4 }} />
            <Skeleton animation={false} variant="rectangular" width={110} height={36} sx={{ borderRadius: 4 }} />
            <Skeleton animation={false} variant="circular" width={34} height={34} />
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
            <Skeleton animation={false} variant="text" height={20} width={90} />
            <Stack direction="row" spacing={1}>
                <Skeleton animation={false} variant="rectangular" width={90} height={34} sx={{ borderRadius: 1 }} />
                <Skeleton animation={false} variant="rectangular" width={90} height={34} sx={{ borderRadius: 1 }} />
            </Stack>
        </Box>
        <CompanyGridView loading companies={[]} selectedCompanies={[]} unselectedCompanies={[]} allCompaniesSelected />
    </Box>
);
