import { isArray, isEmpty } from 'lodash';
import { set } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import { mergeKeys } from 'components/features/Filters/common/utils';
import { convertLegacyValues } from 'components/features/Filters/filters/location/LocationType';
import { getValue } from 'components/features/Filters/filters/utils';
import { FilterID, GroupOption, GroupValues, MappedFilter, NodeValue } from 'components/features/Filters/FilterTypes';
import { SelectOption } from 'components/tokens/select-components/Select';

export type SlotStatus = 'complete' | 'invalid' | null;
const SELF_GROUP_IDS = [FilterID.prospect_address_count, FilterID.country_count] as const;
export type SlotType = 'location' | (typeof SELF_GROUP_IDS)[number];

export type Slot = {
    id: number;
    values: MappedFilter[];
    status: SlotStatus;
    type?: SlotType;
};

export function isSlotLike(value: unknown): value is Slot {
    const castedValue = value as Slot;

    const hasId = 'id' in castedValue;
    const hasValues = 'values' in castedValue;
    const hasStatus = 'status' in castedValue;

    return hasId && hasValues && hasStatus;
}

export function isSelfGroup(value: string) {
    return SELF_GROUP_IDS.includes(value as unknown as (typeof SELF_GROUP_IDS)[number]);
}

export function filterOptions(options: GroupOption[], slot?: Slot) {
    if (!slot) {
        return options;
    }

    if (slot.status === 'complete') {
        return [];
    }

    if (slot.values.length === 0 && slot.type && slot.type !== 'location') {
        return options.filter((option) => option.value === slot.type);
    }

    const containsOfficeType = slot.values.some((v) => v.id === FilterID.location_type);
    // More rules would be here
    return options.filter((option) => {
        if (containsOfficeType && option.value === FilterID.location_type) {
            return false;
        }
        return !isSelfGroup(option.value);
    });
}

let prevSlotId = 0;
export const createNewSlot = (): Slot => {
    return { values: [], status: null, id: prevSlotId++ };
};

function getActiveSlot(slots: Slot[], forceCreate = false) {
    const prevSlot = slots[slots.length - 1];

    let activeSlot: Slot = createNewSlot();

    if (!forceCreate && slots.length > 0 && prevSlot?.status !== 'complete') {
        activeSlot = prevSlot;
    } else {
        slots.push(activeSlot);
    }

    return activeSlot;
}

export function getOfficeTypeValue(values: Pick<MappedFilter, 'id' | 'value'>[]): string[] | 'any' {
    const type = values.find((v) => v.id === FilterID.location_type);
    if (!type) {
        return 'any';
    }

    return getOfficeTypeValueFromFilter(type);
}

export type LocationSelectOption = SelectOption | SelectOption[];

export const ANY_OPTION: SelectOption = { value: '', label: 'Any' };

export function isSelectOption(value: LocationSelectOption): value is SelectOption {
    return !isArray(value);
}

export function getOfficeTypeValueFromFilter(filter: Pick<MappedFilter, 'id' | 'value'>): string[] | 'any' {
    const value = getValue('types', filter.value) as string[];

    if (isEmpty(value)) {
        return 'any';
    }
    return value || 'any';
}

export function nodesToSlots(nodes: MappedFilter[][]) {
    const slots: Slot[] = [];

    const locationTypeSlotMap: Record<string, Slot> = {};
    nodes.forEach((nodeValues) => {
        let activeSlot = getActiveSlot(slots);
        let skipOfficeType = false;

        // if node has only one filter
        if (nodeValues.length === 1) {
            const isSelfGroupSlot = isSelfGroup(nodeValues[0].id);

            // and it is a selfgroup filter then create a new slot
            if (isSelfGroupSlot) {
                if (activeSlot.values.length > 0) {
                    activeSlot = getActiveSlot(slots, true);
                }
                activeSlot.values = [nodeValues[0]];
                activeSlot.type = nodeValues[0].id as SlotType;
                activeSlot.status = 'complete';

                return;
            }
        }

        const currentOfficeTypes = getOfficeTypeValue(nodeValues);
        const key = isArray(currentOfficeTypes) ? currentOfficeTypes.join() : currentOfficeTypes;
        const existingSlot = locationTypeSlotMap[key];

        if (existingSlot) {
            if (activeSlot.values.length === 0) {
                slots.pop(); // FIXME push activeSlot to slots array manually
            }
            activeSlot = existingSlot;
            skipOfficeType = true;
        } else {
            if (activeSlot.values.length > 0) {
                activeSlot = getActiveSlot(slots, true);
            }
            locationTypeSlotMap[key] = activeSlot;
        }

        activeSlot.type = 'location';

        nodeValues.forEach((node) => {
            if (skipOfficeType && node.id === FilterID.location_type) {
                return; // skip the same Location type values
            }

            if (node.id === FilterID.location_type) {
                const convertedNode = convertLegacyValues(node);
                activeSlot.values.push(convertedNode);
                return;
            }
            activeSlot.values.push(node);
        });
    });

    return slots;
}

type CreateNodePropsWithoutPath<T> = T extends object ? { value: T; path?: never } : never;
type CreateNodePropsWithPathString<P = string[]> = P extends string[] ? { value: unknown; path: P } : never;

type CreateNodePropsWithPathArray<P = string> = P extends string ? { value: unknown; path: P } : never;

type CreateNodeProps<T> = CreateNodePropsWithPathString | CreateNodePropsWithoutPath<T> | CreateNodePropsWithPathArray;

function isCreateNodeProps2(v: unknown): v is CreateNodePropsWithPathString {
    return Boolean((v as CreateNodePropsWithPathString).path);
}

export function createNode<T>(props: CreateNodeProps<T>): T {
    if (isCreateNodeProps2(props)) {
        const { path, value } = props;
        const fullPath: string = isArray(path) ? mergeKeys(...path) : path;
        const node = {};
        set(node, fullPath, value);
        return node as T;
    }
    return props.value as unknown as T;
}

export function slotsToNodes(slots: Slot[]) {
    const nodes: Array<NodeValue | NodeValue[]> = [];
    slots.forEach((slot) => {
        if (slot.values.length === 1 && isSelfGroup(slot.values[0].id)) {
            nodes.push(slot.values[0].value);
            return;
        }

        const nodeValue: NodeValue[] = [];
        let type: NodeValue = {};
        for (let index = 0; index < slot.values.length; index++) {
            const currentElement = slot.values[index];
            if (currentElement.id === FilterID.location_type) {
                type = currentElement.value;
                continue;
            }

            nodeValue.push(currentElement.value);
        }

        nodeValue.forEach((v) => {
            const values = [v];
            if (!isEmpty(type)) {
                values.push(type);
            }
            const node = createNode({
                path: [FilterOperator.MATCH, GroupValues.location_visiting_address, FilterOperator.ALL],
                value: values,
            });

            nodes.push(node);
        });
    });

    return nodes;
}

export const splitToMatches = (filters: MappedFilter[]) => {
    if (!isArray(filters)) {
        return [];
    }

    const cloneList: string[] = [FilterID.location_type];
    const mainMap: Map<string, NodeValue[]> = new Map();

    let maxFilters = 1;
    const nodesArr: NodeValue[][] = [];

    filters.forEach((filter) => {
        const value = filter.value;
        const arr = mainMap.get(filter.id);

        if (arr) {
            arr.push(value);
            maxFilters = Math.max(maxFilters, arr.length);
        } else {
            mainMap.set(filter.id, [value]);
        }
    });

    for (let i = 0; i < maxFilters; i += 1) {
        const nodeValues: NodeValue[] = [];
        mainMap.forEach((arr, key) => {
            const item = cloneList.includes(key) ? { ...arr[0] } : arr.pop();

            if (item) {
                nodeValues.push(item);
            }
        });

        if (nodeValues.length) {
            const node = createNode({
                path: [FilterOperator.MATCH, GroupValues.location_visiting_address, FilterOperator.ALL],
                value: nodeValues,
            });
            nodesArr.push(node);
        }
    }

    return nodesArr;
};

export function fillDefaultSlots(slots: Slot[]) {
    const newSlots = [...slots];
    const slotTypes = newSlots.map((slot) => slot.type);

    const addRow = newSlots.length === 0 || !newSlots.some((slot) => slot.type === 'location');
    if (addRow) {
        const newSlot = getActiveSlot(newSlots, true);
        newSlot.type = 'location';
    }

    SELF_GROUP_IDS.forEach((filterId) => {
        if (!slotTypes.includes(filterId)) {
            const newSlot = getActiveSlot(newSlots, true);
            newSlot.type = filterId;
        }
    });

    return newSlots;
}

export function sortSlots(slots: Slot[]) {
    return [...slots].sort((a, b) => {
        const types: string[] = ['location', ...SELF_GROUP_IDS];

        const result = types.indexOf(a.type || '') - types.indexOf(b.type || '');
        return result || a.id - b.id;
    });
}

// Check both objects and plain values
export function isFilterValueEmpty(value: unknown) {
    return isEmpty(value) || !value;
}
