import { getLegacyBusinessId, isNotEmpty } from 'utilities';

import { useGroupStructure } from 'api/groupStructure';
import { GroupStructureHeader, GroupStructureTree } from 'components/features/GroupStructure';

import { TabContent, OrganizationTabProps } from '..';

export const GroupStructureTab: React.FC<OrganizationTabProps> = ({ businessId, mode }) => {
    const { data: groupStructure, isLoading } = useGroupStructure(getLegacyBusinessId(businessId));

    return (
        <TabContent>
            {(isNotEmpty(groupStructure) || isLoading) && (
                <GroupStructureHeader skeleton={isLoading} group_structure={groupStructure} />
            )}
            {(isNotEmpty(groupStructure) || isLoading) && (
                <GroupStructureTree
                    skeleton={isLoading}
                    group_structure={groupStructure}
                    profileBusinessId={businessId}
                    mode={mode}
                />
            )}
        </TabContent>
    );
};

export default GroupStructureTab;
