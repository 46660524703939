import { useMemo } from 'react';

import { assertArray } from 'utilities';

import { Company, CompanyFact } from 'api/types/company';
import { DomainData, Organization, VainuCustomIndustry } from 'api/types/Organization';
import { TagProps } from 'components/templates/Tags';
import { OrganizationResult } from 'contexts/types/FilterCompanies';

export function areVCIsFromFacts(vcis: VainuCustomIndustry[] | CompanyFact[]): vcis is CompanyFact[] {
    return !!vcis[0] && 'id' in vcis[0];
}

export function isVCIFromFacts(vci: VainuCustomIndustry | CompanyFact): vci is CompanyFact {
    return 'id' in vci;
}

/**
 * Should return only the data needed for the rendering i.e. customIndustry.value and
 * later on customIndustry.confidence_score also.
 * @example
 * return customIndustries.map(customIndustry => ({value: customIndustry.value, score: customIndustry.confidence_score}))
 *
 */
export function sortAndFilterCustomIndustries(customIndustries: VainuCustomIndustry[] | CompanyFact[]) {
    if (areVCIsFromFacts(customIndustries)) {
        return customIndustries;
    } else {
        return filterAndSortVCIs(customIndustries);
    }
}

export function getIndustryTagsFromIndustries(company: Company): TagProps[] {
    return (
        company.industry_codes_all?.map((code, index) => ({
            id: code,
            label: company.industry_codes_all_values?.[index] ?? code,
        })) ?? []
    );
}

export function getIndustryTagsFromIndustriesVerbose(organization: OrganizationResult): TagProps[] {
    return (
        organization.industries_verbose?.map((industry) => ({
            id: industry.code,
            label: industry.description || industry.code,
        })) ?? []
    );
}

export const isB2BB2C = (vci: VainuCustomIndustry) => vci.value === 'B2B' || vci.value === 'B2C';

const fallbackThreshold = 0.5;

export const getVCIThresholdRatio = (vci: VainuCustomIndustry) => {
    return ((vci.confidence_score ?? 0) / (vci.confidence_thresholds?.[0] ?? fallbackThreshold)) * fallbackThreshold;
};

// Sorts the B2B and B2C values to the bottom of the list (but keeps them in their own respective order)
export const getVCISortIndex = (vci: VainuCustomIndustry) => getVCIThresholdRatio(vci) + (isB2BB2C(vci) ? -1000 : 0);

export const filterAndSortVCIs = (vcis: VainuCustomIndustry[]) => {
    return vcis
        .filter((vci) => vci.value && vci.confidence_class === 'high')
        .sort((a, b) => getVCISortIndex(b) - getVCISortIndex(a));
};

export function getIndustryTagsFromVCI(data: DomainData | Organization): TagProps[] {
    const sortedVCIs = filterAndSortVCIs(assertArray(data.vainu_custom_industries));
    return sortedVCIs.map((vci) => ({
        id: vci.value as string,
        label: vci.value as string,
    }));
}

export const useIndustryTagsFromVCI = (data: DomainData | Organization): TagProps[] =>
    useMemo(() => getIndustryTagsFromVCI(data), [data]);

export const getVCIScore = (vci: VainuCustomIndustry) => {
    const score = getVCIThresholdRatio(vci);
    if (score >= 0.8) {
        return 5;
    }
    if (score >= 0.65) {
        return 4;
    }
    if (score >= 0.5) {
        return 3;
    }
    return 0;
};
