import { atom } from 'recoil';

import { GenericAsyncProcess } from 'api/types/UserAsyncProcess';

const AsyncProcessAtom = atom<GenericAsyncProcess[]>({
    key: 'AsyncProcesses',
    default: [],
});

export default AsyncProcessAtom;
