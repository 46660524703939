import { TableCell, TableRow } from '@mui/material';
import { get } from 'lodash';
import { range } from 'utilities';

import { FinancialDataLabels, FinancialValue } from 'api/types/FinancialStatement';

import { ValidFinancialStatement } from '../../types';
import { CompactValuePrecision, formatLeveLabel, hasRowValues, hasSectionValues } from '../utils';
import LabelCell from './LabelCell';
import ValueCell from './ValueCell';

export type StatementSectionVariant = 'balanceSheet' | 'incomeStatement';

export type StatementSectionProps = {
    path: string[];
    variant: StatementSectionVariant;
    statements: ValidFinancialStatement[];
    section: FinancialDataLabels;
    level: number;
    mainCurrency?: string;
    precision?: CompactValuePrecision;
    formatValue: (value: FinancialValue) => string;
};

export const StatementSection: React.FC<StatementSectionProps> = ({
    path,
    variant,
    statements,
    section,
    level,
    mainCurrency,
    precision,
    formatValue,
}) => {
    const { label, fields, noBreakdown } = section;
    const showSection = hasSectionValues(statements, section, path);

    if (!showSection) {
        return null;
    }

    const hasTotalValues = hasRowValues(statements, [...path, 'total']);
    const values = statements.map((statement) => {
        const value = get(statement, path);
        return (
            <ValueCell total={!!fields} key={`${statement.accounting_period?.end}${path.join('')}`}>
                {formatValue(value)}
            </ValueCell>
        );
    });

    return (
        <>
            <TableRow>
                <LabelCell level={level} variant={variant}>
                    {formatLeveLabel(label, level, precision, mainCurrency)}
                </LabelCell>
                {fields
                    ? range(statements.length).map((index) => <TableCell key={`${index}${path.join('')}`} />)
                    : values}
            </TableRow>
            {fields &&
                Object.entries(fields).map(([key, field]) => {
                    const nextPath = noBreakdown ? [...path, key] : [...path, 'breakdown', key];
                    return (
                        <StatementSection
                            key={key}
                            path={nextPath}
                            statements={statements}
                            section={field}
                            level={level + 1}
                            variant={variant}
                            formatValue={formatValue}
                        />
                    );
                })}
            {fields && hasTotalValues && (
                <TableRow
                    sx={{
                        '.MuiTableCell-root': {
                            borderTop: level === 1 ? '1px solid' : 0,
                            borderTopColor: level === 1 ? 'border' : undefined,
                        },
                    }}
                >
                    <LabelCell level={level} variant={variant} total>
                        {level === 0 && mainCurrency ? `Total ${label} (${mainCurrency})` : `Total ${label}`}
                    </LabelCell>
                    {values}
                </TableRow>
            )}
        </>
    );
};

export default StatementSection;
