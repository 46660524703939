import React from 'react';

import { Grid, Skeleton } from '@mui/material';
import { range } from 'utilities';

import { Signal } from 'api/types/signals/signals';
import NoFilterResults from 'components/features/lists/CompanyGridView/NoFilterResults';

import SignalCard from '../Card';

export type SignalsGridProps = {
    signals: Signal[];
    loading?: boolean;
    loadingNextPage?: boolean;
    loadingGridSize?: number;
    variant: 'shadowed' | 'outlined';
    currentOrganizationBusinessId: string;
};

export const SignalsGrid: React.VFC<SignalsGridProps> = ({
    signals,
    loading,
    loadingNextPage,
    loadingGridSize = 3,
    variant = 'shadowed',
    currentOrganizationBusinessId,
}) => (
    <Grid container columnSpacing={2} rowSpacing={4}>
        {loading ? (
            range(loadingGridSize).map((index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Skeleton variant="rounded" height={360} />
                </Grid>
            ))
        ) : signals.length === 0 ? (
            <Grid item xs={12}>
                <NoFilterResults sx={{ height: 360 }} />
            </Grid>
        ) : (
            <>
                {signals.map((signal) => (
                    <Grid item xs={12} sm={6} md={4} key={signal.id}>
                        <SignalCard
                            key={signal.id}
                            variant={variant}
                            signal={signal}
                            currentOrganizationBusinessId={currentOrganizationBusinessId}
                        />
                    </Grid>
                ))}
                {loadingNextPage &&
                    range(loadingGridSize).map((index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Skeleton variant="rounded" height={360} />
                        </Grid>
                    ))}
            </>
        )}
    </Grid>
);

export default SignalsGrid;
