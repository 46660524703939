import { Paper, Skeleton, SxProps } from '@mui/material';

import { Typography } from 'components/tokens/Typography';

type NumberTileProps = {
    title: string;
    value: number | undefined;
    subtitle: string;
    sx?: SxProps;
};

const NumberTile: React.FC<NumberTileProps> = ({ title, value, subtitle, sx }) => {
    return (
        <Paper
            variant="outlined"
            sx={{
                padding: 3,
                flex: 1,
                maxWidth: '100%',
                minWidth: 260,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                ...sx,
            }}
        >
            <Typography weight="bold">{title}</Typography>

            <Typography variant="number">{value?.toLocaleString() ?? <Skeleton width={120} />}</Typography>

            <Typography variant="body2" color="subtle">
                {subtitle}
            </Typography>
        </Paper>
    );
};

export default NumberTile;
