import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { once } from 'lodash';
import Joyride, { Step, TooltipRenderProps } from 'react-joyride';

import { ApplicationStore } from 'api/types/ApplicationStore';
import { patchProfile } from 'api/userProfile';
import Button from 'components/tokens/Button';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useTrigger } from 'contexts/TriggerContext';
import { brandPalette } from 'design/theme/colors';

interface Props {
    show: boolean;
    onFinish: () => void;
}

const steps: Step[] = [
    {
        target: '#trigger-source',
        content: "Let's see how triggers work. ✨",
        title: 'Great! You are creating a new trigger',
        placement: 'right',
        hideCloseButton: true,
        disableBeacon: true,
    },
    {
        target: '#trigger-filters',
        content: 'Select the company changes that you want to use as trigger events.',
        title: 'Adding filters 🔍',
        placement: 'right',
        hideCloseButton: true,
        disableBeacon: true,
    },
    {
        target: '#trigger-results',
        title: 'Results and refine 👀',
        content: 'Check & preview your results and refine the filters of the triggers you’ve set as needed.',
        placement: 'bottom',
        hideCloseButton: true,
        disableBeacon: true,
    },
    {
        target: '#trigger-destination',
        content: 'When you have a filter defined you’re able to start sending the events to CRM, Slack, email...',
        title: 'Destination',
        placement: 'right',
        hideCloseButton: true,
        disableBeacon: true,
    },
    {
        target: '#trigger-schedule',
        content: 'Choose when triggers should be created',
        title: 'Schedule ⏰',
        placement: 'right',
        hideCloseButton: true,
        disableBeacon: true,
    },
    {
        target: '#trigger-left-panel',
        content:
            'In this section you can create new triggers, remove, duplicate & move 🤚 triggers between Active & Paused.',
        title: 'Left panel',
        placement: 'right',
        hideCloseButton: true,
        disableBeacon: true,
    },
];

const TriggerOnboarding: React.FC<Props> = ({ show, onFinish }) => {
    const [, , { setActivePopup }] = useTrigger();
    const axios = useAxiosContext();
    const queryClient = useQueryClient();
    const [run, setRun] = useState(false);

    useEffect(() => {
        if (show) {
            setRun(true);
        }
    }, [show]);

    const finishOnboarding = once(async () => {
        const data = { onboarding_status: { trigger_onboarding_shown: 1 } };
        patchProfile(axios, data);
        queryClient.setQueryData<ApplicationStore>(['applicationStore'], (oldData) => {
            return {
                ...oldData,
                onboarding_status: { ...(oldData?.onboarding_status || {}), trigger_onboarding_shown: 1 },
            } as ApplicationStore;
        });
        onFinish();
    });

    return (
        <Joyride
            steps={steps}
            run={run}
            callback={(e) => {
                if (e.status === 'running' && e.index === 0 && e.lifecycle === 'tooltip') {
                    setActivePopup(undefined);
                }
                if (e.status === 'finished' || e.status === 'skipped') {
                    finishOnboarding();
                }
            }}
            tooltipComponent={TooltipComponent}
            showSkipButton
            continuous
            hideBackButton
            disableOverlayClose
            styles={{
                options: {
                    arrowColor: 'brandPalette.onyx',
                    zIndex: 1500,
                },
                buttonNext: {
                    backgroundColor: 'brandPalette.subtleLight',
                    color: 'brandPalette.onyx',
                },
            }}
            disableScrollParentFix
        />
    );
};

export default TriggerOnboarding;

const TooltipComponent = ({ isLastStep, step, index, primaryProps, tooltipProps, skipProps }: TooltipRenderProps) => {
    const { title, content } = step;
    const getButtonText = () => {
        if (index === 0) {
            return "Let's start";
        }
        if (isLastStep) {
            return 'Gotcha!';
        }
        return 'Next step';
    };
    return (
        <StyledTooltip {...tooltipProps}>
            <StyledTooltipTitle>{title}</StyledTooltipTitle>
            <StyledTooltipContent>{content}</StyledTooltipContent>
            <StyledActions>
                <Button variant="tertiary" size="small" onClick={primaryProps.onClick}>
                    {getButtonText()}
                </Button>
                {isLastStep ? null : <StyledSkip onClick={skipProps.onClick}>Skip tutorial</StyledSkip>}
            </StyledActions>
        </StyledTooltip>
    );
};

const StyledTooltip = styled('div')`
    padding: 16px;
    background-color: ${brandPalette.onyx};
    width: 310px;
    min-height: 150px;
    border-radius: 8px;
    color: ${brandPalette.white};
`;

const StyledTooltipTitle = styled('div')`
    font-weight: 700;
    font-size 16px;
    line-height 22px;
    padding-bottom: 8px;
`;

const StyledTooltipContent = styled('div')`
    font-size: 14px;
    line-height: 140%;
    padding-bottom: 16px;
    min-height: 57px;
`;

const StyledActions = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
`;

const StyledSkip = styled('div')`
    margin-left: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    width: 70px;
    cursor: pointer;
`;
