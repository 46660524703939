import { Box, Skeleton, Tooltip } from '@mui/material';
import { isNotEmpty } from 'utilities';

import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon, { IconType } from 'components/tokens/Icon';
import Link from 'components/tokens/Link';

export type SocialNetwork = {
    type: string;
    label: string;
    url?: string;
    userHandle?: string;
    icon: IconType;
};
export type SocialNetworkSidebarCardProps = {
    socialNetworks?: SocialNetwork[];
    skeleton?: boolean;
};

export const SocialNetworkSidebarCard: React.FC<SocialNetworkSidebarCardProps> = ({
    socialNetworks,
    skeleton = false,
}) => {
    return skeleton ? (
        <Skeleton variant="rounded" height={118} sx={{ backgroundColor: 'grey.100' }} />
    ) : isNotEmpty(socialNetworks) ? (
        <Frame title="Social networks" titleProps={{ sx: { marginBottom: 1 } }}>
            <Box sx={{ display: 'flex' }}>
                {socialNetworks?.map(({ type, label, url, userHandle, icon }) => (
                    <Link key={type} href={url} toNewTab="nofollow">
                        <Tooltip title={userHandle ?? label ?? false}>
                            <Button sx={{ marginRight: 0.5 }} variant="flat" startIcon={<Icon type={icon} />} />
                        </Tooltip>
                    </Link>
                ))}
            </Box>
        </Frame>
    ) : null;
};

export default SocialNetworkSidebarCard;
