import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { Operation } from 'api/types/FilterOperators';
import { transformState } from 'components/features/Filters/filters/utils';
import { FilterID, GroupIDValue, NodeValue } from 'components/features/Filters/FilterTypes';

export enum FilterAction {
    removeFilter = 'remove',
    setFilter = 'set',
    addFilterGroup = 'add-group',
    removeFilterGroup = 'remove-group',
    setActiveGroup = 'set-active-group',
    setUndoFilterToState = 'set-undo-filter-to-state',
    updateFilter = 'update-filter',
    setFiltersFromQuery = 'set-filters-from-query',
    setQuery = 'set-query',
}

export type DataBase = 'DOMAIN_DATA_BASIC';

export interface FilterContextType {
    activeGroup: GroupIDValue | 'plus-button' | undefined;
    activeFilter: FilterID | undefined;
    filterListID: string | undefined;
    query: string | undefined;
    queryToFilters: (value: Operation | string, filterListID: string | undefined) => void;
    state: NodeValue;
    setActiveGroup: (group: GroupIDValue | 'plus-button' | undefined) => void;
    setActiveFilter: (filter: FilterID | undefined) => void;
    onSubmit: () => void;
}

export const UPLOAD_LIST_GROUP_ID = uuidv4();

const EMPTY_STATE = {};
export const FilterContext = React.createContext<FilterContextType | undefined>(undefined);

type FilterProviderProps = { children: React.ReactNode };

const FilterProvider = ({ children }: FilterProviderProps) => {
    const [state, setState] = useState(EMPTY_STATE);

    const [filterListID, setFilterListID] = useState<string | undefined>(undefined);
    const [activeGroup, setActiveGroup] = useState<GroupIDValue | 'plus-button' | undefined>(undefined);
    const [activeFilter, setActiveFilter] = useState<FilterID | undefined>(undefined);

    const query = useMemo(() => JSON.stringify(state), [state]);

    const formStateDefault = useMemo(() => transformState(cloneDeep(state), '.', '#'), [state]);
    const { reset, ...otherFormMethods } = useForm<FormData>(formStateDefault);

    useEffect(() => {
        reset(cloneDeep(formStateDefault));
    }, [reset, formStateDefault]);

    const onSubmit = useCallback(() => {
        const onSubmitHandler: SubmitHandler<FormData> = (data) => {
            const updatedData = transformState(data, '#', '.');

            if (JSON.stringify(updatedData) !== query) {
                setState(updatedData);
            }
        };

        otherFormMethods.handleSubmit(onSubmitHandler)();
    }, [otherFormMethods, query]);

    const queryToFilters = useCallback((query: Operation | string, filterListID: string | undefined) => {
        setState(() => {
            try {
                return typeof query === 'string' ? JSON.parse(query) : query || EMPTY_STATE;
            } catch (e) {
                return EMPTY_STATE;
            }
        });
        setFilterListID(filterListID);
    }, []);

    const context: FilterContextType = useMemo(
        () => ({
            state,
            query,
            filterListID,
            activeGroup,
            activeFilter,
            queryToFilters,
            setActiveGroup,
            setActiveFilter,
            onSubmit,
        }),
        [activeFilter, activeGroup, filterListID, onSubmit, query, queryToFilters, state],
    );
    return (
        <FormProvider {...otherFormMethods} reset={reset}>
            <FilterContext.Provider value={context}>{children}</FilterContext.Provider>
        </FormProvider>
    );
};

function useFilterState() {
    const context = useContext(FilterContext);
    if (context === undefined) {
        throw new Error('useFilterState must be used within a FilterProvider');
    }
    return context;
}

function useFilters(): [FilterContextType] {
    return [useFilterState()];
}

export { FilterProvider, useFilters, useFilterState };
