import React from 'react';

import { CircularProgress, LinearProgress, Typography, styled } from '@mui/material';

import { getMatchingObjectsText } from '../landing/pages/ConnectionView/CRMOverview/utils';
import { CrmName } from '../types/Crm';

interface Props {
    hasLeadMatchingPermission: boolean;
    crm: CrmName;
    progressPercentage: number;
    processedCount: number;
}

const MatchingProgressBar: React.FC<Props> = ({
    hasLeadMatchingPermission,
    crm,
    progressPercentage,
    processedCount,
}) => {
    return (
        <Container>
            <ProgressBarHeader>
                <CircularProgressWrapper>
                    <CircularProgress variant="determinate" size={32} thickness={3} value={100} />
                    <CircularProgress variant="indeterminate" disableShrink size={32} thickness={3} />
                </CircularProgressWrapper>
                <div>
                    <Typography fontSize="14px" fontWeight="500">
                        We are matching your {getMatchingObjectsText(hasLeadMatchingPermission, crm)} with Vainu
                    </Typography>
                    <Typography fontSize="12px">You’ll receive an email when we are ready!</Typography>
                </div>
            </ProgressBarHeader>
            <StyledLinearProgress variant="determinate" value={progressPercentage} />
            <MatchedCount align="right">{processedCount.toFixed(0)} processed</MatchedCount>
        </Container>
    );
};

const Container = styled('div')(({ theme: { palette } }) => ({
    height: '118px',
    background: palette.grey[50],
    borderRadius: '16px',
    padding: '16px',
}));

export const StyledLinearProgress = styled(LinearProgress)(({ theme: { palette } }) => ({
    height: '16px',
    borderRadius: '4px',
    backgroundColor: palette.grey[200],
    '& .MuiLinearProgress-bar': {
        backgroundColor: palette.checkbox.checked,
        borderRadius: '4px',
    },
}));

const ProgressBarHeader = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
});

const CircularProgressWrapper = styled('div')(({ theme: { palette } }) => ({
    position: 'relative',
    height: '32px',
    marginRight: '15px',

    '& > span:first-child': {
        color: palette.common.white,
    },
    '& > span:nth-child(2)': {
        position: 'absolute',
        left: 0,
        color: palette.checkbox.checked,
        animationDuration: '1000ms',
    },
}));

const MatchedCount = styled(Typography)(({ theme: { palette } }) => ({
    color: palette.text.primary,
    fontSize: '11px',
    marginTop: '8px',
}));

export { MatchingProgressBar };
