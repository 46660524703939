import { ThemeProvider as MuiThemeProvider } from '@mui/material';

import theme from 'design/theme';

type ThemeProviderProps = {
    children?: React.ReactNode;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => (
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);

export default ThemeProvider;
