import React from 'react';

import { Box, CircularProgress, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { assertArray } from 'utilities';

import { OpenedProfileFromSearchResult } from 'api/types/Search';
import { getHQ } from 'components/features/Locations/utils';
import { getFullLocation } from 'components/templates/CompanyCard/utils';
import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';
import { DomainDataResult, OrganizationResult } from 'contexts/types/FilterCompanies';

import SearchSuggestionCompanyCard from './SearchSuggestionCompanyCard';

type CompanySearchSuggestionsProps = {
    searchTerm: string;
    debouncedSearchText: string;
    suggestions: DomainDataResult[] | OrganizationResult[];
    isLoading?: boolean;
    isFetched?: boolean;
    onShowAllSearchResult: () => void;
    onOpenProfile: (profileIdentifier: OpenedProfileFromSearchResult) => void;
};

export const CompanySearchSuggestions: React.VFC<CompanySearchSuggestionsProps> = ({
    searchTerm,
    debouncedSearchText,
    suggestions,
    isLoading = false,
    isFetched = false,
    onShowAllSearchResult,
    onOpenProfile,
}) => {
    if (isEmpty(suggestions) && !isLoading && !isFetched) {
        return null;
    }

    const slicedSuggestions = suggestions.slice(0, 5);

    const suggestionItems = slicedSuggestions.map((suggestion, index) => {
        return (
            <Link
                key={suggestion.website || suggestion.name}
                onClick={() => onOpenProfile({ type: suggestion.profileType, profileId: suggestion.id })}
                underline="none"
            >
                <SearchSuggestionCompanyCard
                    companyName={suggestion.name}
                    // returns full url so needs to split since logo handler appends base url for static resources.
                    logo={suggestion.logo_url?.split('vainu.io/')[1]}
                    fullAddress={getFullLocation(getHQ(assertArray(suggestion.business_units)))}
                    domain={suggestion.website}
                    levelInGroup={'level_in_group' in suggestion ? suggestion.level_in_group : null}
                />
            </Link>
        );
    });

    return (
        <StyledWrapper elevation={4} padding="none" sx={{ border: '1px solid', borderColor: 'border' }}>
            <Suggestions>
                {isLoading || searchTerm !== debouncedSearchText ? (
                    <StyledBox>
                        <CircularProgress />
                    </StyledBox>
                ) : isFetched && isEmpty(suggestions) ? (
                    <StyledBox>
                        <Typography variant="small" color="subtle" sx={{ span: { color: 'primary.main' } }}>
                            No result found for <span>"{searchTerm}"</span>
                        </Typography>
                    </StyledBox>
                ) : (
                    <>
                        {suggestionItems}
                        <ShowAllBtn variant="flat" onClick={() => onShowAllSearchResult()}>
                            See more companies including <span>"{searchTerm}"</span>
                        </ShowAllBtn>
                    </>
                )}
            </Suggestions>
        </StyledWrapper>
    );
};

export default CompanySearchSuggestions;

const StyledWrapper = styled(Frame)(({ theme }) => ({
    position: 'absolute',
    maxHeight: 371,
    width: 440,
    left: -60,
    overflowY: 'auto',
    animation: '500ms both fadeInDown',
    zIndex: 1000,
    '@media (max-width: 600px)': {
        width: '100%',
        minWidth: 280,
    },
    '@keyframes fadeInDown': {
        from: {
            opacity: 0,
            top: 30,
        },
        to: {
            opacity: 1,
            top: 58,
        },
    },
}));

const Suggestions = styled('ul')`
    display: flex;
    position: relative;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
`;

const ShowAllBtn = styled(Button)(({ theme }) => ({
    padding: 16,
    borderRadius: 0,
    fontWeight: 400,
    color: theme.palette.text.subtle,
    span: {
        marginLeft: 3,
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
}));

const StyledBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    justifyContent: 'center',
});
