import React from 'react';

import { Box, SxProps } from '@mui/material';

import { Signal } from 'api/types/signals/signals';
// import Button from 'components/tokens/Button';
// import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

import RelatedCompanies from './RelatedCompanies';
import RelocationContent from './RelocationContent';
import SignalCardWrapper from './SignalCardWrapper';
import SignalItemTags from './SignalTags';
import { SignalFooter /*ButtonContainer*/ } from './styled';
import { formatSignalDate, formatSignalTitle, getSourceDomain, hasSignalTag } from './utils';

export type SignalTag = {
    id: number;
    value: string;
};

export type SignalDynamicValue = {
    [key: string]: string;
};

export type SignalProspect = {
    id: number;
    company_name: string;
    logo?: string;
};

export type CurrentProspect = {
    vid: number;
};

export type SignalProps = {
    sx?: SxProps;
    signal: Signal;
    selectedTagIds?: number[];
    currentOrganizationBusinessId: string;
    variant: 'shadowed' | 'outlined';
};

type SignalLinkProps = {
    link?: string | null;
    children?: React.ReactNode;
};

const SignalLink: React.FC<SignalLinkProps> = ({ children, link }) => {
    if (link) {
        return (
            <Link underline="none" href={link} toNewTab="nofollow">
                {children}
            </Link>
        );
    }
    return <>{children}</>;
};

// customized styling for them later on
// const NEW_FINANCIAL_PDF_LEAD_ID = 8000037;
const RELOCATION_LEAD_ID = 3325073;

export const SignalCard: React.VFC<SignalProps> = ({
    sx,
    signal,
    selectedTagIds,
    variant = 'shadowed',
    currentOrganizationBusinessId,
}) => {
    const { title, content, tags, link, signalType } = signal;
    const domain = getSourceDomain(link ?? '');

    return (
        <SignalCardWrapper variant={variant} sx={sx}>
            <SignalItemTags tags={tags} selectedTagIds={selectedTagIds} />
            <Box sx={{ height: '100%', position: 'relative' }}>
                <SignalLink link={link}>
                    <Typography
                        variant="h4"
                        sx={{
                            margin: '16px 0',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'normal',
                        }}
                    >
                        {formatSignalTitle(title)}
                    </Typography>
                </SignalLink>
                {hasSignalTag(signal, RELOCATION_LEAD_ID) && signalType === 'data-changes' ? (
                    <RelocationContent signal={signal} />
                ) : (
                    <>
                        <Typography
                            variant="tiny"
                            sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 4,
                                WebkitBoxOrient: 'vertical',
                                whiteSpace: 'normal',
                            }}
                        >
                            {content && formatSignalTitle(content)}
                        </Typography>
                    </>
                )}
                <RelatedCompanies
                    currentOrganizationBusinessId={currentOrganizationBusinessId}
                    organizations={signal.organizations}
                />
                <SignalFooter className="signalFooter">
                    <Typography variant="mini" color="subtle">
                        {domain ? `${domain}\u2002\u00b7\u2002` : null}
                        {formatSignalDate(signal)}
                    </Typography>
                </SignalFooter>

                {/* Disable buttons until further development is made with the export and flagging. */}
                {/* <ButtonContainer className="buttonContainer" direction="row" spacing={1}>
                    {/ * Flag button for internal use only * /}
                    <Button
                        onClick={() => {}}
                        size="small"
                        variant="tertiary"
                        startIcon={<Icon fontSize="mini" type="FlagOutline" />}
                    />
                    <Button
                        onClick={() => {}}
                        size="small"
                        variant="tertiary"
                        startIcon={<Icon fontSize="mini" type="CheckBig" />}
                    />
                    <Button
                        onClick={() => {}}
                        size="small"
                        variant="tertiary"
                        startIcon={<Icon fontSize="mini" type="Export" />}
                    >
                        Send to
                    </Button>
                </ButtonContainer> */}
            </Box>
        </SignalCardWrapper>
    );
};

export default SignalCard;
