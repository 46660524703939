import { useState } from 'react';

import { Box, Chip, Table, TableBody, TableCell, TableRow } from '@mui/material';

import OverflowContainer from 'components/layout/OverflowContainer';
import Skeleton from 'components/modules/connectors/skeletons';
import Icon from 'components/tokens/Icon';

export type PreviewTableProps = {
    data?: string[][];
    skeletonData: string[][];
    chips: string[];
    selectedChipIndex: number;
    onChipChange: (index: number) => void;
};

const PreviewTable = ({ data, skeletonData, selectedChipIndex, chips, onChipChange }: PreviewTableProps) => {
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const handleScroll = (el: HTMLElement) => {
        if (el.scrollLeft === 0) {
            setCanScrollLeft(false);
            setCanScrollRight(el.scrollWidth > el.offsetWidth);
        } else if (el.scrollWidth < el.scrollLeft + el.offsetWidth) {
            setCanScrollLeft(true);
            setCanScrollRight(false);
        } else {
            setCanScrollLeft(true);
            setCanScrollRight(true);
        }
    };

    const headerRow = (data || skeletonData)[0];
    const rows = (data || skeletonData).slice(1, 16);

    return (
        <Box
            sx={{
                height: '100%',
                backgroundColor: 'grey.50',
                borderRadius: 3,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {chips.length > 1 && (
                <Box sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Icon type="Document" fontSize="small" sx={{ marginRight: 0.5 }} /> CSV Preview:
                    </Box>

                    {chips.map((chip, idx) => (
                        <Chip
                            key={idx}
                            label={chip}
                            variant="outlined"
                            clickable
                            sx={{
                                color: 'primary.main',
                                backgroundColor: 'common.white',
                                ...(idx === selectedChipIndex ? { borderWidth: 2, borderColor: 'primary.main' } : {}),
                            }}
                            onClick={() => onChipChange(idx)}
                        />
                    ))}
                </Box>
            )}

            <Box sx={{ flex: 1, overflow: 'hidden' }}>
                <OverflowContainer
                    onScroll={handleScroll}
                    mode="both"
                    // height of 2 first rows
                    headerPadding={86}
                    // width of 2 first cols
                    leftPadding={186}
                    shadows={{
                        top: '0 2px 4px 0 rgba(138,138,138,.05), 0 2px 4px 0 rgba(10,10,10,.05)',
                        left: '0 4px 12px 0 rgba(10,10,10,.15)',
                    }}
                    horizontalSize={[24, 116]}
                    verticalSize={[24, 49]}
                    sx={{
                        height: '100%',
                        '& > .MuiBox-root': {
                            overflow: 'scroll',
                            '&::-webkit-scrollbar': {
                                height: 32,
                                width: 32,
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'grey.50',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'grey.200',
                                border: '13px solid',
                                borderColor: 'grey.50',
                                borderRadius: 8,
                            },
                            '&::-webkit-scrollbar-corner': {
                                backgroundColor: 'grey.50',
                            },
                            '&::-webkit-scrollbar-button:horizontal:end': {
                                width: 32,
                                display: 'block',
                                borderRadius: '4px',
                                '&:increment': {
                                    background: `no-repeat center/70% url('data:image/svg+xml,<svg style="opacity:${
                                        canScrollRight ? 1 : 0.3
                                    }" xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M20 34V14l10 10Z"/></svg>')`,
                                    '&:hover': {
                                        backgroundColor: canScrollRight ? 'grey.200' : undefined,
                                    },
                                },
                                '&:decrement': {
                                    background: `no-repeat center/70% url('data:image/svg+xml,<svg style="opacity:${
                                        canScrollLeft ? 1 : 0.3
                                    }" xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M28 34 18 24l10-10Z"/></svg>')`,
                                    '&:hover': {
                                        backgroundColor: canScrollLeft ? 'grey.200' : undefined,
                                    },
                                },
                            },
                        },
                    }}
                >
                    <Table
                        sx={{
                            width: 'auto',
                            minWidth: '100%',
                            border: 'none',
                            '& .MuiTableCell-root': {
                                padding: 1,
                                textAlign: 'left',
                                borderRight: '1px solid',
                                borderColor: 'border',
                            },
                            '& .header-cell': {
                                zIndex: 15,
                                fontSize: 14,
                                fontWeight: 400,
                                textAlign: 'center',
                                backgroundColor: 'grey.50',
                                color: 'grey.400',
                                padding: 0,
                            },
                            '& .col-a': {
                                position: 'sticky',
                                left: 28,
                                borderRightWidth: 2,
                                zIndex: 9,
                            },
                            '& .row-1 .MuiTableCell-root': {
                                borderBottomWidth: 2,
                                position: 'sticky',
                                top: 28,
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    className="header-cell"
                                    sx={{
                                        width: 28,
                                        height: 28,
                                        position: 'sticky',
                                        top: 0,
                                        left: 0,
                                        border: 'none !important',
                                        zIndex: '16 !important',
                                    }}
                                />
                                {headerRow.map((column, idx) => (
                                    <TableCell
                                        className={['header-cell', idx === 0 ? 'col-a' : undefined].join(' ')}
                                        key={idx}
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 2,
                                            '&:last-of-type': {
                                                borderRight: 'none !important',
                                            },
                                            ...(idx === 0 ? { zIndex: '16 !important' } : {}),
                                        }}
                                    >
                                        {idxToExcelColumn(idx)}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow className="row-1">
                                <TableCell
                                    className="header-cell"
                                    sx={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: '16 !important',
                                    }}
                                >
                                    <Box sx={{ width: 27, height: 56, lineHeight: '56px' }}>1</Box>
                                </TableCell>
                                {headerRow.map((column, idx) => (
                                    <TableCell
                                        className={idx === 0 ? 'col-a' : undefined}
                                        sx={{
                                            backgroundColor: 'common.white',
                                            whiteSpace: 'pre',
                                            fontWeight: 400,
                                            color: 'text.subtle',
                                            '&::first-line': {
                                                fontSize: 14,
                                                color: 'text.strong',
                                                fontWeight: 500,
                                            },
                                            zIndex: idx === 0 ? '16 !important' : 1,
                                        }}
                                        key={idx}
                                    >
                                        {idx === 0 && 'Company\n'}
                                        {column || <Skeleton />}
                                    </TableCell>
                                ))}
                            </TableRow>

                            {rows.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 1,
                                        }}
                                        className="header-cell"
                                    >
                                        {idx + 2}
                                    </TableCell>

                                    {row.map((column: string, cidx: number) => (
                                        <TableCell
                                            key={cidx}
                                            className={cidx === 0 ? 'col-a' : undefined}
                                            sx={{
                                                backgroundColor: 'background.default',
                                                ...(cidx === 0
                                                    ? {
                                                          color: 'text.strong',
                                                          borderRightWidth: 2,
                                                      }
                                                    : {}),
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    maxHeight: 32,
                                                    overflow: 'hidden',
                                                    maxWidth: 150,
                                                    minWidth: 80,
                                                    width: cidx === 0 ? 140 : undefined,
                                                    overflowWrap: 'break-word',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {data ? column : column || <Skeleton />}
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </OverflowContainer>
            </Box>
        </Box>
    );
};

export default PreviewTable;

function idxToExcelColumn(idx: number) {
    const b = idx % 26;
    const a = Math.floor(idx / 26);
    return (a ? String.fromCharCode(65 + a - 1) : '') + String.fromCharCode(65 + b);
}
