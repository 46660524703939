import { SxProps, Theme } from '@mui/material';

import type { TableDataBase } from './SortableTable';

export type CellAlign = 'left' | 'right' | 'center' | undefined;

export type SortableTableColumnProps<T extends TableDataBase> = {
    /** String or ReactNode to show in the header row for the column. */
    label?: React.ReactNode;
    /** Field in the data passed to SortableTable. Can be keyof T or a string with nested keys like 'address.location.name'
     * todo: add a nested keyof type instead of 'keyof T | string'
     */
    field: keyof T | string;
    /** Is the user allowed to click the column header to sort by this column. */
    sortable?: boolean;
    /** Sort by number values; the field must be of type number. If the data contains strings and numbers mixed,
     * pass instead a custom comparing function. */
    numeric?: boolean;
    /** Whether to sort the rows ascending or descending on the first click of the column. */
    sortDefaultAsc?: boolean;
    /** If the column is not string or numeric, you should pass a custom comparing function for sorting. */
    compareFn?: (a: T, b: T) => number;
    /** You can pass a custom rendering function that gets the data row as its parameter. */
    children?: (row: T, index: number) => React.ReactNode;
    /** Width of the column. Required if using a virtualized table. With non-virtualized tables defaults to auto size. */
    width?: number | string;
    /** Alignment of the content. Defaults to right on numeric columns, otherwise to left. */
    align?: CellAlign;
    /** Adjust the column padding to better fit a checkbox. */
    checkbox?: boolean;
    /** Sx props to override the styling on the cells of this column. */
    sx?: SxProps<Theme>;
    /** Sx props to override the styling on the header cells of this column. */
    headerSx?: SxProps<Theme>;
    /** Value to use for key
     *
     * Defaults to `field` prop
     */
    keyValue?: React.Key;
    /** Show loading skeleton */
    loading?: boolean;
    skeletonWidth?: number;
};

// This component renders nothing by design. It's just a prop container that describes the columns,
// while the SortableTableCell handles all the actual rendering per those props.
export const SortableTableColumn = <T extends TableDataBase>({
    // These default values just provide documentation in Storybook. The actual defaults are assigned in SortableTable.
    sortable = true,
    numeric = false,
    sortDefaultAsc = true,
    checkbox = false,
    loading = false,
    skeletonWidth = 100,
}: SortableTableColumnProps<T>) => null;
