import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const NotificationActive = ({ fill, ...props }) => (
    <SvgIcon {...props}>
        <path
            fill={fill}
            d="M12 22C10.896 21.9946 10.0009 21.1039 9.99 20H13.99C13.9924 20.2673 13.9418 20.5324 13.841 20.78C13.5785 21.3819 13.0427 21.8209 12.401 21.96H12.396H12.383H12.373H12.362H12.355C12.2381 21.9842 12.1193 21.9976 12 22ZM20 19H4V17L6 16V10.5C5.94732 9.08912 6.26594 7.68913 6.924 6.44C7.57904 5.28151 8.6987 4.45888 10 4.18V2H14V4.18C16.579 4.794 18 7.038 18 10.5V16L20 17V19ZM21.97 10H19.97C19.8769 7.4139 18.6337 5.00451 16.58 3.43L18 2C19.1961 2.93048 20.1625 4.12311 20.825 5.486C21.51 6.89703 21.8996 8.43307 21.97 10ZM4 10H2C2.07036 8.43307 2.45998 6.89702 3.145 5.486C3.80746 4.12311 4.77395 2.93048 5.97 2L7.39 3.43C5.33611 5.00432 4.09286 7.41382 4 10Z"
        />
    </SvgIcon>
);

export default NotificationActive;
