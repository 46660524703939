import React from 'react';

import { Box, Skeleton } from '@mui/material';

import { ProgressChart } from 'components/templates/charts';
import { Typography, TypographyProps } from 'components/tokens/Typography';
import { brandPalette } from 'design/theme/colors';

import { TileBase } from '../types';
import { getHighlightIndex } from '../utils';
import Tile from './Tile';

interface DonutGridTileProps extends TileBase {
    description?: React.ReactNode;
    size?: number;
    labelVariant?: TypographyProps['variant'];
}

const COLORS = [brandPalette.primaryGreen500, brandPalette.secondaryPurple700];
const ACCENT_COLORS = [brandPalette.primaryGreen100, brandPalette.secondaryPurple100];

const DonutGridTile: React.FC<DonutGridTileProps> = ({
    title,
    data,
    total,
    sx,
    description,
    size = 75,
    labelVariant = 'body2',
}) => {
    const highlightIndex = getHighlightIndex(data);

    return (
        <Tile title={title} sx={{ textAlign: 'center', ...sx }}>
            {data.map((row, idx) => (
                <Box key={idx} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        {!total ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                    height: size,
                                    width: size,
                                }}
                            >
                                <Skeleton variant="circular" sx={{ width: size - 15, height: size - 15 }} />
                                <Box
                                    sx={{
                                        width: size - 30,
                                        height: size - 30,
                                        borderRadius: '50%',
                                        position: 'absolute',
                                        inset: 'auto',
                                        backgroundColor: 'common.white',
                                    }}
                                />
                                <Skeleton width={30} sx={{ position: 'absolute', inset: 'auto' }} />
                            </Box>
                        ) : (
                            <ProgressChart
                                total={total || 1}
                                value={row.value || 0}
                                hideTooltips
                                color={COLORS[idx % COLORS.length]}
                                unfilledColor={ACCENT_COLORS[idx % ACCENT_COLORS.length]}
                                height={size}
                                TypographyProps={{
                                    variant: labelVariant,
                                    weight: highlightIndex === idx ? 'semibold' : 'normal',
                                }}
                            />
                        )}
                    </Box>
                    <Typography variant="body2" weight={highlightIndex === idx ? 'semibold' : 'normal'}>
                        {row.label}
                    </Typography>
                </Box>
            ))}

            {description && (
                <Typography variant="caption" color="subtle" sx={{ marginTop: 1 }}>
                    {description}
                </Typography>
            )}
        </Tile>
    );
};

export default DonutGridTile;
