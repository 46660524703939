import { FC } from 'react';

import ListMenu from 'components/templates/ListMenu';
import Button from 'components/tokens/Button';

import { TriggersStateButtonProps } from './types';

/**
 * Renders a button with Icon and label, when clicked it opens a dropdown menu to display a list of items.
 * @param props Props to be passed to the component
 * @param icon Icon to be displayed in the button
 * @param options List of items to be displayed in the dropdown menu in scrollable list
 * @param staticOptions Static options to be displayed in the dropdown menu, always fixed at bottom, not scrolling
 * @param label Label to be displayed in the button
 * @param onClick callback to be called when an item in the dropdown menu is clicked
 */
export const TriggersStateButton: FC<TriggersStateButtonProps> = ({
    icon,
    options: items,
    staticOptions,
    onClick,
    label,
}) => {
    return (
        <ListMenu
            anchorElement={
                <Button variant="secondary" size="small" startIcon={icon}>
                    {label}
                </Button>
            }
            popperPlacement="bottom-end"
            closeOnMouseLeave={false}
            items={items}
            staticOptions={staticOptions}
            onClick={onClick}
        />
    );
};
