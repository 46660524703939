import React, { useEffect, useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { useQuery } from '@tanstack/react-query';

import { getSlackChannels } from 'api/Slack';
import Select, { SelectOption, SelectOptionTypes } from 'components/tokens/select-components/Select';
import Switch from 'components/tokens/Switch';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';

export interface SlackDestinationType {
    system: 'slack';
    slackChannel: string[];
    active: boolean;
    isValid?: boolean;
}

interface Props {
    destination: SlackDestinationType | null;
    updateDestination: (obj: SlackDestinationType) => void;
    enableDestination: boolean;
    hideHeader?: boolean;
}

const validate = (obj: Pick<SlackDestinationType, 'active' | 'slackChannel'>) => {
    const { active, slackChannel } = obj;
    return !!(active && slackChannel?.[0]);
};

const SlackDestination: React.FC<Props> = ({
    destination,
    updateDestination,
    enableDestination,
    hideHeader = false,
}) => {
    const axios = useAxiosContext();
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [enabled, setEnabled] = useState(!!destination?.active);
    const [selectedChannel, setSelectedChannel] = useState(destination?.slackChannel?.[0] || '');
    const { data, isLoading, error, isError } = useQuery({
        queryKey: ['slackChannels'],
        queryFn: () => getSlackChannels(axios),
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: enabled && enableDestination,
        gcTime: Infinity,
    });

    useEffect(() => {
        if (error) {
            setShowErrorSnackbar(true);
        }
    }, [error]);

    const options: SelectOption[] = useMemo(() => {
        const slackChannels = data?.data || [];
        return slackChannels.map((item) => ({ value: item.id, label: item.name }));
    }, [data]);

    const selectedOption: SelectOptionTypes =
        useMemo(() => {
            return options.find((i) => i.value === selectedChannel);
        }, [selectedChannel, options]) || null;

    if (!enableDestination) {
        return null;
    }

    if (isLoading && !isError) {
        return (
            <Grid container alignItems="center" justifyContent="center">
                <CircularProgress size={40} />
            </Grid>
        );
    }
    const selectChannel = (channel: SelectOptionTypes) => {
        let stringValue = '';
        if (typeof channel === 'string') {
            stringValue = channel;
        }
        if (Array.isArray(channel)) {
            const [first] = channel;
            if (typeof first === 'string') {
                stringValue = `${first}`;
            } else {
                stringValue = `${first?.value || ''}`;
            }
            return;
        } else {
            const { value } = channel as SelectOption;
            stringValue = `${value}`;
        }
        setSelectedChannel(stringValue);
        updateDestination({
            system: 'slack',
            slackChannel: [stringValue],
            active: true,
            isValid: validate({
                slackChannel: [stringValue],
                active: true,
            }),
        });
    };

    const onEnableToggle = (enable: boolean) => {
        setEnabled(enable);
        updateDestination({
            system: 'slack',
            slackChannel: [selectedChannel],
            active: enable,
            isValid: validate({
                slackChannel: [selectedChannel],
                active: enable,
            }),
        });
    };

    return (
        <>
            {hideHeader ? null : (
                <Grid
                    container
                    key={destination?.system}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ padding: '5px 0px 5px 0px' }}
                >
                    <Typography sx={{ fontWeight: 500, fontSize: 14 }}>Slack</Typography>
                    <Switch checked={enabled} onChange={(e) => onEnableToggle(e.target.checked)} />
                </Grid>
            )}
            <Grid container>
                {enabled && options?.length ? (
                    <Select
                        width="100%"
                        multiple={false}
                        placeholder="Select channel"
                        value={selectedOption}
                        onValueChange={(i) => selectChannel(i)}
                        options={options}
                    />
                ) : null}
                {enabled && !options?.length ? (
                    <Typography
                        sx={{
                            fontSize: 12,
                            lineHeight: '12px',
                            fontWeight: 300,
                            paddingLeft: 0,
                            color: '#828282',
                        }}
                    >
                        No slack channels found
                    </Typography>
                ) : null}
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={showErrorSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowErrorSnackbar(false)}
                message={<span>Failed to get Slack channels!</span>}
            />
        </>
    );
};

export default SlackDestination;
