import React, { useMemo, useState, useCallback } from 'react';

import { stopAsyncProcessByProcessId } from 'api/asyncProcesses';
import { CRMBulkExportAsyncProcess, ExportedObjectsCounters } from 'api/types/UserAsyncProcess';
import { CRMObject, CrmName } from 'components/modules/connectors/types/Crm';
import { getObjectPluralForm } from 'components/modules/connectors/utils/CRMTargetText';
import Icon from 'components/tokens/Icon';
import { useAxiosContext } from 'contexts/AxiosContext';

import { getCRMName } from '../../../modules/connectors/utils';
import AdditionalNotificationDialog from '../AdditionalNotificationDialog';
import { crmLogo, AsyncProcessNotificationProps } from '../NotificationItem';
import NotificationItemContent, { NotificationItemContentProps } from '../NotificationItemContent';
import { progress } from '../utils';
import { GreenText } from './sharedComps';

export type NotificationStateBasedData = Pick<
    NotificationItemContentProps,
    'error' | 'title' | 'date' | 'content' | 'progress' | 'hoverText' | 'customContent'
>;

type GroupedExportCounters = {
    [value: number]: CRMObject[];
};

/**
 * Sent CRM objects counters with identical values ​​should be merged in a notification's text
 *
 * input: { 'Company': 10, 'Contact': 20, 'Deal': 10, 'Task': 10 }
 * output: { 10: ['Company', 'Deal', 'Task'], 20: ['Contact'] }
 * text: '10 Companies, Deals & Tasks, 20 Contacts sent'
 */
const getGroupedCounters = (sentObjects: ExportedObjectsCounters) => {
    return (Object.entries(sentObjects) as [CRMObject, number][]).reduce<GroupedExportCounters>(
        (acc, [crmObject, count]) => {
            return !acc[count] ? { ...acc, [count]: [crmObject] } : { ...acc, [count]: [...acc[count], crmObject] };
        },
        {},
    );
};

const getStateBasedData = (notification: CRMBulkExportAsyncProcess): NotificationStateBasedData => {
    const {
        state,
        meta_data: { crm, size, completed_exports, target_group_name = '', sent_data_counts },
    } = notification;

    const crmName = getCRMName(crm);

    const groupedCounters = getGroupedCounters(sent_data_counts ?? {});

    switch (state) {
        case 'accepted':
        case 'process':
            return {
                title: target_group_name ? `Sending “${target_group_name}” to ${crmName}` : `Sending to ${crmName}`,
                customContent: <ExportCounters crm={crm} counters={groupedCounters} />,
                progress: progress(completed_exports, size),
                hoverText: 'In progress',
            };
        case 'completed':
            return {
                title: `✔️ ${target_group_name ? `"${target_group_name}" sent to ${crmName}` : `Sent to ${crmName}`}`,
                customContent: <ExportCounters crm={crm} counters={groupedCounters} />,
                hoverText: 'Complete',
            };
        case 'stopped':
            return {
                title: target_group_name ? `“${target_group_name}” export was stopped` : 'Export was stopped',
                customContent: <ExportCounters crm={crm} counters={groupedCounters} />,
                hoverText: 'Stopped',
            };
        default:
            return {
                error: true,
                title: 'Error occured in export',
                content: `List export to ${crmName} failed.`,
                hoverText: 'Click to see details',
            };
    }
};

interface ExportCountersProps {
    crm: CrmName;
    counters: GroupedExportCounters;
}

const ExportCounters: React.FC<ExportCountersProps> = ({ crm, counters }) => {
    return (
        <>
            {Object.entries(counters).map(([count, keys], idx, arr) => {
                const valueEl = <GreenText>{count}</GreenText>;
                const crmObjects = keys
                    .map((key) => (Number(count) > 1 ? getObjectPluralForm(crm, key) : key))
                    .join(', ');
                const separator = idx === arr.length - 1 ? ' sent' : ', ';

                return (
                    <React.Fragment key={count}>
                        {valueEl} {keys.length > 1 ? crmObjects.replace(/,([^,]*)$/, ' & $1') : crmObjects}
                        {separator}
                    </React.Fragment>
                );
            })}
        </>
    );
};

const CRMBulkExport: React.FC<AsyncProcessNotificationProps<CRMBulkExportAsyncProcess>> = ({
    notification,
    handleMarkAsSeen,
}) => {
    const axios = useAxiosContext();
    const [isErrorDialogOpened, setErrorDialogOpened] = useState(false);
    const notificationData = useMemo(() => getStateBasedData(notification), [notification]);

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            if (notification.state === 'failure') {
                setErrorDialogOpened(true);
            }
        },
        [notification.state],
    );

    if (!notification.state || !notification.meta_data) return null;

    return (
        <>
            <NotificationItemContent
                icon={<Icon type={crmLogo[notification.meta_data.crm]} />}
                date={notification.finished || notification.created}
                {...notificationData}
                state={notification.state}
                seen={notification.seen}
                onClick={handleClick}
                onCancel={() => stopAsyncProcessByProcessId(axios, notification.id)}
                handleMarkAsSeen={handleMarkAsSeen}
            />
            <AdditionalNotificationDialog
                isOpened={isErrorDialogOpened}
                errorMessage={notification.error_msg}
                notificationType="CRMBulkExport"
                error={notification.error}
                exportSize={notification.meta_data.size}
                onClose={() => setErrorDialogOpened(false)}
            />
        </>
    );
};

export default CRMBulkExport;
