export type DiagramItemType = {
    label: string;
    color: string;
    percentage: number;
    count: number;
};

export const getCompaniesDiagramConfig = ({
    isDomainConnector,
    matched,
    unmatched,
    needUpgrade,
    unsupported,
    addedAndMatched,
    missingFromDBCount,
    total,
}: {
    isDomainConnector: boolean;
    matched: number;
    unmatched: number;
    needUpgrade: number;
    unsupported: number;
    addedAndMatched: number;
    missingFromDBCount: number;
    total: number;
}): DiagramItemType[] => {
    return [
        {
            label: 'Matched with Vainu',
            percentage: (matched * 100) / total,
            color: '#079992',
            count: matched,
        },
        isDomainConnector && {
            label: 'Added and matched',
            percentage: (addedAndMatched * 100) / total,
            color: '#4F80FF',
            count: addedAndMatched,
        },
        {
            label: 'Need help',
            percentage: (unmatched * 100) / total,
            color: '#E84D9E',
            count: unmatched,
        },
        isDomainConnector && {
            label: 'Missing from database',
            percentage: (missingFromDBCount * 100) / total,
            color: '#3F3F3F',
            count: missingFromDBCount,
        },
        !isDomainConnector && {
            label: 'Not supported',
            percentage: (unsupported * 100) / total,
            color: '#898989',
            count: unsupported,
        },
        !isDomainConnector && {
            label: 'Need an upgrade',
            percentage: (needUpgrade * 100) / total,
            color: '#9F1DAD',
            count: needUpgrade,
        },
    ].filter((configItem): configItem is DiagramItemType => (!!configItem ? !!configItem.percentage : false));
};

export const getLeadsDiagramConfig = ({
    matched,
    unmatched,
    total,
}: {
    matched: number;
    unmatched: number;
    total: number;
}): DiagramItemType[] => {
    return [
        {
            label: 'Matched with Vainu',
            percentage: (matched * 100) / total,
            color: '#079992',
            count: matched,
        },
        {
            label: 'Not matched',
            percentage: (unmatched * 100) / total,
            color: '#E84D9E',
            count: unmatched,
        },
    ];
};
