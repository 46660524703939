import React from 'react';

import { Box, Tooltip } from '@mui/material';
import { assertArray } from 'utilities';

import { SignalTag } from 'api/types/signals/signals';
import Tag from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';

type SignalTagsProps = {
    tags?: SignalTag[] | null;
    selectedTagIds?: number[];
};

const SignalItemTags: React.VFC<SignalTagsProps> = ({ tags, selectedTagIds = [] }) => {
    const sortedTags = [...assertArray(tags)].sort(compareTagsToSelectedTags(selectedTagIds));
    const firstTag = sortedTags[0];
    const otherTags = sortedTags.slice(1);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 1,
                marginBottom: 1,
            }}
        >
            {/* First tag visible, rest in a tooltip */}
            {firstTag ? (
                <Tag
                    size="medium"
                    label={firstTag.value}
                    sx={{ maxWidth: '85%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                />
            ) : (
                <Tag label="Other" />
            )}
            {otherTags.length > 0 && (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            {otherTags.map(({ id, value }) => (
                                <Box
                                    sx={{
                                        width: 130,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                    key={id}
                                >
                                    {value}
                                </Box>
                            ))}
                        </div>
                    }
                >
                    <Typography
                        variant="mini"
                        color="subtle"
                        sx={{ cursor: 'help', textDecoration: 'underline', marginLeft: '8px' }}
                    >
                        +{otherTags.length}
                    </Typography>
                </Tooltip>
            )}
        </Box>
    );
};

export default SignalItemTags;

/**
 * Puts the tags that are in the current query before the tags that aren't. The last selected tag is put first. Use with Array.prototype.sort().
 */
function compareTagsToSelectedTags(selectedTagIds: number[] | undefined | null) {
    return (tagA: SignalTag, tagB: SignalTag) => {
        if (!Array.isArray(selectedTagIds)) {
            return 0;
        }
        return selectedTagIds.indexOf(tagB.id) - selectedTagIds.indexOf(tagA.id);
    };
}
