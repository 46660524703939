import { Point } from '../types';

// Note: all the functions define the axis signs in the computer graphics-way, where positive y is down

/**
 * Tries to calculate a normalized delta of a mouse wheel change, so that when using the mouse wheel
 * in different browsers it would zoom in and out the same amount.
 */
export function getNormalizedDelta(event: React.WheelEvent) {
    switch (event.deltaMode) {
        case WheelEvent.DOM_DELTA_LINE:
            return event.deltaY * 20;
        case WheelEvent.DOM_DELTA_PAGE:
            return event.deltaY * 60;
        case WheelEvent.DOM_DELTA_PIXEL:
        default:
            return event.deltaY;
    }
}

/**
 * Calculates the centroid, i.e. the center of mass, for a shape.
 */
export const getCentroid = (bounds: Point[]) => {
    if (bounds.length === 1) {
        return { ...bounds[0] };
    }
    if (bounds.length === 2) {
        return { x: (bounds[0].x + bounds[1].x) / 2, y: (bounds[0].y + bounds[1].y) / 2 };
    }
    const sum = bounds.reduce(
        (acc, _curr, index) => {
            const p1 = bounds[index];
            const p2 = bounds[index === 0 ? bounds.length - 1 : index - 1];
            const dotProduct = p1.x * p2.y - p2.x * p1.y;

            return { x: acc.x + (p1.x + p2.x) * dotProduct, y: acc.y + (p1.y + p2.y) * dotProduct };
        },
        { x: 0, y: 0 },
    );
    const f = getArea(bounds) * 6;
    return { x: sum.x / f, y: sum.y / f };
};

/**
 * Calculates the area inside the set of points forming a closed path. If the shape is not convex,
 * the counter-clockwise-winding area is subtracted from the clockwise-winding area.
 */
const getArea = (bounds: Point[]) =>
    bounds.reduce((acc, _curr, index) => {
        const p1 = bounds[index];
        const p2 = bounds[index === 0 ? bounds.length - 1 : index - 1];
        const addedArea = p1.x * p2.y - p2.x * p1.y;
        return acc + addedArea;
    }, 0) / 2;

/**
 * Calculates the most outwards reach of a set of points for specific axes. Used to determine the bounding box
 * of the set.
 */

export const getMinX = (points: Point[]) => points.reduce((acc, curr) => Math.min(acc, curr.x), points[0].x);
export const getMinY = (points: Point[]) => points.reduce((acc, curr) => Math.min(acc, curr.y), points[0].y);
export const getMaxX = (points: Point[]) => points.reduce((acc, curr) => Math.max(acc, curr.x), points[0].x);
export const getMaxY = (points: Point[]) => points.reduce((acc, curr) => Math.max(acc, curr.y), points[0].y);

export const getBoundsRect = (points: Point[]) => {
    return { left: getMinX(points), top: getMinY(points), right: getMaxX(points), bottom: getMaxY(points) };
};

/**
 * Returns the center of a bounding rectangle for a polygon.
 */
export const getCenter = (points: Point[]): Point => {
    const { left, top, right, bottom } = getBoundsRect(points);
    const x = (left + right) / 2;
    const y = (top + bottom) / 2;
    return { x, y };
};

/**
 * Returns the coordinates of a point which has the largest x value.
 * A version of the getMaxX that also returns the respective y value of the point.
 */
export const getEasternmostPoint = (points: Point[]) =>
    points.reduce((acc, curr) => (curr.x > acc.x ? curr : acc), points[0]);

/**
 * Returns the point whose y coordinate is at the center of the cluster bounding rect
 * and its x coordinate same as for the rightmost point.
 */
export const getRightEdgeCenter = (points: Point[]) => {
    const { top, right, bottom } = getBoundsRect(points);
    const y = (top + bottom) / 2;
    const x = right;
    return { x, y };
};

/**
 * Calculates if a path is winding clockwise or counter-clockwise. This is used to determine which
 * normal vector points outwards from a path segment.
 */
const isClockwise = (path: Point[]) => getArea(path) > 0;

export type OffsetSegment = [p1: Point, p2: Point];

/**
 * Takes two path points and returns a new path segment that is offset by the given amount towards
 * the segment normal. The offset direction is outwards from the full path.
 */
export const offsetSegment = (p1: Point, p2: Point, offset: number, clockwise: boolean): OffsetSegment => {
    const length = Math.hypot(p2.x - p1.x, p2.y - p1.y);
    const sign = clockwise ? 1 : -1;
    const normal = { x: ((p1.y - p2.y) / length) * offset * sign, y: ((p2.x - p1.x) / length) * offset * sign };
    return [
        { x: p1.x + normal.x, y: p1.y + normal.y },
        { x: p2.x + normal.x, y: p2.y + normal.y },
    ];
};

/**
 * Draws a path segment between two points and then continues it as a circle arc towards the third point.
 * Returns a string for the d attribute of the <path> element.
 * */
const getRoundedPath = (segments: OffsetSegment[], offset: number, clockwise: boolean) => {
    const firstPoint = segments[0][0];
    const start = `M ${firstPoint.x},${firstPoint.y} `;
    const segmentsStr = segments
        .map(([p1, p2], index, segments) => {
            const nextSegment = index === segments.length - 1 ? segments[0] : segments[index + 1];
            const p3 = nextSegment[0];
            const line = `L ${p2.x},${p2.y} `;
            const arc = `A ${offset} ${offset} 0 0 ${clockwise ? 0 : 1} ${p3.x},${p3.y}`;
            return line + arc;
        })
        .join('');
    const end = 'Z';
    return start + segmentsStr + end;
};

/**
 * Returns path data for a path that is offset outwards from the original path by the offset amount,
 * with rounded corners. Returns a string for the d attribute of the <path> element.
 */
export const offsetPath = (path: Point[], offset: number): string => {
    const segments = path.map<OffsetSegment>((p, index, points) => [
        p,
        index === points.length - 1 ? points[0] : points[index + 1],
    ]);
    const clockwise = isClockwise(path);
    const offsetSegments = segments.map(([p1, p2]) => offsetSegment(p1, p2, offset, clockwise));
    return getRoundedPath(offsetSegments, offset, clockwise);
};
