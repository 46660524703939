import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InCrmHubSpot: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.8206 6.56077V8.80473C18.419 9.0474 19.7661 10.0519 20.4718 11.4489C19.7032 11.1588 18.8701 11 18 11C17.4566 11 16.9276 11.0619 16.4197 11.1791C16.304 11.1586 16.1855 11.1459 16.0648 11.1417C15.7279 11.1326 15.3927 11.1912 15.0788 11.3138C14.7649 11.4365 14.4788 11.6208 14.2373 11.8558C14.0766 12.0122 13.9379 12.189 13.8245 12.3812C12.7832 13.1562 11.9648 14.2138 11.4827 15.4405C10.9123 13.9766 11.0701 12.2986 11.9636 10.9489L5.90325 6.23305C5.61197 6.40238 5.28137 6.49233 4.94445 6.49392C4.55932 6.49342 4.18299 6.37871 3.86307 6.16431C3.54314 5.9499 3.29399 5.64543 3.14712 5.2894C3.00026 4.93337 2.96228 4.54179 3.038 4.16417C3.11371 3.78656 3.29972 3.43989 3.57249 3.168C3.84525 2.89611 4.19253 2.71123 4.57038 2.63674C4.94824 2.56225 5.3397 2.6015 5.69525 2.74952C6.05079 2.89753 6.35446 3.14767 6.56782 3.4683C6.78119 3.78892 6.89467 4.16562 6.89392 4.55075C6.89134 4.71426 6.86844 4.87717 6.82456 5.03559L12.9789 9.82925C13.6507 9.28874 14.4534 8.93533 15.3057 8.80481V6.56077C15.0092 6.42217 14.7581 6.2022 14.5817 5.92645C14.4053 5.6507 14.3108 5.33053 14.3093 5.00319V4.9517C14.3093 3.99731 15.083 3.22361 16.0374 3.22361H16.089C17.0433 3.22361 17.817 3.99731 17.817 4.9517V5.00319C17.8155 5.33053 17.721 5.6507 17.5446 5.92645C17.3682 6.2022 17.1172 6.42217 16.8206 6.56077ZM11.0176 17.4993L10.1954 18.3218C10.0463 18.2742 9.89118 18.2488 9.73474 18.2467C8.85199 18.2475 8.13686 18.9634 8.13709 19.8461C8.13732 20.7288 8.8529 21.4443 9.73557 21.4445C10.5364 21.4447 11.1998 20.8563 11.3167 20.088C11.1109 19.4286 11 18.7272 11 18C11 17.8316 11.0059 17.6647 11.0176 17.4993Z"
            fill="#898989"
        />
        <path
            d="M14.0399 18.2534L13.7058 18.6003L14.0399 18.9471L16.4399 21.4391L16.8 21.8131L17.1601 21.4391L21.9601 16.455L22.2942 16.1082L21.9601 15.7614L20.8929 14.6532L20.5326 14.279L20.1724 14.6533L16.8003 18.1583L15.8279 17.1457L15.4678 16.7707L15.1071 17.1452L14.0399 18.2534ZM12.5 18C12.5 14.9624 14.9624 12.5 18 12.5C21.0376 12.5 23.5 14.9624 23.5 18C23.5 21.0376 21.0376 23.5 18 23.5C14.9624 23.5 12.5 21.0376 12.5 18Z"
            fill="#079992"
            stroke="#079992"
        />
    </SvgIcon>
);

export default InCrmHubSpot;
