import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Minus = (props) => (
    <SvgIcon {...props}>
        <path d="M5 13V11H19V13H5Z" />
    </SvgIcon>
);

export default Minus;
