import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axiosFactory, { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { APIError, useAxiosContext } from 'contexts/AxiosContext';
import { EmbeddedQuery } from 'contexts/EmbeddedQueryContext';
import { Database } from 'contexts/ListsContext';

import {
    ProfileData,
    ProfileDataFields,
    DomainDataFields,
    OrganizationFields,
    ProfileDataAPIResponse,
    ProfileType,
    Organization,
} from './types/Organization';

export type GetOrganizationParams = Partial<{
    business_id: string;
    fields: string;
}>;

export type GetDomainParams = Partial<{
    domain: string;
    fields: string;
}>;

export type ProfileDataParams = {
    type: ProfileType;
    key: string | undefined;
};

export type ProfileDataParamsV3 = {
    type: ProfileType;
    key: string | undefined;
    database: Database | undefined;
};

export type BasicDataFields = Pick<
    ProfileDataFields,
    | 'name'
    | 'email'
    | 'tel'
    | 'auxiliary_names'
    | 'founded'
    | 'legal_entity'
    | 'logo_url'
    | 'industries'
    | 'industries_verbose'
    | 'organization_size_indicators'
    | 'website'
    | 'business_units'
    | 'vainu_custom_industries'
    | 'description'
    | 'metadata'
    | 'official_status'
> & {
    website_data?: Pick<Required<ProfileDataFields>['website_data'], 'screenshot_url' | 'website_traffic_data'>;
};

export type BasicDataDomainData = BasicDataFields & DomainDataFields;
export type BasicDataOrganization = BasicDataFields & OrganizationFields;
export type BasicData = BasicDataDomainData | BasicDataOrganization;
export function useBasicData(params: ProfileDataParams): UseQueryResult<BasicData> {
    const { type } = params;
    const domainFields = ['business_ids', 'countries'];
    const organizationFields = [
        'business_id',
        'country',
        'auxiliary_names',
        'industries',
        'industries_verbose',
        'legal_entity',
        'level_in_group',
        'group_data',
        'official_status',
        'direct_marketing_denied',
    ];
    const fields = [
        'name',
        'email',
        'tel',
        'founded',
        'logo_url',
        'vainu_custom_industries',
        'organization_size_indicators',
        'website',
        'website_data.screenshot_url',
        'website_data.website_traffic_data.ranking',
        'business_units',
        'description',
        'metadata',
        ...(type === 'domain' ? domainFields : organizationFields),
    ];
    return useProfileData(params, fields);
}

export type WebProfileData = Pick<
    ProfileData,
    'technology_data' | 'website_data' | 'website' | 'social_media' | 'indexes' | 'metadata'
>;
export function useWebProfileData(params: ProfileDataParams): UseQueryResult<WebProfileData> {
    const fields = [
        'technology_data.id',
        'technology_data.name',
        'technology_data.types',
        'technology_data.description',
        'technology_data.logo_url',
        'website_data.websites',
        'website_data.website_traffic_data',
        'website_data.screenshot_thumb_url',
        'website',
        'social_media',
        'indexes',
        'metadata',
    ];
    return useProfileData(params, fields);
}

export type BusinessUnitsData = Pick<ProfileData, 'name' | 'business_units'>;
export function useBusinessUnits(params: ProfileDataParams): UseQueryResult<BusinessUnitsData> {
    const fields = ['name', 'business_units', 'metadata'];
    return useProfileData(params, fields);
}

export type BasicDataPlus = Pick<ProfileData, 'basic_data_plus'>;
export function useBasicDataPlus(params: ProfileDataParams): UseQueryResult<BasicDataPlus> {
    const fields = ['basic_data_plus'];
    return useProfileData(params, fields);
}

export type VainuCustomIndustriesData = Pick<ProfileData, 'vainu_custom_industries'>;
export function useVainuCustomIndustries(params: ProfileDataParams): UseQueryResult<VainuCustomIndustriesData> {
    const fields = ['vainu_custom_industries'];
    return useProfileData(params, fields);
}

export type RegistersData = Pick<ProfileData, 'registers'>;
export function useRegisters(params: ProfileDataParams): UseQueryResult<RegistersData> {
    const fields = ['registers'];
    return useProfileData(params, fields);
}

export type FinancialStatementData = Pick<ProfileData, 'name' | 'financial_statements'>;
export function useFinancialStatements(params: ProfileDataParams): UseQueryResult<FinancialStatementData> {
    const fields = ['name', 'financial_statements'];
    return useProfileData(params, fields);
}

export type LocationsData = Pick<ProfileData, 'business_units' | 'name'>;
export function useLocations(params: ProfileDataParams): UseQueryResult<LocationsData> {
    const fields = ['business_units', 'name'];
    return useProfileData(params, fields);
}

export type GroupData = Pick<Organization, 'group_data'>;
export function useGroupData(params: ProfileDataParams): UseQueryResult<ProfileData> {
    const fields = ['level_in_group', 'group_data'];
    return useProfileData(params, fields);
}

export type EmployeesData = Pick<Organization, 'contacts' | 'organization_size_indicators' | 'country'>;
const PUBLIC_CONTACT_FIELDS = [
    'contacts.uid',
    'contacts.metadata.phone_available',
    'contacts.metadata.email_available',
    'contacts.titles',
];
const PRIVATE_CONTACT_FIELDS = [
    ...PUBLIC_CONTACT_FIELDS,
    'contacts.email',
    'contacts.phone',
    'contacts.web_profiles',
    'contacts.first_name',
    'contacts.last_name',
    'contacts.full_name',
    'contacts.metadata.source_types',
    'contacts.metadata.fields.phone_number.vainu_verified',
    'contacts.metadata.fields.email.vainu_verified',
];
export const getContactFieldsByPermission = (hasPermission: boolean) => {
    return hasPermission ? PRIVATE_CONTACT_FIELDS : PUBLIC_CONTACT_FIELDS;
};
export function useEmployees(
    params: ProfileDataParams,
    hasContactDataPermission: boolean,
): UseQueryResult<EmployeesData> {
    const contactFields = getContactFieldsByPermission(hasContactDataPermission);
    const fields = ['organization_size_indicators', 'country', ...contactFields];
    return useProfileData(params, fields);
}

export type SocialNetworksData = Pick<ProfileData, 'social_media' | 'indexes' | 'name'>;
export function useSocialNetworks(params: ProfileDataParams): UseQueryResult<SocialNetworksData> {
    const fields = ['social_media', 'indexes', 'name'];
    return useProfileData(params, fields);
}

export type SimilarDomainsData = Pick<ProfileData, 'similar_domains'>;
export function useSimilarDomains(params: ProfileDataParams): UseQueryResult<SimilarDomainsData> {
    const fields = ['similar_domains'];
    return useProfileData(params, fields);
}

export type VehicleCountData = Pick<ProfileData, 'vehicles_count'>;
export function useVehicleCount(params: ProfileDataParamsV3, hasPermission: boolean): UseQueryResult<VehicleCountData> {
    const fields = ['vehicles_count'];
    return useProfileDataV3(params, fields, { enabled: hasPermission && !!params.database });
}

/**
 * Transforms the API response and injects the normalized profile type and id, and creates the UUIDs
 * for the subdocument lists that miss an id.
 */
export const addIdAndType = (data: ProfileDataAPIResponse): ProfileData =>
    'business_id' in data
        ? {
              ...data,
              id: data.business_id,
              profileType: 'organization',
              business_units: data.business_units?.map((unit) => ({
                  ...unit,
                  uid: unit.uid ?? uuidv4(),
              })),
          }
        : {
              ...data,
              id: data.website,
              profileType: 'domain',
              business_units: data.business_units?.map((unit) => ({
                  ...unit,
                  uid: unit.uid ?? uuidv4(),
              })),
          };

export type QueryResponse = {
    results: ProfileDataAPIResponse[];
};

const organizationKeyField = 'business_id';
const domainKeyField = 'website';
const addProfileKeyField = (fields: string[], type: ProfileType) => {
    if (type === 'organization' && !fields.includes(organizationKeyField)) {
        return [organizationKeyField, ...fields];
    }
    if (type === 'domain' && !fields.includes(domainKeyField)) {
        return [domainKeyField, ...fields];
    }
    return fields;
};

type ProfileDataOptions = Omit<
    UseQueryOptions<AxiosResponse<QueryResponse>, APIError, ProfileData>,
    'queryKey' | 'queryFn' | 'select'
>;
export function useProfileData(params: ProfileDataParams, fields: string[], options?: ProfileDataOptions) {
    const { type, key } = params;
    // Note: in all other contexts the key field for domain data is 'website',
    // but in the query it is 'domain'.
    const queryKeyField = type === 'organization' ? 'business_id' : 'domain';
    const fieldsWithKey = addProfileKeyField(fields, type);
    const axios = useAxiosContext();
    const queryKey = [process.env.REACT_APP_RELEASE_ID ?? '', type, key, ...fieldsWithKey];
    const route = type === 'domain' ? '/api/v2/domains/' : '/api/v2/organizations/';
    const requestParams = { [queryKeyField]: key, fields: fieldsWithKey.join(','), order: 'none', limit: 1 };
    const queryFn = () => axios.get<QueryResponse>(route, { params: requestParams });

    return useQuery<AxiosResponse<QueryResponse>, APIError, ProfileData>({
        retry: 1,
        ...options,
        queryKey,
        queryFn,
        select: (response) => addIdAndType(response.data?.results?.[0]),
    });
}

export function useProfileDataV3(params: ProfileDataParamsV3, fields: string[], options?: ProfileDataOptions) {
    const { type, key } = params;
    // Note: in all other contexts the key field for domain data is 'website',
    // but in the query it is 'domain'.
    const queryKeyField = type === 'organization' ? 'business_id' : 'domain';
    const fieldsWithKey = addProfileKeyField(fields, type);
    const axios = useAxiosContext();
    const queryKey = [process.env.REACT_APP_RELEASE_ID ?? '', type, key, ...fieldsWithKey];
    const route = type === 'domain' ? '/api/v3/domains/' : '/api/v3/organizations/';
    const requestParams = {
        database: params.database,
        fields: fieldsWithKey.join(','),
        order: 'none',
        limit: 1,
        query: {
            '?ALL': [
                {
                    '?EQ': {
                        [queryKeyField]: key,
                    },
                },
            ],
        },
    };
    const queryFn = () => axios.post<ProfileDataAPIResponse[]>(route, requestParams);

    return useQuery<AxiosResponse, APIError, ProfileData>({
        retry: 1,
        ...options,
        queryKey,
        queryFn,
        select: (response) => addIdAndType(response.data[0]),
    });
}

const axiosWithoutAuth = axiosFactory.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const embeddedQueryFields = [
    'name',
    'email',
    'tel',
    'founded',
    'logo_url',
    'vainu_custom_industries',
    'organization_size_indicators',
    'website',
    'website_data.screenshot_url',
    'business_units',
    'description',
    'business_ids',
    'countries',
    'similar_domains',
    'technology_data.id',
    'technology_data.name',
    'technology_data.types',
    'technology_data.description',
    'technology_data.logo_url',
    'website_data.websites',
    'website_data.website_traffic_data',
    'website_data.screenshot_thumb_url',
    'social_media',
    'indexes',
];

export function useProfileDataWithQuery(queryData: EmbeddedQuery, token: string, options?: ProfileDataOptions) {
    const headers = { Authorization: `Bearer ${token}` };
    const queryKey = [process.env.REACT_APP_RELEASE_ID ?? '', queryData];
    const endpoint = '/api/v2/domains/';
    const params = { fields: embeddedQueryFields.join(',') };
    const queryFn = () => axiosWithoutAuth.get<QueryResponse>(endpoint, { params, headers });

    return useQuery<AxiosResponse<QueryResponse>, APIError, ProfileData>({
        retry: 1,
        enabled: 'queries' in queryData && !!queryData.queries[endpoint],
        ...options,
        queryKey,
        queryFn,
        select: (response) => addIdAndType(response.data?.results?.[0]),
    });
}
