import React, { createContext, useCallback, useContext, useRef } from 'react';

export type TransformContextValue = {
    /** A ref to the <svg> element. Used to get the view box. */
    svgRef: React.RefObject<SVGSVGElement>;
    /** A ref to the <g> element that has both the scaling (zoom) transform and
     *  translation (panning) transform applied.  */
    transformRef: React.RefObject<SVGGElement>;
    /** Return the given clientX, clientY as respective SVG coordinates with the current scale and translation. */
    getSVGCoordinates: (clientX: number, clientY: number) => DOMPoint;
    /** Return the given SVG x, y with the current scale and translation as respective client
     *  (screen-space) coordinates. */
    getClientCoordinates: (x: number, y: number) => DOMPoint;
};

const TransformContext = createContext({} as TransformContextValue);

export type TransformProviderProps = {
    children: React.ReactNode;
};

export const TransformProvider: React.FC<TransformProviderProps> = ({ children }) => {
    const transformRef = useRef<SVGGElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);

    /**
     * Transforms the given screen-space coordinates to svg coordinates (with the current
     * zoom and translation transformations applied), or vice versa. Must only be called
     * after the svg and the transforming g elements are mounted.
     */
    const transformCoordinates = useCallback((x: number, y: number, clientToSVG: boolean): DOMPoint => {
        if (!transformRef.current) {
            throw new Error('You should provide a ref to the mounted transforming element.');
        }
        const point = new DOMPoint(x, y);
        const ctm = clientToSVG ? transformRef.current.getScreenCTM()?.inverse() : transformRef.current.getScreenCTM();
        if (!ctm) {
            return new DOMPoint(0, 0);
        }
        const transformedPoint = point.matrixTransform(ctm);
        return transformedPoint;
    }, []);
    const getSVGCoordinates = useCallback(
        (clientX: number, clientY: number): DOMPoint => transformCoordinates(clientX, clientY, true),
        [transformCoordinates],
    );
    const getClientCoordinates = useCallback(
        (x: number, y: number): DOMPoint => transformCoordinates(x, y, false),
        [transformCoordinates],
    );

    const value: TransformContextValue = {
        svgRef,
        transformRef,
        getSVGCoordinates,
        getClientCoordinates,
    };
    return <TransformContext.Provider value={value}>{children}</TransformContext.Provider>;
};

export const useTransformContext = () => useContext(TransformContext);
