import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Terminal = (props) => (
    <SvgIcon {...props}>
        <path d="M20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20ZM4 6V18H20V6H4ZM18 16H12V14H18V16ZM7.414 16L6 14.586L8.293 12.293L6 10L7.414 8.586L11.121 12.293L7.415 16H7.414Z" />
    </SvgIcon>
);

export default Terminal;
