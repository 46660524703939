import React, { useMemo } from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import { styled, Tooltip } from '@mui/material';
import { differenceInDays, formatDistanceToNow, formatRelative } from 'date-fns';

import { UserAsyncProcess } from 'api/types/UserAsyncProcess';
import Icon from 'components/tokens/Icon';
import { formatDate as formatDateUtil, toDateUTC } from 'utilities/date';

import UpdateError from '../common/ErrorComponent';
import { CrmName } from '../types/Crm';
import { localeNumber } from '../utils';
import { MIN_MATCHING_DIAGRAM_ITEM_WIDTH } from './contants';
import { getCompaniesDiagramConfig, DiagramItemType } from './utils';

const formatDate = (date: string) => {
    const now = new Date();
    const dateDiff = differenceInDays(now, toDateUTC(date));

    if (dateDiff < 2) {
        return formatRelative(toDateUTC(date), now);
    }

    return `${formatDistanceToNow(toDateUTC(date))}${dateDiff < 2 ? '' : ` on ${formatDateUtil(toDateUTC(date))}`}`;
};

interface MatchingDiagramProps {
    isDomainConnector: boolean;
    isAutoSyncEnabled: boolean;
    matched: number;
    addedAndMatched: number;
    unmatched: number;
    needUpgrade: number;
    unsupported: number;
    missingFromDBCount: number;
    focusedComponent: string;
    crm: CrmName;
    latestJob?: UserAsyncProcess;
    setFocusedComponent: (component: string) => void;
    enableFullImportSync: () => void;
}

const MatchingResultsDiagram: React.FC<MatchingDiagramProps> = ({
    isDomainConnector,
    isAutoSyncEnabled,
    matched,
    addedAndMatched,
    unmatched,
    needUpgrade,
    unsupported,
    missingFromDBCount,
    focusedComponent,
    crm,
    latestJob,
    setFocusedComponent,
    enableFullImportSync,
}) => {
    // todo: move it to the context??? 🤔
    const total = isDomainConnector
        ? matched + unmatched + addedAndMatched + missingFromDBCount
        : matched + unmatched + needUpgrade + unsupported;

    const diagramConfig = useMemo(
        () =>
            getCompaniesDiagramConfig({
                isDomainConnector,
                matched,
                unmatched,
                needUpgrade,
                unsupported,
                addedAndMatched,
                missingFromDBCount,
                total,
            }),
        [isDomainConnector, matched, unmatched, needUpgrade, unsupported, addedAndMatched, missingFromDBCount, total],
    );

    const formattedDate = latestJob?.finished ? formatDate(latestJob?.finished) : '';

    if (latestJob?.state === 'failure') {
        return (
            <UpdateError
                crm={crm}
                title="Couldn't match"
                confirmText="Try again"
                latestJob={latestJob}
                onConfirm={enableFullImportSync}
            />
        );
    }

    return (
        <Container isWarning={!isAutoSyncEnabled}>
            <ProgressDiagramHeader isAutoSyncEnabled={isAutoSyncEnabled} total={total} formattedDate={formattedDate} />
            <DiagramItemsWrapper>
                {diagramConfig.map(({ percentage, color, label }) => {
                    const formattedPercentage = Number(percentage.toFixed(2));
                    const optimizedWidth =
                        percentage < MIN_MATCHING_DIAGRAM_ITEM_WIDTH
                            ? MIN_MATCHING_DIAGRAM_ITEM_WIDTH
                            : formattedPercentage;
                    const isTooltipOpened = focusedComponent === label;

                    return (
                        <Tooltip
                            key={label}
                            open={isTooltipOpened}
                            arrow
                            title={`${formattedPercentage}%`}
                            placement="top"
                        >
                            <DiagramItem
                                isTooltipOpened={isTooltipOpened}
                                width={optimizedWidth}
                                color={color}
                                onMouseEnter={() => setFocusedComponent(label)}
                                onMouseLeave={() => setFocusedComponent('')}
                            />
                        </Tooltip>
                    );
                })}
            </DiagramItemsWrapper>
            <ProgressDiagramLegend diagramConfig={diagramConfig} />
        </Container>
    );
};

export const DiagramItem = styled('span')<{ isTooltipOpened: boolean; width: number }>(
    ({ color, isTooltipOpened, width }) => ({
        display: 'inline-block',
        width: `${width}%`,
        height: 16,
        marginRight: width > 0 ? 4 : 0,
        background: color,
        opacity: isTooltipOpened ? 1 : 0.5,
        borderRadius: 4,
        cursor: 'pointer',
    }),
);

const LegendItemBadge = styled('span')(({ color }) => ({
    position: 'relative',
    width: '12px',
    height: '12px',
    marginRight: '6px',
    borderRadius: '50%',
    border: `2px solid ${color}`,
    lineHeight: 0,

    '&::after': {
        content: '""',
        position: 'absolute',
        // top, left, width, height values help to prevent pixels distortion on zoom in/out in Chrome
        // top: 0, left: 0, width: 'inherit', left: 'inherit' is better solution, but it cause an visual issue
        left: '-1px',
        top: '-1px',
        width: '11px',
        height: '11px',
        borderRadius: '50%',
        backgroundColor: color,
        opacity: 0.5,
    },
}));

type PropgressDiagramLegendProps = {
    diagramConfig: DiagramItemType[];
};

const ProgressDiagramLegend: React.FC<PropgressDiagramLegendProps> = React.memo(function ({ diagramConfig }) {
    return (
        <DialogLegendItemsContainer>
            {diagramConfig.map(({ label, color }) => {
                return (
                    <DialogLegendItemWrapper key={label}>
                        <LegendItemBadge color={color} />
                        {label}
                    </DialogLegendItemWrapper>
                );
            })}
        </DialogLegendItemsContainer>
    );
});

const ProgressDiagramHeader: React.FC<{ isAutoSyncEnabled: boolean; total: number; formattedDate: string }> = ({
    isAutoSyncEnabled,
    total,
    formattedDate,
}) => {
    return (
        <ProgressDiagramHeaderContainer>
            {isAutoSyncEnabled ? (
                <CheckIcon type="CheckBold" />
            ) : (
                <Tooltip arrow title="You are not syncing company data" placement="top">
                    <StyledWarningIcon />
                </Tooltip>
            )}
            <span>
                <b>{localeNumber(total)}</b>
                {' companies processed ⋅ '}
                <span style={{ color: '#898989' }}>{formattedDate ?? null}</span>
            </span>
        </ProgressDiagramHeaderContainer>
    );
};

const Container = styled('div')<{ isWarning: boolean }>(({ theme: { palette }, isWarning }) => ({
    background: isWarning ? 'rgba(242, 151, 17, 0.1)' : palette.grey[50],
    borderRadius: 16,
    padding: 15,
}));

const StyledWarningIcon = styled(WarningIcon)(({ theme: { palette } }) => ({
    cursor: 'pointer',
    color: palette.warning.main,
}));

const ProgressDiagramHeaderContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    marginBottom: '16px',
    '& svg': {
        marginRight: 8,
        marginLeft: 8,
    },
});

const DiagramItemsWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

const DialogLegendItemsContainer = styled(DiagramItemsWrapper)({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
});

const DialogLegendItemWrapper = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    color: palette.grey[500],
    fontSize: 12,
}));

const CheckIcon = styled(Icon)({
    padding: '2px',
    color: 'white',
    background: 'black',
    borderRadius: '50%',
});

export { MatchingResultsDiagram };
