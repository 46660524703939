import { Box, Skeleton } from '@mui/material';

import Icon, { IconType } from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

export const TooltipRow = ({
    icon,
    children,
    isLoading,
}: {
    icon: IconType;
    children: React.ReactNode;
    isLoading?: boolean;
}) => {
    if (!isLoading && !children) {
        return null;
    }
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:not(:first-of-type)': { marginTop: 1 },
            }}
        >
            {isLoading ? (
                <>
                    <Skeleton width={16} variant="circular" />
                    <Skeleton width={100} height={16.8} />
                </>
            ) : (
                <>
                    <Icon type={icon} sx={{ width: '16px', height: '16px' }} color="grey.500" />
                    <Typography variant="tiny" color="dark">
                        {children}
                    </Typography>
                </>
            )}
        </Box>
    );
};
