import { Box, Skeleton } from '@mui/material';

import { ProfileExportedToCRM } from 'components/modules/connectors/types';
import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import Tags, { TagProps } from 'components/templates/Tags';
import FlagEmoji from 'components/tokens/FlagEmoji';
import StatusIndicator from 'components/tokens/StatusIndicator';
import Typography from 'components/tokens/Typography';

import { getLocation } from '../utils';
import { CRMChip } from './CRMChip';

export type LogoNameAndIndustriesProps = {
    name: string;
    country?: string;
    city?: string;
    logo?: string;
    statusClass?: 'active' | 'warning' | 'danger';
    status?: string;
    industryTags?: TagProps[];
    exportedProfileToCRMs?: ProfileExportedToCRM[][];
    mode?: 'horizontal' | 'vertical';
    showCRMInfoInHeader?: boolean;
    loading?: boolean;
    onIndustryTagClick?: (tag: TagProps, event: React.MouseEvent) => void;
};

export const LogoNameAndIndustries: React.FC<LogoNameAndIndustriesProps> = ({
    loading = false,
    logo,
    name,
    city,
    country,
    industryTags = [],
    status = 'active',
    statusClass = 'active',
    mode = 'horizontal',
    exportedProfileToCRMs = [],
    showCRMInfoInHeader = false,
    onIndustryTagClick,
}) => {
    const crmButtonWidth = 48;
    const nameSideColumnWidth = crmButtonWidth * exportedProfileToCRMs.length;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: mode === 'vertical' ? 'center' : 'flex-start',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: 1.5,
                    flexDirection: mode === 'vertical' ? 'column' : 'row',
                    alignItems: 'center',
                }}
            >
                <CompanyLogo
                    sx={{ marginBottom: mode === 'vertical' ? 2 : 0, marginRight: mode === 'vertical' ? 0 : 1 }}
                    skeleton={!logo && loading}
                    variant="round"
                    size={mode === 'vertical' ? 'large' : 'small'}
                    logo={logo}
                    company_name={name}
                />
                <Box
                    sx={{
                        display: 'flex',
                        width: mode === 'vertical' ? undefined : '100%',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginBottom: mode === 'vertical' ? 1 : 0,
                    }}
                >
                    {mode === 'vertical' && showCRMInfoInHeader && <Box sx={{ width: nameSideColumnWidth }} />}
                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{ lineHeight: '40px', marginRight: mode === 'vertical' ? 0 : 1 }}
                    >
                        {!name && loading ? <Skeleton variant="text" width={160} /> : name}
                    </Typography>
                    {/* only visible when profile is opened in its own tab and screen smaller than 1440px */}
                    {mode === 'vertical' && showCRMInfoInHeader && (
                        <Box sx={{ minWidth: nameSideColumnWidth }}>
                            {exportedProfileToCRMs.map((exportedProfiles) => (
                                <CRMChip
                                    key={exportedProfiles[0].crmTarget}
                                    exportedProfiles={exportedProfiles}
                                    name={name}
                                    country={country}
                                    logo={logo}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
                {!country && loading && mode === 'vertical' ? (
                    <Skeleton variant="text" width={120} />
                ) : country ? (
                    <Typography variant="body1" component="div">
                        <FlagEmoji
                            code={country}
                            sx={{ marginRight: mode === 'vertical' ? 1 : 0, marginBottom: mode === 'vertical' ? 0 : 2 }}
                        />
                        {mode === 'vertical' ? getLocation(city, country) : null}
                    </Typography>
                ) : null}
                {/* only visible when in popup mode and screen smaller than 1440px */}
                {mode === 'horizontal' &&
                    showCRMInfoInHeader &&
                    exportedProfileToCRMs.map((exportedProfiles) => (
                        <CRMChip
                            key={exportedProfiles[0].crmTarget}
                            exportedProfiles={exportedProfiles}
                            name={name}
                            country={country}
                            logo={logo}
                        />
                    ))}
            </Box>
            <Tags
                loading={loading}
                onClick={onIndustryTagClick}
                tags={industryTags}
                sx={{ marginBottom: 2, '& > div': { overflow: 'visible' } }}
            />
            <StatusIndicator statusClass={statusClass} status={status} sx={{ marginBottom: 1 }} />
        </Box>
    );
};

export default LogoNameAndIndustries;
