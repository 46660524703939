import { useCallback, useEffect } from 'react';

import { cloneDeep, isEmpty } from 'lodash';
import { FieldValues, set, useFormContext, UseFormGetValues, useWatch } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import { getAllLeaves } from 'components/tokens/select-components/NestedSelect/utils';
import { useFilterState } from 'contexts/FilterContext';
import { getFirstKey, getObjectKeys } from 'utilities/objectUtils';

import { filterDefinitions } from '../../filters/filterDefinitions';
import { getFiltersFromGroup } from '../../filters/utils';
import { Filter, FilterGroup, FilterID, GroupOption, NodeValue, RawGroup, UpdateGroupsFn } from '../../FilterTypes';
import { GetFiltersResult } from '../types';
import { getGroupFilters } from './groupUtils';
import { useKeys } from './useKeys';

export interface UseGroupProps {
    ids: string[];
    applyChanges: () => void;
    removeGroup: (groupId: string) => void;
    addNewGroup: (value: NodeValue) => void;
    updateGroup: (id: string, value: NodeValue) => void;
    createNewGroup: (value: NodeValue) => NodeValue;
    updateGroups?: UpdateGroupsFn;
}
export interface UseGroupOptions {
    ignoreCurrentFilter?: boolean;
}

export interface UseGroupActionsProps {
    filterGroups: GetFiltersResult;
    options?: GroupOption[];
    applyChanges: () => void;
    removeGroup: (groupId: string) => void;
    addNewGroup: (value: NodeValue) => void;
    updateGroup: (id: string, value: NodeValue) => void;
    createNewGroup: (value: NodeValue) => NodeValue;
    updateGroups?: UpdateGroupsFn;
    fieldValueValidator?: (value: unknown, filterId: FilterID) => boolean;
}

const defaultFieldValueValidator = (fieldValue: unknown) => {
    if (isEmpty(fieldValue)) {
        return false;
    } else if (Array.isArray(fieldValue) && fieldValue[0] == null && fieldValue[1] == null) {
        return false;
    }

    return true;
};

export const useGroupActions = (
    {
        filterGroups,
        options,
        applyChanges,
        removeGroup,
        updateGroup,
        createNewGroup,
        updateGroups,
        addNewGroup: addGroup,
        fieldValueValidator = defaultFieldValueValidator,
    }: UseGroupActionsProps,
    { ignoreCurrentFilter }: UseGroupOptions = {},
) => {
    const { getValues } = useFormContext();

    const { activeFilter, setActiveFilter } = useFilterState();

    const ids = getObjectKeys(filterGroups);

    const { keys, updateKeys } = useKeys(ids, filterGroups);

    const handleFilterRemove = useCallback(
        (groupId: string, filterIndex: number) => {
            if (!removeGroup) {
                return;
            }

            const group = filterGroups[groupId];

            if (group.filters.length === 1) {
                removeGroup(groupId);
                updateKeys(true);
            } else {
                const groupNode = getValues(groupId);
                const values = getValues(group.path);

                const updatedArray = [...values];
                updatedArray.splice(filterIndex, 1);
                set(groupNode, group.nodePath, updatedArray);
                updateGroup && updateGroup(groupId, groupNode);
                updateKeys(true);
            }
        },
        [updateKeys, removeGroup, getValues, updateGroup, filterGroups],
    );

    const addNewGroup = useCallback(
        (
            filterId: FilterID,
            createNewGroup: FilterGroup['createNewGroup'],
            filterDefinition: Filter<unknown>,
            groupNodes: GetFiltersResult,
        ) => {
            // Not create a filter if it is missing in the group options
            if (options) {
                const endOptions = getAllLeaves(options);

                if (!endOptions?.some((option) => option.value === filterId)) {
                    return;
                }
            }

            const createFilter = filterDefinition?.createFilter;

            if (!createFilter) {
                return;
            }
            if (filterDefinition.outsideGroup) {
                addGroup(createFilter());
                return;
            }

            if (!ids.length) {
                if (!createNewGroup) {
                    return;
                }

                addGroup(createNewGroup(createFilter()) as NodeValue);
                return;
            }

            if (!createNewGroup) {
                return;
            }

            const id = ids[ids.length - 1];
            const lastMatch = groupNodes[id];
            const matchKeys = lastMatch.nodePath.split('.');
            const containsMatch = matchKeys[0] === FilterOperator.MATCH;

            if (!containsMatch || lastMatch.filters.some((f) => f.id === filterId)) {
                const value = createNewGroup(createFilter());

                addGroup(value as NodeValue);
            } else {
                const value = getValues(id);
                value[FilterOperator.MATCH][filterDefinition.group][FilterOperator.ALL].push(createFilter());

                updateGroup?.(id, value);
            }
        },
        [addGroup, updateGroup, getValues, ids, options],
    );

    const handleAddFilter = useCallback(
        (filterId: FilterID) => {
            const definition = filterDefinitions[filterId];

            if (!definition || !addNewGroup) {
                return;
            }

            addNewGroup(filterId, createNewGroup, definition, filterGroups);
            updateKeys(true);
        },
        [addNewGroup, createNewGroup, filterGroups, updateKeys],
    );

    const handleAddFilters = useCallback(
        (filterIds: Array<FilterID>, createNewComboGroup: (value: NodeValue[]) => NodeValue) => {
            const definitions = filterIds.map((filterId) => filterDefinitions[filterId]);

            if (!definitions?.length || !createNewComboGroup) {
                return;
            }
            const filters: NodeValue[] = [];
            definitions.forEach((filter) => {
                if (filter?.createFilter) {
                    filters.push(filter.createFilter());
                }
            });
            const newGroup = createNewComboGroup(filters);
            addGroup(newGroup);
            updateKeys(true);
        },
        [addGroup, updateKeys],
    );

    // TODO: move common part into utilities
    const handleApplyChanges = useCallback(() => {
        const updatedGroups: Record<string, NodeValue | undefined> = {};
        ids.forEach((id) => {
            const value = getValues(id);
            const filters = getFiltersFromGroup({ value, path: id } as RawGroup);
            const group = filterGroups[id];

            const filteredIds: number[] = [];
            filters.forEach((r, index) => {
                const nextOperator = getFirstKey(r.value);
                const fieldValue = r.value[nextOperator as keyof NodeValue];

                if (!fieldValueValidator(fieldValue, r.id)) {
                    filteredIds.push(index);
                }
            });

            if (filteredIds.length === 0) {
                updateKeys(true);
                applyChanges();
                return;
            }

            if (filteredIds.length < filters.length) {
                const values: NodeValue[] = getValues(group.path);

                const updatedArray = values.filter((_, i) => {
                    return !filteredIds.includes(i);
                });
                const updatedValue = cloneDeep(value);
                set(updatedValue, group.nodePath, updatedArray);
                updatedGroups[id] = updatedValue;
            } else {
                updatedGroups[id] = undefined;
            }
        });

        updateGroups?.(updatedGroups);
        updateKeys(true);
        applyChanges();
    }, [ids, updateGroups, updateKeys, applyChanges, getValues, filterGroups, fieldValueValidator]);

    useEffect(() => {
        if (ignoreCurrentFilter) {
            return;
        }
        if (activeFilter) {
            handleAddFilter(activeFilter);
            setActiveFilter(undefined);
        }
    }, [activeFilter, handleAddFilter, setActiveFilter, ignoreCurrentFilter]);

    return {
        filterGroups,
        handleApplyChanges,
        handleAddFilter,
        handleFilterRemove,
        keys,
        updateKeys,
        handleAddFilters,
    };
};

export const getGroupNodes = (ids: string[], getValues: UseFormGetValues<FieldValues>) => {
    const filters: GetFiltersResult = {};

    ids.forEach((id) => {
        const result = getGroupFilters(id, getValues);

        if (result) {
            filters[id] = result;
        }
    });
    return filters;
};

export function useGroupNodes(ids: string[]) {
    const { getValues } = useFormContext();
    useWatch({ name: ids });

    return getGroupNodes(ids, getValues);
}
