import { ApplicationStoreConnections } from 'api/types/ApplicationStore';
import { TargetReqParam } from 'components/modules/connectors/utils/CRMTargetText';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import NoValue from 'components/templates/SortableTable/NoValue';
import Dialog from 'components/tokens/Dialog';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import { formatDate, toDateUTC } from 'utilities/date';

import { DealStatus } from './CRMBusinessImpact';
import useDialogData from './useDialogData';

type Props = {
    open: boolean;
    onClose: () => void;
    connectedCRM: ApplicationStoreConnections | undefined;
    target: TargetReqParam;
};

type WonRevenueData = {
    external_id: string;
    external_object: string;
    created_by_vainu: boolean;
    name: string | null;
    object_created_date: string | null;
    close_date: string;
    stage_name: string;
    attributed_to: string;
    deal_status: DealStatus;
    related_accounts:
        | {
              created_by_vainu: boolean;
          }[]
        | null;
    revenue: {
        currency_code: string;
        value: string;
    } | null;
};

const HEADER_HEIGHT = 41;
const ROW_HEIGHT = 49;

const WonRevenueDialog: React.FC<Props> = ({ open, onClose, connectedCRM, target }) => {
    const { infiniteQuery, activeSort, setActiveSort } = useDialogData<WonRevenueData>({
        queryKey: ['business-impact-won-revenue', target],
        query: {
            '?ALL': [
                {
                    '?EQ': {
                        target,
                    },
                },
                {
                    '?EQ': {
                        target_object: 'Opportunity',
                    },
                },
                {
                    '?EQ': {
                        attributed_to: 'vainu',
                    },
                },
                {
                    '?EQ': {
                        deal_status: 'won',
                    },
                },
            ],
        },
        fields: [
            'external_id',
            'external_object',
            'created_by_vainu', // this or related_accounts.created_by_vainu or both should be true. use the truthy one(s) to determine what to show as the one(s) we created
            'name',
            'object_created_date',
            'close_date',
            'stage_name',
            'attributed_to',
            'deal_status',
            'related_accounts.created_by_vainu',
            'revenue',
        ] as (keyof WonRevenueData)[],
        defaultSortColumn: 'close_date',
        defaultSortOrder: 'desc',
        enabled: open,
    });

    const { data, fetchNextPage, hasNextPage, isFetching, isLoading } = infiniteQuery;

    const filteredData = data?.pages.filter(
        (row) => row.created_by_vainu || row.related_accounts?.some((r) => r.created_by_vainu),
    );

    const rows = filteredData || new Array(5).fill({});

    return (
        <Dialog onClose={onClose} open={open} title="Won revenue created from Vainu" maxWidth="xl">
            <SortableTable<WonRevenueData>
                data={rows}
                rowKeyField="external_id"
                tableHeight={Math.min(HEADER_HEIGHT + ROW_HEIGHT * rows.length, 480)}
                sx={{ marginTop: 1 }}
                // hack for the scrollbar overlapping data issue on firefox
                headerCellSx={{
                    '&:last-child': {
                        paddingRight: 3,
                    },
                }}
                cellSx={{
                    '&:last-child': {
                        paddingRight: 3,
                    },
                }}
                defaultSortColumn={activeSort.column}
                defaultSortOrder={activeSort.order}
                onSort={(column, order) => setActiveSort({ column, order })}
                onScroll={(el) => {
                    if (isFetching || !hasNextPage) {
                        return;
                    }
                    const scrollBottom = el.scrollHeight - el.scrollTop - el.clientHeight;

                    if (scrollBottom < el.clientHeight) {
                        fetchNextPage();
                    }
                }}
            >
                <SortableTableColumn<WonRevenueData>
                    label="Created"
                    field="object_created_date"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) =>
                        row.object_created_date ? (
                            <b>{formatDate(toDateUTC(row.object_created_date), 'short')}</b>
                        ) : (
                            <NoValue />
                        )
                    }
                </SortableTableColumn>
                <SortableTableColumn<WonRevenueData>
                    label="Closed"
                    field="close_date"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => (row.close_date ? <b>{formatDate(toDateUTC(row.close_date), 'short')}</b> : <NoValue />)}
                </SortableTableColumn>
                <SortableTableColumn<WonRevenueData>
                    label="Won deal"
                    field="name"
                    sortable={false}
                    loading={isLoading}
                    skeletonWidth={240}
                >
                    {(row) =>
                        row.name ? (
                            <Link
                                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                href={connectedCRM?.link_templates.Opportunity?.replace('{}', row.external_id || '')}
                                toNewTab
                            >
                                <Icon type="HubspotDealSvg" fontSize="small" />
                                {row.name}
                            </Link>
                        ) : (
                            <NoValue />
                        )
                    }
                </SortableTableColumn>
                <SortableTableColumn<WonRevenueData>
                    label="Won revenue"
                    field="revenue.value"
                    align="right"
                    loading={isLoading}
                    skeletonWidth={100}
                >
                    {(row) => (row.revenue ? `${row.revenue.value} ${row.revenue.currency_code}` : <NoValue />)}
                </SortableTableColumn>
            </SortableTable>
        </Dialog>
    );
};

export default WonRevenueDialog;
