import { Collapse, styled } from '@mui/material';

export const boxWidth = 288;
export const boxPadding = 16;
export const boxBorderWidth = 1;
export const logoWidth = 24;
export const logoMargin = 12;
export const flagWidth = 16;
export const flagMargin = 6;
export const nameWidth =
    boxWidth -
    (boxBorderWidth + logoMargin + logoWidth + logoMargin + flagMargin + flagWidth + boxPadding + boxBorderWidth);

export const LeafContent = styled(Collapse)(({ theme }) => ({
    height: 64,
    border: `${boxBorderWidth}px solid`,
    borderColor: theme.palette.border,
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    '&.active': {
        borderColor: theme.palette.highlight.border,
        backgroundColor: theme.palette.highlight.background,
    },
    '&.borderless': {
        border: 0,
    },
    'a > &.normal:hover': {
        backgroundColor: theme.palette.background.default,
        '& .company-name': {
            color: theme.palette.secondary.main,
        },
    },
    '.MuiCollapse-wrapperInner': {
        display: 'flex',
        alignItems: 'center',
        padding: boxPadding,
        paddingLeft: logoMargin,
        minWidth: boxWidth - 2 * boxBorderWidth,
        position: 'relative',
        zIndex: 1,
    },
}));

export const CompanyInfoContainer = styled('div')({
    display: 'flex',
    marginLeft: logoMargin,
    flexDirection: 'column',
    flexGrow: 1,
    '& > div': {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        '& > *': {
            lineHeight: '18px',
        },
    },
});
