import React from 'react';

import { Box, Paper, Skeleton } from '@mui/material';

import { ApplicationStoreConnections } from 'api/types/ApplicationStore';
import { crmLogo } from 'components/features/Notifications/NotificationItem';
import { CrmName } from 'components/modules/connectors/types/Crm';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Tag from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';
import { useDialogContext } from 'hooks/useDialogContext';

type Props = {
    hasError: boolean;
    crmName: CrmName;
    integration: ApplicationStoreConnections | undefined;
};

const CRMInstanceTile: React.FC<Props> = ({ hasError, crmName, integration }) => {
    const { handleDialogOpen } = useDialogContext();

    const fullName = integration?.user?.full_name;
    const email = integration?.user?.email;
    const currentCrmUser = fullName || email;
    const crmInstanceName = integration?.instance?.name;
    const crmInstanceUrl = integration?.instance?.url;

    return (
        <Paper sx={{ width: 209, padding: 2, height: 'fit-content' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            backgroundColor: 'grey.50',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        <Icon type={crmLogo[crmName]} />
                        <Box
                            sx={{
                                width: 12,
                                height: 12,
                                borderRadius: '50%',
                                backgroundColor: hasError ? 'error.main' : 'success.main',
                                border: '2px solid',
                                borderColor: 'common.white',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }}
                        />
                    </Box>

                    {crmInstanceUrl && (
                        <Button
                            variant="flat"
                            startIcon={<Icon type="ExternalLink" color="grey.400" />}
                            sx={{ borderRadius: '50%' }}
                            href={crmInstanceUrl}
                            target="_blank"
                        />
                    )}
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {hasError ? (
                        <Tag variant="purple" label="Disconnected" sx={{ width: 'fit-content' }} />
                    ) : (
                        <Typography variant="caption" weight="bold" color="dark">
                            Connected:
                        </Typography>
                    )}
                    <Typography variant="caption">
                        <Link href={crmInstanceUrl} toNewTab>
                            {integration ? (
                                crmInstanceName || crmInstanceUrl?.substring(0, 24)
                            ) : (
                                <Skeleton width={130} />
                            )}
                        </Link>
                    </Typography>
                    {(!integration || currentCrmUser) && (
                        <Typography variant="caption">
                            {integration ? currentCrmUser : <Skeleton width={150} />}
                        </Typography>
                    )}
                    {/* <Typography variant="mini" color="subtle">
                    {formatDate(toDateUTC(new Date().toISOString().slice(0, -1)), 'long')}
                </Typography> */}

                    <Button
                        variant="secondary"
                        sx={{ marginTop: 1 }}
                        onClick={() => handleDialogOpen('CRM_DISCONNECT_CONFIRM_DIALOG', { crm: crmName })}
                    >
                        Disconnect
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default CRMInstanceTile;
