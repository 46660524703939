import { useState } from 'react';

import { Box, Divider } from '@mui/material';
import { endOfYear, format, startOfYear } from 'date-fns';

import { StyledMenuItem } from 'components/templates/ListMenu/styled';
import { Menu, MenuItemProps } from 'components/templates/Menu';
import { Button, ButtonProps } from 'components/tokens/Button';
import { DateRange, getDisplayValue } from 'components/tokens/DateRange';
import { Icon } from 'components/tokens/Icon';

export type TimeFrameSelectProps = {
    value: string | Date[];
    onChange: (value: string | Date[]) => void;
    variant?: ButtonProps['variant'];
    options: MenuItemProps<string>[];
};

const TimeFrameSelect: React.FC<TimeFrameSelectProps> = ({ value, onChange, variant = 'flat', options }) => {
    const [showCustom, setShowCustom] = useState(false);

    const isCustom = Array.isArray(value);

    if (showCustom && isCustom) {
        return (
            <DateRange
                value={value}
                onChange={onChange}
                type="button"
                autoOpen
                ButtonProps={{ variant }}
                onClose={() => setShowCustom(false)}
            >
                <Box sx={{ display: 'flex', padding: 2 }}>
                    <Button variant="flat" onClick={() => onChange(options[0].action)}>
                        Back
                    </Button>
                </Box>
            </DateRange>
        );
    }

    const selectedOption = options.find((option) => option.action === value);

    return (
        <Menu
            anchorElement={
                <Button size="small" variant={variant} endIcon={<Icon fontSize="mini" type="Calendar" />}>
                    {isCustom
                        ? getDisplayValue(value.map((date) => format(date, 'd MMM, yyyy')))
                        : selectedOption?.label}
                </Button>
            }
            items={options}
            onClick={(value) => onChange(value)}
            selectedAction={isCustom ? undefined : value}
            childrenLast
            sx={{ minWidth: 220 }}
        >
            <Divider />

            <StyledMenuItem
                onClick={() => {
                    setShowCustom(true);
                    if (!isCustom) {
                        onChange([startOfYear(new Date()), endOfYear(new Date())]);
                    }
                }}
                selected={isCustom}
            >
                Choose time frame
                {isCustom ? (
                    <Icon
                        type="CheckBig"
                        fontSize="small"
                        color="text.subtle"
                        sx={{ position: 'absolute', right: -2 }}
                    />
                ) : (
                    <Icon type="Calendar" fontSize="mini" color="text.dark" sx={{ position: 'absolute', right: -2 }} />
                )}
            </StyledMenuItem>
        </Menu>
    );
};

export default TimeFrameSelect;
