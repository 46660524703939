import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

type Props = {
    children?: React.ReactNode;
};

const ErrorStatus: React.FC<Props> = ({ children }) => (
    <Typography
        variant="body2"
        sx={{
            marginTop: 3,
            color: 'error.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Icon type="ErrorOutline" sx={{ marginRight: 0.5 }} />
        {children}
    </Typography>
);

export default ErrorStatus;
