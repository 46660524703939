import { captureException, setContext } from '@sentry/react';

export const pickTokenData = (token: string) => token.split('.')[1];

/**
 * Translates the base64url data to base64 by replacing - and _ with + and /.
 * after which the data is decodable as normal base64 with atob().
 * https://datatracker.ietf.org/doc/html/rfc4648#page-6
 * https://datatracker.ietf.org/doc/html/rfc4648#page-8
 */
export const base64urlToBase64 = (data: string) => data.replace(/-/g, '+').replace(/_/g, '/');

export const parseTokenData = (token: string): Record<string, unknown> | undefined => {
    try {
        return JSON.parse(window.atob(base64urlToBase64(pickTokenData(token))));
    } catch (error) {
        setContext('Data', { data: pickTokenData(token) });
        captureException(error);
        return undefined;
    }
};

export const getUserEmailFromToken = (token: string | undefined): string | undefined => {
    try {
        if (!token) {
            return undefined;
        }
        const { user }: { user?: { email?: string } } = parseTokenData(token) ?? {};
        return user?.email;
    } catch (error) {
        return undefined;
    }
};

export const getAccountIdFromToken = (token: string | undefined): string | undefined => {
    try {
        if (!token) {
            return undefined;
        }
        const { user }: { user?: { profile?: { account?: { id: string } } } } = parseTokenData(token) ?? {};
        return user?.profile?.account?.id;
    } catch (error) {
        return undefined;
    }
};

export const getPlanhatIdFromToken = (token: string | undefined): string | undefined => {
    try {
        if (!token) {
            return undefined;
        }
        const { user }: { user?: { profile?: { account?: { planhat_company_id: string } } } } =
            parseTokenData(token) ?? {};
        return user?.profile?.account?.planhat_company_id;
    } catch (error) {
        return undefined;
    }
};
