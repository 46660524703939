import React from 'react';

import { stopAsyncProcessByProcessId } from 'api/asyncProcesses';
import { CRMImportAsyncProcess } from 'api/types/UserAsyncProcess';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { getCompanyLikeObjectForCrm, getObjectPluralForm } from 'components/modules/connectors/utils/CRMTargetText';
import Icon from 'components/tokens/Icon';
import { useAxiosContext } from 'contexts/AxiosContext';

import { AsyncProcessNotificationProps, crmLogo } from '../NotificationItem';
import NotificationItemContent, { NotificationItemContentProps } from '../NotificationItemContent';
import { progress } from '../utils';
import { GreenText } from './sharedComps';

const getStateBasedData = (notification: CRMImportAsyncProcess): NotificationItemContentProps => {
    const {
        seen,
        created,
        state,
        finished,
        meta_data: { crm, size, completed },
    } = notification;

    const crmIcon = <Icon type={crmLogo[crm as CrmName]} />;
    const objectNamePlural = crm && getObjectPluralForm(crm, getCompanyLikeObjectForCrm(crm));

    switch (state) {
        case 'accepted':
            return {
                seen: seen,
                icon: crmIcon,
                title: `Matching ${objectNamePlural}...`,
                progress: progress(0, 100),
                date: created,
            };
        case 'process':
            return {
                seen: seen,
                icon: crmIcon,
                title: `Matching ${objectNamePlural}...`,
                customContent:
                    (size && completed) || completed === 0 ? (
                        <>
                            <GreenText>{completed}</GreenText>/{size} processed
                        </>
                    ) : undefined,
                progress: progress(completed, size),
                date: created,
            };
        case 'completed':
            return {
                seen: seen,
                icon: crmIcon,
                title: '✔️ Matching complete',
                date: finished,
                customContent: (
                    <>
                        <GreenText>{completed}</GreenText> {objectNamePlural} processed successfully
                    </>
                ),
            };
        case 'stopped':
            return {
                seen: seen,
                icon: <Icon type="Disabled" />,
                title: 'Company matching stopped.',
                content: 'You can restart it from the matching settings.',
                date: finished,
            };

        case 'failure':
        default:
            return {
                seen: seen,
                error: true,
                icon: crmIcon,
                title: 'Company matching had an issue.',
                content: `We couldn't complete matching your ${objectNamePlural}.`,
                date: finished,
            };
    }
};

const CRMImport: React.FC<AsyncProcessNotificationProps<CRMImportAsyncProcess>> = ({
    notification,
    handleMarkAsSeen,
}) => {
    const axios = useAxiosContext();

    if (!notification.state || !notification.meta_data) return null;

    return (
        <NotificationItemContent
            {...getStateBasedData(notification)}
            state={notification.state}
            handleMarkAsSeen={handleMarkAsSeen}
            onCancel={() => stopAsyncProcessByProcessId(axios, notification.id)}
        />
    );
};

export default CRMImport;
