import React from 'react';

import Box from '@mui/material/Box';
import { pluralizeSuffix } from 'utilities';

import { List } from 'api/types/List';
import { CrmMenu } from 'components/modules/connectors/crm-export';
import { CrmName } from 'components/modules/connectors/types/Crm';
import Button from 'components/tokens/Button';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { isUploaded, useListsContext } from 'contexts/ListsContext';
import useAddToStaticList from 'hooks/useAddToStaticList';

import ListMenu from '../ListMenu';
import CompanyCount, { CompanyCountProps } from './CompanyCount';

export type HeaderActionsProps = CompanyCountProps & {
    selectedCompaniesCount?: number;
    toggleSelectAllCompanies?: () => void;
    onAddMenuAction?: (action: string) => void;
    onExportAction?: (crm: CrmName) => void;
    onDelete?: () => void;
    hideActions?: boolean;
    disableBulkCheckbox?: boolean;
    alwaysShowAddAction?: boolean;
    countLabel?: string | null;
    /** If the view using this component is showing list results, give the list id to exclude
     * the list from the selection of static lists where to add the company. */
    currentListId?: List['id'];
};

export const HeaderActions: React.FC<HeaderActionsProps> = ({
    selectedCompaniesCount = 0,
    toggleSelectAllCompanies,
    onAddMenuAction,
    onExportAction,
    onDelete,
    hideActions,
    disableBulkCheckbox,
    alwaysShowAddAction,
    companiesCount,
    countLabel,
    currentListId,
    ...companyCountProps
}) => {
    const { selectedList } = useListsContext();
    const { staticListOptions } = useAddToStaticList({ excludedListId: currentListId });

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {selectedList?.type !== 'legacy' && !hideActions && (
                <Checkbox
                    onClick={() => toggleSelectAllCompanies?.()}
                    checked={!!selectedCompaniesCount}
                    disabled={disableBulkCheckbox}
                    indeterminate={!!selectedCompaniesCount && selectedCompaniesCount !== companiesCount}
                    sx={{ marginRight: '4px' }}
                />
            )}

            {(!selectedCompaniesCount || hideActions) && companiesCount !== undefined ? (
                countLabel !== undefined ? (
                    countLabel !== null && (
                        <Typography variant="small" weight="semibold">
                            {countLabel}
                        </Typography>
                    )
                ) : (
                    <CompanyCount companiesCount={companiesCount} {...companyCountProps} />
                )
            ) : null}

            {!hideActions && selectedCompaniesCount > 0 && (
                <>
                    <Typography variant="small" weight="semibold">
                        {pluralizeSuffix(selectedCompaniesCount, 'result selected', 'results selected')}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2, gap: 1 }}>
                        {onAddMenuAction && (
                            <ListMenu
                                anchorElement={
                                    <Button
                                        variant="tertiary"
                                        startIcon={<Icon type="ListPlus" />}
                                        size="small"
                                        sx={{ marginLeft: 0.5 }}
                                    >
                                        Add to custom list
                                    </Button>
                                }
                                items={staticListOptions}
                                staticOptions={[{ action: 'createNewList', label: 'New custom list', icon: 'Plus' }]}
                                searchable
                                onClick={onAddMenuAction}
                                disablePortal={false}
                            />
                        )}
                        {onDelete && selectedList && isUploaded(selectedList) && (
                            <Button
                                variant="tertiary"
                                startIcon={<Icon type="OffOutlineClose" />}
                                size="small"
                                sx={{ marginLeft: 0.5 }}
                                onClick={onDelete}
                            >
                                Remove
                            </Button>
                        )}
                        {onExportAction && (
                            <CrmMenu
                                anchorButton={
                                    <Button startIcon={<Icon type="Export" />} variant="tertiary" size="small">
                                        Send companies
                                    </Button>
                                }
                                handleClick={onExportAction}
                            />
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default HeaderActions;
