import React, { lazy, Suspense, useEffect, useState } from 'react';

import { Box, Skeleton, SxProps } from '@mui/material';
import { capitalize } from 'lodash';

import { InsightsPayload, Stats } from 'api/filterCompanies';
import { getCountryNameFromCode } from 'components/templates/CompanyCard/utils';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';
import { usePermissionContext } from 'contexts/PermissionContext';
import { Database, databases, isDomainDatabase } from 'contexts/types/databases';

import { companySizeTooltipContent } from '../Employees';
import CountryFlag from '../Locations/CountryFlag';
import { FlatAddress } from '../Locations/types';
import { getPriorityTechnologies } from '../WebProfile/priorityTechnologies';
import { getTechnologyIcon } from '../WebProfile/utils';
import BarTile from './Tiles/BarTile';
import DonutGridTile from './Tiles/DonutGridTile';
import PieChartTile from './Tiles/PieChartTile';
import StackedBarTile, { stackedBarTileColors } from './Tiles/StackedBarTile';
import Tile from './Tiles/Tile';
import { DEFAULT_DATA, defaultMinWidth, employeeClasses, employeeRanges, formatCompanySizeData } from './utils';

const AddressMap = lazy(() => import('../Locations/Map/AddressMap'));

type TileGridProps = {
    data: Stats[] | undefined;
    statsQuery: InsightsPayload['stats'];
    database?: Database;
    sx?: SxProps;
};

const filteredTechnologies = getPriorityTechnologies().flatMap((cat) => cat[1].map((tech) => tech[1]));

const TileGrid: React.FC<TileGridProps> = ({ data, statsQuery, database, sx }) => {
    const { isDomainConnector } = usePermissionContext();
    const [cities, setCities] = useState<{ name: string; lat: number; lon: number }[]>([]);
    const [countries, setCountries] = useState<{
        [countryCode: string]: {
            lat: number;
            lon: number;
            name: string;
        };
    }>({});

    useEffect(() => {
        if ((database && database === 'DOMAIN_DATA_BASIC') || (!database && isDomainConnector)) {
            (async () => {
                const countryCoordinates = await import(`./coordinates/countryCoordinates.json`);
                setCountries(countryCoordinates.default);
            })();
        } else if (database) {
            (async () => {
                const cities = await import(`./coordinates/${database}-cities.json`);
                setCities(cities.default);
            })();
        } else {
            (async () => {
                const cities = await Promise.all(
                    databases
                        .filter((db) => !isDomainDatabase(db))
                        .map(async (database) => (await import(`./coordinates/${database}-cities.json`)).default),
                );
                setCities(cities.flat());
            })();
        }
    }, [database, isDomainConnector]);

    return (
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', ...sx }}>
            {/* Map through the query to show detailed skeletons  */}
            {statsQuery.map((group) => {
                const row = data?.find((row) => row.id === group.id);

                const total = row?.documents_scanned || 0;

                if (row && !row.documents_scanned) {
                    return null;
                }
                if (group.id === 'vci') {
                    return (
                        <BarTile
                            key={group.id}
                            title="Vainu Custom Industries"
                            data={
                                row?.result[0].buckets
                                    .filter((bucket) => !['B2C', 'B2B'].includes(bucket.value as string))
                                    .map((bucket) => ({
                                        value: bucket.count,
                                        label: bucket.value as string,
                                    })) || DEFAULT_DATA
                            }
                            total={total}
                            sx={{ minWidth: `max(${defaultMinWidth}px, calc(50% - 16px))` }}
                            firstColumn={{ width: 200, label: 'VCI' }}
                        />
                    );
                }
                if (group.id === 'hq_country') {
                    return (
                        <React.Fragment key={group.id}>
                            <BarTile
                                title="Headquarters"
                                data={
                                    row?.result[0].buckets.map((bucket) => ({
                                        value: bucket.count,
                                        label: getCountryNameFromCode(bucket.value as string),
                                        secondaryLabel: <CountryFlag country={bucket.value as string} />,
                                    })) || DEFAULT_DATA
                                }
                                total={total}
                                sx={{ minWidth: defaultMinWidth, maxWidth: 'max(600px, 50%)' }}
                                firstColumn={{ label: 'Country' }}
                            />
                            <Tile title="Locations" sx={{ minWidth: defaultMinWidth }}>
                                <Suspense fallback={<Skeleton variant="rounded" height={330} />}>
                                    <AddressMap
                                        height={330}
                                        minZoom={1}
                                        maxClusterRadius={20}
                                        markerType="flag"
                                        padding={40}
                                        addresses={
                                            row?.result[0].buckets.map((bucket) => {
                                                const country = countries[bucket.value as keyof typeof countries];
                                                return {
                                                    coordinates: country
                                                        ? {
                                                              latitude: country.lat,
                                                              longitude: country.lon,
                                                          }
                                                        : undefined,
                                                    street: `${getCountryNameFromCode(
                                                        bucket.value as string,
                                                    )}: ${Math.round((bucket.count / row.documents_scanned) * 100)}%`,
                                                    country: bucket.value as string,
                                                    uid: bucket.value as string,
                                                    addressType: 'visiting_address',
                                                };
                                            }) || []
                                        }
                                    />
                                </Suspense>
                            </Tile>
                        </React.Fragment>
                    );
                }
                if (group.id === 'hq_city') {
                    return (
                        <React.Fragment key={group.id}>
                            <BarTile
                                key={group.id}
                                title="Headquarters"
                                data={
                                    row?.result[0].buckets.map((bucket) => {
                                        const value = bucket.value as {
                                            city: string | null;
                                            country: string | null;
                                        };
                                        return {
                                            value: bucket.count,
                                            label: value.city ? capitalize(value.city) : 'Unknown',
                                            secondaryLabel: <CountryFlag country={value.country || undefined} />,
                                        };
                                    }) || DEFAULT_DATA
                                }
                                total={total}
                                sx={{ minWidth: defaultMinWidth, maxWidth: 'max(600px, 50%)' }}
                                firstColumn={{ label: 'City' }}
                            />
                            <Tile title="Locations" sx={{ minWidth: defaultMinWidth }}>
                                <Suspense fallback={<Skeleton variant="rounded" height={330} />}>
                                    <AddressMap
                                        height={330}
                                        minZoom={1}
                                        maxClusterRadius={20}
                                        markerType="label"
                                        padding={40}
                                        addresses={
                                            (row?.result[0].buckets
                                                .map((bucket) => {
                                                    const value = bucket.value as {
                                                        city: string | null;
                                                        country: string | null;
                                                    };
                                                    const city = cities?.find(
                                                        (row) => row.name.toLowerCase() === value.city?.toLowerCase(),
                                                    );

                                                    if (!city) {
                                                        return null;
                                                    }

                                                    return {
                                                        coordinates: {
                                                            latitude: city.lat,
                                                            longitude: city.lon,
                                                        },
                                                        street: `${city.name}: ${Math.round(
                                                            (bucket.count / row.documents_scanned) * 100,
                                                        )}%`,
                                                        country: value.country || undefined,
                                                        uid: city.name,
                                                        addressType: 'visiting_address',
                                                    };
                                                })
                                                .filter(Boolean) as FlatAddress[]) || []
                                        }
                                    />
                                </Suspense>
                            </Tile>
                        </React.Fragment>
                    );
                }
                if (group.id === 'technologies') {
                    const allData = row?.result[0].buckets.map((bucket) => {
                        const value = bucket.value as {
                            name: string;
                            logo_url: string | null;
                            'types.0': string;
                        };
                        return {
                            value: bucket.count,
                            label: value.name,
                            secondaryLabel: value.logo_url ? (
                                <img
                                    height={20}
                                    width={20}
                                    src={value.logo_url}
                                    alt=""
                                    style={{ borderRadius: '50%', display: 'block' }}
                                />
                            ) : (
                                <Icon
                                    fontSize="small"
                                    type={getTechnologyIcon(value['types.0'])}
                                    color="text.subtle"
                                    sx={{ display: 'block' }}
                                />
                            ),
                        };
                    });
                    return (
                        <BarTile
                            key={group.id}
                            title="Popular technologies"
                            data={allData?.filter((row) => filteredTechnologies.includes(row.label)) || DEFAULT_DATA}
                            extraData={{
                                all: {
                                    label: 'All',
                                    data: allData,
                                },
                            }}
                            tabLabel="Popular"
                            total={total}
                            sx={{ minWidth: defaultMinWidth }}
                            firstColumn={{ label: 'Technology' }}
                            dialogTitle="Technologies"
                        />
                    );
                }
                if (group.id === 'industries') {
                    return (
                        <BarTile
                            key={group.id}
                            title={
                                <>
                                    Industry{' '}
                                    <Typography variant="inherit" color="subtle" component="span">
                                        (official code)
                                    </Typography>
                                </>
                            }
                            data={
                                row?.result[0].buckets.map((bucket) => {
                                    const value = bucket.value as {
                                        'code_2_level_descriptions.en': string;
                                        code_2_level: string;
                                    };
                                    return {
                                        value: bucket.count,
                                        label: value['code_2_level_descriptions.en'],
                                        secondaryLabel: `(${value.code_2_level})`,
                                    };
                                }) || DEFAULT_DATA
                            }
                            total={total}
                            sx={{ minWidth: defaultMinWidth }}
                            firstColumn={{ width: 200, label: 'Industry' }}
                        />
                    );
                }
                if (group.id === 'traffic_rank') {
                    return (
                        <StackedBarTile
                            key={group.id}
                            title="Web traffic rank"
                            data={{
                                trafficRank: {
                                    label: 'Traffic rank',
                                    data:
                                        row?.result[0].buckets.map((bucket) => ({
                                            value: bucket.count,
                                            label: bucket.label as string,
                                        })) ||
                                        ('?GROUP' in group['?AGGREGATE'][0]
                                            ? group['?AGGREGATE'][0]['?GROUP'].buckets
                                            : []
                                        )?.map((bucket) => ({
                                            value: undefined,
                                            label: bucket.label,
                                        })) ||
                                        [],
                                },
                            }}
                            total={total}
                            limit={7}
                            sx={{ minWidth: 320, maxWidth: 500, maxHeight: 340 }}
                        />
                    );
                }
                if (group.id === 'revenue') {
                    const companyData = row && {
                        company: {
                            label: 'Company',
                            displayLabel: 'Company',
                            data: row?.result[0].buckets.map((bucket) => ({
                                value: bucket.count,
                                label: bucket.label as string,
                                ignoreForHighlight: bucket.label === 'Not reported',
                            })),
                            tooltip:
                                'The company’s solely earned income. Subsidiary, sibling, or parent contributions are not considered.',
                        },
                    };

                    const groupDataRow = data?.find((row) => row.id === 'group_revenue');
                    const groupData = groupDataRow && {
                        group: {
                            label: 'Group',
                            displayLabel: 'Group',
                            data: groupDataRow.result[0].buckets.map((bucket) => ({
                                value: bucket.count,
                                label: bucket.label as string,
                                ignoreForHighlight: bucket.label === 'Not reported',
                            })),
                            tooltip: 'The corporate entity’s total income from all their subsidiary companies',
                        },
                    };

                    return (
                        <StackedBarTile
                            key={group.id}
                            title="Revenue"
                            data={
                                (groupData ? { ...groupData, ...companyData } : companyData) || {
                                    skeleton: {
                                        label: 'skeleton',
                                        data:
                                            ('?GROUP' in group['?AGGREGATE'][0]
                                                ? group['?AGGREGATE'][0]['?GROUP'].buckets
                                                : []
                                            )?.map((bucket) => ({
                                                value: undefined,
                                                label: bucket.label,
                                            })) || [],
                                    },
                                }
                            }
                            total={total}
                            limit={6}
                            colors={stackedBarTileColors.slice(2)}
                            sx={{ minWidth: 320, maxWidth: 500, maxHeight: 340 }}
                        />
                    );
                }
                if (group.id === 'company_size') {
                    const realTimeData = row && {
                        realTime: {
                            label: 'Real time (ML model)',
                            displayLabel: 'Real time',
                            data: formatCompanySizeData(row.result[0].buckets, 'value', 'Unknown'),
                            tooltip: companySizeTooltipContent,
                        },
                    };

                    const officialDataRow = data?.find((row) => row.id === 'company_size_official');
                    const officialData = officialDataRow && {
                        reported: {
                            label: 'Annually reported (official)',
                            displayLabel: 'Annually reported',
                            data: formatCompanySizeData(officialDataRow.result[0].buckets, 'label', 'Not reported'),
                            tooltip: (
                                <>
                                    <p>
                                        The official number of employees is the static average companies file in their
                                        annual fiscal report. It doesn't include part-time workers or those registered
                                        under different entities. It can fall outdated by over a year and therefore
                                        can’t reflect any recent company changes.
                                    </p>
                                    <Link
                                        href="https://www.vainu.com/blog/employee-count-data/"
                                        toNewTab
                                        underline="always"
                                        sx={{ textDecorationColor: 'inherit' }}
                                    >
                                        Learn more
                                    </Link>
                                </>
                            ),
                        },
                    };

                    return (
                        <PieChartTile
                            key={group.id}
                            title="Size (employees)"
                            data={
                                (officialData ? { ...officialData, ...realTimeData } : realTimeData) || {
                                    skeleton: {
                                        label: 'skeleton',
                                        data: employeeClasses.map((key) => ({
                                            value: undefined,
                                            label: key ? capitalize(key) : 'Unknown',
                                            secondaryLabel: employeeRanges[key],
                                        })),
                                    },
                                }
                            }
                            total={total}
                            sx={{ minWidth: 390, maxWidth: 650 }}
                        />
                    );
                }
                if (group.id === 'vci_b2b_b2c') {
                    return (
                        <DonutGridTile
                            key={group.id}
                            title="Market"
                            data={
                                row?.result[0].buckets.map((bucket) => ({
                                    value: bucket.count,
                                    label: `have ${bucket['?EQ'] as string} business`,
                                })) || [
                                    { value: undefined, label: 'have B2B business' },
                                    { value: undefined, label: 'have B2C business' },
                                ]
                            }
                            total={total}
                            sx={{ width: 248, flex: 'none', maxHeight: 290 }}
                        />
                    );
                }

                return null;
            })}
        </Box>
    );
};

export default TileGrid;
