import { RecoilRoot } from 'recoil';

import { AuthProvider } from 'contexts/AuthContext';
import { AxiosProvider } from 'contexts/AxiosContext';
import { DialogProvider } from 'contexts/DialogContext/DialogContext';
import { SnackbarProvider } from 'contexts/SnackbarContext/SnackbarContext';

export type AppProvidersProps = {
    children: React.ReactNode;
};

/**
 * Providers that:
 * - are not needed by the EmbeddedView
 * - are needed by the login page
 * - do not need authenticated API access
 */
export const AppProviders: React.FC<AppProvidersProps> = ({ children }) => (
    <RecoilRoot>
        <SnackbarProvider>
            <AuthProvider>
                <AxiosProvider>
                    <DialogProvider>{children}</DialogProvider>
                </AxiosProvider>
            </AuthProvider>
        </SnackbarProvider>
    </RecoilRoot>
);
