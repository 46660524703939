import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import ChipInput from 'components/tokens/ChipInput';

import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterProps,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, getInnerMostValueInFilterState, valueArrayToText } from '../utils';

const FIELD = 'prospectexport#owner_email';
export type TValues = { [FIELD]: string[] };

// ?IN or ?STARTSWITH
const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.prospect_export_crm_owner_email,
    label: "Owner's email",
    defaultOperator: FilterOperator.STARTSWITH,
    initValue: {
        [FilterOperator.STARTSWITH]: {
            [FIELD]: [],
        },
    },
};

export const crmOwnerEmailFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.crm,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <CRMOwnerEmail {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.STARTSWITH, `${FilterOperator.NOT}${FilterOperator.STARTSWITH}`].map(
    (value) => formatOperatorValue(value as OperatorValue),
);

export const CRMOwnerEmail: React.VFC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const { control } = useFormContext();

    const { operator, handleOperatorUpdate } = useOperator(filter);

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    // assuming that the depth will always be 1 to flatten otherwise use `map` and `flat(depth)`
    const disabledFilterOptions = sameFiltersState.flatMap((sameState) =>
        getInnerMostValueInFilterState(sameState, filter.valuePath),
    );

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;
                    return (
                        <ChipInput
                            placeholder={value?.length ? 'Or...' : 'email...'}
                            width="100%"
                            limitTags={2}
                            values={value}
                            disabledOptions={disabledFilterOptions as string[]}
                            setValues={onChange}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
