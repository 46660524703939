import React, { useState } from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import Dialog from 'components/tokens/Dialog';
import Select from 'components/tokens/select-components/Select/Select';
import Typography from 'components/tokens/Typography';

type PricingDialogProps = {
    open: boolean;
    onClose: () => void;
};

type Currency = 'EUR' | 'SEK' | 'NOK' | 'DKK';

const currencyOptions: { value: Currency; label: string }[] = [
    { value: 'EUR', label: '🇪🇺 EUR' },
    { value: 'SEK', label: '🇸🇪 SEK' },
    { value: 'NOK', label: '🇳🇴 NOK' },
    { value: 'DKK', label: '🇩🇰 DKK' },
];

const currenciesMap = {
    EUR: { rate: 1, sign: '€' },
    SEK: { rate: 10, sign: 'kr' },
    NOK: { rate: 10, sign: 'kr' },
    DKK: { rate: 7.4, sign: 'kr' },
};

const convertToCurrency = (amount: number, currency: Currency) => {
    return `${amount * currenciesMap[currency].rate} ${currenciesMap[currency].sign}`;
};

const PricingDialog: React.FC<PricingDialogProps> = ({ open, onClose }) => {
    const [currency, setCurrency] = useState(currencyOptions[0]);

    const prices = [
        ['1 - 1,000', 'Automatically included in your contract'],
        ['1,001 - 10,000', convertToCurrency(250, currency.value)],
        ['10,001 - 50,000', convertToCurrency(180, currency.value)],
        ['50,001 - 250,000', convertToCurrency(165, currency.value)],
        ['250,001 - 500,000', convertToCurrency(150, currency.value)],
        ['500,001+', convertToCurrency(135, currency.value)],
    ];

    return (
        <Dialog open={open} title="Pricing" cancelLabel="Close" maxWidth="xs" onClose={onClose}>
            <Typography variant="small">
                Matched, updated and downloaded companies are priced in tiers of 1,000.
            </Typography>
            <Select
                sx={{ marginTop: '8px' }}
                placeholder="Select"
                width="100px"
                multiple={false}
                value={currency}
                options={currencyOptions}
                onValueChange={(value) => {
                    setCurrency(value as { value: Currency; label: string });
                }}
            />
            <Table sx={{ marginTop: 2 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Number of companies</TableCell>
                        <TableCell>Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prices.map(([limit, price], idx) => (
                        <TableRow key={idx}>
                            <TableCell>{limit}</TableCell>
                            <TableCell>
                                {idx === 0 ? (
                                    <Typography variant="body2" color="subtle">
                                        {price}
                                    </Typography>
                                ) : (
                                    <>
                                        <Typography display="inline" variant="body2">
                                            {price}
                                        </Typography>
                                        <Typography display="inline" color="subtle" variant="body2">
                                            {' '}
                                            / 1000 companies
                                        </Typography>
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Dialog>
    );
};

export default PricingDialog;
