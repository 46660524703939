import React from 'react';

/**
 * text overflow is triggered when the scroll width
 * is greater than the offset width
 */
const isCutOff = <T extends HTMLElement>(node: T | null) => (node ? node.offsetWidth < node.scrollWidth : false);

export const useTextCutOff = <T extends HTMLElement>(
    ref: React.RefObject<T>,
): [isTextCutOff: boolean, onRefresh: (enabled: boolean) => void] => {
    // since the ref is null at first, we need a stateful value
    // to set after we have a ref to the node
    const [isTextCutOff, setIsTextCutOff] = React.useState(isCutOff(ref.current));

    // allow the caller to refresh on account of refs being
    // outside of the render cycle
    const onRefresh = (enabled: boolean) => {
        setIsTextCutOff(enabled ? isCutOff(ref.current) : false);
    };

    return [isTextCutOff, onRefresh];
};
