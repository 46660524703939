import { Box, Skeleton } from '@mui/material';

import { Typography } from 'components/tokens/Typography';

import { TileData } from '../types';
import { getHighlightIndex } from '../utils';

interface LegendProps {
    data: TileData[];
    total: number;
    colors: string[];
}

const Legend: React.FC<LegendProps> = ({ data, total, colors }) => {
    const highlightIndex = getHighlightIndex(data);

    return (
        <Box>
            {data.map((row, idx) => (
                <Box key={idx} sx={{ display: 'flex', alignItems: 'center', gap: 1, marginY: 0.5 }}>
                    <Box
                        sx={{
                            flex: 'none',
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            background: colors[idx % colors.length],
                            border: '2px dotted',
                            borderColor: colors[idx % colors.length] === '#ffffff' ? '#B8B8B8' : 'transparent',
                        }}
                    />
                    <Typography
                        variant="body2"
                        sx={{ flex: 1 }}
                        weight={highlightIndex === idx ? 'semibold' : 'normal'}
                    >
                        {row.label}

                        {row.secondaryLabel && (
                            <Typography variant="inherit" color="subtle" component="span">
                                {' '}
                                ({row.secondaryLabel})
                            </Typography>
                        )}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ textAlign: 'right' }}
                        weight={highlightIndex === idx ? 'semibold' : 'normal'}
                    >
                        {row.value != null ? (
                            row.valueLabel || `${Math.round((row.value * 100) / total)} %`
                        ) : (
                            <Skeleton width={40} />
                        )}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default Legend;
