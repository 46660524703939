import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { setContext } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { assertUnreachable, makeUniqueName } from 'utilities';

import {
    addCompaniesToStaticList,
    cloneList as cloneListAPI,
    combineListToStaticList as combineListToStaticListAPI,
    createList as createListAPI,
    deleteList as deleteListAPI,
    deleteMultipleLists as deleteMultipleListsAPI,
    fetchDomainLists,
    fetchLegacyLists,
    fetchList,
    fetchOrganizationLists,
    migrateLegacyList as migrateLegacyListAPI,
    patchCompaniesToStaticList,
    removeCompaniesFromStaticList,
    revertLegacyList as revertLegacyListAPI,
    updateList,
} from 'api/lists';
import { List, ListType } from 'api/types/List';
import { domainProfileType, organizationProfileType } from 'api/types/Organization';
import { ContactViewItem } from 'components/features/lists/ContactListView';
import { useNavigateToList, useNavigateToUnknownList } from 'components/modules/profiles/ProfilesRouter';
import { useAuthContext } from 'contexts/AuthContext';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useFilterState } from 'contexts/FilterContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import useSelection from 'hooks/useSelection';

import { usePermissionContext } from './PermissionContext';
import { SortId } from './SortCompaniesContext/SortCompaniesContext';
import { Database } from './types/databases';
import { ListAction } from './utilities/listsUtils';

export type { Database, NordicDatabase } from './types/databases';

export const UPLOAD_FILE_VALUE = 'file-upload';

export interface ListsContextValue {
    /** ID of the list that was selected by the user and which is going to be
     * set as the selectedList after the lists have loaded. */
    selectedListId: string | undefined;
    /** Used for navigation inside application */
    lastSelectedListId: string | undefined;
    /** The currently selected list. During the loading of the lists may not be
     * the same that the selectedListId points to. */
    selectedList: List | undefined;
    /** Only the router should use this. Use the router's useNavigateToList instead when selecting a list in the UI. */
    selectList: (listId: string | undefined) => void;
    lists: List[];
    allCompaniesSelected: boolean;
    selectedCompanies: Array<string>;
    unselectedCompanies: Array<string>; // in case of allCompaniesSelected, allow tracking unselected
    selectProfile: (value: string | Array<string>) => void;
    unselectProfile: (value: string) => void;
    toggleSelectAllCompanies: (selected?: boolean) => void;
    allContactsSelected: boolean;
    selectedContacts: Array<ContactViewItem>;
    unselectedContacts: Array<ContactViewItem>;
    selectContact: (value: ContactViewItem) => void;
    unselectContact: (value: ContactViewItem) => void;
    toggleSelectAllContacts: (selected?: boolean) => void;
    isInitialLoading?: boolean;
    isListFetched?: boolean;
    database: Database | undefined;
    dbLists: List[];
    createList: (list: Partial<List>, type: ListType, domains?: string[]) => Promise<string>;
    doListAction: (listId: string, action: ListAction, fields?: Partial<List>) => Promise<void>;
    generateListName: (postfix?: string) => string;
    createNewList: (
        type: ListType,
        payload?: Partial<List>,
        callback?: (id: string) => void,
        domains?: string[],
    ) => Promise<string>;
    modifyList: (list: Partial<List> & { id: string }, type: ListType) => Promise<void>;
    cloneList: (list: Partial<List> & { id: string; name: string; type: ListType }) => Promise<void>;
    refetchLists: () => Promise<unknown[]>;
    refetchList: (id: string) => Promise<void>;
    deleteLists: (ids: string[]) => Promise<void>;
    changeDatabase: (db: Database, navigate?: boolean) => void;
    setSelectedListSort: (sort: SortId) => void;
    lastOpenedListByDatabase: { [K in Database]?: string };
    addCompaniesToList: (id: string, domains: string[]) => Promise<void>;
    patchCompaniesToList: (id: string, domains: string[]) => Promise<void>;
    removeCompaniesFromList: (id: string, domains: string[]) => Promise<void>;
    combineListToStaticList: (target: string, source: string, excludeDomains?: string[]) => Promise<void>;
    revertLegacyList: (list: List) => Promise<void>;
    migrateLegacyList: (list: List) => Promise<void>;
}

const ListsContext = React.createContext<ListsContextValue>({} as ListsContextValue);

const EMPTY_LISTS: List[] = [];

type ListsProviderProps = {
    children?: React.ReactNode;
};

const ListsProvider: React.FC<ListsProviderProps> = ({ children }) => {
    const [lists, setLists] = useState<List[]>(EMPTY_LISTS);
    const { countryPermissions } = usePermissionContext();

    const defaultDataBase: Database = useMemo(() => {
        return countryPermissions.includes('DOMAIN_DATA_BASIC')
            ? 'DOMAIN_DATA_BASIC'
            : (countryPermissions[0] as Database);
    }, [countryPermissions]);

    const [database, setDatabase] = useState<Database | undefined>(undefined);

    const { queryToFilters, query, filterListID, activeGroup } = useFilterState();
    const [selectedListId, setSelectedListId] = useState<string | undefined>(undefined);
    const [selectedList, setSelectedList] = useState<List | undefined>(undefined);
    const {
        selected: selectedCompanies,
        setSelected: setSelectedCompanies,
        unselected: unselectedCompanies,
        setUnselected: setUnselectedCompanies,
        isAllSelected: allCompaniesSelected,
        setIsAllSelected: setAllCompaniesSelected,
        select: selectCompany,
        unselect: unselectCompany,
        toggleAllSelected: toggleSelectAllCompanies,
    } = useSelection<string>();
    const {
        isAllSelected: allContactsSelected,
        selected: selectedContacts,
        unselected: unselectedContacts,
        select: selectContact,
        unselect: unselectContact,
        toggleAllSelected: toggleSelectAllContacts,
    } = useSelection<ContactViewItem>();
    const [lastSelectedListId, setLastSelectedListId] = useState<string | undefined>(undefined);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [lastOpenedListByDatabase, setLastOpenedListByDatabase] = useState<{ [K in Database]?: string }>({});

    const axios = useAxiosContext();
    const { authStatus } = useAuthContext();
    const navigateToUnknownList = useNavigateToUnknownList();
    const navigateToList = useNavigateToList();
    const { showSnackbar } = useSnackbarContext();

    setContext(
        'Selected List',
        selectedList
            ? {
                  id: selectedList.id,
                  query: (selectedList.converted_query?.query || selectedList.query) ?? {},
                  database,
              }
            : null,
    );

    useEffect(() => {
        if (defaultDataBase) {
            setDatabase((db) => {
                return !db ? defaultDataBase : db;
            });
        }
    }, [defaultDataBase]);

    const { data: organizationListsFromAPI, refetch: refetchOrganizationLists } = useQuery({
        queryKey: ['organization-lists'],
        queryFn: () => fetchOrganizationLists(axios),
        refetchOnWindowFocus: false,
        enabled: authStatus === 'logged-in',
    });

    const { data: domainListsFromAPI, refetch: refetchDomainLists } = useQuery({
        queryKey: ['domain-lists'],
        queryFn: () => fetchDomainLists(axios),
        refetchOnWindowFocus: false,
        enabled: authStatus === 'logged-in',
    });

    const { data: legacyListsFromAPI } = useQuery({
        queryKey: ['legacy-lists'],
        queryFn: () => fetchLegacyLists(axios),
        refetchOnWindowFocus: false,
        enabled: authStatus === 'logged-in',
    });

    const refetchLists = useCallback(async () => {
        return Promise.all([refetchOrganizationLists(), refetchDomainLists()]);
    }, [refetchDomainLists, refetchOrganizationLists]);

    // Workaround to to trigger less updates in consumers when dbLists is empty
    // In the current implementation ListsContext has many updates and in the beginning
    // triggers empty dbLists twice with with different reference
    // Can be removed when the core issue is fixed
    const dbLists = useMemo(() => {
        if (!lists.length) {
            return EMPTY_LISTS;
        }
        return lists.filter((list) => list.country === database);
    }, [database, lists]);

    /** Set selected list */
    const updateSelectedList = useCallback(
        (list: List | undefined, setFilters?: boolean) => {
            setSelectedList(list);
            const db: Database = list?.country as Database;
            if (db && countryPermissions.includes(db)) {
                setDatabase(db);
            }
            setSelectedCompanies([]);
            setUnselectedCompanies([]);
            setAllCompaniesSelected(false);
            if (list && setFilters) {
                queryToFilters((list.converted_query?.query || list.query) ?? {}, list.id);
            }
        },
        [countryPermissions, setSelectedCompanies, setUnselectedCompanies, setAllCompaniesSelected, queryToFilters],
    );

    /** Update list's data in lists array & selectedList */
    const updateListsState = useCallback((list: List) => {
        setLists((_lists) => {
            const newLists = _lists.map((l) => (l.id === list.id ? list : l));
            return sortLists(newLists);
        });

        setSelectedList((_list) => {
            if (list.id === _list?.id) {
                return list;
            }
            return _list;
        });
    }, []);

    const modifyList = useCallback(
        async (list: Partial<List> & { id: string }, type: ListType) => {
            const { data } = await updateList(axios, type, list);

            updateListsState(formatList(data));
        },
        [axios, updateListsState],
    );

    const changeDatabase = useCallback(
        (db: Database, navigate = true) => {
            if (countryPermissions.includes(db)) {
                setDatabase(db);
                setLastSelectedListId(undefined);
                setSelectedList(undefined);
                setSelectedListId(undefined);
                if (navigate) {
                    navigateToUnknownList('');
                }
            }
        },
        [navigateToUnknownList, countryPermissions],
    );

    const selectedListPrivileges = selectedList?.privileges?.current;

    useEffect(() => {
        if (activeGroup) {
            // prevent API calls while editing filters
            // while filter group is open
            return;
        }
        const queryChanged = query !== undefined && query !== selectedList?.query;
        const canModifyList =
            selectedList?.type !== 'legacy' &&
            (selectedListPrivileges === 'owner' || selectedListPrivileges === 'edit');
        if (queryChanged && selectedList?.id && selectedList.id === filterListID && canModifyList) {
            modifyList({ id: selectedList.id, query }, selectedList.type);
        }
    }, [
        activeGroup,
        filterListID,
        modifyList,
        query,
        selectedList?.id,
        selectedList?.query,
        selectedList?.type,
        selectedListPrivileges,
    ]);

    useEffect(() => {
        if (!domainListsFromAPI || !organizationListsFromAPI || !legacyListsFromAPI) {
            return;
        }

        const sortedLists = sortLists([
            ...domainListsFromAPI.map(formatList),
            ...organizationListsFromAPI.map(formatList),
            ...legacyListsFromAPI.map(formatList),
        ])
            // filter out list that we have no country permissions to
            .filter((i) => i.country && countryPermissions.includes(i.country));
        setLists(sortedLists);
        if (sortedLists.length) {
            setDatabase(sortedLists[0].country as Database);
        }
        setIsFirstLoad(false);
    }, [domainListsFromAPI, organizationListsFromAPI, legacyListsFromAPI, countryPermissions]);

    const createList = useCallback(
        async (payload: Partial<List>, type: ListType, domains?: string[]) => {
            const { data } = await createListAPI(axios, type, payload);

            const list = formatList(data);
            if (domains) {
                await addCompaniesToStaticList(axios, list.type, list.id, domains);
            }
            setLists((_lists) => sortLists([..._lists, list]));

            return list.id;
        },
        [axios],
    );

    const generateListName = useCallback(
        (postfix?: string): string => {
            const findNewListName = (index?: number): string => {
                const nameExists = (name: string): boolean => {
                    return lists.some((list) => list.name.includes(name));
                };
                const searchIndex = index ?? lists.length + 1;
                const proposedName = `New List #${searchIndex}`;
                if (nameExists(proposedName)) {
                    return findNewListName(searchIndex + 1);
                }
                return proposedName;
            };

            if (postfix) {
                return `${findNewListName()}: ${postfix}`;
            }
            return findNewListName();
        },
        [lists],
    );

    const createNewList = useCallback(
        async (type: ListType, payload?: Partial<List>, callback?: (listId: string) => void, domains?: string[]) => {
            const name = payload?.name ?? generateListName();
            const listId = await createList(
                {
                    name,
                    query: '{"?ALL":[]}',
                    ...(database !== 'DOMAIN_DATA_BASIC' ? { country: database } : {}),
                    ...payload,
                },
                type,
                domains,
            );
            navigateToList(listId, database === 'DOMAIN_DATA_BASIC' ? domainProfileType : organizationProfileType, '');
            if (callback) {
                setTimeout(() => callback(listId), 300);
            }
            return listId;
        },
        [database, createList, navigateToList, generateListName],
    );

    const deleteLists = useCallback(
        async (ids: string[]) => {
            let status;
            if (ids.length === 1) {
                ({ status } = await deleteListAPI(axios, ids[0]));
            } else {
                ({ status } = await deleteMultipleListsAPI(axios, ids));
            }

            if (status === 204) {
                setLastSelectedListId(undefined);
                const isSelectedListDeleted = !!ids.find((id) => selectedList?.id === id);
                setLists((_lists) => {
                    const newLists: List[] = _lists.filter((list) => !ids.includes(list.id));
                    return sortLists(newLists);
                });

                if (isSelectedListDeleted && selectedList) {
                    let nextList;

                    if (dbLists.length === ids.length) {
                        setLastSelectedListId(undefined);
                        setSelectedList(undefined);
                        setSelectedListId(undefined);
                        navigateToUnknownList('');
                        return;
                    }

                    // selected list + lists in the same category that were not deleted
                    const remainingLists = dbLists
                        .filter(
                            (list) =>
                                isUploaded(list) === isUploaded(selectedList) &&
                                isSharedWithYou(list) === isSharedWithYou(selectedList) &&
                                (list.type === 'legacy') === (selectedList.type === 'legacy'),
                        )
                        .filter((list) => list.id === selectedList.id || !ids.includes(list.id));

                    if (remainingLists.length > 1) {
                        const selectedIndexInRemaining = remainingLists.findIndex(
                            (list) => list.id === selectedList.id,
                        );

                        if (selectedIndexInRemaining + 1 < remainingLists.length) {
                            nextList = remainingLists[selectedIndexInRemaining + 1];
                        } else {
                            nextList = remainingLists[selectedIndexInRemaining - 1];
                        }
                    }

                    if (!nextList) {
                        nextList = dbLists.find((list) => !ids.includes(list.id));
                    }

                    setSelectedListId(nextList?.id);
                    updateSelectedList(nextList, true);
                }
            }
        },
        [axios, dbLists, navigateToUnknownList, selectedList, updateSelectedList],
    );

    const cloneList = useCallback(
        async (list: Partial<List> & { id: string; name: string; type: ListType }, select = true) => {
            const { data } = await cloneListAPI(axios, list.type, {
                ...list,
                name: makeUniqueName(
                    list.name,
                    dbLists.map((list) => list.name),
                ),
            });
            if (data?.id) {
                const newList = formatList(data);
                setLists((_lists) => sortLists([..._lists, newList]));
                if (select) {
                    updateSelectedList(newList, true);
                    navigateToList(newList.id);
                }
            }
        },
        [axios, updateSelectedList, navigateToList, dbLists],
    );

    const migrateLegacyList = useCallback(
        async (list: List) => {
            const { data } = await migrateLegacyListAPI(axios, list.id, list.country as Database);

            const newList = formatList(data);
            setLists((_lists) => sortLists([..._lists.filter((l) => l.id !== list.id), newList]));

            if (selectedList?.id === list.id) {
                updateSelectedList(newList, true);
                navigateToList(newList.id);
            }
        },
        [axios, navigateToList, selectedList?.id, updateSelectedList],
    );

    const doListAction = useCallback(
        async (listId: string, action: ListAction, fields?: Partial<List>) => {
            const list = lists.find((_list) => _list.id === listId);
            if (!list) {
                throw new Error('Tried to do action on a nonexisting list.');
            }
            switch (action) {
                case 'duplicate':
                    cloneList(list);
                    break;
                case 'convertToStatic':
                    break;
                case 'showInactive':
                    break;
                case 'favorite':
                    break;
                case 'share':
                    modifyList({ ...fields, id: listId }, list.type);
                    showSnackbar('Sharing permission updated');
                    break;
                case 'delete':
                case 'remove':
                    deleteLists([listId]);
                    break;
                case 'migrate':
                    migrateLegacyList(list);
                    break;
                default:
                    assertUnreachable(action, `Unknown list action: ${action}`);
            }
        },
        [lists, cloneList, modifyList, showSnackbar, deleteLists, migrateLegacyList],
    );

    const refetchList = useCallback(
        async (id: string) => {
            const list = lists.find((l) => l.id === id);
            if (list) {
                const { data } = await fetchList(axios, list.type, id);
                updateListsState(formatList(data));
            }
        },
        [axios, updateListsState, lists],
    );

    const selectList = useCallback(
        (listId: string | undefined) => {
            setSelectedListId(listId);

            if (!isFirstLoad && database) {
                setLastOpenedListByDatabase((values) => ({ ...values, [database]: listId }));
            }
        },
        [database, isFirstLoad],
    );

    const addCompaniesToList = useCallback(
        async (id: string, companies: string[]) => {
            await addCompaniesToStaticList(
                axios,
                database === 'DOMAIN_DATA_BASIC' ? 'static-domain-list' : 'static-organization-list',
                id,
                companies,
            );
        },
        [axios, database],
    );

    const patchCompaniesToList = useCallback(
        async (id: string, companies: string[]) => {
            await patchCompaniesToStaticList(
                axios,
                database === 'DOMAIN_DATA_BASIC' ? 'static-domain-list' : 'static-organization-list',
                id,
                companies,
            );
        },
        [axios, database],
    );

    const removeCompaniesFromList = useCallback(
        async (id: string, companies: string[]) => {
            await removeCompaniesFromStaticList(
                axios,
                database === 'DOMAIN_DATA_BASIC' ? 'static-domain-list' : 'static-organization-list',
                id,
                companies,
            );
        },
        [axios, database],
    );

    const combineListToStaticList = useCallback(
        async (target: string, source: string, excludeCompanies: string[] = []) => {
            const type = database === 'DOMAIN_DATA_BASIC' ? 'static-domain-list' : 'static-organization-list';
            let targetId = target;
            let responseData: List | undefined;

            if (target === 'NEW') {
                const payload = {
                    name: generateListName(),
                    country: database,
                };

                const { data } = await createListAPI(axios, type, payload);
                responseData = data;
                targetId = data?.id;
            }

            await combineListToStaticListAPI(axios, type, targetId, source);

            // till combineList supports excludes
            if (excludeCompanies.length) {
                await removeCompaniesFromStaticList(axios, type, targetId, excludeCompanies);
            }

            if (responseData) {
                const list = formatList(responseData);
                setLists((_lists) => sortLists([..._lists, list]));
                navigateToList(list.id);
            }
        },
        [axios, database, generateListName, navigateToList],
    );

    const revertLegacyList = useCallback(
        async (list: List) => {
            const { data } = await revertLegacyListAPI(axios, list.type, list.id);

            const newList = formatList(data);
            setLists((_lists) => sortLists([..._lists.filter((l) => l.id !== list.id), newList]));

            updateSelectedList(newList, true);
            navigateToList(newList.id);
        },
        [axios, navigateToList, updateSelectedList],
    );

    const setSelectedListSort = useCallback(
        (sort: SortId) => {
            setLists((_lists) => {
                const listIndex = _lists.findIndex((l) => l.id === selectedListId);
                const updatedList = { ..._lists[listIndex], sortBy: sort } as List;
                const newList = _lists.map((list, idx) => {
                    if (idx === listIndex) {
                        return updatedList;
                    }
                    return list;
                });
                return newList;
            });
        },
        [selectedListId],
    );

    const updateLastOpenUIValue = useCallback(
        (list: List) => {
            const listId = list.id;
            setLastSelectedListId(listId);
            const similarLists = dbLists.filter((_list) => list.type === _list.type);
            if (similarLists.findIndex((l) => l.id === listId) > 5) {
                // reorder lists based on updated `last_open_in_ui`
                // update value only if list is not visible,
                // assume 6 lists per category are shown as default

                const last_open_in_ui = new Date().toISOString();
                const updatedList = { ...list, last_open_in_ui } as List;

                updateListsState(updatedList);
                updateList(axios, list.type, {
                    id: list.id,
                    last_open_in_ui,
                });

                return updatedList;
            }

            return list;
        },
        [axios, dbLists, updateListsState],
    );

    /** This function handles setting the selectedList after the contents
     * of the lists array has changed (e.g. after the initial loading of the lists),
     * to have the same list as the selectedListId. Also takes care of the situation
     * where the route pointed to a list the user did not have access to. */
    useEffect(() => {
        if (selectedListId === undefined && selectedList !== undefined) {
            updateSelectedList(undefined, true);
        } else if (lists.length && selectedListId && selectedListId !== selectedList?.id) {
            const foundList = lists.find((list) => list.id === selectedListId);
            if (foundList) {
                const list = updateLastOpenUIValue(foundList);
                updateSelectedList(list, true);
            } else {
                navigateToUnknownList(selectedListId);
            }
        }
    }, [lists, selectedListId, selectedList, updateSelectedList, navigateToUnknownList, updateLastOpenUIValue]);

    const value = useMemo<ListsContextValue>(
        () => ({
            selectedListId,
            lastSelectedListId,
            selectedList,
            selectList,
            lists,
            selectedCompanies,
            unselectedCompanies,
            allCompaniesSelected,
            isInitialLoading: isFirstLoad,
            selectProfile: selectCompany,
            unselectProfile: unselectCompany,
            toggleSelectAllCompanies,
            allContactsSelected,
            selectedContacts,
            unselectedContacts,
            selectContact,
            unselectContact,
            toggleSelectAllContacts,
            createList,
            doListAction,
            generateListName,
            createNewList,
            deleteLists,
            modifyList,
            cloneList,
            refetchLists,
            refetchList,
            changeDatabase,
            database,
            dbLists,
            setSelectedListSort,
            lastOpenedListByDatabase,
            addCompaniesToList,
            patchCompaniesToList,
            removeCompaniesFromList,
            combineListToStaticList,
            revertLegacyList,
            migrateLegacyList,
        }),
        [
            allContactsSelected,
            selectedContacts,
            unselectedContacts,
            selectContact,
            unselectContact,
            toggleSelectAllContacts,
            addCompaniesToList,
            allCompaniesSelected,
            changeDatabase,
            cloneList,
            combineListToStaticList,
            createList,
            createNewList,
            database,
            dbLists,
            deleteLists,
            doListAction,
            generateListName,
            isFirstLoad,
            lastOpenedListByDatabase,
            lastSelectedListId,
            lists,
            modifyList,
            patchCompaniesToList,
            refetchList,
            refetchLists,
            removeCompaniesFromList,
            selectCompany,
            selectList,
            selectedCompanies,
            selectedList,
            selectedListId,
            setSelectedListSort,
            toggleSelectAllCompanies,
            unselectCompany,
            unselectedCompanies,
            revertLegacyList,
            migrateLegacyList,
        ],
    );

    return <ListsContext.Provider value={value}>{children}</ListsContext.Provider>;
};

export const isSharedWithYou = (list: List): boolean => {
    return ['edit', 'view'].includes(list.privileges.current || '');
};

export const isUploaded = (list: List): boolean => {
    return ['static-domain-list', 'static-organization-list'].includes(list.type || '');
};

const formatList = (list: List) => {
    if (list.type === 'legacy') {
        return list;
    }
    return {
        ...list,
        country: list.country || 'DOMAIN_DATA_BASIC',
    };
};

const sortLists = (lists: List[]) => lists.sort((a, b) => b.last_open_in_ui.localeCompare(a.last_open_in_ui));

const useListsContext = () => useContext(ListsContext);

export { ListsContext, ListsProvider, useListsContext };
