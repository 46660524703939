import React, { useState } from 'react';

import { filter, find, map } from 'lodash';

import DragAndDrop, { DraggableListItemType } from '../DragAndDrop';
import { TreeNode, TreeRawData } from '../Treeview';
import TreeContainer from '../Treeview/TreeContainer';
import { getChildIds, handleToggle } from '../Treeview/treeViewUtilities';
import { DragAndDropSection, GridContainer, GridItem, Item } from './styled';

export interface TreeSelectAndDragDropProps {
    rootItemsWithChildren: { [key: string]: string[] };
    data: TreeRawData;
    sortedItems: DraggableListItemType[];
    onOrderChange?: (sortedItems: DraggableListItemType[]) => void;
    disabledItems?: string[];
    alwaysSelectedIds?: string[];
    groupType?: 'dot-separated';
    excludeParents?: boolean;
    defaultExpanded?: string[];
}

export const TreeSelectAndDragDrop: React.FC<TreeSelectAndDragDropProps> = ({
    rootItemsWithChildren,
    data,
    sortedItems,
    onOrderChange,
    disabledItems = [],
    alwaysSelectedIds = [],
    groupType,
    excludeParents = false,
    defaultExpanded,
}) => {
    const [selectedItems, setSelectedItems] = useState<TreeNode[]>(() =>
        sortedItems.map((item) => ({ id: item.id, children: [], label: '', depth: 0 })),
    );

    const toggleSelection = (node: TreeNode) =>
        handleToggle(node, selectedItems, excludeParents, disabledItems, (result) => {
            setSelectedItems(result);
        });

    const handleRemove = (node: TreeNode) => {
        const { id } = node;
        const childIds = getChildIds(node.children);
        const valuesToRemove = [...childIds, id];
        const result = filter(selectedItems, (item) => !valuesToRemove.includes(item.id));
        setSelectedItems(result);
    };

    const onItemRemove = (item: DraggableListItemType): void => {
        const node = find(selectedItems, (selectedItem) => selectedItem.id === item.id);
        return node && handleRemove(node);
    };

    return (
        <GridContainer container>
            <GridItem item xs={6}>
                <TreeContainer
                    data={data}
                    onSelect={toggleSelection}
                    selectedRowIds={[...map(selectedItems, (selectedItem) => selectedItem.id), ...alwaysSelectedIds]}
                    rootItemsWithDirectChildren={rootItemsWithChildren}
                    disabledItems={disabledItems}
                    groupType={groupType}
                    excludeParents={excludeParents}
                    defaultExpanded={defaultExpanded}
                    title="Company data"
                />
            </GridItem>
            <GridItem item xs={6}>
                <Item>
                    <DragAndDropSection>
                        <DragAndDrop
                            disabledItems={[]}
                            data={data}
                            parentIds={alwaysSelectedIds}
                            label="Order shown in file"
                            selectedIds={[
                                ...alwaysSelectedIds,
                                ...map(selectedItems, (selectedItem) => selectedItem.id),
                            ]}
                            onItemRemove={onItemRemove}
                            onChange={onOrderChange}
                            sortedItems={sortedItems}
                        />
                    </DragAndDropSection>
                </Item>
            </GridItem>
        </GridContainer>
    );
};

export default TreeSelectAndDragDrop;
