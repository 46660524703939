import { Box } from '@mui/material';

import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

const Footer = () => (
    <Box
        sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bottom: 24,
        }}
    >
        <Typography variant="tiny" color="subtle">
            By logging in you agree to Vainu’s{' '}
            <Typography variant="tiny" component="span" weight="semibold">
                <Link href="https://www.vainu.com/legal-stuff/" toNewTab>
                    Terms and Conditions
                </Link>
            </Typography>
            {' & '}
            <Typography variant="tiny" component="span" weight="semibold">
                <Link href="https://www.vainu.com/legal-stuff/" toNewTab>
                    Privacy Policy
                </Link>
            </Typography>
            .
        </Typography>
        <Typography variant="tiny" color="subtle">
            ©{new Date().getFullYear()} Vainu. All Rights Reserved.
        </Typography>
    </Box>
);

export default Footer;
