import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ListPlus = (props) => (
    <SvgIcon {...props}>
        <path d="M19 20H17V17H14V15H17V12H19V15H22V17H19V20ZM12 17H2V15H12V17ZM15 13H2V11H15V13ZM15 9H2V7H15V9Z" />
    </SvgIcon>
);

export default ListPlus;
