import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HelpCircleOutline = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C6.47967 21.9939 2.00606 17.5203 2 12V11.8C2.10993 6.30453 6.63459 1.92796 12.1307 2.00088C17.6268 2.07381 22.0337 6.56889 21.9978 12.0653C21.9619 17.5618 17.4966 21.9989 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4.00001 11.992 4.00001C7.57528 4.00001 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM13 18H11V16H13V18ZM13 15H11C10.9684 13.6977 11.6461 12.4808 12.77 11.822C13.43 11.316 14 10.88 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10H8V9.91C8.01608 8.48094 8.79333 7.16901 10.039 6.4684C11.2846 5.76779 12.8094 5.78495 14.039 6.5134C15.2685 7.24186 16.0161 8.57094 16 10C15.9284 11.079 15.3497 12.0603 14.44 12.645C13.6177 13.1612 13.0847 14.0328 13 15Z" />
    </SvgIcon>
);

export default HelpCircleOutline;
