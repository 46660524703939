import React, { useState } from 'react';

import { ErrorBoundary } from '@sentry/react';
import { ErrorBoundaryProps } from '@sentry/react/types/errorboundary';

import { brandPalette } from 'design/theme/colors';

const getSentryLink = (errorId: string) => {
    const env = process.env.REACT_APP_ENV ?? process.env.NODE_ENV;
    const project = getProjectID(process.env.REACT_APP_SENTRY_DSN ?? '');
    return `https://vainu.sentry.io/issues/?environment=${env}&project=${project}&query=id:${errorId}`;
};

const getProjectID = (DSN: string) => DSN.substring(DSN.lastIndexOf('/') + 1);

type FallbackProps = {
    superuser?: boolean;
    errorId?: string;
    errorMsg?: string;
};

const Fallback: React.FC<FallbackProps> = ({ errorId, superuser, errorMsg }) => (
    <div
        style={{
            width: '100%',
            height: '100vh',
            padding: 30,
            backgroundColor: brandPalette.appBg,
            fontFamily: 'Roboto, sans-serif',
        }}
    >
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%',
                maxWidth: 400,
                margin: '0 auto',
            }}
        >
            <h1 style={{ marginBottom: 20 }}>Error! My only weakness!</h1>
            <p style={{ marginBottom: 20 }}>
                It seems you’ve found a bug in our system. We are very sorry for this occurrence. Good news is that
                we’ve logged the error and are onto fixing it.
            </p>
            {/* eslint-disable jsx-a11y/anchor-is-valid, react/jsx-no-target-blank */}
            <p style={{ marginBottom: 20 }}>
                {'Meanwhile, you could try '}
                <a href="#" onClick={() => window.location.reload()}>
                    refreshing the page
                </a>
                {' or '}
                <a target="_blank" href="https://help.vainu.app/">
                    contacting the support
                </a>
                .
            </p>
            {superuser && !!errorId && (
                <>
                    {!!errorMsg && (
                        <p style={{ marginBottom: 20 }}>
                            <h3>Error</h3>
                            <code
                                style={{
                                    display: 'block',
                                    padding: 8,
                                    border: '1px solid',
                                    borderColor: brandPalette.subtleLight,
                                    backgroundColor: brandPalette.white,
                                }}
                            >
                                {errorMsg}
                            </code>
                        </p>
                    )}
                    {process.env.REACT_APP_SENTRY_DSN ? (
                        <p style={{ marginBottom: 20 }}>
                            <a target="_blank" style={{ fontWeight: 'bold' }} href={getSentryLink(errorId)}>
                                View in Sentry
                            </a>
                            <br />
                            <span style={{ fontSize: 12 }}>Wait ~15 seconds for the issue to be created</span>
                        </p>
                    ) : null}
                </>
            )}
            {/* eslint-enable jsx-a11y/anchor-is-valid, react/jsx-no-target-blank */}
        </div>
    </div>
);

export const ErrorHandler: React.FC<ErrorBoundaryProps> = (props) => {
    const [superuser, setSuperuser] = useState(false);
    const [eventId, setEventId] = useState<string | undefined>();
    const [errorMsg, setErrorMsg] = useState('');
    return (
        <ErrorBoundary
            beforeCapture={(scope) => {
                const user = scope.getUser();
                if (user?.email && !superuser) {
                    const isSuperuser = user.email.endsWith('@vainu.io') || user.email.endsWith('@vainu.com');
                    if (!superuser && isSuperuser) {
                        setSuperuser(true);
                    }
                }
            }}
            onError={(error, _componentStack, eventId) => {
                setEventId(eventId);
                setErrorMsg(error.message);
                console.error(error);
            }}
            fallback={<Fallback superuser={superuser} errorId={eventId} errorMsg={errorMsg} />}
            {...props}
        />
    );
};

export default ErrorHandler;
