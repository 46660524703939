import { SelectOption } from 'components/tokens/select-components/Select';

const options: Array<[number, string, number]> = [
    [9572872, 'Homeless Shelter', 2],
    [9572873, 'Freemium', 2],
    [9572874, 'Industrial', 2],
    [9572875, 'Innovation Management', 2],
    [9572876, 'Lighting', 2],
    [9572877, 'Mobile', 2],
    [9572878, 'Tutoring', 2],
    [9572879, 'Clean Energy', 2],
    [9572880, 'Sustainability', 2],
    [9572881, 'Neuroscience', 2],
    [9572882, 'Religion', 2],
    [9572883, 'Social CRM', 2],
    [9572884, 'Information Technology', 2],
    [9572885, 'Events', 2],
    [9572886, 'Public Relations', 2],
    [9572887, 'Enterprise Software', 2],
    [9572888, 'Cloud Data Services', 2],
    [9572889, 'Charity', 2],
    [9572890, 'Video', 2],
    [9572891, 'Physical Security', 2],
    [9572892, 'Knowledge Management', 2],
    [9572893, 'Self-Storage', 2],
    [9572894, 'Hardware', 2],
    [9572895, 'Wholesale', 2],
    [9572896, 'Consulting', 2],
    [9572897, 'IaaS', 2],
    [9572898, 'IT Management', 2],
    [9572899, 'Retail', 2],
    [9572901, 'Vertical Search', 2],
    [9572900, 'Space Travel', 2],
    [9572902, 'Image Recognition', 2],
    [9572903, 'Accounting', 2],
    [9572904, 'Geospatial', 2],
    [9572906, 'Real Estate', 2],
    [9572907, 'Private Social Networking', 2],
    [9572905, 'PaaS', 2],
    [9572908, 'Wireless', 2],
    [9572909, 'Lifestyle', 2],
    [9572910, 'Data Mining', 2],
    [9572911, 'Incubators', 2],
    [9572912, 'Risk Management', 2],
    [9572913, 'Law Enforcement', 2],
    [9572914, 'Medical', 2],
    [9572915, 'Financial Services', 2],
    [9572916, 'Biometrics', 2],
    [9572917, 'Local', 2],
    [9572918, 'Aerospace', 2],
    [9572919, 'Augmented Reality', 2],
    [9572920, 'Credit Cards', 2],
    [9572921, 'Mobile Devices', 2],
    [9572922, 'Celebrity', 2],
    [9572923, 'Racing', 2],
    [9572924, 'Coworking', 2],
    [9572925, 'Vending and Concessions', 2],
    [9572926, 'RFID', 2],
    [9572927, 'College Recruiting', 2],
    [9572928, 'Developer Tools', 2],
    [9572929, 'EBooks', 2],
    [9572930, 'Multi-level Marketing', 2],
    [9572931, 'Document Management', 2],
    [9572932, 'SaaS', 2],
    [9572933, 'Crowdfunding', 2],
    [9572934, 'Outsourcing', 2],
    [9572935, 'Biotechnology', 2],
    [9572936, 'Personalization', 2],
    [9572937, 'Theatre', 2],
    [9572938, 'Social Media', 2],
    [9572939, 'Reading Apps', 2],
    [9572940, 'Loyalty Programs', 2],
    [9572941, 'Toys', 2],
    [9572942, 'Public Transportation', 2],
    [9572943, 'Health Care', 2],
    [9572944, 'Digital Media', 2],
    [9572945, 'Alumni', 2],
    [9572946, 'Internet', 2],
    [9572947, 'E-Commerce', 2],
    [9572948, 'Tobacco', 2],
    [9572949, 'Therapeutics', 2],
    [9572950, 'Business Development', 2],
    [9572951, 'Impact Investing', 2],
    [9572952, 'Blogging Platforms', 2],
    [9572953, 'Crowdsourcing', 2],
    [9572954, 'Credit', 2],
    [9572955, 'Fraud Detection', 2],
    [9572956, 'Energy', 2],
    [9572957, 'Mobile Advertising', 2],
    [9572958, 'Lead Generation', 2],
    [9572959, 'Landscaping', 2],
    [9572960, 'Customer Service', 2],
    [9572961, 'Industrial Automation', 2],
    [9572962, 'Email Marketing', 2],
    [9572963, 'Child Care', 2],
    [9572964, 'Payments', 2],
    [9572965, 'Information Services', 2],
    [9572966, 'Content Syndication', 2],
    [9572967, 'Leisure', 2],
    [9572968, 'Field-Programmable Gate Array (FPGA)', 2],
    [9572969, 'Communications Infrastructure', 2],
    [9572970, 'Social Bookmarking', 2],
    [9572971, 'Power Grid', 2],
    [9572972, 'Public Safety', 2],
    [9572973, 'Internet of Things', 2],
    [9572974, 'Data Center Automation', 2],
    [9572975, 'Open Source', 2],
    [9572976, 'Virtualization', 2],
    [9572977, 'Waste Management', 2],
    [9572978, 'Test and Measurement', 2],
    [9572979, 'Social Media Advertising', 2],
    [9572981, 'Text Analytics', 2],
    [9572980, 'Manufacturing', 2],
    [9572982, 'Energy Efficiency', 2],
    [9572983, 'Cooking', 2],
    [9572984, 'Training', 2],
    [9572988, 'Privacy', 2],
    [9572986, 'Information and Communications Technology (ICT)', 2],
    [9572987, 'Young Adults', 2],
    [9572985, 'Construction', 2],
    [9572989, 'Electronics', 2],
    [9572990, 'Telecommunications', 2],
    [9572991, 'Gift Exchange', 2],
    [9572992, 'Video Conferencing', 2],
    [9572993, 'Web Design', 2],
    [9572994, 'Language Learning', 2],
    [9572995, 'Billing', 2],
    [9572996, 'Architecture', 2],
    [9572997, 'Professional Services', 2],
    [9572998, 'Cloud Security', 2],
    [9572999, 'Project Management', 2],
    [9573000, 'Video Games', 2],
    [9573001, 'Dental', 2],
    [9573002, 'Music', 2],
    [9573003, 'Universities', 2],
    [9573004, 'B2B', 2],
    [9573006, 'Brand Marketing', 2],
    [9573005, 'Music Education', 2],
    [9573007, 'Nanotechnology', 2],
    [9573008, 'Musical Instruments', 2],
    [9573009, 'Fashion', 2],
    [9573010, 'Animal Feed', 2],
    [9573011, 'Advice', 2],
    [9573012, 'Journalism', 2],
    [9573013, 'Lingerie', 2],
    [9573014, 'Career Planning', 2],
    [9573015, 'Solar', 2],
    [9573016, 'Film', 2],
    [9573017, 'E-Commerce Platforms', 2],
    [9573019, 'Hedge Funds', 2],
    [9573018, 'Software', 2],
    [9573020, 'Social Impact', 2],
    [9573021, 'Digital Signage', 2],
    [9573022, 'Organic', 2],
    [9573023, 'Professional Networking', 2],
    [9573024, 'Recreation', 2],
    [9573025, 'Automotive', 2],
    [9573026, 'Semantic Search', 2],
    [9573027, 'Content Delivery Network', 2],
    [9573028, 'Location Based Services', 2],
    [9573029, 'Insurance', 2],
    [9573030, 'Outdoor Advertising', 2],
    [9573031, 'Venture Capital', 2],
    [9573032, 'Virtual Goods', 2],
    [9573033, 'Video Streaming', 2],
    [9573034, 'Legal', 2],
    [9573035, 'Sporting Goods', 2],
    [9573036, 'Gamification', 2],
    [9573037, 'Apps', 2],
    [9573038, 'Resorts', 2],
    [9573039, '3D Printing', 2],
    [9573040, 'Finance', 2],
    [9573041, 'Cloud Management', 2],
    [9573042, 'Visual Search', 2],
    [9573043, 'Web Browsers', 2],
    [9573044, 'Darknet', 2],
    [9573045, 'Semantic Web', 2],
    [9573046, 'Soccer', 2],
    [9573047, 'Concerts', 2],
    [9573048, 'Local Advertising', 2],
    [9573049, 'Computer Vision', 2],
    [9573050, 'Dietary Supplements', 2],
    [9573051, 'MMO Games', 2],
    [9573052, 'National Security', 2],
    [9573053, 'Assisted Living', 2],
    [9573054, 'Music Venues', 2],
    [9573055, 'Small and Medium Businesses', 2],
    [9573056, 'Product Design', 2],
    [9573057, 'Sports', 2],
    [9573058, 'Infrastructure', 2],
    [9573059, 'Aquaculture', 2],
    [9573060, 'Direct Sales', 2],
    [9573061, 'Wealth Management', 2],
    [9573062, 'Direct Marketing', 2],
    [9573063, 'Browser Extensions', 2],
    [9573065, 'Content Creators', 2],
    [9573064, 'Analytics', 2],
    [9573066, 'Natural Resources', 2],
    [9573067, 'Transportation', 2],
    [9573068, 'Retail Technology', 2],
    [9573069, 'In-Flight Entertainment', 2],
    [9573070, 'Human Resources', 2],
    [9573071, 'Event Management', 2],
    [9573072, 'Underserved Children', 2],
    [9573073, 'Clinical Trials', 2],
    [9573074, 'Data Visualization', 2],
    [9573075, 'Prediction Markets', 2],
    [9573076, 'Collectibles', 2],
    [9573077, 'Farmers Market', 2],
    [9573078, 'Commercial Real Estate', 2],
    [9573079, 'Consumer Electronics', 2],
    [9573080, 'Travel', 2],
    [9573081, 'Meeting Software', 2],
    [9573082, 'Emerging Markets', 2],
    [9573083, 'Gambling', 2],
    [9573084, 'Supply Chain Management', 2],
    [9573085, 'Parking', 2],
    [9573086, 'Nutraceutical', 2],
    [9573087, 'Natural Language Processing', 2],
    [9573088, 'Non Profit', 2],
    [9573089, 'Cloud Computing', 2],
    [9573092, 'Jewelry', 2],
    [9573091, '3D Technology', 2],
    [9573090, 'Homeland Security', 2],
    [9573093, 'Advertising Platforms', 2],
    [9573094, 'Lead Management', 2],
    [9573095, 'Charter Schools', 2],
    [9573096, 'QR Codes', 2],
    [9573097, 'Digital Entertainment', 2],
    [9573099, 'Presentations', 2],
    [9573100, 'Price Comparison', 2],
    [9573098, 'Photo Sharing', 2],
    [9573101, 'Advertising', 2],
    [9573102, 'Agriculture', 2],
    [9573103, 'Politics', 2],
    [9573104, 'Nightlife', 2],
    [9573105, 'Email', 2],
    [9573106, 'Banking', 2],
    [9573107, 'Electrical Distribution', 2],
    [9573108, 'Ticketing', 2],
    [9573109, 'Shopping', 2],
    [9573110, 'Machine Learning', 2],
    [9573111, 'Organic Food', 2],
    [9573112, 'Adventure Travel', 2],
    [9573113, 'Tourism', 2],
    [9573114, 'Publishing', 2],
    [9573115, 'Transaction Processing', 2],
    [9573116, 'Content Discovery', 2],
    [9573117, 'Personal Branding', 2],
    [9573118, 'Personal Finance', 2],
    [9573119, 'Film Production', 2],
    [9573120, 'Indoor Positioning', 2],
    [9573121, 'Nightclubs', 2],
    [9573122, 'Restaurants', 2],
    [9573123, 'Financial Exchanges', 2],
    [9573124, 'Edutainment', 2],
    [9573125, 'Contact Management', 2],
    [9573126, 'Security', 2],
    [9573127, 'Rehabilitation', 2],
    [9573128, 'Auctions', 2],
    [9573129, 'SEO', 2],
    [9573130, 'DIY', 2],
    [9573132, 'Fleet Management', 2],
    [9573131, 'Quantified Self', 2],
    [9573133, 'Outdoors', 2],
    [9573134, 'Genetics', 2],
    [9573136, 'Cyber Security', 2],
    [9573144, 'Recycling', 2],
    [9573135, 'TV Production', 2],
    [9573138, 'Stock Exchanges', 2],
    [9573140, 'Energy Management', 2],
    [9573142, 'CAD', 2],
    [9573139, 'Robotics', 2],
    [9573141, 'Craft Beer', 2],
    [9573143, 'Ad Targeting', 2],
    [9573145, 'Point of Sale', 2],
    [9573137, 'Home Renovation', 2],
    [9573146, 'Developer APIs', 2],
    [9573147, 'Audiobooks', 2],
    [9573148, 'Psychology', 2],
    [9573149, 'Green Building', 2],
    [9573150, 'Advanced Materials', 2],
    [9573151, 'Performing Arts', 2],
    [9573153, 'Sales Automation', 2],
    [9573152, 'Business Intelligence', 2],
    [9573158, 'Film Distribution', 2],
    [9573155, 'Oil and Gas', 2],
    [9573154, 'Web Development', 2],
    [9573168, 'Market Research', 2],
    [9573159, 'Collaborative Consumption', 2],
    [9573157, 'Procurement', 2],
    [9573156, 'Identity Management', 2],
    [9573164, 'Fitness', 2],
    [9573160, 'Sex Industry', 2],
    [9573167, 'Humanitarian', 2],
    [9573163, 'CRM', 2],
    [9573166, 'Parenting', 2],
    [9573162, 'Casual Games', 2],
    [9573161, 'Collaboration', 2],
    [9573165, 'NFC', 2],
    [9573169, 'Photography', 2],
    [9573170, 'Search Engine', 2],
    [9573171, 'Data Integration', 2],
    [9573172, 'Video Editing', 2],
    [9573173, 'SMS', 2],
    [9573174, 'Audio', 2],
    [9573175, 'Network Security', 2],
    [9573176, 'Broadcasting', 2],
    [9573177, 'Flash Storage', 2],
    [9573178, 'Brewing', 2],
    [9573179, 'Education', 2],
    [9573180, 'Alternative Medicine', 2],
    [9573181, 'Human Computer Interaction', 2],
    [9573182, 'Photo Editing', 2],
    [9573183, 'Motion Capture', 2],
    [9573184, 'Green Consumer Goods', 2],
    [9573185, 'Coupons', 2],
    [9573186, 'Textiles', 2],
    [9573187, 'Product Search', 2],
    [9573188, 'Veterinary', 2],
    [9573189, 'Virtual Currency', 2],
    [9573190, 'Hospitality', 2],
    [9573191, 'Social News', 2],
    [9573192, 'Video on Demand', 2],
    [9573193, 'Independent Music', 2],
    [9573194, 'Farming', 2],
    [9573195, 'Retirement', 2],
    [9573196, 'Group Buying', 2],
    [9573197, 'Eyewear', 2],
    [9573198, 'News', 2],
    [9573199, 'Google Glass', 2],
    [9573201, 'Linux', 2],
    [9573200, 'Operating Systems', 2],
    [9573202, 'Navigation', 2],
    [9573203, 'Wine And Spirits', 2],
    [9573204, 'Predictive Analytics', 2],
    [9573205, 'Home Decor', 2],
    [9573206, 'Virtual Desktop', 2],
    [9573207, 'Property Management', 2],
    [9573212, 'Video Chat', 2],
    [9573211, 'Big Data', 2],
    [9573209, 'Artificial Intelligence', 2],
    [9573208, 'Smart Building', 2],
    [9573210, 'Corporate Training', 2],
    [9573213, 'Marketing Automation', 2],
    [9573214, 'Elderly', 2],
    [9573215, 'Communities', 2],
    [9573216, 'Sponsorship', 2],
    [9573218, 'Social Recruiting', 2],
    [9573217, 'Coffee', 2],
    [9573220, 'Cause Marketing', 2],
    [9573219, 'Flowers', 2],
    [9573222, 'Bioinformatics', 2],
    [9573221, 'Cosmetic Surgery', 2],
    [9573226, 'Intellectual Property', 2],
    [9573228, 'Simulation', 2],
    [9573225, 'Logistics', 2],
    [9573230, 'Handmade', 2],
    [9573229, 'App Discovery', 2],
    [9573224, 'Consumer Lending', 2],
    [9573231, 'Personal Health', 2],
    [9573234, 'Food Processing', 2],
    [9573233, 'Ediscovery', 2],
    [9573227, 'mHealth', 2],
    [9573235, 'Interior Design', 2],
    [9573223, 'Water Purification', 2],
    [9573232, 'Social Media Management', 2],
    [9573236, 'Tea', 2],
    [9573237, 'Business Information Systems', 2],
    [9573238, 'Messaging', 2],
    [9573239, 'Smart Home', 2],
    [9573240, 'Guides', 2],
    [9573241, 'Q&A', 2],
    [9573242, 'Swimming', 2],
    [9573243, 'Beauty', 2],
    [9573244, 'iOS', 2],
    [9573245, 'Reputation', 2],
    [9573246, 'WebOS', 2],
    [9573247, 'Shoes', 2],
    [9573248, 'Nutrition', 2],
    [9573249, 'Diabetes', 2],
    [9573250, 'Art', 2],
    [9573251, 'App Marketing', 2],
    [9573252, 'Printing', 2],
    [9573253, 'SNS', 2],
    [9573254, 'Web Hosting', 2],
    [9573255, 'Spam Filtering', 2],
    [9573256, 'Fantasy Sports', 2],
    [9573257, 'Recruiting', 2],
    [9573258, 'VoIP', 2],
    [9573259, 'Classifieds', 2],
    [9573260, 'Cloud Infrastructure', 2],
    [9573261, 'Penetration Testing', 2],
    [9573262, 'Teenagers', 2],
    [9573263, 'Android', 2],
    [9573264, 'First Aid', 2],
    [9573265, 'Content', 2],
    [9573266, 'Online Auctions', 2],
    [9573267, 'Cosmetics', 2],
    [9573268, 'Skill Assessment', 2],
    [9573269, 'Mobile Payments', 2],
    [9573270, 'Corrections Facilities', 2],
    [9573271, 'Task Management', 2],
    [9573272, 'Employment', 2],
    [9573273, 'Gift Card', 2],
    [9573274, 'File Sharing', 2],
    [9573275, 'Serious Games', 2],
    [9573276, 'Social Media Marketing', 2],
    [9573277, 'Speech Recognition', 2],
    [9573278, 'Fruit', 2],
    [9573279, 'Shipping', 2],
    [9573280, 'Drones', 2],
    [9573281, 'Water', 2],
    [9573282, 'Diving', 2],
    [9573283, 'Bitcoin', 2],
    [9573284, 'Fertility', 2],
    [9573285, 'Energy Storage', 2],
    [9573286, 'Intelligent Systems', 2],
    [9573287, 'Comics', 2],
    [9573288, 'Facial Recognition', 2],
    [9573289, 'Real Time', 2],
    [9573290, 'Elder Care', 2],
    [9573291, 'Wearables', 2],
    [9573292, 'FinTech', 2],
    [9573293, 'Health Diagnostics', 2],
    [9573294, 'EdTech', 2],
    [9573295, 'Delivery', 2],
    [9573296, 'GreenTech', 2],
    [9573297, 'Ride Sharing', 2],
    [9573298, 'Podcast', 2],
    [9573299, 'Cryptocurrency', 2],
    [9573300, 'Subscription Service', 2],
    [9573301, 'Graphic Design', 2],
    [9573302, 'Furniture', 2],
    [9573321, 'Food Delivery', 2],
    [9573306, 'Google', 2],
    [9573312, 'Trading Platform', 2],
    [9573327, 'Auto Insurance', 2],
    [9573335, 'Media and Entertainment', 2],
    [9573343, 'MOOC', 2],
    [9573348, 'DSP', 2],
    [9573365, 'GPU', 2],
    [9573388, 'B2C', 2],
    [9573303, 'Social Entrepreneurship', 2],
    [9573309, 'Drone Management', 2],
    [9573332, 'Mechanical Engineering', 2],
    [9573311, 'Life Insurance', 2],
    [9573322, 'Railroad', 2],
    [9573310, 'Environmental Engineering', 2],
    [9573317, 'Nuclear', 2],
    [9573313, 'Virtual Reality', 2],
    [9573339, 'Playstation', 2],
    [9573357, 'Generation Z', 2],
    [9573371, 'Mobile Apps', 2],
    [9573378, 'Product Management', 2],
    [9573380, 'Ferry Service', 2],
    [9573384, 'Micro Lending', 2],
    [9573394, 'eSports', 2],
    [9573326, 'Commercial Insurance', 2],
    [9573334, 'Baseball', 2],
    [9573354, 'Property Insurance', 2],
    [9573385, 'Cloud Storage', 2],
    [9573397, 'Home Improvement', 2],
    [9573320, 'Livestock', 2],
    [9573323, 'Network Hardware', 2],
    [9573336, 'IT Infrastructure', 2],
    [9573337, 'Domain Registrar', 2],
    [9573349, 'AgTech', 2],
    [9573361, 'Same Day Delivery', 2],
    [9573402, 'Computer', 2],
    [9573307, 'Twitter', 2],
    [9573368, 'E-Signature', 2],
    [9573375, 'Volley Ball', 2],
    [9573392, 'Ad Network', 2],
    [9573398, 'Leasing', 2],
    [9573331, 'Millennials', 2],
    [9573308, 'Software Engineering', 2],
    [9573314, 'Roku', 2],
    [9573340, 'Ultimate Frisbee', 2],
    [9573366, 'Cricket', 2],
    [9573379, 'Application Specific Integrated Circuit (ASIC)', 2],
    [9573304, 'Industrial Design', 2],
    [9573333, 'Intrusion Detection', 2],
    [9573364, 'Satellite Communication', 2],
    [9573369, 'Embedded Software', 2],
    [9573374, 'Fast-Moving Consumer Goods', 2],
    [9573381, 'Car Sharing', 2],
    [9573387, 'Content Marketing', 2],
    [9573401, 'Compliance', 2],
    [9573316, 'Table Tennis', 2],
    [9573325, 'UX Design', 2],
    [9573330, 'Embedded Systems', 2],
    [9573407, 'Social', 2],
    [9573342, 'Consumer Applications', 2],
    [9573345, 'Windows', 2],
    [9573346, 'Tennis', 2],
    [9573362, 'Data Storage', 2],
    [9573373, 'Basketball', 2],
    [9573376, 'Web Apps', 2],
    [9573382, 'RISC', 2],
    [9573391, 'Ad Retargeting', 2],
    [9573395, 'Home Services', 2],
    [9573403, 'Online Forums', 2],
    [9573344, 'Fossil Fuels', 2],
    [9573356, 'Xbox', 2],
    [9573370, 'Debit Cards', 2],
    [9573377, 'Lending', 2],
    [9573383, 'Enterprise', 2],
    [9573319, 'Tizen', 2],
    [9573328, 'Event Promotion', 2],
    [9573350, 'Product Research', 2],
    [9573352, 'Commercial Lending', 2],
    [9573358, 'Nintendo', 2],
    [9573372, 'American Football', 2],
    [9573386, 'Industrial Engineering', 2],
    [9573324, 'Gift', 2],
    [9573329, 'Chemical Engineering', 2],
    [9573353, 'Cycling', 2],
    [9573359, 'Sharing Economy', 2],
    [9573389, 'Ad Server', 2],
    [9573360, 'Adult', 2],
    [9573363, 'Vocational Education', 2],
    [9573338, 'Management Information Systems', 2],
    [9573341, 'Rugby', 2],
    [9573367, 'Air Transportation', 2],
    [9573390, 'Animation', 2],
    [9573393, 'Facility Management', 2],
    [9573399, 'Virtual Assistant', 2],
    [9573400, 'Electric Vehicle', 2],
    [9573406, 'TV', 2],
    [9573305, 'Hockey', 2],
    [9573347, 'Private Cloud', 2],
    [9573396, 'Made to Order', 2],
    [9573315, 'Water Transportation', 2],
    [9573318, 'Quality Assurance', 2],
    [9573351, 'A/B Testing', 2],
    [9573355, 'Consumer Software', 2],
    [9573404, 'Optical Communication', 2],
    [9573405, 'Fuel Cell', 2],
    [9573408, 'Marketplace', 2],
    [9573409, 'Rental', 2],
    [9573410, 'Timeshare', 2],
    [9573418, 'STEM Education', 2],
    [9573428, 'Franchise', 2],
    [9573419, 'CMS', 2],
    [9573411, 'Vacation Rental', 2],
    [9573413, 'E-Learning', 2],
    [9573416, 'Developer Platform', 2],
    [9573420, 'Primary Education', 2],
    [9573412, 'Reservations', 2],
    [9573430, 'Sex Tech', 2],
    [9573417, 'Property Development', 2],
    [9573415, 'Wellness', 2],
    [9573414, 'Affiliate Marketing', 2],
    [9573422, 'Dating', 2],
    [9573423, 'Residential', 2],
    [9573424, 'Enterprise Applications', 2],
    [9573426, 'Grocery', 2],
    [9573429, 'Communication Hardware', 2],
    [9573425, 'Boating', 2],
    [9573427, 'Biomass Energy', 2],
    [9573421, 'Health Insurance', 2],
    [9573431, 'Debt Collections', 2],
    [9573432, 'Creative Agency', 2],
    [9573433, 'Medical Device', 2],
    [9573434, 'Home and Garden', 2],
    [9573435, 'Laser', 2],
    [9573436, 'GPS', 2],
    [9573437, 'Life Science', 2],
    [9573438, 'Assistive Technology', 2],
    [9573439, 'Unified Communications', 2],
    [9573440, 'Surfing', 2],
    [9573441, 'Employee Benefits', 2],
    [9573442, 'Biofuel', 2],
    [9573443, 'Civil Engineering', 2],
    [9573444, 'Music Label', 2],
    [9573445, 'Online Portals', 2],
    [9573446, 'macOS', 2],
    [9573447, 'Emergency Medicine', 2],
    [9573448, 'Social Shopping', 2],
    [9573449, 'Technical Support', 2],
    [9573450, 'Golf', 2],
    [9573451, 'Wind Energy', 2],
    [9573452, 'Building Material', 2],
    [9573453, 'Semiconductor', 2],
    [9573454, 'Music Streaming', 2],
    [9573455, 'Textbook', 2],
    [9573456, 'Government', 2],
    [9573457, 'Hunting', 2],
    [9573458, 'Social Network', 2],
    [9573459, 'Family', 2],
    [9573460, 'Limousine Service', 2],
    [9573461, 'Data Center', 2],
    [9573462, 'Freelance', 2],
    [9573463, 'LGBT', 2],
    [9573464, 'Courier Service', 2],
    [9573465, 'Hospital', 2],
    [9573466, 'Console Games', 2],
    [9573467, 'Application Performance Management', 2],
    [9573468, 'Electronic Design Automation (EDA)', 2],
    [9573469, 'Real Estate Investment', 2],
    [9573470, 'Marketing', 2],
    [9573471, 'Local Shopping', 2],
    [9573472, 'Windows Phone', 2],
    [9573473, 'ISP', 2],
    [9573474, 'Consumer Research', 2],
    [9573475, 'Ad Exchange', 2],
    [9573476, 'Consumer Reviews', 2],
    [9573477, 'Usability Testing', 2],
    [9573478, 'Asset Management', 2],
    [9573479, 'Mapping Services', 2],
    [9573480, 'Virtual Workforce', 2],
    [9573481, 'Wedding', 2],
    [9573482, 'Sensor', 2],
    [9573483, 'Productivity Tools', 2],
    [9573484, 'Scheduling', 2],
    [9573485, 'Children', 2],
    [9573486, 'Enterprise Resource Planning (ERP)', 2],
    [9573487, 'CleanTech', 2],
    [9573488, 'Baby', 2],
    [9573489, 'Translation Service', 2],
    [9573490, 'Pharmaceutical', 2],
    [9573491, 'Continuing Education', 2],
    [9573492, 'Cannabis', 2],
    [9573493, 'Personal Development', 2],
    [9573494, 'Sailing', 2],
    [9573495, 'Mechanical Design', 2],
    [9573497, 'Angel Investment', 2],
    [9573496, 'Database', 2],
    [9573498, 'PC Games', 2],
    [9573499, 'Archiving Service', 2],
    [9573500, 'Business Travel', 2],
    [9573501, 'Shipping Broker', 2],
    [9573502, 'Mining Technology', 2],
    [9573503, "Women's", 2],
    [9573504, 'Consumer', 2],
    [9573505, "Men's", 2],
    [9573506, 'Food and Beverage', 2],
    [9573507, 'Field Support', 2],
    [9573508, 'Rental Property', 2],
    [9573509, 'Facebook', 2],
    [9573510, 'Skiing', 2],
    [9573511, 'Higher Education', 2],
    [9573512, 'Virtual World', 2],
    [9573513, 'Chemical', 2],
    [9573514, 'Renewable Energy', 2],
    [9573515, 'Video Advertising', 2],
    [9573516, 'Gaming', 2],
    [9573517, 'Funerals', 2],
    [9573518, 'Mineral', 2],
    [9573519, 'Local Business', 2],
    [9573520, 'Hotel', 2],
    [9573521, 'Electronic Health Record (EHR)', 2],
    [9573522, 'Peer to Peer', 2],
    [9573523, 'Fuel', 2],
    [9573524, 'Biopharma', 2],
    [9573525, 'Taxi Service', 2],
    [9573526, 'Battery', 2],
    [9573527, 'DRM', 2],
    [9573528, 'Pollution Control', 2],
    [9573529, 'Internet Radio', 2],
    [9573530, 'Call Center', 2],
    [9573531, 'Precious Metals', 2],
    [9573532, 'Gift Registry', 2],
    [9573533, 'Digital Marketing', 2],
    [9573534, 'Sales', 2],
    [9573535, 'SEM', 2],
    [9573537, 'Commercial', 2],
    [9573539, 'Secondary Education', 2],
    [9573536, 'Generation Y', 2],
    [9573538, 'Staffing Agency', 2],
    [9573540, 'Pet', 2],
    [9573541, 'Service Industry', 2],
    [9573542, 'Flash Sale', 2],
    [9573543, 'Online Games', 2],
    [9573544, 'Building Maintenance', 2],
    [9573547, 'Document Preparation', 2],
    [9573545, 'Extermination Service', 2],
    [9573546, 'Facilities Support Services', 2],
    [9573548, 'Collection Agency', 2],
    [9573551, 'Office Administration', 2],
    [9573550, 'Forestry', 2],
    [9573553, 'Equestrian', 2],
    [9573558, 'Bakery', 2],
    [9573552, 'Horticulture', 2],
    [9573557, 'Packaging Services', 2],
    [9573549, 'Housekeeping Service', 2],
    [9573554, 'Trade Shows', 2],
    [9573555, 'Credit Bureau', 2],
    [9573556, 'Home Health Care', 2],
    [9573559, 'Shopping Mall', 2],
    [9573563, 'Machinery Manufacturing', 2],
    [9573561, 'Winery', 2],
    [9573560, 'Industrial Manufacturing', 2],
    [9573562, 'Foundries', 2],
    [9573564, 'Laundry and Dry-cleaning', 2],
    [9573565, 'Hydroponics', 2],
    [9573566, 'Confectionery', 2],
    [9573567, 'Paper Manufacturing', 2],
    [9573568, 'Timber', 2],
    [9573570, 'Ports and Harbors', 2],
    [9573569, 'Amusement Park and Arcade', 2],
    [9573577, 'Seafood', 2],
    [9573580, 'Outpatient Care', 2],
    [9573572, 'Social Assistance', 2],
    [9573575, 'Recreational Vehicles', 2],
    [9573573, 'Casino', 2],
    [9573571, 'Tour Operator', 2],
    [9573574, 'Recipes', 2],
    [9573576, 'Travel Agency', 2],
    [9573579, 'Catering', 2],
    [9573582, 'Snack Food', 2],
    [9573578, 'Wired Telecommunications', 2],
    [9573581, 'Museums and Historical Sites', 2],
    [9573583, 'Distillery', 2],
    [9573584, 'Contests', 2],
    [9573585, 'Food Trucks', 2],
    [9573586, 'Nursing and Residential Care', 2],
    [9573587, 'Plastics and Rubber Manufacturing', 2],
    [9573588, 'Freight Service', 2],
    [9573589, 'Autonomous Vehicles', 2],
    [9573590, 'Travel Accommodations', 2],
    [9573591, 'Management Consulting', 2],
    [9573592, 'Environmental Consulting', 2],
    [9573593, 'Wood Processing', 2],
    [9573594, 'Warehousing', 2],
    [9573595, 'Janitorial Service', 2],
    [9573596, 'Parks', 2],
    [9573597, 'Delivery Service', 2],
    [9573598, 'Marine Transportation', 2],
    [9573599, 'Consumer Goods', 2],
    [9573600, 'Mining', 2],
    [9573601, 'Presentation Software', 2],
    [9573602, 'GovTech', 2],
    [9573603, 'Desktop Apps', 2],
    [9573604, 'CivicTech', 2],
    [9573605, 'Military', 2],
    [9573606, 'Association', 2],
    [9573607, 'InsurTech', 2],
    [9573608, 'Funding Platform', 2],
    [9573609, 'Blockchain', 2],
    [9573610, 'Ethereum', 2],
    [9573611, 'Quantum Computing', 2],
    [9573612, 'Marine Technology', 2],
    [9573613, 'Smart Cities', 2],
    [9573614, 'Last Mile Transportation', 2],
    [9573615, 'Legal Tech', 2],
    [9576238, '.NET', 2],
    [9576239, 'API', 2],
    [9576240, 'Air Conditioning', 2],
    [9576241, 'Amazon Web Services (AWS)', 2],
    [9576242, 'Assembly', 2],
    [9576243, 'Auditing', 2],
    [9576244, 'Azure', 2],
    [9576245, 'Bankruptcy', 2],
    [9576246, 'Banners', 2],
    [9576247, 'Blogging', 2],
    [9576248, 'Building Information Modeling (BIM)', 2],
    [9576249, 'Business Continuity', 2],
    [9576250, 'Business Law', 2],
    [9576251, 'C#', 2],
    [9576252, 'CCTV', 2],
    [9576253, 'Cisco', 2],
    [9576254, 'Cloud Solutions', 2],
    [9576255, 'Coaching', 2],
    [9576256, 'Commissioning', 2],
    [9576257, 'Communications', 2],
    [9576258, 'Computer Numerical Control (CNC)', 2],
    [9576259, 'Connectivity', 2],
    [9576260, 'Contract Management', 2],
    [9576261, 'Contract Manufacturing', 2],
    [9576262, 'Copywriting', 2],
    [9576263, 'Corporate Finance', 2],
    [9576264, 'Corporate Law', 2],
    [9576265, 'Criminal Defence', 2],
    [9576266, 'Criminal Law', 2],
    [9576267, 'Culture', 2],
    [9576268, 'Customer Engagement', 2],
    [9576269, 'Customer Experience', 2],
    [9576270, 'Data Management', 2],
    [9576271, 'Data Protection', 2],
    [9576272, 'Data Science', 2],
    [9576273, 'Data Security', 2],
    [9576274, 'DevOps', 2],
    [9576275, 'Digital Health', 2],
    [9576276, 'Disaster Recovery', 2],
    [9576277, 'Diversity', 2],
    [9576278, 'Divorce', 2],
    [9576279, 'Drupal', 2],
    [9576280, 'Due Diligence', 2],
    [9576281, 'Electrical Engineering', 2],
    [9576282, 'Employee Engagement', 2],
    [9576283, 'Employer Branding', 2],
    [9576284, 'Engineering', 2],
    [9576285, 'Environment', 2],
    [9576286, 'Executive Search', 2],
    [9576287, 'Facilitation', 2],
    [9576288, 'Facilities Management', 2],
    [9576289, 'Family Law', 2],
    [9576290, 'GDPR', 2],
    [9576291, 'GIS', 2],
    [9576292, 'HTML5', 2],
    [9576293, 'HVAC', 2],
    [9576294, 'Home Insurance', 2],
    [9576295, 'Housing', 2],
    [9576296, 'IT consulting', 2],
    [9576297, 'Immigration', 2],
    [9576298, 'Industry 4.0', 2],
    [9576299, 'Interim Management', 2],
    [9576300, 'Investments', 2],
    [9576301, 'Javascript', 2],
    [9576302, 'Leadership Development', 2],
    [9576303, 'Licensing', 2],
    [9576304, 'Linkedin', 2],
    [9576305, 'Litigation', 2],
    [9576306, 'Luxury', 2],
    [9576307, 'Magento', 2],
    [9576308, 'Managed Services', 2],
    [9576309, 'Marine', 2],
    [9576310, 'Mediation', 2],
    [9576311, 'Meetings', 2],
    [9576312, 'Mergers & Acquisitions', 2],
    [9576313, 'Mergers and Acquisitions', 2],
    [9576314, 'Microsoft', 2],
    [9576315, 'MySQL', 2],
    [9576316, 'Neurology', 2],
    [9576317, 'Office 365', 2],
    [9576318, 'Oncology', 2],
    [9576319, 'Oracle', 2],
    [9576320, 'Outplacement', 2],
    [9576321, 'PHP', 2],
    [9576322, 'Packaging Design', 2],
    [9576323, 'Payroll', 2],
    [9576324, 'Pediatrics', 2],
    [9576325, 'Personal Injury', 2],
    [9576326, 'Personal Insurance', 2],
    [9576327, 'Philanthropy', 2],
    [9576328, 'Plumbing', 2],
    [9576329, 'Power BI', 2],
    [9576330, 'Private Equity', 2],
    [9576331, 'Probate', 2],
    [9576332, 'Public Affairs', 2],
    [9576333, 'Python', 2],
    [9576334, 'Real Estate Law', 2],
    [9576335, 'Real Estate Planning', 2],
    [9576336, 'Relocation Services', 2],
    [9576337, 'Remodeling', 2],
    [9576338, 'Research', 2],
    [9576339, 'Roofing', 2],
    [9576340, 'SAP', 2],
    [9576341, 'SQL', 2],
    [9576342, 'Sales Enablement', 2],
    [9576343, 'Sales Training', 2],
    [9576344, 'Salesforce', 2],
    [9576345, 'Science', 2],
    [9576346, 'Scrum', 2],
    [9576347, 'Service Design', 2],
    [9576348, 'Shopify', 2],
    [9576349, 'Siding', 2],
    [9576350, 'Sourcing', 2],
    [9576351, 'Staff Augmentation', 2],
    [9576352, 'Strategy', 2],
    [9576353, 'Structural Engineering', 2],
    [9576354, 'Succession Planning', 2],
    [9576355, 'Systems Engineering', 2],
    [9576356, 'Talent Management', 2],
    [9576357, 'Tax', 2],
    [9576358, 'Team Building', 2],
    [9576359, 'Technology Consulting', 2],
    [9576360, 'VMWARE', 2],
    [9576361, 'Welding', 2],
    [9576362, 'Wills', 2],
    [9576363, 'Wordpress', 2],
    [9576542, 'Counseling', 2],
    [9576544, 'Employment Law', 2],
    [9576874, 'Circular Economy', 2],
    [9576875, 'Professional Events and Conference Organizers', 2],
    [9577086, 'Casual Dining', 2],
    [9577087, 'Quick Service Restaurant', 2],
    [9577090, 'Modern Quick Meal', 2],
];

const formatOptions = (values: Array<[number, string, number]>): SelectOption[] => {
    return values.map((i) => ({ value: i[0], label: `${i[1]}` }));
};

export const VCIOptions = formatOptions(options).sort((optionA, optionB) =>
    optionA.label < optionB.label ? -1 : optionA.label > optionB.label ? 1 : 0,
);

export const findVCIId = (value: string): number | undefined => {
    const found = options.find((row) => row[1] === value);
    if (found) {
        return found[0];
    }
    return undefined;
};
