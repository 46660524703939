import React from 'react';

import { FilterOperator } from 'api/types/FilterOperators';

import { TValuesType, getFilterConfig } from '../../FiltersBaseConfig';
import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterProps,
} from '../../FilterTypes';
import { useChipInputFilter } from '../common/useChipInputFilter';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, makeFullPath, valueArrayToText } from '../utils';

const { baseConfig, getInitValueConfigDefault } = getFilterConfig(FilterID.company_name);
export type TValues = TValuesType<typeof baseConfig, string[]>;

const config: FilterConfig<TValues> = {
    ...baseConfig,
    ...getInitValueConfigDefault(FilterOperator.STARTSWITH, []),
};

export const companyNameFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.basic,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        render: (props: FilterProps<TValues>) => <CompanyName {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [
    FilterOperator.STARTSWITH,
    FilterOperator.CONTAINS,
    `${FilterOperator.NOT}${FilterOperator.STARTSWITH}`,
    `${FilterOperator.NOT}${FilterOperator.CONTAINS}`,
].map((value) => formatOperatorValue(value as OperatorValue));

export const CompanyName: React.VFC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const props = useChipInputFilter({
        filter,
        sameFiltersState,
        uuid,
        groupUUID,
        removeFilter,
        operators,
        placeholder: 'name...',
        valuePath: makeFullPath(filter, true),
    });

    return <FilterTemplate {...props} />;
};
