import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { isToday, isYesterday } from 'date-fns';

import { isSitewideNotification, Notification } from 'api/types/Notification';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

import NotificationItem from './NotificationItem';
import {
    IconWrapper,
    CategoryWrapper,
    Category,
    NotificationWrapper,
    NotificationSectionHeaderWrapper,
    EmptyNotificationWrapper,
    EmptyIconWrapper,
} from './styled';
import { getNotificationDate, sortNotificationsByTime } from './utils';

export interface NotificationsProps {
    notifications: Notification[];
    onClick: (notificationId: string) => void;
    onNotificationDismiss: (notificationId: string) => void;
    onCloseClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
    handleAsyncProcessSeen: (jobId: string) => void;
    handleNotificationSeen: (notificationId: string) => void;
    isOpen?: boolean;
}

interface NavigationTab {
    name: string;
    id: Tab;
}

const navigationTabs: NavigationTab[] = [
    {
        name: 'All activity',
        id: 'all',
    },
    {
        name: 'App',
        id: 'app',
    },
    {
        name: 'Integrations',
        id: 'integrations',
    },
    {
        name: 'Blog',
        id: 'blog',
    },
];

type Tab = 'all' | 'app' | 'integrations' | 'blog';

type Period = 'today' | 'yesterday' | 'earlier';

interface NotificationsByPeriod {
    today: Notification[];
    yesterday: Notification[];
    earlier: Notification[];
}

const categoryBasedNotification: Record<Tab, string[]> = {
    all: ['all'],
    app: ['target_group_csv', 'public-v3-import-domains-async-api', 'static_list_async', 'send_message_queue_items'],
    integrations: ['crm-import', 'crm-export', '-full-export', '-import'],
    blog: ['sitewide_notification'],
};

/**
 * Config-like list with conditions and labels for grouping dates. Each date category should be a subset of the next.
 */
const getNotificationPeriod = (date: Date): Period => {
    // if (isThisHour) return 'this hour';

    if (isToday(date)) return 'today';
    if (isYesterday(date) || isToday(date)) return 'yesterday';

    // if (isThisWeek) return 'this week';
    // if (isThisMonth) return 'this month';

    return 'earlier';
};

export const NotificationDrawer: React.FC<NotificationsProps> = ({
    notifications,
    isOpen,
    onCloseClick,
    onClick,
    handleAsyncProcessSeen,
    handleNotificationSeen,
    onNotificationDismiss,
}) => {
    const [activeTab, setActiveTab] = useState<Tab>('all');

    const [notificationsToShow, setNotificationsToShow] = useState<Notification[]>(() => []);

    useEffect(() => {
        if (activeTab === 'all') {
            setNotificationsToShow(sortNotificationsByTime(notifications));
        } else {
            const filter = categoryBasedNotification[activeTab];
            const filteredNotifications = notifications.filter((notification) => {
                return filter.some((filterCategory: string) => notification.type.endsWith(filterCategory));
            }) as Notification[];
            setNotificationsToShow(sortNotificationsByTime(filteredNotifications));
        }
    }, [activeTab, notifications]);

    /**
     * Map notification items and add appropriate period group label if notification is the first in its period group.
     */
    const mapNotifications = () => {
        let hasOnlyEarlier = true;

        const notificationsByPeriodMap = notificationsToShow.reduce(
            (acc, notification) => {
                const periodKey: keyof NotificationsByPeriod = getNotificationPeriod(getNotificationDate(notification));
                const nextValue = [...acc[periodKey], notification];

                if (periodKey !== 'earlier' && nextValue.length > 0) hasOnlyEarlier = false;

                return { ...acc, [periodKey]: nextValue };
            },
            { today: [], yesterday: [], earlier: [] } as NotificationsByPeriod,
        );

        const render = (
            hasOnlyEarlier: boolean,
            periodKey: string,
            notification: Notification,
            notificationIndex: number,
        ) => {
            const handleMarkAsSeen = isSitewideNotification(notification)
                ? () => handleNotificationSeen(notification.id)
                : () => handleAsyncProcessSeen(notification.job_id);

            if (notificationIndex > 0) {
                return (
                    <NotificationItem
                        key={notification.id}
                        notification={notification}
                        onDismiss={onNotificationDismiss}
                        handleMarkAsSeen={handleMarkAsSeen}
                        onClick={onClick}
                    />
                );
            }

            return (
                <NotificationSectionHeaderWrapper key={notification.id}>
                    {(periodKey !== 'earlier' || (periodKey === 'earlier' && !hasOnlyEarlier)) && (
                        <Typography variant="tiny" weight="semibold" sx={{ textTransform: 'uppercase' }} color="subtle">
                            {periodKey}
                        </Typography>
                    )}
                    <NotificationItem
                        notification={notification}
                        onDismiss={onNotificationDismiss}
                        onClick={onClick}
                        handleMarkAsSeen={handleMarkAsSeen}
                    />
                </NotificationSectionHeaderWrapper>
            );
        };

        return Object.entries(notificationsByPeriodMap).map(([periodKey, periodValue]) => {
            return periodValue.map((notification: Notification, notificationIndex: number) => {
                return render(hasOnlyEarlier, periodKey, notification, notificationIndex);
            });
        });
    };

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onCloseClick}
            PaperProps={{ elevation: 4, sx: { overflowY: 'visible' } }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'background.default',
                    opacity: '0.5!important',
                },
            }}
        >
            <Box sx={{ width: 520, position: 'relative', height: '100%' }}>
                <CategoryWrapper>
                    {navigationTabs.map((tab) => (
                        <Category
                            key={tab.id}
                            active={tab.id === activeTab ? 'true' : undefined}
                            onClick={() => setActiveTab(tab.id)}
                            variant="flat"
                            size="medium"
                        >
                            {tab.name}
                        </Category>
                    ))}
                </CategoryWrapper>
                <NotificationWrapper>
                    {notificationsToShow.length > 0 ? (
                        mapNotifications()
                    ) : (
                        <EmptyNotificationWrapper>
                            <EmptyIconWrapper>
                                <Icon
                                    type="NotificationOutline"
                                    color="grey.300"
                                    sx={{
                                        height: 160,
                                        width: 160,
                                        position: 'absolute',
                                        left: 45,
                                    }}
                                />
                                <Icon
                                    type="Search"
                                    sx={{
                                        height: 100,
                                        width: 100,
                                        position: 'absolute',
                                        right: '1.5rem',
                                        bottom: '0.5rem',
                                    }}
                                    color="primary.main"
                                />
                            </EmptyIconWrapper>
                            <Typography variant="h5" color="dark">
                                Nothing to notify at this moment
                            </Typography>
                            <Typography variant="small" sx={{ color: 'text.secondary' }}>
                                You might like checking out our&nbsp;
                                <Link toNewTab sx={{ color: 'text.primary' }} href="https://www.vainu.com/blog">
                                    blog
                                </Link>
                            </Typography>
                        </EmptyNotificationWrapper>
                    )}
                </NotificationWrapper>
                <IconWrapper onClick={onCloseClick}>
                    <Icon type="CloseBig" color="primary.main" />
                </IconWrapper>
            </Box>
        </Drawer>
    );
};

export default NotificationDrawer;
