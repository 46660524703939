import type { PaletteOptions } from '@mui/material/styles/createPalette';

import { brandPalette, BrandPalette } from './colors';

type Color = string;

interface AugmentedColors {
    button: {
        text: Color;
        background: Color;
        flatSelected: Color;
    };
    checkbox: {
        empty: Color;
        emptyBg: Color;
        emptyHover: Color;
        checked: Color;
        checkedBg: Color;
        disabled: Color;
    };
    field: {
        background: Color;
        hover: Color;
        hoverDarken: Color;
    };
    highlight: {
        background: Color;
        border: Color;
        searchText: Color;
    };
    border: Color;
    link: Color;
    changeIndicator: {
        up: Color;
        down: Color;
        stagnant: Color;
    };
    icon: {
        basic: Color;
        colorful: Color;
        subtle: Color;
    };
    app: {
        background: Color;
    };
    chart: {
        default: Color;
        grey1: Color;
        grey2: Color;
        grey3: Color;
        grey4: Color;
        grey5: Color;
        axis: Color;
        axisLabel: Color;
        label: Color;
    };
    map: {
        markerShadow: Color;
        markerDefault: Color;
        markerHighlight: Color;
        markerFill: Color;
    };
    critical: Color;
    banner: {
        connectors: Color;
        profiles: Color;
        triggers: Color;
    };
    dateRange: {
        main: Color;
        light: Color;
    };
    brandColors: BrandPalette;
    upgrade: {
        text: Color;
        border: Color;
        background: Color;
        hover: Color;
    };
    usage: {
        matching: Color;
        download: Color;
        updated: Color;
        downloadAccent: Color;
        matchedHistory: Color;
        updatedHistory: Color;
    };
    tooltip: {
        subtle: Color;
    };
    menu: {
        hover: Color;
        selected: Color;
        selectedText: Color;
    };
    clusters: {
        allCompanies: Color;
        allClusters: Color;
        noCluster: Color;
        largestCluster1: Color;
        largestCluster2: Color;
        restClusters1: Color;
        restClusters2: Color;
        selectedRestCluster: Color;
    };
    terms: {
        primary: Color;
        secondary: Color;
        tertiary: Color;
        box: Color;
    };
}

declare module '@mui/material/styles' {
    interface Palette extends AugmentedColors {}
    interface PaletteOptions extends AugmentedColors {}
}

declare module '@mui/material/styles/createPalette' {
    interface TypeText {
        subtle: Color;
        strong: Color;
    }
}

export const augmentedColors: AugmentedColors = {
    button: {
        text: brandPalette.white,
        background: brandPalette.onyx,
        flatSelected: brandPalette.subtleLight,
    },
    checkbox: {
        empty: brandPalette.subtleLight,
        emptyBg: brandPalette.white,
        emptyHover: brandPalette.subtle,
        checked: brandPalette.tertiaryBlue,
        checkedBg: brandPalette.white,
        disabled: brandPalette.subtleLight,
    },
    field: {
        background: brandPalette.cloud,
        hover: brandPalette.subtleLight,
        hoverDarken: 'rgba(0, 0, 0, 0.118)', // This produces the same color as subtleLight on white backgrounds, but darkens colored backgrounds more naturally
    },
    highlight: {
        background: brandPalette.successGreen100,
        border: brandPalette.successGreen500,
        searchText: brandPalette.tertiaryBlue100,
    },
    border: brandPalette.subtleLight,
    link: brandPalette.sky,
    changeIndicator: {
        up: brandPalette.green,
        down: brandPalette.magenta,
        stagnant: brandPalette.sky,
    },
    icon: {
        basic: brandPalette.onyx,
        colorful: brandPalette.sky,
        subtle: brandPalette.subtle,
    },
    app: { background: brandPalette.appBg },
    chart: {
        default: brandPalette.sky,
        grey1: '#2C3F59',
        grey2: '#8498B3',
        grey3: '#A0B8D9',
        grey4: '#CCD9EB',
        grey5: '#E9EFF6',
        axis: brandPalette.subtleLight,
        axisLabel: brandPalette.subtle,
        label: brandPalette.body,
    },
    map: {
        markerShadow: brandPalette.subtleLight,
        markerDefault: brandPalette.green,
        markerHighlight: brandPalette.warningOrange,
        markerFill: brandPalette.white,
    },
    critical: brandPalette.criticalRed,
    banner: {
        connectors: brandPalette.downToEarthBrown,
        profiles: brandPalette.reliableBrown,
        triggers: brandPalette.reliableBrown,
    },
    dateRange: {
        main: brandPalette.tertiaryBlue,
        light: brandPalette.tertiaryBlue500,
    },
    upgrade: {
        text: brandPalette.secondaryPurple,
        border: brandPalette.secondaryPurple500,
        background: brandPalette.secondaryPurple100,
        hover: brandPalette.secondaryPurple500,
    },
    brandColors: brandPalette,
    usage: {
        matching: brandPalette.primaryGreen500,
        updated: brandPalette.tertiaryBlue,
        download: brandPalette.secondaryPurple700,
        downloadAccent: brandPalette.warningOrange,
        matchedHistory: brandPalette.primaryGreen500,
        updatedHistory: brandPalette.tertiaryBlue,
    },
    tooltip: {
        subtle: brandPalette.subtleLight,
    },
    menu: {
        hover: brandPalette.cloud,
        selected: brandPalette.tertiaryBlue100,
        selectedText: brandPalette.tertiaryBlue,
    },
    clusters: {
        allCompanies: brandPalette.onyx,
        allClusters: brandPalette.tertiaryBlue,
        noCluster: brandPalette.subtle,
        largestCluster1: brandPalette.primaryGreen,
        largestCluster2: brandPalette.secondaryPurple,
        restClusters1: brandPalette.reliableBrown,
        restClusters2: brandPalette.downToEarthBrown,
        selectedRestCluster: brandPalette.warningOrange,
    },
    terms: {
        primary: brandPalette.primaryGreen,
        secondary: '#00B26E',
        tertiary: brandPalette.reliableBrown,
        box: `${brandPalette.reliableBrown}26`,
    },
};

export const palette = {
    common: {
        black: brandPalette.onyx,
        white: brandPalette.white,
        green: brandPalette.green,
        orange: brandPalette.orange,
    },
    primary: {
        main: brandPalette.onyx,
    },
    secondary: {
        main: brandPalette.sky,
    },
    error: {
        main: brandPalette.errorMagenta,
        light: brandPalette.errorMagenta100,
    },
    warning: {
        main: brandPalette.warningOrange,
        light: brandPalette.warningOrange100,
    },
    info: {
        main: brandPalette.tertiaryBlue,
        light: brandPalette.tertiaryBlue100,
    },
    success: {
        main: brandPalette.successGreen,
        light: brandPalette.successGreen100,
    },
    grey: {
        50: brandPalette.cloud,
        100: '#f0f0f0', // Should only be used when having skeletons on top of appBg background
        200: brandPalette.subtleLight,
        400: brandPalette.middle,
        500: brandPalette.subtle,
        800: brandPalette.body,
        900: brandPalette.onyx,
    },
    text: {
        primary: brandPalette.body,
        secondary: brandPalette.subtle,
        subtle: brandPalette.subtle,
        strong: brandPalette.onyx,
    },
    background: {
        paper: brandPalette.white,
        default: brandPalette.appBg,
    },
    action: {
        disabled: brandPalette.subtle,
        disabledOpacity: 0.3,
        disabledBackground: brandPalette.subtleLight,
    },
    ...augmentedColors,
};

const paletteOptions: PaletteOptions = palette;

export default paletteOptions;
