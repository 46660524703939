import React from 'react';

import { uniq } from 'lodash';

import { BasicInfoProps } from '.';
import ExpandableRow from './ExpandableRow';

const AuxiliaryNames: React.FC<Required<Pick<BasicInfoProps, 'company_names'>>> = ({ company_names }) => {
    const companyNames = uniq(company_names);

    return (
        <ExpandableRow
            rows={companyNames.map((name) => (
                <div key={name}>{name}</div>
            ))}
        />
    );
};

export default AuxiliaryNames;
