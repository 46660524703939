import React, { useRef } from 'react';

import { Box, Tooltip } from '@mui/material';

import { useTextCutOff } from 'hooks/useTextCutOff';

import Typography, { TypographyProps } from '../Typography';

export type NumberDataPointBaseProps = {
    label?: React.ReactNode;
    variant?: 'default' | 'medium' | 'small';
    isEstimation?: boolean;
    labelPosition?: 'top' | 'bottom';
    /** Only to be used with `small` NumberDataPoint variant  */
    hideLabel?: boolean;
    align?: 'left' | 'center';
    /** By default, the component shows a tooltip when the value container overflows. With this prop you can override
     * it and always show a tooltip. */
    tooltip?: string;
};

export type NumberDataPointContentProps = NumberDataPointBaseProps & {
    children?: React.ReactNode;
};

export const NumberDataPointContent: React.FC<NumberDataPointContentProps> = (props) => {
    const {
        label,
        variant: numberVariant = 'default',
        labelPosition = 'top',
        hideLabel = false,
        isEstimation = false,
        align = 'center',
        tooltip,
        children,
    } = props;

    const valueRef = useRef<HTMLElement | null>(null);
    const [isTextCutOff, onRefresh] = useTextCutOff(valueRef);

    const labelProps: TypographyProps =
        numberVariant === 'small' ? { variant: 'mini' } : { variant: 'small', weight: 'semibold' };
    const valueProps: TypographyProps =
        numberVariant === 'medium'
            ? {
                  fontSize: 24,
                  //   width: '10rem',
              }
            : numberVariant === 'small'
              ? {
                    ...(hideLabel && { variant: 'small' }),
                    fontSize: 14,
                    maxWidth: '5rem',
                    lineHeight: 1.4,
                }
              : { maxWidth: '10rem' };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: align === 'left' ? 'flex-start' : 'center',
            }}
        >
            {!hideLabel && labelPosition === 'top' && (
                <Typography {...labelProps} color="subtle" textAlign={align}>
                    {label}
                </Typography>
            )}
            <Tooltip
                open={!!tooltip ? undefined : isTextCutOff}
                onOpen={!!tooltip ? undefined : () => onRefresh(true)}
                onClose={!!tooltip ? undefined : () => onRefresh(false)}
                title={tooltip ?? children}
                arrow={false}
            >
                <Typography
                    data-testid="number-data-point-value"
                    ref={valueRef}
                    variant="number"
                    {...valueProps}
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: align === 'left' ? 'left' : 'center',
                    }}
                >
                    {isEstimation && '~ '}
                    {children}
                </Typography>
            </Tooltip>
            {!hideLabel && labelPosition === 'bottom' && (
                <Typography {...labelProps} color="subtle" textAlign={align}>
                    {label}
                </Typography>
            )}
        </Box>
    );
};

export default NumberDataPointContent;
