import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const NotificationBellWithDot = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C10.8954 22 10 21.1046 10 20H14C14 21.1046 13.1046 22 12 22ZM20 19H4V17L6 16V10.5C5.94732 9.0891 6.26594 7.68911 6.924 6.43998C7.57904 5.2815 8.6987 4.45886 10 4.17998V1.99998H13.646C12.3464 3.45242 12.1295 5.57638 13.1087 7.26153C14.0879 8.94667 16.0406 9.80992 17.946 9.39998C17.981 9.75698 17.998 10.127 17.998 10.5V16L19.998 17V19H20Z" />
    </SvgIcon>
);

export default NotificationBellWithDot;
