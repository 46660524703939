import React, { useEffect, useMemo, useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { assertArray } from 'utilities';

import { getMessageQueueItems } from 'api/message_queue';
import { MessageQueue } from 'api/types/MessageQueue';
import { useAxiosContext } from 'contexts/AxiosContext';

const MESSAGEQUEUELIMIT = 25;

export type MessageQueueLoadingValue = 'initial' | 'loadingMore' | 'endOfResults' | 'idle';

interface MessageQueueContextValue {
    items: MessageQueue[];
    setItems: React.Dispatch<React.SetStateAction<MessageQueue[]>>;
    fetchMoreMessageQueueItems: () => void;
    hasNextPage: boolean | undefined;
    isFetchingMessageQueue: boolean;
    messageQueueFetching: MessageQueueLoadingValue;
}

type MessageQueueProviderProps = { children: React.ReactNode; triggerID: string };

export const MessageQueueContext = React.createContext<MessageQueueContextValue | undefined>(undefined);

const fetchMessageQueueItems = async (axios: AxiosInstance, offset: number, queryKeys: string[]) => {
    const [_queryKey, triggerID] = queryKeys;
    const { data } = await getMessageQueueItems(axios, triggerID, offset, MESSAGEQUEUELIMIT);
    return data;
};

function MessageQueueProvider({ children, triggerID = '' }: MessageQueueProviderProps) {
    const axios = useAxiosContext();
    const [items, setItems] = useState<MessageQueue[]>([]);

    const {
        data,
        isFetchingNextPage,
        fetchNextPage: fetchMoreMessageQueueItems,
        hasNextPage,
        isFetching: isFetchingMessageQueue,
    } = useInfiniteQuery({
        queryKey: ['message-queue', triggerID],
        queryFn: ({ pageParam = 0, queryKey }) => fetchMessageQueueItems(axios, pageParam, queryKey),
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage?.length < MESSAGEQUEUELIMIT) {
                return undefined;
            }
            return allPages.reduce((acc, cur) => acc + cur.length, 0);
        },
        refetchOnWindowFocus: false,
        enabled: !!triggerID,
    });

    useEffect(() => {
        const mqItems = assertArray(data?.pages).flat();
        setItems(mqItems);
    }, [data]);

    const messageQueueFetching: MessageQueueLoadingValue = useMemo(() => {
        if (isFetchingMessageQueue && isFetchingNextPage) {
            return 'loadingMore';
        }
        if (!isFetchingMessageQueue && hasNextPage) {
            return 'idle';
        }
        if (!isFetchingMessageQueue && !hasNextPage) {
            return 'endOfResults';
        }
        return 'initial';
    }, [isFetchingMessageQueue, isFetchingNextPage, hasNextPage]);

    const value = useMemo(
        () => ({
            items,
            setItems,
            fetchMoreMessageQueueItems,
            hasNextPage,
            isFetchingMessageQueue,
            messageQueueFetching,
        }),
        [fetchMoreMessageQueueItems, hasNextPage, isFetchingMessageQueue, items, messageQueueFetching],
    );

    return <MessageQueueContext.Provider value={value}>{children}</MessageQueueContext.Provider>;
}

export { MessageQueueProvider };
