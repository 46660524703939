import { forwardRef, useRef } from 'react';

import { styled } from '@mui/material';
import Chip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import clsx from 'clsx';

import Icon, { IconType } from 'components/tokens/Icon';

interface ChipWithActionProps extends Omit<MuiChipProps, 'variant' | 'icon'> {
    icon?: IconType;
    label: string;
    actionText: React.ReactNode;
    onActionClick: () => void;
}

export const ChipWithAction: React.FC<ChipWithActionProps> = forwardRef(
    ({ label, icon, className, actionText, onActionClick, ...props }, ref) => {
        return (
            <Chip
                ref={ref}
                label={<LabelWithAction label={label} actionText={actionText} onActionClick={onActionClick} />}
                variant="filled"
                className={clsx(className)}
                icon={icon && <Icon type={icon} />}
                {...props}
            />
        );
    },
);

ChipWithAction.displayName = 'ChipWithAction';

interface LabelWithActionProps {
    label: string;
    actionText: React.ReactNode;
    onActionClick: () => void;
}

const LabelWithAction = ({ label, actionText, onActionClick }: LabelWithActionProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    return (
        <StyledLabel>
            {label}
            <button onClick={onActionClick} ref={buttonRef}>
                {' '}
                {actionText}
            </button>
        </StyledLabel>
    );
};

const StyledLabel = styled('div')`
    & > span {
        padding-right: 0;
    }

    button {
        border: none;
        background: none;
        text-decoration: underline;
        color: ${({ theme }) => theme.palette.brandColors.tertiaryBlue};
        cursor: pointer;
        overflow: hidden;
        padding-left: 5px;
        display: inline;
    }
`;
