import React, { useRef, useState } from 'react';

import { Box, styled, Tooltip } from '@mui/material';

import { List } from 'api/types/List';
import ShareList from 'components/features/lists/ShareList';
import TargetListMenu from 'components/features/TargetListMenu';
import EditableText from 'components/templates/EditableText';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { useListsContext } from 'contexts/ListsContext';
import { ListAction } from 'contexts/utilities/listsUtils';
import { useTextCutOff } from 'hooks/useTextCutOff';

import MigrateButton from './MigrateButton';
import { TriggersStateButton } from './TriggersStateButton/TriggersStateButton';
import { useGetNavigateToTriggers, useTriggersStateButtonWithData } from './TriggersStateButton/utils';

type ResultsViewTopSectionProps = {
    errorMessage: string;
    list: List;
    onTargetNameChange: (name: string) => void;
    onListMenuAction: (listId: string, action: ListAction, fields?: Partial<List>) => void;
};

export const ResultsViewTopSection: React.FC<ResultsViewTopSectionProps> = ({
    list,
    errorMessage,
    onTargetNameChange,
    onListMenuAction,
}) => {
    const { revertLegacyList, selectedList } = useListsContext();
    const [isSharedDialogOpen, setIsSharedDialogOpen] = useState(false);
    const [isMigrating, setIsMigrating] = useState(false);
    const nameRef = useRef<HTMLElement | null>(null);
    const [isTextCutOff, onRefresh] = useTextCutOff(nameRef);
    const isOwner = list.privileges.current === 'owner';
    const hasEditPermission = list.privileges.current === 'edit';
    const isLegacy = list.type === 'legacy';
    const isViewOnly = isLegacy || (!isOwner && !hasEditPermission);

    const triggerStateButtonProps = useTriggersStateButtonWithData({ listId: list.id });
    const navigateToTriggers = useGetNavigateToTriggers({ listId: list.id, listName: list.name });

    return (
        <>
            <StyledBox
                sx={{
                    width: '100%',
                    height: errorMessage ? 72 : 40,
                    marginBottom: 2,
                    justifyContent: 'space-between',
                }}
            >
                <StyledBox>
                    {isViewOnly ? (
                        <>
                            <Tooltip
                                open={isTextCutOff}
                                onOpen={() => onRefresh(true)}
                                onClose={() => onRefresh(false)}
                                title={list.name}
                                arrow={false}
                            >
                                <Typography
                                    ref={nameRef}
                                    variant="h3"
                                    color="dark"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        marginRight: 1,
                                        maxWidth: { xs: 250, lg: 310, xl: 410, profileSidebar: 650 },
                                    }}
                                >
                                    {list.name}
                                </Typography>
                            </Tooltip>
                            <Tooltip title="View only" placement="top">
                                <IconContainer>
                                    <Icon type="Lock" sx={{ color: 'text.subtle', fontSize: 18 }} />
                                </IconContainer>
                            </Tooltip>
                        </>
                    ) : (
                        <EditableText
                            text={list.name}
                            submit={onTargetNameChange}
                            endAdornment={
                                <Tooltip title="Edit name" placement="top">
                                    <Button
                                        variant="flat"
                                        size="small"
                                        startIcon={<Icon type="Edit" fontSize="mini" sx={{ color: 'text.strong' }} />}
                                    />
                                </Tooltip>
                            }
                            fontSize="32px"
                            fontWeight={900}
                            errorText={errorMessage}
                        />
                    )}
                    {/* Only show share icon once the list has been shared */}
                    {isOwner && list.privileges.shared_count !== 0 && (
                        <Tooltip title="Share settings" placement="top">
                            <Button
                                variant="flat"
                                size="small"
                                startIcon={<Icon type="ShareOutline" fontSize="mini" sx={{ color: 'text.strong' }} />}
                                onClick={() => setIsSharedDialogOpen(true)}
                            />
                        </Tooltip>
                    )}
                    {!isOwner && (
                        <Tooltip title={`Owner: ${list.owner}`} placement="top">
                            <IconContainer>
                                <Icon type="Group" sx={{ color: 'text.subtle', fontSize: 18 }} />
                            </IconContainer>
                        </Tooltip>
                    )}
                </StyledBox>
                <Box sx={{ display: 'grid', grid: 'auto / auto-flow max-content', gap: 1 }}>
                    <TriggersStateButton {...triggerStateButtonProps} onClick={navigateToTriggers} />
                    <TargetListMenu
                        anchorElement={
                            <Button
                                data-testid="list-actions-menu"
                                variant="tertiary"
                                size="small"
                                startIcon={<Icon type="MoreHorizontal" />}
                            />
                        }
                        popperPlacement="bottom-end"
                        list={list}
                        isOwner={isOwner}
                        shareList={isOwner || hasEditPermission}
                        hideMigrate
                        onClick={(action, fields) => onListMenuAction(list.id, action, fields)}
                    />
                    {isLegacy && (
                        <MigrateButton
                            ButtonProps={{
                                startIcon: <Icon type="ArrowShortRight" />,
                            }}
                        />
                    )}
                    {!isLegacy && selectedList?.migrated_from_legacy_list && (
                        <Tooltip title={!isOwner ? 'Only list owners can revert lists' : ''}>
                            <div>
                                <Button
                                    startIcon={<Icon type="ArrowShortLeft" />}
                                    variant="tertiary"
                                    size="small"
                                    disabled={!isOwner || isMigrating}
                                    onClick={async () => {
                                        if (selectedList) {
                                            setIsMigrating(true);

                                            try {
                                                await revertLegacyList(selectedList);
                                            } catch (e) {}
                                            setIsMigrating(false);
                                        }
                                    }}
                                >
                                    Revert to legacy list
                                </Button>
                            </div>
                        </Tooltip>
                    )}
                </Box>
            </StyledBox>
            {isSharedDialogOpen && (
                <ShareList
                    open={isSharedDialogOpen}
                    title={`Share ${list.name}`}
                    list={list}
                    onClose={() => setIsSharedDialogOpen(false)}
                    onSubmit={(fields) => onListMenuAction(list.id, 'share', fields)}
                />
            )}
        </>
    );
};

const StyledBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const IconContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    padding: '6px 8px',
});
