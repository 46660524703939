import React from 'react';

import { styled } from '@mui/material';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';

type MappingControlsProps = {
    isMandatory: boolean;
    onEdit?: () => void;
    onRemove: () => void;
};

const MappingsTableControls: React.FC<MappingControlsProps> = ({ isMandatory, onEdit, onRemove }) => {
    return (
        <div>
            <Button
                disabled={!onEdit}
                data-testid="mapping-edit-button"
                sx={{ marginRight: '8px', color: 'text.secondary' }}
                variant="flat"
                size="medium"
                startIcon={<StyledIcon type="Settings" />}
                onClick={onEdit}
            />
            <Button
                disabled={isMandatory}
                data-testid={isMandatory ? 'lock-button' : 'mapping-remove-button'}
                variant="flat"
                size="medium"
                startIcon={
                    <StyledIcon
                        type={isMandatory ? 'Lock' : 'TrashFull'}
                        color={isMandatory ? undefined : 'text.secondary'}
                    />
                }
                onClick={onRemove}
            />
        </div>
    );
};

const StyledIcon = styled(Icon)({
    width: '15px',
    height: '15px',
});

export { MappingsTableControls };
