import { SelectOption } from 'components/tokens/select-components/Select';

export const locationTypeGlobalOptions: SelectOption[] = [
    { label: 'Headquarters', value: 'hq' },
    { label: 'Branches', value: 'other' },
];
export const locationTypeCountryOptions: SelectOption[] = [
    { label: 'Headquarters', value: 'registry_main_location' },
    { label: 'Branch (official)', value: 'registry_other_location' },
    { label: 'Branch (web)', value: 'other' },
];
