import React from 'react';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { styled, SxProps } from '@mui/material/styles';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import Popover from 'material-ui-popup-state/HoverPopover';

import { useProfileData } from 'api/profileData';
import { getHQ } from 'components/features/Locations/utils';
import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import { getFullLocation } from 'components/templates/CompanyCard/utils';
import Typography from 'components/tokens/Typography';
import { CompanyPreview, LeadPreview } from 'contexts/TriggerContext';

import { CellProps } from './TableComponent';
import { TooltipRow } from './TriggerCellTooltip';

const CompanyCell: React.FC<CellProps> = ({ selectCompany, row, exportTable }) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'company-popover' });
    const { business_id, domain } = row?.company || ({} as CompanyPreview);
    const { companies } = row;
    const isDomainData = !business_id;
    const restOfCompanies = companies?.filter((i) => i.business_id !== business_id) || [];

    const { data: companyData, isLoading } = useProfileData(
        { type: isDomainData ? 'domain' : 'organization', key: business_id || domain },
        ['industries_verbose', 'business_units', 'website'],
        { enabled: popupState.isOpen },
    );
    const companyName = row.company?.company_name || '';
    const companyLogo = row.company?.logo;

    const website = domain || companyData?.website;
    const addressStr = getFullLocation(getHQ(companyData?.business_units || []));
    const industry = companyData?.industries_verbose?.[0].description;

    return (
        <StyledColumn>
            {(business_id || domain) && (
                <>
                    <StyledCompanyContainer>
                        <CompanyLogoAndName
                            name={companyName}
                            logo={companyLogo}
                            link={isDomainData ? `/domain/${domain}/` : `/company/${business_id}/`}
                            {...bindHover(popupState)}
                        />
                        {exportTable ? null : (
                            <MoreCompaniesPopover
                                companies={restOfCompanies}
                                lead={row}
                                selectCompany={selectCompany}
                            />
                        )}
                    </StyledCompanyContainer>
                    <Popover
                        {...bindPopover(popupState)}
                        sx={{
                            borderRadius: 8,
                            padding: '16px',
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <StyledPopoverWrapper>
                            <Typography weight="semibold" sx={{ maxWidth: '80%', marginBottom: 1 }}>
                                {companyName}
                            </Typography>

                            {companyLogo ? (
                                <StyledLogoAbsolute src={formatLogoLink(companyLogo)} alt="" />
                            ) : (
                                <StyledTextLogoAbsolute>
                                    <CompanyLogo variant="round" company_name={companyName} logo={companyLogo} />
                                </StyledTextLogoAbsolute>
                            )}

                            {business_id && <TooltipRow icon="CompanyId">{business_id}</TooltipRow>}
                            <TooltipRow icon="HomeAltOutline" isLoading={!website && isLoading}>
                                {website && (
                                    <Link
                                        href={`https://${website.replace('https://', '').replace('http://', '')}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        underline="always"
                                    >
                                        {website}
                                    </Link>
                                )}
                            </TooltipRow>

                            <TooltipRow icon="LocationOutline" isLoading={isLoading}>
                                {addressStr}
                            </TooltipRow>

                            {business_id && (
                                <TooltipRow icon="Label" isLoading={isLoading}>
                                    {industry}
                                </TooltipRow>
                            )}
                        </StyledPopoverWrapper>
                    </Popover>
                </>
            )}
        </StyledColumn>
    );
};

export default CompanyCell;

interface MoreCompaniesProps {
    companies: CompanyPreview[];
    closeParent?: () => void;
    selectCompany?: (id: string, obj: CompanyPreview) => void;
    lead: LeadPreview;
    sx?: SxProps;
}

export const MoreCompaniesPopover: React.FC<MoreCompaniesProps> = ({
    companies,
    lead,
    sx,
    selectCompany = () => ({}),
}) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'more-companies-popover' });
    if (!companies?.length) {
        return null;
    }

    return (
        <>
            <StyledMore sx={sx} {...bindHover(popupState)}>
                +&nbsp;{companies.length}
            </StyledMore>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper sx={{ padding: '12px' }}>
                    <Grid container direction="column" gap={1}>
                        {companies.map((comp) => {
                            const { logo, company_name, id } = comp;
                            return (
                                <StyledCompanyContainer key={id}>
                                    <CompanyLogoAndName
                                        name={company_name || ''}
                                        logo={logo}
                                        link="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            selectCompany(lead.id, comp);
                                            popupState.close();
                                        }}
                                    />
                                </StyledCompanyContainer>
                            );
                        })}
                    </Grid>
                </Paper>
            </Popover>
        </>
    );
};

const StyledColumn = styled('div')`
    max-width: 300px;
`;

const StyledCompanyContainer = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const StyledPopoverWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    min-width: 250px;
    min-height: 140px;
    padding: 16px;
`;

const StyledLogoAbsolute = styled('img')`
    width: 36px;
    height: 36px;
    border-radius: 30px;
    position: absolute;
    top: 20px;
    right: 16px;
`;

const StyledTextLogoAbsolute = styled('div')`
    width: 36px;
    height: 36px;
    border-radius: 30px;
    position: absolute;
    top: 20px;
    right: 16px;
`;

const StyledMore = styled('div')`
    font-size: 14px;
    padding-left: 5px;
    text-decoration: underline;
`;

const formatLogoLink = (str: string) => {
    const src = 'https://images.vainu.io/';
    if (!str) {
        return '';
    }
    if (str.startsWith('a:')) {
        return `${src}${str.slice(2)}`;
    }
    if (str.includes('https://images.vainu.io/')) {
        return str;
    }
    return `${src}${str}`;
};

const CompanyLogoAndName: React.FC<{
    name: string;
    logo?: string;
    link?: string;
    onClick?: (e: React.MouseEvent) => void;
}> = ({ name, logo, link, onClick, ...rest }) => {
    return (
        <StyledHref href={link} target="_blank" onClick={onClick} {...rest}>
            <CompanyLogo variant="round" company_name={name} logo={logo ? formatLogoLink(logo) : ''} border />
            <Typography variant="body2" sx={{ marginLeft: 1 }} color="dark">
                {name}
            </Typography>
        </StyledHref>
    );
};

const StyledHref = styled('a')`
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;
