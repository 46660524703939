import React from 'react';

import { Skeleton, styled } from '@mui/material';

import Typography from 'components/tokens/Typography';

import ObjectSvgIcon from '../common/ObjectIcon';
import { CRMObject, CrmName } from '../types/Crm';
import { getObjectPluralForm } from '../utils/CRMTargetText';

type CRMObjectsMenuProps = {
    isLoading: boolean;
    crmName: CrmName;
    activeTab: CRMObject | '';
    tabs: CRMObject[];
    onSelect: (newTab: CRMObject) => void;
};

const CRMObjectsMenu: React.FC<CRMObjectsMenuProps> = ({ isLoading, activeTab, crmName, tabs, onSelect }) => {
    return (
        <MenuWrapper role={isLoading ? 'progressbar' : 'menu'}>
            <Typography weight="bold" marginBottom="16px">
                Objects
            </Typography>
            {isLoading ? (
                <CRMObjectsMenuSkeleton />
            ) : (
                <ul>
                    {tabs.map((tab) => (
                        <MenuItem key={tab} active={activeTab === tab} onClick={() => onSelect(tab)}>
                            <ObjectSvgIcon crm={crmName} object={tab} />
                            {/* Dynamics CRM objects always in lowercase */}
                            <Typography variant="small" weight="semibold" sx={{ textTransform: 'capitalize' }}>
                                {getObjectPluralForm(crmName, tab)}
                            </Typography>
                        </MenuItem>
                    ))}
                </ul>
            )}
        </MenuWrapper>
    );
};

const CRMObjectsMenuSkeleton: React.FC = () => {
    return (
        <ul>
            {Array.from({ length: 5 }).map((_, index) => (
                <MenuItemBase key={index}>
                    <StyledSkeleton icon animation="wave" variant="rectangular" />
                    <StyledSkeleton animation="wave" variant="rectangular" />
                </MenuItemBase>
            ))}
        </ul>
    );
};

const MenuWrapper = styled('div')({
    position: 'sticky',
    top: '20px',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 3,
    width: '240px',
    height: '100%',
    marginRight: '10px',
});

const MenuItemBase = styled('li')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
    height: '40px',
    padding: '0 4px 0 12px',
});

// todo: a similar to <ListItem> component is used for lists, need to use 1 component in both places
const MenuItem = styled(MenuItemBase, {
    shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active, theme: { palette } }) => ({
    background: active ? palette.menu.selected : 'transparent',
    borderRadius: '4px',
    cursor: 'pointer',

    span: {
        marginLeft: '8px',
        lineHeight: '40px',
        color: active ? palette.secondary.main : palette.text.primary,
    },

    '& > svg path': {
        fill: active ? palette.brandColors.tertiaryBlue : palette.text.subtle,
    },

    '&:hover': {
        background: active ? palette.menu.selected : palette.brandColors.cloud,
    },
}));

const StyledSkeleton = styled(Skeleton, {
    shouldForwardProp: (prop) => prop !== 'icon',
})<{ icon?: boolean }>(({ icon }) => ({
    width: `${icon ? 20 : 112}px`,
    borderRadius: '8px',
    ...(!icon && { marginLeft: '8px' }),
}));

export { CRMObjectsMenu };
