import { useMemo } from 'react';

import { isNotEmpty, range } from 'utilities';

import { BasicData, BasicDataOrganization, useFinancialStatements } from 'api/profileData';
import { CompanyFinancialStatements } from 'api/types/company';
import { DomainData, domainProfileType, Organization, organizationProfileType } from 'api/types/Organization';
import { getOrganizationSize } from 'components/features/Employees/utils';
import { FinancialDataPoint } from 'components/features/Financials/Charts/types';
import { getNewestValueWithChange } from 'components/features/Financials/Charts/utilities';
import { useValidStatements } from 'components/features/Financials/utils';
import { formatCity, getCountriesFromBusinessUnits, getHQ } from 'components/features/Locations/utils';
import { getShortTrafficRanking } from 'components/features/WebProfile/Header/utils';
import { getCountryNameFromCode } from 'components/templates/CompanyCard/utils';
import { NumberDataPointProps } from 'components/tokens/NumberDataPoint';

export function getCompanyNumberDataPoints({ concern }: CompanyFinancialStatements): NumberDataPointProps[] {
    if (!concern) {
        return [];
    }
    const points: NumberDataPointProps[] = [];
    if (concern.turn_over_local) {
        points.push({
            label: 'Group revenue',
            value: concern.turn_over_local,
            style: 'currency',
            currency: concern.currency_code,
            abbreviate: true,
        });
    }
    if (concern.staff_number) {
        points.push({
            label: 'Group employees',
            value: concern.staff_number,
            style: 'integer',
            abbreviate: true,
        });
    }
    if (concern.subsidiaries) {
        points.push({
            label: 'Subsidiaries',
            value: concern.subsidiaries,
            style: 'integer',
        });
    }

    return points;
}

export function getDomainNumberDataPoints(data: BasicData): (NumberDataPointProps & { label: string })[] {
    const points: (NumberDataPointProps & { label: string })[] = [];
    const addressCountries = getCountriesFromBusinessUnits(data.business_units);
    if (isNotEmpty(addressCountries)) {
        points.push({
            label: 'Countries',
            value: addressCountries.length,
            style: 'integer',
        });
    }
    const organizationSize = getOrganizationSize(data);
    if (organizationSize) {
        if (Array.isArray(organizationSize)) {
            points.push({
                label: 'Employees',
                value: organizationSize,
                style: 'range',
                abbreviate: true,
            });
        } else {
            points.push({
                label: 'Employees',
                value: organizationSize,
                style: 'integer',
                abbreviate: true,
            });
        }
    }
    if (data.business_units) {
        const hqCountryCode = getHQ(data.business_units)?.visiting_address?.country ?? '';
        const hqCountryName = getCountryNameFromCode(hqCountryCode);
        const label = 'Headquarters';
        const style = 'string';
        if (hqCountryName.length > 8) {
            points.push({
                value: hqCountryCode,
                tooltip: hqCountryName,
                label,
                style,
            });
        } else {
            points.push({
                value: hqCountryName,
                label,
                style,
            });
        }
    }
    if (data.website_data?.website_traffic_data?.ranking) {
        points.push({
            label: 'Traffic ranking',
            value: getShortTrafficRanking(data.website_data?.website_traffic_data?.ranking),
            style: 'string',
        });
    }
    return points;
}

export const useDomainNumberDataPoints = (data: BasicData): (NumberDataPointProps & { label: string })[] =>
    useMemo(() => getDomainNumberDataPoints(data), [data]);

export function getOrganizationNumberDataPoints(
    data: BasicDataOrganization,
    employeeCountOfficial: FinancialDataPoint | undefined,
    revenue: FinancialDataPoint | undefined,
): (NumberDataPointProps & { label: string })[] {
    const points: (NumberDataPointProps & { label: string })[] = [];

    const groupEmployees = data.group_data?.financial_data.employees.absolute_count;
    if (groupEmployees) {
        points.push({
            label: 'Group employees',
            value: groupEmployees,
            style: 'integer',
            abbreviate: true,
        });
    } else if (employeeCountOfficial) {
        points.push({
            label: 'Employees',
            value: employeeCountOfficial.value,
            style: 'integer',
            abbreviate: true,
        });
    } else {
        const organizationSize = getOrganizationSize(data);
        if (organizationSize) {
            if (Array.isArray(organizationSize)) {
                points.push({
                    label: 'Employees',
                    value: organizationSize,
                    style: 'range',
                    abbreviate: true,
                });
            } else {
                points.push({
                    label: 'Employees',
                    value: organizationSize,
                    style: 'integer',
                    abbreviate: true,
                });
            }
        }
    }

    const groupRevenue = data.group_data?.financial_data.revenue;

    if (groupRevenue) {
        points.push({
            label: 'Group revenue',
            value: groupRevenue.value ?? 0,
            style: 'currency',
            currency: groupRevenue.currency_code ?? '',
            abbreviate: true,
        });
    } else if (revenue) {
        points.push({
            label: 'Revenue',
            value: revenue.value,
            style: 'currency',
            currency: revenue.currency,
            abbreviate: true,
        });
    }

    const subsidiaries = data.group_data?.subsidiary_count;
    if (subsidiaries) {
        points.push({
            label: 'Subsidiaries',
            value: subsidiaries,
            style: 'integer',
        });
    }

    if (data.business_units) {
        const city = formatCity(getHQ(data.business_units)?.visiting_address?.city ?? '');
        const label = 'Headquarters';
        const style = 'string';

        points.push({
            value: city,
            label,
            style,
        });
    }
    return points;
}

export const useOrganizationNumberDataPoints = (
    data: BasicDataOrganization,
): (NumberDataPointProps & { label: string })[] => {
    const { data: statementData } = useFinancialStatements({
        type: organizationProfileType,
        key: data.id,
    });
    const validStatements = useValidStatements(statementData?.financial_statements);
    const employeeCountOfficial = useMemo(() => {
        return getNewestValueWithChange(validStatements, 'employees.absolute_count');
    }, [validStatements]);
    const revenue = useMemo(() => {
        return getNewestValueWithChange(validStatements, 'income_statement.revenue');
    }, [validStatements]);
    return useMemo(
        () => getOrganizationNumberDataPoints(data, employeeCountOfficial, revenue),
        [data, employeeCountOfficial, revenue],
    );
};

export const useSkeletonNumberDataPoints = (): NumberDataPointProps[] =>
    useMemo(() => range(4).map((index) => ({ label: index, skeleton: true })), []);

const imageStorage = process.env.REACT_APP_EXTERNAL_STORAGE_BASE_URL;
export const getImage = (file: string) => {
    if (file.startsWith('http')) {
        return file;
    }
    return `${imageStorage}/${file}`;
};

export const getBannerFallback = (vid: number) =>
    `https://static2.vainu.io/img/aerials/aerial-${Math.floor(vid) % 10}.jpg`;

export const useOrganizationStub = (businessId: string): Organization =>
    useMemo(
        () => ({
            id: businessId,
            profileType: organizationProfileType,
            business_id: businessId,
            level_in_group: null,
            group_data: null,
            official_status: null,
        }),
        [businessId],
    );

export const useDomainDataStub = (website: string): DomainData =>
    useMemo(
        () => ({
            id: website,
            profileType: domainProfileType,
            website,
        }),
        [website],
    );
