import { FilterDefinition } from '../FilterTypes';
import { companyNameFilterDefinition } from './basic/CompanyName';
import { companyRegistrationFilterDefinition } from './basic/CompanyRegistration';
import { employerRegistrationFilterDefinition } from './basic/EmployerRegistration';
import { foundationFilterDefinition } from './basic/Foundation';
import { industryOfficialFilterDefinition } from './basic/IndustryOfficial';
import { legalEntityFilterDefinition } from './basic/LegalEntity';
import { boardMemberCountFilterDefinition } from './board-of-directors/BoardMemberCount';
import { boardUniqueSurnameCountFilterDefinition } from './board-of-directors/BoardUniqueSurnameCount';
import { staffNumberWebFilterDefinition } from './company-size-indicators/StaffNumberWeb';
import { contactFunctionFilterDefinition } from './contacts/ContactFunction';
import { contactInfoFilterDefinition } from './contacts/ContactInfo';
import { contactIsDecisionMakerFilterDefinition } from './contacts/ContactIsDecisionMaker';
import { contactTitleKeywordsFilterDefinition } from './contacts/ContactTitleKeywords';
import { crmOwnerEmailFilterDefinition } from './crm/CRMOwnerEmail';
import { crmSystemFilterDefinition } from './crm/CRMSystem';
import { lostDealCountFilterDefinition } from './crm/LostDealCount';
import { openDealCountFilterDefinition } from './crm/OpenDealCount';
import { wonDealCountFilterDefinition } from './crm/WonDealCount';
import { accountingPeriodEndDateFilterDefinition } from './financial/AccountingPeriodEndDate';
import { accountsReceivableLocalFilterDefinition } from './financial/AccountsReceivableLocal';
import { auditorFilterDefinition } from './financial/Auditor';
import { bookkeeperFilterDefinition } from './financial/Bookkeeper';
import { cashAndCashEquivalentsLocalFilterDefinition } from './financial/CashAndCashEquivalentsLocal';
import { changeInRevenueFilterDefinition } from './financial/ChangeInRevenue';
import { ebitMarginFilterDefinition } from './financial/EbitMargin';
import { staffCountOfficialFilterDefinition } from './financial/EmployeeCount';
import { equityRatioFilterDefinition } from './financial/EquityRatio';
import { financialStatementKeywordsFilterDefinition } from './financial/FinancialStatementKeywords';
import { grossProfitFilterDefinition } from './financial/GrossProfit';
import { inventoryLocalFilterDefinition } from './financial/InventoryLocal';
import { longTermLiabilitiesLocalFilterDefinition } from './financial/LongTermLiabilitiesLocal';
import { netIncomeFilterDefinition } from './financial/NetIncome';
import { quickRatioFilterDefinition } from './financial/QuickRatio';
import { revenueFilterDefinition } from './financial/Revenue';
import { revenueLocalFilterDefinition } from './financial/RevenueLocal';
import { salaryCostsFilterDefinition } from './financial/SalaryCosts';
import { totalEquityLocalFilterDefinition } from './financial/TotalEquityLocal';
import { groupCompanyCountFilterDefinition } from './group-structure/GroupCompanyCount';
import { groupCountriesFilterDefinition } from './group-structure/GroupCountries';
import { groupCountryCountFilterDefinition } from './group-structure/GroupCountryCount';
import { groupIsSubsidiaryFilterDefinition } from './group-structure/GroupIsSubsidiary';
import { vainuCustomIndustryFilterDefinition } from './Industry/VainuCustomIndustry';
import { includeExcludeListFilterDefinition } from './lists/IncludeExcludeList';
import { countryFilterDefinition } from './location/Country';
import { countryAreaFilterDefinition } from './location/CountryArea';
import { countryCountFilterDefinition } from './location/CountryCount';
import { locationTypeFilterDefinition } from './location/LocationType';
import { coordinatesFilterDefinition } from './location/MapFilter';
import { municipalityFilterDefinition } from './location/Municipality';
import { numberOfLocationsFilterDefinition } from './location/NumberOfLocations';
import { postalCodeFilterDefinition } from './location/PostalCode';
import { streetAddressFilterDefinition } from './location/StreetAdress';
import { visitingCityFilterDefinition } from './location/VisitingCity';
import { socialMediaChannelsFilterDefinition } from './social-media/SocialMediaChannels';
import { socialMediaIndexFilterDefinition } from './social-media/SocialMediaIndex';
import { twitterFollowersFilterDefinition } from './social-media/TwitterFollowers';
import { descriptionKeywordsFilterDefinition } from './web-profile/DescriptionKeywords';
import { digitalizationIndexFilterDefinition } from './web-profile/DigitalizationIndex';
import { recruitmentKeywordsFilterDefinition } from './web-profile/RecruitmentKeywords';
import { technologyAdvertisingFilterDefinition } from './web-profile/technologies/TechnologyAdvertising';
import { technologyAllFilterDefinition } from './web-profile/technologies/TechnologyAll';
import { technologyAnalyticsFilterDefinition } from './web-profile/technologies/TechnologyAnalytics';
import { technologyATSFilterDefinition } from './web-profile/technologies/TechnologyATS';
import { technologyCMSFilterDefinition } from './web-profile/technologies/TechnologyCMS';
import { technologyCRMFilterDefinition } from './web-profile/technologies/TechnologyCRM';
import { technologyCustomerServiceFilterDefinition } from './web-profile/technologies/TechnologyCustomerService';
import { technologyDomainServerFilterDefinition } from './web-profile/technologies/TechnologyDomainServer';
import { technologyECommerceFilterDefinition } from './web-profile/technologies/TechnologyECommerce';
import { technologyEmailServerFilterDefinition } from './web-profile/technologies/TechnologyEmailServer';
import { technologyMarketingFilterDefinition } from './web-profile/technologies/TechnologyMarketing';
import { technologyPaymentFilterDefinition } from './web-profile/technologies/TechnologyPayment';
import { technologyReservationFilterDefinition } from './web-profile/technologies/TechnologyReservation';
import { technologyServerFilterDefinition } from './web-profile/technologies/TechnologyServer';
import { technologySocialMediaFilterDefinition } from './web-profile/technologies/TechnologySocialMedia';
import { technologyWebTechFilterDefinition } from './web-profile/technologies/TechnologyWebTech';
import { websiteTrafficFilterDefinition } from './web-profile/website-traffic-rank/WebsiteTraffic';
import { websiteKeywordsFilterDefinition } from './web-profile/WebsiteKeywords';
import { websiteLanguageFilterDefinition } from './web-profile/WebsiteLanguage';
import { websiteUrlFilterDefinition } from './web-profile/WebsiteUrl';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterDefinitions: FilterDefinition<any> = {
    ...staffNumberWebFilterDefinition,
    ...vainuCustomIndustryFilterDefinition,
    ...visitingCityFilterDefinition,
    ...locationTypeFilterDefinition,
    ...countryFilterDefinition,
    ...countryCountFilterDefinition,
    ...numberOfLocationsFilterDefinition,
    ...streetAddressFilterDefinition,
    ...postalCodeFilterDefinition,
    ...countryAreaFilterDefinition,
    ...municipalityFilterDefinition,
    ...coordinatesFilterDefinition,
    ...technologyAllFilterDefinition,
    ...technologyAdvertisingFilterDefinition,
    ...technologyAnalyticsFilterDefinition,
    ...technologyATSFilterDefinition,
    ...technologyCMSFilterDefinition,
    ...technologyCRMFilterDefinition,
    ...technologyCustomerServiceFilterDefinition,
    ...technologyDomainServerFilterDefinition,
    ...technologyECommerceFilterDefinition,
    ...technologyEmailServerFilterDefinition,
    ...technologyMarketingFilterDefinition,
    ...technologyPaymentFilterDefinition,
    ...technologyReservationFilterDefinition,
    ...technologyServerFilterDefinition,
    ...technologySocialMediaFilterDefinition,
    ...technologyWebTechFilterDefinition,
    ...websiteTrafficFilterDefinition,
    ...websiteKeywordsFilterDefinition,
    ...websiteLanguageFilterDefinition,
    ...websiteUrlFilterDefinition,
    ...descriptionKeywordsFilterDefinition,
    ...includeExcludeListFilterDefinition,
    ...companyNameFilterDefinition,
    ...companyRegistrationFilterDefinition,
    ...employerRegistrationFilterDefinition,
    ...foundationFilterDefinition,
    ...legalEntityFilterDefinition,
    ...groupCompanyCountFilterDefinition,
    ...groupCountryCountFilterDefinition,
    ...groupCountriesFilterDefinition,
    ...groupIsSubsidiaryFilterDefinition,
    ...digitalizationIndexFilterDefinition,
    ...industryOfficialFilterDefinition,
    ...socialMediaChannelsFilterDefinition,
    ...socialMediaIndexFilterDefinition,
    ...twitterFollowersFilterDefinition,
    ...boardMemberCountFilterDefinition,
    ...boardUniqueSurnameCountFilterDefinition,
    ...crmSystemFilterDefinition,
    ...crmOwnerEmailFilterDefinition,
    ...lostDealCountFilterDefinition,
    ...openDealCountFilterDefinition,
    ...wonDealCountFilterDefinition,
    ...staffCountOfficialFilterDefinition,
    ...changeInRevenueFilterDefinition,
    ...revenueLocalFilterDefinition,
    ...revenueFilterDefinition,
    ...grossProfitFilterDefinition,
    ...netIncomeFilterDefinition,
    ...salaryCostsFilterDefinition,
    ...auditorFilterDefinition,
    ...accountingPeriodEndDateFilterDefinition,
    ...ebitMarginFilterDefinition,
    ...equityRatioFilterDefinition,
    ...quickRatioFilterDefinition,
    ...inventoryLocalFilterDefinition,
    ...bookkeeperFilterDefinition,
    ...longTermLiabilitiesLocalFilterDefinition,
    ...totalEquityLocalFilterDefinition,
    ...cashAndCashEquivalentsLocalFilterDefinition,
    ...accountsReceivableLocalFilterDefinition,
    ...financialStatementKeywordsFilterDefinition,
    ...recruitmentKeywordsFilterDefinition,
    ...contactFunctionFilterDefinition,
    ...contactIsDecisionMakerFilterDefinition,
    ...contactInfoFilterDefinition,
    ...contactTitleKeywordsFilterDefinition,
};
