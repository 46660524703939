import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SalesforceIcon: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon width="42" height="42" viewBox="0 0 42 42" style={{ fill: '#0d9dda' }} {...props}>
        <path
            fill="#009CDC"
            d="M17.946 10.79c1.162-1.222 2.778-1.993 4.565-1.993 2.374 0 4.445 1.35 5.544 3.355a7.575 7.575 0 013.144-.681c4.282 0 7.748 3.573 7.748 7.97 0 4.415-3.473 7.988-7.748 7.988a7.465 7.465 0 01-1.528-.154c-.972 1.774-2.822 2.97-4.944 2.97-.884 0-1.73-.213-2.481-.58-.985 2.36-3.284 4.011-5.948 4.011-2.778 0-5.159-1.8-6.062-4.306a6.63 6.63 0 01-1.237.129C5.684 29.499 3 26.722 3 23.303c0-2.288 1.2-4.287 2.993-5.36a7.27 7.27 0 01-.568-2.828C5.425 11.18 8.55 8 12.402 8a6.918 6.918 0 015.556 2.802"
        />
    </SvgIcon>
);

export default SalesforceIcon;
