import { Box } from '@mui/material';

import { Permission, usePermissionContext } from 'contexts/PermissionContext';

import JwtAuthentication from './JwtAuthentication/JwtAuthentication';
import { LegacyKeys } from './LegacyKeys/LegacyKeys';

const APIKeys = () => {
    const { hasProductPermission } = usePermissionContext();
    const hasLegacyAPIKeyPermission = hasProductPermission(Permission.LegacyAPIKeyManagementView);
    const hasCanUseApiPermission = hasProductPermission(Permission.CanUseApi);

    return (
        <Box sx={{ display: 'grid', gap: 8 }}>
            {hasCanUseApiPermission && <JwtAuthentication />}
            {hasLegacyAPIKeyPermission && <LegacyKeys />}
        </Box>
    );
};

export default APIKeys;
