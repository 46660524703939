import { forwardRef, memo } from 'react';

import { Box, Skeleton, Tooltip } from '@mui/material';

import Frame from 'components/tokens/Frame';
import Icon, { IconType } from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

export type DescriptionsProps = {
    descriptions: Description[];
    skeleton?: boolean;
};

export type Description = {
    description: string;
    icon: IconType;
    iconTooltip?: string;
};

const IconBox: React.FC<{ icon: IconType }> = forwardRef(({ icon, ...props }, ref) => (
    <Box
        ref={ref}
        sx={{
            width: 45,
            minWidth: 45,
            height: 45,
            borderRadius: 22.5,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'border',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 1,
            backgroundColor: 'common.white',
        }}
        {...props}
    >
        <Icon sx={{ fontSize: 18 }} type={icon} />
    </Box>
));

export const Descriptions: React.VFC<DescriptionsProps> = memo(({ descriptions, skeleton = false }) => {
    return (
        <Frame title="Descriptions" padding="medium">
            <Box
                sx={{
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: {
                        xs: '1fr',
                        sm: 'repeat(auto-fill, minmax(320px, 1fr))',
                    },
                }}
            >
                {/* index used as a key as the list only changes with total rerenders */}
                {skeleton ? (
                    <>
                        <Skeleton variant="rounded" height={96} />
                        <Skeleton variant="rounded" height={96} />
                    </>
                ) : (
                    descriptions.map(({ description, icon, iconTooltip }, index) => {
                        return (
                            <Frame
                                key={index}
                                elevation={0}
                                variant="grey"
                                border
                                sx={{ display: 'flex', height: '100%', padding: [2, 1.5] }}
                            >
                                {iconTooltip ? (
                                    <Tooltip title={iconTooltip}>
                                        <IconBox icon={icon} />
                                    </Tooltip>
                                ) : (
                                    <IconBox icon={icon} />
                                )}
                                <Typography
                                    variant="tiny"
                                    sx={{
                                        overflowY: 'auto',
                                        maxHeight: 180,
                                        paddingRight: 1,
                                        scrollbarWidth: 'thin',
                                        '&::-webkit-scrollbar': {
                                            width: 8,
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: 'grey.400',
                                            borderRadius: '4px',
                                        },
                                    }}
                                >
                                    {description}
                                </Typography>
                            </Frame>
                        );
                    })
                )}
            </Box>
        </Frame>
    );
});

export default Descriptions;
