import { memo, useMemo, useState } from 'react';

import { Box, Skeleton } from '@mui/material';
import { pluralize } from 'utilities';

import { Contact } from 'api/types/Organization';
import { CRMExport, CrmMenu, useCRMExport } from 'components/modules/connectors/crm-export';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { TabChangeHandler } from 'components/modules/profiles/Profile';
import Button from 'components/tokens/Button';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { NordicDatabase } from 'contexts/ListsContext';
import useSimpleSelection from 'hooks/useSimpleSelection';

import { filterContactsBySearch, splitEmployeesByClassification } from '../utils';
import EmployeesTable from './EmployeesTable';
import { SearchField } from './SearchField';

export interface EmployeesTableSectionProps {
    skeleton?: boolean;
    directMarketingDenied?: boolean;
    hasContactDataPermission: boolean;
    businessId: string;
    country?: string;
    companyName?: string;
    allEmployees?: Contact[];
    onGoToClick?: TabChangeHandler;
    collapseBoardMembers?: boolean;
}

export const EmployeesTableSectionBody: React.FC<EmployeesTableSectionProps> = memo(
    ({
        businessId,
        companyName,
        allEmployees = [],
        country,
        hasContactDataPermission,
        skeleton = false,
        collapseBoardMembers,
    }) => {
        const [exportState, { handleOpenExportDialog, handleCloseExportDialog }] = useCRMExport();

        const [employeesSearchTerm, setEmployeesSearchTerm] = useState('');
        const [boardMembersSearchTerm, setBoardMembersSearchTerm] = useState('');

        const { employees, boardMembers } = useMemo(() => splitEmployeesByClassification(allEmployees), [allEmployees]);

        // Search is done after division so that we can determine the original row count as well as the row count after search filter.
        const employeesSearch = filterContactsBySearch(employees, employeesSearchTerm, hasContactDataPermission);
        const boardMembersSearch = filterContactsBySearch(
            boardMembers,
            boardMembersSearchTerm,
            hasContactDataPermission,
        );

        const {
            isAllSelected: isAllEmployeesSelected,
            selected: selectedEmployees,
            select: selectEmployee,
            unselect: unselectEmployee,
            toggleAllSelected: toggleAllEmployeesSelected,
        } = useSimpleSelection<Contact>(employeesSearch);

        const {
            isAllSelected: isAllBoardMembersSelected,
            selected: selectedBoardMembers,
            select: selectBoardMember,
            unselect: unselectBoardMember,
            toggleAllSelected: toggleAllBoardMembersSelected,
        } = useSimpleSelection<Contact>(boardMembersSearch);

        if (skeleton) {
            return <Skeleton variant="rounded" width="100%" height={300} />;
        }

        const handleContactExport = ({ crm, contacts }: { crm: CrmName; contacts: Contact[] }) => {
            handleOpenExportDialog({
                selectionType: 'selection',
                exportType: 'contact',
                crm,
                database: country as NordicDatabase,
                contacts: contacts.map((contact) => ({ ...contact, businessId })),
                companyIds: [businessId],
            });
        };

        return (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center', marginY: 2 }}>
                    <Checkbox
                        disabled={!hasContactDataPermission}
                        checked={isAllEmployeesSelected}
                        indeterminate={selectedEmployees.length > 0 && !isAllEmployeesSelected}
                        onChange={(e) => toggleAllEmployeesSelected(e.target.checked)}
                    />
                    <Typography variant="body2" weight="semibold" sx={{ flex: 1 }}>
                        {selectedEmployees.length ? (
                            `${selectedEmployees.length} ${pluralize(selectedEmployees.length, 'employee')} selected`
                        ) : (
                            <>
                                Employees{' '}
                                {employees.length > 0 && (
                                    <>
                                        (
                                        {employeesSearchTerm
                                            ? `${employeesSearch.length}/${employees.length}`
                                            : employees.length}
                                        )
                                    </>
                                )}
                            </>
                        )}
                    </Typography>

                    <SearchField value={employeesSearchTerm} onChange={setEmployeesSearchTerm} />
                    <CrmMenu
                        disabled={!hasContactDataPermission}
                        anchorButton={
                            <Button
                                startIcon={<Icon type="Export" />}
                                variant="tertiary"
                                size="small"
                                sx={{ marginLeft: 1 }}
                            >
                                Send to
                            </Button>
                        }
                        handleClick={(crm) => {
                            handleContactExport({
                                crm,
                                contacts: selectedEmployees.length > 0 ? selectedEmployees : employees,
                            });
                        }}
                    />
                </Box>
                <EmployeesTable
                    hasContactDataPermission={hasContactDataPermission}
                    companyName={companyName}
                    businessId={businessId}
                    country={country}
                    employees={employeesSearch}
                    selected={selectedEmployees}
                    onSelect={selectEmployee}
                    onUnselect={unselectEmployee}
                    onSingleContactExport={handleContactExport}
                    sx={{ display: 'flex', flexGrow: 1, position: 'relative' }}
                />

                {boardMembers.length > 0 &&
                    (collapseBoardMembers ? (
                        <Box
                            sx={{
                                border: '1px solid',
                                borderColor: 'border',
                                padding: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                borderRadius: 2,
                                marginTop: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'border',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 2,
                                    width: 40,
                                    height: 40,
                                }}
                            >
                                <Icon type="Judge" />
                            </Box>
                            <Typography variant="body2" weight="semibold" sx={{ flex: 1 }}>
                                Board members
                            </Typography>
                            <Typography variant="h4">{boardMembers.length}</Typography>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, marginTop: 4 }}>
                                <Checkbox
                                    disabled={!hasContactDataPermission}
                                    checked={isAllBoardMembersSelected}
                                    indeterminate={selectedBoardMembers.length > 0 && !isAllBoardMembersSelected}
                                    onChange={(e) => toggleAllBoardMembersSelected(e.target.checked)}
                                />
                                <Typography variant="body2" weight="semibold" sx={{ flex: 1 }}>
                                    {selectedBoardMembers.length ? (
                                        `${selectedBoardMembers.length} ${pluralize(
                                            selectedBoardMembers.length,
                                            'board member',
                                        )} selected`
                                    ) : (
                                        <>
                                            Board members{' '}
                                            {boardMembers.length > 0 && (
                                                <>
                                                    (
                                                    {boardMembersSearchTerm
                                                        ? `${boardMembersSearch.length}/${boardMembers.length}`
                                                        : boardMembers.length}
                                                    )
                                                </>
                                            )}
                                        </>
                                    )}
                                </Typography>

                                <SearchField value={boardMembersSearchTerm} onChange={setBoardMembersSearchTerm} />
                                <CrmMenu
                                    anchorButton={
                                        <Button
                                            disabled={!hasContactDataPermission}
                                            startIcon={<Icon type="Export" />}
                                            variant="tertiary"
                                            size="small"
                                            sx={{ marginLeft: 1 }}
                                        >
                                            Send to
                                        </Button>
                                    }
                                    handleClick={(crm) => {
                                        handleContactExport({
                                            crm,
                                            contacts:
                                                selectedBoardMembers.length > 0 ? selectedBoardMembers : boardMembers,
                                        });
                                    }}
                                />
                            </Box>
                            <EmployeesTable
                                hasContactDataPermission={hasContactDataPermission}
                                companyName={companyName}
                                businessId={businessId}
                                country={country}
                                employees={boardMembersSearch}
                                selected={selectedBoardMembers}
                                onSelect={selectBoardMember}
                                onUnselect={unselectBoardMember}
                                onSingleContactExport={handleContactExport}
                            />
                        </>
                    ))}

                <CRMExport {...exportState} onClose={handleCloseExportDialog} />
            </>
        );
    },
);
