import React, { useCallback, useState } from 'react';

import { useLocation } from 'react-router-dom';

import {
    InitialDomainData,
    InitialOrganizationData,
    InitialProfileData,
    isDomainData,
    isOrganization,
    organizationProfileType,
    ProfileType,
} from 'api/types/Organization';
import { NeighborProfile } from 'components/templates/ProfileArrowNavigation';
import { useFilterCompaniesContext } from 'contexts/FilterCompaniesContext/FilterCompaniesContext';
import { ProfileResult } from 'contexts/types/FilterCompanies';

import CompanyProfile from './CompanyProfile';
import DomainProfile from './DomainProfile';
import { defaultTab, isValidTabSlug, TabSlug } from './tabs';

export type ProfileSelectorProps = {
    type: ProfileType;
    id: string;
    scrollRef?: React.RefObject<HTMLElement>;
    previous?: NeighborProfile;
    next?: NeighborProfile;
    initialData?: InitialProfileData;
};

export const ProfileSelector: React.FC<ProfileSelectorProps> = ({
    type,
    id,
    scrollRef,
    previous,
    next,
    initialData: initialDataProp,
}) => {
    const { state } = useLocation();
    const { companies } = useFilterCompaniesContext();
    const [tab, setTab] = useState(() => (isValidTabSlug(state?.tabName || '') ? state.tabName : defaultTab));

    const handleTabChange = useCallback(
        (tab: TabSlug, scrollTop?: boolean) => {
            if (isValidTabSlug(tab)) {
                if (scrollTop && scrollRef?.current) {
                    scrollRef.current.scrollTop = 0;
                }
                setTab(tab);
            }
        },
        [scrollRef],
    );

    if (type === organizationProfileType) {
        const initialData = isOrganization(initialDataProp)
            ? initialDataProp
            : (companies as ProfileResult[]).find(
                  (profile): profile is InitialOrganizationData =>
                      isOrganization(profile) && profile.business_id === id,
              );
        return (
            <CompanyProfile
                mode="popup"
                businessId={id}
                initialData={initialData}
                activeTab={tab}
                onTabChange={handleTabChange}
                previous={previous}
                next={next}
            />
        );
    }

    const initialData = isDomainData(initialDataProp)
        ? initialDataProp
        : (companies as ProfileResult[]).find(
              (profile): profile is InitialDomainData => isDomainData(profile) && profile.website === id,
          );
    return (
        <DomainProfile
            mode="popup"
            domain={id}
            initialData={initialData}
            activeTab={tab}
            onTabChange={handleTabChange}
            previous={previous}
            next={next}
        />
    );
};

export default ProfileSelector;
