import React, { useState, useContext } from 'react';

import { Paper, styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useQuery } from '@tanstack/react-query';

import { StyledLinearProgress } from 'components/templates/ProgressBar/styled';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import { StyledTooltip } from 'components/tokens/select-components/NestedSelect/styled';
import Typography from 'components/tokens/Typography';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';

import { CRMLogoAlt } from '../../../common/CRMLogo';
import useConnectorsApi from '../../../hooks/useConnectorsApi';
import Skeleton from '../../../skeletons';
import { CrmName, DynamicsInstance } from '../../../types/Crm';
import { getCRMName } from '../../../utils';

type DynamicsInstanceSelectViewProps = {
    crm: CrmName;
    onInstanceConfirm: () => Promise<void>;
};

type SelectedInstanceType = DynamicsInstance | null;

const DynamicsInstanceSelectView: React.FC<DynamicsInstanceSelectViewProps> = ({ crm, onInstanceConfirm }) => {
    const { getDynamicsInstanses, confirmDynamicsInstance } = useConnectorsApi();
    const { refetchApplicationStore } = useContext(PermissionContext);

    const [isConfirmLoading, setConfirmLoading] = useState(false);
    const [selectedDynamicsInstance, setSelectedDynamicsInstance] = useState<SelectedInstanceType>(null);

    const { isLoading, data: dynamicsInstances = [] } = useQuery({
        queryKey: ['dynamicsInstances'],
        queryFn: () => getDynamicsInstanses().then(({ data }) => data),
        refetchOnMount: 'always',
    });

    const handleConfirmDynamicsInstance = async () => {
        setConfirmLoading(true);

        await confirmDynamicsInstance(selectedDynamicsInstance as DynamicsInstance);
        await onInstanceConfirm();
        await refetchApplicationStore();

        setConfirmLoading(false);
    };

    if (isConfirmLoading) {
        return (
            <StyledPaper>
                <Typography variant="h4" mb="8px">
                    Connecting
                </Typography>
                <Typography color="subtle" mb={3}>
                    Checking your {getCRMName(crm)}
                </Typography>
                <StyledLinearProgress />
            </StyledPaper>
        );
    }

    return (
        <StyledPaper>
            <Typography variant="h4" mb="8px">
                Almost there...
            </Typography>
            <Typography color="subtle" mb="40px">
                Choose the
                <InstanceTooltip
                    placement="top"
                    title={
                        <>
                            <Typography mb="4px" variant="body1" weight="bold">
                                Instance
                            </Typography>
                            <Typography variant="small" color="subtle">
                                An instance represents an environment in your {getCRMName(crm)}.
                            </Typography>
                            <InstanceHelpLinkWrapper>
                                <CRMLogoAlt name={crm} height={16} width={16} />
                                <Typography variant="tiny" ml="8px">
                                    Learn more from{' '}
                                    <Link
                                        target="_blank"
                                        href="https://learn.microsoft.com/en-us/rest/api/admin.services.crm.dynamics.com/instances/get-instance"
                                    >
                                        Microsoft
                                    </Link>
                                </Typography>
                            </InstanceHelpLinkWrapper>
                        </>
                    }
                >
                    <Typography component="span" color="default" weight="bold" sx={{ cursor: 'pointer' }}>
                        {' '}
                        instance{' '}
                    </Typography>
                </InstanceTooltip>
                you’d like to connect with Vainu. If you need to change it in the future, our Support team is always
                happy to help.
            </Typography>
            <DynamicsInstancesContainer>
                {isLoading
                    ? Array.from({ length: 3 }).map((_, index) => <InstanceCardSkeleton key={index} />)
                    : dynamicsInstances.map((instance) => {
                          const isSelectedInstance = instance.dynamics_url === selectedDynamicsInstance?.dynamics_url;

                          return (
                              <StyledTooltip
                                  key={instance.dynamics_url}
                                  placement="top"
                                  title={
                                      instance.is_active
                                          ? ''
                                          : 'We can’t connect here. To use it with Vainu, reconfigure it from your Dynamics 365 account.'
                                  }
                              >
                                  {/* div is required to render tooltip on disabled instance card with 'pointer-events: none' */}
                                  <div style={{ width: 'calc(33.333% - 16px)' }}>
                                      <DynamicsInstanceCard
                                          isSelected={isSelectedInstance}
                                          isActive={instance.is_active}
                                          onClick={() => setSelectedDynamicsInstance(instance)}
                                      >
                                          <DynamicsInstanceName>
                                              <CRMLogoAlt name={crm} />
                                              <Typography weight="bold" ml="8px">
                                                  {instance.name}
                                              </Typography>
                                          </DynamicsInstanceName>
                                          <DynamicsInstanceContent>
                                              <Typography variant="body2">
                                                  {/* regexp below is used to remove the protocol */}
                                                  {instance.link.replace(/(^\w+:|^)\/\//, '')}
                                              </Typography>
                                              {isSelectedInstance && <CheckIcon type="CheckThick" color="red" />}
                                          </DynamicsInstanceContent>
                                      </DynamicsInstanceCard>
                                  </div>
                              </StyledTooltip>
                          );
                      })}
            </DynamicsInstancesContainer>
            <ConnectButton disabled={!selectedDynamicsInstance} onClick={handleConfirmDynamicsInstance}>
                CONNECT
            </ConnectButton>
        </StyledPaper>
    );
};

const InstanceCardSkeleton: React.FC = () => {
    return (
        <DynamicsInstanceCard isActive isSelected={false}>
            <StyledSkeleton width={150} margin="4px 0 0 0" />
            <StyledSkeleton width={250} margin="22px 0 0 0" />
        </DynamicsInstanceCard>
    );
};

const StyledPaper = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    borderRadius: '8px',
});

const DynamicsInstancesContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
});

const DynamicsInstanceCard = styled('div')<{ isSelected: boolean; isActive: boolean }>(
    ({
        theme: {
            palette: { brandColors, text, border },
            spacing,
        },
        isActive,
        isSelected,
    }) => ({
        flexBasis: 'calc(33.333% - 16px)',
        height: '108px',
        padding: `${spacing(3)} ${spacing(2)}`,
        background: isSelected ? brandColors.tertiaryBlue100 : brandColors.appBg,
        border: isActive ? `1px solid ${isSelected ? brandColors.tertiaryBlue : border}` : 'transparent',
        borderRadius: '8px',
        opacity: isActive ? 1 : 0.5,
        cursor: isActive ? 'pointer' : 'default',
        pointerEvents: isActive ? 'auto' : 'none',

        // '& > div:last-child p' - URL inside intances' card selector
        '& > div:last-child p': {
            color: isSelected ? brandColors.tertiaryBlue : text.secondary,
        },

        '&:hover': {
            border: `1px solid ${isSelected ? brandColors.tertiaryBlue : brandColors.onyx}`,

            '& > div:last-child p': {
                color: isSelected ? brandColors.tertiaryBlue : text.primary,
            },
        },
    }),
);

const DynamicsInstanceContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const DynamicsInstanceName = styled(DynamicsInstanceContent)({
    marginBottom: '16px',

    '& > svg': {
        width: '16px',
        height: '16px',
    },
});

const InstanceHelpLinkWrapper = styled(DynamicsInstanceContent)({
    marginTop: '4px',
});

const ConnectButton = styled(Button)({
    width: '109px',
    marginTop: '48px',
    marginLeft: 'auto',
});

const CheckIcon = styled(Icon)(({ theme: { palette } }) => ({
    width: '20px',
    height: '20px',
    marginLeft: 'auto',
    color: palette.brandColors.tertiaryBlue,
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { palette } }) => ({
    height: '16px',
    background: palette.brandColors.subtleLight,
}));

const InstanceTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme: { palette, shadows } }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: '12px',
        backgroundColor: palette.background.paper,
        color: palette.text.primary,
        boxShadow: shadows[1],
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: palette.background.paper,
    },
}));

export { DynamicsInstanceSelectView };
