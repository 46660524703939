import { Trigger } from 'api/types/Trigger';

export function isValidTrigger(trigger: Trigger): [boolean, string[]] {
    const requiredToHaveValue = ['name', 'notify_type'];
    const fieldNotValid = [];
    function validityCheck(bool: boolean, key: string) {
        if (!bool) {
            fieldNotValid.push(key);
        }
    }
    requiredToHaveValue.forEach((field: string) => {
        validityCheck(!!trigger[field as keyof Trigger], field);
    });
    const {
        utc_office_hours,
        notify,
        query,
        workflow_settings = { destinations: [], daily_limit: null, target_group_source: '', crm_source: '' },
        tracking_logic = {},
        track_changes = [],
    } = trigger;
    const { destinations, daily_limit, target_group_source, crm_source } = workflow_settings;
    validityCheck(['immediately', 'none', 'specific', 'office_hours'].includes(notify || ''), 'notify');
    if (['specific', 'office_hours'].includes(notify || '')) {
        validityCheck(!!utc_office_hours?.week_days?.length, 'week_days');
    }
    // either lead filtering or tracking logic but not both
    const trackingLogicEnabled = !!Object.keys(tracking_logic).length;
    const leadFilteringEnabled = query && query !== '{"?ANY":[]}';
    const trackOtherChanges = !!track_changes.length;
    if (!trackingLogicEnabled && !leadFilteringEnabled && !trackOtherChanges) {
        fieldNotValid.push('tracking_logic_or_queries');
    }

    validityCheck(!!crm_source || !!target_group_source, 'source');
    validityCheck(
        !!destinations?.length &&
            destinations.every((destination) => !destination.active || destination.targets.length),
        'destinations',
    );
    validityCheck(daily_limit === null || daily_limit > 0, 'daily_limit');
    return [!fieldNotValid.length, fieldNotValid];
}

export function isValidForSave(trigger: Trigger): boolean {
    const [isValid, fieldNotValid] = isValidTrigger(trigger);
    // allow saving if source set
    return isValid || !fieldNotValid.includes('source');
}

export function isActiveTrigger(trigger: Trigger): boolean {
    const { notify, notify_type, status } = trigger;
    return (
        isValidTrigger(trigger)[0] &&
        ['immediately', 'office_hours', 'specific', 'none'].includes(notify || '') &&
        notify_type === 'workflow_settings' &&
        status === 'active'
    );
}
