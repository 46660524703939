import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { useFilterState } from 'contexts/FilterContext';

import { mergeKeys } from '../../common/utils';
import { getSameFiltersState } from '../../filters/utils';
import { FilterGroup, FilterGroupProps, FilterID, GroupValues, NodeValue } from '../../FilterTypes';
import { getGroupFilters } from '../common/groupUtils';
import { useGroupActions, useGroupNodes } from '../common/useGroup';
import FilterGroupTemplate from '../FilterGroupTemplate';

const config: FilterGroup = {
    group: GroupValues.target_group,
    label: 'Include / exclude list',
    options: [{ value: FilterID.target_group, label: 'Include / exclude list' }],
    filterUUIDs: [],
    logic: {},
    match: true,
    render: (props: FilterGroupProps) => <ListsGroup {...props} />,
    getFilters: getGroupFilters,
};
export const listsGroupDefinition: Record<GroupValues.target_group, FilterGroup> = {
    [GroupValues.target_group]: config,
};

const createNewGroup = (value: NodeValue) =>
    ({
        [FilterOperator.MATCH]: {
            [GroupValues.target_group]: { [FilterOperator.ALL]: [value] },
        },
    }) as NodeValue;

export const ListsGroup: React.VFC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    removeGroup,
    ids: rawIds = [],
    addNewGroup,
    updateGroup,
    updateGroups,
    disabled = false,
}) => {
    const [isFilterAdded, setIsFilterAdded] = useState(false);

    const isInit = useRef(true);

    const filterGroups = useGroupNodes(rawIds);

    const {
        handleAddFilter: addFilter,
        handleApplyChanges,
        handleFilterRemove,
        keys,
    } = useGroupActions(
        {
            applyChanges,
            removeGroup,
            addNewGroup,
            updateGroup,
            createNewGroup,
            updateGroups,
            filterGroups,
        },
        { ignoreCurrentFilter: true },
    );

    const activeFilters = Object.keys(filterGroups).flatMap((i) => filterGroups[i].filters);

    const ids = Object.keys(filterGroups);

    const renderFilters = () => {
        return ids.map((id) =>
            filterGroups[id]?.filters.map((filter, index) => {
                if (!filter) {
                    return null;
                }

                const filterDefinition = filter.definition;
                const sameFiltersState = getSameFiltersState(activeFilters, filter);

                return filterDefinition
                    ? filterDefinition.render({
                          key: keys[mergeKeys(id, filter.id)],
                          uuid: filterDefinition.id,
                          filter,
                          groupUUID: config.group,
                          removeFilter: () => handleFilterRemove(id, index),
                          sameFiltersState,
                      })
                    : null;
            }),
        );
    };

    const handleAddFilter = useCallback(() => {
        addFilter(FilterID.target_group);
        setIsFilterAdded(true);
    }, [addFilter]);

    const { activeFilter, setActiveFilter } = useFilterState();

    const idsLength = ids.length;
    useEffect(() => {
        if (!isInit.current) {
            return;
        }

        if (activeFilter === FilterID.target_group || !idsLength) {
            handleAddFilter();
            setIsFilterAdded(true);
            setActiveFilter(undefined);
        }
        isInit.current = false;
    }, [handleAddFilter, idsLength, activeFilter, setActiveFilter]);

    const addRowButton = (
        <Button
            variant="tertiary"
            size="small"
            onClick={handleAddFilter}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            AND
        </Button>
    );

    const renderedFilters = renderFilters();
    const props = {
        label: config.label,
        filters: renderedFilters,
        addFilter: handleAddFilter,
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        addRowSelect: addRowButton,
        addRowButton,
        addRow: false,
        isFilterAdded,
        setIsFilterAdded,
        isIndividualFilter: true,
        disabled,
    };
    return <FilterGroupTemplate {...props} />;
};
