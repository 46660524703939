import { useProfileData } from 'api/profileData';
import { organizationProfileType } from 'api/types/Organization';
import { TabContentGridSection } from 'components/modules/profiles/Profile/tabs';
import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import HeaderBox from 'components/templates/HeaderBox';
import Icon from 'components/tokens/Icon';
import { formatDate } from 'utilities/date';

import { ValidFinancialStatement } from '../types';
import { formatStatementPeriod } from './utils';

export type LastStatementProps = {
    statement: ValidFinancialStatement;
};

export const LastStatement: React.VFC<LastStatementProps> = ({ statement }) => {
    const { auditor, accounting_period } = statement;
    const { data: auditorLogoData } = useProfileData(
        { type: organizationProfileType, key: auditor?.business_id },
        ['logo_url'],
        { enabled: !!auditor?.business_id },
    );

    const numColumns = auditor ? 3 : 2;

    const dateRange = `${accounting_period.start ? formatDate(accounting_period.start) : '?'} — ${formatDate(
        accounting_period.end,
    )}`;

    return (
        <TabContentGridSection columns={numColumns}>
            <HeaderBox
                icon={<Icon type="CalendarEdit" />}
                title={formatStatementPeriod(statement)}
                label="Last fiscal year"
                variant="flat-grey"
            />
            {accounting_period.end && (
                <HeaderBox
                    icon={<Icon type="Calendar" />}
                    title={dateRange}
                    label="Fiscal year period"
                    variant="flat-grey"
                />
            )}
            {auditor?.name && (
                <HeaderBox
                    icon={
                        auditorLogoData?.logo_url ? (
                            <CompanyLogo
                                size="tiny"
                                border
                                logo={auditorLogoData.logo_url}
                                company_name={auditor.name}
                            />
                        ) : null
                    }
                    title={auditor.name}
                    label="Auditor"
                    variant="flat-grey"
                />
            )}
        </TabContentGridSection>
    );
};

export default LastStatement;
