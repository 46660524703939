import React from 'react';

import StyledTooltip from '../common/StyledTooltip';

type TooltipPlacementEnum =
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';

export type RenderWithTooltipProps = {
    isTooltip: boolean;
    title: React.ReactNode;
    placement?: TooltipPlacementEnum;
    width?: number;
    margin?: string;
    children: React.ReactElement;
    delay?: number;
};

const RenderWithTooltip: React.FC<RenderWithTooltipProps> = ({
    isTooltip,
    placement = 'left',
    title,
    width,
    margin,
    delay = 100,
    children,
}) => {
    if (!isTooltip) {
        return children;
    }
    return (
        <StyledTooltip
            title={title || ''}
            placement={placement}
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        margin: margin ? `${margin} !important` : undefined,
                        minWidth: 100,
                        maxWidth: width || 250,
                    },
                },
            }}
            enterDelay={delay}
            enterNextDelay={delay}
        >
            <div>{children}</div>
        </StyledTooltip>
    );
};

export default RenderWithTooltip;
