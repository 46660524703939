import { useEffect, useRef } from 'react';

import * as Sentry from '@sentry/react';

export function useLogRender(message: string) {
    const count = useRef(1);

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.debug(message, count.current++);
    });
}

export function logSentry(err: unknown) {
    if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(err);
    }
}
