import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNotEmpty } from 'utilities';

import { deleteApiKey, generateNewApiKey, getAllApiKeys } from 'api/apiKey';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Button from 'components/tokens/Button';
import Dialog from 'components/tokens/Dialog';
import Icon from 'components/tokens/Icon';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import { formatDate } from 'utilities/date';

type KeyRow = {
    id: string;
    name: string;
    key: string;
    created: string;
    lastUsed: string;
};

type ApiKeyDialog = Pick<KeyRow, 'name' | 'key'> & {
    type: 'show' | 'delete';
};

export const LegacyKeys = () => {
    const axios = useAxiosContext();
    const { showSnackbar } = useSnackbarContext();
    const queryClient = useQueryClient();
    const [newApiKeyName, setNewApiKeyName] = useState('');
    const [showApiKey, setShowApiKey] = useState<ApiKeyDialog | null>(null);

    const { data: apiKeys = [], refetch } = useQuery({
        queryKey: ['getAPIKeys'],
        queryFn: () => getAllApiKeys(axios),
    });

    const keys: KeyRow[] = useMemo(() => {
        return apiKeys.map(({ id, name, key, created, last_used }) => ({
            id,
            name,
            key,
            created,
            lastUsed: last_used ?? '',
        }));
    }, [apiKeys]);

    const handleButtonClick = (name: string, key: string, type: 'show' | 'delete') => {
        setShowApiKey({ name, key, type });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await generateNewApiKey(axios, { name: newApiKeyName });
            await refetch();
        } catch (error) {
            showSnackbar('Error in generating key. Please try again later.', 'error');
        }
    };

    const handleDeleteApiKey = async (id: string) => {
        try {
            await deleteApiKey(axios, id);
            queryClient.invalidateQueries({ queryKey: ['getAPIKeys'] });
        } catch (error) {
            showSnackbar('Something went wrong while deleting key.', 'error');
        }
        setShowApiKey(null);
    };

    return (
        <Box sx={{ display: 'grid', gap: 2 }}>
            <Typography variant="h3" color="dark">
                Legacy API Keys
            </Typography>

            <Box>
                <Typography variant="small">Create new key:</Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}
                >
                    <TextField
                        value={newApiKeyName}
                        onChange={(e) => setNewApiKeyName(e.target.value)}
                        sx={{ width: 350 }}
                        placeholder="Name (optional)"
                        compact
                        status={keys.length >= 3 ? 'disabled' : 'default'}
                    />
                    <Button type="submit" disabled={keys.length >= 3}>
                        Create
                    </Button>
                    {keys.length >= 3 && <Typography variant="small">You can only have 3 keys at a time.</Typography>}
                </Box>
            </Box>

            {isNotEmpty(keys) ? (
                <SortableTable<KeyRow>
                    data={keys}
                    rowKeyField="key"
                    rowSx={{
                        height: 64,
                    }}
                    sx={{
                        borderRadius: 2,
                    }}
                    headerCellSx={{
                        fontSize: 14,
                    }}
                    headerRowSx={{
                        height: 52,
                        borderBottom: 'none',
                    }}
                    cellSx={{ fontSize: 14 }}
                >
                    <SortableTableColumn<KeyRow> field="name" label="Name"></SortableTableColumn>
                    <SortableTableColumn<KeyRow> field="key" label="Key">
                        {({ key, name }) => (
                            <Button
                                size="small"
                                variant="tertiary"
                                onClick={() => handleButtonClick(name, key, 'show')}
                            >
                                Show
                            </Button>
                        )}
                    </SortableTableColumn>
                    <SortableTableColumn<KeyRow> field="created" label="Created">
                        {({ created }) => formatDate(new Date(created), 'long')}
                    </SortableTableColumn>
                    <SortableTableColumn<KeyRow> field="lastUsed" label="Last used">
                        {({ lastUsed }) => (lastUsed ? formatDate(new Date(lastUsed), 'long') : '-')}
                    </SortableTableColumn>
                    <SortableTableColumn<KeyRow> field="id" sortable={false}>
                        {({ id, name }) => (
                            <Button
                                aria-label="delete-key"
                                size="small"
                                variant="flat"
                                startIcon={<Icon type="Remove" />}
                                onClick={() => handleButtonClick(name, id, 'delete')}
                            />
                        )}
                    </SortableTableColumn>
                </SortableTable>
            ) : (
                <Typography>You don't have any keys yet.</Typography>
            )}
            <Dialog
                fullWidth
                open={!!showApiKey?.key}
                onClose={() => setShowApiKey(null)}
                cancelLabel="Cancel"
                title={showApiKey?.type === 'delete' ? `Delete ${showApiKey?.name}?` : showApiKey?.name}
                message={showApiKey?.type === 'delete' ? 'This key will be permanently deleted.' : showApiKey?.key}
                customizedConfirm={
                    showApiKey?.type === 'delete' ? (
                        <Button variant="critical" onClick={() => handleDeleteApiKey(showApiKey?.key)}>
                            Delete
                        </Button>
                    ) : undefined
                }
            />
        </Box>
    );
};
