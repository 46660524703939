import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const NotificationDot = (props) => (
    <SvgIcon {...props}>
        <path d="M17 7.99998C15.4097 7.99752 14.0977 6.75453 14.0093 5.16671C13.9209 3.57888 15.0869 2.19797 16.6671 2.01904C18.2473 1.8401 19.6926 2.92533 19.9615 4.49271C20.2304 6.0601 19.2295 7.56499 17.68 7.92298C17.457 7.97421 17.2288 8.00004 17 7.99998Z" />
    </SvgIcon>
);

export default NotificationDot;
