import { useCallback } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { ActionType, getDefaultNewTrigger, useTrigger } from 'contexts/TriggerContext';

import { getTriggerName, TRIGGER_NEW_COMPANY } from '../utils';

/**
 * Handles the initialization of a new trigger or the selection of an existing trigger.
 *
 * If the triggerId is 'new' and searchParams are provided:
 *   - Dispatches a new trigger to the context with prefilled values from the searchParams.
 *
 * If the triggerId is 'new' but no searchParams are provided:
 *   - Prefills the default values for a new trigger without dispatching to the context.
 *
 * If the triggerId matches an existing trigger:
 *   - Selects and dispatches the existing trigger to the context.
 */
export const useInitNewTrigger = () => {
    const [_, dispatch, { triggers, setActivePopup }] = useTrigger();
    const { triggerId } = useParams();
    const [searchParams] = useSearchParams();
    /**
     * Uses the searchParams to prefill values, as there is a plan to
     * transition from using the triggers context to local form state.
     * @see TriggerProvider.reducer comment
     */
    const type = searchParams.get('type');
    const listId = searchParams.get('listId');
    const triggerName = searchParams.get('name');

    return useCallback(() => {
        if (triggerId === 'new' && listId) {
            const newTrigger = {
                ...getDefaultNewTrigger(),
                name: getTriggerName(triggers, triggerName),
                track_changes: type === TRIGGER_NEW_COMPANY ? [TRIGGER_NEW_COMPANY] : [],
                status: 'disabled',
            };

            newTrigger.workflow_settings.target_group_source = listId;
            dispatch({ type: ActionType.SELECT_TRIGGER, trigger: newTrigger, editMode: true });
            if (type !== TRIGGER_NEW_COMPANY) {
                setActivePopup(`select-events-popper`);
            }
            return;
        }

        const initializeWithTrigger = triggers.find((i) => i.id === triggerId);
        if (initializeWithTrigger) {
            dispatch({ type: ActionType.SELECT_TRIGGER, trigger: initializeWithTrigger });
            return;
        }
    }, [dispatch, triggerId, triggers, type, listId, triggerName, setActivePopup]);
};
