import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import RangeSelect from 'components/templates/RangeSelect';

import { FilterConfig, FilterProps, Operator, OperatorValue } from '../../FilterTypes';
import { formatOperatorValue, makeFullPath } from '../utils';
import { useOperator } from './useOperator';

type UseRangeFilterProps<T> = Pick<FilterProps<T>, 'filter' | 'uuid' | 'groupUUID' | 'removeFilter'> & {
    operators?: Operator[];
    config: FilterConfig<T>;
    rangeOptions: number[];
    defaultRange: number[];
};

const defaultOperators: Operator[] = [FilterOperator.RANGE, `${FilterOperator.NOT}${FilterOperator.RANGE}`].map(
    (value) => formatOperatorValue(value as OperatorValue),
);

export const useSimpleRangeFilter = <TValues,>({
    filter,
    uuid,
    groupUUID,
    removeFilter,
    operators = defaultOperators,
    config,
    rangeOptions,
    defaultRange,
}: UseRangeFilterProps<TValues>) => {
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);

    const fullValuePath = makeFullPath(filter, true);

    return {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-range-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;
                    return (
                        <RangeSelect
                            label={config.label}
                            options={rangeOptions}
                            value={value || defaultRange}
                            onRangeChange={onChange}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
};
