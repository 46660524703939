import { useRef } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { FormattedNumber } from 'react-intl';

import { ViewType } from 'components/templates/ResultViewHeader/types';
import Button from 'components/tokens/Button';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import useDebounce from 'hooks/useDebounce';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import ExportDropDown from './TriggerTable/ExportDropDown';
import TriggerTimeFrameSelector from './TriggerTimeFrameSelector';

export type TriggerGroupSubMenuProps = {
    triggerCount: number;
    selectedTriggersCount: number;
    allTriggersSelected: boolean;
    toggleSelectAllTriggers: () => void;
    onViewChange: (newView: ViewType) => void;
    isLoading?: boolean;
    selectAllDisabled?: boolean;
    timeSpan: Date[];
    setTimeSpan: React.Dispatch<React.SetStateAction<Date[]>>;
    openExportModal: (destination: string) => void;
};

const TriggerGroupSubMenu: React.FC<TriggerGroupSubMenuProps> = ({
    triggerCount,
    selectedTriggersCount,
    allTriggersSelected,
    toggleSelectAllTriggers,
    onViewChange,
    isLoading,
    selectAllDisabled,
    timeSpan,
    setTimeSpan,
    openExportModal,
}) => {
    const actionRowRef = useRef(null);
    const isActionRowVisible = useIntersectionObserver({ target: actionRowRef });

    const showLoadingIndicator = useDebounce(isLoading, 200);

    const exportButton = <ExportDropDown openExportModal={openExportModal} />;

    const stickyExportButton = !isActionRowVisible && !!selectedTriggersCount && (
        <Box sx={{ position: 'fixed', top: '75px', left: '270px', zIndex: 1e9 }}>{exportButton}</Box>
    );

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {triggerCount ? (
                    <Checkbox
                        onClick={toggleSelectAllTriggers}
                        disabled={selectAllDisabled}
                        checked={!!selectedTriggersCount}
                        indeterminate={!!selectedTriggersCount && !allTriggersSelected}
                        sx={{ marginRight: '4px' }}
                    />
                ) : null}

                {!selectedTriggersCount && triggerCount !== undefined ? (
                    <Typography variant="small" weight="semibold">
                        <FormattedNumber value={triggerCount} /> result{triggerCount === 1 ? '' : 's'}
                        {showLoadingIndicator ? <CircularProgress size="1em" sx={{ marginLeft: 1 }} /> : null}
                    </Typography>
                ) : null}

                {selectedTriggersCount ? (
                    <Typography variant="small" weight="semibold">
                        <FormattedNumber value={selectedTriggersCount} /> result
                        {selectedTriggersCount === 1 ? '' : 's'} selected
                    </Typography>
                ) : null}

                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }} ref={actionRowRef}>
                    {selectedTriggersCount ? exportButton : null}
                    {stickyExportButton}
                </Box>
            </Box>

            <Box sx={{ display: 'flex' }}>
                <TriggerTimeFrameSelector value={timeSpan} onChange={(dates) => setTimeSpan(dates)} />

                <Button
                    variant="flat"
                    size="small"
                    startIcon={<Icon type="Grid" />}
                    sx={{ marginLeft: 0.5 }}
                    onClick={() => onViewChange('grid')}
                />
                <Button
                    variant="flat"
                    size="small"
                    startIcon={<Icon type="Hamburger" />}
                    onClick={() => onViewChange('list')}
                />
            </Box>
        </Box>
    );
};

export default TriggerGroupSubMenu;
