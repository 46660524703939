import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import { StyledCrmOwnerAndTargetList } from './styled';

type AdditionalTargetListInfoProps = {
    // website will the the unique field for domain data, business_id form organization
    companyIdentifier: string;
};

const AdditionalTargetListInfo: React.VFC<AdditionalTargetListInfoProps> = ({ companyIdentifier }) => {
    // get target lists and active target list either from global store/context
    // const targetList: any[] = [];
    // const activeTargetList: {} = {};

    // find target lists where prospect is included
    // filter out active target list which is opened
    // map through the remaining target list and get target list name
    const foundTargetListNames: string[] = [];

    return foundTargetListNames.length ? (
        <Tooltip
            title={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ textTransform: 'uppercase' }} variant="mini">
                        In target groups:
                    </Typography>
                    {foundTargetListNames.map((name) => (
                        <Typography variant="mini" key={name}>
                            {name}
                        </Typography>
                    ))}
                </div>
            }
            placement="top"
            disableInteractive
        >
            <StyledCrmOwnerAndTargetList id={`prospect-in-target-list-${companyIdentifier}`}>
                <Icon type="ListCheck" fontSize="small" />
            </StyledCrmOwnerAndTargetList>
        </Tooltip>
    ) : null;
};

export default AdditionalTargetListInfo;
