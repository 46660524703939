import * as React from 'react';

import { styled } from '@mui/material';
import List from '@mui/material/List';
import { DragDropContext, Droppable, DroppableProvided, OnDragEndResponder } from 'react-beautiful-dnd';

import { DisabledItem, DraggableListItemType } from '../DragAndDrop';
import DraggableListItem from './DraggableListItem';
import { reorder } from './helpers';

export type DraggableItem = {
    id: string;
    name: string;
};
export interface DraggableListProps {
    items?: DraggableItem[];
    selectedItems: DraggableItem[];
    parentIds: string[];
    disabledItems: DisabledItem[];
    countOfItems: number;
    onDragEnd: OnDragEndResponder;
    removeItem: (index: number) => void;
}

const DraggableList = React.memo(
    ({ selectedItems, parentIds, disabledItems, onDragEnd, removeItem }: DraggableListProps) => {
        const [newItems, setNewItems] = React.useState(selectedItems);

        React.useEffect(() => {
            const index = newItems.findIndex((i) => parentIds.includes(i.id));

            if (index >= parentIds.length) {
                const newItemsOrdering = reorder(newItems, index, 0);
                setNewItems(newItemsOrdering);
            }
        }, [parentIds, newItems]);

        React.useEffect(() => {
            setNewItems(selectedItems);
        }, [selectedItems]);

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-list">
                    {(provided: DroppableProvided) => (
                        <List
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                                margin: '0 7px 0 0',
                                padding: '0 17px',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '6px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#fff',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#E1E1E1',
                                    height: '90px',
                                    borderRadius: '19',
                                },
                            }}
                        >
                            {newItems?.map((item: DraggableListItemType, index) =>
                                parentIds.includes(item.id) ? (
                                    <StyledFirstListItem data-testid="parentItem" key={item.id}>
                                        {item.name}
                                    </StyledFirstListItem>
                                ) : (
                                    <DraggableListItem
                                        item={item}
                                        index={index}
                                        key={item.id}
                                        disabledItems={disabledItems}
                                        removeItem={removeItem}
                                    />
                                ),
                            )}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        );
    },
);

// Styled first item
const StyledFirstListItem = styled('li')`
    opacity: 0.5;
    pointer-events: none;
    padding: 11.7px 16px;
    margin-bottom: 8px;
    background: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    list-style-type: none;
    color: #0a0a0a;
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: bold;
    line-height: 140%;
    letter-spacing: 0.02px;
`;

export default DraggableList;
