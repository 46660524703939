import React from 'react';

import { ProfileExportedToCRM } from 'components/modules/connectors/types';
import { CrmName } from 'components/modules/connectors/types/Crm';
import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon, { IconType } from 'components/tokens/Icon';
import Tag from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';

import { Section } from './Section';
import { TooltipContentSection } from './TooltipContentSection';

export const CRMIcon: Record<string, IconType> = {
    salesforce: 'Salesforce',
    salesforcev3: 'Salesforce',
    salesforcesandbox: 'Salesforce',
    dynamics: 'Dynamics',
    hubspot: 'HubSpot',
    pipedrive: 'Pipedrive',
};

export const CRMTargetToCRMName: Record<string, CrmName> = {
    salesforcev3: 'salesforce',
    dynamics: 'dynamics',
    hubspot: 'hubspot',
    pipedrive: 'pipedrive',
    salesforcesandbox: 'salesforcesandbox',
};

export const DealIcon: Record<string, IconType> = {
    salesforcev3: 'SalesforceOpportunity',
    salesforcesandbox: 'SalesforceOpportunity',
    dynamics: 'DynamicsOpportunity',
    hubspot: 'HubSpotDeal',
    pipedrive: 'PipedriveDeal',
};

export const CRMBasedOwnerTooltipTitle: Record<string, string> = {
    salesforcev3: 'Account Owner',
    salesforcesandbox: 'Account Owner',
    dynamics: 'Account Owner',
    hubspot: 'Company Owner',
    pipedrive: 'Organization Owner',
};

export const CRMBasedDealTooltipTitle: Record<string, string> = {
    salesforcev3: 'Opportunities',
    salesforcesandbox: 'Opportunities',
    dynamics: 'Opportunities',
    hubspot: 'Deals',
    pipedrive: 'Deals',
};

export const CRMBasedLinkTooltipTitle: Record<string, string> = {
    salesforcev3: 'Link to Account',
    salesforcesandbox: 'Link to Account',
    dynamics: 'Link to Account',
    hubspot: 'Link to Company',
    pipedrive: 'Link to Organization',
};

type CompanyCardCRMProps = {
    connectedCRMName?: string;
    exportedProfiles?: ProfileExportedToCRM[];
    name?: string;
    mode: 'default' | 'compact';
    showInTooltip?: boolean;
    onSendToCRMClick?: (crmName: CrmName) => void;
    onOpenDialog?: () => void;
    targetObject?: 'Account' | 'Contact';
};

/**
 * @TODO organize with design revisited esp. for tooltip case
 */
export const CompanyCardCRM: React.FC<CompanyCardCRMProps> = ({
    exportedProfiles: profiles = [],
    name,
    connectedCRMName,
    mode,
    showInTooltip,
    onSendToCRMClick,
    onOpenDialog,
    targetObject = 'Account',
}) => {
    if (!connectedCRMName) {
        return null;
    }

    if (profiles.length) {
        const crmTarget = profiles[0].crmTarget;
        const owners = [...new Set(profiles.map((profile) => profile.ownerEmail))];
        const { wonDealCount, lostDealCount, openDealCount } = profiles.reduce(
            (prev, next) => {
                return {
                    ...prev,
                    wonDealCount: prev.wonDealCount + next.wonDealCount,
                    lostDealCount: prev.lostDealCount + next.lostDealCount,
                    openDealCount: prev.openDealCount + next.openDealCount,
                };
            },
            { wonDealCount: 0, lostDealCount: 0, openDealCount: 0 },
        );

        const ownerText = owners.length > 1 ? `${owners.length} owners` : owners[0] ?? 'No owner';

        return (
            <Frame
                padding={showInTooltip ? 'small' : 'large'}
                variant={showInTooltip ? 'black' : 'white'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: showInTooltip ? '8px' : mode === 'default' ? '16px' : '12px',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                {showInTooltip ? (
                    <>
                        {targetObject === 'Contact' && (
                            <TooltipContentSection label="Contact" content={name} type="basic" />
                        )}
                        {targetObject === 'Account' && (
                            <>
                                <TooltipContentSection
                                    label={CRMBasedOwnerTooltipTitle[crmTarget.toLowerCase()] ?? 'Owner'}
                                    content={ownerText}
                                    type="basic"
                                />
                                <TooltipContentSection
                                    label={CRMBasedDealTooltipTitle[crmTarget.toLowerCase()] ?? 'Deals'}
                                    type="deal"
                                >
                                    <div style={{ display: 'flex', gap: 6 }}>
                                        <Tag
                                            label={lostDealCount > 0 ? `${lostDealCount} lost` : 'no lost'}
                                            size="small"
                                            variant={lostDealCount > 0 ? 'orange' : 'dark'}
                                        />
                                        <Tag
                                            label={openDealCount > 0 ? `${openDealCount} open` : 'no open'}
                                            size="small"
                                            variant={openDealCount > 0 ? 'blue' : 'dark'}
                                        />
                                        <Tag
                                            label={wonDealCount > 0 ? `${wonDealCount} won` : 'no won'}
                                            size="small"
                                            variant={wonDealCount > 0 ? 'green' : 'dark'}
                                        />
                                    </div>
                                </TooltipContentSection>
                            </>
                        )}

                        {profiles.length === 1 ? (
                            <TooltipContentSection
                                label=""
                                content={`Go to ${CRMIcon[crmTarget.toLowerCase()] ?? crmTarget}`}
                                type="link"
                                linkToCRM={profiles[0].linkToCRM}
                            />
                        ) : (
                            <Button
                                variant="tertiary"
                                onClick={onOpenDialog}
                                sx={{ alignSelf: 'flex-end', marginTop: 0.5 }}
                            >
                                Show Accounts ({profiles.length})
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <Section
                            icon={CRMIcon[crmTarget.toLowerCase()] ?? 'Transfer'}
                            iconTitle=""
                            isExported={true}
                            type="title"
                            label={CRMIcon[crmTarget.toLowerCase()] ?? crmTarget}
                            mode={mode}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                gap: mode === 'default' ? '16px' : '12px',
                            }}
                        >
                            <Section
                                icon="UserCircle"
                                iconTitle={CRMBasedOwnerTooltipTitle[crmTarget.toLowerCase()] ?? 'Owner'}
                                type="owner"
                                label={ownerText}
                                mode={mode}
                            />
                            <Section
                                icon={DealIcon[crmTarget.toLowerCase()] ?? 'OtherDeal'}
                                iconTitle={CRMBasedDealTooltipTitle[crmTarget.toLowerCase()] ?? 'Deals'}
                                type="deal"
                                mode={mode}
                            >
                                <Tag
                                    label={lostDealCount > 0 ? `${lostDealCount} lost` : 'no lost'}
                                    size={mode === 'default' ? 'medium' : 'small'}
                                    variant={lostDealCount > 0 ? 'orange' : 'default'}
                                />
                                <Tag
                                    label={openDealCount > 0 ? `${openDealCount} open` : 'no open'}
                                    size={mode === 'default' ? 'medium' : 'small'}
                                    variant={openDealCount > 0 ? 'blue' : 'default'}
                                />
                                <Tag
                                    label={wonDealCount > 0 ? `${wonDealCount} won` : 'no won'}
                                    size={mode === 'default' ? 'medium' : 'small'}
                                    variant={wonDealCount > 0 ? 'green' : 'default'}
                                />
                            </Section>
                            {profiles.length === 1 ? (
                                <Section
                                    icon="ExternalLink"
                                    iconTitle={CRMBasedLinkTooltipTitle[crmTarget.toLowerCase()] ?? 'Link'}
                                    type="link"
                                    label={`Go to ${crmTarget}`}
                                    linkToCRM={profiles[0].linkToCRM}
                                    mode={mode}
                                />
                            ) : (
                                <Button variant="tertiary" onClick={onOpenDialog} sx={{ alignSelf: 'flex-start' }}>
                                    Show Accounts ({profiles.length})
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </Frame>
        );
    }
    return (
        <Frame
            padding="large"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: mode === 'default' ? '16px' : '12px',
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Section
                    icon={CRMIcon[connectedCRMName.toLowerCase()] ?? 'Transfer'}
                    iconTitle=""
                    type="title"
                    label={CRMIcon[connectedCRMName.toLowerCase()] ?? connectedCRMName}
                    mode={mode}
                    isExported={false}
                />
                {onSendToCRMClick && (
                    <Button
                        variant="tertiary"
                        size="small"
                        // what to do when unsupported crm is connected?
                        onClick={() => onSendToCRMClick(CRMTargetToCRMName[connectedCRMName.toLowerCase()])}
                        startIcon={<Icon type="Export" />}
                    >
                        Send
                    </Button>
                )}
            </div>
            <Typography variant={mode === 'default' ? 'small' : 'tiny'}>
                {name} is not one of your Accounts yet
            </Typography>
        </Frame>
    );
};
