import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HubspotContactSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.78425 16.1478C3.40341 15.8175 3.02913 15.4623 2.72923 15.0607C2.36148 14.5681 2.4554 13.8683 2.87401 13.4142C3.67049 12.5504 4.57409 11.827 5.63438 11.3054C5.82126 11.2135 5.9194 11.2097 6.06006 11.3966C6.93484 12.5586 8.10868 13.2221 9.55857 13.3092C10.2618 13.3515 10.9403 13.1267 11.5656 12.7856C12.2286 12.424 12.7941 11.9493 13.226 11.3251C13.3114 11.2018 13.3947 11.145 13.55 11.2216C14.6793 11.7797 15.6602 12.5295 16.4778 13.4872C16.921 14.0063 16.9262 14.7301 16.4685 15.2434C15.0269 16.8599 13.2511 17.8876 11.0949 18.212C10.6205 18.2834 10.1389 18.3055 9.84411 18.3333C7.44357 18.2975 5.46702 17.607 3.78425 16.1478Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2978 10.548C11.5799 11.5524 10.6384 12.1899 9.35103 12.0924C8.30478 12.0133 7.52813 11.4318 6.9276 10.6056C5.94444 9.25289 5.45903 7.72217 5.44774 6.06261C5.43212 3.77868 6.85183 1.9316 9.21607 1.68974C10.5937 1.54887 11.7678 2.05601 12.7033 3.09636C13.1785 3.62493 13.4819 4.24118 13.6295 4.92328C13.7189 5.33757 13.7142 5.77232 13.7518 6.19779C13.6729 7.78981 13.2246 9.25099 12.2978 10.548Z"
        />
    </SvgIcon>
);
export default HubspotContactSvg;
