// A version of useSelection that does not use the inverse "all selected except unselected" mode.

import { useCallback, useMemo, useState } from 'react';

const useSimpleSelection = <T>(allChoices: T[] | undefined) => {
    const [selected, setSelected] = useState<Array<T>>([]);
    const isAllSelected = selected.length === allChoices?.length;

    const select = useCallback((value: T | Array<T>) => {
        if (Array.isArray(value)) {
            setSelected((selected) => Array.from(new Set([...selected, ...value])));
        } else {
            setSelected((selected) => [...selected, value]);
        }
    }, []);

    const unselect = useCallback((value: T) => {
        setSelected((selected) => selected.filter((i) => i !== value));
    }, []);

    const toggleAllSelected = useCallback(
        (selectedState?: boolean) => {
            if (selected.length > 0 || selectedState === false) {
                setSelected([]);
            } else {
                setSelected([...(allChoices ?? [])]);
            }
        },
        [allChoices, selected],
    );

    return useMemo(
        () => ({
            selected,
            isAllSelected,
            select,
            unselect,
            toggleAllSelected,
            setSelected,
        }),
        [selected, isAllSelected, select, unselect, toggleAllSelected, setSelected],
    );
};

export default useSimpleSelection;
