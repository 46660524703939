import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Building = (props) => (
    <SvgIcon {...props}>
        <path d="M20 21H4C3.44772 21 3 20.5523 3 20V12.7C3 12.4119 3.12432 12.1378 3.341 11.948L5 10.5V4C5 3.44772 5.44772 3 6 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21ZM9 7.329C9.24212 7.32928 9.47592 7.4174 9.658 7.577L14.658 11.952C14.874 12.1407 14.9986 12.4132 15 12.7V19H19V5H7V8.75L8.341 7.576C8.52348 7.41654 8.75766 7.32876 9 7.329ZM8 16H10V19H13V13.157L9 9.657L5 13.157V19H8V16Z" />
    </SvgIcon>
);

export default Building;
