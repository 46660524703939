import { Suspense, lazy } from 'react';

import { Box, CircularProgress } from '@mui/material';

import { ListClustersViewContentProps } from './ListClustersViewContent';

const ListClustersViewContent = lazy(() => import('./ListClustersViewContent'));

export const ListClustersView: React.FC<ListClustersViewContentProps> = (props) => {
    return (
        <Suspense
            fallback={
                <Box sx={{ display: 'flex', justifyContent: 'center', gridArea: 'results' }}>
                    <CircularProgress />
                </Box>
            }
        >
            <ListClustersViewContent {...props} />
        </Suspense>
    );
};

export default ListClustersView;
