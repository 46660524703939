import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const SkeletonList = () => (
    <Stack spacing={1}>
        <Skeleton animation={false} variant="rectangular" height={40} sx={{ marginBottom: 5, borderRadius: 1 }} />
        <Skeleton animation={false} variant="text" width={85} height={20} />
        <Skeleton animation={false} variant="rectangular" height={40} sx={{ borderRadius: 1 }} />
        <Skeleton animation={false} variant="rectangular" height={40} sx={{ borderRadius: 1 }} />
        <Skeleton animation={false} variant="rectangular" height={40} sx={{ borderRadius: 1 }} />
    </Stack>
);
