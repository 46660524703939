import React from 'react';

import { Box, Skeleton } from '@mui/material';
import { FormattedNumber } from 'react-intl';

import Frame from 'components/tokens/Frame';
import { formatKr } from 'components/tokens/NumberDataPoint/utils';
import Tag from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';

import ChangeIndicator from './ChangeIndicator';

export type Style = 'decimal' | 'percent' | 'currency' | 'integer';

export interface NumberBoxProps {
    label: string;
    value: number;
    valueStyle: Style;
    valueOptions?: Partial<React.ComponentProps<typeof FormattedNumber>>;
    estimate?: boolean;
    change?: number;
    currency?: string;
    reportYear?: string | number;
    background?: 'white' | 'grey';
    flat?: boolean;
    chart?: React.ReactNode;
    useKr?: boolean;
}

const getNumberFormat = (style: Style, currency?: string): Partial<React.ComponentProps<typeof FormattedNumber>> => {
    if (style === 'decimal') {
        return {
            style: 'decimal',
            notation: 'compact',
            maximumFractionDigits: 1,
        };
    }
    if (style === 'integer') {
        return {
            style: 'decimal',
            notation: 'compact',
            maximumFractionDigits: 0,
        };
    }
    if (style === 'currency') {
        return {
            style: 'currency',
            notation: 'compact',
            maximumFractionDigits: 1,
            currency,
        };
    }
    if (style === 'percent') {
        return {
            style: 'percent',
            maximumSignificantDigits: 2,
        };
    }
    return {};
};

export const NumberBox: React.FC<NumberBoxProps> = ({
    label,
    value,
    valueStyle,
    valueOptions,
    estimate = false,
    change,
    currency,
    reportYear,
    background,
    flat = false,
    chart,
    useKr = false,
}) => {
    const changeColor =
        change === undefined || change === 0 ? undefined : change > 0 ? 'changeIndicator.up' : 'changeIndicator.down';
    const numberProps = getNumberFormat(valueStyle, currency);
    const showChangeValue = Boolean(chart);
    const elevation = flat ? 0 : 1;
    const border = flat;

    return (
        <Frame variant={background} padding="large" elevation={elevation} border={border}>
            <Typography
                variant="body1"
                weight="bold"
                component="h2"
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                {label}
                {reportYear && <Tag variant="default" label={reportYear} size="small" />}
            </Typography>
            <Typography variant="number">
                {estimate && (
                    <Box component="span" sx={{ marginRight: '6px' }}>
                        ~
                    </Box>
                )}
                <FormattedNumber value={value} {...numberProps} {...valueOptions}>
                    {formatKr(useKr, currency)}
                </FormattedNumber>
                {change !== undefined && change !== 0 ? (
                    <>
                        <ChangeIndicator changeValue={change} />
                        {showChangeValue && (
                            <Typography paragraph variant="small" sx={{ color: changeColor }}>
                                {valueStyle === 'percent' ? (
                                    <>
                                        <FormattedNumber value={change} style="decimal" signDisplay="always" />
                                        {' p.p.'}
                                    </>
                                ) : (
                                    <FormattedNumber value={change} style="percent" signDisplay="always" />
                                )}
                            </Typography>
                        )}
                    </>
                ) : (
                    showChangeValue && (
                        <Typography paragraph variant="small">
                            &nbsp;
                        </Typography>
                    )
                )}
                {chart}
            </Typography>
        </Frame>
    );
};

export default NumberBox;

export const NumberBoxSkeleton = ({ height = 291 }) => (
    <Skeleton variant="rounded" width="100%" height={height} sx={{ backgroundColor: 'grey.100' }} />
);
