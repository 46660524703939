import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

import { EmptyStateIconWrapper, NoProfileContainer, SocialIconWrapper } from './styled';

export type FeedErrorProps = {
    companyName?: string;
    mode: 'empty' | 'error';
    type: 'facebook' | 'twitter';
};

export const FeedError: React.VFC<FeedErrorProps> = ({ companyName, mode, type }) => {
    const mediaName = type === 'facebook' ? 'Facebook' : 'Twitter';
    const mediaIcon = type === 'facebook' ? 'Facebook' : 'Twitter';
    const link =
        type === 'facebook'
            ? `https://www.facebook.com/public?query=${companyName}&type=pages`
            : `https://twitter.com/search?q=${companyName}&f=user`;
    const bgIcon = mode === 'error' ? 'Disabled' : 'HelpCircleOutline';
    const searchLabel = `Search ${companyName} from ${mediaName}.`;
    const message = mode === 'error' ? `Incorrect ${mediaName} profile link` : `No ${mediaName} profile`;
    return (
        <NoProfileContainer>
            <EmptyStateIconWrapper>
                <Icon type={bgIcon} sx={{ fontSize: 230 }} color="field.background" />
                <SocialIconWrapper>
                    <Icon type={mediaIcon} sx={{ fontSize: 68 }} />
                </SocialIconWrapper>
            </EmptyStateIconWrapper>
            <Typography variant="body1" color="dark">
                {message}
            </Typography>
            {companyName && (
                <Link href={link} toNewTab="nofollow" sx={{ color: 'text.subtle' }}>
                    {searchLabel}
                </Link>
            )}
        </NoProfileContainer>
    );
};

export default FeedError;
