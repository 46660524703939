import React from 'react';

const MassExportErrorWithCircle: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="1.5" y="1.5" width="97" height="97" rx="48.5" fill="white" />
            <path
                d="M70.0763 69.4957H29.9215C29.1474 69.4957 28.4322 69.0827 28.0451 68.4123C27.6581 67.742 27.6581 66.916 28.0452 66.2457L48.1215 31.579C48.5088 30.9098 49.2235 30.4977 49.9967 30.4977C50.77 30.4977 51.4847 30.9098 51.872 31.579L71.9483 66.2457C72.3352 66.9157 72.3354 67.7412 71.9489 68.4114C71.5624 69.0816 70.8478 69.4949 70.0742 69.4957H70.0763ZM47.8333 58.6623V62.9957H49.8548H49.9957H50.1365H52.1623V58.6623H47.8333ZM47.8333 43.4957V54.329H52.1667V43.4957H47.8333Z"
                fill="#DF2664"
            />
            <rect x="1.5" y="1.5" width="97" height="97" rx="48.5" stroke="#E1E1E1" strokeWidth="3" />
        </svg>
    );
};

export default MassExportErrorWithCircle;
