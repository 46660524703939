import DonutGridTile from 'components/features/CRMInsights/Tiles/DonutGridTile';

import { TileProps } from '../NewVehicles';

const UsedVehiclesTile: React.FC<TileProps> = ({ data, valueField }) => {
    const usedDataRow = data?.find((row) => row.id === 'used_car_flag');
    const usedCars = (usedDataRow?.result[0].buckets.find((bucket) => bucket.value)?.[valueField] || 0) as number;
    const unusedCars = (usedDataRow?.result[0].buckets.find((bucket) => !bucket.value)?.[valueField] || 0) as number;
    const carCount = usedCars + unusedCars;

    return (
        <DonutGridTile
            title="Used vehicles"
            data={[{ value: usedDataRow && usedCars, label: '' }]}
            total={carCount}
            sx={{ width: 248, flex: 'none', maxHeight: 290 }}
            description={<>&nbsp;</>}
            size={158}
            labelVariant="h3"
        />
    );
};

export default UsedVehiclesTile;
