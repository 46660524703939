import { memo } from 'react';

import { Box, Tooltip } from '@mui/material';

import { TabChangeHandler } from 'components/modules/profiles/Profile';
import { TabContentGridSection, TabSlug } from 'components/modules/profiles/Profile/tabs';
import HeaderBox from 'components/templates/HeaderBox';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import { FormattedValue } from 'components/tokens/NumberDataPoint';
import Tag from 'components/tokens/Tag';

export interface EmployeesHeaderProps {
    onTabChange?: TabChangeHandler;
    employeeCountOfficial?: number;
    employeeCountOfficialYear?: string;
    employeeCountWeb?: {
        value: number | [lower: number, upper?: number | null] | undefined;
        class: string | undefined;
    };
    linkedInId?: string;
    groupEmployeeCount?: number;
    subsidiaryCount?: number;
    skeleton?: boolean;
    website?: string;
}

export const EmployeesHeader: React.FC<EmployeesHeaderProps> = memo(
    ({
        onTabChange,
        employeeCountOfficial,
        employeeCountOfficialYear,
        employeeCountWeb,
        linkedInId,
        groupEmployeeCount,
        subsidiaryCount,
        skeleton = false,
        website,
    }) => {
        if (skeleton) {
            return (
                <TabContentGridSection columns={3}>
                    <HeaderBox skeleton />
                    <HeaderBox skeleton />
                    <HeaderBox skeleton />
                </TabContentGridSection>
            );
        }

        return (
            <TabContentGridSection columns={2}>
                {employeeCountOfficial && (
                    <HeaderBox
                        icon={<Icon type="LineChartUp" />}
                        title="Number of employees (official)"
                        label={
                            <>
                                reported in{' '}
                                <Link onClick={() => onTabChange?.('financials' as TabSlug)}>
                                    {employeeCountOfficialYear}
                                </Link>
                            </>
                        }
                        number={employeeCountOfficial}
                    />
                )}

                {groupEmployeeCount && (
                    <HeaderBox
                        icon={<Icon type="GroupStructure" />}
                        title="Group employees"
                        label={
                            <>
                                from{' '}
                                <Link onClick={() => onTabChange?.('group-structure' as TabSlug)}>
                                    {subsidiaryCount || 'all'} subsidiaries
                                </Link>
                            </>
                        }
                        number={groupEmployeeCount}
                    />
                )}

                {employeeCountWeb && (
                    <HeaderBox
                        icon={<Icon type="Globe" />}
                        sx={{ alignItems: 'center' }}
                        title="Estimated size (~employees)"
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                <span>
                                    from{' '}
                                    <Link href={website} toNewTab>
                                        {website}
                                    </Link>{' '}
                                    using Vainu's size indicator
                                </span>

                                <Tooltip title={companySizeTooltipContent}>
                                    <span>
                                        <Icon type="InfoCircleOutline" fontSize="mini" sx={{ display: 'block' }} />
                                    </span>
                                </Tooltip>
                            </Box>
                        }
                        number={
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 0.5 }}>
                                <Tag label={employeeCountWeb.class} />
                                {Array.isArray(employeeCountWeb.value) ? (
                                    <FormattedValue value={employeeCountWeb.value} style="range" />
                                ) : (
                                    employeeCountWeb.value
                                )}
                            </Box>
                        }
                    />
                )}
            </TabContentGridSection>
        );
    },
);

export default EmployeesHeader;

export const companySizeTooltipContent = (
    <>
        <p>
            Vainu uses machine learning (ML) to predict the company size, considering their real-time web traffic,
            number of offices, web technologies, key phrases on their website and more.
        </p>
        <Link
            href="https://www.vainu.com/blog/employee-count-data/"
            toNewTab
            underline="always"
            sx={{ textDecorationColor: 'inherit' }}
        >
            Learn more
        </Link>
    </>
);
