import React from 'react';

import { Box, Paper, SxProps } from '@mui/material';

import { Typography } from 'components/tokens/Typography';

type TileProps = {
    title?: React.ReactNode;
    children: React.ReactNode;
    sx?: SxProps;
    control?: React.ReactNode;
};

const Tile: React.FC<TileProps> = ({ title, children, sx, control, ...props }) => {
    return (
        <Paper
            variant="outlined"
            sx={{
                padding: 3,
                flex: 1,
                maxWidth: '100%',
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                ...sx,
            }}
            {...props}
        >
            {title && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                    <Typography weight="bold">{title}</Typography>

                    {control}
                </Box>
            )}

            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: 1 }}>{children}</Box>
            </Box>
        </Paper>
    );
};

export default Tile;
