import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CircleChevronLeft: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.6054 4.00111 4.03446 7.54686 4.00224 11.9413C3.97002 16.3358 7.48858 19.9336 11.8827 19.9991C16.2768 20.0647 19.9011 16.5735 20 12.18V13.963V12C19.995 7.58378 16.4162 4.00496 12 4ZM13.45 16.9L8.5 11.95L13.45 7L14.864 8.414L11.328 11.95L14.863 15.486L13.451 16.9H13.45Z" />
    </SvgIcon>
);

export default CircleChevronLeft;
