import { useState } from 'react';

import { Box } from '@mui/material';

import Skeleton from 'components/modules/connectors/skeletons';
import Icon from 'components/tokens/Icon';

type JSONItemProps = {
    data: { key: string; value: Object | string };
    depth?: number;
};

const JSONItem = ({ data, depth = 0 }: JSONItemProps) => {
    const [expanded, setExpanded] = useState(depth < 3);

    const { key, value } = data;

    if (key.startsWith('__skeleton')) {
        return (
            <Box sx={{ marginLeft: 8 }}>
                <Skeleton sx={{ backgroundColor: 'grey.200' }} width={150} variant="text" />
            </Box>
        );
    }

    if (['string', 'number'].includes(typeof value) || value == null) {
        return (
            <Box sx={{ marginLeft: 8 }}>
                {key}: {value ? <>{value}</> : <span className="null">null</span>}
            </Box>
        );
    }

    const entries = Object.entries(value);

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: depth === 0 ? 0 : 4 }}
        >
            <Box
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => setExpanded((v) => !v)}
            >
                <Icon
                    type="ArrowDropDown"
                    sx={{
                        marginRight: 1,
                        transform: !expanded ? 'rotate(-90deg)' : undefined,
                    }}
                />
                <span>{key}</span>{' '}
                <span className="row-count">
                    {Array.isArray(value) ? `[${entries.length}]` : `{${entries.length}}`}
                </span>
            </Box>
            {expanded && entries.map(([key, value]) => <JSONItem key={key} data={{ key, value }} depth={depth + 1} />)}
        </Box>
    );
};

export default JSONItem;
