import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const NotificationOutlineDot = (props) => (
    <SvgIcon {...props}>
        <path d="M17 8C16.3958 8.00073 15.8055 7.81839 15.307 7.477C14.1288 6.67158 13.6811 5.14761 14.2365 3.8329C14.7919 2.5182 16.1966 1.77678 17.5954 2.06004C18.9942 2.34329 19.9998 3.5728 20 5C20 6.65685 18.6569 8 17 8Z" />
    </SvgIcon>
);

export default NotificationOutlineDot;
