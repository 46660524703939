import { Tooltip } from '@mui/material';

import { ProfileType } from 'api/types/Organization';
import { CrmMenu } from 'components/modules/connectors/crm-export';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { getProfilePath } from 'components/modules/profiles/Profile';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';

import AddToStaticList from './AddToStaticList';

export type HeaderActionsProps = {
    id: string;
    profileType: ProfileType | 'company-legacy';
    onSendToCRMClick?: (crm: CrmName) => void;
};

export const HeaderActions: React.FC<HeaderActionsProps> = ({ id, profileType, onSendToCRMClick }) => (
    <div>
        <AddToStaticList id={id} />
        <Tooltip title="Send to">
            <CrmMenu
                anchorButton={
                    <Button
                        variant="tertiary"
                        startIcon={<Icon fontSize="mini" type="Export" />}
                        sx={{ marginRight: 1 }}
                    />
                }
                handleClick={(crm) => onSendToCRMClick?.(crm)}
                disablePortal={false}
            />
        </Tooltip>
        <Tooltip title="Open in new window">
            <Button
                variant="tertiary"
                startIcon={<Icon type="ExternalLink" />}
                href={getProfilePath(profileType, id)}
                target="_blank"
            />
        </Tooltip>
    </div>
);

export default HeaderActions;
