import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { formatDistanceToNow, isValid } from 'date-fns';

import { getAccountWideAsyncProcesses } from 'api/asyncProcesses';
import {
    AsyncProcessType,
    asyncProcessTypeListDataV3,
    asyncProcessTypeListDataV2,
    isListDataV3AsyncProcess,
    isListDataV2AsyncProcess,
    ListDataV2AsyncProcess,
    ListDataV3AsyncProcess,
    TargetGroupCSVAsyncProcess,
} from 'api/types/UserAsyncProcess';
import Skeleton from 'components/modules/connectors/skeletons';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';

const HEADER_HEIGHT = 41;
const ROW_HEIGHT = 49;

type TableData = {
    id: string;
    // count: number,
    listName?: string;
    fileType?: string;
    downloadedBy?: string;
    time: string;
    // database: string;
    downloadLink?: string;
};

type HistoryTableProps = {
    dateRange: string[];
};

const asyncProcessTypeAllDownloadTypes = [
    ...asyncProcessTypeListDataV3,
    ...asyncProcessTypeListDataV2,
    AsyncProcessType.TargetGroupCSV,
];

const HistoryTable: React.FC<HistoryTableProps> = ({ dateRange }) => {
    const axios = useAxiosContext();

    const { data } = useQuery({
        queryKey: ['usage-download-history', ...dateRange],
        queryFn: () =>
            getAccountWideAsyncProcesses<ListDataV2AsyncProcess | ListDataV3AsyncProcess | TargetGroupCSVAsyncProcess>(
                axios,
                {
                    type: asyncProcessTypeAllDownloadTypes,
                    limit: 500,
                    after: dateRange?.[0],
                    before: dateRange?.[1],
                    state: 'completed',
                },
            ).then((response) => {
                return response.data
                    .filter((process) => process.state === 'completed')
                    .sort((a, b) => b.finished.localeCompare(a.finished))
                    .map((process) => ({
                        id: process.id,
                        listName:
                            isListDataV2AsyncProcess(process) || isListDataV3AsyncProcess(process)
                                ? process.meta_data.list_name
                                : process.meta_data.name,
                        fileType:
                            isListDataV2AsyncProcess(process) || isListDataV3AsyncProcess(process)
                                ? process.meta_data.requested_format.toUpperCase()
                                : 'CSV',
                        time: process.finished,
                        downloadLink: process.download_link,
                        downloadedBy: process.owner,
                    }));
            }),
        enabled: !!dateRange?.length,
    });

    if (!data) {
        return <Skeleton variant="rectangular" width="100%" height={480} />;
    }

    if (!data.length) {
        return (
            <Typography variant="tiny" color="subtle" sx={{ fontStyle: 'italic' }}>
                No data for this time period
            </Typography>
        );
    }

    return (
        <SortableTable<TableData>
            data={data}
            rowKeyField="id"
            tableHeight={Math.min(HEADER_HEIGHT + ROW_HEIGHT * data.length, 480)}
            sx={{ marginTop: 1 }}
        >
            <SortableTableColumn<TableData>
                label="List name"
                field="listName"
                sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
            >
                {(row) => row.listName}
            </SortableTableColumn>
            <SortableTableColumn<TableData> label="File type" field="fileType">
                {(row) => row.fileType}
            </SortableTableColumn>
            <SortableTableColumn<TableData> label="Downloaded by" field="downloadedBy">
                {(row) => row.downloadedBy}
            </SortableTableColumn>
            <SortableTableColumn<TableData> label="Time" field="time">
                {(row) => {
                    const date = new Date(`${row.time}Z`);
                    return isValid(date) ? formatDistanceToNow(date, { addSuffix: true }) : null;
                }}
            </SortableTableColumn>
            <SortableTableColumn<TableData> label="" field="downloadLink">
                {(row) => (
                    <Tooltip title="Repeat downloads are free" arrow placement="top">
                        <Button
                            size="small"
                            variant="tertiary"
                            onClick={() => {
                                if (row.downloadLink) {
                                    document.location = row.downloadLink;
                                }
                            }}
                            startIcon={<Icon type="Download" />}
                        />
                    </Tooltip>
                )}
            </SortableTableColumn>
        </SortableTable>
    );
};

export default HistoryTable;
