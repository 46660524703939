import { useState } from 'react';

import { DialogProps, useTheme } from '@mui/material';
import { isNil } from 'utilities';

const useDialogMount = ({ transitionDuration }: { transitionDuration?: DialogProps['transitionDuration'] } = {}) => {
    const [open, setOpen] = useState(false);
    const [mount, setMount] = useState(false);
    const theme = useTheme();

    const handleOpen = () => {
        setOpen(true);
        setMount(true);
    };

    const handleClose = () => {
        setOpen(false);
        let duration = theme.transitions.duration.leavingScreen;
        if (transitionDuration) {
            if (typeof transitionDuration === 'number') {
                duration = transitionDuration;
            } else if (!isNil(transitionDuration.exit)) {
                duration = transitionDuration.exit;
            }
        }
        setTimeout(() => setMount(false), duration);
    };

    return { open, mount, handleOpen, handleClose };
};

export default useDialogMount;
