import { styled } from '@mui/material';

const StyledAvatar = styled('div')(({ theme: { shape, palette, params } }) => ({
    '&.logoWrapper': {
        width: 32,
        height: 32,
        borderRadius: shape.borderRadius,
        overflow: 'hidden',
        flex: '0 0 auto',
        '& img, & svg': {
            display: 'block',
            width: '100%',
            height: '100%',
        },
    },
    '&.xlarge': {
        width: 96,
        height: 96,
    },
    '&.larger': {
        width: 60,
        height: 60,
    },
    '&.large': {
        width: 48,
        height: 48,
    },
    '&.medium': {
        width: 40,
        height: 40,
    },
    '&.small': {
        width: 32,
        height: 32,
        borderRadius: 3,
    },
    '&.tiny': {
        width: 24,
        height: 24,
    },
    '&.xtiny': {
        width: 20,
        height: 20,
    },
    '&.round': {
        borderRadius: '50%',
    },
    '&.border': {
        border: `2px solid ${palette.primary.main}`,
    },
    '&.grey-border': {
        borderColor: palette.grey[400],
    },
    '&.skeleton': {
        ...params.skeletonBackground,
    },
    ...params.skeletonBackgroundKeyframes,
}));

export default StyledAvatar;
