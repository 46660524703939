import React from 'react';

import { styled } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';

import { IntegrationKey } from 'api/types/ApplicationStore';
import { Button, ButtonProps } from 'components/tokens/Button';
import { Icon, IconType } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';

import { CRMLogoAlt } from '../../common/CRMLogo';
import useConnectorsApi from '../../hooks/useConnectorsApi';
import { CrmName } from '../../types/Crm';
import { getCRMName } from '../../utils';
import { LandingTemplate } from '../template/Template';
import { LandingContent } from '../template/TemplateContent';
import { LandingHeader } from '../template/TemplateHeader';
import { crmsConnectConfig } from '../utils';

type ConnectCRMLandingPageProps = {
    isApplicationStoreLoading: boolean;
    isDomainConnector: boolean;
    salesforceSandboxConnected?: boolean;
    checkIsConnected: (integration: IntegrationKey) => boolean;
    checkIsPurchased: (crm: CrmName) => boolean;
};

const ConnectCRMLandingPage: React.FC<ConnectCRMLandingPageProps> = ({
    isApplicationStoreLoading,
    isDomainConnector,
    checkIsConnected,
    checkIsPurchased,
}) => {
    const { crmName: crmParam } = useParams<{ crmName: CrmName }>();
    const { connectCrm } = useConnectorsApi();

    if (isApplicationStoreLoading) return null;

    const crm = crmsConnectConfig.find((crm) => crm.id === crmParam);

    if (!crmParam || !crm || !checkIsPurchased(crmParam)) {
        return <Navigate to="../crms" />;
    }

    const isCRMConnected = checkIsConnected(crm.integration);

    const handleConnect = async (crmName: CrmName) => {
        const { data } = await connectCrm(crmName);
        window.location.href = data?.redirect_to;
    };

    const color = isCRMConnected ? 'success' : 'primary';
    const variant = isCRMConnected ? 'secondary' : 'primary';
    const ctaProps: ButtonProps =
        isDomainConnector && !crm.releasedDomain
            ? { disabled: true, color, variant }
            : { disabled: isCRMConnected, color, variant };
    const buttonText =
        isDomainConnector && !crm.releasedDomain ? 'COMING SOON' : isCRMConnected ? 'CONNECTED' : 'CONNECT NOW';

    const cta = (
        <CTAWrapper>
            <Button {...ctaProps} onClick={() => handleConnect(crm.id)}>
                {buttonText}
            </Button>
            {crmParam === 'salesforce' && (
                <Button
                    variant="secondary"
                    disabled={isCRMConnected}
                    onClick={() => handleConnect('salesforcesandbox')}
                >
                    TRY SANDBOX FIRST
                </Button>
            )}
        </CTAWrapper>
    );

    const integrationLogo = (
        <IntegrationLogoWrapper>
            <div style={{ position: 'relative' }}>
                <VainuIcon type="Vainu" />
                {crm.id === 'hubspot' && <HubSpotCertificationIcon type="HubSpotCertification" />}
            </div>
            <ArrowIconWrapper>
                <Icon color="white" type="ArrowShortLeft" sx={{ height: '60px', width: '60px' }} />
                <Icon color="white" type="ArrowShortRight" sx={{ height: '60px', width: '60px' }} />
            </ArrowIconWrapper>
            <CRMLogoAlt name={crm.id} height={90} width={90} style={{ marginRight: 12 }} />
        </IntegrationLogoWrapper>
    );

    return (
        <LandingTemplate>
            <LandingHeader
                hasGoBack
                title={`Connect your ${getCRMName(crm.id)} with Vainu`}
                subtitle="Connecting will match your companies with our database so you can easily find new business opportunities, stay updated on company changes, and maintain accurate company data."
                rightIcon={integrationLogo}
                cta={cta}
            />
            <LandingContent title="Why should you connect?">
                <WhyConnectGrid>
                    <WhyConnectGridItem
                        icon="Advertising"
                        title="New prospects"
                        text="Vainu allows you to exclude companies already in your CRM from your target lists, so you can focus on new prospects."
                    />
                    <WhyConnectGridItem
                        icon="Export"
                        title="Data where you work"
                        text="Vainu delivers companies right to your doorstep, so you don’t have to spend time manually inputting company information."
                    />
                    <WhyConnectGridItem
                        icon="Refresh02"
                        title="Up-to-date information"
                        text="Vainu automatically updates company data points, so you’re always working with the latest information."
                    />
                    <WhyConnectGridItem
                        icon="Analytics"
                        title="Customer overview"
                        text="Vainu analyzes the companies in your CRM, identifies your most profitable accounts, and finds you more of the same."
                    />
                </WhyConnectGrid>
            </LandingContent>
        </LandingTemplate>
    );
};

const CTAWrapper = styled('div')`
    display: flex;
    gap: 1rem;
`;

const IntegrationLogoWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: end;
    height: 200px;
`;

const VainuIcon = styled(Icon)(({ theme: { palette } }) => ({
    height: '105px',
    width: '105px',
    background: palette.brandColors.appBg,
    borderRadius: '50%',
    padding: '1rem',
}));

const HubSpotCertificationIcon = styled(Icon)({
    position: 'absolute',
    top: '70px',
    left: '70px',
    width: 56,
    height: 56,
    background: 'white',
    padding: '8px',
    borderRadius: '50%',
    boxShadow: '0px 2px 4px 0px rgba(10, 10, 10, 0.05), 0px 2px 4px 0px rgba(138, 138, 138, 0.05);',
});

const ArrowIconWrapper = styled('div')`
    display: flex;

    svg:first-child {
        margin-right: -20px;
    }

    svg:last-child {
        margin-left: -20px;
    }
`;

const WhyConnectGrid = styled('div')`
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    max-width: 70%;
`;

type GridItemProp = {
    icon: IconType;
    title: string;
    text: string;
};

const WhyConnectGridItem = ({ icon, title, text }: GridItemProp) => {
    return (
        <GridItem>
            <GridItemIcon type={icon} />
            <div>
                <Typography fontSize={20}>{title}</Typography>
                <Typography variant="body2" sx={{ marginTop: '8px' }}>
                    {text}
                </Typography>
            </div>
        </GridItem>
    );
};

const GridItemIcon = styled(Icon)(({ theme: { palette } }) => ({
    background: palette.brandColors.cloud,
    height: '45px',
    width: '45px',
    padding: '8px',
    color: palette.brandColors.subtle,
    borderRadius: '50%',
}));

const GridItem = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 20px;
    align-items: flex-start;
`;

export { ConnectCRMLandingPage };
