import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AutocompleteRenderGetTagProps, Popover, PopoverOrigin } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';

import { getApplicationStore } from 'api/applicationstore';
import { patchProfile } from 'api/userProfile';
import { Values } from 'components/templates/RangeSelect/types';
import { ChipWithAction } from 'components/tokens/Chip/ChipWithAction';
import { SelectOption, SelectProps } from 'components/tokens/select-components/Select';
import { useAxiosContext } from 'contexts/AxiosContext';
import { brandPalette } from 'design/theme/colors';

import { Mode, SelectWithScoreDataSet } from '../../types';
import { useRange } from '../../utils';
import { IndustryPicker } from '../IndustryPicker';
import { PopoverContent } from '../PopoverContent';
import VciOnboarding from '../VciOnboarding';
import { Score } from './useSelectedScore';

export interface SelectWithScoreProps<T> extends Omit<SelectProps<T>, 'onValueChange'> {
    data: SelectWithScoreDataSet | undefined;
    selectedScore: Score;
    onSelectedScoreChange: (score: Score) => void;
    mode: Mode;
    onModeChange: (mode: Mode) => void;
    onValueChange: (rawValues: SelectOption[], currentChip: number) => void;
}

export const SelectWithScore = forwardRef<HTMLDivElement, SelectWithScoreProps<SelectOption>>(
    (
        {
            value,
            onValueChange,
            width = '200px',
            placeholder = 'Placeholder...',
            options = [],
            limitTags = 1,
            data,
            selectedScore,
            onSelectedScoreChange,
            disabledOptions = [],
            onModeChange,
            mode,
        },
        ref,
    ) => {
        const axios = useAxiosContext();
        const queryClient = useQueryClient();
        const [currentChipId, setCurrentChipId] = useState<number>(0);
        const [isExpanded, setIsExpanded] = useState(false);
        const [showOnboarding, setShowOnboarding] = useState(false);
        const [onboardingStep, setOnboardingStep] = useState(0);
        const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
        const [open, setOpen] = useState(false);

        const currentData = data?.[currentChipId];

        const firstSelectedIndustry = (value as SelectOption[])[0];

        const maxScore = useMemo(() => {
            return (
                (currentData?.rawData.histogram_data[currentData?.rawData.histogram_data.length - 1]?.score || 1) * 100
            );
        }, [currentData]);

        const { rangeValue, onRangeChange } = useRange({
            mode,
            threshold: currentData?.threshold,
            value: selectedScore[currentChipId],
            maxValue: maxScore,
        });

        const onChange = useCallback(
            (event: React.SyntheticEvent, value: SelectOption[], reason: string) => {
                onValueChange(value, currentChipId);

                if (!isOnboardingCompleted && value.length !== 0) {
                    setShowOnboarding(true);
                }
            },
            [onValueChange, currentChipId, isOnboardingCompleted],
        );

        const handleActionClick = useCallback(
            (industryId: number) => {
                setCurrentChipId(industryId);

                setOpen(true);
                setIsExpanded(true);
                const filterRange = selectedScore[industryId];

                if (filterRange) {
                    onModeChange('custom');

                    onRangeChange(filterRange);
                } else {
                    onModeChange('high');
                }
            },
            [selectedScore, onRangeChange, onModeChange],
        );

        const handleSelectedScoreChange = (mode: Mode) => {
            onSelectedScoreChange({ [currentChipId]: mode === 'custom' ? rangeValue : undefined });
        };

        const handleClose = () => {
            setOpen(false);
            setIsExpanded(false);
            handleSelectedScoreChange(mode);
        };

        const finishOnboarding = () => {
            setIsOnboardingCompleted(true);
            const data = { onboarding_status: { vci_onboarding_shown: 1 } };
            patchProfile(axios, data);
            queryClient.invalidateQueries({ queryKey: ['getApplicationStore'] });
        };

        const handleNext = () => {
            setShowOnboarding(false);

            if (onboardingStep === 0) {
                handleActionClick(firstSelectedIndustry.value as number);
                setTimeout(() => {
                    setOnboardingStep(1);
                    setShowOnboarding(true);
                }, 0);
            }
        };

        const chipRef = useRef<HTMLDivElement>(null);

        const renderTags = useCallback(
            (values: SelectOption[], getTagProps: AutocompleteRenderGetTagProps) => {
                return values.map((item, index) => {
                    let value;
                    if (currentChipId === item.value) {
                        value = rangeValue?.[0] ?? 100;
                    } else {
                        value = selectedScore[item.value]?.[0] ?? 100;
                    }
                    const threshold = data?.[item.value]?.threshold ?? 0;
                    const actionText = (
                        <span
                            style={{
                                color: value >= threshold ? brandPalette.sky : brandPalette.warningOrange,
                                textDecoration: 'underline',
                            }}
                        >
                            {value >= threshold ? 'high' : 'low'}
                        </span>
                    );

                    return (
                        <ChipWithAction
                            size="small"
                            label={item.label}
                            {...getTagProps({ index })}
                            actionText={actionText}
                            onClick={noop}
                            onActionClick={() => handleActionClick(item.value as number)}
                        />
                    );
                });
            },
            [currentChipId, data, rangeValue, selectedScore, handleActionClick],
        );

        useEffect(() => {
            let unmount = false;
            (async function getOnboardingStatus() {
                try {
                    const { data } = await queryClient.fetchQuery({
                        queryKey: ['getApplicationStore'],
                        queryFn: () => getApplicationStore(axios),
                        staleTime: Infinity,
                    });
                    if (!unmount) {
                        setIsOnboardingCompleted(!!data.onboarding_status?.vci_onboarding_shown);
                    }
                } catch (e) {}
            })();

            return () => {
                unmount = true;
            };
        }, [queryClient, axios]);

        const handleModeChange = (mode: Mode) => {
            onModeChange(mode);
        };

        const handleRangeChange = (values: Values) => {
            // update the min value of range even if max value is changed
            const changedValue = values[1] < maxScore ? values[1] : values[0];
            const newValues = [changedValue, maxScore];
            if (mode === 'custom') {
                onRangeChange(newValues);
            } else {
                onModeChange('custom');
            }
        };

        return (
            <>
                <Popover
                    open={open}
                    anchorEl={chipRef.current}
                    onClose={handleClose}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    keepMounted
                >
                    <PopoverContent
                        data={currentData}
                        onRangeChange={handleRangeChange}
                        rangeValue={rangeValue}
                        onChangeMode={handleModeChange}
                        mode={mode}
                        maxScore={maxScore}
                    />
                </Popover>
                <IndustryPicker
                    setInputRef={(_ref) => (ref = _ref)}
                    placeholder={placeholder}
                    ref={chipRef}
                    value={value as SelectOption[]}
                    options={options}
                    onChange={onChange}
                    limitTags={limitTags}
                    width={width}
                    isExpanded={isExpanded}
                    renderTags={renderTags}
                    getOptionDisabled={(option) =>
                        disabledOptions.some((disabledOption) => disabledOption.value === option.value)
                    }
                />
                <div style={{ order: 1 }}>
                    <VciOnboarding
                        show={showOnboarding}
                        step={onboardingStep}
                        firstSelectedIndustry={firstSelectedIndustry}
                        onNext={handleNext}
                        onFinish={() => {
                            setShowOnboarding(false);
                            finishOnboarding();
                        }}
                    />
                </div>
            </>
        );
    },
);

const anchorOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

const transformOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
};
