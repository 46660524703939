export const brandPalette = {
    sky: '#2f80ed',
    orange: '#f29711',
    green: '#349780',
    magenta: '#df2664',
    onyx: '#0a0a0a',
    body: '#3f3f3f',
    subtle: '#898989',
    middle: '#bfbfbf',
    subtleLight: '#e1e1e1',
    cloud: '#f5f5f5',
    appBg: '#fafafa',
    white: '#ffffff',
    vainuBlack: '#000000',
    vainuBlackContrast: '#ffffff',

    // New colors
    primaryGreen: '#00e880',
    primaryGreen100: '#D9FCDF',
    primaryGreen200: '#9BFAB7',
    primaryGreen300: '#58F599',
    primaryGreen400: '#00E880',
    primaryGreen500: '#00CC70',
    primaryGreen600: '#00A85D',
    primaryGreen700: '#008549',
    primaryGreen800: '#006638',
    primaryGreen900: '#004727',
    secondaryPurple: '#700078',
    secondaryPurple700: '#9F1DAD',
    secondaryPurple500: '#dfabe3',
    secondaryPurple100: '#F9E8FF',
    tertiaryBlue: '#4f80ff',
    tertiaryBlue500: '#8FAEFF',
    tertiaryBlue100: '#e5edff',
    warningOrange: '#fa983a',
    warningOrange500: '#FCBE83',
    warningOrange100: '#FEF2E6',
    successGreen: '#079992',
    successGreen500: '#acdcda',
    successGreen100: '#edf8f6',
    errorMagenta: '#e84d9e',
    errorMagenta100: '#fce8f3',
    criticalRed: '#e82235',
    reliableBrown: '#ccc7b8',
    downToEarthBrown: '#e0ccab',
    statusError100: '#FCE8F3',
    statusError500: '#F8C9E1',
};

export type BrandPalette = typeof brandPalette;
