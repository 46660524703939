import { Box, Grid } from '@mui/material';

import { Team } from 'api/types/Account';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';

import { Permission, UserRow } from '../types';
import { AccountLevelPermission } from './AccountLevelPermission';
import { PermissionRadio, PermissionRadioProps } from './PermissionRadio';

export interface SharedListDialogProps {
    totalUsersCount: number;
    listOwner: Team;
    usersWithoutListOwner: Team[];
    userRows: UserRow[];
    searchText: string;
    allReadOnlyProfileIds: string[];
    allReadWriteProfileIds: string[];
    onSearchChange: (keyword: string) => void;
    onAccountLevelPermissionChange: (readIds: string[], readWriteIds: string[]) => void;
    onUserPermissionChange: (profileId: string, type: Permission) => void;
}

export const SharedListContent: React.FC<SharedListDialogProps> = ({
    listOwner,
    totalUsersCount,
    usersWithoutListOwner,
    userRows,
    searchText,
    allReadOnlyProfileIds,
    allReadWriteProfileIds,
    onSearchChange,
    onAccountLevelPermissionChange,
    onUserPermissionChange,
}) => {
    const renderPermissionRadio = (
        additionalProps: Pick<PermissionRadioProps, 'profileId' | 'value' | 'listOwner'>,
    ) => (
        <PermissionRadio
            {...additionalProps}
            onChange={onUserPermissionChange}
            allReadOnlyProfileIds={allReadOnlyProfileIds}
            allReadWriteProfileIds={allReadWriteProfileIds}
        />
    );
    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Grid container alignItems="center" marginBottom={3}>
                <Grid item xs={12} md={6}>
                    <Typography component="p" variant="small" color="dark">
                        Set permissions for the whole team:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AccountLevelPermission
                        allReadOnlyProfileIds={allReadOnlyProfileIds}
                        allReadWriteProfileIds={allReadWriteProfileIds}
                        onChange={onAccountLevelPermissionChange}
                        usersWithoutListOwner={usersWithoutListOwner}
                    />
                </Grid>
            </Grid>

            {totalUsersCount >= 10 && (
                <Grid item xs={12} marginBottom={3}>
                    <TextField
                        icon="Search"
                        placeholder="Search..."
                        compact
                        onChange={(e) => onSearchChange(e.target.value)}
                        value={searchText}
                        style={{ maxWidth: 320 }}
                    />
                </Grid>
            )}

            <SortableTable<UserRow> rowKeyField="id" data={userRows} cellSx={{ fontSize: 14, fontWeight: 500 }}>
                <SortableTableColumn<UserRow>
                    sx={{ minWidth: 380 }}
                    sortable={false}
                    field="email"
                    label={`Email (${userRows.length})`}
                >
                    {({ email, user }) => (
                        <>
                            {email} {user === listOwner.user ? ' (owner)' : ''}
                        </>
                    )}
                </SortableTableColumn>
                <SortableTableColumn<UserRow> sortable={false} field="read_write" label="Can edit">
                    {({ id, read_write }) =>
                        renderPermissionRadio({
                            profileId: id,
                            value: read_write,
                            listOwner: id === listOwner.id,
                        })
                    }
                </SortableTableColumn>
                {/* /> */}
                <SortableTableColumn<UserRow> sortable={false} field="read_only" label="Can view">
                    {({ id, read_only }) =>
                        renderPermissionRadio({
                            profileId: id,
                            value: read_only,
                            listOwner: id === listOwner.id,
                        })
                    }
                </SortableTableColumn>
                <SortableTableColumn<UserRow> sortable={false} field="not_shared" label="No access">
                    {({ id, not_shared }) =>
                        renderPermissionRadio({
                            profileId: id,
                            value: not_shared,
                            listOwner: id === listOwner.id,
                        })
                    }
                </SortableTableColumn>
            </SortableTable>
        </Box>
    );
};

export default SharedListContent;
