import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import { StyledFormControl } from './styled';

type SearchBarProps = {
    setSearchQuery(elem: string): void;
    setShowSearch(isShowSearch: boolean): void;
    searchTerm: string;
    showSearch: boolean;
};

export const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
    ({ setSearchQuery, setShowSearch, searchTerm, showSearch }: SearchBarProps, ref) => (
        <StyledFormControl width={showSearch} className={showSearch ? 'expanded' : ''}>
            {showSearch ? (
                <TextField
                    autoFocus
                    inputRef={ref}
                    value={searchTerm}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    variant="outlined"
                    placeholder="Search attributes for..."
                    size="medium"
                ></TextField>
            ) : null}
            <IconButton onClick={() => setShowSearch(!showSearch)} aria-label="search">
                {showSearch ? <CloseIcon /> : <SearchIcon />}
            </IconButton>
        </StyledFormControl>
    ),
);
