import React from 'react';

import { styled } from '@mui/material';

type LetterLogoProps = React.HTMLAttributes<HTMLDivElement> & {
    company: {
        id: number | string;
        company_name: string;
        form_of_company?: string;
    };
    width?: number;
    height?: number;
};

const LetterLogo: React.FC<LetterLogoProps> = ({ company, width, height, ...props }) => {
    // for realestate do not show logo, it is usually management companys
    if (!(company.company_name || company.id) || company.form_of_company === 'AOY') {
        return null;
    }
    const letter = (company?.company_name || company.id)?.toString()?.charAt(0);
    return (
        <Container backgroundImage={getColor(company.id)} width={width} height={height} {...props}>
            <Letter>{letter}</Letter>
        </Container>
    );
};

const Container = styled('div')<{ backgroundImage: string; width?: number; height?: number }>`
    line-height: normal;
    width: ${(props) => props.width || 40}px;
    height: ${(props) => props.height || 40}px;
    color: #fff;
    font-size: ${(props) => (props.width || 40) * 1.8}px;
    font-weight: 900;
    overflow: hidden;
    border-radius: 3px;
    background-image: ${(props) => props.backgroundImage};
`;
const Letter = styled('div')`
    transform-origin: 287% 87%;
    transform: rotate(20deg);
    opacity: 0.3;
`;
const violetGradient = 'linear-gradient(180deg, #7042bf, #3023ae)';
const greenGradient = 'linear-gradient(180deg, #17cec4, #17cec4 0%, #08aeea)';
const orangeGradient = 'linear-gradient(180deg, #ff8308, #fd4f00)';
const redGradient = 'linear-gradient(180deg, #ed629a, #c850c0)';

function getColor(id: string | number): string {
    let gradient = violetGradient;
    if (!id) {
        return gradient;
    }

    let num = String(id).substr(-1);
    num = (+num / 2).toFixed();
    switch (+num) {
        case 1:
            gradient = orangeGradient;
            break;
        case 2:
            gradient = greenGradient;
            break;
        case 3:
            gradient = redGradient;
            break;
        default:
            break;
    }
    return gradient;
}

export default LetterLogo;
