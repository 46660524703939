import { useState, useMemo, memo } from 'react';

import { Box, Skeleton } from '@mui/material';
import { isNotEmpty } from 'utilities';

import { TechnologyData } from 'api/types/Organization';
import { TabChangeHandler } from 'components/modules/profiles/Profile';
import { TabSlug } from 'components/modules/profiles/Profile/tabs';
import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import { formatWebsiteLabel, formatTechnologiesUpdateDate, selectHighlightedTechnologies } from '../utils';
import WebProfileHighlightsGrid from './Grid';
import WebProfileTable from './Table';

export interface WebProfileTechnologiesListProps {
    technology_data?: TechnologyData[];
    website?: string;
    disableTable?: boolean;
    skeleton?: boolean;
    updatedDate?: string;
    onGoToClick?: TabChangeHandler;
}

export const WebProfileTechnologiesList: React.VFC<WebProfileTechnologiesListProps> = memo(
    ({ technology_data, website, disableTable = false, skeleton = false, updatedDate, onGoToClick }) => {
        const [mode, setMode] = useState<'highlights' | 'table'>('highlights');

        const highlightedTechnologies = useMemo(
            () => (technology_data ? selectHighlightedTechnologies(technology_data, 9) : null),
            [technology_data],
        );

        if (skeleton) {
            return (
                <Frame sx={{ width: '100%' }} padding="large">
                    <Typography
                        variant="body1"
                        weight="bold"
                        component="h2"
                        sx={{ lineHeight: onGoToClick ? '40px' : undefined, marginBottom: 2 }}
                    >
                        Technologies
                    </Typography>
                    <Typography paragraph variant="small" color="subtle" sx={{ marginBottom: 3 }}>
                        <Skeleton variant="text" width={240} />
                    </Typography>
                    <WebProfileHighlightsGrid skeleton />
                </Frame>
            );
        }

        if (!isNotEmpty(technology_data) || !website) {
            return null;
        }

        const { updatedString } = formatTechnologiesUpdateDate(updatedDate);
        const websiteLabel = formatWebsiteLabel(website);

        const numTechnologies = technology_data.length;
        const numHighlights = highlightedTechnologies?.length ?? 0;

        return (
            <Frame sx={{ width: '100%' }} padding="large">
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
                    <Typography variant="body1" weight="bold" component="h2">
                        Technologies
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="tiny" color="subtle" sx={{ fontStyle: 'italic' }}>
                            {'Last checked: '}
                            <Box component="span" sx={{ fontWeight: 500 }}>
                                {updatedString}
                            </Box>
                        </Typography>
                        {onGoToClick && (
                            <Button
                                sx={{ marginLeft: 1 }}
                                variant="tertiary"
                                startIcon={<Icon type="CircleRight" />}
                                onClick={() => onGoToClick(TabSlug.WebProfile, true)}
                            />
                        )}
                    </Box>
                </Box>
                <Typography paragraph variant="small" color="subtle" sx={{ marginBottom: 3 }}>
                    {'We’ve found '}
                    <Box component="span" sx={{ color: 'text.primary', fontWeight: 500 }}>
                        {technology_data.length}
                    </Box>
                    {' technologies used at '}
                    <Box component="span" sx={{ color: 'link', fontWeight: 500 }}>
                        {websiteLabel}
                    </Box>
                    .
                </Typography>
                {(mode === 'highlights' || disableTable) && highlightedTechnologies && numHighlights > 0 ? (
                    <WebProfileHighlightsGrid highlightedTechnologies={highlightedTechnologies} />
                ) : (
                    <WebProfileTable technology_data={technology_data} />
                )}

                {!disableTable && numHighlights > 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                        <Button
                            variant="flat"
                            size="small"
                            onClick={() => setMode(mode === 'highlights' ? 'table' : 'highlights')}
                        >
                            {mode === 'highlights'
                                ? `Show all technologies (${numTechnologies})`
                                : `Show only top ${numHighlights}`}
                        </Button>
                    </Box>
                )}
            </Frame>
        );
    },
);

export default WebProfileTechnologiesList;
