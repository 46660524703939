import React, { useContext } from 'react';

import { Box, SxProps, Tooltip } from '@mui/material';
import { isNotEmpty, localeCompareEmptyLast } from 'utilities';

import { Contact } from 'api/types/Organization';
import NoFilterResults from 'components/features/lists/CompanyGridView/NoFilterResults';
import { getLocaleFromCountry } from 'components/features/Locations/utils';
import { CrmName } from 'components/modules/connectors/types/Crm';
import ContactAvatar from 'components/templates/avatars/ContactAvatar';
import CrmOwnerIndicator from 'components/templates/CompanyCard/CrmOwnerIndicator';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Tags from 'components/templates/Tags';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import { TextTruncateTooltip } from 'components/tokens/TextTruncate';
import Typography from 'components/tokens/Typography';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';

import { checkBoardMember, checkDecisionMaker, formatContactSource, getRoles } from '../utils';
import { ContactConnectInfo } from './ContactConnectInfo';

export interface EmployeesTableProps {
    companyName?: string;
    businessId: string;
    employees: Contact[];
    country?: string;
    hasContactDataPermission: boolean;
    sx?: SxProps;
    selected: Contact[];
    onSelect: (value: Contact) => void;
    onUnselect: (value: Contact) => void;
    onSingleContactExport: ({ crm, contacts }: { crm: CrmName; contacts: Contact[] }) => void;
}
const MIN_HEIGHT = 350;
export const EmployeesTable: React.FC<EmployeesTableProps> = ({
    companyName,
    businessId,
    employees,
    country,
    hasContactDataPermission,
    sx,
    selected,
    onSelect,
    onUnselect,
    onSingleContactExport,
}) => {
    const locale = getLocaleFromCountry(country);
    const headerHeight = 41;
    const rowHeight = 57;
    const tableHeight = headerHeight + (employees.length ? rowHeight * Math.min(5, employees.length) : MIN_HEIGHT);

    const { integrations } = useContext(PermissionContext);

    return (
        <Box
            sx={{
                em: {
                    fontStyle: 'normal',
                    fontWeight: 500,
                },
                ...sx,
            }}
        >
            <SortableTable<Contact>
                tableHeight={tableHeight}
                data={employees}
                defaultSortColumn="last_name"
                rowKeyField="uid"
                sortLocale={locale}
                noDataPlaceholder={
                    <Box sx={{ height: '50vh' }}>
                        <NoFilterResults type="contacts" />
                    </Box>
                }
            >
                <SortableTableColumn<Contact>
                    field=""
                    keyValue="selection"
                    sx={{ padding: 0.5 }}
                    headerSx={{ padding: 0 }}
                    sortable={false}
                    skeletonWidth={60}
                >
                    {(row) => (
                        <Checkbox
                            disabled={!hasContactDataPermission}
                            checked={!!selected.find((i) => i.uid === row.uid)}
                            onChange={(event) => {
                                const isSelected = event.target.checked;
                                isSelected ? onSelect(row) : onUnselect(row);
                            }}
                        />
                    )}
                </SortableTableColumn>
                <SortableTableColumn<Contact>
                    width={340}
                    label="Contact"
                    field="full_name"
                    compareFn={(a, b) =>
                        `${a.last_name ?? ''} ${a.first_name ?? ''}`.localeCompare(
                            `${b.last_name ?? ''} ${b.first_name ?? ''}`,
                        )
                    }
                >
                    {(row) => {
                        const isDecisionMaker = checkDecisionMaker(row);
                        const isBoardMember = checkBoardMember(row);
                        const contactTitles =
                            row.titles
                                ?.filter((title) => title.title)
                                .map((title) => title.title)
                                .join(', ') || '';

                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <ContactAvatar
                                    id={companyName}
                                    first_name={hasContactDataPermission ? row.first_name : contactTitles[0]}
                                    last_name={hasContactDataPermission ? row.last_name : contactTitles[1]}
                                />
                                <Box>
                                    {hasContactDataPermission ? (
                                        <>
                                            <Typography weight="semibold" variant="body2" sx={{ display: 'flex' }}>
                                                {row.full_name}
                                                {isDecisionMaker && (
                                                    <Tooltip title="Decision maker" placement="top">
                                                        <span style={{ height: '16px' }}>
                                                            <Icon
                                                                type="Award"
                                                                fontSize="small"
                                                                color="brandColors.tertiaryBlue"
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {isBoardMember && (
                                                    <Tooltip title="Board member" placement="top">
                                                        <span style={{ height: '16px' }}>
                                                            <Icon
                                                                type="Judge"
                                                                fontSize="small"
                                                                color="brandColors.body"
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography weight="semibold" variant="body2" sx={{ display: 'flex' }}>
                                            {contactTitles}
                                            {isDecisionMaker && (
                                                <Tooltip title="Decision maker" placement="top">
                                                    <span style={{ height: '16px' }}>
                                                        <Icon
                                                            type="Award"
                                                            fontSize="small"
                                                            color="brandColors.tertiaryBlue"
                                                        />
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        );
                    }}
                </SortableTableColumn>
                <SortableTableColumn<Contact> width={160} label="Function" field="roles">
                    {(row) => (
                        <Tags
                            tags={getRoles(row.titles).map((role) => ({
                                label: role,
                                id: role,
                            }))}
                            cutoff={1}
                            sx={{ justifyContent: 'flex-start' }}
                        />
                    )}
                </SortableTableColumn>
                {hasContactDataPermission && (
                    <SortableTableColumn<Contact>
                        width={200}
                        label="Title"
                        field="titles"
                        key="titles"
                        compareFn={(a, b) =>
                            localeCompareEmptyLast(a.titles?.[0].title ?? '', b.titles?.[0].title ?? '')
                        }
                    >
                        {(row) => (
                            <Box sx={{ minWidth: 180 }}>
                                <TextTruncateTooltip
                                    text={
                                        row.titles
                                            ?.filter((title) => title.title)
                                            .map((title) => title.title)
                                            .join(', ') || ''
                                    }
                                    showTooltip
                                />
                            </Box>
                        )}
                    </SortableTableColumn>
                )}
                <SortableTableColumn<Contact>
                    sortable={false}
                    width={186}
                    label="Contact info"
                    field=""
                    keyValue="contact_info"
                >
                    {(contact) => <ContactConnectInfo disabled={!hasContactDataPermission} contact={contact} />}
                </SortableTableColumn>
                {hasContactDataPermission && (
                    <SortableTableColumn<Contact>
                        width={240}
                        label="Source"
                        field="metadata"
                        compareFn={(a, b) =>
                            localeCompareEmptyLast(
                                a.metadata?.source_types?.join(', ') ?? '',
                                b.metadata?.source_types?.join(', ') ?? '',
                            )
                        }
                    >
                        {(row) => formatContactSource(row)}
                    </SortableTableColumn>
                )}
                {isNotEmpty(integrations) && (
                    <SortableTableColumn<Contact> width={240} label="Export" field="" sortable={false}>
                        {(row) => (
                            <CrmOwnerIndicator
                                disabled={!hasContactDataPermission}
                                id={businessId}
                                contactId={row.uid}
                                profileType="organization"
                                name={row.full_name}
                                targetObject="Contact"
                                onSendToCrm={(crm: CrmName) => onSingleContactExport({ crm, contacts: [row] })}
                            />
                        )}
                    </SortableTableColumn>
                )}
            </SortableTable>
        </Box>
    );
};

export default EmployeesTable;
