import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HubSpot = (props) => (
    <SvgIcon style={{ fill: '#FF7A59' }} {...props}>
        <path d="M16.8206 8.80484V6.5608C17.1172 6.4222 17.3682 6.20223 17.5446 5.92648C17.721 5.65073 17.8155 5.33056 17.817 5.00322V4.95173C17.817 3.99734 17.0433 3.22364 16.089 3.22364H16.0374C15.083 3.22364 14.3093 3.99734 14.3093 4.95173V5.00322C14.3108 5.33056 14.4053 5.65073 14.5817 5.92648C14.7581 6.20223 15.0092 6.4222 15.3057 6.5608V8.80484C14.4534 8.93536 13.6507 9.28877 12.9789 9.82928L6.82456 5.03562C6.86844 4.8772 6.89134 4.7143 6.89392 4.55078C6.89467 4.16565 6.78119 3.78895 6.56782 3.46833C6.35446 3.1477 6.05079 2.89756 5.69525 2.74955C5.3397 2.60153 4.94824 2.56228 4.57038 2.63677C4.19253 2.71126 3.84525 2.89615 3.57249 3.16803C3.29972 3.43992 3.11371 3.78659 3.038 4.16421C2.96228 4.54182 3.00026 4.9334 3.14712 5.28943C3.29399 5.64546 3.54314 5.94993 3.86307 6.16434C4.18299 6.37875 4.55932 6.49345 4.94445 6.49395C5.28137 6.49236 5.61197 6.40241 5.90325 6.23309L11.9636 10.9489C10.8494 12.6321 10.8792 14.8257 12.0386 16.478L10.1954 18.3218C10.0463 18.2742 9.89118 18.2489 9.73474 18.2467C8.85199 18.2475 8.13686 18.9634 8.13709 19.8461C8.13732 20.7288 8.8529 21.4443 9.73557 21.4446C10.6183 21.4448 11.3342 20.7297 11.335 19.8469C11.3329 19.6905 11.3076 19.5353 11.2598 19.3863L13.0833 17.5622C14.7126 18.8165 16.9503 18.9245 18.6929 17.8331C20.4354 16.7416 21.3148 14.681 20.8973 12.6677C20.4798 10.6543 18.8535 9.11339 16.8206 8.80476V8.80484ZM16.0648 16.1853C15.7279 16.1944 15.3927 16.1358 15.0788 16.0132C14.7649 15.8905 14.4788 15.7062 14.2373 15.4712C13.9958 15.2361 13.8039 14.955 13.6728 14.6446C13.5418 14.3341 13.4743 14.0005 13.4743 13.6635C13.4743 13.3265 13.5418 12.9929 13.6728 12.6825C13.8039 12.372 13.9958 12.0909 14.2373 11.8559C14.4788 11.6208 14.7649 11.4365 15.0788 11.3139C15.3927 11.1912 15.7279 11.1327 16.0648 11.1417C17.4224 11.1893 18.4985 12.3032 18.4993 13.6617C18.4999 15.02 17.4249 16.1351 16.0673 16.184" />
    </SvgIcon>
);

export default HubSpot;
