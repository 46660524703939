import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FlagSweden: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            d="M22 12.4583C22 17.9812 17.5228 22.4583 12 22.4583C6.47715 22.4583 2 17.9812 2 12.4583C2 6.93547 6.47715 2.45831 12 2.45831C17.5228 2.45831 22 6.93547 22 12.4583Z"
            fill="#024D8E"
        />
        <path
            d="M9.91415 10.7049V2.67617L9.4022 2.79906L8.90134 2.94767L8.41434 3.12038L8.15756 3.22317L7.90428 3.33283L7.65381 3.44962L7.38961 3.58219L7.16257 3.70411L6.91459 3.84611L6.44839 4.13962L6.06864 4.40648V10.7049H2.15322L2.08745 11.1299L2.03766 11.5844L2.00995 12.0081L2 12.4583L2.00913 12.8895L2.03766 13.3322L2.08394 13.7599L2.15213 14.2056H6.5395V20.8372L6.92731 21.078L7.34495 21.311L7.77022 21.5223L8.20556 21.7133L8.59882 21.865L9.031 22.0102L9.52661 22.1501L9.77909 22.2108L9.94096 22.2461V22.1019L9.91415 14.2056H21.8479L21.9172 13.7509L21.9634 13.3198L22 12.4583L21.99 12.0082L21.9592 11.5493L21.911 11.1181L21.8468 10.7049H9.91415Z"
            fill="#F7C502"
        />
    </SvgIcon>
);

export default FlagSweden;
