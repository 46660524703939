import { Nullable } from 'utilities';

import { NewVehicle } from './company';
import { FinancialStatement } from './FinancialStatement';

export type AddressDetails = Partial<{
    care_of: string;
    street_name: string;
    street_number: string;
    floor: string;
    property_unit_number: string;
    po_box: string;
    entrance: string;
    instructions: string;
}>;

export type Coordinates = {
    latitude: number;
    longitude: number;
};

export type Address = Partial<{
    street: string;
    postal_code: string;
    city: string;
    country: string;
    state: string;
    municipality: string;
    county: string;
    po_box: string;
    types: string[];
    details: AddressDetails;
    coordinates: Coordinates;
}>;

export type BusinessUnitAPIResponse = Partial<{
    uid?: string;
    address: Address;
    visiting_address: Address;
    office_type: string;
    types: string[];
    tel: string;
}>;

export type BusinessUnit = BusinessUnitAPIResponse & {
    uid: string;
};

export type EInvoice = Partial<{
    e_invoice_address: string;
    invoice_address_name: string;
    operator_name: string;
    operator_id: string;
    operator_business_id: string;
    is_receiving: boolean;
    is_sending: boolean;
}>;

export type VainuCustomIndustry = Partial<{
    confidence_score: number;
    confidence_class: string;
    confidence_thresholds: number[];
    source_version: string;
    type: string;
    value: string;
}>;

export type BasicDataPlus = Partial<{
    e_invoice_addresses: EInvoice[];
    company_keywords: string[];
    employer_registration_date: string;
}>;

export type WebsiteData = Partial<{
    keyword_frequencies: {
        [key: string]: number;
    };
    keywords: string[];
    total_word_count: number;
    websites: Partial<{ link: string; domain: string; description: string | null }>[];
    screenshot_thumb_url: string;
    screenshot_url: string;
    website_traffic_data: Partial<{
        source: string;
        ranking: number;
        website_traffic_class: string;
    }>;
}>;

export type TechnologyData = Partial<{
    id: number;
    name: string;
    types: string[];
    description: string;
    /** This is set as internal field so must be explicitly passed to get the result */
    logo_url: string;
}>;

export type FinancialData = Partial<{
    currency: string;
    revenue: number;
    revenue_eur: number;
    profit: number;
    development_of_revenue: number;
    employee_count: number;
}>;

export type OrganizationSizeIndicators = Partial<{
    indicator_type: string;
    value_range: {
        lower_limit?: number;
        upper_limit?: number;
    };
    size_classes: string[];
}>;

export type SocialMedia = Partial<{
    type: string;
    url: string;
    followers: number;
    username: string;
    id: number;
    description: string;
}>;

export type ProfileIndexes = Partial<{
    digitalization_index: number;
    social_media_index: number;
}>;

export type ContactTitle = Nullable<{
    title: string;
    title_cls: string;
    classifications: Array<{
        functional_area: string | null;
        is_decision_maker: boolean;
    }>;
}>;
export type Contact = {
    uid: string;
} & Partial<{
    first_name: string;
    last_name: string;
    full_name: string;
    email: string;
    phone: string;
    titles: ContactTitle[];
    metadata: Nullable<{
        modified: string;
        phone_available: boolean;
        email_available: boolean;
        source_types: (string | null | undefined)[];
        fields: Nullable<{
            email: {
                vainu_verified: string;
            };
            phone_number: {
                vainu_verified: string;
            };
        }>;
    }>;
    web_profiles: {
        type: string;
        url: string;
    }[];
}>;

export type Register = Partial<{
    register_name: string;
    registration_date: string;
    end_date: string;
    status_description: string;
    authority: string;
}>;

export type SimilarDomain = {
    domain: string;
    similarity_score: number;
};

export type ProfileMetadata = Partial<{
    crawled: Partial<{
        web_technologies: string;
    }>;
}>;

export type ProfileDataFieldsAPIResponse = Partial<{
    business_units: BusinessUnitAPIResponse[];
    auxiliary_names: string[];
    email: string;
    founded: string;
    industries: string[];
    industries_verbose: { scheme: string; code: string; description?: string }[];
    legal_entity: string;
    name: string;
    official_status: string | null; //??
    tel: string;
    website: string;
    basic_data_plus: BasicDataPlus;
    vainu_custom_industries: VainuCustomIndustry[];
    website_data: WebsiteData;
    technology_data: TechnologyData[];
    financial_data: FinancialData;
    financial_statements: FinancialStatement[];
    organization_size_indicators: OrganizationSizeIndicators[];
    logo_url: string;
    social_media: SocialMedia[];
    indexes: ProfileIndexes;
    description: string;
    contacts: Contact[];
    registers: Register[];
    similar_domains: SimilarDomain[];
    metadata: ProfileMetadata;
}>;

export type ProfileDataFields = Omit<ProfileDataFieldsAPIResponse, 'business_units'> &
    Partial<{
        business_units: BusinessUnit[];
        vehicles_count: number;
        vehicles: NewVehicle[];
    }>;

export type DomainDataFieldsAPIResponse = {
    website: string;
    countries?: string[];
    business_ids?: string[];
    similar_domains?: SimilarDomain[];
};

export type ProfileID = string;

export type DomainDataIDFields = {
    id: ProfileID;
    profileType: DomainProfileType;
};

export type DomainDataFields = DomainDataFieldsAPIResponse & DomainDataIDFields;

export type OrganizationFieldsAPIResponse = {
    business_id: string;
    country?: string;
    level_in_group?: number | null;
    group_data?: {
        name: string;
        organizations_in_countries: string[];
        subsidiary_count: number;
        parent_organization: {
            business_id: string;
            country: string;
            name: string;
        };
        financial_data: {
            employees: {
                absolute_count: number | null;
                range_lower_count: number | null;
                range_upper_count: number | null;
            };
            revenue: {
                value: number | null;
                currency_code: string | null;
            };
        };
    } | null;
    direct_marketing_denied?: boolean;
    contacts?: Contact[];
    vehicles?: NewVehicle[];
};

export type OrganizationIDFields = {
    id: ProfileID;
    profileType: OrganizationProfileType;
};

export type OrganizationFields = OrganizationFieldsAPIResponse & OrganizationIDFields;

export type DomainDataAPIResponse = ProfileDataFieldsAPIResponse & DomainDataFieldsAPIResponse;
export type OrganizationAPIResponse = ProfileDataFieldsAPIResponse & OrganizationFieldsAPIResponse;
export type ProfileDataAPIResponse = DomainDataAPIResponse | OrganizationAPIResponse;

export type DomainData = ProfileDataFields & DomainDataFields;
export type Organization = ProfileDataFields & OrganizationFields;
export type ProfileData = DomainData | Organization;

export const organizationProfileType = 'organization';
export const domainProfileType = 'domain';
export type OrganizationProfileType = typeof organizationProfileType;
export type DomainProfileType = typeof domainProfileType;
export type ProfileType = OrganizationProfileType | DomainProfileType;

export type ProfileStub = Pick<ProfileData, 'profileType'>;
export type OrganizationStub = Pick<Organization, 'profileType'>;
export type DomainDataStub = Pick<DomainData, 'profileType'>;

export type InitialOrganizationData = OrganizationIDFields &
    Partial<Organization> &
    Required<Pick<Organization, 'business_id'>>;
export type InitialDomainData = DomainDataIDFields & Partial<DomainData> & Required<Pick<DomainData, 'website'>>;
export type InitialProfileData = InitialOrganizationData | InitialDomainData;

export const isOrganization = <T extends ProfileStub>(
    data: T | T[] | {} | {}[] | undefined,
): data is OrganizationStub | OrganizationStub[] =>
    !!data &&
    (Array.isArray(data)
        ? 'profileType' in data[0] && data[0].profileType === organizationProfileType
        : 'profileType' in data && data?.profileType === organizationProfileType);

export const isDomainData = <T extends ProfileStub>(
    data: T | T[] | {} | {}[] | undefined,
): data is DomainDataStub | DomainDataStub[] =>
    !!data &&
    (Array.isArray(data)
        ? 'profileType' in data[0] && data[0].profileType === domainProfileType
        : 'profileType' in data && data?.profileType === domainProfileType);
