import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InCrmSalesforce: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            d="M14.0399 18.2534L13.7058 18.6003L14.0399 18.9471L16.4399 21.4391L16.8 21.8131L17.1601 21.4391L21.9601 16.455L22.2942 16.1082L21.9601 15.7614L20.8929 14.6532L20.5326 14.279L20.1724 14.6533L16.8003 18.1583L15.8279 17.1457L15.4678 16.7707L15.1071 17.1452L14.0399 18.2534ZM12.5 18C12.5 14.9624 14.9624 12.5 18 12.5C21.0376 12.5 23.5 14.9624 23.5 18C23.5 21.0376 21.0376 23.5 18 23.5C14.9624 23.5 12.5 21.0376 12.5 18Z"
            fill="#079992"
            stroke="#079992"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.859 5.43788C11.8658 5.43788 10.9678 5.85471 10.3228 6.52678C9.61311 5.59886 8.49373 5 7.23419 5C5.08889 5 3.34957 6.73673 3.34957 8.87917C3.34957 9.42762 3.46382 9.94928 3.66923 10.4219C2.67151 11.0051 2 12.0951 2 13.3436C2 15.2065 3.49459 16.7165 5.33818 16.7165C5.57351 16.7165 5.80286 16.692 6.02422 16.645C6.53077 18.019 7.85043 18.9988 9.39886 18.9988C9.98461 18.9988 10.5376 18.8586 11.0262 18.61C11.0089 18.409 11 18.2055 11 18C11 14.134 14.134 11 18 11C19.4434 11 20.7847 11.4369 21.899 12.1856C21.9652 11.8829 22 11.5683 22 11.2456C22 8.84358 20.0695 6.89627 17.6883 6.89627C17.0675 6.89627 16.4775 7.02876 15.9445 7.26692C15.3311 6.17405 14.1792 5.43788 12.859 5.43788Z"
            fill="#898989"
        />
    </SvgIcon>
);

export default InCrmSalesforce;
