// This reflects the /poll_async_processes/ schema at https://app.vainu.io/api/schema

import { CrmName } from 'components/modules/connectors/types/Crm';

import {
    AsyncProcessMetaDataCRMBulkExport,
    AsyncProcessMetaDataCRMImport,
    AsyncProcessMetaDataDynamicToStaticList,
    AsyncProcessMetaDataImportListAsyncAPI,
    AsyncProcessMetaDataListClustering,
    AsyncProcessMetaDataListDataV2,
    AsyncProcessMetaDataListDataV3,
    AsyncProcessMetaDataListStats,
    AsyncProcessMetaDataSendMessageQueueItems,
    AsyncProcessMetaDataTargetGroupCSV,
} from './AsyncProcessMetaData';

export * from './AsyncProcessMetaData';

// These are async job types that are defined in the API enum, but not used in the frontend in any way.
// Some may be obsolete or used by other (obsolete) apps.
// If taking a type from this into use, move onto AsyncProcessType first.
export enum AsyncProcessTypeUnused {
    BatchImport = 'batch-import',
    BatchMatch = 'batch-match',
    BatchMatchChild = 'batch-match-child',
    BulkCRMExport = 'bulk_crm_export',
    BulkCRMExportConfirmed = 'bulk_crm_export_confirmed',
    CRMImport = 'crm-import',
    EnrichSheet = 'enrich-sheet',
    EnrichSheetChild = 'enrich-sheet-child',
    EnrichSpreadsheet = 'enrich_spreadsheet',
    ExportTargetGroupJson = 'export-target-group-json',
    ExternalReferencesAsyncAPI = 'external-references-async-api',
    ExternalReferencesStatsAsyncAPI = 'external-references-stats-async-api',
    HubSpotImportExport = 'hubspot-import-export',
    JSONExport = 'json_export',
    PipedriveImportExport = 'pipedrive-import-export',
    PipedrivenotificationImport = 'pipedrivenotification-import',
    PublicCompaniesAsyncAPI = 'public-companies-async-api',
    PublicSignalsAsyncAPI = 'public-signals-async-api',
    PublicV3FilterSimilarDomainsAsyncAPI = 'public-v3-filter-similar-domains-async-api',
    PublicV3FilterSimilarOrganizationsAsyncAPI = 'public-v3-filter-similar-organizations-async-api',
    SuperofficeFullExport = 'superoffice-full-export',
    SuperofficeImport = 'superoffice-import',
    UploadCSV = 'upload_csv',
    UploadProspectsCSV = 'upload_prospects_csv',
    UpsalesImport = 'upsales-import',
}

export enum AsyncProcessType {
    DynamicsFullExport = 'dynamics-full-export',
    DynamicsImport = 'dynamics-import',
    HubSpotFullExport = 'hubspot-full-export',
    HubSpotImport = 'hubspot-import',
    ListDomainsAsyncAPI = 'list-domains-async-api',
    ListOrganizationsAsyncAPI = 'list-organizations-async-api',
    PipedriveFullExport = 'pipedrive-full-export',
    PipedriveImport = 'pipedrive-import',
    PublicDomainsAsyncAPI = 'public-domains-async-api',
    PublicDomainsStatsAsyncAPI = 'public-domains-stats-async-api',
    PublicOrganizationsAsyncAPI = 'public-organizations-async-api',
    PublicOrganizationsStatsAsyncAPI = 'public-organizations-stats-async-api',
    PublicV3DomainClusteringAPI = 'public-v3-domain-clustering-api',
    PublicV3ImportBusinessIdsAsyncAPI = 'public-v3-import-business_ids-async-api',
    PublicV3ImportDomainsAsyncAPI = 'public-v3-import-domains-async-api',
    SalesforceFullExport = 'salesforce-full-export',
    SalesforceImport = 'salesforce-import',
    SalesforceSandboxFullExport = 'salesforcesandbox-full-export',
    SalesforceSandboxImport = 'salesforcesandbox-import',
    StaticListAsync = 'static_list_async',
    SendMessageQueueItems = 'send_message_queue_items',
    TargetGroupCSV = 'target_group_csv',
}

export const asyncProcessType = Object.values(AsyncProcessType);

export const isAsyncProcess = <T extends { type: string }>(process: GenericAsyncProcess | T): process is AsyncProcess =>
    (asyncProcessType as string[]).includes(process.type);

const isAsyncProcessOfType =
    <SpecificProcessType extends AsyncProcess>(types: readonly AsyncProcessType[]) =>
    (process: GenericAsyncProcess): process is SpecificProcessType =>
        (types as string[]).includes(process.type);

export const asyncProcessTypeCRMBulkExport = [
    AsyncProcessType.DynamicsFullExport,
    AsyncProcessType.HubSpotFullExport,
    AsyncProcessType.PipedriveFullExport,
    AsyncProcessType.SalesforceFullExport,
    AsyncProcessType.SalesforceSandboxFullExport,
] as const;

export const asyncProcessTypeCRMBulkExportByCRM: Record<CrmName, AsyncProcessType> = {
    dynamics: AsyncProcessType.DynamicsFullExport,
    hubspot: AsyncProcessType.HubSpotFullExport,
    pipedrive: AsyncProcessType.PipedriveFullExport,
    salesforce: AsyncProcessType.SalesforceFullExport,
    salesforcesandbox: AsyncProcessType.SalesforceSandboxFullExport,
} as const;

export const getAsyncProcessTypeCRMBulkExportByCRM = (crm: CrmName) => asyncProcessTypeCRMBulkExportByCRM[crm];

export type AsyncProcessTypeCRMBulkExport = (typeof asyncProcessTypeCRMBulkExport)[number];

export const isCRMBulkExportAsyncProcess =
    isAsyncProcessOfType<CRMBulkExportAsyncProcess>(asyncProcessTypeCRMBulkExport);

export const asyncProcessTypeCRMImport = [
    AsyncProcessType.DynamicsImport,
    AsyncProcessType.HubSpotImport,
    AsyncProcessType.PipedriveImport,
    AsyncProcessType.SalesforceImport,
    AsyncProcessType.SalesforceSandboxImport,
] as const;

export const asyncProcessTypeCRMImportByCRM: Record<CrmName, AsyncProcessType> = {
    dynamics: AsyncProcessType.DynamicsImport,
    hubspot: AsyncProcessType.HubSpotImport,
    pipedrive: AsyncProcessType.PipedriveImport,
    salesforce: AsyncProcessType.SalesforceImport,
    salesforcesandbox: AsyncProcessType.SalesforceSandboxImport,
} as const;

export const getAsyncProcessTypeCRMImportByCRM = (crm: CrmName) => asyncProcessTypeCRMImportByCRM[crm];

export type AsyncProcessTypeCRMImport = (typeof asyncProcessTypeCRMImport)[number];

export const isCRMImportAsyncProcess = isAsyncProcessOfType<CRMImportAsyncProcess>(asyncProcessTypeCRMImport);

export const isDynamicToStaticListAsyncProcess = (job: GenericAsyncProcess): job is DynamicToStaticListAsyncProcess =>
    job.type === AsyncProcessType.StaticListAsync;

export const asyncProcessTypeImportList = [
    AsyncProcessType.PublicV3ImportDomainsAsyncAPI,
    AsyncProcessType.PublicV3ImportBusinessIdsAsyncAPI,
] as const;

export type AsyncProcessTypeImportList = (typeof asyncProcessTypeImportList)[number];

export const isImportListAsyncProcess = isAsyncProcessOfType<ImportListAsyncProcess>(asyncProcessTypeImportList);

export const isListClusteringAsyncProcess = (job: GenericAsyncProcess): job is ListClusteringAsyncProcess =>
    job.type === AsyncProcessType.PublicV3DomainClusteringAPI;

export const asyncProcessTypeListDataV2 = [
    AsyncProcessType.PublicDomainsAsyncAPI,
    AsyncProcessType.PublicOrganizationsAsyncAPI,
] as const;

export type AsyncProcessTypeListDataV2 = (typeof asyncProcessTypeListDataV2)[number];

export const isListDataV2AsyncProcess = isAsyncProcessOfType<ListDataV2AsyncProcess>(asyncProcessTypeListDataV2);

export const asyncProcessTypeListDataV3 = [
    AsyncProcessType.ListDomainsAsyncAPI,
    AsyncProcessType.ListOrganizationsAsyncAPI,
] as const;

export type AsyncProcessTypeListDataV3 = (typeof asyncProcessTypeListDataV3)[number];

export const isListDataV3AsyncProcess = isAsyncProcessOfType<ListDataV3AsyncProcess>(asyncProcessTypeListDataV3);

export const asyncProcessTypeListStats = [
    AsyncProcessType.PublicDomainsStatsAsyncAPI,
    AsyncProcessType.PublicOrganizationsStatsAsyncAPI,
] as const;

export type AsyncProcessTypeListStats = (typeof asyncProcessTypeListStats)[number];

export const isListStatsAsyncProcess = isAsyncProcessOfType<ListStatsAsyncProcess>(asyncProcessTypeListStats);

export const isSendMessageQueueItemsAsyncProcess = (
    job: GenericAsyncProcess,
): job is SendMessageQueueItemsAsyncProcess => job.type === AsyncProcessType.SendMessageQueueItems;

export const isTargetGroupCSVAsyncProcess = (job: GenericAsyncProcess): job is TargetGroupCSVAsyncProcess =>
    job.type === AsyncProcessType.TargetGroupCSV;

export type UserAsyncProcessType = `${AsyncProcessType}`;

export type CrmNameLowerCase = 'dynamics' | 'hubspot' | 'pipedrive' | 'salesforce' | 'superoffice' | 'upsales';

export type AsyncProcessState =
    | 'accepted'
    | 'completed'
    | 'failure'
    | 'partial_failure_complete'
    | 'partial_failure_incomplete'
    | 'process'
    | 'stopped';

interface UserAsyncProcessChildProcess {
    child_id: string;
    child_state: string;
}

export type AsyncProcessCommonFields = {
    child_processes: UserAsyncProcessChildProcess[];
    id: string;
    /** @deprecated Legacy field; should be refactored out and replaced with "id" */
    job_id: string;
    state: AsyncProcessState;
    progress: number | null;
    progress_max: number | null;
    created: string;
    finished: string;
    error: string;
    error_msg?: string;
    deleted: boolean;
    seen: boolean;
    is_child_process: boolean;
    main_process_id: string;
    download_link?: string;
    query_uid?: string;
    query_hash?: string | null;
    owner?: string;
};

export type CRMBulkExportAsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessTypeCRMBulkExport;
    meta_data: AsyncProcessMetaDataCRMBulkExport;
    error:
        | 'DUPLICATE_FOUND'
        | 'INTEGRATION_ERROR'
        | 'AUTH_ERROR'
        | 'INTERNAL_ERROR'
        | 'MISSING_PERMISSION'
        | 'USER_MESSAGE'
        | 'BAD_REQUEST'
        | string;
};

export type CRMImportAsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessTypeCRMImport;
    meta_data: AsyncProcessMetaDataCRMImport;
};

export type DynamicToStaticListAsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessType.StaticListAsync;
    meta_data: AsyncProcessMetaDataDynamicToStaticList;
};

export type ImportListAsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessTypeImportList;
    meta_data: AsyncProcessMetaDataImportListAsyncAPI;
};

export type ListClusteringAsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessType.PublicV3DomainClusteringAPI;
    meta_data: AsyncProcessMetaDataListClustering;
};

export type ListDataV2AsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessTypeListDataV2;
    meta_data: AsyncProcessMetaDataListDataV2;
};

export type ListDataV3AsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessTypeListDataV3;
    meta_data: AsyncProcessMetaDataListDataV3;
};

export type ListStatsAsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessTypeListStats;
    meta_data: AsyncProcessMetaDataListStats;
};

export type SendMessageQueueItemsAsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessType.SendMessageQueueItems;
    meta_data: AsyncProcessMetaDataSendMessageQueueItems;
};

export type TargetGroupCSVAsyncProcess = AsyncProcessCommonFields & {
    type: AsyncProcessType.TargetGroupCSV;
    meta_data: AsyncProcessMetaDataTargetGroupCSV;
};

/** Use this type when you only need the AsyncProcessCommonFields. */
export type GenericAsyncProcess = AsyncProcessCommonFields & {
    type: string;
    /** You should not use this field directly. Instead use a type guard to find the correct async process type you want to access.
     *  This field is only reserved for places where async processes of multiple type are serialized and stored. */
    meta_data: {
        [field: string]: unknown;
    };
};

export type AsyncProcess =
    | CRMBulkExportAsyncProcess
    | CRMImportAsyncProcess
    | DynamicToStaticListAsyncProcess
    | ImportListAsyncProcess
    | ListClusteringAsyncProcess
    | ListDataV2AsyncProcess
    | ListDataV3AsyncProcess
    | ListStatsAsyncProcess
    | SendMessageQueueItemsAsyncProcess
    | TargetGroupCSVAsyncProcess;

export type UserAsyncProcess = AsyncProcess;
