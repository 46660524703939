import { Operation } from 'api/types/FilterOperators';
import { Database } from 'contexts/ListsContext';

export type CrmName = 'hubspot' | 'pipedrive' | 'salesforce' | 'dynamics' | 'salesforcesandbox';
export const crmNames: CrmName[] = ['hubspot', 'pipedrive', 'salesforce', 'dynamics', 'salesforcesandbox'];
export const isValidCrmName = (name: string | undefined): name is CrmName => crmNames.includes(name);

export type CRMNameAPIResponse = 'Hubspot' | 'Salesforce' | 'Dynamics' | 'Pipedrive' | 'SalesforceSandbox';
export type BulkDataExportSource = 'mass_export' | 'manual_update' | 'auto_update';
export type DataUpdateType = 'always' | 'only_for_new_companies' | 'safe_update';

export type VainuObject = 'Prospect' | 'Lead' | 'Contact' | 'Text' | 'Picklist';

export type HubspotObject = 'Company' | 'Contact' | 'Deal' | 'Note' | 'Task';
export type SalesforceObject = 'Account' | 'Contact' | 'Opportunity' | 'Lead' | 'Task';
export type DynamicsObject = 'accounts' | 'contacts' | 'opportunities' | 'leads' | 'tasks' | 'annotations';
export type PipedriveObject = 'organization' | 'person' | 'deal' | 'note' | 'lead' | 'activity';

export type CRMObject = SalesforceObject | DynamicsObject | HubspotObject | PipedriveObject;

type HubspotCompanyObject = Extract<HubspotObject, 'Company'>;
type PipedriveCompanyObject = Extract<PipedriveObject, 'organization'>;
type SalesforceCompanyObject = Extract<SalesforceObject, 'Account'>;
type DynamicsCompanyObject = Extract<DynamicsObject, 'accounts'>;

type PipedriveLeadObject = Extract<PipedriveObject, 'lead'>;
type SalesforceLeadObject = Extract<SalesforceObject, 'Lead'>;
type DynamicsLeadObject = Extract<DynamicsObject, 'leads'>;

type HubspotContactObject = Extract<HubspotObject, 'Contact'>;
type PipedriveContactObject = Extract<PipedriveObject, 'person'>;
type SalesforceContactObject = Extract<SalesforceObject, 'Contact'>;
type DynamicsContactObject = Extract<DynamicsObject, 'contacts'>;

export type CrmCompanyObject =
    | HubspotCompanyObject
    | PipedriveCompanyObject
    | SalesforceCompanyObject
    | DynamicsCompanyObject;

// type duplicate + this type is outdated,
// todo: remove this type usage in Triggers, use CRMPropertiesMap
export type CRMObjectFields = {
    fields: {
        created_by_vainu: boolean;
        label: string;
        mandatory: boolean;
        max_length: null;
        options: [];
        options_mutable: boolean;
        optionset_name: null;
        read_only: boolean;
        type: string;
        vainu_type: string;
        value: string;
    }[];
    required_fields: { value: string; label: string }[];
};

// todo: remove when the new schema mappings migration is done
export interface FieldMapping<T> {
    target_field: string;
    source_field: string;
    source_field_type: string;
    target_object: T;
    source_object: VainuObject;
    priorityIndex?: number;
    options?: {
        format?: string;
        tag_ids?: unknown[];
        tag_type_names?: string[];
    };
    toBeCreated?: string;
    update_type?: DataUpdateType;
}

export interface ExtraSettings {
    create_opportunity: number;
    objects_to_create: string[];
    pipeline?: string;
    pipeline_stage?: string;
    industry_code_format?: number;
    business_id_format?: number;
}

export interface IntegrationSetting<T> {
    id?: string;
    account?: string;
    integration_target: CRMNameAPIResponse;
    name: string;
    field_mapping: FieldMapping<T>[];
    active: boolean;
    extra_settings: ExtraSettings;
}

export interface IntegrationOption {
    use_custom_mapping: boolean;
    target: string;
    match_type: string;
    match_key: string;
    match_field_setting_dict: Object;
    last_full_sync: string | null;
    last_sync_timestamp: Date;
    extra_options?: unknown;
    company_url: string;
    auto_syncing_user_id?: number;
    auto_syncing_enabled: boolean;
    enrich_enabled: boolean;
    enrich_type: string;
    full_import_done: boolean;
}

export interface ProspectCountByCountryProps {
    count: number;
    _id: string;
}
export type IntegrationMatchCountryParams = {
    target: CrmName;
    enrich_enabled?: boolean;
    auto_syncing_enabled?: boolean;
};

type CreateSingleCRMPropertyParams = {
    crm: CrmName;
    propertyName: string;
    propertyType: VainuFieldType;
    crmObject: CRMObject;
};

type CreateSingleCRMPropertyResponse = {
    ok: boolean;
    message?: string;
    error?: string;
    data: {
        field_key: string;
        field_label: string;
    };
};

type CreateFullExportParams = {
    target: CrmName;
    enrich_type: 'automatic';
    restart?: boolean;
};

export interface MatchCompanyParams {
    target_fk: string;
    crm_name: string;
    prospect_business_id: string;
    no_update?: boolean;
}

export interface StartCRMImportResponse {
    job_id: string;
}

export type DBType = 'business_id' | 'domain';

export interface InitBulkExportParams {
    target: CrmName;
    target_group_id: string;
    resync_prospect_exports?: boolean;
}

export interface ConfirmBulkExportParams {
    jobId: string;
    target: CrmName;
    prospect_exports?: Array<{ id: string; crm_owner_id?: string; tag?: string; tags?: string[] }>;
    name: string;
    default_tags?: string[];
    objects_to_create?: CRMObject[];
    resync_prospect_exports?: boolean;
    meta_data?: {
        [key: string]: unknown;
    };
}

export interface ContactPayload {
    business_id: string;
    contact_uid: string;
}

export interface ConfirmNewMassExportParams {
    target_group_id?: string;
    target_group_order?: string;
    target: CrmName;
    contacts: ContactPayload[] | null;
    default_tags?: string[];
    assign_info: {
        owner_ids: (string | number)[];
        replace_company_owner: boolean;
        attached_resources_to_company_owner: boolean;
    };
    objects_to_create: CRMObject[];
    meta_data?: {
        [key: string]: string | string[];
    };
    // todo: should not be optional
    database?: Database;
    contact_filter?: Operation;
    include_companies_that_dont_match_contact_filter: boolean;
}

export type DynamicsInstance = {
    is_active: boolean;
    link: string;
    name: string;
    dynamics_url: string;
};

// legacy response type (when filter_legacy parameter equals `false`)
// todo: remove it when the Mapping 2.0 is done
export interface CRMAccountSettingsLegacy {
    auto_syncing_enabled: boolean;
    pipedrive_lead_scope_exists: boolean;
    last_sync: string;
    objects: {
        [CRMObjectKey in CRMObject]: { fields: CRMProperty[] };
    };
    // ...other legacy properties
}

/* Mappings types */
export type VainuFieldType =
    | 'MonetaryField'
    | 'StringField'
    | 'TagsField'
    | 'UrlField'
    | 'ListField'
    | 'SetField'
    | 'SingleSelect'
    | 'IntField'
    | 'BooleanField'
    | 'DateTimeField'
    | 'StringFieldLong'
    | 'FloatField'
    | 'PhoneField'
    | 'Text'; // 'Text' is used for custom inputs

// BE adds 'vainu_type' property to every CRM property
export type CRMPropertyVainuType =
    | 'String'
    | 'Monetary'
    | 'Float'
    | 'Int'
    | 'DateTime'
    | 'Date'
    | 'Boolean'
    | 'SingleSelect'
    | 'MultiSelect'
    | 'Url'
    | 'Unknown';

type VainuFieldPreview = string | string[] | null;

interface MappingSource {
    id: string;
    options: {
        label?: string;
        format?: string;
        tag_ids?: number[]; // selected values for Technologies datapoint
        tag_type_names?: number[];
    };
    source_field: string;
    source_field_type: string;
    source_object: VainuObject;
}

interface EnhancedMappingSource extends MappingSource {
    preview: VainuFieldPreview;
}

export type Mapping = {
    id: string;
    last_modified: string;
    target_field: string;
    target_object: CRMObject;
    update_type: DataUpdateType;
    sources: MappingSource[];
};

export type EnhancedMapping = Omit<Mapping, 'sources'> & {
    mandatory: boolean;
    createdByVainu: boolean;
    crmPropertyLabel: string;
    crmPropertyType: string;
    sources: EnhancedMappingSource[];
};

export type MappingsMap = {
    [CRMObjectKey in CRMObject]: Mapping[];
};

export type CRMPropertiesMap = {
    [CRMObjectKey in CRMObject]: { fields: CRMProperty[] };
};

export interface CRMIntegrationSettings {
    active: boolean;
    id: string;
    account: string;
    integration_target: CRMNameAPIResponse;
    field_mappings: MappingsMap;
}

// some CRM Picklist properties have CRM-defined values we need to show in the datapoints dropdown
export interface CRMPicklistValue {
    label: string;
    value: string;
}

// these properties are required by BE when we create a new mapping using CRM-defined Picklist values
export interface EnhancedCRMPicklistValue extends CRMPicklistValue {
    type: 'Picklist';
    object: 'Picklist';
}

export interface CRMProperty {
    mandatory: boolean;
    label: string;
    max_length: null; // Ask BE devs
    options: CRMPicklistValue[];
    type: string;
    vainu_type: CRMPropertyVainuType;
    value: string;
    created_by_vainu: boolean;
}

export interface CRMPropertiesMenuOption extends CRMProperty {
    disabled: boolean;
}

export interface CRMAccountSettings {
    ok: boolean;
    match_type: DBType;
    auto_syncing_enabled: boolean;
    last_sync: string;
    crm_objects: CRMPropertiesMap;
}

export type FieldToCRMPropertyMap = Record<string, CRMProperty>;

export type AllCRMPropertiesMap = {
    [CRMObjectKey in CRMObject]: FieldToCRMPropertyMap;
};

export interface VainuDatapointOption {
    description: string | null;
    label: string;
    value: string;
    examples: { label: string; permission: string | null }[];
}

export interface VainuDatapoint {
    allowed_for_user: boolean;
    field: string; // field, label, value properties are duplicates
    label: string; // todo: need to remove 2 of 3 of them
    value: string;
    description: string | null;
    header: string;
    object: VainuObject;
    example: string | string[] | null;
    type: VainuFieldType;
    required_permission: string | null;
    groups: Array<'global' | 'business_id'>;
    default_option: { format: string } | null;
    field_options: {
        format: { oneOf: string[] };
    } | null;
    options_p2:
        | {
              option_key: string;
              select_type: VainuFieldType;
              options: VainuDatapointOption[];
          }[]
        | null;
}

// real BE response has 1 more element in the tuple, but it's not used of the FE side
// it's the number of times the tech exists in companies
export type Technology = [number, string, string];

export type TechnologiesTree = {
    [category: string]: { id: number; name: string }[];
};

export type TechIdToCategoryMap = Record<number, string>;
