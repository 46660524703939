function weightedRandom(max: number, bellFactor: number) {
    let num = 0;
    for (let i = 0; i < bellFactor; i++) {
        num += Math.random() * (max / bellFactor);
    }
    return num;
}

export function getRandomData(count: number) {
    const numbers = Array.apply(null, new Array(count)).map(function (x, i) {
        return [i, 0];
    });

    for (let i = 0; i < 500; i++) {
        let num = weightedRandom(count, 4);

        num = Math.floor(num);
        numbers[num][1]++;
    }

    return numbers;
}

export const HISTOGRAM_DATA = getRandomData(50).map((item) => ({ label: item[0].toString(), value: item[1] }));
