import React from 'react';

import { Alert, SxProps } from '@mui/material';

enum StateColor {
    active = 'success',
    warning = 'warning',
    danger = 'error',
}
export type StatusIndicatorClass = keyof typeof StateColor;
export type StatusIndicatorProps = {
    statusClass: StatusIndicatorClass;
    status: string;
    showActive?: boolean;
    sx?: SxProps;
};

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({ statusClass, status, showActive = false, sx }) => {
    const showStatus = statusClass !== 'active' || showActive;
    const severity = StateColor[statusClass];

    return showStatus ? (
        <Alert
            severity={severity}
            sx={{ ...(statusClass === 'active' ? { backgroundColor: 'grey.50', color: 'text.primary' } : {}), ...sx }}
            icon={statusClass === 'active' ? false : undefined}
        >
            {status}
        </Alert>
    ) : null;
};

export default StatusIndicator;
