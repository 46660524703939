import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const OffClose = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C9.3471 22.0024 6.80218 20.9496 4.92629 19.0737C3.05041 17.1978 1.99761 14.6529 2 12V11.8C2.08178 7.79223 4.5478 4.22016 8.26636 2.72307C11.9849 1.22597 16.2381 2.0929 19.074 4.92601C21.9365 7.78609 22.7932 12.0893 21.2443 15.8276C19.6955 19.5659 16.0464 22.0024 12 22ZM12 13.41L14.59 16L16 14.59L13.41 12L16 9.41001L14.59 8.00001L12 10.59L9.41 8.00001L8 9.41001L10.59 12L8 14.59L9.41 16L12 13.411V13.41Z" />
    </SvgIcon>
);

export default OffClose;
