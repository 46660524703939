import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import decisionMakerDemo from 'src/images/decision-makers-demo.svg';
import { getErrorMessage } from 'utilities';

import { getAccountData } from 'api/usageData';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import { brandPalette } from 'design/theme/colors';
import { LocalStorage } from 'utilities/localStorage';

const StyledImage = styled('img')`
    height: 354px;
    margin-top: 16px;
    margin-bottom: 12px;
`;

export const SoftBlock = () => {
    const { showSnackbar } = useSnackbarContext();
    const axios = useAxiosContext();
    const { data: accountData } = useQuery({ queryKey: ['usage-account-data'], queryFn: () => getAccountData(axios) });

    const ownerName = accountData?.owner;
    const ownerEmail = accountData?.owner_email;
    const text = ownerEmail
        ? 'You tried to log in to our new platform which features contact data, similar companies and more, but you don’t have access at the moment. To gain access, please contact your Customer Success Manager.'
        : `You tried to log in to our new platform which features contact data, similar companies and more, but you don’t have access at the moment. To gain access, please contact our Customer Success team.`;

    const contactEmail = ownerEmail || 'customersuccess@vainu.io';

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(contactEmail);
            showSnackbar('The email has been copied to the clipboard');
        } catch (error) {
            showSnackbar(`Cannot copy the message to clipboard: ${getErrorMessage(error)}`, 'error');
        }
    };

    const handleRedirect = () => {
        LocalStorage.clear();
        window.location.href = 'https://app.vainu.io';
    };

    const customerSuccessTitle = ownerEmail ? 'Your Customer Success Manager' : '';
    const customerSuccessName = ownerEmail && ownerName ? ownerName : 'Our Customer Success team';

    return (
        <Dialog
            open
            maxWidth="lg"
            PaperProps={{
                sx: {
                    width: 600,
                    margin: '0px',
                    padding: '32px 24px',
                },
            }}
            onClose={() => {}}
        >
            <DialogContent sx={{ padding: '0px' }}>
                <Typography color="dark" variant="h4" sx={{ paddingBottom: '8px' }}>
                    You don’t have access to the new Vainu platform
                </Typography>
                <Typography color="subtle" variant="body2">
                    {text}
                </Typography>
                <StyledImage src={decisionMakerDemo} alt="decision-makers-demo" />
                <ContactCustomerSuccess title={customerSuccessTitle} name={customerSuccessName} email={contactEmail} />
            </DialogContent>
            <DialogActions sx={{ padding: '0px', marginTop: '24px' }}>
                <Button variant="flat" onClick={handleRedirect}>
                    Go to your platform
                </Button>
                <Button onClick={handleCopyClick} endIcon={<Icon type="Copy" />}>
                    Copy email
                </Button>
            </DialogActions>
        </Dialog>
    );
};
interface CustomerSuccessProps {
    title?: string;
    name: string;
    email: string;
}
export const ContactCustomerSuccess: React.FC<CustomerSuccessProps> = ({ title, name, email }) => {
    return (
        <ContactWrapper>
            {title ? (
                <Typography color="subtle" variant="body2">
                    {title}
                </Typography>
            ) : null}
            <Typography color="dark" variant="body2" weight="bold">
                {name}
            </Typography>
            <StyledEmailRow>
                <Icon type="Mail" sx={{ fontSize: 16, color: brandPalette.body }} />
                <Link href={`mailto:${email}`} sx={{ fontSize: 14, color: brandPalette.body }}>
                    {email}
                </Link>
            </StyledEmailRow>
        </ContactWrapper>
    );
};

const StyledEmailRow = styled('div')({
    display: 'flex',
    gap: 4,
    alignItems: 'center',
});

const ContactWrapper = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: 4,
    border: `1px solid ${palette.brandColors.subtleLight}`,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: '16px 12px',
}));
