import React, { useMemo, useState } from 'react';

import { Box, ClickAwayListener } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { bindHover, bindPopover, bindPopper, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import Popover from 'material-ui-popup-state/HoverPopover';

import { useCompany } from 'api/company';
import { Contact } from 'api/types/company';
import { ContactsUpgradeButton } from 'components/features/Employees';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';

import TriggerButton from '../TriggerSettings/TriggerButton';
import { CellProps } from './TableComponent';
import { TooltipRow } from './TriggerCellTooltip';

type ContactCellContact = Partial<Contact>;

const sortByTitle = (a: ContactCellContact, b: ContactCellContact) => (a?.title_cls || 0) - (b?.title_cls || 0);

function sortContact(contacts: Contact[], keyword: string) {
    let activeContacts = contacts?.filter((i) => !i.deleted);
    if (keyword) {
        activeContacts = activeContacts.filter(
            ({ first_name = '', last_name = '', title = '' }) =>
                `${first_name} ${last_name}`.toLowerCase().includes(keyword.toLowerCase()) ||
                title.toLowerCase().includes(keyword.toLowerCase()),
        );
    }
    if (activeContacts?.length) {
        return activeContacts.sort(sortByTitle);
    }
    return [];
}

const newEmptyContact: ContactCellContact = {
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    phone: '',
};

const ContactsCell: React.FC<CellProps> = ({ hasContactDataPermission, row, selectContact, openUpgradeDialog }) => {
    const [newContact, setNewContact] = useState<ContactCellContact>({} as Contact);
    const [showErrorSnackbarMessage, setShowErrorSnackbarMessage] = useState('');
    const [filterContact, setFilterContact] = useState('');
    const popupState = usePopupState({ variant: 'popover', popupId: 'company-popover' });
    const popperState = usePopupState({ variant: 'popper', popupId: 'select-contact-popper' });
    const { company } = row;

    const { data = { contacts: [] }, isLoading } = useCompany(
        company?.business_id || '',
        {
            contacts: true,
        },
        popperState.isOpen,
    );

    const { contacts } = data;

    const editContact = (field: string, v: string) => {
        setNewContact({ ...newContact, [field]: v });
    };

    const sortedContacts = useMemo(() => sortContact(contacts || [], filterContact), [contacts, filterContact]);

    const { first_name = '', last_name = '', title = '', phone = '', email = '' } = row.contact || {};
    const fullName = first_name && last_name ? `${first_name} ${last_name}` : '';
    const useHover = popperState.isOpen || !fullName ? {} : bindHover(popupState);

    const setContact = (_contact: ContactCellContact) => {
        selectContact?.(row.id, _contact);
    };
    const onSave = () => {
        if (!isEmpty(newContact)) {
            setContact(newContact);
        }
    };

    return (
        <StyledColumn>
            {hasContactDataPermission ? (
                <TriggerButton
                    startIcon={<Icon type={fullName ? 'IdCard' : 'UserPlus'} />}
                    sx={{ backgroundColor: 'grey.50', color: 'text.primary' }}
                    {...useHover}
                    {...bindToggle(popperState)}
                >
                    {fullName || 'Add contact'}
                </TriggerButton>
            ) : (
                <ContactsUpgradeButton onClick={openUpgradeDialog} />
            )}
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <StyledPaper>
                    <Grid container direction="column" wrap="nowrap">
                        {fullName ? (
                            <Typography weight="semibold" sx={{ marginBottom: 1 }}>
                                {fullName}
                            </Typography>
                        ) : (
                            <Skeleton variant="text" />
                        )}

                        <TooltipRow icon="IdCard" isLoading={isLoading}>
                            {title}
                        </TooltipRow>
                        <TooltipRow icon="Mail" isLoading={isLoading}>
                            {email}
                        </TooltipRow>
                        <TooltipRow icon="Phone" isLoading={isLoading}>
                            {phone}
                        </TooltipRow>
                        {/* <TooltipRow icon="LinkedIn">N/A</TooltipRow> */}
                    </Grid>
                </StyledPaper>
            </Popover>
            <Popper
                {...bindPopper(popperState)}
                placement="top-start"
                transition
                popperOptions={{
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: true,
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 4],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener
                        mouseEvent="onMouseUp"
                        onClickAway={() => {
                            popperState.close();
                            onSave();
                            setNewContact({} as Contact);
                        }}
                    >
                        <Fade {...TransitionProps} timeout={350}>
                            <Card>
                                <CardContent sx={{ width: 500, height: 300, display: 'flex', flexDirection: 'column' }}>
                                    {isEmpty(newContact) && (sortedContacts.length > 4 || filterContact) ? (
                                        <Grid container justifyContent="flex-end">
                                            <TextField
                                                icon="Search"
                                                placeholder="Search..."
                                                compact
                                                onChange={(e) => setFilterContact(e.target.value)}
                                                value={filterContact}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                    ) : null}
                                    {!isEmpty(newContact) ? (
                                        <CreateContact editContact={editContact} />
                                    ) : (
                                        <Box sx={{ flex: 1, overflowY: 'auto' }}>
                                            <Box
                                                onClick={() => setNewContact(newEmptyContact)}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                    paddingY: 2,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <Icon type="UserCircle" />
                                                <Box sx={{ flex: 1 }}>
                                                    <Typography variant="body2" weight="semibold">
                                                        Add a new contact...
                                                    </Typography>
                                                </Box>
                                                <Icon type="ChevronRight" color="grey.500" />
                                            </Box>

                                            {sortedContacts.map((contact) => {
                                                const {
                                                    first_name: contactFirstName = '',
                                                    last_name: contactLastName = '',
                                                    title: contactTitle = '',
                                                    phone: contactPhone = '',
                                                    email: contactEmail = '',
                                                } = contact;
                                                const contactFullName =
                                                    contactFirstName && contactLastName
                                                        ? `${contactFirstName} ${contactLastName}`
                                                        : '';
                                                if (!contactFullName) {
                                                    return null;
                                                }
                                                return (
                                                    <Box
                                                        key={contactFullName}
                                                        onClick={() => {
                                                            setNewContact({} as Contact);
                                                            setContact(contact);
                                                            popperState.close();
                                                        }}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                            paddingY: 2,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <Icon type="UserCircle" />
                                                        <Box sx={{ flex: 1 }}>
                                                            <Typography variant="body2" weight="semibold">
                                                                {contactFullName}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 1,
                                                                }}
                                                            >
                                                                {contactTitle ? (
                                                                    <Typography variant="tiny" color="subtle">
                                                                        {contactTitle}
                                                                    </Typography>
                                                                ) : null}
                                                                {contactPhone ? (
                                                                    <Icon
                                                                        type="Phone"
                                                                        color="text.subtle"
                                                                        sx={{ width: 16, height: 16 }}
                                                                    />
                                                                ) : null}
                                                                {contactEmail ? (
                                                                    <Icon
                                                                        type="Mail"
                                                                        color="text.subtle"
                                                                        sx={{ width: 16, height: 16 }}
                                                                    />
                                                                ) : null}
                                                            </Box>
                                                        </Box>
                                                        <Icon type="ChevronRight" color="grey.500" />
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    )}
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'flex-end', padding: 2, paddingTop: 0 }}>
                                    <Button
                                        variant="tertiary"
                                        onClick={() => {
                                            popperState.close();
                                            setNewContact({} as Contact);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            popperState.close();
                                            onSave();
                                            setNewContact({} as Contact);
                                        }}
                                    >
                                        Save
                                    </Button>
                                </CardActions>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    open={!!showErrorSnackbarMessage}
                                    autoHideDuration={3000}
                                    onClose={() => setShowErrorSnackbarMessage('')}
                                    message={<span>{showErrorSnackbarMessage}</span>}
                                />
                            </Card>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </StyledColumn>
    );
};

export default ContactsCell;
interface CreateContactProps {
    editContact: (field: string, value: string) => void;
}

const CreateContact: React.FC<CreateContactProps> = ({ editContact }) => {
    return (
        <Box>
            <Typography weight="semibold" sx={{ marginBottom: 1 }}>
                Add a new contact
            </Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}>
                <TextField onBlur={(e) => editContact('first_name', e.target.value)} label="First name" autoFocus />
                <TextField onBlur={(e) => editContact('last_name', e.target.value)} label="Last name" />
                <TextField onBlur={(e) => editContact('title', e.target.value)} label="Title" />
                <TextField onBlur={(e) => editContact('email', e.target.value)} label="Email" />
                <TextField onBlur={(e) => editContact('phone', e.target.value)} label="Phone" />
            </Box>
        </Box>
    );
};

const StyledColumn = styled('div')`
    max-width: 300px;
`;

const StyledPaper = styled(Paper)`
    width: 243px;
    border-radius: 8px;
    padding: 16px;
`;
