import type { CompanyFact } from 'api/types/company';
import Frame from 'components/tokens/Frame';
import Tag from 'components/tokens/Tag';

export type CompanyMembershipsProps = {
    facts: CompanyFact[];
};

export const CompanyMemberships: React.VFC<CompanyMembershipsProps> = ({ facts }) => {
    const memberships = facts.filter((fact) => fact.type === 'membership');
    return (
        <Frame title="Memberships">
            {memberships.map(({ value }) => (
                <Tag key={value} sx={{ marginRight: '6px', marginBottom: 1 }} size="big" label={value} />
            ))}
        </Frame>
    );
};

export default CompanyMemberships;
