import { AxiosInstance } from 'axios';
import { isArray } from 'lodash';

import { Operation } from 'api/types/FilterOperators';
import { Database } from 'contexts/ListsContext';
import { SortId } from 'contexts/SortCompaniesContext/SortCompaniesContext';
import { CountryDatabase } from 'contexts/types/databases';
import { DomainDataResultAPIResponse, OrganizationResultAPIResponse } from 'contexts/types/FilterCompanies';

import { Contact } from './types/Organization';
import { UserAsyncProcess } from './types/UserAsyncProcess';

export interface FilterPayloadCompanies {
    query?: Operation | string;
    use_filters_v2: 'true' | 'false';
    database: Database;
    limit: number;
    offset: number;
    order: SortId;
    fields: readonly string[];
    list?: string;
    unwind_subdocument?: string;
    unwind_subdocument_query?: Operation | string;
}

export interface FilterPayloadCount {
    query?: Operation | string;
    use_filters_v2: 'true' | 'false';
    database: Database;
    count: true;
    limit_for_count?: number;
    list?: string;
}

export interface CachedCountPayload {
    query?: Operation | string;
    database: Database;
    recount?: boolean;
    unwind_subdocument?: 'contacts';
    unwind_subdocument_query?: Operation | string;
}

export type FilterCompaniesResponse = DomainDataResultAPIResponse[] | OrganizationResultAPIResponse[];

export type CompaniesCountResponse = {
    count: number;
    status: number;
};

export type CachedCompanyCountResponse = {
    count: number | null;
    status: 'process' | 'ready' | 'error' | 'scheduled';
    time: string;
    duration: number | null;
    rate_of_change: number | null;
    eta_utc: string | null;
};

const validateStatus = (status: number) => status !== 429 && status < 500;

const getV2FilterAPIPath = (database: Database) => {
    if (database === 'DOMAIN_DATA_BASIC') {
        return '/api/v2/domains/';
    }
    return '/api/v2/organizations/';
};

const getFilterAPIPath = (database: Database) => {
    if (database === 'DOMAIN_DATA_BASIC') {
        return '/api/v3/domains/';
    }
    return '/api/v3/organizations/';
};

export const filterCompanies = async (
    axios: AxiosInstance,
    payload: FilterPayloadCompanies,
    signal?: AbortSignal,
): Promise<FilterCompaniesResponse> => {
    // legacy lists can't be fetched with v3 list api
    // TODO: should be payload.use_filters_v2 === 'true'
    if (payload.use_filters_v2 === 'false') {
        const { data } = await axios.post(getV2FilterAPIPath(payload.database), payload, { signal, validateStatus });
        return data.results ?? [];
    }

    const { data } = await axios.post(getFilterAPIPath(payload.database), payload, { signal, validateStatus });

    return data ?? [];
};

export type FilterPayloadContacts = Omit<FilterPayloadCompanies, 'database'> & { database: CountryDatabase };
export const getFilteredContacts = async (
    axios: AxiosInstance,
    payload: FilterPayloadContacts,
    signal?: AbortSignal,
): Promise<Contact[]> => {
    const { data = [] } = await axios.post<OrganizationResultAPIResponse[]>(
        getFilterAPIPath(payload.database),
        payload,
        {
            signal,
            validateStatus,
        },
    );

    return data.reduce<Contact[]>((contacts, company) => {
        if (isArray(company.contacts)) {
            return [...contacts, company.contacts[0]];
        }
        return contacts;
    }, []);
};

export const filterCompaniesCount = async (
    axios: AxiosInstance,
    payload: FilterPayloadCount,
    signal?: AbortSignal,
): Promise<CompaniesCountResponse> => {
    const { data } = await axios.post(getFilterAPIPath(payload.database), payload, { signal, validateStatus });
    return data;
};

export const fetchCachedCompanyCount = async (
    axios: AxiosInstance,
    payload: CachedCountPayload,
    signal?: AbortSignal,
): Promise<CachedCompanyCountResponse> => {
    const { data } = await axios.post(`${getFilterAPIPath(payload.database)}count/`, payload, {
        signal,
    });

    return data;
};

export interface InsightsPayload {
    query?: Operation | string;
    list?: string;
    use_filters_v2: 'true' | 'false';
    database: Database;
    order?: number | null;
    limit?: number;
    offset?: number;
    query_uid: string;
    query_hash?: string;
    stats: {
        id: string;
        '?AGGREGATE': (
            | {
                  '?SELECT': {
                      path: 'organization_match' | 'domain_match';
                  };
              }
            | {
                  '?FILTER_SUBDOCUMENTS': {
                      path: string;
                      query: Object;
                  };
              }
            | {
                  '?GROUP': {
                      path: string;
                      buckets?: {
                          label?: string;
                          bucket_count_range?: number;
                          [key: string]: string | number | undefined;
                      }[];
                      bucket_count_range?: number;
                  };
              }
        )[];
        label?: string;
        meta?: Object;
    }[];
}

export interface Bucket {
    count: number;
    label?: string;
    value?:
        | string
        | { currency_code: string | null }
        | { city: string | null; country: string | null }
        | { name: string; logo_url: string | null; 'types.0': string }
        | {
              'code_2_level_descriptions.en': string;
              code_2_level: string;
          }
        | Record<string, boolean>;
    sum?: number | null;
    meta?: Object;
    [k: string]: unknown;
}

export interface Stats {
    documents_scanned: number;
    id: string;
    label: string | null;
    meta: Object | null;
    result: {
        average: number | null;
        buckets: Bucket[];
    }[];
}

export const fetchStats = async (
    axios: AxiosInstance,
    payload: InsightsPayload,
    signal?: AbortSignal,
): Promise<Stats[]> => {
    const { data } = await axios.post(`${getV2FilterAPIPath(payload.database)}stats/`, payload, {
        signal,
        validateStatus,
    });
    return data;
};

export const generateStatsAsync = async (
    axios: AxiosInstance,
    payload: InsightsPayload,
    signal?: AbortSignal,
): Promise<UserAsyncProcess> => {
    const { data } = await axios.post(`${getV2FilterAPIPath(payload.database)}stats/async/`, payload, {
        signal,
        validateStatus,
    });
    return data;
};
