import type { Theme } from '@mui/material';

import { ChartDataPoint } from '..';

export const gradientOffset = (data: ChartDataPoint[]) => {
    const min = data.reduce((acc, curr) => (curr.value === undefined ? acc : Math.min(acc, curr.value)), 0);
    const max = data.reduce((acc, curr) => (curr.value === undefined ? acc : Math.max(acc, curr.value)), 0);
    return max / (max - min);
};

export type AxisDomain = [number | 'auto', number | 'auto'];

export type AxisConfig = {
    color: string;
    fillColor: string;
    gradientDef: JSX.Element;
    axisDomain: AxisDomain;
};

export function formatAxis(data: ChartDataPoint[], variant: 'default' | 'posneg', theme: Theme): AxisConfig {
    const posColor = variant === 'posneg' ? theme.palette.brandColors.green : theme.palette.chart.default;
    const negColor = variant === 'posneg' ? theme.palette.brandColors.magenta : theme.palette.chart.default;

    const allPositive = !data.some((point) => point.value !== undefined && point.value < 0);
    const allNegative = !data.some((point) => point.value !== undefined && point.value > 0);

    if (allPositive || allNegative) {
        const color = allPositive ? posColor : negColor;
        const axisDomain: AxisDomain = allPositive ? [0, 'auto'] : ['auto', 0];
        return {
            color,
            fillColor: 'url(#areachart-gradient-pos)',
            axisDomain,
            gradientDef: (
                <defs>
                    <linearGradient id="areachart-gradient-pos" x1="0" y1="0" x2="0" y2="1">
                        <stop offset={0} stopColor={color} stopOpacity={0.8} />
                        <stop offset={1} stopColor={color} stopOpacity={0.4} />
                    </linearGradient>
                </defs>
            ),
        };
    }

    const offset = gradientOffset(data);
    return {
        color: posColor,
        fillColor: 'url(#areachart-gradient-posneg)',
        axisDomain: ['auto', 'auto'],
        gradientDef: (
            <defs>
                <linearGradient id="areachart-gradient-posneg" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={0} stopColor={posColor} stopOpacity={0.8} />
                    <stop offset={offset} stopColor={posColor} stopOpacity={0.4} />
                    <stop offset={offset} stopColor={negColor} stopOpacity={0.8} />
                    <stop offset={1} stopColor={negColor} stopOpacity={0.4} />
                </linearGradient>
            </defs>
        ),
    };
}
