import React, { useCallback, useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { getList } from 'api/lists';
import { createTrigger, updateTrigger } from 'api/triggers';
import { Trigger } from 'api/types/Trigger';
import { MainPath } from 'components/beta/Platform/Router/routePaths';
import EditableText from 'components/templates/EditableText';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Tag from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useListsContext } from 'contexts/ListsContext';
import { ActionType, useTrigger } from 'contexts/TriggerContext';
import { getObjectKeys } from 'utilities/objectUtils';
import { isValidForSave } from 'utilities/triggers';

import TriggerFilters from '../TriggerFilters/TriggerFilters';
import TriggerPreview from '../TriggerPreview';
import SelectDeliveryTimePopper from './SelectDeliveryTimePopper';
import SelectDestinationPopper from './SelectDestinationPopper';
import SelectLimitPopper from './SelectLimitPopper';
import SelectSourcePopper from './SelectSourcePopper';
import { useInitNewTrigger } from './useInitNewTrigger';

interface Props {
    onboardingActive: boolean;
    scrollRef?: React.RefObject<HTMLElement>;
}

const TriggerSettingsForm: React.FC<Props> = ({ onboardingActive, scrollRef }) => {
    const { database } = useListsContext();
    const axios = useAxiosContext();
    const [state, dispatch, { setActivePopup }] = useTrigger();
    const navigate = useNavigate();

    const [showHint, setHint] = useState(true);
    const queryClient = useQueryClient();
    const [selectedRowIds, setSelectedRowIds] = useState<Record<string, boolean>>({});
    const { name, source = '', selectedTrigger, editedTrigger, isActive, isValid, id, errors } = state;
    const sourceField = 'Select list';

    const { data } = useQuery({
        queryKey: ['get-source', source],
        queryFn: () => getList(axios, source || ''),
        enabled: !!source,
        refetchOnWindowFocus: false,
    });

    const selectedSource = data?.data;

    const sourceHadLeadFilters: boolean =
        !!selectedSource?.query?.includes('leads__tags__rel') ||
        !!selectedSource?.query?.includes('leads__content__or__leads__title');

    const updateTriggerInCache = useCallback(
        (data: Trigger) => {
            queryClient.setQueryData<Trigger[]>(['triggers', database], (oldData) => {
                if (!oldData) {
                    return [data];
                }
                if (!oldData.some((trigger) => trigger.id === data.id)) {
                    return [...oldData, data];
                }
                return oldData.map((trigger) => {
                    if (trigger.id === data.id) {
                        return data;
                    }
                    return trigger;
                });
            });
        },
        [database, queryClient],
    );

    const saveTrigger = useCallback(
        async (triggerData: Partial<Trigger>) => {
            const { id: triggerID, ..._data } = triggerData;
            _data.notify_type = 'workflow_settings';
            let data: Trigger;
            if (triggerID) {
                const response = await updateTrigger(axios, database, triggerID, _data);
                data = response.data;
            } else {
                const response = await createTrigger(axios, database, _data);
                data = response.data;
                navigate(`/triggers/${data.id}`);
            }
            dispatch({ type: ActionType.SELECT_TRIGGER, trigger: data });
            updateTriggerInCache(data);
        },
        [updateTriggerInCache, axios, database, navigate, dispatch],
    );

    const activateTrigger = useCallback(async () => {
        if (!isValid) {
            let popupID;
            if (errors.includes('destinations')) {
                popupID = 'select-destination-popper';
            } else if (errors.includes('notify')) {
                popupID = 'select-delivery-time-popper';
            } else if (errors.includes('daily_limit')) {
                popupID = 'select-limit-popper';
            }
            if (popupID) {
                setActivePopup(popupID);
            }
            return;
        }
        let triggerID = editedTrigger?.id;
        if (!Object.keys(editedTrigger).length && selectedTrigger.id) {
            triggerID = selectedTrigger.id;
        }
        if (!triggerID) {
            await saveTrigger({ ...editedTrigger, status: 'active' });
            return;
        }
        const { data: updatedTrigger } = await updateTrigger(axios, database, triggerID, { status: 'active' });
        dispatch({ type: ActionType.SELECT_TRIGGER, trigger: updatedTrigger });
        updateTriggerInCache(updatedTrigger);
    }, [
        axios,
        database,
        dispatch,
        editedTrigger,
        errors,
        isValid,
        saveTrigger,
        selectedTrigger.id,
        setActivePopup,
        updateTriggerInCache,
    ]);

    const pauseTrigger = async () => {
        if (!Object.keys(editedTrigger).length && selectedTrigger.id) {
            const { data: updatedTrigger } = await updateTrigger(axios, database, selectedTrigger.id, {
                status: 'disabled',
            });
            dispatch({ type: ActionType.SELECT_TRIGGER, trigger: updatedTrigger });
            updateTriggerInCache(updatedTrigger);
            return;
        }
        const { id: triggerID, ..._data } = editedTrigger;
        _data.status = 'disabled';
        const { data: updatedTrigger } = await updateTrigger(axios, database, triggerID, _data);
        dispatch({ type: ActionType.SELECT_TRIGGER, trigger: updatedTrigger });
        updateTriggerInCache(updatedTrigger);
    };

    const showPauseTrigger = () => {
        const triggerEdited = Object.keys(editedTrigger).length;
        const editedTriggerEqualsSavedOne = isEqual(editedTrigger, selectedTrigger);
        return isActive && isValid && id && (!triggerEdited || (triggerEdited && editedTriggerEqualsSavedOne));
    };

    useEffect(() => {
        if (isValidForSave(editedTrigger)) {
            if (editedTrigger.id) {
                const diff = getObjectKeys(editedTrigger).reduce((acc, key) => {
                    if (!isEqual(editedTrigger[key], selectedTrigger[key])) {
                        return { ...acc, [key]: editedTrigger[key] };
                    }
                    return acc;
                }, {});

                if (Object.keys(diff).length) {
                    saveTrigger({ id: editedTrigger.id, ...diff });
                }
            } else {
                saveTrigger(editedTrigger);
            }
        }
    }, [editedTrigger, saveTrigger, selectedTrigger]);

    const initNewTrigger = useInitNewTrigger();

    useEffect(() => {
        initNewTrigger();
    }, [initNewTrigger]);

    return (
        <Box>
            <Box
                sx={{
                    height: 40,
                    marginBottom: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <EditableText
                    text={typeof name === 'string' ? name : 'Untitled trigger'}
                    submit={(newName: string) => dispatch({ type: ActionType.SET_NAME, name: newName })}
                    endAdornment={
                        <Tooltip title="Edit name" placement="top">
                            <Button
                                variant="flat"
                                size="small"
                                startIcon={<Icon type="Edit" fontSize="mini" sx={{ color: 'text.strong' }} />}
                            />
                        </Tooltip>
                    }
                    fontSize="32px"
                    fontWeight={900}
                />

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {showPauseTrigger() ? (
                        <Button
                            variant="secondary"
                            size="small"
                            startIcon={<Icon type="PauseCircleOutline" sx={{ width: 16, height: 16 }} />}
                            onClick={() => pauseTrigger()}
                        >
                            Pause
                        </Button>
                    ) : (
                        <Button
                            disabled={!isValid}
                            variant="primary"
                            size="small"
                            startIcon={<Icon type="PlayCircleOutline" sx={{ width: 16, height: 16 }} />}
                            onClick={() => activateTrigger()}
                        >
                            Activate
                        </Button>
                    )}
                </Box>
            </Box>

            <StyledFiltersContainer>
                <StyledFilterRow>
                    <StyledFilterTitle>
                        <Typography variant="small">List:</Typography>
                    </StyledFilterTitle>
                    <Grid item>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <SelectSourcePopper
                                hasValue={!!selectedSource}
                                sourceValue={selectedSource?.name || sourceField}
                            />
                            {selectedSource?.id && (
                                <Button
                                    size="small"
                                    variant="flat"
                                    startIcon={<Icon type="CircleRight" />}
                                    onClick={() => navigate(`/${MainPath.Profiles}/${selectedSource.id}`)}
                                >
                                    Go to list
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </StyledFilterRow>
                <StyledFilterRow>
                    <StyledFilterTitle>
                        <Typography variant="small">Event:</Typography>
                    </StyledFilterTitle>
                    <Grid item>
                        <TriggerFilters sourceName={selectedSource?.name} />
                    </Grid>
                </StyledFilterRow>
                <StyledFilterRow>
                    <StyledFilterTitle>
                        <Typography variant="small">Delivery:</Typography>
                    </StyledFilterTitle>
                    <div className="chip-button">
                        <SelectDestinationPopper />
                    </div>
                    <div className="chip-button">
                        <SelectDeliveryTimePopper />
                    </div>
                    <div>
                        <SelectLimitPopper />
                    </div>
                </StyledFilterRow>
            </StyledFiltersContainer>
            {showHint && errors?.length ? (
                <Grid container sx={{ marginY: 3 }} alignItems="center">
                    <Box
                        sx={{
                            backgroundColor: 'warning.main',
                            borderRadius: '50%',
                            width: '8px',
                            height: '8px',
                            marginRight: 0.5,
                        }}
                    />
                    <Typography variant="tiny" color="subtle" weight="semibold">
                        Paused. To send your triggers, tell us the
                    </Typography>
                    {!name ? <Tag label={renderHint('name')} sx={{ marginLeft: 1 }} /> : null}
                    {errors.map((errorStr) => (
                        <Tag key={errorStr} label={renderHint(errorStr)} sx={{ marginLeft: 1 }} />
                    ))}
                    <Typography
                        variant="tiny"
                        color="subtle"
                        sx={{ fontStyle: 'italic', cursor: 'pointer', marginLeft: 1 }}
                        onClick={() => setHint(false)}
                    >
                        Dismiss
                    </Typography>
                </Grid>
            ) : null}

            {sourceHadLeadFilters ? (
                <Grid container sx={{ marginTop: '24px' }} alignItems="center">
                    <StyledCircle color="#DF2664" />
                    <StyledHintText>
                        Source list contains event filtering, those filters will be discarded
                    </StyledHintText>
                </Grid>
            ) : null}

            <TriggerPreview
                selectedRowIds={selectedRowIds}
                setSelectedRowIds={setSelectedRowIds}
                onboardingActive={onboardingActive}
                scrollRef={scrollRef}
                activateTrigger={activateTrigger}
            />
        </Box>
    );
};

function renderHint(str: string) {
    switch (str) {
        case 'name':
            return 'Name';
        case 'notify':
            return 'Schedule';
        case 'queries':
            return 'Filter';
        case 'tracking_logic_or_queries':
            return 'Filter';
        case 'tracking_logic_and_queries':
            return 'Cannot have both change tracking and filter';
        case 'source':
            return 'Source';
        case 'destinations':
            return 'Destination';
        case 'week_days':
            return 'Schedule';
        case 'daily_limit':
            return 'Limit';
        default:
            console.error('unknown type', str);
            return '';
    }
}

export default TriggerSettingsForm;

const StyledCircle = styled('span')`
    height: 8px;
    width: 8px;
    border-radius: 30px;
    margin-right: 8px;
`;

const StyledHintText = styled('div')`
    padding-left: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
`;

const StyledFiltersContainer = styled('div')`
    padding: 8px 0;
`;
const StyledFilterRow = styled('div')`
    display: flex;
    align-items: center;
    margin: 0 0 12px;
    .chip-button {
        margin-right: 6px;
    }
`;

const StyledFilterTitle = styled('div')`
    margin-right: 8px;
`;
