import { pluralize } from 'utilities';

import { Operation } from 'api/types/FilterOperators';
import BarTile from 'components/features/CRMInsights/Tiles/BarTile';
import { DEFAULT_DATA, defaultMinWidth } from 'components/features/CRMInsights/utils';

import { TileProps } from '../NewVehicles';

const MakeTile: React.FC<TileProps> = ({ data, valueRange, valueField, onDetailsClick }) => {
    const makeData = data?.find((row) => row.id === 'make');

    return (
        <BarTile
            data-testid="make-tile"
            title="Make"
            data={
                makeData?.result[0].buckets.map((bucket) => ({
                    value: bucket[valueField],
                    label: bucket.value as string,
                    valueLabel: valueRange ? `${bucket.count_min}-${bucket.count_max}` : bucket[valueField],
                    bucket,
                })) || DEFAULT_DATA
            }
            total={(makeData?.result[0].buckets[0]?.[valueField] as number) || 0}
            sx={{ minWidth: defaultMinWidth, maxWidth: 'max(600px, 50%)' }}
            firstColumn={{ label: 'Make', width: 110 }}
            lastColumn={{ label: 'Vehicles' }}
            hidePercent
            percentHeaderLabel=""
            showCount
            onRowClick={
                onDetailsClick
                    ? (row) => {
                          if (!row.bucket?.value) {
                              return;
                          }
                          onDetailsClick({
                              title: `${row.label} (${makeData?.result[0].buckets.length} ${pluralize(makeData?.result[0].buckets.length || 0, 'vehicle')})`,
                              query: {
                                  '?ALL': [
                                      {
                                          '?EQ': {
                                              make: row.bucket.value,
                                          },
                                      },
                                  ],
                              } as Operation,
                          });
                      }
                    : undefined
            }
        />
    );
};

export default MakeTile;
