import { AxiosResponse, AxiosInstance } from 'axios';
import { assertArray } from 'utilities';

import { Database } from 'contexts/ListsContext';

import { List, ListType } from './types/List';

export function cloneList(
    axios: AxiosInstance,
    type: ListType,
    { id, name }: Partial<List> & { id: string; name: string },
): Promise<AxiosResponse<List>> {
    if (!id) {
        return Promise.reject();
    }
    return axios.post(`/api/v3/lists/${getListCategory(type)}/${getListType(type)}/${id}/clone/`, { name });
}

export function deleteList(axios: AxiosInstance, id: string): Promise<AxiosResponse<void>> {
    if (!id) {
        return Promise.reject();
    }
    return axios.delete(`/mapi/objects/target_group/${id}/`);
}

export function deleteMultipleLists(axios: AxiosInstance, ids: string[]): Promise<AxiosResponse<void>> {
    if (Array.isArray(ids) && ids.length) {
        return axios.post(`/mapi/objects/target_group/bulk_delete/`, { ids });
    }
    return Promise.reject();
}

export function getList(axios: AxiosInstance, id: string): Promise<AxiosResponse<List>> {
    if (!id) {
        return Promise.reject();
    }
    return axios.get(`/mapi/objects/target_group/${id}/`);
}

export function fetchList(axios: AxiosInstance, type: ListType, id: string): Promise<AxiosResponse<List>> {
    if (!id) {
        return Promise.reject();
    }
    return axios.get(`/api/v3/lists/${getListCategory(type)}/${getListType(type)}/${id}/`);
}

export async function fetchDomainLists(axios: AxiosInstance): Promise<List[]> {
    try {
        const { data } = await axios.get<List[] | null | undefined>(`/api/v3/lists/domains/`);
        return assertArray(data);
    } catch (err) {
        console.error(err);
        return [];
    }
}

export async function fetchOrganizationLists(axios: AxiosInstance): Promise<List[]> {
    try {
        const { data } = await axios.get<List[] | null | undefined>(`/api/v3/lists/organizations/`);
        return assertArray(data);
    } catch (err) {
        console.error(err);
        return [];
    }
}

export async function fetchLegacyLists(axios: AxiosInstance): Promise<List[]> {
    try {
        const { data } = await axios.get<List[] | null | undefined>(`/api/v3/lists/legacy/`);
        return assertArray(data);
    } catch (err) {
        console.error(err);
        return [];
    }
}

export function createList(axios: AxiosInstance, type: ListType, data: Partial<List>): Promise<AxiosResponse<List>> {
    return axios.post(`/api/v3/lists/${getListCategory(type)}/${getListType(type)}/`, data);
}

export function updateList(
    axios: AxiosInstance,
    type: ListType,
    data: Partial<List> & { id: string },
): Promise<AxiosResponse<List>> {
    const { id, ...rest } = data;
    if (!id) {
        return Promise.reject();
    }
    return axios.patch(`/api/v3/lists/${getListCategory(type)}/${getListType(type)}/${id}/`, rest);
}

export function addCompaniesToStaticList(
    axios: AxiosInstance,
    type: ListType,
    id: string,
    companies: string[],
): Promise<AxiosResponse<List>> {
    const category = getListCategory(type);
    return axios.patch(`/api/v3/lists/${category}/static/${id}/add/`, companies);
}

export function patchCompaniesToStaticList(
    axios: AxiosInstance,
    type: ListType,
    id: string,
    companies: string[],
): Promise<AxiosResponse<List>> {
    const category = getListCategory(type);
    return axios.patch(
        `/api/v3/lists/${category}/static/${id}/`,
        category === 'domains' ? { domains: companies } : { business_ids: companies },
    );
}

export function removeCompaniesFromStaticList(
    axios: AxiosInstance,
    type: ListType,
    id: string,
    companies: string[],
): Promise<AxiosResponse<List>> {
    const category = getListCategory(type);
    return axios.patch(`/api/v3/lists/${category}/static/${id}/remove/`, companies);
}

export function combineListToStaticList(
    axios: AxiosInstance,
    type: ListType,
    target: string,
    source: string,
): Promise<AxiosResponse<List>> {
    return axios.patch(`/api/v3/lists/${getListCategory(type)}/static/${target}/combine/${source}/`);
}

export function migrateLegacyList(axios: AxiosInstance, id: string, country: Database): Promise<AxiosResponse<List>> {
    return axios.post(`/api/v3/lists/legacy/${id}/migrate/`, { country, allow_partial_convertion: true });
}

export function revertLegacyList(axios: AxiosInstance, type: ListType, id: string): Promise<AxiosResponse<List>> {
    return axios.post(`/api/v3/lists/${getListCategory(type)}/${getListType(type)}/${id}/revert_migrate/`);
}

const getListCategory = (type: ListType) =>
    ['static-domain-list', 'dynamic-domain-list'].includes(type || '') ? 'domains' : 'organizations';
const getListType = (type: ListType) =>
    ['static-domain-list', 'static-organization-list'].includes(type || '') ? 'static' : 'dynamic';
