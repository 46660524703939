import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Localisation: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.98 4.07005C7.06003 4.59005 4.03003 7.95005 4.03003 12C4.03003 16.05 7.06003 19.41 10.98 19.93V21.9501C5.95003 21.42 2.03003 17.16 2.03003 12C2.03003 6.84005 5.95003 2.58005 10.98 2.05005V4.07005ZM13.02 21.9501V19.9301C14.48 19.7501 15.81 19.1701 16.92 18.3101L18.34 19.7401C16.86 20.9501 15.03 21.7501 13.02 21.9501ZM19.9501 10.9999H21.9701C21.7701 8.98993 20.9701 7.15993 19.7601 5.67993L18.3301 7.10993C19.1901 8.20993 19.7701 9.53993 19.9501 10.9999ZM13.02 2.05005C15.02 2.25005 16.86 3.05005 18.34 4.26005L16.92 5.69005C15.81 4.83005 14.48 4.25005 13.02 4.07005V2.05005ZM18.3301 16.9L19.7601 18.32C20.9701 16.84 21.7701 15.01 21.9701 13H19.9501C19.7701 14.46 19.1901 15.79 18.3301 16.9ZM12 7C14.1 7 16 8.61 16 11.1C16 12.76 14.67 14.73 12 17C9.33 14.73 8 12.76 8 11.1C8 8.61 9.9 7 12 7ZM10.93 10.93C10.93 11.52 11.41 12 12 12C12.59 12 13.07 11.52 13.07 10.93C13.07 10.34 12.59 9.86 12 9.86C11.41 9.86 10.93 10.34 10.93 10.93Z"
        />
    </SvgIcon>
);

export default Localisation;
