import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowLongDown = (props) => (
    <SvgIcon {...props}>
        <path d="M11 18.17L8.41 15.59L7 17L12 22L17 17L15.59 15.59L13 18.17V2H11V18.17Z" />
    </SvgIcon>
);

export default ArrowLongDown;
