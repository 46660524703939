import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Gmail = (props) => (
    <SvgIcon {...props}>
        <path d="M4.2273 18.7534H7.09092V11.7988L3 8.73059V17.5262C3 18.2042 3.54921 18.7535 4.2273 18.7535V18.7534Z" />
        <path d="M16.9091 18.7533H19.7727C20.4507 18.7533 20.9999 18.2041 20.9999 17.5261V8.73059L16.9091 11.7988V18.7533Z" />
        <path d="M16.9091 6.48055V11.7987L20.9999 8.73051V7.09419C20.9999 5.5775 19.2685 4.71127 18.0545 5.62148L16.9091 6.48055Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.09094 11.7988V6.48059L12 10.1624L16.9091 6.48059V11.7988L12 15.4806L7.09094 11.7988Z"
        />
        <path d="M3 7.0942V8.73053L7.09092 11.7987V6.48055L5.94546 5.62149C4.73145 4.71127 3 5.57751 3 7.09413V7.0942Z" />
    </SvgIcon>
);

export default Gmail;
