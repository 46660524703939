import { Contact } from 'api/types/Organization';
import { CRMBulkExportAsyncProcess } from 'api/types/UserAsyncProcess';
import { isAsyncProcessOngoing } from 'utilities/asyncProcess';

import {
    CrmName,
    CRMObject,
    MappingsMap,
    HubspotObject,
    SalesforceObject,
    DynamicsObject,
    PipedriveObject,
} from '../types/Crm';
import { ExportableContact } from './useCRMExport';

type ExportableObjectsByCRM = {
    hubspot: HubspotObject[];
    salesforce: SalesforceObject[];
    salesforcesandbox: SalesforceObject[];
    dynamics: DynamicsObject[];
    pipedrive: PipedriveObject[];
};

const getExportObjects = (target: CrmName, normalizedCrmObject: 'contact' | 'company'): CRMObject[] => {
    const companyExportables: ExportableObjectsByCRM = {
        dynamics: ['accounts', 'leads'],
        pipedrive: ['organization'],
        hubspot: ['Company'],
        salesforce: ['Account', 'Lead'],
        salesforcesandbox: ['Account', 'Lead'],
    };

    const contactExportables: ExportableObjectsByCRM = {
        dynamics: ['contacts', 'leads', 'tasks'],
        pipedrive: ['person', 'activity'],
        hubspot: ['Contact', 'Task'],
        salesforce: ['Contact', 'Lead', 'Task'],
        salesforcesandbox: ['Contact', 'Lead', 'Task'],
    };

    if (normalizedCrmObject === 'contact') {
        return contactExportables?.[target] ?? [];
    }

    if (normalizedCrmObject === 'company') {
        return companyExportables?.[target] ?? [];
    }

    return [];
};

const hasMappings = (mappings: MappingsMap | {}): mappings is MappingsMap => {
    return Object.keys(mappings).length > 0;
};

const getCRMObjectsWithMappings = (mappings: MappingsMap | {}) => {
    if (!hasMappings(mappings)) return [];

    return Object.keys(mappings).filter((crmObject) => mappings[crmObject as CRMObject].length > 0) as CRMObject[];
};

export interface ContactOption extends ExportableContact {
    label: string;
    value: string;
}

/**
 * Add `value` and `label` properties to each contact, as required by the `SelectOption` type.
 */
const getContactOption = (contact: Contact, businessId: string): ContactOption => {
    return {
        ...contact,
        value: contact.uid,
        label: contact.full_name || '',
        businessId,
    };
};

const getOngoingExports = (exports: CRMBulkExportAsyncProcess[]) => {
    return exports.filter(isAsyncProcessOngoing);
};

export { getExportObjects, getCRMObjectsWithMappings, getContactOption, getOngoingExports };
