import { isNotEmpty } from 'utilities';

import { useFinancialStatements, useGroupData } from 'api/profileData';
import { Organization, organizationProfileType } from 'api/types/Organization';
import { FinancialsChartSection } from 'components/features/Financials/Charts';
import { useFinancialValuesForChartsSection } from 'components/features/Financials/Charts/utilities';
import FinancialStatements from 'components/features/Financials/FinancialStatements';
import { useValidStatements } from 'components/features/Financials/utils';

import { TabContent, OrganizationTabProps } from '..';

export const FinancialsTab: React.VFC<OrganizationTabProps> = ({ businessId }) => {
    const { data: statementData, isLoading } = useFinancialStatements({
        type: organizationProfileType,
        key: businessId,
    });

    const { data } = useGroupData({ type: organizationProfileType, key: businessId });

    const groupData = (data as Organization)?.group_data;
    const levelInGroup = (data as Organization)?.level_in_group;
    const validStatements = useValidStatements(statementData?.financial_statements);

    const financialsChartSectionProps = useFinancialValuesForChartsSection(validStatements);

    return (
        <TabContent>
            {((validStatements && isNotEmpty(validStatements)) || isLoading) && (
                <>
                    <FinancialsChartSection
                        skeleton={isLoading}
                        mode={levelInGroup === 1 ? 'group' : 'company'}
                        {...financialsChartSectionProps}
                        groupEmployees={groupData?.financial_data.employees.absolute_count}
                        groupRevenue={groupData?.financial_data.revenue}
                    />
                    <FinancialStatements skeleton={isLoading} name={statementData?.name} statements={validStatements} />
                </>
            )}
        </TabContent>
    );
};

export default FinancialsTab;
