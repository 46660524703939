import React from 'react';

import { Button, ButtonProps } from 'components/tokens/Button';

import { ConnectorDrawer } from '../ConnectorDrawer';
import { ConnectorTabProvider } from '../context/ConnectorTabContext';
import { TabEnum } from '../types';
import { CrmName, CRMObject } from '../types/Crm';

const ConnectorDrawerWithButton: React.FC<{
    crm: CrmName;
    object?: CRMObject;
    tab: TabEnum;
    openCompanyMatchingAdvanceMenu?: boolean;
    open: boolean;
    isButton?: boolean;
    buttonText?: React.ReactNode;
    className?: string;
    buttonProps?: ButtonProps;
    handleClick?: () => void;
    handleClose: () => void;
}> = ({
    crm,
    object,
    tab,
    handleClick,
    handleClose,
    openCompanyMatchingAdvanceMenu,
    open,
    isButton = true,
    buttonText = 'Open',
    className,
    buttonProps = {},
}) => {
    const values = {
        crm,
        open,
        tab,
        object,
        handleClose,
        openCompanyMatchingAdvanceMenu,
    };

    return (
        <>
            {isButton && (
                <Button className={className} onClick={handleClick} {...buttonProps}>
                    {buttonText}
                </Button>
            )}

            {open && tab && (
                <ConnectorTabProvider {...values}>
                    <ConnectorDrawer crm={crm} />
                </ConnectorTabProvider>
            )}
        </>
    );
};

export default ConnectorDrawerWithButton;
