import React, { useState } from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import { Button as MuiButton, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { UserAsyncProcess, CRMBulkExportAsyncProcess } from 'api/types/UserAsyncProcess';
import useAsyncProcess from 'hooks/useAsyncProcess';

import { CrmName } from '../../types/Crm';
import { formatDate } from '../../utils';
import { bulkExportProgress } from '../../utils/exportUtils';
import { BorderLinearProgress } from '../BorderLinearProgress';
import ErrorContent from './ErrorContent';

export type Props = {
    title?: string;
    crm: CrmName;
    isDisabled?: boolean;
    latestJob?: UserAsyncProcess;
    customError?: {
        error: CRMBulkExportAsyncProcess['error'];
        error_msg: string;
    };
    confirmText?: string;
    cancelText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export const ErrorWithWrapper: React.FC<Props> = ({
    title,
    crm,
    customError,
    latestJob,
    isDisabled = false,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
}) => {
    const navigate = useNavigate();
    const [, { markAsyncProcessSeen }] = useAsyncProcess();

    const [isLoading, setIsLoading] = useState(false);

    const jobFinishedTime = latestJob?.finished;
    const completedTime = jobFinishedTime ? formatDate(jobFinishedTime) : '';

    const hideJob = () => {
        if (latestJob?.job_id) {
            markAsyncProcessSeen(latestJob.job_id);
        }
    };

    const handleConfirm = async () => {
        try {
            setIsLoading(true);
            onConfirm && (await onConfirm());
        } catch (e) {
            //
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        } else {
            hideJob();
        }
    };

    return (
        <div>
            <FinishedTimeWrapper>
                {title && (
                    <>
                        <WarningIcon sx={{ color: 'error.main' }} fontSize="small" />
                        <span>{title}</span>
                    </>
                )}
                {completedTime && (
                    <>
                        {' ⋅ '}
                        {formatDate(jobFinishedTime)}
                    </>
                )}
            </FinishedTimeWrapper>
            {latestJob && (
                <ErrorProgress>
                    <BorderLinearProgress
                        value={bulkExportProgress(latestJob as CRMBulkExportAsyncProcess)}
                        variant="buffer"
                        valueBuffer={bulkExportProgress(latestJob as CRMBulkExportAsyncProcess)}
                        color="secondary"
                    />
                </ErrorProgress>
            )}
            <ErrorWrapper>
                <ErrorContent
                    error={customError?.error || latestJob?.error || ''}
                    error_msg={customError?.error_msg || latestJob?.error_msg}
                    includeTitle
                    onCloseClick={hideJob}
                    crm={crm}
                    disabledActions
                />
            </ErrorWrapper>
            <ErrorCTA>
                {cancelText && (
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={handleCancel}
                        style={{ marginRight: 10 }}
                        disabled={isDisabled || isLoading}
                    >
                        {cancelText}
                    </Button>
                )}
                {latestJob?.error === 'AUTH_ERROR' && (
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={() =>
                            navigate(`/connectors/connections/${crm}/overview?tab=overview&forceTabChange=true`)
                        }
                        style={{ marginRight: 10 }}
                    >
                        Go to disconnect
                    </Button>
                )}
                {confirmText && (
                    <Button
                        variant="contained"
                        color="inherit"
                        disabled={isDisabled || isLoading}
                        onClick={handleConfirm}
                    >
                        {confirmText}
                    </Button>
                )}
            </ErrorCTA>
        </div>
    );
};
export default ErrorWithWrapper;

const ErrorProgress = styled('div')`
    margin-top: 10px;
`;
const FinishedTimeWrapper = styled('div')(({ theme }) => ({
    marginTop: '10px',
    fontStyle: 'italic',
    fontWeight: '300',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[500],
    marginBottom: 2,
    svg: {
        marginRight: '5px',
    },
    span: {
        fontWeight: 'bold',
        marginRight: '5px',
    },
}));

const ErrorWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '16px',
}));

const ErrorCTA = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '8px 0px',
});

const Button = styled(MuiButton)(({ theme }) => ({
    '&.MuiButton-root': {
        fontSize: '14px',
        background: theme.palette.grey[50],
        color: theme.palette.text.strong,
    },
}));
