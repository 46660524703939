import React, { useMemo, useState, useCallback } from 'react';

import { Skeleton, styled } from '@mui/material';

import Button from 'components/tokens/Button';
import Typography from 'components/tokens/Typography';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import { useDialogContext } from 'hooks/useDialogContext';

import useConnectorsApi from '../hooks/useConnectorsApi';
import { CrmName, CRMObject, EnhancedMapping, CRMProperty, CRMPropertiesMenuOption } from '../types/Crm';
import { getCRMName } from '../utils';
import { getObjectSingularForm } from '../utils/CRMTargetText';
import { CRM_PICKLIST_LIMITS } from './constants';
import { CRMPropertiesMenu } from './CRMPropertiesMenu';
import { OptionValue } from './dialogs/NewCRMPropertySuggestDialog';
import { MappingsSearchInput } from './MappingsSearchInput';
import { MappingsTable } from './MappingsTable';
import { MappingsTableSkeleton } from './MappingsTableSkeleton';

type MappingsTabContentProps = {
    isLoading: boolean;
    crmName: CrmName;
    activeCRMObjectTab: CRMObject | '';
    crmIntegrationId: string | undefined;
    mappings: EnhancedMapping[];
    crmProperties: CRMProperty[];
    refetchMappings: () => Promise<void>;
    refetchCRMProperties: () => Promise<void>;
};

const MappingsTabContent: React.FC<MappingsTabContentProps> = ({
    isLoading,
    crmName,
    activeCRMObjectTab,
    crmIntegrationId,
    mappings,
    crmProperties = [],
    refetchMappings,
    refetchCRMProperties,
}) => {
    const { createMapping, updateMapping, removeMapping, createSingleCRMProperty } = useConnectorsApi();
    const { handleDialogOpen, handleDialogClose } = useDialogContext();
    const { showSnackbar } = useSnackbarContext();

    const [searchValue, setSearchValue] = useState('');

    const formattedSelectedCRMObject = useMemo(
        () => (activeCRMObjectTab ? getObjectSingularForm(crmName, activeCRMObjectTab as CRMObject) : ''),
        [crmName, activeCRMObjectTab],
    );

    const filteredMappings = useMemo(() => getMappingsBySearchValue(mappings, searchValue), [searchValue, mappings]);

    const crmPropertiesMenuOptions: CRMPropertiesMenuOption[] = useMemo(() => {
        return crmProperties.map((crmProperty) => ({
            ...crmProperty,
            disabled: mappings.some((mapping) => mapping.crmPropertyLabel === crmProperty.label),
        }));
    }, [mappings, crmProperties]);

    const handleCreateMapping = useCallback(
        async (newMapping: EnhancedMapping) => {
            return createMapping(crmIntegrationId as string, newMapping)
                .then(async () => {
                    await refetchMappings();
                    showSnackbar(`"${newMapping.crmPropertyLabel}" mapping has been created successfully`);
                    handleDialogClose();
                })
                .catch(() => {
                    showSnackbar('Something went wrong. Please try again later.', 'error');
                });
        },
        [crmIntegrationId, createMapping, refetchMappings, showSnackbar, handleDialogClose],
    );

    const handleCreateNewCRMPropertyMapping = useCallback(
        (newMapping: EnhancedMapping, crmPropertyType: string, vciOrTechnologiesSourceIndex: number) => {
            handleDialogOpen('NEW_CRM_PROPERTY_SUGGEST', {
                crmName,
                datapoint: newMapping.sources[vciOrTechnologiesSourceIndex],
                crmPropertyType,
                onSubmit: async (selectedOption: OptionValue, newCRMPropertyName: string) => {
                    if (selectedOption === 'create_new_crm_property') {
                        return createSingleCRMProperty({
                            crm: crmName,
                            propertyName: newCRMPropertyName,
                            propertyType: 'SetField',
                            crmObject: activeCRMObjectTab as CRMObject,
                        })
                            .then(async ({ data }) => {
                                await refetchCRMProperties();

                                const createdCRMProperty = data[0].data;

                                handleCreateMapping({
                                    ...newMapping,
                                    id: `${activeCRMObjectTab}.${createdCRMProperty.field_key}`,
                                    target_field: createdCRMProperty.field_key,
                                    crmPropertyLabel: createdCRMProperty.field_label,
                                });
                            })
                            .catch(() => {
                                showSnackbar('Something went wrong. Please try again later.', 'error');
                                handleDialogClose();
                            });
                    } else {
                        handleCreateMapping(newMapping);
                    }
                },
            });
        },
        [
            crmName,
            activeCRMObjectTab,
            showSnackbar,
            createSingleCRMProperty,
            handleCreateMapping,
            handleDialogOpen,
            handleDialogClose,
            refetchCRMProperties,
        ],
    );

    const handleSelectCRMProperty = useCallback(
        (crmProperty: CRMProperty) => {
            handleDialogOpen('MAPPING_FORM', {
                activeCRMObjectTab,
                crmName,
                crmProperty,
                mapping: {
                    id: `${activeCRMObjectTab}.${crmProperty.value}`,
                    target_field: crmProperty.value,
                    target_object: activeCRMObjectTab,
                    update_type: 'safe_update',
                    crmPropertyLabel: crmProperty.label,
                    crmPropertyType: crmProperty.type,
                },
                onSubmit: (newMapping: EnhancedMapping) => {
                    const vciSourceIndex = newMapping.sources.findIndex(
                        ({ source_field }) => source_field === 'vainu_custom_industry',
                    );

                    const technologiesSourceIndex = newMapping.sources.findIndex(
                        ({ source_field }) => source_field === 'technographic_data',
                    );

                    const hasVCISource = vciSourceIndex >= 0;
                    const hasTechnologiesSource = technologiesSourceIndex >= 0;
                    const technologiesSource = newMapping.sources[technologiesSourceIndex];

                    const isPicklistLimitReached =
                        hasTechnologiesSource &&
                        (technologiesSource.options.tag_ids as number[]).length >= CRM_PICKLIST_LIMITS[crmName];

                    // suggest to create a new picklist CRM property when:
                    // - user tries to map Technologies to a 'non-multiselect' CRM property and selected technologies <= CRM picklist limit;
                    // - user tries to map VCI to a 'non-multiselect' CRM property;
                    const shouldSuggestNewPicklistProperty =
                        (crmProperty.vainu_type !== 'MultiSelect' && hasVCISource) ||
                        (crmProperty.vainu_type !== 'MultiSelect' && hasTechnologiesSource && !isPicklistLimitReached);

                    if (shouldSuggestNewPicklistProperty) {
                        handleCreateNewCRMPropertyMapping(
                            newMapping,
                            crmProperty.type,
                            hasVCISource ? vciSourceIndex : technologiesSourceIndex,
                        );
                    } else {
                        handleCreateMapping(newMapping);
                    }
                },
            });
        },
        [crmName, activeCRMObjectTab, handleDialogOpen, handleCreateMapping, handleCreateNewCRMPropertyMapping],
    );

    const handleUpdateMapping = useCallback(
        async (mapping: EnhancedMapping) => {
            return updateMapping(crmIntegrationId as string, mapping).then(() => {
                showSnackbar(`"${mapping.crmPropertyLabel}" mapping has been updated successfully`);
                refetchMappings();
            });
        },
        [crmIntegrationId, updateMapping, refetchMappings, showSnackbar],
    );

    const handleEditMapping = useCallback(
        (mapping: EnhancedMapping) =>
            handleDialogOpen('MAPPING_FORM', {
                activeCRMObjectTab,
                crmName,
                mapping,
                crmProperty: crmProperties.find((crmProperty) => crmProperty.value === mapping.target_field),
                onSubmit: (newMapping: EnhancedMapping) => handleUpdateMapping(newMapping).then(handleDialogClose),
            }),
        [crmName, activeCRMObjectTab, crmProperties, handleUpdateMapping, handleDialogOpen, handleDialogClose],
    );

    const handleRemoveMapping = useCallback(
        (crmPropertyLabel: string, mappingId: string) => {
            handleDialogOpen('REMOVE_MAPPING', {
                crmName: getCRMName(crmName),
                crmPropertyLabel,
                onSubmit: () =>
                    removeMapping(crmIntegrationId as string, mappingId).then(() => {
                        handleDialogClose();
                        refetchMappings();
                        showSnackbar(`"${crmPropertyLabel}" mapping has been removed successfully`);
                    }),
            });
        },
        [crmName, crmIntegrationId, removeMapping, refetchMappings, handleDialogOpen, handleDialogClose, showSnackbar],
    );

    return (
        <ContentWrapper>
            {isLoading ? (
                <Skeleton width={186} height={60} sx={{ marginTop: '-18px' }} />
            ) : (
                <Typography variant="h3" marginBottom="4px">
                    {formattedSelectedCRMObject} data
                </Typography>
            )}
            {isLoading ? (
                <Skeleton width={500} height={30} sx={{ marginBottom: '16px' }} />
            ) : (
                <Typography color="subtle" marginBottom="24px">
                    Add {formattedSelectedCRMObject} fields for your team to enrich when sending Vainu lists, data
                    updates or triggers to {getCRMName(crmName)}.
                </Typography>
            )}
            <HeaderControls>
                <CRMPropertiesMenu
                    anchorElement={
                        <Button
                            id="crm-properties-menu-button"
                            data-testid="crm-properties-menu-button"
                            disabled={isLoading}
                        >
                            + Add {getCRMName(crmName)} field
                        </Button>
                    }
                    options={crmPropertiesMenuOptions.sort((a, b) => alphabeticalSort(a, b, 'label'))}
                    onSelect={handleSelectCRMProperty}
                />
                <MappingsSearchInput isLoading={isLoading} value={searchValue} onChange={setSearchValue} />
            </HeaderControls>
            {isLoading ? (
                <MappingsTableSkeleton />
            ) : (
                <MappingsTable
                    crmName={crmName}
                    crmObject={activeCRMObjectTab as CRMObject}
                    searchValue={searchValue}
                    mappings={filteredMappings.sort((a, b) => alphabeticalSort(a, b, 'crmPropertyLabel'))}
                    onMappingEdit={handleEditMapping}
                    onMappingRemove={handleRemoveMapping}
                    onMappingUpdateTypeChange={handleUpdateMapping}
                />
            )}
        </ContentWrapper>
    );
};

const getMappingsBySearchValue = (mappings: EnhancedMapping[], searchValue: string) => {
    return mappings.filter(({ crmPropertyLabel, target_field }) => {
        const normalizedLabel = crmPropertyLabel.toLowerCase();
        const normalizedTargetField = target_field.toLowerCase();
        const normalizedSearchValue = searchValue.toLowerCase();

        return normalizedLabel.includes(normalizedSearchValue) || normalizedTargetField.includes(normalizedSearchValue);
    });
};

const alphabeticalSort = <T, K extends keyof T>(a: T, b: T, key: K) => {
    return String(a[key]).localeCompare(String(b[key]));
};

const ContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    padding: '24px',
    background: 'white',
    borderRadius: '8px',
});

const HeaderControls = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',

    '& > button:last-child': {
        marginLeft: '8px',
    },
});

export { MappingsTabContent };
