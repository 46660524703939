import { AxiosInstance } from 'axios';

import { InsightsPayload } from './filterCompanies';
import { Operation } from './types/FilterOperators';
import { UserAsyncProcess } from './types/UserAsyncProcess';

export type ExternalReferencesStatsPayload = {
    query: Operation | string;
    limit?: number;
    order?: number | null;
    query_uid: string;
    stats: InsightsPayload['stats'];
};

export async function getExternalReferencesStatsAsync(axios: AxiosInstance, payload: ExternalReferencesStatsPayload) {
    return (await axios.post<UserAsyncProcess>('/api/v3/external_references/filter/stats/async/', payload)).data;
}

export type ExternalReferencesPayload = {
    query: Operation;
    fields: string[];
    offset?: number;
    limit?: number;
    order?: string | null;
};

export async function getExternalReferences<T>(axios: AxiosInstance, payload: ExternalReferencesPayload): Promise<T> {
    return (await axios.post('/api/v3/external_references/filter/', payload)).data;
}
