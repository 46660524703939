import { FC } from 'react';

import { Box } from '@mui/material';

import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import Link from 'components/tokens/Link';
import { TextTruncateTooltip } from 'components/tokens/TextTruncate';

interface CompanyRowProps {
    href: string;
    name?: string;
    loading?: boolean;
    logo?: string;
    onClick?: () => void;
}
export const CompanyRow: FC<CompanyRowProps> = ({ href, name, loading, logo, onClick }) => (
    <Link href={href} onClick={onClick} underline="none">
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                    '& .list-company-name': {
                        color: 'secondary.main',
                    },
                },
            }}
        >
            <CompanyLogo
                skeleton={loading}
                sx={{ width: 32, height: 32, borderRadius: 50 }}
                company_name={name}
                logo={logo}
                border
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingLeft: 1,
                    width: '100%',
                    minWidth: 150,
                }}
            >
                <TextTruncateTooltip
                    className="list-company-name"
                    text={name || '-'}
                    showTooltip
                    variant="body2"
                    weight="semibold"
                />
            </Box>
        </Box>
    </Link>
);
