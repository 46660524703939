import { useCallback, useEffect } from 'react';

import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import {
    domainProfileType,
    isDomainData,
    isOrganization,
    organizationProfileType,
    ProfileType,
} from 'api/types/Organization';
import { createPath } from 'components/beta/Platform/Router';
import { MainPath } from 'components/beta/Platform/Router/routePaths';
import { useFilterCompaniesContext } from 'contexts/FilterCompaniesContext/FilterCompaniesContext';
import { Database, useListsContext } from 'contexts/ListsContext';
import { DomainDataResult, OrganizationResult, ProfileResult } from 'contexts/types/FilterCompanies';

import CompanyLegacyProfile from './CompanyLegacyProfile';
import CompanyProfile from './CompanyProfile';
import DomainProfile from './DomainProfile';
import { defaultTab, isValidTabSlug } from './tabs';

export type ProfileRouterProps = {
    type: ProfileType | 'company-legacy';
    scrollRef?: React.RefObject<HTMLElement>;
};

const ProfileRoute: React.FC<ProfileRouterProps> = ({ type, scrollRef }) => {
    const { database, changeDatabase } = useListsContext();
    const { key, tab } = useParams<'key' | 'tab'>();
    const { companies } = useFilterCompaniesContext();
    const navigate = useNavigate();

    const activeTab = tab && isValidTabSlug(tab) ? tab : defaultTab;

    useEffect(() => {
        if (scrollRef?.current) {
            scrollRef.current?.scrollTo({ top: 0 });
        }
    }, [key, scrollRef]);

    // set database from url
    useEffect(() => {
        if (database !== 'DOMAIN_DATA_BASIC' && type === 'domain') {
            changeDatabase('DOMAIN_DATA_BASIC', false);
        } else if (key && key.slice(0, 2) !== database) {
            changeDatabase(key.slice(0, 2) as Database, false);
        }
    }, [type, key, database, changeDatabase]);

    const handleTabChange = useCallback(
        (tabName: string, scrollTop?: boolean) => {
            if (scrollTop && scrollRef?.current) {
                scrollRef.current.scrollTop = 0;
            }
            navigate(`../${tabName}`);
        },
        [navigate, scrollRef],
    );

    if (!key || !tab) {
        return <div>not found</div>;
    }

    if (type === 'company-legacy') {
        return <CompanyLegacyProfile businessId={key} activeTab={activeTab} onTabChange={handleTabChange} />;
    } else if (type === 'domain') {
        const initialData = (companies as ProfileResult[]).find(
            (profile: ProfileResult): profile is DomainDataResult => profile.website === key && isDomainData(profile),
        );
        return (
            <DomainProfile domain={key} initialData={initialData} activeTab={activeTab} onTabChange={handleTabChange} />
        );
    }
    const initialData = (companies as ProfileResult[]).find(
        (profile: ProfileResult): profile is OrganizationResult => profile.website === key && isOrganization(profile),
    );
    return (
        <CompanyProfile
            businessId={key}
            initialData={initialData}
            activeTab={activeTab}
            onTabChange={handleTabChange}
        />
    );
};

export const getProfilePath = (profileType?: ProfileType | 'company-legacy', profileId?: string, tabName?: string) => {
    if (profileType === domainProfileType && profileId) {
        return createPath(`${MainPath.Domain}/${profileId}${tabName ? `/${tabName}` : ''}`);
    }
    if (profileType === organizationProfileType && profileId) {
        return createPath(`${MainPath.Organization}/${profileId}${tabName ? `/${tabName}` : ''}`);
    }
    if (profileType === 'company-legacy' && profileId) {
        return createPath(`${MainPath.Company}/${profileId}${tabName ? `/${tabName}` : ''}`);
    }
    return createPath(`${MainPath.Profiles}`);
};

export const ProfileRouter: React.FC<ProfileRouterProps> = ({ type, scrollRef }) => {
    return (
        <Routes>
            <Route path=":key">
                <Route index element={<Navigate replace to={defaultTab} />} />
                <Route path=":tab" element={<ProfileRoute type={type} scrollRef={scrollRef} />} />
            </Route>
        </Routes>
    );
};

export default ProfileRouter;
