import { Box, FormControlLabel, useTheme } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

import Skeleton from 'components/modules/connectors/skeletons';
import { CompanyLogo } from 'components/templates/avatars/CompanyLogo';
import { Checkbox } from 'components/tokens/Checkbox';
import { Frame } from 'components/tokens/Frame';
import { Link } from 'components/tokens/Link';
import { TextTruncateTooltip } from 'components/tokens/TextTruncate';
import { Typography } from 'components/tokens/Typography';
import { CompanyPreview, LeadPreview } from 'contexts/TriggerContext';
import { toDateUTC } from 'utilities/date';

import { MoreCompaniesPopover } from '../TriggerTable/CompanyCell';
import { LeadTags } from '../TriggerTable/LeadCell';

interface Props {
    lead: LeadPreview;
    selectedRowIds: Record<string, boolean>;
    setSelectedRowIds: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    selectCompany: (id: string, company: CompanyPreview) => void;
}

const LeadCard: React.FC<Props> = ({ lead, selectedRowIds, setSelectedRowIds, selectCompany }) => {
    const { palette } = useTheme();

    const restOfCompanies = lead.companies?.filter((i) => i.business_id !== lead.company?.business_id) || [];

    const isSelected = !!selectedRowIds[lead.id];

    return (
        <Frame
            elevation={2}
            sx={{
                padding: '20px',
                width: '100%',
                maxWidth: 420,
                height: 410,
                verticalAlign: 'top',
                display: 'inline-flex',
                flexDirection: 'column',
                position: 'relative',
                transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 50ms',
                '& .card-select-box-diminished': {
                    opacity: 0.5,
                },
                '&:hover': {
                    transform: 'scale(1.03)',
                    boxShadow: '0px 32px 32px rgba(10, 10, 10, 0.05), 0px 16px 16px rgba(10, 10, 10, 0.05)',
                    '& .card-select-box-diminished': {
                        opacity: 1,
                    },
                    '& .card-action svg': {
                        fill: palette.primary.main,
                    },
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 2 }}>
                <Link
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    href={
                        !lead.company?.business_id
                            ? `/domain/${lead.company?.domain}/`
                            : `/company/${lead.company?.business_id}/`
                    }
                    toNewTab
                    underline="hover"
                >
                    <CompanyLogo
                        size="small"
                        logo={lead.company?.logo}
                        company_name={lead.company?.company_name ?? '?'}
                    />

                    <Typography variant="body1" weight="bold" component="div">
                        {lead.company?.company_name}
                        <MoreCompaniesPopover
                            sx={{
                                display: 'inline',
                                fontWeight: 'inherit',
                                fontSize: 'inherit',
                                color: 'inherit',
                            }}
                            companies={restOfCompanies}
                            lead={lead}
                            selectCompany={selectCompany}
                        />
                    </Typography>
                </Link>

                <Box>
                    <LeadTags tags={lead.lead?.tags || []} />
                </Box>

                <TextTruncateTooltip
                    text={lead.lead?.title || ''}
                    variant="h4"
                    line={2}
                    showTooltip
                    sx={{ height: 'auto' }}
                />

                <TextTruncateTooltip
                    text={lead.lead?.content || ''}
                    variant="tiny"
                    color="default"
                    line={6}
                    sx={{ flex: 1, overflow: 'hidden' }}
                    showTooltip={false}
                />

                <Typography sx={{ textAlign: 'right' }} color="subtle" variant="mini">
                    <Link toNewTab href={lead.lead?.link || undefined}>
                        {lead.lead?.link?.match(/.*:\/\/?([^/]+)/)?.[1]}
                    </Link>{' '}
                    &middot;{' '}
                    {lead.lead?.vainu_date && formatDistanceToNow(toDateUTC(lead.lead.vainu_date), { addSuffix: true })}
                </Typography>

                <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    className={isSelected ? undefined : 'card-select-box-diminished'}
                >
                    <FormControlLabel
                        label={
                            <Typography component="span" color="subtle" variant="small">
                                Select{isSelected && 'ed'}
                            </Typography>
                        }
                        control={
                            <Checkbox
                                onChange={(e) => setSelectedRowIds((ids) => ({ ...ids, [lead.id]: e.target.checked }))}
                                checked={isSelected}
                                disabled={lead.delivered}
                            />
                        }
                    />
                </Box>
            </Box>
        </Frame>
    );
};

export default LeadCard;

export const LeadCardSkeleton = () => {
    return (
        <Skeleton
            height="410px"
            width="100%"
            sx={{
                maxWidth: 420,
            }}
        />
    );
};
