import React from 'react';

import { Box, SxProps, Tooltip } from '@mui/material';
import { simpleHash } from 'utilities';

import { generateStatsAsync, InsightsPayload } from 'api/filterCompanies';
import MigrateButton from 'components/modules/profiles/FilterResults/MigrateButton';
import { Button } from 'components/tokens/Button';
import FeedbackBanner from 'components/tokens/FeedbackBanner/FeedbackBanner';
import { Icon } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useFilterState } from 'contexts/FilterContext';
import { useListsContext } from 'contexts/ListsContext';
import { DomainDataResult, OrganizationResult } from 'contexts/types/FilterCompanies';
import { formatDate, toDateUTC } from 'utilities/date';
import { combineSx } from 'utilities/mui';

import AnalysisProgress from '../CRMInsights/AnalysisProgress';
import { CRM_INSIGHT_LIMIT } from '../CRMInsights/CRMInsights';
import getStatsPayload from '../CRMInsights/getStatsPayload';
import TileGrid from '../CRMInsights/TileGrid';
import Tile from '../CRMInsights/Tiles/Tile';
import useStats from '../CRMInsights/useStats';
import { getQueryUid } from '../CRMInsights/utils';
import NoFilterResults from '../lists/CompanyGridView/NoFilterResults';
import { CompanyLogoStack } from './CompanyLogoStack';

type Props = {
    companiesCount: number;
    loadingCount: boolean;
    companies: DomainDataResult[] | OrganizationResult[];
    loadingCompanies: boolean;
    sx?: SxProps;
};

const INSIGHTS_LIMIT_REACHED_TEXT = `This list is too large. To generate insights filter it below ${CRM_INSIGHT_LIMIT.toLocaleString()} companies.`;

const getQueryHash = (query: string | null | undefined) => simpleHash(query ?? '', 36);

const CompanyInsightsView: React.FC<Props> = ({ companiesCount, loadingCount, companies, loadingCompanies, sx }) => {
    const axios = useAxiosContext();
    const { database, selectedList } = useListsContext();
    const { setActiveGroup } = useFilterState();

    const statsQueryUid = getQueryUid(`list-${selectedList?.id}`, database, 'default');
    const statsPayload = getStatsPayload(database === 'DOMAIN_DATA_BASIC');

    const insightsPayload = {
        use_filters_v2: 'true',
        database,
        limit: CRM_INSIGHT_LIMIT,
        // required
        order: null,
        query_uid: statsQueryUid,
        stats: statsPayload,
        query_hash: getQueryHash(selectedList?.query),
        ...(selectedList?.type.startsWith('static') ? { list: selectedList?.id } : { query: selectedList?.query }),
    } as InsightsPayload;

    const { generateStats, processesQuery, statsQuery, cancelAnalysis } = useStats({
        queryUid: statsQueryUid,
        processType:
            database === 'DOMAIN_DATA_BASIC'
                ? 'public-domains-stats-async-api'
                : 'public-organizations-stats-async-api',
    });

    const { data: statsProcesses, isFetching } = processesQuery;

    const handleGenerateStatsClick = async () => {
        generateStats(() => generateStatsAsync(axios, insightsPayload));
    };

    const isLegacy = selectedList?.type === 'legacy';
    const progress = statsProcesses?.inProgress?.progress;
    const isInsightsLimitReached = companiesCount > CRM_INSIGHT_LIMIT;
    const isAnalysisDisabled = isLegacy || !!statsProcesses?.inProgress || loadingCount || isInsightsLimitReached;

    const hasNoAnalysis = !isFetching && !statsProcesses?.completed && !statsProcesses?.inProgress;
    const hasStaleAnalysis =
        !isFetching &&
        statsProcesses?.completed &&
        statsProcesses.completed.query_hash !== getQueryHash(selectedList?.query);

    const gridOpacity = hasNoAnalysis ? 0.3 : hasStaleAnalysis ? 0.5 : 1;

    if (!loadingCompanies && !companies.length) {
        return (
            <Box sx={combineSx({ height: '50vh' }, sx)}>
                <NoFilterResults />
            </Box>
        );
    }

    return (
        <Box sx={sx}>
            {statsProcesses?.completed?.finished && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: 1,
                        marginY: 1,
                    }}
                >
                    <Typography variant="caption" color="subtle">
                        Analyzed:{' '}
                        <span style={{ fontWeight: 500 }}>
                            {formatDate(toDateUTC(statsProcesses.completed.finished), 'long-datetime')}
                        </span>
                    </Typography>

                    <Button
                        startIcon={<Icon type="Refresh" />}
                        disabled={isAnalysisDisabled}
                        variant="flat"
                        onClick={handleGenerateStatsClick}
                    />
                </Box>
            )}

            {(hasNoAnalysis || hasStaleAnalysis) && (
                <Tile sx={{ marginY: 2 }}>
                    {loadingCount && !isLegacy && !isInsightsLimitReached ? (
                        <FeedbackBanner label="Waiting for list to load..." loading />
                    ) : (
                        hasStaleAnalysis && (
                            <FeedbackBanner label="Your filters have changed. To see accurate insights, generate them again." />
                        )
                    )}

                    {isLegacy ? (
                        <FeedbackBanner
                            label="This is a legacy list, to generate insights you’ll have to migrate it."
                            severity="warning"
                            action={<MigrateButton ButtonProps={{ variant: 'flat' }} label="Migrate list" />}
                        />
                    ) : (
                        isInsightsLimitReached && (
                            <FeedbackBanner
                                label={INSIGHTS_LIMIT_REACHED_TEXT}
                                severity="warning"
                                action={
                                    <Button size="small" variant="flat" onClick={() => setActiveGroup('plus-button')}>
                                        Add filter
                                    </Button>
                                }
                            />
                        )
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <CompanyLogoStack companies={companies} loading={loadingCompanies} />
                        <Typography variant="subtitle1" color="dark" weight="normal" sx={{ flex: 1 }}>
                            Insights for {selectedList?.name}
                        </Typography>
                        <Tooltip title={isInsightsLimitReached ? INSIGHTS_LIMIT_REACHED_TEXT : ''}>
                            <Box sx={{ display: 'inline-block' }}>
                                <Button onClick={handleGenerateStatsClick} disabled={isAnalysisDisabled}>
                                    Generate Insights
                                </Button>
                            </Box>
                        </Tooltip>
                    </Box>
                </Tile>
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    marginTop: 2,
                }}
            >
                {statsProcesses?.inProgress && (
                    <AnalysisProgress progress={progress} companyCount={companiesCount} onCancel={cancelAnalysis} />
                )}

                <TileGrid
                    data={statsQuery.isFetching ? undefined : statsQuery.data}
                    statsQuery={insightsPayload.stats}
                    database={database}
                    sx={{
                        opacity: gridOpacity,
                    }}
                />
            </Box>
        </Box>
    );
};

export default CompanyInsightsView;
