import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';

export const ProgressBarWrapper = styled(Frame)(() => ({
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
}));

export const CloseIconutton = styled(Icon)(({ theme: { palette } }) => ({
    cursor: 'pointer',
    color: palette.grey[200],

    '&:hover': {
        color: palette.grey[900],
        transition: 'all 0.5s',
    },
}));

export const IconWrapper = styled('div')(({ theme: { palette } }) => ({
    position: 'relative',
    height: 40,
    width: 40,
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
    flex: 'none',
    justifyContent: 'center',
    backgroundColor: palette.grey[50],
    borderRadius: 20,
}));

export const NewProgressBar = styled('div')(({ theme: { palette } }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    width: 12,
    height: 12,
    backgroundColor: palette.secondary.main,
    border: `2px solid ${palette.common.white}`,
    borderRadius: 12,
}));

export const ContentWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}));

export const StyledLinearProgress = styled(LinearProgress)(({ theme: { palette } }) => ({
    margin: '8px 0',
    height: 6,
    borderRadius: 18,
    backgroundColor: palette.grey[200],

    '& .MuiLinearProgress-bar': {
        backgroundColor: palette.common.black,
        borderRadius: 18,
    },
}));

export const MessageWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
