import { styled } from '@mui/material/styles';

import Frame from 'components/tokens/Frame';

/**
 *  ******************** Plugins *********************
 */

export const SocialNetworkWrapper = styled('div')`
    position: relative;
    display: flex;
`;

export const PluginWrapper = styled(Frame)(({ theme }) => ({
    position: 'relative',
    minHeight: 362,
    maxHeight: 650,
    height: '100%',
}));

export const FacebookContainer = styled('div')({
    height: '92%',
    width: '100%',
    overflow: 'hidden',
});

export const TwitterContainer = styled('div')({
    height: '92%',
    width: '100%',
    overflowY: 'auto',
});

export const NoProfileContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 92%;
`;

export const EmptyStateIconWrapper = styled('div')`
    position: relative;
`;

export const SocialIconWrapper = styled('div')`
    position: absolute;
    top: 84px;
    left: 80px;
`;
