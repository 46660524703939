import { isNotEmpty } from 'utilities';

import { useLocations, useWebProfileData } from 'api/profileData';
import { domainProfileType } from 'api/types/Organization';
import BasicInfo from 'components/features/BasicInfo';
import { useBasicDataFromProfileData } from 'components/features/BasicInfo/utils';
import LocationsMap from 'components/features/Locations/Map';
import { useAddressesFromBusinessUnits, useCountriesFromBusinessUnits } from 'components/features/Locations/utils';
import { WebProfileTechnologiesList } from 'components/features/WebProfile';
import Descriptions from 'components/templates/Descriptions';
import { useDescriptionsFromDomainData } from 'components/templates/Descriptions/utils';

import { TabContent, DomainTabProps } from '..';

export const OverviewTab: React.VFC<DomainTabProps> = ({ data, domain, loading = false, onTabChange }) => {
    const params = { type: domainProfileType, key: domain } as const;

    const { data: webProfileData, isLoading: isLoadingWebProfileData } = useWebProfileData(params);
    const { data: locationsData, isLoading: isLoadingLocationsData } = useLocations(params);

    const basicDataProps = useBasicDataFromProfileData(data);
    const descriptions = useDescriptionsFromDomainData(webProfileData);
    const addresses = useAddressesFromBusinessUnits(locationsData);
    const addressCountries = useCountriesFromBusinessUnits(locationsData?.business_units);
    const technologiesUpdatedDate = data.metadata?.crawled?.web_technologies;

    return (
        <TabContent>
            <BasicInfo
                loading={loading}
                loadingMode={domainProfileType}
                {...basicDataProps}
                onGoToClick={onTabChange}
            />
            {(isLoadingWebProfileData || isNotEmpty(descriptions)) && (
                <Descriptions skeleton={isLoadingWebProfileData} descriptions={descriptions} />
            )}
            {(isNotEmpty(locationsData?.business_units) || isLoadingLocationsData) && (
                <LocationsMap
                    skeleton={isLoadingLocationsData}
                    company_name={data.name}
                    countries={addressCountries}
                    addresses={addresses}
                    onGoToClick={onTabChange}
                />
            )}
            {(isNotEmpty(webProfileData?.technology_data) && webProfileData?.website) || isLoadingWebProfileData ? (
                <WebProfileTechnologiesList
                    skeleton={isLoadingWebProfileData}
                    technology_data={webProfileData?.technology_data}
                    website={webProfileData?.website}
                    updatedDate={technologiesUpdatedDate}
                    disableTable
                    onGoToClick={onTabChange}
                />
            ) : null}
        </TabContent>
    );
};

export default OverviewTab;
