import { AxiosInstance } from 'axios';

import { SitewideNotification } from './types/Notification';

export async function getUserNotifications(axios: AxiosInstance) {
    return axios.get<SitewideNotification[]>('/mapi/sitewide_notifications/');
}

export async function setNotificationSeen(axios: AxiosInstance, notificationId: string) {
    return axios.post('/mapi/sitewide_notifications/', { seen: notificationId });
}

export async function setNotificationDismissed(axios: AxiosInstance, notificationId: string) {
    return axios.post('/mapi/sitewide_notifications/', { dismiss: notificationId });
}
