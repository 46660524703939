import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { useRecoilState } from 'recoil';

import { useAxiosContext } from 'contexts/AxiosContext';
import reshapedAccountAtom from 'store/reshapedAccountAtom';
import { logSentry } from 'utilities/logUtils';

import {
    Account,
    UpdateAccountPayload,
    RemoveUserPayload,
    RemoveInviteTokenPayload,
    CreditUsageParams,
    CreditUsage,
    CRMUsageHistory,
} from './types/Account';
import { UsageInfo, ExportCount } from './types/Usage';

/**
 *
 * @param withUsers - If set to `True`, makes API call returning all the users for the account
 */
export function getAccount(axios: AxiosInstance, withUsers: boolean = false) {
    return axios.get<Account>(withUsers ? '/mapi/account/?with_users=1' : '/mapi/account/');
}

/**
 * Changing role, also deleting user.
 *
 * Data format to send for changing role:
 * @example
 * { change_role: { user: '5432534253', role: 'user' | 'admin' } }
 *
 * Data format to send for deleting user
 * @example
 * { remove_user: '54325325435' }
 */
export function updateAccount(axios: AxiosInstance, accountId: string, data: UpdateAccountPayload | RemoveUserPayload) {
    return axios.post<Account>(`/mapi/account/${accountId}/`, data);
}

/**
 * Remove invite token
 */
export function removeInviteToken(axios: AxiosInstance, data: RemoveInviteTokenPayload) {
    return axios.patch<Account>(`/mapi/account/`, data);
}

export function getCreditUsage(
    axios: AxiosInstance,
    accountId: string,
    params: CreditUsageParams,
    signal?: AbortSignal,
) {
    return axios.get<CreditUsage>(`/mapi/account/${accountId}/usage/`, { params, signal, disableErrorNotify: true });
}

export function getMatchedHistory(
    axios: AxiosInstance,
    params: { since: string; before: string },
    signal?: AbortSignal,
) {
    return axios.get<Array<CRMUsageHistory>>('/mapi/account/crm/usage/', { params, signal, disableErrorNotify: true });
}

export function getMatchedCount(axios: AxiosInstance, signal?: AbortSignal) {
    return axios.get<UsageInfo>('/mapi/ondemand-usage-data/data_point/?data_point=current_matched_companies', {
        signal,
    });
}

export function getTotalUpdatedCount(axios: AxiosInstance, signal?: AbortSignal) {
    return axios.get<UsageInfo>('/mapi/ondemand-usage-data/data_point/?data_point=total_updated_companies', {
        signal,
    });
}

export function getUsageInfo(axios: AxiosInstance) {
    return axios.get<UsageInfo>(
        '/mapi/ondemand-usage-data/data_point/?data_point=current_usage_period_start_date,exported_companies_quota,matched_companies_quota,updated_companies_quota',
    );
}

export function getExportCount(axios: AxiosInstance) {
    return axios.get<ExportCount>(`/mapi/ondemand-usage-data/data_point/?data_point=csv_exports`);
}

export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
export const USAGE_IS_ENABLED =
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'feature' ||
    process.env.REACT_APP_ENV === 'staging';

export function useUsageInfo({ enabled }: { enabled?: boolean }) {
    const axios = useAxiosContext();
    const [reshapedAccount] = useRecoilState(reshapedAccountAtom);

    const query = useQuery({
        queryKey: [
            'usage-current_usage_period_start_date',
            'exported_companies_quota',
            'matched_companies_quota',
            'updated_companies_quota',
        ],
        queryFn: () => getUsageInfo(axios).then((r) => r.data),
        enabled,
    });

    useEffect(() => {
        // this should not happen in production
        if (USAGE_IS_ENABLED && query.data && !query.data.current_usage_period_start_date) {
            logSentry(`current_usage_period_start_date was not returned for account ${reshapedAccount.accountId}`);
        }
    }, [query.data, reshapedAccount.accountId]);

    return query;
}

export function useExportCount({ enabled }: { enabled?: boolean }) {
    const axios = useAxiosContext();

    return useQuery({
        queryKey: ['usage-csv_exports'],
        queryFn: () => getExportCount(axios).then((r) => r.data),
        enabled,
    });
}
