import React from 'react';

function Icon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" {...props}>
            <path
                fill="#1F2A33"
                d="M23.751 7c-3.228 0-5.099 1.464-5.994 2.44-.109-.868-.678-1.98-2.902-1.98H10v5.042h1.98c.325 0 .434.108.434.434V36h5.75v-9.269c.895.813 2.604 1.951 5.289 1.951 5.614 0 9.547-4.445 9.547-10.84C33.027 11.363 29.284 7 23.75 7m-1.318 17C19.39 24 18 20.944 18 18.084 18 13.598 20.323 12 22.513 12 25.184 12 27 14.411 27 18.028 26.973 22.178 24.677 24 22.433 24"
            />
        </svg>
    );
}

export default Icon;
