import React from 'react';

const ChevronLeftDuoSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.5852 13.01L0.575195 6.99999L6.5852 0.98999L8.0002 2.40399L3.4002 7.00399L8.0002 11.604L6.5862 13.01H6.5852ZM12.0102 13.01L5.9992 6.99999L12.0102 0.98999L13.4242 2.40399L8.8242 7.00399L13.4242 11.604L12.0112 13.01H12.0102Z"
                fill="#2F80ED"
            />
        </svg>
    );
};

export default ChevronLeftDuoSvg;
