import { memo } from 'react';

import Grid from '@mui/material/Grid';

import { AreaChart, BarChart } from 'components/templates/charts';

import NumberBox, { NumberBoxSkeleton } from '../NumberBox';
import { FinancialDataPoint } from '../types';

export type Mode = 'group' | 'company';

export interface FinancialsChartSectionProps {
    groupRevenue?: { value: number | null; currency_code: string | null };
    groupEmployees?: number | null;
    revenue?: FinancialDataPoint;
    employees?: FinancialDataPoint;
    ebit?: FinancialDataPoint;
    equityRatio?: FinancialDataPoint;
    mode: Mode;
    skeleton?: boolean;
}

export const FinancialsChartSection: React.FC<FinancialsChartSectionProps> = memo(
    ({ revenue, employees, ebit, equityRatio, mode, skeleton = false, groupEmployees, groupRevenue }) => {
        if (skeleton) {
            return (
                <Grid container spacing={2} rowSpacing={5}>
                    <Grid item xs={12} md={6}>
                        <NumberBoxSkeleton height={321} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberBoxSkeleton height={321} />
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid container spacing={2} rowSpacing={5}>
                {mode === 'group' && groupRevenue !== undefined && (
                    <Grid item xs={12} md={6}>
                        <NumberBox
                            label="Group Revenue"
                            value={groupRevenue.value ?? 0}
                            valueStyle="currency"
                            currency={groupRevenue.currency_code ?? ''}
                        />
                    </Grid>
                )}
                {mode === 'group' && groupEmployees !== undefined && (
                    <Grid item xs={12} md={6}>
                        <NumberBox label="Group Employees" value={groupEmployees || 0} valueStyle="integer" />
                    </Grid>
                )}
                {revenue !== undefined && (
                    <Grid item xs={12} md={6}>
                        <NumberBox
                            label="Revenue"
                            value={revenue.value}
                            valueStyle="currency"
                            change={revenue.change}
                            currency={revenue.currency}
                            reportYear={revenue.year}
                            chart={
                                revenue.data !== undefined &&
                                revenue.data.length !== 0 && (
                                    <BarChart
                                        data={revenue.data}
                                        max={5}
                                        cutFromStart
                                        numberStyle="currency"
                                        currency={revenue.currency}
                                    />
                                )
                            }
                        />
                    </Grid>
                )}
                {employees !== undefined && (
                    <Grid item xs={12} md={6}>
                        <NumberBox
                            label="Employees"
                            value={employees.value}
                            valueStyle="integer"
                            change={employees.change}
                            reportYear={employees.year}
                            chart={
                                employees.data !== undefined && <BarChart data={employees.data} max={5} cutFromStart />
                            }
                        />
                    </Grid>
                )}
                {ebit !== undefined && (
                    <Grid item xs={12} md={6}>
                        <NumberBox
                            label="EBIT (%)"
                            value={ebit.value}
                            valueStyle="percent"
                            change={ebit.change}
                            reportYear={ebit.year}
                            chart={
                                ebit.data !== undefined && (
                                    <AreaChart
                                        data={ebit.data}
                                        variant="posneg"
                                        max={5}
                                        numberStyle="percent"
                                        cutFromStart
                                    />
                                )
                            }
                        />
                    </Grid>
                )}
                {equityRatio !== undefined && (
                    <Grid item xs={12} md={6}>
                        <NumberBox
                            label="Equity Ratio (%)"
                            value={equityRatio.value}
                            valueStyle="percent"
                            change={equityRatio.change}
                            reportYear={equityRatio.year}
                            chart={
                                equityRatio.data !== undefined && (
                                    <BarChart data={equityRatio.data} max={5} cutFromStart />
                                )
                            }
                        />
                    </Grid>
                )}
            </Grid>
        );
    },
);

export default FinancialsChartSection;
