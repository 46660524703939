import React from 'react';

import { Skeleton } from '@mui/material';

import FormattedValue, { FormattedValueProps } from './FormattedValue';
import NumberDataPointContent, { NumberDataPointBaseProps } from './NumberDataPointContent';

type FormattedValueNoSkeletonProps = FormattedValueProps & {
    skeleton?: false;
};

type SkeletonValueProps = {
    skeleton: true;
    value?: unknown;
    style?: unknown;
};

export type NumberDataPointProps = (FormattedValueNoSkeletonProps | SkeletonValueProps) & NumberDataPointBaseProps;

export const NumberDataPoint: React.FC<NumberDataPointProps> = (props) => {
    const { label, skeleton } = props;

    return (
        <NumberDataPointContent {...props} label={skeleton ? <Skeleton variant="text" width={60} /> : label}>
            {skeleton ? <Skeleton variant="text" width={80} /> : <FormattedValue {...props} />}
        </NumberDataPointContent>
    );
};

export default NumberDataPoint;
