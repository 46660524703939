import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Pdf = (props) => (
    <SvgIcon {...props}>
        <path d="M18 22H6C4.89543 22 4 21.1046 4 20V4.00002C4 2.89545 4.89543 2.00002 6 2.00002H13C13.009 1.99886 13.018 1.99886 13.027 2.00002H13.033C13.0424 2.00298 13.0522 2.00498 13.062 2.00602C13.1502 2.01167 13.2373 2.02882 13.321 2.05702H13.336H13.351H13.363C13.3815 2.06994 13.3988 2.08432 13.415 2.10002C13.5239 2.14844 13.6232 2.2162 13.708 2.30002L19.708 8.30002C19.7918 8.3848 19.8596 8.48407 19.908 8.59302C19.917 8.61502 19.924 8.63602 19.931 8.65902L19.941 8.68702C19.9689 8.77041 19.9854 8.8572 19.99 8.94502C19.9909 8.95498 19.9932 8.96476 19.997 8.97402V8.98002C19.9986 8.98657 19.9996 8.99327 20 9.00002V20C20 20.5305 19.7893 21.0392 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22ZM14.424 14V19H15.364V16.96H16.824V16.122H15.364V14.841H17V14H14.424ZM10.724 14V19H11.93C12.4359 19.0249 12.9258 18.8189 13.262 18.44C13.6069 17.9999 13.7797 17.4493 13.748 16.891V16.081C13.7712 15.5286 13.5936 14.9866 13.248 14.555C12.9226 14.1847 12.4476 13.9808 11.955 14H10.724ZM7 14V19H7.94V17.241H8.566C8.98402 17.2642 9.39232 17.1094 9.69 16.815C9.97408 16.4974 10.1214 16.0806 10.1 15.655C10.1186 15.2192 9.97135 14.7926 9.688 14.461C9.40772 14.1502 9.00309 13.9811 8.585 14H7ZM13 4.00002V9.00002H18L13 4.00002ZM11.946 18.162H11.664V14.841H12.006C12.2489 14.8267 12.4824 14.9367 12.626 15.133C12.7726 15.4363 12.8354 15.7733 12.808 16.109V16.978C12.83 17.2978 12.7606 17.6172 12.608 17.899C12.4441 18.0903 12.1965 18.1887 11.946 18.162ZM8.585 16.4H7.939V14.841H8.594C8.75752 14.8428 8.90863 14.9285 8.994 15.068C9.10222 15.2466 9.15447 15.4535 9.144 15.662C9.15654 15.8565 9.1049 16.0497 8.997 16.212C8.89754 16.3369 8.74447 16.4067 8.585 16.4Z" />
    </SvgIcon>
);

export default Pdf;
