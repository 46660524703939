import React from 'react';

const BannerRightBoxes = (props) => (
    <svg width="376" height="310" viewBox="0 0 376 310" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M124.544 0C128.962 0 132.544 3.58172 132.544 8V123C132.544 127.418 128.962 131 124.544 131H8.14258C3.7243 131 0.142578 127.418 0.142578 123V8C0.142578 3.58172 3.7243 0 8.14258 0H124.544ZM58.0068 237.311C58.0068 241.729 61.5886 245.311 66.0068 245.311H131.011C135.429 245.311 139.011 241.729 139.011 237.311V170.717C139.011 166.299 135.429 162.717 131.011 162.717H66.0068C61.5886 162.717 58.0068 166.299 58.0068 170.717V237.311ZM206.589 253.717C206.589 258.135 210.171 261.717 214.589 261.717H268.224C272.642 261.717 276.224 258.135 276.224 253.717V198.715C276.224 194.297 272.642 190.715 268.224 190.715H214.589C210.171 190.715 206.589 194.297 206.589 198.715V253.717ZM367.771 310C372.189 310 375.771 306.418 375.771 302V275.736C375.771 271.318 372.189 267.736 367.771 267.736H362.743V252.793C362.743 248.374 359.161 244.793 354.743 244.793H321.002C316.584 244.793 313.002 248.374 313.002 252.793V286.302C313.002 290.72 316.584 294.302 321.002 294.302H334.32V302C334.32 306.418 337.902 310 342.32 310H367.771Z"
        />
    </svg>
);

export default BannerRightBoxes;
