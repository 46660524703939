// TODO: This file usage should be replaced with utilities/planhatOwnersData

import axios from 'axios';

import { LocalStorage } from 'utilities/localStorage';

export interface PlanhatOwnersProps {
    cs_owner: string;
    cs_owner_fullname: string;
    cs_co_owner: string;
    cs_co_owner_fullname: string;
}

const base_url = window.location.origin;

const fetchPlanhatCsOwners = async () => {
    try {
        const { data: response } = await axios.get(`${base_url}/mapi/analyticsdata/`);
        const planhatPayload = {
            cs_owner: response?.cs_owner,
            cs_owner_fullname: response?.cs_owner_fullname,
            cs_co_owner: response?.cs_co_owner,
            cs_co_owner_fullname: response?.cs_co_owner_fullname,
        } as PlanhatOwnersProps;
        return planhatPayload;
    } catch (e) {
        return null;
    }
};
export const setPlanhatOwnersToStorage = async () => {
    const csOwnersFromStorage = getPlanhatOwnersFromStorage();
    if (!csOwnersFromStorage) {
        const data = await fetchPlanhatCsOwners();
        if (data) {
            LocalStorage.setItem('planhat-owners', data);
        }
    }
};

export const getPlanhatOwnersFromStorage = () => {
    return LocalStorage.getItem<PlanhatOwnersProps>('planhat-owners');
};
