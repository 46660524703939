import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowLongLeft = (props) => (
    <SvgIcon {...props}>
        <path d="M5.83 11L8.41 8.41L7 7L2 12L7 17L8.41 15.59L5.83 13H22V11H5.83Z" />
    </SvgIcon>
);

export default ArrowLongLeft;
