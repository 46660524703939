import { TableCell } from '@mui/material';

import Typography, { TypographyProps } from 'components/tokens/Typography';

import { StatementSectionVariant } from './StatementSection';

const baseIndent = 0;

const levelIndents: Record<StatementSectionVariant, number[]> = {
    balanceSheet: [0, 5, 3, 5, 7, 9],
    incomeStatement: [0, 3, 5, 7, 9],
};

const getLevelIndent = (level: number, variant: StatementSectionVariant, total?: boolean) => {
    if (variant === 'balanceSheet' && level === 1 && total) {
        return 0;
    }
    const indents = variant === 'balanceSheet' ? levelIndents.balanceSheet : levelIndents.incomeStatement;
    return baseIndent + (indents[level] ?? indents[indents.length - 1]);
};

export type LabelCellProps = {
    level: number;
    variant: StatementSectionVariant;
    total?: boolean;
    children?: React.ReactNode;
};
const balanceSheetLevelStyles: TypographyProps[] = [
    {
        weight: 'bold',
    },
    {
        variant: 'small',
        weight: 'semibold',
        textTransform: 'uppercase',
    },
    {
        variant: 'small',
        weight: 'semibold',
    },
    {
        variant: 'tiny',
        fontStyle: 'italic',
    },
];

const balanceSheetLevel1TotalStyles: TypographyProps = {
    variant: 'small',
    weight: 'semibold',
};

const incomeStatementLevelStyles: TypographyProps[] = [balanceSheetLevelStyles[0], ...balanceSheetLevelStyles.slice(2)];

const levelStyles: Record<StatementSectionVariant, TypographyProps[]> = {
    balanceSheet: balanceSheetLevelStyles,
    incomeStatement: incomeStatementLevelStyles,
};

const getLevelStyles = (level: number, variant: StatementSectionVariant, total?: boolean) => {
    if (variant === 'balanceSheet' && level === 1 && total) {
        return balanceSheetLevel1TotalStyles;
    }
    const styles = variant === 'balanceSheet' ? levelStyles.balanceSheet : levelStyles.incomeStatement;
    return styles[level] ?? styles[styles.length - 1];
};

export const LabelCell: React.FC<LabelCellProps> = ({ level, variant, total, children }) => {
    return (
        <TableCell sx={{ width: 320, paddingLeft: getLevelIndent(level, variant, total) }}>
            <Typography {...getLevelStyles(level, variant, total)}>{children}</Typography>
        </TableCell>
    );
};

export default LabelCell;
