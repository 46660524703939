import React, { useState } from 'react';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';

import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import { formatDate } from 'utilities/date';

import { Order } from '..';
import { getComparator, stableSort } from '../utils';
import { TableCellFirstColumn } from './TableCell/TableCellFirstColumn';
import { TableCellSecondColumn } from './TableCell/TableCellSecondColumn';
import {
    StyledMuiTableContainer,
    StyledMuiTable,
    StyledMuiTableRow,
    StyledMuiTableSortLabel,
    StyledMuiTablePagination,
} from './tableStyled';

interface Statement {
    text: string;
    type_code: string;
}

interface ClassDescription {
    international_code_total_no: string;
    us_code_total_no: string;
    status_code: string;
    status_date: string;
    first_use_in_commerce_date: string;
    primary_code: string;
    us_code: string;
    international_code: string;
    first_use_anywhere_date: string;
}

interface Miscellaneous {
    filing_basis_filed_as_66a_in: string;
    filing_basis_filed_as_44d_in: string;
    status_code: string;
    current_location: string;
    section_15_acknowledged_in: string;
    amended_to_use_application_in: string;
    amended_to_44d_application_in: string;
    section_15_filed_in: string;
    section_2f_in: string;
    standard_characters_claimed_in: string;
    mark_drawing_code: string;
    intent_to_use_current_in: string;
    drawing_3d_current_in: string;
    opposition_pending_in: string;
    filing_current_no_basis_in: string;
    collective_trademark_in: string;
    location_date: string;
    section_8_partial_accept_in: string;
    change_registration_in: string;
    certification_mark_in: string;
    color_drawing_filed_in: string;
    renewal_filed_in: string;
    section_12c_in: string;
    law_office_assigned_location_code: string;
    trademark_in: string;
    collective_service_mark_in: string;
    concurrent_use_proceeding_in: string;
    foreign_priority_in: string;
    section_8_filed_in: string;
    mark_identification: string;
    supplemental_register_in: string;
    status_date: string;
    supplemental_register_amended_in: string;
    filed_as_use_application_in: string;
    amended_to_44e_application_in: string;
    interference_pending_in: string;
    without_basis_currently_in: string;
    amended_to_itu_application_in: string;
    service_mark_in: string;
    section_8_accepted_in: string;
    concurrent_use_in: string;
    filing_basis_current_44d_in: string;
    filing_basis_filed_as_44e_in: string;
    section_2f_in_part_in: string;
    intent_to_use_in: string;
    published_concurrent_in: string;
    use_application_currently_in: string;
    cancellation_pending_in: string;
    filing_basis_current_44e_in: string;
    abandonment_date: string;
    filing_basis_current_66a_in: string;
    collective_membership_mark_in: string;
    drawing_3d_filed_in: string;
    principal_register_amended_in: string;
    color_drawing_current_in: string;
}

interface Applicant {
    city: string;
    party_type: string;
    business_id: string;
    legal_entity_type_code: string;
    address_1: string;
    country: string;
    entry_number: string;
    company_name: string;
}

interface Record {
    description_text: string;
    date: string;
    code: string;
    type: string;
    number: string;
}

export type Trademark = Partial<{
    vid: number;
    modified: string;
    created: string;
    source: string;
    registration_country: string;
    application_number: string;
    application_date: string;
    expiry_date: string;
    current_status: string;
    current_status_details: string;
    current_status_date: string;
    trademark_feature: string;
    text_of_mark: string;
    statements: Statement[];
    registration_number: string;
    registration_date: string;
    class_description: ClassDescription[];
    miscellaneous: Miscellaneous;
    applicants: Applicant[];
    record: Record[];
    business_ids: string[];
    applicant_business_ids: string[];
    representative_business_ids: string[];
    filing_date: string;
    renewal_date: string;
}>;

enum TrademarkSortField {
    text_of_mark = 'text_of_mark',
    trademark_feature = 'trademark_feature',
    registration_country = 'registration_country',
    filing_date = 'filing_date',
}
export type TrademarkSortFieldStrings = keyof typeof TrademarkSortField;
export type TrademarkSortValues = {
    id: TrademarkSortFieldStrings;
    label: string;
};

export type TrademarkHeaders = TrademarkSortValues[];

const trademarkHeaders: TrademarkHeaders = [
    {
        id: 'trademark_feature',
        label: 'Trademark feature',
    },
    {
        id: 'text_of_mark',
        label: 'Text of mark',
    },
    {
        id: 'registration_country',
        label: 'Registration country',
    },
    {
        id: 'filing_date',
        label: 'Filing date',
    },
    // {
    //     id: 'current_status',
    //     disablePadding: false,
    //     label: 'Current status',
    // },
];

type TrademarkTableBodyProps = {
    tableData: Trademark[];
};

const TrademarkTable: React.FC<TrademarkTableBodyProps> = ({ tableData }) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<TrademarkSortFieldStrings>('trademark_feature');
    const [page, setPage] = useState(0);

    const handleRequestSort = (property: TrademarkSortFieldStrings) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    return (
        <Frame title={`Trademarks (${tableData.length})`}>
            <StyledMuiTableContainer>
                <StyledMuiTable>
                    <TableHead>
                        <StyledMuiTableRow>
                            {trademarkHeaders.map((header) => {
                                return (
                                    <TableCell key={header.id} sortDirection={orderBy === header.id ? order : false}>
                                        <StyledMuiTableSortLabel
                                            active={orderBy === header.id}
                                            direction={order}
                                            onClick={handleRequestSort(header.id)}
                                        >
                                            {header.label}
                                            {orderBy === header.id && (
                                                <Icon
                                                    sx={{ marginLeft: '4px' }}
                                                    fontSize="mini"
                                                    type={order === 'asc' ? 'ArrowShortUp' : 'ArrowShortDown'}
                                                />
                                            )}
                                        </StyledMuiTableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </StyledMuiTableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(tableData, getComparator(order, orderBy))
                            .slice(page * 7, page * 7 + 7)
                            .map((row: Trademark) => {
                                return (
                                    <StyledMuiTableRow key={row.application_number}>
                                        <TableCellFirstColumn>{row.trademark_feature || '-'}</TableCellFirstColumn>
                                        <TableCellSecondColumn>{row.text_of_mark || '-'}</TableCellSecondColumn>
                                        <TableCell>{row.registration_country || '-'}</TableCell>
                                        <TableCell>{row.filing_date ? formatDate(row.filing_date) : '-'}</TableCell>
                                    </StyledMuiTableRow>
                                );
                            })}
                    </TableBody>
                </StyledMuiTable>
            </StyledMuiTableContainer>
            <StyledMuiTablePagination
                rowsPerPage={7}
                rowsPerPageOptions={[7]}
                component="div"
                page={page}
                count={tableData.length}
                onPageChange={handlePageChange}
            />
        </Frame>
    );
};

export default TrademarkTable;
