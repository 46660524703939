import React, { useState } from 'react';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Select from 'components/tokens/select-components/Select';

import { mergeKeys } from '../../common/utils';
import { getSameFiltersState } from '../../filters/utils';
import { FilterGroup, FilterGroupProps, FilterID, GroupValues, NodeValue } from '../../FilterTypes';
import { getGroupFilters, useFilterGroupOptions } from '../common/groupUtils';
import { useGroupActions, useGroupNodes } from '../common/useGroup';
import FilterGroupTemplate from '../FilterGroupTemplate';

const options = [
    {
        value: FilterID.social_media_channels,
        label: 'Social media channels',
    },
    {
        value: FilterID.social_media_index,
        label: 'Social media index',
    },
    {
        value: FilterID.twitter_followers,
        label: 'Twitter followers',
    },
];

const createNewGroup = (value: NodeValue) =>
    ({
        [FilterOperator.MATCH]: {
            [GroupValues.social_media]: { [FilterOperator.ALL]: [value] },
        },
    }) as NodeValue;

const config: FilterGroup = {
    group: GroupValues.social_media,
    label: 'Social media',
    options,
    filterUUIDs: [],
    logic: {},
    render: (props: FilterGroupProps) => <SocialMediaGroup {...props} />,
    getFilters: getGroupFilters,
    createNewGroup,
};

export const socialMediaGroupDefinition: Record<GroupValues.social_media, FilterGroup> = {
    [GroupValues.social_media]: config,
};

export const SocialMediaGroup: React.VFC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    removeGroup,
    ids = [],
    addNewGroup,
    updateGroup,
    updateGroups,
    disabled = false,
}) => {
    const filterGroups = useGroupNodes(ids);

    const { activeFilters, filterToHide, filteredOptions } = useFilterGroupOptions({ filterGroups, options });

    const { handleAddFilter, handleFilterRemove, keys, handleApplyChanges } = useGroupActions({
        applyChanges,
        removeGroup,
        addNewGroup,
        updateGroup,
        updateGroups,
        createNewGroup,
        filterGroups,
        options: filteredOptions,
    });

    const renderFilters = () => {
        return ids.map((id) =>
            filterGroups[id].filters.map((filter, index) => {
                const filterDefinition = filter.definition;
                const sameFiltersState = getSameFiltersState(activeFilters, filter);

                return filterDefinition
                    ? filterDefinition.render({
                          key: keys[mergeKeys(id, filter.id)],
                          uuid: filterDefinition.id,
                          filter,
                          groupUUID: config.group,
                          removeFilter: () => handleFilterRemove(id, index),
                          sameFiltersState: sameFiltersState,
                      })
                    : null;
            }),
        );
    };

    const [addRow, setAddRow] = useState(false);
    const [isFilterAdded, setIsFilterAdded] = useState(false);

    const addRowSelect = (
        <Select
            placeholder="Select"
            width="100%"
            multiple={false}
            value={null}
            autoFocus
            openOnFocus
            sx={{ gridArea: 'add' }}
            options={filteredOptions || []}
            onValueChange={(option) => {
                if (Array.isArray(option)) {
                    throw new Error('should not be array value');
                }
                if (typeof option === 'number') {
                    throw new Error('should not be number value');
                }
                if (typeof option === 'string') {
                    throw new Error('should not be string value');
                }
                if (!option) {
                    return;
                }
                handleAddFilter(option.value as FilterID);
                setAddRow(false);
                setIsFilterAdded(true);
            }}
        />
    );
    const addRowButton = (
        <Button
            variant="tertiary"
            size="small"
            onClick={() => setAddRow(true)}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            AND
        </Button>
    );

    const renderedFilters = renderFilters();
    const props = {
        label: config.label,
        filters: renderedFilters,
        addFilter: handleAddFilter,
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        addRowSelect,
        addRowButton,
        addRow,
        isFilterAdded,
        setIsFilterAdded,
        filterToHide,
        disabled,
    };
    return <FilterGroupTemplate {...props} />;
};
