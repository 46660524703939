import { FC, ReactNode, useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { FilterOperator } from 'api/types/FilterOperators';
import { mergeKeys } from 'components/features/Filters/common/utils';
import { filterDefinitions } from 'components/features/Filters/filters/filterDefinitions';
import { getSameFiltersState, getValue, makeFieldType } from 'components/features/Filters/filters/utils';
import Button from 'components/tokens/Button';
import FeedbackBanner from 'components/tokens/FeedbackBanner/FeedbackBanner';
import { SelectOption } from 'components/tokens/select-components/Select';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';
import { Database } from 'contexts/ListsContext';

import {
    FilterGroup,
    FilterGroupProps,
    FilterID,
    GroupOptionRaw,
    GroupValues,
    LocationFilterProps,
    NodeValue,
} from '../../../FilterTypes';
import { getGroupFilters } from '../../common/groupUtils';
import { getMergedLabel } from '../../common/optionsUtils';
import { AddFilterAction } from './AddFilterButton';
import LocationFilterGroupTemplate, {
    LocationFilterGroupTemplateProps,
    SlotGridRow,
} from './LocationFilterGroupTemplate';
import { useActiveFilter } from './useActiveFilter';
import { useAddRowAction } from './useAddRowAction';
import { useLocationGroupActions } from './useLocationGroup';
import { getOfficeTypeValue, getOfficeTypeValueFromFilter, isFilterValueEmpty, Slot } from './utils/helpers';
import { getRemovableStatuses, validateGroup } from './utils/validation';
import { locationGroupStateReducer } from './validation/state';

const COUNTRY_DATABASES: Database[] = ['FI', 'NO', 'DK', 'SE'];

const basicOptions: GroupOptionRaw[] = [
    {
        value: FilterID.visiting_city,
        databases: COUNTRY_DATABASES, // Temp hidden from GLOBAl because of bad data
    },
    {
        value: FilterID.location_type,
    },
    {
        value: FilterID.country,
        databases: ['DOMAIN_DATA_BASIC'],
    },
    {
        value: FilterID.prospect_address_count,
    },
    {
        value: FilterID.country_count,
    },
    { value: FilterID.address, databases: COUNTRY_DATABASES },
    { value: FilterID.postal, databases: COUNTRY_DATABASES },
    { value: FilterID.country_area, databases: COUNTRY_DATABASES },
    { value: FilterID.municipality, databases: COUNTRY_DATABASES },
    { value: FilterID.coordinates, databases: COUNTRY_DATABASES },
];

const options = basicOptions.map((o) => ({ ...o, label: getMergedLabel(o) }));
const createNewGroup = (value: NodeValue) =>
    ({
        [FilterOperator.MATCH]: {
            [GroupValues.location_visiting_address]: { [FilterOperator.ALL]: [value] },
        },
    }) as NodeValue;

const config: FilterGroup = {
    group: GroupValues.location_visiting_address,
    label: 'Location',
    options: options,
    filterUUIDs: [],
    logic: {},
    match: true,
    render: (props: FilterGroupProps) => <LocationGroup {...props} />,
    getFilters: getGroupFilters,
    createNewGroup,
};

export const locationGroupDefinition: Record<GroupValues.location_visiting_address, FilterGroup> = {
    [GroupValues.location_visiting_address]: config,
};

export const LocationGroup: FC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    ids,
    updateGroups,
    disabled = false,
}) => {
    const {
        slots,
        setSlots,
        handleAddFilter,
        handleFilterRemove,
        handleApplyChanges,
        handleSlotRemove,
        handleSlotClear,
    } = useLocationGroupActions({
        ids,
        applyChanges,
        updateGroups,
    });

    const { locationTypeOptions } = useFilterOptionsContext();

    const validationResult = useMemo(() => validateGroup(slots), [slots]);

    const removableStatus = useMemo(() => getRemovableStatuses(slots), [slots]);

    const [filterState, setFilterState] = useReducer(locationGroupStateReducer, { value: 'default' });

    const [isFilterAdded, setIsFilterAdded] = useState(false);

    const locationOptionsCount = locationTypeOptions.length;
    useActiveFilter({ slots, onAddFilter: handleAddFilter, locationOptionsCount, skip: slots.length === 0 });

    const renderedFilters = useMemo(() => {
        const officeTypeValues: string[] = [];
        let locationSlotsCount = 0;
        return slots.map((slot, slotIndex) => {
            const slotValidationResult = validationResult[slotIndex];

            if (slot.type === 'location') {
                locationSlotsCount++;
            }

            let isMapFilterAdded = false;
            const renderedValues = slot.values.reduce((filters, filter, filterIndex) => {
                const sameFiltersState = getSameFiltersState(slot.values, filter);

                const types = getOfficeTypeValueFromFilter(filter);

                if (types !== 'any') {
                    types.forEach((type) => {
                        officeTypeValues.push(type);
                    });
                }

                const filterDefinition = filterDefinitions[filter.id as keyof typeof filterDefinitions];

                if (!filterDefinition) {
                    return filters;
                }

                let forceOpen = false;

                if (filterDefinition.id !== FilterID.location_type) {
                    const value = getValue(makeFieldType(filterDefinition.id), filter.value);
                    forceOpen = isFilterValueEmpty(value);
                }

                const filterKeysId = mergeKeys(slotIndex, filterIndex, filter.id);

                const renderProps: Omit<LocationFilterProps, 'filterOption'> = {
                    key: filterKeysId,
                    uuid: filter.uuid,
                    filter: {
                        ...filter,
                        groupPath: mergeKeys(slotIndex, 'values', filterIndex, 'value'),
                        path: '',
                    },
                    groupUUID: config.group,
                    removeFilter: () => {
                        setFilterState('filterRemoved');
                        handleFilterRemove(slotIndex, filterIndex);
                    },
                    forceOpen: forceOpen,
                    apply: (data) => {
                        if (filter.id !== FilterID.location_type) {
                            setFilterState('filterUpdated');
                        }

                        setSlots((prev) => {
                            const updated = [...prev];

                            updated[slotIndex].values[filterIndex].value = data.value;
                            updated[slotIndex].values[filterIndex].valuePath = data.valuePath;

                            return updated;
                        });
                    },

                    onClose: () => {
                        if (filter.id !== FilterID.location_type) {
                            setFilterState('filterClosed');
                        }
                    },
                    onOpen: () => {
                        if (filter.id !== FilterID.location_type) {
                            setFilterState('filterOpened');
                        }
                    },
                };

                const allowErrorState =
                    filterState.allowWarning &&
                    slotValidationResult.isLocationTypeError &&
                    filter.id !== FilterID.location_type;

                const showErrorState = slotValidationResult.message && allowErrorState;

                const level = slotValidationResult.level ?? 'err';

                if (filter.id === FilterID.coordinates) {
                    if (!isMapFilterAdded) {
                        isMapFilterAdded = true;

                        filters.push(
                            filterDefinition.render<LocationFilterProps>({
                                ...renderProps,
                                filterOption: () => true,
                                warningLevel: showErrorState ? level : undefined,
                            }),
                        );
                    }

                    return filters;
                } else {
                    filters.push(
                        filterDefinition.render<LocationFilterProps>({
                            ...renderProps,
                            sameFiltersState,
                            filterOption: (option: unknown) => {
                                if (locationSlotsCount > 1 && filter.id === FilterID.location_type) {
                                    if (officeTypeValues.length && (option as SelectOption).value === '') {
                                        return false;
                                    }
                                    return !officeTypeValues.includes((option as SelectOption).value.toString());
                                }
                                return true;
                            },
                            warningLevel: showErrorState ? level : undefined,
                        }),
                    );
                }

                return filters;
            }, [] as ReactNode[]);

            const locationTypeIndex = renderedValues.findIndex(
                (node) => (node as JSX.Element).props.filter.id === FilterID.location_type,
            );
            if (locationTypeIndex !== -1) {
                renderedValues.unshift(renderedValues.splice(locationTypeIndex, 1)[0]);
            }

            if (slotValidationResult.message) {
                if (slotValidationResult.isLocationTypeError && !filterState.allowWarning) {
                    return renderedValues;
                }

                renderedValues.push(
                    <FeedbackBanner
                        label={slotValidationResult.message}
                        severity={slotValidationResult.level === 'warn' ? 'warning' : 'error'}
                    />,
                );
            }

            return renderedValues;
        });
    }, [filterState.allowWarning, handleFilterRemove, setSlots, slots, validationResult]);

    useEffect(() => {
        const locationSlots = slots.filter((slot) => slot.type === 'location');

        if (locationSlots.length) {
            locationSlots.forEach((slot) => {
                if (!slot.values.length || !slot.values.some((v) => v.id === FilterID.location_type)) {
                    handleAddFilter(FilterID.location_type, slot.id);
                    setIsFilterAdded(true);
                }
            });
        }
    }, [handleAddFilter, slots]);

    const renderGroupClearAction = useCallback(
        (slotId: number) => {
            const currentStatus = removableStatus[slotId];

            if (currentStatus !== 'null' && currentStatus !== 'withdraw') {
                const handler = currentStatus === 'clear' ? handleSlotClear : handleSlotRemove;
                return (
                    <Button variant="flat" onClick={() => handler(slotId)} size="small">
                        Clear group
                    </Button>
                );
            } else {
                return null;
            }
        },
        [handleSlotClear, handleSlotRemove, removableStatus],
    );

    const getAddRowAction = useAddRowAction({
        options,
        onValueChange: handleAddFilter,
        validationResult,
        slots,
        locationOptionsCount,
    });

    const renderAddRowAction = useCallback(
        (index: number | undefined) => {
            const actionProps = getAddRowAction(index);
            if (!actionProps) {
                return null;
            }

            const { rowOptions, label, isInnerAddRowLabel, handleValueChange } = actionProps;
            let forceOpen = false;

            // Auto open add row select if Location type is chosen
            if (isInnerAddRowLabel && index !== undefined) {
                const values = slots[index].values;
                if (values.length === 1) {
                    const types = getOfficeTypeValue(slots[index].values);
                    if (types !== 'any') {
                        if (rowOptions.length === 1) {
                            handleValueChange(options[0]);
                        } else {
                            forceOpen = true;
                        }
                    }
                }
            }

            return (
                <AddFilterAction
                    options={rowOptions}
                    onValueChange={handleValueChange}
                    label={label}
                    isInnerAddRowLabel={isInnerAddRowLabel}
                    forceOpen={forceOpen}
                    onOpen={() => {
                        setFilterState('addRowActionOpened');
                    }}
                    onClose={() => {
                        setFilterState('addRowActionClosed');
                    }}
                />
            );
        },
        [getAddRowAction, slots],
    );

    const slotRows = useMemo(
        () => renderSlots({ slots, renderAddRowAction, renderGroupClearAction, renderedFilters }),
        [renderAddRowAction, renderGroupClearAction, renderedFilters, slots],
    );

    const props: LocationFilterGroupTemplateProps = {
        label: config.label,
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        isFilterAdded,
        setIsFilterAdded,
        rows: slotRows,
        disabled,
    };
    return <LocationFilterGroupTemplate {...props} />;
};

interface RenderSlotsProps {
    slots: Slot[];
    renderAddRowAction: (slotId?: number) => React.ReactNode;
    renderGroupClearAction: (slotId: number) => React.ReactNode;
    renderedFilters: React.ReactNode[][];
}

export function renderSlots({ slots, renderAddRowAction, renderGroupClearAction, renderedFilters }: RenderSlotsProps) {
    let isLast = false;
    const slotRows: React.ReactNode[] = [];

    slots.forEach((slot, index) => {
        if (slot.type !== 'location' && !isLast) {
            isLast = true;
            const action = renderAddRowAction(undefined);
            if (action) {
                slotRows.push(<SlotGridRow key="add-office-type-button" addRowAction={action} />);
            }
        }

        const row = (
            <SlotGridRow
                key={`slot-row-${slot.id}`}
                data-test-id={`e2e-location-group-slot-${slot.id}`}
                filters={renderedFilters[index]}
                addRowAction={renderAddRowAction(index)}
                clearAction={renderGroupClearAction(index)}
            />
        );

        slotRows.push(row);
    });

    return slotRows;
}
