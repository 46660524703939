import { Ensure } from 'utilities';

import { FinancialStatement } from 'api/types/FinancialStatement';

export type ValidFinancialStatement = Ensure<FinancialStatement, 'accounting_period' | 'years'> & {
    accounting_period: {
        start?: string | null;
        end: string;
    };
};

export const isValidStatement = (statement: FinancialStatement): statement is ValidFinancialStatement =>
    Array.isArray(statement.years) && statement.years.length > 0 && statement.accounting_period?.end != null;
