import * as React from 'react';

import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { isNil } from 'utilities';

import Icon from 'components/tokens/Icon';

import SeparateFileIndicator from '../Treeview/SeparateFileIndicator';
import { DisabledItem } from './DragAndDrop';

type Item = {
    id: string;
    name: string;
    args?: string;
};

type ListItemStyledProps = {
    isDragging: boolean;
    isDisabled: boolean;
};

export type DraggableListItemProps = {
    item: Item;
    disabledItems: DisabledItem[];
    index: number;
    removeItem: (index: number) => void;
};

const DraggableListItem = ({ item, disabledItems, index, removeItem }: DraggableListItemProps) => {
    const [newDisabledItems, setNewDisabledItems] = React.useState(disabledItems);
    React.useEffect(() => {
        setNewDisabledItems(disabledItems);
    }, [disabledItems]);

    const isDisabled = (name: string): boolean =>
        newDisabledItems?.some((i) => i.name.toLowerCase() === name.toLowerCase());

    return (
        <Draggable draggableId={item.id} index={index} isDragDisabled={isDisabled(item?.name)}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                <ListItem
                    sx={{ padding: 0, marginBottom: '8px' }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <StyledDiv isDragging={snapshot.isDragging} isDisabled={isDisabled(item.name)}>
                        <ListItemAvatar
                            sx={{
                                minWidth: '20px',
                                marginRight: '11.5px',
                                display: isDisabled(item.name) ? 'none' : 'flex',
                            }}
                        >
                            <Avatar
                                sx={{
                                    background: 'none',
                                    width: '20px',
                                    height: '20px',
                                    marginRight: 0,
                                    '& svg': {
                                        color: '#000',
                                        margin: '0 -0.37em',
                                    },
                                }}
                            >
                                <Icon type={'MoreVertical'}></Icon>
                                <Icon type={'MoreVertical'}></Icon>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                '& span': {
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    lineHeight: '140%',
                                    color: '#0A0A0A',
                                    letterSpacing: 0.02,
                                    border: 'none',
                                },
                            }}
                            primary={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {!isNil(item.args) && <SeparateFileIndicator fieldName={item.name} />}
                                    {item.name}
                                </Box>
                            }
                        />
                        <Button
                            data-testid={`${item.name}-remove`}
                            sx={{
                                display: 'none',
                                color: '#898989',
                                padding: '5px',
                                marginLeft: '15px',
                                minWidth: '35px',
                                height: '20px',
                            }}
                            onClick={() => removeItem(index)}
                        >
                            <Icon type={'CloseBig'}></Icon>
                        </Button>
                    </StyledDiv>
                </ListItem>
            )}
        </Draggable>
    );
};

export default DraggableListItem;

const StyledDiv = styled('div', {
    shouldForwardProp: (prop) => !['isDragging', 'isDisabled'].includes(prop as string),
})<ListItemStyledProps>(({ isDragging, isDisabled }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    padding: '8px 16px',
    border: `1px solid ${isDragging ? '#E1E1E1' : '#E0E0E0'}`,
    height: '45px',
    background: `${isDragging ? '#E1E1E1' : '#F5F5F5'}`,
    borderRadius: 4,
    '&:hover': {
        background: '#E1E1E1',
        border: '1px solid #E1E1E1',
        '& button': {
            display: 'flex',
            padding: 0,
        },
    },
    pointerEvents: isDisabled ? 'none' : 'all',
    opacity: isDisabled ? 0.5 : 1,
}));
