import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { useAxiosContext } from 'contexts/AxiosContext';

import { GroupStructureRoot } from './types/groupStructure';

export const getGroupStructure = async (axios: AxiosInstance, businessId: string) =>
    (await axios.get(`/mapi/objects/prospect/group_structure/${businessId}/?business_id=1`)).data ?? [];

export function useGroupStructure(businessId: string) {
    const axios = useAxiosContext();
    return useQuery<GroupStructureRoot[]>({
        queryKey: ['group-structure', businessId],
        queryFn: () => getGroupStructure(axios, businessId),
        retry: 1,
    });
}
