import { getLegacyBusinessId, isNotEmpty } from 'utilities';

import { DaughterCompany, GroupStructureEntity, GroupStructureRoot } from 'api/types/groupStructure';

export const getCountries = (group_structure: GroupStructureRoot[]): string[] => {
    const countries = new Set<string>();
    reduceCountries(group_structure, countries);
    return [...countries];
};

const reduceCountries = (daughters: GroupStructureEntity[] | undefined, accCountries: Set<string>) => {
    if (isNotEmpty(daughters)) {
        daughters.forEach((entity) => {
            if (entity.country) {
                accCountries.add(entity.country);
            }
            reduceCountries(entity.daughters, accCountries);
        });
    }
    return accCountries;
};

export const getSubsidiaryCount = (companies: DaughterCompany[]) => {
    let subsidiaryCount = 0;
    if (isNotEmpty(companies)) {
        companies.forEach((company) => {
            subsidiaryCount += 1;
            if (company.daughters) {
                subsidiaryCount += getSubsidiaryCount(company.daughters);
            }
        });
    }
    return subsidiaryCount;
};

export function getStaffNumber(prospect: GroupStructureEntity) {
    let staff_number = 0;
    if (prospect && prospect.staff_number) {
        staff_number = prospect.staff_number;
    }

    return staff_number;
}

/**
 * This searches if the given business id is either the one of the current company or
 * some of its daughter companies, i.e. if the business id is contained in this tree branch.
 *
 * It's not the most efficient method when the recursion is done for all the leaves separately,
 * but the group structures should be simple enough that the performance loss of O(N^2) is negligible.
 */
export function isCompanyInSubtree(businessId: string, entity: GroupStructureEntity): boolean {
    if (getLegacyBusinessId(entity.business_id) === getLegacyBusinessId(businessId)) {
        return true;
    }
    if (isNotEmpty(entity.daughters)) {
        return entity.daughters.some((daughter) => isCompanyInSubtree(businessId, daughter));
    }
    return false;
}
