import React, { useState } from 'react';

import { Box } from '@mui/material';

import { SortableTable, SortableTableColumn } from 'components/templates/SortableTable';
import { Button } from 'components/tokens/Button';
import { ButtonTabs } from 'components/tokens/ButtonTabs';
import { Dialog } from 'components/tokens/Dialog';

import { TileData } from '../types';
import { ProgressBar } from './BarTile';

const HEADER_HEIGHT = 41;
const ROW_HEIGHT = 49;

type ColumnOptions = {
    label: string;
    width?: number;
};

type Props = {
    title: React.ReactNode;
    onClose: () => void;
    open: boolean;
    data: TileData[];
    // tab label for `data`
    tabLabel?: string;
    extraData?: {
        [key: string]: {
            label: string;
            data?: TileData[];
        };
    };
    total: number;
    firstColumn?: ColumnOptions;
    lastColumn?: ColumnOptions;
    hidePercent?: boolean;
    percentHeaderLabel?: string;
};

const BarTileDialog: React.FC<Props> = ({
    onClose,
    title,
    open,
    tabLabel,
    extraData,
    data,
    firstColumn,
    lastColumn,
    total,
    hidePercent = false,
    percentHeaderLabel = 'Percent',
}) => {
    const [selectedTab, setSelectedTab] = useState('default');

    return (
        <Dialog open={open} onClose={onClose} title={title} maxWidth="lg">
            {extraData && (
                <ButtonTabs selected={selectedTab}>
                    {[
                        <Button value="default" key="default" onClick={() => setSelectedTab('default')}>
                            {tabLabel}
                        </Button>,

                        ...Object.keys(extraData).map((tab) => (
                            <Button key={tab} value={tab} onClick={() => setSelectedTab(tab)}>
                                {extraData[tab].label}
                            </Button>
                        )),
                    ]}
                </ButtonTabs>
            )}

            <SortableTable<TileData>
                data={selectedTab === 'default' ? data : extraData?.[selectedTab].data || []}
                rowKeyField="label"
                tableHeight={Math.min(HEADER_HEIGHT + ROW_HEIGHT * data.length, 480)}
                sx={{ marginTop: 1 }}
                // hack for the scrollbar overlapping data issue on firefox
                headerCellSx={{
                    '&:last-child': {
                        paddingRight: 3,
                    },
                }}
                cellSx={{
                    '&:last-child': {
                        paddingRight: 3,
                    },
                }}
            >
                <SortableTableColumn<TileData> label={firstColumn?.label || 'Value'} field="label">
                    {(row) => <b>{row.label}</b>}
                </SortableTableColumn>
                {data[0]?.secondaryLabel != null && (
                    <SortableTableColumn<TileData> label="" field="secondaryLabel" width={50}>
                        {(row) => row.secondaryLabel}
                    </SortableTableColumn>
                )}
                <SortableTableColumn<TileData> label={percentHeaderLabel} field="value" keyValue="percent">
                    {(row) => {
                        const percent = row.value ? (row.value / total) * 100 : 0;
                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <ProgressBar value={percent} sx={{ width: 100 }} />
                                {!hidePercent && <>{Math.round(percent)} %</>}
                            </Box>
                        );
                    }}
                </SortableTableColumn>
                <SortableTableColumn<TileData> label={lastColumn?.label || 'Companies'} field="value" align="right">
                    {(row) => row.valueLabel ?? row.value}
                </SortableTableColumn>
            </SortableTable>
        </Dialog>
    );
};

export default BarTileDialog;
