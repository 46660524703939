import React from 'react';

import { Skeleton, styled } from '@mui/material';

import { Tag } from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';

import ConfirmationDialog from '../common/ConfirmDialog';
import { Row } from '../common/Styled';
import TooltipIcon from '../common/TooltipIcon';
import LogoSvg from '../svg/LogoSvg';
import { CrmName } from '../types/Crm';
import { getCRMName } from '../utils';
import { getCompanyLikeObjectForCrm } from '../utils/CRMTargetText';

type Props = { isOpen: boolean; crm: CrmName; handleDialogClose: () => void };

const MetaFieldsOnboardingDialog: React.FC<Props> = ({ isOpen, crm, handleDialogClose }) => {
    const crmName = getCRMName(crm);

    return (
        <ConfirmationDialog
            isSmall
            open={isOpen}
            title={`Keep track of updates in ${crmName}`}
            subTitle={`You can keep track of data updates for each ${getCompanyLikeObjectForCrm(
                crm,
            )}, directly in ${crmName} with these properties Vainu created for you.`}
            primaryButtonText="Got it"
            primaryButtonClick={handleDialogClose}
            handleClose={handleDialogClose}
            extraBodyContent={
                <DialogContent>
                    <FieldMapping
                        fieldName="Latest update from Vainu"
                        tooltipDescription="The date when the company was updated last time"
                        example="e.g. 2021-10-01 12:00:00"
                    />
                    <FieldMapping
                        fieldName="Update status"
                        tooltipDescription="Status shows if the company is up-to-date with Vainu data or not"
                        example="e.g. Your data is up to date"
                    />
                </DialogContent>
            }
        />
    );
};

export default MetaFieldsOnboardingDialog;

type FieldMappingProps = {
    fieldName: string;
    tooltipDescription: string;
    example: string;
};

export const FieldMapping: React.FC<FieldMappingProps> = ({ fieldName, tooltipDescription, example }) => {
    return (
        <div>
            <Row justifyContent="flex-start">
                <Typography margin="0 6px 0 0" color="dark" weight="semibold">
                    {fieldName}
                </Typography>
                <TooltipIcon text={tooltipDescription} />
                <Tag variant="blue" label="NEW" sx={{ marginLeft: '6px', border: 'none' }} />
            </Row>
            <Row justifyContent="flex-start">
                <SkeletonWrapper>
                    <LogoSvg width={13} height={11} />
                    <StyledSkeleton variant="rectangular" />
                </SkeletonWrapper>
                <Typography margin="0 0 0 8px" fontSize="12px">
                    {example}
                </Typography>
            </Row>
        </div>
    );
};

const DialogContent = styled('div')(({ theme }) => ({
    marginTop: 16,
    padding: 16,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 8,

    '& > div:not(:first-child)': {
        marginTop: 16,
    },
}));

const SkeletonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 154,
    height: 36,
    marginTop: 6,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 4,
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    width: 114,
    height: 13,
    marginLeft: 6,
    background: theme.palette.field.background,
    borderRadius: 2,
}));
