import { AxiosInstance } from 'axios';

import { Database } from 'contexts/ListsContext';

import { Trigger } from './types/Trigger';

export async function getTriggers(axios: AxiosInstance, database?: Database) {
    if (!database) {
        return Promise.reject();
    }
    const db = getUrlDb(database);
    return (await axios.get<Trigger[]>(`/api/v3/trigger-definitions/${db}/`)).data;
}

export async function getTrigger(axios: AxiosInstance, database: Database | undefined, triggerId: string) {
    if (!database) {
        return Promise.reject();
    }
    const db = getUrlDb(database);
    return (await axios.get<Trigger[]>(`/api/v3/trigger-definitions/${db}/${triggerId}`)).data;
}

export function updateTrigger(
    axios: AxiosInstance,
    database: Database | undefined,
    triggerId: string | undefined,
    data: Partial<Trigger>,
) {
    if (!database) {
        return Promise.reject();
    }
    const db = getUrlDb(database);
    return axios.patch<Trigger>(`/api/v3/trigger-definitions/${db}/${triggerId}/`, data);
}

export function createTrigger(axios: AxiosInstance, database: Database | undefined, data: Partial<Trigger>) {
    if (!database) {
        return Promise.reject();
    }
    const db = getUrlDb(database);
    return axios.post<Trigger>(`/api/v3/trigger-definitions/${db}/`, data);
}

export function deleteTrigger(axios: AxiosInstance, database: Database | undefined, triggerId: string | undefined) {
    if (!database || !triggerId) {
        return Promise.reject();
    }
    const db = getUrlDb(database);
    return axios.delete<Trigger>(`/api/v3/trigger-definitions/${db}/${triggerId}/`);
}

const getUrlDb = (database?: Database) => (database === 'DOMAIN_DATA_BASIC' ? 'GLOBAL' : database);
