import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SuperOfficeMonoInverse = (props) => (
    <SvgIcon {...props}>
        <path d="M12.8947,12.3485s1.3028-6.06-4.1762-7.3686C6.2277,4.3849,6.4366,5.6078,1,3.7178c0,0,1.5226,3.3746,4.2192,3.9691A8.1619,8.1619,0,0,0,6.9439,7.82h.0236q.1375,0,.2757-.0007c.0931,0,.1859-.0007.2793-.0007H7.547q.1755,0,.3507.0023l.0249,0c.2374.0038.4734.0129.7056.0322l.0212.0018c.1158.01.2306.0224.3441.0383l.0029,0v.0044c.01,0,.02,0,.03,0a3.5812,3.5812,0,0,1,1.4117.4833A4.1145,4.1145,0,0,1,11.8088,11.82a4.8407,4.8407,0,0,1-.4572,1.8767,4.1148,4.1148,0,0,0,.0532-.6591,3.6859,3.6859,0,0,0-.6705-2.18,2.1568,2.1568,0,0,0-1.733-.9581,2.1567,2.1567,0,0,0-1.7331.9581,3.6859,3.6859,0,0,0-.6705,2.18,4.0583,4.0583,0,0,0,.1159.9676,4.6663,4.6663,0,0,1-.53-2.4308,4.0065,4.0065,0,0,1,1.854-3.4161q-.2451-.0063-.4947-.0066-.1481,0-.2959.0008t-.296.0008c-.3359,0-.7026-.0045-1.0684-.0349a5.9037,5.9037,0,0,0-1.0654,3.1767c-.1281,2.8078,1.5926,5.16,3.8433,5.2542,2.0558.086,3.8417-1.7427,4.2332-4.2Zm-1.862.8983a3.4954,3.4954,0,0,1-.3828,1.4669,2.4086,2.4086,0,0,1-1.8193.7821,2.3075,2.3075,0,0,1-1.4858-.6624,3.4019,3.4019,0,0,1-.3823-1.7644,3.2334,3.2334,0,0,1,.8045-2.0657c.4362.4412.9472.9367.9513.8406.004-.0883-.0757-.7557-.152-1.3676a1.5441,1.5441,0,0,1,.5519-.078C10.2421,10.4475,11.0992,11.7226,11.0327,13.2468Z" />
        <path d="M15.9242,14.2683a8.1952,8.1952,0,0,0-1.1565-1.4568,7.8179,7.8179,0,0,0-1.2935-1.1037,6.5326,6.5326,0,0,1-2.023,4.2454c.1844.302.566.9195.8945,1.4051.7638,1.1293,2.1159,2.9239,2.1159,2.9239,1.4316-1.6235,2.1622-4.4833,1.6217-5.7072A3.0826,3.0826,0,0,0,15.9242,14.2683Z" />
        <path d="M17.1257,7.8522a2.8331,2.8331,0,0,1,.3753-.0489c.0512-.0032.1005-.0056.1488-.0075A13.7311,13.7311,0,0,0,19.5244,7.8a4.5583,4.5583,0,0,0,1.2669-.507A5.3192,5.3192,0,0,0,23,4.3136a10.8955,10.8955,0,0,1-2.61.8679c-1.4985.201-2.8277-.2666-4.6374.9668-1.9146,1.305-1.8135,4.95-1.7928,5.41.1639.1214.3387.2648.5173.4234A8.5912,8.5912,0,0,1,16.45,14.4133a2.0064,2.0064,0,0,1,.0737.1956,3.0122,3.0122,0,0,1,.1278.7264,2.8114,2.8114,0,0,0,.8655.1261c1.9235-.0244,3.4521-2.01,3.4141-4.4347a5.0551,5.0551,0,0,0-.9912-2.9925c-.06.0193-.1178.0355-.1742.05a2.75,2.75,0,0,1-.7223.0943c-.1587,0-.3127-.0113-.4744-.0234a3.6023,3.6023,0,0,1,1.2308,2.9525,4.3135,4.3135,0,0,1-.4474,1.8913,3.5576,3.5576,0,0,0,.0934-.5944c.0066-.0893.0111-.1794.0111-.2709,0-1.5948-1.0239-2.8922-2.2824-2.8922A2.1941,2.1941,0,0,0,15.2982,10.49a3.6257,3.6257,0,0,1,.8845-2.068A2.034,2.034,0,0,1,17.1257,7.8522ZM15.8434,10.51c.3359.3431.8033.8061.8067.7275s-.0863-.8062-.1495-1.304a1.5107,1.5107,0,0,1,.826-.1891c1.0607.07,1.8433,1.2933,1.7481,2.7318a3.4368,3.4368,0,0,1-.1851.9115,1.8866,1.8866,0,0,1-1.4485.7465c-1.0511-.0093-1.9159-1.0646-2.1241-2.4717A3.0144,3.0144,0,0,1,15.8434,10.51Z" />{' '}
    </SvgIcon>
);

export default SuperOfficeMonoInverse;
