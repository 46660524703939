export const injectHighlightTag = (text: string | undefined, searchTerm: string | undefined) => {
    if (!searchTerm) {
        return text;
    }
    const foundIndex = text?.toLowerCase().indexOf(searchTerm);
    if (text && foundIndex !== undefined && foundIndex !== -1) {
        return (
            <>
                {text.substring(0, foundIndex)}
                <em>{text.substring(foundIndex, foundIndex + searchTerm.length)}</em>
                {text.substring(foundIndex + searchTerm.length)}
            </>
        );
    }
    return text;
};
