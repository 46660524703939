import { isEmpty } from 'lodash';

import { OptionProps, ValueWithParent } from './NestedSelectTypes';

export function appendNextParent(object: ValueWithParent, parent: ValueWithParent | undefined): ValueWithParent {
    if (object.parent) {
        return { ...object, parent: appendNextParent(object.parent, parent) };
    }

    return { ...object, parent };
}

export function getRootNode(option: ValueWithParent): ValueWithParent {
    if (option.parent) {
        return getRootNode(option.parent);
    } else {
        return option;
    }
}

export function getAllLeaves<T extends OptionProps[] = OptionProps[]>(options: T) {
    let leaves: T = [] as unknown as T;

    options.forEach((option) => {
        if (option.options) {
            leaves = leaves.concat(getAllLeaves(option.options)) as T;
        } else {
            leaves.push(option);
        }
    });

    return leaves;
}

export function getAllNodesFlat<T extends OptionProps[] = OptionProps[]>(options: T) {
    let flatOptions: T = [] as unknown as T;

    options.forEach((option) => {
        const { options: nestedOptions, ...values } = option;

        flatOptions.push(values);

        if (nestedOptions) {
            flatOptions = flatOptions.concat(getAllNodesFlat(nestedOptions)) as T;
        }
    });

    return flatOptions;
}

export function filterOptionsDeep<T extends OptionProps = OptionProps>(options: T[], filter: (option: T) => boolean) {
    return options.reduce<T[]>((arr, option) => {
        if (!filter(option)) {
            return arr;
        }

        const { options: _, ...otherProps } = option;
        const updatedOption = { ...otherProps } as T;

        let nestedOptions = option.options as T[];
        if (nestedOptions) {
            nestedOptions = filterOptionsDeep(nestedOptions as T[], filter);
        }

        if (!isEmpty(nestedOptions)) {
            updatedOption.options = nestedOptions as T['options'];
        }
        arr.push(updatedOption);

        return arr;
    }, []);
}
