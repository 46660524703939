export enum MainPath {
    Root = '/',
    Profiles = 'profiles',
    Connectors = 'connectors',
    Triggers = 'triggers',
    Settings = 'settings',
    Search = 'search',
    Company = 'company-legacy',
    Organization = 'company',
    Domain = 'domain',
    Impersonate = 'impersonate',
    EmbeddedView = 'view',
    EmbeddedToken = 'embedded',
    EmbeddedDomain = 'domain',
    EmbeddedOrganization = 'company',
    SSO = 'sso',
}

export enum SettingsPath {
    Team = 'profile-and-team',
    Usage = 'usage',
    APIKeys = 'api-access',
}
