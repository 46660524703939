import { useMemo } from 'react';

import { Box, SxProps, Tooltip } from '@mui/material';

import { Contact, ProfileData, ProfileType } from 'api/types/Organization';
import { CRMExport, useCRMExport, CRMS_EXPORT_OPTIONS } from 'components/modules/connectors/crm-export';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { getProfilePath } from 'components/modules/profiles/Profile';
import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import ContactAvatar from 'components/templates/avatars/ContactAvatar';
import CrmOwnerIndicator from 'components/templates/CompanyCard/CrmOwnerIndicator';
import {
    fieldLabel,
    formatPriorityFields,
    getPriorityFieldsToDisplay,
} from 'components/templates/CompanyCardPriorityFields/utils';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Tags from 'components/templates/Tags';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import NumberDataPoint from 'components/tokens/NumberDataPoint';
import { TextTruncateTooltip } from 'components/tokens/TextTruncate';
import Typography from 'components/tokens/Typography';
import { Database, NordicDatabase, useListsContext } from 'contexts/ListsContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import { OrganizationResult, ProfileResult } from 'contexts/types/FilterCompanies';
import { combineSx } from 'utilities/mui';

import { extractDomainName, formatURL } from '../../BasicInfo/utils';
import { ContactConnectInfo } from '../../Employees/Table/ContactConnectInfo';
import { checkDecisionMaker, getRoles } from '../../Employees/utils';
import { getIndustryTagsFromIndustriesVerbose } from '../../Industries/utils';
import NoFilterResults from '../CompanyGridView/NoFilterResults';

export type ContactListViewProps = {
    loading: boolean;
    hideActions?: boolean;
    hideCrmIndicator?: boolean;
    allContactsSelected: boolean;
    tableData: OrganizationResult[] | ProfileData[];
    selectedContacts: Array<ContactViewItem>;
    unselectedContacts: Array<ContactViewItem>;
    unselectedCompanies?: Array<string>;
    sx?: SxProps;
    onOpenProfile?: (profileType: ProfileType, profileId: string, tabName?: string) => void;
    onSelectContact?: (value: ContactViewItem, isShiftClick: boolean) => void;
    onUnselectContact?: (value: ContactViewItem, isShiftClick: boolean) => void;
};

export interface ContactViewItem extends Contact {
    company: ProfileData;
    id: string;
    isFirst: boolean;
    isLast: boolean;
    color: string;
    [k: string]: unknown;
}

export const ContactListView: React.FC<ContactListViewProps> = ({
    loading = false,
    allContactsSelected,
    tableData,
    selectedContacts,
    unselectedContacts,
    sx,
    onOpenProfile,
    onSelectContact,
    onUnselectContact,
    hideActions,
    hideCrmIndicator,
}) => {
    const { selectedList, database } = useListsContext();
    const { hasIntegration, hasProductPermission } = usePermissionContext();
    const [exportState, { handleOpenExportDialog, handleCloseExportDialog }] = useCRMExport();

    const hasContactDataPermission = hasProductPermission(Permission.ContactData);
    const exportableIntegrations = CRMS_EXPORT_OPTIONS.filter((item) => hasIntegration(item.integration));

    const contacts = useMemo(() => getContacts(tableData), [tableData]);

    const priorityFields = getPriorityFieldsToDisplay({
        isDomainDb: false,
        priorityFields: ['staff_number'],
        viewType: 'list',
    });

    if (contacts.length === 0 && !loading) {
        return (
            <Box sx={combineSx({ height: '50vh' }, sx)}>
                <NoFilterResults type="contacts" />
            </Box>
        );
    }

    const crmLabel = exportableIntegrations.length === 1 ? exportableIntegrations[0].label : 'CRM';

    const onSendContactToCrm = (crmName: CrmName, contact: ContactViewItem) => {
        handleOpenExportDialog({
            selectionType: 'selection',
            exportType: 'contact',
            crm: crmName,
            database: database as NordicDatabase,
            contacts: [{ ...contact, businessId: contact.company.id }],
            companyIds: [contact.company.id],
        });
    };

    const onSendCompanyToCrm = (crmName: CrmName, prospect: ProfileResult) => {
        handleOpenExportDialog({
            selectionType: 'selection',
            exportType: 'company',
            crm: crmName,
            database: database as Database,
            companyIds: [prospect.id],
            defaultVainuTags: selectedList ? [selectedList.name] : [],
        });
    };

    return (
        <Box sx={sx}>
            <SortableTable<ContactViewItem>
                data={loading ? new Array(5).fill({}) : contacts}
                rowKeyField="id"
                sx={{ overflowY: 'hidden' }}
                rowSxGetter={(row) => ({
                    '& td': {
                        borderBottom: row.isLast ? undefined : 'none',
                        backgroundColor: row.color,
                    },
                })}
            >
                {[
                    !hideActions && (
                        <SortableTableColumn<ContactViewItem>
                            field=""
                            keyValue="selection"
                            key="selection"
                            sx={{ padding: 0.5 }}
                            headerSx={{ padding: 0 }}
                            sortable={false}
                            loading={loading}
                            skeletonWidth={38}
                        >
                            {(row) => {
                                const isSelected = allContactsSelected
                                    ? !unselectedContacts.includes(row)
                                    : selectedContacts.includes(row);

                                return (
                                    <Checkbox
                                        disabled={!hasContactDataPermission}
                                        checked={isSelected}
                                        onClick={(e) => {
                                            if (isSelected) {
                                                onUnselectContact?.(row, e.shiftKey);
                                            } else {
                                                onSelectContact?.(row, e.shiftKey);
                                            }
                                        }}
                                    />
                                );
                            }}
                        </SortableTableColumn>
                    ),
                    <SortableTableColumn<ContactViewItem>
                        width={300}
                        label="Contact"
                        field="full_name"
                        key="full_name"
                        compareFn={(a, b) =>
                            `${a.last_name ?? ''} ${a.first_name ?? ''}`.localeCompare(
                                `${b.last_name ?? ''} ${b.first_name ?? ''}`,
                            )
                        }
                        sx={{ paddingLeft: 0 }}
                        headerSx={{ paddingLeft: 0, minWidth: 210 }}
                        sortable={false}
                        loading={loading}
                        skeletonWidth={120}
                    >
                        {(row) => {
                            const isDecisionMaker = checkDecisionMaker(row);
                            const contactTitles =
                                row.titles
                                    ?.filter((title) => title.title)
                                    .map((title) => title.title)
                                    .join(', ') || '';

                            return (
                                <Link
                                    href={getProfilePath(row.company.profileType, row.company.id, 'employees')}
                                    onClick={
                                        onOpenProfile &&
                                        (() => onOpenProfile(row.company.profileType, row.company.id, 'employees'))
                                    }
                                    underline="none"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            '&:hover': {
                                                '& .contact-name': {
                                                    color: 'secondary.main',
                                                },
                                            },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <ContactAvatar
                                                id={row.company.name}
                                                first_name={
                                                    hasContactDataPermission ? row.first_name : contactTitles[0]
                                                }
                                                last_name={hasContactDataPermission ? row.last_name : contactTitles[1]}
                                            />
                                            {/* for double border wrapping */}
                                            <Box
                                                sx={{
                                                    marginLeft: '-10px',
                                                    width: 22,
                                                    height: 22,
                                                    borderRadius: '50%',
                                                    border: '1px solid',
                                                    borderColor: 'common.white',
                                                }}
                                            >
                                                <CompanyLogo
                                                    skeleton={loading}
                                                    sx={{
                                                        '&&': {
                                                            width: 20,
                                                            height: 20,
                                                            borderColor: 'grey.400',
                                                            borderWidth: 1,
                                                        },
                                                    }}
                                                    company_name={row.company.name}
                                                    logo={row.company.logo_url}
                                                    border
                                                />
                                            </Box>
                                        </Box>

                                        <Typography weight="semibold" variant="body2" sx={{ display: 'flex' }}>
                                            <span className="contact-name">
                                                {hasContactDataPermission ? row.full_name : contactTitles}
                                            </span>
                                            {isDecisionMaker && (
                                                <Tooltip title="Decision maker">
                                                    <span>
                                                        <Icon
                                                            type="Award"
                                                            fontSize="small"
                                                            color="brandColors.tertiaryBlue"
                                                            sx={{ display: 'block', marginTop: '-1px' }}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </Typography>
                                    </Box>
                                </Link>
                            );
                        }}
                    </SortableTableColumn>,
                    !hideCrmIndicator && exportableIntegrations.length !== 0 && (
                        <SortableTableColumn<ContactViewItem>
                            label={
                                <Tooltip title={`Contact sent to ${crmLabel}`}>
                                    <div>
                                        <Icon
                                            type="UserCircle"
                                            fontSize="small"
                                            sx={{ display: 'block', marginLeft: '2px' }}
                                        />
                                    </div>
                                </Tooltip>
                            }
                            field=""
                            keyValue="contact-crm"
                            key="contact-crm"
                            sortable={false}
                            loading={loading}
                            skeletonWidth={20}
                            sx={{ paddingY: 0.5 }}
                        >
                            {(row) => (
                                <Box sx={{ display: 'flex' }}>
                                    <CrmOwnerIndicator
                                        disabled={!hasContactDataPermission}
                                        id={row.company.id}
                                        contactId={row.uid}
                                        profileType={row.company.profileType}
                                        name={row.full_name}
                                        targetObject="Contact"
                                        onSendToCrm={(crm: CrmName) => onSendContactToCrm(crm, row)}
                                    />
                                </Box>
                            )}
                        </SortableTableColumn>
                    ),
                    <SortableTableColumn<ContactViewItem>
                        width={125}
                        label="Function"
                        field="roles"
                        key="roles"
                        sortable={false}
                    >
                        {(row) => {
                            const tags = getRoles(row.titles).map((role) => ({
                                label: role,
                                id: role,
                            }));
                            return (
                                <Tags
                                    tags={tags}
                                    cutoff={tags.length > 2 ? 1 : 2}
                                    sx={{ justifyContent: 'flex-start' }}
                                />
                            );
                        }}
                    </SortableTableColumn>,
                    hasContactDataPermission && (
                        <SortableTableColumn<ContactViewItem>
                            width={200}
                            label="Title"
                            field="titles"
                            key="titles"
                            sortable={false}
                        >
                            {(row) => (
                                <Box sx={{ minWidth: 180 }}>
                                    <TextTruncateTooltip
                                        text={
                                            row.titles
                                                ?.filter((title) => title.title)
                                                .map((title) => title.title)
                                                .join(', ') || ''
                                        }
                                        showTooltip
                                    />
                                </Box>
                            )}
                        </SortableTableColumn>
                    ),
                    <SortableTableColumn<ContactViewItem>
                        sortable={false}
                        width={186}
                        label="Contact info"
                        field=""
                        keyValue="contact_info"
                        key="contact_info"
                    >
                        {(contact) => <ContactConnectInfo disabled={!hasContactDataPermission} contact={contact} />}
                    </SortableTableColumn>,

                    <SortableTableColumn<ContactViewItem>
                        label="Company"
                        field="company.name"
                        key="company.name"
                        sortable={false}
                        loading={loading}
                        skeletonWidth={120}
                        sx={{ paddingY: 0 }}
                    >
                        {(row) =>
                            row.isFirst && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <Link
                                        href={getProfilePath(row.company.profileType, row.company.id)}
                                        onClick={
                                            onOpenProfile &&
                                            (() => onOpenProfile(row.company.profileType, row.company.id))
                                        }
                                        underline="none"
                                    >
                                        <CompanyLogo
                                            skeleton={loading}
                                            sx={{
                                                marginLeft: '-10px',
                                                '&&': {
                                                    width: 20,
                                                    height: 20,
                                                    borderColor: 'grey.400',
                                                    borderWidth: 1,
                                                },
                                            }}
                                            company_name={row.company.name}
                                            logo={row.company.logo_url}
                                            border
                                        />
                                    </Link>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            paddingLeft: 1,
                                            width: '100%',
                                            minWidth: 150,
                                        }}
                                    >
                                        <Link
                                            href={getProfilePath(row.company.profileType, row.company.id)}
                                            onClick={
                                                onOpenProfile &&
                                                (() => onOpenProfile(row.company.profileType, row.company.id))
                                            }
                                            underline="none"
                                            sx={{ width: '100%' }}
                                        >
                                            <TextTruncateTooltip
                                                variant="body2"
                                                // nbsp to not cut off at space
                                                text={row.company.name?.replaceAll(' ', ' ') || '-'}
                                                showTooltip
                                                color="dark"
                                                sx={{
                                                    '&:hover': {
                                                        color: 'secondary.main',
                                                    },
                                                }}
                                            />
                                        </Link>
                                        {row.company.website && (
                                            <Link
                                                sx={{ color: 'text.subtle' }}
                                                href={formatURL(row.company.website)}
                                                toNewTab="nofollow"
                                            >
                                                {extractDomainName(row.company.website)}
                                            </Link>
                                        )}
                                    </Box>
                                </Box>
                            )
                        }
                    </SortableTableColumn>,
                    !hideCrmIndicator && exportableIntegrations.length !== 0 && (
                        <SortableTableColumn<ContactViewItem>
                            label={
                                <Tooltip title={`Company sent to ${crmLabel}`}>
                                    <div>
                                        <Icon
                                            type="Profiles"
                                            fontSize="small"
                                            sx={{ display: 'block', marginLeft: '2px' }}
                                        />
                                    </div>
                                </Tooltip>
                            }
                            field=""
                            keyValue="company-crm"
                            key="company-crm"
                            sortable={false}
                            loading={loading}
                            skeletonWidth={20}
                            sx={{ paddingY: 0.5 }}
                        >
                            {(row) =>
                                row.isFirst && (
                                    <Box sx={{ display: 'flex' }}>
                                        <CrmOwnerIndicator
                                            disabled={!hasContactDataPermission}
                                            id={row.company.id}
                                            profileType={row.company.profileType}
                                            logo={row.company.logo_url}
                                            name={row.company.name}
                                            onSendToCrm={(crm: CrmName) => onSendCompanyToCrm(crm, row.company)}
                                        />
                                    </Box>
                                )
                            }
                        </SortableTableColumn>
                    ),
                    <SortableTableColumn<ContactViewItem>
                        label="Industries (official)"
                        field="industries_verbose"
                        key="industries_verbose"
                        sortable={false}
                        loading={loading}
                        skeletonWidth={60}
                    >
                        {(row) =>
                            row.isFirst && (
                                <Tags
                                    tags={getIndustryTagsFromIndustriesVerbose(row.company as OrganizationResult)}
                                    cutoff={1}
                                    sx={{ justifyContent: 'flex-start', maxWidth: 120 }}
                                />
                            )
                        }
                    </SortableTableColumn>,
                    ...priorityFields.map((field) => (
                        <SortableTableColumn<ContactViewItem>
                            key={field}
                            label={fieldLabel[field]}
                            field={field}
                            sortable={false}
                            loading={loading}
                            skeletonWidth={60}
                        >
                            {(row) => {
                                if (!row.isFirst) {
                                    return null;
                                }
                                const formattedPriorityField = formatPriorityFields(
                                    row.company as OrganizationResult,
                                    field,
                                );
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            gap: '6px',
                                        }}
                                    >
                                        <NumberDataPoint
                                            key={formattedPriorityField.field}
                                            variant="small"
                                            align="left"
                                            hideLabel
                                            {...formattedPriorityField}
                                        />
                                    </Box>
                                );
                            }}
                        </SortableTableColumn>
                    )),
                ]}
            </SortableTable>
            <CRMExport {...exportState} onClose={handleCloseExportDialog} />
        </Box>
    );
};

export default ContactListView;

const getContacts = (data: OrganizationResult[] | ProfileData[]) => {
    const result: ContactViewItem[] = [];

    let companyIdx = 0;
    let lastCompanyId: string;

    data.forEach((company, idx) => {
        if (company.id !== lastCompanyId) {
            companyIdx++;
            lastCompanyId = company.id;
        }
        // 1 contact per company
        const contact = company.contacts?.[0];
        if (contact) {
            result.push({
                ...contact,
                id: `${contact.uid}-${company.id}`,
                company,
                isFirst: company.id !== data[idx - 1]?.id,
                isLast: company.id !== data[idx + 1]?.id,
                color: companyIdx % 2 ? 'common.white' : 'background.default',
            });
        }
    });

    return result;
};
