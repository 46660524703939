import { parseISO, differenceInYears } from 'date-fns';

import { Vehicle } from 'api/types/company';

import { VehiclesProps, DataPoint } from './Vehicles';

const insuranceCompanyNames: Record<string, string> = {
    11: 'Greenval Insurance',
    13: 'Pohjantähti',
    14: 'IF sivuliike',
    15: 'Volvia',
    19: 'Ingonord',
    20: 'Tryg',
    21: 'Ups',
    22: 'Tapiola',
    26: 'Protector Forsikring ASA',
    29: 'Axa',
    '03': 'Lähivakuutus',
    30: 'Kansa',
    32: 'Varma',
    36: 'OP Vakuutus',
    38: 'Suomen Vahinkovakuutus',
    39: 'AIG',
    '04': 'A-vakuutus',
    40: 'Euro Insurances',
    41: 'IF',
    43: 'Y-Sampo',
    44: 'LähiTapiola',
    50: 'Folksam',
    52: 'IF-TEVA',
    56: 'Turva',
    65: 'Fennia',
    66: 'Ålands ömsesdia försäkringsbolag',
    90: 'Valtionkonttori',
    92: 'LVK',
    99: 'Foreign',
};

export const insuranceCodeToCompany = (code: string): string =>
    code in insuranceCompanyNames ? insuranceCompanyNames[code as keyof typeof insuranceCompanyNames] : '';

const powerTypes: Record<string, string> = {
    1: 'Petrol',
    2: 'Diesel oil',
    3: 'Fuel oil',
    4: 'Electric',
    5: 'Hydrogen',
    6: 'Gas',
    7: 'Methanol',
    8: '-',
    9: '-',
    10: 'Biodiesel',
    11: 'LPG',
    13: 'CNG',
    31: 'Light fuel oil (kerosene)',
    32: 'Diesel / Wood',
    33: 'Gasoline / Wood',
    34: 'Petrol + light fuel oil (kerosene)',
    37: 'Ethanol',
    38: 'Gasoline / CNG',
    39: 'Gasoline / Electric',
    40: 'Gasoline / Ethanol',
    41: 'Gasoline / Methanol',
    42: 'Gasoline / LPG',
    43: 'Diesel / CNG',
    44: 'Diesel / Electric',
    45: 'Diesel / Ethanol',
    46: 'Diesel / Methanol',
    47: 'Diesel / LPG',
    48: 'Diesel / Biodiesel',
    49: 'Diesel / Biodiesel / Electric',
    50: 'Diesel / Biodiesel / Ethanol',
    51: 'Diesel / Biodiesel / Methanol',
    52: 'Diesel / Biodiesel / LPG',
    53: 'Diesel / Biodiesel / CNG',
    54: 'Hydrogen / Electricity',
    55: 'Diesel / Other',
    56: 'H-group gas',
    57: 'L-Group natural gas',
    58: 'HL-Group natural gas',
    59: 'CNG / biomethane',
    60: 'Biomethane',
    61: 'Wood',
    62: 'Ethanol (ED95)',
    63: 'Ethanol (E85)',
    64: 'The hydrogen-natural gas mixture (H2NG)',
    65: 'LNG',
    66: 'LNG20',
    67: 'Diesel / LNG',
    68: 'Diesel / LNG20',
    X: 'N/A',
    Y: 'Other',
};

export const powerCodeToName = (code: string): string =>
    code in powerTypes ? powerTypes[code as keyof typeof powerTypes] : '';

const ownerTypes: Record<string, string> = {
    1: 'Owner',
    2: 'Other owner',
    3: 'Holder',
    4: 'Other holder',
    5: 'Intermediate owner',
    6: 'Retailer',
    7: 'Recipient',
    8: 'Former owner',
    9: 'Former holder',
};

export const ownerCodeToName = (code: string): string =>
    code in ownerTypes ? ownerTypes[code as keyof typeof ownerTypes] : '';

const possessionTypes: Record<string, string> = {
    1: '',
    2: 'Leasing',
    3: 'Työsuhde',
    4: 'Muu',
};

export const possessionCodeToName = (code: string): string =>
    code in possessionTypes ? possessionTypes[code as keyof typeof possessionTypes] : '';

const gearTypes: Record<string, string> = {
    1: 'Manual',
    2: 'Automatic',
    3: 'Continuously variable',
    4: 'Manual/Automatic',
    5: 'Variable',
    6: 'CVT',
    7: 'Foot-mounted',
    X: 'Not applicable',
    Y: 'Other',
    M: 'Manual',
    A: 'Automatic',
    T: 'Manual w/ addition',
    Z: 'Automatic w/ addition',
    V: 'Vercomatic',
};

export const gearCodeToName = (code: string): string =>
    code in gearTypes ? gearTypes[code as keyof typeof gearTypes] : '';

export function getVehiclesAggregates(vehicles: Vehicle[]): VehiclesProps {
    const changeIndex = undefined;
    const leasing = undefined;
    const types: DataPoint[] = aggregate(vehicles, 'body_type');
    const brands: DataPoint[] = aggregate(vehicles, 'brand_human_readable');
    const age: DataPoint[] = sortAndLabelVehiclesAges(aggregateArray(getVehicleAges(vehicles)));
    const owningCompany: DataPoint[] = aggregate(vehicles, 'owner_business_id');
    const powerSource: DataPoint[] = aggregate(vehicles, 'vehicle_power');
    const emissions: DataPoint[] = aggregate(vehicles, 'emission_co2');
    const owner: DataPoint[] = aggregate(vehicles, 'owner_type');
    const gearing: DataPoint[] = aggregate(vehicles, 'gearing');

    getVehicleAges(vehicles);

    return {
        changeIndex,
        leasing,
        types,
        brands,
        age,
        owningCompany,
        powerSource,
        emissions,
        owner,
        gearing,
    };
}

function aggregate(vehicles: Vehicle[], field: keyof Vehicle, includeUnknown = false) {
    const map = new Map();
    vehicles.forEach((vehicle) => {
        const value = vehicle[field];
        if ((value !== undefined && value !== null && value !== '') || includeUnknown) {
            map.set(value, (map.get(value) ?? 0) + 1);
        }
    });
    return [...map].map(([label, value]) => ({ label: label ?? 'unknown', value }));
}

function aggregateArray(array: (string | number)[], includeUnknown = false) {
    const map = new Map();
    array.forEach((value) => {
        if ((value !== undefined && value !== null && value !== '' && value !== -1) || includeUnknown) {
            map.set(value, (map.get(value) ?? 0) + 1);
        }
    });
    return [...map].map(([label, value]) => ({ label: label ?? 'unknown', value }));
}

function getVehicleAges(vehicles: Vehicle[]): number[] {
    return vehicles.map((vehicle) => {
        if (!vehicle.first_registration_date) {
            return -1;
        }
        const now = new Date();
        const regDate = parseISO(vehicle.first_registration_date);
        const age = differenceInYears(now, regDate);
        if (age === undefined) return -1;
        if (age >= 11) return 11;
        if (age >= 6) return 6;
        if (age >= 3) return 3;
        if (age >= 1) return 1;
        if (age === 0) return 0;
        return -1;
    });
}

const ageLabels: Record<string, string> = {
    0: '< 1',
    1: '1–2',
    3: '3–5',
    6: '6–10',
    11: '> 10',
    '-1': '',
};

function sortAndLabelVehiclesAges(ages: DataPoint[]) {
    const numberLabels = ages.map(({ label, value }) => ({ ageRange: parseInt(label), value }));
    numberLabels.sort((a, b) => a.ageRange - b.ageRange);
    return numberLabels.map(({ ageRange, value }) => ({
        label: ageLabels[ageRange] ?? '',
        value,
    }));
}
