import React, { useState, useCallback } from 'react';

import { FormControlLabel, RadioGroup, styled } from '@mui/material';

import Radio from 'components/tokens/Radio';
import Tag from 'components/tokens/Tag';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';

import ConfirmDialog from '../../common/ConfirmDialog';
import { CrmName, EnhancedMappingSource } from '../../types/Crm';

export type OptionValue = 'create_new_crm_property' | 'use_existing_crm_property';

interface NewCRMPropertySuggestDialogProps {
    isOpen: boolean;
    crmName: CrmName;
    crmPropertyType: string;
    datapoint: EnhancedMappingSource;
    onSubmit: (selectedOption: OptionValue, newCRMPropertyName: string) => {};
    handleDialogClose: () => void;
}

const imagesURLsMap: { [datapoint: string]: { [key in CrmName]?: string } } = {
    technographic_data: {
        pipedrive: 'technologies-multiselect-pipedrive.png',
        hubspot: 'technologies-multiselect-hubspot.png',
        dynamics: 'technologies-multiselect-dynamics.png',
        // not implemented for Salesforce
    },
    vainu_custom_industry: {
        pipedrive: 'vci_pipedrive_filter.png',
        hubspot: 'vci_hubSpot_filter.png',
        dynamics: 'vci_dynamics_filter.png',
        // not implemented for Salesforce
    },
};

const NewCRMPropertySuggestDialog: React.FC<NewCRMPropertySuggestDialogProps> = ({
    isOpen,
    crmName,
    datapoint,
    crmPropertyType,
    onSubmit,
    handleDialogClose,
}) => {
    const [isDialogSubmitted, setDialogSubmitted] = useState(false);
    const [selectedOption, setSelectedOption] = useState<OptionValue | ''>('');
    const [newCRMPropertyName, setNewCRMPropertyName] = useState('');

    const handleSelectOption = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setSelectedOption(event.target.value as OptionValue),
        [],
    );

    const handleNewCRMPropertyNameChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setNewCRMPropertyName(event.target.value),
        [],
    );

    return (
        <ConfirmDialog
            open={isOpen}
            title=""
            subTitle=""
            customWidth="440px"
            primaryButtonDisabled={!selectedOption || isDialogSubmitted}
            primaryButtonText={isDialogSubmitted ? 'Loading...' : 'Continue'}
            primaryButtonClick={() => {
                onSubmit(selectedOption as OptionValue, newCRMPropertyName);
                setDialogSubmitted(true);
            }}
            handleClose={handleDialogClose}
            extraBodyContent={
                <Container>
                    <Image src={`https://images.vainu.io/${imagesURLsMap[datapoint.source_field][crmName]}`} />
                    <Typography variant="h4" sx={{ marginBottom: '18px' }}>
                        Are you sure about the field type?
                    </Typography>
                    <Typography color="subtle">
                        We noticed you have selected a {crmPropertyType} field for the data point. Are you sure you
                        wouldn’t like to change it to a picklist? It makes filtering easier in your CRM.
                    </Typography>
                    <StyledRadioGroup value={selectedOption} onChange={handleSelectOption}>
                        <StyledFormControlLabel
                            value="create_new_crm_property"
                            label={
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="body2">Let Vainu create a new checkboxes field</Typography>
                                        <Tag variant="blue" label="Recommended" sx={{ marginLeft: 1 }} />
                                    </div>
                                    <TextField
                                        disabled={selectedOption === 'use_existing_crm_property'}
                                        size="small"
                                        sx={{ marginTop: '8px' }}
                                        value={newCRMPropertyName}
                                        onChange={handleNewCRMPropertyNameChange}
                                    />
                                </>
                            }
                            control={<StyledRadio size="medium" />}
                        />
                        <StyledFormControlLabel
                            value="use_existing_crm_property"
                            label="I want to use the field I have already mapped"
                            control={<StyledRadio size="medium" />}
                        />
                    </StyledRadioGroup>
                </Container>
            }
        />
    );
};

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const StyledRadioGroup = styled(RadioGroup)({
    padding: '8px',
    marginTop: '8px',
});

const Image = styled('img')({
    width: '100%',
    marginBottom: '16px',
});

const StyledFormControlLabel = styled(FormControlLabel)({
    margin: '0 0 16px -4px',

    '.MuiFormControlLabel-label': {
        fontSize: '14px',
    },
});

const StyledRadio = styled(Radio)({
    alignSelf: 'flex-start',
    marginRight: '16px',
});

export { NewCRMPropertySuggestDialog };
