import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Phone = (props) => (
    <SvgIcon {...props}>
        <path d="M17.595 21.006H17.567C13.7253 20.9114 10.0593 19.3775 7.295 16.708C4.62452 13.9462 3.08969 10.2816 2.99488 6.441C2.98923 5.903 3.20046 5.38536 3.581 5.005L6.295 2.293C6.6855 1.90262 7.3185 1.90262 7.709 2.293L11.709 6.293C12.0994 6.6835 12.0994 7.3165 11.709 7.707L10.113 9.3C10.409 10.4059 10.9543 11.4294 11.707 12.292C12.568 13.045 13.5902 13.5906 14.695 13.887L16.295 12.293C16.6855 11.9026 17.3185 11.9026 17.709 12.293L21.709 16.293C22.0994 16.6835 22.0994 17.3165 21.709 17.707L18.995 20.42C18.6249 20.7939 18.1211 21.0048 17.595 21.006ZM7 4.415H6.995L4.995 6.419C5.07655 9.73766 6.40132 12.9047 8.707 15.293C11.0954 17.5979 14.2618 18.9225 17.58 19.005L19.587 17L16.995 14.415L15.702 15.707C15.517 15.894 15.2651 15.9995 15.002 16C14.9318 16 14.8617 15.9927 14.793 15.978C13.1266 15.6094 11.5794 14.8285 10.293 13.707C9.17145 12.4206 8.39061 10.8734 8.022 9.207C7.95246 8.87577 8.05519 8.53184 8.295 8.293L9.587 7L7 4.415Z" />
    </SvgIcon>
);

export default Phone;
