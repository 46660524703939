import React from 'react';

import { endOfDay, startOfMonth } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import DateRange from 'components/tokens/DateRange';

import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    FilterProps,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, dateValueToText, makeFieldType } from '../utils';

const FIELD = makeFieldType(FilterID.accounting_period_end);

export type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.accounting_period_end,
    label: 'Accounting period end date',
    defaultOperator: FilterOperator.RANGE,
    initValue: {
        [FilterOperator.RANGE]: {
            [FIELD]: [startOfMonth(new Date()).toISOString(), endOfDay(new Date()).toISOString()],
        },
    },
};

export const accountingPeriodEndDateFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.financial,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <AccountingPeriodEndDate {...props} />,
        valueToText: dateValueToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.RANGE].map((value) => formatOperatorValue(value as OperatorValue));

export const AccountingPeriodEndDate: React.VFC<FilterProps<TValues>> = ({ filter, uuid, groupUUID, removeFilter }) => {
    const { control, setValue } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const handleDateChange = (value: Date[]) => {
        let newValue: Array<Date | null> = value;
        if (value.length === 1) {
            newValue = [value[0], null];
        }
        setValue(
            fullValuePath,
            newValue.map((v) => (v ? v.toISOString() : null)),
        );
    };

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        operatorDisabled: true,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange: _onChange, value, ref: _ref, ...fieldProps } = field;
                    return (
                        <DateRange
                            value={(value as Array<string | null>).filter(Boolean).map((v) => new Date(v as string))}
                            onChange={handleDateChange}
                            TextFieldProps={{
                                compact: true,
                                disabled: value === null,
                                status: value === null ? 'disabled' : undefined,
                            }}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
