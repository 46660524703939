import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CircleCheckOutline = (props) => (
    <SvgIcon {...props}>
        <path
            d="M2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
            style={{ stroke: 'currentColor', fill: 'none' }}
            strokeWidth="1.5"
        />
        <path d="M10 17.1538L6 13.0004L7.77877 11.1534L10 13.4664L16.2212 7L18 8.84699L10 17.1538Z" />
    </SvgIcon>
);

export default CircleCheckOutline;
