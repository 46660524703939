import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InCrmDynamics: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <mask
            id="mask0_3483_424"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="2"
            width="16"
            height="20"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7007 6.50617C18.5299 6.8023 19.0834 7.5877 19.0834 8.46813L19.0833 11.0833C18.7303 11.0285 18.3685 11 18 11C14.134 11 11 14.134 11 18C11 19.0593 11.2353 20.0637 11.6565 20.9636L9.36933 21.7993C8.82565 21.998 8.25 21.5955 8.25 21.0166V14.4577C8.25 14.1078 8.46865 13.7951 8.79733 13.675L14.9167 11.4391V9.7979C14.9167 8.93036 14.3791 8.15363 13.5671 7.84807L12.1467 7.31353C11.8743 7.21099 11.5833 7.41238 11.5833 7.70351V11.4723C11.5833 12.0485 11.0124 12.4509 10.4697 12.257L5.05306 10.3225C4.7214 10.2041 4.5 9.8899 4.5 9.53774V2.97417C4.5 2.3979 5.07092 1.99557 5.6136 2.18939L17.7007 6.50617Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_3483_424)">
            <path
                d="M4.5 1.79163L19.0834 6.99992V14.1509C19.0834 14.7271 18.5125 15.1295 17.9698 14.9357L14.9167 13.8456V9.79665C14.9167 8.92871 14.3786 8.15169 13.5661 7.84642L12.1465 7.31306C11.8741 7.21071 11.5833 7.41208 11.5833 7.7031V12.6547L4.5 10.125V1.79163Z"
                fill="#898989"
            />
            <g filter="url(#filter0_f_3483_424)">
                <path
                    d="M19.0833 8.54163C19.0833 9.4165 18.5367 10.1997 17.715 10.5L8.25 13.9583V22.2916L19.0833 18.3333V8.54163Z"
                    fill="black"
                    fillOpacity="0.24"
                />
            </g>
            <g filter="url(#filter1_f_3483_424)">
                <path
                    d="M19.0833 8.87488C19.0833 9.74975 18.5367 10.5329 17.715 10.8332L8.25 14.2916V22.6249L19.0833 18.6665V8.87488Z"
                    fill="black"
                    fillOpacity="0.32"
                />
            </g>
            <path
                d="M19.0833 8.45825C19.0833 9.33313 18.5367 10.1163 17.715 10.4166L8.25 13.8749V22.2083L19.0833 18.2499V8.45825Z"
                fill="#898989"
            />
            <path
                opacity="0.5"
                d="M19.0833 8.45825C19.0833 9.33313 18.5367 10.1163 17.715 10.4166L8.25 13.8749V22.2083L19.0833 18.2499V8.45825Z"
                fill="#898989"
            />
            <path
                opacity="0.5"
                d="M14.9169 11.4417L11.5817 12.6618L11.5817 17.5489C11.5817 17.84 11.8727 18.0414 12.1451 17.9388L13.5675 17.4034C14.3794 17.0978 14.9169 16.3212 14.9169 15.4537V11.4417Z"
                fill="#898989"
            />
        </g>
        <path
            d="M14.0399 18.2534L13.7058 18.6003L14.0399 18.9471L16.4399 21.4391L16.8 21.8131L17.1601 21.4391L21.9601 16.455L22.2942 16.1082L21.9601 15.7614L20.8929 14.6532L20.5326 14.279L20.1724 14.6533L16.8003 18.1583L15.8279 17.1457L15.4678 16.7707L15.1071 17.1452L14.0399 18.2534ZM12.5 18C12.5 14.9624 14.9624 12.5 18 12.5C21.0376 12.5 23.5 14.9624 23.5 18C23.5 21.0376 21.0376 23.5 18 23.5C14.9624 23.5 12.5 21.0376 12.5 18Z"
            fill="#079992"
            stroke="#079992"
        />
        <defs>
            <filter
                id="filter0_f_3483_424"
                x="7.45"
                y="7.74163"
                width="12.4334"
                height="15.3501"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_3483_424" />
            </filter>
            <filter
                id="filter1_f_3483_424"
                x="0.25"
                y="0.874878"
                width="26.8334"
                height="29.7501"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_3483_424" />
            </filter>
        </defs>
    </SvgIcon>
);

export default InCrmDynamics;
