import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const NotificationDeactivated = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C10.8954 22 9.99999 21.1046 9.99999 20H14C14 21.1046 13.1046 22 12 22ZM19.585 21.9L16.685 19H3.99999V17L5.99999 16V10.5C5.9965 9.82904 6.05745 9.1593 6.18199 8.5L2.61499 4.929L4.02899 3.515L20.998 20.487L19.586 21.9H19.585ZM17.999 14.659L8.27899 4.938C8.7989 4.5776 9.38313 4.32028 9.99999 4.18V2H14V4.18C16.579 4.793 18 7.038 18 10.5V14.66L17.999 14.659Z" />
    </SvgIcon>
);

export default NotificationDeactivated;
