import React, { useState } from 'react';

import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Select from 'components/tokens/select-components/Select';
import { getFirstKey } from 'utilities/objectUtils';

import { mergeKeys } from '../../common/utils';
import { getFiltersFromGroup, getSameFiltersState } from '../../filters/utils';
import { FilterGroup, FilterGroupProps, FilterID, GroupValues, NodeValue, RawGroup } from '../../FilterTypes';
import { getGroupFilters, useFilterGroupOptions } from '../common/groupUtils';
import { useGroupActions, useGroupNodes } from '../common/useGroup';
import FilterGroupTemplate from '../FilterGroupTemplate';

const options = [
    {
        value: FilterID.prospect_export_crm_system,
        label: 'CRM system',
    },
    {
        value: FilterID.prospect_export_crm_owner_email,
        label: "Owner's email",
    },
    {
        value: FilterID.prospect_export_crm_open_deal_count,
        label: 'Open deals (count)',
    },
    {
        value: FilterID.prospect_export_crm_won_deal_count,
        label: 'Won deals (count)',
    },
    {
        value: FilterID.prospect_export_crm_lost_deal_count,
        label: 'Lost deals (count)',
    },
];

const createNewGroup = (value: NodeValue) =>
    ({
        [FilterOperator.MATCH]: {
            [GroupValues.crm]: { [FilterOperator.ALL]: [value] },
        },
    }) as NodeValue;

const config: FilterGroup = {
    group: GroupValues.crm,
    label: 'CRM data',
    options,
    filterUUIDs: [],
    logic: {},
    render: (props: FilterGroupProps) => <CRMGroup {...props} />,
    getFilters: getGroupFilters,
};

export const crmGroupDefinition: Record<GroupValues.crm, FilterGroup> = {
    [GroupValues.crm]: config,
};

export const CRMGroup: React.FC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    removeGroup,
    ids = [],
    addNewGroup,
    updateGroup,
    disabled = false,
}) => {
    const [addRow, setAddRow] = useState(false);
    const [isFilterAdded, setIsFilterAdded] = useState(false);
    const { getValues } = useFormContext();

    const filterGroups = useGroupNodes(ids);

    const { activeFilters, filteredOptions } = useFilterGroupOptions({ filterGroups, options });

    const { handleAddFilter, handleFilterRemove, keys } = useGroupActions({
        applyChanges,
        removeGroup,
        addNewGroup,
        updateGroup,
        createNewGroup,
        filterGroups,
        options: filteredOptions,
    });

    const renderFilters = () => {
        return ids.map((id) =>
            filterGroups[id].filters.map((filter, index) => {
                const filterDefinition = filter.definition;
                const sameFiltersState = getSameFiltersState(activeFilters, filter);

                return filterDefinition
                    ? filterDefinition.render({
                          key: keys[mergeKeys(id, filter.id)],
                          uuid: filterDefinition.id,
                          filter,
                          groupUUID: config.group,
                          removeFilter: () => handleFilterRemove(id, index),
                          sameFiltersState,
                      })
                    : null;
            }),
        );
    };

    const handleApplyChanges = () => {
        ids.forEach((id) => {
            const value = getValues(id);
            const result = getFiltersFromGroup({ value, path: id } as RawGroup);

            result.forEach((r, index) => {
                const nextOperator = getFirstKey(r.value);
                const fieldValue = r.value[nextOperator as keyof NodeValue];

                if (r.id === FilterID.prospect_export_crm_system) {
                    if (!fieldValue) {
                        handleFilterRemove(id, index);
                    }
                    return;
                }

                if (isEmpty(fieldValue)) {
                    handleFilterRemove(id, index);
                } else if (fieldValue[0] == null && fieldValue[1] == null) {
                    handleFilterRemove(id, index);
                }
            });
        });

        applyChanges();
    };

    const addRowSelect = (
        <Select
            placeholder="Select"
            width="100%"
            multiple={false}
            value={null}
            autoFocus
            openOnFocus
            sx={{ gridArea: 'add' }}
            options={filteredOptions || []}
            onValueChange={(value) => {
                if (Array.isArray(value)) {
                    throw new Error('should not be array value');
                }
                if (typeof value === 'number') {
                    throw new Error('should not be number value');
                }
                if (typeof value === 'string') {
                    throw new Error('should not be string value');
                }
                if (!value) {
                    return;
                }
                handleAddFilter(value.value as FilterID);
                setAddRow(false);
                setIsFilterAdded(true);
            }}
        />
    );

    const addRowButton = (
        <Button
            variant="tertiary"
            size="small"
            onClick={() => setAddRow(true)}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            AND
        </Button>
    );

    const renderedFilters = renderFilters();

    const props = {
        label: config.label,
        filters: renderedFilters,
        addFilter: handleAddFilter,
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        addRowSelect,
        addRowButton,
        addRow,
        isFilterAdded,
        setIsFilterAdded,
        disabled,
    };
    return <FilterGroupTemplate {...props} />;
};
