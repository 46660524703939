import { useSimilarCompanies } from 'api/similarCompanies';
import SimilarCompanies from 'components/features/SimilarCompanies/SimilarCompanies';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useListsContext } from 'contexts/ListsContext';

import { DomainTabProps, TabContent } from '..';

export const SimilarCompaniesTab: React.FC<DomainTabProps> = ({ domain, data: companyData }) => {
    const { database } = useListsContext();
    const axios = useAxiosContext();

    const { data, isLoading } = useSimilarCompanies(axios, database || 'DOMAIN_DATA_BASIC', {
        '?ALL': [{ '?EQ': { domain } }],
    });

    return (
        <TabContent>
            <SimilarCompanies data={data} isLoading={isLoading} currentCompanyData={companyData} />
        </TabContent>
    );
};

export default SimilarCompaniesTab;
