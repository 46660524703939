import React, { useCallback, useMemo } from 'react';

import { Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';

import { isImportListAsyncProcess } from 'api/types/UserAsyncProcess';
import Button from 'components/tokens/Button';
import Chip from 'components/tokens/Chip';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { useFileUploadContext } from 'contexts/FileUploadContext';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';
import { isUploaded, useListsContext } from 'contexts/ListsContext';

import { groupIsSubsidiaryOptions } from '../filters/group-structure/GroupIsSubsidiary';
import { getFilterGenerator, getOperator as getOperatorDefault } from '../filters/utils';
import { FilterGroup, FilterID, GroupValues, MappedFilter, NodeValue } from '../FilterTypes';
import { FilterChipContent, LabelToRender } from './FilterChipContent';

type ActiveFiltersGroupTopSectionProps = {
    uuid: string;
    activeFilterGroup: FilterGroup;
    activeFilters: MappedFilter[];
    activeGroup?: string;
    onClick: React.MouseEventHandler<HTMLElement>;
    onClearFilters: (uuid: string) => void;
    hideClear?: boolean;
    showAll?: boolean;
    sx?: SxProps;
};

const additionalChipProp = {
    disableRipple: true,
};

const ActiveFiltersGroupTopSection: React.FC<ActiveFiltersGroupTopSectionProps> = ({
    uuid,
    activeFilterGroup,
    activeFilters,
    activeGroup,
    onClick,
    onClearFilters,
    hideClear = false,
    showAll = false,
    sx,
}) => {
    const {
        webTags,
        VCIOptions,
        locationTypeOptions,
        companySizeIndicatorOptions,
        countriesOptions,
        selectListFilterOptions,
        crmOptions,
    } = useFilterOptionsContext();
    const { fileUploadProcesses } = useFileUploadContext();

    const { selectedList, createNewList, database } = useListsContext();

    const isOwner = selectedList?.privileges.current === 'owner';
    const hasEditPermission = selectedList?.privileges.current === 'edit';

    /**
     * Could be extracted into utility function but it uses `webTags` which is obtained
     * from context hook. So either we keep it here or create a utility function and
     * pass `webTags` as param of the function. We might need other values which is obtained
     * from context hook. If it happens we can pass those as object. But for now let's keep
     * as it is.
     */

    const getDisplayFilterValues = useCallback(
        <T,>(filterToRender: MappedFilter<T> | undefined): LabelToRender => {
            if (!filterToRender) {
                return { valueToRender: '', operator: '' };
            }

            const { valueToText, getOperator = getOperatorDefault } = filterToRender.definition ?? {};

            const operator = getOperator(filterToRender.value) || '';

            const getOptions = (id: FilterID) => {
                switch (id) {
                    case FilterID.vainu_custom_industry:
                        return VCIOptions;
                    case FilterID.location_type:
                        return locationTypeOptions;
                    case FilterID.staff_number_web:
                        return companySizeIndicatorOptions;
                    case FilterID.country:
                        return countriesOptions;
                    case FilterID.target_group:
                        return selectListFilterOptions;
                    case FilterID.website_language:
                    case FilterID.technology_all:
                    case FilterID.technology_advertising:
                    case FilterID.technology_analytics:
                    case FilterID.technology_ats:
                    case FilterID.technology_cms:
                    case FilterID.technology_crm:
                    case FilterID.technology_customer_service:
                    case FilterID.technology_domain_server:
                    case FilterID.technology_e_commerce:
                    case FilterID.technology_email_server:
                    case FilterID.technology_marketing:
                    case FilterID.technology_payment:
                    case FilterID.technology_reservation:
                    case FilterID.technology_server:
                    case FilterID.technology_web_tech:
                        return webTags[id];
                    case FilterID.technology_social_media:
                        return webTags[FilterID.social_media_channels];
                    case FilterID.group_is_subsidiary:
                        return groupIsSubsidiaryOptions;
                    case FilterID.prospect_export_crm_system:
                        return crmOptions;
                    default:
                        return undefined;
                }
            };

            return {
                valueToRender: valueToText(filterToRender.value, getOptions(filterToRender.id)),
                operator,
            };
        },
        [
            webTags,
            VCIOptions,
            locationTypeOptions,
            companySizeIndicatorOptions,
            countriesOptions,
            selectListFilterOptions,
            crmOptions,
        ],
    );

    const labels = useMemo(() => {
        const newLabels = activeFilters.map((filter) => getDisplayFilterValues(filter));

        for (const filter of getFilterGenerator(activeFilterGroup as NodeValue)) {
            newLabels.push(getDisplayFilterValues(filter));
        }
        return newLabels;
    }, [activeFilterGroup, activeFilters, getDisplayFilterValues]);

    const isStaticList = selectedList && isUploaded(selectedList);
    const isStaticListFromUploadedFile = isStaticList;

    const hideUploadListGroupforOtherThanStaticLists = () => {
        return activeFilterGroup.group === GroupValues.upload_list && !isStaticList;
    };

    const hideOtherThanUploadListGroupForStaticList = () => {
        return activeFilterGroup.group !== GroupValues.upload_list && isStaticList;
    };

    if (hideUploadListGroupforOtherThanStaticLists()) {
        return null;
    }

    if (hideOtherThanUploadListGroupForStaticList()) {
        return null;
    }

    const getUploadListGroup = () => {
        let content: React.ReactNode = 'Upload';
        if (isStaticListFromUploadedFile) {
            const onGoingProcess = fileUploadProcesses.find(
                (process) =>
                    isImportListAsyncProcess(process) &&
                    process.meta_data?.target_group_id === selectedList?.id &&
                    process.state === 'process',
            );
            if (onGoingProcess) {
                content = 'Processing file...';
            }
        } else {
            content = (
                <>
                    Selected companies:{' '}
                    <Typography component="span" weight="semibold" color="subtle" sx={{ fontSize: 'inherit' }}>
                        {selectedList?.prospect_count || 0}
                    </Typography>
                </>
            );
        }

        return (
            <Button
                variant="tertiary"
                disabled={!isOwner && !hasEditPermission}
                onClick={onClick}
                startIcon={<Icon type="Upload" />}
                size="small"
            >
                {content}
            </Button>
        );
    };

    if (activeFilterGroup.group === GroupValues.upload_list) {
        return (
            <>
                {getUploadListGroup()}
                <Tooltip title="It's not yet possible to filter custom lists. For now, you can use this to create a filterable list that only includes companies from this custom list.">
                    <Button
                        variant="tertiary"
                        onClick={() =>
                            createNewList(
                                database === 'DOMAIN_DATA_BASIC' ? 'dynamic-domain-list' : 'dynamic-organization-list',
                                {
                                    query: `{"?ALL":[{"?IN":{"target_group._id":["${selectedList?.id}"]}}]}`,
                                },
                            )
                        }
                        startIcon={<Icon type="Filter" />}
                        size="small"
                    >
                        Filter
                    </Button>
                </Tooltip>
            </>
        );
    }

    return (
        <Chip
            disabled={!isOwner && !hasEditPermission}
            onClick={onClick}
            state={uuid === activeGroup ? 'active' : labels.length ? 'filled' : 'default'}
            label={
                <FilterChipContent
                    uuid={uuid}
                    activeFilterGroup={activeFilterGroup}
                    labels={labels}
                    onClearFilters={onClearFilters}
                    hideClear={hideClear}
                    showAll={showAll}
                />
            }
            sx={{ maxWidth: 320, ...sx }}
            /**
             * disableRipple is not part of official API but it works
             */
            {...additionalChipProp}
        />
    );
};

export default ActiveFiltersGroupTopSection;
