import React, { useContext, useEffect, useRef, useState } from 'react';

import { Grid, Typography, styled } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { DialogController } from 'contexts/DialogContext/DialogController';
import { Permission } from 'contexts/PermissionContext';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import useAsyncProcess from 'hooks/useAsyncProcess';
import { useDialogContext } from 'hooks/useDialogContext';
import { filteredLatestCrmImportProcess } from 'store/asyncProcessAtomSelectors';

import GoBack from '../common/GoBack';
import ViewOnlyFeedback from '../common/SettingsViewOnlyFeedback';
import { StyledCard } from '../common/Styled';
import { useCRMIntegConfig } from '../context/CRMIntegrationConfigContext';
import { useCompanyMatch } from '../hooks/useCompanyMatch';
import { RestartMatchingConfirmDialog } from '../landing/pages/ConnectionView/CRMOverview/RestartMatchingConfirmDialog';
import MatchingSkeleton from '../skeletons/MatchingSkeleton';
import { CrmName } from '../types/Crm';
import { getCRMName } from '../utils';
import AdvanceDropdown from './AdvanceDropdown';
import CompanyMatchStart from './CompanyMatchStart';
import ManualCompanyMatch from './manualCompanyMatch/ManualCompanyMatch';
import { MatchingProgressBar } from './MatchingProgressBar';
import { MatchingResultsDiagram } from './MatchingResultsDiagram';
import { MatchingSuccessBanner } from './MatchingSuccessBanner';
import { MatchingSummary } from './MatchingSummary';
import { NoDomainCompaniesDialog } from './NoDomainCompaniesDialog';

type MatchCompanyProps = {
    crm: CrmName;
};

export const CompanyMatchTab: React.FC<MatchCompanyProps> = ({ crm }) => {
    const {
        isInitialFullImportDone,
        addedAndMatched,
        matchedUnsupportedCountries,
        matchedUpgradableCountries,
        nonMatchedCountries,
        missingFromDB,
        matchedByCountries,
        matchedByDomains,
        fetchMatchingResults,
    } = useCompanyMatch();

    const { handleDialogOpen } = useDialogContext();
    const { showSnackbar } = useSnackbarContext();

    const latestCRMImportProcess = useRecoilValue(filteredLatestCrmImportProcess({ crm }));

    const { isAccountAdmin, isDomainConnector, connections, hasAccountOrUserPermission } =
        useContext(PermissionContext);
    const hasLeadMatchingPermission = hasAccountOrUserPermission(Permission.CRMLeadMatching);

    const [, { markAsyncProcessSeen }] = useAsyncProcess();

    const [{ auto_syncing_enabled }, { saveSyncOption, startFullImport }] = useCRMIntegConfig();

    const latestJob = latestCRMImportProcess;
    const prevMatchingJobRef = useRef(latestJob?.job_id);

    const [currentComponentView, setCurrentComponentView] = useState<'result' | 'progress' | 'manual_match'>('result');
    const [focusedComponent, setFocusedComponent] = useState('');
    const importJobStatus = latestJob?.state;

    const [isMatchingRestartDialogOpen, setMatchingRestartDialogOpen] = useState(false);

    // todo: should be refactored
    // send fetchMatchingResults() request only when matching job is completed
    useEffect(() => {
        if (latestJob?.job_id !== prevMatchingJobRef.current && latestJob?.state === 'completed') {
            fetchMatchingResults();
            prevMatchingJobRef.current = latestJob?.job_id;
        }
    }, [latestJob, fetchMatchingResults]);

    const handleAutoSyncChange = async ({ target: { checked } }: { target: { checked: boolean } }) => {
        try {
            if (!checked) {
                // deactivating sync should deactive automatic update
                await saveSyncOption({ enrich_enabled: false, auto_syncing_enabled: false });
                window.analytics?.track('Connector: Disabled automatic update', { app: crm });
                window.analytics?.track(`Connector: Disabled sync activity`, { app: crm });
            } else {
                await saveSyncOption({ auto_syncing_enabled: checked });
                window.analytics?.track(`Connector: Enabled sync activity`, { app: crm });
            }
        } catch (e) {
            showSnackbar('😔 Oops, something went wrong, please try again or contact support', 'error');
        }
    };

    const enableFullImportSync = async (enableSync = false) => {
        await startFullImport();

        if (enableSync) {
            await saveSyncOption({ auto_syncing_enabled: true });
        }
    };

    const markJobSeen = () => {
        if (latestJob?.job_id) {
            markAsyncProcessSeen(latestJob?.job_id);
        }
    };

    /* Render view when matching is not done */
    if (!isInitialFullImportDone && !importJobStatus) {
        return (
            <StyledCard>
                {!isAccountAdmin ? <ViewOnlyFeedback /> : null}
                <Wrapper>
                    <CompanyMatchStart
                        crm={crm}
                        isAccountAdmin={isAccountAdmin}
                        startCompanyImport={() => enableFullImportSync(true)}
                    />
                </Wrapper>
                <Typography style={{ position: 'relative', marginTop: 16, color: '#898989', fontSize: 12 }}>
                    Company matching itself is only used for your Vainu connection, it won’t make changes to your{' '}
                    {getCRMName(crm)} data
                </Typography>
            </StyledCard>
        );
    }

    /* Render matching skeleton */
    if (importJobStatus && (importJobStatus === 'accepted' || importJobStatus === 'process')) {
        // 'size' property default value is 100 for rendering not filled progress bar
        const { completed = 0, size = 100 } = latestJob?.meta_data ?? {};
        const progressPercentage = ((completed * 100) / size)?.toFixed(2) ?? '0';

        return (
            <StyledCard>
                <MatchingProgressBar
                    hasLeadMatchingPermission={hasLeadMatchingPermission}
                    crm={crm}
                    progressPercentage={Number(progressPercentage)}
                    processedCount={completed}
                />
                <MatchingSkeleton />
            </StyledCard>
        );
    }

    /* Render manual matching form view */
    if (currentComponentView === 'manual_match') {
        return (
            <StyledCard>
                <Wrapper overflow="unset">
                    <GoBack
                        bottomDivider
                        text="Results"
                        padding="0px 0px 20px 0px"
                        onClick={() => setCurrentComponentView('result')}
                    />
                    <ManualCompanyMatch
                        isAccountAdmin={isAccountAdmin}
                        isDomainConnector={isDomainConnector}
                        crm={crm}
                    />
                </Wrapper>
            </StyledCard>
        );
    }

    /* Render matching summary */
    return (
        <>
            {!!latestJob && latestJob?.state !== 'failure' && (
                <MatchingSuccessBanner
                    isDomainConnector={isDomainConnector}
                    crm={crm}
                    matchedCount={isDomainConnector ? matchedByDomains.count : matchedByCountries.count}
                    addedAndMatched={addedAndMatched.count}
                    missingFromDB={missingFromDB.count}
                    latestJob={latestJob}
                    markJobSeen={markJobSeen}
                />
            )}
            <StyledCard>
                <Wrapper>
                    {currentComponentView === 'result' && (
                        <>
                            {!isAccountAdmin ? (
                                <div>
                                    <ViewOnlyFeedback />
                                </div>
                            ) : null}
                            <Grid container justifyContent="space-between">
                                <Typography fontWeight="900" fontSize="24px">
                                    Your matching results
                                </Typography>
                                <AdvanceDropdown
                                    isAutoSyncEnabled={auto_syncing_enabled}
                                    isAccountAdmin={isAccountAdmin}
                                    crm={crm}
                                    handleFullImportSync={() => setMatchingRestartDialogOpen(true)}
                                    handleAutoSyncChange={handleAutoSyncChange}
                                />
                            </Grid>
                            <MatchingResultsDiagram
                                isDomainConnector={isDomainConnector}
                                isAutoSyncEnabled={auto_syncing_enabled}
                                missingFromDBCount={missingFromDB.count}
                                matched={isDomainConnector ? matchedByDomains.count : matchedByCountries.count}
                                addedAndMatched={addedAndMatched.count}
                                unmatched={nonMatchedCountries.count}
                                needUpgrade={matchedUpgradableCountries.count}
                                unsupported={matchedUnsupportedCountries.count}
                                focusedComponent={focusedComponent}
                                crm={crm}
                                latestJob={latestJob}
                                setFocusedComponent={setFocusedComponent}
                                enableFullImportSync={enableFullImportSync}
                            />
                            <MatchSummaryContainer lowOpacity={importJobStatus === 'failure'}>
                                <MatchingSummary
                                    isDomainConnector={isDomainConnector}
                                    isAccountAdmin={isAccountAdmin}
                                    crm={crm}
                                    matchedCompanies={isDomainConnector ? matchedByDomains : matchedByCountries}
                                    addedAndMatchedCount={addedAndMatched.count}
                                    needUpgradeCompanies={matchedUpgradableCountries}
                                    nonMatchedCount={nonMatchedCountries.count}
                                    missingFromDBCount={missingFromDB.count}
                                    unsupportedCompanies={matchedUnsupportedCountries}
                                    focusedComponent={focusedComponent}
                                    setFocusedComponent={setFocusedComponent}
                                    navigateToManualMatch={() => setCurrentComponentView('manual_match')}
                                    handleOpenNoDomainCompaniesDialog={() => {
                                        handleDialogOpen('NO_DOMAIN_COMPANIES_DIALOG', {
                                            crm,
                                            nonMatchedCount: nonMatchedCountries.count,
                                            crmConnection: connections.find(
                                                ({ crm_name }) => crm_name.toLowerCase() === crm,
                                            ),
                                        });
                                    }}
                                />
                            </MatchSummaryContainer>
                        </>
                    )}
                </Wrapper>
            </StyledCard>
            <DialogController type="NO_DOMAIN_COMPANIES_DIALOG" component={NoDomainCompaniesDialog} />
            <RestartMatchingConfirmDialog
                isOpen={isMatchingRestartDialogOpen}
                onSubmit={() => {
                    enableFullImportSync();
                    setMatchingRestartDialogOpen(false);
                }}
                handleDialogClose={() => setMatchingRestartDialogOpen(false)}
            />
        </>
    );
};

const Wrapper = styled('div')<{ overflow?: string }>(({ theme, overflow }) => ({
    display: 'grid',
    gridAutoRows: 'min-content',
    gap: '20px',
    background: theme.palette.common.white,
    overflowY: 'scroll',
}));

const MatchSummaryContainer = styled('div')<{ lowOpacity: boolean }>(({ lowOpacity }) => ({
    opacity: lowOpacity ? 0.6 : 1,
}));
