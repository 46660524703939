import React, { forwardRef } from 'react';

import { ChipProps as MuiChipProps } from '@mui/material/Chip';
import clsx from 'clsx';

import Icon, { IconType } from 'components/tokens/Icon';

import { StyledMuiChip } from './styled';

type ChipType = 'square' | 'round' | 'basic';

type ChipState = 'default' | 'active' | 'filled';

interface ChipProps extends Omit<MuiChipProps, 'variant' | 'icon'> {
    type?: ChipType;
    state?: ChipState;
    icon?: IconType;
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
    ({ label, type = 'round', state = 'default', icon, className, ...props }, ref) => {
        return (
            <StyledMuiChip
                ref={ref}
                label={label}
                variant="filled"
                className={clsx(className, `${type} ${state}`)}
                deleteIcon={<Icon type="CloseBig" />}
                icon={icon && <Icon type={icon} />}
                {...props}
            />
        );
    },
);

export default Chip;
