import { useLocations } from 'api/profileData';
import { domainProfileType } from 'api/types/Organization';
import LocationsHeader from 'components/features/Locations/Header';
// import LocationsMap from 'components/features/Locations/Map';
import LocationsTable from 'components/features/Locations/Table';
import { useAddressesFromBusinessUnits, useCountriesFromBusinessUnits } from 'components/features/Locations/utils';

import { TabContent, DomainTabProps } from '..';

export const LocationsTab: React.VFC<DomainTabProps> = ({ data, domain }) => {
    const { data: locationsData, isLoading } = useLocations({
        key: domain,
        type: domainProfileType,
    });

    const addresses = useAddressesFromBusinessUnits(locationsData);
    const addressCountries = useCountriesFromBusinessUnits(locationsData?.business_units);
    return (
        <TabContent>
            <LocationsHeader skeleton={isLoading} countries={addressCountries} addresses={addresses} />
            {/* Disabled for now; the domain business unit addresses don't yet contain any coordinates. */}
            {/* <LocationsMap skeleton={isLoading} countries={addressCountries} company_name={data.name} prospect_addresses={addresses} /> */}
            <LocationsTable skeleton={isLoading} addresses={addresses} />
        </TabContent>
    );
};

export default LocationsTab;
