import React from 'react';

import { add, formatDistance } from 'date-fns';

/**
 *  @example estimation: "0:00:22.000000" -> in less than a minute
 *  @example estimation: "0:12:00.000000" -> in 12 minutes
 *  @example estimation: "0:50:00.000000" -> in about 1 hour
 *  @example estimation: "25:00:00.000000" -> in 1 day
 */
export const RelativeTimeEstimation: React.FC<{
    value: string;
}> = ({ value }) => {
    const now = new Date();
    const split = value.split(':').map((str) => parseInt(str));
    const duration = { hours: split[0], minutes: split[1], seconds: split[2] };
    return <>{formatDistance(add(now, duration), now, { addSuffix: true })}</>;
};
