import { Box, SxProps, TableCell, Theme } from '@mui/material';

import Icon from 'components/tokens/Icon';

import { TableDataBase, checkboxPaddingSx } from './SortableTable';
import type { SortableTableColumnProps } from './SortableTableColumn';
import { getCellAlign } from './utils';

export type SortableTableHeaderCellProps<T extends TableDataBase> = SortableTableColumnProps<T> & {
    active?: boolean;
    asc?: boolean;
    sx?: SxProps<Theme>;
    virtualized?: boolean;
    onSortableClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const SortableTableHeaderCell = <T extends TableDataBase>({
    label,
    width,
    align,
    numeric = false,
    sortable = true,
    active = false,
    asc = true,
    checkbox,
    sx,
    virtualized = false,
    onSortableClick,
}: SortableTableHeaderCellProps<T>): JSX.Element => {
    const cellSx = { ...(checkbox ? checkboxPaddingSx : undefined), ...sx };
    return (
        <TableCell
            component={virtualized ? 'div' : undefined}
            onClick={sortable ? onSortableClick : undefined}
            sx={{
                height: 40,
                position: 'sticky',
                top: 0,
                backgroundColor: 'field.background',
                color: active ? 'text.strong' : 'text.subtle',
                cursor: sortable ? 'pointer' : 'default',
                '&:hover': sortable ? { color: 'text.strong' } : {},
                width,
                maxWidth: width,
                fontSize: 14,
                zIndex: 1,
                ...cellSx,
            }}
        >
            <Box
                sx={{
                    ...getCellAlign(align, numeric),
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: align === 'right' || (align === undefined && numeric) ? 'row-reverse' : 'row',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    {label}{' '}
                    <Box
                        sx={{
                            marginRight: align === 'right' || (align === undefined && numeric) ? 0 : 0.5,
                            marginLeft: align === 'right' || (align === undefined && numeric) ? 0 : 0.5,
                            height: 16,
                            '& > svg': { fontSize: 16 },
                        }}
                    >
                        {active ? (
                            asc ? (
                                <Icon type="ArrowShortUp" />
                            ) : (
                                <Icon type="ArrowShortDown" />
                            )
                        ) : sortable ? (
                            <Box
                                sx={{
                                    width: 16,
                                }}
                            />
                        ) : null}
                    </Box>
                </Box>
            </Box>
        </TableCell>
    );
};

export default SortableTableHeaderCell;
