import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FacebookLetter: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            d="M9.5 8.66667H7V12H9.5V22H13.6667V12H16.7017L17 8.66667H13.6667V7.2775C13.6667 6.48167 13.8267 6.16667 14.5958 6.16667H17V2H13.8267C10.83 2 9.5 3.31917 9.5 5.84583V8.66667Z"
            fill="#3B5998"
        />
    </SvgIcon>
);

export default FacebookLetter;
