import { memo } from 'react';

import { Box, Skeleton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';

import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';

import { FlatAddress } from '../types';
import {
    formatAddressCountry,
    formatCityWithMunicipality,
    formatLocationType,
    getHQ,
    getLocationPriority,
    addressCompareFn,
    getLocaleFromCountry,
} from '../utils';

export interface LocationsTableProps {
    addresses: FlatAddress[];
    country?: string;
    skeleton?: boolean;
}

export const LocationsTable: React.VFC<LocationsTableProps> = memo(({ addresses, country, skeleton = false }) => {
    const intl = useIntl();

    if (skeleton) {
        return (
            <Frame title="Office list" sx={{ width: '100%' }}>
                <Skeleton variant="rounded" width="100%" height={300} />
            </Frame>
        );
    }

    const hq = getHQ(addresses);
    const locale = getLocaleFromCountry(country);

    return (
        <Frame title={`Office list (${addresses.length})`} sx={{ width: '100%' }}>
            <SortableTable<FlatAddress>
                data={addresses}
                defaultSortColumn="types"
                rowKeyField="uid"
                sortLocale={locale}
            >
                <SortableTableColumn<FlatAddress>
                    label="Type"
                    field="types"
                    compareFn={(a, b) => getLocationPriority(b) - getLocationPriority(a)}
                >
                    {(row) => {
                        const type = formatLocationType(row, hq);
                        return (
                            <Box sx={{ display: 'flex', fontWeight: 500 }}>
                                {type === 'Headquarters' ? (
                                    <>
                                        <Icon
                                            type="MapMarker"
                                            color="brandColors.orange"
                                            sx={{
                                                fontSize: 20,
                                                marginRight: 0.5,
                                                marginTop: '-2px',
                                                marginBottom: '-2px',
                                            }}
                                        />
                                        {type}
                                    </>
                                ) : (
                                    type
                                )}
                            </Box>
                        );
                    }}
                </SortableTableColumn>
                <SortableTableColumn<FlatAddress>
                    label="Address"
                    field="street"
                    compareFn={addressCompareFn('street', locale)}
                >
                    {(row) => (
                        <Box sx={{ display: 'flex' }}>
                            {row.street && (
                                <Tooltip
                                    title={
                                        row.addressType === 'visiting_address' ? 'Visiting address' : 'Postal address'
                                    }
                                    placement="left"
                                >
                                    <div>
                                        <Icon
                                            type={row.addressType === 'visiting_address' ? 'LocationOutline' : 'Mail'}
                                            color="text.subtle"
                                            sx={{ fontSize: 16, marginRight: 0.5 }}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {row.street}
                        </Box>
                    )}
                </SortableTableColumn>
                <SortableTableColumn<FlatAddress>
                    label="Postal code"
                    field="postal_code"
                    compareFn={addressCompareFn('postal_code', locale)}
                />
                <SortableTableColumn<FlatAddress>
                    label="City/Postal area"
                    field="city"
                    compareFn={addressCompareFn('city', locale)}
                >
                    {(row) => formatCityWithMunicipality(row.city, row.municipality, row.country)}
                </SortableTableColumn>
                <SortableTableColumn<FlatAddress> label="Country" field="country">
                    {(row: FlatAddress) => formatAddressCountry(row, intl)}
                </SortableTableColumn>
            </SortableTable>
        </Frame>
    );
});

export default LocationsTable;
