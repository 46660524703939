import React, { useRef, useState } from 'react';

import { Box, ClickAwayListener } from '@mui/material';

import { OpenedProfileFromSearchResult } from 'api/types/Search';
import { databaseOptions } from 'components/beta/Platform/Header/DatabaseNavigation';
import CompanyProfilePopup from 'components/layout/CompanyProfilePopup';
import ProfileSelector from 'components/modules/profiles/Profile';
import TextField from 'components/tokens/TextField';
import { useListsContext, Database } from 'contexts/ListsContext';
import { DomainDataResult, OrganizationResult } from 'contexts/types/FilterCompanies';

import CompanySearchSuggestions from './CompanySearchSuggestions';

export type CompanySearchProps = {
    searchTerm: string;
    debouncedSearchTerm: string;
    suggestions: DomainDataResult[] | OrganizationResult[];
    isLoading?: boolean;
    isFetched?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onSubmit: (event?: React.FormEvent) => void;
    onClearForm: () => void;
};

const getSearchPlaceholder = (db: Database | undefined) => {
    if (db === undefined) {
        return '';
    }
    if (db === 'DOMAIN_DATA_BASIC') {
        return 'Search companies globally';
    }
    return `Search companies in ${databaseOptions.find((option) => option.action === db)?.label}`;
};

export const CompanySearch: React.FC<CompanySearchProps> = ({
    searchTerm,
    debouncedSearchTerm,
    suggestions,
    isLoading,
    isFetched,
    onChange,
    onKeyDown,
    onSubmit,
    onClearForm,
}) => {
    const { database } = useListsContext();
    const [searchFocus, setSearchFocus] = useState(false);
    const [openedProfile, setOpenedProfile] = useState<OpenedProfileFromSearchResult | undefined>(undefined);
    const scrollRef = useRef<HTMLDivElement>(null);

    const handleClickAway = () => {
        onClearForm();
        setSearchFocus(false);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        onSubmit(event);
        setSearchFocus(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: 'relative' }}>
                <Box
                    component="form"
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        width: 260,
                    }}
                    role="search"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        type="search"
                        compact
                        icon="Search"
                        inputProps={{
                            'data-testid': 'company-search-input',
                        }}
                        iconBehavior="hideOnFocus"
                        variant="rounded"
                        placeholder={getSearchPlaceholder(database)}
                        value={searchTerm}
                        onFocus={() => setSearchFocus(true)}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                    />
                </Box>
                {searchFocus && (
                    <CompanySearchSuggestions
                        onShowAllSearchResult={() => {
                            onSubmit();
                            setSearchFocus(false);
                        }}
                        onOpenProfile={setOpenedProfile}
                        searchTerm={searchTerm.trim()}
                        debouncedSearchText={debouncedSearchTerm}
                        suggestions={suggestions}
                        isLoading={isLoading}
                        isFetched={isFetched}
                    />
                )}
                {openedProfile !== undefined ? (
                    <CompanyProfilePopup
                        scrollRef={scrollRef}
                        open={openedProfile !== undefined}
                        onClose={() => setOpenedProfile(undefined)}
                    >
                        <ProfileSelector scrollRef={scrollRef} type={openedProfile.type} id={openedProfile.profileId} />
                    </CompanyProfilePopup>
                ) : null}
            </Box>
        </ClickAwayListener>
    );
};

export default CompanySearch;
