import MarketingBanner from 'components/features/MarketingBanner';

type LandingPageProps = {
    onClick: () => void;
};

const LandingPage = ({ onClick }: LandingPageProps) => (
    <div>
        <div style={{ height: 420 }}>
            <MarketingBanner route="profiles" onClick={onClick} />
        </div>
    </div>
);

export default LandingPage;
