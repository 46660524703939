import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ShareOutline = (props) => (
    <SvgIcon {...props}>
        <path d="M17.5 22C16.418 22.0125 15.3924 21.5181 14.7282 20.6637C14.0641 19.8094 13.838 18.6935 14.117 17.648L7.857 14.07C6.77528 15.061 5.18968 15.2708 3.88749 14.5951C2.58529 13.9194 1.84387 12.5022 2.03137 11.0472C2.21886 9.59217 3.29533 8.40923 4.72628 8.08574C6.15722 7.76224 7.63786 8.36711 8.433 9.59999L14.116 6.35099C14.0425 6.07317 14.0035 5.78735 14 5.49999C13.9857 3.8267 15.1478 2.37313 16.7832 2.01881C18.4186 1.66449 20.0781 2.50673 20.7576 4.03591C21.4371 5.56508 20.95 7.36122 19.591 8.3375C18.2319 9.31378 16.3743 9.20208 15.142 8.06999L8.991 11.585C8.98491 11.8443 8.94866 12.102 8.883 12.353L15.142 15.93C16.2943 14.8725 18.0088 14.7093 19.3399 15.5303C20.671 16.3513 21.2946 17.9567 20.8668 19.461C20.439 20.9653 19.0639 22.0023 17.5 22ZM17.5 17C16.6716 17 16 17.6716 16 18.5C16 19.3284 16.6716 20 17.5 20C18.3284 20 19 19.3284 19 18.5C19 17.6716 18.3284 17 17.5 17ZM5.5 9.99999C4.67157 9.99999 4 10.6716 4 11.5C4 12.3284 4.67157 13 5.5 13C6.32843 13 7 12.3284 7 11.5C7 10.6716 6.32843 9.99999 5.5 9.99999ZM17.5 3.99999C16.6716 3.99999 16 4.67156 16 5.49999C16 6.32842 16.6716 6.99999 17.5 6.99999C18.3284 6.99999 19 6.32842 19 5.49999C19 4.67156 18.3284 3.99999 17.5 3.99999Z" />
    </SvgIcon>
);

export default ShareOutline;
