import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Slack = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.33262 2C8.22705 2.00082 7.3323 2.89802 7.33311 4.00359C7.3323 5.10917 8.22787 6.00637 9.33344 6.00719H11.3338V4.00441C11.3346 2.89884 10.439 2.00163 9.33262 2C9.33344 2 9.33344 2 9.33262 2V2ZM9.33262 7.34401H4.00005C2.89448 7.34483 1.99891 8.24203 1.99973 9.3476C1.99809 10.4532 2.89366 11.3504 3.99924 11.352H9.33262C10.4382 11.3512 11.3338 10.454 11.3329 9.34842C11.3338 8.24203 10.4382 7.34483 9.33262 7.34401Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.0001 9.3476C22.0009 8.24203 21.1053 7.34483 19.9997 7.34401C18.8941 7.34483 17.9986 8.24203 17.9994 9.3476V11.352H19.9997C21.1053 11.3512 22.0009 10.454 22.0001 9.3476ZM16.6666 9.3476V4.00359C16.6675 2.89884 15.7727 2.00163 14.6671 2C13.5615 2.00082 12.6659 2.89802 12.6668 4.00359V9.3476C12.6651 10.4532 13.5607 11.3504 14.6663 11.352C15.7719 11.3512 16.6675 10.454 16.6666 9.3476Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6663 22.04C15.7718 22.0392 16.6674 21.142 16.6666 20.0364C16.6674 18.9309 15.7718 18.0337 14.6663 18.0328H12.6659V20.0364C12.6651 21.1412 13.5607 22.0384 14.6663 22.04ZM14.6663 16.6952H19.9996C21.1052 16.6944 22.0008 15.7972 22 14.6916C22.0016 13.586 21.106 12.6888 20.0005 12.6872H14.6671C13.5615 12.688 12.6659 13.5852 12.6667 14.6908C12.6659 15.7972 13.5607 16.6944 14.6663 16.6952Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 14.6915C1.99918 15.7971 2.89476 16.6943 4.00034 16.6952C5.10592 16.6943 6.00149 15.7971 6.00068 14.6915V12.6879H4.00034C2.89476 12.6887 1.99918 13.586 2 14.6915ZM7.33342 14.6915V20.0356C7.33179 21.1412 8.22736 22.0384 9.33294 22.0401C10.4385 22.0392 11.3341 21.142 11.3333 20.0364V14.6932C11.3349 13.5876 10.4393 12.6904 9.33376 12.6887C8.22736 12.6887 7.3326 13.586 7.33342 14.6915C7.33342 14.6915 7.33342 14.6924 7.33342 14.6915Z"
        />
    </SvgIcon>
);

export default Slack;
