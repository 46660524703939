import React from 'react';

import { ImportListAsyncProcess } from 'api/types/UserAsyncProcess';
import Icon from 'components/tokens/Icon';

import { AsyncProcessNotificationProps } from '../NotificationItem';
import NotificationItemContent from '../NotificationItemContent';
import { progress } from '../utils';
import { NotificationStateBasedData } from './CRMBulkExport';
import TitleWithCheckmark from './TitleWithCheckmark';

const getUploadBasedData = (notification: ImportListAsyncProcess): NotificationStateBasedData => {
    const listName = notification.meta_data.list_name;
    const listNameString = listName ? `to "${listName}"` : '';
    const fileName = notification.meta_data?.uploaded_file_name ?? '';
    const current = notification.progress ?? 0;
    const total = notification.progress_max ?? 0;
    switch (notification.state) {
        case 'process':
            // case 'before':
            return {
                title: `Data uploading from file ${fileName} ${listNameString}`,
                progress: progress(current, total),
                date: notification.created,
                hoverText: 'In progress',
            };
        case 'stopped':
            return {
                title: `Data upload stopped for ${fileName || 'file'}`,
                content: 'Upload stopped!',
                date: notification.finished,
                hoverText: 'In progress',
            };
        case 'completed':
            return {
                title: (
                    <TitleWithCheckmark>
                        Data uploaded from {fileName || 'file'} {listNameString}
                    </TitleWithCheckmark>
                ),
                content: 'Completed',
                date: notification.finished,
                hoverText: 'Completed',
            };
        default:
            return {
                title: 'Error occurred in file upload',
                content: `Failed to upload data from file ${fileName} ${listNameString}`,
                error: true,
                date: notification.finished,
                hoverText: 'Failed',
            };
    }
};

const CSVUpload: React.FC<AsyncProcessNotificationProps<ImportListAsyncProcess>> = ({
    notification,
    handleMarkAsSeen,
}) => {
    const notificationContent = getUploadBasedData(notification);
    if (!notification.state || !notification.meta_data) return null;

    return (
        <NotificationItemContent
            icon={<Icon type="Excel2019" />}
            seen={notification.seen}
            state={notification.state}
            handleMarkAsSeen={handleMarkAsSeen}
            {...notificationContent}
        />
    );
};

export default CSVUpload;
