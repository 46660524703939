import React from 'react';

function Icon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" {...props}>
            <path
                fill="#FF7A59"
                d="M28.872 15.905v-3.514a2.708 2.708 0 001.563-2.443v-.083a2.715 2.715 0 00-2.708-2.707h-.082a2.715 2.715 0 00-2.708 2.707v.083a2.71 2.71 0 001.563 2.443v3.522a7.68 7.68 0 00-3.651 1.606l-9.667-7.528a3.082 3.082 0 00-5.424-2.675 3.084 3.084 0 003.98 4.554l9.504 7.399a7.7 7.7 0 00.119 8.684l-2.893 2.892a2.462 2.462 0 00-.717-.117 2.51 2.51 0 102.51 2.511 2.462 2.462 0 00-.116-.718l2.86-2.861a7.715 7.715 0 105.862-13.755m-1.185 11.58a3.96 3.96 0 01.013-7.917 3.958 3.958 0 013.954 3.96 3.958 3.958 0 01-3.958 3.959"
            />
        </svg>
    );
}

export default Icon;
