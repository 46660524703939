import { useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty, isEqual } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { mergeKeys } from 'components/features/Filters/common/utils';
import { filterDefinitions } from 'components/features/Filters/filters/filterDefinitions';
import { getMapFilterData } from 'components/features/Filters/filters/location/MapFilter/utils/mapFilterUtils';
import { getAllLeaves } from 'components/tokens/select-components/NestedSelect/utils';
import usePrevious from 'hooks/usePrevious';
import { getObjectKeys } from 'utilities/objectUtils';

import { getValue, getValuePath, getFieldById } from '../../../filters/utils';
import { FilterID, NodeValue, Filter, GroupOption, UpdateGroupsFn } from '../../../FilterTypes';
import { useGroupNodes } from '../../common/useGroup';
import { useKeys2 } from '../../common/useKeys';
import {
    createNewSlot,
    fillDefaultSlots,
    isSelfGroup,
    nodesToSlots,
    Slot,
    slotsToNodes,
    SlotType,
    sortSlots,
} from './utils/helpers';
import { validateSlot } from './utils/validation';

export interface UseGroupActionsProps {
    ids: string[];
    applyChanges: () => void;
    options?: GroupOption[];
    updateGroups?: UpdateGroupsFn;
}

export const useLocationGroupActions = ({ ids, options, applyChanges, updateGroups }: UseGroupActionsProps) => {
    const { slots, setSlots, addSlot } = useSlots(ids);

    const { getValues } = useFormContext();

    const { keys, updateKeys } = useKeys2(slots);

    const addFilter = useCallback(
        (filterId: FilterID, filterDefinition: Filter<unknown>, slotId: number | undefined) => {
            // Not create a filter if it is missing in the group options

            if (options) {
                const endOptions = getAllLeaves(options);

                if (!endOptions?.some((option) => option.value === filterId)) {
                    return;
                }
            }

            const updatedSlots = [...slots];

            if (slotId === undefined) {
                const slot = createNewSlot();
                if (isSelfGroup(filterDefinition.id)) {
                    slot.type = filterDefinition.id as SlotType;
                } else {
                    slot.type = 'location';
                }

                const createFilter = filterDefinition?.createFilter;

                if (!createFilter) {
                    return;
                }

                const newValue = createFilter();
                const valuePath = getValuePath(filterId as never, newValue);

                slot.values.push({
                    value: newValue,
                    id: filterId,
                    definition: filterDefinition,
                    uuid: '',
                    valuePath,
                    path: '',
                    groupPath: '',
                });

                const validationResult = validateSlot(slot);
                slot.status = validationResult.status;
                addSlot(slot);
                return;
            }

            const slot = updatedSlots.find((slot) => slot.id === slotId);

            if (!slot) {
                return;
            }

            const createFilter = filterDefinition?.createFilter;

            if (!createFilter) {
                return;
            }

            const newValue = createFilter();
            const valuePath = getValuePath(filterId as never, newValue);

            slot.values.push({
                value: newValue,
                id: filterId,
                definition: filterDefinition,
                uuid: '',
                valuePath,
                groupPath: '',
                path: '',
            });

            const validationResult = validateSlot(slot);

            slot.status = validationResult.status;
            setSlots(updatedSlots);

            updateKeys(true);
        },
        [options, slots, setSlots, updateKeys, addSlot],
    );

    const handleAddFilter = useCallback(
        (filterId: FilterID, slotId: number | undefined) => {
            const definition = filterDefinitions[filterId];

            if (!definition) {
                return;
            }

            addFilter(filterId, definition, slotId);
        },
        [addFilter],
    );

    const handleFilterRemove = useCallback(
        (slotId: number, filterIndex: number) => {
            setSlots((prev) => {
                const updatedSlots = [...prev];
                const slot = updatedSlots[slotId];

                const updatedArray = [...slot.values];
                // Map filter is like VCI filter in structure so each layer creates its own filter.
                // But we are showing all layers in 1 filter only.
                // If filter index belongs to map filter, remove all map filters
                const isMapFilterIndex = updatedArray.findIndex((value) => value.id === FilterID.coordinates);
                if (isMapFilterIndex === filterIndex) {
                    const mapFilterCount = updatedArray.filter((value) => value.id === FilterID.coordinates);
                    updatedArray.splice(filterIndex, mapFilterCount.length);
                } else {
                    updatedArray.splice(filterIndex, 1);
                }
                slot.values = updatedArray;

                const validationResult = validateSlot(slot);
                slot.status = validationResult.status;
                return updatedSlots;
            });
            updateKeys(true);
        },
        [updateKeys, setSlots],
    );

    const handleSlotRemove = useCallback(
        (slotId: number) => {
            const updatedSlots = [...slots];

            updatedSlots.splice(slotId, 1);
            setSlots(updatedSlots);
        },
        [slots, setSlots],
    );

    const handleSlotClear = useCallback(
        (slotId: number) => {
            const slotToClear = slots[slotId];

            if (slotToClear) {
                slotToClear.values = [];
                slotToClear.status = null;
                setSlots((slots) => [...slots]);
            }
        },
        [slots, setSlots],
    );

    const handleApplyChanges = useCallback(() => {
        const filteredSlots: Slot[] = [];
        let hasMapFilter = false;
        slots.forEach((slot) => {
            // Filter slot.values with empty values (empty filters)
            const filteredValues = slot.values.filter(({ value, id }) => {
                if (id === FilterID.coordinates) {
                    if (hasMapFilter) {
                        return false;
                    } else {
                        hasMapFilter = true;
                    }

                    // check both include & exclude group for map filter
                    const { includeFields, excludeFields } = getMapFilterData(value);
                    if (!includeFields.length && !excludeFields.length) {
                        return false;
                    }
                } else {
                    const nodeValue = getValue(getFieldById(id), value);

                    if (!nodeValue) {
                        return false;
                    } else if (isEmpty(nodeValue)) {
                        return false;
                    } else if (Array.isArray(nodeValue) && nodeValue[0] == null && nodeValue[1] == null) {
                        return false;
                    }
                }

                return true;
            });

            // Filter slots without any values
            if (filteredValues.length) {
                filteredSlots.push({ ...slot, values: filteredValues });
            }
        });

        const groups = slotsToNodes(filteredSlots);

        /**
         * Location group has internal structure for handling grouping (slots) logic, that not exists on the backend
         * and this structure also does not exists in the form. For location group the slots array is source of truth,
         * that why we need always rewrite all nodes related to that group
         */

        // Get all filters nodes from all groups
        const fields: NodeValue[] = getValues('?ALL') ?? [];

        // Remove the nodes that belongs to location group
        const filteredFields = fields.filter((field, index) => {
            if (ids.includes(mergeKeys('?ALL', index))) {
                return false;
            }

            return true;
        });

        // Add location nodes converted from slots
        const updatedArray = filteredFields.concat(groups as NodeValue[]);

        updateGroups?.(updatedArray);
        applyChanges();
    }, [applyChanges, getValues, ids, slots, updateGroups]);

    return useMemo(
        () => ({
            slots,
            setSlots,
            handleAddFilter,
            keys,
            handleFilterRemove,
            handleApplyChanges,
            handleSlotRemove,
            handleSlotClear,
        }),
        [
            handleAddFilter,
            handleApplyChanges,
            handleFilterRemove,
            handleSlotClear,
            handleSlotRemove,
            keys,
            setSlots,
            slots,
        ],
    );
};

const EMPTY_SLOTS: Slot[] = [];
export function useSlots(ids: string[]) {
    const filterGroups = useGroupNodes(ids);
    const [slots, setSlots] = useState<Slot[]>(EMPTY_SLOTS);

    const setSortedSlots = useCallback((value: React.SetStateAction<Slot[]>) => {
        if (typeof value === 'function') {
            setSlots((prev) => {
                return sortSlots(value(prev));
            });
        } else {
            setSlots(sortSlots(value));
        }
    }, []);

    const addSlot = useCallback(
        (slot: Slot) => {
            setSortedSlots((prev) => [...prev, slot]);
        },
        [setSortedSlots],
    );

    const prevIds = usePrevious(ids);

    useEffect(() => {
        if (!isEqual(ids, prevIds)) {
            const nodes = getObjectKeys(filterGroups).map((key) => filterGroups[key].filters);
            const rawSlots = nodesToSlots(nodes);

            const updatedSlots = fillDefaultSlots(rawSlots);

            setSortedSlots(updatedSlots);
        }
    }, [filterGroups, prevIds, ids, setSortedSlots]);

    return useMemo(() => ({ slots, setSlots: setSortedSlots, addSlot }), [addSlot, setSortedSlots, slots]);
}
