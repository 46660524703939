import { useRef, useState } from 'react';

import { Box } from '@mui/material';

import { AuthResponse, getJWT } from 'api/auth';
import { Button } from 'components/tokens/Button';
import { Dialog } from 'components/tokens/Dialog';
import { Link } from 'components/tokens/Link';
import { TextField } from 'components/tokens/TextField';
import { Typography } from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';

const JwtAuthentication = () => {
    const axios = useAxiosContext();
    const lifetimeInput = useRef<HTMLInputElement>(null);
    const [jwt, setJwt] = useState<AuthResponse | null>(null);
    const [isInvalid, setIsInvalid] = useState(false);

    return (
        <Box sx={{ display: 'grid', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h3" color="dark">
                    JWT authentication
                </Typography>
            </Box>
            <Typography variant="small">
                See{' '}
                <Link href="https://developers.vainu.com/docs/authentication" toNewTab>
                    developers.vainu.com
                </Link>{' '}
                for instructions
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                <TextField
                    ref={lifetimeInput}
                    onChange={() => setIsInvalid(false)}
                    type="number"
                    label="Lifetime in days"
                    sx={{ width: 140 }}
                    compact
                    defaultValue={30}
                    errorText="1-365 days"
                    status={isInvalid ? 'error' : 'default'}
                />
                <Button
                    onClick={async () => {
                        const lifetime = Number(lifetimeInput.current?.value);

                        if (isNaN(lifetime) || lifetime < 1 || lifetime > 365) {
                            setIsInvalid(true);
                            return;
                        }

                        const response = await getJWT(axios, lifetime);
                        setJwt(response.data);
                    }}
                >
                    Get a key
                </Button>
            </Box>

            {jwt && (
                <Dialog fullWidth open onClose={() => setJwt(null)} cancelLabel="Close">
                    <Typography variant="subtitle1">Refresh token:</Typography>
                    <TextField rows="6" multiline value={jwt.refresh} onFocus={(e) => e.target.select()} />
                </Dialog>
            )}
        </Box>
    );
};

export default JwtAuthentication;
