import { useCallback, useEffect, useMemo, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { mergeKeys } from '../../common/utils';
import { MappedFilter } from '../../FilterTypes';
import { Slot } from '../groups/LocationGroup/utils/helpers';

// Hack to fix keys issue, would be great to do it some better way :(
export const useKeys = (ids: string[], filterGroups: Record<string, { filters: MappedFilter[] } | undefined>) => {
    const [keys, setKeys] = useState<Record<string, string>>({});

    const updateKeys = useCallback(
        (force?: boolean) => {
            const map = { ...keys };
            let changed = false;
            ids.forEach((id) => {
                filterGroups[id]?.filters.forEach((filter) => {
                    const path = mergeKeys(id, filter.id);
                    if (!map[path] || force) {
                        changed = true;
                        map[path] = uuid();
                    }
                });
            });

            if (changed) {
                setKeys(map);
            }
        },
        [ids, filterGroups, keys],
    );

    useEffect(() => {
        updateKeys();
    }, [updateKeys]);

    return { keys, updateKeys };
};

export const useKeys2 = (slots: Slot[]) => {
    const [keys, setKeys] = useState<Record<string, string>>({});

    const updateKeys = useCallback(
        (force?: boolean) => {
            const map = { ...keys };
            let changed = false;

            slots.forEach((slot, slotIndex) => {
                slot.values.forEach((filter, filterIndex) => {
                    const path = mergeKeys(slotIndex, filterIndex, filter.id);
                    if (!map[path] || force) {
                        changed = true;
                        map[path] = uuid();
                    }
                });
            });

            if (changed) {
                setKeys(map);
            }
        },
        [slots, keys],
    );

    useEffect(() => {
        updateKeys();
    }, [updateKeys]);

    return useMemo(() => ({ keys, updateKeys }), [keys, updateKeys]);
};
