import React, { useState } from 'react';

import { Badge, Box } from '@mui/material';
import { format, formatDistanceToNow, isToday } from 'date-fns';

import { AsyncProcessState } from 'api/types/UserAsyncProcess';
import { BorderLinearProgress } from 'components/modules/connectors/common/BorderLinearProgress';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { brandPalette } from 'design/theme/colors';
import { isAsyncProcessStateOngoing } from 'utilities/asyncProcess';
import { toDateUTC } from 'utilities/date';

import { CancelBtn, Notification, NotificationRight, StyledLogoWrapper } from './styled';

type NotificationBadgeProps = {
    seen: boolean;
    icon: React.ReactNode;
    state?: AsyncProcessState;
};

export type NotificationItemContentProps = {
    icon: React.ReactNode;
    seen: boolean;
    error?: boolean;
    title: React.ReactNode;
    content?: string;
    customContent?: React.ReactNode;
    date?: string;
    hoverText?: string;
    progress?: number;
    state?: AsyncProcessState;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onDismiss?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    handleMarkAsSeen?: () => void;
    progressVariant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
    inProgressText?: string;
};

const getNotificationDotColor = (state: AsyncProcessState | undefined) => {
    switch (state) {
        case 'completed':
            return brandPalette.tertiaryBlue;
        case 'failure':
            return brandPalette.errorMagenta;
        case 'accepted':
        case 'process':
            return brandPalette.warningOrange;
        default:
            return brandPalette.tertiaryBlue;
    }
};

const NotificationBadge: React.FC<NotificationBadgeProps> = ({ seen, state, icon }) => {
    return (
        <StyledLogoWrapper>
            <Badge
                invisible={seen}
                variant="dot"
                overlap="circular"
                sx={{
                    height: '100%',
                    width: '40px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .MuiBadge-badge': {
                        border: `2px solid`,
                        borderColor: 'background.paper',
                        height: '12px',
                        width: '12px',
                        borderRadius: '50%',
                        backgroundColor: getNotificationDotColor(state),
                    },
                }}
            >
                {icon}
            </Badge>
        </StyledLogoWrapper>
    );
};

const NotificationItemContent: React.FC<NotificationItemContentProps> = ({
    icon,
    seen,
    title,
    content,
    // customContent prop overrides content prop
    // and gives us possibility to pass React.Node instead of string
    // todo: refactor in future
    customContent,
    date,
    hoverText,
    progress,
    error,
    state,
    onClick,
    onCancel,
    handleMarkAsSeen,
    progressVariant = 'determinate',
    inProgressText = 'In progress',
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const isOngoing = state && isAsyncProcessStateOngoing(state);
    const showCancel = !!onCancel && isOngoing;

    return (
        <Notification
            sx={{ cursor: onClick ? 'pointer' : undefined }}
            onClick={onClick}
            onMouseEnter={() => {
                setIsHovered(true);

                // prevent unnecessary patch request if notification is already seen
                if (handleMarkAsSeen && !seen) {
                    handleMarkAsSeen();
                }
            }}
            onMouseLeave={() => setIsHovered(false)}
        >
            <NotificationBadge seen={seen} icon={icon} state={state} />
            <NotificationRight>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ color: error ? 'error.main' : 'primary.main' }} variant="small" weight="semibold">
                        {title}
                    </Typography>
                    {showCancel && (
                        <CancelBtn
                            size="small"
                            variant="flat"
                            startIcon={<Icon type="OffClose" color="border" />}
                            onClick={onCancel}
                        />
                    )}
                </Box>
                <Typography
                    variant="tiny"
                    color="default"
                    sx={{
                        marginBottom: 1,
                        overflow: 'hidden',
                        display: '-webkit-box !important',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        whiteSpace: 'normal',
                    }}
                >
                    {customContent ?? content}
                </Typography>
                {isOngoing ? (
                    <>
                        <BorderLinearProgress variant={progressVariant} value={progress} />
                        <Typography variant="mini" color="subtle" sx={{ marginTop: 0.5 }}>
                            {inProgressText}
                        </Typography>
                    </>
                ) : (
                    !!date && (
                        <Typography variant="mini" color="subtle">
                            {isHovered && hoverText ? hoverText : formatDate(date)}
                        </Typography>
                    )
                )}
            </NotificationRight>
        </Notification>
    );
};

const formatDate = (date: string) => {
    if (!date) return '';
    const dateObj = toDateUTC(date);
    return isToday(dateObj) ? formatDistanceToNow(dateObj, { addSuffix: true }) : format(dateObj, 'MMM d, HH:mm');
};

export default NotificationItemContent;
