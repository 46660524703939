import { AppProviders } from './AppProviders';
import Core from './Core';

export const Platform = () => (
    <AppProviders>
        <Core />
    </AppProviders>
);

export default Platform;
