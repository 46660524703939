import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Code: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M8.01 18.02L2 12.01L8.01 6L9.425 7.414L4.825 12.014L9.425 16.614L8.011 18.02H8.01ZM15.989 18.02L14.576 16.607L19.176 12.007L14.576 7.407L15.99 6L22 12.01L15.99 18.02H15.989Z" />
    </SvgIcon>
);

export default Code;
