import React, { useEffect, useState } from 'react';

import { CircularProgress, Tooltip, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { differenceInMinutes } from 'date-fns';
import { FormattedNumber } from 'react-intl';
import { pluralize } from 'utilities';

import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { toDateUTC } from 'utilities/date';

const COUNT_UPDATE_INTERVAL = 10000;

export type CompanyCountProps = {
    companiesCount: number;
    loadingCount?: boolean;
    countError?: boolean;
    countEta?: string;
    finalCount?: boolean;
};

const CompanyCount: React.FC<CompanyCountProps> = ({
    companiesCount,
    loadingCount,
    countError,
    countEta,
    finalCount,
}) => {
    const [countMinutesRemaining, setCountMinutesRemaining] = useState<number | null>(null);

    useEffect(() => {
        setCountMinutesRemaining(null);

        if (!countEta) {
            return;
        }

        const updateRemaining = () => {
            const diff = Math.max(differenceInMinutes(toDateUTC(countEta), new Date()), 0);
            setCountMinutesRemaining(diff);
        };

        updateRemaining();

        const intervalId = setInterval(updateRemaining, COUNT_UPDATE_INTERVAL);

        return () => clearInterval(intervalId);
    }, [countEta]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="small" weight="semibold">
                <FormattedNumber value={companiesCount} />
                {finalCount === false && '+'} {pluralize(companiesCount, 'result')}
            </Typography>

            {countError && (
                <Tooltip title="There was an error in loading the number of results. Our team has been notified and we're looking into it.">
                    <span>
                        <Icon type="InfoCircleOutline" fontSize="mini" color="error.main" sx={{ display: 'block' }} />
                    </span>
                </Tooltip>
            )}
            {loadingCount && (
                <>
                    {finalCount ? (
                        <DelayedContent>
                            <Tooltip
                                title={
                                    <div>
                                        <p>We're refreshing results, this number might change slightly.</p>
                                        {countMinutesRemaining != null && (
                                            <p style={{ marginTop: 8 }}>
                                                {countMinutesRemaining || '<1'} minute
                                                {countMinutesRemaining > 1 && 's'} remaining
                                            </p>
                                        )}
                                    </div>
                                }
                            >
                                <span>
                                    <Icon
                                        type="InfoCircleOutline"
                                        fontSize="mini"
                                        color="text.subtle"
                                        sx={{ display: 'block' }}
                                    />
                                </span>
                            </Tooltip>
                        </DelayedContent>
                    ) : (
                        <Tooltip title="You can keep browsing, results will load in the background">
                            <CircularProgress size="1em" />
                        </Tooltip>
                    )}
                </>
            )}
        </Box>
    );
};

export default CompanyCount;

const DelayedContent = styled(Box)({
    animation: '2s linear forwards delay',
    '@keyframes delay': {
        '0%': { display: 'none' },
        '99%': { display: 'none' },
        '100%': { display: 'block' },
    },
});
