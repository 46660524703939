import BarTile from 'components/features/CRMInsights/Tiles/BarTile';
import { DEFAULT_DATA, defaultMinWidth } from 'components/features/CRMInsights/utils';

import { TileProps } from '../NewVehicles';

const GearingTile: React.FC<TileProps> = ({ data, valueRange, valueField }) => {
    const gearingData = data?.find((row) => row.id === 'gearing');

    return (
        <BarTile
            title="Gearing"
            data={
                gearingData?.result[0].buckets.map((bucket) => ({
                    value: bucket[valueField],
                    label: (bucket.value as string) || 'Unknown',
                    valueLabel: valueRange ? `${bucket.count_min}-${bucket.count_max}` : bucket[valueField],
                })) || DEFAULT_DATA
            }
            total={(gearingData?.result[0].buckets[0]?.[valueField] as number) || 0}
            sx={{ minWidth: defaultMinWidth, maxWidth: 'max(600px, 50%)' }}
            firstColumn={{ label: 'Gearing' }}
            lastColumn={{ label: 'Vehicles' }}
            hidePercent
            percentHeaderLabel=""
        />
    );
};

export default GearingTile;
