import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { Database, useListsContext } from 'contexts/ListsContext';

export type SortOrder = 'descending' | 'ascending';

export type SortId =
    | '-vainu_score'
    | 'vainu_score'
    | 'company_name'
    | '-company_name'
    | '-turn_over_c'
    | 'turn_over_c'
    | '-staff_number_c'
    | 'staff_number_c'
    | 'website_traffic_data.ranking'
    | '-website_traffic_data.ranking'
    | 'domain'
    | '-domain'
    | 'similarity'
    | '-similarity'
    | 'none'
    | '-none';

type ActiveSort = {
    name: string;
    id: SortId;
    type: SortOrder;
    databases: string[];
    isDefaultOrder?: boolean;
};

type SortCompanyOptions = { [sortKey: string]: ActiveSort };

type SortCompanyContextState = {
    activeSortType: ActiveSort;
    sortOrder: SortOrder;
    changeSortOrder: (value: SortOrder) => void;
    handleActiveSort: (newValue: SortId) => void;
    sortCompanyOptions: SortCompanyOptions;
};

interface SortCompanyProps {
    sortBy?: SortId;
    sortByOrder?: SortOrder;
    changeSort?: (value: SortId) => void;
    database?: Database;
    children?: React.ReactNode;
}

const NORDIC_DATABASES = ['FI', 'SE', 'NO', 'DK'];

const sortOptions: SortCompanyOptions = {
    '-vainu_score': {
        name: 'Vainu score',
        id: '-vainu_score',
        type: 'descending',
        databases: ['DOMAIN_DATA', 'DOMAIN_DATA_BASIC', ...NORDIC_DATABASES],
        isDefaultOrder: true,
    },
    vainu_score: {
        name: 'Vainu score',
        id: 'vainu_score',
        type: 'ascending',
        databases: ['DOMAIN_DATA', 'DOMAIN_DATA_BASIC', ...NORDIC_DATABASES],
    },
    '-domain': { name: 'Domain', id: '-domain', type: 'descending', databases: ['DOMAIN_DATA', 'DOMAIN_DATA_BASIC'] },
    domain: {
        name: 'Domain',
        id: 'domain',
        type: 'ascending',
        databases: ['DOMAIN_DATA', 'DOMAIN_DATA_BASIC'],
        isDefaultOrder: true,
    },
    '-company_name': { name: 'Company name', id: '-company_name', type: 'descending', databases: NORDIC_DATABASES },
    company_name: {
        name: 'Company name',
        id: 'company_name',
        type: 'ascending',
        databases: NORDIC_DATABASES,
        isDefaultOrder: true,
    },
    '-turn_over_c': {
        name: 'Revenue',
        id: '-turn_over_c',
        type: 'descending',
        databases: NORDIC_DATABASES,
        isDefaultOrder: true,
    },
    turn_over_c: { name: 'Revenue', id: 'turn_over_c', type: 'ascending', databases: NORDIC_DATABASES },
    '-website_traffic_data.ranking': {
        name: 'Web rank',
        id: '-website_traffic_data.ranking',
        type: 'descending',
        databases: ['DOMAIN_DATA', 'DOMAIN_DATA_BASIC'],
    },
    'website_traffic_data.ranking': {
        name: 'Web rank',
        id: 'website_traffic_data.ranking',
        type: 'ascending',
        databases: ['DOMAIN_DATA', 'DOMAIN_DATA_BASIC'],
        isDefaultOrder: true,
    },
    '-staff_number_c': {
        name: 'Employees',
        id: '-staff_number_c',
        type: 'descending',
        databases: NORDIC_DATABASES,
        isDefaultOrder: true,
    },
    staff_number_c: { name: 'Employees', id: 'staff_number_c', type: 'ascending', databases: NORDIC_DATABASES },
    // "order: 'none'" is a temp fix for Global DB
    // The server should process the request faster when the keywords filter is enabled.
    none: {
        name: 'Unordered',
        id: 'none',
        type: 'ascending',
        databases: ['DOMAIN_DATA', 'DOMAIN_DATA_BASIC'],
        isDefaultOrder: true,
    },
    '-none': {
        name: 'Unordered',
        id: '-none',
        type: 'descending',
        databases: ['DOMAIN_DATA', 'DOMAIN_DATA_BASIC'],
    },
};

export const SortCompanyContext = createContext<SortCompanyContextState>({} as SortCompanyContextState);

export const SortCompanyProvider: React.FC<SortCompanyProps> = ({
    children,
    sortBy = '-vainu_score',
    sortByOrder = 'descending',
    changeSort,
}) => {
    const { database, selectedList, setSelectedListSort } = useListsContext();
    const [activeSort, setActiveSort] = useState<ActiveSort>(sortOptions[selectedList?.sortBy || sortBy]);
    const [sortOrder, setSortOrder] = useState<SortOrder>(sortByOrder);
    const { Provider } = SortCompanyContext;

    const handleActiveSort = useCallback(
        (newSort: SortId) => {
            setActiveSort(sortOptions[newSort]);
            setSortOrder(sortOptions[newSort].type);
            changeSort && changeSort(newSort);
            setSelectedListSort(newSort);
        },
        [changeSort, setSelectedListSort],
    );

    useEffect(() => {
        if (selectedList?.sortBy) {
            setActiveSort(sortOptions[selectedList.sortBy]);
            setSortOrder(selectedList.sortBy[0] === '-' ? 'descending' : 'ascending');
            changeSort?.(selectedList.sortBy as SortId);
        } else {
            setActiveSort(sortOptions[sortBy]);
            setSortOrder(sortByOrder);
            changeSort?.(sortBy);
        }
    }, [selectedList, changeSort, sortBy, sortByOrder]);

    const options = useMemo(() => {
        const opts: SortCompanyOptions = {};
        Object.keys(sortOptions).forEach((key) => {
            if (
                database &&
                sortOptions[key].databases.includes(database) &&
                (activeSort.id === sortOptions[key].id ||
                    (activeSort.name !== sortOptions[key].name && sortOptions[key].isDefaultOrder))
            ) {
                opts[key] = sortOptions[key];
            }
        });
        return opts;
    }, [activeSort, database]);

    const changeSortOrder = useCallback(
        (value: SortOrder) => {
            let id;
            if (value === 'descending') {
                id = activeSort.id.startsWith('-') ? activeSort.id : `-${activeSort.id}`;
            } else {
                id = activeSort.id.startsWith('-') ? activeSort.id.replace('-', '') : activeSort.id;
            }
            setActiveSort(sortOptions[id]);
            changeSort && changeSort(id as SortId);
            setSelectedListSort(id as SortId);
            setSortOrder(value);
        },
        [activeSort.id, changeSort, setSelectedListSort],
    );

    const value = useMemo(
        () => ({
            activeSortType: activeSort,
            handleActiveSort,
            sortCompanyOptions: options,
            sortOrder,
            changeSortOrder,
        }),
        [activeSort, changeSortOrder, handleActiveSort, options, sortOrder],
    );

    return <Provider value={value}>{children}</Provider>;
};
