import React, { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Destination, LeadPreview } from 'contexts/TriggerContext';
import { enabledCRMs } from 'utilities/triggers';

import CompanyCell from './CompanyCell';
import LeadCell from './LeadCell';
import PreviewCell from './PreviewCell';
import PreviewModal from './PreviewModal';
import { Column } from './TableComponent';

interface Props {
    data: LeadPreview[];
    selectedDestination: Destination | null;
}

const columns: Column[] = [
    {
        title: 'Title',
        id: 'lead',
        Cell: LeadCell,
    },
    {
        title: 'Company',
        id: 'company',
        Cell: CompanyCell,
    },
    {
        title: 'Preview',
        id: 'preview',
        Cell: PreviewCell,
    },
];

const ExportTable: React.FC<Props> = ({ data, selectedDestination }) => {
    const [showPreviewModal, setShowPreviewModal] = useState<string | null>(null);
    const hasCRMdestination = enabledCRMs.includes(selectedDestination?.system || '');

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((col) => (
                            <TableCell key={col.id}>{col.title}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            {columns.map((col) => (
                                <TableCell key={col.id}>
                                    <col.Cell
                                        hasCRMdestination={hasCRMdestination}
                                        setShowPreviewModal={setShowPreviewModal}
                                        row={row}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <PreviewModal
                open={showPreviewModal !== null}
                data={showPreviewModal !== null ? data?.find((row) => row.id === showPreviewModal) || null : null}
                onClose={() => setShowPreviewModal(null)}
                selectedDestination={selectedDestination}
            />
        </div>
    );
};

export default ExportTable;
