import React from 'react';

import { Grid, Pagination as MUIPagination, PaginationItem, /*SelectChangeEvent,*/ SxProps } from '@mui/material';
// import MenuItem from '@mui/material/MenuItem';
// import Button from 'components/tokens/Button';

export type PaginationProps = {
    page: number;
    count: number;
    // showJumpToPage?: boolean;
    sx?: SxProps;
    onPageChange: ((event: React.ChangeEvent<unknown>, page: number) => void) | undefined;
    // handleJumpToPage?: (showJumpToPage: boolean) => void;
    // handleSelectPage?: (event: SelectChangeEvent<number>, child: React.ReactNode) => void;
};

export const Pagination: React.FC<PaginationProps> = ({
    page = 1,
    count = 9,
    // showJumpToPage = false,
    sx,
    onPageChange,
    // handleJumpToPage,
    // handleSelectPage,
}) => {
    const hidePrevButton = page === 1;
    const hideNextButton = page === count;
    // const showFirstButton = page !== 1;
    // const showLastButton = page !== count;

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={sx}>
            <Grid item xs={12}>
                <MUIPagination
                    count={count}
                    boundaryCount={0}
                    page={page}
                    onChange={onPageChange}
                    hidePrevButton={hidePrevButton}
                    hideNextButton={hideNextButton}
                    siblingCount={3}
                    size="large"
                    shape="rounded"
                    renderItem={(item) => {
                        return (
                            <PaginationItem
                                {...item}
                                sx={{
                                    margin: 0,
                                    minWidth: 36,
                                    borderRadius: 2,
                                    '&.Mui-selected, &.Mui-selected:hover': {
                                        backgroundColor: 'transparent',
                                        color: 'grey.200',
                                    },
                                }}
                            />
                        );
                    }}
                    // showFirstButton={showFirstButton}
                    // showLastButton={showLastButton}
                />
            </Grid>
            {/* <Grid item xs={12}>
                <Frame elevation={0}>
                    {showJumpToPage && (
                        <Button size="small" variant="flat" onClick={() => handleJumpToPage(false)}>
                            Jump to page
                        </Button>
                    )}
                    {!showJumpToPage && (
                        <Select
                            value={page}
                            onChange={handleSelectPage}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {Array.from({ length: count }, (_, index) => index).map((_, index) => (
                                <MenuItem
                                    key={index}
                                    style={{ display: 'block', textAlign: 'center' }}
                                    value={index + 1}
                                >
                                    {index + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </Frame>
            </Grid> */}
        </Grid>
    );
};

export default Pagination;
