import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Share = (props) => (
    <SvgIcon {...props}>
        <path d="M5.5 15C6.37466 14.9974 7.21623 14.6653 7.857 14.07L14.117 17.647C13.6834 19.3413 14.5595 21.0969 16.174 21.7692C17.7884 22.4415 19.6517 21.8265 20.5487 20.3253C21.4458 18.824 21.1047 16.8918 19.7477 15.7885C18.3908 14.6852 16.4296 14.7455 15.143 15.93L8.883 12.353C8.94863 12.1021 8.98487 11.8443 8.991 11.585L15.142 8.07C16.3629 9.17994 18.1944 9.28285 19.532 8.31668C20.8695 7.35051 21.3474 5.57944 20.6774 4.07159C20.0073 2.56374 18.3726 1.73141 16.7591 2.07657C15.1456 2.42173 13.9945 3.85 14 5.5C14.0036 5.78808 14.0429 6.0746 14.117 6.353L8.433 9.6C7.5225 8.19142 5.74072 7.62676 4.18511 8.25381C2.6295 8.88086 1.73739 10.5233 2.05826 12.1696C2.37913 13.8158 3.82277 15.0031 5.5 15Z" />
    </SvgIcon>
);

export default Share;
