import React from 'react';

import { SxProps } from '@mui/material';
import clsx from 'clsx';

import StyledAvatar from './styled';

export type LogoSize = 'xlarge' | 'larger' | 'large' | 'medium' | 'small' | 'tiny' | 'xtiny';
export type LogoVariant = 'round' | 'roundedRect';

export type AvatarProps = {
    size?: LogoSize;
    variant?: LogoVariant;
    sx?: SxProps;
    className?: string;
    border?: boolean;
    borderColor?: 'black' | 'grey';
    onClick?: React.MouseEventHandler<HTMLDivElement>;
} & (
    | {
          skeleton?: false;
          children: React.ReactNode;
      }
    | {
          skeleton: true;
          children?: never;
      }
);

export const Avatar: React.FC<AvatarProps> = ({
    skeleton = false,
    size = 'small',
    variant = 'round',
    border = false,
    borderColor = 'black',
    sx,
    className,
    children,
    onClick,
}) => {
    const combinedClassName = clsx(
        className,
        skeleton && 'skeleton',
        'logoWrapper',
        size !== 'small' && size,
        variant === 'round' && 'round',
        border && 'border',
        borderColor === 'grey' && 'grey-border',
    );
    if (skeleton) {
        return <StyledAvatar sx={sx} className={combinedClassName} onClick={onClick} />;
    }

    return (
        <StyledAvatar sx={sx} className={combinedClassName} onClick={onClick}>
            {children}
        </StyledAvatar>
    );
};

export default Avatar;
