import { Box } from '@mui/material';

type ContentViewProps = {
    left: React.ReactNode;
    right: React.ReactNode;
};

export const ContentView: React.VFC<ContentViewProps> = ({ left, right }) => (
    <Box
        sx={{
            display: 'grid',
            height: '100%',
            width: '100%',
            gridTemplateColumns: '240px minmax(0, 1fr)',
            alignItems: 'start',
            columnGap: { xs: 2.5, md: 4, lg: 5 },
            padding: { xs: 2.5, md: 4, lg: 5 },
        }}
    >
        <Box>{left}</Box>
        <Box>{right}</Box>
    </Box>
);

export default ContentView;
