import { useState, useEffect, ChangeEvent } from 'react';

import Radio from 'components/tokens/Radio';

import { Permission } from '../types';
import { getCurrentPermission } from './utils';

export type PermissionRadioProps = {
    profileId: string;
    listOwner: boolean;
    value: Permission;
    allReadOnlyProfileIds: string[];
    allReadWriteProfileIds: string[];
    onChange: (profileId: string, type: Permission) => void;
};

export const PermissionRadio = ({
    profileId,
    listOwner,
    value,
    allReadOnlyProfileIds,
    allReadWriteProfileIds,
    onChange,
}: PermissionRadioProps) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (!listOwner) {
            const currentPermission = getCurrentPermission(profileId, allReadOnlyProfileIds, allReadWriteProfileIds);
            if (currentPermission === value) {
                setChecked(true);
            } else {
                setChecked(false);
            }
        } else if (listOwner && value === 'read_write') {
            setChecked(true);
        }
    }, [allReadOnlyProfileIds, allReadWriteProfileIds, listOwner, profileId, value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(profileId, event.target.value as Permission);
    };

    return <Radio value={value} checked={checked} disabled={listOwner} onChange={handleChange} />;
};
