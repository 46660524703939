import { useMemo } from 'react';

import { assertArray } from 'utilities';

import { Signal } from 'api/types/signals';

export const combineAndSortSignals = (news: Signal[] | undefined, dataChanges: Signal[] | undefined, limit = 20) =>
    [...assertArray(news), ...assertArray(dataChanges)]
        .sort((a, b) => -(a.vainu_date ?? '0').localeCompare(b.vainu_date ?? '0'))
        .slice(0, limit);

export const useCombinedAndSortedSignals = (
    news: Signal[] | undefined,
    dataChanges: Signal[] | undefined,
    limit = 20,
) => useMemo(() => combineAndSortSignals(news, dataChanges, limit), [news, dataChanges, limit]);
