import React from 'react';

import { Box } from '@mui/material';

import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

type TooltipContentSectionProps = {
    label: string;
    content?: string;
    children?: React.ReactNode;
} & (
    | {
          type: 'basic' | 'deal';
          linkToCRM?: never;
      }
    | {
          type: 'link';
          /**
           * Need to pass when `type === 'link'`
           */
          linkToCRM: string | undefined;
      }
);

export const TooltipContentSection: React.FC<TooltipContentSectionProps> = ({
    label,
    content,
    type,
    linkToCRM,
    children,
}) => {
    const typographyStyle = { color: 'inherit', fontWeight: 500 };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '6px',
            }}
        >
            <Typography component="p" variant="tiny" sx={{ color: 'inherit' }}>
                {label}
            </Typography>
            {type === 'deal' ? (
                children
            ) : type === 'link' ? (
                linkToCRM ? (
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                        <Icon type="ExternalLink" fontSize="small" />
                        <Link
                            href={linkToCRM}
                            underline="always"
                            toNewTab="nofollow"
                            variant="tiny"
                            sx={{ fontWeight: 500 }}
                        >
                            {content}
                        </Link>
                    </div>
                ) : (
                    <Typography component="p" variant="tiny" sx={typographyStyle}>
                        No link available
                    </Typography>
                )
            ) : (
                <Typography component="p" variant="tiny" sx={typographyStyle}>
                    {content}
                </Typography>
            )}
        </Box>
    );
};
