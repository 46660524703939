import React from 'react';

import { cloneDeep } from 'lodash';

import { FilterOperator } from 'api/types/FilterOperators';

import { TValuesType, getFilterConfig } from '../../FiltersBaseConfig';
import {
    FilterConfig,
    FilterDefinition,
    FilterID,
    GroupValues,
    LocationFilterProps,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useChipInputLocationFilter } from '../common/useChipInputFilter';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, valueArrayToText } from '../utils';

const { baseConfig, getInitValueConfigDefault } = getFilterConfig(FilterID.address);
export type TValues = TValuesType<typeof baseConfig, string[]>;

const config: FilterConfig<TValues> = {
    ...baseConfig,
    ...getInitValueConfigDefault(FilterOperator.IN, []),
};

export const streetAddressFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.location_visiting_address,
        label: config.label,
        fields: config.fields,
        render: (props: LocationFilterProps<TValues>) => <StreetAddress {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => cloneDeep(config.initValue),
    },
};

const operators: Operator[] = [FilterOperator.STARTSWITH].map((value) => formatOperatorValue(value as OperatorValue));

export const StreetAddress: React.FC<LocationFilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
    apply,
    forceOpen,
    onClose,
}) => {
    const props = useChipInputLocationFilter({
        filter,
        sameFiltersState,
        uuid,
        groupUUID,
        removeFilter,
        operators,
        placeholder: 'address...',
        apply,
        forceOpen,
        onBlur: onClose,
    });

    return <FilterTemplate {...props} />;
};
