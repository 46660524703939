import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Google = (props) => (
    <SvgIcon {...props}>
        <g clipPath="url(#clip0)">
            <path
                d="M20.9905 11.9322C20.9905 11.1776 20.9292 10.6269 20.7967 10.0558H12.1793V13.4619H17.2375C17.1356 14.3084 16.5849 15.5832 15.3611 16.4398L15.3439 16.5538L18.0686 18.6646L18.2574 18.6834C19.991 17.0823 20.9905 14.7265 20.9905 11.9322Z"
                fill="#4285F4"
            />
            <path
                d="M12.1792 20.9068C14.6573 20.9068 16.7377 20.0909 18.2573 18.6836L15.361 16.4399C14.586 16.9804 13.5458 17.3578 12.1792 17.3578C9.7521 17.3578 7.69209 15.7567 6.95776 13.5436L6.85012 13.5528L4.01697 15.7454L3.97992 15.8484C5.48923 18.8467 8.58947 20.9068 12.1792 20.9068Z"
                fill="#34A853"
            />
            <path
                d="M6.95777 13.5436C6.764 12.9725 6.65187 12.3606 6.65187 11.7283C6.65187 11.096 6.764 10.4841 6.94757 9.91306L6.94244 9.79143L4.07377 7.5636L3.97992 7.60824C3.35786 8.85243 3.00092 10.2496 3.00092 11.7283C3.00092 13.2071 3.35786 14.6042 3.97992 15.8484L6.95777 13.5436Z"
                fill="#FBBC05"
            />
            <path
                d="M12.1793 6.09893C13.9028 6.09893 15.0653 6.84339 15.7283 7.46552L18.3186 4.93636C16.7277 3.45763 14.6574 2.54999 12.1793 2.54999C8.5895 2.54999 5.48924 4.61 3.97992 7.60824L6.94759 9.91306C7.69212 7.70006 9.75214 6.09893 12.1793 6.09893Z"
                fill="#EB4335"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="18" height="18.9" fill="white" transform="translate(3 2.54999)" />
            </clipPath>
        </defs>
    </SvgIcon>
);

export default Google;
