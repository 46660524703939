import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// eslint-disable-next-line @typescript-eslint/naming-convention
const DynamicsOpportunity: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M18.7692 2H4V21.6923H17.5384C17.8648 21.6923 18.1779 21.5626 18.4087 21.3318C18.6395 21.101 18.7692 20.7879 18.7692 20.4615V10.9231C18.7673 10.5103 18.9262 10.113 19.2123 9.81537C19.7212 9.28655 20.0038 8.58007 20 7.84614V3.23077C20 2.90435 19.8703 2.5913 19.6395 2.36048C19.4087 2.12967 19.0956 2 18.7692 2ZM17.5384 20.4615H5.23077V3.23077H16.3077V7.84614C16.3038 8.58007 16.5864 9.28655 17.0954 9.81537C17.3814 10.113 17.5404 10.5103 17.5384 10.9231V20.4615ZM18.7692 7.84614C18.7711 8.25893 18.6122 8.65624 18.3261 8.95383C18.265 9.0247 18.2074 9.09864 18.1538 9.17537C18.1002 9.09864 18.0427 9.0247 17.9815 8.95383C17.6954 8.65624 17.5365 8.25893 17.5384 7.84614V3.23077H18.7692V7.84614Z" />
        <path d="M12.6154 8.15383H11.3846V13.0769H12.6154V8.15383Z" />
        <path d="M12.6154 14.3077H11.3846V15.5384H12.6154V14.3077Z" />
    </SvgIcon>
);

export default DynamicsOpportunity;
