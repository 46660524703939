import { useWebProfileData } from 'api/profileData';
import { organizationProfileType } from 'api/types/Organization';
import { useCountriesFromBusinessUnits } from 'components/features/Locations/utils';
import { WebProfileHeader, WebProfileTechnologiesList } from 'components/features/WebProfile';
import Descriptions, { useDescriptionsFromDomainData } from 'components/templates/Descriptions';

import { TabContent, OrganizationTabProps } from '..';

export const WebProfileTab: React.FC<OrganizationTabProps> = ({ data, businessId }) => {
    const { data: webProfileData, isLoading } = useWebProfileData({ type: organizationProfileType, key: businessId });
    const addressCountries = useCountriesFromBusinessUnits(data.business_units);
    const descriptions = useDescriptionsFromDomainData(webProfileData);

    const { technology_data, website_data, website, indexes, metadata } = webProfileData ?? {};
    const updatedDate = metadata?.crawled?.web_technologies;

    return (
        <TabContent>
            <WebProfileHeader
                skeleton={isLoading}
                website={website}
                technology_data={technology_data}
                website_data={website_data}
                countries={addressCountries}
                digitalization_index={indexes?.digitalization_index}
                updatedDate={updatedDate}
            />
            <WebProfileTechnologiesList
                skeleton={isLoading}
                technology_data={technology_data}
                website={website}
                updatedDate={updatedDate}
            />
            <Descriptions skeleton={isLoading} descriptions={descriptions} />
        </TabContent>
    );
};

export default WebProfileTab;
