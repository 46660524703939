import React, { useRef } from 'react';

import { Box, CircularProgress, SxProps, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';

import { ProfileExportedToCRM } from 'components/modules/connectors/types';
import { CompanyCardCRM, CRMIcon } from 'components/templates/CompanyCardCRM';
import Button, { ButtonProps } from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { useDialogContext } from 'hooks/useDialogContext';

type CRMChipProps = {
    exportedProfiles: ProfileExportedToCRM[];
    name: string;
    country?: string;
    logo?: string;
    size?: ButtonProps['size'];
    chipSx?: SxProps;
    targetObject?: 'Account' | 'Contact';
};

export const CRMChip: React.FC<CRMChipProps> = ({
    exportedProfiles,
    logo,
    name,
    country,
    chipSx,
    size = 'medium',
    targetObject = 'Account',
}) => {
    const { handleDialogOpen } = useDialogContext();

    // keep track of previous status to know when export has finished
    const exportStatus = useRef(false);

    if (isEmpty(exportedProfiles)) {
        return null;
    }

    const exportEvent =
        exportedProfiles.find((row) => row.status === 'progress') ||
        exportedProfiles.find((row) => row.status === 'failed');
    const wasExporting = exportStatus.current;
    const isExporting = exportEvent?.status === 'progress';
    exportStatus.current = exportEvent?.status === 'progress';

    return (
        <Tooltip
            title={
                isExporting ? (
                    ''
                ) : (
                    <CompanyCardCRM
                        connectedCRMName={exportedProfiles[0].crmTarget}
                        exportedProfiles={exportedProfiles}
                        targetObject={targetObject}
                        name={name}
                        showInTooltip
                        mode="default"
                        onOpenDialog={() => {
                            handleDialogOpen('CRM_EXPORTED_PROFILES', {
                                data: exportedProfiles,
                                name,
                                country,
                                logo,
                            });
                        }}
                    />
                )
            }
            leaveDelay={100}
        >
            <Button
                variant="tertiary"
                href={!isExporting && exportedProfiles.length === 1 ? exportedProfiles[0].linkToCRM : undefined}
                target="_blank"
                sx={{
                    marginLeft: 1,
                    borderRadius: 50,
                    '&&': {
                        borderWidth: 0,
                    },
                    ...chipSx,
                }}
                startIcon={
                    <>
                        <Icon
                            fontSize="mini"
                            type={CRMIcon[exportedProfiles[0].crmTarget.toLowerCase()] ?? 'Transfer'}
                        />
                        {isExporting && (
                            <CircularProgress sx={{ position: 'absolute', inset: 0 }} size="100%" color="info" />
                        )}

                        {/* when export finishes */}
                        {wasExporting && exportEvent?.status === 'failed' && <ExportFinishedIcon type="fail" />}
                        {wasExporting && !exportEvent && <ExportFinishedIcon type="success" />}
                    </>
                }
                size={size}
            />
        </Tooltip>
    );
};

const ExportFinishedIcon = ({ type }: { type: 'success' | 'fail' }) => (
    <Box
        sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            animation: '200ms linear forwards hide 1000ms',
            '@keyframes hide': {
                from: {
                    opacity: 1,
                },
                to: {
                    opacity: 0,
                },
            },
            '@keyframes translate': {
                from: {
                    transform: 'translate(0, 0)',
                    width: 24,
                    height: 24,
                    fontSize: 20,
                },
                to: {
                    transform: 'translate(6px, 6px)',
                    width: 12,
                    height: 12,
                    fontSize: 10,
                },
            },
        }}
    >
        <Box
            sx={{
                borderRadius: '50%',
                backgroundColor: 'common.white',
                width: 24,
                height: 24,
                fontSize: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                animation: '300ms ease-in-out forwards translate 300ms',
            }}
        >
            <Icon
                type={type === 'success' ? 'CheckThick' : 'Error'}
                sx={{
                    display: 'block',
                    fontSize: 'inherit',
                }}
                color={type === 'success' ? 'success.secondary' : 'error.main'}
            />
        </Box>
    </Box>
);
