import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { useAxiosContext } from 'contexts/AxiosContext';
import { Database, NordicDatabase } from 'contexts/ListsContext';
import {
    domainDataFields,
    DomainDataResult,
    DomainDataResultAPIResponse,
    organizationFields,
    OrganizationResult,
    OrganizationResultAPIResponse,
} from 'contexts/types/FilterCompanies';

import { domainProfileType, organizationProfileType } from './types/Organization';

type GetSearchCompaniesParams = {
    companyName: string;
    database: Database | undefined;
};

const getFields = (database: Database) => (database === 'DOMAIN_DATA_BASIC' ? domainDataFields : organizationFields);
const getEndpoint = (database: Database) =>
    database === 'DOMAIN_DATA_BASIC' ? '/api/v3/domains/' : '/api/v3/organizations/search/';
const getSearchOrQuery = (value: string, database: Database) => {
    if (database === 'DOMAIN_DATA_BASIC') {
        return {
            query: { '?ALL': [{ '?STARTSWITH': { domain: value } }] },
            use_filters_v2: 'true',
            order: '-vainu_score',
        };
    }
    return { search: value };
};

// industries,auxiliary_names,industries_verbose, removed from v2 domain API as they are not supposed to be there
/**
 * Query string should reflect selected database once business id based companies are available.
 */
export const getCompaniesSearchResult = async (
    axios: AxiosInstance,
    { companyName, database }: GetSearchCompaniesParams,
) => {
    if (database === undefined) {
        return { results: [] };
    }
    const res = (
        await axios.post<{ results: DomainDataResultAPIResponse[] | OrganizationResultAPIResponse[] }>(
            getEndpoint(database),
            {
                database,
                fields: getFields(database).join(','),
                limit: 50,
                ...getSearchOrQuery(companyName, database),
            },
        )
    ).data;
    return { results: res };
};

export const getCompaniesFromMultipleNordicDBs = async (
    axios: AxiosInstance,
    { companyName, databases }: { companyName: string; databases: NordicDatabase[] },
) => {
    if (!databases) {
        return { results: [] };
    }

    return axios
        .post<{ results: DomainDataResultAPIResponse[] | OrganizationResultAPIResponse[] }>(
            '/api/v3/organizations/search/',
            {
                database: databases,
                fields: organizationFields,
                limit: 50,
                ...{ search: companyName },
            },
        )
        .then(({ data }) => ({ results: data }));
};

/**
 *
 * Since we are using the hook in two different places, we don't want to trigger refetch.
 * So, `refetchOnMount: false` option has been added.
 */
export function useCompaniesSearchResult(
    params: GetSearchCompaniesParams,
): UseQueryResult<DomainDataResult[] | OrganizationResult[]> {
    const axios = useAxiosContext();
    const { companyName, database } = params;
    return useQuery({
        queryKey: [process.env.REACT_APP_RELEASE_ID ?? '', 'search', params],
        queryFn: () => getCompaniesSearchResult(axios, params),
        enabled: !!companyName && companyName.length > 2,
        gcTime: 60 * 1000 * 50,
        // These should be controlled from BE. Until then, let's use these values,
        staleTime: 60 * 1000 * 100,
        refetchOnMount: false,
        select: ({ results }) => {
            if (database === 'DOMAIN_DATA_BASIC') {
                return (results as DomainDataResultAPIResponse[]).map((profile) => ({
                    ...profile,
                    profileType: domainProfileType,
                    id: profile.website,
                }));
            }
            return (results as OrganizationResultAPIResponse[]).map((profile) => ({
                ...profile,
                profileType: organizationProfileType,
                id: profile.business_id,
            }));
        },
    });
}
