import React, { useState, useContext } from 'react';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { CRMNameAPIResponse, CrmName, FieldMapping, CRMObject } from 'components/modules/connectors/types/Crm';
import { crmLabels } from 'components/modules/connectors/utils';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Switch from 'components/tokens/Switch';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { getIntegrationImage } from 'utilities/triggers';

import CRMDestinationSettings from './CRMDestinationSettings';

export interface CRMDestinationType {
    system: CrmName;
    crm_objects: CRMObject[];
    active: boolean;
    isValid?: boolean;
    assignTo?: string[];
}

interface Props {
    destination: CRMDestinationType | null;
    updateDestination: (obj: CRMDestinationType) => void;
    lowerCaseName: CrmName;
    target: CRMNameAPIResponse;
    crmObjects: CRMObject[];
    hideHeader?: boolean;
    fieldMapping?: FieldMapping<CRMObject>[];
    settingsAsDialog?: boolean;
    triggerName?: string;
}

type OptionType = {
    value: CRMObject;
    label: string;
};

const getIcon = (system: string) => {
    return system === 'email' ? (
        <Icon type="Mail" sx={{ width: 13, height: 13 }} />
    ) : (
        <StyledImg src={getIntegrationImage(system)} />
    );
};

const CRMDestination: React.FC<Props> = ({
    destination = {} as CRMDestinationType,
    lowerCaseName,
    target,
    updateDestination,
    crmObjects,
    hideHeader = false,
    fieldMapping = [],
    settingsAsDialog = true,
    triggerName,
}) => {
    /*
        placeholder, not implemented
    */
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(
        !!destination?.active && destination?.crm_objects?.length === 0,
    );
    const [enabled, setEnabled] = useState(!!destination?.active);
    const [values, setValues] = useState<CRMObject[]>(destination?.crm_objects || []);
    const permissionContext = useContext(PermissionContext);
    const options: OptionType[] = crmObjects.map((value) => ({ value, label: value }));
    const icon = getIcon(lowerCaseName);
    const onEnableToggle = (enable: boolean) => {
        setEnabled(enable);
        updateDestination({
            system: lowerCaseName,
            crm_objects: values,
            active: enable,
            isValid: !!values?.length,
            assignTo: [],
        });
    };

    const onChange = (objectNames: CRMObject[], assignTo: string[]) => {
        setValues(objectNames);
        updateDestination({
            system: lowerCaseName,
            crm_objects: objectNames,
            active: true,
            isValid: !!objectNames?.length,
            assignTo,
        });
    };
    if (!options.length || !lowerCaseName) {
        return (
            <div>
                Hello, Unfortunately there seems to be some kind of problem with the CRM integration. Please contact
                support to get this sorted out. We are sorry for the inconvenience.
            </div>
        );
    }

    const getText = () => {
        if (values.length) {
            if (values.length > 1) {
                return (
                    <StyledText>
                        Sending as a <b>{values[0]}</b> with <b>{values.length - 1}</b> object
                        {values.length !== 2 && 's'} alongside
                    </StyledText>
                );
            }
            return (
                <StyledText>
                    Sending as a <b>{values[0]}</b>
                </StyledText>
            );
        }
        return <StyledText>Set settings</StyledText>;
    };

    if (!permissionContext.hasProductPermission('crm_settings')) {
        return (
            <>
                <Grid
                    container
                    key={destination?.system}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ padding: '5px 0px 5px 0px' }}
                >
                    <StyledFlex>
                        <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                            {crmLabels[lowerCaseName as keyof typeof crmLabels] || target}
                        </Typography>
                        <StyledUpgradeText>UPDATE</StyledUpgradeText>
                    </StyledFlex>
                    <Button variant="tertiary" size="small">
                        <Link href="/connectors" target="_blank">
                            To connectors
                        </Link>
                    </Button>
                </Grid>
            </>
        );
    }
    return (
        <>
            {hideHeader ? null : (
                <Grid
                    container
                    key={destination?.system}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ padding: '5px 0px 5px 0px' }}
                >
                    <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                        {crmLabels[lowerCaseName as keyof typeof crmLabels] || target}
                    </Typography>
                    <Switch checked={enabled} onChange={(e) => onEnableToggle(e.target.checked)} />
                </Grid>
            )}
            {settingsAsDialog ? (
                <Grid container>
                    {enabled ? (
                        <StyledRow>
                            <StyledFlex>
                                {icon}
                                {getText()}
                            </StyledFlex>
                            <Button variant="tertiary" size="small" onClick={() => setShowSettingsModal(true)}>
                                Export settings
                            </Button>
                        </StyledRow>
                    ) : null}
                </Grid>
            ) : null}
            <CRMDestinationSettings
                destination={destination}
                handleClose={() => setShowSettingsModal(false)}
                open={showSettingsModal}
                options={options}
                selectedCrmObjects={values}
                lowerCaseName={lowerCaseName}
                onChange={onChange}
                target={target}
                fieldMapping={fieldMapping}
                showAsDialog={settingsAsDialog}
                triggerName={triggerName}
                confirmBtnText="Save"
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={showErrorSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowErrorSnackbar(false)}
                message={<span>Error</span>}
            />
        </>
    );
};

export default CRMDestination;

const StyledRow = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const StyledFlex = styled('div')`
    display: flex;
    align-items: center;
`;

const StyledText = styled('span')`
    margin-left: 8px;
    font-size: 12px;
    line-height: 140%;
`;

const StyledImg = styled('img')`
    width: 13px;
    height: 13px;
`;

const StyledUpgradeText = styled('p')`
    color: brandPalette.orange;
    border-radius: 3px;
    padding: 4px;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    margin-left: 8px;
`;
