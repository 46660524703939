import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
    Grid,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    styled,
} from '@mui/material';

import { PlanhatOwnersProps } from '../types';
// import useConnectorsApi from '../hooks/useConnectorsApi';

type Props = {
    callToAction: React.ReactElement;
    header: string;
    subHeader: string;
};

const ContactSupportDialog = ({ callToAction, header, subHeader }: Props) => {
    /*
     * TO DO:
     * Vainu analytics data api is used for CS owner information. Need to store in  recoil
     * */

    // const { data, isError } = useQuery('getVainuAnalyticsData', () => getVainuAnalyticsData(), {
    //     refetchOnWindowFocus: false,
    // });

    const planhatOwners = {} as PlanhatOwnersProps;
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            {callToAction && React.cloneElement(callToAction, { onClick: () => setOpenDialog(true) })}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth style={{ zIndex: 2000 }}>
                <StyledCloseIcon onClick={() => setOpenDialog(false)} />
                <StyledDialogTitle>{header}</StyledDialogTitle>
                <StyledDialogContent>
                    <StyledDialogContentText>{subHeader}</StyledDialogContentText>
                    <Grid container spacing={2} alignItems="center">
                        {planhatOwners?.cs_owner ? (
                            <>
                                <CustomerSuccess
                                    title={planhatOwners?.cs_owner_fullname}
                                    contactRole="Customer success"
                                    email={planhatOwners.cs_owner}
                                />
                                <StyledTypography color="textSecondary" variant="subtitle1">
                                    For further inquiries contact{' '}
                                    <a href="mailto:customersuccess@vainu.io">customersuccess@vainu.io</a> for details
                                </StyledTypography>
                            </>
                        ) : (
                            <CustomerSuccess
                                title="Customer success"
                                contactRole="General inquiries"
                                email="customersuccess@vainu.io"
                            />
                        )}
                    </Grid>
                </StyledDialogContent>
                <DialogActions sx={{ margin: '0px 32px 40px 32px' }}>
                    <StyledButton onClick={() => setOpenDialog(false)} variant="outlined">
                        Got it
                    </StyledButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ContactSupportDialog;

interface CustomerSuccessProps {
    title: string;
    contactRole: string;
    email: string;
}
export const CustomerSuccess: React.FC<CustomerSuccessProps> = ({ title, contactRole, email }) => {
    return (
        <Grid item xs={6}>
            <ContactWrapper>
                <ContactName color="textSecondary" variant="subtitle1">
                    {title}
                </ContactName>
                <ContactRole color="textSecondary">{contactRole}</ContactRole>
                <ContactEmail color="textSecondary">
                    <EmailOutlinedIcon /> <a href={`mailto:${email}`}>{email}</a>
                </ContactEmail>
            </ContactWrapper>
        </Grid>
    );
};

const ContactWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.border}`,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: '15px 12px',
}));

const ContactName = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '140%',
    color: theme.palette.text.strong,
}));

const ContactRole = styled(Typography)(({ theme }) => ({
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '140%',
    color: theme.palette.text.subtle,
    padding: '5px 0px',
}));

const ContactEmail = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.strong,
    padding: '5px 0px',
    fontSize: 12,
    lineHeight: '140%',
    textDecorationLine: 'underline',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
        color: theme.palette.text.subtle,
        fontSize: 12,
        marginRight: 8,
    },
    '& a': {
        color: theme.palette.text.strong,
    },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    color: theme.palette.text.strong,
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'left',
    padding: 0,
    margin: '40px 32px 8px 32px',
}));

const StyledDialogContent = styled(DialogContent)({
    padding: 0,
    margin: '8px 32px 24px 32px',
    overflow: 'hidden',
});

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 'normal',
    textAlign: 'left',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    padding: '8px 12px',
    fontSize: 14,
    background: theme.palette.primary.main,
    fontWeight: 500,
    borderRadius: 4,
    color: theme.palette.button.text,
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
    color: theme.palette.icon.subtle,
    fontSize: 14,
    cursor: 'pointer',
    position: 'absolute',
    top: 15,
    right: 15,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.subtle,
    fontSize: 12,
    padding: '0px 10px 10px 10px',
    '& a': {
        color: theme.palette.text.subtle,
        textDecorationLine: 'underline',
    },
}));
