import { ReactNode } from 'react';

import { isArray } from 'lodash';
import { FormattedNumber } from 'react-intl';

import { FilterOperator, ValueOfExcludeFilterOperator, ValueOfSingleFilterOperator } from 'api/types/FilterOperators';
import { SelectOption } from 'components/tokens/select-components/Select';
import { formatDate } from 'utilities/date';
import { getFirstKey, getObjectKeys, getObjectValues } from 'utilities/objectUtils';

import { combineKey, mergeKeys } from '../common/utils';
import {
    FilterID,
    FilterNodeValueExcluded,
    FilterNodeValueExtended,
    FILTERS_IDS,
    FilterState,
    isGroupIDValue,
    MappedFilter,
    MatchValue,
    NodeValue,
    Operator,
    OperatorValue,
    RawGroup,
} from '../FilterTypes';

const getOperatorLabel = (value: OperatorValue) => {
    switch (value) {
        case FilterOperator.CONTAINS:
        case FilterOperator.ICONTAINS:
            return 'contains';
        case FilterOperator.NOT + FilterOperator.CONTAINS:
        case FilterOperator.NOT + FilterOperator.ICONTAINS:
            return "doesn't contain";
        case FilterOperator.NOT + FilterOperator.EQ:
        case FilterOperator.NOT + FilterOperator.IEXACT:
        case FilterOperator.NOT + FilterOperator.YEAR:
        case FilterOperator.NOT + FilterOperator.IN:
        case FilterOperator.NOT + FilterOperator.RANGE:
            return 'is not';
        case FilterOperator.STARTSWITH:
            return 'starts with';
        case FilterOperator.NOT + FilterOperator.STARTSWITH:
            return "doesn't start with";
        case FilterOperator.EQ:
        case FilterOperator.IEXACT:
        case FilterOperator.YEAR:
        case FilterOperator.IN:
        case FilterOperator.RANGE:
            return 'is';
        case FilterOperator.ENDSWITH:
            return 'ends with';
        default:
            throw new Error(`Unknown value ${value}`);
    }
};

export const formatOperatorValue = (value: OperatorValue): Operator => {
    return { value, label: getOperatorLabel(value) };
};

type GetOperatorValue<T> = T extends OperatorValue ? OperatorValue : undefined;

export const getOperator = <T extends OperatorValue | undefined>(
    value: unknown,
    defaultOperator?: T,
): T extends OperatorValue ? OperatorValue : undefined => {
    if (!value) {
        return defaultOperator as GetOperatorValue<T>;
    }

    const operator = getFirstKey<ValueOfSingleFilterOperator | ValueOfExcludeFilterOperator>(
        value as FilterNodeValueExtended,
    );
    return (
        operator === FilterOperator.NOT
            ? FilterOperator.NOT + getFirstKey<string>((value as FilterNodeValueExcluded)[operator])
            : operator
    ) as GetOperatorValue<T>;
};

export const dbValueToState = <T,>(
    field: string,
    operator: OperatorValue,
    value: T[keyof T],
    state: FilterState<T>,
): FilterState<T> => {
    return {
        ...state,
        [field]: {
            operator,
            value,
        },
    };
};

type FirstField<T extends object> = keyof T extends never ? never : T[keyof T];

type GetValueType<Key extends string | number | symbol, Node extends NodeValue> = Key extends keyof Node
    ? Node[Key] extends never
        ? unknown
        : Node[Key]
    : FirstField<Node> extends object
      ? GetValueType<Key, FirstField<Node>>
      : undefined;

export const getValue = <Key extends string | number | symbol, Node extends NodeValue>(
    field: Key,
    node: Node,
): GetValueType<Key, Node> => {
    const mappedField = getFieldById(field as FilterID);
    let value = node;
    let key = '';

    while (key !== mappedField) {
        if (typeof value !== 'object' || value == null) {
            return undefined as GetValueType<Key, Node>;
        }
        key = getFirstKey(value);
        value = value[key as keyof NodeValue];
    }

    return value as GetValueType<Key, Node>;
};

export const getValuePath = <T,>(field: keyof T, node: NodeValue) => {
    const mappedField = getFieldById(field as FilterID);
    let value = node;
    let key = '';
    let path = '';
    while (key !== mappedField) {
        if (typeof value !== 'object') {
            throw new Error(`Field ${mappedField.toString()} is not found`);
        }
        key = getFirstKey(value);
        value = value[key as keyof NodeValue];
        path = mergeKeys(path, key);
    }

    return path;
};

export const valueArrayToText = <T,>(field: keyof T, separator = ', ') => {
    return (node: NodeValue, options?: SelectOption[]) => {
        const value = getValue(field, node);

        if (Array.isArray(value)) {
            if (options) {
                return value
                    .map((value: number | string) => options.find((tag) => tag.value === value)?.label)
                    .filter(Boolean)
                    .join(separator);
            }

            return value.join(separator);
        }
        return '';
    };
};

export const rangeValueToText = <T,>(field: keyof T, rangeOptions?: number[]) => {
    return (node: NodeValue, format?: (v: [number, number]) => ReactNode) => {
        const value = getValue(field, node);

        if (Array.isArray(value)) {
            let low = value[0] ?? -Infinity;
            let high = value[1] ?? Infinity;
            let lowInfinity = false;
            let highInfinity = false;

            if (value[0] == null && rangeOptions) {
                lowInfinity = true;
                low = rangeOptions[0];
                if (rangeOptions[0] === -Infinity) {
                    low = rangeOptions[1];
                }
            }
            if (value[1] == null && rangeOptions) {
                highInfinity = true;
                high = rangeOptions[rangeOptions.length - 1];
                if (rangeOptions[rangeOptions.length - 1] === Infinity) {
                    high = rangeOptions[rangeOptions.length - 2];
                }
            }
            return format ? (
                format([low, high])
            ) : (
                <>
                    {lowInfinity && '<'}
                    <FormattedNumber value={low} /> - {highInfinity && '>'}
                    <FormattedNumber value={high} />
                </>
            );
        }
        return '';
    };
};

export function indexRangeValueToText<T>(field: keyof T) {
    return (node: NodeValue) => {
        let value = node;
        let key: string = '';
        while (key !== field) {
            key = getFirstKey<string>(value);
            value = value[key as keyof NodeValue];
        }

        if (Array.isArray(value)) {
            const low = value[0] || '';
            const high = value[1] || '';
            return (
                <>
                    <FormattedNumber value={low * 10} /> - <FormattedNumber value={high * 10} />
                </>
            );
        }
        return '';
    };
}

export const valueToText = <T,>(field: keyof T) => {
    return (node: NodeValue, options?: SelectOption[]) => {
        const value = getValue(field as string, node);

        if (options) {
            return options.find((option) => (option.value as unknown as T[keyof T]) === value)?.label || '';
        }

        return value ? value : '';
    };
};

/**
 * Get the value of from inner most nested object.
 *
 * This is to be used in normal filters only. Filter like `VCI` and `OrganizationSizeIndicator`
 * has different structure so this function cannot be used.
 *
 * @example
 * // Since the structure of filter state is
 * {
 *  "?NOT": {
 *      "?IN": {
 *        "legal_entity": [
 *          "AYH"
 *        ]
 *      }
 *   }
 * }
 * // We need to grab the value from inner most object i.e. `legal_entity`.
 *
 */
export const getInnerMostValueInFilterState = <T extends Object>(object: T, field: string): unknown => {
    let foundValue: unknown = [];
    const key = getFirstKey(object);

    if (typeof object[key] !== 'object' || Array.isArray(object[key])) {
        foundValue = object[key] as unknown;
        return foundValue;
    }
    return getInnerMostValueInFilterState(object[key], field);
};

export const dateValueToText = <T,>(field: keyof T) => {
    return (node: NodeValue) => {
        const value = getValue(field, node);
        return getDateValueLabel(value as Array<string | null> | string | null);
    };
};

export function getDateValueLabel(value: Array<string | null> | string | null) {
    if (!value) {
        return 'Not registered';
    } else if (Array.isArray(value)) {
        const [startDate, endDate] = value;
        const start = startDate ? formatDate(startDate, 'long') : '';
        const end = endDate ? formatDate(endDate, 'long') : '';
        return `${start} - ${end}`;
    }
    return value;
}

export const getSameFiltersState = (activeFilters: MappedFilter[], targetFilter: MappedFilter) => {
    const filters: NodeValue[] = [];
    activeFilters.forEach((filter) => {
        if (filter.id === targetFilter.id && filter.uuid !== targetFilter.uuid) {
            filters.push(filter.value as NodeValue);
        }
    });

    return filters;
};

export const getSelectOptionsToDisable = <T,>(
    originalOptions: SelectOption[],
    duplicateStates: FilterState<T>[],
    keyToFilter: string,
) =>
    originalOptions.filter((option) =>
        duplicateStates.some((duplicateState) => {
            /**
             * This is to ensure that options are checked for every technology filters.
             * e.g. If 'salesforce' is selected in `all webtags`, it should not be possible
             * to select it in `CRM webtags` and vice-versa.
             */
            if (keyToFilter.includes('urls_web_tags')) {
                for (const stateKey in duplicateState) {
                    if (Object.prototype.hasOwnProperty.call(duplicateState, stateKey)) {
                        const valueToCheck = duplicateState[stateKey].value;
                        return Array.isArray(valueToCheck) ? valueToCheck.includes(option.value) : false;
                    }
                }
            }
            const valueToCheck = duplicateState[keyToFilter as keyof T].value;
            return Array.isArray(valueToCheck) ? valueToCheck.includes(option.value) : false;
        }),
    );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormState = any;

export function transformState<T = FormState>(obj: T, from: string, to: string) {
    if (obj != null && typeof obj === 'object') {
        const newObj: FormState = (isArray(obj) ? [] : {}) as T;
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                let newKey;
                if (key === '#coordinates' || key === '#radius') {
                    newKey = key;
                } else {
                    newKey = key.replaceAll(from, to);
                }
                newObj[newKey] = transformState(obj[key], from, to);
            }
        }
        return newObj as T;
    }
    return obj;
}

export function* getFilterGenerator(operation: NodeValue, key = ''): Iterable<MappedFilter> {
    if (!operation) {
        return;
    }

    for (const operator of Object.keys(operation)) {
        const nestedKey = combineKey(key, operator);

        const mappedOperator = getObjectValues(FILTER_ID_MAP).includes(operator) ? getIdByField(operator) : operator;

        if (FILTERS_IDS.includes(mappedOperator as FilterID)) {
            yield { value: operation, id: mappedOperator, path: nestedKey } as MappedFilter;
        }

        if (isGroupIDValue(operator)) {
            yield* getFilterGenerator(operation[operator as keyof NodeValue] as NodeValue, nestedKey);
        }

        if (
            !Object.values(FilterOperator).includes(operator as (typeof FilterOperator)[keyof typeof FilterOperator]) &&
            !/^\d+$/.test(operator)
        ) {
            continue;
        }

        yield* getFilterGenerator(operation[operator as keyof NodeValue], nestedKey);
    }
}
export const getFiltersFromGroup = (group: Pick<RawGroup, 'path' | 'value'>) => {
    const filters: MappedFilter[] = [];

    if (!group) {
        return filters;
    }

    for (const filter of getFilterGenerator(group.value)) {
        filters.push({ ...filter, path: filter.path, groupPath: group.path });
    }
    return filters;
};

export function getNodesByKey(
    operation: NodeValue,
    nodeKey: string | number,
    key: string = '',
): MappedFilter | undefined {
    if (!operation) {
        return;
    }

    for (const operator of getObjectKeys<keyof typeof operation>(operation)) {
        const nestedKey = combineKey(key, operator);

        const value = operation[operator];
        if (value === nodeKey) {
            return { value: value as NodeValue, id: operator as string, path: nestedKey } as MappedFilter;
        }

        return getNodesByKey(operation[operator], nodeKey, nestedKey);
    }
}

export function getNextArray(operation: NodeValue, key: string = ''): MappedFilter | undefined {
    if (!operation) {
        return;
    }

    for (const operator of getObjectKeys<keyof NodeValue>(operation)) {
        const nestedKey = combineKey(key, operator);

        const value = operation[operator] as MatchValue;

        if (isArray(value)) {
            return { value: value as NodeValue, id: operator as string, path: nestedKey } as MappedFilter;
        }

        return getNextArray(operation[operator], nestedKey);
    }
}
/**
 * [1,2,..9,10,20,30,..90,100,200,300..]
 */
export const getRangeOptions = (max: number, min = 1) => {
    const options: number[] = [min];
    for (let i = min; i < max; i *= 10) {
        for (let j = 2; j <= 10; j++) {
            const value = i * j;
            if (value > max) {
                break;
            }
            options.push(value);
        }
    }
    return options;
};

export const getDefaultRange = (options: number[]) => {
    return [options[0], options[options.length - 1]];
};

export const makeFieldType = <T extends string>(value: T) => {
    return value as `${T}`;
};

export function makeFullPath<T>({ groupPath, path, valuePath }: MappedFilter<T>, outsideGroup = false) {
    return mergeKeys(groupPath, outsideGroup ? undefined : path, valuePath);
}

export const FILTER_ID_MAP: Partial<Record<FilterID, string>> = { [FilterID.location_type]: 'types' };

export function getFieldById(filterId: FilterID) {
    return FILTER_ID_MAP[filterId] ?? filterId;
}

export function getIdByField(field: string) {
    const value = Object.entries(FILTER_ID_MAP).find((entry) => entry[1] === field);
    if (value) {
        return value[0];
    }

    return field;
}
