import { Theme, Paper as MuiPaper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledFrameProps, Elevation, Padding } from '.';

interface ElevationStyle {
    boxShadow?: string;
}

const elevations: Record<Elevation, ElevationStyle> = {
    0: {
        boxShadow: 'none',
    },
    1: {
        boxShadow: '0px 2px 4px rgba(138, 138, 138, 0.05), 0px 2px 4px rgba(10, 10, 10, 0.05)',
    },
    2: {
        boxShadow: '0px 4px 24px rgba(10, 10, 10, 0.05)',
    },
    3: {
        boxShadow: '0px 4px 12px rgba(10, 10, 10, 0.15)',
    },
    4: {
        boxShadow: '0px 16px 16px -8px rgba(10, 10, 10, 0.1)',
    },
    5: {
        boxShadow: '0px 16px 36px -8px rgba(10, 10, 10, 0.15), 0px 1px 1px rgba(10, 10, 10, 0.1)',
    },
    6: {
        boxShadow: '0px 32px 32px rgba(10, 10, 10, 0.05), 0px 16px 16px rgba(10, 10, 10, 0.05)',
    },
};

const paddings: (theme: Theme, border: boolean) => Record<Padding, string> = (theme, border) => ({
    none: '0',
    small: border ? '7px' : '8px',
    medium: border ? '15px' : '16px',
    large: border ? '23px' : '24px',
    xlarge: border ? '39px 31px' : '40px 32px',
});

export const StyledFrame = styled(MuiPaper, {
    shouldForwardProp: (prop) => prop !== 'padding' && prop !== 'elevation' && prop !== 'display' && prop !== 'border',
})<StyledFrameProps>(({ theme, padding, elevation, display, border }) => ({
    '&.variant-black': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    '&.variant-white': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
    },
    '&.variant-grey': {
        backgroundColor: theme.palette.field.background,
        color: theme.palette.primary.main,
    },
    '&.variant-lightgrey': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
    },
    '&.border': {
        border: `1px solid ${theme.palette.border}`,
    },
    ...elevations[elevation],
    display: display,
    padding: paddings(theme, border)[padding],
    borderRadius: 8,
    overflow: 'hidden',
}));
