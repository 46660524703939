import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Menu = (props) => (
    <SvgIcon {...props}>
        <path d="M21 18H3V16H21V18ZM21 13H3V11H21V13ZM21 8H3V6H21V8Z" />
    </SvgIcon>
);

export default Menu;
