import { createTheme } from '@mui/material/styles';

import components from './componentOverrides';
import palette from './palette';
import params from './params';
import typography from './typography';

const theme = createTheme({
    params,
    components,
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            profileSidebar: 1440,
        },
    },
    palette,
    typography,
});

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        profileSidebar: true; // The point where the sidebar of the company/domain profile is shown/hidden
    }
}
declare module '@mui/material/Chip' {
    interface ChipPropsSizeOverrides {
        big: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsSizeOverrides {
        mini: true;
    }
}

// This used only in Storybook to enumerate possible color choices
// in controls that assign the color to the sx prop.
export const paletteCombinedKeys = Object.entries(theme.palette)
    .map(([sectionName, section]) => {
        if (sectionName === 'mode') {
            return false;
        }
        if (typeof section === 'string') {
            return sectionName;
        }
        return Object.keys(section).map((key) => `${sectionName}.${key}`);
    })
    .filter(Boolean)
    .flat();

export default theme;
export type Theme = typeof theme;
