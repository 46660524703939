import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { getCountryPrefixBusinessId } from 'utilities';

import { ProfileDisplayMode } from 'components/modules/profiles/Profile';
import { TabSlug } from 'components/modules/profiles/Profile/tabs';
import { usePermissionContext } from 'contexts/PermissionContext';

import GroupStructureLeaf, { GroupStructureLeafProps } from './GroupStructureLeaf';

export type ProfileLinkProps = GroupStructureLeafProps & {
    mode: ProfileDisplayMode;
};

export const ProfileLink: React.FC<ProfileLinkProps> = ({ company, mode, ...props }) => {
    const { countryPermissions } = usePermissionContext();

    const { business_id, country, is_concern } = company;
    const linkBusinessId = getCountryPrefixBusinessId(business_id, country);
    const isLinkEnabled = countryPermissions.includes(country) && !is_concern;
    const path = `/company/${linkBusinessId}/${TabSlug.Overview}`;

    if (isLinkEnabled) {
        return (
            <Box sx={{ '& > a': { textDecoration: 'none' } }}>
                <Link to={path} relative="path" target="_blank">
                    <GroupStructureLeaf company={company} {...props} />
                </Link>
            </Box>
        );
    }
    return <GroupStructureLeaf company={company} {...props} />;
};

export default ProfileLink;
