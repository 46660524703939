import { useVainuCustomIndustries } from 'api/profileData';
import { organizationProfileType } from 'api/types/Organization';
import BasicInfo from 'components/features/BasicInfo';
import CompanyEvents from 'components/templates/CompanyEvents';
import CompanyMemberships from 'components/templates/CompanyMemberships';
import Descriptions from 'components/templates/Descriptions';
import { getDescriptionsFromCompany } from 'components/templates/Descriptions/utils';
import EInvoiceAddresses from 'components/templates/EInvoiceAddresses';

import { TabContent, TabContentGridSection, CompanyTabProps } from '..';

export const OverviewTab: React.VFC<CompanyTabProps> = ({ businessId, data }) => {
    const { data: VCIData } = useVainuCustomIndustries({ type: organizationProfileType, key: businessId });

    return (
        <TabContent>
            <BasicInfo {...data} customIndustries={VCIData?.vainu_custom_industries} />
            <Descriptions descriptions={getDescriptionsFromCompany(data)} />
            {data.e_invoice_addresses !== undefined &&
                data.e_invoice_addresses !== null &&
                data.e_invoice_addresses.length > 0 && <EInvoiceAddresses addresses={data.e_invoice_addresses} />}
            <TabContentGridSection columns={2}>
                {data.facts && data.facts.some((fact) => fact.type === 'event') && <CompanyEvents facts={data.facts} />}
                {data.facts && data.facts.some((fact) => fact.type === 'membership') && (
                    <CompanyMemberships facts={data.facts} />
                )}
            </TabContentGridSection>
        </TabContent>
    );
};

export default OverviewTab;
