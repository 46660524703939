import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TagOutline = (props) => (
    <SvgIcon {...props}>
        <path d="M12.897 21.968C12.366 21.9696 11.8564 21.7586 11.482 21.382L3.64799 13.547C3.23458 13.1348 3.0226 12.5621 3.06799 11.98L3.56799 5.41401C3.63929 4.4264 4.42619 3.64163 5.41399 3.57301L11.98 3.07301C12.031 3.06201 12.083 3.06201 12.134 3.06201C12.6638 3.06337 13.1717 3.27399 13.547 3.64801L21.382 11.482C21.7572 11.8571 21.9681 12.3659 21.9681 12.8965C21.9681 13.4271 21.7572 13.9359 21.382 14.311L14.311 21.382C13.9368 21.7583 13.4277 21.9693 12.897 21.968ZM12.133 5.06201L5.56199 5.56201L5.06199 12.133L12.897 19.968L19.967 12.898L12.133 5.06201ZM8.65399 10.654C7.69983 10.6542 6.87841 9.98037 6.69207 9.04458C6.50574 8.10879 7.0064 7.17169 7.88786 6.80639C8.76933 6.44109 9.78609 6.74933 10.3163 7.54259C10.8466 8.33586 10.7426 9.39322 10.068 10.068C9.69382 10.4443 9.18467 10.6553 8.65399 10.654Z" />
    </SvgIcon>
);

export default TagOutline;
