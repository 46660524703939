import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InCrmPipedrive: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6411 13.2368C10.7573 13.2368 9.90122 11.4164 9.90122 9.72897C9.90122 7.07204 11.3409 6.12603 12.6875 6.12603C14.3402 6.12603 15.4506 7.56329 15.4506 9.70421C15.4506 10.4869 15.3055 11.1308 15.0623 11.6444C15.9556 11.2308 16.9508 11 18 11C18.3024 11 18.6002 11.0192 18.8925 11.0564C18.9633 10.6101 19 10.1428 19 9.6573C19 5.67517 16.7289 3 13.3512 3C11.3861 3 10.2486 3.89129 9.6998 4.50633C9.63395 3.97598 9.28793 3.28798 7.93609 3.28798H5V6.38924H6.20336C6.40736 6.38924 6.47321 6.45439 6.47321 6.66028V20.8219H9.97224V15.5067C9.97224 15.3634 9.96966 15.2291 9.96578 15.1093C10.2611 15.3826 10.7021 15.7124 11.3026 15.9576C11.6169 14.9258 12.1631 13.995 12.8805 13.226C12.8008 13.2332 12.7209 13.2368 12.6411 13.2368Z"
            fill="#898989"
        />
        <path
            d="M14.0399 18.2534L13.7058 18.6003L14.0399 18.9471L16.4399 21.4391L16.8 21.8131L17.1601 21.4391L21.9601 16.455L22.2942 16.1082L21.9601 15.7614L20.8929 14.6532L20.5326 14.279L20.1724 14.6533L16.8003 18.1583L15.8279 17.1457L15.4678 16.7707L15.1071 17.1452L14.0399 18.2534ZM12.5 18C12.5 14.9624 14.9624 12.5 18 12.5C21.0376 12.5 23.5 14.9624 23.5 18C23.5 21.0376 21.0376 23.5 18 23.5C14.9624 23.5 12.5 21.0376 12.5 18Z"
            fill="#079992"
            stroke="#079992"
        />
    </SvgIcon>
);

export default InCrmPipedrive;
