import React from 'react';

import Icon, { IconType } from 'components/tokens/Icon';

import { ListMenuItemProps } from '../ListMenu/ListMenuItem';
import { StyledMenuItem } from '../ListMenu/styled';

export type MenuItemProps<T> = Omit<ListMenuItemProps<T>, 'icon'> & {
    isSelected?: boolean;
    href?: string;
    icon?: IconType;
};

export const MenuItem = <T,>({ action, label, icon, color, isSelected, href, onClick }: MenuItemProps<T>) => (
    <StyledMenuItem
        disableGutters
        component={href ? 'a' : 'li'}
        href={href}
        sx={{
            '&.Mui-selected': {
                backgroundColor: 'border',
                fontWeight: 500,
            },
        }}
        selected={isSelected}
        onClick={(event: React.MouseEvent<HTMLLIElement>) => onClick && onClick(event, action)}
    >
        {icon && <Icon type={icon} fontSize="mini" sx={{ color: color }} />}
        {label}
        {isSelected && (
            <Icon
                type="CheckBig"
                fontSize="small"
                sx={{ position: 'absolute', right: -2, fill: (theme) => theme.palette.text.subtle }}
            />
        )}
    </StyledMenuItem>
);
