import React from 'react';

import { cloneDeep } from 'lodash';

import { FilterOperator } from 'api/types/FilterOperators';
import { SelectOption } from 'components/tokens/select-components/Select';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';

import { TValuesType, getFilterConfig } from '../../FiltersBaseConfig';
import {
    GroupValues,
    FilterID,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterDefinition,
    LocationFilterProps,
} from '../../FilterTypes';
import { useOperatorFromFilter } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, valueArrayToText, getInnerMostValueInFilterState } from '../utils';
import { LocationFiltersSelect } from './LocationFiltersSelect';
import { useLocationFilterValue } from './useLocationFilterValue';

const { baseConfig, getInitValueConfigDefault } = getFilterConfig(FilterID.municipality);
export type TValues = TValuesType<typeof baseConfig, string[]>;

const config: FilterConfig<TValues> = {
    ...baseConfig,
    ...getInitValueConfigDefault(FilterOperator.IN, []),
};

export const municipalityFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.location_visiting_address,
        label: config.label,
        fields: config.fields,
        render: (props: LocationFilterProps<TValues>) => <Municipality {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => cloneDeep(config.initValue),
    },
};

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

export const Municipality: React.FC<LocationFilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
    apply,
    forceOpen,
    onClose,
    onOpen,
    warningLevel,
}) => {
    const { operator, handleOperatorUpdate } = useOperatorFromFilter(filter, ({ key, value, valuePath }) => {
        apply({ key, value, valuePath });
    });

    const { municipalitiesOptions } = useFilterOptionsContext();
    const optionsToDisable = sameFiltersState.flatMap((sameState) =>
        getInnerMostValueInFilterState(sameState, filter.valuePath),
    );
    const disabledOptions = municipalitiesOptions.filter((option) => optionsToDisable.includes(option.value));
    const { value, onChange } = useLocationFilterValue({ filter, apply });
    const selectedOptions = municipalitiesOptions.filter((item) => value.includes(item.value as string));

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: (
            <LocationFiltersSelect
                placeholder={selectedOptions?.length ? 'Or...' : 'Select'}
                multiple={true}
                width="100%"
                options={municipalitiesOptions}
                disabledOptions={disabledOptions}
                value={selectedOptions}
                optionMatchFrom="start"
                onValueChange={(options) => onChange((options as SelectOption[]).map((o) => o.value))}
                forceOpen={forceOpen}
                onClose={onClose}
                onOpen={onOpen}
                warningLevel={warningLevel}
            />
        ),
    };
    return <FilterTemplate {...props} />;
};
