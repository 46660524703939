import { useCallback } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { FilterOperator, ValueOfSingleFilterOperator } from 'api/types/FilterOperators';
import { getFirstKey } from 'utilities/objectUtils';

import { mergeKeys } from '../../common/utils';
import {
    FilterNodeValue,
    FilterNodeValueExcluded,
    FilterNodeValueExtended,
    MappedFilter,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { getOperator, getValuePath } from '../utils';

export const useOperator = <T>(filter: MappedFilter<T>) => {
    const { setValue } = useFormContext();
    const key = filter.definition?.outsideGroup ? filter.groupPath : mergeKeys(filter.groupPath, filter.path);
    const value: FilterNodeValueExtended = useWatch({ name: key });

    const operator = getOperator(value);

    if (!operator) {
        throw new Error('Operator not found');
    }

    const handleOperatorUpdate = useCallback(
        (newOperator: Operator) => {
            const newValue = updateOperator({ currentOperator: operator, newOperator, value });
            setValue(key, newValue);
        },
        [operator, setValue, value, key],
    );

    return { operator, handleOperatorUpdate };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOperatorFromFilter = <T>(filter: MappedFilter<T>, setValue: (data: any) => void) => {
    const key = filter.definition?.outsideGroup ? filter.groupPath : mergeKeys(filter.groupPath, filter.path);

    const value = filter.value as FilterNodeValueExtended;

    const operator = getOperator(value);

    if (!operator) {
        throw new Error('Operator not found');
    }

    const id = filter.id;
    const handleOperatorUpdate = useCallback(
        (newOperator: Operator) => {
            const newValue = updateOperator({ currentOperator: operator, newOperator, value });

            const valuePath = getValuePath(id as never, newValue as any);
            setValue({ key, value: newValue, valuePath });
        },
        [operator, setValue, value, key, id],
    );

    return { operator, handleOperatorUpdate };
};

interface UpdateOperatorProps {
    currentOperator: OperatorValue | undefined;
    newOperator: Operator;
    value: FilterNodeValueExtended;
}

export function updateOperator({ currentOperator, newOperator, value }: UpdateOperatorProps) {
    const newValue: unknown = {};
    if (!currentOperator) {
        throw new Error('No operator found');
    }
    if (currentOperator.startsWith(FilterOperator.NOT) && !newOperator.value.startsWith(FilterOperator.NOT)) {
        const nestedOperator = getFirstKey((value as FilterNodeValueExcluded)[FilterOperator.NOT]);
        (newValue as FilterNodeValue)[newOperator.value as ValueOfSingleFilterOperator] = (
            value as FilterNodeValueExcluded
        )[FilterOperator.NOT][nestedOperator];
    } else if (currentOperator.startsWith(FilterOperator.NOT) && newOperator.value.startsWith(FilterOperator.NOT)) {
        (newValue as FilterNodeValueExcluded)[FilterOperator.NOT] = {
            [newOperator.value.replace(FilterOperator.NOT, '')]: (value as FilterNodeValueExcluded)[FilterOperator.NOT][
                currentOperator.replace(FilterOperator.NOT, '') as ValueOfSingleFilterOperator
            ],
        };
    } else if (newOperator.value.startsWith(FilterOperator.NOT)) {
        (newValue as FilterNodeValueExcluded)[FilterOperator.NOT] = {
            [newOperator.value.replace(FilterOperator.NOT, '')]: (value as FilterNodeValue)[
                currentOperator as ValueOfSingleFilterOperator
            ],
        };
    } else {
        (newValue as FilterNodeValue)[newOperator.value as ValueOfSingleFilterOperator] = (value as FilterNodeValue)[
            currentOperator as ValueOfSingleFilterOperator
        ];
    }
    return newValue;
}
