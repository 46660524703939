import React, { useState, useEffect, useCallback } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { getBookkeeperValues } from 'api/filter_values';
import { FilterOperator } from 'api/types/FilterOperators';
import { Values } from 'components/templates/RangeSelect';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useListsContext } from 'contexts/ListsContext';
import useDebounce from 'hooks/useDebounce';

import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterProps,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, getInnerMostValueInFilterState, makeFieldType, valueArrayToText } from '../utils';

const FIELD = makeFieldType(FilterID.bookkeeper);

export type TValues = { [FIELD]: Values };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.bookkeeper,
    label: 'Bookkeeper',
    defaultOperator: FilterOperator.IN,
    initValue: {
        [FilterOperator.IN]: {
            [FIELD]: [],
        },
    },
};

export const bookkeeperFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.financial,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <Auditor {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

export const Auditor: React.VFC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    removeFilter,
    sameFiltersState = [],
}) => {
    const axios = useAxiosContext();
    const { database } = useListsContext();
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);
    const [searchString, setSearchString] = useState('');
    const [options, setOptions] = useState<SelectOption[]>([]);

    const debouncedSearchString = useDebounce(searchString, 400);

    const fetchOptions = useCallback(async () => {
        if (debouncedSearchString.length < 3) {
            return;
        }
        const data = await getBookkeeperValues(axios, database, debouncedSearchString);

        setOptions(data);
    }, [axios, database, debouncedSearchString]);

    useEffect(() => {
        fetchOptions();
    }, [fetchOptions]);

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const optionsToDisable = sameFiltersState.flatMap((sameState) =>
        getInnerMostValueInFilterState(sameState, filter.valuePath),
    );

    const disabledOptions = options.filter((option) => optionsToDisable.includes(option.value));

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-range-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;

                    const selectedOptions = value.map((str: string) => ({ value: str, label: str }));

                    return (
                        <Select
                            placeholder={selectedOptions?.length ? 'Or...' : 'Select'}
                            multiple={true}
                            width="100%"
                            options={options}
                            disabledOptions={disabledOptions}
                            value={selectedOptions}
                            onValueChange={(options) => {
                                onChange((options as SelectOption[]).map((o) => o.value));
                                setSearchString('');
                            }}
                            onInputChange={(e, value, reason) => {
                                if (reason === 'input') {
                                    setSearchString(value);
                                }
                            }}
                            inputValue={searchString}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };

    return <FilterTemplate {...props} />;
};
