import { DomainData, Organization, ProfileData } from 'api/types/Organization';

export const commonFields = [
    'website',
    'name',
    'logo_url',
    'organization_size_indicators',
    'founded',
    'business_units',
    'website_data.screenshot_thumb_url',
    'website_data.screenshot_url',
    'description',
];
export const organizationFields: readonly string[] = [
    ...commonFields,
    'business_id',
    'country',
    'industries_verbose',
    'financial_data.profit',
    'financial_data.employee_count',
    'financial_data.revenue',
    'financial_data.currency',
    'group_data',
    'level_in_group',
];
export const domainDataFields: readonly string[] = [
    ...commonFields,
    'address',
    'website_data.website_traffic_data',
    'vainu_custom_industries',
];

export type CommonResult = Pick<
    ProfileData,
    | 'website'
    | 'name'
    | 'logo_url'
    | 'organization_size_indicators'
    | 'founded'
    | 'business_units'
    | 'id'
    | 'description'
> & {
    website_data?: Pick<Required<ProfileData>['website_data'], 'screenshot_thumb_url' | 'screenshot_url'>;
};

export type OrganizationResult = CommonResult &
    Pick<
        Organization,
        | 'business_id'
        | 'country'
        | 'industries_verbose'
        | 'profileType'
        | 'level_in_group'
        | 'group_data'
        | 'official_status'
        | 'contacts'
        | 'vehicles'
    > & {
        financial_data?: Pick<
            Required<ProfileData>['financial_data'],
            'profit' | 'employee_count' | 'revenue' | 'currency'
        >;
    };
export type DomainDataResult = CommonResult &
    Pick<DomainData, 'profileType'> &
    Pick<Required<DomainData>, 'website'> & {
        website_data?: Pick<Required<ProfileData>['website_data'], 'website_traffic_data'>;
    };

export type OrganizationResultAPIResponse = Omit<OrganizationResult, 'id' | 'profileType'>;
export type DomainDataResultAPIResponse = Omit<DomainDataResult, 'id' | 'profileType'>;
export type ResultAPIResponse = OrganizationResultAPIResponse | DomainDataResultAPIResponse;

export type ProfileResult = OrganizationResult | DomainDataResult;
