import { memo } from 'react';

import { Box, Skeleton, useTheme } from '@mui/material';
import { FormattedNumber } from 'react-intl';
import { assertArray, isNil } from 'utilities';

import { TabContentGridColumn, TabContentGridSection } from 'components/modules/profiles/Profile/tabs';
import { ProgressChart } from 'components/templates/charts';
import HeaderBox from 'components/templates/HeaderBox';
import Frame from 'components/tokens/Frame';
import Icon, { IconType } from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

export type SocialNetwork = {
    type: string;
    label: string;
    amountLabel: string;
    followers?: number;
    userHandle?: string;
    url?: string;
    icon: IconType;
};
export interface SocialNetworkHeaderProps {
    socialNetworks?: SocialNetwork[];
    social_media_index?: number;
    skeleton?: boolean;
}

export const SocialNetworkHeader: React.VFC<SocialNetworkHeaderProps> = memo(
    ({ socialNetworks, social_media_index, skeleton = false }) => {
        const theme = useTheme();

        if (skeleton) {
            return (
                <TabContentGridSection columns={2}>
                    <div>
                        <Skeleton variant="rounded" height={320} sx={{ backgroundColor: 'grey.100' }} />
                    </div>
                    <TabContentGridColumn>
                        <HeaderBox skeleton />
                        <HeaderBox skeleton />
                        <HeaderBox skeleton />
                    </TabContentGridColumn>
                </TabContentGridSection>
            );
        }

        const hasIndex = !isNil(social_media_index);
        const columns = hasIndex ? 2 : 3;

        const media = assertArray(socialNetworks).map(
            ({ type, icon, label, amountLabel, followers, userHandle, url }) => (
                <HeaderBox
                    icon={<Icon type={icon} color="common.white" />}
                    title={followers ? `${label} ${amountLabel}` : label}
                    number={followers ? <FormattedNumber value={followers} notation="compact" /> : null}
                    label={
                        <Link toNewTab="nofollow" href={url}>
                            {userHandle}
                        </Link>
                    }
                />
            ),
        );

        return (
            <TabContentGridSection columns={columns}>
                {hasIndex && (
                    <TabContentGridColumn>
                        <Frame title="Social media index" sx={{ height: 320 }}>
                            <ProgressChart
                                value={social_media_index * 10}
                                total={10}
                                height={176}
                                color={theme.palette.brandColors.orange}
                                labelAsNumber
                                formatOptions={{ maximumFractionDigits: 0, notation: 'standard' }}
                                hideTooltips
                            />
                            <Typography variant="tiny" color="subtle" paragraph sx={{ marginTop: 2 }}>
                                Social media index is calculated based on the{' '}
                                <Box
                                    component="a"
                                    target="_blank"
                                    href="https://help.vainu.app/en/articles/145624-what-are-vainu-indices"
                                    sx={{ color: 'unset', '&:hover': { color: 'text.strong' } }}
                                >
                                    following
                                </Box>
                                .
                            </Typography>
                        </Frame>
                    </TabContentGridColumn>
                )}
                {hasIndex ? <TabContentGridColumn>{media}</TabContentGridColumn> : media}
            </TabContentGridSection>
        );
    },
);

export default SocialNetworkHeader;
