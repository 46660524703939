import React from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import Popover from 'material-ui-popup-state/HoverPopover';

import { SignalTag } from 'api/types/signals';
import TagComponent from 'components/tokens/Tag';
import { Typography } from 'components/tokens/Typography';
import { formatDate } from 'utilities/date';

import { CellProps } from './TableComponent';
import { TooltipRow } from './TriggerCellTooltip';

const LeadCell: React.FC<CellProps> = ({ row }) => {
    const { title = '', tags, link = '', vainu_date } = row.lead || {};

    const popupState = usePopupState({ variant: 'popover', popupId: 'company-popover' });

    return (
        <>
            <StyledContainer {...bindHover(popupState)}>
                <Typography
                    weight="semibold"
                    variant="body2"
                    sx={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}
                >
                    {title}
                </Typography>
            </StyledContainer>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <StyledPaper>
                    <Grid container direction="column">
                        <TooltipRow icon="TagOutline">
                            <LeadTags tags={tags || []} />
                        </TooltipRow>
                        <TooltipRow icon="WindowTerminal">{title}</TooltipRow>
                        {vainu_date && (
                            <TooltipRow icon="Calendar">{formatDate(vainu_date, 'long-datetime')}</TooltipRow>
                        )}
                        {link && (
                            <TooltipRow icon="HomeAltOutline">
                                <Link href={link} target="_blank" rel="noreferrer" underline="always">
                                    {link.length > 40 ? `${link.slice(0, 40)}...` : link}
                                </Link>
                            </TooltipRow>
                        )}
                    </Grid>
                </StyledPaper>
            </Popover>
        </>
    );
};

export default LeadCell;

const StyledContainer = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const StyledPaper = styled(Paper)`
    border-radius: 8;
    word-break: 'break-word';
    padding: 16px;
`;

type LeadTagsProps = {
    tags: SignalTag[];
};

export const LeadTags: React.FC<LeadTagsProps> = ({ tags }) => {
    const [first, ...rest] = tags || [];
    const firstChip = first ? <TagComponent label={first.value} sx={{ overflow: 'hidden' }} /> : null;
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {firstChip}
            <MoreTagsPopOver tags={rest} />
        </Box>
    );
};

type MoreTagsProps = {
    tags: SignalTag[];
};

export const MoreTagsPopOver: React.FC<MoreTagsProps> = ({ tags }) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'more-tags-popover' });
    if (!tags?.length) {
        return null;
    }
    return (
        <>
            <Box sx={{ fontSize: 14, textDecoration: 'underline' }} {...bindHover(popupState)}>
                {`+${tags.length}`}
            </Box>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper sx={{ padding: '12px' }}>
                    <Grid container direction="column" gap={1}>
                        {tags.map((tag) => (
                            <TagComponent key={tag.value} label={tag.value} />
                        ))}
                    </Grid>
                </Paper>
            </Popover>
        </>
    );
};
