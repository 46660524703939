import React, { useState, useEffect, useCallback, useRef } from 'react';

import { pull, difference, includes, isEqual } from 'lodash';
import { isNotEmpty } from 'utilities';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import useDebounce from 'hooks/useDebounce';

import { SearchBar } from './SearchBar';
import { StyledDiv, StyledAlert, StyledStrong, Container, ScrollableContainer } from './styled';
import TreeView from './TreeView';
import { buildTreeList, findMatchingItems } from './treeViewUtilities';
import { TreeRawData, TreeNode } from './types';

export interface TreeContainerProps {
    data: TreeRawData;
    disabledItems?: string[];
    onSelect?: (node: TreeNode) => void;
    selectedRowIds?: string[];
    rootItemsWithDirectChildren: { [key: string]: string[] };
    groupType?: 'dot-separated';
    excludeParents?: boolean;
    defaultExpanded?: string[];
    title?: string;
}

const TreeContainer: React.FC<TreeContainerProps> = ({
    disabledItems = [],
    onSelect,
    selectedRowIds = [],
    rootItemsWithDirectChildren,
    data,
    groupType,
    excludeParents = false,
    defaultExpanded,
    title = 'Choose your attributes',
}) => {
    const [treeData, setTreeData] = useState([] as TreeNode[]);
    const [filteredIds, setFilteredIds] = useState<string[]>([]);
    const [openRowIds, setOpenRowIds] = useState(defaultExpanded || []);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isFoundMatches, setIsFoundMatches] = useState<boolean>(true);
    const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
    const searchBarRef = useRef<HTMLInputElement | null>(null);

    const usePrevious = (value: string) => {
        const ref = useRef<string>();
        useEffect(() => {
            ref.current = value;
        });

        return ref.current;
    };

    const debouncedFilterValue = useDebounce(searchTerm, 400);
    const previousFilterValue = usePrevious(debouncedFilterValue);

    useEffect(() => {
        const treeData = buildTreeList(data, rootItemsWithDirectChildren, groupType);
        setTreeData(treeData);
    }, [setTreeData, data, rootItemsWithDirectChildren, groupType]);

    const toggleOpen = (node: TreeNode) => {
        const { id } = node;
        const result = [...openRowIds];
        if (!includes(openRowIds, id)) {
            result.push(id);
        } else {
            pull(result, id);
        }
        setOpenRowIds(result);
    };

    const openRows = useCallback(
        (ids: string[]) => {
            const result = [...openRowIds];
            ids.forEach((id: string) => {
                if (!includes(openRowIds, id)) {
                    result.push(id);
                }
            });

            setOpenRowIds(result);
        },
        [openRowIds],
    );

    useEffect(() => {
        if (debouncedFilterValue && debouncedFilterValue !== '') {
            if (previousFilterValue !== debouncedFilterValue) {
                const { matches, paths: rowsToOpen } = findMatchingItems(treeData, debouncedFilterValue);

                if (isNotEmpty(matches) && !isEqual(treeData, matches)) {
                    setFilteredIds(matches);
                    setIsFoundMatches(true);
                } else {
                    setIsFoundMatches(false);
                }

                if (isNotEmpty(rowsToOpen) && isNotEmpty(difference(rowsToOpen, openRowIds))) {
                    openRows(rowsToOpen);
                }
            }
        } else {
            setIsFoundMatches(true);
        }
    }, [debouncedFilterValue, previousFilterValue, treeData, openRows, openRowIds]);

    useEffect(() => {
        if (!showSearchBar || searchTerm === '') {
            setSearchTerm('');
            setOpenRowIds(defaultExpanded || []);
            setFilteredIds([]);
        }
    }, [showSearchBar, searchTerm, defaultExpanded]);

    const handleButtonClick = (_e: React.MouseEvent) => {
        setSearchTerm('');
        setShowSearchBar(true);
        /** To make sure the element is available in dom */
        setTimeout(() => {
            searchBarRef.current?.focus();
        }, 100);
    };

    return (
        <Container>
            <StyledDiv>
                <Typography
                    variant="body1"
                    padding={'8px 0 14px'}
                    component="p"
                    sx={{ color: '#3F3F3F', fontWeight: 'bolder', fontSize: '16px' }}
                >
                    {title}
                </Typography>
                <SearchBar
                    ref={searchBarRef}
                    setSearchQuery={setSearchTerm}
                    searchTerm={searchTerm}
                    setShowSearch={setShowSearchBar}
                    showSearch={showSearchBar}
                />
            </StyledDiv>
            <ScrollableContainer sx={{ overflow: 'auto' }}>
                {isFoundMatches ? (
                    <TreeView
                        filterValue={searchTerm}
                        data={treeData}
                        toggleOpen={toggleOpen}
                        openRows={openRowIds}
                        filteredItems={filteredIds}
                        selectedItems={selectedRowIds}
                        toggleSelection={onSelect}
                        disabledItems={disabledItems}
                        excludeParents={excludeParents}
                    />
                ) : (
                    <StyledAlert>
                        <span className="no-result">
                            🤔 Hmm, we couldn’t find an attribute with <StyledStrong>{searchTerm}</StyledStrong>{' '}
                        </span>
                        <Button onClick={handleButtonClick} startIcon={<Icon color="#000" type="Search" />}>
                            Try something else
                        </Button>
                        {/* TODO: add this button If we have search suggestions with results (i.e Namep ➡️ Name) or synonyms
                    based on their keyword */}
                    </StyledAlert>
                )}
            </ScrollableContainer>
        </Container>
    );
};

export default TreeContainer;
