import React, { useMemo, useCallback } from 'react';

import { AutocompleteRenderGroupParams, Divider, InputLabel, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import {
    Controller,
    useForm,
    useFieldArray,
    FieldArrayWithId,
    Control,
    UseFieldArrayRemove,
    UseFormSetError,
    UseFormClearErrors,
    UseFormGetValues,
    UseFieldArrayAppend,
} from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import OverflowContainer from 'components/layout/OverflowContainer';
import { Button } from 'components/tokens/Button';
import { Icon } from 'components/tokens/Icon';
import NestedSelect, { OptionProps, SubOption } from 'components/tokens/select-components/NestedSelect';
import Select from 'components/tokens/select-components/Select/Select';
import { SelectOptionTypes } from 'components/tokens/select-components/VirtualizedSelect';
import { TextField } from 'components/tokens/TextField';
import { Typography } from 'components/tokens/Typography';

import ConfirmDialog from '../../common/ConfirmDialog';
import useConnectorsApi from '../../hooks/useConnectorsApi';
import {
    CrmName,
    CRMObject,
    EnhancedMapping,
    EnhancedMappingSource,
    VainuDatapoint,
    VainuDatapointOption,
    Technology,
    TechnologiesTree,
    TechIdToCategoryMap,
    VainuObject,
    CRMProperty,
    CRMPicklistValue,
    EnhancedCRMPicklistValue,
    DataUpdateType,
} from '../../types/Crm';
import { getCRMName } from '../../utils';
import { CRM_PICKLIST_LIMITS } from '../constants';
import { getDatapointUpdateConfig, MappingUpdateSelect } from '../MappingUpdateSelect';
import { getSourcePreview } from '../utils';
import { MappingsTechnologiesTree } from './TechnologiesTree';

interface MappingFormDialogProps {
    isOpen: boolean;
    crmName: CrmName;
    activeCRMObjectTab: CRMObject;
    crmProperty: CRMProperty;
    mapping: EnhancedMapping;
    onSubmit: (mapping: EnhancedMapping) => void;
    handleDialogClose: () => void;
}

const initialMappingSource: EnhancedMappingSource = {
    id: uuid(),
    options: {},
    source_field: '',
    source_field_type: '',
    source_object: '' as VainuObject,
    preview: '',
};

// todo: remove EnhancedMapping specific properties from API request
const initialValues: EnhancedMapping = {
    createdByVainu: false,
    mandatory: false,
    id: '',
    target_field: '',
    target_object: '' as CRMObject,
    update_type: 'safe_update',
    last_modified: '',
    crmPropertyLabel: '',
    crmPropertyType: '',
    sources: [initialMappingSource],
};

const CUSTOM_INPUT_OPTION: VainuDatapoint = {
    object: 'Text',
    field: 'Text',
    label: 'Custom input',
    header: 'Custom input',
    type: 'Text',
    value: '',
    example: '',
    field_options: null,
    options_p2: null,
    default_option: null,
    description: null,
    required_permission: null,
    groups: ['business_id', 'global'],
    allowed_for_user: true,
};

const getTechnologiesData = (technologies: Technology[]) => {
    // tree is the most optimal data structure for TechnologiesTree rendering
    const tree: TechnologiesTree = {};
    // map helps to reduce the amount of iterations through the array of selected technologies
    const idToCategoryMap: TechIdToCategoryMap = {};

    technologies.forEach((technology) => {
        const [id, name, categoryName] = technology;

        tree[categoryName] = tree[categoryName] ? [...tree[categoryName], { id, name }] : [{ id, name }];
        idToCategoryMap[id] = categoryName;
    });

    return { tree, idToCategoryMap };
};

const isCompanyObject = (crmObject: CRMObject) => {
    const normalizedCRMObject = crmObject.toLowerCase();
    return (
        normalizedCRMObject.includes('account') ||
        normalizedCRMObject.includes('company') ||
        normalizedCRMObject.includes('organization')
    );
};

const isContactObject = (crmObject: CRMObject) => {
    const normalizedCRMObject = crmObject.toLowerCase();
    return normalizedCRMObject.includes('contact') || normalizedCRMObject.includes('person');
};

const getDatapointObjectAlias = (datapointObject: VainuObject) => {
    const objectsNamesMap: Partial<Record<VainuObject, string>> = {
        Prospect: 'Company',
        Lead: 'Event',
        Text: 'Custom Input',
    };

    return objectsNamesMap[datapointObject] ?? datapointObject;
};

type DatapointsTree = {
    isCustomInput: boolean;
    alias: string;
    description: string;
    endAdornment: JSX.Element | null;
    label: string;
    value: string;
    options: Array<VainuDatapoint | EnhancedCRMPicklistValue>;
}[];

const getDatapointsTree = ({
    activeCRMObjectTab,
    crmPropertyOptions,
    datapoints,
}: {
    activeCRMObjectTab: CRMObject;
    crmPropertyOptions: CRMPicklistValue[];
    datapoints: VainuDatapoint[];
}): DatapointsTree => {
    const crmPicklistValues = crmPropertyOptions.map((option) => ({ ...option, object: 'Picklist', type: 'Picklist' }));

    const datapointsByObjectsMap = datapoints.reduce(
        (acc, datapoint) => {
            const { object } = datapoint;

            return {
                ...acc,
                [object]: [...(acc[object] ?? []), datapoint],
            };
        },
        { Picklist: crmPicklistValues } as Record<VainuObject, Array<VainuDatapoint | EnhancedCRMPicklistValue>>,
    );

    return Object.entries(datapointsByObjectsMap)
        .map(([object, datapoints]) => {
            const isCustomInput = object === 'Text';

            return {
                isCustomInput,
                description: '',
                alias: getDatapointObjectAlias(object as VainuObject),
                label: object,
                value: object,
                endAdornment: isCustomInput ? <Icon type="CustomInput" color="icon.subtle" /> : null,
                options: datapoints,
            };
        })
        .filter((datapointsByObject) => {
            const { alias, options } = datapointsByObject;

            if (alias === 'Picklist' && options.length === 0) return false;

            if (isCompanyObject(activeCRMObjectTab)) {
                return alias === 'Picklist' || alias === 'Company' || alias === 'Custom Input';
            }

            if (isContactObject(activeCRMObjectTab)) {
                return alias === 'Picklist' || alias === 'Company' || alias === 'Contact' || alias === 'Custom Input';
            }

            return datapointsByObject;
        });
};

const MappingFormDialog: React.FC<MappingFormDialogProps> = ({
    isOpen,
    activeCRMObjectTab,
    crmProperty,
    crmName,
    mapping,
    onSubmit,
    handleDialogClose,
}) => {
    const { getVainuIntegrationSettingsFields, getWebTechnologiesData } = useConnectorsApi();

    const { data: datapoints = [] } = useQuery({
        queryKey: ['vainu-datapoints'],
        queryFn: () => getVainuIntegrationSettingsFields().then(({ data }) => data),
        select: (datapoints) => [
            // hack: raw datapoint's 'label' property contains wrong data
            // 'label' property is required by Select component
            ...datapoints.map((datapoint) => ({ ...datapoint, label: datapoint.header }) as VainuDatapoint),
            CUSTOM_INPUT_OPTION,
        ],
        staleTime: Infinity,
    });

    const { data: webTechnologies = { tree: {}, idToCategoryMap: {} } } = useQuery({
        queryKey: ['web-technologies'],
        queryFn: () => getWebTechnologiesData().then(({ data }) => data.ALL),
        select: (technologies) => getTechnologiesData(technologies),
        staleTime: Infinity,
    });

    const {
        formState: { isDirty, errors },
        control,
        watch,
        getValues,
        setValue,
        setError,
        clearErrors,
    } = useForm({
        defaultValues: { ...initialValues, ...mapping },
    });
    const { fields, append, remove } = useFieldArray({ control, name: 'sources' });
    const primaryMappingSource = watch(`sources.0`);

    const filteredDatapoints: VainuDatapoint[] = useMemo(
        () => datapoints.filter(({ allowed_for_user }) => allowed_for_user),
        [datapoints],
    );

    const datapointsTree = useMemo(
        () =>
            getDatapointsTree({
                activeCRMObjectTab,
                datapoints: filteredDatapoints,
                crmPropertyOptions: crmProperty.vainu_type === 'SingleSelect' ? crmProperty.options : [],
            }),
        [activeCRMObjectTab, crmProperty, filteredDatapoints],
    );

    const sourceFieldToDatapointMap = useMemo(() => {
        return datapoints.reduce(
            (acc, datapoint) => ({ ...acc, [datapoint.field]: datapoint }),
            {} as Record<string, VainuDatapoint>,
        );
    }, [datapoints]);

    const hasFallbacks = fields.length > 1;

    const crmPicklistLimit = crmProperty.vainu_type === 'MultiSelect' ? CRM_PICKLIST_LIMITS[crmName] : Infinity;

    const isFallbackAllowed = primaryMappingSource.source_field !== 'technographic_data';

    const handleSubmit = useCallback(() => {
        const formValues = getValues();

        onSubmit({
            ...formValues,
            sources: formValues.sources.filter(({ source_field }) => !!source_field),
            last_modified: new Date().toISOString(),
        });
    }, [getValues, onSubmit]);

    const handleUpdateTypeChange = useCallback(
        (updateType: DataUpdateType) => setValue('update_type', updateType),
        [setValue],
    );

    return (
        <ConfirmDialog
            data-testid="mapping-form-dialog"
            open={isOpen}
            maxWidth="lg"
            customWidth="760px"
            title={<div style={{ marginTop: '-32px' }}>{mapping.last_modified ? 'Edit' : 'Add'} field</div>}
            primaryButtonText={mapping.last_modified ? 'Save changes' : 'Create field'}
            primaryButtonClick={handleSubmit}
            primaryButtonDisabled={
                !isDirty || Object.keys(errors).length > 0 || primaryMappingSource.source_field === ''
            }
            subTitle=""
            extraBodyContent={
                <OverflowContainer>
                    <>
                        <FormSection label={getCRMName(crmName)}>
                            <div>
                                <StyledInputLabel>Field</StyledInputLabel>
                                <TextField
                                    status="disabled"
                                    value={mapping.crmPropertyLabel}
                                    sx={{ width: '256px', marginRight: 1 }}
                                    InputProps={{ sx: { height: '40px' } }}
                                />
                            </div>
                            <div>
                                <StyledInputLabel>Field type</StyledInputLabel>
                                <TextField
                                    status="disabled"
                                    value={mapping.crmPropertyType}
                                    sx={{ width: '256px' }}
                                    InputProps={{ sx: { height: '40px' } }}
                                />
                            </div>
                        </FormSection>
                        <StyledDivider light />
                        <FormSection label="Vainu">
                            <div>
                                <MappingSourcesList
                                    fields={fields}
                                    control={control}
                                    crmPicklistLimit={crmPicklistLimit}
                                    sourceFieldToDatapointMap={sourceFieldToDatapointMap}
                                    webTechnologies={webTechnologies}
                                    datapointsTree={datapointsTree}
                                    getValues={getValues}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                    handleAdd={append}
                                    handleRemove={remove}
                                    handleUpdateTypeChange={handleUpdateTypeChange}
                                />
                                {isFallbackAllowed && (
                                    <Button
                                        variant="tertiary"
                                        sx={{ width: '90px' }}
                                        onClick={() => append(initialMappingSource)}
                                    >
                                        + Fallback
                                    </Button>
                                )}
                            </div>
                        </FormSection>
                        <StyledDivider />
                        <FormSection label="Fill the field">
                            <Controller
                                name="update_type"
                                control={control}
                                render={({ field }) => {
                                    const updateTypeConfig =
                                        getDatapointUpdateConfig(primaryMappingSource.source_field) ||
                                        // 'source_object' is used for the Custom input datapoint
                                        getDatapointUpdateConfig(primaryMappingSource.source_object);

                                    return (
                                        <MappingUpdateSelect
                                            disabled={hasFallbacks ? false : updateTypeConfig.isUpdateTypeFixed}
                                            disabledTooltip={hasFallbacks ? undefined : updateTypeConfig.tooltip}
                                            showInfoBanner
                                            defaultSelectStyles
                                            value={field.value}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                field.onChange(event.target.value as DataUpdateType)
                                            }
                                        />
                                    );
                                }}
                            />
                        </FormSection>
                    </>
                </OverflowContainer>
            }
            handleClose={handleDialogClose}
        />
    );
};

interface MappingSourcesListProps {
    fields: FieldArrayWithId<EnhancedMapping>[];
    control: Control<EnhancedMapping>;
    crmPicklistLimit: number;
    sourceFieldToDatapointMap: Record<string, VainuDatapoint>;
    datapointsTree: DatapointsTree;
    webTechnologies: { tree: TechnologiesTree; idToCategoryMap: TechIdToCategoryMap };
    getValues: UseFormGetValues<EnhancedMapping>;
    setError: UseFormSetError<EnhancedMapping>;
    clearErrors: UseFormClearErrors<EnhancedMapping>;
    handleAdd: UseFieldArrayAppend<EnhancedMapping>;
    handleRemove: UseFieldArrayRemove;
    handleUpdateTypeChange: (updateType: DataUpdateType) => void;
}

const renderOptionsDropdownGroup = (params: AutocompleteRenderGroupParams) => {
    return (
        <div>
            <Typography weight="bold" fontSize="12px" color="subtle" sx={{ padding: '6px 8px' }}>
                {params.group}
            </Typography>
            {params.children}
        </div>
    );
};

const MappingSourcesList: React.FC<MappingSourcesListProps> = ({
    fields,
    control,
    crmPicklistLimit,
    sourceFieldToDatapointMap,
    datapointsTree,
    webTechnologies,
    getValues,
    setError,
    clearErrors,
    handleAdd,
    handleRemove,
    handleUpdateTypeChange,
}) => {
    return (
        <>
            {fields.map(({ id }, sourceIndex) => (
                <Controller
                    key={id}
                    name={`sources.${sourceIndex}`}
                    control={control}
                    render={({ field }) => {
                        const { source_field: sourceField } = field.value;
                        const datapointOptions = sourceFieldToDatapointMap[sourceField]?.options_p2;
                        const isCustomInput = field.value.source_object === 'Text';
                        const hasTwoInputs = !!datapointOptions || isCustomInput;

                        const handleDatapointSelect = (datapoint: SelectOptionTypes) => {
                            const vainuDatapoint = datapoint as VainuDatapoint;
                            const datapointOptions = vainuDatapoint.options_p2?.[0].options;

                            const mappingOptions =
                                vainuDatapoint.value === 'technographic_data'
                                    ? { tag_ids: [] }
                                    : {
                                          format: vainuDatapoint.default_option?.format,
                                          label: datapointOptions?.find(
                                              (option) => option.value === vainuDatapoint.default_option?.format,
                                          )?.label,
                                      };

                            field.onChange({
                                ...field.value,
                                source_field: vainuDatapoint.value,
                                source_field_type: vainuDatapoint.type,
                                source_object: vainuDatapoint.object,
                                options: mappingOptions,
                            });

                            const updateTypeConfig = getDatapointUpdateConfig(vainuDatapoint.field);

                            if (sourceIndex === 0) {
                                handleUpdateTypeChange(updateTypeConfig.defaultUpdateType);
                            }
                        };

                        const handleDatapointOptionSelect = (option: SelectOptionTypes) => {
                            const datapointOption = option as VainuDatapointOption;

                            field.onChange({
                                ...field.value,
                                options: {
                                    format: datapointOption.value,
                                    label: datapointOption.label,
                                },
                            });
                        };

                        // field.onChange() triggers useWatch() from react-hook-form
                        // which is not performant for user keyboard events
                        const handleCustomInputChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
                            field.onChange({
                                ...field.value,
                                source_field: event.target.value,
                            });
                        }, 200);

                        const handleTechnologiesSelect = (technologiesIds: number[]) => {
                            field.onChange({
                                ...field.value,
                                options: { ...field.value.options, tag_ids: technologiesIds },
                            });

                            if ((getValues('sources.0.options.tag_ids') as number[]).length > crmPicklistLimit) {
                                setError('sources', { type: 'maxLength', message: 'Picklist limit reached' });
                            } else {
                                clearErrors('sources');
                            }
                        };

                        const sourcePreview = getSourcePreview({
                            selectedOptionValue: field.value.options.format ?? '',
                            sourceObject: field.value.source_object,
                            sourceField,
                            sourceFieldToDatapointMap,
                        });

                        return (
                            <>
                                <DatapointWrapper>
                                    <div>
                                        {sourceIndex === 0 && (
                                            <StyledInputLabel htmlFor="source-0">Data</StyledInputLabel>
                                        )}
                                        <NestedSelect
                                            data-testid={`source-${sourceIndex}`}
                                            openInitialDefaultSelectionOnMenuOpen={false}
                                            height={40}
                                            placeholder="Select datapoint"
                                            width={hasTwoInputs ? '124px' : '252px'}
                                            defaultValueObject={{
                                                object: field.value.source_object,
                                                key: sourceField,
                                                value: sourceField,
                                                // Picklists - use `sourceField` as an input value
                                                // Custom inputs - use 'Custom input' string as an input value
                                                // otherwise, use the `header` of the selected datapoint as an input value
                                                label: isCustomInput
                                                    ? 'Custom input'
                                                    : sourceFieldToDatapointMap[sourceField]?.header ?? sourceField,
                                            }}
                                            nestedOptions={datapointsTree as OptionProps[]}
                                            customInputClickCallback={() => handleDatapointSelect(CUSTOM_INPUT_OPTION)}
                                            onChange={({ option: datapoint }) =>
                                                handleDatapointSelect(datapoint as SubOption)
                                            }
                                        />
                                    </div>
                                    {datapointOptions && (
                                        <Select
                                            multiple={false}
                                            placeholder="Options..."
                                            width="124px"
                                            sx={{ '.MuiInputBase-root': { height: '40px' }, marginLeft: '4px' }}
                                            groupBy={() =>
                                                sourceField === 'company_size_indicators' ? 'SOURCE' : 'FORMAT'
                                            }
                                            renderGroup={renderOptionsDropdownGroup}
                                            value={
                                                datapointOptions[0].options.find(
                                                    (option) => option.value === field.value.options.format,
                                                )?.label ?? ''
                                            }
                                            options={datapointOptions?.[0].options ?? []}
                                            onValueChange={handleDatapointOptionSelect}
                                        />
                                    )}
                                    {isCustomInput && (
                                        <TextField
                                            sx={{ width: '124px', marginLeft: '4px' }}
                                            InputProps={{ sx: { height: '40px' } }}
                                            placeholder="Type..."
                                            defaultValue={sourceField}
                                            onChange={handleCustomInputChange}
                                        />
                                    )}
                                    {field.value.source_object && (
                                        <div>
                                            {sourceIndex === 0 && (
                                                <StyledInputLabel sx={{ marginLeft: '8px' }}>Preview</StyledInputLabel>
                                            )}
                                            <Preview>
                                                {Array.isArray(sourcePreview)
                                                    ? sourcePreview.join(', ')
                                                    : sourcePreview}
                                            </Preview>
                                        </div>
                                    )}
                                    <Button
                                        data-testid="remove-fallback-button"
                                        variant="flat"
                                        sx={{ marginLeft: '8px' }}
                                        startIcon={<Icon type="Remove" color="brandColors.subtle" />}
                                        onClick={() => {
                                            handleRemove(sourceIndex);

                                            const mappingSources = getValues('sources');

                                            if (mappingSources.length === 0) {
                                                handleAdd(initialMappingSource);
                                            }

                                            if (mappingSources.length === 1) {
                                                const updateTypeConfig =
                                                    getDatapointUpdateConfig(mappingSources[0].source_field) ||
                                                    getDatapointUpdateConfig(mappingSources[0].source_object);

                                                if (updateTypeConfig) {
                                                    handleUpdateTypeChange(updateTypeConfig.defaultUpdateType);
                                                }
                                            }
                                        }}
                                    />
                                </DatapointWrapper>
                                {sourceField === 'technographic_data' && (
                                    <MappingsTechnologiesTree
                                        limit={crmPicklistLimit}
                                        data={webTechnologies}
                                        selectedValues={field.value.options.tag_ids ?? []}
                                        onSelect={handleTechnologiesSelect}
                                    />
                                )}
                            </>
                        );
                    }}
                />
            ))}
        </>
    );
};

type FormSectionProps = {
    label: string;
    children: React.ReactNode;
};

const FormSection: React.FC<FormSectionProps> = ({ label, children }) => {
    return (
        <SectionWrapper>
            <Typography variant="body2" weight="semibold" sx={{ flex: '0 0 120px', marginRight: '8px' }}>
                {label}
            </Typography>
            {children}
        </SectionWrapper>
    );
};

const SectionWrapper = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '24px',
});

const StyledDivider = styled(Divider)(({ theme: { palette } }) => ({
    marginBottom: '24px',
    borderColor: palette.brandColors.cloud,
}));

const DatapointWrapper = styled('div')({
    display: 'flex',
    alignItems: 'end',
    marginBottom: '8px',
});

const StyledInputLabel = styled(InputLabel)({
    marginBottom: '4px',
    fontSize: '14px',
    fontWeight: 500,
});

const Preview = styled('div')(({ theme: { palette } }) => ({
    width: '256px',
    height: '40px',
    marginLeft: '8px',
    padding: '8px 12px',
    background: palette.app.background,
    color: palette.text.secondary,
    fontSize: '14px',
    borderRadius: '4px',
    lineHeight: '24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
}));

export { MappingFormDialog };
