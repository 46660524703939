import React from 'react';

import { Box } from '@mui/material';
import { range } from 'utilities';

import { TechnologyData } from 'api/types/Organization';
import Icon from 'components/tokens/Icon';

import { getTechnologyIcon } from '../../utils';
import HighlightBox, { HighlightBoxSkeleton } from './HighlightBox';

export interface WebProfileHighlightsProps {
    highlightedTechnologies?: TechnologyData[];
    skeleton?: boolean;
}

export const WebProfileHighlights: React.VFC<WebProfileHighlightsProps> = ({
    highlightedTechnologies,
    skeleton = false,
}) => {
    if (!highlightedTechnologies && !skeleton) {
        return null;
    }

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 2 }}>
            {skeleton
                ? range(9).map((index) => <HighlightBoxSkeleton key={index} />)
                : highlightedTechnologies
                  ? highlightedTechnologies.map((technology) => (
                        <HighlightBox
                            key={technology.name}
                            description={technology.description}
                            title={technology.name}
                            icon={
                                technology.logo_url ? (
                                    <img src={technology.logo_url} alt={technology.name} />
                                ) : (
                                    <Icon type={getTechnologyIcon(technology.types?.[0])} color="text.subtle" />
                                )
                            }
                        />
                    ))
                  : null}
        </Box>
    );
};
export default WebProfileHighlights;
