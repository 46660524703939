import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

export const CloseButtonWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '4px 4px 0 0',
}));

export const StyledMuiDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        borderRadius: 8,
        padding: '0 0 24px',
    },
}));

export const StyledMuiDialogTitle = styled(DialogTitle)(({ theme: { palette } }) => ({
    fontSize: 24,
    paddingTop: 0,
    marginTop: -12,
    fontWeight: 700,
    letterSpacing: ' -0.02em',
    color: palette.common.black,
}));

export const StyledMuiDialogText = styled(DialogContentText)(() => ({
    fontSize: 14,
    marginBottom: 8,
}));

export const StyledMuiDialogActions = styled(DialogActions)(() => ({
    padding: '0 24px',
}));
