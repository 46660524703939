import { IconButton, Box } from '@mui/material';
import { format } from 'date-fns';

import { List } from 'api/types/List';
import TargetListMenu from 'components/features/TargetListMenu';
import Icon from 'components/tokens/Icon';
import MainListItem from 'components/tokens/MainListItem';
import { ListAction } from 'contexts/utilities/listsUtils';

import { getListPath } from '..';

type TargetListItemProps = {
    list: List;
    isListActive: boolean;
    onListClick?: (listId: string) => void;
    onListMenuAction?: (listId: string, action: ListAction, fields?: Partial<List>) => void;
};

export const TargetListItem: React.FC<TargetListItemProps> = ({
    list,
    isListActive,
    onListClick,
    onListMenuAction,
}) => {
    const { id, name, created, modified, privileges } = list;

    const isOwner = privileges.current === 'owner';
    const hasEditPermission = privileges.current === 'edit';

    return (
        <MainListItem
            key={id}
            selected={isListActive}
            to={getListPath(id)}
            onClick={() => onListClick?.(id)}
            tooltip={
                <Box sx={{ fontWeight: 'normal' }}>
                    Last modified: {modified ? format(new Date(modified), 'PP') : format(new Date(created), 'PP')}
                </Box>
            }
            overflowInTooltip
            endIcon={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isOwner && privileges.shared_count !== 0 && (
                        <Icon
                            type="ShareOutline"
                            fontSize="mini"
                            sx={{ color: isListActive ? 'secondary.main' : undefined }}
                        />
                    )}
                    {!isOwner && !hasEditPermission && <Icon type="Lock" fontSize="mini" className="controls_icon" />}
                    <TargetListMenu
                        anchorElement={
                            <IconButton data-testid={`list-item-actions-menu-${id}`}>
                                <Icon
                                    type="MoreHorizontal"
                                    fontSize="mini"
                                    sx={{ color: isListActive ? 'secondary.main' : undefined }}
                                />
                            </IconButton>
                        }
                        list={list}
                        closeOnMouseLeave={true}
                        isOwner={isOwner}
                        shareList={isOwner || hasEditPermission}
                        onClick={onListMenuAction ? (action, fields) => onListMenuAction(id, action, fields) : () => {}}
                    />
                </Box>
            }
            endIconVisibleOnHover
        >
            {name}
        </MainListItem>
    );
};
