import { SxProps, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';

import FlagEmoji from 'components/tokens/FlagEmoji';

export type CountryFlagProps = {
    country?: string;
    sx?: SxProps;
    disableTooltip?: boolean;
};

export const CountryFlag: React.VFC<CountryFlagProps> = ({ country, sx, disableTooltip = false }) => {
    const intl = useIntl();

    if (!country) {
        return null;
    }

    const flag = <FlagEmoji code={country} sx={{ marginRight: '6px', '&:last-child': { marginRight: 0 }, ...sx }} />;

    if (disableTooltip) {
        return flag;
    }

    const countryName = intl.formatDisplayName(country, { type: 'region' });
    const renderTooltip = Boolean(Intl.DisplayNames);

    if (renderTooltip && countryName) {
        return <Tooltip title={countryName}>{flag}</Tooltip>;
    }
    return flag;
};
export default CountryFlag;
