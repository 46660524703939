import { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Trigger } from 'api/types/Trigger';
import { MainPath } from 'components/beta/Platform/Router/routePaths';
import { TRIGGER_NEW_COMPANY } from 'components/modules/triggers/utils';
import { TriggersIcon } from 'components/tokens/Icon/TriggersIcon';
import { useTrigger } from 'contexts/TriggerContext';

import {
    TriggersStateButtonProps,
    UseTriggersStateButtonProps,
    UseTriggersStateButtonValue,
    UseTriggersStateButtonWithDataProps,
} from './types';

type ButtonStatus = 'none' | 'active' | 'paused';

/**
 * Determines the status of triggers.
 *
 * @param triggers - An array of Trigger objects.
 * @returns 'none' if there are no triggers,
 *          'active' if there is at least one active trigger,
 *          'paused' if there are triggers but none are active.
 */
export const getStatus = (triggers: Trigger[]) => {
    return triggers.length === 0
        ? 'none'
        : triggers.some((trigger) => trigger.status === 'active')
          ? 'active'
          : 'paused';
};

/**
 * returns params for a button with a menu list that displays an icon and text based on the presence of triggers
 * for the provided list ID. If there are no triggers, the user can create a new trigger from the
 * menu options. If triggers exist, the user can navigate to the existing triggers from the menu.
 * @param {string} listId - The ID of the list for which triggers should be displayed.
 * @param {Trigger[]} triggers - An array of Trigger objects.
 * @returns {UseTriggersStateButtonValue} An object containing the options for the button, static options, icon, and label.
 */
export const useTriggersStateButton = ({
    triggers,
    listId,
}: UseTriggersStateButtonProps): UseTriggersStateButtonValue => {
    const { buttonStatus, listTriggers } = useMemo<{
        buttonStatus: ButtonStatus;
        listTriggers: Trigger[];
    }>(() => {
        const listTriggers = triggers.filter((trigger) => trigger.workflow_settings.target_group_source === listId);

        const triggerStatus = getStatus(listTriggers);

        return { buttonStatus: triggerStatus, listTriggers };
    }, [triggers, listId]);

    const iconState = buttonStatus === 'none' ? 'normal' : buttonStatus;

    const items = useMemo(() => {
        if (buttonStatus === 'none') {
            return [
                {
                    action: TRIGGER_NEW_COMPANY,
                    label: 'New company in list',
                },
                // This item is temporarily hidden until contacts are added to triggers
                /*{
                    action: 'new',
                    label: 'New contact in list',
                },*/
            ];
        }
        return listTriggers.map((trigger) => ({
            icon: <TriggersIcon state={trigger.status === 'active' ? 'active' : 'paused'} fontSize="mini" />,
            action: trigger.id as string,
            label: trigger.name,
        }));
    }, [buttonStatus, listTriggers]);

    const staticOptions = useMemo(() => {
        if (buttonStatus === 'none') {
            return [
                {
                    icon: 'TriggersUpgrade',
                    action: 'new',
                    label: 'More events…',
                },
            ];
        }
        return [
            {
                icon: 'Add',
                action: 'new',
                label: 'Create new',
            },
        ];
    }, [buttonStatus]);

    const getLabel = () => {
        if (buttonStatus === 'paused') {
            return 'Tracking paused';
        }
        if (buttonStatus === 'active') {
            return 'Tracking changes';
        }
        return 'Track changes';
    };
    const label = getLabel();

    return useMemo(
        () => ({
            options: items,
            staticOptions: staticOptions as TriggersStateButtonProps['staticOptions'],
            icon: <TriggersIcon state={iconState} />,
            label,
        }),
        [items, staticOptions, iconState, label],
    );
};

/**
 * Hook wrapper that pass triggers data to useTriggersStateButton
 * @param listId - The ID of the target list.
 * @returns Props for the TriggerStateButton component.
 * @see TriggersStateButton for more details on the props.
 */
export const useTriggersStateButtonWithData = ({ listId }: Pick<UseTriggersStateButtonWithDataProps, 'listId'>) => {
    const [, , { triggers }] = useTrigger();

    const props = useTriggersStateButton({ triggers, listId });

    return props;
};

export const getTriggersLink = ({
    listId,
    listName,
    action,
}: Partial<UseTriggersStateButtonWithDataProps> & { action: string }) => {
    if (listId) {
        if (action === TRIGGER_NEW_COMPANY) {
            const params = new URLSearchParams({
                type: TRIGGER_NEW_COMPANY,
                listId,
                ...(listName && { name: `${listName} trigger` }),
            });

            return `/${MainPath.Triggers}/new?${params.toString()}`;
        }
        if (action === 'new') {
            const params = new URLSearchParams({
                listId,
                ...(listName && { name: `${listName} trigger` }),
            });

            return `/${MainPath.Triggers}/new?${params.toString()}`;
        }
    }

    return `/${MainPath.Triggers}/${action}`;
};

export const useGetNavigateToTriggers = ({ listId, listName }: UseTriggersStateButtonWithDataProps) => {
    const navigate = useNavigate();
    return useCallback(
        (action: string) => {
            const link = getTriggersLink({ listId, listName, action });
            navigate(link);
        },
        [navigate, listId, listName],
    );
};
