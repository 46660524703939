import React, { useState, Dispatch, SetStateAction } from 'react';

import { IconButton, InputAdornment, styled } from '@mui/material';

import { Button } from 'components/tokens/Button';
import { Icon } from 'components/tokens/Icon';
import { TextField } from 'components/tokens/TextField';

type MappingsSearchInputProps = {
    isLoading: boolean;
    value: string;
    onChange: Dispatch<SetStateAction<string>>;
};

const MappingsSearchInput: React.FC<MappingsSearchInputProps> = ({ isLoading, value, onChange }) => {
    const [isInputShown, setInputShown] = useState(false);

    const handleSwitchInputVisibility = () => {
        setInputShown((prev) => !prev);
        // todo: 🤔🤔🤔
        onChange('');
    };

    return (
        <Container>
            {isInputShown ? (
                <TextField
                    compact
                    autoFocus
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    sx={{ width: '300px' }}
                    iconBehavior="showAlways"
                    placeholder="Search..."
                    icon="Search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    disableRipple
                                    data-testid="close-button"
                                    onClick={handleSwitchInputVisibility}
                                >
                                    <Icon type="CloseSmall" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            ) : (
                <Button
                    disabled={isLoading}
                    data-testid="search-button"
                    variant="tertiary"
                    startIcon={<Icon type="Search" />}
                    onClick={handleSwitchInputVisibility}
                />
            )}
        </Container>
    );
};

const Container = styled('div')({
    marginLeft: 'auto',
});

export { MappingsSearchInput };
