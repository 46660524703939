import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronRight: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M8.28796 12L14.298 18.01L15.712 16.596L11.112 11.996L15.712 7.39599L14.298 5.98999L8.28796 12Z" />
    </SvgIcon>
);

export default ChevronRight;
