import React from 'react';

import { Tooltip } from '@mui/material';

import { Trigger } from 'api/types/Trigger';
import { getCRMName } from 'components/modules/connectors/utils';
import { Button } from 'components/tokens/Button';
import { Dialog } from 'components/tokens/Dialog';
import { Icon } from 'components/tokens/Icon';
import { Link } from 'components/tokens/Link';
import { Typography } from 'components/tokens/Typography';

export interface TriggersWarningProps {
    triggers: Trigger[];
    onClose: () => void;
    onConfirm: () => void;
    onDuplicate: () => void;
}

export const TriggersWarning: React.FC<TriggersWarningProps> = ({ triggers, onClose, onConfirm, onDuplicate }) => {
    const destinations = [
        ...new Set(
            triggers.flatMap((trigger) =>
                trigger.workflow_settings.destinations.map((destination) => destination.destination_type),
            ),
        ),
    ];

    const destinationsString = destinations
        .map((destination, idx) => {
            let separator = '';
            if (idx !== 0) {
                if (idx === destinations.length - 1) {
                    separator = ' and';
                } else {
                    separator = ' ,';
                }
            }
            return `${separator} ${destination === 'email' ? 'Email' : getCRMName(destination)}`;
        })
        .join('');

    return (
        <Dialog
            open
            onClose={onClose}
            cancelLabel="Cancel"
            customizedCancel={
                <Button onClick={onDuplicate} variant="flat" startIcon={<Icon type="Duplicate" />}>
                    Duplicate list
                </Button>
            }
            customizedConfirm={<Button onClick={onConfirm}>Allow changes</Button>}
            title={`Changes made to this list will apply to its ${destinationsString} trigger${
                triggers.length > 1 ? 's' : ''
            }`}
        >
            <Typography color="subtle" sx={{ marginTop: 1 }}>
                Filter changes for this list will apply to{' '}
                <Tooltip
                    title={
                        <ul>
                            {triggers.map((trigger) => (
                                <li key={trigger.id}>&middot; {trigger.name}</li>
                            ))}
                        </ul>
                    }
                >
                    <Link href="#" sx={{ color: 'text-dark' }} onClick={(e) => e.preventDefault()}>
                        {triggers.length} active trigger{triggers.length > 1 ? 's' : ''}
                    </Link>
                </Tooltip>{' '}
                set up to send events to {destinationsString} automatically
            </Typography>
        </Dialog>
    );
};

export default TriggersWarning;
