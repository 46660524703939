import { FC } from 'react';

import Frame from 'components/tokens/Frame';

import { EmployeesTableSectionBody, EmployeesTableSectionProps } from './EmployeesTableSectionBody';
import { EmployeesTableSectionHeader } from './EmployeesTableSectionHeader';
import { EmployeesTableSectionHeaderProps } from './types';

export const EmployeesTableSection: FC<
    EmployeesTableSectionProps & Omit<EmployeesTableSectionHeaderProps, 'isEmpty'>
> = (props) => (
    <Frame padding="large" sx={{ width: '100%' }}>
        <EmployeesTableSectionHeader {...props} isEmpty={props.allEmployees?.length === 0} />
        <EmployeesTableSectionBody {...props} />
    </Frame>
);
