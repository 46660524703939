import { FC } from 'react';

import Popover from '@mui/material/Popover';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useIntl } from 'react-intl';

import Typography from 'components/tokens/Typography';
import { brandPalette } from 'design/theme/colors';

import { RangePicker, RangePickerProps } from './components/RangePicker';
import { Values } from './types';

export interface RangeSelectProps extends Omit<RangePickerProps, 'value' | 'onRangeChange'> {
    additionalData?: React.ReactNode;
    // converts nulls to +-Infinity to work with filters
    value: [number | null, number | null];
    onRangeChange: (values: [number | null, number | null]) => void;
}

const RangeSelect: FC<RangeSelectProps> = ({
    onRangeChange,
    value,
    options,
    min = 0,
    max = 100,
    step = 1,
    noInputs = false,
    size = 'small',
    additionalData,
}) => {
    const intl = useIntl();
    const popupState = usePopupState({ variant: 'popover', popupId: 'range-select' });
    if (!Array.isArray(value)) {
        throw new Error(`Expected array: ${value}`);
    }

    const rangeValue = [value[0] === null ? -Infinity : value[0], value[1] === null ? Infinity : value[1]];

    const getLabel = (value: Values) => {
        let low = value[0];
        let high = value[1];
        let lowInfinity = false;
        let highInfinity = false;

        if (value[0] === -Infinity && options) {
            lowInfinity = true;
            low = options[0];
            // if first option is -infinity, show `<{next value}`
            if (options[0] === -Infinity) {
                low = options[1];
            }
        }
        if (value[1] === Infinity && options) {
            highInfinity = true;
            high = options[options.length - 1];
            if (options[options.length - 1] === Infinity) {
                high = options[options.length - 2];
            }
        }
        return `${lowInfinity ? '<' : ''}${intl.formatNumber(low, { notation: 'compact' })} - ${
            highInfinity ? '>' : ''
        }${intl.formatNumber(high, { notation: 'compact' })}`;
    };

    return (
        <div>
            <Typography
                color="dark"
                sx={{
                    fontSize: 14,
                    border: `1px solid ${brandPalette.subtleLight}`,
                    borderRadius: '4px',
                    height: size === 'small' ? 40 : 56,
                    width: '100%',
                    backgroundColor: 'background.paper',
                    paddingLeft: '12px',
                    paddingTop: size === 'small' ? 1 : 2,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    '&:hover': {
                        border: `1px solid ${brandPalette.onyx}`,
                        cursor: 'text',
                    },
                }}
                {...bindTrigger(popupState)}
                role="button"
            >
                {getLabel(rangeValue)}
            </Typography>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                elevation={9}
                PaperProps={{
                    sx: { padding: '20px', height: 250, width: 400 },
                }}
            >
                <RangePicker
                    onRangeChange={(value) => {
                        const from = value[0] === -Infinity ? null : value[0];
                        const to = value[1] === Infinity ? null : value[1];
                        onRangeChange([from, to]);
                    }}
                    value={rangeValue}
                    options={options}
                    min={min}
                    max={max}
                    noInputs={noInputs}
                    step={step}
                />
                {additionalData}
            </Popover>
        </div>
    );
};

export default RangeSelect;
