import { memo } from 'react';

import { Box, useMediaQuery } from '@mui/material';

import { CrmMenu } from 'components/modules/connectors/crm-export';
import { ProfileExportedToCRM } from 'components/modules/connectors/types';
import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import NumberDataPoint, { NumberDataPointProps } from 'components/tokens/NumberDataPoint';
import Typography from 'components/tokens/Typography';
import { Theme } from 'design/theme';

import CompanyDescription from '../CompanyDescription';
import AddToStaticList from './components/AddToStaticList';
import SidebarHeader, { SidebarHeaderProps } from './SidebarHeader';

export type CompanyProfileSidebarCardProps = Omit<SidebarHeaderProps, 'mode' | 'showCRMInfoInHeader'> & {
    showBottomActions?: boolean;
    description?: string;
    numberDataPoints?: NumberDataPointProps[];
    isGroupHQ?: boolean;
    groupHQHref?: string;
    frame?: boolean;
    showSections?: 'top' | 'bottom' | 'both';
    wide?: boolean;
    exportedProfileToCRMs?: ProfileExportedToCRM[][];
    onGroupHQClick?: (event: React.MouseEvent) => void;
};

export const CompanyProfileSidebarCard: React.FC<CompanyProfileSidebarCardProps> = memo(
    ({
        id,
        profileType: type,
        name,
        country,
        city,
        description,
        statusClass = 'active',
        status = 'active',
        logo,
        industryTags = [],
        numberDataPoints = [],
        isGroupHQ = false,
        groupHQHref,
        frame = true,
        showSections = 'both',
        showHeaderActions = false,
        showBottomActions = false,
        wide = false,
        exportedProfileToCRMs,
        loading = false,
        onIndustryTagClick,
        onSendToCRMClick,
        onGroupHQClick,
    }) => {
        const showCRMInfoInHeader = useMediaQuery<Theme>((theme) => theme.breakpoints.down('profileSidebar'));

        const topVisible = showSections === 'both' || showSections === 'top';
        const bottomVisible = showSections === 'both' || showSections === 'bottom';

        return (
            <Frame
                padding={frame ? 'large' : 'none'}
                elevation={frame ? 1 : 0}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: frame ? 'center' : 'flex-start',
                    textAlign: frame ? 'center' : 'left',
                    borderRadius: frame ? undefined : 0,
                    position: 'relative',
                    overflow: 'visible',
                }}
            >
                {topVisible && (
                    <SidebarHeader
                        loading={loading}
                        logo={logo}
                        name={name}
                        city={city}
                        country={country}
                        industryTags={industryTags}
                        status={status}
                        statusClass={statusClass}
                        mode={frame ? 'vertical' : 'horizontal'}
                        onIndustryTagClick={onIndustryTagClick}
                        showHeaderActions={showHeaderActions}
                        id={id}
                        profileType={type}
                        showCRMInfoInHeader={showCRMInfoInHeader}
                        exportedProfileToCRMs={exportedProfileToCRMs}
                        onSendToCRMClick={onSendToCRMClick}
                    />
                )}
                {bottomVisible && (
                    <>
                        {(description || loading) && (
                            <CompanyDescription
                                skeleton={loading && !description}
                                description={description}
                                wide={wide}
                                sx={{ marginBottom: 2 }}
                            />
                        )}
                        {isGroupHQ && frame && (
                            <Typography variant="small" weight="semibold">
                                {`${name} is a `}
                                <Link href={groupHQHref} onClick={onGroupHQClick}>
                                    Group HQ
                                </Link>
                            </Typography>
                        )}
                        <Box
                            sx={{
                                width: '100%',
                                display: 'grid',
                                gridTemplateColumns: `repeat(${frame && wide ? numberDataPoints.length : 6}, 1fr)`,
                                gap: 2,
                            }}
                        >
                            {numberDataPoints.map((props) => (
                                <Box
                                    key={props.label?.toString()}
                                    sx={{
                                        gridColumn: wide ? 'span 1' : 'span 3',
                                    }}
                                >
                                    <NumberDataPoint
                                        align={frame ? 'center' : 'left'}
                                        variant={wide ? 'small' : 'medium'}
                                        labelPosition="bottom"
                                        {...props}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
                {showBottomActions && (
                    <div style={{ display: 'flex', marginTop: '32px' }}>
                        <AddToStaticList id={id} showButtonText />
                        <CrmMenu
                            anchorButton={
                                <Button
                                    variant="tertiary"
                                    startIcon={<Icon fontSize="mini" type="Export" />}
                                    sx={{ marginRight: 1 }}
                                >
                                    Send company
                                </Button>
                            }
                            handleClick={(crm) => onSendToCRMClick?.(crm)}
                            disablePortal={false}
                        />
                    </div>
                )}
            </Frame>
        );
    },
);

export default CompanyProfileSidebarCard;
