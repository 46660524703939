import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FolderMinus: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 60 55" {...props}>
        <path
            d="M53.646 54.2786H6.04661C2.76055 54.2786 0.0966797 51.6147 0.0966797 48.3287V6.67918C0.0966797 3.39312 2.76055 0.729248 6.04661 0.729248H23.8964C24.6853 0.729248 25.4419 1.04296 25.9997 1.60091L31.078 6.67918H53.646C56.9321 6.67918 59.596 9.34305 59.596 12.6291V48.3287C59.596 51.6147 56.9321 54.2786 53.646 54.2786ZM6.04661 12.6291V48.3287H53.646V12.6291H6.04661ZM41.7462 33.4539H17.9465V27.5039H41.7462V33.4539Z"
            fill="#E1E1E1"
        />
    </SvgIcon>
);

export default FolderMinus;
