import { useQuery } from '@tanstack/react-query';

import { filterCompanies } from 'api/filterCompanies';
import { organizationProfileType } from 'api/types/Organization';
import BarTile from 'components/features/CRMInsights/Tiles/BarTile';
import { DEFAULT_DATA, defaultMinWidth } from 'components/features/CRMInsights/utils';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useListsContext } from 'contexts/ListsContext';
import { OrganizationResultAPIResponse } from 'contexts/types/FilterCompanies';

import { TileProps } from '../NewVehicles';

const OwningCompanyTile: React.FC<TileProps> = ({ data, valueRange, valueField, businessId }) => {
    const axios = useAxiosContext();
    const { database } = useListsContext();

    const ownerData = data?.find((row) => row.id === 'owner_business_id');

    const { data: ownerCompanies } = useQuery({
        queryKey: ['vehicle-owners', database, businessId],
        queryFn: () =>
            filterCompanies(axios, {
                database: database || 'FI',
                query: {
                    '?ALL': [
                        {
                            '?IN': {
                                business_id: ownerData?.result[0].buckets.map((bucket) => bucket.value as string) || [],
                            },
                        },
                    ],
                },
                use_filters_v2: 'true',
                limit: 20,
                offset: 0,
                order: 'vainu_score',
                fields: ['business_id', 'name'],
            }),
        select: (data) =>
            (data as OrganizationResultAPIResponse[]).map((company) => ({
                ...company,
                id: company.business_id,
                profileType: organizationProfileType,
            })),
        enabled: !!database && !!ownerData,
    });

    return (
        <BarTile
            title="Owning company"
            data={
                ownerData?.result[0].buckets
                    .filter((bucket) => bucket.value)
                    .map((bucket) => ({
                        value: bucket[valueField],
                        label: ownerCompanies?.find((row) => row.id === bucket.value)?.name || (bucket.value as string),
                        valueLabel: valueRange ? `${bucket.count_min}-${bucket.count_max}` : bucket[valueField],
                    })) || DEFAULT_DATA
            }
            total={(ownerData?.result[0].buckets[0]?.[valueField] as number) || 0}
            sx={{ minWidth: defaultMinWidth, maxWidth: 'max(600px, 50%)' }}
            firstColumn={{ label: 'Company', width: 140 }}
            lastColumn={{ label: 'Vehicles' }}
            hidePercent
            percentHeaderLabel=""
            showCount
        />
    );
};

export default OwningCompanyTile;
