import React, { useState } from 'react';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Select from 'components/tokens/select-components/Select';

import { getMergedLabel } from '../common/optionsUtils';
import { mergeKeys } from './../../common/utils';
import { getSameFiltersState } from './../../filters/utils';
import { FilterGroup, FilterGroupProps, FilterID, GroupOptionRaw, GroupValues, NodeValue } from './../../FilterTypes';
import { getGroupFilters, useFilterGroupOptions } from './../common/groupUtils';
import { useGroupActions, useGroupNodes } from './../common/useGroup';
import FilterGroupTemplate from './../FilterGroupTemplate';

const rawOptions: GroupOptionRaw[] = [
    {
        value: FilterID.company_name,
    },
    {
        value: FilterID.legal_entity,
        label: 'Legal entity',
    },
    {
        value: FilterID.employer_registration_date,
        databases: ['NO', 'DK', 'SE'],
    },
    {
        value: FilterID.company_registration_date,
    },
    {
        value: FilterID.foundation,
    },
];

const createNewGroup = (value: NodeValue) =>
    ({
        [FilterOperator.MATCH]: {
            [GroupValues.basic]: { [FilterOperator.ALL]: [value] },
        },
    }) as NodeValue;

const options = rawOptions.map((o) => ({ ...o, label: getMergedLabel(o) }));

const config: FilterGroup = {
    group: GroupValues.basic,
    label: 'Basic',
    options,
    filterUUIDs: [],
    logic: {},
    render: (props: FilterGroupProps) => <BasicGroup {...props} />,
    getFilters: getGroupFilters,
    createNewGroup,
};

export const basicGroupDefinition: Record<GroupValues.basic, FilterGroup> = {
    [GroupValues.basic]: config,
};

export const BasicGroup: React.FC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    removeGroup,
    ids = [],
    addNewGroup,
    updateGroup,
    updateGroups,
    disabled = false,
}) => {
    const [addRow, setAddRow] = useState(false);
    const [isFilterAdded, setIsFilterAdded] = useState(false);

    const filterGroups = useGroupNodes(ids);
    const { activeFilters, filteredOptions } = useFilterGroupOptions({ filterGroups, options });
    const { handleAddFilter, handleFilterRemove, handleApplyChanges, keys } = useGroupActions({
        applyChanges,
        removeGroup,
        addNewGroup,
        updateGroup,
        updateGroups,
        createNewGroup,
        filterGroups,
        options: filteredOptions,
    });

    const renderFilters = () => {
        return ids.map((id) =>
            filterGroups[id].filters.map((filter, index) => {
                const filterDefinition = filter.definition;
                const sameFiltersState = getSameFiltersState(activeFilters, filter);

                return filterDefinition
                    ? filterDefinition.render({
                          key: keys[mergeKeys(id, filter.id)],
                          uuid: filterDefinition.id,
                          filter,
                          groupUUID: config.group,
                          removeFilter: () => handleFilterRemove(id, index),
                          sameFiltersState: sameFiltersState,
                      })
                    : null;
            }),
        );
    };

    const addRowSelect = (
        <Select
            placeholder="Select"
            width="100%"
            multiple={false}
            value={null}
            autoFocus
            openOnFocus
            sx={{ gridArea: 'add' }}
            options={filteredOptions || []}
            onValueChange={(value) => {
                if (Array.isArray(value)) {
                    throw new Error('should not be array value');
                }
                if (typeof value === 'number') {
                    throw new Error('should not be number value');
                }
                if (typeof value === 'string') {
                    throw new Error('should not be string value');
                }
                if (!value) {
                    return;
                }
                handleAddFilter(value.value as FilterID);
                setAddRow(false);
                setIsFilterAdded(true);
            }}
        />
    );

    const addRowButton = (
        <Button
            variant="tertiary"
            size="small"
            onClick={() => setAddRow(true)}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            AND
        </Button>
    );

    const renderedFilters = renderFilters();

    const props = {
        label: config.label,
        filters: renderedFilters,
        addFilter: handleAddFilter,
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        addRowSelect,
        addRowButton,
        addRow,
        isFilterAdded,
        setIsFilterAdded,
        disabled,
    };
    return <FilterGroupTemplate {...props} />;
};
