const trafficClasses: { classMax: number; label: string }[] = [
    { classMax: 1000, label: 'Top 1K' },
    { classMax: 10000, label: 'Top 10K' },
    { classMax: 100000, label: 'Top 100K' },
    { classMax: 500000, label: 'High' },
    { classMax: 2000000, label: 'Medium' },
    { classMax: 4000000, label: 'Med–Low' },
    { classMax: Number.MAX_SAFE_INTEGER, label: 'Low' },
];

export const getShortTrafficRanking = (ranking: number): string =>
    trafficClasses.find(({ classMax }) => classMax >= ranking)?.label ?? 'n/a';
