import { TreeRawData } from 'components/templates/Treeview';

const NORDIC_TREE_SELECT_FIELDS: TreeRawData = [
    ['ALL', 'All fields'],
    ['ALL.BASIC', 'Basic'],
    ['ALL.BASIC.Business ID', 'Business ID'],
    ['ALL.BASIC.Business name', 'Business name'],
    ['ALL.BASIC.Legal entity', 'Legal entity'],
    ['ALL.BASIC.Status', 'Status'],
    ['ALL.BASIC.Number of employees', 'Number of employees (latest official)'],
    ['ALL.BASIC.OrgSize', 'Estimated size (~employees)'],
    ['ALL.BASIC.Website', 'Website'],
    ['ALL.BASIC.Phone', 'Phone'],
    ['ALL.BASIC.Email', 'Email'],
    ['ALL.BASIC.Registration date', 'Registration date'],
    ['ALL.BASIC.Employer Registration date', 'Employer registration date'],
    ['ALL.BASIC.Industry', 'Industry'],
    ['ALL.BASIC.Main Industry', 'Main industry'],
    ['ALL.BASIC.Vainu Custom Industry (all)', 'Vainu Custom Industries (all)', '', '', 'vainu_custom_industries'],
    ['ALL.BASIC.Vainu Custom Industry (high class)', 'Vainu Custom Industries (high class)'],
    ['ALL.BASIC.Direct marketing denied', 'Direct marketing denied'],
    // ['ALL.BASIC.Link to Vainu', 'Link to Vainu'],
    // ['ALL.BASIC.Vainu score', 'Vainu score'],

    ['ALL.LOCATION', 'Location'],
    ['ALL.LOCATION.Address', 'Address (street)'],
    ['ALL.LOCATION.Postal', 'Postal'],
    ['ALL.LOCATION.City', 'City'],
    ['ALL.LOCATION.Visiting Address', 'Visiting address'],
    ['ALL.LOCATION.Visiting Postal', 'Visiting postal'],
    ['ALL.LOCATION.Visiting City', 'Visiting city'],
    ['ALL.LOCATION.Business Units', 'Business units', '', '', 'business_units'],

    ['ALL.FINANCIAL', 'Financial'],
    // ['ALL.FINANCIAL.Group Employee Count', 'Group Employee Count'],
    ['ALL.FINANCIAL.Revenue (latest)', 'Revenue (latest)'], // TODO: currency?
    ['ALL.FINANCIAL.Gross profit (latest)', 'Gross profit (latest)'],
    // ['ALL.FINANCIAL.Auditor', 'Auditor'],
    ['ALL.FINANCIAL.STATEMENTS', 'Financial statements', '', '', 'financial_statements'],
    ['ALL.FINANCIAL.STATEMENTS.Years', 'Years', '', '', 'financial_statements'],
    ['ALL.FINANCIAL.STATEMENTS.Accounting period start', 'Accounting period start', '', '', 'financial_statements'],
    ['ALL.FINANCIAL.STATEMENTS.Accounting period end', 'Accounting period end', '', '', 'financial_statements'],
    ['ALL.FINANCIAL.STATEMENTS.Revenue', 'Revenue', '', '', 'financial_statements'],
    ['ALL.FINANCIAL.STATEMENTS.Earnings', 'Earnings (net income)', '', '', 'financial_statements'],
    ['ALL.FINANCIAL.STATEMENTS.Total assets', 'Total assets', '', '', 'financial_statements'],
    ['ALL.FINANCIAL.STATEMENTS.Employee count', 'Employee count', '', '', 'financial_statements'],

    ['ALL.WEB_PROFILE', 'Web profile'],
    ['ALL.WEB_PROFILE.Technologies', 'Technologies'],
    ['ALL.WEB_PROFILE.Description', 'Description'],
    ['ALL.WEB_PROFILE.Website traffic rank', 'Website traffic rank'],
    ['ALL.WEB_PROFILE.Website traffic class', 'Website traffic class'],
    ['ALL.WEB_PROFILE.Languages', 'Languages'],
    ['ALL.WEB_PROFILE.Digitalization index', 'Digitalization index'],

    ['ALL.SOME', 'Social media'],
    ['ALL.SOME.LinkedIn Link', 'LinkedIn link'],
    ['ALL.SOME.Twitter Link', 'Twitter link'],
    ['ALL.SOME.Twitter followers', 'Twitter followers'],
    ['ALL.SOME.Youtube Link', 'Youtube link'],
    ['ALL.SOME.Facebook Link', 'Facebook link'],
    ['ALL.SOME.Social Media index', 'Social media index'],

    ['ALL.Contacts', 'Contacts', '', '', 'contacts'],

    ['ALL.GROUP', 'Group data'],
    ['ALL.GROUP.Group name', 'Group name'],
    ['ALL.GROUP.Subsidiary count', 'Subsidiary count'],
    ['ALL.GROUP.Organizations in countries', 'Organizations of countries'],
    ['ALL.GROUP.Parent organization name', 'Parent organization\nName'],
    ['ALL.GROUP.Parent organization country', 'Parent organization\nCountry'],
    ['ALL.GROUP.Parent organization Business ID', 'Parent organization\nBusiness ID'],
    ['ALL.GROUP.Group employee count', 'Group employee count'],
    ['ALL.GROUP.Group revenue', 'Group revenue'],
    ['ALL.GROUP.Level in group', 'Level in group'],
];

const NORDIC_CSV_EXPORT_ALWAYS_SELECTED_IDS = ['ALL.BASIC.Business ID', 'ALL.BASIC.Business name'];

const NORDIC_URL_PARAMS_BY_ITEM_ID = {
    'ALL.BASIC.Business name': 'name',
    'ALL.BASIC.Business ID': 'business_id',
    'ALL.BASIC.Legal entity': 'legal_entity',
    'ALL.BASIC.Status': 'official_status',
    'ALL.BASIC.Website': 'website',
    'ALL.BASIC.Phone': 'tel',
    'ALL.BASIC.Email': 'email',
    // 'registers.registration_date' is missing in NO, DK and SE so 'founded' is used
    'ALL.BASIC.Registration date': 'founded',
    'ALL.BASIC.Employer Registration date': 'basic_data_plus.employer_registration_date',
    'ALL.BASIC.Industry': 'official_industries.0.descriptions.en',
    'ALL.BASIC.Main Industry': 'official_industries.0.code_2_level_descriptions.en',
    'ALL.BASIC.Number of employees': 'financial_data.employee_count',
    'ALL.BASIC.OrgSize':
        'organization_size_indicators.indicator_type$linkedin_company_size.0.size_classes,organization_size_indicators.indicator_type$company_size_indicator_model.0.size_classes',
    'ALL.BASIC.Vainu Custom Industry (all)':
        'vainu_custom_industries.value,vainu_custom_industries.confidence_score,vainu_custom_industries.confidence_class',
    'ALL.BASIC.Vainu Custom Industry (high class)': 'vainu_custom_industries.confidence_class$high.value',
    'ALL.BASIC.Direct marketing denied': 'direct_marketing_denied',
    // 'ALL.BASIC.Link to Vainu': 'link_to_vainu',
    // 'ALL.BASIC.Vainu score': 'vainu_score',

    'ALL.LOCATION.Address': 'address.street',
    'ALL.LOCATION.Postal': 'address.postal_code',
    'ALL.LOCATION.City': 'address.city',
    'ALL.LOCATION.Visiting Address': 'visiting_address.street',
    'ALL.LOCATION.Visiting Postal': 'visiting_address.postal_code',
    'ALL.LOCATION.Visiting City': 'visiting_address.city',
    'ALL.LOCATION.Business Units':
        'business_units.visiting_address.street,business_units.visiting_address.city,business_units.visiting_address.postal_code,business_units.visiting_address.country',

    // 'ALL.FINANCIAL.Group Employee Count': 'concern_staff_number',
    'ALL.FINANCIAL.Revenue (latest)': 'financial_data.revenue',
    'ALL.FINANCIAL.Gross profit (latest)': 'financial_data.profit',
    // 'ALL.FINANCIAL.Auditor': 'financial_data.profit',

    'ALL.FINANCIAL.STATEMENTS.Years': 'financial_statements.years',
    'ALL.FINANCIAL.STATEMENTS.Accounting period start': 'financial_statements.accounting_period.start',
    'ALL.FINANCIAL.STATEMENTS.Accounting period end': 'financial_statements.accounting_period.end',
    'ALL.FINANCIAL.STATEMENTS.Revenue': 'financial_statements.income_statement.revenue.value',
    'ALL.FINANCIAL.STATEMENTS.Earnings': 'financial_statements.income_statement.earnings.value',
    'ALL.FINANCIAL.STATEMENTS.Total assets': 'financial_statements.balance_sheet.assets.total.value',
    'ALL.FINANCIAL.STATEMENTS.Employee count': 'financial_statements.employees.absolute_count',

    'ALL.WEB_PROFILE.Technologies': 'technology_data.$.name',
    'ALL.WEB_PROFILE.Description': 'website_data.websites.0.description,social_media.type$linkedin.0.description',
    'ALL.WEB_PROFILE.Website traffic rank': 'website_data.website_traffic_data.ranking',
    'ALL.WEB_PROFILE.Website traffic class': 'website_data.website_traffic_data.website_traffic_class',
    'ALL.WEB_PROFILE.Languages': 'technology_data.types$language.name',
    'ALL.WEB_PROFILE.Digitalization index': 'indexes.digitalization_index',

    'ALL.SOME.LinkedIn Link': 'social_media.type$linkedin.0.url',
    'ALL.SOME.Twitter Link': 'social_media.type$twitter.0.url',
    'ALL.SOME.Twitter followers': 'social_media.type$twitter.0.followers',
    'ALL.SOME.Youtube Link': 'social_media.type$youtube.0.url',
    'ALL.SOME.Facebook Link': 'social_media.type$facebook.0.url',
    'ALL.SOME.Social Media index': 'indexes.social_media_index',

    'ALL.Contacts': `contacts.first_name,contacts.last_name,contacts.full_name,contacts.email,contacts.phone,contacts.web_profiles.0.url,contacts.titles.0.title,contacts.titles.0.classifications.0.functional_area,contacts.titles.0.classifications.0.is_decision_maker,contacts.titles.1.title,contacts.titles.1.classifications.0.functional_area,contacts.titles.1.classifications.0.is_decision_maker,contacts.titles.2.title,contacts.titles.2.classifications.0.functional_area,contacts.titles.2.classifications.0.is_decision_maker`,

    'ALL.GROUP.Group name': 'group_data.name',
    'ALL.GROUP.Subsidiary count': 'group_data.subsidiary_count',
    'ALL.GROUP.Organizations in countries': 'group_data.organizations_in_countries',
    'ALL.GROUP.Parent organization name': 'group_data.parent_organization.name',
    'ALL.GROUP.Parent organization country': 'group_data.parent_organization.country',
    'ALL.GROUP.Parent organization Business ID': 'group_data.parent_organization.business_id',
    'ALL.GROUP.Group employee count': 'group_data.financial_data.employees.absolute_count',
    'ALL.GROUP.Group revenue':
        'group_data.financial_data.revenue.value,group_data.financial_data.revenue.currency_code',
    'ALL.GROUP.Level in group': 'level_in_group',
};

const GLOBAL_TREE_SELECT_FIELDS: TreeRawData = [
    ['ALL', 'All fields'],
    ['ALL.Domain', 'Domain'],
    ['ALL.HQCountry', 'HQ country'],
    ['ALL.BusinessUnits', 'Business units', '', '', 'business_units'],
    ['ALL.CountriesOfOperations', 'Countries of operations'],
    [
        'ALL.VCI',
        'Vainu Custom Industries (all)',
        '',
        'Exports all Vainu Custom Industries with company-specific confidence scores and class',
        'vainu_custom_industries',
    ],
    [
        'ALL.VCIClassHigh',
        'Vainu Custom Industries (high class)',
        '',
        'Exports all Vainu Custom Industries that are above a high confidence threshold',
    ],
    ['ALL.OrgSize', 'Company size'],
    ['ALL.Desc', 'Descriptions'],
    ['ALL.SocialMediaLink', 'Social media links'],
    ['ALL.WebsiteTraffic', 'Website traffic'],
    ['ALL.DigitalizationIndex', 'Digitalization index'],
    ['ALL.OfficeLocationCount', 'Office location count'],
    ['ALL.Technologies', 'Technologies'],
    ['ALL.WebsiteLanguages', 'Website languages'],
];

const GLOBAL_CSV_EXPORT_ALWAYS_SELECTED_IDS = ['ALL.Domain'];

const GLOBAL_URL_PARAMS_BY_ITEM_ID = {
    'ALL.Domain': 'website',
    'ALL.HQCountry': 'business_units.types$hq.0.visiting_address.country',
    'ALL.BusinessUnits':
        'business_units.visiting_address.street,business_units.visiting_address.city,business_units.visiting_address.postal_code,business_units.visiting_address.country',
    'ALL.OfficeLocationCount': 'count.business_units',
    'ALL.CountriesOfOperations': 'countries',
    'ALL.VCI':
        'vainu_custom_industries.value,vainu_custom_industries.confidence_score,vainu_custom_industries.confidence_class',
    'ALL.VCIClassHigh': 'vainu_custom_industries.confidence_class$high.value',
    'ALL.Desc': 'website_data.websites.0.description,social_media.type$linkedin.0.description',
    'ALL.OrgSize':
        'organization_size_indicators.indicator_type$linkedin_company_size.0.size_classes,organization_size_indicators.indicator_type$company_size_indicator_model.0.size_classes',
    'ALL.Technologies': 'technology_data.$.name',
    'ALL.WebsiteLanguages': 'technology_data.types$language.name',
    'ALL.WebsiteTraffic':
        'website_data.website_traffic_data.ranking,website_data.website_traffic_data.website_traffic_class',
    'ALL.SocialMediaLink':
        'social_media.type$linkedin.0.url,social_media.type$facebook.0.url,social_media.type$instagram.0.url,social_media.type$twitter.0.url,social_media.type$youtube.0.url',
    'ALL.DigitalizationIndex': 'indexes.digitalization_index',
};

const LABELS_BY_ITEM_ID = {
    'ALL.BusinessUnits': 'Business unit\nAddress,Business unit\nCity,Business unit\nPostal code,Business unit\nCountry',
    'ALL.LOCATION.Business Units':
        'Business unit\nAddress,Business unit\nCity,Business unit\nPostal code,Business unit\nCountry',
    'ALL.VCI': 'Vainu Custom Industries,VCI confidence score,VCI confidence class',
    'ALL.BASIC.Vainu Custom Industry (all)': 'Vainu Custom Industries,VCI confidence score,VCI confidence class',
    'ALL.VCIClassHigh': 'Vainu Custom Industries\nHigh class',
    'ALL.Desc': 'Description\nWebsite,Description\nLinkedIn',
    'ALL.WEB_PROFILE.Description': 'Description\nWebsite,Description\nLinkedIn',
    'ALL.OrgSize': 'Company size\nLinkedIn,Company size\nVainu ML model',
    'ALL.BASIC.OrgSize': 'Company size\nLinkedIn,Company size\nVainu ML model',
    'ALL.WebsiteTraffic': 'Website traffic rank,Website traffic class',
    'ALL.SocialMediaLink': 'LinkedIn,Facebook,Instagram,Twitter,Youtube',
    'ALL.Contacts':
        'Contact\nFirst name,Contact\nLast name,Contact\nFull name,Contact\nEmail,Contact\nPhone,Contact\nLinkedIn,Contact\nTitle (1),Contact\nFunctional area (1),Contact\nDecision maker (1),Contact\nTitle (2),Contact\nFunctional area (2),Contact\nDecision maker (2),Contact\nTitle (3),Contact\nFunctional area (3),Contact\nDecision maker (3)',
    'ALL.GROUP.Group revenue': 'Group revenue,Group revenue currency',
};

const DATA_FORMATS = [
    { value: 'csv', label: 'CSV' },
    { value: 'json', label: 'JSON' },
];

export {
    GLOBAL_TREE_SELECT_FIELDS,
    GLOBAL_URL_PARAMS_BY_ITEM_ID,
    DATA_FORMATS,
    GLOBAL_CSV_EXPORT_ALWAYS_SELECTED_IDS,
    LABELS_BY_ITEM_ID,
    NORDIC_TREE_SELECT_FIELDS,
    NORDIC_CSV_EXPORT_ALWAYS_SELECTED_IDS,
    NORDIC_URL_PARAMS_BY_ITEM_ID,
};
