import React from 'react';

const RedirectToSvg: React.FC<React.SVGProps<SVGSVGElement>> = ({ color = '', ...props }) => {
    return (
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.5 10.5522H1.71687C0.935326 10.5522 0.301758 9.96656 0.301758 9.24408V2.04921C0.301758 1.32674 0.935326 0.741054 1.71687 0.741054H4.5471V2.04921H1.71687V9.24408H9.5V6.62777H10.9151V9.24408C10.9151 9.96656 10.2815 10.5522 9.5 10.5522ZM5.74995 6.43612L4.75229 5.51125L9.20495 1.39513H6.66977V0.0869751H11.6227V4.66553H10.2076V2.32065L5.74995 6.43612Z"
                fill={color || '#2F80ED'}
            />
        </svg>
    );
};

export default RedirectToSvg;
