import { Box } from '@mui/material';

import { injectHighlightTag } from './utils';

export type SearchHighlightProps = { text?: string; searchTerm?: string };
export const SearchHighlight: React.FC<SearchHighlightProps> = ({ text, searchTerm }) => {
    return (
        <Box sx={{ em: { fontStyle: 'normal', backgroundColor: 'highlight.searchText', fontWeight: 500 } }}>
            {injectHighlightTag(text, searchTerm)}
        </Box>
    );
};

export default SearchHighlight;
