import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CustomInputSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.33744 16.2181L4.78244 12.6625L13.7372 3.70716C14.1277 3.31662 14.7609 3.31661 15.1514 3.70714L17.2928 5.84853C17.6834 6.23905 17.6834 6.87222 17.2928 7.26274L8.33744 16.2181ZM3.14884 17.2623C3.07812 17.6127 3.38729 17.922 3.73773 17.8513L7.45369 17.1025L3.89869 13.5469L3.14884 17.2623Z"
        />
        <path
            xmlns="http://www.w3.org/2000/svg"
            d="M17.9999 21H15.9999V18H12.9999V16H15.9999V13H17.9999V16H20.9999V18H17.9999V21Z"
        />
    </SvgIcon>
);

export default CustomInputSvg;
