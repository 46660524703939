import { CRMBulkExportAsyncProcess } from 'api/types/UserAsyncProcess';

export const errorTypes: CRMBulkExportAsyncProcess['error'][] = [
    'BAD_REQUEST',
    // 'DUPLICATE_FOUND',
    'AUTH_ERROR',
    'MISSING_PERMISSION',
    'INTERNAL_ERROR',
    'USER_MESSAGE',
    'INTEGRATION_ERROR',
];

export const errorTitle = (error: CRMBulkExportAsyncProcess['error']) => {
    switch (error) {
        case 'BAD_REQUEST':
            return 'Data mapping issue';
        case 'AUTH_ERROR':
            return 'Authentication issue';
        case 'MISSING_PERMISSION':
            return 'Permission issue';
        case 'INTERNAL_ERROR':
        case 'USER_MESSAGE':
        case 'INTEGRATION_ERROR':
        default:
            return 'We couldn’t complete this';
    }
};
