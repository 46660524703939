import React, { useState } from 'react';

import { TableBody, TableCell, TableHead } from '@mui/material';

import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import TextOnTruncate from 'components/tokens/TextOnTruncate';
import { formatDate } from 'utilities/date';

import { Order } from '..';
import { getComparator, stableSort } from '../utils';
import { TableCellFirstColumn } from './TableCell/TableCellFirstColumn';
import { TableCellSecondColumn } from './TableCell/TableCellSecondColumn';
import {
    StyledMuiTableContainer,
    StyledMuiTable,
    StyledMuiTableRow,
    StyledMuiTableSortLabel,
    StyledMuiTablePagination,
} from './tableStyled';

export type License = Partial<{
    license_number: string;
    license_type: string;
    start_date: string;
    end_date: string;
    additional_information: string;
    dynamic_data: Record<string, string>;
    /* {
        building_heating_system: string;
        energy_source_2_weighted_yearly_energy: string;
        public_building: string;
        energy_source_2_weighted_square_meter_yearly_energy: string;
        energy_certification_id: string;
        building_purpose: string;
        building_name: string;
        city: string;
        energy_source_1_standard_bought_energy: string;
        building_class: string;
        energy_source_1_weighted_square_meter_yearly_energy: string;
        energy_source_3_weighted_yearly_energy: string;
        energy_license_ending_date: string;
        energy_certification_version: string;
        energy_class: string;
        energy_source_3_weighted_square_meter_yearly_energy: string;
        warmed_area_size_squaremeter: string;
        energy_license_link: string;
        energy_source_2_standard_bought_energy: string;
        energy_class_level: string;
        address: string;
        postal: string;
        energy_source_3_standard_bought_energy: string;
        building_id: string;
        energy_source_2: string;
        energy_source_3: string;
        energy_source_1: string;
        building_ventilation_system: string;
        building_construction_year: string;
        energy_source_1_weighted_yearly_energy: string;
        new_building: string;
        energy_license_created_date: string;
        energy_license_issuing_company: string;
    }; */
}>;

enum LicenseSortField {
    license_type = 'license_type',
    license_number = 'license_number',
    start_date = 'start_date',
    end_date = 'end_date',
}

export type LicenseSortFieldStrings = keyof typeof LicenseSortField;

export type LicenseSortValues = {
    id: LicenseSortFieldStrings;
    label: string;
};

type LicenseTableProps = {
    tableData: License[];
    tableHeight?: number;
};

type LicenseHeaders = LicenseSortValues[];

const licenseHeaders: LicenseHeaders = [
    {
        id: 'license_type',
        label: 'License Type',
    },
    {
        id: 'license_number',
        label: 'License Number',
    },
    {
        id: 'start_date',
        label: 'Start date',
    },
    {
        id: 'end_date',
        label: 'End date',
    },
];

const LicenseTable: React.VFC<LicenseTableProps> = ({ tableData }) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<LicenseSortFieldStrings>('license_number');
    const [page, setPage] = useState(0);

    const handleRequestSort = (property: LicenseSortFieldStrings) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    return (
        <Frame title={`Licenses (${tableData.length})`}>
            <StyledMuiTableContainer>
                <StyledMuiTable>
                    <TableHead>
                        <StyledMuiTableRow>
                            {licenseHeaders.map((header) => {
                                return (
                                    <TableCell key={header.id} sortDirection={orderBy === header.id ? order : false}>
                                        <StyledMuiTableSortLabel
                                            active={orderBy === header.id}
                                            direction={order}
                                            onClick={handleRequestSort(header.id)}
                                        >
                                            {header.label}
                                            {orderBy === header.id && (
                                                <Icon
                                                    sx={{ marginLeft: '4px' }}
                                                    fontSize="mini"
                                                    type={order === 'asc' ? 'ArrowShortUp' : 'ArrowShortDown'}
                                                />
                                            )}
                                        </StyledMuiTableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </StyledMuiTableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(tableData, getComparator(order, orderBy))
                            .slice(page * 7, page * 7 + 7)
                            .map((row: License) => {
                                return (
                                    <StyledMuiTableRow key={row.license_number}>
                                        <TableCellFirstColumn additionalData={row.additional_information}>
                                            <TextOnTruncate
                                                variant="small"
                                                weight="semibold"
                                                content={row.license_type || '-'}
                                                maxLength={130}
                                            />
                                        </TableCellFirstColumn>
                                        <TableCellSecondColumn>{row.license_number || '-'}</TableCellSecondColumn>
                                        <TableCell>{row.start_date ? formatDate(row.start_date) : '-'}</TableCell>
                                        <TableCell>{row.end_date ? formatDate(row.end_date) : '-'}</TableCell>
                                    </StyledMuiTableRow>
                                );
                            })}
                    </TableBody>
                </StyledMuiTable>
            </StyledMuiTableContainer>
            <StyledMuiTablePagination
                rowsPerPage={7}
                rowsPerPageOptions={[7]}
                component="div"
                page={page}
                count={tableData.length}
                onPageChange={handlePageChange}
            />
        </Frame>
    );
};

export default LicenseTable;
