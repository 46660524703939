import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Select, { SelectOption } from 'components/tokens/select-components/Select';

import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterProps,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, valueToText } from '../utils';

const FIELD = 'concern#parent_rel';

export type TValues = { [FIELD]: number | undefined };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.group_is_subsidiary,
    label: 'Parent / Subsidiary',
    defaultOperator: FilterOperator.EQ,
    initValue: { [FilterOperator.EQ]: { [FIELD]: undefined } },
};

export const groupIsSubsidiaryOptions = [
    { label: 'Parent', value: 3 },
    { label: 'Subsidiary', value: 2 },
    { label: 'No subsidiaries', value: 1 },
    { label: 'Has subsidiaries', value: 4 },
    { label: 'Parent or no subsidiaries', value: 5 },
];

export const groupIsSubsidiaryFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.group_structure,
        label: config.label,
        fields: config.fields,
        selectableOnce: true,
        outsideGroup: true,
        render: (props: FilterProps<TValues>) => <GroupIsSubsidiary {...props} />,
        valueToText: valueToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.EQ].map((value) => formatOperatorValue(value as OperatorValue));

const GroupIsSubsidiary: React.VFC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);

    const fullValuePath = `${filter.path}.${filter.valuePath}`;

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;

                    const selectedOption =
                        groupIsSubsidiaryOptions.find((item) => value === (item.value as number)) || null;

                    return (
                        <Select
                            placeholder="Select"
                            multiple={false}
                            width="100%"
                            options={groupIsSubsidiaryOptions}
                            value={selectedOption}
                            onValueChange={(option) => onChange((option as SelectOption).value)}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
