import React, { useState, useEffect, createContext } from 'react';

import { TabEnum } from '../types';
import { CrmName, CRMObject } from '../types/Crm';

export interface ConnectorTabContextProps {
    crm: CrmName;
    openDrawer: boolean;
    defaultTab: TabEnum;
    openCompanyMatchingAdvanceMenu?: boolean;
    closeCompanyMatchingAdvanceMenu?: () => void;
    onDrawerClose?: () => void;
}
const ConnectorTabContext = createContext<ConnectorTabContextProps | undefined>(undefined);

const ConnectorTabProvider: React.FC<{
    crm: CrmName;
    tab: TabEnum;
    open: boolean;
    object?: CRMObject;
    openCompanyMatchingAdvanceMenu?: boolean;
    closeCompanyMatchingAdvanceMenu?: () => void;
    handleClose: () => void;
    children?: React.ReactNode;
}> = ({ crm, open, tab, object, openCompanyMatchingAdvanceMenu, handleClose, children }) => {
    const [openAdvanceDropdown, setOpenAdvanceDropdown] = useState(false);

    useEffect(() => {
        if (openCompanyMatchingAdvanceMenu) {
            setOpenAdvanceDropdown(true);
        }
    }, [openCompanyMatchingAdvanceMenu]);
    const handleCompanyMatchingAutoSyncMenuClose = () => {
        setOpenAdvanceDropdown(false);
    };

    return (
        <ConnectorTabContext.Provider
            value={{
                crm,
                openDrawer: open,
                defaultTab: tab,
                openCompanyMatchingAdvanceMenu: openAdvanceDropdown,
                closeCompanyMatchingAdvanceMenu: handleCompanyMatchingAutoSyncMenuClose,
                onDrawerClose: handleClose,
            }}
        >
            {children}
        </ConnectorTabContext.Provider>
    );
};

export { ConnectorTabProvider, ConnectorTabContext };
