import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HubspotCompanySvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.96781 9.9943H9.15704C9.44948 9.9943 9.68655 10.2314 9.68655 10.5238C9.68655 10.8163 9.44948 11.0533 9.15704 11.0533H3.96781C3.67537 11.0533 3.4383 10.8163 3.4383 10.5238C3.4383 10.2314 3.67537 9.9943 3.96781 9.9943ZM3.96781 7.13493H9.15704C9.44948 7.13493 9.68655 7.372 9.68655 7.66444C9.68655 7.95688 9.44948 8.19395 9.15704 8.19395H3.96781C3.67537 8.19395 3.4383 7.95688 3.4383 7.66444C3.4383 7.372 3.67537 7.13493 3.96781 7.13493ZM3.96781 4.27556H9.15704C9.44948 4.27556 9.68655 4.51263 9.68655 4.80507C9.68655 5.09751 9.44948 5.33458 9.15704 5.33458H3.96781C3.67537 5.33458 3.4383 5.09751 3.4383 4.80507C3.4383 4.51263 3.67537 4.27556 3.96781 4.27556ZM11.4652 17.0212C11.4647 17.6891 11.1468 18.0265 10.4776 18.0394C9.753 18.0535 9.02791 18.0338 8.30349 18.0479C8.03742 18.0531 7.97132 17.9443 7.9745 17.7008C7.98457 16.9207 7.98205 16.1404 7.97669 15.3601C7.97266 14.7562 7.81378 14.6044 7.19892 14.6002C6.76692 14.5973 6.33459 14.5965 5.90259 14.6003C5.30467 14.6057 5.13807 14.7713 5.13354 15.3798C5.12801 16.146 5.12197 16.9123 5.13757 17.6783C5.14328 17.9587 5.07416 18.0724 4.76899 18.0632C4.07276 18.0417 3.37535 18.0604 2.67845 18.0552C2.0052 18.0503 1.66866 17.7194 1.66849 17.0571C1.66665 12.8913 1.66547 8.72548 1.66916 4.55984C1.67017 3.42842 2.432 2.55771 3.56375 2.53254C5.5689 2.48809 7.57656 2.48926 9.58171 2.53556C10.7133 2.56157 11.461 3.42523 11.4637 4.56588L11.4652 17.0212Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5566 9.20632C12.5575 8.57266 12.861 8.2477 13.4928 8.24032C14.4395 8.22924 15.3867 8.22035 16.3329 8.24434C17.5141 8.27437 18.3296 9.12981 18.3315 10.3106C18.3347 12.5373 18.3332 14.7643 18.3322 16.991C18.3318 17.6831 18.0347 17.9919 17.3534 17.9944C16.0725 17.9995 14.7914 18 13.5106 17.9943C12.8492 17.9912 12.5573 17.6852 12.5565 17.0143L12.5566 9.20632ZM14.8758 9.98371H15.9348C16.2273 9.98371 16.4643 10.2208 16.4643 10.5132C16.4643 10.8057 16.2273 11.0427 15.9348 11.0427H14.8758C14.5833 11.0427 14.3463 10.8057 14.3463 10.5132C14.3463 10.2208 14.5833 9.98371 14.8758 9.98371ZM14.8758 12.9066H15.9348C16.2273 12.9066 16.4643 13.1437 16.4643 13.4361C16.4643 13.7286 16.2273 13.9656 15.9348 13.9656H14.8758C14.5833 13.9656 14.3463 13.7286 14.3463 13.4361C14.3463 13.1437 14.5833 12.9066 14.8758 12.9066Z"
        />
    </SvgIcon>
);
export default HubspotCompanySvg;
