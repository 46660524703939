/**
 * Does a bicubic easing for the animation.
 */
export function easeInOutCubic(time: number, initial: number, final: number, duration: number) {
    const delta = final - initial;
    if ((time /= duration / 2) < 1) {
        return (delta / 2) * time * time * time + initial;
    } else {
        return (delta / 2) * ((time -= 2) * time * time + 2) + initial;
    }
}

/**
 * Linear change for animation. No easing.
 */
export function linear(time: number, initial: number, final: number, duration: number) {
    const delta = final - initial;
    return initial + delta * (time / duration);
}
