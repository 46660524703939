import { get } from 'lodash';

import { CellAlign } from './SortableTableColumn';

/**
 * Default compare functions. Coerces both non-empty values to strings and compares.
 * undefined, null, NaN, and false are considered "empty" and sort to the bottom.
 */

const isEmpty = (a: unknown) => a === undefined || a === null || a === false || Number.isNaN(a);
export const defaultCompareFn =
    <T extends unknown>(field: keyof T, locale: string) =>
    (aRow: T, bRow: T): number => {
        const a = get(aRow, field);
        const b = get(bRow, field);

        if (isEmpty(a)) {
            if (isEmpty(b)) {
                return 0;
            }
            return 1;
        }
        if (isEmpty(b)) {
            return -1;
        }
        return String(a).localeCompare(String(b), locale);
    };

export const numberCompareFn =
    <T extends unknown>(field: keyof T) =>
    (aRow: T, bRow: T): number => {
        const a = get(aRow, field);
        const b = get(bRow, field);

        if (typeof a === 'number') {
            if (typeof b === 'number') {
                return a - b;
            }
            return -1;
        }
        if (typeof b === 'number') {
            return 1;
        }
        return 0;
    };

export const getCellAlign = (align: CellAlign, numeric: boolean) => ({
    justifyContent:
        align === 'left'
            ? 'flex-start'
            : align === 'right'
              ? 'flex-end'
              : align === 'center'
                ? 'center'
                : numeric
                  ? 'flex-end'
                  : 'flex-start',
    textAlign: align ?? (numeric ? 'right' : 'left'),
});
