import { useState } from 'react';

import { Box, Collapse } from '@mui/material';

import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import Button from 'components/tokens/Button';
import Icon, { IconType } from 'components/tokens/Icon';

export type NavigationButtonProps = {
    position: 'left' | 'right';
    name?: string;
    link?: string;
    logo?: string;
    /** Overrides the logo and shows an icon instead. */
    icon?: IconType;
    onClick?: (event?: React.MouseEvent) => void;
};

export const NavigationButton: React.FC<NavigationButtonProps> = ({ position, name, link, logo, icon, onClick }) => {
    const [expanded, setExpanded] = useState(false);

    const handleClick = (event: React.MouseEvent) => {
        const isLinkCtrlClick = !!link && (event.ctrlKey || event.metaKey);
        if (!isLinkCtrlClick && onClick) {
            event.preventDefault();
            onClick(event);
        }
    };

    return (
        <Button
            href={link}
            variant="tertiary"
            size="small"
            sx={{
                position: 'absolute',
                borderRadius: 1000,
                '.MuiButton-startIcon': {
                    marginRight: 0,
                },
                '.MuiButton-endIcon': {
                    marginLeft: 0,
                },
            }}
            startIcon={position === 'left' && <Icon type="ChevronLeft" />}
            endIcon={position === 'right' && <Icon type="ChevronRight" />}
            onMouseEnter={() => setExpanded(true)}
            onMouseLeave={() => setExpanded(false)}
            onClick={handleClick}
        >
            <Collapse orientation="horizontal" in={expanded} sx={{ height: 20 }}>
                <Box
                    sx={{
                        display: 'flex',
                        marginLeft: position === 'left' ? 1 : 0,
                        marginRight: position === 'right' ? 1 : 0,
                        alignItems: 'center',
                        width: 'max-content',
                    }}
                >
                    {position === 'left' && name}
                    {icon ? (
                        <Icon type={icon} sx={{ fontSize: 18, marginLeft: 1, marginRight: 1 }} />
                    ) : (
                        <CompanyLogo
                            size="xtiny"
                            logo={logo}
                            company_name={name}
                            sx={{
                                marginLeft: position === 'left' ? 1 : 0,
                                marginRight: position === 'right' ? 1 : 0,
                                svg: { display: 'block' },
                            }}
                        />
                    )}
                    {position === 'right' && name}
                </Box>
            </Collapse>
        </Button>
    );
};

export default NavigationButton;
