import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExportSalesforce: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 23.953C10.8908 15.8157 18.1667 14.3449 18.1667 14.3449V11.6001L24 17.0659L18.1667 22.5802V19.8354C18.1667 19.8354 13.025 19.6946 9 23.953Z"
            fill="#0A0A0A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.859 5.43788C11.8658 5.43788 10.9678 5.85471 10.3228 6.52678C9.61311 5.59886 8.49373 5 7.23419 5C5.08889 5 3.34957 6.73673 3.34957 8.87917C3.34957 9.42762 3.46382 9.94928 3.66923 10.4219C2.67151 11.0051 2 12.0951 2 13.3436C2 15.2065 3.49459 16.7165 5.33818 16.7165C5.57351 16.7165 5.80286 16.692 6.02422 16.645C6.53077 18.019 7.85043 18.9988 9.39886 18.9988C9.62849 18.9988 9.85308 18.9773 10.0707 18.9361C11.1705 17.2949 12.4908 16.113 13.7337 15.2755C14.8395 14.5304 15.8817 14.0587 16.6513 13.7721C16.84 13.7018 17.0128 13.6425 17.1667 13.5928V9.30005L21.4716 13.3336C21.8084 12.7139 22 12.0023 22 11.2456C22 8.84358 20.0695 6.89627 17.6883 6.89627C17.0675 6.89627 16.4775 7.02876 15.9445 7.26692C15.3311 6.17405 14.1792 5.43788 12.859 5.43788Z"
            fill="#00A1E0"
        />
    </SvgIcon>
);

export default ExportSalesforce;
