import React, { useRef, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import MicrosoftLoginImg from 'src/images/ms-symbollockup_signin_light.svg';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';
import { useAuthContext } from 'contexts/AuthContext';

import ErrorStatus from './ErrorStatus';
import Footer from './Footer';

export type LoginStatus = {
    type: 'initial' | 'loading' | 'error';
    response: string;
};

export const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [invalidStatus, setInvalidStatus] = useState(false);

    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const { authStatus, authError, clearError, authenticate, navigateToAzureAuth, navigateToGoogleAuth } =
        useAuthContext();

    const handleUsernameChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(currentTarget.value);
        if (currentTarget.value && password) {
            setInvalidStatus(false);
        }
    };

    const handlePasswordChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(currentTarget.value);
        if (currentTarget.value && username) {
            setInvalidStatus(false);
        }
    };

    const submit = async () => {
        if (!username || !password) {
            setInvalidStatus(true);
            clearError();
        } else {
            authenticate(username, password);
            setInvalidStatus(false);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;
        if (key === 'Enter') {
            if (username && password) {
                submit();
            } else if (username && !password) {
                usernameRef?.current?.blur();
                passwordRef?.current?.focus();
            } else if (password && !username) {
                passwordRef?.current?.blur();
                usernameRef?.current?.focus();
            }
        }
    };

    const showFooter = authStatus !== 'refreshing' && authStatus !== 'logging-out';

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'background.paper',
            }}
        >
            <Icon type="VainuText" sx={{ position: 'absolute', left: 24, top: 0, fontSize: 100 }} />
            <Box sx={{ width: 440 }}>
                {authStatus === 'refreshing' ? (
                    <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        <CircularProgress sx={{ marginBottom: 1 }} />
                        <Typography>Logging you in</Typography>
                    </Box>
                ) : authStatus === 'logging-out' ? (
                    <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        <CircularProgress sx={{ marginBottom: 1 }} />
                        <Typography>Logging you out</Typography>
                    </Box>
                ) : (
                    <>
                        <Typography variant="h1" sx={{ textAlign: 'center', marginBottom: 2 }}>
                            Welcome!
                        </Typography>
                        <Typography color="subtle" sx={{ textAlign: 'center' }}>
                            Don’t have an account? Ask for a{' '}
                            <Link href="https://www.vainu.com/free-trial/?utm_source=vainu_global_login_page">
                                free trial
                            </Link>
                            .
                        </Typography>
                        <Box
                            component="form"
                            sx={{ minHeight: 280, marginTop: 5, display: 'flex', flexDirection: 'column' }}
                        >
                            <TextField
                                sx={{ marginBottom: 1.5 }}
                                inputRef={usernameRef}
                                fullWidth
                                autoFocus
                                disabled={authStatus === 'logging-in'}
                                name="username"
                                label="Email"
                                placeholder=""
                                value={username}
                                onChange={handleUsernameChange}
                                onKeyDown={handleKeyDown}
                            />
                            <TextField
                                inputRef={passwordRef}
                                fullWidth
                                disabled={authStatus === 'logging-in'}
                                placeholder=""
                                name="password"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                onKeyDown={handleKeyDown}
                            />
                            <Button variant="primary" size="large" sx={{ marginTop: 3 }} onClick={submit}>
                                {authStatus === 'logging-in' ? (
                                    <CircularProgress sx={{ color: 'button.text' }} size={24} />
                                ) : (
                                    'Log in'
                                )}
                            </Button>
                            <Typography
                                variant="small"
                                color="subtle"
                                sx={{ display: 'flex', justifyContent: 'center', paddingY: 3 }}
                            >
                                <Link href={`${process.env.REACT_APP_API_URL}/ui/reset_password`}>
                                    Forgot your password?
                                </Link>
                            </Typography>

                            {invalidStatus && (
                                <ErrorStatus>Please enter both a valid email address and a password</ErrorStatus>
                            )}
                            {authStatus === 'error' && authError && <ErrorStatus>{authError}</ErrorStatus>}
                        </Box>
                    </>
                )}

                {showFooter && (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                borderTop: '1px solid',
                                borderTopColor: 'border',
                                padding: 2,
                                marginTop: 4,
                                '> img': {
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            <img src={MicrosoftLoginImg} alt="Sign in with Microsoft" onClick={navigateToAzureAuth} />
                        </Box>
                        {process.env.REACT_APP_ENV !== 'production' && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 1,
                                    cursor: 'pointer',
                                    border: '1.5px solid',
                                    borderColor: 'grey.500',
                                    width: '215px',
                                    height: '41px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                                onClick={navigateToGoogleAuth}
                            >
                                <Icon type="Google" sx={{ marginRight: 1 }} />
                                Sign in with Google
                            </Box>
                        )}
                    </>
                )}
            </Box>
            {showFooter && <Footer />}
        </Box>
    );
};

export default Login;
