import React, { useMemo, useState } from 'react';

import { Fade, styled } from '@mui/material';

import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

import ConfirmDialog from '../common/ConfirmDialog';
import { getPlanhatOwnersFromStorage } from '../utils/planhatOwnerForAccount';

type DataUpdatesPermissionDialogProps = {
    isOpen: boolean;
    handleDialogClose: () => void;
};

const CUSTOMER_SUCCESS = {
    NAME: 'Customer success',
    EMAIL: 'customersuccess@vainu.io',
};

const DataUpdatesPermisssionDialog: React.FC<DataUpdatesPermissionDialogProps> = ({ isOpen, handleDialogClose }) => {
    const [isCopied, setCopied] = useState(false);

    const planhatOwners = useMemo(getPlanhatOwnersFromStorage, []);

    const contactPerson = {
        name: planhatOwners?.cs_owner_fullname ?? CUSTOMER_SUCCESS.NAME,
        email: planhatOwners?.cs_owner ?? CUSTOMER_SUCCESS.EMAIL,
    };

    const copyEmailToClipboard = () => {
        if (isCopied) return;

        navigator.clipboard.writeText(contactPerson.email);
        setCopied(true);

        setTimeout(() => setCopied(false), 1000);
    };

    return (
        <ConfirmDialog
            isSmall
            open={isOpen}
            title="Get data updates"
            subTitle="Contact us to upgrade and get data updates"
            extraBodyContent={
                <>
                    <ContactWrapper>
                        <Typography fontSize={16} weight="bold" mb="4px">
                            {contactPerson.name}
                        </Typography>
                        {planhatOwners?.cs_owner && (
                            <Typography mb="4px" variant="body2" color="subtle">
                                Your account manager
                            </Typography>
                        )}
                        <Link
                            sx={{ display: 'flex', alignItems: 'center' }}
                            variant="body2"
                            href={`mailto:${contactPerson.email}`}
                        >
                            <Icon type="Mail" fontSize="small" sx={{ marginRight: '4px' }} />
                            {contactPerson.email}
                        </Link>
                    </ContactWrapper>
                    <Fade in={isCopied}>
                        <ClipboardHint variant="body2" color="subtle">
                            Copied!
                        </ClipboardHint>
                    </Fade>
                </>
            }
            primaryButtonText="Copy email"
            primaryButtonClick={copyEmailToClipboard}
            handleClose={handleDialogClose}
        />
    );
};

const ContactWrapper = styled('div')(({ theme: { palette } }) => ({
    border: `1px solid ${palette.border}`,
    marginTop: '16px',
    padding: '16px 12px',
    borderRadius: '8px',
}));

const ClipboardHint = styled(Typography)({
    position: 'absolute',
    bottom: 40,
    right: 130,
});

export { DataUpdatesPermisssionDialog };
