import React, { useCallback, useMemo } from 'react';

import { Skeleton, useMediaQuery } from '@mui/material';
import { assertArray } from 'utilities';

import { useBasicData, useProfileData, useSocialNetworks } from 'api/profileData';
import { domainProfileType, isDomainData } from 'api/types/Organization';
import { useTitle } from 'components/beta/Platform/Router/utils';
import { useIndustryTagsFromVCI } from 'components/features/Industries/utils';
import { getHQ } from 'components/features/Locations/utils';
import ProfileTabs from 'components/features/ProfileTabs';
import SocialNetworkSidebarCard from 'components/features/SocialNetwork/SidebarCard';
import { useSocialNetworksSidebarFromDomainData } from 'components/features/SocialNetwork/utils';
import CompanyProfilePageLayout from 'components/layout/CompanyProfilePageLayout';
import { CRMExport, useCRMExport } from 'components/modules/connectors/crm-export';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { useAllProfileExportsWithConnectedCRM } from 'components/modules/profiles/Profile/useAllProfileExportsWithConnectedCRM';
import { CompanyCardCRM } from 'components/templates/CompanyCardCRM';
import ExportedProfilesDialog from 'components/templates/CompanyCardCRM/ExportedProfilesDialog';
import CompanyProfileSidebarCard from 'components/templates/CompanyProfileSidebarCard';
import ProfileArrowNavigation from 'components/templates/ProfileArrowNavigation';
import { DialogController } from 'contexts/DialogContext/DialogController';
import { DomainDataResult } from 'contexts/types/FilterCompanies';
import { Theme } from 'design/theme';
import { useDialogContext } from 'hooks/useDialogContext';

import { ProfileProps } from '..';
import { domainTabs as tabs } from '../tabs';
import { getBannerFallback, useDomainDataStub, useDomainNumberDataPoints, useSkeletonNumberDataPoints } from '../utils';
import useVisibleTabs from './useVisibleTabs';

export type DomainProfileProps = ProfileProps & {
    domain: string;
    initialData?: DomainDataResult;
};

export const DomainProfile: React.FC<DomainProfileProps> = ({
    domain,
    initialData,
    mode = 'page',
    activeTab,
    onTabChange,
    previous,
    next,
}) => {
    const params = {
        type: domainProfileType,
        key: domain,
    } as const;

    // If initial data was not provided, make a quick query to show the name and logo while loading other data
    const { data: nameData } = useProfileData(params, ['name', 'logo_url'], { enabled: !initialData });
    const nameDataDomainData = isDomainData(nameData) ? nameData : undefined;

    const { data: profileData, isLoading, isSuccess } = useBasicData(params);
    const { data: socialNetworksData, isLoading: isLoadingSocialNetworksData } = useSocialNetworks(params);

    const domainDataStub = useDomainDataStub(domain);
    const data = isDomainData(profileData) ? profileData : initialData ?? nameDataDomainData ?? domainDataStub;

    const showSidebar = useMediaQuery<Theme>((theme) => theme.breakpoints.up('profileSidebar'));
    useTitle(data?.website, isLoading);

    const visibleTabs = useVisibleTabs(domain, activeTab);

    const hq = getHQ(assertArray(data?.business_units));

    const industryTags = useIndustryTagsFromVCI(data);

    const actualNumberDataPoints = useDomainNumberDataPoints(data);
    const skeletonNumberDataPoints = useSkeletonNumberDataPoints();
    const numberDataPoints =
        actualNumberDataPoints.length === 0 && isLoading ? skeletonNumberDataPoints : actualNumberDataPoints;
    const socialNetworks = useSocialNetworksSidebarFromDomainData(socialNetworksData);

    const {
        profileExportedToCRMs,
        connectedButNotExportedToCRMs,
        isLoading: isLoadingCRMExports,
    } = useAllProfileExportsWithConnectedCRM(domainProfileType, domain);
    const crmMode = showSidebar ? 'default' : 'compact';

    const Content = useMemo(() => tabs.find((tab) => tab.name === activeTab)?.component ?? null, [activeTab]);

    const { handleDialogOpen, handleDialogClose } = useDialogContext();
    const [exportState, { handleOpenExportDialog, handleCloseExportDialog }] = useCRMExport();

    const handleSendToCRMClick = useCallback(
        (crm: CrmName) =>
            handleOpenExportDialog({
                selectionType: 'selection',
                exportType: 'company',
                crm,
                database: 'DOMAIN_DATA_BASIC',
                companyIds: [data.id],
            }),
        [data, handleOpenExportDialog],
    );

    if (isLoading || isSuccess) {
        return (
            <>
                <CompanyProfilePageLayout
                    mode={mode}
                    backgroundImage={data.website_data?.screenshot_url ?? getBannerFallback(0)}
                    superheader={(previous || next) && <ProfileArrowNavigation previous={previous} next={next} />}
                    header={
                        mode === 'popup' ? (
                            <CompanyProfileSidebarCard
                                profileType="domain"
                                id={data.website}
                                loading={isLoading}
                                name={data.name ?? ''}
                                country={hq?.visiting_address?.country}
                                description={data.description}
                                city={hq?.visiting_address?.city}
                                logo={data.logo_url}
                                statusClass="active"
                                industryTags={industryTags}
                                numberDataPoints={numberDataPoints}
                                showSections={showSidebar ? 'top' : 'both'}
                                showHeaderActions
                                frame={false}
                                wide
                                exportedProfileToCRMs={profileExportedToCRMs}
                                onSendToCRMClick={handleSendToCRMClick}
                            />
                        ) : null
                    }
                    sidebar={
                        <CompanyProfileSidebarCard
                            showBottomActions={mode === 'page'}
                            profileType="domain"
                            id={data.website}
                            loading={isLoading}
                            name={data.name ?? ''}
                            country={hq?.visiting_address?.country}
                            description={data.description}
                            city={hq?.visiting_address?.city}
                            logo={data.logo_url}
                            statusClass="active"
                            industryTags={industryTags}
                            numberDataPoints={numberDataPoints}
                            showSections={mode === 'popup' ? 'bottom' : 'both'}
                            wide={mode === 'page' && !showSidebar}
                            exportedProfileToCRMs={profileExportedToCRMs}
                            onSendToCRMClick={handleSendToCRMClick}
                        />
                    }
                    crm={
                        isLoadingCRMExports ? (
                            <Skeleton variant="rounded" height={135} sx={{ backgroundColor: 'grey.100' }} />
                        ) : (
                            <>
                                {profileExportedToCRMs.map((exported, idx) => (
                                    <CompanyCardCRM
                                        key={idx}
                                        name={data.name}
                                        connectedCRMName={exported[0].crmTarget}
                                        exportedProfiles={exported}
                                        mode={crmMode}
                                        onOpenDialog={() => {
                                            handleDialogOpen('CRM_EXPORTED_PROFILES', {
                                                data: profileExportedToCRMs[idx],
                                                name: data.name ?? '',
                                                country: hq?.visiting_address?.country,
                                                logo: data.logo_url,
                                                onClose: handleDialogClose,
                                            });
                                        }}
                                    />
                                ))}
                                {connectedButNotExportedToCRMs.map((crm) => (
                                    <CompanyCardCRM
                                        key={crm.prospect_export_target}
                                        name={data.name}
                                        connectedCRMName={crm.prospect_export_target || crm.crm_name}
                                        mode={crmMode}
                                        onSendToCRMClick={handleSendToCRMClick}
                                    />
                                ))}
                            </>
                        )
                    }
                    sidebarExtra={
                        <SocialNetworkSidebarCard
                            skeleton={isLoadingSocialNetworksData}
                            socialNetworks={socialNetworks}
                        />
                    }
                    tabs={
                        <ProfileTabs
                            tabs={visibleTabs}
                            activeTab={activeTab}
                            loading={isLoading}
                            onChange={onTabChange}
                            frame={mode === 'page'}
                        />
                    }
                    content={
                        Content && (
                            <Content
                                type={domainProfileType}
                                loading={isLoading}
                                domain={domain}
                                data={data}
                                mode={mode}
                                onTabChange={onTabChange}
                            />
                        )
                    }
                />
                <CRMExport {...exportState} onClose={handleCloseExportDialog} />
                <DialogController type="CRM_EXPORTED_PROFILES" component={ExportedProfilesDialog} />
            </>
        );
    }

    return null;
};

export default DomainProfile;
