import { Company } from 'api/types/company';
import { Contact } from 'api/types/company/contacts';
import { MessageQueue } from 'api/types/MessageQueue';
import { SignalAPIResponse } from 'api/types/signals';
import { Trigger, MessageContextType, ExtraDict, TriggerDestinationType, TrackingLogic } from 'api/types/Trigger';
import { CRMObject } from 'components/modules/connectors/types/Crm';

export type EventKeywordType = 'content' | 'headline';

export interface EventKeywords {
    operator: string;
    values: string[];
    type: EventKeywordType;
}

export interface EventFilters {
    [tag: string]: EventKeywords[];
}

export interface EventsInCategoryCount {
    [key: string]: number;
}

export interface MessageContextItem {
    context_type: MessageContextType;
    lead_tag?: number;
    context_for: string;
    data_field?: string;
    description: string;
}

/**
 * TODO: Add proper typings that includes other destination types like
 * EmailDestinationType, SlackDestinationType etc
 */
export interface Destination {
    system: TriggerDestinationType;
    crm_objects?: CRMObject[];
    emails?: string[];
    urls?: string[];
    slackChannel?: string[];
    active: boolean;
    isValid?: boolean;
    extra?: ExtraDict;
    assignTo?: string[];
}

export type DeliveryType = 'immediately' | 'none' | 'specific' | 'office_hours' | null;

export interface DeliveryTime {
    week_days: number[];
    start_hour: number;
    start_min: number;
    end_hour: number;
    end_min: number;
    timezone_offset: string; // e.g. +02:00
    timezone_name: string; // e.g. Europe/Helsinki
}

export type NotifyType = 'workflow_settings';

export type State = {
    name: string | undefined;
    query: string | undefined;
    queries: string[] | undefined;
    source: string | null | undefined;
    id: string | undefined;
    editTrigger: boolean;
    events: EventFilters;
    eventsInCategoryCount: EventsInCategoryCount;
    editEvent: string;
    destinations: Destination[];
    deliveryTime: DeliveryTime;
    dailyLimit: number | null;
    notifyType: NotifyType;
    notify: DeliveryType;
    isValid: boolean;
    isActive: boolean;
    errors: string[];
    status: string;
    selectedTrigger: Trigger;
    editedTrigger: Trigger;
    messageContext: MessageContextItem[];
    trackingLogic: TrackingLogic;
    trackChanges: string[];
    logicChanged?: string;
    created?: string;
};

export type CompanyPreview = Partial<Company> & { id: number };

export type LeadPreview = Partial<SignalAPIResponse> & {
    id: string;
    vainu_date: string;
    company?: CompanyPreview;
    isValid: boolean;
    endOfResults?: boolean;
    contact?: Partial<Contact>;
    companies?: CompanyPreview[];
    lead?: Partial<SignalAPIResponse>;
    preview?: LeadPreview;
    messages?: MessageQueue[];
    delivered?: boolean;
    olderThanTrigger?: boolean;
    olderThanTriggerChanged?: boolean;
};

export enum ActionType {
    UNSELECT_TRIGGER = 'unselect_trigger',
    SELECT_TRIGGER = 'select-trigger',
    SELECT_SOURCE = 'select-source',
    SET_NAME = 'set-name',
    REMOVE_EVENT_FILTER = 'remove-event-filter',
    REPLACE_TRIGGER_FILTERS = 'replace-trigger-filters',
    CONVERT_EVENTS_TO_QUERIES = 'convert-events-to-queries',
    CONVERT_QUERIES_TO_EVENTS = 'convert-queries-to-events',
    EDIT_DESTINATION = 'edit-destination',
    DISABLE_DESTINATION = 'disable-destination',
    EDIT_DELIVERY_TIME = 'edit-delivery-time',
    EDIT_LIMIT = 'edit-limit',
    SET_CONTEXT = 'set-context',
    RESET_FILTER_STATE = 'reset-filter-state-to-db-value',
}
