import { logSentry } from './logUtils';

type MemoryStorage = {
    [key: string]: string;
};

let IN_MEMORY_LOCAL_STORAGE: MemoryStorage = {};

export const LocalStorage = {
    setItem: (key: string, value: unknown) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            logSentry(err);
            console.error('localStorage setItem operation failed, defaults to inmemory', key, value, err);
            IN_MEMORY_LOCAL_STORAGE[key] = JSON.stringify(value);
        }
    },
    getItem: <T = unknown>(key: string): T | null => {
        let value;
        try {
            value = localStorage.getItem(key);
        } catch (err) {
            logSentry(err);
            console.error('localStorage getItem operation failed, defaults to inmemory', key, err);
            value = IN_MEMORY_LOCAL_STORAGE?.[key] ?? null;
        }
        return value ? JSON.parse(value) : null;
    },
    removeItem: (key: string) => {
        try {
            return localStorage.removeItem(key);
        } catch (err) {
            logSentry(err);
            console.error('localStorage removeItem operation failed, defaults to inmemory', key, err);
            delete IN_MEMORY_LOCAL_STORAGE[key];
        }
    },
    clear: () => {
        try {
            return localStorage.clear();
        } catch (err) {
            logSentry(err);
            console.error('localStorage clear operation failed, defaults to inmemory', err);
            IN_MEMORY_LOCAL_STORAGE = {};
        }
    },
};
