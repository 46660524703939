import React from 'react';

import { styled } from '@mui/material';
import { simpleHash } from 'utilities';

export type LetterLogoGeneratorProps = { id?: string | null } & (
    | {
          variant?: 'default';
          name?: string | null;
      }
    | {
          variant: 'initials';
          firstName?: string | null;
          lastName?: string | null;
      }
);

const StyledSvg = styled('svg')({
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    '& text': {
        fontFamily: 'Aeroport',
        fontWeight: 700,
        fill: 'white',
        opacity: 0.52,
    },
});

export const LetterLogoGenerator: React.FC<LetterLogoGeneratorProps> = ({ id, ...props }) => {
    // These expressions cannot destructure props, as TypeScript needs it as an object to pick the correct type variant first.
    const text =
        props.variant === 'initials'
            ? ((props.firstName?.charAt(0) ?? '') + (props.lastName?.charAt(0) ?? '')).toUpperCase()
            : (props.name ?? '').charAt(0).toUpperCase();

    const { variant } = props;
    const index = id ? simpleHash(id.slice(0, 8)) % 4 : 0;
    const color = [
        'brandColors.warningOrange',
        'brandColors.tertiaryBlue',
        'brandColors.successGreen',
        'brandColors.errorMagenta',
    ][index];
    const fontSize = variant === 'initials' ? 10 : 18;
    // The Aeroport cap height is exactly 2/3 of the font point size; this aligns the letters vertically.
    // 10 is half the height of the SVG view box; it doesn't affect SVG's outer scaling.
    const vPos = 10 + fontSize / 3;
    return (
        <StyledSvg
            viewBox="0 0 20 20"
            sx={{ backgroundColor: color, '& text': { opacity: variant === 'initials' ? 1 : undefined } }}
        >
            <text x="50%" y={vPos} fontSize={fontSize} textAnchor="middle">
                {text}
            </text>
        </StyledSvg>
    );
};

export default LetterLogoGenerator;
