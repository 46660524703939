import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, MenuItem, OutlinedInput, styled } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { isArray, truncate } from 'lodash';

import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';

import TextWithTooltip from '../common/TextWithTooltip';
import { CrmName, CRMObject } from '../types/Crm';
import NoOptionFoundWithEmoji from './NoOptionFoundWithEmoji';
import SelectIconTextRenderer from './SelectIconTextRenderer';

export type ContactExportOption = {
    label: string;
    value: string;
    subLabel?: React.ReactNode;
    endAdornment?: React.ReactNode;
    icon?: React.ReactNode;
    tooltip?: string;
    title?: string;
    email?: string;
    phone?: string;
};

export interface ContactExportProps {
    crm: CrmName;
    object: CRMObject;
    options: ContactExportOption[];
    defaultSelected: string[];
    defaultLabel?: string;
    multiple?: boolean;
    open?: boolean;
    width?: number;
    haveAvatar?: boolean;
    onSelect: (contactId: string[]) => void;
}
const ContactExport: React.FC<ContactExportProps> = ({
    crm,
    object,
    options,
    defaultSelected,
    defaultLabel = 'Select',
    onSelect,
    multiple = false,
    open = false,
    width,
    haveAvatar = false,
}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [onFocusItem, setOnFocusItem] = useState<string>('');
    const [searchContactValue, setSearchContactValue] = useState('');

    useEffect(() => {
        if (open) {
            setMenuOpen(open);
        }
    }, [open]);

    const handleSelect = (e: SelectChangeEvent<string | string[]>) => {
        e.preventDefault();
        const value = e.target.value;
        isArray(value) ? onSelect(value) : onSelect([value]);
    };

    const getContactAvatarText = (label = ''): string => {
        const [firstname = '', lastname = ''] = label.includes('Yourself (')
            ? label.replace('Yourself (', '').split(' ')
            : label.split(' ');
        const formattedLabel = `${firstname.charAt(0)}${lastname.charAt(0)}`;
        return formattedLabel.toUpperCase();
    };

    const getFormattedLabel = (label = '') => {
        const splitByText = 'Yourself';
        const [, name = ''] = label.split(splitByText);
        if (!name) {
            return label;
        }
        return (
            <>
                {splitByText} <i style={{ color: '#3F3F3F', fontWeight: 400 }}>{name}</i>
            </>
        );
    };
    const getFormattedLabelWithTooltip = ({
        text,
        props,
    }: {
        text: string;
        props: {
            icon?: React.ReactNode;
            object?: CRMObject;
            crm?: CrmName;
            tooltip?: string;
            noIcon?: boolean;
        };
    }) => {
        const trimmedText =
            text?.length > 45
                ? truncate(text, {
                      length: 45,
                  })
                : '';
        return (
            <TextWithTooltip
                delay={300}
                isTooltip={!!trimmedText || !!props.tooltip}
                title={props.tooltip || text}
                placement="top"
            >
                <SelectIconTextRenderer text={trimmedText || text} {...props} />
            </TextWithTooltip>
        );
    };

    const getAssignedUserLabels = (list: string[]) => {
        const isYourself = list.find((val) => {
            const { label = '' } = options?.find(({ value }) => value === val) || {};
            return label.includes('Yourself');
        });
        if (list.length > 1) {
            if (list.length > 2) {
                return getFormattedLabelWithTooltip({
                    props: { noIcon: true },
                    text: `${isYourself ? `${list.length - 1} teammates, and yourself` : `${list.length} teammates`}`,
                });
            }
            const firstUser = options?.find(({ value }) => value === list[0])?.label;
            const secondUser = options?.find(({ value }) => value === list[1])?.label;
            let label = `${firstUser}, and ${secondUser}`;
            if (isYourself) {
                const otherUserName = firstUser?.includes('Yourself') ? secondUser : firstUser;
                label = `${otherUserName}, and yourself`;
            }
            return getFormattedLabelWithTooltip({
                props: { noIcon: true },
                text: label,
            });
        }
        const option = options?.find(({ value }) => value === list[0]);
        return getFormattedLabelWithTooltip({
            text: isYourself ? 'Yourself' : option?.label || '',
            props: multiple ? { noIcon: true } : { object, crm, noIcon: true, tooltip: option?.tooltip },
        });
    };

    const filteredOptions = options.filter(({ label }) => {
        return label.toLowerCase().includes(searchContactValue.toLowerCase());
    });

    return (
        <Select
            value={defaultSelected}
            displayEmpty
            renderValue={(val: string[]) => {
                if (!val || !val?.length) {
                    return <div style={{ fontSize: 14, color: '#808080' }}>{defaultLabel}</div>;
                }
                return getAssignedUserLabels(val);
            }}
            onOpen={(e) => {
                e.preventDefault();
                setMenuOpen(true);
            }}
            onClose={(e: Event | React.SyntheticEvent) => {
                const target = e?.target as HTMLTextAreaElement;
                if (target?.nodeName !== 'INPUT') {
                    setMenuOpen(false);
                }
            }}
            open={menuOpen}
            onChange={handleSelect}
            IconComponent={ExpandMoreIcon}
            multiple={multiple}
            input={<StyledOutlinedInput fullWidth menuOpen={open} />}
        >
            {!!options?.length && (
                <div>
                    <MenuItem
                        sx={{
                            background: '#fff !important',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: 0,
                            width: '100%',
                            padding: '16px',
                        }}
                        onClickCapture={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                        disableRipple
                    >
                        <TextField
                            label={searchContactValue ? '' : 'Search...'}
                            variant="default"
                            fullWidth
                            size="small"
                            value={searchContactValue}
                            autoComplete="off"
                            sx={{
                                background: '#F5F5F5',
                                '& label.Mui-focused': {
                                    color: '#0A0A0A',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: '#0A0A0A',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderWidth: 1,
                                    '& fieldset': {
                                        borderColor: '#E1E1E1',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#0A0A0A',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#0A0A0A',
                                    },
                                },
                            }}
                            InputLabelProps={{ shrink: false }}
                            onChange={(e) => {
                                e.stopPropagation();
                                setSearchContactValue(e.target.value);
                            }}
                        />
                    </MenuItem>
                </div>
            )}
            {filteredOptions?.map(({ label, value, subLabel, endAdornment }) => {
                const isSelected = defaultSelected.includes(String(value));
                const isFocused = onFocusItem === value;
                const isYourself = label?.includes('Yourself');
                return (
                    <StyledMenuItem
                        key={label + value}
                        onClick={(event) => {
                            event.preventDefault();
                        }}
                        value={value}
                        selected={isSelected}
                        onMouseEnter={() => setOnFocusItem(value)}
                        onMouseLeave={() => setOnFocusItem('')}
                    >
                        <div className="items">
                            <div className="item-wrapper">
                                {haveAvatar && <Avatar className="avatar">{getContactAvatarText(label)}</Avatar>}
                                <div>
                                    <Typography>{isYourself ? getFormattedLabel(label) : label}</Typography>
                                    {subLabel && subLabel}
                                </div>
                                {endAdornment && endAdornment}
                            </div>
                            <div>
                                {isSelected && (
                                    <>
                                        {isFocused && multiple ? (
                                            <CloseIcon fontSize="small" htmlColor="#0A0A0A" />
                                        ) : (
                                            <CheckIcon fontSize="small" htmlColor="#0A0A0A" />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </StyledMenuItem>
                );
            })}

            {filteredOptions.length === 0 && (
                <NoOptionFoundWithEmoji
                    WrapperComponent={MenuItem}
                    text={
                        !options?.length
                            ? 'We don’t seem to have contact data for this company'
                            : 'We can’t find anyone with that name'
                    }
                    componentProps={{
                        disableRipple: true,
                        style: { alignItems: options?.length ? 'flex-start' : 'center' },
                    }}
                />
            )}
        </Select>
    );
};

export default ContactExport;

const StyledOutlinedInput = styled(OutlinedInput)<{ menuOpen: boolean }>(({ menuOpen }) => ({
    '&.MuiOutlinedInput-root': {
        background: '#fff',
        height: 40,
        '& fieldset': {
            border: menuOpen ? '2px solid #0A0A0A' : undefined,
        },
        '& $notchedOutline': {
            borderRadius: '4px',
        },
        '&$focused $notchedOutline': {
            border: '2px solid #0A0A0A',
            borderRadius: '4px',
        },
        minWidth: 100,
    },
    focused: {},
    notchedOutline: {},
}));

const StyledMenuItem = styled(MenuItem)({
    '&.MuiMenuItem-root': {
        minWidth: 170,
        width: '100%',
        padding: 16,
        height: 73,
        color: '#3F3F3F',
        fontSize: 14,
        whiteSpace: 'normal',
        margin: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#F5F5F5',
        },
        '&.Mui-selected': {
            borderRadius: 0,
            backgroundColor: '#FFFFFF',
        },
        '&.Mui-selected:hover': {
            backgroundColor: 'rgba(47, 128, 237, 0.1)',
        },
        '& .items': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            '& .item-wrapper': {
                display: 'flex',
                alignItems: 'center',
                '& .avatar': {
                    marginRight: 16,
                    height: 36,
                    width: 36,
                },
            },
        },
        '& .items>div:first-child': {
            '& span': {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 12,
                color: '#898989',
            },
            '& p': {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 14,
                color: '#0A0A0A',
            },
        },
    },
});
