import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TriggersDot = (props) => (
    <SvgIcon {...props}>
        <path d="M18.3071 6.47694C18.8056 6.81833 19.3958 7.00067 20.0001 6.99994C21.6569 6.99994 23.0001 5.65679 23.0001 3.99994C22.9999 2.57274 21.9943 1.34323 20.5955 1.05998C19.1966 0.776721 17.7919 1.51814 17.2365 2.83284C16.6811 4.14754 17.1288 5.67152 18.3071 6.47694Z" />
    </SvgIcon>
);

export default TriggersDot;
