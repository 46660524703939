import React from 'react';

import { Grid, Typography, Link, styled } from '@mui/material';
import { isEmpty } from 'lodash';

import { ProspectLogo } from '../logo/ProspectLogo';

export type ProspectOptionProps = {
    value: string | number;
    label: string;
    company_name: string;
    logo: string;
    visiting_city: string;
    domain: string;
    business_id: string;
};
type Props = {
    data: ProspectOptionProps;
};
const ProspectOption: React.FC<Props> = (props) => {
    const { data, ...styles } = props;
    if (isEmpty(data)) {
        return null;
    }
    return (
        <div {...styles}>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <ProspectLogo
                        company={{
                            id: data.value,
                            logo: data.logo,
                            company_name: data.company_name,
                        }}
                    />
                </Grid>
                <Grid item xs container direction="column">
                    <Grid item>
                        <NameTypography>{data.company_name}</NameTypography>
                    </Grid>
                    <Grid item sx={{ color: '#8C9BA8', fontSize: '12px' }}>
                        <Typography color="inherit" variant="inherit" display="inline">
                            {data.visiting_city}
                        </Typography>
                        {data.domain ? '  -  ' : null}
                        <DomainLink
                            color="inherit"
                            variant="inherit"
                            display="inline"
                            href={`https://${data.domain}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            fontSize={12}
                        >
                            {data.domain}
                        </DomainLink>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ProspectOption;

const NameTypography = styled(Typography)({
    fontSize: '12px',
    color: '#000',
    fontWeight: 500,
});

const DomainLink = styled(Link)(({ theme: { palette } }) => ({
    color: palette.text.primary,
    '&:hover': {
        textDecoration: 'none',
        borderColor: '#000',
    },
}));
