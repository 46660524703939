import { useState } from 'react';

import { Box, CircularProgress } from '@mui/material';

import ConnectorDrawerWithButton from 'components/modules/connectors/common/ConnectorDrawerWithButton';
import { useCRMIntegConfigState } from 'components/modules/connectors/context/CRMIntegrationConfigContext';
import { TabEnum } from 'components/modules/connectors/types';

import { ConnectionViewTabProps } from '../ConnectionView';
import CRMBusinessImpact from './CRMBusinessImpact';

const CRMBusinessImpactTab: React.FC<ConnectionViewTabProps> = ({ crmName }) => {
    const { last_full_sync } = useCRMIntegConfigState();

    const [openedConnectorTab, setOpenedConnectorTab] = useState<TabEnum | null>(null);

    if (last_full_sync === undefined) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (last_full_sync === null || last_full_sync === '1970-01-01T00:00:00') {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'flex-start',
                    backgroundColor: 'brandColors.statusError100',
                    padding: 3,
                    borderRadius: 2,
                }}
            >
                To generate an analysis, you will have to (re)run matching
                <ConnectorDrawerWithButton
                    crm={crmName}
                    tab={openedConnectorTab || 'matching'}
                    handleClick={() => setOpenedConnectorTab('matching')}
                    openCompanyMatchingAdvanceMenu
                    handleClose={() => setOpenedConnectorTab(null)}
                    open={!!openedConnectorTab}
                    buttonText="Go to matching"
                />
            </Box>
        );
    }

    return <CRMBusinessImpact crmName={crmName} />;
};

export default CRMBusinessImpactTab;
