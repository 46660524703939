import React, { useEffect } from 'react';

import { format } from 'date-fns';
import { find } from 'lodash';
import { useRecoilValue } from 'recoil';

import { isCRMImportAsyncProcess, UserAsyncProcess } from 'api/types/UserAsyncProcess';
import { filteredCrmImportProcesses } from 'store/asyncProcessAtomSelectors';
import { toDateUTC } from 'utilities/date';

import useConnectorsApi from '../hooks/useConnectorsApi';
import { CrmName } from '../types/Crm';

type ImportState = {
    isNewProcess: boolean;
    status: string;
    completed: number;
    matched: number;
    importFinishedTimestamp: string | null;
    jobID: string | null;
    statusText: string;
    progress: number;
    size: number;
    sizeIsMinumum: boolean;
    error: string | null;
};
const initialimportState: ImportState = {
    isNewProcess: false,
    status: 'none',
    completed: 0,
    matched: 0,
    importFinishedTimestamp: null,
    jobID: null,
    statusText: '',
    progress: 0,
    error: null,
    size: 0,
    sizeIsMinumum: false,
};

const formatTimeStamp = (timestamp: string): string => {
    return format(toDateUTC(timestamp), 'PPp');
};
type Action =
    | { type: 'SET_STATUS'; value: string }
    | { type: 'importProcessStopped' }
    | { type: 'clearStatusAndStatusText' }
    | { type: 'waitForNewProcessToSpawn' }
    | { type: 'processing'; completed: number; matched: number; size?: number; sizeIsMinumum: boolean }
    | {
          type: 'processIsCompleted';
          importFinishedTimestamp: string;
          progress: number;
          statusText: string;
          completed: number;
          matched: number;
      }
    | { type: 'processIsStopped' }
    | { type: 'processFailed'; error: string }
    | { type: 'newJobStarted'; jobId: string };

function reducer(state: ImportState, action: Action): ImportState {
    switch (action.type) {
        case 'newJobStarted':
            return {
                ...state,
                status: 'waiting',
                completed: 0,
                progress: 2,
                isNewProcess: true,
                statusText: 'Connecting',
                jobID: action.jobId,
                error: null,
            };
        case 'importProcessStopped':
            return {
                ...state,
                status: 'none',
                isNewProcess: false,
                jobID: null,
            };
        case 'clearStatusAndStatusText':
            return {
                ...state,
                status: 'none',
                statusText: '',
            };
        case 'waitForNewProcessToSpawn':
            return {
                ...state,
                status: 'waiting',
                statusText: 'Connecting',
                completed: 0,
                progress: 2,
            };
        case 'processIsStopped':
            return {
                ...state,
                status: 'stopped',
                statusText: 'Cancelled',
                isNewProcess: false,
            };
        case 'processFailed':
            return {
                ...state,
                status: 'failed',
                statusText: 'Cancelled',
                isNewProcess: false,
                error: action.error,
            };
        case 'processIsCompleted':
            return {
                ...state,
                status: 'finished',
                ...action,
                isNewProcess: false,
            };
        case 'processing':
            const progress = action.size ? Math.round((action.completed / action.size) * 100) : 0;

            return {
                ...state,
                status: 'saving',
                statusText: 'Matching and saving',
                ...action,
                progress: progress || 7,
                isNewProcess: true,
            };
        case 'SET_STATUS':
            return {
                ...state,
                status: action.value,
            };
        default:
            return state;
    }
}

export const useCRMImportSettingsHook = (crm: CrmName) => {
    const [state, dispatch] = React.useReducer<React.Reducer<ImportState, Action>>(reducer, initialimportState);
    const { startCRMImport } = useConnectorsApi();
    const asyncProcesses = useRecoilValue(filteredCrmImportProcesses({ crm })) as UserAsyncProcess[];

    const startFullImport = async (): Promise<void> => {
        const {
            data: { job_id: jobId },
        } = await startCRMImport(crm);

        if (jobId) {
            dispatch({ type: 'newJobStarted', jobId });
            // Need to check
            // storeDispatch(
            //     pollUserAsyncProcess(jobId, {
            //         type: `${crm}-import`,
            //         meta_data: {
            //             crm,
            //             size: null,
            //             matched: 0,
            //             completed: 0,
            //             companies_under_processing: [],
            //         },
            //         job_id: jobId,
            //         state: 'process',
            //         created: new Date(),
            //     })
            // );
            getCurrentAsyncProcessStatus(true, jobId);
        } else {
            dispatch({ type: 'SET_STATUS', value: 'import_start_failed' });
        }
    };

    const getCurrentAsyncProcessStatus = (newProcessStarted?: boolean, newJobID?: string) => {
        const { jobID, isNewProcess } = state;
        const crmProcesses = asyncProcesses
            .filter(isCRMImportAsyncProcess)
            .filter((process) => process.meta_data.crm === crm);
        const newProcess = isNewProcess || newProcessStarted;
        const jobid = jobID || newJobID;
        if (!newProcess && crmProcesses.length === 0) {
            dispatch({ type: 'clearStatusAndStatusText' });
            return;
        }
        const currentProcess = jobid ? find(crmProcesses, { job_id: jobid }) : crmProcesses[0];

        if (newProcess && !currentProcess) {
            setTimeout(() => getCurrentAsyncProcessStatus(newProcess, jobid), 1000);
            dispatch({ type: 'waitForNewProcessToSpawn' });
            return;
        }
        if (currentProcess?.state === 'failure') {
            dispatch({ type: 'processFailed', error: currentProcess.error });
            return;
        }
        if (currentProcess?.state === 'stopped') {
            dispatch({ type: 'processIsStopped' });
            return;
        }
        if (currentProcess?.state === 'completed') {
            const {
                meta_data: { completed = 0, matched = 0 },
            } = currentProcess;
            const processFinished = formatTimeStamp(currentProcess.finished);

            let statusText = `The previous import was finished on ${processFinished}`;
            statusText += completed ? ` The number of imports was ${completed}.` : '';
            statusText += matched ? ` Matched companies: ${matched}` : '';

            dispatch({
                type: 'processIsCompleted',
                progress: 100,
                completed,
                matched,
                statusText,
                importFinishedTimestamp: currentProcess.finished,
            });
        } else if (currentProcess?.state === 'process') {
            // setTimeout(() => getCurrentAsyncProcessStatus(newProcess, jobid), 5000);
            const {
                meta_data: { completed, matched, size, size_is_minimum: sizeIsMinumum, saving },
            } = currentProcess;

            if (saving) {
                dispatch({ type: 'processing', completed, matched, sizeIsMinumum, size } as Action);
            }
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        getCurrentAsyncProcessStatus();
    }, [asyncProcesses]);
    /* eslint-enable react-hooks/exhaustive-deps */
    return [state, { startFullImport }] as const;
};
