import { Box } from '@mui/material';
import { groupBy } from 'lodash';

import { ApplicationStoreConnections } from 'api/types/ApplicationStore';
import { TargetReqParam } from 'components/modules/connectors/utils/CRMTargetText';
import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import NoValue from 'components/templates/SortableTable/NoValue';
import Dialog from 'components/tokens/Dialog';
import Link from 'components/tokens/Link';
import Tag from 'components/tokens/Tag';
import { usePermissionContext } from 'contexts/PermissionContext';
import { formatDate, toDateUTC } from 'utilities/date';

import { DealStatus } from './CRMBusinessImpact';
import useDialogData from './useDialogData';

type Props = {
    open: boolean;
    onClose: () => void;
    connectedCRM: ApplicationStoreConnections | undefined;
    target: TargetReqParam;
};

type NewCompaniesData = {
    external_id: string;
    external_object: string;
    created_by_vainu: boolean;
    name: string | null;
    object_created_date: string | null;
    related_deals:
        | {
              deal_status: DealStatus;
              revenue: {
                  currency_code: string;
                  value: string;
              } | null;
          }[]
        | null;
    organization_match?: {
        name: string;
        logo_url: string | null;
        business_id: string;
    } | null;
    domain_match?: {
        name: string;
        logo_url: string | null;
        website: string;
    } | null;
};

const HEADER_HEIGHT = 41;
const ROW_HEIGHT = 49;

const NewCompaniesDialog: React.FC<Props> = ({ open, onClose, connectedCRM, target }) => {
    const { isDomainConnector } = usePermissionContext();

    const { infiniteQuery, activeSort, setActiveSort } = useDialogData<NewCompaniesData>({
        queryKey: ['business-impact-new-companies', target],
        query: {
            '?ALL': [
                {
                    '?EQ': {
                        target,
                    },
                },
                {
                    '?EQ': {
                        target_object: 'Account',
                    },
                },
                {
                    '?EQ': {
                        attributed_to: 'vainu',
                    },
                },
            ],
        },
        fields: [
            'external_id',
            'external_object',
            'created_by_vainu',
            'name',
            'object_created_date',
            'related_deals.deal_status',
            'related_deals.revenue',
            ...(isDomainConnector
                ? ['domain_match.name', 'domain_match.logo_url', 'domain_match.website']
                : ['organization_match.name', 'organization_match.logo_url', 'organization_match.business_id']),
        ] as (keyof NewCompaniesData)[],
        defaultSortColumn: 'object_created_date',
        defaultSortOrder: 'desc',
        enabled: open,
    });

    const { data, fetchNextPage, hasNextPage, isFetching, isLoading } = infiniteQuery;

    const rows = data?.pages || new Array(5).fill({});

    return (
        <Dialog onClose={onClose} open={open} title="New companies created from Vainu" maxWidth="xl">
            <SortableTable<NewCompaniesData>
                data={rows}
                rowKeyField="external_id"
                tableHeight={Math.min(HEADER_HEIGHT + ROW_HEIGHT * rows.length, 480)}
                sx={{ marginTop: 1 }}
                // hack for the scrollbar overlapping data issue on firefox
                headerCellSx={{
                    '&:last-child': {
                        paddingRight: 3,
                    },
                }}
                cellSx={{
                    '&:last-child': {
                        paddingRight: 3,
                    },
                }}
                defaultSortColumn={activeSort.column}
                defaultSortOrder={activeSort.order}
                onSort={(column, order) => setActiveSort({ column, order })}
                onScroll={(el) => {
                    if (isFetching || !hasNextPage) {
                        return;
                    }
                    const scrollBottom = el.scrollHeight - el.scrollTop - el.clientHeight;

                    if (scrollBottom < el.clientHeight) {
                        fetchNextPage();
                    }
                }}
            >
                <SortableTableColumn<NewCompaniesData>
                    label="Created"
                    field="object_created_date"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) =>
                        row.object_created_date ? (
                            <b>{formatDate(toDateUTC(row.object_created_date), 'short')}</b>
                        ) : (
                            <NoValue />
                        )
                    }
                </SortableTableColumn>
                <SortableTableColumn<NewCompaniesData>
                    label="New company"
                    field="name"
                    sortable={false}
                    loading={isLoading}
                    skeletonWidth={120}
                >
                    {(row) => {
                        const company = row.organization_match || row.domain_match;
                        return (
                            <Link
                                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                href={connectedCRM?.account_link_template?.replace('{}', row.external_id || '')}
                                toNewTab
                            >
                                <CompanyLogo
                                    variant="round"
                                    size="tiny"
                                    logo={company?.logo_url}
                                    company_name={company?.name}
                                    border
                                />
                                {company?.name}
                            </Link>
                        );
                    }}
                </SortableTableColumn>
                <SortableTableColumn<NewCompaniesData>
                    label="Deals"
                    field="related_deals"
                    keyValue="deals"
                    sortable={false}
                    loading={isLoading}
                    skeletonWidth={120}
                >
                    {(row) => {
                        if (!row.related_deals?.length) {
                            return <NoValue />;
                        }
                        const lost = row.related_deals.filter((deal) => deal.deal_status === 'lost').length;
                        const open = row.related_deals.filter((deal) => deal.deal_status === 'open').length;
                        const won = row.related_deals.length - lost - open;
                        return (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Tag label={`${lost} lost`} size="small" variant="orange" />
                                <Tag label={`${open} open`} size="small" variant="blue" />
                                <Tag label={`${won} won`} size="small" variant="green" />
                            </Box>
                        );
                    }}
                </SortableTableColumn>
                <SortableTableColumn<NewCompaniesData>
                    label="Won revenue"
                    field="related_deals.revenue"
                    sortable={false}
                    align="right"
                    loading={isLoading}
                    skeletonWidth={100}
                >
                    {(row) => {
                        if (!row.related_deals) {
                            return <NoValue />;
                        }

                        const grouped = groupBy(
                            row.related_deals
                                .filter((deal) => deal.deal_status === 'won')
                                .flatMap((deal) => deal.revenue),
                            'currency_code',
                        );
                        const combined = Object.values(grouped).map((currencyGroup) =>
                            currencyGroup.reduce(
                                (prev, next) => ({
                                    ...prev,
                                    value: prev.value + (next?.value ? parseFloat(next.value) : 0),
                                }),
                                { currency_code: currencyGroup[0]?.currency_code, value: 0 },
                            ),
                        );

                        return combined.map((row) => (
                            <p key={row.currency_code}>
                                {row.value} {row.currency_code}
                            </p>
                        ));
                    }}
                </SortableTableColumn>
            </SortableTable>
        </Dialog>
    );
};

export default NewCompaniesDialog;
