import { Box, alpha, Theme, SxProps } from '@mui/material';

export type FadeProps = {
    position: 'left' | 'right' | 'top' | 'bottom';
    width: number;
    height: number;
    scrollbarGutter: number;
    headerPadding: number;
    leftPadding: number;
    phase: number;
    shadow?: string;
    color?: string | ((theme: Theme) => string);
};

const Fade: React.VFC<FadeProps> = ({
    position,
    width,
    height,
    scrollbarGutter,
    headerPadding,
    leftPadding,
    phase,
    shadow,
    color = (theme) => theme.palette.common.white,
}) => {
    const wrapperSx: SxProps = {
        position: 'absolute',
        overflow: 'hidden',
        width,
        height,
        pointerEvents: 'none',
    };
    const gradientAngle = {
        left: '270deg',
        right: '90deg',
        top: '0deg',
        bottom: '180deg',
    }[position];
    const fadeSx: SxProps<Theme> = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        background: shadow
            ? undefined
            : (theme) =>
                  `linear-gradient(${gradientAngle}, ${alpha(
                      typeof color === 'string' ? color : color(theme),
                      0,
                  )} 0%, ${typeof color === 'string' ? color : color(theme)} 100%)`,
    };

    if (position === 'left') {
        return (
            <Box sx={{ ...wrapperSx, top: 0, left: leftPadding }}>
                <Box sx={{ ...fadeSx }} style={{ left: phase }}>
                    {shadow && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width,
                                left: -width,
                                top: 0,
                                bottom: 0,
                                boxShadow: shadow,
                            }}
                        />
                    )}
                </Box>
            </Box>
        );
    } else if (position === 'right') {
        return (
            <Box sx={{ ...wrapperSx, top: 0, right: scrollbarGutter }}>
                <Box sx={{ ...fadeSx }} style={{ right: phase }}>
                    {shadow && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width,
                                right: -width,
                                top: 0,
                                bottom: 0,
                                boxShadow: shadow,
                            }}
                        />
                    )}
                </Box>
            </Box>
        );
    } else if (position === 'top') {
        return (
            <Box sx={{ ...wrapperSx, top: headerPadding, left: 0 }}>
                <Box sx={{ ...fadeSx }} style={{ top: phase }}>
                    {shadow && (
                        <Box
                            sx={{
                                position: 'absolute',
                                height,
                                left: 0,
                                top: -height,
                                right: 0,
                                boxShadow: shadow,
                            }}
                        />
                    )}
                </Box>
            </Box>
        );
    }
    return (
        <Box sx={{ ...wrapperSx, bottom: scrollbarGutter, left: 0 }}>
            <Box sx={{ ...fadeSx }} style={{ bottom: phase }}>
                {shadow && (
                    <Box
                        sx={{
                            position: 'absolute',
                            height,
                            left: 0,
                            bottom: -height,
                            right: 0,
                            boxShadow: shadow,
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Fade;
