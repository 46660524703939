import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Disabled = (props) => (
    <SvgIcon {...props}>
        <path d="M4.92186 19.064C1.02145 15.1558 1.02672 8.82594 4.93364 4.92422C8.84057 1.02251 15.1704 1.02567 19.0734 4.93129C22.9764 8.83691 22.9754 15.1667 19.0711 19.0711C15.1617 22.9758 8.82733 22.9726 4.92186 19.064ZM6.22153 6.46477C3.14284 9.61037 3.18349 14.6521 6.31242 17.7477C9.44151 20.8432 14.4834 20.8297 17.5957 17.7173C20.708 14.605 20.7216 9.56313 17.6261 6.43404C14.5305 3.30511 9.48875 3.26446 6.34315 6.34315L6.22153 6.46477ZM6.34315 19.0711L4.92893 17.6569L17.6569 4.92893L19.0711 6.34315L6.34315 19.0711Z" />
    </SvgIcon>
);

export default Disabled;
