import React from 'react';

import Grid from '@mui/material/Grid';

import { Signal } from 'api/types/signals/signals';
import Typography from 'components/tokens/Typography';

type RelocationSignalProps = {
    signal: Signal;
};

const RelocationContent: React.VFC<RelocationSignalProps> = ({ signal }) => {
    const { address: newAddress } = getNewAddress(signal);
    const { address: oldAddress } = getOldAddress(signal);

    return (
        <Grid container alignContent="flex-start" spacing={1}>
            <Grid item xs={12}>
                <Typography variant="small" weight="semibold">
                    {newAddress}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="mini" color="subtle" sx={{ fontStyle: 'italic' }}>
                    Old address: {oldAddress}
                </Typography>
            </Grid>
        </Grid>
    );
};
export default RelocationContent;

function getNewAddress(signal: Signal) {
    const newAddress = signal.dynamic_values?.find((item) => item.key === 'new_visiting_address');
    if (newAddress?.key === 'new_visiting_address') {
        const { value, visiting_postal, visiting_city, visiting_lat, visiting_lng } = newAddress;
        return {
            address: joinAddressParts(value, visiting_postal, visiting_city),
            coordinates: visiting_lat && visiting_lng ? [visiting_lat, visiting_lng] : null,
        };
    }
    return {};
}

function getOldAddress(signal: Signal) {
    const oldAddress = signal.dynamic_values?.find((item) => item.key === 'old_visiting_address');
    if (oldAddress?.key === 'old_visiting_address') {
        const { value, visiting_postal_o, visiting_city_o, visiting_lng_o, visiting_lat_o } = oldAddress;
        return {
            address: joinAddressParts(value, visiting_postal_o, visiting_city_o),
            coordinates: visiting_lng_o && visiting_lat_o ? [visiting_lat_o, visiting_lng_o] : null,
        };
    }
    return {};
}

const joinAddressParts = (
    street: string | null | undefined,
    postal_code: string | null | undefined,
    city: string | null | undefined,
) => [street, [postal_code, city].filter(Boolean).join(' ')].filter(Boolean).join(', ');
