import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

import Button from 'components/tokens/Button';
import Icon, { IconType } from 'components/tokens/Icon';

import { MainPath } from '../Router/routePaths';

export type NavButtonProps = {
    path: MainPath;
    label: string;
    icon: IconType;
    active?: boolean;
};

export const NavButton: React.FC<NavButtonProps> = ({ path, label, icon, active = false }) => (
    <Link to={path} key={path} data-testid={`e2e-nav-link-${label}`}>
        <Tooltip title={label} placement="right">
            <Button variant={active ? 'primary' : 'flat'} startIcon={<Icon type={icon} />} />
        </Tooltip>
    </Link>
);

export default NavButton;
