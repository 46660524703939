import React, { useState, useCallback, createContext, useMemo } from 'react';

type MountingPhaseType = 'MOUNTED' | 'UNMOUNTING' | 'UNMOUNTED';
export type DialogTypes =
    | 'NO_FIELDS_FOR_UPDATE_DIALOG'
    | 'UPDATES_REMINDER_DIALOG'
    | 'ONE_TIME_UPDATE_DIALOG'
    | 'META_FIELDS_ONBOARDING'
    | 'STOP_DYNAMICS_CONNECTION'
    | 'NO_DOMAIN_COMPANIES_DIALOG'
    | 'CRM_DISCONNECT_CONFIRM_DIALOG'
    | 'DATA_UPDATES_PERMISSION'
    | 'MAPPING_FORM'
    | 'REMOVE_MAPPING'
    | 'CRM_EXPORTED_PROFILES'
    | 'NEW_CRM_PROPERTY_SUGGEST';

// mountingPhase is necessary to persist MUI Dialog closing animation
// dialog will be removed from DOM in 200 ms, check handleDialogClose() for more info
type DialogStateType = {
    openedDialog: DialogTypes | null;
    mountingPhase: MountingPhaseType;
    dialogProps: object;
};

const initialState: DialogStateType = {
    openedDialog: null,
    dialogProps: {},
    mountingPhase: 'UNMOUNTED',
};

type DialogContextType = DialogStateType & {
    handleDialogOpen: (type: DialogTypes, dialogProps?: object) => void;
    handleDialogClose: () => void;
};

const DialogContext = createContext<DialogContextType>({} as DialogContextType);

type DialogProviderProps = {
    children?: React.ReactNode;
};

// todos:
// - Rename 'handleDialogClose' to 'onClose' in DialogController;
// - Fix dialog props typings. There is no typecheck when using 'handleDialogOpen('TYPE', { ... })'

const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
    const [{ openedDialog, mountingPhase, dialogProps }, setDialogState] = useState(initialState);

    const handleDialogOpen = useCallback((type: DialogTypes, dialogProps?: object) => {
        setDialogState({
            openedDialog: type,
            dialogProps: dialogProps ?? {},
            mountingPhase: 'MOUNTED',
        });
    }, []);

    const handleDialogClose = useCallback(() => {
        setDialogState((prev) => ({ ...prev, mountingPhase: 'UNMOUNTING' }));

        setTimeout(() => setDialogState(initialState), 200);
    }, []);

    const value = useMemo(
        () => ({
            openedDialog,
            mountingPhase,
            dialogProps,
            handleDialogOpen,
            handleDialogClose,
        }),
        [dialogProps, handleDialogClose, handleDialogOpen, mountingPhase, openedDialog],
    );

    return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>;
};

export { DialogProvider, DialogContext };
