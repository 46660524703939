import { useContext } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';

import { ConnectorsNavigationMenu } from './ConnectorsNavigationMenu';
import { APILandingPage } from './pages/APILandingPage';
import { ChooseCRMLandingPage } from './pages/ChooseCRMLandingPage';
import { ConnectCRMLandingPage } from './pages/ConnectCRMLandingPage';
import ConnectionView, { defaultTab } from './pages/ConnectionView';
import { crmsConnectConfig } from './utils';

const Connectors = () => {
    const { isDomainConnector, isApplicationStoreLoading, hasIntegration, hasProductPermission } =
        useContext(PermissionContext);

    const connectedCRMs = crmsConnectConfig.filter((crm) => hasIntegration(crm.integration));

    return (
        <Routes>
            <Route path="/" element={<ConnectorsNavigationMenu connectedCRMs={connectedCRMs} />}>
                <Route path="connections/:crmName">
                    <Route index element={<Navigate to={defaultTab} />} />
                    <Route path=":tab" element={<ConnectionView />} />
                </Route>

                <Route path="crms">
                    <Route
                        index
                        element={
                            <ChooseCRMLandingPage
                                checkIsConnected={hasIntegration}
                                checkIsPurchased={hasProductPermission}
                            />
                        }
                    />
                    <Route
                        path=":crmName"
                        element={
                            <ConnectCRMLandingPage
                                isApplicationStoreLoading={isApplicationStoreLoading}
                                isDomainConnector={isDomainConnector}
                                checkIsConnected={hasIntegration}
                                checkIsPurchased={hasProductPermission}
                            />
                        }
                    ></Route>
                </Route>
                <Route path="api" element={<APILandingPage />} />
                <Route
                    path="/"
                    element={<Navigate to={connectedCRMs.length > 0 ? `connections/${connectedCRMs[0].id}` : 'crms'} />}
                />
            </Route>
        </Routes>
    );
};

export default Connectors;
