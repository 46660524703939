import React, { useState, useEffect } from 'react';

import { Box, FormControlLabel, Popover } from '@mui/material';
import { endOfDay, startOfMonth } from 'date-fns';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import Checkbox from 'components/tokens/Checkbox';
import DateRange from 'components/tokens/DateRange';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import Typography from 'components/tokens/Typography';

import { getDateValueLabel } from '../../utils';

export type DateFilterValue = string | Array<string | null> | null;

const DATE_OPTIONS = [
    { label: 'Date', value: 'daterange' },
    { label: 'Year', value: 'year' },
];

const START_YEAR = 1800;
const CURRENT_YEAR = new Date().getFullYear();

const getYearOptions = (): SelectOption[] => {
    return new Array(CURRENT_YEAR - START_YEAR + 1).fill(null).map((_, idx) => {
        const value = (CURRENT_YEAR - idx).toString();
        return {
            label: value,
            value,
        };
    });
};

type DateFilterProps = {
    value: DateFilterValue;
    onChange: (value: DateFilterValue) => void;
    showNull?: boolean;
};

const DateFilter = ({ value, onChange, showNull }: DateFilterProps) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'employer-registration' });

    const [localValue, setLocalValue] = useState<Array<string | null> | string>(value || CURRENT_YEAR.toString());

    useEffect(() => {
        if (value) {
            setLocalValue(value);
        }
    }, [value]);

    const mode = Array.isArray(localValue) ? 'daterange' : 'year';

    return (
        <div>
            <Typography
                color="dark"
                sx={{
                    fontSize: 14,
                    border: '1px solid',
                    borderColor: 'border',
                    borderRadius: '4px',
                    height: 40,
                    width: '100%',
                    backgroundColor: 'background.paper',
                    paddingLeft: '12px',
                    paddingTop: 1,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    '&:hover': {
                        border: `1px solid`,
                        borderColor: 'primary.main',
                        cursor: 'text',
                    },
                }}
                {...bindTrigger(popupState)}
            >
                {getDateValueLabel(value)}
            </Typography>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                elevation={9}
                PaperProps={{
                    sx: { padding: '12px 8px', width: showNull ? 300 : 240 },
                }}
            >
                <Select
                    disabled={value === null}
                    value={DATE_OPTIONS.find((i) => i.value === mode) || null}
                    options={DATE_OPTIONS}
                    multiple={false}
                    onValueChange={(value) => {
                        onChange(
                            (value as SelectOption).value === 'year'
                                ? CURRENT_YEAR.toString()
                                : [startOfMonth(new Date()).toISOString(), endOfDay(new Date()).toISOString()],
                        );
                    }}
                    width="100px"
                />

                <Box
                    sx={{
                        padding: 1,
                        border: '1px solid',
                        borderColor: 'border',
                        marginTop: 2,
                        marginBottom: 1,
                        '& .rmdp-container': { width: '100%' },
                    }}
                >
                    {mode === 'year' ? (
                        <Select
                            disabled={value === null}
                            value={localValue as string}
                            options={getYearOptions()}
                            multiple={false}
                            onValueChange={(value) => {
                                onChange((value as SelectOption).value as string);
                            }}
                            width="100%"
                        />
                    ) : (
                        <DateRange
                            value={(localValue as Array<string | null>)
                                .filter(Boolean)
                                .map((v) => new Date(v as string))}
                            onChange={(value) => {
                                let newValue: Array<string | null> = value.map((v) => v.toISOString());
                                if (newValue.length === 1) {
                                    newValue = [...newValue, null];
                                }
                                onChange(newValue);
                            }}
                            TextFieldProps={{
                                compact: true,
                                disabled: value === null,
                                status: value === null ? 'disabled' : undefined,
                            }}
                        />
                    )}
                </Box>

                {showNull && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value === null}
                                onChange={(_, checked) => {
                                    onChange(checked ? null : localValue);
                                }}
                            />
                        }
                        label={
                            <Typography variant="small" weight="semibold">
                                Show only non-registered companies
                            </Typography>
                        }
                    />
                )}
            </Popover>
        </div>
    );
};

export default DateFilter;
