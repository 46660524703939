import React from 'react';

import { Box, SxProps } from '@mui/material';

export type TabContentGridSectionProps = {
    sx?: SxProps;
    columns?: 1 | 2 | 3;
    children: React.ReactNode;
};

export const TabContentGridSection: React.FC<TabContentGridSectionProps> = ({ columns = 1, sx, children }) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
            gap: { xs: 1, md: 2 },
            ...sx,
        }}
    >
        {React.Children.map(children, (child) =>
            child ? (
                <Box
                    sx={{
                        gridColumn:
                            columns === 1
                                ? 'span 6'
                                : columns === 2
                                  ? { xs: 'span 6', md: 'span 3' }
                                  : { xs: 'span 6', md: 'span 3', lg: 'span 2' },
                        ...(typeof child === 'object' && 'props' in child && child.props.sx),
                    }}
                >
                    {child}
                </Box>
            ) : null,
        )}
    </Box>
);

export default TabContentGridSection;
