export type LocationGroupStateAction =
    | 'default'
    | 'filterUpdated'
    | 'filterClosed'
    | 'addRowActionClosed'
    | 'addRowActionOpened'
    | 'filterOpened'
    | 'filterRemoved';

type StateCallback = (state: LocationGroupState, action: LocationGroupStateAction) => LocationGroupState;

export interface LocationGroupStateConfigValue {
    transition?: { [key in LocationGroupStateAction]?: StateCallback };
    onEnter?: StateCallback;
}

interface LocationGroupState {
    value: LocationGroupStateAction;
    allowWarning?: boolean;
}

type LocationGroupStateConfig = Partial<Record<LocationGroupStateAction, LocationGroupStateConfigValue>>;

// Current logic is simple so, no library really needed
// but ff logic be more complicated maybe switch to some state machine library
// with visualizer like xstate.js
export const config: LocationGroupStateConfig = {
    filterUpdated: {
        transition: {
            filterClosed: (state) => ({ ...state, allowWarning: true }),
        },
    },
    filterRemoved: {
        onEnter: (state) => {
            return { ...state, allowWarning: true };
        },
    },
    filterClosed: {
        onEnter: (state, action) => {
            return { ...state, value: action, allowWarning: true };
        },
    },
    addRowActionOpened: {
        transition: {
            addRowActionClosed: (state, action) => {
                return { ...state, value: action, allowWarning: true };
            },
        },
    },
    default: {
        onEnter: (state, action) => {
            return { ...state, value: action, allowWarning: false };
        },
    },
};

export const locationGroupStateReducer = (state: LocationGroupState, action: LocationGroupStateAction) => {
    if (state.value === action) {
        return state;
    }

    const currentState = config[state.value];
    if (currentState) {
        const transition = currentState.transition?.[action];

        if (transition) {
            return transition(state, action);
        }
    }

    const newState = config[action];

    if (newState?.onEnter) {
        return newState.onEnter(state, action);
    }

    const defaultState = config['default'];

    if (defaultState?.onEnter) {
        return defaultState.onEnter(state, action);
    }

    return state;
};
