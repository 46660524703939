import React, { useContext, useEffect, useState } from 'react';

import { useTheme, Box, IconButton, Snackbar } from '@mui/material';
import { AxiosError } from 'axios';
import { useRecoilState } from 'recoil';
import { replaceItemAtIndex } from 'utilities';

import { removeInviteToken, updateAccount } from 'api/account';
import {
    InvitedUser,
    RemoveInviteTokenPayload,
    RemoveUserPayload,
    Team as TeamType,
    UpdateAccountPayload,
} from 'api/types/Account';
import { SnackbarMessage } from 'api/userProfile';
import Team from 'components/features/settings/UserProfileAndTeam/Team';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import reshapedAccountAtom from 'store/reshapedAccountAtom';

const TeamSettings = () => {
    const axios = useAxiosContext();
    const theme = useTheme();
    const { profile } = useContext(PermissionContext);
    const [reshapedAccount, setReshapedAccount] = useRecoilState(reshapedAccountAtom);
    const [snackMessagesQueue, setSnackMessagesQueue] = useState<readonly SnackbarMessage[]>([]);
    const [snackMessage, setSnackMessage] = useState<SnackbarMessage | undefined>(undefined);
    const [snackOpen, setSnackOpen] = useState(false);

    /**
     *
     * @param data
     * @param  users
     * @param isDelete - Need to be passed only when deleting user
     * @param userEmail - Need to be passed only when deleting user
     */
    const saveChangesToAccount = async (
        data: UpdateAccountPayload | RemoveUserPayload,
        users: TeamType[],
        isDelete?: boolean,
        userEmail?: string,
    ) => {
        try {
            await updateAccount(axios, reshapedAccount.accountId, data);
            setReshapedAccount((oldValues) => ({ ...oldValues, users }));
            if (isDelete) {
                setSnackMessagesQueue((prev) => [
                    ...prev,
                    { moreInfo: userEmail, message: `'s account was removed`, key: 'user_delete_success' },
                ]);
            }
        } catch (err) {
            const error = err as AxiosError;
            if (isDelete && error.response) {
                setSnackMessagesQueue((prev) => [
                    ...prev,
                    { message: `An error occurred while deleting this user!`, key: 'user_delete_failure' },
                ]);
            }
        }
    };

    const handleChangePrivilege = (user: TeamType) => {
        const togglePrivilege = user.privilege === 'user' ? 'admin' : 'user';
        if (user.privilege === 'admin' || user.privilege === 'user') {
            const userIndex = reshapedAccount.users.findIndex((userToFind) => userToFind.id === user.id);
            const updatedUsers = replaceItemAtIndex(reshapedAccount.users, userIndex, {
                ...user,
                privilege: togglePrivilege,
            });
            saveChangesToAccount(
                { change_role: { user: user.id, role: togglePrivilege } } as UpdateAccountPayload,
                updatedUsers,
            );
        }
    };

    const handleDeleteUser = (userToDelete: TeamType) => {
        const updatedUsers = reshapedAccount.users.filter((user) => user.id !== userToDelete.id);
        saveChangesToAccount(
            { remove_user: userToDelete.id } as RemoveUserPayload,
            updatedUsers,
            true,
            userToDelete.email,
        );
    };

    const handleRemoveInviteToken = async (invitedUserToDelete: InvitedUser) => {
        const data: RemoveInviteTokenPayload = {
            id: reshapedAccount.accountId,
            remove_invite_token: invitedUserToDelete.token,
        };
        try {
            await removeInviteToken(axios, data);
            const updatedInvites = reshapedAccount.invitedUsers.filter(
                (invitedUser) => invitedUser.token !== invitedUserToDelete.token,
            );
            setReshapedAccount((oldValues) => ({ ...oldValues, invitedUsers: updatedInvites }));
            setSnackMessagesQueue((prev) => [
                ...prev,
                { moreInfo: invitedUserToDelete.email, message: `'s invite was removed`, key: 'invite_remove_success' },
            ]);
        } catch (err) {
            const error = err as AxiosError;
            if (error.response) {
                setSnackMessagesQueue((prev) => [
                    ...prev,
                    { message: `An error occurred while deleting this invite!`, key: 'invite_remove_failure' },
                ]);
            }
        }
    };

    /** ***************************Snackbar*********************************** */
    useEffect(() => {
        if (snackMessagesQueue.length && !snackMessage) {
            // Set a new snack when we don't have an active one
            setSnackMessage({ ...snackMessagesQueue[0] });
            setSnackMessagesQueue((prev) => prev.slice(1));
            setSnackOpen(true);
        } else if (snackMessagesQueue.length && snackMessage && snackOpen) {
            // Close an active snack when a new one is added
            setSnackOpen(false);
        }
    }, [snackMessagesQueue, snackMessage, snackOpen]);

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleExited = () => {
        setSnackMessage(undefined);
    };

    const currentUser = reshapedAccount.users.find((user) => user.user === profile.userID);

    return currentUser?.privilege === 'admin' ? (
        <>
            <Snackbar
                key={snackMessage ? snackMessage.key : undefined}
                open={snackOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                autoHideDuration={6000}
                disableWindowBlurListener={true}
                onClose={handleSnackClose}
                TransitionProps={{ onExited: handleExited }}
                message={
                    snackMessage ? (
                        <Typography>
                            <Box component="span" sx={{ fontWeight: 700 }}>
                                {snackMessage.moreInfo}
                            </Box>
                            {snackMessage.message}
                        </Typography>
                    ) : undefined
                }
                action={
                    <IconButton size="small" sx={{ marginLeft: 1 }} onClick={handleSnackClose}>
                        <Icon type="CheckBig" fontSize="mini" sx={{ color: theme.palette.background.paper }} />
                    </IconButton>
                }
                sx={{
                    borderRadius: 2,
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: theme.palette.button.background,
                        padding: '4px 20px',
                    },
                }}
            />
            <Team
                users={reshapedAccount.users}
                invitedUsers={reshapedAccount.invitedUsers}
                userLimit={reshapedAccount.userLimit}
                currentUser={currentUser}
                onChangePrivilege={handleChangePrivilege}
                onDelete={handleDeleteUser}
                onRemoveInvite={handleRemoveInviteToken}
            />
        </>
    ) : null;
};

export default TeamSettings;
