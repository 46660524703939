import Icon from 'components/tokens/Icon';

interface Props {
    changeValue?: number;
}

export const ChangeIndicator: React.VFC<Props> = ({ changeValue }) => {
    if (changeValue !== undefined) {
        if (changeValue > 0) {
            return <Icon type="ArrowShortUp" color="changeIndicator.up" sx={{ margin: '0 6px -4px' }} />;
        } else if (changeValue < 0) {
            return <Icon type="ArrowShortDown" color="changeIndicator.down" sx={{ margin: '0 6px -4px' }} />;
        }
    }
    return null;
};

export default ChangeIndicator;
