import { formatDistanceToNow, parseISO } from 'date-fns';

import { Signal } from 'api/types/signals/signals';

export function formatSignalTitle(text?: string | null) {
    if (text === 'NEW_WEBSITE_CONTENT') {
        return 'New Website Content';
    }
    return text;
}

export function formatSignalDate(signal: Signal) {
    return signal.vainu_date
        ? formatDistanceToNow(parseISO(signal.vainu_date), {
              addSuffix: true,
          })
        : '';
}

export function getSourceDomain(link: string) {
    const match = link.match(/(?:https?:\/\/)?(?:www.)?([^:/]+).*/);
    return match ? match[1] : undefined;
}

export function hasSignalTag(signal: Signal, tagId: number) {
    return signal?.tags?.some(({ id }) => id === tagId) ?? false;
}
