import { styled } from '@mui/material';
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';

export const StyledMuiChip = styled(MuiChip)<MuiChipProps>(({ theme: { palette } }) => ({
    backgroundColor: palette.field.background,
    color: palette.primary.main,
    border: '1px solid',
    borderColor: palette.border,
    borderRadius: 20,
    '&.square': {
        borderRadius: 4,
        color: palette.text.primary,
    },
    '&:hover': {
        backgroundColor: palette.field.hover,
        outline: `1px solid ${palette.field.hover}`,
    },
    '&.active': {
        backgroundColor: palette.background.paper,
        borderColor: palette.primary.main,
        outline: `1px solid ${palette.primary.main}`,
        color: palette.primary.main,
    },
    '&.filled': {
        backgroundColor: palette.background.paper,
        color: palette.button.background,
    },
    '&.basic.active, &.basic.filled': {
        backgroundColor: palette.button.background,
        color: palette.button.text,
        borderColor: palette.primary.main,
        outline: 0,
        '& .MuiChip-icon': {
            color: palette.button.text,
        },
    },
    '& .MuiChip-icon': {
        fontSize: 16,
        color: palette.primary.main,
    },
    '& .MuiChip-deleteIcon': {
        fontSize: '14px',
        color: palette.text.subtle,
    },
}));
