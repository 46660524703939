import React from 'react';

import { Box, Grid, Link, Tooltip } from '@mui/material';

import Skeleton from 'components/modules/connectors/skeletons';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

export type TierProgressProps = {
    title?: string;
    segments?: { used: number; color: string }[] | null;
    limit?: number | null;
    color: string;
    tooltip: string;
    hideProgress?: boolean;
    onShowPricingClick: () => void;
};

const TierProgress: React.FC<TierProgressProps> = ({
    segments,
    limit,
    color,
    tooltip,
    hideProgress,
    onShowPricingClick,
    title = 'Total',
}) => {
    let cumulative = 0;
    return (
        <Box>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="subtitle1" color="dark" weight="normal" sx={{ marginBottom: 0.5 }}>
                        {title}
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{ color, fontWeight: '500', letterSpacing: 0, fontFamily: 'Aeroport' }}
                    >
                        {segments?.reduce((prev, next) => prev + next.used, 0).toLocaleString() ?? (
                            <Skeleton width={120} />
                        )}
                    </Typography>
                </Grid>

                {!hideProgress && (
                    <Grid item sx={{ textAlign: 'right' }}>
                        <Tooltip
                            arrow
                            placement="left"
                            title={
                                <Box sx={{ padding: '12px 4px' }}>
                                    {tooltip}
                                    <Box sx={{ marginTop: tooltip ? 1 : 0 }}>
                                        <Link
                                            href="#1"
                                            sx={{ textDecoration: 'underline' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onShowPricingClick();
                                            }}
                                        >
                                            Learn more about pricing
                                        </Link>
                                    </Box>
                                </Box>
                            }
                            leaveDelay={400}
                        >
                            <Typography variant="subtitle1" color="dark" weight="normal">
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    sx={{ marginBottom: 0.5 }}
                                >
                                    <span>Your quota</span>
                                    <Icon
                                        type="InfoCircleOutline"
                                        fontSize="inherit"
                                        color="text.subtle"
                                        sx={{ marginLeft: '4px' }}
                                    />
                                </Grid>
                            </Typography>
                        </Tooltip>
                        <Typography
                            variant="h2"
                            color="subtle"
                            sx={{ fontWeight: '500', letterSpacing: 0, fontFamily: 'Aeroport' }}
                        >
                            {limit?.toLocaleString() ?? <Skeleton width={120} />}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            {!hideProgress && (
                <Box
                    sx={{
                        backgroundColor: 'grey.200',
                        borderRadius: '4px',
                        height: 16,
                        marginTop: 0.5,
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    {segments?.map((segment, idx, arr) => {
                        const width = (segment.used ?? 0) + cumulative;
                        if (segment) {
                            cumulative += segment.used;
                        }
                        return (
                            <Box
                                key={idx}
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    opacity: 1 - idx / arr.length,
                                    backgroundColor: segment.color,
                                    width: `${(width * 100) / (limit ?? 1)}%`,
                                    height: '100%',
                                    borderRadius: '4px',
                                    zIndex: 10 - idx,
                                }}
                            />
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default TierProgress;
