import { differenceInDays, startOfDay, sub } from 'date-fns';

import TimeFrameSelect from 'components/tokens/select-components/TimeFrameSelect/TimeFrameSelect';

const OPTIONS = [
    { action: 'all', label: 'All activity', title: 'Timeframe' },
    { action: 'month', label: 'Last 30 days' },
    { action: 'week', label: 'Last 7 days' },
];

type Props = {
    value: Date[];
    onChange: (dates: Date[]) => void;
};

const TriggerTimeFrameSelector: React.FC<Props> = ({ value, onChange }) => {
    // custom
    let selectedAction: string | Date[] = value;

    if (value.length === 1) {
        const diff = differenceInDays(new Date(), value[0]);

        if (diff === 7) {
            selectedAction = 'week';
        } else if (diff === 30) {
            selectedAction = 'month';
        } else if (diff === 730) {
            selectedAction = 'all';
        }
    }

    const handleSelectOption = (action: string | Date[]) => {
        let newValue: Date[];

        if (typeof action === 'string') {
            const today = startOfDay(new Date());

            if (action === 'all') {
                newValue = [sub(today, { days: 730 })];
            } else if (action === 'month') {
                newValue = [sub(today, { days: 30 })];
            } else if (action === 'week') {
                newValue = [sub(today, { days: 7 })];
            } else {
                newValue = [];
            }
        } else {
            newValue = action;
        }

        onChange(newValue);
    };

    return <TimeFrameSelect value={selectedAction} onChange={(value) => handleSelectOption(value)} options={OPTIONS} />;
};

export default TriggerTimeFrameSelector;
