import { memo } from 'react';

import { Box, IconButton, Skeleton, Tooltip } from '@mui/material';
import { isNotEmpty } from 'utilities';

import { CompanyFact } from 'api/types/company';
import { BusinessUnit, organizationProfileType, ProfileType, VainuCustomIndustry } from 'api/types/Organization';
import { TabChangeHandler } from 'components/modules/profiles/Profile';
import { TabSlug } from 'components/modules/profiles/Profile/tabs';
import { GroupLevelTag } from 'components/templates/CompanyCard';
import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import StatusIndicator from 'components/tokens/StatusIndicator';
import Typography from 'components/tokens/Typography';
import { formatDate } from 'utilities/date';

import AuxiliaryNames from './AuxiliaryNames';
import CustomIndustries from './CustomIndustries';
// import ExpandableRow from './ExpandableRow';
import DirectMarketingDenied from './DirectMarketingDenied';
import Industries from './Industries';
import InfoRow from './InfoRow';
import { BasicInfoContainer, BasicTitleContainer, InfoContainer } from './styled';
import {
    // getTeleoperators,
    countMoreAddresses,
    formatHQAddress,
    formatURL,
    getFuzzyTime,
} from './utils';

export type BasicInfoProps = {
    loading?: boolean;
    loadingMode?: ProfileType;
    business_id: string;
    levelInGroup?: number | null;
    company_names?: string[];
    email?: string;
    legal_entity?: string;
    foundation_date?: string;
    facts?: CompanyFact[];
    industry_codes_all_values?: string[];
    link?: string;
    phone?: string;
    status?: string | null;
    nstatus?: number;
    hq?: BusinessUnit;
    business_units?: BusinessUnit[];
    locale?: string;
    last_checked?: string;
    direct_marketing_denied?: boolean;
    customIndustries?: VainuCustomIndustry[];
    onGoToClick?: TabChangeHandler;
};

export const BasicInfo: React.FC<BasicInfoProps> = memo(
    ({
        loading = false,
        loadingMode = organizationProfileType,
        business_id,
        levelInGroup,
        email,
        facts,
        legal_entity,
        foundation_date,
        industry_codes_all_values = [],
        link,
        phone,
        status = 'active',
        nstatus,
        hq,
        company_names = [],
        business_units,
        last_checked,
        direct_marketing_denied,
        customIndustries = [],
        onGoToClick,
    }) => {
        const displayedAddress = formatHQAddress(hq);
        const moreAddressesCount = business_units ? countMoreAddresses(business_units, hq) : 0;
        const customIndustriesFromFacts = facts?.filter((fact) => fact.type === 'industry_category_second_level') ?? [];
        // const teleoperators = getTeleoperators(facts);

        return (
            <Frame padding="none">
                <BasicInfoContainer>
                    <BasicTitleContainer>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Typography variant="body1" weight="bold">
                                Basic information
                            </Typography>
                            {direct_marketing_denied && <DirectMarketingDenied />}
                        </Box>
                        {last_checked && (
                            <Typography variant="tiny" weight="normal" color="subtle" sx={{ fontStyle: 'italic' }}>
                                Last checked: <span style={{ fontWeight: 500 }}>{formatDate(last_checked)}</span>
                            </Typography>
                        )}
                    </BasicTitleContainer>
                    <InfoContainer>
                        {(status || (loading && loadingMode === organizationProfileType)) && (
                            <InfoRow label="Status">
                                {status ? (
                                    <StatusIndicator
                                        sx={{ alignSelf: 'flex-start' }}
                                        status={status}
                                        showActive
                                        statusClass={
                                            ['active', 'aktiivinen', 'aktivt', 'normal'].includes(status.toLowerCase())
                                                ? 'active'
                                                : 'warning'
                                        }
                                    />
                                ) : (
                                    <Skeleton variant="rounded" width={70} height={22} />
                                )}
                            </InfoRow>
                        )}
                        <InfoRow label="Business ID">{business_id}</InfoRow>
                        {(foundation_date || loading) && (
                            <InfoRow label="Founded">
                                {foundation_date ? (
                                    getFuzzyTime(foundation_date)
                                ) : (
                                    <Skeleton variant="text" width={64} />
                                )}
                            </InfoRow>
                        )}
                        {(link || loading) && (
                            <InfoRow label="URL">
                                {link ? (
                                    <>
                                        <Icon
                                            type="HomeAltOutline"
                                            sx={{ height: 16, width: 16, marginRight: '6px' }}
                                        />
                                        <Link href={formatURL(link)} target="_blank" rel="noreferrer nofollow">
                                            {link}
                                        </Link>
                                    </>
                                ) : (
                                    <Skeleton variant="text" width={120} />
                                )}
                            </InfoRow>
                        )}
                        {(phone || loading) && (
                            <InfoRow label="Tel">
                                {phone ? (
                                    <>
                                        <Icon type="Phone" sx={{ height: 16, width: 16, marginRight: '6px' }} />
                                        <Link href={`tel:${phone}`}>{phone}</Link>
                                    </>
                                ) : (
                                    <Skeleton variant="text" width={80} />
                                )}
                            </InfoRow>
                        )}
                        {(email || (loading && loadingMode === organizationProfileType)) && (
                            <InfoRow label="Email">
                                {email ? (
                                    <>
                                        <Icon type="Mail" sx={{ height: 16, width: 16, marginRight: '6px' }} />
                                        <Link href={`mailto:${email}`}>{email}</Link>
                                    </>
                                ) : (
                                    <Skeleton variant="text" width={120} />
                                )}
                            </InfoRow>
                        )}
                        {(displayedAddress || loading) && (
                            <InfoRow
                                label="Visiting address"
                                labelSx={{ alignSelf: 'center' }}
                                contentSx={{ justifyContent: 'space-between' }}
                            >
                                {displayedAddress ? (
                                    <>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Icon
                                                type="GoogleMaps"
                                                sx={{ height: 16, width: 16, marginRight: '6px' }}
                                            />
                                            <Link
                                                href={`https://maps.google.com?daddr=${encodeURIComponent(
                                                    displayedAddress,
                                                )}`}
                                                toNewTab="nofollow"
                                            >
                                                {displayedAddress}
                                            </Link>
                                        </Box>
                                        {moreAddressesCount !== undefined && moreAddressesCount > 0 && onGoToClick && (
                                            <Button
                                                variant="tertiary"
                                                size="small"
                                                onClick={() => onGoToClick(TabSlug.Locations, true)}
                                            >
                                                {`+ ${moreAddressesCount} more addresses`}
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center', height: '32.5px' }}>
                                        <Skeleton variant="text" width={240} />
                                    </Box>
                                )}
                            </InfoRow>
                        )}
                        {(legal_entity || (loading && loadingMode === organizationProfileType)) && (
                            <InfoRow label="Legal entity">
                                {legal_entity || <Skeleton variant="text" width={64} />}
                            </InfoRow>
                        )}
                        {levelInGroup && (
                            <InfoRow label="Group entity:">
                                <GroupLevelTag
                                    level={levelInGroup}
                                    onClick={() => onGoToClick?.(TabSlug.GroupStructure, true)}
                                />
                            </InfoRow>
                        )}
                        {(isNotEmpty(customIndustries) || isNotEmpty(customIndustriesFromFacts) || loading) && (
                            <InfoRow
                                label="Vainu Custom Industries"
                                trailingIcon={
                                    <Tooltip
                                        title="This is our modern way of profiling companies. 
                            Most companies get multiple industry labels."
                                        arrow={false}
                                    >
                                        <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                                            <Icon sx={{ fontSize: 16 }} type="InfoCircleOutline" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            >
                                {loading ? (
                                    <div>
                                        <Skeleton variant="text" width={160} />
                                        <Skeleton variant="text" width={144} />
                                        <Skeleton variant="text" width={192} />
                                        <Skeleton variant="text" width={176} />
                                    </div>
                                ) : (
                                    <CustomIndustries
                                        customIndustries={
                                            customIndustries.length ? customIndustries : customIndustriesFromFacts
                                        }
                                    />
                                )}
                            </InfoRow>
                        )}
                        {(isNotEmpty(industry_codes_all_values) ||
                            (loading && loadingMode === organizationProfileType)) && (
                            <InfoRow label="Industry (official)">
                                {loading ? (
                                    <div>
                                        <Skeleton variant="text" width={224} />
                                        <Skeleton variant="text" width={256} />
                                    </div>
                                ) : (
                                    <Industries industry_codes_all_values={industry_codes_all_values} />
                                )}
                            </InfoRow>
                        )}
                        {(isNotEmpty(company_names) || (loading && loadingMode === organizationProfileType)) && (
                            <InfoRow label="Auxiliary names">
                                {loading ? (
                                    <div>
                                        <Skeleton variant="text" width={176} />
                                        <Skeleton variant="text" width={144} />
                                    </div>
                                ) : (
                                    <AuxiliaryNames company_names={company_names} />
                                )}
                            </InfoRow>
                        )}
                        {/* {(isNotEmpty(teleoperators) || (loading && loadingMode === organizationProfileType)) && (
                        <InfoRow label="Teleoperators">
                            {teleoperators ? (
                                <ExpandableRow
                                    rows={teleoperators.map((operator) => (
                                        <div key={operator.vid}>{operator.name}</div>
                                    ))}
                                />
                            ) : (
                                <div>
                                    <Skeleton variant="text" width={240} />
                                    <Skeleton variant="text" width={240} />
                                </div>
                            )}
                        </InfoRow>
                    )} */}
                    </InfoContainer>
                </BasicInfoContainer>
            </Frame>
        );
    },
);

export default BasicInfo;
