import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import Frame from 'components/tokens/Frame';

export const Card = styled(Frame)<{ is_parent?: string }>(({ is_parent, theme: { palette } }) => ({
    width: '100%',
    maxWidth: 360,
    height: 390,
    verticalAlign: 'top',
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 50ms',
    cursor: 'pointer',
    '& .card-select-box-diminished': {
        opacity: 0.5,
    },
    '&:hover': {
        transform: 'scale(1.03)',
        boxShadow: '0px 32px 32px rgba(10, 10, 10, 0.05), 0px 16px 16px rgba(10, 10, 10, 0.05)',
        // since targeting another component is not possible without customizing webpack/emotion engine, we are using className
        '& .company-card-background-image': {
            filter: 'saturate(1)',
        },
        '& .card-select-box-diminished': {
            opacity: 1,
        },
        '& .card-action svg': {
            fill: palette.primary.main,
        },
    },
}));

export const ImageFrame = styled('div')({
    height: '40%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
    position: 'relative',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 50ms',
});

export const Image = styled('div')<{ image: string; is_parent?: string }>(({ image, is_parent }) => ({
    backgroundImage: `linear-gradient(225deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.0001) 50%), url(${image})`,
    backgroundSize: 'cover',
    borderRadius: '0 0 40px 0',
    width: '100%',
    height: is_parent ? 150 : 170,
    position: 'absolute',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 50ms',
    bottom: 0,
    clipPath: is_parent ? `url(#parentClip)` : `url(#daughterClip)`,
    filter: 'saturate(0.5)',
}));

export const LogoAndOwner = styled('div')<{ is_parent?: string }>(({ is_parent }) => ({
    height: 50,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: is_parent ? 10 : 0,
    left: 24,
}));

export const ButtonContainer = styled(Stack)({
    padding: '0 20px 20px',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 50ms',
});

/** styling for exported to crm and additional target list data if present */
export const StyledCrmOwnerAndTargetList = styled('div')(({ theme: { palette } }) => ({
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 11,
    color: palette.text.strong,
    background: palette.field.hover,
    border: `4px solid ${palette.button.text}`,
    borderRadius: 15,
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 50ms',
    '&:hover': {
        borderColor: palette.field.hover,
    },
}));
