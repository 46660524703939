import React from 'react';

import ConfirmDialog from 'components/modules/connectors/common/ConfirmDialog';

interface RestartMatchingDialogProps {
    isOpen: boolean;
    onSubmit: () => void;
    handleDialogClose: () => void;
}

const RestartMatchingConfirmDialog: React.FC<RestartMatchingDialogProps> = ({
    isOpen,
    handleDialogClose,
    onSubmit,
}) => {
    return (
        <ConfirmDialog
            isSmall
            open={isOpen}
            title="Restart matching?"
            subTitle="If something went wrong, restart matching from the beginning."
            primaryButtonText="Restart"
            secondaryButtonText="Cancel"
            primaryButtonClick={onSubmit}
            secondaryButtonClick={handleDialogClose}
            handleClose={handleDialogClose}
        />
    );
};

export { RestartMatchingConfirmDialog };
