import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PipedriveOrganizationSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M10.0001 5.83333V2.5H1.66675V17.5H18.3334V5.83333H10.0001ZM5.00008 15.8333H3.33341V14.1667H5.00008V15.8333ZM5.00008 12.5H3.33341V10.8333H5.00008V12.5ZM5.00008 9.16667H3.33341V7.5H5.00008V9.16667ZM5.00008 5.83333H3.33341V4.16667H5.00008V5.83333ZM8.33342 15.8333H6.66675V14.1667H8.33342V15.8333ZM8.33342 12.5H6.66675V10.8333H8.33342V12.5ZM8.33342 9.16667H6.66675V7.5H8.33342V9.16667ZM8.33342 5.83333H6.66675V4.16667H8.33342V5.83333ZM16.6667 15.8333H10.0001V14.1667H11.6667V12.5H10.0001V10.8333H11.6667V9.16667H10.0001V7.5H16.6667V15.8333ZM15.0001 9.16667H13.3334V10.8333H15.0001V9.16667ZM15.0001 12.5H13.3334V14.1667H15.0001V12.5Z" />
    </SvgIcon>
);
export default PipedriveOrganizationSvg;
