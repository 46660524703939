import React from 'react';

import { styled, keyframes } from '@mui/material/styles';

type PulseDotAnimationProps = { color: string };

export const PulseDotAnimation = ({ color }: PulseDotAnimationProps) => (
    <StyledPulseWrapper>
        <StyledDot color={color} />
        <StyledPulse color={color} />
    </StyledPulseWrapper>
);

export default PulseDotAnimation;

const FadeLoopInfinite = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
`;

const PulseAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0.0;
  }
  25% {
    transform: scale(0);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.1);
    -webkit-transform: scale(0.1)
    opacity: 0.3;
  }
  75% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.0;
  }
`;

const StyledPulseWrapper = styled('div')`
    position: absolute;
    top: -7px;
    right: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    z-index: 1;
`;

const StyledDot = styled('div')`
    position: absolute;
    top: auto;
    left: auto;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: ${(props) => (props.color ? props.color : props.theme.palette.error.main)};
    animation: ${FadeLoopInfinite} 2s ease-out infinite;
    animation-fill-mode: both;
`;

const StyledPulse = styled('span')`
    background: transparent;
    height: 18px;
    width: 18px;
    z-index: 1;
    border: 3px solid ${(props) => (props.color ? props.color : props.theme.palette.error.main)};
    border-radius: 100%;
    animation: ${PulseAnimation} 2s ease-out infinite;
    animation-fill-mode: both;
`;
