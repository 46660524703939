import React, { useRef } from 'react';

import { Tooltip } from '@mui/material';

import Typography, { TypographyProps } from 'components/tokens/Typography';

import { useTextTruncation, TextTruncationProps } from './hooks/useTextTruncation';

type TextTruncateTooltipProps = Omit<TextTruncationProps, 'textTruncateChild' | 'isScrollable' | 'textContainerRef'> &
    TypographyProps & {
        showArrow?: boolean;
    };

export const TextTruncateTooltip: React.FC<TextTruncateTooltipProps> = ({
    text,
    line = 1,
    showTooltip,
    showArrow,
    variant = 'small',
    ...props
}) => {
    const containerRef = useRef<HTMLElement | null>(null);
    const [renderedText, { fullTextWidth, scopeWidth }] = useTextTruncation({
        text,
        line,
        showTooltip,
        textContainerRef: containerRef,
    });
    return showTooltip && fullTextWidth > scopeWidth ? (
        <Tooltip title={text} placement="top" arrow={showArrow}>
            <Typography
                ref={containerRef}
                variant={variant}
                sx={{ display: 'inline-block', width: '100%', height: 'inherit' }}
                {...props}
            >
                {renderedText}
            </Typography>
        </Tooltip>
    ) : (
        <Typography
            ref={containerRef}
            variant={variant}
            sx={{ display: 'inline-block', width: '100%', height: 'inherit' }}
            {...props}
        >
            {renderedText}
        </Typography>
    );
};
