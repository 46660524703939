import { IntegrationKey } from 'api/types/ApplicationStore';
import { ListMenuItemProps } from 'components/templates/ListMenu/ListMenuItem';

import { CrmName } from '../types/Crm';
import { getCRMName } from '../utils';

type CRMDropdownOption = { integration: IntegrationKey } & Required<
    Pick<ListMenuItemProps<CrmName>, 'action' | 'label' | 'icon'>
>;

export const CRMS_EXPORT_OPTIONS: CRMDropdownOption[] = [
    {
        integration: 'hubspot_oauth2',
        action: 'hubspot',
        label: getCRMName('hubspot'),
        icon: 'HubSpot',
    },
    {
        integration: 'dynamics_oauth2',
        action: 'dynamics',
        label: getCRMName('dynamics'),
        icon: 'Dynamics',
    },
    {
        integration: 'salesforce_oauth2',
        action: 'salesforce',
        label: getCRMName('salesforce'),
        icon: 'Salesforce',
    },
    {
        integration: 'salesforce_oauth2_sandbox',
        action: 'salesforcesandbox',
        label: getCRMName('salesforcesandbox'),
        icon: 'Salesforce',
    },
    {
        integration: 'pipedrive_oauth2',
        action: 'pipedrive',
        label: getCRMName('pipedrive'),
        icon: 'Pipedrive',
    },
];

export const EXPORT_SIZE_LIMIT = 10_000;
