import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import ErrorContent from 'components/modules/connectors/common/ErrorComponent/ErrorContent';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

type AdditionalNotificationDialogProps = {
    isOpened: boolean;
    title?: React.ReactNode;
    notificationType: string;
    errorMessage?: string;
    exportSize?: number;
    error?: string;
    onClose: (e: React.MouseEvent) => void;
};

const getNotificationTypeBasedData = ({
    notificationType,
    errorMessage,
    title,
    error,
    exportSize,
    onClose,
}: {
    notificationType: string;
    errorMessage?: string;
    title?: React.ReactNode;
    error?: string;
    exportSize?: number;
    onClose: React.MouseEventHandler;
}) => {
    switch (notificationType) {
        case 'CRMBulkExport':
        case 'CRMExport':
            return (
                <>
                    <BigIconWrapper>
                        <Box
                            sx={{
                                height: 100,
                                width: 100,
                                borderRadius: 50,
                                border: '3px solid',
                                borderColor: 'border',
                                position: 'relative',
                            }}
                        >
                            <Icon
                                type="Lock"
                                {...{ style: { position: 'absolute', height: 50, width: 50, top: '25%', left: '25%' } }}
                            />
                        </Box>
                    </BigIconWrapper>
                    <DialogContent>
                        <Typography variant="small" component="div" marginBottom={2}>
                            {notificationType === 'CRMBulkExport' && (
                                <Typography sx={{ color: '#ED2B6C', fontSize: '24px' }} weight="bold" component="span">
                                    {exportSize}
                                </Typography>
                            )}
                            <Typography sx={{ fontSize: '24px' }} weight="bold" component="span">
                                {notificationType === 'CRMBulkExport' ? ` companies` : 'Companies'} couldn’t be{' '}
                                {notificationType === 'CRMExport' ? 'updated' : 'exported'}
                            </Typography>
                        </Typography>
                        <ErrorContent error={error || ''} includeTitle disabledActions />
                        <Typography variant="small" weight="semibold" component="div" marginTop={2}>
                            Error Message:
                        </Typography>
                        <Box sx={{ backgroundColor: 'field.background', borderRadius: 1, margin: '8px 0', padding: 1 }}>
                            <Typography variant="tiny" sx={{ '& code': { wordWrap: 'break-word' } }}>
                                <code>{errorMessage}</code>
                            </Typography>
                        </Box>
                    </DialogContent>
                    <StyledMuiDialogActions>
                        <Button variant="flat" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="flat"
                            onClick={() =>
                                window.open(
                                    'https://help.vainu.app/en/articles/145627-crm-integration-error-messages',
                                    '_blank',
                                )
                            }
                        >
                            Check help guide
                        </Button>

                        <Button variant="primary" onClick={() => window.HubSpotConversations?.widget?.open()}>
                            Open chat
                        </Button>
                    </StyledMuiDialogActions>
                </>
            );
        case 'CRMImport':
            return (
                <Fragment>
                    <Typography variant="h4">You need an extra permission.</Typography>

                    <DialogContent></DialogContent>

                    <StyledMuiDialogActions>
                        <Button variant="flat" onClick={onClose}>
                            Cancel
                        </Button>
                    </StyledMuiDialogActions>
                </Fragment>
            );
        case 'CSVDownload':
            return (
                <Fragment>
                    <DialogTitle>{title ?? 'You need an extra permission.'}</DialogTitle>
                    <DialogContent>{errorMessage}</DialogContent>

                    <StyledMuiDialogActions>
                        <Button variant="flat" onClick={onClose}>
                            Cancel
                        </Button>
                    </StyledMuiDialogActions>
                </Fragment>
            );
        case 'CSVUpload':
            return (
                <Fragment>
                    <DialogTitle>You need an extra permission.</DialogTitle>
                    <DialogContent></DialogContent>

                    <StyledMuiDialogActions>
                        <Button variant="flat" onClick={onClose}>
                            Cancel
                        </Button>
                    </StyledMuiDialogActions>
                </Fragment>
            );
        case 'DynamicToStaticList':
            return (
                <Fragment>
                    <DialogTitle>You need an extra permission.</DialogTitle>
                    <DialogContent></DialogContent>

                    <StyledMuiDialogActions>
                        <Button variant="flat" onClick={onClose}>
                            Cancel
                        </Button>
                    </StyledMuiDialogActions>
                </Fragment>
            );
        case 'TriggerManualSend':
            return (
                <Fragment>
                    <DialogTitle>You need an extra permission.</DialogTitle>
                    <DialogContent></DialogContent>

                    <StyledMuiDialogActions>
                        <Button variant="flat" onClick={onClose}>
                            Cancel
                        </Button>
                    </StyledMuiDialogActions>
                </Fragment>
            );
        // case 'ListActivity': as default
        default:
            return (
                <Fragment>
                    <DialogTitle>You need an extra permission.</DialogTitle>
                    <DialogContent>{errorMessage}</DialogContent>

                    <StyledMuiDialogActions>
                        <Button variant="flat" onClick={onClose}>
                            Cancel
                        </Button>
                    </StyledMuiDialogActions>
                </Fragment>
            );
    }
};

const AdditionalNotificationDialog: React.FC<AdditionalNotificationDialogProps> = ({
    isOpened,
    notificationType,
    errorMessage,
    title,
    exportSize,
    error,
    onClose,
}) => {
    return (
        // TODO: Use token dialog instead?
        <StyledMuiDialog open={isOpened} onClose={onClose} maxWidth="xs">
            <CloseButtonWrapper>
                <Button
                    variant="flat"
                    size="large"
                    startIcon={<Icon type="CloseSmall" color="grey.500" />}
                    onClick={onClose}
                />
            </CloseButtonWrapper>
            {getNotificationTypeBasedData({ notificationType, onClose, errorMessage, title, error, exportSize })}
        </StyledMuiDialog>
    );
};

export default AdditionalNotificationDialog;

const CloseButtonWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));

const StyledMuiDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        borderRadius: 8,
        padding: '0 0 24px',
    },
});

const BigIconWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 32,
});

const StyledMuiDialogActions = styled(DialogActions)(() => ({
    padding: '0 24px',
}));
