import React from 'react';

import { Box, Tooltip } from '@mui/material';

import Icon from 'components/tokens/Icon';

type SeparateFileIndicatorProps = {
    fieldName: string;
};

const SeparateFileIndicator = ({ fieldName }: SeparateFileIndicatorProps) => {
    return (
        <Tooltip
            title={<span>You'll get a separate file with {fieldName} if you download a spreadsheet.</span>}
            placement="top"
            arrow
        >
            <Box
                sx={{
                    marginRight: 1,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Icon type="Document" color="text.dark" fontSize="small" />
            </Box>
        </Tooltip>
    );
};

export default SeparateFileIndicator;
