import React from 'react';

import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';
import clsx from 'clsx';

export type TypographyProps = Omit<MuiTypographyProps, 'color'> & {
    color?: 'default' | 'subtle' | 'dark';
    weight?: 'normal' | 'semibold' | 'bold';
    component?: React.ElementType;
};

export const Typography = React.forwardRef<HTMLElement, TypographyProps>(
    ({ component, className, color, weight, children, sx, ...props }, ref) => (
        <MuiTypography
            component={component as React.ElementType}
            className={clsx(color, weight, className)}
            sx={{
                color:
                    color === 'subtle'
                        ? 'text.subtle'
                        : color === 'dark'
                          ? 'text.strong'
                          : color === 'default'
                            ? 'text.primary'
                            : weight === 'bold' || weight === 'semibold'
                              ? 'text.strong'
                              : undefined,
                fontWeight:
                    weight === 'bold' ? 700 : weight === 'semibold' ? 500 : weight === 'normal' ? 400 : undefined,
                ...sx,
            }}
            {...props}
            ref={ref}
        >
            {children}
        </MuiTypography>
    ),
);

export default Typography;
