import { useCallback, useEffect, useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { formatISO } from 'date-fns';
import { useRecoilState } from 'recoil';

import { getAsyncProcesses, updateAsyncProcess } from 'api/asyncProcesses';
import { UserAsyncProcess, AsyncProcessType, GenericAsyncProcess } from 'api/types/UserAsyncProcess';
import { useAuthContext } from 'contexts/AuthContext';
import { useAxiosContext } from 'contexts/AxiosContext';
import { toDateUTC } from 'utilities/date';

import asyncProcessAtom from '../store/asyncProcessAtom';

type ExportAsyncProcessBasicParams = {
    jobId?: string;
};

type ExportAsyncProcessBasicParamsWithType<T extends GenericAsyncProcess> = ExportAsyncProcessBasicParams & {
    type: T['type'];
};

type ExportAsyncProcessBasicParamsWithInit<T extends GenericAsyncProcess> = ExportAsyncProcessBasicParamsWithType<T> & {
    initialAsyncProcessObj: Partial<T>;
};

type ExportAsyncProcessParams<T extends GenericAsyncProcess> =
    | ExportAsyncProcessBasicParams
    | (ExportAsyncProcessBasicParamsWithType<T> & {
          onUpdate?: (currentProcess: T) => void;
      })
    | false;

type GetCurrentProcessParams<T extends GenericAsyncProcess> = ExportAsyncProcessBasicParams & {
    processes: T[];
};

type GetCurrentProcessParamsWithType<T extends GenericAsyncProcess> = ExportAsyncProcessBasicParamsWithType<T> & {
    processes: T[];
};

type GetCurrentProcessParamsWithInit<T extends GenericAsyncProcess> = ExportAsyncProcessBasicParamsWithInit<T> & {
    processes: T[];
};

// Make return value as object instead of array
const useAsyncProcess = (): [State, Dispatch] => {
    const { authStatus } = useAuthContext();
    const axios = useAxiosContext();
    const [asyncProcesses, setAsyncProcesses] = useRecoilState(asyncProcessAtom);
    const timeoutHandle = useRef(0);

    const queryClient = useQueryClient();

    const cancelPolling = () => {
        window.clearTimeout(timeoutHandle.current);
    };

    const updateUserAsyncProcessStatus = useCallback(
        async (params: ExportAsyncProcessParams<GenericAsyncProcess>): Promise<() => void> => {
            if (params === false) {
                return cancelPolling;
            }
            const { jobId } = params;
            const type = 'type' in params ? params.type : undefined;
            const onUpdate = 'onUpdate' in params ? params.onUpdate : undefined;

            const { data: processes = [] } = await queryClient.fetchQuery({
                queryKey: ['getAllAsyncProcesses'],
                queryFn: () => getAsyncProcesses(axios, { limit: 100 }),
                staleTime: 0,
            });

            setAsyncProcesses(processes);

            // TypeScript function overloading needs this check. Not defined and undefined object attributes are different.
            const currentProcess = type
                ? getCurrentProcess({ processes, jobId, type })
                : getCurrentProcess({ processes, jobId });

            if (!currentProcess) {
                return cancelPolling;
            }

            if (onUpdate) {
                onUpdate(currentProcess);
            }

            if (
                currentProcess.state === 'completed' ||
                currentProcess.state === 'stopped' ||
                currentProcess.state === 'failure'
            ) {
                return cancelPolling;
            }

            timeoutHandle.current = window.setTimeout(() => {
                updateUserAsyncProcessStatus({ jobId, type, onUpdate });
            }, 3000);
            return cancelPolling;
        },
        [queryClient, axios, setAsyncProcesses],
    );

    const isUsersLoaded = useRef(false);

    useEffect(() => {
        // Load first time if async process state is empty
        if (!isUsersLoaded.current && !asyncProcesses?.length && authStatus === 'logged-in') {
            updateUserAsyncProcessStatus(false);
            isUsersLoaded.current = true;
        }
    }, [updateUserAsyncProcessStatus, asyncProcesses, authStatus]);

    /*
     * pollMultipleUserAsyncProcesses
     * Subscribe to multiple async processes using this function
     * If async process type is not enough, add other ways as you need
     */
    const pollMultipleUserAsyncProcesses = useCallback(
        async (types: string[] = []) => {
            if (!types?.length) {
                return;
            }
            const eachProcesses = types.map((type) => updateUserAsyncProcessStatus({ type }));
            await Promise.all(eachProcesses);
        },
        [updateUserAsyncProcessStatus],
    );

    const markAsyncProcessSeen = useCallback(
        async (jobId: string) => {
            await updateAsyncProcess(axios, jobId, { seen: true });
            setAsyncProcesses(
                asyncProcesses.map((each) => {
                    if (each.job_id === jobId) {
                        return {
                            ...each,
                            seen: true,
                        };
                    }
                    return each;
                }),
            );
        },
        [setAsyncProcesses, asyncProcesses, axios],
    );

    const state: State = {
        asyncProcesses,
    };
    const dispatch: Dispatch = {
        pollUserAsyncProcess: updateUserAsyncProcessStatus,
        pollMultipleUserAsyncProcesses,
        markAsyncProcessSeen,
    };

    return [state, dispatch];
};

export default useAsyncProcess;

export type State = {
    asyncProcesses: GenericAsyncProcess[];
};
export type Dispatch = {
    /** Starts polling for an async job. Returns a function that can be called to stop the polling, if polling was started. */
    pollUserAsyncProcess: (props: ExportAsyncProcessParams<GenericAsyncProcess>) => Promise<() => void>;
    pollMultipleUserAsyncProcesses: (jobTypes: AsyncProcessType[]) => Promise<void>;
    markAsyncProcessSeen: (jobId: string) => Promise<void>;
};

const formatUserAsyncProcessJob = <T extends GenericAsyncProcess>(processes: T[] = []) => {
    return processes.map((process: T) => ({
        ...process,
        created: formatISO(toDateUTC(process.created)),
    }));
};

function getCurrentProcess(params: GetCurrentProcessParams<GenericAsyncProcess>): GenericAsyncProcess;
function getCurrentProcess<T extends GenericAsyncProcess>(
    params: GetCurrentProcessParamsWithType<GenericAsyncProcess>,
): T;
function getCurrentProcess(params: GetCurrentProcessParamsWithInit<GenericAsyncProcess>): Partial<GenericAsyncProcess>;
function getCurrentProcess<T extends UserAsyncProcess>(
    params: GetCurrentProcessParams<T> | GetCurrentProcessParamsWithType<T> | GetCurrentProcessParamsWithInit<T>,
): GenericAsyncProcess | T | Partial<GenericAsyncProcess> | null {
    const { jobId, processes } = params;
    let currentProcess: GenericAsyncProcess | null = null;

    if (jobId) {
        // jobId and id is same for `public-{organizations | domains}-async-api` type
        const result = formatUserAsyncProcessJob(processes).find((i) => i.job_id === jobId || i.id === jobId);

        if (result) {
            currentProcess = { ...result };
        }
    } else if ('type' in params) {
        const result = formatUserAsyncProcessJob(processes).find<T>((i): i is T => i.type === params.type);
        if (result) {
            currentProcess = { ...result };
        }
    }

    return currentProcess;
}
