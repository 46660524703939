import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { uniqBy } from 'lodash';
import { isNil } from 'utilities';

import { USAGE_IS_ENABLED, useExportCount, useUsageInfo } from 'api/account';
import { Operation } from 'api/types/FilterOperators';
import { GenericAsyncProcess } from 'api/types/UserAsyncProcess';
import { DraggableListItemType } from 'components/templates/DragAndDrop';
import TreeSelectAndDragDrop from 'components/templates/TreeSelectAndDragDrop';
import { TreeRawDataRow } from 'components/templates/Treeview';
import { getDottedRootItems } from 'components/templates/Treeview/treeViewUtilities';
import Button from 'components/tokens/Button';
import Dialog, { DialogProps } from 'components/tokens/Dialog';
import Icon from 'components/tokens/Icon';
import { DefaultValueObjectsProps } from 'components/tokens/select-components/NestedSelect';
import { useFilterCompaniesContext } from 'contexts/FilterCompaniesContext/FilterCompaniesContext';
import { useListsContext } from 'contexts/ListsContext';
import { useNotification } from 'contexts/NotificationsContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import { createIntercomEvent } from 'utilities/createIntercomEvent';

import { PreviewStep } from './components/PreviewStep';
import {
    GLOBAL_CSV_EXPORT_ALWAYS_SELECTED_IDS,
    GLOBAL_TREE_SELECT_FIELDS,
    NORDIC_CSV_EXPORT_ALWAYS_SELECTED_IDS,
    NORDIC_TREE_SELECT_FIELDS,
} from './constants';
import { useGetListExportQuery } from './useGetListExportQuery';

export type ListExportDialogProps = Omit<DialogProps, 'onClose'> & {
    listId?: string;
    companyIds: string[];
    totalCount: number;
    loadingCount: boolean;
    onClose?: () => void;
    query?: Operation;
    usageCount: number;
    loadingUsageCount: boolean;
    scheduledCount?: boolean;
    filename?: string;
};

export const fileTypeOptions: DefaultValueObjectsProps[] = [
    { value: 'csv', label: 'CSV', object: 'csv', key: 'CSV' },
    { value: 'json', label: 'JSON', object: 'json', key: 'JSON' },
];

export const MIN_EXTRA_FILE_COUNT_TO_ZIP = 2;

enum DialogStep {
    Review = 'review',
    ChooseData = 'choose-data',
}

export const ListExportDialog: React.FC<ListExportDialogProps> = ({
    listId,
    totalCount,
    loadingCount,
    usageCount,
    loadingUsageCount,
    scheduledCount,
    companyIds,
    onClose,
    query,
    filename,
    ...props
}) => {
    const { tab } = useFilterCompaniesContext();

    const [order, setOrder] = useState<DraggableListItemType[]>([]);
    const [step, setStep] = useState<DialogStep>(DialogStep.ChooseData);
    const [selectedFileType, setSelectedFileType] = useState<DefaultValueObjectsProps>(fileTypeOptions[0]);
    const [selectedFileChip, setSelectedFileChip] = useState(0);

    const { selectedList, database } = useListsContext();
    const { profile, hasProductPermission } = usePermissionContext();
    const hasContactDataPermission = hasProductPermission(Permission.ContactData);

    const exportLimit = profile.export_limit;

    const isContactExport = tab === 'contacts';

    const isDomainDb = database === 'DOMAIN_DATA_BASIC';
    const fields = isDomainDb ? GLOBAL_TREE_SELECT_FIELDS : NORDIC_TREE_SELECT_FIELDS;
    const alwaysSelectedIds = useMemo(
        () =>
            isDomainDb
                ? GLOBAL_CSV_EXPORT_ALWAYS_SELECTED_IDS
                : [
                      ...NORDIC_CSV_EXPORT_ALWAYS_SELECTED_IDS,
                      ...(isContactExport ? ['ALL.Contacts'] : []),
                      ...(isContactExport && database === 'DK' ? ['ALL.BASIC.Direct marketing denied'] : []),
                  ],
        [database, isContactExport, isDomainDb],
    );

    const disabledItems = useMemo(
        () => [
            ...alwaysSelectedIds,
            ...(!hasContactDataPermission ? ['ALL.Contacts'] : []),
            // api doesn't support unwinding multiple fields when filtering one so disable them
            ...(isContactExport && !isDomainDb
                ? [...NORDIC_TREE_SELECT_FIELDS.filter(hasArgs).map((field) => field[0])]
                : []),
        ],
        [hasContactDataPermission, alwaysSelectedIds, isContactExport, isDomainDb],
    );

    const { setNotificationDrawerOpen } = useNotification();

    const { data: usageData } = useUsageInfo({ enabled: USAGE_IS_ENABLED });
    const { data: exportCountData } = useExportCount({ enabled: USAGE_IS_ENABLED });

    const rootItemsWithChildren: { [key: string]: string[] } = useMemo(() => getDottedRootItems(fields), [fields]);

    const defaultExpanded = useMemo(() => ['ALL'], []);

    const handleClose = () => {
        onClose?.();
    };

    const onProcessPoll = useCallback(({ state, download_link }: GenericAsyncProcess) => {
        if (state === 'completed' && download_link) {
            window.location.href = download_link;
        }
    }, []);

    const { downloadCSV } = useGetListExportQuery({
        listId,
        query,
        order,
        onPollUpdate: onProcessPoll,
        format: selectedFileType.value.toString(),
        limit: exportLimit,
        isDomainDb,
        count: loadingCount ? undefined : totalCount,
        database,
        isContactExport,
        filename,
    });

    const download = async () => {
        setNotificationDrawerOpen(true);
        handleClose();
        downloadCSV();
        if (isContactExport) {
            createIntercomEvent({
                eventName: 'Contacts exported - File',
            });
        }
    };

    const onContinueClick = () => {
        setSelectedFileChip(0);
        setStep(DialogStep.Review);
    };

    const extraFileFields = order.filter((field) => !isNil(field.args));
    const extraFileCount = uniqBy(extraFileFields, 'args').length;
    // Is there at least one field besides the always-selected fields
    const baseFileCount =
        order.length > extraFileFields.length + alwaysSelectedIds.length || extraFileFields.length === 0 ? 1 : 0;
    const zipFileCount = baseFileCount + (extraFileCount >= MIN_EXTRA_FILE_COUNT_TO_ZIP ? extraFileCount : 0);

    const exportQuota = usageData?.exported_companies_quota ?? Infinity;
    const exportQuotaUsed = exportCountData?.csv_exports ?? 0;

    const getConfirmButtonLabel = () => {
        if (step === DialogStep.ChooseData) {
            return 'Choose file type';
        }

        return `Download ${selectedFileType.label}${
            selectedFileType.value === 'csv' && zipFileCount > 1 ? `s (${zipFileCount})` : ''
        }`;
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            maxWidth="lg"
            title={step === DialogStep.ChooseData ? 'Choose your data' : 'Choose your file type'}
            customizedCancel={
                step === DialogStep.Review ? (
                    <Button
                        variant="secondary"
                        color="primary"
                        onClick={() => setStep(DialogStep.ChooseData)}
                        startIcon={<Icon type="ArrowShortLeft" />}
                        sx={{ marginRight: 'auto' }}
                    >
                        Change data
                    </Button>
                ) : (
                    <div />
                )
            }
            customizedConfirm={
                <Button
                    variant="primary"
                    color="primary"
                    disableElevation
                    disabled={order.length === 0}
                    onClick={step === DialogStep.ChooseData ? onContinueClick : download}
                    startIcon={step === DialogStep.Review && <Icon color="common.white" type="Download" />}
                    endIcon={step === DialogStep.ChooseData && <Icon color="common.white" type="ArrowShortRight" />}
                >
                    {getConfirmButtonLabel()}
                </Button>
            }
            sx={{
                '& .MuiDialog-paper': {
                    width: 1080,
                    maxWidth: '90%',
                    height: '90%',
                },
                '& .MuiDialogContent-root': {
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
            {...props}
        >
            <Box sx={{ marginBottom: 2 }}>
                {query ? (
                    'The file will download from notifications'
                ) : (
                    <>
                        Your list <b>{selectedList?.name || ''}</b> will download from notifications
                    </>
                )}
                {zipFileCount > 1 &&
                    step === DialogStep.Review &&
                    selectedFileType.value === 'csv' &&
                    ` as a zip file with ${zipFileCount} CSVs`}
                .
            </Box>

            {step === DialogStep.ChooseData ? (
                <TreeSelectAndDragDrop
                    data={fields}
                    rootItemsWithChildren={rootItemsWithChildren}
                    sortedItems={order}
                    disabledItems={disabledItems}
                    alwaysSelectedIds={alwaysSelectedIds}
                    onOrderChange={setOrder}
                    groupType="dot-separated"
                    excludeParents
                    defaultExpanded={defaultExpanded}
                />
            ) : (
                <PreviewStep
                    order={order}
                    companyIds={companyIds}
                    selectedFile={selectedFileChip}
                    selectedFileType={selectedFileType}
                    exportLimit={exportLimit}
                    scheduledCount={scheduledCount}
                    totalCount={totalCount}
                    usageCount={usageCount}
                    loadingUsageCount={loadingUsageCount}
                    onFileTypeChange={setSelectedFileType}
                    onSelectedFileChange={setSelectedFileChip}
                    alwaysSelectedIds={alwaysSelectedIds}
                    query={query}
                    exportQuota={exportQuota}
                    exportQuotaUsed={exportQuotaUsed}
                />
            )}
        </Dialog>
    );
};

const hasArgs = (field: TreeRawDataRow) => !!field[4];
