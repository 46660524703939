import { InvitedUser, Team } from 'api/types/Account';

export function getPrivilegeLabel(privilege: string) {
    switch (privilege) {
        case 'admin':
            return 'Admin';
        default:
            return 'Default';
    }
}

/**
 * Typeguard to check if passed user is either normal user or just an invited one.
 * @param user
 */
export function isTeamUser(user: Team | InvitedUser): user is Team {
    return 'user' in user;
}
