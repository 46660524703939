import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { uniq } from 'lodash';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import { TreeNode } from 'components/templates/Treeview';
import TreeContainer from 'components/templates/Treeview/TreeContainer';
import { buildTreeList, getParentItems, handleToggle } from 'components/templates/Treeview/treeViewUtilities';
import Dialog from 'components/tokens/Dialog';
import { TextTruncateTooltip } from 'components/tokens/TextTruncate';
import { useIndustryContext } from 'contexts/IndustriesContext';
import { useListsContext } from 'contexts/ListsContext';
import { createObjectFilter } from 'utilities/objectUtils';

import {
    FilterConfig,
    FilterDefinition,
    FilterID,
    FilterProps,
    GroupValues,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, getInnerMostValueInFilterState, valueArrayToText } from '../utils';

const FIELD = 'industry_codes_local';

type OnChangeHandler = ControllerRenderProps['onChange'];

export type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.industry_official,
    label: 'Industry (official)',
    defaultOperator: FilterOperator.IN,
    initValue: {
        [FilterOperator.IN]: {
            [FIELD]: [],
        },
    },
};

export const industryOfficialFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.industry,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        render: (props: FilterProps<TValues>) => <IndustryOfficial {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

/**
 * Dropdown selection was trimmed out from the release. So the tree view will open directly instead of dropdown options.
 */
export const IndustryOfficial: React.VFC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const [open, setOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<TreeNode[]>([]);
    const [tempSelectedItems, setTempSelectedItems] = useState<TreeNode[]>([]);
    const { control, getValues } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);
    const {
        industries,
        industryRootItemsWithDirectChildren,
        getAllRelatedOptionsForSelectedIndustries,
        getSelectedIndustriesLabel,
    } = useIndustryContext();
    const { database } = useListsContext();

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const industryValue = getValues(fullValuePath);

    const officialIndustries = database ? industries[database] : [];

    const matchedFilterValues = sameFiltersState.flatMap((sameState) =>
        getInnerMostValueInFilterState(sameState, filter.valuePath),
    );
    const disabledOptions = officialIndustries.filter((i) => matchedFilterValues.includes(i[0])).map((f) => f[0]);

    const selectedOptions = useMemo(
        () => getAllRelatedOptionsForSelectedIndustries(industryValue),
        [getAllRelatedOptionsForSelectedIndustries, industryValue],
    );

    const actualSelectedIndustriesLabel = useMemo(
        () => getSelectedIndustriesLabel(industryValue),
        [getSelectedIndustriesLabel, industryValue],
    );

    useEffect(() => {
        const ids: TreeNode[] = [];
        if (selectedOptions.length) {
            const selectedMainIndustries = selectedOptions.reduce((acc: string[], key) => {
                if (key[2] === 'codes_1') {
                    acc.push(key[0]);
                }
                return acc;
            }, []);

            const selectedIndustryWithChildren = createObjectFilter(
                industryRootItemsWithDirectChildren,
                selectedMainIndustries,
            );

            // since 'X' main industry doesn't have any children to display, it should
            // be removed from `selectedIndustryWithChildren`
            delete selectedIndustryWithChildren['X'];

            const tree = buildTreeList(selectedOptions, selectedIndustryWithChildren);

            for (const branch of tree) {
                handleToggle(branch, ids, false, [], (result) => {
                    for (const alphabetIndustryKey of Object.keys(selectedIndustryWithChildren)) {
                        // if top level industry is selected no need to remove second level and its children
                        if (!industryValue.includes(alphabetIndustryKey)) {
                            const secondLevelIndustryToRemove = selectedIndustryWithChildren[
                                alphabetIndustryKey
                            ].filter((industryKey) => !industryValue.includes(industryKey));
                            if (secondLevelIndustryToRemove.length) {
                                // eslint-disable-next-line no-loop-func
                                secondLevelIndustryToRemove.forEach((industryId) => {
                                    result = result.filter((treeNode) => {
                                        /**
                                         * check if the treeNode's id starts with second level industry code.
                                         * Or check if treeNode's id  starts with one of the selected 'value'
                                         */
                                        return (
                                            !treeNode.id.startsWith(industryId) ||
                                            industryValue.some((v: string) => treeNode.id.startsWith(v))
                                        );
                                    });
                                });
                            }
                            // to make sure the branch with main industry doesn't get added to selected items
                            result = result.filter((treeNode) => treeNode.id !== alphabetIndustryKey);
                        }
                    }
                    ids.push(...result);
                });
            }
        }
        const uniqIds = uniq(ids);
        setSelectedItems(uniqIds);
        setTempSelectedItems(uniqIds);
    }, [industryRootItemsWithDirectChildren, selectedOptions, industryValue]);

    const toggleSelection = (node: TreeNode) => {
        handleToggle(node, tempSelectedItems, false, [], (result) => {
            setTempSelectedItems(result);
        });
    };

    const handleTreeViewSubmit = (onChange: OnChangeHandler) => {
        setSelectedItems(tempSelectedItems);
        setOpen(false);
        const onlyParent = getParentItems(tempSelectedItems);

        onChange(onlyParent.map((selectedItem) => selectedItem.id));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setTempSelectedItems(selectedItems);
        setOpen(false);
    };

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-industry-select-value`}
                render={({ field }) => {
                    const { onChange, value: _value, ref: _ref, ...fieldProps } = field;

                    return (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: 'background.paper',
                                    paddingLeft: '12px',
                                    border: '1px solid',
                                    borderColor: 'border',
                                    borderRadius: '4px',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        cursor: 'text',
                                    },
                                }}
                                onClick={handleOpen}
                            >
                                <TextTruncateTooltip
                                    text={
                                        actualSelectedIndustriesLabel.length < 1
                                            ? 'Choose...'
                                            : actualSelectedIndustriesLabel.length === 1
                                              ? actualSelectedIndustriesLabel[0]
                                              : `${actualSelectedIndustriesLabel.length} options selected`
                                        // 'please select'
                                    }
                                    showTooltip
                                    showArrow
                                    component="p"
                                />
                            </Box>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                PaperProps={{ elevation: 5 }}
                                confirmLabel="Apply"
                                maxWidth="md"
                                onSubmit={() => handleTreeViewSubmit(onChange)}
                            >
                                <TreeContainer
                                    data={officialIndustries}
                                    disabledItems={disabledOptions}
                                    rootItemsWithDirectChildren={industryRootItemsWithDirectChildren}
                                    selectedRowIds={tempSelectedItems.map((selectedItem) => selectedItem.id)}
                                    onSelect={toggleSelection}
                                    {...fieldProps}
                                />
                            </Dialog>
                        </>
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
