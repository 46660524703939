import React from 'react';

import { Modal, Box } from '@mui/material';

import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';

export type CompanyProfilePopupProps = {
    open: boolean;
    onClose?: (event: React.MouseEvent) => void;
    scrollRef?: React.RefObject<HTMLDivElement>;
    children?: React.ReactNode;
};

export const CompanyProfilePopup: React.FC<CompanyProfilePopupProps> = ({ open, onClose, children, scrollRef }) => (
    <Modal open={open} onClose={onClose}>
        <Box
            sx={{
                width: '100vw',
                height: 'calc(100vh - 40px)',
                marginTop: 5,
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: -40,
                    right: 0,
                    display: 'flex',
                }}
            >
                <Button
                    sx={{
                        color: 'common.white',
                        padding: 0,
                        '&:hover': {
                            color: 'text.primary',
                        },
                        '& .MuiButton-startIcon .MuiSvgIcon-root': {
                            fontSize: 30,
                        },
                    }}
                    variant="flat"
                    startIcon={<Icon type="CloseBig" />}
                    onClick={onClose}
                />
            </Box>
            <Frame
                ref={scrollRef}
                variant="lightgrey"
                padding="none"
                sx={{
                    overflowY: 'scroll',
                    width: 'calc(100vw-10px)',
                    height: 'calc(100vh - 40px)',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }}
            >
                {children}
            </Frame>
        </Box>
    </Modal>
);

export default CompanyProfilePopup;
