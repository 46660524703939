import React from 'react';

import { styled } from '@mui/material';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

type NoUpdatesPermissionBannerProps = {
    handleUpgradeClick: () => void;
};

const NoUpdatesPermissionBanner: React.FC<NoUpdatesPermissionBannerProps> = ({ handleUpgradeClick }) => {
    return (
        <BannerContainer>
            <Icon type="Refresh02" color="link" sx={{ marginRight: '16px', width: 40, height: 40 }} />
            <div>
                <Typography variant="h4" mb={2}>
                    Enable updates!
                </Typography>
                <Typography variant="body2">
                    Upgrade your plan with Vainu and get access to Data Updates. This feature automatically identifies
                    outdated data in your CRM and replaces it with Vainu’s accurate and up-to-date information.
                </Typography>
                <ButtonsWrapper>
                    <Button
                        variant="tertiary"
                        component="a"
                        href="https://help.vainu.app/en/articles/145629-connector-data-updates"
                        target="_blank"
                    >
                        Read more
                    </Button>
                    <Button onClick={handleUpgradeClick}>Upgrade</Button>
                </ButtonsWrapper>
            </div>
        </BannerContainer>
    );
};

const BannerContainer = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    marginTop: '32px',
    padding: '40px 32px',
    borderRadius: '8px',
    background: palette.background.paper,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
}));

const ButtonsWrapper = styled('div')({
    width: 'fit-content',
    marginTop: '20px',
    marginLeft: 'auto',

    '& > button:last-child': {
        marginLeft: '10px',
    },
});

export { NoUpdatesPermissionBanner };
