import React, { useState } from 'react';

import { Box, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { filterArrayBySearch, isNotEmpty } from 'utilities';

import { List } from 'api/types/List';
import TargetListMenu from 'components/features/TargetListMenu';
import { useNavigateToList } from 'components/modules/profiles';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import NoValue from 'components/templates/SortableTable/NoValue';
import Button from 'components/tokens/Button';
import Checkbox from 'components/tokens/Checkbox';
import Dialog from 'components/tokens/Dialog';
import Icon from 'components/tokens/Icon';
import { Link } from 'components/tokens/Link';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';
import { UPLOAD_FILE_VALUE, useListsContext } from 'contexts/ListsContext';
import { useTrigger } from 'contexts/TriggerContext';
import { ListAction } from 'contexts/utilities/listsUtils';

import { ListNameColumn } from './ListNameColumn';
import { filterListsByType, isReadonlyPermission, isSharedTargetList } from './utils';

export type ListCategory =
    | 'default'
    | 'shared'
    | 'dynamic-legacy'
    | 'static-legacy'
    | 'shared-legacy'
    | typeof UPLOAD_FILE_VALUE;

type ManageListProps = {
    type: ListCategory;
    onClose?: () => void;
};

type RemoveConfirmationInfo = {
    title: string;
    subtitle: string;
    subtitle2?: React.ReactElement;
    isMultiple: boolean;
    listName?: string;
};

type ListRow = Pick<List, 'name' | 'id' | 'created' | 'modified'> & {
    list: List;
    isSelected: boolean;
    labelId: string;
    isShared: boolean;
    permission: string;
};

const getLabelId = (id: string) => `target-list-dialog-checkbox-${id}`;

const ManageLists: React.FC<ManageListProps> = ({ type, onClose }) => {
    const [, , { triggers, updateTrigger }] = useTrigger();
    const { dbLists, deleteLists, doListAction, migrateLegacyList } = useListsContext();
    const [searchText, setSearchText] = useState('');
    const [selected, setSelected] = useState<string[]>([]);
    const [removeConfirmationInfo, setRemoveConfirmationInfo] = useState<RemoveConfirmationInfo | undefined>(undefined);
    const [isMigrating, setIsMigrating] = useState(false);
    const [showMigrationConfirm, setShowMigrationConfirm] = useState(false);
    const navigateToList = useNavigateToList();

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const shownLists = filterArrayBySearch(filterListsByType(dbLists, type), searchText, ['name']);
    const listRows: ListRow[] = shownLists.map((list: List, index: number) => {
        const labelId = `target-list-dialog-checkbox-${index}`;
        return {
            ...list,
            list,
            isSelected: isSelected(list.id),
            labelId,
            isShared: type !== 'shared' && type !== 'shared-legacy' && isSharedTargetList(list),
            permission: ['shared', 'shared-legacy'].includes(type)
                ? isReadonlyPermission(list)
                    ? 'View only'
                    : 'Edit'
                : '',
        };
    });

    const handleTargetListFilterByName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = shownLists.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleDeleteAllSelected = () => {
        deleteLists(selected);
        setSelected([]);
    };

    const handleRowClick = (_event: React.MouseEvent<unknown>, list: ListRow) => {
        onClose && onClose();
        navigateToList(list.id);
    };

    const handleCheckboxClick = (event: React.MouseEvent<unknown>, id: string) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleRemoveListAction = (id: string) => {
        if (selected.includes(id)) {
            setSelected(selected.filter((i) => i !== id));
        }
    };

    const handleListAction = (listId: string, action: ListAction, fields?: Partial<List>) => {
        if (['remove', 'delete'].includes(action)) {
            handleRemoveListAction(listId);
        }
        doListAction(listId, action, fields);
    };

    const activeTriggers = triggers.filter(
        (trigger) =>
            trigger.status === 'active' && selected.includes(trigger.workflow_settings.target_group_source || ''),
    );

    const openDialog = (multiple: boolean) => {
        if (type === 'shared' || type === 'shared-legacy') {
            if (multiple) {
                setRemoveConfirmationInfo({
                    title: `Remove ${selected.length} shared lists?`,
                    subtitle:
                        'You will lose access to these lists. Other team members will still be able to access them.',
                    isMultiple: true,
                });
            } else {
                const selectedList = dbLists.find((list) => list.id === selected[0]);
                if (selectedList) {
                    setRemoveConfirmationInfo({
                        title: 'Remove shared list?',
                        subtitle:
                            'You will lose access to $listName. Other team members will still be able to access it.',
                        listName: selectedList.name,
                        isMultiple: false,
                    });
                }
            }
        } else {
            const subtitle2 = activeTriggers.length ? (
                <>
                    <Tooltip
                        title={
                            <ul>
                                {activeTriggers.map((trigger) => (
                                    <li key={trigger.id}>&middot; {trigger.name}</li>
                                ))}
                            </ul>
                        }
                    >
                        <Link href="#" sx={{ color: 'text-dark' }} onClick={(e) => e.preventDefault()}>
                            {activeTriggers.length} trigger{activeTriggers.length > 1 ? 's' : ''}
                        </Link>
                    </Tooltip>{' '}
                    will be paused.
                </>
            ) : undefined;

            if (multiple) {
                setRemoveConfirmationInfo({
                    title: `Delete ${selected.length} lists?`,
                    subtitle: 'These lists will be permanently deleted.',
                    subtitle2,
                    isMultiple: true,
                });
            } else {
                const selectedList = dbLists.find((list) => list.id === selected[0]);
                if (selectedList) {
                    setRemoveConfirmationInfo({
                        title: 'Delete list?',
                        subtitle: '$listName will be permanently deleted.',
                        subtitle2,
                        listName: selectedList.name,
                        isMultiple: false,
                    });
                }
            }
        }
    };

    const handleConfirmDialog = (multiple: boolean) => {
        activeTriggers.map((trigger) =>
            updateTrigger(trigger.id || '', {
                status: 'disabled',
                workflow_settings: { ...trigger.workflow_settings, target_group_source: null },
            }),
        );
        if (multiple) {
            handleDeleteAllSelected();
        } else {
            handleListAction(selected[0], 'remove');
        }
        setRemoveConfirmationInfo(undefined);
    };

    const migrate = async () => {
        setIsMigrating(true);
        try {
            await Promise.all(
                selected.map((id) => {
                    const list = dbLists.find((list) => list.id === id);
                    if (!list) {
                        return null;
                    }
                    return migrateLegacyList(list);
                }),
            );
        } catch (e) {}
        setIsMigrating(false);
        onClose?.();
    };

    return (
        <>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', minWidth: 560 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 3,
                        height: 45,
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 100,
                    }}
                >
                    <TextField
                        icon="Search"
                        compact
                        sx={{ width: 312 }}
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleTargetListFilterByName}
                    />

                    {isNotEmpty(selected) && (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="secondary"
                                size="medium"
                                startIcon={
                                    <Icon type={type === 'shared' ? 'OffOutlineClose' : 'TrashFull'} fontSize="small" />
                                }
                                onClick={() => openDialog(selected.length > 1 ? true : false)}
                            >
                                {['shared', 'shared-legacy'].includes(type) ? 'Remove' : 'Delete'}{' '}
                                {selected.length > 1 && `(${selected.length})`}
                            </Button>

                            {['dynamic-legacy', 'static-legacy'].includes(type) && (
                                <>
                                    <Button
                                        startIcon={<Icon type="ArrowShortRight" />}
                                        disabled={isMigrating}
                                        onClick={async () => {
                                            const hasErrors = selected.some(
                                                (id) => dbLists.find((list) => list.id === id)?.converted_query?.errors,
                                            );
                                            if (hasErrors) {
                                                setShowMigrationConfirm(true);
                                            } else {
                                                migrate();
                                            }
                                        }}
                                    >
                                        Migrate
                                    </Button>

                                    {showMigrationConfirm && (
                                        <Dialog
                                            open
                                            title="Some of the filters can't be migrated"
                                            onClose={() => setShowMigrationConfirm(false)}
                                            confirmLabel="Migrate"
                                            onSubmit={() => {
                                                migrate();
                                                setShowMigrationConfirm(false);
                                            }}
                                        >
                                            Are you sure you want to migrate? Some filters will be lost.
                                        </Dialog>
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                </Box>
                <SortableTable<ListRow>
                    rowKeyField="id"
                    data={listRows}
                    defaultSortOrder="desc"
                    defaultSortColumn="modified"
                    onRowClick={handleRowClick}
                    rowSx={{
                        height: 48,
                        '& .SortableTable-cell-name': { color: 'text.strong' },
                        '&:hover': {
                            '.SortableTable-cell-name': { color: 'link' },
                        },
                    }}
                    cellSx={{ fontSize: 14 }}
                >
                    <SortableTableColumn<ListRow>
                        width={48}
                        sortable={false}
                        checkbox
                        label={
                            <Checkbox
                                checked={selected.length > 0}
                                indeterminate={selected.length > 0 && selected.length !== shownLists.length}
                                onChange={handleSelectAllClick}
                            />
                        }
                        field="isSelected"
                        sx={{ paddingY: 0, paddingLeft: 1, paddingRight: 0 }}
                        headerSx={{ paddingY: 0, paddingLeft: 1, paddingRight: 0 }}
                    >
                        {({ id, isSelected }) => (
                            <Checkbox
                                onClick={(e) => handleCheckboxClick(e, id)}
                                checked={isSelected}
                                inputProps={{ 'aria-labelledby': getLabelId(id) }}
                            />
                        )}
                    </SortableTableColumn>
                    <SortableTableColumn<ListRow>
                        width={290}
                        label="List name"
                        field="name"
                        sx={{ paddingLeft: 0.5 }}
                        headerSx={{ paddingLeft: 0.5 }}
                    >
                        {(props) => <ListNameColumn {...props} />}
                    </SortableTableColumn>
                    {type === 'shared' ? (
                        <SortableTableColumn<ListRow> label="Permission" field="permission" />
                    ) : (
                        <SortableTableColumn<ListRow> label="Shared" field="isShared">
                            {({ isShared }) => (isShared ? 'Yes' : <NoValue />)}
                        </SortableTableColumn>
                    )}
                    <SortableTableColumn<ListRow> label="Last modified" field="modified">
                        {({ modified }) => format(new Date(modified), 'P')}
                    </SortableTableColumn>
                    <SortableTableColumn<ListRow> label="Created" field="created">
                        {({ created }) => format(new Date(created), 'P')}
                    </SortableTableColumn>
                    <SortableTableColumn<ListRow>
                        width={64}
                        sortable={false}
                        field="list"
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            paddingY: 0,
                            paddingRight: 3,
                            overflow: 'visible',
                        }}
                    >
                        {({ list }) => {
                            const isOwner = list.privileges.current === 'owner';
                            const hasEditPermission = list.privileges.current === 'edit';
                            return (
                                <TargetListMenu
                                    anchorElement={
                                        <Button
                                            variant="tertiary"
                                            size="small"
                                            startIcon={<Icon type="MoreHorizontal" />}
                                        />
                                    }
                                    isOwner={isOwner}
                                    shareList={isOwner || hasEditPermission}
                                    list={list}
                                    onClick={(action, fields) => handleListAction(list.id, action, fields)}
                                />
                            );
                        }}
                    </SortableTableColumn>
                </SortableTable>
            </Box>
            {!!removeConfirmationInfo && (
                <Dialog
                    PaperProps={{
                        sx: {
                            minWidth: 420,
                        },
                    }}
                    open={!!removeConfirmationInfo}
                    title={removeConfirmationInfo.title}
                    cancelLabel="Cancel"
                    onClose={() => setRemoveConfirmationInfo(undefined)}
                    customizedConfirm={
                        <Button
                            variant={type === 'shared' ? 'primary' : 'critical'}
                            onClick={() => handleConfirmDialog(removeConfirmationInfo.isMultiple)}
                        >
                            {type === 'shared' ? 'Remove' : 'Delete'}
                        </Button>
                    }
                >
                    <Typography
                        variant="small"
                        color="subtle"
                        sx={{
                            '&>span': {
                                color: 'text.strong',
                                fontWeight: '700',
                            },
                        }}
                    >
                        {(() => {
                            const [start, end] = removeConfirmationInfo.subtitle.split('$listName');
                            return (
                                <>
                                    {start}
                                    <span>{removeConfirmationInfo.listName}</span>
                                    {end}
                                </>
                            );
                        })()}
                    </Typography>

                    {removeConfirmationInfo.subtitle2 && (
                        <Typography variant="small" color="subtle" component="div" sx={{ marginTop: 2 }}>
                            {removeConfirmationInfo.subtitle2}
                        </Typography>
                    )}
                </Dialog>
            )}
        </>
    );
};

export default ManageLists;
