import { useEffect, useMemo, useState } from 'react';

import { Box, Skeleton, Tooltip } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import Menu, { MenuItem } from 'components/templates/Menu';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { brandPalette } from 'design/theme/colors';

import { TileBase, TileData } from '../types';
import Legend from './Legend';
import SingleDatapoint from './SingleDatapoint';
import Tile from './Tile';

interface PieChartTileProps extends Omit<TileBase, 'data'> {
    data: {
        [key: string]: {
            label: string;
            displayLabel?: string;
            data: TileData[];
            tooltip?: React.ReactNode;
        };
    };
}

const COLORS = [
    brandPalette.primaryGreen500,
    brandPalette.secondaryPurple700,
    brandPalette.onyx,
    brandPalette.subtleLight,
    brandPalette.tertiaryBlue,
    brandPalette.white,
];

const PieChartTile: React.FC<PieChartTileProps> = ({ data, total, title, sx }) => {
    const options = useMemo(
        () =>
            Object.keys(data).map((key) => ({
                ...data[key],
                action: key,
            })),
        [data],
    );

    const [selected, setSelected] = useState<string>(options[0].action);

    useEffect(() => {
        if (!options.find((option) => option.action === selected)) {
            setSelected(options[0].action);
        }
    }, [options, selected]);

    const visibleData = data[selected]?.data;

    const selectedOption = options.find((option) => option.action === selected);

    return (
        <Tile
            title={title}
            sx={sx}
            control={
                options.length > 1 ? (
                    <Menu
                        anchorElement={
                            <Button
                                variant="secondary"
                                endIcon={<Icon fontSize="mini" type="ChevronDown" />}
                                sx={{ borderColor: 'border' }}
                            >
                                {selectedOption?.displayLabel || selectedOption?.label}
                            </Button>
                        }
                        items={[]}
                        onClick={(value) => setSelected(value)}
                        selectedAction={selected}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        sx={{ width: 230 }}
                    >
                        {options.map((option) => (
                            <Tooltip title={option.tooltip} key={option.action} placement="right">
                                <div>
                                    <MenuItem
                                        isSelected={option.action === selected}
                                        onClick={(e, action) => setSelected(action as string)}
                                        {...option}
                                    />
                                </div>
                            </Tooltip>
                        ))}
                    </Menu>
                ) : null
            }
        >
            {selectedOption?.data.length === 1 ? (
                <SingleDatapoint data={selectedOption.data[0]} />
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', '& > *': { flex: 1 } }}>
                    <ResponsiveContainer width="100%" height={128}>
                        {!total ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                            >
                                <Skeleton variant="circular" sx={{ width: 128, height: 128 }} />
                                <Box
                                    sx={{
                                        width: 88,
                                        height: 88,
                                        borderRadius: '50%',
                                        position: 'absolute',
                                        top: 'calc(50% - 44px)',
                                        left: 'calc(50% - 44px)',
                                        background: '#fff',
                                    }}
                                />
                            </Box>
                        ) : (
                            <PieChart width={200} height={200}>
                                <Pie
                                    data={visibleData}
                                    dataKey="value"
                                    nameKey="label"
                                    innerRadius={44}
                                    outerRadius={64}
                                    startAngle={90}
                                    endAngle={-270}
                                >
                                    {visibleData?.map((entry, index) => (
                                        <Cell key={index} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        )}
                    </ResponsiveContainer>
                    {visibleData && <Legend data={visibleData} total={total} colors={COLORS} />}
                </Box>
            )}
        </Tile>
    );
};

export default PieChartTile;
