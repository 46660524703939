import React from 'react';

const BannerLeftBoxes = (props) => (
    <svg width="342" height="87" viewBox="0 0 342 87" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.710938 8C0.710938 3.58172 4.29266 0 8.71094 0H34.1615C38.5798 0 42.1615 3.58172 42.1615 8V15.698H55.479C59.8973 15.698 63.479 19.2797 63.479 23.698V57.2074C63.479 61.6257 59.8973 65.2074 55.479 65.2074H21.7383C17.32 65.2074 13.7383 61.6257 13.7383 57.2074V42.2642H8.71095C4.29267 42.2642 0.710938 38.6824 0.710938 34.2642V8ZM341.031 31C341.031 26.5817 337.45 23 333.031 23H287.244C282.826 23 279.244 26.5817 279.244 31V79C279.244 83.4183 282.826 87 287.244 87H333.031C337.45 87 341.031 83.4183 341.031 79V31Z"
        />
    </svg>
);

export default BannerLeftBoxes;
