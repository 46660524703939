import React from 'react';

import {
    isSitewideNotification,
    Notification,
    SitewideNotification as SitewideNotificationType,
} from 'api/types/Notification';
import {
    UserAsyncProcess,
    isCRMBulkExportAsyncProcess,
    isCRMImportAsyncProcess,
    isDynamicToStaticListAsyncProcess,
    isImportListAsyncProcess,
    isListDataV3AsyncProcess,
    isSendMessageQueueItemsAsyncProcess,
} from 'api/types/UserAsyncProcess';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { IconType } from 'components/tokens/Icon';

import CRMBulkExport from './items/CRMBulkExport';
import CRMExport from './items/CRMExport';
import CRMImport from './items/CRMImport';
import CSVDownload from './items/CSVDownload';
import CSVUpload from './items/CSVUpload';
import DynamicToStaticList from './items/DynamicToStaticList';
import SitewideNotification from './items/SitewideNotification';
import TriggerManualSend from './items/TriggerManualSend';

export const crmLogo: Record<CrmName, IconType> = {
    dynamics: 'Dynamics',
    hubspot: 'HubSpot',
    pipedrive: 'Pipedrive',
    salesforce: 'Salesforce',
    salesforcesandbox: 'Salesforce',
};

type BaseNotificationType = {
    onClick: (notificationId: string) => void;
    onDismiss: (notificationId: string) => void;
    handleMarkAsSeen: () => void;
};

export type AsyncProcessNotificationProps<T extends UserAsyncProcess = UserAsyncProcess> = {
    notification: T;
} & BaseNotificationType;

export type SitewideNotificationProps = {
    notification: SitewideNotificationType;
} & BaseNotificationType;

export type NotificationItemProps = {
    notification: Notification;
} & BaseNotificationType;

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, ...props }) => {
    if (isSitewideNotification(notification)) {
        return <SitewideNotification notification={notification} {...props} />;
    }
    if (isCRMBulkExportAsyncProcess(notification)) {
        if (notification.meta_data.enrich_type === 'automatic') {
            return <CRMExport notification={notification} {...props} />;
        }
        return <CRMBulkExport notification={notification} {...props} />;
    }
    if (isCRMImportAsyncProcess(notification)) {
        return <CRMImport notification={notification} {...props} />;
    }
    if (isListDataV3AsyncProcess(notification)) {
        return <CSVDownload notification={notification} {...props} />;
    }
    if (isImportListAsyncProcess(notification)) {
        return <CSVUpload notification={notification} {...props} />;
    }
    if (isDynamicToStaticListAsyncProcess(notification)) {
        return <DynamicToStaticList notification={notification} {...props} />;
    }
    if (isSendMessageQueueItemsAsyncProcess(notification)) {
        return <TriggerManualSend notification={notification} {...props} />;
    }
    console.warn(`Unhandled notification type: ${notification.type}`);
    return null;
};

export default NotificationItem;
