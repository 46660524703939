import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Mail = (props) => (
    <SvgIcon {...props}>
        <path d="M20 20H4C2.89543 20 2 19.1046 2 18V5.913C2.04661 4.84255 2.92853 3.99899 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20ZM4 7.868V18H20V7.868L12 13.2L4 7.868ZM4.8 6L12 10.8L19.2 6H4.8Z" />
    </SvgIcon>
);

export default Mail;
