import React from 'react';

import { Tooltip } from '@mui/material';

import { createFilterWithValue } from 'components/features/Filters/filters/Industry/createFilterWithValue';
import Link from 'components/tokens/Link';
import ScoreIndicator from 'components/tokens/ScoreIndicator';
import { useListsContext } from 'contexts/ListsContext';
import { findVCIId } from 'utilities/profiles/constants/vainuCustomIndustryOptions';

type CustomIndustryLinkProps = {
    value: string;
    score?: number;
};
export const CustomIndustryLink: React.VFC<CustomIndustryLinkProps> = ({ value, score }) => {
    const { createNewList, generateListName, database } = useListsContext();

    const handleClick = () => {
        const id = findVCIId(value);
        if (id) {
            const filter = createFilterWithValue({
                fact_vid: {
                    operator: '?EQ',
                    value: id,
                },
                confidence_class: {
                    operator: '?EQ',
                    value: 1,
                },
            });

            if (!filter) {
                return;
            }

            const query = JSON.stringify({
                '?ALL': [filter],
            });
            const name = generateListName(value);

            createNewList(database === 'DOMAIN_DATA_BASIC' ? 'dynamic-domain-list' : 'dynamic-organization-list', {
                query,
                name,
            });
        }
    };

    return (
        <div>
            <Tooltip
                enterDelay={500}
                enterNextDelay={1500}
                placement="right"
                title={`Create a new list searching for domains with ${value} Vainu Custom Industries`}
            >
                <Link onClick={handleClick}>{value}</Link>
            </Tooltip>
            {score !== undefined && <ScoreIndicator score={score} sx={{ marginLeft: 1 }} />}
        </div>
    );
};

export default CustomIndustryLink;
