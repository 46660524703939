import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Grid = (props) => (
    <SvgIcon {...props}>
        <path d="M20 20H16V16H20V20ZM14 20H10V16H14V20ZM8 20H4V16H8V20ZM20 14H16V10H20V14ZM14 14H10V10H14V14ZM8 14H4V10H8V14ZM20 8H16V4H20V8ZM14 8H10V4H14V8ZM8 8H4V4H8V8Z" />
    </SvgIcon>
);

export default Grid;
