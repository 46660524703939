import { useMemo } from 'react';

import { SvgIcon } from '@mui/material';

import Icon, { IconProps } from '..';

export type TriggersIconState = 'normal' | 'active' | 'paused' | 'upgrade';
export type TriggersIconProps = {
    state: TriggersIconState;
} & Omit<IconProps, 'type'>;

/**
 * Renders the Triggers Icon with a status indicator.
 * @param {string} state - Determines the indicator's color and shape:
 *  - 'normal' (default): No indicator
 *  - 'active': Green dot
 *  - 'paused': Orange dot
 *  - 'upgrade': Plus icon
 */
export const TriggersIcon: React.FC<TriggersIconProps> = ({ state = 'normal', sx, color, ...props }) => {
    const dotSx = useMemo(() => {
        switch (props.fontSize) {
            case 'mini':
                return { height: 16, width: 16, marginLeft: -2 };
            case 'medium':
                return { height: 24, width: 24, marginLeft: -3 };
            case 'large':
                return { height: 35, width: 35, marginLeft: -4 };
            case 'small':
            default:
                return { height: 20, width: 20, marginLeft: -2.5 };
        }
    }, [props.fontSize]);

    switch (state) {
        case 'normal':
        default:
            return <Icon {...props} type="Triggers" sx={{ ...sx }} />;
        case 'active':
            return (
                <SvgIcon {...props}>
                    <Icon {...props} type="Triggers" sx={sx} color={color} />
                    <Icon {...props} type="TriggersDot" color="success.main" sx={{ ...sx, ...dotSx }} />
                </SvgIcon>
            );
        case 'paused':
            return (
                <SvgIcon {...props}>
                    <Icon {...props} type="Triggers" sx={sx} color={color} />
                    <Icon {...props} type="TriggersDot" color="warning.main" sx={{ ...sx, ...dotSx }} />
                </SvgIcon>
            );
        case 'upgrade':
            return <Icon {...props} type="TriggersUpgrade" sx={sx} color={color} />;
    }
};

export default TriggersIcon;
