import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Upload: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="m 10.75,1.5 -6,6 1.41,1.41 3.59,-3.58 V 17.5 h 2 V 5.33 l 3.59,3.58 1.41,-1.41 z" />
        <path d="M 14,20 H 0 v -2 h 14 z" transform="translate(3.75,1.5)" />
    </SvgIcon>
);

export default Upload;
