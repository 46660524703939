import React from 'react';

import { styled } from '@mui/material';

import { getImage } from 'components/modules/profiles/Profile/utils';
import Avatar, { AvatarProps } from 'components/tokens/Avatar';

import LetterLogoGenerator from '../LetterLogoGenerator';

export type ContactAvatarProps = Omit<AvatarProps, 'children'> & {
    avatar?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    id?: string | null;
};

const StyledImg = styled('img')({
    display: 'block',
});

export const ContactAvatar: React.FC<ContactAvatarProps> = ({
    avatar,
    border = true,
    borderColor = 'grey',
    id,
    first_name,
    last_name,
    skeleton = false,
    ...props
}) => {
    const name = `${first_name} ${last_name}`;
    return skeleton ? (
        <Avatar skeleton {...props} border={false} />
    ) : (
        <Avatar border={border} borderColor={borderColor} {...props}>
            {avatar ? (
                <StyledImg src={getImage(avatar)} alt={name} />
            ) : (
                <LetterLogoGenerator variant="initials" id={id} firstName={first_name} lastName={last_name} />
            )}
        </Avatar>
    );
};
export default ContactAvatar;
