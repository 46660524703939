import React from 'react';

import ConfirmDialog from '../../common/ConfirmDialog';

type RemoveMappingDialogProps = {
    isOpen: boolean;
    crmName: string;
    crmPropertyLabel: string;
    onSubmit: () => void;
    handleDialogClose: () => void;
};

const RemoveMappingDialog: React.FC<RemoveMappingDialogProps> = ({
    isOpen,
    crmName,
    crmPropertyLabel,
    onSubmit,
    handleDialogClose,
}) => {
    return (
        <ConfirmDialog
            data-testid="remove-mapping-dialog"
            isSmall
            open={isOpen}
            title={`Remove ${crmPropertyLabel} from Vainu?`}
            subTitle={`This field will still exist in ${crmName}, but it will no longer be filled by Vainu when sending lists, triggers, or data updates.`}
            primaryButtonText="Remove"
            secondaryButtonText="Keep"
            primaryButtonClick={onSubmit}
            secondaryButtonClick={handleDialogClose}
            handleClose={handleDialogClose}
        />
    );
};

export { RemoveMappingDialog };
