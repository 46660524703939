import { Box } from '@mui/material';

import HeaderActions, { HeaderActionsProps } from './components/HeaderActions';
import LogoNameAndIndustries, { LogoNameAndIndustriesProps } from './components/LogoNameAndIndustries';

export type SidebarHeaderProps = LogoNameAndIndustriesProps &
    HeaderActionsProps & {
        showHeaderActions?: boolean;
    };

// TODO: Change component name, it is not only sidebar
export const SidebarHeader: React.FC<SidebarHeaderProps> = ({ showHeaderActions = false, ...props }) => {
    return showHeaderActions ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
            }}
        >
            <LogoNameAndIndustries {...props} />
            <HeaderActions {...props} />
        </Box>
    ) : (
        <LogoNameAndIndustries {...props} />
    );
};

export default SidebarHeader;
