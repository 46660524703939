import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Tag = (props) => (
    <SvgIcon {...props}>
        <path d="M12.897 21.968C12.3663 21.9696 11.857 21.7585 11.483 21.382L3.646 13.547C3.23247 13.1349 3.02047 12.5621 3.066 11.98L3.566 5.41401C3.63742 4.42569 4.42541 3.64068 5.414 3.57301L11.98 3.07301C12.032 3.06201 12.083 3.06201 12.135 3.06201C12.6647 3.06138 13.1726 3.27234 13.546 3.64801L21.383 11.482C21.7582 11.8571 21.9691 12.3659 21.9691 12.8965C21.9691 13.4271 21.7582 13.9359 21.383 14.311L14.311 21.382C13.9368 21.7583 13.4277 21.9693 12.897 21.968ZM8.654 6.65401C7.74882 6.65427 6.95666 7.26245 6.72259 8.13684C6.48853 9.01123 6.87091 9.93383 7.65491 10.3863C8.43891 10.8387 9.42904 10.7082 10.069 10.068L10.076 10.062L10.083 10.055L10.075 10.062C10.6434 9.48857 10.8113 8.62957 10.5006 7.88432C10.1898 7.13907 9.46143 6.65377 8.654 6.65401Z" />
    </SvgIcon>
);

export default Tag;
