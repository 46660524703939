import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SalesforceOpportunitySvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M16.25 15.4546V16.0606C16.25 16.3954 15.9702 16.6667 15.625 16.6667H4.375C4.02982 16.6667 3.75 16.3954 3.75 16.0606V15.4546C3.75 15.1199 4.02982 14.8485 4.375 14.8485H15.625C15.9702 14.8485 16.25 15.1199 16.25 15.4546ZM16.25 4.5455C15.3409 4.5455 14.6305 5.50575 15.1946 6.39785L15.2513 6.48125C15.5856 6.94125 15.505 7.21822 15.2794 7.54125C14.8013 8.22428 14.1094 8.78792 13.125 8.78792C11.5488 8.78792 10.7163 7.52549 10.5431 6.4558L10.5201 6.30157C10.4849 6.02757 10.4988 5.79709 10.7431 5.5325L10.8156 5.45943C11.1256 5.16792 11.3319 4.76186 11.2188 4.27943C11.0962 3.76004 10.6169 3.33337 10 3.33337C9.31 3.33337 8.75 3.8764 8.75 4.5455C8.75 4.8755 8.89833 5.16033 9.11116 5.38631L9.18437 5.45943C9.4983 5.75554 9.5179 6.00266 9.4797 6.30157L9.45687 6.4558C9.28312 7.52489 8.45062 8.78792 6.875 8.78792C5.89062 8.78792 5.19875 8.22428 4.72062 7.54065C4.50946 7.23972 4.42515 6.97838 4.68721 6.57088L4.74875 6.48125C5.41375 5.56671 4.6875 4.5455 3.75 4.5455C3.06 4.5455 2.5 5.08853 2.5 5.75762C2.5 6.20844 2.75262 6.60701 3.13185 6.81237L3.215 6.85337C3.66996 7.05605 3.73857 7.52248 3.74842 7.98498L3.75 8.16913V12.4243C3.75 13.0937 4.30964 13.6364 5 13.6364H15C15.6904 13.6364 16.25 13.0937 16.25 12.4243V8.16913C16.25 7.682 16.2672 7.14977 16.6991 6.89735L16.785 6.85337C17.21 6.66368 17.5 6.24065 17.5 5.75762C17.5 5.08853 16.9394 4.5455 16.25 4.5455Z" />
    </SvgIcon>
);
export default SalesforceOpportunitySvg;
