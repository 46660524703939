import { styled, alpha, Palette, FormControl } from '@mui/material';

interface TreeItemProps {
    disabled?: boolean;
    isVisible?: boolean;
    selected?: boolean;
    level?: number;
    hasChildren?: boolean;
}
interface FormControlStyledProps {
    width?: boolean;
}

const handleLabelColor = (props: TreeItemProps, palette: Palette) => {
    if (props.disabled) {
        return palette.brandColors.subtleLight;
    } else if (props.selected) {
        return palette.brandColors.sky;
    } else {
        return palette.brandColors.body;
    }
};

const RowWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isVisible',
})<TreeItemProps>(({ isVisible }) => ({
    display: isVisible ? 'block' : 'none',
    width: '100%',
    margin: '0 0 4px',
}));

const Row = styled('li', {
    shouldForwardProp: (prop) => prop !== 'level',
})<TreeItemProps>(({ level = 0, selected, disabled, theme: { palette } }) => ({
    background: selected && !disabled ? alpha(palette.brandColors.sky, 0.1) : 'initial',
    display: 'flex',
    marginTop: '2px',
    paddingRight: 9,
    fontSize: 14,
    fontWeight: selected ? 500 : 400,
    border: '1px solid',
    borderColor: selected && !disabled ? palette.checkbox.checked : 'transparent',
    borderRadius: '4px',
    '&:hover': {
        background: !selected && !disabled && palette.field.background,
    },
    cursor: !disabled ? 'pointer' : 'default',
    paddingLeft: level * 32,
}));

const OpenerIcon = styled('div', {
    shouldForwardProp: (prop) => prop !== 'disabled',
})<TreeItemProps>(({ disabled, theme: { palette } }) => ({
    marginTop: '0.5rem',
    pointerEvents: disabled ? 'none' : 'auto',
    cursor: disabled ? 'default' : 'pointer',
    color: disabled ? palette.brandColors.subtleLight : 'initial',
}));

const RowLabel = styled('div')<TreeItemProps>((props) => ({
    position: 'relative',
    alignSelf: 'center',
    paddingLeft: '6px',
    color: handleLabelColor(props, props.theme.palette),
}));

const LineBreak = styled('div')({
    flexBasis: '100%',
    height: '0',
});

const StyledDiv = styled('div')(() => ({
    display: 'flex',
    position: 'relative',
    margin: '0',
}));

const Container = styled('div')(() => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}));

const StyledFormControl = styled(FormControl, {
    shouldForwardProp: (prop) => prop !== 'width',
})<FormControlStyledProps>(({ width, theme }) => ({
    position: 'absolute',
    zIndex: 3,
    right: '0',
    display: 'flex',
    flexWrap: 'wrap',
    width: width ? '100%' : '40px',
    height: '40px',
    transition: 'width 100ms',
    overflow: 'hidden',
    borderRadius: '4px',
    margin: '3px',
    input: {
        width: '100%',
        height: '36px',
        padding: '0 40px 0 15px',
        margin: '0',
        boxShadow: 'none',
        background: theme.palette.background.paper,
        '&:focus': {
            border: 'none',
        },
    },
    fieldset: {
        border: 'none',
    },
    button: {
        marginTop: '3px',
        width: '34px',
        height: '34px',
        borderRadius: '6px',
        border: 'none',
        position: 'absolute',
        right: '3px',
        background: theme.palette.grey[50],
        '&:hover': {
            borderRadius: '0',
        },
        svg: {
            fill: theme.palette.common.black,
            fontSize: '15px',
        },
    },
    '&.expanded': {
        button: {
            background: theme.palette.common.white,
            svg: {
                fill: theme.palette.icon.subtle,
            },
        },
        input: {
            border: `2px solid ${theme.palette.common.black}`,
            borderRadius: '4px',
            color: theme.palette.common.black,
        },
    },
}));

const StyledAlert = styled('div')(({ theme }) => ({
    background: theme.palette.app.background,
    margin: '10px 10px 0',
    padding: '16px 10px',
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '4px',
    textAlign: 'center',
    '.no-result': {
        fontSize: '14px',
        color: theme.palette.grey[500],
        strong: {
            display: 'block',
            padding: '3px',
            color: theme.palette.text.strong,
            fontWeight: '800',
        },
    },
    button: {
        marginTop: '10px',
        color: theme.palette.common.black,
        background: theme.palette.grey[50],
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: '4px',
        '&:hover, &:focus, &:active': {
            background: theme.palette.grey[50],
        },
        '&.variant-primary:hover': {
            background: theme.palette.grey[50],
            opacity: '0.7',
        },
    },
}));

const StyledStrong = styled('strong')(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const ScrollableContainer = styled('div')(({ theme }) => ({
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.common.white,
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey[200],
        borderRadius: '4px',
    },
    '& > *': {
        paddingRight: '6px',
    },
}));

export {
    RowWrapper,
    Row,
    OpenerIcon,
    RowLabel,
    LineBreak,
    StyledDiv,
    StyledFormControl,
    StyledAlert,
    StyledStrong,
    Container,
    ScrollableContainer,
};
