import { FilterCompaniesProvider } from 'contexts/FilterCompaniesContext/FilterCompaniesContext';
import { FilterProvider } from 'contexts/FilterContext';
import { FilterOptionsProvider } from 'contexts/FilterOptions/FilterOptionsContext';
import { IndustryProvider } from 'contexts/IndustriesContext/IndustriesContext';
import { ListsProvider } from 'contexts/ListsContext';
import { NotificationProvider } from 'contexts/NotificationsContext';
import { PermissionProvider } from 'contexts/PermissionContext/PermissionContext';
import { TriggerProvider } from 'contexts/TriggerContext';

export type DataProvidersProps = {
    children: React.ReactNode;
};

/**
 * Providers that need the authenticated API to work. Only the login page is shown outside these.
 */
export const DataProviders: React.FC<DataProvidersProps> = ({ children }) => (
    <PermissionProvider>
        <FilterProvider>
            <ListsProvider>
                <TriggerProvider>
                    <IndustryProvider>
                        <FilterOptionsProvider>
                            <NotificationProvider>
                                <FilterCompaniesProvider>{children}</FilterCompaniesProvider>
                            </NotificationProvider>
                        </FilterOptionsProvider>
                    </IndustryProvider>
                </TriggerProvider>
            </ListsProvider>
        </FilterProvider>
    </PermissionProvider>
);
