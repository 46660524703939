import React from 'react';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Typography from 'components/tokens/Typography';

type TableCellFirstColumnProps = {
    weight?: 'normal' | 'semibold' | 'bold';
    additionalData?: string | number;
    children?: React.ReactNode;
};

export const TableCellFirstColumn: React.FC<TableCellFirstColumnProps> = ({
    weight = 'semibold',
    additionalData,
    children,
}) => (
    <TableCell
        sx={{
            [`&.${tableCellClasses.head}`]: {
                minWidth: 250,
            },
            [`&.${tableCellClasses.body}`]: {
                minWidth: 250,
            },
        }}
    >
        <Typography component={'p'} variant="small" weight={weight} color="dark">
            {children}
        </Typography>

        {!!additionalData && (
            <Typography variant="small" weight={weight} color="subtle">
                ({additionalData})
            </Typography>
        )}
    </TableCell>
);
