import React from 'react';

import { styled } from '@mui/material';

import { Icon } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';

import ObjectSvgIcon from '../common/ObjectIcon';
import { CRMObject, CrmName } from '../types/Crm';
import { getCRMName } from '../utils';
import { getObjectSingularForm } from '../utils/CRMTargetText';

type MappingsTablePlaceholderProps = {
    crmName: CrmName;
    searchValue: string;
    crmObject: CRMObject;
};

const MappingsTablePlaceholder: React.FC<MappingsTablePlaceholderProps> = ({ searchValue, crmName, crmObject }) => {
    const crmPropertiesMenuButton = document.getElementById('crm-properties-menu-button') as HTMLButtonElement;

    return (
        <Container data-testid="mappings-table-placeholder">
            <IconWrapper>
                <CRMObjectIcon crm={crmName} object={crmObject} />
                <StyledIcon type={searchValue ? 'Search' : 'Add'} />
            </IconWrapper>
            <Typography variant="h4" mt="24px" fontSize="20px" weight="semibold">
                {getObjectSingularForm(crmName, crmObject)} data isn’t set up {searchValue && 'for'}
            </Typography>
            {searchValue && (
                <Typography variant="h4" mt="4px" fontSize="20px" sx={{ wordBreak: 'break-all' }}>
                    “{searchValue}”
                </Typography>
            )}
            <Typography mt="8px" color="subtle" fontSize="14px">
                {searchValue ? (
                    `No data setup found for ${getCRMName(crmName)} fields`
                ) : (
                    <>
                        To use them with Vainu,{' '}
                        <Typography
                            component="span"
                            fontSize="14px"
                            color="dark"
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => crmPropertiesMenuButton.click()}
                        >
                            add fields
                        </Typography>{' '}
                        to your connection
                    </>
                )}
            </Typography>
        </Container>
    );
};

const Container = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '550px',
    marginTop: '16px',
    padding: '24px',
    background: palette.brandColors.appBg,
    borderRadius: '8px',
}));

const IconWrapper = styled('div')(({ theme: { palette } }) => ({
    position: 'relative',
    width: '130px',
    height: '130px',
    background: palette.brandColors.cloud,
    borderRadius: '50%',
}));

const CRMObjectIcon = styled(ObjectSvgIcon)(({ theme: { palette } }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70px',
    height: '70px',
    color: palette.brandColors.subtleLight,
}));

const StyledIcon = styled(Icon)({
    position: 'absolute',
    bottom: '-10%',
    right: '-10%',
    width: '70px',
    height: '70px',
});

export { MappingsTablePlaceholder };
