import React from 'react';

import { styled } from '@mui/system';

import { getPlanhatOwnersFromStorage } from 'components/modules/connectors/utils/planhatOwnerForAccount';
import Dialog from 'components/tokens/Dialog';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';
import { useSnackbarContext } from 'contexts/SnackbarContext';

interface ContactsUpgradeDialogProps {
    open: boolean;
    onClose: () => void;
}

const CUSTOMER_SUCCESS = {
    name: 'Customer success',
    email: 'customersuccess@vainu.io',
};

// todo: DataUpdatesPermissionDialog component is almost the same, need to create a generic component for all similar cases
const ContactsUpgradeDialog: React.FC<ContactsUpgradeDialogProps> = ({ open, onClose }) => {
    const { showSnackbar } = useSnackbarContext();

    const planhatOwners = getPlanhatOwnersFromStorage();

    const contactPerson = {
        name: planhatOwners?.cs_owner_fullname ?? CUSTOMER_SUCCESS.name,
        email: planhatOwners?.cs_owner ?? CUSTOMER_SUCCESS.email,
    };

    const handleUpgradeSubmit = async () => {
        try {
            await navigator.clipboard.writeText(contactPerson.email);
            showSnackbar('The email has been copied to the clipboard');
            onClose();
        } catch (_) {
            showSnackbar('Error copying the email', 'error');
        }
    };

    return (
        <Dialog
            open={open}
            title="Get contact data"
            message="Contact us to upgrade and get contact data for decision makers"
            confirmLabel="Copy email"
            maxWidth="xs"
            sx={{
                '& .MuiDialog-paper': {
                    width: '438px',
                },
            }}
            onClose={onClose}
            onSubmit={handleUpgradeSubmit}
        >
            <ContactWrapper>
                <Typography fontSize={16} weight="bold" mb="4px">
                    {contactPerson.name}
                </Typography>
                {planhatOwners?.cs_owner && (
                    <Typography mb="4px" variant="body2" color="subtle">
                        Your account manager
                    </Typography>
                )}
                <Link
                    sx={{ display: 'flex', alignItems: 'center' }}
                    variant="body2"
                    href={`mailto:${contactPerson.email}`}
                >
                    <Icon type="Mail" fontSize="small" sx={{ marginRight: '4px' }} />
                    {contactPerson.email}
                </Link>
            </ContactWrapper>
        </Dialog>
    );
};

const ContactWrapper = styled('div')(({ theme: { palette } }) => ({
    border: `1px solid ${palette.border}`,
    marginTop: '16px',
    padding: '16px 12px',
    borderRadius: '8px',
}));

export { ContactsUpgradeDialog };
