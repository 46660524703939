import React from 'react';

import { FilterOperator } from 'api/types/FilterOperators';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';

import {
    FilterConfig,
    FilterDefinition,
    FilterID,
    FilterProps,
    GroupValues,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useChipInputFilter } from '../common/useChipInputFilter';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, makeFieldType, makeFullPath, valueArrayToText } from '../utils';

const FIELD = makeFieldType(FilterID.recruitment_keywords);

export type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.recruitment_keywords,
    label: 'Recruitment keywords',
    defaultOperator: FilterOperator.IN,
    initValue: {
        [FilterOperator.IN]: {
            [FIELD]: [],
        },
    },
};

export const recruitmentKeywordsFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.web_profile,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        render: (props: FilterProps<TValues>) => <RecruitmentKeywords {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

const RecruitmentKeywords: React.FC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const { recruitmentKeywordsOptions } = useFilterOptionsContext();

    const props = useChipInputFilter({
        filter,
        sameFiltersState,
        uuid,
        groupUUID,
        removeFilter,
        operators,
        placeholder: 'recruitment keywords...',
        valuePath: makeFullPath(filter, true),
        options: recruitmentKeywordsOptions,
        freeSolo: false,
    });

    return <FilterTemplate {...props} />;
};
