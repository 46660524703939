import MuiCheckbox, { CheckboxProps as MUICheckboxProps } from '@mui/material/Checkbox';

import CheckboxChecked from './icons/checkbox_checked';
import CheckboxEmpty from './icons/checkbox_empty';
import CheckboxIndeterminate from './icons/checkbox_indeterminate';

export type CheckboxProps = Omit<MUICheckboxProps, 'color'> & {
    color?: string;
    inheritBorder?: boolean;
};

export const Checkbox: React.FC<CheckboxProps> = ({ checked, size = 'small', sx, color, inheritBorder, ...props }) => {
    return (
        <MuiCheckbox
            checked={checked}
            icon={<CheckboxEmpty className={inheritBorder ? undefined : 'empty'} />}
            checkedIcon={<CheckboxChecked />}
            indeterminateIcon={<CheckboxIndeterminate />}
            size={size}
            sx={{
                '.MuiSvgIcon-root': {
                    transition: '200ms ease',
                    color: color || 'checkbox.checked',
                    '.background': {
                        color: 'checkbox.checkedBg',
                        fill: 'currentColor',
                    },
                    '&.empty': {
                        color: 'checkbox.empty',
                        '.background': {
                            color: 'checkbox.emptyBg',
                        },
                    },
                },
                '&:hover .MuiSvgIcon-root.empty': { color: 'checkbox.emptyHover' },
                '&.Mui-disabled .MuiSvgIcon-root': {
                    color: 'checkbox.disabled',
                    '&.empty .background': {
                        color: 'checkbox.disabled',
                        fill: 'currentColor',
                    },
                },
                ...sx,
            }}
            {...props}
        />
    );
};

export default Checkbox;
