import React, { useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import { assertArray } from 'utilities';

import { DomainData, domainProfileType } from 'api/types/Organization';
import { useTitle } from 'components/beta/Platform/Router/utils';
import { useIndustryTagsFromVCI } from 'components/features/Industries/utils';
import { getHQ } from 'components/features/Locations/utils';
import ProfileTabs from 'components/features/ProfileTabs';
import SocialNetworkSidebarCard from 'components/features/SocialNetwork/SidebarCard';
import { useSocialNetworksSidebarFromDomainData } from 'components/features/SocialNetwork/utils';
import CompanyProfilePageLayout from 'components/layout/CompanyProfilePageLayout';
import CompanyProfileSidebarCard from 'components/templates/CompanyProfileSidebarCard';
import ProfileArrowNavigation from 'components/templates/ProfileArrowNavigation';
import { EmbeddedQuery, useEmbeddedQueryContext } from 'contexts/EmbeddedQueryContext';
import { Theme } from 'design/theme';

import { ProfileProps } from '..';
import { TabSlug, embeddedDomainTabs as tabs } from '../tabs';
import { getBannerFallback, useDomainNumberDataPoints, useSkeletonNumberDataPoints } from '../utils';
// import useVisibleTabs from './useVisibleTabs';

export type EmbeddedDomainProfileProps = Omit<ProfileProps, 'initialData'> & {
    data?: DomainData;
    isLoading?: boolean;
    isSuccess?: boolean;
};

export const EmbeddedDomainProfile: React.FC<EmbeddedDomainProfileProps> = ({
    data = {} as DomainData,
    isLoading = false,
    isSuccess = false,
    mode = 'page',
    activeTab,
    onTabChange,
    previous,
    next,
}) => {
    const showSidebar = useMediaQuery<Theme>((theme) => theme.breakpoints.up('profileSidebar'));
    useTitle(data?.website, isLoading);
    const { query } = useEmbeddedQueryContext();

    // TODO: This will be done more properly later for each tab in a separate file.
    const hasTokenSimilarCompaniesEndpoint = (query: EmbeddedQuery | null) =>
        query &&
        'queries' in query &&
        Object.keys(query.queries).some((endpoint) => endpoint.includes('similar_domains'));
    const visibleTabs = tabs.filter(
        (tab) => tab.name !== TabSlug.SimilarCompanies || hasTokenSimilarCompaniesEndpoint(query),
    );

    const hq = getHQ(assertArray(data?.business_units));

    const industryTags = useIndustryTagsFromVCI(data);

    const actualNumberDataPoints = useDomainNumberDataPoints(data);
    const skeletonNumberDataPoints = useSkeletonNumberDataPoints();
    const numberDataPoints =
        actualNumberDataPoints.length === 0 && isLoading ? skeletonNumberDataPoints : actualNumberDataPoints;
    const socialNetworks = useSocialNetworksSidebarFromDomainData(data);

    const Content = useMemo(() => tabs.find((tab) => tab.name === activeTab)?.component ?? null, [activeTab]);

    if (isLoading || isSuccess) {
        return (
            <CompanyProfilePageLayout
                mode={mode}
                backgroundImage={data.website_data?.screenshot_url ?? getBannerFallback(0)}
                superheader={(previous || next) && <ProfileArrowNavigation previous={previous} next={next} />}
                header={
                    mode === 'popup' ? (
                        <CompanyProfileSidebarCard
                            profileType="domain"
                            id={data.website}
                            loading={isLoading}
                            name={data.name ?? ''}
                            country={hq?.visiting_address?.country}
                            description={data.description}
                            city={hq?.visiting_address?.city}
                            logo={data.logo_url}
                            statusClass="active"
                            industryTags={industryTags}
                            numberDataPoints={numberDataPoints}
                            showSections={showSidebar ? 'top' : 'both'}
                            showHeaderActions
                            frame={false}
                            wide
                        />
                    ) : null
                }
                sidebar={
                    <CompanyProfileSidebarCard
                        showBottomActions={false} // don't render action buttons in Vainu View
                        profileType="domain"
                        id={data.website ?? ''}
                        loading={isLoading}
                        name={data.name ?? ''}
                        country={hq?.visiting_address?.country}
                        description={data.description}
                        city={hq?.visiting_address?.city}
                        logo={data.logo_url}
                        statusClass="active"
                        industryTags={industryTags}
                        numberDataPoints={numberDataPoints}
                        showSections={mode === 'popup' ? 'bottom' : 'both'}
                        wide={mode === 'page' && !showSidebar}
                    />
                }
                sidebarExtra={<SocialNetworkSidebarCard skeleton={isLoading} socialNetworks={socialNetworks} />}
                tabs={
                    <ProfileTabs
                        tabs={visibleTabs}
                        activeTab={activeTab}
                        loading={isLoading}
                        onChange={onTabChange}
                        frame={mode === 'page'}
                    />
                }
                content={
                    Content && (
                        <Content
                            type={domainProfileType}
                            loading={isLoading}
                            domain={data.website ?? ''}
                            data={data}
                            mode={mode}
                            onTabChange={onTabChange}
                        />
                    )
                }
            />
        );
    }

    return null;
};

export default EmbeddedDomainProfile;
