import * as React from 'react';

import { Box } from '@mui/material';
import { differenceWith, filter, isEqual, sortBy } from 'lodash';
import { DropResult } from 'react-beautiful-dnd';

import { TreeRawData } from 'components/templates/Treeview';
import Icon from 'components/tokens/Icon';
import TextOnTruncate from 'components/tokens/TextOnTruncate';
import Typography from 'components/tokens/Typography';

import DraggableList, { DraggableItem } from './DraggableList';
import { getItems, reorder } from './helpers';

export type DisabledItem = { name: string };

export type DraggableListItemType = { id: string; name: string; args?: string };

type DragAndDropProps = {
    data: TreeRawData;
    disabledItems: DisabledItem[];
    parentIds: string[];
    label?: string;
    selectedIds: string[];
    onItemRemove?: (item: DraggableItem) => void;
    onChange?: (items: DraggableItem[]) => void;
    sortedItems: DraggableItem[];
};

const filteredItems = (data: Array<DraggableListItemType>, selectedIds: string[]) =>
    data.filter((it: DraggableListItemType) => selectedIds.includes(it.id));

const sortItems = (parentIds: string[], data: TreeRawData) =>
    sortBy(getItems(data), (field) => parentIds.includes(field.id), 'id');

export const DragAndDrop: React.FC<DragAndDropProps> = ({
    disabledItems,
    parentIds,
    label,
    selectedIds,
    onItemRemove,
    data,
    onChange,
    sortedItems,
}) => {
    const [selectedItems, setSelectedItems] = React.useState(
        filteredItems(sortedItems?.length > 0 ? sortedItems : sortItems(parentIds, data), selectedIds),
    );

    const onDragEnd = ({ destination, source }: DropResult) => {
        if (!destination) return; // dropped outside the list
        const newItems = reorder(selectedItems, source.index, destination.index);
        setSelectedItems(newItems);
        onChange?.(newItems);
    };

    const removeItem = (index: number) => {
        const newItems = Array.from(selectedItems);
        newItems.splice(index, 1);
        setSelectedItems(newItems);
        onItemRemove?.(selectedItems[index]);
    };

    React.useEffect(() => {
        const newSelectedItems = filteredItems(sortItems(parentIds, data), selectedIds);

        if (sortedItems) {
            const addedItems = differenceWith(newSelectedItems, sortedItems, isEqual);
            const removedItems = differenceWith(sortedItems, newSelectedItems, isEqual);

            if (addedItems.length > 0) {
                const newSortedItems = [...sortedItems, ...addedItems];
                setSelectedItems(newSortedItems);
                onChange?.(newSortedItems);
            }
            if (removedItems.length > 0) {
                const newSortedItems = filter(sortedItems, (item) => item.id !== removedItems[0].id);
                setSelectedItems(newSortedItems);
                onChange?.(newSortedItems);
            }
        } else {
            setSelectedItems(newSelectedItems);
        }
    }, [selectedIds, sortedItems, parentIds, data, onChange]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Typography
                variant="body1"
                padding={'0 20px'}
                component="div"
                sx={{ color: '#3F3F3F', fontWeight: 'bolder' }}
            >
                {label}
                <Icon
                    type="HelpCircleOutline"
                    color="#898989"
                    fontSize="small"
                    sx={{ marginLeft: '5px', verticalAlign: 'text-bottom' }}
                />
                <TextOnTruncate
                    maxLength={0}
                    content={
                        'Starting from the top, your order will be used for the export file. You can drag and drop to organize attributes the way you like.'
                    }
                    sx={{
                        display: 'inline-block',
                        position: 'relative',
                        top: '-14px',
                        left: '-18px',
                        width: '17px',
                        height: '17px',
                        fontSize: '0px',
                        cursor: 'pointer',
                        color: 'primary.main',
                    }}
                />
            </Typography>
            <DraggableList
                selectedItems={selectedItems}
                parentIds={parentIds}
                countOfItems={selectedItems.length}
                disabledItems={disabledItems}
                onDragEnd={onDragEnd}
                removeItem={removeItem}
            />
        </Box>
    );
};

export default DragAndDrop;
