import { cloneDeep } from 'lodash';
import { set } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';

import { mergeKeys } from '../../common/utils';
import { FilterID } from '../../FilterTypes';
import { initValue } from './initValue';
import type { IndustryFilterStateValue } from './VainuCustomIndustry';

export const createFilterWithValue = (value: IndustryFilterStateValue) => {
    const filterPath = mergeKeys(
        FilterOperator.ANY,
        0,
        FilterOperator.MATCH,
        FilterID.vainu_custom_industry,
        FilterOperator.ALL,
    );
    const filter = cloneDeep(initValue);

    if (!filter) {
        return;
    }

    const convertedValues: unknown[] = [{ [value.fact_vid.operator]: { fact_vid: value.fact_vid.value } }];

    if (value?.confidence_score?.value) {
        convertedValues.push({ [value.confidence_score.operator]: { confidence_score: value.confidence_score.value } });
    }

    if (value?.confidence_class?.value) {
        convertedValues.push({
            [value.confidence_class.operator]: { confidence_class: value.confidence_class.value },
        });
    }

    set(filter, filterPath, convertedValues);

    return filter;
};
