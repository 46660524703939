import { format, parseISO } from 'date-fns';
import { get } from 'lodash';
import { FormatNumberOptions, IntlShape, useIntl } from 'react-intl';
import { makeNumber } from 'utilities';

import { FinancialStatement, FinancialDataLabels, isFinancialValue, isBreakdown } from 'api/types/FinancialStatement';

import { isValidStatement, ValidFinancialStatement } from '../types';

export const getSortedStatements = (
    statements: FinancialStatement[],
    limit?: number,
    order: 'asc' | 'desc' = 'asc',
    requirePdf: boolean = false,
): ValidFinancialStatement[] => {
    const sortedStatements = statements
        .filter(isValidStatement)
        .filter(
            (statement) =>
                requirePdf === false || (statement.artifact?.file_type === 'pdf' && !!statement.artifact.preview_url),
        )
        .sort((a, b) =>
            order === 'asc'
                ? a.accounting_period.end.localeCompare(b.accounting_period.end)
                : b.accounting_period.end.localeCompare(a.accounting_period.end),
        );
    if (limit !== undefined) {
        return sortedStatements.slice(order === 'desc' ? limit : -limit);
    }
    return sortedStatements;
};

export const formatStatementPeriod = ({ years, accounting_period }: ValidFinancialStatement) => {
    // Note: getMonth() returns 0-11.
    if (accounting_period?.end != null) {
        if (Array.isArray(years) && years.length === 1 && parseISO(accounting_period.end).getMonth() === 11) {
            return years[0].toString();
        }
        const endPeriod = format(parseISO(accounting_period.end), 'MM/yyyy');
        if (Array.isArray(years) && years.length <= 2) {
            return endPeriod;
        }
        if (accounting_period.start) {
            const startPeriod = format(parseISO(accounting_period.start), 'MM/yyyy');
            return `${startPeriod}–${endPeriod}`;
        }
        return `?–${endPeriod}`;
    }
    return '?';
};

export const formatLeveLabel = (
    label: string,
    level: number,
    precision: CompactValuePrecision | undefined,
    mainCurrency: string | undefined,
) => {
    if (level === 0 && mainCurrency && precision) {
        const precisionLabel = compactValuePrecisionLabels[precision];
        if (precisionLabel) {
            return `${label} (${mainCurrency}, ${precisionLabel})`;
        }
        return `${label} (${mainCurrency})`;
    }
    return label;
};

/**
 * Recursively checks the section of a statement if it has a value in at least one statement,
 * or if some of its subsections has a value in at least one statement.
 */
export const hasSectionValues = (
    statements: FinancialStatement[],
    section: FinancialDataLabels,
    path: string[],
): boolean => {
    const { fields, noBreakdown } = section;

    const financialValuePath = [...path, 'value'];
    const breakdownValuePath = [...path, 'total'];

    if (fields) {
        return (
            hasRowValues(statements, breakdownValuePath) ||
            Object.entries(fields).some(([key, field]) => {
                const nextPath = noBreakdown ? [...path, key] : [...path, 'breakdown', key];
                return hasSectionValues(statements, field, nextPath);
            })
        );
    }
    return hasRowValues(statements, financialValuePath) || hasRowValues(statements, breakdownValuePath);
};

export const hasRowValues = (statements: FinancialStatement[], path: string[]): boolean =>
    statements.some((statement) => get(statement, path) != null);

/**
 * Recursively goes through an object and finds all the fields named 'currency_code', and counts how many of each value there are.
 */
const addCurrencyCode = (subtree: object, codes: Map<string, number>) => {
    Object.entries(subtree).forEach(([key, value]) => {
        if (key === 'currency_code') {
            codes.set(value, (codes.get(value) ?? 0) + 1);
        } else if (value != null && typeof value === 'object') {
            addCurrencyCode(value, codes);
        }
    });
    return codes;
};
const getAllCurrencyCodes = (statements: FinancialStatement[]): [code: string, count: number][] =>
    Array.from(
        statements.reduce((codes: Map<string, number>, statement) => addCurrencyCode(statement, codes), new Map()),
    ).sort((a, b) => b[1] - a[1]);

/**
 * Recursively goes through an object and finds all the objects that have a field named 'value' and finds the largest such value.
 */
const getLargestValueFromObject = (acc: number, subtree: object | number | null): number => {
    if (
        subtree != null &&
        typeof subtree === 'object' &&
        'value' in subtree &&
        (typeof subtree.value === 'number' || typeof subtree.value === 'string')
    ) {
        return Math.max(acc, Math.abs(makeNumber(subtree.value)));
    }
    if (subtree != null && typeof subtree === 'object') {
        return Object.values(subtree).reduce(getLargestValueFromObject, acc);
    }
    return acc;
};

const getLargestValue = (statements: FinancialStatement[]) =>
    statements
        .map(({ income_statement, balance_sheet }) => ({ income_statement, balance_sheet }))
        .reduce(getLargestValueFromObject, 0);

const shownSignificantDigits = 2;

export type CompactValuePrecision = 1 | 1_000 | 1_000_000;

/**
 * Returns a number that is used to divide all statement values so that no value exceeds a length of 5 numbers.
 * Never uses larger abbreviation than to millions.
 * Rounds the largest value first to two significant numbers to get the length at eventually shown precision.
 * Examples: largest value found in statements is 1,000,000,000 => Shown as 1,000M => returns 1,000,000.
 */
const getCompactValuePrecision = (statements: FinancialStatement[]): CompactValuePrecision => {
    const largestValue = getLargestValue(statements);
    const largestValueLength = parseFloat(Math.abs(largestValue).toPrecision(shownSignificantDigits)).toFixed().length;
    if (largestValueLength > 8) {
        return 1_000_000;
    }
    if (largestValue > 5) {
        return 1_000;
    }
    return 1;
};

const compactValueSymbols = {
    1: '',
    1_000: 'K',
    1_000_000: 'M',
};

const compactValuePrecisionLabels = {
    1: undefined,
    1_000: 'thousands',
    1_000_000: 'millions',
};

const replaceMinusSignToLonger = (part: Intl.NumberFormatPart) =>
    part.type === 'minusSign' ? { ...part, value: '\u2212' } : part;

const formatCompactValue = (
    value: number | string,
    currency: string,
    precision: CompactValuePrecision,
    mainCurrency: string | undefined,
    intl: IntlShape,
): string => {
    const compactedValue = makeNumber(value) / precision;
    const compactSymbol = compactValueSymbols[precision];

    const currencyOptions: FormatNumberOptions =
        currency === mainCurrency
            ? {
                  style: 'decimal',
              }
            : {
                  style: 'currency',
                  currency,
                  currencyDisplay: 'code',
              };

    const parts = intl
        .formatNumberToParts(compactedValue, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            ...currencyOptions,
        })
        .map(replaceMinusSignToLonger);

    const currencyIndex = parts.findIndex((part) => part.type === 'currency');
    // If currency symbol is at the beginning or does not exist => put compact symbol to end
    if (currencyIndex === -1 || currencyIndex === 0 || (parts[0].type === 'minusSign' && currencyIndex === 1)) {
        return [...parts.map((part) => part.value), compactSymbol].join('');
    }
    // If currency symbol is at the end => put compact symbol before currency symbol
    return [
        ...parts.slice(0, currencyIndex).map((part) => part.value),
        compactSymbol,
        ...parts.slice(currencyIndex).map((part) => part.value),
    ].join('');
};

/**
 * Returns a function that is used to format all statement values compacted so that no value exceeds
 * a length of 5 numbers, but never compacts more than to millions.
 *
 * Recreates Intl.NumberFormat with notation = compact, which is not used, as it compacts
 * all values separately and does not clamp the compaction.
 */
export const useStatementFormatter = (statements: FinancialStatement[]) => {
    const intl = useIntl();

    const currencyCounts = getAllCurrencyCodes(statements);
    const mainCurrency = currencyCounts[0]?.[0] as string | undefined;
    const precision = getCompactValuePrecision(statements);

    const formatValue = (value: unknown): string => {
        if (typeof value === 'number' || typeof value === 'string') {
            // TODO: This needs to be later changed to also show percentages and integers for certain values.
            // Right now the data doesn't contain them.
            return makeNumber(value).toFixed(2);
        }
        if (isFinancialValue(value)) {
            return formatCompactValue(value.value, value.currency_code, precision, mainCurrency, intl);
        }
        if (isBreakdown(value) && value.total != null) {
            return formatCompactValue(value.total.value, value.total.currency_code, precision, mainCurrency, intl);
        }
        return '';
    };

    return { formatValue, mainCurrency, currencyCounts, precision };
};
