import React, { useState, useContext, useEffect } from 'react';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { AxiosError } from 'axios';

import {
    SnackbarMessage,
    postChangePassword,
    PasswordParams,
    // updateProfile,
} from 'api/userProfile';
import Profile from 'components/features/settings/UserProfileAndTeam/Profile';
import { useAxiosContext } from 'contexts/AxiosContext';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { getObjectEntries } from 'utilities/objectUtils';

const ProfileSettings = () => {
    const axios = useAxiosContext();
    const { profile } = useContext(PermissionContext);
    const [isChangePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
    const [passwordFields, setPasswordFields] = useState<PasswordParams>({
        current_password: '',
        password1: '',
        password2: '',
    });
    const [passwordError, setPasswordError] = useState<Partial<PasswordParams>>({} as PasswordParams);
    const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
    const [snackMessage, setSnackMessage] = useState<SnackbarMessage | undefined>(undefined);
    const [snackOpen, setSnackOpen] = useState(false);

    /** ********************Profile field change section*******************
     * This can be used later on if we decide to let user change profile information
     */
    // const [loadingFields, setLoadingFields] = useState<Record<string, boolean>>({});
    // const debouncedUpdateProfile = debounce(updateProfile, 3000);

    // const onHandleProfileChange = async (type: string, value: string, oldValue: string) => {
    //     debouncedUpdateProfile.cancel();
    //     if (oldValue !== value) {
    //         try {
    //             setLoadingFields({ [type]: true });
    //             await debouncedUpdateProfile(axios, profile.profileid, { country: 'SE' });
    //             refetchApplicationStore();
    //             setSnackPack((prev) => [
    //                 ...prev,
    //                 { message: `${type} has been updated`, key: `${type}_updated` },
    //             ]);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //         setLoadingFields({});
    //     }
    // };

    // useEffect(() => {
    //     return () => {
    //         debouncedUpdateProfile.cancel();
    //     };
    // }, []);

    /** *******************Password change section************************* */
    const handleSubmitPasswordChange = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            await postChangePassword(axios, passwordFields);

            clearPasswordFields();
            setChangePasswordDialogOpen(false);
            setSnackPack((prev) => [
                ...prev,
                { message: 'Password changed successfully!', key: 'password_changed_success' },
            ]);
        } catch (e: unknown) {
            const error = e as AxiosError<Record<keyof PasswordParams, string[]>>;
            const errorResponse: typeof passwordError = {};
            if (!error.response?.data) {
                throw e;
            }
            for (const [key, value] of getObjectEntries(error.response.data)) {
                if (value[0].includes('fields do not match')) {
                    errorResponse['password2'] = value[0];
                } else if (value[0].includes('incorrect')) {
                    errorResponse['current_password'] = value[0];
                } else {
                    // probably the error key is `non_field_errors`. If there are more,
                    // need to address them as well.
                    errorResponse[key] = value[0];
                }
            }
            setPasswordError(errorResponse);
        }
    };

    const validatePasswordFields = (newValue: string, type: string) => {
        if (newValue.length < 8) {
            setPasswordError((oldError) => ({ ...oldError, [type]: 'Password too short' }));
        } else if (newValue.length >= 8) {
            setPasswordError((oldError) => ({ ...oldError, non_field_errors: '', [type]: '' }));
        }
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value.trim();
        validatePasswordFields(fieldValue, fieldName);
        // additional validation for password matching
        if (fieldName === 'password2' && fieldValue !== passwordFields.password1) {
            setPasswordError((oldError) => ({ ...oldError, password2: 'Passwords do not match' }));
        }
        setPasswordFields((oldValue) => ({ ...oldValue, [fieldName]: fieldValue }));
    };

    const clearPasswordFields = () => {
        setPasswordFields({ current_password: '', password1: '', password2: '' });
        setPasswordError({} as PasswordParams);
    };

    /** ***************************Snackbar*********************************** */
    useEffect(() => {
        if (snackPack.length && !snackMessage) {
            // Set a new snack when we don't have an active one
            setSnackMessage({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setSnackOpen(true);
        } else if (snackPack.length && snackMessage && snackOpen) {
            // Close an active snack when a new one is added
            setSnackOpen(false);
        }
    }, [snackPack, snackMessage, snackOpen]);

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleExited = () => {
        setSnackMessage(undefined);
    };

    return (
        <>
            <Snackbar
                key={snackMessage ? snackMessage.key : undefined}
                open={snackOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                TransitionProps={{ onExited: handleExited }}
            >
                {snackMessage ? (
                    <Alert
                        onClose={handleSnackClose}
                        severity="success"
                        sx={{ width: '100%', padding: '8px 12px' }}
                        variant="filled"
                    >
                        {snackMessage.message}
                    </Alert>
                ) : undefined}
            </Snackbar>
            <Profile
                profile={profile}
                isChangePasswordDialogOpen={isChangePasswordDialogOpen}
                onChangePasswordDialog={setChangePasswordDialogOpen}
                passwordFields={passwordFields}
                onHandlePasswordChange={handlePasswordChange}
                onSubmitPasswordChange={handleSubmitPasswordChange}
                passwordChangeError={passwordError}
                onClearPasswordFields={clearPasswordFields}
                // loadingFields={loadingFields}
                // loadingFields={{ currency_code: true }}
                // onHandleProfileChange={onHandleProfileChange}
            />
        </>
    );
};

export default ProfileSettings;
