export enum Permission {
    ExportFull = 'can_export_saved_searches',
    ExportDisabled = 'no_excel_export_allowed',
    ExportIncludeContacts = 'can_export_contact_details',
    FinancialPdfView = 'financial_pdf_readonly',
    FinancialPdfViewAndKeywordFilter = 'financial_pdf',
    FinancialPdfDownload = 'allow_prh_statement_download',
    LegacyAPIKeyManagementView = 'legacy_api_key_in_platform2',
    IncludeLegacyTriggers = 'legacy_triggers',
    CanUseApi = 'can_use_api',
    ContactData = 'data_catalogue_contact_details',
    CrmBulkExport = 'crm_bulk_export',
    FeatureFlagVehiclesP2 = 'feature-flag-vehicles-p2',
    CRMLeadMatching = 'crm_lead_matching',
}
