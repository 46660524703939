import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const SignalFooter = styled(Box)`
    position: absolute;
    right: 4px;
    bottom: 0;
    height: 22px;
    display: flex;
    align-items: center;
    transition: all 0.2s;
`;

export const ButtonContainer = styled(Stack)`
    position: absolute;
    opacity: 0;
    right: 4px;
    bottom: 0;
    transition: opacity 0.2s;
`;
