import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PipedriveNoteSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M3.57143 2.5H16.4286C16.7127 2.5 16.9853 2.61288 17.1862 2.81381C17.3871 3.01475 17.5 3.28727 17.5 3.57143V14.2857L14.2857 17.5H3.57143C3.28727 17.5 3.01475 17.3871 2.81381 17.1862C2.61288 16.9853 2.5 16.7127 2.5 16.4286V3.57143C2.5 3.28727 2.61288 3.01475 2.81381 2.81381C3.01475 2.61288 3.28727 2.5 3.57143 2.5V2.5ZM13.2143 13.2143V16.4286L16.4286 13.2143H13.2143ZM5.71429 7.85714H14.2857V5.71429H5.71429V7.85714ZM5.71429 11.0714H11.0714V8.92857H5.71429V11.0714Z" />
    </SvgIcon>
);
export default PipedriveNoteSvg;
