import React, { useRef } from 'react';

import { Divider, Popover } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Checkbox from 'components/tokens/Checkbox';
import Chip from 'components/tokens/Chip';
import Icon from 'components/tokens/Icon';
import { StyledMenuItem } from 'components/tokens/select-components/NestedSelect/styled';
import Typography from 'components/tokens/Typography';

import {
    ContactFilterProps,
    FilterConfig,
    FilterDefinition,
    FilterID,
    GroupValues,
    NodeValue,
} from '../../FilterTypes';
import { getValue, makeFieldType } from '../utils';

const FIELD = makeFieldType(FilterID.contact_function);

export type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.contact_function,
    label: 'Function',
    defaultOperator: FilterOperator.IN,
    initValue: {
        [FilterOperator.IN]: {
            [FIELD]: [],
        },
    },
};

const valueArrayToText = <T,>(field: keyof T) => {
    return (node: NodeValue) => {
        const value = getValue(field, node);

        if (Array.isArray(value)) {
            if (value.length > 2) {
                return `${value.length} functions`;
            }

            return value.join(', ');
        }
        return '';
    };
};

export const contactFunctionFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.contacts,
        label: config.label,
        fields: config.fields,
        selectableOnce: true,
        render: (props: ContactFilterProps<TValues>) => <ContactFunction {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

export const functions = [
    'Administration',
    'Board',
    'CEO',
    'Communications',
    'Development',
    'Finance',
    'HR',
    'Legal',
    'Logistics',
    'Marketing',
    'Mayor',
    'Procurement',
    'Production',
    'Real Estate',
    'Sales',
    'Technology',
] as const;

export type FunctionType = (typeof functions)[number] | 'Real estate';

export const ContactFunction: React.FC<ContactFilterProps<TValues>> = ({ filter, uuid, activeFilter }) => {
    const anchor = useRef(null);

    const { control } = useFormContext();

    const fullValuePath = `${filter.groupPath}.${filter.path}.${filter.valuePath}`;

    const isActive = activeFilter === filter.id;

    return (
        <Controller
            name={fullValuePath}
            control={control}
            key={`${uuid}-select-value`}
            render={({ field }) => {
                const { onChange, value, ref: _ref } = field;

                return (
                    <>
                        <StyledMenuItem
                            sx={{
                                display: 'flex',
                                gap: 1,
                                '&.Mui-selected': {
                                    backgroundColor: 'brandColors.cloud',
                                    '&:hover': { backgroundColor: 'brandColors.cloud' },
                                },
                            }}
                            ref={anchor}
                            selected={isActive}
                        >
                            <span style={{ flex: 1 }}>Function</span>
                            {value.length !== 0 && <Chip label={value.length} size="small" />}
                            <Icon type="ChevronRight" sx={{ margin: '0 !important' }} />
                        </StyledMenuItem>

                        <Popover
                            anchorEl={anchor.current}
                            open={isActive}
                            elevation={4}
                            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            sx={{ height: 0 }}
                            hideBackdrop
                            PaperProps={{
                                elevation: 4,
                                sx: {
                                    padding: 1,
                                    borderRadius: 1,
                                    border: '1px solid',
                                    borderColor: 'grey.50',
                                    zIndex: 2,
                                    marginTop: -1,
                                    marginLeft: 2,
                                    maxHeight: 300,
                                },
                            }}
                        >
                            <StyledMenuItem
                                onClick={() => {
                                    if (value.length !== functions.length) {
                                        onChange(functions);
                                    } else {
                                        onChange([]);
                                    }
                                }}
                            >
                                <Checkbox
                                    checked={value.length === functions.length}
                                    size="small"
                                    indeterminate={value.length !== 0 && value.length !== functions.length}
                                    sx={{ padding: 0, marginRight: 1 }}
                                />
                                <Typography variant="body2">
                                    {value.length === 0 ? 'Select all' : `${value.length} selected`}
                                </Typography>
                            </StyledMenuItem>

                            <Divider />

                            {functions.map((fn) => {
                                const isSelected = value.includes(fn);

                                return (
                                    <StyledMenuItem
                                        key={fn}
                                        onClick={() => {
                                            if (isSelected) {
                                                onChange(value.filter((v: string) => v !== fn));
                                            } else {
                                                onChange([...value, fn]);
                                            }
                                        }}
                                    >
                                        <Checkbox
                                            checked={isSelected}
                                            size="small"
                                            sx={{ padding: 0, marginRight: 1 }}
                                        />
                                        <Typography variant="body2">{fn}</Typography>
                                    </StyledMenuItem>
                                );
                            })}
                        </Popover>
                    </>
                );
            }}
        />
    );
};
