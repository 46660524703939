import { SelectOption } from 'components/tokens/select-components/Select';

export const crmOptions: SelectOption[] = [
    { label: 'Dynamics', value: 'Dynamics' },
    { label: 'HubSpot', value: 'HubSpot' },
    { label: 'Pipedrive', value: 'Pipedrive' },
    { label: 'Salesforce', value: 'Salesforcev3' },
    { label: 'Salesforce Sandbox', value: 'SalesforceSandbox' },
    { label: 'Customer API', value: 'CustomerData' },
];
