import { useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

export interface HistogramDataResponse {
    score: number;
    count: number;
}
export interface IndustryScoresResponse {
    id: number;
    thresholds: number[];
    histogram_data: HistogramDataResponse[];
}

const getVciHistogramData = (industry: number) => {
    return fetch(`https://static.vainu.io/vci_histograms/${industry}.json`)
        .then((response) => response.json())
        .catch(console.error);
};

export const useVciHistogramData = (industries: number[]) => {
    const queryClient = useQueryClient();
    const [data, setData] = useState<IndustryScoresResponse[]>([]);
    const isCancelled = useRef(false);

    useEffect(() => {
        isCancelled.current = false;

        const requests = industries.map((value) =>
            queryClient.fetchQuery<IndustryScoresResponse>({
                queryKey: ['custom-industry-scores-value', value],
                queryFn: () => getVciHistogramData(value),
            }),
        );

        Promise.all(requests).then((result) => {
            if (isCancelled.current) {
                return;
            }

            setData(result ?? []);
        });

        return () => {
            isCancelled.current = true;
        };
    }, [queryClient, industries]);

    return data;
};
