import React, { Suspense, lazy } from 'react';

import { Box, CircularProgress } from '@mui/material';

import { CompanyProfilePopup } from 'components/layout/CompanyProfilePopup';

import { PDFDocumentProps } from './PDFDocument';

export type PDFViewerProps = Omit<PDFDocumentProps, 'url'> &
    Partial<Pick<PDFDocumentProps, 'url'>> & {
        onClose?: (event: React.MouseEvent) => void;
    };

const PDFViewerProvider = lazy(async () => await import('./PDFViewerContext'));

export const PDFViewer: React.FC<PDFViewerProps> = ({ url, downloadUrl, downloadFilename, onClose }) => {
    return (
        <CompanyProfilePopup onClose={onClose} open={!!url}>
            {!!url && (
                <Suspense
                    fallback={
                        <Box sx={{ height: '100%' }}>
                            <Box
                                sx={{
                                    height: 64,
                                    backgroundColor: 'background.default',
                                    borderBottom: '1px solid',
                                    borderBottomColor: 'border',
                                }}
                            />
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'field.background',
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        </Box>
                    }
                >
                    <PDFViewerProvider url={url} downloadUrl={downloadUrl} downloadFilename={downloadFilename} />
                </Suspense>
            )}
        </CompanyProfilePopup>
    );
};

export default PDFViewer;
