import { useEffect } from 'react';

import { setContext } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { fetchPlanhatCsOwner } from 'api/planhat_owner';
import { PlanhatOwner } from 'api/types/PlanhatOwner';
import { useAuthContext } from 'contexts/AuthContext';
import { useAxiosContext } from 'contexts/AxiosContext';
import { LocalStorage } from 'utilities/localStorage';

const storageKey = 'planhat-owners';

export const setPlanhatOwnersToStorage = (data: PlanhatOwner) => {
    if (data) {
        LocalStorage.setItem(storageKey, {
            cs_co_owner: data.cs_co_owner,
            cs_co_owner_fullname: data.cs_co_owner_fullname,
            cs_owner: data.cs_owner,
            cs_owner_fullname: data.cs_owner_fullname,
        });
    }
};

export const getPlanhatOwnersFromStorage = () => {
    return LocalStorage.getItem<PlanhatOwner>(storageKey);
};

export const usePlanhatOwnersData = () => {
    const { authStatus } = useAuthContext();
    const axios = useAxiosContext();

    const csOwnersFromStorage = getPlanhatOwnersFromStorage();
    const { data } = useQuery({
        queryKey: ['planhat-owners-data'],
        queryFn: () => fetchPlanhatCsOwner(axios),
        enabled: !csOwnersFromStorage && authStatus === 'logged-in',
    });

    useEffect(() => {
        if (data) {
            setPlanhatOwnersToStorage(data);
        }
    }, [data]);

    useEffect(() => {
        if (csOwnersFromStorage) {
            const { cs_owner, cs_owner_fullname } = csOwnersFromStorage;
            if (cs_owner || cs_owner_fullname) {
                setContext('Planhat Owner', { name: cs_owner_fullname, email: cs_owner });
            }
        }
    }, [csOwnersFromStorage]);
};
