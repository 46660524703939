import React, { useRef } from 'react';

import { Box, Popover, SxProps } from '@mui/material';
import { Controller, get, set, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Chip from 'components/tokens/Chip';
import ChipInput from 'components/tokens/ChipInput';
import Icon from 'components/tokens/Icon';
import { StyledMenuItem } from 'components/tokens/select-components/NestedSelect/styled';
import Typography from 'components/tokens/Typography';
import { useListsContext } from 'contexts/ListsContext';
import { getFirstKey } from 'utilities/objectUtils';

import { mergeKeys } from '../../common/utils';
import {
    ContactFilterProps,
    FilterConfig,
    FilterDefinition,
    FilterID,
    GroupValues,
    NodeValue,
} from '../../FilterTypes';
import { getInnerMostValueInFilterState, makeFieldType } from '../utils';

const FIELD = makeFieldType(FilterID.contact_title_keywords);

type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.contact_title_keywords,
    label: 'Function',
    defaultOperator: FilterOperator.STARTSWITH,
    initValue: {
        [FilterOperator.ALL]: [
            {
                [FilterOperator.STARTSWITH]: {
                    [FIELD]: [],
                },
            },
        ],
    },
};

const valueToText = (node: NodeValue) => {
    const values = (getInnerMostValueInFilterState(node, '') as NodeValue[]).flatMap((subNode, idx) => {
        const firstKey = getFirstKey(subNode);
        const value = getInnerMostValueInFilterState(subNode, '') as string[];

        return (
            <>
                {idx !== 0 && ', '}
                <Typography
                    variant="inherit"
                    component="span"
                    sx={{ color: firstKey === FilterOperator.NOT ? 'error.main' : 'success.main' }}
                >
                    {value.join(', ')}
                </Typography>
            </>
        );
    });

    return values;
};

export const contactTitleKeywordsFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.contacts,
        label: config.label,
        fields: config.fields,
        render: (props: ContactFilterProps<TValues>) => <ContactTitleKeywords {...props} />,
        valueToText,
        config,
        createFilter: () => config.initValue,
    },
};

const examples = {
    FI: 'Toimitusjohtajan',
    SE: 'Ledamot',
    NO: 'Nestleder',
    DK: 'Direktion',
    DOMAIN_DATA_BASIC: 'Director',
};

export const ContactTitleKeywords: React.FC<ContactFilterProps<TValues>> = ({ filter, uuid, activeFilter }) => {
    const anchor = useRef(null);

    const { control } = useFormContext();
    const { database } = useListsContext();

    const fullValuePath = `${filter.groupPath}.${filter.path}`;

    const getPlaceholder = (value: []) =>
        value?.length ? 'Or...' : `e.g. ${examples[database || 'DOMAIN_DATA_BASIC']}...`;

    const isActive = activeFilter === filter.id;

    return (
        <Controller
            name={fullValuePath}
            control={control}
            key={`${uuid}-select-value`}
            render={({ field }) => {
                const { value, onChange } = field;

                const isIncludeFirst = FilterOperator.STARTSWITH in value[FilterOperator.ALL][0];

                const includePath = mergeKeys(
                    FilterOperator.ALL,
                    isIncludeFirst ? 0 : 1,
                    FilterOperator.STARTSWITH,
                    FilterID.contact_title_keywords,
                );
                const excludePath = mergeKeys(
                    FilterOperator.ALL,
                    isIncludeFirst ? 1 : 0,
                    FilterOperator.NOT,
                    FilterOperator.STARTSWITH,
                    FilterID.contact_title_keywords,
                );

                const includeValue = get(value, includePath) || [];
                const excludeValue = get(value, excludePath) || [];
                const count = includeValue.length + excludeValue.length;

                const onValueChange = (path: string) => (values: string[]) => {
                    const newValue = { ...value };
                    set(newValue, path, values);

                    const group = newValue[FilterOperator.ALL];

                    // remove empty include/exclude
                    if (group.length > 1) {
                        newValue[FilterOperator.ALL] = group.filter(
                            (filterRow: NodeValue) =>
                                (getInnerMostValueInFilterState(filterRow, '') as string[]).length,
                        );
                    }

                    onChange(newValue);
                };

                return (
                    <>
                        <StyledMenuItem
                            sx={{
                                display: 'flex',
                                gap: 1,
                                '&.Mui-selected': {
                                    backgroundColor: 'grey.50',
                                    '&:hover': { backgroundColor: 'grey.50' },
                                },
                            }}
                            ref={anchor}
                            selected={isActive}
                        >
                            <span style={{ flex: 1 }}>Title keyword</span>
                            {count !== 0 && <Chip label={count} size="small" />}
                            <Icon type="ChevronRight" sx={{ margin: '0 !important' }} />
                        </StyledMenuItem>

                        <Popover
                            anchorEl={anchor.current}
                            open={isActive}
                            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            sx={{ height: 0 }}
                            hideBackdrop
                            PaperProps={{
                                elevation: 4,
                                sx: {
                                    padding: 1.5,
                                    borderRadius: 1,
                                    border: '1px solid',
                                    borderColor: 'grey.50',
                                    width: 320,
                                    zIndex: 2,
                                    marginTop: -1,
                                    marginLeft: 2,
                                    maxHeight: 300,
                                },
                            }}
                        >
                            <ContactTitleKeywordCategory
                                value={includeValue}
                                onChange={onValueChange(includePath)}
                                placeholder={getPlaceholder(includeValue)}
                                type="include"
                            />

                            <ContactTitleKeywordCategory
                                value={excludeValue}
                                onChange={onValueChange(excludePath)}
                                placeholder={getPlaceholder(excludeValue)}
                                type="exclude"
                                sx={{ marginTop: 2 }}
                            />
                        </Popover>
                    </>
                );
            }}
        />
    );
};

const ContactTitleKeywordCategory = ({
    value,
    onChange,
    placeholder,
    type,
    sx,
}: {
    value: string[];
    onChange: (data: string[]) => void;
    placeholder: string;
    type: 'include' | 'exclude';
    sx?: SxProps;
}) => {
    return (
        <Box sx={sx}>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                {type === 'include' ? 'Keyword starts with' : "Keyword doesn't start with"}
            </Typography>
            <Box
                sx={{
                    borderRadius: 2,
                    padding: 1,
                    backgroundColor: 'grey.50',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <ChipInput
                    placeholder={placeholder}
                    width="100%"
                    limitTags={2}
                    values={value}
                    setValues={onChange}
                    chipVariant={type === 'include' ? 'green' : 'magenta'}
                />

                <Button
                    variant="flat"
                    startIcon={<Icon type="Remove" />}
                    sx={{ color: 'text.subtle' }}
                    onClick={() => onChange([])}
                />
            </Box>
        </Box>
    );
};
