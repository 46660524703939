import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BarChart = (props) => (
    <SvgIcon {...props}>
        <path d="M21 21H18V11H21V21ZM16 21H13V8H16V21ZM11 21H8V5H11V21ZM6 21H3V13H6V21Z" />
    </SvgIcon>
);

export default BarChart;
