import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckboxIndeterminate: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <rect className="background" x="1.6665" y="1.66675" width="16.6667" height="16.6667" rx="3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.1665 1.66675C2.78579 1.66675 1.6665 2.78604 1.6665 4.16675V15.8334C1.6665 17.2141 2.78579 18.3334 4.1665 18.3334H15.8332C17.2139 18.3334 18.3332 17.2141 18.3332 15.8334V4.16675C18.3332 2.78604 17.2139 1.66675 15.8332 1.66675H4.1665ZM5.83317 9.16675C5.37293 9.16675 4.99984 9.53984 4.99984 10.0001C4.99984 10.4603 5.37293 10.8334 5.83317 10.8334H14.1665C14.6267 10.8334 14.9998 10.4603 14.9998 10.0001C14.9998 9.53984 14.6267 9.16675 14.1665 9.16675H5.83317Z"
        />
    </SvgIcon>
);

export default CheckboxIndeterminate;
