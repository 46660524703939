import { useSimilarCompaniesWithAxios } from 'api/similarCompanies';
import SimilarCompanies from 'components/features/SimilarCompanies/SimilarCompanies';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';

import { DomainTabProps, TabContent } from '..';
import { getProfilePath } from '../../ProfileRouter';

export const SimilarCompaniesTab: React.FC<DomainTabProps> = ({ domain, data: companyData }) => {
    const { data, isLoading } = useSimilarCompaniesWithAxios('DOMAIN_DATA_BASIC', {
        '?ALL': [{ '?EQ': { domain } }],
    });

    return (
        <TabContent>
            <SimilarCompanies
                data={data}
                isLoading={isLoading}
                currentCompanyData={companyData}
                hideActions
                hideCrmIndicator
                customActions={
                    <Link href={getProfilePath('domain', domain)} toNewTab>
                        <Button startIcon={<Icon type="ExternalLink" />} variant="tertiary" size="small">
                            Open Vainu to send companies
                        </Button>
                    </Link>
                }
            />
        </TabContent>
    );
};

export default SimilarCompaniesTab;
