import { forwardRef } from 'react';

import { styled, alpha } from '@mui/material';
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import clsx from 'clsx';

export type TagVariant = 'default' | 'blue' | 'orange' | 'green' | 'purple' | 'error' | 'dark' | 'black';

export type TagProps = Omit<MuiChipProps, 'variant'> & {
    label: React.ReactNode;
    variant?: TagVariant;
};

export const Tag: React.FC<TagProps> = forwardRef(
    ({ label, variant = 'default', size = 'small', className, ...props }, ref) => (
        <StyledMuiTag label={label} size={size} className={clsx(className, `${variant}`)} ref={ref} {...props} />
    ),
);

export default Tag;

const StyledMuiTag = styled(MuiChip)(({ theme: { palette } }) => ({
    height: 'auto',
    borderRadius: 3,
    border: `1px solid ${palette.border}`,
    '&.MuiChip-sizeSmall': {
        fontSize: 9,
    },
    '&.MuiChip-sizeMedium': {
        fontSize: 10,
    },
    '& .MuiChip-label': {
        textTransform: 'uppercase',
        padding: 4,
    },
    '&.blue': {
        color: palette.link,
        backgroundColor: alpha(palette.link, 0.1),
        borderColor: palette.link,
    },
    '&.orange': {
        color: palette.warning.main,
        backgroundColor: alpha(palette.warning.main, 0.1),
        borderColor: palette.warning.main,
    },
    '&.green': {
        color: palette.success.main,
        backgroundColor: alpha(palette.success.main, 0.1),
        borderColor: palette.success.main,
    },
    '&.purple': {
        color: palette.error.main,
        backgroundColor: alpha(palette.error.main, 0.1),
        borderColor: palette.error.main,
    },
    '&.error': {
        color: palette.brandColors.white,
        backgroundColor: palette.error.main,
        borderColor: palette.error.main,
    },
    '&.dark': {
        color: palette.button.text,
        backgroundColor: alpha(palette.field.background, 0.2),
        borderColor: 'transparent',
    },
    '&.black': {
        color: palette.button.text,
        backgroundColor: palette.button.background,
        borderColor: 'transparent',
    },
    '&.MuiChip-sizeBig .MuiChip-label': {
        textTransform: 'none',
        padding: '6px 8px',
    },
}));
