import React, { useEffect } from 'react';

import { Box, SxProps } from '@mui/material';

import NavigationButton, { NavigationButtonProps } from './NavigationButton';

export type NeighborProfile = Omit<NavigationButtonProps, 'position'>;

export type ProfileArrowNavigationProps = {
    previous?: NeighborProfile;
    next?: NeighborProfile;
    sx?: SxProps;
};

export const ProfileArrowNavigation: React.FC<ProfileArrowNavigationProps> = ({ previous, next, sx }) => {
    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowRight' && next?.onClick) {
                next.onClick();
            } else if (e.key === 'ArrowLeft' && previous?.onClick) {
                previous.onClick();
            }
        };
        document.addEventListener('keydown', onKeyDown);

        return () => document.removeEventListener('keydown', onKeyDown);
    }, [next, previous]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                height: 0,
                position: 'relative',
                zIndex: 1000,
                ...sx,
            }}
        >
            <Box sx={{ width: '50%', position: 'relative' }}>
                {!!previous && <NavigationButton position="left" {...previous} />}
            </Box>
            <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
                {!!next && <NavigationButton position="right" {...next} />}
            </Box>
        </Box>
    );
};

export default ProfileArrowNavigation;
