import { FC, useEffect, useState } from 'react';

import { ClickAwayListener } from '@mui/material';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Select from 'components/tokens/select-components/Select';

import { SelectOption } from '../../../../../tokens/select-components/Select/Select';

interface AddFilterButtonProps {
    label: string;
    isInnerAddRowLabel: boolean;
    onClick?: () => void;
}

export const AddFilterButton: FC<AddFilterButtonProps> = ({ label, isInnerAddRowLabel, onClick }) => {
    return (
        <Button
            variant={isInnerAddRowLabel ? 'flat' : 'tertiary'}
            size="small"
            onClick={onClick}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            {label}
        </Button>
    );
};

interface AddFilterSelectProps {
    onValueChange: (obj: SelectOption) => void;
    options: SelectOption[];
    onClose?: () => void;
    onOpen?: () => void;
    forceOpen?: boolean;
}

export const AddFilterSelect: FC<AddFilterSelectProps> = ({ onValueChange, options, onClose, onOpen, forceOpen }) => {
    const [open, setOpen] = useState(true);

    const handleOpen = () => {
        setOpen(true);
        onOpen?.();
    };

    const handleClose = (force?: boolean) => {
        if (force || !forceOpen) {
            setOpen(false);
            onClose?.();
        }
    };

    useEffect(() => {
        if (forceOpen) {
            setOpen(true);
            onOpen?.();
        }
    }, [forceOpen, onOpen]);

    return (
        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={() => handleClose(true)}>
            <div style={{ width: '100%', gridArea: 'add', display: 'inline-flex', position: 'relative' }}>
                <Select
                    placeholder="Select"
                    width="100%"
                    multiple={false}
                    value={null}
                    autoFocus
                    open={open}
                    onOpen={handleOpen}
                    sx={{ width: '100%' }}
                    options={options}
                    onClose={() => handleClose()}
                    onValueChange={(option) => {
                        if (Array.isArray(option)) {
                            throw new Error('should not be array value');
                        }
                        if (typeof option === 'number') {
                            throw new Error('should not be number value');
                        }
                        if (typeof option === 'string') {
                            throw new Error('should not be string value');
                        }
                        if (!option) {
                            return;
                        }

                        onValueChange(option);
                    }}
                />
            </div>
        </ClickAwayListener>
    );
};

type AddFilterActionProps = AddFilterSelectProps & AddFilterButtonProps;

export const AddFilterAction: FC<AddFilterActionProps> = ({
    onValueChange,
    options,
    label,
    isInnerAddRowLabel,
    onClick,
    onClose,
    onOpen,
    forceOpen = false,
}) => {
    const isSelectDisabled = options.length === 1;
    const [showSelect, setShowSelect] = useState(false);

    useEffect(() => {
        if (forceOpen) {
            setShowSelect(true);
        }
    }, [forceOpen]);

    const handleButtonClick = () => {
        if (isSelectDisabled) {
            handleValueChange(options[0]);
        } else {
            setShowSelect(true);
        }

        onClick?.();
    };

    const handleValueChange = (option: SelectOption) => {
        setShowSelect(false);
        onValueChange(option);
    };

    return showSelect ? (
        <AddFilterSelect
            onValueChange={handleValueChange}
            options={options}
            onClose={() => {
                setShowSelect(false);
                onClose?.();
            }}
            onOpen={onOpen}
            forceOpen={forceOpen}
        />
    ) : (
        <AddFilterButton isInnerAddRowLabel={isInnerAddRowLabel} label={label} onClick={handleButtonClick} />
    );
};
