import { Box } from '@mui/material';

import Icon from 'components/tokens/Icon';

import { boxPadding } from './GroupStructureLeaf.styled';

export type ExpandButtonProps = {
    expanded?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const iconSize = 20;
const innerBgPadding = 2;
const innerBgSize = iconSize - 2 * innerBgPadding;

export const ExpandButton: React.VFC<ExpandButtonProps> = ({ expanded = false, onClick }) => (
    <Box
        sx={{
            position: 'absolute',
            width: iconSize,
            height: iconSize,
            top: `calc(50% - ${iconSize / 2 + 0.5}px + ${boxPadding / 2}px)`,
            left: -iconSize / 2 + 0.5,
            cursor: 'pointer',
            zIndex: 1,
            '&:hover': {
                transition: 'transform 0.1s',
                transform: 'scale(1.2)',
            },
        }}
        onClick={onClick}
    >
        <Box
            sx={{
                position: 'absolute',
                top: innerBgPadding,
                left: innerBgPadding,
                width: innerBgSize,
                height: innerBgSize,
                borderRadius: `${innerBgSize / 2}px`,
                backgroundColor: 'background.paper',
            }}
        />
        <Icon
            type={expanded ? 'MinusCircleOutline' : 'PlusCircleOutline'}
            sx={{
                color: expanded ? 'error.main' : 'primary.main',
                fontSize: iconSize,
                position: 'relative',
                zIndex: 1,
            }}
        />
    </Box>
);

export default ExpandButton;
