import { FC } from 'react';

import { Box } from '@mui/material';

import DirectMarketingDenied from 'components/features/BasicInfo/DirectMarketingDenied';
import { TabSlug } from 'components/modules/profiles/Profile/tabs';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { useOpen } from 'hooks/useOpen';
import { formatDate } from 'utilities/date';

import { ContactsUpgradeButton } from './ContactsUpgradeButton';
import { ContactsUpgradeDialog } from './ContactsUpgradeDialog';
import { EmployeesTableSectionHeaderProps } from './types';

export const EmployeesTableSectionHeader: FC<EmployeesTableSectionHeaderProps> = ({
    directMarketingDenied,
    hasContactDataPermission,
    isEmpty,
    lastModified,
    onGoToClick,
}) => {
    const { isOpen: upgradeDialogOpen, close: closeUpgradeDialog, open: openUpgradeDialog } = useOpen();
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography
                    variant="body1"
                    weight="bold"
                    component="h2"
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    Contacts
                </Typography>
                {directMarketingDenied && <DirectMarketingDenied />}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {!isEmpty && (
                    <>
                        {!hasContactDataPermission && (
                            <>
                                <ContactsUpgradeButton sx={{ marginLeft: 1 }} onClick={openUpgradeDialog} />
                                <ContactsUpgradeDialog open={upgradeDialogOpen} onClose={closeUpgradeDialog} />
                            </>
                        )}

                        {lastModified && (
                            <Typography variant="tiny" color="subtle" sx={{ fontStyle: 'italic' }}>
                                {'Last change: '}
                                <Box component="span" sx={{ fontWeight: 500 }}>
                                    {formatDate(lastModified, 'long')}
                                </Box>
                            </Typography>
                        )}

                        {onGoToClick && (
                            <Button
                                onClick={() => onGoToClick(TabSlug.Employees, true)}
                                variant="tertiary"
                                startIcon={<Icon type="CircleRight" fontSize="inherit" />}
                            />
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};
