import React from 'react';

import { Box, SxProps } from '@mui/material';
import { range } from 'utilities';

export type ScoreIndicatorColor = [color: string, threshold: number];

export type ScoreIndicatorProps = {
    score?: number;
    max?: number;
    /** Customize the score colors. The colors given in this array must be in ascending order. */
    colors?: ScoreIndicatorColor[];
    unfilledColor?: string;
    size?: number;
    gap?: number;
    sx?: SxProps;
};

const defaultColors: ScoreIndicatorColor[] = [
    ['error.main', 0],
    ['warning.main', 2],
    ['success.main', 4],
];

export const ScoreIndicator: React.FC<ScoreIndicatorProps> = ({
    score = 0,
    max = 5,
    colors = defaultColors,
    unfilledColor = 'checkbox.empty',
    size = 5,
    gap = 2,
    sx,
}) => {
    const color = [...colors].reverse().find((c) => c[1] <= score)?.[0] ?? unfilledColor;
    return (
        <Box component="span" sx={sx}>
            {range(1, max + 1).map((index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: index <= score ? color : unfilledColor,
                        marginRight: `${gap}px`,
                        '&:last-child': { marginRight: 0 },
                    }}
                />
            ))}
        </Box>
    );
};

export default ScoreIndicator;
