import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { List } from 'api/types/List';
import { GroupValues } from 'components/features/Filters/FilterTypes';
import ManageTargetList from 'components/features/lists/ManageTargetList';
import { ListCategory } from 'components/features/lists/ManageTargetList/ManageLists';
import { filterListsByType } from 'components/features/lists/ManageTargetList/utils';
import { ListMenu } from 'components/templates/ListMenu';
import Button from 'components/tokens/Button';
import { IconType } from 'components/tokens/Icon';
import { useFilterState } from 'contexts/FilterContext';
import { useListsContext } from 'contexts/ListsContext';

import ListsTemplate from './ListsTemplate';

export type GridId =
    | 'saved-lists'
    | 'shared-lists'
    | 'uploaded-lists'
    | 'favorite-lists'
    | 'dynamic-legacy-lists'
    | 'static-legacy-lists'
    | 'shared-legacy-lists';

const listCategories: {
    title: string;
    chipLabel?: string;
    icon: IconType;
    gridId: GridId;
    listType: ListCategory;
    showEmpty?: boolean;
}[] = [
    {
        title: 'My lists',
        icon: 'Profiles',
        gridId: 'saved-lists',
        listType: 'default',
        showEmpty: true,
    },
    {
        title: 'Shared with me',
        icon: 'Group',
        gridId: 'shared-lists',
        listType: 'shared',
    },
    {
        title: 'Custom lists',
        icon: 'ListCheck',
        gridId: 'uploaded-lists',
        listType: 'file-upload',
        showEmpty: true,
    },
    {
        title: 'Dynamic lists',
        chipLabel: 'Legacy',
        icon: 'Folder',
        gridId: 'dynamic-legacy-lists',
        listType: 'dynamic-legacy',
    },
    {
        title: 'Static lists',
        chipLabel: 'Legacy',
        icon: 'Folder',
        gridId: 'static-legacy-lists',
        listType: 'static-legacy',
    },
    {
        title: 'Shared lists',
        chipLabel: 'Legacy',
        icon: 'Folder',
        gridId: 'shared-legacy-lists',
        listType: 'shared-legacy',
    },
];

export const Lists = () => {
    const { setActiveGroup } = useFilterState();
    const [expandedGridId, setExpandedGridId] = useState<GridId | undefined>(undefined);
    const [openEditLists, setOpenEditLists] = useState<ListCategory | undefined>(undefined);
    const { selectedList, dbLists, createNewList, doListAction, database } = useListsContext();

    const listsByType = useMemo(() => {
        const types: ListCategory[] = [
            'default',
            'shared',
            'dynamic-legacy',
            'static-legacy',
            'shared-legacy',
            'file-upload',
        ];

        return Object.fromEntries(types.map((type) => [type, filterListsByType(dbLists, type)])) as Record<
            ListCategory,
            List[]
        >;
    }, [dbLists]);

    useEffect(() => {
        let sectionToShow: GridId = 'saved-lists';

        if (selectedList) {
            const selectedType = Object.entries(listsByType).find(([_, lists]) =>
                lists.find((list) => list.id === selectedList.id),
            )?.[0];
            const selectedCategory = listCategories.find((category) => category.listType === selectedType)?.gridId;
            if (selectedCategory) {
                sectionToShow = selectedCategory;
            }
        }

        setExpandedGridId(sectionToShow);
    }, [listsByType, selectedList]);

    const newListAction = async (action: string) => {
        if (action === 'uploadFile') {
            return createNewList(
                database === 'DOMAIN_DATA_BASIC' ? 'static-domain-list' : 'static-organization-list',
                undefined,
                () => setActiveGroup(GroupValues.upload_list),
            );
        }
        return createNewList(database === 'DOMAIN_DATA_BASIC' ? 'dynamic-domain-list' : 'dynamic-organization-list');
    };

    const handleListSectionTitleClick = (id: GridId) => {
        setExpandedGridId((oldId) => (oldId === id ? undefined : id));
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gap: 5,
            }}
        >
            <ListMenu
                anchorElement={
                    <Button variant="secondary" fullWidth>
                        + Create a list
                    </Button>
                }
                items={[
                    { action: 'createNewList', label: 'New List', icon: 'Plus' },
                    { action: 'uploadFile', label: 'Upload a list', icon: 'Excel' },
                ]}
                onClick={newListAction}
            />

            {listCategories
                .filter((category) => category.showEmpty || listsByType[category.listType].length)
                .map((category) => (
                    <ListsTemplate
                        key={category.listType}
                        title={category.title}
                        chipLabel={category.chipLabel}
                        titleIcon={category.icon}
                        gridId={category.gridId}
                        lists={listsByType[category.listType]}
                        selectedListId={selectedList?.id}
                        expandedGridId={expandedGridId}
                        onListMenuAction={doListAction}
                        onShowMoreClick={() => setOpenEditLists(category.listType)}
                        onListSectionTitleClick={handleListSectionTitleClick}
                    />
                ))}

            {openEditLists ? (
                <ManageTargetList
                    open={!!openEditLists}
                    title={
                        ['shared', 'shared-legacy'].includes(openEditLists)
                            ? 'Manage shared lists'
                            : 'Manage your lists'
                    }
                    onClose={() => setOpenEditLists(undefined)}
                    listType={openEditLists}
                />
            ) : null}
        </Box>
    );
};

export default Lists;
