import React from 'react';

import Icon from 'components/tokens/Icon';

import { SitewideNotificationProps } from '../NotificationItem';
import NotificationItemContent from '../NotificationItemContent';

const ensureSecured = (url: string) => url.replace(/^http:\/\//i, 'https://');

const SitewideNotification: React.FC<SitewideNotificationProps> = ({ notification, onClick, handleMarkAsSeen }) => {
    const renderIcon = () => {
        if (
            notification.meta_data.hubspot_author_avatar &&
            notification.meta_data.hubspot_blog_name?.startsWith('Vainu Blog')
        ) {
            return (
                <img
                    height="100%"
                    width="40px"
                    style={{ borderRadius: 50 }}
                    src={ensureSecured(notification.meta_data.hubspot_author_avatar)}
                    alt={notification.meta_data.hubspot_blog_name}
                />
            );
        }

        return <Icon type="Vainu" />;
    };

    const handleClick = () => {
        window.open(notification.content.cta_url, '_blank');
        onClick(notification.id);
    };

    return (
        <NotificationItemContent
            seen={notification.seen}
            icon={renderIcon()}
            title={notification.content.title}
            content={notification.content.excerpt}
            date={notification.created}
            hoverText="Click to read more"
            onClick={handleClick}
            handleMarkAsSeen={handleMarkAsSeen}
        />
    );
};

export default SitewideNotification;
