import { formatISO, sub } from 'date-fns';
import { isNotEmpty } from 'utilities';

import { useEmployees, useFinancialStatements, useLocations, useWebProfileData } from 'api/profileData';
import { useSignals } from 'api/signals';
import { organizationProfileType } from 'api/types/Organization';
import BasicInfo from 'components/features/BasicInfo';
import { useBasicDataFromProfileData } from 'components/features/BasicInfo/utils';
import { EmployeesTableSection } from 'components/features/Employees';
import { FinancialsOverview } from 'components/features/Financials/Charts';
import { useFinancialValuesForOverview } from 'components/features/Financials/Charts/utilities';
import { useValidStatements } from 'components/features/Financials/utils';
import LocationsMap from 'components/features/Locations/Map';
import { useAddressesFromBusinessUnits, useCountriesFromBusinessUnits } from 'components/features/Locations/utils';
import { SignalsOverview } from 'components/features/Signals/Overview';
import { useCombinedAndSortedSignals } from 'components/features/Signals/utils';
import { WebProfileTechnologiesList } from 'components/features/WebProfile';
import Descriptions from 'components/templates/Descriptions';
import { useDescriptionsFromDomainData } from 'components/templates/Descriptions/utils';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';

import { TabContent, OrganizationTabProps } from '..';

export const OverviewTab: React.FC<OrganizationTabProps> = ({ data, businessId, loading = false, onTabChange }) => {
    const params = { type: organizationProfileType, key: businessId } as const;

    const { hasProductPermission } = usePermissionContext();
    const hasContactDataPermission = hasProductPermission(Permission.ContactData);

    const { data: locationsData, isLoading: isLoadingLocationsData } = useLocations(params);
    const { data: statementData, isLoading: isLoadingStatementData } = useFinancialStatements(params);
    const { data: webProfileData, isLoading: isLoadingWebProfileData } = useWebProfileData(params);
    const { data: employeesData, isLoading: isLoadingEmployeesData } = useEmployees(
        {
            key: businessId,
            type: organizationProfileType,
        },
        hasContactDataPermission,
    );

    const numSignals = 3;
    const signalAgeLimit = formatISO(sub(new Date(), { years: 2 }), { representation: 'date' });
    const { data: news, isLoading: isLoadingSignalsNews } = useSignals({
        type: 'organization',
        key: businessId,
        signalType: 'news',
        afterDate: signalAgeLimit,
        pageSize: numSignals,
    });
    const { data: dataChanges, isLoading: isLoadingSignalsDataChanges } = useSignals({
        type: 'organization',
        key: businessId,
        signalType: 'data-changes',
        afterDate: signalAgeLimit,
        pageSize: numSignals,
    });

    const basicDataProps = useBasicDataFromProfileData(data);
    const descriptions = useDescriptionsFromDomainData(webProfileData);
    const validStatements = useValidStatements(statementData?.financial_statements);
    const financialsOverviewProps = useFinancialValuesForOverview(validStatements);
    const signals = useCombinedAndSortedSignals(news, dataChanges, numSignals);
    const isLoadingSignals = isLoadingSignalsNews || isLoadingSignalsDataChanges;
    const addresses = useAddressesFromBusinessUnits(locationsData);
    const addressCountries = useCountriesFromBusinessUnits(locationsData?.business_units);
    const technologiesUpdatedDate = data.metadata?.crawled?.web_technologies;

    return (
        <TabContent>
            <BasicInfo loading={loading} {...basicDataProps} onGoToClick={onTabChange} />
            {(isLoadingWebProfileData || isNotEmpty(descriptions)) && (
                <Descriptions skeleton={isLoadingWebProfileData} descriptions={descriptions} />
            )}
            {(isLoadingStatementData || isNotEmpty(validStatements)) && (
                <FinancialsOverview
                    skeleton={isLoadingStatementData}
                    {...financialsOverviewProps}
                    onGoToClick={onTabChange}
                />
            )}
            {(isLoadingEmployeesData || employeesData) && (
                <EmployeesTableSection
                    skeleton={isLoadingEmployeesData}
                    hasContactDataPermission={hasContactDataPermission}
                    directMarketingDenied={basicDataProps.direct_marketing_denied}
                    businessId={businessId}
                    country={employeesData?.country}
                    allEmployees={employeesData?.contacts}
                    companyName={data.name}
                    collapseBoardMembers
                    onGoToClick={onTabChange}
                />
            )}
            {(isNotEmpty(signals) || isLoadingSignals) && (
                <SignalsOverview
                    skeleton={isLoadingSignals}
                    signals={signals}
                    currentOrganizationBusinessId={data.business_id}
                    onGoToClick={onTabChange}
                />
            )}
            {(isNotEmpty(locationsData?.business_units) || isLoadingLocationsData) && (
                <LocationsMap
                    skeleton={isLoadingLocationsData}
                    company_name={data.name}
                    countries={addressCountries}
                    addresses={addresses}
                    onGoToClick={onTabChange}
                />
            )}
            {(isNotEmpty(webProfileData?.technology_data) && webProfileData?.website) || isLoadingWebProfileData ? (
                <WebProfileTechnologiesList
                    skeleton={isLoadingWebProfileData}
                    technology_data={webProfileData?.technology_data}
                    website={webProfileData?.website}
                    updatedDate={technologiesUpdatedDate}
                    disableTable
                    onGoToClick={onTabChange}
                />
            ) : null}
        </TabContent>
    );
};

export default OverviewTab;
