import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GoogleMaps = ({ fill, ...props }) => (
    <SvgIcon {...props}>
        <g clipPath="url(#clip0_1448:508)">
            <path
                d="M14.1311 2.33466C13.4637 2.12169 12.7356 2 11.9924 2C9.85372 2 7.9274 2.97354 6.63812 4.50992L9.94473 7.29365L14.1311 2.33466Z"
                fill="#1A73E8"
            />
            <path
                d="M6.63814 4.50989C5.62189 5.72682 5 7.30883 5 9.01253C5 10.3359 5.25785 11.4008 5.69772 12.3591L9.94475 7.29362L6.63814 4.50989V4.50989Z"
                fill="#EA4335"
            />
            <path
                d="M12.0076 6.33532C13.4941 6.33532 14.6923 7.53704 14.6923 9.02778C14.6923 9.68188 14.4496 10.2903 14.0553 10.7619C14.0553 10.7619 16.1636 8.23677 18.2265 5.7877C17.3771 4.14484 15.9058 2.89749 14.1311 2.33466L9.94476 7.29365C10.4453 6.71561 11.1734 6.33532 12.0076 6.33532"
                fill="#4285F4"
            />
            <path
                d="M12.0076 11.705C10.5212 11.705 9.32289 10.5033 9.32289 9.01255C9.32289 8.35845 9.55041 7.74999 9.94478 7.29364L5.69775 12.3591C6.42581 13.9715 7.63925 15.2797 8.88302 16.9074L14.0553 10.7467C13.5547 11.3399 12.8267 11.705 12.0076 11.705Z"
                fill="#FBBC04"
            />
            <path
                d="M13.9643 18.6111C16.3001 14.9451 19.0152 13.2871 19.0152 9.0278C19.0152 7.8565 18.727 6.76126 18.2264 5.78772L8.883 16.9074C9.27736 17.4246 9.6869 18.0179 10.0813 18.6263C11.507 20.832 11.1127 22.1402 12.0228 22.1402C12.9328 22.1402 12.5385 20.8168 13.9643 18.6111"
                fill="#34A853"
            />
        </g>
        <defs>
            <clipPath id="clip0_1448:508">
                <rect width="14" height="20.4615" fill="white" transform="translate(5 2)" />
            </clipPath>
        </defs>
    </SvgIcon>
);

export default GoogleMaps;
