import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { createTrigger, deleteTrigger, updateTrigger } from 'api/triggers';
import ContentView from 'components/layout/ContentView';
import Button from 'components/tokens/Button';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useListsContext } from 'contexts/ListsContext';
import { usePermissionContext } from 'contexts/PermissionContext';
import { ActionType, getDefaultNewTrigger, useTrigger } from 'contexts/TriggerContext';
import { isValidTrigger } from 'utilities/triggers';

import TriggerLanding from './TriggerLanding';
import { TriggerList } from './TriggerList/TriggerList';
import TriggerOnboarding from './TriggerOnboarding';
import TriggerSettingsForm from './TriggerSettings/TriggerSettingsForm';
import { getTriggerName } from './utils';

type Props = {
    scrollRef?: React.RefObject<HTMLElement>;
};

export type TriggerAction = 'activate' | 'pause' | 'duplicate' | 'delete';

const Triggers: React.FC<Props> = ({ scrollRef }) => {
    const { database } = useListsContext();
    const { profile, isApplicationStoreLoading } = usePermissionContext();
    const axios = useAxiosContext();
    const [state, dispatch, { triggers, refetch }] = useTrigger();
    const location = useLocation();
    const navigate = useNavigate();

    const [onboardingActive, setOnboardingActive] = useState(false);

    const { id: selectedTriggerId } = state;

    const activeTriggers = triggers.filter(
        (i) => i.is_valid && i.notify_type === 'workflow_settings' && i.status === 'active',
    );
    const inactiveTriggers = triggers.filter(
        (i) => !i.is_valid || i.notify_type === 'disabled' || i.status === 'disabled',
    );

    const selectTrigger = (id?: string) => {
        const trigger = triggers.find((t) => t.id === id);
        if (!trigger) {
            return;
        }
        navigate(`/triggers/${trigger.id}`);
        dispatch({ type: ActionType.SELECT_TRIGGER, trigger });
    };

    const createNewTrigger = useCallback(() => {
        navigate('/triggers/new');
        const track_changes = database === 'DOMAIN_DATA_BASIC' ? ['new_company'] : [];
        const newTrigger = { ...getDefaultNewTrigger(), name: getTriggerName(triggers), track_changes };
        dispatch({ type: ActionType.SELECT_TRIGGER, trigger: newTrigger });
    }, [database, dispatch, navigate, triggers]);

    const onMenuAction = async (id: string, action: TriggerAction) => {
        const trigger = triggers.find((t) => t.id === id);
        if (!trigger) {
            return;
        }
        if (action === 'pause' || action === 'activate') {
            await updateTrigger(axios, database, id, { status: action === 'pause' ? 'disabled' : 'active' });
            refetch();
        } else if (action === 'duplicate') {
            const data = {
                ...trigger,
                name: `${trigger.name} copy`,
            };
            delete data.id;
            if (isValidTrigger(data)?.[0]) {
                const { data: newTrigger } = await createTrigger(axios, database, data);
                refetch();
                navigate(`/triggers/${newTrigger.id}`);
                dispatch({ type: ActionType.SELECT_TRIGGER, trigger: newTrigger });
            }
        } else if (action === 'delete') {
            await deleteTrigger(axios, database, trigger.id);
            refetch();
            if (location.pathname.slice(location.pathname.lastIndexOf('/') + 1) === trigger.id) {
                navigate('/triggers');
                dispatch({
                    type: ActionType.UNSELECT_TRIGGER,
                });
            }
        }
    };

    useEffect(() => {
        dispatch({ type: ActionType.UNSELECT_TRIGGER });
    }, [database, dispatch]);

    useEffect(() => {
        if (
            !isApplicationStoreLoading &&
            !profile.onboarding_status?.trigger_onboarding_shown &&
            location.pathname === '/triggers/new'
        ) {
            setOnboardingActive(true);
        }
    }, [isApplicationStoreLoading, location.pathname, profile.onboarding_status?.trigger_onboarding_shown]);

    return (
        <Box>
            <TriggerOnboarding show={onboardingActive} onFinish={() => setOnboardingActive(false)} />

            <ContentView
                left={
                    <Box
                        sx={{
                            display: 'grid',
                            gap: 5,
                            gridTemplateAreas: `
                                'button'
                                'active-triggers'
                                'paused-triggers'
                        `,
                        }}
                        id="trigger-left-panel"
                    >
                        <Button variant="secondary" sx={{ gridArea: 'button' }} onClick={createNewTrigger} fullWidth>
                            + Create a trigger
                        </Button>

                        <TriggerList
                            title="Active"
                            gridId="active-triggers"
                            triggers={activeTriggers}
                            selectedTriggerId={selectedTriggerId}
                            onClick={selectTrigger}
                            onMenuAction={onMenuAction}
                            state="active"
                        />

                        <TriggerList
                            title="Paused"
                            gridId="paused-triggers"
                            triggers={inactiveTriggers}
                            selectedTriggerId={selectedTriggerId}
                            onClick={selectTrigger}
                            onMenuAction={onMenuAction}
                            state="paused"
                        />
                    </Box>
                }
                right={
                    <Routes>
                        <Route path="/" element={<TriggerLanding createNewTrigger={createNewTrigger} />} />
                        <Route
                            path="/:triggerId"
                            element={<TriggerSettingsForm onboardingActive={onboardingActive} scrollRef={scrollRef} />}
                        />
                    </Routes>
                }
            />
        </Box>
    );
};

export default Triggers;
