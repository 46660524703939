import { Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { range } from 'utilities';

import { balanceSheetLabels, incomeStatementLabels } from 'api/types/FinancialStatement';

import { ValidFinancialStatement } from '../../types';
import { formatStatementPeriod, getSortedStatements, useStatementFormatter } from '../utils';
import HeaderCell from './HeaderCell';
import StatementSection from './StatementSection';

export interface StatementTableProps {
    statements: ValidFinancialStatement[];
}

export const StatementTable: React.FC<StatementTableProps> = ({ statements }) => {
    const shownStatements = getSortedStatements(statements, 4);
    const { formatValue, mainCurrency, precision } = useStatementFormatter(shownStatements);

    if (shownStatements.length === 0) {
        return null;
    }

    return (
        <Table
            sx={{
                width: 'auto',
                border: 0,
                borderRadius: 0,
                '.MuiTableCell-root': {
                    border: 0,
                    backgroundColor: 'background.paper',
                },
                '.MuiTableCell-root:nth-of-type(even)': {
                    backgroundColor: 'field.background',
                },
            }}
        >
            <TableHead>
                <TableRow>
                    <TableCell />
                    {shownStatements.map((statement) => (
                        <HeaderCell key={statement.accounting_period.end}>
                            {formatStatementPeriod(statement)}
                        </HeaderCell>
                    ))}
                </TableRow>
            </TableHead>

            <TableBody>
                <StatementSection
                    path={['balance_sheet']}
                    variant="balanceSheet"
                    statements={shownStatements}
                    section={balanceSheetLabels}
                    level={0}
                    mainCurrency={mainCurrency}
                    precision={precision}
                    formatValue={formatValue}
                />
                <TableRow>
                    {range(shownStatements.length + 1).map((index) => (
                        <TableCell key={index} sx={{ height: 40 }} />
                    ))}
                </TableRow>
                <StatementSection
                    path={['income_statement']}
                    variant="incomeStatement"
                    statements={shownStatements}
                    section={incomeStatementLabels}
                    level={0}
                    mainCurrency={mainCurrency}
                    precision={precision}
                    formatValue={formatValue}
                />
            </TableBody>
        </Table>
    );
};

export default StatementTable;
