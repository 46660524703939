import { Box, IconButton, List, styled, Tooltip } from '@mui/material';
import { isNotEmpty } from 'utilities';

import { Trigger } from 'api/types/Trigger';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import TriggersIcon, { TriggersIconState } from 'components/tokens/Icon/TriggersIcon';
import Typography from 'components/tokens/Typography';

import { TriggerAction } from '../Triggers';
import { TriggerListItem } from './TriggerListItem';

export type TriggerListProps = {
    title: string;
    gridId: string;
    triggers: Trigger[];
    maxShownItems?: number;
    selectedTriggerId?: string;
    onClick?: (id?: string) => void;
    onMenuAction?: (id: string, action: TriggerAction) => void;
    onShowMoreClick?: () => void;
    onTitleClick?: (gridId: string) => void;
    state: TriggersIconState;
};

export const TriggerList: React.FC<TriggerListProps> = ({
    title,
    gridId,
    triggers,
    maxShownItems = Infinity,
    selectedTriggerId,
    onClick,
    onMenuAction,
    onShowMoreClick,
    state,
}) => {
    return (
        <Box sx={{ gridArea: gridId, minWidth: 0 }}>
            <StyledButton variant="flat" size="small" fullWidth disableRipple>
                <span style={{ display: 'flex' }}>
                    <TriggersIcon state={state} />
                    <Typography weight="bold" marginLeft={0.5}>
                        {title}
                    </Typography>
                </span>
            </StyledButton>

            {isNotEmpty(triggers) ? (
                <List>
                    {triggers.slice(0, maxShownItems).map((trigger) => (
                        <TriggerListItem
                            key={trigger.id}
                            trigger={trigger}
                            isSelected={trigger.id === selectedTriggerId}
                            onClick={onClick}
                            onMenuAction={onMenuAction}
                        />
                    ))}
                </List>
            ) : (
                <Typography variant="small" color="subtle" sx={{ fontStyle: 'italic', padding: 1 }}>
                    No triggers
                </Typography>
            )}

            {triggers.length > maxShownItems && (
                <Tooltip title={`Show all ${triggers.length} triggers`}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton onClick={onShowMoreClick}>
                            <Icon type="ChevronDown" />
                        </IconButton>
                    </Box>
                </Tooltip>
            )}
        </Box>
    );
};

export default TriggerList;

const StyledButton = styled(Button)(({ theme: { palette } }) => ({
    cursor: 'default',
    justifyContent: 'space-between',
    marginBottom: '8px',
    padding: '4px 8px 4px 4px',
    '&.variant-flat': {
        '&:hover': {
            backgroundColor: palette.field.background,
            borderColor: palette.field.background,
        },
        '&.Mui-outlinedPrimary': {
            '&:hover': {
                backgroundColor: palette.field.background,
            },
        },
    },
}));
