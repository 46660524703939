import Icon from 'components/tokens/Icon';

export type TitleWithCheckmarkProps = {
    children?: React.ReactNode;
};

/**
 * Prepends the notification item title with a checkmark icon.
 */
export const TitleWithCheckmark: React.FC<TitleWithCheckmarkProps> = ({ children }) => (
    <>
        <Icon type="CheckThick" sx={{ fontSize: 20, marginBottom: '-4px', marginRight: 0.5 }} />
        {children}
    </>
);

export default TitleWithCheckmark;
