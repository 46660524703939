import React, { useEffect, useMemo, useState } from 'react';

import {
    Box,
    ClickAwayListener,
    Divider,
    Grow,
    MenuList,
    Paper,
    Popper,
    PopperPlacementType,
    SxProps,
    styled,
} from '@mui/material';
import { Options } from '@popperjs/core/lib/types';
import { v4 as uuid } from 'uuid';

import { Elevation } from 'components/tokens/Frame';
import TextField from 'components/tokens/TextField';
import { useForwardedRef } from 'hooks/useForwardedRef';

import { ConfirmationContent } from '../PopoverWithAction/PopoverContent';
import ListMenuItem, { ListMenuItemProps } from './ListMenuItem';

export interface ListMenuProps<Action> {
    disabled?: boolean;
    anchorElement: React.ReactElement;
    items: ListMenuItemProps<Action>[];
    eleUid?: string;
    sx?: SxProps;
    popperModifiers?: Options['modifiers'];
    /** Sx props for the Paper component */
    paperSx?: SxProps;
    /** Elevation for the Paper component. Default is 1 */
    elevation?: Elevation;
    /** zIndex for the Popper component so that it won't hide behind another zIndexed component. Default is 1000 */
    popperZIndex?: number;
    /** Placement for the Popper component wrt to the anchor element. Default is `bottom-start` */
    popperPlacement?: PopperPlacementType;
    /** To determine how the Popper component will become visible. Default is `top right` */
    transformOrigin?: string;
    /** By default `ListMenu` doesn't close when moving the mouse out of the component/anchor element. If you
     * don't want this behavior, set `closeOnMouseLeave` to `true` and the `ListMenu` closes when `onMouseLeave`
     * event occurs on the anchor element */
    closeOnMouseLeave?: boolean;
    onClick: (action: Action, item?: ConfirmationContent) => void;
    disablePortal?: boolean;
    searchable?: boolean;
    staticOptions?: ListMenuItemProps<Action>[];
    onMouseLeave?: () => void;
}

const subMenuOffsetModifier = {
    name: 'offset',
    options: {
        offset: ({ placement }: { placement: string }) => {
            if (placement === 'left-start') {
                return [-9, 13];
            } else if (placement === 'left-end') {
                return [9, 13];
            }
            return [];
        },
    },
};

/** Use `ListMenu` if you want to retain scrolling ability on the site.
 * Otherwise use `Menu` */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ListMenu = React.forwardRef<HTMLDivElement, ListMenuProps<any>>(
    <Action,>(
        {
            disabled = false,
            anchorElement,
            items,
            eleUid,
            sx,
            paperSx,
            popperModifiers,
            elevation = 1,
            popperZIndex = 1400,
            popperPlacement = 'bottom-start',
            transformOrigin = 'top right',
            onClick,
            closeOnMouseLeave = false,
            disablePortal = true,
            searchable,
            staticOptions,
            ...props
        }: ListMenuProps<Action>,
        ref: React.Ref<HTMLDivElement>,
    ) => {
        const [open, setOpen] = useState(false);
        const [searchValue, setSearchValue] = useState('');
        const anchorRef = useForwardedRef(ref);
        const buttonId = useMemo(() => uuid(), []);
        const menuId = useMemo(() => uuid(), []);

        useEffect(() => {
            if (open) {
                setSearchValue('');
            }
        }, [open]);

        const handleToggle = (e: Event) => {
            e.stopPropagation();
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event: MouseEvent | TouchEvent) => {
            if (anchorRef?.current?.contains(event.target as HTMLElement)) {
                return;
            }
            setOpen(false);
        };

        const handleItemClick = (_event: React.MouseEvent, action: Action, confirmContent?: ConfirmationContent) => {
            if (action === ('noop' as unknown as Action)) {
                return;
            }
            onClick(action, confirmContent);
            setOpen(false);
        };

        const handleListKeyDown = (event: React.KeyboardEvent) => {
            if (event.key === 'Tab') {
                event.preventDefault();
                setOpen(false);
            } else if (event.key === 'Escape') {
                setOpen(false);
            }
        };

        const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value);
        };

        return (
            <>
                <anchorElement.type
                    {...props}
                    {...anchorElement.props}
                    ref={anchorRef}
                    onClick={(e: Event) => {
                        if (!disabled) {
                            e.stopPropagation();
                            handleToggle(e);
                        }
                    }}
                    id={buttonId}
                    aria-controls={open ? menuId : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                />
                <Popper
                    open={open}
                    anchorEl={anchorRef?.current}
                    placement={popperPlacement}
                    transition
                    disablePortal={disablePortal}
                    style={{ zIndex: popperZIndex, cursor: 'default' }}
                    onMouseLeave={closeOnMouseLeave ? () => setOpen(false) : undefined}
                    modifiers={popperModifiers}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin,
                            }}
                        >
                            <Paper elevation={elevation} sx={{ ...paperSx }}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        inset: '-40px -20px',
                                        zIndex: -1,
                                    }}
                                    onClick={() => setOpen(false)}
                                />
                                <ClickAwayListener
                                    onClickAway={handleClose}
                                    mouseEvent="onMouseDown"
                                    touchEvent="onTouchStart"
                                >
                                    <StyledMenuList
                                        id={menuId}
                                        aria-labelledby={buttonId}
                                        sx={sx}
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {searchable && (
                                            <TextField
                                                size="small"
                                                value={searchValue}
                                                onChange={handleSearchChange}
                                                onKeyDown={(e) => e.stopPropagation()}
                                                placeholder="Search..."
                                                autoFocus
                                                sx={{ marginBottom: 1 }}
                                            />
                                        )}
                                        <Box
                                            sx={{
                                                maxHeight: 200,
                                                overflowY: 'auto',
                                                overflowX: 'hidden',
                                                '&::-webkit-scrollbar': {
                                                    width: 6,
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                    backgroundColor: '#fff',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    backgroundColor: 'grey.200',
                                                    borderRadius: '4px',
                                                },
                                            }}
                                        >
                                            {items
                                                .filter((item) =>
                                                    item.label.toUpperCase().includes(searchValue.toUpperCase()),
                                                )
                                                .map((item) => {
                                                    if (item.children) {
                                                        return (
                                                            <ListMenu
                                                                key={`${menuId}-${item.action}`}
                                                                anchorElement={<ListMenuItem {...item} />}
                                                                items={item.children}
                                                                staticOptions={item.staticOptions}
                                                                searchable={item.searchable !== false}
                                                                onClick={(action, confirmContent) => {
                                                                    onClick(action, confirmContent);
                                                                    setOpen(false);
                                                                }}
                                                                disablePortal={false}
                                                                popperPlacement="left-start"
                                                                popperModifiers={[subMenuOffsetModifier]}
                                                            />
                                                        );
                                                    }
                                                    return (
                                                        <ListMenuItem
                                                            key={`${menuId}-${item.action}`}
                                                            {...item}
                                                            onClick={(e, action) =>
                                                                handleItemClick(e, action, item.confirmationContent)
                                                            }
                                                        />
                                                    );
                                                })}
                                        </Box>
                                        {staticOptions?.length && (
                                            <div>
                                                <Divider sx={{ marginY: 1 }} />
                                                {staticOptions.map((item) => (
                                                    <ListMenuItem
                                                        key={`${menuId}-${item.action}`}
                                                        {...item}
                                                        onClick={(e, action) =>
                                                            handleItemClick(e, action, item.confirmationContent)
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </StyledMenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        );
    },
);

export default ListMenu;

export const StyledMenuList = styled(MenuList)(({ theme: { palette } }) => ({
    width: 200, // increase this when option with longer label is added
    border: '1px solid',
    borderColor: palette.border,
    borderRadius: 4,
    padding: 8,
}));
