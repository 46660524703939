import React, { InputHTMLAttributes, useMemo, useState, useContext, useCallback } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { Button, IconButton, styled } from '@mui/material';

import { CRMBulkExportAsyncProcess } from 'api/types/UserAsyncProcess';
import Switch from 'components/tokens/Switch';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { DialogController } from 'contexts/DialogContext/DialogController';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { useDialogContext } from 'hooks/useDialogContext';
import { isAsyncProcessOngoing } from 'utilities/asyncProcess';

import { BorderLinearProgress } from '../common/BorderLinearProgress';
import UpdateError from '../common/ErrorComponent/';
import { RelativeTimeEstimation } from '../common/RelativeTimeEstimation';
import { Row } from '../common/Styled';
import StyledTooltip from '../common/StyledTooltip';
import TooltipConfirmation from '../common/TooltipConfirmation';
import TooltipIcon from '../common/TooltipIcon';
import { CrmName } from '../types/Crm';
import { formatDate } from '../utils/';
import { bulkExportProgress } from '../utils/exportUtils';
import AutomaticUpdateStatus from './AutomaticUpdateStatus';
import HeaderWithDescription from './HeaderWithDescription';
import MetaFieldsOnboardingDialog from './MetaFieldsOnboardingDialog';

type Props = {
    isAutomaticUpdateOn: boolean;
    isCompanyMatched: boolean;
    isDataUpdateDisabled?: boolean;
    crm: CrmName;
    latestJob?: CRMBulkExportAsyncProcess;
    handleAutomaticUpdate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleManualUpdateStart: () => void;
    handleStopManualUpdate: () => void;
};

const UpdatesCard: React.FC<Props> = ({
    isAutomaticUpdateOn,
    isCompanyMatched,
    isDataUpdateDisabled,
    crm,
    latestJob,
    handleAutomaticUpdate,
    handleManualUpdateStart,
    handleStopManualUpdate,
}) => {
    const axios = useAxiosContext();
    const {
        profile: { profileid, onboarding_status },
        refetchApplicationStore,
    } = useContext(PermissionContext);

    const { handleDialogOpen, handleDialogClose } = useDialogContext();

    const isJobRunning = isAsyncProcessOngoing(latestJob) && !latestJob?.deleted;
    const isUpdateAutomatic = latestJob?.meta_data?.export_source === 'auto_update';
    const isManualUpdate = latestJob?.meta_data?.export_source === 'manual_update';
    const jobFinishedTime = latestJob?.finished;

    const isVisibleError =
        jobFinishedTime && latestJob?.state === 'failure' && !(latestJob?.deleted || latestJob?.seen);

    const tooltipFeedbackMessage = useMemo(() => {
        return !isCompanyMatched
            ? 'First, you need to match companies with Vainu and make sure Company sync is enabled'
            : '';
    }, [isCompanyMatched]);

    const handleSkipMetaFieldsOnboaring = useCallback(async () => {
        await axios.patch(`/mapi/profile/${profileid}/`, { onboarding_status: { meta_fields_creation: 1 } });
        refetchApplicationStore();
    }, [profileid, axios, refetchApplicationStore]);

    const handleOpenMetaFieldsDialog = useCallback(() => {
        if (!onboarding_status?.meta_fields_creation) {
            handleDialogOpen('META_FIELDS_ONBOARDING', { crm });
            handleSkipMetaFieldsOnboaring();
        }
    }, [onboarding_status?.meta_fields_creation, crm, handleDialogOpen, handleSkipMetaFieldsOnboaring]);

    const handleUpdateNowClick = useCallback(() => {
        handleManualUpdateStart();
        handleOpenMetaFieldsDialog();
    }, [handleManualUpdateStart, handleOpenMetaFieldsDialog]);

    const handleAutoUpdatesSwitch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            handleAutomaticUpdate(event);
            if (event.target.checked) {
                handleOpenMetaFieldsDialog();
            }
        },
        [handleAutomaticUpdate, handleOpenMetaFieldsDialog],
    );

    return (
        <Wrapper>
            <HeaderWithDescription
                header="Automatic updates"
                description={
                    isJobRunning && isUpdateAutomatic ? (
                        <>
                            <Typography fontSize="14px" sx={{ fontStyle: 'italic' }}>
                                First, we run full update to mapped fields.
                            </Typography>
                            <ProgressBar latestJob={latestJob} onProcessConfirm={() => handleStopManualUpdate()} />
                        </>
                    ) : (
                        <AutomaticUpdateStatus
                            isOn={isAutomaticUpdateOn}
                            jobFinishTime={(isAutomaticUpdateOn && isUpdateAutomatic && jobFinishedTime) || undefined}
                        />
                    )
                }
                callToAction={
                    <StyledTooltip
                        arrow
                        disableHoverListener={!tooltipFeedbackMessage}
                        placement="top"
                        title={<Typography fontSize="12px">{tooltipFeedbackMessage}</Typography>}
                    >
                        {/*
                            <div> is used for rendering the Tooltip on disabled Switch
                            disabled Switch has 'pointer-events: none', so Tooltip won't be opened
                        */}
                        <div>
                            <Switch
                                disabled={(isJobRunning && isUpdateAutomatic) || isDataUpdateDisabled}
                                checked={isAutomaticUpdateOn}
                                onChange={handleAutoUpdatesSwitch}
                                inputProps={
                                    {
                                        'data-testid': 'automatic-update-switch',
                                    } as InputHTMLAttributes<HTMLInputElement>
                                }
                            />
                        </div>
                    </StyledTooltip>
                }
            />
            <HeaderWithDescription
                header={
                    <Row>
                        One-time update
                        <TooltipIcon
                            text={
                                <Typography fontSize="12px">
                                    In some cases you may want to run a one-time update. For example, you are not using
                                    automatic updates, or have selected new company fields but there isn't any recent
                                    changes in that data.
                                </Typography>
                            }
                            style={{ margin: '0px 0px 0px 8px' }}
                        />
                    </Row>
                }
                description={
                    <>
                        <Typography fontSize="14px">Run one full update for all selected fields.</Typography>
                        {isManualUpdate && jobFinishedTime && !isVisibleError && (
                            <Typography fontSize="12px" sx={{ fontStyle: 'italic' }}>
                                Last updated {formatDate(jobFinishedTime, 'on')}
                            </Typography>
                        )}
                        {isJobRunning && isManualUpdate && (
                            <ProgressBar latestJob={latestJob} onProcessConfirm={() => handleStopManualUpdate()} />
                        )}
                        {isVisibleError && (
                            <UpdateError
                                title="Unable to update"
                                crm={crm}
                                latestJob={latestJob}
                                isDisabled={isDataUpdateDisabled}
                                onConfirm={() => {
                                    handleManualUpdateStart();
                                    handleDialogClose();
                                }}
                                confirmText="Try again"
                                cancelText="Cancel update"
                            />
                        )}
                    </>
                }
                callToAction={
                    <StyledTooltip
                        disableHoverListener={!tooltipFeedbackMessage}
                        title={
                            tooltipFeedbackMessage && (
                                <Typography fontSize="12px" color="subtle">
                                    {tooltipFeedbackMessage}
                                </Typography>
                            )
                        }
                        placement="top"
                        arrow
                    >
                        <UpdateButton
                            variant="contained"
                            color="inherit"
                            disabled={isJobRunning || isDataUpdateDisabled}
                            onClick={handleUpdateNowClick}
                        >
                            Update now
                        </UpdateButton>
                    </StyledTooltip>
                }
                parentStyle={{ marginTop: 26 }}
            />
            <DialogController type="META_FIELDS_ONBOARDING" component={MetaFieldsOnboardingDialog} />
        </Wrapper>
    );
};

export default UpdatesCard;

type ProgressBarProps = { latestJob: CRMBulkExportAsyncProcess; onProcessConfirm: () => void };
const ProgressBar: React.FC<ProgressBarProps> = ({ latestJob, onProcessConfirm }) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const estimatedRemainingTime = latestJob?.meta_data?.estimated_time_remaining;
    return (
        <>
            <div style={{ width: '570px' }}>
                <Row>
                    <div style={{ width: '100%' }}>
                        <BorderLinearProgress
                            value={bulkExportProgress(latestJob as CRMBulkExportAsyncProcess)}
                            variant={
                                latestJob?.state === 'accepted' ||
                                !(estimatedRemainingTime && estimatedRemainingTime !== 'None')
                                    ? 'indeterminate'
                                    : 'buffer'
                            }
                            valueBuffer={bulkExportProgress(latestJob as CRMBulkExportAsyncProcess)}
                        />
                    </div>
                    <TooltipConfirmation
                        open={openTooltip}
                        header="Stop this data update?"
                        buttonConfirmText="Stop update"
                        buttonCancelText="Not now"
                        subheader="Are you sure you would like to stop updating? Already updated data won’t be deleted."
                        buttonsAlignment="left"
                        onConfirm={() => {
                            setOpenTooltip(false);
                            onProcessConfirm();
                        }}
                        onCancel={() => {
                            setOpenTooltip(false);
                        }}
                        placement="left"
                    >
                        <StyledTooltip title="Stop update" placement="left" arrow>
                            <StyledIconButton
                                onClick={() => {
                                    setOpenTooltip(true);
                                }}
                            >
                                <CancelIcon
                                    htmlColor="#898989"
                                    fontSize="small"
                                    sx={{
                                        cursor: 'pointer',
                                        height: 13,
                                        width: 13,
                                        '&:hover': {
                                            color: '#0A0A0A',
                                        },
                                    }}
                                />
                            </StyledIconButton>
                        </StyledTooltip>
                    </TooltipConfirmation>
                </Row>
                <Typography fontSize="12px" sx={{ textAlign: 'right', marginTop: 4 }}>
                    {estimatedRemainingTime && estimatedRemainingTime !== 'None' && (
                        <RelativeTimeEstimation value={estimatedRemainingTime} />
                    )}
                </Typography>
            </div>
        </>
    );
};

const Wrapper = styled('div')({
    padding: '26px 24px 24px 24px',
    background: '#FFFFFF',
    border: '1px solid #E1E1E1',
    borderRadius: 8,
});

const UpdateButton = styled(Button)({
    fontWeight: 500,
    fontSize: 12,
    color: '#0A0A0A',
    padding: '0px 8px',
    background: '#F5F5F5',
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    borderRadius: 4,
    height: 32,
});

const StyledIconButton = styled(IconButton)({
    width: 24,
    height: 24,
    marginRight: -6,
});
