import React from 'react';

import ConfirmDialog from '../../../common/ConfirmDialog';

type DynamicsConnectionStopDialogProps = {
    isOpen: boolean;
    onSubmit: () => void;
    handleDialogClose: () => void;
};

const DynamicsConnectionStopDialog: React.FC<DynamicsConnectionStopDialogProps> = ({
    isOpen,
    onSubmit,
    handleDialogClose,
}) => {
    return (
        <ConfirmDialog
            isSmall
            open={isOpen}
            title="Want to connect later?"
            subTitle="Vainu has access your account but its not yet connected to a Dynamics 365 instance. To keep connecting, choose the one you want to use."
            primaryButtonText="Connect later"
            secondaryButtonText="Keep connecting"
            primaryButtonClick={onSubmit}
            secondaryButtonClick={handleDialogClose}
            handleClose={handleDialogClose}
        />
    );
};

export { DynamicsConnectionStopDialog };
