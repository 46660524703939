import { TreeRawData } from '../Treeview';

// a little function to help us with reordering the result
export const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const getItems = (data: TreeRawData) => {
    const transformedData = data.map((k) => ({
        id: k[0],
        name: k[1],
        args: k?.[4],
    }));

    return transformedData;
};
