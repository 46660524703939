import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExportPipedrive: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 23.953C10.8908 15.8157 18.1667 14.3449 18.1667 14.3449V11.6001L24 17.0659L18.1667 22.5802V19.8354C18.1667 19.8354 13.025 19.6946 9 23.953Z"
            fill="#0A0A0A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6411 13.2368C10.7573 13.2368 9.90122 11.4164 9.90122 9.72897C9.90122 7.07204 11.3409 6.12603 12.6875 6.12603C14.3402 6.12603 15.4506 7.56329 15.4506 9.70421C15.4506 12.1461 14.0381 13.2368 12.6411 13.2368ZM19 9.6573C19 5.67517 16.7289 3 13.3512 3C11.3861 3 10.2486 3.89129 9.6998 4.50633C9.63395 3.97598 9.28793 3.28798 7.93609 3.28798H5V6.38924H6.20336C6.40736 6.38924 6.47321 6.45439 6.47321 6.66028V20.8219H9.01494C9.30442 20.1937 9.62672 19.616 9.97224 19.0852V15.5067C9.97224 15.3634 9.96966 15.2291 9.96578 15.1093C10.4235 15.533 11.2314 16.0922 12.441 16.2622C12.873 15.8898 13.3079 15.5624 13.7337 15.2755C14.8395 14.5304 15.8817 14.0587 16.6513 13.7721C16.84 13.7018 17.0128 13.6425 17.1667 13.5928V9.30005L18.9109 10.9343C18.9697 10.5251 19 10.0987 19 9.6573Z"
            fill="#0A0A0A"
        />
    </SvgIcon>
);

export default ExportPipedrive;
