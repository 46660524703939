import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const BannerContainer = styled(Box)(() => ({
    position: 'relative',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
}));

export const BannerTitleWrapper = styled(Box)(({ theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 34,
    padding: '0 8px',
    position: 'relative',
    borderRadius: 1,
    borderLeft: '3px solid',
    svg: {
        color: palette.field.background,
    },
    '&.profiles': {
        borderColor: palette.secondary.main,
    },
    '&.connectors': {
        borderColor: palette.warning.main,
    },
    '&.triggers': {
        borderColor: palette.success.main,
    },
    '&.settings': {
        borderColor: '#573AD9',
    },
}));
