import React from 'react';

import { ListDialog } from '../common/ListDialog';
import ManageLists, { ListCategory } from './ManageLists';

export type ManageTargetListProps = {
    title: string;
    open?: boolean;
    onClose: () => void;
    listType: ListCategory;
};

export const ManageTargetList: React.FC<ManageTargetListProps> = ({ title, open = false, listType, onClose }) => {
    return (
        <ListDialog
            open={open}
            onClose={onClose}
            title={title}
            message=""
            children={<ManageLists type={listType} onClose={onClose} />}
            cancelLabel="Close"
            maxWidth="lg"
        />
    );
};

export default ManageTargetList;
