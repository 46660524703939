import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import Typography from 'components/tokens/Typography';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';

import useConnectorsApi from '../hooks/useConnectorsApi';
import { CrmName } from '../types/Crm';
import { getCRMName } from '../utils';
import ConfirmDialog from './ConfirmDialog';

type Props = {
    isOpen: boolean;
    crm: CrmName;
    handleDialogClose: () => void;
};

const CRMDisconnectConfirmDialog: React.FC<Props> = ({ isOpen, crm, handleDialogClose }) => {
    const navigate = useNavigate();
    const { disconnectCRM } = useConnectorsApi();
    const { refetchApplicationStore } = useContext(PermissionContext);

    const handleCrmDisconnect = async () => {
        try {
            await disconnectCRM(crm);
            handleDialogClose();
            await refetchApplicationStore();
            navigate('/connectors', { replace: true });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ConfirmDialog
            warning
            isSmall
            open={isOpen}
            title={
                <>
                    {`Disconnect ${getCRMName(crm)}?`}
                    <Typography mt={1}>You will no longer be able to send data</Typography>
                </>
            }
            subTitle="By disconnecting you won't lose your current connection settings. They are backed up to make reconnecting easier."
            primaryButtonText="Disconnect"
            secondaryButtonText="Stay connected"
            primaryButtonClick={handleCrmDisconnect}
            secondaryButtonClick={handleDialogClose}
            handleClose={handleDialogClose}
        />
    );
};

export { CRMDisconnectConfirmDialog };
