import { isFilterOperatorValue } from 'api/types/FilterOperators';
import { getObjectKeys } from 'utilities/objectUtils';

import { filterDefinitions } from '../filters/filterDefinitions';
import { CombinedNodeValue, isFilterId, isGroupIDValue, MatchValue, NodeValue, RawGroup } from '../FilterTypes';
import { filterGroupDefinitions } from '../groups/groupDefinitions';
import { combineKey } from './utils';

export function getGroup(operation: NodeValue, defaultPath = '') {
    let order = 0;

    function findGroup(operation: NodeValue | CombinedNodeValue | MatchValue, basePath: string): RawGroup | undefined {
        if (operation === null || operation === undefined) {
            return undefined;
        }

        for (const groupValue of getObjectKeys(operation)) {
            const nestedPath = combineKey(basePath, groupValue);

            if (isGroupIDValue(groupValue)) {
                if (!(groupValue in filterGroupDefinitions)) {
                    throw new Error(`group not found for '${groupValue}'`);
                }

                order += 1;

                return {
                    path: nestedPath,
                    value: { ...(operation as MatchValue)[groupValue] },
                    group: groupValue,
                    UIorder: order,
                };
            }

            if (isFilterId(groupValue)) {
                const group = filterDefinitions[groupValue]?.group;
                if (group) {
                    return {
                        path: nestedPath,
                        value: { ...(operation as MatchValue)[groupValue] },
                        group,
                        UIorder: order,
                    };
                }
                return;
            }

            if (!isFilterOperatorValue(groupValue) && !/^\d+$/.test(groupValue)) {
                return;
            }

            return findGroup((operation as NodeValue)[groupValue as keyof NodeValue], nestedPath);
        }
    }
    return findGroup(operation, defaultPath);
}
