import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Lock = (props) => (
    <SvgIcon {...props}>
        <path d="M7 8.66667V7C7 4.23917 9.23833 2 12 2C14.76 2 17 4.23917 17 7V8.66667H15.3333V7C15.3333 5.16167 13.8375 3.66667 12 3.66667C10.1625 3.66667 8.66667 5.16167 8.66667 7V8.66667H7ZM19.5 10.3333V22H4.5V10.3333H19.5ZM17.8333 12H6.16667V20.3333H17.8333V12Z" />
    </SvgIcon>
);

export default Lock;
