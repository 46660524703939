import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SalesforceContactSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M1.66675 5.69717V13.8399C1.66675 14.9153 2.59949 15.787 3.75008 15.787H16.2501C17.4007 15.787 18.3334 14.9153 18.3334 13.8399V5.69717C18.3334 4.62178 17.4007 3.75 16.2501 3.75H3.75008C2.59949 3.75 1.66675 4.62178 1.66675 5.69717ZM4.0911 12.3646C4.08868 11.7347 4.11777 11.375 4.89349 11.2073C5.74375 11.0238 6.5825 10.8601 6.17949 10.1645C4.98379 8.10429 5.83829 6.93627 7.12126 6.93627C8.37999 6.93627 9.2551 8.06068 8.06425 10.1645C7.67214 10.8555 8.4812 11.0198 9.34964 11.2073C10.1278 11.3755 10.1544 11.7375 10.1514 12.3725C10.1514 12.4986 10.0421 12.6008 9.90718 12.6008H4.34383C4.20425 12.6008 4.0911 12.495 4.0911 12.3646ZM15.0506 11.4854H12.5253C12.1767 11.4854 11.894 11.4733 11.894 10.7438C11.894 10.0143 12.1767 10.0143 12.5253 10.0143H15.0506C15.3993 10.0143 15.6819 10.0143 15.6819 10.7438C15.6819 11.4733 15.3993 11.4854 15.0506 11.4854ZM15.0506 8.92002H10.7762C10.4276 8.92002 10.1449 8.92002 10.1449 8.1905C10.1449 7.46098 10.4276 7.46098 10.7762 7.46098H15.0506C15.3993 7.46098 15.6819 7.46098 15.6819 8.1905C15.6819 8.92002 15.3993 8.92002 15.0506 8.92002Z" />
    </SvgIcon>
);
export default SalesforceContactSvg;
