import { selectorFamily, GetRecoilValue } from 'recoil';

import {
    CRMBulkExportAsyncProcess,
    CRMImportAsyncProcess,
    getAsyncProcessTypeCRMImportByCRM,
    isCRMBulkExportAsyncProcess,
    isCRMImportAsyncProcess,
} from 'api/types/UserAsyncProcess';
import { CrmName } from 'components/modules/connectors/types/Crm';

import asyncProcessAtom from './asyncProcessAtom';

type CrmExportSelectorFilterParams = CrmProcessFilterParams & {
    onlyLatest?: boolean;
};

const exportSelectorFilter =
    (enrich_type: string | null) =>
    (params: CrmExportSelectorFilterParams) =>
    ({ get }: { get: GetRecoilValue }) => {
        const { crm, onlyLatest = false } = params;
        const notifications = get(asyncProcessAtom);
        const filteredCrmProcesses = notifications.filter(
            (notification): notification is CRMBulkExportAsyncProcess =>
                isCRMBulkExportAsyncProcess(notification) &&
                notification.meta_data?.enrich_type === enrich_type &&
                (crm ? notification.meta_data?.crm === crm : true),
        );
        if (onlyLatest) {
            return filteredCrmProcesses?.[0];
        }
        return filteredCrmProcesses;
    };

type CrmProcessFilterParams = { crm?: CrmName };

export const filteredCrmImportProcesses = selectorFamily({
    key: 'CrmImportProcesses',
    get:
        (params: CrmProcessFilterParams) =>
        ({ get }) => {
            const { crm } = params;
            const notifications = get(asyncProcessAtom);
            if (crm) {
                return notifications.filter(
                    (notification) => notification.type === getAsyncProcessTypeCRMImportByCRM(crm),
                );
            }
            return notifications.filter(isCRMImportAsyncProcess);
        },
});

export const filteredLatestCrmImportProcess = selectorFamily({
    key: 'LatestCrmImportProcess',
    get:
        (params: CrmProcessFilterParams) =>
        ({ get }) => {
            const { crm } = params;
            const notifications = get(asyncProcessAtom);
            if (crm) {
                return notifications.find(
                    (notification): notification is CRMImportAsyncProcess =>
                        notification.type === getAsyncProcessTypeCRMImportByCRM(crm),
                );
            }
            return notifications.find(isCRMImportAsyncProcess);
        },
});

export const filteredCrmFullExportProcesses = selectorFamily({
    key: 'CrmFullExportProcesses',
    get: exportSelectorFilter('automatic'),
});

export const filteredCrmMassExportProcesses = selectorFamily({
    key: 'CrmMassExportProcesses',
    get: exportSelectorFilter(null),
});
