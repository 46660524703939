import { IconButton, Tooltip, styled, useTheme } from '@mui/material';

import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

export const SelectTitle = () => {
    const theme = useTheme();
    return (
        <Title>
            <Typography variant="h4" sx={{ paddingRight: '5px' }}>
                Confidence Score
            </Typography>
            <Tooltip
                title={
                    <TooltipText>
                        <Typography variant="h5">What’s a confidence score?</Typography>
                        It’s how confident we are that a company belongs to an industry. The higher the score the more
                        likely it’s their focus. High scores are different for each industry, so we recommend taking the
                        graph of their score distribution into account.{' '}
                        <a
                            href="https://www.vainu.com/blog/confidence-score-filtering"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more
                        </a>
                    </TooltipText>
                }
            >
                <IconButton>
                    <Icon type="HelpCircleOutline" color={theme.palette.brandColors.subtleLight} />
                </IconButton>
            </Tooltip>
        </Title>
    );
};

const Title = styled('div')`
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.spacing(1)};
`;

const TooltipText = styled('div')`
    background-color: ${(props) => props.theme.palette.brandColors.onyx};
    color: ${(props) => props.theme.palette.brandColors.subtle};
    padding: ${(props) => props.theme.spacing(1)};

    h5 {
        color: ${(props) => props.theme.palette.brandColors.white};
    }

    a {
        pointer-events: all;
        color: ${(props) => props.theme.palette.brandColors.white};
    }
`;
