import React from 'react';

import { Tooltip, IconButton, styled } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

import { Icon } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';
import { toDateUTC } from 'utilities/date';

import { CRMLogoAlt } from '../common/CRMLogo';
import { CrmName, EnhancedMapping } from '../types/Crm';
import { getCRMName } from '../utils';

type MappingTooltipProps = {
    hasError: boolean;
    crmName: CrmName;
    mapping: EnhancedMapping;
};

const MappingInfoTooltip: React.FC<MappingTooltipProps> = ({ hasError, crmName, mapping }) => {
    const createdByIcon = mapping.createdByVainu ? <Icon type="Vainu" /> : <CRMLogoAlt name={crmName} />;

    return (
        <Tooltip
            placement="bottom"
            componentsProps={{
                tooltip: {
                    sx: {
                        width: '250px',
                        padding: '16px',
                        borderRadius: '8px',
                    },
                },
            }}
            title={
                <>
                    <dl>
                        <DescriptionListItem>
                            <DescriptionTerm>API name</DescriptionTerm>
                            <DescriptionDefinition>{mapping.target_field}</DescriptionDefinition>
                        </DescriptionListItem>
                        <DescriptionListItem>
                            <DescriptionTerm sx={{ color: hasError ? 'error.main' : 'grey.200' }}>
                                Field type
                            </DescriptionTerm>
                            <DescriptionDefinition sx={{ color: hasError ? 'error.main' : 'grey.200' }}>
                                {hasError ? "Property doesn't exist in CRM" : mapping.crmPropertyType}
                            </DescriptionDefinition>
                        </DescriptionListItem>
                        {mapping.sources.map((source, index) => (
                            <DescriptionListItem key={source.source_field}>
                                <DescriptionTerm>{index === 0 ? 'Data' : 'Data fallback'}</DescriptionTerm>
                                <DescriptionDefinition>{source.source_field}</DescriptionDefinition>
                            </DescriptionListItem>
                        ))}
                        <DescriptionListItem>
                            <DescriptionTerm>Last edited</DescriptionTerm>
                            <DescriptionDefinition>
                                {formatDistanceToNow(toDateUTC(mapping.last_modified), { addSuffix: true })}
                            </DescriptionDefinition>
                        </DescriptionListItem>
                    </dl>
                    <CreatedByLabel>
                        {createdByIcon}
                        <Typography variant="tiny" color="subtle" weight="semibold">
                            {mapping.createdByVainu ? 'Created by Vainu' : `Created in ${getCRMName(crmName)}`}
                        </Typography>
                    </CreatedByLabel>
                </>
            }
        >
            <IconButton data-testid="mapping-info-icon" disableRipple size="small">
                <Icon fontSize="mini" color={hasError ? 'error.main' : 'brandColors.middle'} type="InfoCircleOutline" />
            </IconButton>
        </Tooltip>
    );
};

export const DescriptionListItem = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    lineHeight: '16px',

    '&:last-child': {
        marginBottom: 0,
    },
}));

export const DescriptionTerm = styled('dt')(({ theme: { palette } }) => ({
    flex: '0 0 30%',
    fontWeight: '500',
    color: palette.grey[200],
}));

export const DescriptionDefinition = styled('dd')(({ theme: { palette } }) => ({
    marginLeft: 'auto',
    fontWeight: '400',
    color: palette.background.paper,
    whiteSpace: 'nowrap',
    textAlign: 'right',
}));

const CreatedByLabel = styled(DescriptionListItem)({
    marginBottom: '0px',
    marginTop: '8px',

    svg: {
        width: '15px',
        height: '15px',
        marginRight: '4px',
    },
});

export { MappingInfoTooltip };
