import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronDown: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M12 15.713L18.01 9.70302L16.597 8.28802L12 12.888L7.40399 8.28802L5.98999 9.70202L12 15.713Z" />
    </SvgIcon>
);

export default ChevronDown;
