export const domainDatabase = 'DOMAIN_DATA_BASIC';
export const countries = ['FI', 'SE', 'NO', 'DK'] as const;
export const databases = [domainDatabase, ...countries] as const;

export type Database = (typeof databases)[number];
export type CountryDatabase = (typeof countries)[number];
export type DomainDatabase = typeof domainDatabase;
export type NordicDatabase = Exclude<Database, 'DOMAIN_DATA_BASIC'>;

export const isValidDatabase = (database: string): database is Database =>
    databases.includes(database as (typeof databases)[number]);
export const isDomainDatabase = (database: string): database is Database => database === domainDatabase;
