import React from 'react';

import { SxProps } from '@mui/material';

import { InfoRowWrapper, InfoLabel, InfoValue } from './styled';

export type InfoRowProps = {
    label: string;
    trailingIcon?: React.ReactNode;
    labelSx?: SxProps;
    contentSx?: SxProps;
    children?: React.ReactNode;
};

const InfoRow: React.FC<InfoRowProps> = ({ label, trailingIcon, labelSx, contentSx, children }) => {
    if (!children) {
        return null;
    }
    return (
        <InfoRowWrapper>
            <InfoLabel sx={labelSx}>
                {label}
                {trailingIcon}
            </InfoLabel>
            <InfoValue sx={contentSx}>{children}</InfoValue>
        </InfoRowWrapper>
    );
};

export default InfoRow;
