import { styled } from '@mui/material/styles';

export const BasicInfoContainer = styled('div')`
    height: 100%;
    padding-top: 4px;
`;

export const BasicTitleContainer = styled('div')(({ theme }) => ({
    margin: '20px 16px 0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const InfoContainer = styled('div')({
    width: '100%',
    height: '100%',
    marginTop: 16,
    borderRadius: 8,
});

export const InfoRowWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    padding: '12px 24px 12px 16px',
    borderBottom: `1px solid ${theme.palette.border}`,

    ':last-child': {
        border: 'none',
    },
}));

export const InfoLabel = styled('div')(({ theme }) => ({
    minWidth: '33%',
    fontSize: 14,
    lineHeight: 1.4,
    fontWeight: 500,
    display: 'inline-flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    color: theme.palette.text.subtle,
}));

export const InfoValue = styled('div')({
    fontSize: 14,
    lineHeight: 1.4,
    minWidth: 140,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
});
