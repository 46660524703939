import React, { useEffect, useRef } from 'react';

import { styled } from '@mui/material';
import { endOfDay, endOfMonth } from 'date-fns';
import DatePicker, { CalendarProps, DateObject, DatePickerProps } from 'react-multi-date-picker';

import Button, { ButtonProps } from '../Button';
import Icon from '../Icon';
import TextField from '../TextField';
import { TextFieldProps } from '../TextField/types';

const MONTH_SHORT_LABELS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const DAY_SHORT_LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export interface DateRangeProps extends Omit<CalendarProps, 'value' | 'onChange'>, DatePickerProps {
    value: Date[];
    onChange: (values: Date[]) => void;
    autofillEnd?: boolean;
    TextFieldProps?: Omit<TextFieldProps, 'value'>;
    ButtonProps?: Omit<ButtonProps, 'value'>;
    type?: 'input' | 'button';
    children?: React.ReactNode;
    autoOpen?: boolean;
}

export const DateRange: React.FC<DateRangeProps> = ({
    onChange,
    autofillEnd,
    TextFieldProps,
    ButtonProps,
    type = 'input',
    children,
    autoOpen,
    onClose,
    ...props
}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>(null);

    useEffect(() => {
        if (autoOpen) {
            ref.current?.openCalendar();
        }
    }, [autoOpen]);

    const handleChange = (values: DateObject[]) => {
        const start = values[0].toDate();
        let end = values[1]?.toDate();

        if (!end) {
            onChange([start]);
            return;
        }

        if (props.onlyMonthPicker) {
            end = endOfMonth(end);
        } else {
            end = endOfDay(end);
        }
        onChange([start, end]);
    };

    const handleClose = () => {
        // autofill end on close if it's not selected
        if (autofillEnd && props.value.length === 1) {
            const start = props.value[0];
            let end;
            if (props.onlyMonthPicker) {
                end = endOfMonth(start);
            } else {
                end = endOfDay(start);
            }
            onChange([start, end]);
        }
        onClose?.();
    };

    return (
        <StyledDatePicker
            render={type === 'button' ? <CustomButton {...ButtonProps} /> : <CustomInput {...TextFieldProps} />}
            className={!props.value[1] ? 'range-not-selected' : undefined}
            range
            onClose={handleClose}
            onChange={handleChange}
            format={props.onlyMonthPicker ? 'MMMM YYYY' : 'D MMM, YYYY'}
            months={MONTH_SHORT_LABELS}
            weekDays={DAY_SHORT_LABELS}
            shadow={false}
            arrow={false}
            portal
            zIndex={1500}
            ref={ref}
            {...props}
        >
            {children}
        </StyledDatePicker>
    );
};

export default DateRange;

const StyledDatePicker = styled(DatePicker)(({ theme: { palette } }) => ({
    fontFamily: 'Roboto',
    boxShadow: '0px 16px 16px -8px rgba(10, 10, 10, 0.1)',
    border: `1px solid ${palette.grey[50]}`,
    borderRadius: 8,
    '&.range-not-selected .rmdp-range': {
        backgroundColor: palette.dateRange.main,
        color: palette.button.text,
        borderRadius: 40,
    },
    '& .rmdp-calendar': {
        padding: 16,
    },
    '& .rmdp-header': {
        fontSize: 16,
    },
    '& .rmdp-arrow-container': {
        alignItems: 'center',
        '&.rmdp-left .rmdp-arrow': {
            marginLeft: 2,
        },
    },
    '& .rmdp-arrow': {
        borderColor: palette.primary.main,
        marginTop: 0,
        '&:hover': {
            backgroundColor: palette.dateRange.main,
        },
    },
    '& .rmdp-day-picker': {
        padding: 0,
    },
    '& .rmdp-week-day': {
        color: palette.grey[500],
        fontSize: 14,
        fontWeight: 400,
        width: 40,
        height: 40,
    },
    '& .rmdp-day': {
        height: 40,
        width: 40,
        '& span': {
            borderRadius: 40,
        },
        '&.rmdp-today span': {
            backgroundColor: 'transparent',
            color: palette.primary.main,
            border: `1px solid ${palette.primary.main}`,
        },
    },
    '& .rmdp-range': {
        backgroundColor: palette.dateRange.light,
        color: palette.primary.main,
        boxShadow: 'none',
        '&.start > span, &.end > span': {
            backgroundColor: palette.dateRange.main,
            color: palette.button.text,
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
        },
        '&.start, &:first-of-type': {
            borderBottomLeftRadius: 40,
            borderTopLeftRadius: 40,
        },
        '&.end, &:last-of-type': {
            borderBottomRightRadius: 40,
            borderTopRightRadius: 40,
        },
    },
    '& .rmdp-week': {
        margin: '4px 0',
    },
    '& .rmdp-month-picker': {
        height: 'auto',
        width: 'auto',
        '& > div': {
            height: 'auto',
            margin: '4px 0',
        },
        '& .rmdp-day': {
            width: 90,
        },
    },
}));

type CustomElementProps = {
    openCalendar?: () => void;
    value?: string[];
    handleValueChange?: () => void;
};

const CustomInput = ({ openCalendar, value, handleValueChange, ...props }: TextFieldProps & CustomElementProps) => {
    const displayValue = getDisplayValue(value);
    return <TextField {...props} onFocus={openCalendar} value={displayValue} onChange={handleValueChange} />;
};

const CustomButton = ({ openCalendar, value, handleValueChange, ...props }: ButtonProps & CustomElementProps) => {
    const displayValue = getDisplayValue(value);
    return (
        <Button variant="flat" size="small" {...props} onFocus={openCalendar} endIcon={<Icon type="Calendar" />}>
            {displayValue}
        </Button>
    );
};

export const getDisplayValue = (value?: string[]) => {
    if (!value || !value.length) {
        return 'Select dates';
    }
    return `${value[0]} - ${value[1] || ''}`;
};
