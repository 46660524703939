import { isArray } from 'lodash';

import { FilterID } from 'components/features/Filters/FilterTypes';

import { MatcherValue, Rule } from '../validation/matchers';
import { getOfficeTypeValue, isSlotLike } from './helpers';

// Rules can be a bit simplified later but need some polishing before
const filter = { property: { key: 'type', value: 'location' } };

// If there only one location slot and officeType = any and no other location filters
const RULE_LOCATION_NULL = {
    rule: {
        and: [
            { rule: { property: { key: 'length', value: 1 }, filter } },
            {
                some: {
                    rule: {
                        and: [
                            { some: { officeType: 'any', field: 'values' } },
                            {
                                each: {
                                    not: { property: { key: 'id', value: FilterID.location_type } },
                                    field: 'values',
                                },
                            },
                        ],
                    },
                    filter,
                },
            },
        ],
    },
    message: 'null',
    priority: 2,
}; /*satisfies Rule;*/

export const RULE_VALIDATE_REMOVABLE_STATUSES: Rule = {
    or: [
        // If there more than one slot with type="location"
        {
            rule: { property: { key: 'length', value: 1, operator: 'gte' } },
            message: 'remove',
            priority: 4,
            filter,
        },
        // if officeType != any
        {
            rule: { rule: { some: { some: { not: { officeType: 'any' }, field: 'values' } }, filter } },
            message: 'clear',
            priority: 3,
        },
        RULE_LOCATION_NULL,
    ],
};

const countOfficeTypeValuesLessThan = (expectedCount: number) => (data: MatcherValue) => {
    if (!isArray(data)) {
        return { valid: false };
    }

    const count = data.reduce((count, slot) => {
        if (!isSlotLike(slot)) {
            return count;
        }

        const type = getOfficeTypeValue(slot.values);
        const currentCount = type === 'any' ? 0 : type.length;

        return count + currentCount;
    }, 0);

    return { valid: count < expectedCount };
};

export function getRuleShowAddOfficeTypeButton(optionsCount: number): Rule {
    return {
        and: [
            {
                rule: { custom: countOfficeTypeValuesLessThan(optionsCount) },
                filter,
            },
            {
                each: {
                    rule: {
                        and: [
                            {
                                // Location type not any
                                each: {
                                    rule: { not: { officeType: 'any' } },
                                    field: 'values',
                                    filter: { property: { key: 'id', value: FilterID.location_type } },
                                },
                            },
                            {
                                // At least one other location filter has value
                                some: {
                                    rule: { not: { filterValue: { value: [] } } },
                                    filter: { not: { property: { key: 'id', value: FilterID.location_type } } },
                                    field: 'values',
                                },
                            },
                            {
                                // At least one Location type filter has value
                                some: {
                                    rule: { not: { filterValue: { value: undefined } } },
                                    filter: { property: { key: 'id', value: FilterID.location_type } },
                                    field: 'values',
                                },
                            },
                        ],
                    },
                    // Ignore non location slots
                    filter: { property: { key: 'type', value: 'location' } },
                },
            },
        ],
    };
}
