import { CrmName } from 'components/modules/connectors/types/Crm';

import { ConnectorsAppConfig } from '../types';

// todo: use enum
const connectorRoutes = ['crms', 'api', 'apps', 'connections'];
export type ConnectorRoute = (typeof connectorRoutes)[number] | CrmName;

export const crmsConnectConfig: ConnectorsAppConfig[] = [
    {
        id: 'hubspot',
        integration: 'hubspot_oauth2',
        releasedDomain: true,
        releasedBizId: true,
    },
    {
        id: 'salesforce',
        integration: 'salesforce_oauth2',
        releasedDomain: true,
        releasedBizId: true,
    },
    {
        id: 'salesforcesandbox',
        integration: 'salesforce_oauth2_sandbox',
        releasedDomain: true,
        releasedBizId: true,
    },
    {
        id: 'dynamics',
        integration: 'dynamics_oauth2',
        releasedDomain: false,
        releasedBizId: true,
    },
    {
        id: 'pipedrive',
        integration: 'pipedrive_oauth2',
        releasedDomain: false,
        releasedBizId: true,
    },
];
