import { FilterOperator } from 'api/types/FilterOperators';

export const initValue = {
    [FilterOperator.ANY]: [
        {
            [FilterOperator.MATCH]: {
                vainu_custom_industry: {
                    [FilterOperator.ALL]: [{ '?EQ': { fact_vid: undefined } }, { '?EQ': { confidence_class: 1 } }],
                },
            },
        },
    ],
};
