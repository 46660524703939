import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material';

interface Props {
    text: React.ReactNode;
    onClick: () => void;
    bottomDivider?: boolean;
    padding?: string;
}
const GoBack: React.FC<Props> = ({ text, onClick, bottomDivider = false, padding = '0px 40px' }) => {
    return (
        <div>
            <Container onClick={() => onClick()}>
                <ArrowBackIcon /> {text}
            </Container>
            {bottomDivider && <FullWidthDivider />}
        </div>
    );
};
export default GoBack;

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontStyle: 'normal',
    cursor: 'pointer',
    fontWeight: 500,
    '& svg': {
        color: theme.palette.primary.main,
        height: 20,
        width: 20,
    },
}));

const FullWidthDivider = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));
