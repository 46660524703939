import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PipedriveActivitySvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M14.1667 10.8333H10V15H14.1667V10.8333ZM13.3333 1.66663V3.33329H6.66667V1.66663H5V3.33329H4.16667C3.72608 3.3355 3.3043 3.51206 2.99354 3.82438C2.68278 4.13671 2.50833 4.55937 2.50833 4.99996L2.5 16.6666C2.5 17.1087 2.67559 17.5326 2.98816 17.8451C3.30072 18.1577 3.72464 18.3333 4.16667 18.3333H15.8333C16.2754 18.3333 16.6993 18.1577 17.0118 17.8451C17.3244 17.5326 17.5 17.1087 17.5 16.6666V4.99996C17.5 4.55793 17.3244 4.13401 17.0118 3.82145C16.6993 3.50889 16.2754 3.33329 15.8333 3.33329H15V1.66663H13.3333ZM15.8333 16.6666H4.16667V7.49996H15.8333V16.6666Z" />
    </SvgIcon>
);
export default PipedriveActivitySvg;
