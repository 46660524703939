import { Tooltip } from '@mui/material';

import ListMenu from 'components/templates/ListMenu';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { useListsContext } from 'contexts/ListsContext';
import { getStaticListOptions } from 'contexts/utilities/listsUtils';
import useAddToStaticList from 'hooks/useAddToStaticList';

export type AddToStaticListProps = {
    id: string;
    showButtonText?: boolean;
};

export const AddToStaticList: React.FC<AddToStaticListProps> = ({ id, showButtonText = false }) => {
    const { dbLists } = useListsContext();
    const staticListOptions = getStaticListOptions(dbLists);
    const { addToList } = useAddToStaticList();

    return (
        <Tooltip title={showButtonText ? '' : 'Add to custom list'}>
            <ListMenu
                anchorElement={
                    <Button
                        onClick={(e) => {}}
                        variant="tertiary"
                        startIcon={<Icon type="ListPlus" />}
                        sx={{ marginRight: 1 }}
                    >
                        {showButtonText && 'Add to custom list'}
                    </Button>
                }
                items={staticListOptions || []}
                staticOptions={[{ action: 'createNewList', label: 'New custom list', icon: 'Plus' }]}
                searchable
                onClick={(action) => addToList(action, [id])}
                disablePortal={false}
            />
        </Tooltip>
    );
};

export default AddToStaticList;
