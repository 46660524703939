import { Box } from '@mui/material';

import Typography from 'components/tokens/Typography';

const ScrollHint: React.VFC<{ opacity: number }> = ({ opacity }) => (
    <Box
        sx={{
            position: 'absolute',
            zIndex: 11,
            bottom: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            pointerEvents: 'none',
        }}
    >
        <Typography
            component="div"
            variant="tiny"
            weight="semibold"
            sx={{
                backgroundColor: 'field.background',
                padding: '6px 8px 9px',
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                transition: 'opacity 500ms ease',
            }}
            style={{ opacity }}
        >
            Scroll to see more
        </Typography>
    </Box>
);

export default ScrollHint;
