import { useEffect, useState } from 'react';

import { InputAdornment, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { InputProps } from '@mui/material/Input';
import TextField from '@mui/material/TextField';

import Icon from 'components/tokens/Icon';
import { brandPalette } from 'design/theme/colors';

import { Options, Values } from '../types';

interface FieldGroupProps {
    options?: Options;
    value: Values;
    onChange: (value: Values) => void;
    size?: 'small' | 'medium';
    endLocked?: boolean;
    InputProps?: Partial<InputProps>;
}

export const FieldGroup: React.FC<FieldGroupProps> = ({
    options,
    value,
    endLocked = false,
    onChange,
    size = 'medium',
    InputProps,
}) => {
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');

    useEffect(() => {
        setMin(toInputValue(value[0]));
        setMax(toInputValue(value[1]));
    }, [value]);

    return (
        <>
            <TextField
                type={!!options?.length ? 'string' : 'number'}
                placeholder="Low value"
                value={min}
                size={size}
                onChange={(e) => {
                    setMin(e.target.value);
                    const parsed = e.target.value === '' ? -Infinity : parseInt(e.target.value, 10);

                    if (isNaN(parsed) || parsed > value[1]) {
                        return;
                    }
                    onChange([parsed, value[1]]);
                }}
                onBlur={() => setMin(toInputValue(value[0]))}
                InputProps={InputProps}
            />

            <Icon type="Minus" />

            {endLocked ? (
                <CustomWidthTooltip title="The maximum is the highest score we have for the industry" placement="top">
                    <TextField
                        placeholder="High value"
                        value="Max"
                        disabled
                        size={size}
                        InputProps={{
                            ...InputProps,
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Icon type="Lock" />
                                </InputAdornment>
                            ),
                            readOnly: true,
                        }}
                        sx={{ '& .MuiInputBase-root': { backgroundColor: brandPalette.cloud } }}
                    />
                </CustomWidthTooltip>
            ) : (
                <TextField
                    type={!!options?.length ? 'string' : 'number'}
                    placeholder="High value"
                    value={max}
                    size={size}
                    onChange={(e) => {
                        setMax(e.target.value);
                        const parsed = e.target.value === '' ? Infinity : parseInt(e.target.value, 10);

                        if (isNaN(parsed) || parsed < value[0]) {
                            return;
                        }
                        onChange([value[0], parsed]);
                    }}
                    onBlur={() => setMax(toInputValue(value[1]))}
                    InputProps={InputProps}
                />
            )}
        </>
    );
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 180,
    },
});

const toInputValue = (value: number) => {
    if (value === Infinity || value === -Infinity) {
        return '';
    }
    return value.toString();
};
