import React from 'react';

import Box from '@mui/material/Box';

import MarketingBanner from 'components/features/MarketingBanner';
import Typography from 'components/tokens/Typography';
import { useListsContext } from 'contexts/ListsContext';

import TriggerExample from './TriggerExample';

interface Props {
    createNewTrigger: () => void;
}

const TriggerLanding: React.FC<Props> = ({ createNewTrigger }) => {
    const { database } = useListsContext();
    return (
        <div>
            <MarketingBanner route="triggers" onClick={createNewTrigger} />
            <Typography weight="bold" sx={{ marginTop: 6, marginBottom: 3 }}>
                Get started with a trigger to
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'auto',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                    gap: '1em',
                    alignItems: 'flex-start',
                }}
            >
                {database === 'DOMAIN_DATA_BASIC' ? (
                    <TriggerExample exampleID="newCompanyInTargetGroup" target="crm" />
                ) : (
                    <>
                        <TriggerExample exampleID="newCompanyInTargetGroup" target="crm" />
                        <TriggerExample exampleID="newCeo" target="crm" />
                        <TriggerExample exampleID="newFinancialStatement" target="email" />
                    </>
                )}
            </Box>
        </div>
    );
};

export default TriggerLanding;
