import React from 'react';

import { MenuItem, Tooltip, styled } from '@mui/material';

import Icon from 'components/tokens/Icon';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';

import { DataUpdateType } from '../types/Crm';

interface FixedDatapointConfig {
    isUpdateTypeFixed: boolean;
    defaultUpdateType: DataUpdateType;
    tooltip: string;
}

const DATAPOINTS_UPDATE_TYPE_CONFIG: Record<string, FixedDatapointConfig | undefined> = {
    _vainu_tags: {
        isUpdateTypeFixed: true,
        defaultUpdateType: 'always',
        tooltip: 'When sending Vainu Segments, we always add your new segments to this property.',
    },
    vainu_custom_industry: {
        isUpdateTypeFixed: true,
        defaultUpdateType: 'always',
        tooltip: 'To guarantee the correct datapoint, we will always overwrite this property.',
    },
    last_update_from_vainu: {
        isUpdateTypeFixed: true,
        defaultUpdateType: 'always',
        tooltip: 'To guarantee the correct datapoint, we will always overwrite this property.',
    },
    _auto_update_on: {
        isUpdateTypeFixed: true,
        defaultUpdateType: 'always',
        tooltip: 'To guarantee the correct datapoint, we will always overwrite this property.',
    },
    _auto_update_off: {
        isUpdateTypeFixed: true,
        defaultUpdateType: 'always',
        tooltip: 'To guarantee the correct datapoint, we will always overwrite this property.',
    },
    // custom text datapoint
    Text: {
        isUpdateTypeFixed: false,
        defaultUpdateType: 'only_for_new_companies',
        tooltip: 'This datapoint never changes, so it will only be used when creating a new company.',
    },
};

export const getDatapointUpdateConfig = (datapointValue: string): FixedDatapointConfig => {
    return (
        DATAPOINTS_UPDATE_TYPE_CONFIG[datapointValue] ?? {
            isUpdateTypeFixed: false,
            defaultUpdateType: 'safe_update',
            tooltip: '',
        }
    );
};

const UPDATE_TYPE_TOOLTIPS_MAP: Record<DataUpdateType, string> = {
    safe_update:
        'Keep data written by your team safe, and only fill this field if it’s empty or previously filled by Vainu.',
    always: 'If there is existing data in this field it will be overwritten. The field will also receive data if it is empty.',
    only_for_new_companies:
        'Only fill this data when creating a new company. This field will not be used in future updates.',
};

const UPDATE_OPTIONS = [
    {
        value: 'safe_update',
        label: 'If empty or filled by Vainu',
        description: UPDATE_TYPE_TOOLTIPS_MAP['safe_update'],
    },
    {
        value: 'always',
        label: 'Always by overwriting',
        description: UPDATE_TYPE_TOOLTIPS_MAP['always'],
    },
    {
        value: 'only_for_new_companies',
        label: 'Only for creating a new company',
        description: UPDATE_TYPE_TOOLTIPS_MAP['only_for_new_companies'],
    },
];

interface MappingUpdateControlProps {
    disabled?: boolean;
    showInfoBanner?: boolean;
    defaultSelectStyles?: boolean;
    disabledTooltip: string | undefined;
    value: DataUpdateType;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MappingUpdateSelect: React.FC<MappingUpdateControlProps> = ({
    disabled,
    showInfoBanner = false,
    defaultSelectStyles = false,
    disabledTooltip,
    value,
    onChange,
}) => {
    return (
        <div>
            <Tooltip title={disabledTooltip} placement="right">
                <div style={{ width: 'fit-content' }}>
                    <StyledTextField
                        disabled={disabled}
                        compact
                        select
                        defaultSelectStyles={defaultSelectStyles}
                        value={value}
                        onChange={onChange}
                    >
                        {UPDATE_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                <Tooltip title={option.description} placement="right">
                                    <span>{option.label}</span>
                                </Tooltip>
                            </MenuItem>
                        ))}
                    </StyledTextField>
                </div>
            </Tooltip>
            {showInfoBanner && (
                <UpdateInfoBanner>
                    <Icon color="action.disabled" fontSize="small" type="InfoCircleOutline" />
                    <Typography component="span" style={{ marginLeft: '8px' }} fontSize="14px">
                        {UPDATE_TYPE_TOOLTIPS_MAP[value]}
                    </Typography>
                </UpdateInfoBanner>
            )}
        </div>
    );
};

const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'defaultSelectStyles',
})<{ defaultSelectStyles: boolean }>(({ theme: { palette }, disabled, defaultSelectStyles }) => ({
    '&&': {
        width: 230,
        pointerEvents: disabled ? 'none' : 'auto',
    },
    '&& .MuiInputBase-root': {
        background: 'inherit',
        paddingLeft: 0,
    },
    ...(!defaultSelectStyles && {
        '&&:hover .MuiInputBase-root, && .MuiInputBase-root.Mui-focused': {
            background: palette.brandColors.subtleLight,
        },
        '&& .MuiInputBase-root fieldset': {
            border: 'none',
        },
        '.MuiInputBase-root': {
            fontWeight: 500,
        },
    }),
}));

const UpdateInfoBanner = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    padding: '16px',
    backgroundColor: '#F9F9F9',
    borderRadius: '8px',
});

export { MappingUpdateSelect };
