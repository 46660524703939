import { styled } from '@mui/material';
import { ResponsiveContainer } from 'recharts';

const StyledResponsiveContainer = styled(ResponsiveContainer)({
    '& svg': {
        overflow: 'visible',
    },
});

export { StyledResponsiveContainer as ResponsiveContainer };
export default StyledResponsiveContainer;
