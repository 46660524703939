import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import { countriesGlobalOptions } from 'utilities/profiles/constants';

import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterProps,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, valueArrayToText } from '../utils';

const FIELD = 'concern#countries';

export type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.group_countries,
    label: "Group's countries of operations",
    defaultOperator: FilterOperator.IN,
    initValue: { [FilterOperator.IN]: { [FIELD]: [] } },
};

export const groupCountriesFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.group_structure,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <GroupCountries {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

const GroupCountries: React.VFC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;

                    const selectedOptions = countriesGlobalOptions.filter((item) =>
                        value.includes(item.value as string),
                    );

                    return (
                        <Select
                            placeholder={selectedOptions?.length ? 'Or...' : 'Select'}
                            multiple={true}
                            width="100%"
                            options={countriesGlobalOptions}
                            value={selectedOptions}
                            onValueChange={(options) => onChange((options as SelectOption[]).map((o) => o.value))}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
