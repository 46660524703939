import React, { useRef } from 'react';

import { useParams, useSearchParams, useLocation, Navigate } from 'react-router-dom';

import { useCompaniesSearchResult } from 'api/search_companies';
import { MainPath } from 'components/beta/Platform/Router/routePaths';
import SearchResults from 'components/features/SearchResults';
import CompanyProfilePopup from 'components/layout/CompanyProfilePopup';
import { useListsContext } from 'contexts/ListsContext';
import useAddToStaticList from 'hooks/useAddToStaticList';

import ProfileSelector from '../profiles/Profile';
import { getProfileDataType, isValidProfileType } from '../profiles/ProfilesRouter';
import { useNavigateToSearch } from './SearchRouter';

export type SearchTermState = {
    searchTerm?: string;
};

export const Search: React.VFC = () => {
    const [searchParams] = useSearchParams();
    const { state } = useLocation();
    const { profileType, profileId } = useParams();
    const navigateToSearch = useNavigateToSearch();
    const { addToList } = useAddToStaticList();
    const {
        allCompaniesSelected,
        selectedCompanies,
        unselectedCompanies,
        database,
        selectProfile,
        unselectProfile,
        toggleSelectAllCompanies,
    } = useListsContext();

    const lastTarget = useRef<string | number | null>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    const searchTerm = searchParams.get('q') ?? (state as SearchTermState)?.searchTerm ?? '';

    const { data = [], isLoading } = useCompaniesSearchResult({ companyName: searchTerm, database });

    const handleProfileClose = () => {
        navigateToSearch(searchTerm);
    };

    const handleSelectCompany = (value: string, isShiftClick: boolean) => {
        if (isShiftClick && lastTarget.current) {
            const lastTargetIndex = data.findIndex((company) => company.id === lastTarget.current);
            const targetIndex = data.findIndex((company) => company.id === value);
            if (lastTargetIndex !== -1 && targetIndex !== -1) {
                const selected = data
                    .slice(Math.min(lastTargetIndex, targetIndex), Math.max(lastTargetIndex, targetIndex) + 1)
                    .map((company) => company.id);
                selectProfile(selected);
            }
        } else {
            lastTarget.current = value;
            selectProfile(value);
        }
    };

    const handleUnselectCompany = (value: string) => {
        lastTarget.current = null;
        unselectProfile(value);
    };

    const handleAddMenuAction = async (action: string) => {
        if (allCompaniesSelected) {
            const selectedCompanyIds = data
                .map((company) => company.id)
                .filter((id) => !unselectedCompanies.includes(id));
            addToList(action, selectedCompanyIds);
        } else {
            addToList(action, selectedCompanies);
        }
    };

    if (!searchTerm && !profileType && !profileId) {
        return <Navigate to={`/${MainPath.Profiles}`} replace />;
    }

    return (
        <>
            <SearchResults
                searchTerm={searchTerm}
                navbarDomainResults={data}
                isLoading={isLoading}
                isAllCompaniesSelected={allCompaniesSelected}
                selectedCompanies={selectedCompanies}
                unselectedCompanies={unselectedCompanies}
                onSelectCompany={handleSelectCompany}
                onUnselectCompany={handleUnselectCompany}
                onHandleAddMenuAction={handleAddMenuAction}
                onToggleSelectAllCompanies={toggleSelectAllCompanies}
            />
            {profileId !== undefined && isValidProfileType(profileType) && (
                <CompanyProfilePopup scrollRef={scrollRef} open={profileId !== undefined} onClose={handleProfileClose}>
                    <ProfileSelector scrollRef={scrollRef} type={getProfileDataType(profileType)} id={profileId} />
                </CompanyProfilePopup>
            )}
        </>
    );
};

export default Search;
