import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HelpCircle = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM11 17V19H13V17H11ZM12 7C13.1046 7 14 7.89543 14 9C14.0035 9.53073 13.7904 10.0399 13.41 10.41L12.17 11.67C11.4214 12.4217 11.0008 13.4391 11 14.5V15H13C12.9223 13.925 13.3559 12.8763 14.17 12.17L15.07 11.25C15.6681 10.6543 16.003 9.84411 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9H10C10 7.89543 10.8954 7 12 7Z" />
    </SvgIcon>
);

export default HelpCircle;
