import React from 'react';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Typography from 'components/tokens/Typography';

export const TableCellSecondColumn: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
    <TableCell
        sx={{
            [`&.${tableCellClasses.head}`]: {
                minWidth: 140,
            },
            [`&.${tableCellClasses.body}`]: {
                minWidth: 140,
                wordBreak: 'break-all',
            },
        }}
    >
        <Typography variant="small" weight="normal" color="dark">
            {children}
        </Typography>
    </TableCell>
);
