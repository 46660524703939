import { AxiosInstance } from 'axios';

import { Database, NordicDatabase } from 'contexts/ListsContext';
import { CompanyPreview } from 'contexts/TriggerContext';

import { Contact } from './types/company';
import { MessageQueue, MessageQueueType, SendMessageQueueRequest } from './types/MessageQueue';
import { SignalAPIResponse } from './types/signals';

export function getMessageQueueItems(
    axios: AxiosInstance,
    triggerID: string,
    offset: number,
    limit: number = 100,
    lead?: number,
) {
    if (!triggerID) {
        return Promise.reject();
    }
    const offsetParam = offset ? `&offset=${offset}` : '';
    const leadParam = lead ? `&lead=${lead}` : '';
    return axios.get<MessageQueue[]>(`/mapi/message_queue/${triggerID}/?limit=${limit}${offsetParam}${leadParam}`);
}

export function createMessageQueueItem(axios: AxiosInstance, triggerID: string, data: MessageQueue) {
    if (!triggerID) {
        return Promise.reject();
    }
    return axios.post<MessageQueue>(`/mapi/message_queue/${triggerID}/`, data);
}

export function sendMessageQueueItem(axios: AxiosInstance, data: SendMessageQueueRequest) {
    return axios.post<MessageQueue>(`/mapi/send_message_queue_items/`, data);
}

export async function createMultipleMessageQueueItems(axios: AxiosInstance, triggerID: string, data: MessageQueue[]) {
    if (!triggerID) {
        return Promise.reject();
    }
    const requests = data.map((message) => createMessageQueueItem(axios, triggerID, message));
    const responses = await Promise.all(requests);
    return responses.map(({ data: responseData = [] }) => responseData) as MessageQueue[];
}

export function generateMessageQueueObject(
    company: CompanyPreview,
    lead: Pick<SignalAPIResponse, 'vid' | 'dynamic_values'>,
    triggerId: string,
    contact: Partial<Contact> | undefined,
    database: Database,
): MessageQueue {
    const data = {
        relation: {
            db: formatDb(database),
            key: (database === 'DOMAIN_DATA_BASIC' ? company.domain : company.business_id) as string,
        },
        lead: lead.vid as number,
        lead_type: lead.dynamic_values ? 'GeneratedLead' : 'Lead',
        message_type: 'trigger_leads' as MessageQueueType,
        trigger: triggerId,
        contact,
    };
    return data;
}

const formatDb = (db: Database): NordicDatabase | 'GLOBAL' => {
    if (db === 'DOMAIN_DATA_BASIC') {
        return 'GLOBAL';
    }
    return db;
};
