import React, { useEffect, useState } from 'react';

import { ClickAwayListener } from '@mui/base';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Popper from '@mui/material/Popper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { anchorRef, bindPopper, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';

import Button from 'components/tokens/Button';
import { useTrigger, ActionType } from 'contexts/TriggerContext';
import { brandPalette } from 'design/theme/colors';

import TriggerButton from './TriggerButton';

const activeChipStyle = {
    fontSize: 14,
    fontWeight: 500,
    color: brandPalette.white,
    backgroundColor: brandPalette.onyx,
    border: `1px solid ${brandPalette.onyx}`,
    borderRadius: '19px',
    marginRight: '6px',
    '&:focus': {
        backgroundColor: `${brandPalette.onyx} !important`,
    },
    '&:checked': {
        backgroundColor: `${brandPalette.onyx} !important`,
    },
    '&:hover': {
        backgroundColor: `${brandPalette.onyx} !important`,
    },
};

const chipStyle = {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: '#F2F2F2',
    border: '1px solid #E0E0E0',
    borderRadius: '19px',
    marginRight: '6px',
};

const OPTIONS: [number | null, string | number][] = [
    [10, 10],
    [20, 20],
    [30, 30],
    [40, 40],
    [50, 50],
];

const SelectLimitPopper: React.FC = () => {
    const popupState = usePopupState({ variant: 'popper', popupId: 'select-limit-popper' });
    const [state, dispatch, { activePopup, setActivePopup }] = useTrigger();
    const { dailyLimit } = state;
    const [editedLimit, setEditedLimit] = useState(dailyLimit);
    const [showErrorSnackbarMessage, setShowErrorSnackbarMessage] = useState('');
    const [showCustomValue, setShowCustomValue] = useState(false);
    const anchor = anchorRef(popupState);

    useEffect(() => {
        if (popupState.isOpen) {
            setActivePopup(popupState.popupId);
        }
    }, [popupState, setActivePopup]);

    useEffect(() => {
        if (activePopup && popupState.popupId !== activePopup && popupState.isOpen) {
            popupState.close();
        }
        if (activePopup && popupState.popupId === activePopup && !popupState.isOpen) {
            popupState.open();
        }
    }, [activePopup, popupState]);

    useEffect(() => {
        setEditedLimit(dailyLimit);
    }, [dailyLimit]);

    const getChipStyle = (value: number | null) => {
        return editedLimit === value ? activeChipStyle : chipStyle;
    };

    const onCancel = () => {
        setActivePopup(undefined);
        popupState.close();
    };

    const onSave = () => {
        dispatch({ type: ActionType.EDIT_LIMIT, dailyLimit: editedLimit });
        setActivePopup(undefined);
        popupState.close();
    };

    const getLimitFieldText = () => {
        if (dailyLimit === null) {
            return 'Unlimited';
        }
        if (dailyLimit) {
            return `Max ${dailyLimit} daily`;
        }
        return 'Limit';
    };
    const limitFieldText = getLimitFieldText();
    const customValue = ![null, 0, 10, 20, 30, 40, 50].includes(editedLimit) || showCustomValue;
    return (
        <div id="trigger-limit" ref={anchor}>
            <TriggerButton {...bindToggle(popupState)}>{limitFieldText}</TriggerButton>
            <Popper
                {...bindPopper(popupState)}
                placement="bottom-start"
                transition
                popperOptions={{
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: true,
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 4],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={onSave}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Card sx={{ width: 500, display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ padding: 2, paddingTop: 3, overflowY: 'auto', flex: 1 }}>
                                    <Grid container>
                                        {OPTIONS.map(([value, label]) => (
                                            <Chip
                                                key={value}
                                                color="default"
                                                sx={getChipStyle(value)}
                                                label={label}
                                                variant="outlined"
                                                clickable
                                                onClick={() => {
                                                    setEditedLimit(value);
                                                    setShowCustomValue(false);
                                                }}
                                            />
                                        ))}
                                        {customValue ? (
                                            <TextField
                                                type="number"
                                                id="custom-limit-input"
                                                data-testid="custom-limit-input"
                                                value={editedLimit}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{ inputProps: { min: 1 } }}
                                                sx={{
                                                    marginTop: '20px',
                                                    input: {
                                                        '& label.Mui-focused': {
                                                            color: brandPalette.onyx,
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: brandPalette.onyx,
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: brandPalette.onyx,
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: brandPalette.onyx,
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: brandPalette.onyx,
                                                            },
                                                        },
                                                    },
                                                }}
                                                onChange={(e) => setEditedLimit(parseInt(e.target.value, 10))}
                                                label="Type amount"
                                            />
                                        ) : (
                                            <Chip
                                                color="default"
                                                sx={chipStyle}
                                                label="Type amount"
                                                variant="outlined"
                                                clickable
                                                onClick={() => {
                                                    setEditedLimit(1);
                                                    setShowCustomValue(true);
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ padding: 2 }}>
                                    <Grid container justifyContent="flex-end" spacing={1}>
                                        <Grid item>
                                            <Button variant="flat" onClick={() => onCancel()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => onSave()}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    open={!!showErrorSnackbarMessage}
                                    autoHideDuration={3000}
                                    onClose={() => setShowErrorSnackbarMessage('')}
                                    message={<span>{showErrorSnackbarMessage}</span>}
                                />
                            </Card>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </div>
    );
};

export default SelectLimitPopper;
