import { Team } from 'api/types/Account';

export const getCurrentPermission = (
    profileId: string,
    allReadOnlyProfileIds: string[],
    allReadWriteProfileIds: string[],
) => {
    if (allReadOnlyProfileIds.some((id) => id === profileId)) {
        return 'read_only';
    } else if (allReadWriteProfileIds.some((id) => id === profileId)) {
        return 'read_write';
    } else {
        return 'not_shared';
    }
};

export const getAllProfileIDsAsList = (users: Team[]) => {
    return users.map((user) => user.id);
};
