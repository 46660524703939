export { default as AreaChart } from './AreaChart';
export { default as BarChart } from './BarChart';
export { default as HorizontalBarChart } from './HorizontalBarChart';
export { default as LineChart } from './LineChart';
export { default as PieChart } from './PieChart';
export { default as ProgressChart } from './ProgressChart';

export type ChartYearDataPoint = {
    year: number;
    value: number | undefined;
};

export type ChartCategoryDataPoint = {
    label: string;
    value: number | undefined;
};

export type ChartDataPoint = ChartYearDataPoint | ChartCategoryDataPoint | { [k: string]: number };

export function isYearData(data: ChartDataPoint): data is ChartYearDataPoint {
    return 'year' in data;
}
