import { sub } from 'date-fns';
import { simpleHash } from 'utilities';

import { InsightsPayload } from 'api/filterCompanies';
import { Database } from 'contexts/ListsContext';

import { VehicleCategoryTop } from './vehicleTypes';

const getPayload = (
    database: Database | undefined,
    businessId: string,
    useRanges: boolean,
    categories?: VehicleCategoryTop[],
): InsightsPayload | undefined => {
    if (!database) {
        return;
    }

    const bucket_count_range = useRanges ? 5 : undefined;

    const query = {
        '?ALL': [
            {
                '?EQ': {
                    business_id: businessId,
                },
            },
        ],
    };

    return {
        use_filters_v2: 'true',
        database,
        limit: 1,
        query_hash: simpleHash(JSON.stringify(query), 36),
        query_uid: [database, businessId, ...(categories || [])].join('-'),
        query,
        stats: [
            {
                id: 'make',
                '?AGGREGATE': [
                    {
                        '?GROUP': {
                            path: 'vehicles.*.make',
                            bucket_count_range,
                        },
                    },
                ],
            },
            {
                id: 'category',
                '?AGGREGATE': [
                    {
                        '?GROUP': {
                            path: 'vehicles.*.category_top',
                            bucket_count_range,
                        },
                    },
                ],
            },
            {
                id: 'used_car_flag',
                '?AGGREGATE': [
                    {
                        '?GROUP': {
                            path: 'vehicles.*.used_car_flag',
                            bucket_count_range,
                        },
                    },
                ],
            },
            {
                id: 'age',
                '?AGGREGATE': [
                    {
                        '?GROUP': {
                            path: 'vehicles.*.first_registered',
                            buckets: getFirstRegisteredBuckets(),
                            bucket_count_range,
                        },
                    },
                ],
            },
            {
                id: 'use_start_within_year',
                '?AGGREGATE': [
                    {
                        '?GROUP': {
                            path: 'vehicles.*.use_start_date',
                            buckets: [{ '?GTE': { date: sub(new Date(), { years: 1 }) } }],
                            bucket_count_range,
                        },
                    },
                ],
            },
            {
                id: 'owner_business_id',
                '?AGGREGATE': [
                    {
                        '?GROUP': {
                            path: 'vehicles.*.owner_business_id',
                            bucket_count_range,
                        },
                    },
                ],
            },
            {
                id: 'ownership_type',
                '?AGGREGATE': [
                    {
                        '?GROUP': {
                            path: 'vehicles.*.ownership_type',
                            bucket_count_range,
                        },
                    },
                ],
            },
            {
                id: 'gearing',
                '?AGGREGATE': [
                    {
                        '?GROUP': {
                            path: 'vehicles.*.gear_type',
                            bucket_count_range,
                        },
                    },
                ],
            },
        ].map((statGroup) => {
            if (categories?.length) {
                return {
                    ...statGroup,
                    '?AGGREGATE': [
                        {
                            '?FILTER_SUBDOCUMENTS': {
                                path: 'vehicles',
                                query: {
                                    '?IN': {
                                        category_top: categories,
                                    },
                                },
                            },
                        },
                        ...statGroup['?AGGREGATE'],
                    ],
                };
            }
            return statGroup;
        }),
    };
};

export default getPayload;

const getFirstRegisteredBuckets = () => {
    const now = new Date();
    return [
        { '?GTE': { date: sub(now, { years: 1 }) }, label: '< 1' },
        { '?GTE': { date: sub(now, { years: 2 }) }, label: '1-2' },
        { '?GTE': { date: sub(now, { years: 5 }) }, label: '3-5' },
        { '?GTE': { date: sub(now, { years: 10 }) }, label: '6-10' },
        { label: '> 10' },
    ];
};
