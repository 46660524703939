import Frame, { FrameProps } from 'components/tokens/Frame';

export type SignalCardWrapperProps = Omit<FrameProps, 'variant'> & {
    variant: 'shadowed' | 'outlined';
};

export const SignalCardWrapper: React.FC<SignalCardWrapperProps> = ({ children, variant, sx, ...props }) => (
    <Frame
        elevation={variant === 'outlined' ? 0 : 2}
        sx={{
            position: 'relative',
            height: 360,
            maxWidth: 320,
            minWidth: 215,
            display: 'flex',
            flexDirection: 'column',
            transition: 'all 0.2s',
            ...(variant === 'outlined'
                ? {
                      border: '1px solid',
                      borderColor: 'border',
                      padding: { xs: '15px', md: '19px' },
                  }
                : {
                      padding: { xs: '16px', md: '20px' },
                  }),
            '& .relatedCompanies': {
                position: 'absolute',
                bottom: 26,
                transition: 'bottom 0.2s',
            },
            '&:hover': {
                transform: 'scale(1.04)',
                boxShadow: '0px 32px 32px rgba(10, 10, 10, 0.05), 0px 16px 16px rgba(10, 10, 10, 0.05)',
                // Disable these until the card buttons are brought back.
                // '& .relatedCompanies': {
                //     bottom: 66,
                // },
                // '& .signalFooter': {
                //     bottom: 40,
                // },
                '& .buttonContainer': {
                    opacity: 1,
                },
            },
            ...sx,
        }}
        {...props}
    >
        {children}
    </Frame>
);

export default SignalCardWrapper;
