import { useEffect, useMemo, useRef, useState } from 'react';

export interface UseFilterGroupTemplateScrollProps {
    isFilterAdded: boolean;
    setIsFilterAdded: (value: boolean) => void;
}

export const useFilterGroupTemplateScroll = ({
    isFilterAdded,
    setIsFilterAdded,
}: UseFilterGroupTemplateScrollProps) => {
    const topWrapperRef = useRef<HTMLDivElement | null>(null);
    const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        setContainerEl(topWrapperRef?.current);
    }, [topWrapperRef]);

    useEffect(() => {
        if (isFilterAdded) {
            topWrapperRef.current?.scrollTo({ top: topWrapperRef.current?.scrollHeight, behavior: 'smooth' });
            setIsFilterAdded(false);
        }
    }, [isFilterAdded, setIsFilterAdded]);

    return useMemo(() => ({ topWrapperRef, containerEl }), [containerEl]);
};
