import React, { useState } from 'react';

import { Badge, Box, InputAdornment, MenuItem, SxProps, Popover, MenuList } from '@mui/material';

import { FilterTagObject } from 'api/types/Tag';
import Button from 'components/tokens/Button';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import SearchHighlight from 'components/tokens/SearchHighlight';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';

export type SignalsMultiSelectProps = {
    tags: FilterTagObject[];
    value: number[];
    label: string;
    sx?: SxProps;
    onChange?: (id: number) => void;
    onSelectAll?: (shownTagIds: number[]) => void;
    onDeselectAll?: (shownTagIds: number[]) => void;
    onClose?: () => void;
};

export const SignalsMultiSelect: React.VFC<SignalsMultiSelectProps> = ({
    tags,
    value,
    label = 'Filter results',
    sx,
    onChange,
    onSelectAll,
    onDeselectAll,
    onClose,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        onClose?.();
    };

    const foundTags = tags.filter((tag) => tag.label.toLowerCase().includes(searchTerm.toLowerCase()));
    const selectedTagsCount = foundTags.filter((tag) => value.includes(tag.id)).length;
    const someTagsSelected = selectedTagsCount > 0 && selectedTagsCount !== tags.length;

    const handleChange = (itemId: number) => {
        onChange?.(itemId);
    };

    const handleSelectAllClick = () => {
        if (selectedTagsCount > 0) {
            onDeselectAll?.(foundTags.map((tag) => tag.id));
        } else {
            onSelectAll?.(foundTags.map((tag) => tag.id));
        }
        setSearchTerm('');
    };

    const getSelectAllLabel = () => {
        if (searchTerm) {
            if (selectedTagsCount > 0) {
                return `Deselect all with “${searchTerm}” (${selectedTagsCount})`;
            }
            return `Select all with “${searchTerm}” (${foundTags.length})`;
        }
        if (selectedTagsCount > 0) {
            return `Clear all (${selectedTagsCount})`;
        }
        return `Select all (${foundTags.length})`;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                ...sx,
            }}
        >
            <Badge badgeContent={value.length} color="secondary">
                <Button
                    id="signals-filter-button"
                    aria-controls="signals-filter-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    endIcon={<Icon type={open ? 'ChevronUp' : 'ChevronDown'} />}
                    variant="tertiary"
                >
                    {label}
                </Button>
            </Badge>
            <Popover
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                elevation={3}
                BackdropProps={{ sx: { background: 'transparent' } }}
                sx={{
                    marginTop: 0.5,
                }}
                PaperProps={{
                    sx: {
                        padding: 1,
                        paddingTop: 0,
                        width: 360,
                        maxHeight: 370,
                        position: 'relative',
                        overflowY: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                    'aria-labelledby': 'signals-filter-button',
                }}
                id="signals-filter-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <TextField
                    fullWidth
                    compact
                    icon="Search"
                    value={searchTerm}
                    onChange={(event) => {
                        setSearchTerm(event.target.value);
                    }}
                    placeholder="Search filter type"
                    autoComplete="off"
                    onKeyDown={(event) => {
                        // Prevent jumping to another option while typing
                        event.stopPropagation();
                    }}
                    sx={{
                        paddingTop: 1,
                        marginBottom: 1,
                        '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
                            marginRight: 0,
                            opacity: 1,
                        },
                    }}
                    InputProps={{
                        endAdornment: searchTerm ? (
                            <InputAdornment position="end">
                                <Button
                                    size="medium"
                                    variant="flat"
                                    startIcon={<Icon sx={{ height: 20, width: 20 }} type="CloseSmall" />}
                                    onClick={() => setSearchTerm('')}
                                />
                            </InputAdornment>
                        ) : undefined,
                    }}
                />
                <MenuItem sx={{ display: 'flex' }} divider disableGutters onClick={handleSelectAllClick}>
                    <Checkbox
                        checked={selectedTagsCount > 0}
                        indeterminate={someTagsSelected}
                        size="small"
                        sx={{ marginY: '-4px', marginRight: '4px', marginLeft: 0, padding: '4px' }}
                    />
                    <Typography variant="small">
                        <span>{getSelectAllLabel()}</span>
                    </Typography>
                </MenuItem>
                <MenuList
                    sx={{
                        paddingTop: 0.5,
                        overflowY: 'auto',
                    }}
                >
                    {foundTags.length ? (
                        foundTags.map(({ id, label }) => (
                            <MenuItem
                                key={id}
                                sx={{
                                    display: 'flex',
                                    '&&': { minHeight: 32 },
                                    '&.Mui-selected': { backgroundColor: 'background.paper' },
                                    ':hover': {
                                        backgroundColor: 'background.default',
                                    },
                                }}
                                disableGutters
                                onClick={() => handleChange(id)}
                            >
                                <Checkbox
                                    size="small"
                                    checked={value.includes(id)}
                                    sx={{ marginY: '-4px', marginRight: '4px', marginLeft: 0, padding: '4px' }}
                                />
                                <Typography variant="small">
                                    <SearchHighlight text={label} searchTerm={searchTerm} />
                                </Typography>
                            </MenuItem>
                        ))
                    ) : (
                        <Typography
                            paragraph
                            variant="small"
                            color="subtle"
                            sx={{ paddingLeft: '32px', lineHeight: '32px', margin: 0 }}
                        >
                            No results
                        </Typography>
                    )}
                </MenuList>
            </Popover>
        </Box>
    );
};

export default SignalsMultiSelect;
