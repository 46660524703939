import { Box, BoxProps } from '@mui/material';
import { range } from 'utilities';

import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import { combineSx } from 'utilities/mui';

export type CompanyLogoStackProps = {
    companies?: {
        name?: string;
        logo_url?: string;
    }[];
    count?: number;
    loading?: boolean;
} & BoxProps;

export const CompanyLogoStack: React.FC<CompanyLogoStackProps> = ({
    companies,
    count = 3,
    loading = false,
    sx,
    ...props
}) =>
    companies ? (
        <Box sx={combineSx({ display: 'flex', alignItems: 'center', paddingLeft: 0.5 }, sx)} {...props}>
            {range(loading ? count : Math.min(count, companies.length)).map((index) => (
                <CompanyLogo
                    key={index}
                    size="tiny"
                    logo={companies[index]?.logo_url}
                    company_name={companies[index]?.name ?? '?'}
                    sx={{ outlineWidth: '4px', outlineStyle: 'solid', color: 'common.white', marginLeft: -0.5 }}
                    skeleton={loading}
                />
            ))}
        </Box>
    ) : null;
