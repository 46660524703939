import { useCallback, useState } from 'react';

import { Values } from 'components/templates/RangeSelect';

export const RANGE_DEFAULT_VALUE = [0, 100];

export type Score = Record<string, Values | undefined>;

export const useSelectedScore = (initialValues: Score) => {
    const [selectedScore, setSelectedScore] = useState<Score>(initialValues);

    const handleSelectedScoreChange = useCallback(
        (score: Score) => setSelectedScore((prev) => ({ ...prev, ...score })),
        [],
    );

    return { selectedScore, handleSelectedScoreChange };
};
