import { get, set } from 'react-hook-form';

import { LocationFilterProps, MappedFilter } from '../../FilterTypes';

interface UseLocationFilterValueProps<T> {
    filter: MappedFilter<T>;
    apply: LocationFilterProps<T>['apply'];
}

export function useLocationFilterValue<T>({ filter, apply }: UseLocationFilterValueProps<T>) {
    const value = get(filter.value, filter.valuePath);

    const onChange = (newValue: unknown) => {
        const newFilter = { ...filter };
        set(newFilter.value, filter.valuePath, newValue);
        apply(newFilter);
    };

    return { value, onChange };
}
