import React, { useState } from 'react';

import { ArrowRight } from '@mui/icons-material';
import { Grid, Typography, Collapse, Button, styled } from '@mui/material';

import { CRMBulkExportAsyncProcess } from 'api/types/UserAsyncProcess';

import { CrmName } from '../../types/Crm';
import { getCRMName } from '../../utils';
import BulkExportFailedDialog from './BulkExportFailedDialog';
import ErrorActionButton from './ErrorActionButton';
import { errorTitle } from './utils';

type ErrorContentProps = Pick<CRMBulkExportAsyncProcess, 'error' | 'error_msg'> & {
    meta_data?: CRMBulkExportAsyncProcess['meta_data'];
    disabledActions?: boolean;
    includeTitle?: boolean;
    crm?: CrmName;
    onCloseClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    primaryActionButton?: React.ReactNode;
    actionButtonPosition?: 'left' | 'right' | 'center';
    container?: 'sidepanel' | 'widget';
};

const ErrorContent: React.FC<ErrorContentProps> = ({
    includeTitle,
    error,
    error_msg,
    meta_data,
    disabledActions,
    crm,
    /* Action button props */
    onCloseClick,
    primaryActionButton,
    actionButtonPosition = 'right',
}) => {
    const [expand, setExpand] = useState(false);
    const toggleExpand = () => {
        setExpand((prev) => !prev);
    };
    return (
        <Container container spacing={1}>
            <Grid item xs={12}>
                <Typography color="inherit" fontSize="13px">
                    {includeTitle ? <ErrorTitle>{errorTitle(error)}: </ErrorTitle> : null}
                    {errorDesc(error, crm, meta_data)}
                </Typography>
            </Grid>
            {error_msg && (
                <>
                    <Grid item xs={12}>
                        <ToogleButton
                            variant="text"
                            onClick={toggleExpand}
                            disableElevation
                            disableRipple
                            startIcon={<ToggleIcon isExpanded={expand} htmlColor="#C4C4C4" />}
                        >
                            <Typography fontSize={13}>View error</Typography>
                        </ToogleButton>
                    </Grid>
                    <Collapse in={expand}>
                        <ErrorMsgWrapper item xs={12}>
                            <ErrorMsg color="inherit" variant="inherit">
                                {error_msg}
                            </ErrorMsg>
                        </ErrorMsgWrapper>
                    </Collapse>
                </>
            )}
            {!disabledActions ? (
                <ErrorActionButton
                    actionButtonPosition={actionButtonPosition}
                    onCloseClick={onCloseClick}
                    primaryActionButton={primaryActionButton}
                    error={error}
                />
            ) : null}
        </Container>
    );
};
export default ErrorContent;

function errorDesc(
    error: CRMBulkExportAsyncProcess['error'],
    crm: string = '',
    meta_data?: CRMBulkExportAsyncProcess['meta_data'],
) {
    const helpGuideLink = `https://help.vainu.app/en/articles/145627-crm-integration-error-messages`;
    const style = {
        color: '#0366d6',
        textDecoration: 'underline',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    } as React.CSSProperties;
    switch (error) {
        case 'BAD_REQUEST':
            return (
                <>
                    There seems to be an issue with your data mapping. Try checking our help guide to fix it directly or
                    ask our support team in the chat.
                </>
            );
        case 'AUTH_ERROR':
            return (
                <>
                    {getCRMName(crm)} had a problem identifying you. You can try again or try to reconnect it without
                    losing your integration settings. If this doesn't help, please, contact our support team in the{' '}
                    <b style={style} onClick={() => window.HubSpotConversations?.widget?.open()}>
                        chat
                    </b>
                    .
                </>
            );
        case 'MISSING_PERMISSION':
            return (
                <>
                    Seems like you need an extra permission to complete this. Try checking our{' '}
                    <b style={style} onClick={() => window.open(helpGuideLink, '_blank')}>
                        help guide
                    </b>{' '}
                    to fix it directly or ask our support team in the{' '}
                    <b style={style} onClick={() => window.HubSpotConversations?.widget?.open()}>
                        chat
                    </b>
                    .
                </>
            );
        case 'DUPLICATE_FOUND':
            const { size = 0, error_messages = [] } = meta_data || {};
            const errorCount = error_messages?.length ?? 0;
            const exportedCount = size - errorCount;
            return (
                <div style={{ backgroundColor: '#fff', padding: 16 }}>
                    <b
                        style={{
                            color: '#ED2B6C',
                            fontSize: 12,
                            fontWeight: 500,
                        }}
                    >
                        Issue with exporting:
                    </b>{' '}
                    We have exported <b style={{ color: '#349780' }}>{exportedCount}</b> companies to your{' '}
                    {getCRMName(meta_data?.crm)} but <b style={{ color: '#ED2B6C' }}>{errorCount}</b> couldn’t be sent
                    because you might have too strict duplicate rules in CRM or there is an issue with data mapping.
                    {meta_data && <BulkExportFailedDialog notificationMetadata={meta_data} />}
                </div>
            );
        case 'INTERNAL_ERROR':
        case 'USER_MESSAGE':
        case 'INTEGRATION_ERROR':
        default:
            return (
                <>
                    Sorry, it seems like something went wrong. Please, try again or contact our support team in the{' '}
                    <b style={style} onClick={() => window.HubSpotConversations?.widget?.open()}>
                        chat
                    </b>
                    .
                </>
            );
    }
}

const Container = styled(Grid)({
    color: '#5A6A78',
    fontWeight: 'normal',
});

const ToogleButton = styled(Button)({
    padding: 0,
    background: 'transparent !important',
});

const ToggleIcon = styled(ArrowRight)<{ isExpanded: boolean }>(({ isExpanded }) => ({
    transition: 'transform 200ms ease-in-out',
    transform: isExpanded ? 'rotate(90deg)' : '',
}));

const ErrorTitle = styled('span')({
    color: '#ED2B6C',
    fontWeight: 500,
});

const ErrorMsgWrapper = styled(Grid)({
    overflowY: 'scroll',
    maxHeight: 146,
});

const ErrorMsg = styled(Typography)({
    fontSize: '12px',
    marginLeft: '30px !important',
    display: 'block',
    padding: '8px 0px',
});
