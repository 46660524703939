import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckBold: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M8.81806 19.779L2.4541 13.415L5.2841 10.585L8.81806 14.129L18.716 4.22104L21.546 7.05104L8.81806 19.779Z" />
    </SvgIcon>
);

export default CheckBold;
