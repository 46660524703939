import { useEffect } from 'react';

const defaultTitle = 'Vainu';
const loadingTitle = 'Loading…';

export function setTitle(title?: string) {
    document.title = title || defaultTitle;
}

export const useTitle = (title?: string, isLoading = false) =>
    useEffect(() => {
        const previousTitle = document.title;
        if (isLoading) {
            setTitle(loadingTitle);
        }
        if (title) {
            setTitle(title);
        }
        return () => {
            document.title = previousTitle;
        };
    }, [title, isLoading]);

export const rootPath = process.env.BASE_URL || '/';

export const createPath = (path: string) => {
    if (path === '/') {
        return rootPath.endsWith('/') ? rootPath : `${rootPath}/`;
    }
    return rootPath.endsWith('/') ? `${rootPath}${path}` : `${rootPath}/${path}`;
};
