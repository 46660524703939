import React from 'react';

import { Button, Grid } from '@mui/material';

type ErrorActionButtonProps = {
    actionButtonPosition?: 'left' | 'right' | 'center';
    onCloseClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    primaryActionButton?: React.ReactNode;
    error: string;
};

export const ErrorActionButton: React.FC<ErrorActionButtonProps> = ({
    actionButtonPosition,
    primaryActionButton,
    error,
    onCloseClick = () => {},
}) => {
    let justify = 'center';
    if (actionButtonPosition === 'right') {
        justify = 'flex-end';
    } else if (actionButtonPosition === 'left') {
        justify = 'flex-start';
    }

    const defaultPrimaryAction = getErrorPrimaryActionButton(error, onCloseClick);

    return (
        <Grid container spacing={1} style={{ justifyContent: justify }}>
            <Grid item xs={actionButtonPosition === 'center' ? 6 : 'auto'}>
                {primaryActionButton || (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={defaultPrimaryAction?.onClick}
                        fullWidth
                        sx={{ padding: '4px 8px', fontSize: 14 }}
                    >
                        {defaultPrimaryAction.text}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

export default ErrorActionButton;

const getErrorPrimaryActionButton = (
    error: string,
    onCloseClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
) => {
    switch (error) {
        case 'MISSING_PERMISSION':
        case 'BAD_REQUEST':
        case 'AUTH_ERROR':
        case 'INTERNAL_ERROR':
        case 'USER_MESSAGE':
        case 'INTEGRATION_ERROR':
        default:
            return {
                text: 'Dismiss',
                onClick: onCloseClick,
            };
    }
};
