import { useMemo, useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import { getExternalReferences } from 'api/externalReferences';
import { Operation } from 'api/types/FilterOperators';
import { useAxiosContext } from 'contexts/AxiosContext';

const ROWS_PER_PAGE = 20;

type UseDialogDataProps<T> = {
    queryKey: string[];
    query: Operation;
    fields: (keyof T)[];
    defaultSortColumn: keyof T;
    defaultSortOrder: 'asc' | 'desc';
    enabled: boolean;
};

const useDialogData = <T,>({
    queryKey,
    query,
    fields,
    defaultSortColumn,
    defaultSortOrder,
    enabled,
}: UseDialogDataProps<T>) => {
    const axios = useAxiosContext();

    const [activeSort, setActiveSort] = useState<{ column: keyof T; order: 'asc' | 'desc' }>({
        column: defaultSortColumn,
        order: defaultSortOrder,
    });

    const infiniteQuery = useInfiniteQuery({
        queryKey: [...queryKey, activeSort.column, activeSort.order],
        queryFn: ({ pageParam = 0 }) =>
            getExternalReferences<T[]>(axios, {
                query,
                fields: fields as string[],
                offset: pageParam,
                limit: ROWS_PER_PAGE,
                order: `${activeSort.order === 'asc' ? '' : '-'}${String(activeSort.column)}`,
            }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage?.length < ROWS_PER_PAGE) {
                return undefined;
            }
            return allPages.reduce((acc, cur) => acc + cur.length, 0);
        },
        select: ({ pages, pageParams }) => ({
            pages: pages.flat(),
            pageParams,
        }),
        enabled,
    });

    return useMemo(() => ({ infiniteQuery, activeSort, setActiveSort }), [activeSort, infiniteQuery]);
};

export default useDialogData;
