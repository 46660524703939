import { FC, useState } from 'react';

import { Divider } from '@mui/material';
import { isArray, isEmpty } from 'lodash';

import Icon from 'components/tokens/Icon';
import { SelectOption, SelectProps } from 'components/tokens/select-components/Select';
import Select from 'components/tokens/select-components/Select/Select';

import { ANY_OPTION, LocationSelectOption, isSelectOption } from '../../groups/groups/LocationGroup/utils/helpers';
import { getColor } from './LocationFiltersSelect';

type RenderOptionFn = SelectProps<SelectOption>['renderOption'];
type OnValueChangeFn = SelectProps<SelectOption>['onValueChange'];

interface LocationTypeSelectProps extends Omit<SelectProps<SelectOption>, 'onValueChange'> {
    disableAnyOption?: boolean;
    onValueChange: (value: LocationSelectOption) => void;
    warningLevel?: 'warn' | 'err';
}

export function isAnyOptionValue(option: LocationSelectOption) {
    if (isSelectOption(option)) {
        return option.value === ANY_OPTION.value;
    } else {
        return isEmpty(option);
    }
}

export const LocationTypeSelect: FC<LocationTypeSelectProps> = ({
    onValueChange,
    disableAnyOption,
    value,
    warningLevel,
    color,
    ...props
}) => {
    const renderOption: RenderOptionFn = (optionProps, option, { selected }) => {
        if (option.value === ANY_OPTION.value) {
            return [
                <li style={{ fontSize: 14, padding: 8, borderRadius: 4 }} {...optionProps}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        {option.label}
                        <Icon
                            type="CheckBig"
                            sx={{
                                visibility: selected ? 'visible' : 'hidden',
                            }}
                        />
                    </div>
                </li>,

                <Divider sx={{ margin: '8px 0' }} key="divider" />,
            ];
        }
        return (
            <li style={{ fontSize: 14, padding: 8 }} {...optionProps}>
                {option.label}
            </li>
        );
    };

    const hideDefaultAnyOption = disableAnyOption && isAnyOptionValue(value as LocationSelectOption);
    const getConvertedValue = () => {
        if (hideDefaultAnyOption) {
            return props.multiple ? [] : null;
        }
        return value;
    };

    const convertedValue = getConvertedValue();

    return (
        <Select
            renderOption={renderOption}
            onValueChange={onValueChange as OnValueChangeFn}
            value={convertedValue}
            placeholder={hideDefaultAnyOption ? 'Select' : 'Or...'}
            color={color ?? getColor(warningLevel)}
            {...props}
        />
    );
};

interface UseLocationTypeValueProps {
    initialValue?: LocationSelectOption;
    onValueChange?: (options: LocationSelectOption) => void;
}

export function useLocationTypeValue({ initialValue = ANY_OPTION, onValueChange }: UseLocationTypeValueProps = {}) {
    const [value, _setValue] = useState<LocationSelectOption>(isEmpty(initialValue) ? ANY_OPTION : initialValue);
    const [isMultiple, setMultiple] = useState(isArray(value));

    const updateValue = (value: LocationSelectOption) => {
        _setValue(value);
        onValueChange?.(value);
    };
    const handleValueChange = (newValue: LocationSelectOption) => {
        if (isSelectOption(newValue)) {
            if (newValue.value === ANY_OPTION.value) {
                updateValue(ANY_OPTION);
                setMultiple(false);
                return;
            } else {
                updateValue([newValue]);
                setMultiple(true);
                return;
            }
        }

        if (newValue.length === 0 || newValue.some((v) => v.value === ANY_OPTION.value)) {
            updateValue(ANY_OPTION);
            setMultiple(false);
            return;
        }

        updateValue(newValue);
        setMultiple(true);
    };

    return { onValueChange: handleValueChange, isMultiple, value };
}
