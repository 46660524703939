import { useRegisters } from 'api/profileData';
import { organizationProfileType } from 'api/types/Organization';
import RegistersTable from 'components/features/Registers/Tables/RegistersTable';

import { TabContent, OrganizationTabProps } from '..';

export const RegistersTab: React.FC<OrganizationTabProps> = ({ businessId }) => {
    const { data: registersData, isLoading } = useRegisters({ type: organizationProfileType, key: businessId });

    return (
        <TabContent>
            <RegistersTable skeleton={isLoading} registers={registersData?.registers} />
        </TabContent>
    );
};

export default RegistersTab;
