import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Tooltip } from '@mui/material';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { intersection, isArray } from 'lodash';
import { useFieldArray, UseFieldArrayProps, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import ErrorHandler from 'components/beta/Platform/Core/ErrorHandler';
import { Icon } from 'components/tokens/Icon';
import { Link } from 'components/tokens/Link';
import { Typography } from 'components/tokens/Typography';
import { FileUploadProvider } from 'contexts/FileUploadContext';
import { useFilterState } from 'contexts/FilterContext';
import { Database, isUploaded, useListsContext } from 'contexts/ListsContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import { useTrigger } from 'contexts/TriggerContext';

import { TriggersWarning } from '../TriggersWarning';
import ActiveFiltersGroupTopSection from './ActiveFiltersGroupTopSection';
import { AddFiltersButton } from './AddFiltersButton';
import { getGroup } from './common/findGroup';
import { mergeKeys } from './common/utils';
import { getInnerMostValueInFilterState } from './filters/utils';
import {
    FilterID,
    GroupIDValue,
    GroupValues,
    MappedFilter,
    MappedGroup,
    NodeValue,
    UpdateGroupsFn,
} from './FilterTypes';
import { filterGroupDefinitions } from './groups/groupDefinitions';
import { contactOptions } from './groups/groups/ContactsGroup';

const crmEnabledByVainu = [
    'pipedrive',
    'salesforce',
    'dynamics',
    'upsales',
    'superoffice',
    'hubspot',
    'customer_api',
    'customer_api_lime_crm',
];

type Group = {
    id: GroupIDValue;
    databases?: Database[];
    permissions?: string[];
    showDefault?: Database[];
    requiresStaticList?: boolean;
};
type Groups = Group[];

const allGroups: Groups = [
    { id: GroupValues.basic, databases: ['FI', 'NO', 'DK', 'SE'] },
    { id: GroupValues.vainu_custom_industry, showDefault: ['DOMAIN_DATA_BASIC'], databases: ['DOMAIN_DATA_BASIC'] },
    {
        id: GroupValues.industry,
        showDefault: ['FI', 'NO', 'DK', 'SE'],
        databases: ['FI', 'NO', 'DK', 'SE'],
    },
    { id: GroupValues.location_visiting_address, showDefault: ['DOMAIN_DATA_BASIC', 'FI', 'NO', 'DK', 'SE'] },
    { id: GroupValues.organization_size_indicators, showDefault: ['DOMAIN_DATA_BASIC', 'FI', 'NO', 'DK', 'SE'] },
    { id: GroupValues.financial, databases: ['FI', 'NO', 'DK', 'SE'] },
    { id: GroupValues.social_media, databases: ['FI', 'NO', 'DK', 'SE'] },
    { id: GroupValues.web_profile },
    { id: GroupValues.group_structure, databases: ['FI', 'NO', 'DK', 'SE'] },
    { id: GroupValues.crm, permissions: crmEnabledByVainu },
    { id: GroupValues.target_group, databases: ['DOMAIN_DATA_BASIC', 'FI', 'NO', 'DK', 'SE'] },
    { id: GroupValues.board_of_directors, databases: ['FI', 'NO', 'DK', 'SE'] },
    {
        id: GroupValues.upload_list,
        requiresStaticList: true,
    },
    {
        id: GroupValues.contacts,
        databases: ['FI', 'NO', 'DK', 'SE'],
        showDefault: ['FI', 'NO', 'DK', 'SE'],
    },
];

enum TriggerWarningState {
    initial = 'initial',
    active = 'active',
    dismissed = 'dismissed',
}

const ROOT_PREFIX = FilterOperator.ALL;

const EMPTY_IDS: string[] = [];
const Filters: React.FC = memo(() => {
    const { activeGroup, setActiveGroup, onSubmit } = useFilterState();
    const { database, selectedList, doListAction } = useListsContext();
    const { productPermissions, hasProductPermission } = usePermissionContext();
    const [, , { triggers }] = useTrigger();
    const [triggerWarningState, setTriggerWarningState] = useState(TriggerWarningState.initial);

    const container = useRef(null);

    const activeTriggers = triggers.filter(
        (trigger) => trigger.status === 'active' && trigger.workflow_settings.target_group_source === selectedList?.id,
    );

    const shouldShowTriggerWarning = activeTriggers.length && triggerWarningState !== TriggerWarningState.dismissed;

    const ownerOrHasEditPermission =
        selectedList?.privileges.current === 'edit' || selectedList?.privileges.current === 'owner';

    const isStaticList = selectedList && isUploaded(selectedList);
    const isLegacy = selectedList?.type === 'legacy';
    const canAddFilters = !isLegacy && !isStaticList;

    const hasContactDataPermission = hasProductPermission(Permission.ContactData);
    const showContactFilters = hasContactDataPermission && database !== 'DOMAIN_DATA_BASIC';

    useEffect(() => {
        setTriggerWarningState(TriggerWarningState.initial);
    }, [selectedList?.id]);

    const { sortedGroupIDS, defaultGroupIds } = useMemo(
        () => ({
            sortedGroupIDS: allGroups
                .filter((group) => {
                    if (group.id === GroupValues.contacts) {
                        return false;
                    }
                    const { databases, requiresStaticList, permissions } = group;
                    if (requiresStaticList && !isStaticList) {
                        return false;
                    }
                    const hasDBpermission =
                        databases === undefined || (databases && databases.includes(database as Database));
                    const hasPermission =
                        permissions === undefined || Boolean(intersection(permissions, productPermissions).length);
                    return hasDBpermission && hasPermission;
                })
                .map((group) => group.id),
            defaultGroupIds: allGroups
                .filter((group) => {
                    if (isLegacy) {
                        return false;
                    }
                    const { showDefault, requiresStaticList } = group;

                    return (
                        (showDefault && showDefault.includes(database as Database)) ||
                        (isStaticList && requiresStaticList)
                    );
                })
                .map((group) => group.id),
        }),
        [database, isLegacy, isStaticList, productPermissions],
    );

    const { reset, formState, control, getValues } = useFormContext();

    const { fields, append, remove, update, replace } = useFieldArrayTyped<NodeValue>({
        control,
        name: FilterOperator.ALL,
    });

    const getMappedGroups = () => {
        const map = new Map<string, MappedGroup>();
        fields.forEach((field, index) => {
            const category = getGroup(field);

            const id = `${ROOT_PREFIX}.${index}`;

            if (!category) {
                return;
            }

            if (category.path.endsWith('social_media_urls#urls_web_tags')) {
                category.group = database === 'DOMAIN_DATA_BASIC' ? GroupValues.web_profile : GroupValues.social_media;
            } else if (category.path.includes('vainu_custom_industry')) {
                category.group =
                    database === 'DOMAIN_DATA_BASIC' ? GroupValues.vainu_custom_industry : GroupValues.industry;
            }

            const key = category.group;
            if (map.has(key)) {
                map.get(key)?.groupIds.push(id);
            } else {
                const definition = filterGroupDefinitions[key as GroupValues];

                map.set(key, {
                    groupIds: [id],
                    definition,
                    index,
                    path: mergeKeys(id, category.path),
                    value: field,
                });
            }
        });
        return map;
    };

    const mappedGroups = getMappedGroups();

    const undoChanges = () => {
        reset(formState.defaultValues);
    };

    const applyChanges = () => {
        onSubmit();
        setActiveGroup(undefined);
    };

    const clearFilters = (groupId?: string) => {
        const groupToFilter = activeGroup || groupId;
        if (!groupToFilter) {
            return;
        }

        const group = mappedGroups.get(groupToFilter);
        removeMany(group?.groupIds);
    };

    const removeMany = (ids: string[] = []) => {
        // Filter groups and exclude id field that is automatically added by useFieldArray
        const newFields = fields.reduce<NodeValue[]>((array, field, index) => {
            const id = `${ROOT_PREFIX}.${index}`;
            if (!ids.includes(id)) {
                const { id: _id, ...fieldValue } = field;
                array.push(fieldValue);
            }
            return array;
        }, []);

        replace(newFields);
    };

    const updateGroup = useCallback(
        (id: string, group: NodeValue) => {
            const keys = id.split('.');
            const index = keys[keys.length - 1];
            update(parseInt(index), group);
        },
        [update],
    );

    const updateGroups: UpdateGroupsFn = useCallback(
        (groups) => {
            if (isArray(groups)) {
                replace(groups);
                return;
            }

            const ids = Object.keys(groups);

            if (!ids.length) {
                return;
            }

            const newFields = fields.reduce<NodeValue[]>((array, field, index) => {
                const id = `${ROOT_PREFIX}.${index}`;

                if (ids.includes(id)) {
                    const group = groups[id];
                    if (group) {
                        array.push(group);
                    }
                } else {
                    const fieldValue = getValues(id);
                    array.push(fieldValue);
                }
                return array;
            }, []);

            replace(newFields);
        },
        [fields, getValues, replace],
    );

    const removeGroup = useCallback(
        (groupId: string) => {
            const keys = groupId.split('.');
            const index = keys[keys.length - 1];

            remove(parseInt(index));
        },
        [remove],
    );

    const getFilters = (groupName: string | undefined): MappedFilter[] => {
        if (!groupName || !mappedGroups.has(groupName)) {
            return [];
        }

        const group = mappedGroups.get(groupName);
        const groupIds = group?.groupIds;

        let filters: MappedFilter[] = [];

        const cb = group?.definition.getFilters;

        if (!cb) {
            return filters;
        }

        groupIds?.forEach((id) => {
            const nestedFilters = cb(id, getValues);

            if (!nestedFilters || nestedFilters?.isUndefined) {
                return;
            }

            if (isArray(nestedFilters?.filters)) {
                filters = filters.concat(nestedFilters.filters);
            } else {
                filters.push(nestedFilters?.filters);
            }
        });

        return filters;
    };

    const currentDef = activeGroup && activeGroup !== 'plus-button' ? filterGroupDefinitions[activeGroup] : undefined;
    const currentGroup = mappedGroups.get(activeGroup || '');

    return (
        <ErrorHandler fallback={<Fallback />}>
            <FileUploadProvider>
                <div>
                    <form>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }} ref={container}>
                            <FilterRow>
                                {canAddFilters && showContactFilters && (
                                    <Typography variant="body2">Companies:</Typography>
                                )}
                                <Grid>
                                    {sortedGroupIDS.map((groupName) => {
                                        const group = mappedGroups.get(groupName);

                                        const definition =
                                            group?.definition ?? filterGroupDefinitions?.[groupName as GroupValues];

                                        const groupFilters = getFilters(groupName);

                                        const showGroup =
                                            groupFilters.length ||
                                            groupName === activeGroup ||
                                            defaultGroupIds.includes(groupName);
                                        if (!showGroup) {
                                            return undefined;
                                        }
                                        return (
                                            <ActiveFiltersGroupTopSection
                                                onClick={() => {
                                                    if (shouldShowTriggerWarning) {
                                                        setTriggerWarningState(TriggerWarningState.active);
                                                        return;
                                                    }
                                                    setActiveGroup(groupName);
                                                }}
                                                onClearFilters={(groupId) => {
                                                    if (shouldShowTriggerWarning) {
                                                        setTriggerWarningState(TriggerWarningState.active);
                                                        return;
                                                    }
                                                    clearFilters(groupId);
                                                    onSubmit();
                                                }}
                                                key={groupName}
                                                uuid={groupName}
                                                activeFilterGroup={definition}
                                                activeFilters={groupFilters}
                                                activeGroup={activeGroup}
                                                hideClear={isLegacy}
                                            />
                                        );
                                    })}
                                    {canAddFilters ? (
                                        <>
                                            <Box sx={{ marginX: 1, borderLeft: '1px solid', borderColor: 'border' }} />
                                            <AddFiltersButton
                                                sortedGroupIDS={sortedGroupIDS}
                                                disabled={!ownerOrHasEditPermission}
                                                updateGroup={updateGroup}
                                                append={append}
                                                mappedGroups={mappedGroups}
                                                getValues={getValues}
                                                onOpen={
                                                    shouldShowTriggerWarning
                                                        ? () => {
                                                              setTriggerWarningState(TriggerWarningState.active);
                                                              return false;
                                                          }
                                                        : undefined
                                                }
                                            />
                                        </>
                                    ) : null}
                                </Grid>
                            </FilterRow>
                            {canAddFilters && showContactFilters && (
                                <FilterRow>
                                    <Typography variant="body2">Contacts:</Typography>
                                    <Grid>
                                        {[GroupValues.contacts].map((groupName) => {
                                            const group = mappedGroups.get(groupName);

                                            const definition =
                                                group?.definition ?? filterGroupDefinitions?.[groupName as GroupValues];

                                            const groupFilters = getFilters(groupName)
                                                .filter((filter) => {
                                                    let value = getInnerMostValueInFilterState(filter.value, '');

                                                    if (filter.id === FilterID.contact_is_decision_maker) {
                                                        return value;
                                                    }

                                                    if (filter.id === FilterID.contact_title_keywords) {
                                                        value = getInnerMostValueInFilterState(value as Object, '');
                                                        return (value as NodeValue[]).length;
                                                    }

                                                    if (filter.id === FilterID.contact_info) {
                                                        value = getInnerMostValueInFilterState(value as Object, '');
                                                        return value === true || value === 'linkedin';
                                                    }

                                                    if (Array.isArray(value)) {
                                                        return value.length !== 0;
                                                    }
                                                    return value != null;
                                                })
                                                .sort((a, b) => {
                                                    const aIdx = contactOptions.findIndex((opt) => opt.value === a.id);
                                                    const bIdx = contactOptions.findIndex((opt) => opt.value === b.id);
                                                    return aIdx - bIdx;
                                                });

                                            const showGroup =
                                                groupFilters.length ||
                                                groupName === activeGroup ||
                                                defaultGroupIds.includes(groupName);
                                            if (!showGroup) {
                                                return undefined;
                                            }
                                            return (
                                                <ActiveFiltersGroupTopSection
                                                    onClick={() => {
                                                        if (shouldShowTriggerWarning) {
                                                            setTriggerWarningState(TriggerWarningState.active);
                                                            return;
                                                        }
                                                        setActiveGroup(groupName);
                                                    }}
                                                    onClearFilters={(groupId) => {
                                                        if (shouldShowTriggerWarning) {
                                                            setTriggerWarningState(TriggerWarningState.active);
                                                            return;
                                                        }
                                                        clearFilters(groupId);
                                                        onSubmit();
                                                    }}
                                                    key={groupName}
                                                    uuid={groupName}
                                                    activeFilterGroup={definition}
                                                    activeFilters={groupFilters}
                                                    activeGroup={activeGroup}
                                                    hideClear={isLegacy}
                                                    showAll
                                                    sx={{ maxWidth: undefined }}
                                                />
                                            );
                                        })}
                                    </Grid>
                                </FilterRow>
                            )}
                        </Box>
                    </form>

                    {isLegacy && selectedList?.converted_query?.errors && (
                        <Tooltip
                            title={
                                typeof selectedList.converted_query.errors === 'string' ? (
                                    ''
                                ) : (
                                    <ul>
                                        {selectedList.converted_query.errors.map((error, idx) => (
                                            <li key={idx}>
                                                &middot; {error.field}: {error.code}
                                            </li>
                                        ))}
                                    </ul>
                                )
                            }
                        >
                            <Typography
                                variant="caption"
                                sx={{ marginTop: 2, display: 'inline-flex', alignItems: 'center', gap: 1 }}
                            >
                                <Icon type="WarningOutline" />
                                {typeof selectedList.converted_query.errors === 'string' ? (
                                    <>
                                        The list couldn't be converted.
                                        <Link toNewTab href="https://help.vainu.app/">
                                            Contact support
                                        </Link>
                                    </>
                                ) : (
                                    <Link href="#" onClick={(e) => e.preventDefault()}>
                                        Some of your filters couldn't be converted automatically
                                    </Link>
                                )}
                            </Typography>
                        </Tooltip>
                    )}

                    {triggerWarningState === TriggerWarningState.active && (
                        <TriggersWarning
                            triggers={activeTriggers}
                            onClose={() => setTriggerWarningState(TriggerWarningState.initial)}
                            onConfirm={() => {
                                setTriggerWarningState(TriggerWarningState.dismissed);
                            }}
                            onDuplicate={() => {
                                if (selectedList?.id) {
                                    doListAction(selectedList.id, 'duplicate');
                                    setTriggerWarningState(TriggerWarningState.initial);
                                }
                            }}
                        />
                    )}

                    <Popper
                        anchorEl={container.current}
                        open={!!activeGroup}
                        placement="bottom-start"
                        sx={{ zIndex: 2 }}
                    >
                        {activeGroup
                            ? currentDef?.render({
                                  filters: [],
                                  ids: currentGroup?.groupIds ?? EMPTY_IDS,
                                  removeGroup: removeGroup,
                                  undoChanges: undoChanges,
                                  applyChanges,
                                  clearFilters,
                                  addNewGroup: (group: NodeValue) => {
                                      append(group);
                                  },
                                  updateGroups,
                                  updateGroup,
                                  disabled: isLegacy,
                              })
                            : null}
                    </Popper>
                </div>
            </FileUploadProvider>
        </ErrorHandler>
    );
});

export default Filters;

Filters.displayName = 'Filters';

const Grid = styled('div')`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const FilterRow = styled('div')`
    display: flex;
    gap: 8px;
    > p {
        margin-top: 6px;
    }
`;

export function useFieldArrayTyped<FieldType>(props: UseFieldArrayProps) {
    const { fields, ...other } = useFieldArray(props);
    return { fields: fields as (FieldType & { id: string })[], ...other };
}

const Fallback = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',

            width: '100%',
            height: '100%',
            padding: 40,
            fontFamily: 'Roboto, sans-serif',
        }}
    >
        <h3 style={{ marginBottom: 20 }}>Error! My only weakness!</h3>
        <p style={{ marginBottom: 20 }}>
            It seems you’ve found a bug in our system. We are very sorry for this occurrence. Good news is that we’ve
            logged the error and are onto fixing it.
        </p>
        {/* eslint-disable */}
        <p style={{ marginBottom: 20 }}>
            {'Meanwhile, you could try '}
            <a href="#" onClick={() => window.location.reload()}>
                refreshing the page
            </a>
            {' or '}
            <a target="_blank" href="https://help.vainu.app/">
                contacting the support
            </a>
            .
        </p>
    </div>
);
