import React, { useCallback, useMemo, useReducer, useState } from 'react';

import { useQueries, useQuery } from '@tanstack/react-query';
import { produce } from 'immer';

import { useTags } from 'api/filter_values';
import { getTriggers, updateTrigger as updateTriggerAPI } from 'api/triggers';
import { FilterTag } from 'api/types/Tag';
import { TrackingLogic, Trigger } from 'api/types/Trigger';
import { CRMIntegConfigProvider } from 'components/modules/connectors/context/CRMIntegrationConfigContext';
import useConnectorsApi from 'components/modules/connectors/hooks/useConnectorsApi';
import { CRMObject, IntegrationSetting } from 'components/modules/connectors/types/Crm';
import { useAxiosContext } from 'contexts/AxiosContext';
import { getCategory, isActiveTrigger, isValidTrigger, mapStateToTriggerDBObject } from 'utilities/triggers';

import { useListsContext } from '../ListsContext';
import { MessageQueueProvider } from '../MessageQueueContext/MessageQueueContext';
import { usePermissionContext } from '../PermissionContext';
import {
    ActionType,
    DeliveryTime,
    DeliveryType,
    Destination,
    EventFilters,
    MessageContextItem,
    NotifyType,
    State,
} from './TriggerContextTypes';
import { convertEventsToQuery, convertQueryToEvents } from './triggerUtils';

type Action =
    | { type: ActionType.UNSELECT_TRIGGER }
    | { type: ActionType.SELECT_TRIGGER; trigger: Trigger; editMode?: boolean }
    | { type: ActionType.SELECT_SOURCE; source: string }
    | { type: ActionType.SET_NAME; name: string }
    | { type: ActionType.REMOVE_EVENT_FILTER; tag: string; name: string; convertEvents?: boolean }
    | {
          type: ActionType.REPLACE_TRIGGER_FILTERS;
          events: EventFilters;
          trackingLogic: TrackingLogic;
          trackChanges: string[];
      }
    | { type: ActionType.CONVERT_EVENTS_TO_QUERIES }
    | { type: ActionType.CONVERT_QUERIES_TO_EVENTS }
    | { type: ActionType.EDIT_DESTINATION; destination: Destination }
    | { type: ActionType.DISABLE_DESTINATION; destination: Destination }
    | { type: ActionType.EDIT_DELIVERY_TIME; deliveryTime: DeliveryTime; notify: DeliveryType }
    | { type: ActionType.EDIT_LIMIT; dailyLimit: number | null }
    | { type: ActionType.SET_CONTEXT; context: MessageContextItem[]; trackingLogic: TrackingLogic }
    | { type: ActionType.RESET_FILTER_STATE };

export type Dispatch = (action: Action) => void;

type TriggerProviderProps = { children: React.ReactNode };

export interface TriggerContextValue {
    triggers: Trigger[];
    refetch: () => void;
    updateTrigger: (id: string, payload: Partial<Trigger>) => void;
    pauseTrigger: (id: string) => void;
    allTags: FilterTag[];
    activeCRMIntegrationSettings: IntegrationSetting<CRMObject>[];
    activePopup: string | undefined;
    setActivePopup: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const TriggerStateContext = React.createContext<{ old: State; new: TriggerContextValue } | undefined>(undefined);

export const TriggerDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const CRMDestinations = ['pipedrive', 'dynamics', 'salesforce', 'hubspot', 'salesforcesandbox'];

// todo: refactor trigger data to TriggerSettingsForm's local state
// selected trigger could be in the url
function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.UNSELECT_TRIGGER:
            return { ...state, editTrigger: false };
        case ActionType.SELECT_TRIGGER:
            const {
                name,
                query = '',
                id = '',
                queries,
                notify,
                workflow_settings,
                utc_office_hours,
                notify_type,
                status,
                tracking_logic,
                track_changes = [],
                message_context,
            } = action.trigger;
            return produce(state, (draftState) => {
                const events = convertQueryToEvents(query);
                const { daily_limit, destinations, crm_source, target_group_source } = workflow_settings;
                const source = crm_source || target_group_source;
                const [is_valid, errors] = isValidTrigger(action.trigger);
                const mappedDestinations = destinations.map((destination) => {
                    const { targets, destination_type, active, extra = {}, assign_to = [] } = destination;
                    return {
                        system: destination_type,
                        active,
                        crm_objects: CRMDestinations.includes(destination_type) ? targets.map((i) => i.target) : [],
                        emails: destination_type === 'email' ? targets.map((i) => i.target) : [],
                        urls: destination_type === 'webhook' ? targets.map((i) => i.target) : [],
                        slackChannel: destination_type === 'slack' ? targets.map((i) => i.target) : [],
                        isValid: true,
                        extra,
                        assignTo: assign_to,
                    } as Destination;
                });
                draftState.trackingLogic = tracking_logic || {};
                draftState.trackChanges = track_changes;
                draftState.events = events;
                draftState.name = name;
                draftState.source = source;
                draftState.query = query;
                draftState.id = id;
                draftState.queries = queries;
                draftState.notify = notify as DeliveryType;
                draftState.destinations = mappedDestinations;
                draftState.deliveryTime = utc_office_hours;
                draftState.isValid = is_valid;
                draftState.dailyLimit = daily_limit;
                draftState.logicChanged = action.trigger?.logic_changed;
                draftState.created = action.trigger?.created;
                draftState.errors = errors;
                draftState.isActive = isActiveTrigger(action.trigger);
                draftState.editTrigger = true;
                draftState.status = status || 'disabled';
                draftState.messageContext = message_context?.context_items || [];
                draftState.selectedTrigger = {
                    name,
                    id,
                    query,
                    queries,
                    notify,
                    workflow_settings,
                    utc_office_hours,
                    notify_type,
                    status,
                    is_valid,
                    message_context,
                    tracking_logic,
                    track_changes,
                };
                type EventCategory = {
                    [key: string]: number;
                };
                const eventsInCategoryCount: EventCategory = {};
                Object.keys(events).forEach((key) => {
                    const category = getCategory(parseInt(key, 10));
                    eventsInCategoryCount[category] += 1;
                });
                draftState.eventsInCategoryCount = { ...defaultEventsInCategoryCounts, ...eventsInCategoryCount };
                if (action.editMode) {
                    draftState.editedTrigger = draftState.selectedTrigger;
                } else {
                    draftState.editedTrigger = {} as Trigger;
                }
            });
        case ActionType.SELECT_SOURCE:
            return produce(state, (draftState) => {
                draftState.source = action.source;
                const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                draftState.isValid = !!trigger.is_valid;
                draftState.isActive = is_active;
                draftState.errors = errors;
                draftState.editedTrigger = trigger;
            });
        case ActionType.SET_NAME:
            return produce(state, (draftState) => {
                draftState.name = action.name;
                const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                draftState.isValid = !!trigger.is_valid;
                draftState.isActive = is_active;
                draftState.errors = errors;
                draftState.editedTrigger = trigger;
            });
        case ActionType.REPLACE_TRIGGER_FILTERS:
            return produce(state, (draftState) => {
                draftState.eventsInCategoryCount = { ...defaultEventsInCategoryCounts };
                draftState.events = action.events;
                draftState.trackChanges = action.trackChanges.filter((i) => !!i);
                draftState.trackingLogic = action.trackingLogic;
                Object.keys(action.events).forEach((tag) => {
                    const category = getCategory(parseInt(tag, 10));
                    draftState.eventsInCategoryCount[category] += 1;
                });
                // TO-DO clean up messageContext
                draftState.query = convertEventsToQuery(draftState.events);
                const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                draftState.isValid = !!trigger.is_valid;
                draftState.isActive = is_active;
                draftState.errors = errors;
                draftState.editedTrigger = trigger;
            });
        case ActionType.REMOVE_EVENT_FILTER:
            return produce(state, (draftState) => {
                delete draftState.events[action.tag];
                const category = getCategory(parseInt(action.tag, 10));
                draftState.eventsInCategoryCount[category] -= 1;
                // remove item from context
                draftState.messageContext = draftState.messageContext.filter(
                    (i) => i?.lead_tag !== parseInt(action.tag, 10),
                );
                if (action.convertEvents) {
                    draftState.query = convertEventsToQuery(draftState.events);
                    const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                    draftState.isValid = !!trigger.is_valid;
                    draftState.isActive = is_active;
                    draftState.errors = errors;
                    draftState.editedTrigger = trigger;
                }
            });
        case ActionType.CONVERT_EVENTS_TO_QUERIES:
            return produce(state, (draftState) => {
                draftState.query = convertEventsToQuery(draftState.events);
                const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                draftState.isValid = !!trigger.is_valid;
                draftState.isActive = is_active;
                draftState.errors = errors;
                draftState.editedTrigger = trigger;
            });
        case ActionType.CONVERT_QUERIES_TO_EVENTS:
            return produce(state, (draftState) => {
                const events = convertQueryToEvents(draftState.query);
                draftState.events = events;
            });
        case ActionType.RESET_FILTER_STATE: {
            return produce(state, (draftState) => {
                const events = convertQueryToEvents(draftState.selectedTrigger.query);
                draftState.events = events;
                draftState.trackingLogic = draftState.selectedTrigger.tracking_logic || {};
                draftState.trackChanges = draftState.selectedTrigger.track_changes || [];
            });
        }
        case ActionType.EDIT_DESTINATION:
            return produce(state, (draftState) => {
                draftState.destinations = draftState.destinations.filter((i) => i.system !== action.destination.system);
                draftState.destinations.push(action.destination);
                const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                draftState.isValid = !!trigger.is_valid;
                draftState.isActive = is_active;
                draftState.errors = errors;
                draftState.editedTrigger = trigger;
            });
        case ActionType.DISABLE_DESTINATION:
            return produce(state, (draftState) => {
                draftState.destinations = draftState.destinations.filter((i) => i.system !== action.destination.system);
                const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                draftState.isValid = !!trigger.is_valid;
                draftState.isActive = is_active;
                draftState.errors = errors;
                draftState.editedTrigger = trigger;
            });
        case ActionType.EDIT_DELIVERY_TIME:
            return produce(state, (draftState) => {
                draftState.notify = action.notify;
                draftState.deliveryTime = action.deliveryTime;
                const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                draftState.isValid = !!trigger.is_valid;
                draftState.isActive = is_active;
                draftState.errors = errors;
                draftState.editedTrigger = trigger;
            });
        case ActionType.EDIT_LIMIT:
            return produce(state, (draftState) => {
                draftState.dailyLimit = action.dailyLimit;
                const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                draftState.isValid = !!trigger.is_valid;
                draftState.isActive = is_active;
                draftState.errors = errors;
                draftState.editedTrigger = trigger;
            });
        case ActionType.SET_CONTEXT:
            return produce(state, (draftState) => {
                const { context, trackingLogic } = action;
                draftState.messageContext = context;
                draftState.trackingLogic = trackingLogic;
                const [trigger, is_active, errors] = mapStateToTriggerDBObject(draftState);
                draftState.isValid = !!trigger.is_valid;
                draftState.isActive = is_active;
                draftState.errors = errors;
                draftState.editedTrigger = trigger;
            });
        default:
            return state;
    }
}

const defaultEventsInCategoryCounts = {
    financial_development: 0,
    personnel_changes: 0,
    legalities_and_controversy: 0,
    company_direction: 0,
    operations: 0,
    investments_and_upgrades: 0,
};

const initialState = {
    name: 'Untitled trigger 1',
    query: '',
    queries: undefined,
    source: '',
    id: '',
    editTrigger: false,
    events: {},
    editEvent: '',
    eventsInCategoryCount: { ...defaultEventsInCategoryCounts },
    destinations: [],
    deliveryTime: {
        week_days: [],
        start_hour: 8,
        end_hour: 16,
        start_min: 0,
        end_min: 0,
        timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezone_offset: '',
    },
    trackingLogic: {},
    dailyLimit: 0,
    notifyType: 'workflow_settings' as NotifyType,
    notify: null as DeliveryType,
    isValid: false,
    isActive: false,
    errors: [],
    selectedTrigger: {} as Trigger,
    editedTrigger: {} as Trigger,
    status: '',
    trackChanges: [],
    messageContext: [] as MessageContextItem[],
};

const integrationsMapping: Record<string, string> = {
    pipedrive_oauth2: 'pipedrive',
    dynamics_oauth2: 'dynamics',
    hubspot_oauth2: 'hubspot',
    superoffice_oauth2: 'superoffice',
    salesforce_oauth2: 'salesforce',
    salesforce_oauth2_sandbox: 'salesforcesandbox',
};

export function TriggerProvider({ children }: TriggerProviderProps) {
    const { database } = useListsContext();
    const { countryPermissions, integrations } = usePermissionContext();
    const axios = useAxiosContext();
    const { getActiveIntegrationSettingsOrCreateDefaults } = useConnectorsApi();

    const [activePopup, setActivePopup] = useState<string | undefined>();

    const [state, dispatch] = useReducer(reducer, initialState);
    const { id: triggerID } = state;

    const { data: triggers = [], refetch } = useQuery({
        queryKey: ['triggers', database],
        queryFn: () => getTriggers(axios, database),
        refetchOnWindowFocus: false,
        enabled: !!database,
    });

    const updateTrigger = useCallback(
        async (id: string, payload: Partial<Trigger>) => {
            await updateTriggerAPI(axios, database, id, payload);
            refetch();
        },
        [axios, database, refetch],
    );

    const pauseTrigger = useCallback(
        async (id: string) => {
            await updateTrigger(id, { status: 'disabled' });
        },
        [updateTrigger],
    );

    const { data: tags = [] } = useTags();

    const getActiveCRMIntegrationSettings = useCallback(
        async function (target: string) {
            const { data } = await getActiveIntegrationSettingsOrCreateDefaults(target);
            return data;
        },
        [getActiveIntegrationSettingsOrCreateDefaults],
    );

    const integrationSettingsQueries = useMemo(() => {
        return integrations
            .map((integration) => integrationsMapping[integration])
            .filter(Boolean)
            .map((target) => ({
                queryKey: ['getActiveCRMIntegrationSettings', target],
                queryFn: () => getActiveCRMIntegrationSettings(target),
                refetchOnWindowFocus: false,
                cacheTime: Infinity,
            }));
    }, [integrations, getActiveCRMIntegrationSettings]);

    const integrationSettingsData = useQueries({ queries: integrationSettingsQueries });

    const activeCRMIntegrationSettings = useMemo(() => {
        return integrationSettingsData.map((i) => i.data).filter(Boolean) as IntegrationSetting<unknown>[];
    }, [integrationSettingsData]);

    const allTags = useMemo(() => {
        return tags.filter((tag) => !tag[3].length || tag[3].filter((country) => countryPermissions.includes(country)));
    }, [countryPermissions, tags]);

    const newState = useMemo(
        () =>
            ({
                triggers,
                refetch,
                updateTrigger,
                pauseTrigger,
                allTags,
                activeCRMIntegrationSettings,
                activePopup,
                setActivePopup,
            }) as TriggerContextValue,
        [triggers, refetch, updateTrigger, pauseTrigger, allTags, activeCRMIntegrationSettings, activePopup],
    );

    return (
        <CRMIntegConfigProvider>
            <TriggerStateContext.Provider
                value={{
                    old: state,
                    new: newState,
                }}
            >
                <TriggerDispatchContext.Provider value={dispatch}>
                    <MessageQueueProvider triggerID={triggerID || ''}>{children}</MessageQueueProvider>
                </TriggerDispatchContext.Provider>
            </TriggerStateContext.Provider>
        </CRMIntegConfigProvider>
    );
}
