import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Globe = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 6.4775 17.5225 2 12 2ZM20.3333 12C20.3333 12.5708 20.275 13.1283 20.165 13.6666H16.9542C17.055 12.5975 17.0617 11.4825 16.9542 10.3333H20.165C20.275 10.8716 20.3333 11.4291 20.3333 12ZM16.7158 8.66659H19.6325C18.7567 6.66993 17.1217 5.08243 15.0925 4.26826C15.8392 5.54076 16.3967 7.03326 16.7158 8.66659ZM15.0042 8.66671H12.8333V3.95171C13.8458 5.16838 14.5975 6.79338 15.0042 8.66671ZM11.1667 8.66671V3.95171C10.1542 5.16838 9.40248 6.79338 8.99582 8.66671H11.1667ZM11.1667 10.3333V13.6666H8.725C8.60333 12.4825 8.60333 11.5191 8.725 10.3333H11.1667ZM11.1667 20.0483V15.3333H8.99582C9.40248 17.2066 10.1542 18.8316 11.1667 20.0483ZM12.8333 20.0483V15.3333H15.0042C14.5975 17.2066 13.8458 18.8316 12.8333 20.0483ZM12.8333 10.3333V13.6666H15.275C15.3967 12.4808 15.3967 11.5175 15.275 10.3333H12.8333ZM8.90751 4.26826C8.16084 5.54076 7.60334 7.03326 7.28417 8.66659H4.36751C5.24334 6.66993 6.87834 5.08243 8.90751 4.26826ZM7.04584 10.3333H3.83501C3.72501 10.8716 3.66668 11.4291 3.66668 12C3.66668 12.5708 3.72501 13.1283 3.83501 13.6666H7.04584C6.93834 12.5175 6.94501 11.4025 7.04584 10.3333ZM4.36751 15.3333H7.28417C7.60334 16.9666 8.16084 18.4591 8.90751 19.7316C6.87834 18.9175 5.24334 17.33 4.36751 15.3333ZM16.7158 15.3333C16.3967 16.9666 15.8392 18.4591 15.0925 19.7316C17.1217 18.9175 18.7567 17.33 19.6325 15.3333H16.7158Z"
        />
    </SvgIcon>
);

export default Globe;
