import { Alert, Box, Collapse, IconButton, List as MuiList, styled, Tooltip } from '@mui/material';
import { isNotEmpty } from 'utilities';

import { List } from 'api/types/List';
import Button from 'components/tokens/Button';
import Icon, { IconType } from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { ListAction } from 'contexts/utilities/listsUtils';

import { GridId } from './Lists';
import { TargetListItem } from './TargetListItem';

export type ListsTemplateProps = {
    title: string;
    titleIcon: IconType;
    chipLabel?: string;
    gridId: GridId;
    lists: List[];
    maxShownLists?: number;
    selectedListId?: string;
    expandedGridId?: GridId;
    onListClick?: (listId: string) => void;
    onListMenuAction?: (listId: string, action: ListAction, fields?: Partial<List>) => void;
    onShowMoreClick?: () => void;
    onListSectionTitleClick: (gridId: GridId) => void;
};

export const ListsTemplate: React.FC<ListsTemplateProps> = ({
    title,
    titleIcon,
    chipLabel,
    gridId,
    lists,
    maxShownLists = 6,
    selectedListId,
    expandedGridId,
    onListClick,
    onListMenuAction,
    onShowMoreClick,
    onListSectionTitleClick,
}) => {
    return (
        <Box sx={{ minWidth: 0 }}>
            <StyledButton
                variant="flat"
                size="small"
                fullWidth
                disableRipple
                endIcon={<Icon type={expandedGridId === gridId ? 'ChevronUp' : 'ChevronDown'} />}
                onClick={() => onListSectionTitleClick(gridId)}
            >
                <span style={{ display: 'flex' }}>
                    <Icon type={titleIcon} fontSize="small" sx={{ color: 'primary.main' }} />
                    <Typography weight="bold" marginLeft={0.5}>
                        {title}
                    </Typography>
                    {chipLabel && (
                        <Alert sx={{ marginLeft: 0.5, backgroundColor: 'grey.50', color: 'text.primary' }} icon={false}>
                            {chipLabel}
                        </Alert>
                    )}
                </span>
            </StyledButton>
            <Collapse in={expandedGridId === gridId} timeout="auto">
                <MuiList>
                    {isNotEmpty(lists) ? (
                        lists
                            .slice(0, maxShownLists)
                            .map((list) => (
                                <TargetListItem
                                    key={list.id}
                                    list={list}
                                    isListActive={list.id === selectedListId}
                                    onListClick={onListClick}
                                    onListMenuAction={onListMenuAction}
                                />
                            ))
                    ) : (
                        <Typography variant="small" color="subtle" sx={{ fontStyle: 'italic', padding: 1 }}>
                            No lists yet
                        </Typography>
                    )}
                    {lists.length > maxShownLists && (
                        <Tooltip title={`Show all ${lists.length} lists`}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                <IconButton onClick={onShowMoreClick}>
                                    <Icon type="ChevronDown" />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    )}
                </MuiList>
            </Collapse>
        </Box>
    );
};

export default ListsTemplate;

const StyledButton = styled(Button)(({ theme: { palette } }) => ({
    justifyContent: 'space-between',
    marginBottom: '8px',
    padding: '4px 8px 4px 4px',
    '&.variant-flat': {
        '&:hover': {
            backgroundColor: palette.field.background,
            borderColor: palette.field.background,
        },
        '&.Mui-outlinedPrimary': {
            '&:hover': {
                backgroundColor: palette.field.background,
            },
        },
    },
}));
