import { AsyncProcessState, GenericAsyncProcess } from 'api/types/UserAsyncProcess';

export const isAsyncProcessOngoing = <T extends GenericAsyncProcess>(process: T | null | undefined): process is T => {
    if (!process) {
        return false;
    }
    return isAsyncProcessStateOngoing(process.state);
};

export const isAsyncProcessStateOngoing = (process: AsyncProcessState): boolean =>
    process === 'process' || process === 'accepted';
