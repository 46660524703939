import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import TextOnTruncate from 'components/tokens/TextOnTruncate';
import Typography from 'components/tokens/Typography';
import { brandPalette } from 'design/theme/colors';

interface EditableTextProps {
    text: string;
    submit: (str: string) => void;
    endAdornment?: React.ReactNode;
    fontSize: string | number;
    fontWeight: number;
    errorText?: string;
}

export const EditableText = ({
    text,
    submit,
    endAdornment,
    fontSize = '2.8rem',
    fontWeight = 500,
    errorText,
}: EditableTextProps) => {
    const [editMode, setEditMode] = useState(false);
    const [draftText, setDraftText] = useState(text);
    const theme = useTheme();
    const xlUp = useMediaQuery(theme.breakpoints.up('xl'));

    useEffect(() => {
        if (text !== draftText) {
            setDraftText(text);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    function handleTextFieldKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }

    function handleSubmit() {
        const trimmedDraftText = draftText.trim();
        if (trimmedDraftText && trimmedDraftText !== text) {
            submit(trimmedDraftText);
        }
        setDraftText(trimmedDraftText);
        setEditMode(false);
    }

    return (
        <Box>
            {editMode ? (
                <StyledInput
                    disableUnderline
                    data-testid="listNameEdit"
                    autoFocus={true}
                    onBlur={handleSubmit}
                    onKeyDown={handleTextFieldKeyDown}
                    onChange={({ target: { value } }) => setDraftText(value)}
                    value={draftText}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                />
            ) : (
                <Grid container wrap="nowrap" columnSpacing={1} alignItems="center" onClick={() => setEditMode(true)}>
                    <Grid item>
                        <TextOnTruncate
                            content={draftText}
                            maxLength={xlUp ? 35 : 20}
                            sx={{
                                fontSize,
                                fontWeight,
                                cursor: 'text',
                                color: 'primary.main',
                                ...(errorText && {
                                    boxShadow: `inset 0 -3px ${theme.palette.error.main}`,
                                }),
                                '&:hover': {
                                    background: brandPalette.subtleLight,
                                },
                            }}
                        />
                    </Grid>
                    {endAdornment ? (
                        <Grid item display="flex">
                            {endAdornment}
                        </Grid>
                    ) : null}
                </Grid>
            )}
            {errorText && (
                <Typography
                    component="p"
                    variant="tiny"
                    sx={{
                        marginTop: '6px',
                        fontStyle: 'italic',
                        color: 'error.main',
                    }}
                >
                    {errorText}
                </Typography>
            )}
        </Box>
    );
};

const StyledInput = styled(Input, {
    shouldForwardProp: (prop) => prop !== 'fontSize' && prop !== 'fontWeight',
})<{ fontSize: number | string; fontWeight: number }>(({ theme, fontSize, fontWeight }) => ({
    height: 44,
    minWidth: 'unset',
    color: theme.palette.text.strong,
    '> input': {
        fontSize,
        fontWeight,
        padding: 0,
    },
}));

export default EditableText;
