import React from 'react';

import { IconButton, styled, Tooltip } from '@mui/material';

import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Icon from 'components/tokens/Icon';
import Tag from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';

import { CRMLogoAlt } from '../common/CRMLogo';
import { CrmName, CRMObject, DataUpdateType, EnhancedMapping, VainuFieldPreview } from '../types/Crm';
import { MappingInfoTooltip } from './MappingInfoTooltip';
import { MappingsTableControls } from './MappingsTableControls';
import { MappingsTablePlaceholder } from './MappingsTablePlaceholder';
import { getDatapointUpdateConfig, MappingUpdateSelect } from './MappingUpdateSelect';

type MappingsTableProps = {
    crmName: CrmName;
    crmObject: CRMObject;
    searchValue: string;
    mappings: EnhancedMapping[];
    onMappingEdit: (mapping: EnhancedMapping) => void;
    onMappingRemove: (crmPropertyLabel: string, mappingId: string) => void;
    onMappingUpdateTypeChange: (mapping: EnhancedMapping) => void;
};

const MappingsTable: React.FC<MappingsTableProps> = ({
    crmName,
    crmObject,
    searchValue,
    mappings,
    onMappingEdit,
    onMappingRemove,
    onMappingUpdateTypeChange,
}) => {
    if (mappings.length === 0) {
        return <MappingsTablePlaceholder searchValue={searchValue} crmName={crmName} crmObject={crmObject} />;
    }

    return (
        <SortableTable<EnhancedMapping>
            rowKeyField="id"
            data={mappings}
            sx={{ border: 'none' }}
            tableSx={tableStyles}
            headerRowSx={headerRowStyles}
        >
            <SortableTableColumn<EnhancedMapping> sortable={false} field="crmPropertyLabel" label="Fields" width={120}>
                {(mapping) => {
                    // now we support only case when user has removed a property from CRM
                    const hasError = mapping.crmPropertyLabel === 'MISSING CRM PROPERTY';

                    return (
                        <PropertyNameWrapper>
                            <CRMLogoAlt name={crmName} height={15} width={15} />
                            <Typography
                                sx={{ color: hasError ? 'error.main' : 'primary.main' }}
                                fontSize={14}
                                weight="semibold"
                                marginLeft="8px"
                            >
                                {mapping.crmPropertyLabel}
                            </Typography>
                            <MappingInfoTooltip hasError={hasError} crmName={crmName} mapping={mapping} />
                        </PropertyNameWrapper>
                    );
                }}
            </SortableTableColumn>
            <SortableTableColumn<EnhancedMapping>
                sortable={false}
                field="data_preview"
                label="Data preview"
                width={100}
            >
                {({ sources }) => renderMappingPreview(sources[0].preview)}
            </SortableTableColumn>
            <SortableTableColumn<EnhancedMapping>
                sortable={false}
                width={120}
                field="update_type"
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Fill the field
                        <Tooltip
                            arrow
                            placement="top"
                            title="In the future when you send or update companies, when should we fill the field with data?"
                        >
                            <IconButton disableRipple size="small">
                                <Icon fontSize="mini" type="HelpCircleOutline" />
                            </IconButton>
                        </Tooltip>
                    </div>
                }
            >
                {(mapping) => {
                    const primaryMappingSource = mapping.sources[0];
                    const hasFallbacks = mapping.sources.length > 1;

                    const fixedUpdateTypeConfig =
                        getDatapointUpdateConfig(primaryMappingSource.source_field) ||
                        getDatapointUpdateConfig(primaryMappingSource.source_object); // Custom input

                    return (
                        <MappingUpdateSelect
                            disabled={
                                mapping.crmPropertyLabel === 'MISSING CRM PROPERTY' ||
                                (hasFallbacks ? false : fixedUpdateTypeConfig.isUpdateTypeFixed)
                            }
                            disabledTooltip={hasFallbacks ? undefined : fixedUpdateTypeConfig.tooltip}
                            value={mapping.update_type}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                onMappingUpdateTypeChange({
                                    ...mapping,
                                    update_type: event.target.value as DataUpdateType,
                                })
                            }
                        />
                    );
                }}
            </SortableTableColumn>
            <SortableTableColumn<EnhancedMapping> field="controls" align="right" sortable={false} width={70}>
                {(mapping) => (
                    <MappingsTableControls
                        isMandatory={mapping.mandatory}
                        onEdit={
                            mapping.crmPropertyLabel === 'MISSING CRM PROPERTY'
                                ? undefined
                                : () => onMappingEdit(mapping)
                        }
                        onRemove={() => onMappingRemove(mapping.crmPropertyLabel, mapping.id)}
                    />
                )}
            </SortableTableColumn>
        </SortableTable>
    );
};

const renderMappingPreview = (preview: VainuFieldPreview) => {
    if (!preview) return 'NO PREVIEW';

    return typeof preview === 'string' ? (
        <Typography data-testid="mapping-preview-text" variant="body2" color="subtle">
            {preview}
        </Typography>
    ) : (
        preview.map((value) => {
            return <PreviewTag data-testid="mapping-preview-tag" key={value} label={value} size="medium" />;
        })
    );
};

const tableStyles = {
    borderSpacing: '0px 12px',
    borderCollapse: 'separate',
    border: 'none',

    '& > thead > tr > th': {
        height: '10px',
        paddingBottom: '0px',
    },

    '& > tbody': {
        height: '56px',

        td: {
            border: '1px solid #f5f5f5',
            borderStyle: 'solid none',
            backgroundColor: '#fafafa',
        },
        'td:first-of-type': {
            borderLeftStyle: 'solid',
            borderTopLeftRadius: '11px',
            borderBottomLeftRadius: '11px',
        },
        'td:last-child': {
            borderRightStyle: 'solid',
            borderBottomRightRadius: '11px',
            borderTopRightRadius: '11px',
        },

        'tr:hover > td': {
            backgroundColor: '#f5f5f5',
        },
    },
};

const headerRowStyles = {
    height: 20,

    '& > th': {
        background: 'white',
        border: 'none',
    },
};

const PreviewTag = styled(Tag)({
    marginRight: '4px',

    '& .MuiChip-label': {
        textTransform: 'none',
    },
});

const PropertyNameWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

export { MappingsTable };
