import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowShortLeft = (props) => (
    <SvgIcon {...props}>
        <path d="M7.83 11L11.41 7.41L10 6L4 12L10 18L11.41 16.59L7.83 13H20V11H7.83Z" />
    </SvgIcon>
);

export default ArrowShortLeft;
