import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HubspotDealSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.7409 6.19911C2.83499 6.20104 2.90392 6.1721 2.97493 6.11349C4.31816 5.00534 5.66433 3.90085 7.00677 2.7918C7.3349 2.52067 7.69637 2.41171 8.0953 2.58059C8.78856 2.87407 8.89804 3.76494 8.30155 4.2362C7.47013 4.89303 6.68796 5.60397 5.93384 6.34716C5.16256 7.10735 5.02974 8.35329 5.64507 9.24311C6.05358 9.83385 6.62265 10.1688 7.34589 10.2279C8.02549 10.2834 8.61251 10.0737 9.11695 9.61917C9.47601 9.2956 9.83778 8.97511 10.1984 8.65326C10.3571 8.51155 10.358 8.51066 10.5036 8.66122C11.2696 9.45317 14.6587 12.9609 15.5876 13.9226C16.0891 14.4418 15.9818 15.2197 15.3664 15.5295C14.9791 15.7244 14.5215 15.6398 14.1957 15.3039C13.4651 14.5505 12.737 13.7946 12.0095 13.0381C11.8428 12.8647 11.6505 12.7772 11.4041 12.799C10.946 12.8396 10.6215 13.4329 10.979 13.8734C11.1485 14.0825 12.0405 14.979 12.2804 15.249C12.573 15.5784 12.6076 16.0621 12.385 16.4413C12.0962 16.9335 11.4099 17.0423 10.9577 16.6561C10.7729 16.4984 8.81938 14.472 8.01293 13.6344C7.82887 13.4433 7.61441 13.379 7.36149 13.4341C6.90724 13.533 6.71413 14.0747 6.98244 14.456C7.1082 14.6347 7.22694 14.8278 7.30036 15.0317C7.43313 15.4009 7.2766 15.8663 6.98516 16.0746C6.63291 16.3264 6.16207 16.3389 5.82839 16.0775C5.6345 15.9256 5.46551 15.7405 5.29376 15.5622C4.93732 15.1922 4.58852 14.8147 4.23066 14.4461C4.18963 14.4038 4.11113 14.3783 4.04985 14.378C3.01319 14.3739 1.97647 14.375 0.939752 14.3752C0.573002 14.3752 0.274651 14.2403 0.10258 13.9071C0.0399371 13.7858 0.0221439 13.6369 0.00466462 13.498C-0.00920364 13.3872 0.0123052 9.22312 0.0119389 7.25487C0.0118866 6.99132 0.0643766 6.74688 0.244821 6.54163C0.426103 6.33549 0.650978 6.21198 0.92468 6.20266C1.23355 6.1922 2.44485 6.19309 2.7409 6.19911Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 13.507C20.0018 13.7281 19.9118 13.8948 19.7977 14.0677C19.619 14.3386 19.3486 14.3809 19.0602 14.3923C18.2691 14.4235 17.6121 14.1395 17.0647 13.5699C14.9858 11.4064 10.6657 6.92137 10.5877 6.83937C10.4677 6.71319 10.4644 6.70995 10.3286 6.82806C9.67821 7.39352 8.10628 8.77627 7.94735 8.85791C7.53658 9.06881 7.00771 8.91982 6.72663 8.53773C6.46287 8.17915 6.48181 7.64729 6.8169 7.32826C7.38733 6.78531 10.6878 3.97419 11.7475 3.09033C12.0569 2.83233 12.5802 2.77707 12.9034 3.00534C13.4073 3.36116 16.1629 5.45731 17.0521 6.12848C17.1056 6.16883 17.1848 6.19337 17.2523 6.194C17.8142 6.19913 18.3771 6.21791 18.9377 6.1906C19.4307 6.16658 20.0071 6.57823 19.9992 7.24982C19.9867 8.30778 19.9909 12.4794 20 13.507Z"
        />
    </SvgIcon>
);
export default HubspotDealSvg;
