const linesNarrowNormal = 4;
const linesNarrowExpanded = 8;
const linesWideNormal = 2;
const linesWideExpanded = 6;

export const getLinesToShow = (expanded: boolean, wide: boolean) => {
    if (wide) {
        if (expanded) {
            return linesWideExpanded;
        }
        return linesWideNormal;
    }
    if (expanded) {
        return linesNarrowExpanded;
    }
    return linesNarrowNormal;
};
