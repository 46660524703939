import { useTheme } from '@mui/material';

export type ClusterColors = {
    largestCluster1: string;
    largestCluster2: string;
    restClusters1: string;
    restClusters2: string;
};

export const useClusterColors = () => {
    const theme = useTheme();
    return theme.palette.clusters;
};

export const getClusterColor = (index: number, colors: ClusterColors) => {
    if (index === 0) {
        return colors.largestCluster1;
    }
    if (index === 1) {
        return colors.largestCluster2;
    }
    return index % 2 ? colors.restClusters1 : colors.restClusters2;
};
