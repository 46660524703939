import { OutlinedTextFieldProps } from '@mui/material/TextField';

import { IconType } from '../Icon';

export type Status = 'default' | 'disabled' | 'error' | 'success';
export type TextFieldVariant = 'default' | 'rounded';

export type TextFieldOwnProps = {
    status?: Status;
    variant?: TextFieldVariant;
    compact?: boolean;
    icon?: IconType;
    iconColor?: string;
    errorText?: string;
    iconBehavior?: 'showAlways' | 'hideOnFocus' | 'hideOnTyping';
};

// Class instead of type to have a list of props to filter
class StylingPropsClass {
    status: Status = 'default';
    isEmpty: boolean = false;
    showIcon: boolean = false;
    styleVariant: TextFieldVariant = 'default';
}

type StylingProps = StylingPropsClass;

export const STYLED_PROPS_TO_NOT_FORWARD = Object.keys(new StylingPropsClass());

type MuiTextFieldProps = Omit<OutlinedTextFieldProps, 'variant'>;
export type TextFieldProps = MuiTextFieldProps & TextFieldOwnProps;
export type StyledTextFieldProps = MuiTextFieldProps & StylingProps;
