import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { filterCompanies, FilterPayloadCompanies } from 'api/filterCompanies';
import { getContactFieldsByPermission } from 'api/profileData';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useFilterState } from 'contexts/FilterContext';
import { Database, isUploaded, useListsContext } from 'contexts/ListsContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import { usePrevious } from 'hooks/usePrevious';

import {
    domainDataFields,
    DomainDataResult,
    organizationFields,
    OrganizationResult,
    ResultAPIResponse,
} from '../types/FilterCompanies';
import {
    getContactGroups,
    getQueryKeyCacheBatch,
    getQueryKeyResultObjects,
    ResultObjectType,
    resultsQueryKeyPrefix,
    useCompanyCount,
    useCompanyList,
} from './utils';

export type ProfileCount = {
    final: boolean;
    loading: boolean;
    status: 'process' | 'scheduled' | 'ready' | 'error' | undefined;
    eta: string | undefined;
    count: number;
};

interface FilterCompaniesValue {
    loadingCompanies: boolean;
    loadingContacts: boolean;
    numberOfPages: number | undefined;
    tab: ResultObjectType;
    page: number;
    companyCount: ProfileCount;
    contactCount: ProfileCount;
    companies: DomainDataResult[] | OrganizationResult[];
    contacts: DomainDataResult[] | OrganizationResult[];
    changePage: (page: number) => void;
    changeOrderBy: (orderBy: FilterPayloadCompanies['order']) => void;
    refetchResults: (listId: string) => void;
    setTab: (tab: ResultObjectType) => void;
}

const FilterCompaniesContext = React.createContext<FilterCompaniesValue>({} as FilterCompaniesValue);

const getFields = (database: Database) => (database === 'DOMAIN_DATA_BASIC' ? domainDataFields : organizationFields);

type FilterCompaniesProviderProps = {
    children?: React.ReactNode;
};

const FilterCompaniesProvider: React.FC<FilterCompaniesProviderProps> = ({ children }) => {
    const axios = useAxiosContext();
    const [offset, setOffset] = useState(0);
    const [orderBy, changeOrderBy] = useState<FilterPayloadCompanies['order']>('-vainu_score');
    const [companyCacheSize, setCompanyCacheSize] = useState(0);
    const [contactCacheSize, setContactCacheSize] = useState(0);
    const [tab, setTab] = useState<ResultObjectType>('companies');
    const queryClient = useQueryClient();
    const { query } = useFilterState();
    const { selectedList, database } = useListsContext();
    const listID = selectedList?.id;
    const isStaticList = selectedList && isUploaded(selectedList);
    const isLegacyList = selectedList?.type === 'legacy';
    const { hasProductPermission } = usePermissionContext();
    const hasContactDataPermission = hasProductPermission(Permission.ContactData);

    const refetchResults = useCallback(
        (refetchListID: string) => {
            // clear cached queries for static and legacy lists (the normal dynamic list query keys don't set the list ID, only the query)
            queryClient.invalidateQueries({
                predicate: (q) => {
                    const [prefix, _action, _objectType, queryKeyDatabase, queryKeyListID] = q.queryKey;
                    return (
                        typeof prefix === 'string' &&
                        typeof queryKeyDatabase === 'string' &&
                        typeof queryKeyListID === 'string' &&
                        prefix === resultsQueryKeyPrefix &&
                        queryKeyDatabase === database &&
                        queryKeyListID === refetchListID
                    );
                },
            });
        },
        [queryClient, database],
    );

    const limit = 20;

    const previousListID = usePrevious<string | undefined>(listID);

    useEffect(() => {
        if (previousListID !== undefined && previousListID !== listID) {
            setOffset(0);
        }
    }, [previousListID, listID]);

    useEffect(() => {
        setOffset(0);
    }, [query, tab]);

    useEffect(() => {
        if (database === 'DOMAIN_DATA_BASIC' || isLegacyList) {
            setTab('companies');
        }
    }, [database, isLegacyList]);

    const getFetchCompaniesPayload = useCallback(
        (db: Database): FilterPayloadCompanies => {
            if (isLegacyList) {
                return {
                    offset,
                    limit,
                    list: selectedList.id,
                    use_filters_v2: 'false',
                    database: db,
                    order: orderBy,
                    fields: getFields(db),
                };
            } else {
                const parsedQuery = isStaticList
                    ? { '?ALL': [{ '?EQ': { 'target_group._id': listID } }] }
                    : JSON.parse(query || '{"?ALL": []}');
                return {
                    offset,
                    limit,
                    query: parsedQuery,
                    use_filters_v2: 'true',
                    database: db,
                    order: orderBy,
                    fields: getFields(db),
                };
            }
        },
        [isLegacyList, isStaticList, listID, offset, orderBy, query, selectedList?.id],
    );

    const getFetchContactsPayload = useCallback(
        (db: Database, hasContactDataPermission: boolean): FilterPayloadCompanies => {
            if (isLegacyList) {
                return {
                    offset,
                    limit,
                    list: selectedList.id,
                    use_filters_v2: 'false',
                    database: db,
                    order: orderBy,
                    fields: getFields(db),
                };
            } else {
                const parsedQuery = isStaticList
                    ? { '?ALL': [{ '?EQ': { 'target_group._id': listID } }] }
                    : query && JSON.parse(query);

                const contactGroups = getContactGroups(parsedQuery);

                return {
                    offset,
                    limit,
                    query: parsedQuery,
                    use_filters_v2: 'true',
                    database: db,
                    order: orderBy,
                    fields: [...getFields(db), ...getContactFieldsByPermission(hasContactDataPermission)],
                    unwind_subdocument: 'contacts',
                    unwind_subdocument_query: contactGroups,
                };
            }
        },
        [isLegacyList, isStaticList, listID, offset, query, selectedList?.id, orderBy],
    );

    const companiesQueryKey = getQueryKeyResultObjects(
        'companies',
        database,
        ((isStaticList || isLegacyList) && listID) || null,
        query,
        offset,
        limit,
        orderBy,
    );

    const contactsQueryKey = getQueryKeyResultObjects(
        'contacts',
        database,
        ((isStaticList || isLegacyList) && listID) || null,
        query,
        offset,
        limit,
        orderBy,
    );

    const { data: companies = [], isFetching: loadingCompanies } = useCompanyList(
        companiesQueryKey,
        async ({ signal }) => {
            const payload = getFetchCompaniesPayload(database as Database);
            return filterCompanies(axios, payload, signal);
        },
        tab === 'companies' && !!database && !!listID && (!!query || !!isStaticList),
    );

    const { data: contacts = [], isFetching: loadingContacts } = useCompanyList(
        contactsQueryKey,
        async ({ signal }) => {
            const payload = getFetchContactsPayload(database as Database, hasContactDataPermission);
            return filterCompanies(axios, payload, signal);
        },
        tab === 'companies' && !!database && !!listID && (!!query || !!isStaticList),
    );

    const companyCount = useCompanyCount('companies', (companies?.length || 0) + companyCacheSize);
    const contactCount = useCompanyCount('contacts', (contacts?.length || 0) + contactCacheSize);

    useEffect(() => {
        if (!listID) {
            return;
        }

        const setQueryData = (queryKey: QueryKey, data: ResultAPIResponse[]) => {
            queryClient.setQueryData(queryKey, data);
        };

        const prefetchPages = async () => {
            setCompanyCacheSize(0);
            setContactCacheSize(0);

            if (database === undefined) {
                return;
            }

            const cacheNextPages = async ({
                type,
                pages,
                payload,
                query,
                offset,
                limit,
                orderBy,
            }: {
                type: ResultObjectType;
                pages: number;
                payload: FilterPayloadCompanies;
                query: string;
                offset: number;
                limit: number;
                orderBy: string;
            }) => {
                /*
                    Cache n next pages.
                    Try if results are cached for next n pages. If all are cached
                    do nothing. Use the first page that is not cached as offset.
                    and fetch n pages worth of next results. Cache results to
                    matching offset keys.

                    This way it might fetch results for pages that already exists due to overlapping.
                    e.g. if you start from middle pagination and jump forward and backward.

                    Fetching a batch was preferred to reduce the amount of request and DB/server load.

                */
                const pagesToBeCached = Array.from(Array(pages).keys()).map((num) => offset + (num + 1) * limit);
                const nextStartOffset = pagesToBeCached.filter((nextOffset) => {
                    const nextPageKey = getQueryKeyResultObjects(
                        type,
                        database,
                        isStaticList ? listID : null,
                        query,
                        nextOffset,
                        limit,
                        orderBy,
                    );
                    const state = queryClient.getQueryState(nextPageKey);
                    return state?.status !== 'success';
                })[0];
                if (nextStartOffset) {
                    const cacheNextPagesKey = getQueryKeyCacheBatch(
                        type,
                        database,
                        isStaticList ? listID : null,
                        query,
                        nextStartOffset,
                        limit * pages,
                        orderBy,
                    );
                    const results = await queryClient.fetchQuery({
                        queryKey: cacheNextPagesKey,
                        queryFn: ({ signal }) =>
                            filterCompanies(
                                axios,
                                {
                                    ...payload,
                                    offset: nextStartOffset,
                                    limit: limit * pages,
                                },
                                signal,
                            ),
                        staleTime: 24 * 60 * 60 * 1000,
                        retry: (failureCount, error) => (error as AxiosError)?.response?.status === 429,
                        retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
                    });

                    if (results.length) {
                        let count = 0;
                        Array.from(Array(pages).keys())
                            .map((num) => nextStartOffset + num * limit)
                            .forEach((nextOffset, index) => {
                                const nextPageKey = getQueryKeyResultObjects(
                                    type,
                                    database,
                                    isStaticList ? listID : null,
                                    query,
                                    nextOffset,
                                    limit,
                                    orderBy,
                                );
                                const nextPageData = results.slice(index * limit, (index + 1) * limit);
                                count += nextPageData.length;
                                setQueryData(nextPageKey, nextPageData);
                            });
                        if (tab === 'companies') {
                            setCompanyCacheSize(count);
                        } else {
                            setContactCacheSize(count);
                        }
                    }
                }
            };

            const payload =
                tab === 'companies'
                    ? getFetchCompaniesPayload(database)
                    : getFetchContactsPayload(database, hasContactDataPermission);

            cacheNextPages({ type: tab, pages: 5, payload, query: query || '', offset, limit, orderBy });
        };

        prefetchPages();
    }, [
        hasContactDataPermission,
        axios,
        database,
        getFetchCompaniesPayload,
        getFetchContactsPayload,
        isStaticList,
        listID,
        offset,
        orderBy,
        query,
        queryClient,
        tab,
    ]);

    const changePage = useCallback((page: number) => {
        setOffset((page - 1) * limit);
    }, []);

    const count = tab === 'companies' ? companyCount?.count : contactCount?.count;
    const numberOfPages = count != null ? Math.ceil(count / limit) : undefined;
    const page = offset / limit + 1;

    const value = useMemo(
        () => ({
            companies,
            companyCount,
            loadingCompanies,
            contacts,
            contactCount,
            loadingContacts,
            changePage,
            changeOrderBy,
            numberOfPages,
            page,
            refetchResults,
            tab,
            setTab,
        }),
        [
            companies,
            companyCount,
            loadingCompanies,
            contacts,
            contactCount,
            loadingContacts,
            changePage,
            numberOfPages,
            page,
            refetchResults,
            tab,
        ],
    );

    return <FilterCompaniesContext.Provider value={value}>{children}</FilterCompaniesContext.Provider>;
};

const useFilterCompaniesContext = () => useContext(FilterCompaniesContext);

export { FilterCompaniesContext, FilterCompaniesProvider, useFilterCompaniesContext };
