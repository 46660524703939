import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LineChartUp: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M21 21H4C3.44772 21 3 20.5523 3 20V3H5V19H21V21ZM8.373 16L7 14.656L11.856 9.9C12.2383 9.52848 12.8467 9.52848 13.229 9.9L15.456 12.081L19.627 8L21 9.344L16.144 14.1C15.7617 14.4715 15.1533 14.4715 14.771 14.1L12.543 11.918L8.374 16H8.373Z" />
    </SvgIcon>
);

export default LineChartUp;
