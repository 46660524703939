import { Children, cloneElement, isValidElement } from 'react';

import { Box, SxProps } from '@mui/material';

import { ButtonProps } from 'components/tokens/Button';

export type ButtonTabsProps = {
    selected?: string;
    sx?: SxProps;
    size?: ButtonProps['size'];
    onChange?: (value: string, event: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactElement<ButtonProps>[];
};

export const ButtonTabs = ({ selected, onChange, children, size, sx }: ButtonTabsProps) => {
    return (
        <Box sx={sx}>
            {Children.map(children, (child) => {
                if (isValidElement(child)) {
                    const { onClick, value, size: buttonSize, sx: buttonSx } = child.props;
                    return cloneElement(child, {
                        variant: 'flat',
                        size: buttonSize ?? size,
                        sx: {
                            borderRadius: 1,
                            marginRight: 0.5,
                            ':last-child': {
                                marginRight: 0,
                            },
                            color: 'text.strong',
                            '& path': { fill: 'currentColor' },
                            ...(value === selected && { backgroundColor: 'button.flatSelected' }),
                            ...buttonSx,
                        },
                        onClick: onChange
                            ? (event: React.MouseEvent<HTMLButtonElement>) => {
                                  typeof value === 'string' && onChange(value, event);
                                  onClick && onClick(event);
                              }
                            : onClick,
                    });
                }
                return child;
            })}
        </Box>
    );
};

export default ButtonTabs;
