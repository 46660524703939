import { useMemo } from 'react';

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosResponse, AxiosInstance } from 'axios';
import { assertArray } from 'utilities';

import { SelectOption } from 'components/tokens/select-components/Select';
import { useAxiosContext } from 'contexts/AxiosContext';

import { AddressesData } from './types/Location';
import { FilterTag, FilterTagObject, LegalEntityTag, WebTag } from './types/Tag';

export async function getTags(axios: AxiosInstance): Promise<FilterTag[]> {
    return (await axios.get('https://filter.vainu.io/filtervalues/en/TAGS_BY_TYPE.json')).data;
}

export const getTagObjectFromTuple = (tag: FilterTag) => ({
    id: tag[0],
    label: tag[1],
    category: tag[2],
    countries: tag[3],
});

export const filterTagsByCountry = (tags: FilterTagObject[] | undefined, country?: string) =>
    assertArray(tags).filter((tag) => tag.countries.length === 0 || (country && tag.countries.includes(country)));

export const useTagsFilteredByCountry = (tags: FilterTagObject[] | undefined, country?: string) =>
    useMemo(() => filterTagsByCountry(tags, country), [tags, country]);

// A list of tags considered obsolete but still exist in the tag master file.
// Based on the discussion here: https://vainu.slack.com/archives/C04KFUUJSUB/p1674209367340459
const obsoleteTags = [
    6967635, // EU funding, FI
    8000010, // Bankruptcy, NL, SE, NO, DK, FI
    8000016, // Public Decision on Financing, NO
];

export function useTags<TData = FilterTag[]>(
    options?: Omit<UseQueryOptions<FilterTag[], unknown, TData>, 'queryKey' | 'queryFn'>,
) {
    const axios = useAxiosContext();
    return useQuery<FilterTag[], unknown, TData>({
        ...options,
        queryKey: ['tags'],
        queryFn: () => getTags(axios),
    });
}

export const useTagObjects = () => {
    return useTags({
        select: (data: FilterTag[]) =>
            data
                .map(getTagObjectFromTuple)
                .filter(({ id }) => !obsoleteTags.includes(id))
                .sort((a, b) => a.label.localeCompare(b.label)),
    });
};

export function getLegalEntities(axios: AxiosInstance) {
    return axios
        .get<LegalEntityTag[]>('https://filter.vainu.io/filtervalues/en/FORM_OF_COMPANY.json')
        .then((r) => r.data);
}

export function getWebTags(
    axios: AxiosInstance,
    database = 'ALL',
): Promise<AxiosResponse<{ [database: string]: WebTag[] }>> {
    return axios.get(`https://filter.vainu.io/filtervalues/${database}_en_weighted_webtags.json`);
}

export function getAddresses(axios: AxiosInstance, database: string | undefined) {
    return axios
        .get<AddressesData>(`https://filter.vainu.io/filtervalues/address_choices_${database}.json`)
        .then((r) => r.data);
}

export function getAuditValues(axios: AxiosInstance, database: string | undefined) {
    return axios
        .get<SelectOption[]>(`https://filter.vainu.io/filtervalues/en/${database}_ACCOUNTING_FIRM_VALUES.json`)
        .then((r) => r.data);
}

const EMPTY_SELECT_OPTIONS: SelectOption[] = [];
export function getBookkeeperValues(axios: AxiosInstance, database: string | undefined, search: string) {
    if (!database) {
        return EMPTY_SELECT_OPTIONS;
    }
    return axios
        .get<
            SelectOption[]
        >(`/mapi/get_filter_options/?search=${search}&queryfield=_facts_numeric__bookkeeping_firm__exact&country=${database}`)
        .then((r) => r.data);
}

export function getRecruitmentKeywords(axios: AxiosInstance) {
    return axios
        .get<{ [key: string]: string[] }>(`https://filter.vainu.io/filtervalues/recruitment_keywords.json`)
        .then((r) => r.data);
}
