import { CircularProgress } from '@mui/material';

import { useWebProfileData } from 'api/profileData';
import { organizationProfileType } from 'api/types/Organization';
import { WebProfileHeader, WebProfileTechnologiesList } from 'components/features/WebProfile';
import Descriptions, { getDescriptionsFromCompany } from 'components/templates/Descriptions';

import { TabContent, CompanyTabProps } from '..';

export const WebProfileTab: React.VFC<CompanyTabProps> = ({ data, businessId }) => {
    const {
        data: webProfileData,
        isSuccess,
        isLoading,
    } = useWebProfileData({ type: organizationProfileType, key: businessId });

    if (isLoading) {
        return <CircularProgress />;
    }

    if (isSuccess && webProfileData) {
        const { technology_data, website_data } = webProfileData;
        return (
            <TabContent>
                <WebProfileHeader
                    website={webProfileData.website}
                    technology_data={technology_data}
                    website_data={website_data}
                    countries={[]}
                    digitalization_index={data.digitality}
                />
                {technology_data && webProfileData.website ? (
                    <WebProfileTechnologiesList technology_data={technology_data} website={webProfileData.website} />
                ) : null}
                <Descriptions descriptions={getDescriptionsFromCompany(data)} />
            </TabContent>
        );
    }

    return null;
};

export default WebProfileTab;
