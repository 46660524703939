import { useVehicles } from 'api/vehicles';
import Vehicles from 'components/features/Vehicles';
import { getVehiclesAggregates } from 'components/features/Vehicles/utils';

import { TabContent, CompanyTabProps } from '..';

export const VehiclesTab: React.VFC<CompanyTabProps> = ({ data, businessId }) => {
    const { country } = data;
    const { isSuccess, data: vehicles } = useVehicles(businessId, country);

    return isSuccess && vehicles && vehicles.length > 0 ? (
        <TabContent>
            <Vehicles {...getVehiclesAggregates(vehicles)} />
        </TabContent>
    ) : null;
};

export default VehiclesTab;
