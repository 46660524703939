import React from 'react';

import { styled } from '@mui/material';

type LandingTemplateProps = {
    /** isAPILanding prop configures landing template appearance e.g. remove bottom-right icon and change top-right icon color */
    isAPILanding?: boolean;
    children: React.ReactNode;
};

const LandingTemplate: React.FC<LandingTemplateProps> = ({ isAPILanding = false, children }) => {
    return (
        <Container>
            <TopRightIcon isAPILanding={isAPILanding} />
            {!isAPILanding && <BottomRightIcon />}
            {children}
        </Container>
    );
};

const Container = styled('div')(({ theme: { palette } }) => ({
    position: 'relative',
    width: '90%',
    margin: '0 auto',
    height: '100%',
    paddingBottom: '60px',
    backgroundColor: palette.brandColors.white,
    borderRadius: '8px',
}));

const TopRightIcon = styled('div')<{ isAPILanding: boolean }>(({ isAPILanding, theme: { palette } }) => ({
    position: 'absolute',
    width: '320px',
    height: '270px',
    background: isAPILanding ? '#E7D8E8' : palette.brandColors.downToEarthBrown,
    top: '0px',
    right: '0px',
    clipPath: "path('M 50 0 C -110 110 170 220 320 270 L 320 0')",
    borderTopRightRadius: '8px',
}));

const BottomRightIcon = styled('div')(({ theme: { palette } }) => ({
    position: 'absolute',
    width: '250px',
    height: '400px',
    background: palette.brandColors.downToEarthBrown,
    bottom: '0px',
    right: '0px',
    clipPath: "path('M 250 0 C 190 162 5 270 0 400 L 250 400')",
    borderBottomRightRadius: '8px',
}));

export { LandingTemplate };
