import { useCallback, useEffect, useRef } from 'react';

import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

import { domainProfileType, organizationProfileType, ProfileType } from 'api/types/Organization';
import { MainPath } from 'components/beta/Platform/Router/routePaths';
import { setTitle } from 'components/beta/Platform/Router/utils';
import CompanyProfilePopup from 'components/layout/CompanyProfilePopup';
import { NeighborProfile } from 'components/templates/ProfileArrowNavigation';
import { getNeighborProfile } from 'components/templates/ProfileArrowNavigation/utils';
import { useFilterCompaniesContext } from 'contexts/FilterCompaniesContext/FilterCompaniesContext';
import { useListsContext } from 'contexts/ListsContext';

import ProfileSelector from './Profile';
import Profiles from './Profiles';

export type ProfilesRouterProps = {
    scrollRef?: React.RefObject<HTMLElement>;
};

export const domainProfilePathSlug = 'domain';
export const organizationProfilePathSlug = 'company';

export type RouterProfileType = typeof domainProfilePathSlug | typeof organizationProfilePathSlug;

export const isValidProfileType = (profileType: string | undefined): profileType is RouterProfileType =>
    profileType === domainProfilePathSlug || profileType === organizationProfilePathSlug;

export const unknownListSlug = '-';

const isNoListSelected = (listIdRouteParam: string | undefined, selectedListId: string | undefined) =>
    (listIdRouteParam === unknownListSlug || listIdRouteParam === undefined) && selectedListId !== undefined;

const isSelectedListChanged = (listIdRouteParam: string | undefined, selectedListId: string | undefined) =>
    listIdRouteParam !== selectedListId && listIdRouteParam !== unknownListSlug;

const ListSelector: React.VFC<ProfilesRouterProps> = ({ scrollRef }) => {
    const { listId, profileType, profileId } = useParams<'listId' | 'profileType' | 'profileId'>();
    const { companies, page, numberOfPages, changePage } = useFilterCompaniesContext();
    const {
        selectedListId,
        selectedList,
        lastSelectedListId,
        selectList,
        dbLists,
        database,
        lastOpenedListByDatabase,
    } = useListsContext();

    const popupScrollRef = useRef<HTMLDivElement>(null);
    const navigateToList = useNavigateToList();

    useEffect(() => {
        if (popupScrollRef?.current) {
            popupScrollRef.current?.scrollTo({ top: 0 });
        }
    }, [profileType, profileId]);

    useEffect(() => {
        if (isNoListSelected(listId, selectedListId)) {
            selectList(undefined);
        } else if (dbLists.length > 0 && !selectedListId && !listId) {
            if (lastSelectedListId) {
                navigateToList(lastSelectedListId);
            } else {
                const lastOpenedDBList = database && lastOpenedListByDatabase[database];
                navigateToList(lastOpenedDBList || dbLists[0].id);
            }
        } else if (isSelectedListChanged(listId, selectedListId)) {
            selectList(listId);
        }
    }, [
        listId,
        selectedListId,
        lastSelectedListId,
        dbLists,
        selectList,
        navigateToList,
        database,
        lastOpenedListByDatabase,
    ]);

    useEffect(() => {
        const previousTitle = document.title;
        if (selectedList && !profileId) {
            setTitle(selectedList.name);
            return () => setTitle(previousTitle);
        }
    }, [selectedList, profileId]);

    let previousButton: NeighborProfile | undefined;
    let nextButton: NeighborProfile | undefined;
    let previousProfileId: string;
    let nextProfileId: string;

    const currentProfileIndex = companies.findIndex((profile) => profile.id === profileId);
    if (currentProfileIndex !== -1) {
        const previousProfile = companies[currentProfileIndex - 1];
        if (currentProfileIndex === 0 && page > 1) {
            // First profile of a page
            previousButton = {
                name: 'Previous page',
                icon: 'Profiles',
                onClick: () => {
                    if (scrollRef?.current) {
                        scrollRef.current.scrollTo({ top: 0 });
                    }
                    navigateToList(selectedListId);
                    changePage(page - 1);
                },
            };
        } else if (previousProfile) {
            previousButton = {
                ...getNeighborProfile(previousProfile),
                onClick: () => {
                    if (isValidProfileType(profileType) && previousProfileId) {
                        navigateToList(selectedListId, getProfileDataType(profileType), previousProfileId);
                    }
                },
            };
            previousProfileId = companies[currentProfileIndex - 1].id;
        }

        const nextProfile = companies[currentProfileIndex + 1];
        if (currentProfileIndex === 19 && numberOfPages && page < numberOfPages) {
            // Last profile of a page
            nextButton = {
                name: 'Next page',
                icon: 'Profiles',
                onClick: () => {
                    if (scrollRef?.current) {
                        scrollRef.current.scrollTo({ top: 0 });
                    }
                    navigateToList(selectedListId);
                    changePage(page + 1);
                },
            };
        } else if (nextProfile) {
            nextButton = {
                ...getNeighborProfile(nextProfile),
                onClick: () => {
                    if (isValidProfileType(profileType) && nextProfileId) {
                        navigateToList(selectedListId, getProfileDataType(profileType), nextProfileId);
                    }
                },
            };
            nextProfileId = companies[currentProfileIndex + 1].id;
        }
    }

    return (
        <>
            <Profiles />
            {profileId !== undefined && isValidProfileType(profileType) && (
                <CompanyProfilePopup
                    scrollRef={popupScrollRef}
                    open={profileId !== undefined}
                    onClose={() => navigateToList(selectedListId)}
                >
                    <ProfileSelector
                        scrollRef={popupScrollRef}
                        type={getProfileDataType(profileType)}
                        id={profileId}
                        previous={previousButton}
                        next={nextButton}
                    />
                </CompanyProfilePopup>
            )}
        </>
    );
};

export const ProfilesRouter: React.VFC<ProfilesRouterProps> = ({ scrollRef }) => (
    <Routes>
        <Route path="/" element={<ListSelector scrollRef={scrollRef} />} />
        <Route path=":listId" element={<ListSelector scrollRef={scrollRef} />} />
        <Route path=":listId/:profileType/:profileId" element={<ListSelector scrollRef={scrollRef} />} />
    </Routes>
);

export default ProfilesRouter;

export const getListPath = (listId?: string, profileType?: ProfileType, profileId?: string) => {
    if (!listId && (!profileType || !profileId)) {
        return `/${MainPath.Profiles}`;
    }
    const listSlug = listId ?? unknownListSlug;
    if (profileType && profileId) {
        return `/${MainPath.Profiles}/${listSlug}/${getRouterProfileType(profileType)}/${profileId}`;
    }
    return `/${MainPath.Profiles}/${listSlug}`;
};

/** Converts the profile data object type to the router slug for profile type. */
export function getRouterProfileType(profileType: ProfileType): RouterProfileType {
    if (profileType === domainProfileType) {
        return domainProfilePathSlug;
    } else if (profileType === organizationProfileType) {
        return organizationProfilePathSlug;
    }
    throw new Error(`Unknown profile type: ${profileType}`);
}

/** Converts the router slug for profile type to the profile data object type. */
export function getProfileDataType(profileType: RouterProfileType): ProfileType {
    if (profileType === domainProfilePathSlug) {
        return domainProfileType;
    } else if (profileType === organizationProfilePathSlug) {
        return organizationProfileType;
    }
    throw new Error(`Unknown profile type: ${profileType}`);
}

/**
 * A hook that returns a function that navigates to a list by list ID. Use to select a list in the UI.
 */
export const useNavigateToList = () => {
    const navigate = useNavigate();
    return useCallback(
        (listId?: string, profileType?: ProfileType, profileId?: string, tabName?: string) => {
            navigate(getListPath(listId, profileType, profileId), tabName ? { state: { tabName } } : undefined);
        },
        [navigate],
    );
};

/**
 * A hook that returns a function that can be used to navigate to the lists front page.
 * Retains the possible profile type and ID of the route. Should only be used by the
 * ListsContext; in the UI the useNavigateToList should be used instead.
 */
export const useNavigateToUnknownList = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return useCallback(
        (listId: string) => {
            if (pathname.includes(MainPath.Triggers)) {
                navigate(`/${MainPath.Triggers}`);
            } else if (pathname.endsWith(listId)) {
                navigate(`/${MainPath.Profiles}`);
            } else {
                navigate(pathname.replace(listId, unknownListSlug));
            }
        },
        [navigate, pathname],
    );
};
