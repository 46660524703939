export const combineKey = (value: string, key: string) => {
    if (!value) {
        return key;
    }
    return `${value}.${key}`;
};

export const mergeKeys = (...keys: Array<string | number | undefined>) => {
    return keys.filter((key) => !!key?.toString()).join('.');
};
