import React from 'react';

import { styled } from '@mui/material/styles';

import Skeleton from './index';

const MatchingSkeleton = () => {
    return (
        <MatchingSkeletonWrapper>
            <CardSkeleton showLogo />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
        </MatchingSkeletonWrapper>
    );
};

const CardSkeleton: React.FC<{ showLogo?: boolean }> = ({ showLogo = false }) => {
    return (
        <CardWrapper>
            <Skeleton width={111} height={40} radius={4} />
            <Skeleton width={111} height={12} margin="8px 0px" />
            <Skeleton width={300} height={12} margin="8px 0px" />
            <Skeleton width={241} height={12} />
            {showLogo && <LogoPlaceholder />}
        </CardWrapper>
    );
};

export default MatchingSkeleton;

const CardWrapper = styled('div')(({ theme: { palette } }) => ({
    position: 'relative',
    width: '368px',
    height: '247px',
    marginTop: '32px',
    padding: '24px',
    boxSizing: 'border-box',
    background: palette.common.white,
    border: `1px solid ${palette.border}`,
    borderRadius: '16px',
}));

const MatchingSkeletonWrapper = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const LogoPlaceholder = styled('div')(({ theme: { palette } }) => ({
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    width: '50px',
    height: '50px',
    border: `4px solid ${palette.grey[50]}`,
    borderRadius: '50%',
}));
