import React from 'react';

import { cloneDeep } from 'lodash';

import { FilterOperator } from 'api/types/FilterOperators';
import RangeSelect, { Values } from 'components/templates/RangeSelect';

import { getFilterConfig, TValuesType } from '../../FiltersBaseConfig';
import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    LocationFilterProps,
} from '../../FilterTypes';
import { useOperatorFromFilter } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, rangeValueToText } from '../utils';
import { useLocationFilterValue } from './useLocationFilterValue';

const { baseConfig, getInitValueConfigDefault } = getFilterConfig(FilterID.country_count);
export type TValues = TValuesType<typeof baseConfig, Values>;

const MAX = 100;
const defaultRange = [1, MAX];

const config: FilterConfig<TValues> = {
    ...baseConfig,
    ...getInitValueConfigDefault(FilterOperator.RANGE, defaultRange),
};

/**
 * **This filter should only be selected once.**
 */
export const countryCountFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.location_visiting_address,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: LocationFilterProps<TValues>) => <CountryCount {...props} />,
        valueToText: rangeValueToText(config.fields[0]),
        config,
        createFilter: () => cloneDeep(config.initValue),
    },
};

const operators: Operator[] = [FilterOperator.RANGE, `${FilterOperator.NOT}${FilterOperator.RANGE}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

export const CountryCount: React.VFC<LocationFilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    removeFilter,
    apply,
}) => {
    const { operator, handleOperatorUpdate } = useOperatorFromFilter(filter, ({ key, value, valuePath }) => {
        apply({ key, value, valuePath });
    });
    const { value, onChange } = useLocationFilterValue({ filter, apply });

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: <RangeSelect label={config.label} max={MAX} value={value || defaultRange} onRangeChange={onChange} />,
    };

    return <FilterTemplate {...props} />;
};
