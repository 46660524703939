import SocialNetworkFeeds from 'components/features/SocialNetwork/Feeds';
import SocialNetworkHeader from 'components/features/SocialNetwork/Header';
import { getSocialNetworkFeeds, useSocialNetworksHeaderFromDomainData } from 'components/features/SocialNetwork/utils';

import { TabContent, EmbeddedDomainTabProps } from '..';

export const SocialNetworkTab: React.FC<EmbeddedDomainTabProps> = ({ data, loading }) => {
    const socialNetworks = useSocialNetworksHeaderFromDomainData(data);

    return (
        <TabContent>
            <SocialNetworkHeader
                skeleton={loading}
                socialNetworks={socialNetworks}
                social_media_index={data?.indexes?.social_media_index}
            />
            <SocialNetworkFeeds {...getSocialNetworkFeeds(socialNetworks)} />
        </TabContent>
    );
};

export default SocialNetworkTab;
