import React from 'react';

import Grid from '@mui/material/Grid';

import Icon from 'components/tokens/Icon';

import { StyledGridItem, StyledListCount, StyledTooltip, StyledTooltipFieldExample } from './styled';

type LabelWithTooltipProps = {
    label: React.ReactNode;
    description?: string | React.ReactNode;
    example?: string;
    isOption: boolean;
    endAdornment?: React.ReactNode;
    count?: number;
};

export const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({
    label,
    description,
    example,
    isOption,
    endAdornment = '',
    count = 0,
}) => {
    const renderTooltipBody = () => {
        return (
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs="auto">
                    {label}{' '}
                </Grid>
                <StyledGridItem item xs="auto">
                    {isOption ? (
                        <>
                            {Boolean(count) && count > 0 && (
                                <StyledListCount>
                                    <span>{count}</span>
                                </StyledListCount>
                            )}

                            <Icon type="ChevronRight" color="text.subtle" />
                        </>
                    ) : null}
                </StyledGridItem>
                {endAdornment ? (
                    <StyledGridItem item xs="auto">
                        {endAdornment}
                    </StyledGridItem>
                ) : null}
            </Grid>
        );
    };
    if (!description) {
        return <>{renderTooltipBody()}</>;
    }
    return (
        <StyledTooltip
            title={
                <div>
                    {description}
                    {example && <StyledTooltipFieldExample>e.g. {example}</StyledTooltipFieldExample>}
                </div>
            }
            placement="right"
            arrow
            enterDelay={500}
            enterNextDelay={500}
        >
            {renderTooltipBody()}
        </StyledTooltip>
    );
};
