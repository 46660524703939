import { memo, useState } from 'react';

import { Box, Tooltip } from '@mui/material';
import { difference, union } from 'lodash';

import { Signal } from 'api/types/signals/signals';
import { FilterTagObject } from 'api/types/Tag';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';

import SignalsGrid from '../Grid/SignalsGrid';
import SignalsMultiSelect from '../Select/SignalsMultiSelect';

export type SignalsViewProps = {
    signals: Signal[];
    loading?: boolean;
    loadingNextPage?: boolean;
    tags: FilterTagObject[];
    currentOrganizationBusinessId: string;
    onFilterChange?: (tagIds: number[]) => void;
    onLoadMoreClick?: () => void;
};

export const SignalsView: React.VFC<SignalsViewProps> = memo(
    ({ signals, loading, loadingNextPage, tags, currentOrganizationBusinessId, onFilterChange, onLoadMoreClick }) => {
        const [selectedTags, setSelectedTags] = useState<number[]>([]);

        const handleChange = (id: number) => {
            setSelectedTags((tags) => {
                if (tags.includes(id)) {
                    const newTags = tags.filter((tag) => tag !== id);
                    onFilterChange?.(newTags);
                    return newTags;
                } else {
                    const newTags = [...tags, id];
                    onFilterChange?.(newTags);
                    return newTags;
                }
            });
        };
        const handleSelectAll = (shownTagIds: number[]) => {
            setSelectedTags((tags) => {
                const newTags = union(tags, shownTagIds);
                onFilterChange?.(newTags);
                return newTags;
            });
        };
        const handleDeselectAll = (shownTagIds: number[]) => {
            setSelectedTags((tags) => {
                const newTags = difference(tags, shownTagIds);
                onFilterChange?.(newTags);
                return newTags;
            });
        };

        return (
            <Box>
                <SignalsMultiSelect
                    value={selectedTags}
                    tags={tags}
                    label="Filter results"
                    onChange={handleChange}
                    onSelectAll={handleSelectAll}
                    onDeselectAll={handleDeselectAll}
                    sx={{ marginBottom: 2 }}
                />
                <SignalsGrid
                    loading={loading}
                    loadingNextPage={loadingNextPage}
                    signals={signals}
                    variant="shadowed"
                    currentOrganizationBusinessId={currentOrganizationBusinessId}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                    <Tooltip title="Load more">
                        <Button
                            variant="flat"
                            startIcon={<Icon type="ChevronDown" color="text.secondary" />}
                            size="large"
                            sx={{ borderRadius: '50px' }}
                            onClick={onLoadMoreClick}
                        />
                    </Tooltip>
                </Box>
            </Box>
        );
    },
);

export default SignalsView;
