import * as Sentry from '@sentry/react';

export function initSentry() {
    if (process.env.REACT_APP_SENTRY_DSN) {
        const release = process.env.REACT_APP_RELEASE_ID;
        if (!release) {
            console.warn('Warning: Missing Sentry release ID.');
        }
        console.info('Initializing Sentry'); // eslint-disable-line no-console
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [new Sentry.BrowserTracing()],
            release,
            tracesSampleRate: 0,
            environment: process.env.REACT_APP_ENV ?? process.env.NODE_ENV,
            // This prevents creating issues from errors that e.g. Google Analytics could cause, eating our issue quota.
            allowUrls: ['vainu.io', 'vainu.app'],
            beforeSend: (event, hint) => {
                const error = hint.originalException;
                if (error && typeof error === 'object' && 'code' in error && error.code === 'ECONNABORTED') {
                    return null;
                }
                return event;
            },
        });
    }
}
