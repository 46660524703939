import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import ChipInput from 'components/tokens/ChipInput';
import Switch from 'components/tokens/Switch';

export interface WebhookDestinationType {
    system: 'webhook';
    urls: string[];
    active: boolean;
    isValid?: boolean;
}

interface Props {
    destination: WebhookDestinationType | null;
    updateDestination: (obj: WebhookDestinationType) => void;
    hideHeader?: boolean;
}

const validate = (obj: Pick<WebhookDestinationType, 'urls' | 'active'>) => {
    const { active, urls } = obj;
    return !!(active && urls?.length);
};

const WebhookDestination: React.FC<Props> = ({ destination, updateDestination, hideHeader = false }) => {
    const [values, setValues] = useState(destination?.urls || []);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [enabled, setEnabled] = useState(!!destination?.active);

    const onValueChange = (urls: string[]) => {
        setValues(urls);
        updateDestination({
            system: 'webhook',
            urls,
            active: true,
            isValid: validate({ urls, active: true }),
        });
    };

    const onEnableToggle = (enable: boolean) => {
        setEnabled(enable);
        updateDestination({
            system: 'webhook',
            urls: values,
            active: enable,
            isValid: validate({ urls: values, active: enable }),
        });
    };
    const handleValidateUrl = (url: string) => {
        if (!url) {
            return false;
        }
        const isValid =
            /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
                url,
            );
        if (!isValid) {
            setShowErrorSnackbar(true);
        }
        return isValid;
    };

    return (
        <div>
            {hideHeader ? null : (
                <Grid
                    container
                    key={destination?.system}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ padding: '5px 0px 5px 0px' }}
                >
                    <Typography sx={{ fontWeight: 500, fontSize: 14 }}>Webhook</Typography>
                    <Switch checked={enabled} onChange={(e) => onEnableToggle(e.target.checked)} />
                </Grid>
            )}
            <Grid container>
                {enabled ? (
                    <ChipInput
                        values={values}
                        setValues={onValueChange}
                        width="300px"
                        onValidateInput={handleValidateUrl}
                        placeholder="https://..."
                    />
                ) : null}
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={showErrorSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowErrorSnackbar(false)}
                message={<span>Invalid destination url!</span>}
            />
        </div>
    );
};

export default WebhookDestination;
