import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const skeletonCards = () => {
    return new Array(8).fill(null).map((_, idx) => (
        <Stack spacing={1} key={idx}>
            <Skeleton variant="rectangular" width="100%" height={390} />
        </Stack>
    ));
};
