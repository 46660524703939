import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { setUser } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
    AuthDataOrError,
    ImpersonateAuth,
    ImpersonateAuthOrError,
    authWithSsoCode,
    getAzureRedirectUrl,
    getGoogleSSORedirectUrl,
    isImpersonateSuccess,
    isSuccess,
    postAuthentication,
    postImpersonateAuthentication,
    postRefresh,
} from 'api/auth';
import { MainPath } from 'components/beta/Platform/Router/routePaths';
import { createPath } from 'components/beta/Platform/Router/utils';
import { LocalStorage } from 'utilities/localStorage';

import { getUserEmailFromToken } from './utilities/authToken';

export class AuthenticationError extends AxiosError {}

export type AuthStatus =
    | 'initial'
    | 'logged-in'
    | 'logging-in'
    | 'refreshing'
    | 'logging-out'
    | 'logged-out'
    | 'error'
    | 'accept-terms';
export type AuthContextValue = {
    authState: AuthState | null;
    /** Note: You should not normally use this function, but instead use the authState variable
     * which triggers component rerenders. This is only used by the Axios context, so that
     * all the Axios instances have the newest tokens in case the instances are used in
     * callbacks, e.g. with setTimeout. */
    getAuth: () => Promise<AuthState | null>;
    setAuth: (newState: AuthDataOrError) => void;
    /** Try authenticating the user with a username or password. Note: the function does not return a state;
     * observe authState changes instead. */
    authenticate: (username: string, password: string) => void;
    /** Gives a status of the authentication. Returns true when the user has successfully logged in and has a valid
     * access token. Returns false when the user needs to log in first. Returns 'logging-in' when the backend is processing
     * the login request. Returns 'refreshing' when the user has a valid refresh token, but the access token has expired,
     * and the context is requesting a new access token. */
    authStatus: AuthStatus;
    authError: string;
    /** Use this to clear the error message to stop showing it in the UI. */
    clearError: () => void;
    logout: () => void;
    doRefresh: () => Promise<AuthState>;
    impersonateAuthState: ImpersonateAuth | null;
    setImpersonateAuth: (newState: ImpersonateAuthOrError | null) => void;
    getImpersonateAuth: () => ImpersonateAuth | null;
    doImpersonation: (email: string, notes: string) => Promise<void>;
    stopImpersonation: () => void;
    navigateToAzureAuth: () => void;
    navigateToGoogleAuth: () => void;
    doSsoAuth: (provider: string, code: string) => Promise<void>;
};

export type AuthState = {
    access?: string;
    refresh?: string;
    expiresAt?: number;
};

// Beware of this type assertion, but in essence the context will always be properly defined.
// It's either this, checking for context !== undefined every time
// or using useContext(authContext)! every time.
// The context value is anyway type-checked in the Provider assignment.
export const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

export const authStorageKey = 'vainu_auth';
export const impersonateStorageKey = 'vainu_impersonate_auth';

type AuthProviderProps = {
    mock?: boolean;
    children?: React.ReactNode;
};

const defaultErrorMessage = 'The authentication did not succeed for unknown reason. Please try again later.';

const expiredRefreshTokenError = 'Expired refresh token';

const setSentryContext = (authState: AuthState | undefined | null) => {
    if (authState?.access) {
        const email = getUserEmailFromToken(authState.access);
        if (email) {
            setUser({ email });
        } else {
            setUser(null);
        }
    } else {
        setUser(null);
    }
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, mock }) => {
    const { Provider } = AuthContext;

    useEffect(() => {
        const handleStorageEvent = (event: StorageEvent) => {
            if (event.key === authStorageKey && typeof event.newValue === 'string') {
                setSentryContext(JSON.parse(event.newValue));
                setAuthState(JSON.parse(event.newValue));
            }
        };

        window.addEventListener('storage', handleStorageEvent);
        return () => window.removeEventListener('storage', handleStorageEvent);
    }, []);

    const getAuth = useCallback(async (): Promise<AuthState | null> => {
        if (refreshPromise.current) {
            return refreshPromise.current;
        }
        return getStoredAuth();
    }, []);

    const getStoredAuth = (): AuthState | null => LocalStorage.getItem<AuthState>(authStorageKey);

    const [authState, setAuthState] = useState<AuthState | null>(getStoredAuth);
    const [authStatus, setAuthStatus] = useState<AuthStatus>('initial');
    const [authError, setAuthError] = useState('');
    const refreshPromise = useRef<Promise<AuthState> | null>(null);
    const queryClient = useQueryClient();

    const setAuth = useCallback((newAuth: AuthDataOrError): { status: AuthStatus; error?: string } => {
        if (isSuccess(newAuth)) {
            LocalStorage.setItem(authStorageKey, newAuth);
            setSentryContext(newAuth);
            setAuthState({ ...newAuth });
            setAuthError('');
            const status = newAuth.required_actions?.includes('ACCEPT_TERMS') ? 'accept-terms' : 'logged-in';
            setAuthStatus(status);
            return { status };
        } else {
            setSentryContext(null);
            setAuthState({});
            // If the token failed due to invalid token magic hash, purge the refresh token and show an error message.
            if (newAuth.detail === 'Platform id check failed') {
                LocalStorage.removeItem(authStorageKey);
                const error = 'Authentication failed. Your browser may not be compatible with our platform.';
                setAuthError(error);
                const status = 'error';
                setAuthStatus(status);
                return { status, error };
            }
            // If the token is not valid for some other reason, just consider the user as a logged-out.
            // (Most probably the refresh token has just already expired.)
            if (newAuth.code === 'token_not_valid') {
                // Purge the invalid token from the browser. Note: the react-query cache is kept intact.
                LocalStorage.removeItem(authStorageKey);
                setAuthError('');
                const status = 'logged-out';
                setAuthStatus(status);
                return { status };
            } else {
                const error =
                    newAuth.detail === 'Incorrect authentication credentials.'
                        ? 'Invalid email or password'
                        : newAuth.detail ?? 'Something unexpected happened';
                setAuthError(error);
                const status = 'error';
                setAuthStatus(status);
                return { status, error };
            }
        }
    }, []);

    const getImpersonateAuth = useCallback((): ImpersonateAuth | null => {
        try {
            return LocalStorage.getItem<ImpersonateAuth>(impersonateStorageKey);
        } catch (error) {
            return null;
        }
    }, []);

    const impersonateAuth = getImpersonateAuth();
    const [impersonateAuthState, setImpersonateAuthState] = useState<ImpersonateAuth | null>(
        impersonateAuth ? { ...impersonateAuth } : null,
    );

    const setImpersonateAuth = useCallback((newAuth: ImpersonateAuthOrError | null) => {
        if (newAuth && isImpersonateSuccess(newAuth)) {
            LocalStorage.setItem(impersonateStorageKey, newAuth);
            setImpersonateAuthState({ ...newAuth });
        } else {
            setImpersonateAuthState(null);
            LocalStorage.removeItem(impersonateStorageKey);
        }
    }, []);

    const authenticate = useCallback(
        async (username: string, password: string) => {
            setAuthStatus('logging-in');
            try {
                const response = await postAuthentication(username, password);
                setAuth(response);
            } catch (error) {
                setAuthState({});
                setAuthError(defaultErrorMessage);
                setAuthStatus('error');
            }
        },
        [setAuth],
    );

    const doRefresh = useCallback(async (): Promise<AuthState> => {
        if (refreshPromise.current) {
            return refreshPromise.current;
        } else if (authState?.refresh) {
            const newRefreshPromise = new Promise<AuthState>(async (resolve, reject) => {
                if (authState?.refresh) {
                    const response = await postRefresh(authState.refresh);
                    refreshPromise.current = null;
                    const { status } = setAuth(response);
                    if (status === 'logged-in') {
                        const authState = await getAuth();
                        if (authState) {
                            return resolve(authState);
                        }

                        return reject(
                            'The access token was successfully refreshed, but not found in the storage afterwards.',
                        );
                    }
                    return reject(expiredRefreshTokenError);
                }
                reject('Tried to refresh without a refresh token.');
            });
            refreshPromise.current = newRefreshPromise;
            return newRefreshPromise;
        }
        throw new AuthenticationError('Tried to refresh without a refresh token.', 'REFRESH_TOKEN_MISSING');
    }, [authState, getAuth, setAuth]);

    const logout = useCallback(() => {
        LocalStorage.clear();
        setSentryContext(null);
        setAuthState(null);
        setAuthStatus('logging-out');
        queryClient.clear();
        window.location.href = createPath(MainPath.Root);
    }, [queryClient]);

    const clearError = useCallback(() => {
        setAuthError('');
    }, []);

    const doImpersonation = useCallback(
        async (email: string, notes: string) => {
            if (authState?.access) {
                queryClient.clear();
                const response = await postImpersonateAuthentication(authState.access, email, notes);
                setImpersonateAuth(response);
                // As Axios does not seem to await for the request to close, only for the response data to end,
                // redirecting sometimes causes connection aborted error.
                // Redirecting on the next tick seems to solve the problem (not sure if guaranteed).
                setTimeout(() => (window.location.href = createPath(MainPath.Root)));
            }
        },
        [authState, setImpersonateAuth, queryClient],
    );

    const stopImpersonation = useCallback(() => {
        setImpersonateAuth(null);
        queryClient.clear();
        window.location.href = createPath(MainPath.Root);
    }, [setImpersonateAuth, queryClient]);

    const navigateToAzureAuth = useCallback(async () => {
        try {
            const redirectUrl = await getAzureRedirectUrl();
            window.location.href = redirectUrl;
        } catch (e) {
            setAuthStatus('error');
            setAuthError('Azure authentication error');
        }
    }, []);

    const navigateToGoogleAuth = useCallback(async () => {
        try {
            const redirectUrl = await getGoogleSSORedirectUrl();
            window.location.href = redirectUrl;
        } catch (e) {
            setAuthStatus('error');
            setAuthError('Google authentication error');
        }
    }, []);

    const doSsoAuth = useCallback(
        async (provider: string, code: string) => {
            if (code && provider) {
                try {
                    setAuthError('');
                    setAuthStatus('logging-in');
                    const newAuth = await authWithSsoCode(code, provider);
                    setAuth(newAuth);
                } catch (e) {
                    setAuthStatus('error');
                    setAuthError('SSO authentication error');
                }
            }
        },
        [setAuth],
    );

    useEffect(() => {
        if (mock && authStatus !== 'logged-in') {
            setAuthStatus('logged-in');
        } else if (authStatus === 'initial') {
            if (!authState) {
                setAuthStatus('logged-out');
            } else {
                const { refresh, access, expiresAt } = authState;
                const now = Date.now();

                // If the user has an access token, which _should_ be valid for the current time, go and log the user in.
                // In case the token isn't actually valid (due to clock mismatch, for example), the first request made
                // will refreh the token.
                const isLoginValid = !!refresh && !!access && !!expiresAt && expiresAt > now;
                // If the user has a token that is probably expired (again, clock mismatch is possible),
                // try refreshing it now. Only do this at the initial log-in state to prevent circular refresh loops.
                const isAccessTokenExpired = !!refresh && !!expiresAt && expiresAt < now;

                if (isLoginValid) {
                    setSentryContext(authState);
                    setAuthStatus('logged-in');
                } else if (isAccessTokenExpired) {
                    (async () => {
                        try {
                            if (refresh) {
                                setAuthStatus('refreshing');
                                await doRefresh();
                            } else {
                                setAuthStatus('logged-out');
                            }
                        } catch (error) {
                            // If the refreshing process logged out, it was most probably due to expired refresh token, so just show the login page again.
                            if (typeof error === 'string' && error !== expiredRefreshTokenError) {
                                setAuthState({});
                                setImpersonateAuth(null);
                                setAuthError(
                                    'The authentication did not succeed for unknown reason. Please try again later.',
                                );
                                setAuthStatus('error');
                            }
                        }
                    })();
                } else {
                    setAuthStatus('logged-out');
                }
            }
        }
    }, [authState, authStatus, mock, doRefresh, setImpersonateAuth]);

    const value = useMemo<AuthContextValue>(
        () => ({
            authState,
            getAuth,
            setAuth,
            authenticate,
            authStatus,
            authError,
            clearError,
            logout,
            doRefresh,
            impersonateAuthState,
            setImpersonateAuth,
            getImpersonateAuth,
            doImpersonation,
            stopImpersonation,
            navigateToAzureAuth,
            navigateToGoogleAuth,
            doSsoAuth,
        }),
        [
            authError,
            authState,
            authStatus,
            authenticate,
            clearError,
            doImpersonation,
            doRefresh,
            getAuth,
            getImpersonateAuth,
            impersonateAuthState,
            logout,
            setAuth,
            setImpersonateAuth,
            stopImpersonation,
            navigateToAzureAuth,
            navigateToGoogleAuth,
            doSsoAuth,
        ],
    );

    return <Provider value={value}>{children}</Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
