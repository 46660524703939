import React, { MouseEvent, useState } from 'react';

import { Box, Tooltip } from '@mui/material';
import { includes, isEmpty } from 'lodash';
import { isNil, isNotEmpty } from 'utilities';

import { ContactsUpgradeButton, ContactsUpgradeDialog } from 'components/features/Employees';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';

import SeparateFileIndicator from './SeparateFileIndicator';
import { LineBreak, OpenerIcon, Row, RowLabel, RowWrapper } from './styled';
import { hasAllDescendantsSelected, hasSelectedDescendants } from './treeViewUtilities';
import { TreeNode } from './types';

export interface TreeViewProps {
    filterValue?: string;
    data: TreeNode[];
    disabledItems: string[];
    filteredItems: string[];
    selectedItems: string[];
    openRows: string[];
    toggleOpen: (item: TreeNode) => void;
    toggleSelection?: (item: TreeNode) => void;
    excludeParents?: boolean;
}

const TreeView: React.FC<TreeViewProps> = ({
    filterValue,
    data,
    toggleOpen,
    toggleSelection,
    filteredItems,
    openRows,
    disabledItems,
    selectedItems,
    excludeParents = false,
}) => {
    const { hasProductPermission } = usePermissionContext();
    const hasContactDataPermission = hasProductPermission(Permission.ContactData);

    const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);

    const getIsSelected = (node: TreeNode): boolean => {
        if (isNotEmpty(node.children)) {
            return hasAllDescendantsSelected(node, selectedItems, excludeParents, disabledItems);
        } else {
            return includes(selectedItems, node.id);
        }
    };

    const renderTree = (nodes: TreeNode[]) => {
        return (
            <ul className="list">
                {isNotEmpty(nodes) &&
                    nodes.map((node) => {
                        const isOpen = includes(openRows, node.id);
                        const isDisabled = includes(disabledItems, node.id);
                        const isSelected = getIsSelected(node);
                        const isVisible =
                            isEmpty(filteredItems) || (isNotEmpty(filteredItems) && includes(filteredItems, node.id));
                        const isIndeterminate =
                            isNotEmpty(node.children) &&
                            hasSelectedDescendants(node.children, selectedItems) &&
                            !hasAllDescendantsSelected(node, selectedItems, excludeParents, disabledItems);

                        const renderTreeChilds =
                            isOpen && !isDisabled && node.children.length > 0 && renderTree(node.children);

                        return (
                            <RowWrapper key={node.id} isVisible={isVisible}>
                                <Row
                                    selected={isSelected && !isIndeterminate}
                                    disabled={isDisabled}
                                    area-hidden="true"
                                    aria-label={node.label}
                                    onClick={() => !isDisabled && toggleSelection?.(node)}
                                    level={node.depth}
                                >
                                    <Checkbox
                                        role="checkbox"
                                        key={`${node.id}-checkbox`}
                                        data-testid={`${node.id}-checkbox`}
                                        checked={isSelected}
                                        indeterminate={isIndeterminate}
                                        disabled={isDisabled}
                                        onChange={() => toggleSelection?.(node)}
                                    />
                                    <OpenerIcon disabled={isDisabled}>
                                        {node.children?.length > 0 && [
                                            isOpen && !isDisabled ? (
                                                <Icon
                                                    data-testid="ChevronDownIcon"
                                                    type="ChevronDown"
                                                    key={`${node.id}-close`}
                                                    onClick={(e: MouseEvent) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        toggleOpen(node);
                                                    }}
                                                />
                                            ) : (
                                                <Icon
                                                    data-testid="ChevronRightIcon"
                                                    type="ChevronRight"
                                                    key={`${node.id}-open`}
                                                    onClick={(e: MouseEvent) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        toggleOpen(node);
                                                    }}
                                                />
                                            ),
                                        ]}
                                    </OpenerIcon>
                                    <RowLabel
                                        key={node.id}
                                        selected={isSelected && !isIndeterminate}
                                        disabled={isDisabled}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {!isNil(node.args) && <SeparateFileIndicator fieldName={node.label} />}
                                            {node.label}
                                            {node.args === 'contacts' && !hasContactDataPermission && (
                                                <ContactsUpgradeButton
                                                    sx={{ marginLeft: 1 }}
                                                    onClick={() => setUpgradeDialogOpen(true)}
                                                />
                                            )}
                                        </Box>
                                    </RowLabel>
                                    {node.tooltip ? (
                                        <Tooltip title={node.tooltip} placement="top" arrow>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginLeft: 1,
                                                }}
                                            >
                                                <Icon
                                                    type="HelpCircleOutline"
                                                    color="text.subtle"
                                                    sx={{
                                                        fontSize: '1em',
                                                    }}
                                                />
                                            </Box>
                                        </Tooltip>
                                    ) : null}
                                </Row>
                                {/* Element to force children into their own rows */}
                                <LineBreak />
                                <div>{renderTreeChilds}</div>
                            </RowWrapper>
                        );
                    })}
            </ul>
        );
    };

    return (
        <div>
            {renderTree(data)}

            <ContactsUpgradeDialog open={upgradeDialogOpen} onClose={() => setUpgradeDialogOpen(false)} />
        </div>
    );
};

export default TreeView;
