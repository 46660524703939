import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, FixedSizeList } from 'react-window';

import { Contact } from 'api/types/Organization';
import Skeleton from 'components/modules/connectors/skeletons';
import { CompanyPreview, LeadPreview } from 'contexts/TriggerContext';
import useDebounce from 'hooks/useDebounce';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import { ScrollContainerOuter } from '../ScrollContainerOuter';
import CompanyCell from './CompanyCell';
import ContactsCell from './ContactsCell';
import LeadCell from './LeadCell';
import PreviewCell from './PreviewCell';
import PreviewModal from './PreviewModal';
import SelectionCell from './SelectionCell';
import StatusCell from './StatusCell';
import VainuDateCell from './VainuDateCell';

interface TableProps {
    hasContactDataPermission: boolean;
    data: LeadPreview[];
    isFetching: boolean;
    selectContact: (id: string, contact: Partial<Contact>) => void;
    selectCompany: (id: string, company: CompanyPreview) => void;
    hasCRMdestination: boolean;
    selectedRowIds: Record<string, boolean>;
    setReDeliverID: React.Dispatch<React.SetStateAction<string | null>>;
    setShowExportModal: React.Dispatch<React.SetStateAction<boolean>>;
    openExportModal: (destination: string) => void;
    openUpgradeDialog: () => void;
    setSelectedRowIds: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    nextDeliveryLabel: string;
    hiddenColumns?: string[];
    fetchMore: () => void;
    scrollRef?: React.RefObject<HTMLElement>;
}

export type Column = {
    title: string;
    id: string;
    Cell: React.ComponentType<CellProps>;
    width?: string | number;
};

const columns: Column[] = [
    {
        title: '',
        id: 'selection',
        Cell: SelectionCell,
        width: 62,
    },
    {
        title: 'Title',
        id: 'lead',
        Cell: LeadCell,
    },
    {
        title: 'Contact',
        id: 'contact',
        Cell: ContactsCell,
        width: 200,
    },
    {
        title: 'Company',
        id: 'company',
        Cell: CompanyCell,
        width: 200,
    },
    {
        title: 'Detected',
        id: 'vainu_date',
        Cell: VainuDateCell,
        width: 150,
    },
    {
        title: 'Delivered',
        id: 'status',
        Cell: StatusCell,
        width: 62,
    },
    {
        title: '',
        id: 'preview',
        Cell: PreviewCell,
        width: 140,
    },
];

const TableComponent = ({
    hasContactDataPermission,
    data,
    isFetching,
    selectContact,
    selectCompany,
    hasCRMdestination,
    selectedRowIds,
    setReDeliverID,
    setShowExportModal,
    openExportModal,
    openUpgradeDialog,
    setSelectedRowIds,
    nextDeliveryLabel,
    hiddenColumns = [],
    fetchMore,
    scrollRef,
}: TableProps) => {
    const [showPreviewModal, setShowPreviewModal] = useState<string | null>(null);

    const loadMoreButtonRef = useRef(null);
    const shouldLoadMore = useIntersectionObserver({ target: loadMoreButtonRef, threshold: 0.1 });

    useEffect(() => {
        if (!isFetching && shouldLoadMore) {
            fetchMore();
        }
    }, [fetchMore, shouldLoadMore, isFetching]);

    const showLoadingIndicator = useDebounce(isFetching, 200);

    const validItems = data.filter((lead) => lead.isValid);

    if (!validItems.length && !showLoadingIndicator) {
        return null;
    }

    const filteredColumns = columns.filter((col) => !hiddenColumns.includes(col.id));

    const itemData = {
        hasContactDataPermission,
        items: validItems,
        setShowPreviewModal,
        selectContact,
        selectCompany,
        setReDeliverID,
        setShowExportModal,
        openExportModal,
        openUpgradeDialog,
        hasCRMdestination,
        selectedRowIds,
        setSelectedRowIds,
        nextDeliveryLabel,
        isFetching: showLoadingIndicator,
        columns: filteredColumns,
    };

    return (
        <Box sx={{ marginTop: 1 }}>
            <AutoSizer disableHeight style={{ width: '100%' }}>
                {({ width = 0 }) => (
                    <Table component="div" sx={{ display: 'block' }}>
                        <TableHead component="div" sx={{ display: 'block' }}>
                            <TableRow component="div" sx={{ display: 'flex' }}>
                                {filteredColumns.map((col) => (
                                    <TableCell
                                        component="div"
                                        key={col.id}
                                        sx={{ width: col.width, flex: col.width ? 'none' : 'auto' }}
                                    >
                                        {col.title}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody component="div" sx={{ display: 'block' }}>
                            <FixedSizeList
                                itemSize={55}
                                itemCount={validItems.length}
                                width={width}
                                height={scrollRef?.current?.offsetHeight || 100}
                                outerElementType={ScrollContainerOuter}
                                itemData={itemData}
                                style={{ width: '100%' }}
                            >
                                {Row}
                            </FixedSizeList>

                            {showLoadingIndicator ? (
                                <TableRow component="div">
                                    {filteredColumns.map((col) => (
                                        <TableCell
                                            component="div"
                                            key={col.id}
                                            sx={{ width: col.width, flex: col.width ? 'none' : 'auto' }}
                                        >
                                            <Skeleton height={32} />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </Table>
                )}
            </AutoSizer>

            <div ref={loadMoreButtonRef} style={{ position: 'absolute', bottom: 0, color: 'transparent', height: 500 }}>
                .
            </div>

            <PreviewModal
                open={showPreviewModal !== null}
                data={showPreviewModal !== null ? data?.find((row) => row.id === showPreviewModal) || null : null}
                onClose={() => setShowPreviewModal(null)}
            />
        </Box>
    );
};

export default TableComponent;

type RowProps = {
    data: {
        hasContactDataPermission: boolean;
        items: LeadPreview[];
        isFetching: boolean;
        selectedRowIds: Record<string, boolean>;
        setReDeliverID: React.Dispatch<React.SetStateAction<string | null>>;
        setShowExportModal: React.Dispatch<React.SetStateAction<boolean>>;
        openExportModal: (destination: string) => void;
        setSelectedRowIds: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
        selectContact: (id: string, contact: Partial<Contact>) => void;
        selectCompany: (id: string, company: CompanyPreview) => void;
        hasCRMdestination: boolean;
        nextDeliveryLabel: string;
        columns: Column[];
    };
    index: number;
    style: React.CSSProperties;
};

const Row = React.memo(({ data, index, style }: RowProps) => {
    const row = data.items[index];

    if (!row && !data.isFetching) {
        return null;
    }

    return row ? (
        <TableRow component="div" sx={{ ...style, display: 'flex', alignItems: 'center' }}>
            {data.columns.map((col) => (
                <TableCell
                    key={col.id}
                    component="div"
                    sx={{
                        width: col.width,
                        flex: col.width ? 'none' : 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        height: 55,
                    }}
                >
                    <col.Cell {...data} row={row} />
                </TableCell>
            ))}
        </TableRow>
    ) : null;
}, areEqual);

export type CellProps = {
    hasContactDataPermission?: boolean;
    row: LeadPreview;
    selectedRowIds?: Record<string, boolean>;
    setReDeliverID?: React.Dispatch<React.SetStateAction<string | null>>;
    setShowExportModal?: React.Dispatch<React.SetStateAction<boolean>>;
    openExportModal?: (destination: string) => void;
    openUpgradeDialog?: () => void;
    setSelectedRowIds?: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    selectContact?: (id: string, contact: Partial<Contact>) => void;
    selectCompany?: (id: string, company: CompanyPreview) => void;
    hasCRMdestination?: boolean;
    exportTable?: boolean;
    setShowPreviewModal?: React.Dispatch<React.SetStateAction<string | null>>;
    nextDeliveryLabel?: string;
};
