import { Nullable } from 'utilities';

/**
 * Backend now returns the financial values as strings to preserve decimals exactly.
 * For the time being, numbers are still accepted as well.
 */
export type NumberOrString = number | string;

export type FinancialValue = {
    currency_code: string;
    value: NumberOrString;
} | null;

export enum BreakdownCheck {
    AsExpected = 'as_expected',
    LessThanExpected = 'less_than_expected',
    GreaterThanExpected = 'greated_than_expected',
    NotApplicable = 'not_applicable',
}

export type Breakdown<Subdocument> = {
    breakdown: Subdocument | null;
    breakdown_check: BreakdownCheck;
    total: FinancialValue;
} | null;

export type FinancialDataOrganization = {
    business_id: string;
    country: string;
    name: string;
};

export type BalanceSheet = Nullable<{
    assets: Breakdown<{
        current_assets: Breakdown<{
            cash_and_cash_equivalents: FinancialValue;
            inventory: FinancialValue;
            long_term_receivables: Breakdown<{
                other_long_term_receivables: FinancialValue;
            }>;
            other_current_assets: FinancialValue;
            short_term_receivables: Breakdown<{
                accounts_receivable: FinancialValue;
                other_short_term_receivables: FinancialValue;
                prepaid_expenses_and_accrued_income: FinancialValue;
            }>;
        }>;
        noncurrent_assets: Breakdown<{
            noncurrent_intangible_assets: Breakdown<{
                goodwill: FinancialValue;
                other_noncurrent_intangible_assets: FinancialValue;
            }>;
            noncurrent_tangible_assets: Breakdown<{
                property_plant_and_equipment: FinancialValue;
                other_noncurrent_tangible_assets: FinancialValue;
            }>;
            other_noncurrent_assets: FinancialValue;
        }>;
    }>;
    equity_and_liabilities: Breakdown<{
        equity: Breakdown<{
            earnings: FinancialValue;
            other_equity: FinancialValue;
            other_reserves: FinancialValue;
            retained_earnings: FinancialValue;
            share_capital: FinancialValue;
        }>;
        liabilities: Breakdown<{
            long_term_liabilities: Breakdown<{
                long_term_interest_bearing_debt: FinancialValue;
                other_long_term_liabilities: FinancialValue;
            }>;
            short_term_liabilities: Breakdown<{
                accounts_payable: FinancialValue;
                accrued_expenses_and_deferred_income: FinancialValue;
                deferred_revenue: FinancialValue;
                other_short_term_liabilities: FinancialValue;
                short_term_interest_bearing_debt: FinancialValue;
            }>;
        }>;
    }>;
}>;

export type IncomeStatement = Nullable<{
    cost_of_goods_sold: FinancialValue;
    depreciation_and_amortization_costs: Breakdown<{
        amortization_costs: FinancialValue;
        depreciation_costs: FinancialValue;
    }>;
    earnings: FinancialValue;
    earnings_margin: number;
    ebit: FinancialValue;
    ebit_margin: number;
    ebitda: FinancialValue;
    ebitda_margin: number;
    ebt: FinancialValue;
    employee_costs: Breakdown<{
        salary_costs: FinancialValue;
        social_security_costs: FinancialValue;
    }>;
    financial_income_and_costs: Breakdown<{
        financial_costs: FinancialValue;
        financial_income: FinancialValue;
    }>;
    gross_profit: FinancialValue;
    other_operating_costs: FinancialValue;
    other_operating_income: FinancialValue;
    revenue: FinancialValue;
    taxes: Breakdown<{
        income_taxes: FinancialValue;
    }>;
}>;

export type FinancialStatement = Nullable<{
    accounting_period: Nullable<{
        start: string;
        end: string;
    }>;
    auditor: FinancialDataOrganization;
    balance_sheet: BalanceSheet;
    bookkeeper: FinancialDataOrganization;
    employees: Nullable<{
        absolute_count: NumberOrString;
        absolute_full_time_employee_count: NumberOrString;
        average_full_time_employee_count: NumberOrString;
        range_lower_count: NumberOrString;
        range_upper_count: NumberOrString;
    }>;
    income_statement: IncomeStatement;
    metadata: Nullable<{
        created: string;
        modified: string;
        parsed: string;
        // FIXME: This is just a placeholder for the future field having this data.
        // The actual field name may be named differently.
        // pdf_path: string;
    }>;
    years: number[];
    artifact: Nullable<{
        preview_url: string;
        download_url: string;
        file_type: string;
    }>;
}>;

export type FinancialDataLabels = {
    label: string;
    // breakdown?: boolean;
    noBreakdown?: boolean;
    fields?: Record<string, FinancialDataLabels>;
};

/**
 * Ordered object of all the balance sheet labels.
 */
export const balanceSheetLabels: FinancialDataLabels = {
    label: 'Balance sheet',
    noBreakdown: true,
    fields: {
        assets: {
            label: 'Assets',
            fields: {
                current_assets: {
                    label: 'Current assets',
                    fields: {
                        cash_and_cash_equivalents: { label: 'Cash and cash equivalents' },
                        inventory: { label: 'Inventory' },
                        short_term_receivables: {
                            label: 'Short-term receivables',
                            fields: {
                                accounts_receivable: { label: 'Accounts receivable' },
                                prepaid_expenses_and_accrued_income: { label: 'Prepaid expenses and accrued income' },
                                other_short_term_receivables: { label: 'Other short-term receivables' },
                            },
                        },
                        long_term_receivables: {
                            label: 'Long-term receivables',
                            fields: {
                                other_long_term_receivables: { label: 'Other long-term receivables' },
                            },
                        },
                        other_current_assets: { label: 'Other current assets' },
                    },
                },
                noncurrent_assets: {
                    label: 'Non-current assets',
                    fields: {
                        noncurrent_tangible_assets: {
                            label: 'Non-current tangible assets',
                            fields: {
                                property_plant_and_equipment: { label: 'Property plant and equipment' },
                                other_noncurrent_tangible_assets: { label: 'Other non-current tangible assets' },
                            },
                        },
                        noncurrent_intangible_assets: {
                            label: 'Non-current intangible assets',
                            fields: {
                                goodwill: { label: 'Goodwill' },
                                other_noncurrent_intangible_assets: { label: 'Other non-current intangible assets' },
                            },
                        },
                        other_noncurrent_assets: { label: 'Other non-current assets' },
                    },
                },
            },
        },
        equity_and_liabilities: {
            label: 'Equity and liabilities',
            fields: {
                equity: {
                    label: 'Equity',
                    fields: {
                        share_capital: { label: 'Share capital' },
                        retained_earnings: { label: 'Retained earnings' },
                        earnings: { label: 'Earnings' },
                        other_equity: { label: 'Other equity' },
                        other_reserves: { label: 'Other reserves' },
                    },
                },
                liabilities: {
                    label: 'Liabilities',
                    fields: {
                        short_term_liabilities: {
                            label: 'Short-term liabilities',
                            fields: {
                                accounts_payable: { label: 'Accounts payable' },
                                accrued_expenses_and_deferred_income: { label: 'Accrued expenses and deferred income' },
                                short_term_interest_bearing_debt: { label: 'Short-term interest bearing debt' },
                                other_short_term_liabilities: { label: 'Other short-term liabilities' },
                                deferred_revenue: { label: 'Deferred revenue' },
                            },
                        },
                        long_term_liabilities: {
                            label: 'Long-term liabilities',
                            fields: {
                                long_term_interest_bearing_debt: { label: 'Long-term interest bearing debt' },
                                other_long_term_liabilities: { label: 'Other long-term liabilities' },
                            },
                        },
                    },
                },
            },
        },
    },
};

/**
 * Ordered object of all the income statement labels.
 */
export const incomeStatementLabels: FinancialDataLabels = {
    label: 'Income statement',
    noBreakdown: true,
    fields: {
        revenue: { label: 'Revenue' },
        other_operating_income: { label: 'Other operating income' },
        cost_of_goods_sold: { label: 'Cost of goods sold' },
        other_operating_costs: { label: 'Other operating costs' },
        gross_profit: { label: 'Gross profit' },
        employee_costs: {
            label: 'Employee costs',
            fields: {
                salary_costs: { label: 'Salary costs' },
                social_security_costs: { label: 'Social security costs' },
            },
        },
        ebitda: { label: 'EBITDA' },
        depreciation_and_amortization_costs: {
            label: 'Depreciation and amortization costs',
            fields: {
                depreciation_costs: { label: 'Depreciation costs' },
                amortization_costs: { label: 'Amortization costs' },
            },
        },
        ebit: { label: 'EBIT' },
        financial_income_and_costs: {
            label: 'Financial income and costs',
            fields: {
                financial_income: { label: 'Financial income' },
                financial_costs: { label: 'Financial costs' },
            },
        },
        taxes: {
            label: 'Taxes',
            fields: {
                income_taxes: { label: 'Income taxes' },
            },
        },
        earnings: { label: 'Earnings' },
    },
};

export const isBreakdown = (value: unknown): value is NonNullable<Breakdown<unknown>> =>
    // Note: != checks for both null and undefined (!isNil() doesn't work with TypeScript)
    value != null &&
    typeof value === 'object' &&
    'total' in value &&
    'breakdown' in value &&
    'breakdown_check' in value;

export const isFinancialValue = (value: unknown): value is NonNullable<FinancialValue> =>
    // Note: != checks for both null and undefined (!isNil() doesn't work with TypeScript)
    value != null &&
    typeof value === 'object' &&
    'value' in value &&
    (typeof value.value === 'number' || typeof value.value === 'string') &&
    'currency_code' in value &&
    typeof value.currency_code === 'string';
