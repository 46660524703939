import React, { useState } from 'react';

import { stopAsyncProcessByProcessId } from 'api/asyncProcesses';
import { CRMBulkExportAsyncProcess } from 'api/types/UserAsyncProcess';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { useAxiosContext } from 'contexts/AxiosContext';

import { getCRMName } from '../../../modules/connectors/utils';
import Icon from '../../../tokens/Icon';
import AdditionalNotificationDialog from '../AdditionalNotificationDialog';
import { crmLogo, AsyncProcessNotificationProps } from '../NotificationItem';
import NotificationItemContent from '../NotificationItemContent';
import { progress } from '../utils';
import { NotificationStateBasedData } from './CRMBulkExport';
import { GreenText } from './sharedComps';

const getStateBasedData = (notification: CRMBulkExportAsyncProcess): NotificationStateBasedData => {
    const {
        meta_data: { crm, size, completed_exports, export_source },
    } = notification;
    const crmName = getCRMName(crm);
    switch (notification.state) {
        case 'process':
        case 'accepted':
            return {
                title: `${crmName} data update is in progress.`,
                customContent:
                    (size && completed_exports) || completed_exports === 0 ? (
                        <>
                            <GreenText>{completed_exports}</GreenText>/{size} updated
                        </>
                    ) : undefined,
                progress: progress(completed_exports, size),
                hoverText: 'In progress',
            };
        case 'completed':
            return {
                title: '✔️ Data update done',
                customContent: (
                    <>
                        <GreenText>{completed_exports}</GreenText> updated
                    </>
                ),
                hoverText: 'Complete',
            };
        case 'stopped':
            return {
                title: 'Update stopped',
                content: `Update to ${crmName} stopped.`,
                hoverText: 'Stopped',
            };
        default:
            return {
                error: true,
                title: 'Error occured in update',
                content:
                    export_source === 'auto_update'
                        ? `Auto-update to ${crmName} failed`
                        : `One-time update to ${crmName} failed`,
                hoverText: 'Click to see details',
            };
    }
};

const CRMExport: React.FC<AsyncProcessNotificationProps<CRMBulkExportAsyncProcess>> = ({
    notification,
    handleMarkAsSeen,
}) => {
    const [isErrorDialogOpened, setErrorDialogOpened] = useState(false);
    const axios = useAxiosContext();

    const notificationData = getStateBasedData(notification);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (notification.state === 'failure') {
            setErrorDialogOpened(true);
        }
    };

    return (
        <>
            <NotificationItemContent
                seen={notification.seen}
                icon={<Icon type={crmLogo[notification.meta_data.crm as CrmName]} />}
                date={notification.finished || notification.created}
                state={notification.state}
                {...notificationData}
                handleMarkAsSeen={handleMarkAsSeen}
                onClick={handleClick}
                onCancel={() => stopAsyncProcessByProcessId(axios, notification.id)}
            />
            <AdditionalNotificationDialog
                isOpened={isErrorDialogOpened}
                error={notification.error}
                errorMessage={notification.error_msg}
                notificationType="CRMExport"
                onClose={() => setErrorDialogOpened(false)}
            />
        </>
    );
};

export default CRMExport;
