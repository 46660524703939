import React, { useState } from 'react';

import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from '@mui/material';

import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

export type DataFrameProps = {
    title: string;
    children: React.ReactNode;
    popup: React.ReactNode;
    popupWidth?: number;
    popupTooltip?: string;
};

export const DataFrame: React.VFC<DataFrameProps> = ({ title, children, popup, popupWidth, popupTooltip }) => {
    const [popupOpen, togglePopup] = useState(false);

    return (
        <Frame padding="large">
            <Box sx={{ marginBottom: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1" weight="bold" component="h2">
                    {title}
                </Typography>
                {Boolean(popup) &&
                    (popupTooltip ? (
                        <Tooltip arrow title={popupTooltip}>
                            <Button
                                onClick={() => togglePopup(true)}
                                sx={{ marginBottom: '-40px', height: 40 }}
                                variant="tertiary"
                                startIcon={<Icon type="OpenInModal" />}
                            />
                        </Tooltip>
                    ) : (
                        <Button
                            onClick={() => togglePopup(true)}
                            sx={{ marginBottom: '-40px', height: 40 }}
                            variant="tertiary"
                            startIcon={<Icon type="OpenInModal" />}
                        />
                    ))}
            </Box>
            {children}
            <Dialog open={Boolean(popup) && popupOpen} onClose={() => togglePopup(false)}>
                <Button
                    variant="flat"
                    sx={{ color: 'text.subtle', position: 'absolute', right: 5, top: 5 }}
                    startIcon={<Icon type="CloseBig" />}
                    onClick={() => togglePopup(false)}
                />
                <DialogTitle>
                    <Typography sx={{ marginTop: 3, marginBottom: 3 }} variant="h4">
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ width: popupWidth, overflow: 'visible' }}>{popup}</DialogContent>
                <DialogActions sx={{ padding: 3, paddingTop: 0 }}>
                    <Button variant="flat" onClick={() => togglePopup(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Frame>
    );
};

export default DataFrame;
