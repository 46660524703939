import React from 'react';

const ChevronRightDuoSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.4142 13.01L5.9992 11.597L10.5992 6.99699L5.9992 2.39699L7.4142 0.98999L13.4242 6.99999L7.4152 13.01H7.4142ZM1.9892 13.01L0.575195 11.597L5.1752 6.99699L0.575195 2.40399L1.9892 0.98999L8.0002 6.99999L1.9902 13.01H1.9892Z"
                fill="#2F80ED"
            />
        </svg>
    );
};

export default ChevronRightDuoSvg;
