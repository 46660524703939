import React from 'react';

import { useTheme, styled, darken } from '@mui/material';
import { useIntl } from 'react-intl';
import { Pie, Cell, PieChart as RechartsPieChart, Text, Tooltip, Sector } from 'recharts';

import { ChartTooltip, formatTooltipValue } from '../ChartTooltip';
import ResponsiveContainer from '../utils/ResponsiveContainer';

export type ChartNumberStyle = 'decimal' | 'percent' | 'currency';

export interface ChartDataPoint {
    label: number | string;
    value: number | undefined;
}

export type ChartDataField = keyof ChartDataPoint;

export type PieChartProps = {
    data: ChartDataPoint[];
    style?: ChartNumberStyle;
    currency?: string;
    order?: 'default' | 'descending' | 'ascending';
    /** Determines if the pie has a hole in the middle. Set to true to use the default hole radius (75%), or to a number to override (e.g. 0.5 for 50% radius). */
    donut?: boolean | number;
    height?: number;
    /** Determines the order the data points are rendered beginning from the 12 o’clock. */
    anticlockwise?: boolean;
};

const sortAscending = (a: ChartDataPoint, b: ChartDataPoint) =>
    a.value ? (b.value ? a.value - b.value : a.value) : b.value ? b.value : 0;
const sortDescending = (a: ChartDataPoint, b: ChartDataPoint) =>
    a.value ? (b.value ? b.value - a.value : a.value) : b.value ? b.value : 0;

const StyledSector = styled(Sector)(({ fill }) => ({
    transition: 'all 200ms ease',
    '&:hover': {
        fill: fill ? darken(fill, 0.2) : undefined,
    },
}));

export const PieChart: React.VFC<PieChartProps> = ({
    data,
    height = 180,
    donut,
    order = 'default',
    anticlockwise = false,
    style = 'decimal',
    currency,
}) => {
    const theme = useTheme();
    const intl = useIntl();

    if (data.length === 0) {
        return <div style={{ height }} />;
    }

    const colors = [
        theme.palette.chart.default,
        theme.palette.chart.grey1,
        theme.palette.chart.grey2,
        theme.palette.chart.grey3,
        theme.palette.chart.grey4,
        theme.palette.chart.grey5,
    ];

    let sortedData = data;
    if (order !== 'default') {
        sortedData = [...data].sort(order === 'descending' ? sortDescending : sortAscending);
    }

    const outerRadius = height * 0.4;
    const innerRadius =
        typeof donut === 'number' ? outerRadius * donut : donut === true ? outerRadius * 0.75 : undefined;

    const startAngle = 90;
    const endAngle = anticlockwise ? 450 : -270;

    // For some dummy reason Recharts' pie chart doesn't support a custom rendering component like the other charts,
    // but instead just a custom renderer for the active sector. This array is needed to set all the sectors as active,
    // which effectively makes all the sectors use the custom renderer.
    const sectorList = data.map((_data, index) => index);

    return (
        <div>
            <ResponsiveContainer debounce={300} width="100%" height={height}>
                <RechartsPieChart width={400} height={300}>
                    <Pie
                        data={sortedData}
                        dataKey="value"
                        isAnimationActive={false}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        outerRadius={outerRadius}
                        innerRadius={innerRadius}
                        label={(props) =>
                            props.percent > 0.03 ? (
                                <Text {...props} {...theme.typography.chartYAxisLabel}>
                                    {props.label}
                                </Text>
                            ) : null
                        }
                        labelLine={false}
                        activeIndex={sectorList}
                        activeShape={(props) => <StyledSector {...props} />}
                    >
                        {data.map((_entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} stroke={undefined} />
                        ))}
                    </Pie>
                    <Tooltip
                        content={<ChartTooltip labelKey="label" />}
                        formatter={formatTooltipValue(style, currency, style === 'percent' ? 2 : 0, intl)}
                        isAnimationActive={false}
                    />
                </RechartsPieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PieChart;
