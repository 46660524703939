import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EmailServer: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 4H19C20.1 4 21 4.9 21 6V11H19V8L13.375 11H9.625L4 8V17H6V19H4C2.9 19 2 18.1 2 17V6C2 4.9 2.9 4 4 4ZM4 6L11.5 10L19 6H4Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3333 13C9.58557 13 8.98089 13.4032 8.59623 13.908C8.21141 14.4131 8 15.0677 8 15.75V19.25C8 19.9323 8.21141 20.5869 8.59623 21.092C8.98089 21.5968 9.58557 22 10.3333 22H19.6667C20.4144 22 21.0191 21.5968 21.4038 21.092C21.7886 20.5869 22 19.9323 22 19.25V15.75C22 15.0677 21.7886 14.4131 21.4038 13.908C21.0191 13.4032 20.4144 13 19.6667 13H10.3333ZM10 15.75C10 15.4698 10.0886 15.2494 10.1871 15.1201C10.2351 15.0571 10.2776 15.0255 10.3026 15.0115C10.3148 15.0047 10.3228 15.0021 10.3265 15.0011C10.33 15.0001 10.3319 15 10.3333 15H19.6667C19.6681 15 19.67 15.0001 19.6735 15.0011C19.6772 15.0021 19.6852 15.0047 19.6974 15.0115C19.7224 15.0255 19.7649 15.0571 19.8129 15.1201C19.9114 15.2494 20 15.4698 20 15.75V19.25C20 19.5302 19.9114 19.7506 19.8129 19.8799C19.7649 19.9429 19.7224 19.9745 19.6974 19.9885C19.6852 19.9953 19.6772 19.9979 19.6735 19.9989C19.6716 19.9995 19.6702 19.9997 19.6691 19.9999C19.6681 20 19.6673 20 19.6667 20H10.3333C10.3319 20 10.33 19.9999 10.3265 19.9989C10.3228 19.9979 10.3148 19.9953 10.3026 19.9885C10.2776 19.9745 10.2351 19.9429 10.1871 19.8799C10.0886 19.7506 10 19.5302 10 19.25V15.75ZM13 18.5C12.18 18.5 11.5 17.83 11.5 17C11.5 16.17 12.18 15.5 13 15.5C13.82 15.5 14.5 16.17 14.5 17C14.5 17.83 13.83 18.5 13 18.5Z"
        />
    </SvgIcon>
);

export default EmailServer;
