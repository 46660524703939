import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PipedriveText = (props) => (
    <SvgIcon style={{ fill: '#26292C' }} {...props}>
        <path d="M5.64139 10H5.02635V10.6476H5.64139V10Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5294 10.371V12.7758C13.5294 12.8116 13.5424 12.8278 13.5815 12.8311H13.8125V13.4331H13.2138C12.9567 13.4331 12.8851 13.2964 12.8721 13.189C12.7647 13.3127 12.5434 13.4884 12.1627 13.4884C11.4989 13.4884 11.0498 12.9645 11.0498 12.1868C11.0498 11.422 11.5184 10.8851 12.1887 10.8851C12.5337 10.8851 12.7322 11.0055 12.833 11.0966V10.6606C12.833 10.6215 12.82 10.6085 12.781 10.6085H12.5434V10.0032H13.1617C13.409 10.0032 13.5294 10.1236 13.5294 10.371ZM11.7559 12.1835C11.7559 12.6098 11.9674 12.8832 12.2961 12.8832C12.5629 12.8832 12.846 12.7009 12.846 12.1803C12.846 11.8386 12.6801 11.4936 12.3059 11.4936C11.9772 11.4936 11.7559 11.7702 11.7559 12.1835Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.64985 10.8819C3.26261 10.8819 3.03807 11.0576 2.93069 11.1747C2.91767 11.0706 2.84933 10.9372 2.58249 10.9372H2V11.5425H2.23755C2.2766 11.5425 2.28962 11.5555 2.28962 11.5945V14.3638H2.9795V13.2509C3.08688 13.3485 3.2919 13.4852 3.61406 13.4852C4.28766 13.4852 4.75951 12.9515 4.75951 12.1835C4.76277 11.4058 4.3137 10.8819 3.64985 10.8819ZM3.50992 12.8832C3.13895 12.8832 2.96974 12.5285 2.96974 12.1965C2.96974 11.6759 3.25285 11.4904 3.51969 11.4904C3.8451 11.4904 4.06638 11.7702 4.06638 12.19C4.06313 12.6716 3.78327 12.8832 3.50992 12.8832Z"
        />
        <path d="M5.69021 12.7758V11.3081C5.69021 11.0608 5.56981 10.9404 5.32574 10.9404H4.70745V11.5457H4.94501C4.98406 11.5457 4.99707 11.5587 4.99707 11.5978V13.0589C4.99707 13.3094 5.11422 13.4266 5.36154 13.4266H5.97983V12.8246H5.74228C5.70648 12.8278 5.69021 12.8116 5.69021 12.7758Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.77059 11.1747C6.88123 11.0576 7.10577 10.8819 7.49301 10.8819C8.16011 10.8819 8.60919 11.4058 8.60919 12.1835C8.60919 12.9515 8.13734 13.4852 7.46373 13.4852C7.14482 13.4852 6.93655 13.3485 6.82916 13.2509V14.3638H6.13278V11.5945C6.13278 11.5555 6.11976 11.5425 6.08071 11.5425H5.84316V10.9372H6.4224C6.68924 10.9372 6.75757 11.0706 6.77059 11.1747ZM6.81289 12.1965C6.81289 12.5285 6.98211 12.8832 7.35308 12.8832C7.62969 12.8832 7.90954 12.6716 7.90954 12.19C7.90954 11.7702 7.68826 11.4904 7.36285 11.4904C7.09601 11.4904 6.81289 11.6759 6.81289 12.1965Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.90757 10.8819C9.16238 10.8819 8.64497 11.4188 8.64497 12.1835C8.64497 12.9385 9.20468 13.4852 9.97917 13.4852C10.5569 13.4852 10.9205 13.1596 10.9697 13.1155L10.9749 13.111L11.0042 13.0849L10.7048 12.587L10.6593 12.6293C10.656 12.6326 10.3827 12.8832 10.0215 12.8832C9.67653 12.8832 9.42271 12.6716 9.36088 12.3397H10.9977L11.001 12.2942C11.001 12.2927 11.0018 12.2837 11.0032 12.2699C11.0076 12.2238 11.0172 12.1246 11.0172 12.0696C11.0172 11.3602 10.5714 10.8819 9.90757 10.8819ZM9.38691 11.8256C9.46176 11.5815 9.6505 11.4416 9.90757 11.4416C10.1158 11.4416 10.2753 11.601 10.3046 11.8256H9.38691Z"
        />
        <path d="M15.3518 10.9047C15.0654 10.9047 14.7985 11.0706 14.6423 11.3342V11.2789C14.6423 11.0544 14.5187 10.9372 14.2746 10.9372H13.6824V11.5425H13.9199C13.959 11.5425 13.972 11.5555 13.972 11.5945V13.4266H14.6619V12.4309C14.6619 12.3007 14.6781 12.1738 14.7107 12.0566C14.8148 11.7117 15.0914 11.5913 15.3127 11.5913C15.3908 11.5913 15.4494 11.601 15.4494 11.601L15.508 11.6108V10.9242L15.4657 10.9177C15.4657 10.9144 15.4071 10.9047 15.3518 10.9047Z" />
        <path d="M16.4907 11.3081V12.7758C16.4907 12.8116 16.507 12.8278 16.5428 12.8246H16.7804V13.4266H16.162C15.9147 13.4266 15.7976 13.3094 15.7976 13.0589V11.5978C15.7976 11.5587 15.7845 11.5457 15.7455 11.5457H15.5665V10.9404H16.1262C16.3703 10.9404 16.4907 11.0608 16.4907 11.3081Z" />
        <path d="M18.7296 10.9404C18.5148 10.9404 18.3976 11.0153 18.3293 11.1975L17.8802 12.4536C17.8639 12.4992 17.8509 12.5512 17.8379 12.6033C17.8249 12.5545 17.8086 12.4992 17.7924 12.4536L17.3433 11.1975C17.2749 11.012 17.1643 10.9404 16.943 10.9404H16.5525V11.5457H16.6534C16.7087 11.5457 16.725 11.5652 16.738 11.5978L17.4344 13.4299H18.2121L18.9085 11.5978C18.9216 11.5652 18.9378 11.5457 18.9931 11.5457H19.1005V10.9404H18.7296Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.0257 12.1835C19.0257 11.4188 19.5431 10.8819 20.2884 10.8819C20.9522 10.8819 21.398 11.3602 21.398 12.0696C21.398 12.1246 21.3904 12.2237 21.3868 12.2697C21.3857 12.2836 21.385 12.2927 21.385 12.2942L21.3818 12.3397H19.7449C19.8067 12.6716 20.0606 12.8832 20.4055 12.8832C20.7635 12.8832 21.0368 12.6326 21.0401 12.6293L21.0856 12.587L21.385 13.0849L21.3557 13.111L21.3505 13.1155C21.3013 13.1596 20.9377 13.4852 20.36 13.4852C19.5855 13.4852 19.0257 12.9385 19.0257 12.1835ZM20.2884 11.4416C20.0313 11.4416 19.8425 11.5815 19.7677 11.8256H20.6854C20.6561 11.601 20.4966 11.4416 20.2884 11.4416Z"
        />
        <path d="M15.8301 10H16.4451V10.6476H15.8301V10Z" />
        <path d="M21.385 10.9404H21.4761V11.1878H21.5444V10.9404H21.6355V10.8819H21.385V10.9404Z" />
        <path d="M21.8373 11.0934L21.9056 10.8819H22V11.1878H21.9382V10.973L21.8633 11.1878H21.8113L21.7364 10.973H21.7332V11.1878H21.6713V10.8819H21.7657L21.8373 11.0934Z" />
    </SvgIcon>
);

export default PipedriveText;
