import { isNotEmptyObject, simpleHash } from 'utilities';

import { DomainData, ProfileDataFields, Organization, isDomainData, BusinessUnit } from 'api/types/Organization';
import { formatCity } from 'components/features/Locations/utils';
import { ProfileResult } from 'contexts/types/FilterCompanies';
import { COUNTRIES_FULL_NAME } from 'utilities/profiles/constants/countries';

import { TagProps } from '../Tags';

type IndustryCallback = (country: string, industry_code?: string | undefined) => string;

export function formatMainIndustry(
    company: Organization | DomainData | ProfileResult,
    getMainIndustry?: IndustryCallback,
) {
    const mainIndustry: TagProps = { label: '', id: '' };
    if (isDomainData(company)) {
        // Domain data doesn't have official industry. So vainu_custom_industries with
        // highest confidence_score is used as main industry and this is usually the first item.
        /* 
        // This is to be hidden now as we need to make sure this reflect the filter selected (if any)
        if (company.vainu_custom_industries?.length) {
            const customIndustry = company.vainu_custom_industries[0];
            mainIndustry.label = customIndustry.value!;
            mainIndustry.id = `${customIndustry.value}-${customIndustry.confidence_score}`;
        } */
    } else {
        if (company.industries_verbose?.length) {
            const industry = company.industries_verbose[0];
            mainIndustry.label = industry?.description || '';
            mainIndustry.id = industry?.code || '';
        }
    }
    return mainIndustry;
}

export function getFullLocation(hq?: BusinessUnit) {
    if (!hq) {
        return '';
    }
    const { visiting_address, address } = hq;
    if (isNotEmptyObject(visiting_address)) {
        const { city, country } = visiting_address;
        return `${city ? formatCity(city) : ''}${city && country ? ', ' : ''}${
            country ? getCountryNameFromCode(country) : ''
        }`;
    }
    if (isNotEmptyObject(address)) {
        const { city, country } = address;
        return `${city ? formatCity(city) : ''}${city && country ? ', ' : ''}${
            country ? getCountryNameFromCode(country) : ''
        }`;
    }
    return '';
}

export function getCity(hq?: BusinessUnit) {
    if (!hq) {
        return '-';
    }
    return hq.visiting_address?.city
        ? formatCity(hq.visiting_address.city)
        : hq.address?.city
          ? formatCity(hq.address.city)
          : '-';
}

export function getCountryNameFromCode(code?: string) {
    if (!code) {
        return '';
    }
    return COUNTRIES_FULL_NAME[code] ?? code;
}

// @TODO - fix this with real implementation
export function getThumbnailImage({ website_data, name }: ProfileDataFields) {
    return (
        website_data?.screenshot_thumb_url ||
        `https://static2.vainu.io/img/aerials/aerial-${name ? simpleHash(name.slice(0, 8)) % 7 : 0}.jpg`
    );
}
