import React from 'react';

const MatchedSuccessIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0973 19.4941H18.4063C18.7055 19.4941 18.948 19.7367 18.948 20.0359C18.948 20.3351 18.7055 20.5776 18.4063 20.5776H13.0973C12.7981 20.5776 12.5556 20.3351 12.5556 20.0359C12.5556 19.7367 12.7981 19.4941 13.0973 19.4941ZM13.0973 16.5688H18.4063C18.7055 16.5688 18.948 16.8113 18.948 17.1105C18.948 17.4097 18.7055 17.6523 18.4063 17.6523H13.0973C12.7981 17.6523 12.5556 17.4097 12.5556 17.1105C12.5556 16.8113 12.7981 16.5688 13.0973 16.5688ZM13.0973 13.6434H18.4063C18.7055 13.6434 18.948 13.886 18.948 14.1852C18.948 14.4844 18.7055 14.7269 18.4063 14.7269H13.0973C12.7981 14.7269 12.5556 14.4844 12.5556 14.1852C12.5556 13.886 12.7981 13.6434 13.0973 13.6434ZM20.7678 26.6832C20.7672 27.3665 20.442 27.7117 19.7573 27.7249C19.016 27.7393 18.2742 27.7192 17.5331 27.7336C17.2608 27.739 17.1932 27.6276 17.1965 27.3785C17.2068 26.5804 17.2042 25.7821 17.1987 24.9838C17.1946 24.3659 17.0321 24.2106 16.403 24.2063C15.961 24.2034 15.5187 24.2025 15.0768 24.2065C14.465 24.212 14.2946 24.3814 14.29 25.0039C14.2843 25.7878 14.2781 26.5718 14.2941 27.3555C14.2999 27.6423 14.2292 27.7587 13.917 27.7493C13.2047 27.7273 12.4912 27.7463 11.7782 27.741C11.0894 27.736 10.7451 27.3976 10.7449 26.7199C10.7431 22.458 10.7419 18.1961 10.7456 13.9343C10.7467 12.7767 11.5261 11.8859 12.6839 11.8602C14.7354 11.8147 16.7894 11.8159 18.8408 11.8633C19.9985 11.8899 20.7635 12.7735 20.7662 13.9405L20.7678 26.6832Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.8844 18.688C21.8852 18.0397 22.1957 17.7072 22.8421 17.6997C23.8106 17.6884 24.7797 17.6793 25.7478 17.7038C26.9563 17.7345 27.7906 18.6097 27.7925 19.8177C27.7957 22.0959 27.7942 24.3742 27.7932 26.6523C27.7928 27.3603 27.4889 27.6763 26.7918 27.6789C25.4814 27.6841 24.1707 27.6846 22.8603 27.6787C22.1837 27.6756 21.885 27.3626 21.8842 26.6762L21.8844 18.688ZM24.257 19.4833H25.3405C25.6397 19.4833 25.8822 19.7259 25.8822 20.025C25.8822 20.3242 25.6397 20.5668 25.3405 20.5668H24.257C23.9578 20.5668 23.7153 20.3242 23.7153 20.025C23.7153 19.7259 23.9578 19.4833 24.257 19.4833ZM24.257 22.4737H25.3405C25.6397 22.4737 25.8822 22.7162 25.8822 23.0154C25.8822 23.3146 25.6397 23.5571 25.3405 23.5571H24.257C23.9578 23.5571 23.7153 23.3146 23.7153 23.0154C23.7153 22.7162 23.9578 22.4737 24.257 22.4737Z"
                fill="black"
            />
            <rect x="2.26855" y="2.5" width="34" height="34" rx="17" stroke="#F5F5F5" strokeWidth="4" />
            <path
                d="M26.5748 32.2827L26.9349 32.6566L27.295 32.2827L32.6284 26.7448L32.9624 26.3979L32.6284 26.0511L31.4425 24.8197L31.0822 24.4456L30.7221 24.8199L26.9352 28.756L25.8147 27.5892L25.4546 27.2142L25.0939 27.5887L23.9081 28.82L23.5741 29.1669L23.9081 29.5137L26.5748 32.2827ZM22.1016 28.4999C22.1016 25.0942 24.8625 22.3333 28.2682 22.3333C31.674 22.3333 34.4349 25.0942 34.4349 28.4999C34.4349 31.9057 31.674 34.6666 28.2682 34.6666C24.8625 34.6666 22.1016 31.9057 22.1016 28.4999Z"
                fill="#079992"
                stroke="#079992"
            />
        </svg>
    );
};

export default MatchedSuccessIcon;
