import { useEffect, useRef, useState } from 'react';

import { Box, CircularProgress, Skeleton, SxProps, Tooltip } from '@mui/material';

import { StyledLinearProgress } from 'components/modules/connectors/matching/MatchingProgressBar';
import { Button } from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import { Icon } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';

export type JobProgressStatus = 'init' | 'processing' | 'finished';
export type JobProgressProps = {
    title?: React.ReactNode;
    initMessage?: string;
    determinateProcessMessage?: string;
    indeterminateProcessMessage?: string;
    status?: JobProgressStatus;
    progress?: number | null;
    total?: number | null;
    disableProgressCounter?: boolean;
    /** Instead of showing the job in `init` status with an indeterminate bar, show it as determinate progress. */
    showInitAsProgress?: boolean;
    /** Show fake linear progress that goes further and further, but does never quite reach the 100%. */
    fakeIndeterminateProgress?: boolean;
    /** Set this to make the progress be relative to this time (process init time).
     * If it is not provided, the component instantiation time is used instead. */
    fakeIndeterminateStartTime?: number;
    /** Set this to time in minutes in which the fake progress will reach 95%. */
    fakeIndeterminateLength?: number;
    sx?: SxProps;
    onCancel?: () => void;
};

// This can be set to any number.
const fakeTotal = 100;
// This value makes the progress bar approximately reach 95% in fakeIndeterminateLength minutes.
const fakeLengthRatio = 31000;
// The interval to update the fake progress. It controls the smoothness of the progress.
const fakeUpdateFrequency = 200;
// This makes sure the fake progress always has a visible cap.
const cappedFakeTotal = fakeTotal * 0.99;

// This uses the logistics function to asymptotically approach the cappedfakeTotal, but never reach it.
const getFakeProgress = (startTime: number, length: number) => {
    return Math.tanh((Date.now() - startTime) / fakeLengthRatio / length) * cappedFakeTotal;
};

export const JobProgress: React.FC<JobProgressProps> = ({
    title,
    initMessage = 'Getting started…',
    determinateProcessMessage = 'processed',
    indeterminateProcessMessage = 'processing…',
    status = 'init',
    progress,
    total,
    disableProgressCounter,
    showInitAsProgress,
    fakeIndeterminateProgress = false,
    fakeIndeterminateStartTime,
    fakeIndeterminateLength = 5,
    sx,
    onCancel,
}) => {
    const [fakeProgress, setFakeProgress] = useState(0);
    const initTime = useRef(Date.now());
    const fakeStartTime = fakeIndeterminateStartTime ?? initTime.current;

    const barVariant =
        (progress == null && !fakeIndeterminateProgress) || (status === 'init' && !showInitAsProgress)
            ? 'indeterminate'
            : 'determinate';
    const shownProgress = fakeIndeterminateProgress && progress == null ? fakeProgress : progress;
    const shownTotal = fakeIndeterminateProgress && progress == null ? fakeTotal : total;

    useEffect(() => {
        if (fakeIndeterminateProgress) {
            const h = setInterval(() => {
                setFakeProgress(getFakeProgress(fakeStartTime, fakeIndeterminateLength));
            }, fakeUpdateFrequency);
            return () => clearInterval(h);
        }
    }, [fakeIndeterminateProgress, fakeIndeterminateLength, fakeStartTime]);

    return (
        <Frame sx={sx} elevation={0} border padding="large">
            {title && (
                <Typography variant="h5" color="dark">
                    {title}
                </Typography>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                {status === 'init' && (
                    <Typography variant="body2" color="dark">
                        {initMessage}
                    </Typography>
                )}
                {status === 'processing' && !disableProgressCounter && (
                    <>
                        <CircularProgress size={20} />
                        {progress == null ? (
                            <Typography variant="body2" color="dark">
                                {indeterminateProcessMessage}
                            </Typography>
                        ) : (
                            <Typography variant="body2" color="dark">
                                <Typography variant="inherit" weight="semibold" component="span">
                                    {progress}
                                </Typography>
                                /
                                {total != null ? (
                                    total
                                ) : (
                                    <Skeleton width={30} component="span" sx={{ display: 'inline-block' }} />
                                )}{' '}
                                {determinateProcessMessage}
                            </Typography>
                        )}
                    </>
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, height: 40 }}>
                <StyledLinearProgress
                    value={shownTotal && shownProgress != null ? (shownProgress / shownTotal) * 100 : 0}
                    sx={{ flexGrow: 1 }}
                    variant={barVariant}
                />
                {!!onCancel && (
                    <Tooltip title="Cancel analysis">
                        <Button
                            variant="flat"
                            startIcon={<Icon type="OffClose" color="grey.400" />}
                            sx={{ borderRadius: '50%' }}
                            onClick={onCancel}
                        />
                    </Tooltip>
                )}
            </Box>
        </Frame>
    );
};

export default JobProgress;
