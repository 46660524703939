import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Link02 = (props) => (
    <SvgIcon {...props}>
        <path d="M8.465 20.535C6.44284 20.535 4.61978 19.3169 3.8458 17.4487C3.07182 15.5805 3.49933 13.4301 4.929 12L7.051 9.87799L8.465 11.292L6.344 13.413C5.58606 14.1709 5.29005 15.2756 5.56748 16.311C5.8449 17.3464 6.65361 18.1551 7.68898 18.4325C8.72434 18.7099 9.82906 18.4139 10.587 17.656L12.708 15.535L14.122 16.95L12.001 19.071C11.0651 20.0115 9.79185 20.5387 8.465 20.535ZM9.172 16.242L7.758 14.828L14.829 7.75699L16.243 9.17099L9.173 16.241L9.172 16.242ZM16.951 14.121L15.536 12.707L17.657 10.586C18.4253 9.83032 18.7292 8.72065 18.4532 7.67897C18.1773 6.63729 17.3638 5.82364 16.3222 5.54743C15.2806 5.27123 14.1708 5.57491 13.415 6.34299L11.293 8.46399L9.879 7.04999L12.001 4.92799C13.9561 2.98996 17.11 2.99686 19.0565 4.94344C21.0031 6.89002 21.01 10.0439 19.072 11.999L16.951 14.12V14.121Z" />
    </SvgIcon>
);

export default Link02;
