import React from 'react';

import { Box } from '@mui/material';
import { useIntl, FormatNumberOptions } from 'react-intl';

import Typography from 'components/tokens/Typography';

export type BigNumberProps = {
    value: number;
    options?: FormatNumberOptions;
    label?: string;
    height?: number;
    icon?: React.ReactElement;
};

export const BigNumber: React.VFC<BigNumberProps> = ({ value, options, label, height = 180, icon }) => {
    const intl = useIntl();

    return (
        <Box
            sx={{
                height,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {icon}
                <Typography variant="number" textAlign="center">
                    {intl.formatNumber(value, options)}
                </Typography>
            </Box>
            <Typography sx={{ marginTop: 1.5 }} variant="tiny" color="subtle" textAlign="center">
                {label}
            </Typography>
        </Box>
    );
};

export default BigNumber;
