import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

import { Icon, IconType } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';

import Button, { ButtonProps } from '../Button';
import BannerDetails, { BannerDetailsProps } from './BannerDetails';

export type FeedbackBannerSeverity = 'info' | 'warning' | 'error' | 'success';

const icons: Record<FeedbackBannerSeverity, IconType> = {
    info: 'InfoCircle',
    warning: 'Warning',
    error: 'Error',
    success: 'CircleCheck',
};

const iconColors: Record<FeedbackBannerSeverity, string> = {
    info: 'info.main',
    warning: 'warning.main',
    error: 'error.main',
    success: 'success.main',
};

const progressColors: Record<FeedbackBannerSeverity, CircularProgressProps['color']> = {
    info: 'info',
    warning: 'warning',
    error: 'error',
    success: 'success',
};

const colors: Record<FeedbackBannerSeverity, string> = {
    info: 'info.light',
    warning: 'warning.light',
    error: 'error.light',
    success: 'success.light',
};

export type FeedbackBannerProps = {
    'data-testid'?: string;
    title?: React.ReactNode;
    label: React.ReactNode;
    severity?: FeedbackBannerSeverity;
    loading?: boolean;
    action?: React.ReactNode;
    secondaryAction?: React.ReactNode;
    actionButtonProps?: ButtonProps;
    secondaryActionButtonProps?: ButtonProps;
    onActionClick?: ButtonProps['onClick'];
    onSecondaryActionClick?: ButtonProps['onClick'];
    onDismiss?: ButtonProps['onClick'];
} & BannerDetailsProps;

export const FeedbackBanner: React.FC<FeedbackBannerProps> = ({
    title,
    label,
    'data-testid': dataTestId,
    details,
    severity = 'info',
    detailsStyle = 'text',
    loading,
    action,
    secondaryAction,
    actionButtonProps,
    secondaryActionButtonProps,
    onActionClick,
    onSecondaryActionClick,
    onDismiss,
}) => {
    const icon = loading ? (
        <CircularProgress size={16} color={progressColors[severity]} />
    ) : (
        <Icon type={icons[severity]} color={iconColors[severity]} sx={{ fontSize: 16 }} />
    );

    return (
        <Box
            data-testid={dataTestId}
            sx={{
                display: 'flex',
                justifyContent: 'stretch',
                gap: 1,
                backgroundColor: colors[severity],
                borderRadius: 2,
                padding: 1.5,
                paddingY: title ? 2 : 1,
                marginBottom: 2,
            }}
        >
            <Box sx={{ paddingTop: title ? '2px' : '8px' }}>{icon}</Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                {title && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body1" weight="bold">
                            {title}
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        columnGap: 2,
                    }}
                >
                    <Typography variant="body2" sx={{ paddingY: '6px', width: title ? '100%' : undefined }}>
                        {label}
                    </Typography>

                    {details && <BannerDetails details={details} detailsStyle={detailsStyle} />}
                    {(action || secondaryAction) && (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            {action && typeof action === 'string' ? (
                                <Button
                                    variant={secondaryAction ? 'primary' : 'flat'}
                                    size="small"
                                    onClick={onActionClick}
                                    sx={{ marginLeft: !secondaryAction ? '-8px' : 0 }}
                                    {...actionButtonProps}
                                >
                                    {action}
                                </Button>
                            ) : (
                                action
                            )}
                            {secondaryAction && typeof secondaryAction === 'string' ? (
                                <Button
                                    variant="flat"
                                    size="small"
                                    onClick={onSecondaryActionClick}
                                    {...secondaryActionButtonProps}
                                >
                                    {secondaryAction}
                                </Button>
                            ) : (
                                secondaryAction
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {onDismiss && (
                <Box sx={{ width: 32 }}>
                    <Button variant="flat" size="small" startIcon={<Icon type="CloseBig" />} onClick={onDismiss} />
                </Box>
            )}
        </Box>
    );
};

export default FeedbackBanner;
