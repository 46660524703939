import { useSocialNetworks } from 'api/profileData';
import { organizationProfileType } from 'api/types/Organization';
import SocialNetworkFeeds from 'components/features/SocialNetwork/Feeds';
import SocialNetworkHeader from 'components/features/SocialNetwork/Header';
import { getSocialNetworkFeeds, useSocialNetworksHeaderFromDomainData } from 'components/features/SocialNetwork/utils';

import { TabContent, OrganizationTabProps } from '..';

export const SocialNetworkTab: React.FC<OrganizationTabProps> = ({ data, businessId }) => {
    const { data: socialNetworksData, isLoading } = useSocialNetworks({
        type: organizationProfileType,
        key: businessId,
    });

    const socialNetworks = useSocialNetworksHeaderFromDomainData(socialNetworksData);

    return (
        <TabContent>
            <SocialNetworkHeader
                skeleton={isLoading}
                socialNetworks={socialNetworks}
                social_media_index={socialNetworksData?.indexes?.social_media_index}
            />
            <SocialNetworkFeeds {...getSocialNetworkFeeds(socialNetworks)} />
        </TabContent>
    );
};

export default SocialNetworkTab;
