import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExportDynamics: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <mask
            id="mask0_3570_312"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="2"
            width="16"
            height="20"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7007 6.50617C18.5299 6.8023 19.0834 7.5877 19.0834 8.46813L19.0833 11.0959L17.1667 9.30005V13.5928C17.0128 13.6425 16.84 13.7018 16.6513 13.7721C15.8817 14.0587 14.8395 14.5304 13.7337 15.2755C11.8764 16.527 9.84614 18.5478 8.6374 21.7206C8.40836 21.5753 8.25 21.3202 8.25 21.0166V14.4577C8.25 14.1078 8.46865 13.7951 8.79733 13.675L14.9167 11.4391V9.7979C14.9167 8.93036 14.3791 8.15363 13.5671 7.84807L12.1467 7.31353C11.8743 7.21099 11.5833 7.41238 11.5833 7.70351V11.4723C11.5833 12.0485 11.0124 12.4509 10.4697 12.257L5.05306 10.3225C4.7214 10.2041 4.5 9.8899 4.5 9.53774V2.97417C4.5 2.3979 5.07092 1.99557 5.6136 2.18939L17.7007 6.50617Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_3570_312)">
            <path
                d="M4.5 1.79175L19.0834 7.00004V14.151C19.0834 14.7273 18.5125 15.1296 17.9698 14.9359L14.9167 13.8458V9.79677C14.9167 8.92883 14.3786 8.15181 13.5661 7.84654L12.1465 7.31319C11.8741 7.21083 11.5833 7.41221 11.5833 7.70323V12.6548L4.5 10.1251V1.79175Z"
                fill="url(#paint0_linear_3570_312)"
            />
            <g filter="url(#filter0_f_3570_312)">
                <path
                    d="M19.0833 8.54175C19.0833 9.41662 18.5367 10.1998 17.715 10.5001L8.25 13.9584V22.2918L19.0833 18.3334V8.54175Z"
                    fill="black"
                    fillOpacity="0.24"
                />
            </g>
            <g filter="url(#filter1_f_3570_312)">
                <path
                    d="M19.0833 8.875C19.0833 9.74988 18.5367 10.5331 17.715 10.8333L8.25 14.2917V22.625L19.0833 18.6667V8.875Z"
                    fill="black"
                    fillOpacity="0.32"
                />
            </g>
            <path
                d="M19.0833 8.45825C19.0833 9.33313 18.5367 10.1163 17.715 10.4166L8.25 13.8749V22.2083L19.0833 18.2499V8.45825Z"
                fill="url(#paint1_linear_3570_312)"
            />
            <path
                opacity="0.5"
                d="M19.0833 8.45825C19.0833 9.33313 18.5367 10.1163 17.715 10.4166L8.25 13.8749V22.2083L19.0833 18.2499V8.45825Z"
                fill="url(#paint2_linear_3570_312)"
            />
            <path
                opacity="0.5"
                d="M14.9169 11.4419L11.5817 12.662L11.5817 17.5491C11.5817 17.8402 11.8727 18.0416 12.1451 17.9391L13.5675 17.4037C14.3794 17.0981 14.9169 16.3214 14.9169 15.4539V11.4419Z"
                fill="#B0ADFF"
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 23.953C10.8908 15.8157 18.1667 14.3449 18.1667 14.3449V11.6001L24 17.0659L18.1667 22.5802V19.8354C18.1667 19.8354 13.025 19.6946 9 23.953Z"
            fill="#0A0A0A"
        />
        <defs>
            <filter
                id="filter0_f_3570_312"
                x="7.45"
                y="7.74175"
                width="12.4334"
                height="15.35"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_3570_312" />
            </filter>
            <filter
                id="filter1_f_3570_312"
                x="0.25"
                y="0.875"
                width="26.8334"
                height="29.75"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_3570_312" />
            </filter>
            <linearGradient
                id="paint0_linear_3570_312"
                x1="9.92606"
                y1="1.79175"
                x2="13.8039"
                y2="11.9424"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0B53CE" />
                <stop offset="1" stopColor="#7252AA" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3570_312"
                x1="15.362"
                y1="21.4775"
                x2="15.362"
                y2="9.37811"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2266E3" />
                <stop offset="1" stopColor="#AE7FE2" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_3570_312"
                x1="19.0833"
                y1="13.8303"
                x2="14.9326"
                y2="13.8303"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#94B9FF" />
                <stop offset="0.287843" stopColor="#94B9FF" stopOpacity="0.523646" />
                <stop offset="1" stopColor="#538FFF" stopOpacity="0" />
            </linearGradient>
        </defs>
    </SvgIcon>
);

export default ExportDynamics;
