import React from 'react';

import Typography from 'components/tokens/Typography';
import { formatDate } from 'utilities/date';

import { CellProps } from './TableComponent';

const VainuDateCell: React.FC<CellProps> = ({ row }) => {
    return (
        <Typography variant="body2" color="dark">
            {formatDate(row.vainu_date, 'long-datetime')}
        </Typography>
    );
};

export default VainuDateCell;
