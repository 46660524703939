import React from 'react';

import { Star } from '@mui/icons-material';

import DynamicsAccountSvg from '../svg/CrmObjects/DynamicsAccountSvg';
import DynamicsAnnotationSvg from '../svg/CrmObjects/DynamicsAnnotationSvg';
import DynamicsContactSvg from '../svg/CrmObjects/DynamicsContactSvg';
import DynamicsLeadSvg from '../svg/CrmObjects/DynamicsLeadSvg';
import DynamicsOpportunitySvg from '../svg/CrmObjects/DynamicsOpportunitySvg';
import DynamicsTaskSvg from '../svg/CrmObjects/DynamicsTaskSvg';
import HubspotCompanySvg from '../svg/CrmObjects/HubspotCompanySvg';
import HubspotContactSvg from '../svg/CrmObjects/HubspotContactSvg';
import HubspotDealSvg from '../svg/CrmObjects/HubspotDealSvg';
import HubspotNoteSvg from '../svg/CrmObjects/HubspotNoteSvg';
import HubspotTaskSvg from '../svg/CrmObjects/HubspotTaskSvg';
import PipedriveActivitySvg from '../svg/CrmObjects/PipedriveActivitySvg';
import PipedriveContactSvg from '../svg/CrmObjects/PipedriveContactSvg';
import PipedriveDealSvg from '../svg/CrmObjects/PipedriveDealSvg';
import PipedriveLeadSvg from '../svg/CrmObjects/PipedriveLeadSvg';
import PipedriveNoteSvg from '../svg/CrmObjects/PipedriveNoteSvg';
import PipedriveOrganizationSvg from '../svg/CrmObjects/PipedriveOrganizationSvg';
import SalesforceAccountSvg from '../svg/CrmObjects/SalesforceAccountSvg';
import SalesforceContactSvg from '../svg/CrmObjects/SalesforceContactSvg';
import SalesforceLeadSvg from '../svg/CrmObjects/SalesforceLeadSvg';
import SalesforceOpportunitySvg from '../svg/CrmObjects/SalesforceOpportunitySvg';
import SalesforceTaskSvg from '../svg/CrmObjects/SalesforceTaskSvg';
import { CrmName, CRMObject } from '../types/Crm';

type ObjectSvgIconProps = {
    className?: string;
    crm: CrmName;
    object: CRMObject;
    style?: React.CSSProperties;
    color?: string;
};

const objectIconComponent = {
    hubspot: {
        Company: HubspotCompanySvg,
        Contact: HubspotContactSvg,
        Deal: HubspotDealSvg,
        Task: HubspotTaskSvg,
        Note: HubspotNoteSvg,
    },
    salesforce: {
        Account: SalesforceAccountSvg,
        Contact: SalesforceContactSvg,
        Lead: SalesforceLeadSvg,
        Task: SalesforceTaskSvg,
        Opportunity: SalesforceOpportunitySvg,
    },
    salesforcesandbox: {
        Account: SalesforceAccountSvg,
        Contact: SalesforceContactSvg,
        Lead: SalesforceLeadSvg,
        Task: SalesforceTaskSvg,
        Opportunity: SalesforceOpportunitySvg,
    },
    pipedrive: {
        organization: PipedriveOrganizationSvg,
        person: PipedriveContactSvg,
        deal: PipedriveDealSvg,
        lead: PipedriveLeadSvg,
        note: PipedriveNoteSvg,
        activity: PipedriveActivitySvg,
    },
    dynamics: {
        accounts: DynamicsAccountSvg,
        contacts: DynamicsContactSvg,
        tasks: DynamicsTaskSvg,
        leads: DynamicsLeadSvg,
        opportunities: DynamicsOpportunitySvg,
        annotations: DynamicsAnnotationSvg,
    },
};

const ObjectSvgIcon: React.FC<ObjectSvgIconProps> = React.forwardRef(
    ({ className, crm, object, color, ...props }, ref) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const IconComponent = (objectIconComponent as any)[crm]?.[object];
        if (!IconComponent) return <Star {...props} style={{ width: '20px', height: '20px' }} />;

        return <IconComponent ref={ref} color={color} className={className} {...props} />;
    },
);

export default ObjectSvgIcon;
