// A file for all the short global, lodash-like utilities

/**
 * Returns true if the object is a non-null array with at least one row.
 */
export function isNotEmpty<T>(array: T[] | unknown): array is T[] {
    return Boolean(array && Array.isArray(array) && array.length > 0);
}

/**
 * A utility type to make all fields in an object nullable, in addition to being undefined. Compounds Partial<> with null.
 */
export type Nullable<T> = Partial<{ [K in keyof T]: T[K] | null }>;

/**
 * A utility type to convert all fields of an object to non-nullable. Inverse of Nullable<>.
 */
export type NonNullableObject<T> = Required<{
    [P in keyof T]: NonNullable<T[P]>;
}>;

/**
 * A utility type to convert some of the nullable fields to non-nullable.
 */
export type Ensure<T, K extends keyof T> = T & NonNullableObject<Pick<T, K>>;

/**
 * Returns true if the value is null or undefined.
 */
export function isNil<T>(value: T | undefined | null): value is undefined | null {
    return value == null;
}

/**
 * Returns true if the value is not null or undefined.
 * Useful for filtering out nulls from arrays with type predicates, as filter<Type>(Boolean) does not work with TypeScript.
 */
export function isNotNil<T>(value: T | undefined | null): value is T {
    return value != null;
}

/**
 * Returns true if the object contains at least one non-null field.
 * @param recurse If a field is an object, recursively check its field for non-null fields.
 */
export function isNotEmptyObject<T>(object: T | undefined | null, recurse = false): object is T {
    return Boolean(
        object &&
            typeof object === 'object' &&
            Object.values(object).some(
                (value) => !isNil(value) && (!recurse || typeof value !== 'object' || isNotEmptyObject(value)),
            ),
    );
}

/**
 * Returns the variable array if it is an array, and an empty array otherwise.
 */
export function assertArray<T>(array: T[] | undefined | null): T[] {
    return Array.isArray(array) ? array : [];
}

/**
 * Checks the provided string to be valid and not contains 'undefined' or 'null' values.
 * The list can be extended if necessary.
 * @param value
 * @returns boolean
 */
export function isValidString(value: string) {
    if (!value || ['undefined', 'null'].includes(value)) {
        return false;
    }
    return true;
}

export function replaceItemAtIndex<T>(arr: T[], index: number, newValue: T) {
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

/**
 * Use to assert that all the switch cases are handled. Produces a TypeScript error if not.
 */
export function assertUnreachable(_switchParam: never, message?: string) {
    throw new Error(message ?? 'Unhandled switch case reached.');
}

export type ConstArrayValuesType<T extends ReadonlyArray<unknown>> =
    T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export const toLower = (value: string) => {
    try {
        return value.toLowerCase();
    } catch (err) {
        return '';
    }
};

export const makeUniqueName = (name: string, existingNames: string[]): string => {
    /**
     * add (copy) -> (copy 2) -> (copy 3) to name until name not in existingNames
     */
    if (existingNames.includes(name)) {
        if (name.endsWith('(copy)')) {
            return makeUniqueName(name.replace('(copy)', '(copy 2)'), existingNames);
        }
        const re = new RegExp(/\(copy (\d+)/);
        const match = name.match(re);
        const num = match?.[1] ? parseInt(match?.[1]) + 1 : 0;
        const untilIndex = match?.index ? match?.index - 1 : undefined;
        const nameSuggestion = num > 0 ? `${name.slice(0, untilIndex)} (copy ${num})` : `${name} (copy)`;
        return makeUniqueName(nameSuggestion, existingNames);
    }
    return name;
};

/**
 * Creates a range array of integers between the two parameters (end non-inclusive),
 * or from 0 up to the first parameter (when second parameter is omitted).
 * E.g. range(2, 5) => [2, 3, 4]. range(4) => [0, 1, 2, 3].
 * Also accepts negative values and descending order, e.g. range(2, -3) => [2, 1, 0, -1, -2].
 */
export const range = (a: number, b?: number): number[] => {
    const start = b === undefined ? 0 : a;
    const end = b === undefined ? a : b;
    const length = Math.abs(end - start);
    const direction = Math.sign(end - start);
    return Array.from({ length }, (_value, index) => start + index * direction);
};

/**
 * This will filter out the array of objects based on search keyword which will search
 * the value of property passed in `searchFields` parameter. The `searchFields` must be
 * an array of string.
 * @param items - array of objects to search from
 * @param filterValue - search keyword
 * @param searchFields - array of property of object to search from. Current implementation
 * assumes that the value of the properties are `string` type only. Can be extended to include
 * other type if necessary
 * @returns filtered array
 */
export const filterArrayBySearch = <T, K extends keyof T>(items: T[], filterValue: string, searchFields: K[]) => {
    const filteredItemsByFields: T[] = [];
    items.forEach((item) => {
        if (filterValue) {
            const lower_case_filter = filterValue.toLowerCase();
            const search_in = searchFields.map((field) => item[field]);
            if (
                !search_in.some((value) => typeof value === 'string' && value.toLowerCase().includes(lower_case_filter))
            ) {
                return;
            }
        }
        filteredItemsByFields.push(item);
    });
    return filteredItemsByFields;
};

/**
 * Use this function to get the business id in the non-universal format, which some API endpoints require as a key.
 * In this legacy format, FI and SE business ids don't have the country prefix. For the other countries, the
 * business ID must already be in the country-prefixed format; this function does not add it.
 */
export function getLegacyBusinessId(businessId: string): string {
    if (businessId.startsWith('FI') || businessId.startsWith('SE')) {
        return businessId.substring(2);
    }
    return businessId;
}

const prefixCountries = ['FI', 'SE', 'NO', 'DK'];

/**
 * Use this function to get the business id in the universal format, i.e. a format where it
 * always contains a country prefix. It only adds the prefix for the databases we have.
 * If the business ID already contains the country prefix, this function does not add or
 * change it, regardless of the country.
 */
export function getCountryPrefixBusinessId(businessId: string, country: string): string {
    if (prefixCountries.includes(businessId.substring(0, 2))) {
        return businessId;
    }
    if (prefixCountries.includes(country)) {
        return `${country}${businessId}`;
    }
    return businessId;
}

/**
 * @param keys - all fields of the object to create
 * @returns Object with empty array as value for provided fields
 * @example
 * ```
 * const technology = initializeOptions('crm', 'marketing', 'cms', 'advertising')
 * // console.log(technology)
 * // returns
 * // {
 * //   crm: [],
 * //   marketing: [],
 * //   cms: [],
 * //   advertising: []
 * // }
 * ```
 */
export const initializeObjectWithArrayValues = <K extends PropertyKey>(...keys: K[]) => {
    return Object.fromEntries(keys.map((k) => [k, []])) as { [P in K]: [] };
};

/**
 * @param values array of numbers in string form, i.e. '01', '02'
 * @example
 * // returns ['10', '11', '12']
 * getValues(['10', '12'])
 */
export const getValues = (values: string[]) => {
    return range(parseInt(values[0]), parseInt(values[1]) + 1).map((v) => {
        return v.toString();
    });
};

export const localeCompareEmptyLast = (a: string, b: string) => {
    if (a === '') {
        if (b === '') {
            return 0;
        }
        return 1;
    }
    if (b === '') {
        return -1;
    }
    return a.localeCompare(b);
};

/**
 * Returns a number, when given either a number or a string containing a parsable floating-point number.
 */
export const makeNumber = ((value: number | string | undefined): number | undefined =>
    typeof value === 'string' ? parseFloat(value) : value) as ((value: number | string) => number) &
    ((value: number | string | undefined) => number | undefined);

/**
 * Tests if the error variable is of Error type or similar with attribute .message and returns it.
 * Otherwise just coerces the variable into string.
 */
export const getErrorMessage = (error: unknown): string => {
    if (error && typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
        return error.message;
    }
    return `${error}`;
};

/**
 * Returns the given singular form string, if the count parameter is exactly 1.
 * Otherwise returns the singular string appended with 's', or the plural form string, if given one.
 * Example: `pluralize(3, 'item')` returns `'items'`; `pluralize(2, 'mouse', 'mice')` returns `'mice'`
 */
export function pluralize(count: number, singular: string): string;
export function pluralize(count: number, singular: string, plural: string): string;
export function pluralize(count: number, singular: string, plural?: string): string {
    if (plural) {
        return count === 1 ? singular : plural;
    }
    return `${singular}${count === 1 ? '' : 's'}`;
}

/**
 * Returns the count appended with the given singular string, if the count parameter is exactly 1.
 * Otherwise returns the count appended with the singular string and 's', or the plural form string, if given one.
 * Example: `pluralize(3, 'item')` returns `'3 items'`; `pluralize(2, 'mouse', 'mice')` returns `'2 mice'`
 */
export function pluralizeSuffix(count: number, singular: string): string;
export function pluralizeSuffix(count: number, singular: string, plural: string): string;
export function pluralizeSuffix(count: number, singular: string, plural?: string): string {
    if (plural) {
        return `${count.toLocaleString()} ${pluralize(count, singular, plural)}`;
    }
    return `${count.toLocaleString()} ${pluralize(count, singular)}`;
}

/**
 * Returns a non-secure 32-bit hash of the given input string.
 * @param base: If given, converts the output integer to a string of given base.
 */
export function simpleHash(string: string, base: number): string;
export function simpleHash(string: string): number;
export function simpleHash(string: string, base?: number) {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = hash * 31 + char;
        hash &= hash;
    }
    if (base != null) {
        return new Uint32Array([hash])[0].toString(base);
    }
    return new Uint32Array([hash])[0];
}
