import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const OpenInModal: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.001 2.99994L19.001 3C20.1056 3 21.001 3.89537 21.001 4.99994V12V15.9999V16C21.001 16.069 20.9975 16.1372 20.9907 16.2044C20.8883 17.213 20.0366 18 19.001 18H8.001C6.89643 18 6.001 17.1046 6.001 16V8.99994V5V4.99994C6.001 3.89537 6.89643 2.99994 8.001 2.99994ZM19.001 4.99994V12V15.9999L15.001 15.9999V16H8.001L8.001 8.99994L8.001 5H12.001V4.99994L19.001 4.99994ZM4 22H16V20H4V7.99997H2V20C2 21.1045 2.89543 22 4 22Z"
        />
    </SvgIcon>
);

export default OpenInModal;
