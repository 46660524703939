import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Export = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 20C4.44908 9.46027 13.873 7.5552 13.873 7.5552V4L21.4286 11.0795L13.873 18.2219V14.6667C13.873 14.6667 7.21333 14.4843 2 20Z"
        />
    </SvgIcon>
);

export default Export;
