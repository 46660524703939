import { FC, memo } from 'react';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FilterPayloadContacts, getFilteredContacts } from 'api/filterCompanies';
import { ContactsUpgradeButton, ContactsUpgradeDialog, EmployeesTableSectionBody } from 'components/features/Employees';
import SidebarHeader from 'components/templates/CompanyProfileSidebarCard/SidebarHeader';
import { useAxiosContext } from 'contexts/AxiosContext';
import { getContactGroups } from 'contexts/FilterCompaniesContext/utils';
import { useFilterState } from 'contexts/FilterContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import { CountryDatabase } from 'contexts/types/databases';
import { useOpen } from 'hooks/useOpen';

import { ListDialog } from '../common/ListDialog';

interface ContactsDialogProps {
    open: boolean;
    companyName: string;
    country: CountryDatabase;
    logo?: string;
    businessId: string;
    onClose: () => void;
}

export const ContactsDialog: FC<ContactsDialogProps> = memo(
    ({ open, companyName, logo, businessId, country, onClose }) => {
        const { hasProductPermission } = usePermissionContext();
        const hasContactDataPermission = hasProductPermission(Permission.ContactData);

        const { query } = useFilterState();
        const parsedQuery = JSON.parse(query ?? '');

        const contactGroups = getContactGroups(parsedQuery);

        const params: FilterPayloadContacts = {
            database: country,
            use_filters_v2: 'false',
            unwind_subdocument: 'contacts',
            unwind_subdocument_query: contactGroups,
            limit: 20,
            order: '-vainu_score',
            fields: ['name', 'contacts'],
            offset: 0,
            query: {
                '?ALL': [
                    {
                        '?EQ': {
                            business_id: businessId,
                        },
                    },
                ],
            },
        };

        const axios = useAxiosContext();
        const { data: contacts = [], isLoading } = useQuery({
            queryKey: ['contacts-per-company', businessId, query],
            queryFn: () => getFilteredContacts(axios, params),
            enabled: open,
        });

        const { isOpen: upgradeDialogOpen, close: closeUpgradeDialog, open: openUpgradeDialog } = useOpen();

        const isEmpty = contacts?.length === 0;
        return (
            <ListDialog
                sx={{ height: 'auto' }}
                open={open}
                onClose={onClose}
                maxWidth="lg"
                title={
                    <SidebarHeader
                        id={businessId}
                        country={country}
                        name={companyName}
                        profileType="organization"
                        logo={logo}
                    />
                }
            >
                <Box sx={{ height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' }}>
                    {!isEmpty && !hasContactDataPermission && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ContactsUpgradeButton sx={{ marginLeft: 1 }} onClick={openUpgradeDialog} />
                            <ContactsUpgradeDialog open={upgradeDialogOpen} onClose={closeUpgradeDialog} />
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '700px',
                            flexGrow: 1,
                            position: 'relative',
                        }}
                    >
                        <EmployeesTableSectionBody
                            hasContactDataPermission={hasContactDataPermission}
                            companyName={companyName}
                            businessId={businessId}
                            country={country}
                            skeleton={isLoading}
                            allEmployees={contacts}
                        />
                    </Box>
                </Box>
            </ListDialog>
        );
    },
);
