import React from 'react';

import { MenuItem, Tabs, Tab, Menu, Skeleton, Box } from '@mui/material';

import { TabChangeHandler } from 'components/modules/profiles/Profile';
import { TabSlug, isValidTabSlug } from 'components/modules/profiles/Profile/tabs';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';

import { ProfileTab } from './ProfileTabs';

export type TabsContentProps = {
    visibleTabs: readonly ProfileTab[];
    overflownTabs: readonly ProfileTab[];
    activeTab: TabSlug;
    /** This is used to hide the tabs that were revealed just for the overflow determination, to make them
     * reserve space, but render invisibly, to avoid flashing them when the container gets bigger. */
    visibleTabCount: number;
    /** This is used to force rendering of the overflow button when the tabs container is enlarged and the
     * overflow menu is momentarily empty while the overflow is still being calculated. */
    showOverflowButton?: boolean;
    moreButtonWidth: number;
    tabsRef: React.Ref<HTMLButtonElement>;
    onChange?: TabChangeHandler;
};

export const TabsContent: React.FC<TabsContentProps> = ({
    visibleTabs,
    overflownTabs,
    activeTab,
    visibleTabCount,
    moreButtonWidth,
    tabsRef,
    onChange,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const overflowMenuOpen = Boolean(anchorEl);
    const totalTabCount = visibleTabs.length + overflownTabs.length;

    const showTabFromDropdown = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTabSelectFromDropdown = (tabName: TabSlug) => {
        handleCloseDropdown();
        onChange?.(tabName);
    };

    const handleCloseDropdown = () => {
        setAnchorEl(null);
    };

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        isValidTabSlug(newValue) && onChange?.(newValue);
    };

    return (
        <>
            <Tabs ref={tabsRef} value={activeTab} onChange={handleTabChange}>
                {visibleTabs.map(({ name, label, skeleton }, index) => (
                    <Tab
                        key={name}
                        value={name}
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                {skeleton ? (
                                    <Skeleton variant="text" sx={{ color: 'transparent' }}>
                                        {label}
                                    </Skeleton>
                                ) : (
                                    label
                                )}
                                {/* This makes the label reserve horizontal space enough for the semibold label,
                                so that its width stays the same when it's selected and the visible label turns
                                from normal to semibold. */}
                                <Box sx={{ height: 0, color: 'transparent', fontWeight: 500 }}>{label}</Box>
                            </Box>
                        }
                        disabled={skeleton}
                        id={`profileTabs-${name}`}
                        sx={{
                            minHeight: 60,
                            paddingY: 1.5,
                            fontWeight: 400,
                            '&.Mui-selected': {
                                fontWeight: 500,
                            },
                            opacity: index >= visibleTabCount ? 0 : 1,
                        }}
                    />
                ))}
            </Tabs>
            <Button
                id="tabs-overflow-button"
                aria-controls="tabs-overflow-menu"
                aria-expanded={overflowMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={showTabFromDropdown}
                variant="flat"
                sx={{
                    minWidth: moreButtonWidth,
                    borderRadius: 0,
                    position: 'absolute',
                    zIndex: 1,
                    right: 0,
                    height: '100%',
                    visibility: visibleTabCount < totalTabCount ? 'visible' : 'hidden',
                }}
            >
                <Icon
                    type="MoreHorizontal"
                    sx={{ color: overflowMenuOpen ? 'inherit' : 'text.subtle', width: 18, height: 18 }}
                />
            </Button>
            <Menu
                id="tabs-overflow-menu"
                aria-labelledby="tabs-overflow-button"
                anchorEl={anchorEl}
                onClose={handleCloseDropdown}
                open={overflowMenuOpen}
                elevation={3}
                sx={{
                    '& .MuiMenu-list': {
                        padding: 1,
                        minWidth: 186,
                    },
                    visibility: overflownTabs.length ? 'visible' : 'hidden',
                }}
            >
                {overflownTabs.map((item) => (
                    <MenuItem
                        sx={{
                            paddingLeft: 1,
                            paddingRight: 1,
                            borderRadius: 1,
                            '&:hover': {
                                outline: '1px solid',
                                outlineColor: (theme) => theme.palette.border,
                                outlineOffset: -1,
                            },
                        }}
                        key={item.name}
                        onClick={() => handleTabSelectFromDropdown(item.name)}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
