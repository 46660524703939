import { useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { useAuthContext } from 'contexts/AuthContext';

const SsoHandler = () => {
    const { doSsoAuth } = useAuthContext();
    const navigate = useNavigate();
    const { provider } = useParams();

    useEffect(() => {
        const checkSsoAuth = async () => {
            const params = new URLSearchParams(document.location.search);
            const code = params.get('code');

            if (provider && code) {
                await doSsoAuth(provider, code);
            }

            navigate('/', { replace: true });
        };

        checkSsoAuth();
    }, [doSsoAuth, navigate, provider]);

    return (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />;
        </Box>
    );
};

export default SsoHandler;
