import SvgIcon from '@mui/material/SvgIcon';

const NotificationOutlineBellWithDot = (props) => (
    <SvgIcon
        {...props}
        sx={{
            height: '30px',
            'path:last-of-type': {
                color: 'brandColors.tertiaryBlue',
                transform: 'translate(0px, -5px) scale(1.2)',
            },
        }}
    >
        <path d="M12 22C10.8954 22 10 21.1046 10 20H14C14 21.1046 13.1046 22 12 22ZM20 19H4V17L6 16V10.5C6 7.038 7.421 4.793 10 4.18V2H14V4.18C16.579 4.792 18 7.036 18 10.5V16L20 17V19ZM12 5.75C10.7797 5.6712 9.60278 6.21728 8.875 7.2C8.25255 8.18456 7.94714 9.33638 8 10.5V17H16V10.5C16.0528 9.33639 15.7474 8.18458 15.125 7.2C14.3972 6.21728 13.2203 5.6712 12 5.75Z"></path>
        <path d="M17 7.99998C15.4097 7.99752 14.0977 6.75453 14.0093 5.16671C13.9209 3.57888 15.0869 2.19797 16.6671 2.01904C18.2473 1.8401 19.6926 2.92533 19.9615 4.49271C20.2304 6.0601 19.2295 7.56499 17.68 7.92298C17.457 7.97421 17.2288 8.00004 17 7.99998Z"></path>
    </SvgIcon>
);

export default NotificationOutlineBellWithDot;
