import { styled, alpha } from '@mui/material';
import MuiButton from '@mui/material/Button';

const StyledMuiButton = styled(MuiButton)(({ theme: { palette } }) => ({
    lineHeight: 1.5,
    '&.variant-primary': {
        '&:hover': {
            backgroundColor: alpha(palette.button.background, 0.8),
        },
        '&.Mui-disabled': {
            backgroundColor: alpha(palette.button.background, 0.3),
            color: palette.button.text,
        },
    },
    '&.variant-secondary': {
        padding: '5px 11px',
        '&:hover': {
            backgroundColor: palette.field.hoverDarken,
        },
        '&.Mui-disabled': {
            color: alpha(palette.button.background, 0.3),
            borderColor: alpha(palette.button.background, 0.3),
        },
    },
    '&.variant-tertiary': {
        textTransform: 'none',
        padding: '5px 11px',
        borderColor: palette.border,
        backgroundColor: palette.field.background,
        '&:hover': {
            backgroundColor: palette.field.hover,
            borderColor: palette.field.hover,
        },
        '&.Mui-outlinedPrimary': {
            '&:hover': {
                backgroundColor: palette.field.hover,
            },
        },
        '&.Mui-disabled': {
            color: alpha(palette.text.primary, 0.3),
            borderColor: palette.field.background,
            backgroundColor: palette.field.background,
        },
        '&.MuiButton-sizeLarge': {
            padding: '5px 15px',
        },
        '&.MuiButton-sizeSmall': {
            padding: '5px 7px',
        },
    },
    '&.variant-flat': {
        '&:hover': {
            backgroundColor: palette.field.hoverDarken,
            borderColor: palette.field.hoverDarken,
        },
        '&.Mui-outlinedPrimary': {
            '&:hover': {
                backgroundColor: palette.field.hover,
            },
        },
        '&.Mui-disabled': {
            color: alpha(palette.text.primary, 0.3),
        },
    },
    '&.variant-critical': {
        backgroundColor: palette.critical,
        '&:hover': {
            backgroundColor: alpha(palette.critical, 0.8),
        },
        '&.Mui-disabled': {
            backgroundColor: alpha(palette.critical, 0.3),
            color: palette.button.text,
        },
    },
    '&.noLabel .MuiButton-startIcon': {
        marginRight: -4,
    },
    '&.MuiButton-sizeSmall.noLabel .MuiButton-startIcon': {
        marginRight: -2,
    },
    overflow: 'hidden',
    '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export default StyledMuiButton;
