import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TrashFull = (props) => (
    <SvgIcon {...props}>
        <path d="M17 22H7C5.89543 22 5 21.1046 5 20V7H3V5H7V4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V5H21V7H19V20C19 21.1046 18.1046 22 17 22ZM7 7V20H17V7H7ZM9 4V5H15V4H9ZM15 18H13V9H15V18ZM11 18H9V9H11V18Z" />
    </SvgIcon>
);

export default TrashFull;
