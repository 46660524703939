import { ClusterProvider } from 'components/features/clustering/contexts/ClusterContext';
import { TransformProvider } from 'components/features/clustering/contexts/TransformContext';
import { ZoomAndCenterProvider } from 'components/features/clustering/contexts/ZoomAndCenterContext';
import { CompanyProfilePopupWrapper } from 'components/features/CRMCompanies/CompanyProfilePopupWrapper';
import { CompanyProfileProvider } from 'components/features/CRMCompanies/contexts/CompanyProfileContext';

import FilterResultsCompanies from './FilterResultsCompanies';

export const FilterResultsClusterProvider = () => {
    return (
        <CompanyProfileProvider viewMode="popup">
            <ClusterProvider>
                <TransformProvider>
                    <ZoomAndCenterProvider>
                        <FilterResultsCompanies />
                        <CompanyProfilePopupWrapper />
                    </ZoomAndCenterProvider>
                </TransformProvider>
            </ClusterProvider>
        </CompanyProfileProvider>
    );
};
export default FilterResultsClusterProvider;
