import { FilterOperator, GeoOperatorValue } from 'api/types/FilterOperators';

import { FilterConfig, FilterID, OperatorValue } from '../../../FilterTypes';

export type FieldValue = { operator: OperatorValue; geoJSON: GeoOperatorValue; isInverseLayer?: boolean };

export const FIELD = 'visiting_coordinates';

export type TValues = { [FIELD]: GeoOperatorValue };

export const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.coordinates,
    label: 'Map',
    defaultOperator: FilterOperator.GEO_WITHIN_BOX,
    initValue: {
        [FilterOperator.ALL]: [
            // the include/exclude blocks can be in either order
            // included areas
            {
                [FilterOperator.ANY]: [
                    {
                        [FilterOperator.GEO_WITHIN_BOX]: {
                            [FIELD]: [],
                        },
                    },
                ],
            },
            // excluded areas
            // {
            //     [FilterOperator.ALL]: [
            //         {
            //             [FilterOperator.NOT]: {
            //                 [FilterOperator.GEO_WITHIN_BOX]: {
            //                     [FIELD]: [],
            //                 },
            //             },
            //         },
            //     ],
            // },
        ],
    },
};

export const EARTH_RADIUS_IN_METER = 6378100;
