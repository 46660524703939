import NewVehicles from 'components/features/NewVehicles/NewVehicles';

import { OrganizationTabProps, TabContent } from '..';

export const VehiclesTab: React.FC<OrganizationTabProps> = ({ businessId }) => {
    return (
        <TabContent>
            <NewVehicles businessId={businessId} />
        </TabContent>
    );
};

export default VehiclesTab;
