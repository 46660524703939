import { Box } from '@mui/material';

export type AppLayoutProps = {
    superheader?: React.ReactNode;
    navigation?: React.ReactNode;
    header?: React.ReactNode;
    scrollRef: React.RefObject<HTMLDivElement>;
    children?: React.ReactNode;
};

export const AppLayout: React.FC<AppLayoutProps> = ({ superheader, navigation, header, scrollRef, children }) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateAreas: `
                    "superheader superheader"
                    "navigation header"
                    "navigation content"
                `,
                gridTemplateRows: superheader ? '48px 64px 1fr' : '0 64px 1fr',
                gridTemplateColumns: '64px 1fr',
                width: '100vw',
                height: '100vh',
            }}
        >
            {!!superheader && <Box sx={{ gridArea: 'superheader' }}>{superheader}</Box>}
            <Box
                sx={{
                    gridArea: 'navigation',
                    borderRight: '1px solid',
                    borderColor: 'border',
                    backgroundColor: 'background.paper',
                }}
            >
                {navigation}
            </Box>
            <Box sx={{ gridArea: 'header', borderBottom: '1px solid', borderColor: 'border' }}>{header}</Box>
            <Box sx={{ gridArea: 'content', overflow: 'scroll' }} ref={scrollRef} id="scroll-container">
                {children}
            </Box>
        </Box>
    );
};

export default AppLayout;
