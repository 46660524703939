import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DynamicsSvgAlt: React.VFC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_4686_3452)">
                <mask
                    id="mask0_4686_3452"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="4"
                    y="0"
                    width="20"
                    height="28"
                >
                    <path
                        d="M23.4445 9.29084C23.4445 8.11693 22.7065 7.06973 21.6009 6.6749L5.48481 0.919185C4.76122 0.660758 4 1.1972 4 1.96557V10.717C4 11.1865 4.29519 11.6054 4.73742 11.7634L11.9596 14.3427C12.6832 14.6011 13.4444 14.0647 13.4444 13.2963V8.27134C13.4444 7.88318 13.8324 7.61465 14.1957 7.75137L16.0895 8.46409C17.1721 8.87151 17.8889 9.90715 17.8889 11.0639V13.2521L9.72978 16.2333C9.29153 16.3935 9 16.8103 9 17.277V26.0222C9 26.794 9.76753 27.3307 10.4924 27.0658L21.62 22.9999C22.7156 22.5996 23.4444 21.5573 23.4444 20.3908L23.4445 9.29084Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_4686_3452)">
                    <path
                        d="M4 0.388901L23.4445 7.33329V16.8679C23.4445 17.6363 22.6834 18.1727 21.9598 17.9144L17.8889 16.4609V11.0623C17.8889 9.90501 17.1714 8.86898 16.0881 8.46196L14.1954 7.75082C13.8321 7.61435 13.4444 7.88285 13.4444 8.27087V14.873L4 11.5V0.388901Z"
                        fill="url(#paint0_linear_4686_3452)"
                    />
                    <g filter="url(#filter0_f_4686_3452)">
                        <path
                            d="M23.4444 9.38884C23.4444 10.5553 22.7156 11.5996 21.62 12L9 16.6111V27.7222L23.4444 22.4444V9.38884Z"
                            fill="black"
                            fillOpacity="0.24"
                        />
                    </g>
                    <g filter="url(#filter1_f_4686_3452)">
                        <path
                            d="M23.4444 9.83328C23.4444 10.9998 22.7156 12.044 21.62 12.4444L9 17.0555V28.1666L23.4444 22.8888V9.83328Z"
                            fill="black"
                            fillOpacity="0.32"
                        />
                    </g>
                    <path
                        d="M23.4444 9.27774C23.4444 10.4442 22.7156 11.4885 21.62 11.8888L9 16.5V27.6111L23.4444 22.3333V9.27774Z"
                        fill="url(#paint1_linear_4686_3452)"
                    />
                    <path
                        opacity="0.5"
                        d="M23.4444 9.27774C23.4444 10.4442 22.7156 11.4885 21.62 11.8888L9 16.5V27.6111L23.4444 22.3333V9.27774Z"
                        fill="url(#paint2_linear_4686_3452)"
                    />
                    <path
                        opacity="0.5"
                        d="M17.8892 13.2556L13.4423 14.8825L13.4423 21.3986C13.4423 21.7868 13.8303 22.0553 14.1935 21.9185L16.09 21.2047C17.1725 20.7972 17.8892 19.7616 17.8892 18.605V13.2556Z"
                        fill="#B0ADFF"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_f_4686_3452"
                    x="8.2"
                    y="8.58884"
                    width="16.0445"
                    height="19.9334"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_4686_3452" />
                </filter>
                <filter
                    id="filter1_f_4686_3452"
                    x="1"
                    y="1.83328"
                    width="30.4445"
                    height="34.3334"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_4686_3452" />
                </filter>
                <linearGradient
                    id="paint0_linear_4686_3452"
                    x1="11.2348"
                    y1="0.388901"
                    x2="16.4051"
                    y2="13.9232"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0B53CE" />
                    <stop offset="1" stopColor="#7252AA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_4686_3452"
                    x1="18.4827"
                    y1="26.6367"
                    x2="18.4827"
                    y2="10.5042"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2266E3" />
                    <stop offset="1" stopColor="#AE7FE2" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_4686_3452"
                    x1="23.4444"
                    y1="16.4405"
                    x2="17.9101"
                    y2="16.4405"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#94B9FF" />
                    <stop offset="0.287843" stopColor="#94B9FF" stopOpacity="0.523646" />
                    <stop offset="1" stopColor="#538FFF" stopOpacity="0" />
                </linearGradient>
                <clipPath id="clip0_4686_3452">
                    <rect width="26.6667" height="26.6667" fill="white" transform="translate(0.666687 0.666672)" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export default DynamicsSvgAlt;
