import React from 'react';

import { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Button from 'components/tokens/Button';

import { DialogCloseButton } from './DialogCloseButton';
import { StyledMuiDialog, StyledMuiDialogTitle, StyledMuiDialogText, StyledMuiDialogActions } from './styled';

export interface DialogProps extends Omit<MuiDialogProps, 'title' | 'onClose' | 'onSubmit'> {
    title?: React.ReactNode;
    message?: string;
    isSubmitDisabled?: boolean;
    onSubmit?: (event: React.MouseEvent) => void;
    onClose: (event: React.MouseEvent) => void;
    confirmLabel?: string;
    cancelLabel?: string;
    /**
     * Can be used when default element for `confirmLabel` is not enough. e.g. you want to
     * customize the confirm action button or add another button to the actions. When used,
     * you don't need to pass `onSubmit` prop as it can be handled inside `customizedConfirm`.
     */
    customizedConfirm?: React.ReactNode;
    customizedCancel?: React.ReactNode;
}

export const Dialog: React.FC<DialogProps> = ({
    title,
    message,
    isSubmitDisabled,
    onSubmit,
    onClose,
    open,
    confirmLabel,
    cancelLabel = 'Close',
    children,
    customizedConfirm,
    customizedCancel,
    ...props
}) => {
    return (
        <StyledMuiDialog open={open} onClose={onClose} {...props}>
            <DialogCloseButton onClick={onClose} />
            {title && <StyledMuiDialogTitle variant="h4">{title}</StyledMuiDialogTitle>}

            <DialogContent>
                {message && <StyledMuiDialogText>{message}</StyledMuiDialogText>}
                {children}
            </DialogContent>

            <StyledMuiDialogActions>
                {customizedCancel || (
                    <Button variant="flat" onClick={onClose}>
                        {cancelLabel}
                    </Button>
                )}
                {customizedConfirm ||
                    (confirmLabel && (
                        <Button data-testid="submit_button" autoFocus disabled={isSubmitDisabled} onClick={onSubmit}>
                            {confirmLabel}
                        </Button>
                    ))}
            </StyledMuiDialogActions>
        </StyledMuiDialog>
    );
};

export default Dialog;
