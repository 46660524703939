import { FC } from 'react';

import Dialog, { DialogProps } from 'components/tokens/Dialog';

export const ListDialog: FC<DialogProps> = (props) => {
    return (
        <Dialog
            PaperProps={{
                sx: {
                    height: '100%',
                    '@media (min-height: 640px)': {
                        maxHeight: 'calc(100% - 96px)',
                    },
                    '@media (min-height: 800px)': {
                        maxHeight: '80%',
                    },
                },
            }}
            {...props}
        />
    );
};
