import { useEffect, useRef } from 'react';

export type ThrottleOptions = Partial<{
    /** Is the throttle making newest throttled call after the throttle window closes. Default: true. */
    trailingCall: boolean;
}>;

const defaultOptions: ThrottleOptions = {
    trailingCall: true,
};

/**
 * Creates a function that only calls the provided callback on the set interval.
 *
 * Note: The callback function cannot access the newest props when called, unless you provide them as arguments
 * to the function itself. Otherwise it can only access the prop values as they were when the throttle callback
 * was created (the first time the useThrottle() is called).
 * @param callbackFn
 * @param interval ms, required
 * @param options trailingCall: whether to call the function one last time after the interval window closes.
 * @returns the function you may call as many times as you like; it'll throttle the calls to the callback.
 */
export const useThrottle = <F extends Function>(callbackFn: F, interval: number, options?: ThrottleOptions): F => {
    const lastExec = useRef(0);
    const newestCall = useRef<(() => unknown) | null>(null);
    const timeout = useRef(0);
    const throttledFn = useRef<F | null>(null);

    if (!throttledFn.current) {
        const { trailingCall } = { ...defaultOptions, ...options };

        throttledFn.current = ((...args: unknown[]) => {
            const now = Date.now();
            if (now > lastExec.current + interval) {
                lastExec.current = now;
                callbackFn(...args);
                newestCall.current = null;
                window.clearTimeout(timeout.current);
                timeout.current = 0;
            } else if (trailingCall) {
                newestCall.current = () => callbackFn(...args);
            }
            if (!timeout.current && trailingCall) {
                // This ensures the function is once more called with the newest params at the end of the interval window.
                timeout.current = window.setTimeout(() => {
                    if (newestCall.current) {
                        lastExec.current = Date.now();
                        newestCall.current();
                        timeout.current = 0;
                    }
                }, interval);
            }
        }) as unknown as F;
    }

    useEffect(() => {
        return () => {
            window.clearTimeout(timeout.current);
        };
    }, []);

    return throttledFn.current;
};

export default useThrottle;
