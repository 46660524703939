import { memo } from 'react';

import { Box, Skeleton, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { isNil, isNotEmpty } from 'utilities';

import { TechnologyData, WebsiteData } from 'api/types/Organization';
import { TabContentGridSection } from 'components/modules/profiles/Profile/tabs';
import TabContentGridColumn from 'components/modules/profiles/Profile/tabs/TabContentGridColumn';
import { ProgressChart } from 'components/templates/charts';
import HeaderBox from 'components/templates/HeaderBox';
import Frame from 'components/tokens/Frame';
import Typography from 'components/tokens/Typography';

import { getWebsiteLanguages, formatLanguages, formatCountries } from '../utils';
import WebsitePreview from './WebsitePreview';
import WebsiteRanking, { hasRanking } from './WebsiteRanking';

export interface WebProfileHeaderProps {
    technology_data?: TechnologyData[];
    website_data?: WebsiteData;
    countries?: string[];
    digitalization_index?: number;
    website?: string;
    updatedDate?: string;
    skeleton?: boolean;
}

export const WebProfileHeader: React.VFC<WebProfileHeaderProps> = memo(
    ({ technology_data, website_data, countries, digitalization_index, website, updatedDate, skeleton = false }) => {
        const intl = useIntl();
        const theme = useTheme();

        if (skeleton) {
            return (
                <TabContentGridSection columns={3}>
                    <div>
                        <Skeleton variant="rounded" height={320} sx={{ backgroundColor: 'grey.100' }} />
                    </div>
                    <div>
                        <Skeleton variant="rounded" height={320} sx={{ backgroundColor: 'grey.100' }} />
                    </div>
                    <TabContentGridColumn>
                        <HeaderBox skeleton />
                        <HeaderBox skeleton />
                        <HeaderBox skeleton />
                    </TabContentGridColumn>
                </TabContentGridSection>
            );
        }

        const languages = technology_data ? getWebsiteLanguages(technology_data) : null;
        const ranking = website_data?.website_traffic_data?.ranking;

        return (
            <TabContentGridSection columns={3}>
                {(website_data || website) && (
                    <WebsitePreview website_data={website_data} website={website} updatedDate={updatedDate} />
                )}

                {!isNil(digitalization_index) && (
                    <Frame title="Digitalization index" sx={{ height: 320 }}>
                        <ProgressChart
                            value={digitalization_index * 10}
                            total={10}
                            height={176}
                            color={theme.palette.brandColors.orange}
                            labelAsNumber
                            formatOptions={{ maximumFractionDigits: 0, notation: 'standard' }}
                            hideTooltips
                        />
                        <Typography variant="tiny" color="subtle" paragraph sx={{ marginTop: 2 }}>
                            Digitalization index is calculated based on the{' '}
                            <Box
                                component="a"
                                target="_blank"
                                href="https://help.vainu.app/en/articles/145624-what-are-vainu-indices"
                                sx={{ color: 'unset', '&:hover': { color: 'text.strong' } }}
                            >
                                following
                            </Box>
                            .
                        </Typography>
                    </Frame>
                )}
                {(isNotEmpty(countries) || isNotEmpty(languages)) && (
                    <TabContentGridColumn>
                        {hasRanking(ranking) && <WebsiteRanking ranking={ranking} />}
                        {isNotEmpty(languages) && (
                            <HeaderBox
                                title="Website languages"
                                label={formatLanguages(languages, intl)}
                                number={languages.length}
                                multiline
                            />
                        )}
                        {isNotEmpty(countries) && (
                            <HeaderBox
                                title="Countries with operations"
                                label={formatCountries(countries, intl)}
                                number={countries.length}
                                multiline
                            />
                        )}
                    </TabContentGridColumn>
                )}
            </TabContentGridSection>
        );
    },
);

export default WebProfileHeader;
