import { Contact } from 'api/types/company';
import { MessageQueue } from 'api/types/MessageQueue';
import { TrackingLogic } from 'api/types/Trigger';
import { LeadPreview } from 'contexts/TriggerContext';

const generateMessageQueueTitle = (mq: MessageQueue): string => {
    if (mq.message_type === 'trigger_new_prospects') {
        return 'New company in source list';
    }
    const title = mq?.context?.title;
    if (title) {
        return title;
    }
    switch (mq.message_type) {
        case 'trigger_scoring':
            return 'Scoring change';
        case 'trigger_track_prospect_data_changes':
            return 'Data change';
        default:
            return '';
    }
};

export const pickMessageQueueType = (trackChanges: string[], trackingLogic: TrackingLogic) => {
    const validTypes = [];
    if (trackChanges.includes('scoring_change')) {
        validTypes.push('trigger_scoring');
    }
    if (trackChanges.includes('new_company')) {
        validTypes.push('trigger_new_prospects');
    }
    if (Object.keys(trackingLogic)?.length) {
        validTypes.push('trigger_track_prospect_data_changes');
    }
    return validTypes;
};

export const formatMessageQueueAsLeads = (
    mqs: MessageQueue[],
    messageTypes: string[],
    selectedContacts?: {
        [id: string]: Partial<Contact>;
    },
): LeadPreview[] => {
    return mqs
        .filter((mq) => messageTypes.includes(mq.message_type))
        .map((mq) => {
            const isDomainData = mq.relation.db === 'GLOBAL';
            const company = {
                id: 0,
                company_name: mq.context?.company_name,
                logo: mq.context?.company_logo,
                ...(isDomainData ? { domain: mq.relation.key } : { business_id: mq.relation.key }),
            };
            const id = mq.id || Math.random().toString();

            return {
                id,
                companies: [company],
                company,
                contact: selectedContacts?.[id] || {},
                delivered: mq.state === 'delivered',
                isValid: true,
                lead: {
                    title: generateMessageQueueTitle(mq),
                    tags: [],
                    vainu_date: mq.created,
                    link: `${window.location.origin}/${isDomainData ? 'domain' : 'company'}/${mq.relation.key}/`,
                },
                messages: mqs.filter((_mq) => _mq.relation.key === mq.relation.key),
                tags: [],
                vainu_date: mq.created || '',
                preview: undefined,
            };
        });
};
