import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HubspotNoteSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M3.35282 10.1251C3.35282 8.13969 3.35282 6.15431 3.35282 4.16893C3.35282 2.80583 4.39528 1.70943 5.76538 1.6798C7.52268 1.65016 9.30976 1.6798 11.0968 1.6798C11.5734 1.6798 11.9308 1.88722 12.2584 2.21318C13.5094 3.48738 14.7901 4.76158 16.0709 6.00615C16.4879 6.421 16.6666 6.86549 16.6666 7.45814C16.6666 10.214 16.6666 12.9402 16.6666 15.696C16.6666 17.4739 15.8028 18.3333 14.0455 18.3333C11.4245 18.3333 8.77363 18.3333 6.15258 18.3333C4.09743 18.3333 3.3826 17.5925 3.3826 15.6071C3.32303 13.7699 3.32303 11.9327 3.35282 10.1251ZM4.69313 10.1251C4.69313 11.9623 4.72291 13.7995 4.69313 15.6367C4.69313 16.4961 5.02076 16.9702 5.88452 16.9702C8.6545 16.9702 11.3947 16.9998 14.1647 16.9702C14.7901 16.9702 15.2071 16.6739 15.2071 15.9331C15.1773 13.4143 15.1773 10.8955 15.2071 8.34712C15.2071 7.75447 14.9689 7.60631 14.4327 7.57668C10.9181 7.42851 10.8883 7.42851 10.7692 3.93187C10.7394 3.30959 10.5011 3.04289 9.87567 3.04289C8.68428 3.04289 7.46311 3.04289 6.27172 3.04289C5.19947 3.04289 4.69313 3.54665 4.72291 4.61342C4.69313 6.45064 4.69313 8.28786 4.69313 10.1251Z" />
    </SvgIcon>
);
export default HubspotNoteSvg;
