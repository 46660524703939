import { memo } from 'react';

import { GroupStructureRoot } from 'api/types/groupStructure';
import CountryFlag from 'components/features/Locations/CountryFlag';
import { TabContentGridSection } from 'components/modules/profiles/Profile/tabs';
import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import HeaderBox from 'components/templates/HeaderBox';
import Icon from 'components/tokens/Icon';

import { getCountries } from '../utils';

export type GroupStructureHeaderProps = {
    group_structure?: GroupStructureRoot[];
    skeleton?: boolean;
};

export const GroupStructureHeader: React.VFC<GroupStructureHeaderProps> = memo(
    ({ group_structure, skeleton = false }) => {
        if (skeleton) {
            return (
                <TabContentGridSection columns={3}>
                    <HeaderBox skeleton />
                    <HeaderBox skeleton />
                    <HeaderBox skeleton />
                </TabContentGridSection>
            );
        }

        if (!group_structure) {
            return null;
        }

        const uniqueCountries = getCountries(group_structure);
        const groupRoot = group_structure[0];
        const parent = groupRoot.daughters?.[0];

        const subsidiaryCount = groupRoot.subsidiaries;
        const groupLogo = groupRoot.logo ?? parent?.logo;

        return (
            <TabContentGridSection columns={3}>
                {parent && (
                    <HeaderBox
                        title={parent.company_name}
                        label="Group parent company"
                        icon={<CompanyLogo size="tiny" border logo={groupLogo} company_name={parent.company_name} />}
                    />
                )}
                <HeaderBox
                    title="Countries"
                    label={uniqueCountries.map((country) => (
                        <CountryFlag key={country} country={country} />
                    ))}
                    icon={<Icon type="Globe" />}
                    number={uniqueCountries.length}
                />
                <HeaderBox title="Subsidiaries" icon={<Icon type="Profiles" />} number={subsidiaryCount} />
            </TabContentGridSection>
        );
    },
);

export default GroupStructureHeader;
