import React from 'react';

import { TechnologyData } from 'api/types/Organization';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';

import { formatTechnologyTypes } from '../../utils';
import { DescriptionCell } from './DescriptionCell';
import { TechnologyNameCell } from './TechnologyNameCell';

export interface WebProfileTableProps {
    technology_data: TechnologyData[];
}

const tableMaxHeight = 41 + 10 * 48; // Header + max 10 rows + one border

export const WebProfileTable: React.VFC<WebProfileTableProps> = ({ technology_data }) => {
    if (!technology_data || !Array.isArray(technology_data)) {
        return null;
    }

    return (
        <SortableTable<TechnologyData>
            data={technology_data}
            defaultSortColumn="types"
            rowKeyField="name"
            // virtualized
            tableHeight={tableMaxHeight}
        >
            <SortableTableColumn<TechnologyData> width="20%" label="Technology" field="name" sx={{ fontWeight: '500' }}>
                {({ name, logo_url, types }) =>
                    name ? <TechnologyNameCell name={name} logo_url={logo_url} type={types?.[0]} /> : ''
                }
            </SortableTableColumn>
            <SortableTableColumn<TechnologyData> width="50%" label="Description" field="description">
                {({ description }) => (description ? <DescriptionCell description={description} /> : '-')}
            </SortableTableColumn>
            <SortableTableColumn<TechnologyData> width="30%" label="Type" field="types">
                {({ types }) => (types ? formatTechnologyTypes(types) : '')}
            </SortableTableColumn>
        </SortableTable>
    );
};
export default WebProfileTable;
