import { Box, SxProps } from '@mui/material';

export type PageGridSidebarColumnProps = {
    sx?: SxProps;
    hiding?: boolean;
    children?: React.ReactNode;
};

export const PageGridSidebarColumn: React.FC<PageGridSidebarColumnProps> = ({ children, hiding = false, sx }) => (
    <Box
        sx={{
            gridColumn: hiding
                ? 1
                : {
                      xs: '1 / 5',
                      profileSidebar: 1,
                  },
            display: hiding
                ? {
                      xs: 'none',
                      profileSidebar: 'block',
                  }
                : undefined,
            ...sx,
        }}
    >
        {children}
    </Box>
);

export default PageGridSidebarColumn;
