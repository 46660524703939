import { memo, useState } from 'react';

import { Box, Skeleton } from '@mui/material';
import { getYear, parseISO } from 'date-fns';
import { isNotEmpty } from 'utilities';

import PDFViewer from 'components/features/PDFViewer';
import Frame from 'components/tokens/Frame';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';

import { getLatestValidStatement } from '../Charts/utilities';
import { ValidFinancialStatement } from '../types';
import AvailableStatements from './AvailableStatements';
import LastStatement from './LastStatement';
import StatementTable from './Table';
import { getSortedStatements } from './utils';

export interface FinancialStatementsProps {
    name?: string;
    statements?: ValidFinancialStatement[];
    skeleton?: boolean;
}

export const FinancialStatements: React.FC<FinancialStatementsProps> = memo(
    ({ name, statements, skeleton = false }) => {
        const { hasProductPermission } = usePermissionContext();
        const [openStatement, setOpenStatement] = useState<ValidFinancialStatement>();

        if (skeleton) {
            return (
                <Frame title="Financial statements">
                    <AvailableStatements skeleton />
                    <Skeleton variant="rectangular" width="100%" height={240} sx={{ marginTop: 5 }} />
                </Frame>
            );
        }

        if (!isNotEmpty(statements)) {
            return null;
        }

        const lastFiscalYear = getLatestValidStatement(statements);
        const hasPDFPermission =
            hasProductPermission(Permission.FinancialPdfView) ||
            hasProductPermission(Permission.FinancialPdfViewAndKeywordFilter);
        const canDownloadPdf = hasProductPermission(Permission.FinancialPdfDownload);
        const upgradeEmail = 'customersuccess@vainu.com';
        const statementsWithPdf = getSortedStatements(statements, undefined, 'desc', true);
        const previewUrl = openStatement?.artifact?.preview_url ?? undefined;
        const downloadUrl = (canDownloadPdf ? openStatement?.artifact?.download_url : undefined) ?? undefined;
        const year = openStatement?.accounting_period.end
            ? getYear(parseISO(openStatement?.accounting_period.end))
            : '';
        const downloadFilename = `${name ?? 'unknown company'} financial statement${year ? ` ${year}` : ''}.pdf`;

        const handleStatementClick = (statement: ValidFinancialStatement) => {
            if (statement.artifact?.file_type === 'pdf' && !!statement.artifact.preview_url) {
                setOpenStatement(statement);
            }
        };

        const handleStatementClose = () => {
            setOpenStatement(undefined);
        };

        return (
            <Frame title="Financial statements">
                {lastFiscalYear && <LastStatement statement={lastFiscalYear} />}
                {hasPDFPermission && statementsWithPdf.length > 0 && (
                    <>
                        <Typography sx={{ marginTop: 3 }} variant="body1" weight="bold" color="dark">
                            Available Statements
                        </Typography>
                        <Typography component="div" sx={{ marginBottom: 2 }} variant="tiny" color="subtle">
                            {canDownloadPdf ? (
                                <>To see their complete financial health and earnings and download, open a statement.</>
                            ) : (
                                <>
                                    To see their complete financial health and earnings, open a statement. For a
                                    permission to <strong>download</strong> statements, please{' '}
                                    <Link href={`mailto:${upgradeEmail}`}>contact us</Link>.
                                </>
                            )}
                        </Typography>
                        <AvailableStatements statementsWithPdf={statementsWithPdf} onClick={handleStatementClick} />
                        <PDFViewer
                            url={previewUrl}
                            downloadUrl={downloadUrl}
                            downloadFilename={downloadFilename}
                            onClose={handleStatementClose}
                        />
                    </>
                )}
                <Box sx={{ marginTop: 5 }}>
                    <StatementTable statements={statements} />
                </Box>
            </Frame>
        );
    },
);

export default FinancialStatements;
