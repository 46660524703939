import { AxiosInstance } from 'axios';

import { Database } from 'contexts/ListsContext';

import { IndustryValues } from './types/MainIndustry';

export async function getIndustries(axios: AxiosInstance, country: Database | undefined, language: string) {
    if (!country || country === 'DOMAIN_DATA_BASIC') {
        return [];
    }
    return (await axios.get<IndustryValues>(`/mapi/industries_filtervalues/${country}/${language}/`)).data;
}
