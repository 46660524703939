import React from 'react';

import { FilterOperator } from 'api/types/FilterOperators';
import { Values } from 'components/templates/RangeSelect';

import { FilterConfig, FilterDefinition, FilterID, FilterProps, GroupValues } from '../../FilterTypes';
import { useSimpleRangeFilter } from '../common/useSimpleRangeFilter';
import FilterTemplate from '../FilterTemplate';
import { getDefaultRange, makeFieldType, rangeValueToText } from '../utils';

const FIELD = makeFieldType(FilterID.net_income);

const M = 1000000;
const K = 1000;
const positiveRange = [50 * M, 10 * M, 5 * M, 500 * K, 100 * K, 50 * K, 40 * K, 30 * K, 20 * K, 10 * K];
const negativeRange = positiveRange.map((r) => r * -1);
const rangeOptions = [-Infinity, ...negativeRange, 0, ...positiveRange.reverse(), Infinity];
const defaultRange = getDefaultRange(rangeOptions);

export type TValues = { [FIELD]: Values };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.net_income,
    label: 'Net income (earnings)',
    defaultOperator: FilterOperator.RANGE,
    initValue: {
        [FilterOperator.RANGE]: {
            [FIELD]: defaultRange,
        },
    },
};

/**
 * **This filter should only be selected once.**
 */
export const netIncomeFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.financial,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <NetIncome {...props} />,
        valueToText: rangeValueToText(config.fields[0], rangeOptions),
        config,
        createFilter: () => config.initValue,
    },
};

export const NetIncome: React.VFC<FilterProps<TValues>> = ({ filter, uuid, groupUUID, removeFilter }) => {
    const props = useSimpleRangeFilter({
        filter,
        uuid,
        groupUUID,
        removeFilter,
        config,
        rangeOptions,
        defaultRange,
    });

    return <FilterTemplate {...props} />;
};
