import React from 'react';

import { cloneDeep, isArray } from 'lodash';

import { FilterOperator } from 'api/types/FilterOperators';
import { SelectOption } from 'components/tokens/select-components/Select';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';

import { getFilterConfig, TValuesType } from '../../FiltersBaseConfig';
import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    LocationFilterProps,
    MappedFilter,
} from '../../FilterTypes';
import { ANY_OPTION, LocationSelectOption, isSelectOption } from '../../groups/groups/LocationGroup/utils/helpers';
import { useOperatorFromFilter } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, valueArrayToText } from '../utils';
import { LocationTypeSelect, useLocationTypeValue } from './LocationTypeSelect';
import { useLocationFilterValue } from './useLocationFilterValue';

const { baseConfig, getInitValueConfigDefault } = getFilterConfig(FilterID.location_type);
export type TValues = TValuesType<typeof baseConfig, string[] | string | undefined>;

const config: FilterConfig<TValues> = {
    ...baseConfig,
    ...getInitValueConfigDefault<TValues>(FilterOperator.IN, []),
};

export const locationTypeFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.location_visiting_address,
        label: config.label,
        fields: config.fields,
        render: (props: LocationFilterProps<TValues>) => <LocationType {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => cloneDeep(config.initValue),
    },
};

const operators: Operator[] = [FilterOperator.IN].map((value) => formatOperatorValue(value as OperatorValue));

const LocationType: React.FC<LocationFilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    warningLevel,
    removeFilter,
    apply,
    filterOption,
}) => {
    const { operator, handleOperatorUpdate } = useOperatorFromFilter(filter, ({ key, value, valuePath }) => {
        apply({ key, value, valuePath });
    });

    const { locationTypeOptions } = useFilterOptionsContext();

    const getFilteredOptions = () => {
        const mergedOption = [ANY_OPTION, ...locationTypeOptions];

        return mergedOption.filter(filterOption);
    };

    const filteredOptions = getFilteredOptions();

    const { value: valueRaw, onChange } = useLocationFilterValue({ filter, apply });

    const values = isArray(valueRaw) ? valueRaw : valueRaw ? [valueRaw] : [];

    const selectedOption = locationTypeOptions.filter((item) => values?.includes?.(item.value));

    const { value, onValueChange, isMultiple } = useLocationTypeValue({
        initialValue: selectedOption,
        onValueChange: (options: LocationSelectOption) => {
            if (isSelectOption(options)) {
                if (options.value === ANY_OPTION.value) {
                    onChange([]);
                    return;
                }
                onChange([options.value]);
                return;
            }

            onChange((options as SelectOption[]).map((o) => o.value));
        },
    });

    const disableAnyOption = !filteredOptions.some((o) => o.value === ANY_OPTION.value);

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        operatorDisabled: true,
        updateOperator: handleOperatorUpdate,
        isLocationTypeFilter: true,
        children: (
            <LocationTypeSelect
                options={filteredOptions}
                value={value}
                autoHighlight
                onValueChange={onValueChange}
                multiple={isMultiple}
                width="100%"
                disableAnyOption={disableAnyOption}
                warningLevel={warningLevel}
            />
        ),
    };
    return <FilterTemplate {...props} key={uuid} />;
};

export function convertLegacyValues(filter: MappedFilter) {
    if ('?EQ' in filter.value) {
        const value = filter.value['?EQ'] as { types: string };
        const convertedValue = { '?IN': { types: [value.types] } };
        const convertedNode = { ...filter, value: convertedValue };
        convertedNode.valuePath = convertedNode.valuePath.replace('?EQ', '?IN');

        return convertedNode as MappedFilter;
    }
    return filter;
}
