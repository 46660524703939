import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { cloneDeep, concat, difference, filter, has, intersection, isArray, keys, orderBy, sortBy, uniq } from 'lodash';
import { initializeObjectWithArrayValues, isNil } from 'utilities';

import { getIndustries } from 'api/industries';
import { IndustryValues, OfficialIndustries } from 'api/types/MainIndustry';
import { SelectOption } from 'components/tokens/select-components/Select';
import { industryRootItemsWithDirectChildren } from 'utilities/profiles/constants';

import { useAuthContext } from '../AuthContext';
import { useAxiosContext } from '../AxiosContext';
import { Database, useListsContext } from '../ListsContext';

export type IndustryContextValue = {
    industries: OfficialIndustries;
    industryWithSubtree: IndustryWithSubtree;
    industryRootItemsWithDirectChildren: { [key: string]: string[] };
    getMainIndustry: (country: string, industry_code?: string) => string;
    getAllRelatedOptionsForSelectedIndustries: (selectedValue: string[]) => IndustryValues;
    getSelectedIndustriesLabel: (selectedValue: string[]) => string[];
};

export const IndustryContext = createContext<IndustryContextValue>({} as IndustryContextValue);

type Industry = SelectOption & { subtree: string[] };

type IndustryWithSubtree = {
    [key: string]: Industry;
};

type IndustryProviderProps = {
    children: React.ReactNode;
};

export const IndustryProvider: React.FC<IndustryProviderProps> = ({ children }) => {
    const { Provider } = IndustryContext;
    const [officialIndustries, setOfficialIndustries] = useState<OfficialIndustries>({} as OfficialIndustries);
    const [industryWithSubtree, setIndustryWithSubtree] = useState<IndustryWithSubtree>({} as IndustryWithSubtree);
    const axios = useAxiosContext();
    const { database } = useListsContext();
    const { authStatus } = useAuthContext();

    const { data: industries = [] } = useQuery({
        queryKey: [`official-industries-${database}`],
        queryFn: () => getIndustries(axios, database, 'en'),
        refetchOnWindowFocus: false,
        enabled: authStatus === 'logged-in',
    });

    useEffect(() => {
        if (database && industries.length) {
            const newIndustries: OfficialIndustries = initializeObjectWithArrayValues(
                'FI',
                'NO',
                'DK',
                'SE',
                'DOMAIN_DATA_BASIC',
            );
            const newIndustriesWithSubtree: IndustryWithSubtree = {};
            for (const industry of industries) {
                const industryCode = industry[0];
                const industryLabel = industry[1];

                if (industryCode.startsWith('00')) {
                    continue;
                }
                const tempIndustry = {
                    label: industryLabel,
                    value: industryCode,
                    subtree: [],
                };

                newIndustries[database]?.push(industry);
                newIndustriesWithSubtree[industryCode] = tempIndustry;
            }
            addMainIndustrySubOptions(newIndustriesWithSubtree, industryRootItemsWithDirectChildren);
            addIndustrySubOptions(newIndustriesWithSubtree);
            fillOrphans(newIndustriesWithSubtree, industryRootItemsWithDirectChildren);
            setIndustryWithSubtree(newIndustriesWithSubtree);
            newIndustries[database] = sortBy(newIndustries[database], [
                function (o) {
                    return o[0];
                },
            ]);
            setOfficialIndustries(newIndustries);
        }
    }, [database, industries]);

    const getMainIndustry = useCallback(
        (country: string, industryCodeAlphabet?: string) => {
            if (officialIndustries && officialIndustries[country as Database]) {
                const foundCountryIndustryCodes = officialIndustries[country as Database];

                for (let index = 0; index < foundCountryIndustryCodes.length; index++) {
                    if (foundCountryIndustryCodes[index][0] === industryCodeAlphabet) {
                        return foundCountryIndustryCodes[index][1];
                    }
                }
            }
            return '';
        },
        [officialIndustries],
    );

    const getSubTreeCodes = useCallback(
        (industryCode: string) => {
            const industryCodes: string[] = [];
            if (industryWithSubtree?.[industryCode]?.subtree?.length) {
                industryCodes.push(...industryWithSubtree[industryCode].subtree);
                for (const code of industryWithSubtree[industryCode].subtree) {
                    industryCodes.push(...getSubTreeCodes(code));
                }
            }
            return industryCodes;
        },
        [industryWithSubtree],
    );

    const getSelectedIndustriesLabel = useCallback(
        (selectedIndustries: string[]) => {
            const selectedIndustriesLabel: string[] = [];
            selectedIndustries.forEach((selected) => {
                const selectedIndustry = industryWithSubtree[selected] as Industry | undefined;
                // The industry may not be found. In that case, it should be ignored.
                // Example: 'NO' list contains 'DK' industries inside the filter
                // if a customer has made a list in 'DK' and then changed to list to 'NO'
                selectedIndustry && selectedIndustriesLabel.push(selectedIndustry.label);
            });
            return selectedIndustriesLabel;
        },
        [industryWithSubtree],
    );

    const getAllRelatedOptionsForSelectedIndustries = useCallback(
        (selectedIndustries: string[]) => {
            const industryCodes: string[] = [];
            const relatedOptions: IndustryValues = [];

            // causes issue if second level is missing in case one of the item is selected
            // so find which top level selected industry is in
            const selectedIndustriesCopy = cloneDeep(selectedIndustries);
            selectedIndustries.forEach((selected) => {
                for (const secondaryCodes of Object.values(industryRootItemsWithDirectChildren)) {
                    const found = secondaryCodes.includes(selected);
                    if (found) {
                        selectedIndustriesCopy.push(...secondaryCodes);
                    }
                }
            });
            const uniqSelectedIndustries = uniq(selectedIndustriesCopy);

            // this should include all the missing industries to iterate over
            // which should be removed after tree as been constructed
            uniqSelectedIndustries.forEach((selected) => {
                // In case the selected industry is top level i.e. A,B,C
                if (selected.length === 1) {
                    industryCodes.push(selected, ...getSubTreeCodes(selected));
                } else {
                    for (let industryCodeLength = selected.length; industryCodeLength > 1; industryCodeLength--) {
                        const industryCode = selected.substring(0, industryCodeLength);
                        if (industryCodeLength === selected.length) {
                            industryCodes.push(...getSubTreeCodes(industryCode));
                        }
                        if (industryWithSubtree[industryCode]?.value) {
                            industryCodes.push(industryCode);
                        }
                        if (industryCodeLength === 2) {
                            const mainIndustryCode = Object.keys(industryWithSubtree).find((k) =>
                                industryWithSubtree[k].subtree.includes(industryCode),
                            );
                            if (mainIndustryCode) {
                                industryCodes.push(mainIndustryCode);
                            }
                        }
                    }
                }
            });
            if (database) {
                for (const industry of officialIndustries[database]) {
                    if (industryCodes.includes(industry[0])) {
                        relatedOptions.push(industry);
                    }
                }
            }
            return relatedOptions;
        },
        [database, getSubTreeCodes, industryWithSubtree, officialIndustries],
    );

    const value = useMemo(
        () => ({
            industries: officialIndustries,
            industryWithSubtree,
            industryRootItemsWithDirectChildren,
            getMainIndustry,
            getAllRelatedOptionsForSelectedIndustries,
            getSelectedIndustriesLabel,
        }),
        [
            getAllRelatedOptionsForSelectedIndustries,
            getMainIndustry,
            getSelectedIndustriesLabel,
            industryWithSubtree,
            officialIndustries,
        ],
    );

    return <Provider value={value}>{children}</Provider>;
};

// filter object and return keys with given length e.g. length = 2 --> return "62"
function getKeysWithLength(tree: IndustryWithSubtree, length: number) {
    return Object.keys(tree).filter((item) => {
        return item.length === length;
    });
}

function getItemsStartsWith(tree: IndustryWithSubtree, parentLevelKey: string) {
    const keys = getKeysWithLength(tree, parentLevelKey.length + 1);
    const match = keys.filter((key) => {
        return key.startsWith(parentLevelKey);
    });
    return match;
}

const addMainIndustrySubOptions = (tree: IndustryWithSubtree, mainMapping: Record<string, string[]>) => {
    const items = getKeysWithLength(tree, 2);
    for (const key of Object.keys(mainMapping)) {
        let subtree: string[] = [];
        subtree = intersection(mainMapping[key], items);

        subtree = sortBy(subtree);
        if (has(tree, key)) {
            tree[key].subtree = subtree;
        }
    }
};

function addIndustrySubOptions(tree: IndustryWithSubtree) {
    for (const key of Object.keys(tree)) {
        if (key.length >= 2) {
            tree[key].subtree = getItemsStartsWith(tree, key);
        }
    }
}

// find keys that are not in tree and try to find parent for those
function fillOrphans(tree: IndustryWithSubtree, mainMapping: Record<string, string[]>) {
    const subtreeItems: string[] = [];
    for (const key of Object.keys(tree)) {
        subtreeItems.push(...tree[key].subtree);
    }
    const orphans = filter(difference(keys(tree), subtreeItems), (k) => {
        return k.length > 2;
    });
    orphans.forEach((key) => {
        fillParent(tree, key, mainMapping);
    });
}

// find parent from keys shorter than given keys
// if not found in first loop try to find from initial_industry_tree_mapping
function fillParent(tree: IndustryWithSubtree, objectKey: string, mainMapping: Record<string, string[]>) {
    const keyLength = objectKey.length;
    let key: string | undefined = undefined;
    for (let lengthOfIndustryTreeKey = keyLength - 1; lengthOfIndustryTreeKey > 1; lengthOfIndustryTreeKey--) {
        const keys = getKeysWithLength(tree, lengthOfIndustryTreeKey);
        const keyToMatch = objectKey.slice(0, lengthOfIndustryTreeKey);
        key = keys.find((key) => {
            return key.startsWith(keyToMatch);
        });
        if (!isNil(key)) {
            break;
        }
    }
    if (key) {
        if (isArray(tree[key].subtree)) {
            tree[key].subtree = uniq(orderBy(concat(tree[key].subtree, [objectKey])));
        } else {
            tree[key].subtree = [objectKey];
        }
    } else {
        const keys = getKeysWithLength(tree, 1);
        for (let lengthOfIndustryKey = 0; lengthOfIndustryKey < keys.length; lengthOfIndustryKey++) {
            if (
                has(mainMapping, keys[lengthOfIndustryKey]) &&
                mainMapping[keys[lengthOfIndustryKey]].includes(objectKey.slice(0, 2))
            ) {
                tree[keys[lengthOfIndustryKey]].subtree = uniq(
                    orderBy(concat(tree[keys[lengthOfIndustryKey]].subtree, [objectKey])),
                );
                break;
            }
        }
    }
}
