import { lazy, Suspense } from 'react';

import { Skeleton } from '@mui/material';
import { cloneDeep } from 'lodash';

import { CombinedNodeValue, FilterDefinition, GroupValues, LocationFilterProps } from '../../../FilterTypes';
import FilterTemplate from '../../FilterTemplate';
import { config, TValues } from './config';
import { getMapFilterData } from './utils/mapFilterUtils';

const MapFilterMapContainer = lazy(() => import('./MapFilterMapContainer'));

const geoCoordinatesToText = <T,>(_field: keyof T) => {
    return (node: CombinedNodeValue<T>) => {
        const { includeFields, excludeFields } = getMapFilterData(node);
        const count = includeFields.length + excludeFields.length;
        return `${count} area${count !== 1 ? 's' : ''} included`;
    };
};

export const coordinatesFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.location_visiting_address,
        label: config.label,
        fields: config.fields,
        selectableOnce: true,
        render: (props: LocationFilterProps<TValues>) => <MapFilter {...props} />,
        valueToText: geoCoordinatesToText(config.fields[0]),
        config,
        createFilter: () => cloneDeep(config.initValue),
    },
};

export const MapFilter: React.FC<LocationFilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    removeFilter,
    apply,
    onClose,
    warningLevel,
}) => {
    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        isMapFilter: true,
        children: (
            <Suspense fallback={<Skeleton variant="rounded" height={311} sx={{ marginTop: 1 }} />}>
                <MapFilterMapContainer filter={filter} apply={apply} onClose={onClose} warningLevel={warningLevel} />
            </Suspense>
        ),
    };
    return <FilterTemplate {...props} />;
};
