import { get } from 'lodash';

import { FilterOperator } from 'api/types/FilterOperators';
import { FunctionType, functions } from 'components/features/Filters/filters/contacts/ContactFunction';
import { FilterID, NodeValue } from 'components/features/Filters/FilterTypes';

type IntercomPhoneEmailLinkedIn =
    | 'Phone'
    | 'Email'
    | 'LinkedIn'
    | 'Phone & Email'
    | 'Phone & Email & LinkedIn'
    | 'Email & LinkedIn'
    | 'Phone & LinkedIn';

type IntercomEventName =
    | 'Contact filter - Decision maker - On'
    | 'Contact filter - Function - All - On'
    | 'Contact filter - Function - Administration - On'
    | 'Contact filter - Function - Board - On'
    | 'Contact filter - Function - CEO - On'
    | 'Contact filter - Function - Communications - On'
    | 'Contact filter - Function - Development - On'
    | 'Contact filter - Function - Finance - On'
    | 'Contact filter - Function - HR - On'
    | 'Contact filter - Function - Legal - On'
    | 'Contact filter - Function - Logistics - On'
    | 'Contact filter - Function - Marketing - On'
    | 'Contact filter - Function - Mayor - On'
    | 'Contact filter - Function - Procurement - On'
    | 'Contact filter - Function - Production - On'
    | 'Contact filter - Function - Real estate - On'
    | 'Contact filter - Function - Sales - On'
    | 'Contact filter - Function - Technology - On'
    | 'Contact filter - Title keywords - Include value - Entered'
    | "Contact filter - Title keywords - Doesn't include value - Entered"
    | 'Contacts exported - CRM'
    | 'Contacts exported - File'
    | `Contact filter - Contact - ${IntercomPhoneEmailLinkedIn}`;

type IntercomEventMetadata = Record<string, string | number>;
type IntercomEvent = {
    eventName: IntercomEventName;
    metadata?: IntercomEventMetadata;
};

export const createIntercomEvent = (event: IntercomEvent) => {
    if (process.env.REACT_APP_INTERCOM_APP_ID) {
        try {
            window.Intercom('trackEvent', event.eventName, event.metadata);
        } catch (err) {
            console.error(err);
        }
    }
};

const mapValueToEventName = (value: FunctionType) => {
    if (value === 'Real Estate') {
        createIntercomEvent({
            eventName: `Contact filter - Function - Real estate - On`,
        });
        return;
    }
    createIntercomEvent({
        eventName: `Contact filter - Function - ${value} - On`,
    });
};

export const createIntercomContactFilterEvent = (filterId: FilterID, nodeValue: NodeValue) => {
    if (filterId === 'titles#classifications#functional_area') {
        const value: string[] = get(nodeValue, ['?IN', 'titles#classifications#functional_area']);
        if (value?.length) {
            if (value.length === functions.length) {
                createIntercomEvent({
                    eventName: 'Contact filter - Function - All - On',
                });
            } else {
                value.forEach((v) => {
                    mapValueToEventName(v as FunctionType);
                });
            }
        }
    } else if (filterId === 'titles#classifications#is_decision_maker') {
        const value = get(nodeValue, ['?EQ', 'titles#classifications#is_decision_maker']);
        if (value) {
            createIntercomEvent({
                eventName: 'Contact filter - Decision maker - On',
            });
        }
    } else if (filterId === FilterID.contact_title_keywords) {
        const excludes: string[] =
            get(nodeValue, [
                FilterOperator.ALL,
                '0',
                FilterOperator.NOT,
                FilterOperator.STARTSWITH,
                FilterID.contact_title_keywords,
            ]) ||
            get(nodeValue, [
                FilterOperator.ALL,
                '1',
                FilterOperator.NOT,
                FilterOperator.STARTSWITH,
                FilterID.contact_title_keywords,
            ]) ||
            [];
        const includes: string[] =
            get(nodeValue, [FilterOperator.ALL, '0', FilterOperator.STARTSWITH, FilterID.contact_title_keywords]) ||
            get(nodeValue, [FilterOperator.ALL, '1', FilterOperator.STARTSWITH, FilterID.contact_title_keywords]) ||
            [];
        if (includes.length) {
            createIntercomEvent({
                eventName: 'Contact filter - Title keywords - Include value - Entered',
                metadata: {
                    includes: includes.join(','),
                },
            });
        }
        if (excludes.length) {
            createIntercomEvent({
                eventName: "Contact filter - Title keywords - Doesn't include value - Entered",
                metadata: {
                    excludes: excludes.join(','),
                },
            });
        }
    } else if (filterId === FilterID.contact_info) {
        const events = {
            Phone: false,
            Email: false,
            LinkedIn: false,
        };
        const filters = get(nodeValue, [FilterOperator.ALL]) || get(nodeValue, [FilterOperator.ANY]) || [];
        filters.forEach((v: NodeValue) => {
            if (get(v, [FilterOperator.EXISTS, 'phone'])) {
                events.Phone = true;
            }
            if (get(v, [FilterOperator.EXISTS, 'email'])) {
                events.Email = true;
            }
            if (get(v, [FilterOperator.EQ, 'web_profiles#type']) === 'linkedin') {
                events.LinkedIn = true;
            }
        });
        if (Object.values(events).some(Boolean)) {
            let endPart = '';
            if (events.Phone) {
                endPart += 'Phone';
            }
            if (events.Email) {
                endPart += endPart ? ' & Email' : 'Email';
            }
            if (events.LinkedIn) {
                endPart += endPart ? ' & LinkedIn' : 'LinkedIn';
            }
            if (endPart) {
                const event = `Contact filter - Contact - ${endPart}`;
                createIntercomEvent({
                    eventName: event as IntercomEventName,
                });
            }
        }
    }
};
