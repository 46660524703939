import { styled } from '@mui/material';

export const Row = styled('div')<{
    display?: string;
    justifyContent?: string;
    alignItems?: string;
    margin?: string;
    padding?: string;
}>`
    display: ${(props) => (props.display ? props.display : 'flex')};
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding};
`;

export const Header = styled('div')(({ theme }) => ({
    color: theme.palette.text.strong,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
}));

export const StyledCard = styled('div')(({ theme }) => ({
    padding: '24px',
    height: 'fit-content',
    marginTop: '32px',
    borderRadius: '8px',
    background: theme.palette.common.white,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
}));
