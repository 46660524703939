import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { useAxiosContext } from 'contexts/AxiosContext';

import { Trademark } from './types/company';

export const getTrademarks = async (axios: AxiosInstance, businessId: string) =>
    (await axios.get(`/mapi/get_trademarks_for_business_id/${businessId}/`)).data?.trademarks;

export function useTrademarks(businessId: string) {
    const axios = useAxiosContext();
    return useQuery<Trademark[]>({
        queryKey: ['trademarks', businessId],
        queryFn: () => getTrademarks(axios, businessId),
        retry: 1,
    });
}
