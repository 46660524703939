import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PlusCircleOutline: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M12.7143 16.2857V12.7143H16.2857C16.6802 12.7143 17 12.3945 17 12C17 11.6055 16.6802 11.2857 16.2857 11.2857H12.7143V7.71429C12.7143 7.3198 12.3945 7 12 7C11.6055 7 11.2857 7.3198 11.2857 7.71429V11.2857H7.71429C7.3198 11.2857 7 11.6055 7 12C7 12.3945 7.3198 12.7143 7.71429 12.7143H11.2857V16.2857C11.2857 16.6802 11.6055 17 12 17C12.3945 17 12.7143 16.6802 12.7143 16.2857Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5Z"
        />
    </SvgIcon>
);

export default PlusCircleOutline;
