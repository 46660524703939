import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from 'components/tokens/Button';
import FeedbackBanner from 'components/tokens/FeedbackBanner/FeedbackBanner';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

const StyledSpinner = styled(CircularProgress)`
    margin-left: 4px;
    margin-right: 2px;
`;

export type UsageInfoBannerProps = {
    type: 'csv-export' | 'crm-export';
    crmName?: 'HubSpot' | 'Pipedrive' | 'Salesforce' | 'Dynamics';
    crmUpdates?: boolean;
    count: number;
    currentQuota: number;
    quotaUsed: number;
    showCurrentUsage?: boolean;
    loadingCount?: boolean;
    isUncertainCount?: boolean;
    scheduledCount?: boolean;
};

const messages = defineMessages({
    crmExportWithUpdates: {
        id: 'quota.crmExportWithUpdates',
        defaultMessage:
            'When you send {count} new companies to {crmName}, we will match them with our system and keep them updated. This action will upgrade your respective quotas from {current_quota} to {updated_quota}.',
    },
    crmExportNoUpdates: {
        id: 'quota.crmExportNoUpdates',
        defaultMessage:
            'When you send {count} new companies to {crmName}, we will match them with our system. This will upgrade your respective quota from {current_quota} to {updated_quota}.',
    },
    fileExportTakesOverQuota: {
        id: 'quota.fileExportTakesOverQuota',
        defaultMessage:
            'After this download, your team’s usage will go over quota: {usage} / {quota}. You will receive an invoice for the difference.',
    },
    fileExportTakesMoreOverQuota: {
        id: 'quota.fileExportTakesMoreOverQuota',
        defaultMessage:
            'Your team’s usage is over quota. Usage after this download: {usage} / {quota}. You will receive an invoice for the difference.',
    },
});

const getBannerMessage = (
    type: UsageInfoBannerProps['type'],
    crmUpdates: UsageInfoBannerProps['crmUpdates'],
    alreadyOverQuota: boolean,
) => {
    if (type === 'crm-export') {
        return crmUpdates ? messages.crmExportWithUpdates : messages.crmExportNoUpdates;
    }
    if (alreadyOverQuota) {
        return messages.fileExportTakesMoreOverQuota;
    }
    return messages.fileExportTakesOverQuota;
};

export const UsageInfoBanner: React.FC<UsageInfoBannerProps> = ({
    type,
    crmName,
    crmUpdates,
    currentQuota,
    count,
    quotaUsed,
    showCurrentUsage = false,
    loadingCount = false,
    isUncertainCount = false,
    scheduledCount = false,
}) => {
    const intl = useIntl();
    const highlightQuotaUpgrading = quotaUsed + count > currentQuota + 10000 || quotaUsed > currentQuota;
    const compactNumberFormatter = Intl.NumberFormat('en', { notation: 'compact' });
    const standardNumberFormatter = Intl.NumberFormat('en', { notation: 'standard' });
    const message = getBannerMessage(type, crmUpdates, quotaUsed > currentQuota);

    const quota = standardNumberFormatter.format(currentQuota);
    const futureUsage = `${isUncertainCount || scheduledCount ? '~' : ''}${standardNumberFormatter.format(
        quotaUsed + count,
    )}`;

    const showLoading = loadingCount && !scheduledCount;
    const text = intl.formatMessage(message, {
        count: Intl.NumberFormat('en').format(count),
        crmName,
        usage: showLoading ? (
            <>
                <b>Loading...</b>
                <StyledSpinner size="1em" />
            </>
        ) : (
            <b>{futureUsage}</b>
        ),
        quota: standardNumberFormatter.format(currentQuota),
        current_quota: compactNumberFormatter.format(currentQuota),
        updated_quota: compactNumberFormatter.format(quotaUsed + Math.ceil(count / 1000) * 1000),
    });

    const seeUsageButton = (
        <Link to="/settings/usage" target="_blank">
            <Button variant="flat" size="small" endIcon={<Icon type="ExternalLink" />} sx={{ marginLeft: '-8px' }}>
                See usage
            </Button>
        </Link>
    );

    return highlightQuotaUpgrading ? (
        <FeedbackBanner label={text} action={seeUsageButton} />
    ) : showCurrentUsage ? (
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '16px' }}>
            {count && !showLoading ? (
                <Typography variant="body2">
                    {`Usage after download: `}
                    {quotaUsed + count > currentQuota ? <b>{futureUsage}</b> : futureUsage}
                    {` / ${quota}`}
                </Typography>
            ) : (
                <Typography variant="body2">
                    Usage after download: Loading...
                    <StyledSpinner size="1em" />
                    {` / ${quota}`}
                </Typography>
            )}
            {seeUsageButton}
        </Grid>
    ) : null;
};

export default UsageInfoBanner;
