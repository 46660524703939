import React, { useRef, useState } from 'react';

import { Box } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { ResultObjectType } from 'contexts/FilterCompaniesContext/utils';
import { useSortCompany } from 'contexts/SortCompaniesContext';
import { SortId, SortOrder } from 'contexts/SortCompaniesContext/SortCompaniesContext';

type SortMenuListProps = {
    tab?: ResultObjectType;
};

type SortPropertyLabelKey = 'ascendingLabel' | 'descendingLabel';

type SortProperties = {
    [key: string]: {
        ascendingLabel: string;
        descendingLabel: string;
        showDescendingFirst?: boolean;
    };
};

const sortProperties: SortProperties = {
    vainu_score: {
        ascendingLabel: 'Lowest first',
        descendingLabel: 'Highest first',
        showDescendingFirst: true,
    },
    'website_traffic_data.ranking': {
        ascendingLabel: 'Highest first',
        descendingLabel: 'Lowest first',
    },
    domain: {
        ascendingLabel: 'A-Z',
        descendingLabel: 'Z-A',
    },
    company_name: {
        ascendingLabel: 'A-Z',
        descendingLabel: 'Z-A',
    },
};

const SortMenuList: React.FC<SortMenuListProps> = ({ tab }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const { activeSortType, handleActiveSort, sortCompanyOptions, sortOrder, changeSortOrder } = useSortCompany();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleSortBy = (sortValue: SortId) => (e: React.SyntheticEvent) => {
        handleActiveSort(sortValue);
        handleClose(e);
    };

    const handleSortByOrder = (order: SortOrder) => {
        changeSortOrder(order);
        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const sortOptionKey = activeSortType.id.replace('-', '');

    return (
        <>
            <Button
                variant="flat"
                endIcon={<Icon type="ChevronDown" />}
                ref={anchorRef}
                id="sort-companies-button"
                aria-controls={open ? 'sort-companies-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                size="small"
            >
                Sort by: {tab === 'contacts' && <Icon type="Profiles" fontSize="mini" sx={{ marginX: 0.5 }} />}
                {activeSortType.name}
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                sx={{ zIndex: 1 }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'left top',
                        }}
                    >
                        <Paper elevation={3} sx={{ border: '1px solid', borderColor: 'border' }}>
                            <ClickAwayListener
                                onClickAway={handleClose}
                                mouseEvent="onMouseDown"
                                touchEvent="onTouchStart"
                            >
                                <MenuList
                                    autoFocusItem={open}
                                    id="sort-companies-menu"
                                    aria-labelledby="sort-companies-button"
                                    onKeyDown={handleListKeyDown}
                                    sx={{ width: 190, padding: 1 }}
                                >
                                    {tab === 'contacts' && (
                                        <Box
                                            sx={{
                                                height: '32px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '6px 8px',
                                                gap: 1,
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <Icon type="Profiles" fontSize="mini" color="text.strong" />
                                            <Typography
                                                variant="caption"
                                                weight="bold"
                                                color="subtle"
                                                sx={{ lineHeight: 1 }}
                                            >
                                                COMPANY
                                            </Typography>
                                        </Box>
                                    )}
                                    {Object.values(sortCompanyOptions).map((option) => (
                                        <StyledMenuItem
                                            key={option.id}
                                            onClick={handleSortBy(option.id)}
                                            className={option.id === activeSortType.id ? 'isActive' : undefined}
                                        >
                                            {option.name}{' '}
                                            <Icon className="option-selected" type="CheckBig" color="text.subtle" />
                                        </StyledMenuItem>
                                    ))}
                                    <Divider variant="middle" />
                                    {(sortProperties[sortOptionKey]?.showDescendingFirst
                                        ? ['descending', 'ascending']
                                        : ['ascending', 'descending']
                                    ).map((prop) => (
                                        <StyledMenuItem
                                            key={prop}
                                            onClick={() => handleSortByOrder(prop as SortOrder)}
                                            className={sortOrder === prop ? 'isActive' : undefined}
                                        >
                                            {sortProperties[sortOptionKey]?.[`${prop}Label` as SortPropertyLabelKey] ||
                                                prop}
                                            <Icon className="option-selected" type="CheckBig" color="text.subtle" />
                                        </StyledMenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default SortMenuList;

const StyledMenuItem = styled(MenuItem)(({ theme: { palette } }) => ({
    backgroundColor: palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 8px',
    marginBottom: 4,
    borderRadius: 4,
    border: '1px solid transparent',
    ':last-of-type': {
        marginBottom: 0,
    },
    '& .option-selected': {
        display: 'none',
    },
    ':hover': {
        background: palette.field.background,
        borderColor: palette.border,
    },
    '&.isActive': {
        backgroundColor: palette.field.hover,
        '& .option-selected': {
            display: 'inherit',
        },
        ':hover': {
            background: palette.field.hover,
        },
    },
}));
