import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Alert, Box, Skeleton, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { CRMDisconnectConfirmDialog } from 'components/modules/connectors/common/CRMDisconnectConfirmDialog';
import ObjectSvgIcon from 'components/modules/connectors/common/ObjectIcon';
import { SettingsDrawer } from 'components/modules/connectors/common/SettingsDrawer';
import { useCRMIntegConfig } from 'components/modules/connectors/context/CRMIntegrationConfigContext';
import { useCompanyMatch } from 'components/modules/connectors/hooks/useCompanyMatch';
import { MatchingProgressBar } from 'components/modules/connectors/matching/MatchingProgressBar';
import { getCompaniesDiagramConfig, getLeadsDiagramConfig } from 'components/modules/connectors/matching/utils';
import { TabEnum } from 'components/modules/connectors/types';
import { CRMObject, CrmName } from 'components/modules/connectors/types/Crm';
import { getCompanyLikeObjectForCrm, getObjectPluralForm } from 'components/modules/connectors/utils/CRMTargetText';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { DialogController } from 'contexts/DialogContext/DialogController';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import { filteredLatestCrmImportProcess } from 'store/asyncProcessAtomSelectors';
import { isAsyncProcessOngoing } from 'utilities/asyncProcess';

import { ConnectionViewTabProps } from '../ConnectionView';
import CRMInstanceTile from './CRMInstanceTile';
import { OverviewMatchingResultDiagram } from './OverviewMatchingResultDiagram';
import { RestartMatchingConfirmDialog } from './RestartMatchingConfirmDialog';
import { Tile, TileContent } from './Tile';
import { getMatchingObjectsText } from './utils';

const CRMOverviewTab: React.FC<ConnectionViewTabProps> = ({ crmName }) => {
    const {
        isLoading,
        isInitialFullImportDone,
        addedAndMatched,
        matchedUnsupportedCountries,
        matchedUpgradableCountries,
        nonMatchedCountries,
        missingFromDB,
        matchedByCountries,
        matchedByDomains,
        fetchMatchingResults,
        matchedLeads,
        unmatchedLeads,
    } = useCompanyMatch();
    const { isDomainConnector, connections, isAccountAdmin, hasAccountOrUserPermission } = usePermissionContext();
    const hasLeadMatchingPermission = hasAccountOrUserPermission(Permission.CRMLeadMatching);

    const [
        { isLoaded, crm, auto_syncing_enabled, enrich_enabled, error, field_mappings },
        { saveSyncOption, startFullImport },
    ] = useCRMIntegConfig();

    const latestCRMImportProcess = useRecoilValue(filteredLatestCrmImportProcess({ crm }));
    const prevMatchingJobRef = useRef(latestCRMImportProcess?.job_id);

    const navigate = useNavigate();
    const { search } = useLocation();

    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

    const { tab, forceTabChange, connected } = queryParams;

    const [isMatchingRestartDialogOpen, setMatchingRestartDialogOpen] = useState(false);

    // automatically start matching after connecting
    useEffect(() => {
        const startMatching = async () => {
            await startFullImport();
            await saveSyncOption({ auto_syncing_enabled: true });
        };

        if (isAccountAdmin && crm && connected === '' && isInitialFullImportDone === false) {
            startMatching();
        }
    }, [isInitialFullImportDone, connected, startFullImport, saveSyncOption, crm, isAccountAdmin]);

    useEffect(() => {
        if (
            latestCRMImportProcess?.job_id !== prevMatchingJobRef.current &&
            latestCRMImportProcess?.state === 'completed'
        ) {
            fetchMatchingResults();
            prevMatchingJobRef.current = latestCRMImportProcess?.job_id;
        }
    }, [latestCRMImportProcess, fetchMatchingResults]);

    const integration = useMemo(() => {
        return connections?.find(({ crm_name }) => crmName === crm_name?.toLowerCase());
    }, [crmName, connections]);

    const [openedConnectorTab, setOpenedConnectorTab] = useState<TabEnum | undefined>(undefined);

    const matched = isDomainConnector ? matchedByDomains.count : matchedByCountries.count;
    const unmatched = nonMatchedCountries.count;
    const addedAndMatchedCount = addedAndMatched.count;
    const missingFromDBCount = missingFromDB.count;

    const needUpgrade = matchedUpgradableCountries.count;
    const unsupported = matchedUnsupportedCountries.count;

    const total = isDomainConnector
        ? matched + unmatched + addedAndMatchedCount + missingFromDBCount
        : matched + unmatched + needUpgrade + unsupported;

    const companiesDiagramConfig = useMemo(
        () =>
            getCompaniesDiagramConfig({
                isDomainConnector,
                matched,
                unmatched,
                needUpgrade,
                unsupported,
                addedAndMatched: addedAndMatchedCount,
                missingFromDBCount,
                total,
            }),
        [
            isDomainConnector,
            matched,
            unmatched,
            needUpgrade,
            unsupported,
            addedAndMatchedCount,
            missingFromDBCount,
            total,
        ],
    );

    const leadsDiagramConfig = useMemo(
        () =>
            getLeadsDiagramConfig({
                matched: matchedLeads.count,
                unmatched: unmatchedLeads.count,
                total: matchedLeads.count + unmatchedLeads.count,
            }),
        [matchedLeads.count, unmatchedLeads.count],
    );

    useEffect(() => {
        if (forceTabChange) {
            navigate(
                {
                    search: qs.stringify({
                        ...queryParams,
                        tab: undefined,
                        forceTabChange: undefined,
                    }),
                },
                { replace: true },
            );
        }
    }, [forceTabChange, navigate, queryParams]);

    const getMatchTileContent = () => {
        if (isAsyncProcessOngoing(latestCRMImportProcess)) {
            const { completed = 0, size = 100 } = latestCRMImportProcess.meta_data ?? {};
            const progressPercentage = ((completed * 100) / size)?.toFixed(2) ?? '0';

            return (
                <MatchingProgressBar
                    hasLeadMatchingPermission={hasLeadMatchingPermission}
                    crm={crmName}
                    progressPercentage={Number(progressPercentage)}
                    processedCount={completed}
                />
            );
        }

        return (
            <>
                <OverviewMatchingResultDiagram
                    isLoading={isLoading}
                    crm={crmName}
                    matchType="company"
                    total={total}
                    matched={matched}
                    diagramConfig={companiesDiagramConfig}
                />
                {crmName === 'salesforce' && hasLeadMatchingPermission && (
                    <OverviewMatchingResultDiagram
                        isLoading={isLoading}
                        crm={crmName}
                        matchType="lead"
                        total={matchedLeads.count + unmatchedLeads.count}
                        matched={matchedLeads.count}
                        diagramConfig={leadsDiagramConfig}
                    />
                )}
            </>
        );
    };

    return (
        <Box data-testid="overview-tab-content" sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    flex: 1,
                    minWidth: 'min(100%, 550px)',
                    opacity: error ? 0.3 : 1,
                    pointerEvents: error ? 'none' : 'auto',
                }}
            >
                <Tile
                    title="Matched with Vainu"
                    cta={
                        <div>
                            <Button variant="tertiary" onClick={() => setOpenedConnectorTab('matching')}>
                                View {getObjectPluralForm(crmName, getCompanyLikeObjectForCrm(crmName))} matches
                            </Button>
                            <Button
                                startIcon={<Icon type="Refresh" />}
                                variant="tertiary"
                                onClick={() => setMatchingRestartDialogOpen(true)}
                                sx={{ marginLeft: '8px' }}
                            />
                        </div>
                    }
                >
                    <TileContent>
                        {getMatchTileContent()}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography variant="body2">
                                Match new {getMatchingObjectsText(hasLeadMatchingPermission, crmName)}
                            </Typography>
                            {auto_syncing_enabled == null ? (
                                <Skeleton width={70} />
                            ) : auto_syncing_enabled ? (
                                <Alert severity="success">Yes</Alert>
                            ) : (
                                <Alert severity="error">No</Alert>
                            )}
                        </Box>
                    </TileContent>
                </Tile>

                <Tile
                    title="Data updates"
                    cta={
                        <Button variant="tertiary" onClick={() => setOpenedConnectorTab('sync')}>
                            Check updates
                        </Button>
                    }
                >
                    <TileContent>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography variant="body2" sx={{ width: 155 }}>
                                Last update
                            </Typography>
                            <Typography variant="body2" color="subtle">
                                {formatDate(toDateUTC(new Date().toISOString().slice(0, -1)), 'long-datetime')}
                            </Typography>
                        </Box> */}

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography variant="body2" sx={{ width: 155 }}>
                                Automatically update
                            </Typography>
                            {enrich_enabled == null ? (
                                <Skeleton width={70} />
                            ) : enrich_enabled ? (
                                <Alert severity="success">Yes</Alert>
                            ) : (
                                <Alert severity="error">No</Alert>
                            )}
                        </Box>
                    </TileContent>
                </Tile>

                <Tile
                    title="Data your team can send"
                    cta={
                        <Button variant="tertiary" onClick={() => setOpenedConnectorTab('mapping')}>
                            Manage data
                        </Button>
                    }
                >
                    <TileContent
                        sx={{
                            backgroundColor: 'brandColors.appBg',
                            padding: '16px',
                            borderRadius: '8px',
                        }}
                    >
                        {!isLoaded || isEmpty(field_mappings)
                            ? Array.from({ length: 5 }).map((_, index) => (
                                  <MappingsNumberWrapper key={index}>
                                      <Skeleton width={20} />
                                      <Skeleton width={100} />
                                      <Skeleton width={80} />
                                  </MappingsNumberWrapper>
                              ))
                            : Object.entries(field_mappings).map(([crmObject, mappingsByCRMObject]) => (
                                  <MappingsNumberWrapper key={crmObject}>
                                      <ObjectSvgIcon crm={crm} object={crmObject as CRMObject} />
                                      <Typography variant="small" sx={{ width: '100px' }}>
                                          {getObjectPluralForm(crm, crmObject as CRMObject)}
                                      </Typography>
                                      <Typography variant="small" weight="semibold">
                                          {mappingsByCRMObject.length} properties
                                      </Typography>
                                  </MappingsNumberWrapper>
                              ))}
                    </TileContent>
                </Tile>
            </Box>
            <CRMInstanceTile hasError={!!error} crmName={crmName as CrmName} integration={integration} />
            {!!openedConnectorTab && (
                <SettingsDrawer
                    crm={crmName}
                    connected
                    open={!!openedConnectorTab}
                    forceTabChangeTo={forceTabChange ? (tab as TabEnum) : undefined}
                    onClose={() => setOpenedConnectorTab(undefined)}
                    defaultTab={openedConnectorTab}
                />
            )}
            <DialogController type="CRM_DISCONNECT_CONFIRM_DIALOG" component={CRMDisconnectConfirmDialog} />
            <RestartMatchingConfirmDialog
                isOpen={isMatchingRestartDialogOpen}
                onSubmit={() => {
                    startFullImport();
                    setMatchingRestartDialogOpen(false);
                }}
                handleDialogClose={() => setMatchingRestartDialogOpen(false)}
            />
        </Box>
    );
};

export default CRMOverviewTab;

const MappingsNumberWrapper = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    '& > *': {
        marginRight: '16px',
    },
    '& > .MuiSkeleton-root': {
        backgroundColor: palette.border,
    },
}));
