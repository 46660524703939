import { useEffect, useState } from 'react';

import { useFilterState } from 'contexts/FilterContext';

import { FilterID } from '../../FilterTypes';

export function useCreateFilterByDefaultSelfGroup(
    filterId: FilterID,
    ids: string[],
    handleAddFilter: (filterId: FilterID) => void,
) {
    const [isFilterAdded, setIsFilterAdded] = useState(false);
    const { activeFilter, setActiveFilter } = useFilterState();

    useEffect(() => {
        if (activeFilter === filterId || !ids.length) {
            handleAddFilter(filterId);
            setActiveFilter(undefined);
            setIsFilterAdded(true);
        }
    }, [handleAddFilter, ids, filterId, activeFilter, setActiveFilter]);

    return { isFilterAdded, setIsFilterAdded };
}
