import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { styled } from '@mui/system';
import { useInfiniteQuery } from '@tanstack/react-query';
import { isNull } from 'lodash';

import { ApplicationStoreConnections } from 'api/types/ApplicationStore';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Dialog from 'components/tokens/Dialog';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';

import useConnectorsApi from '../hooks/useConnectorsApi';
import { ProspectExport } from '../types';
import { CrmName } from '../types/Crm';
import { getCRMName } from '../utils';

type NoDomainCompaniesDialogProps = {
    crm: CrmName;
    nonMatchedCount: number;
    crmConnection: ApplicationStoreConnections | undefined;
    handleDialogClose: () => void;
};

const COMPANIES_PER_PAGE = 20;

const getCrmLink = (targetFk: string, template: string | undefined) => {
    return template ? template.replace('{}', targetFk) : '';
};

const NoDomainCompaniesDialog: React.FC<NoDomainCompaniesDialogProps> = ({
    crm,
    nonMatchedCount,
    crmConnection,
    handleDialogClose,
}) => {
    const { getProspectExport } = useConnectorsApi();

    const scrollContainer = useRef<HTMLElement | null>(null);
    const crmName = useMemo(() => getCRMName(crm), [crm]);

    const getNoDomainCompanies = useCallback(
        async (rowOffset: number) => {
            return getProspectExport({
                target: crm,
                target_object: 'Account',
                no_domain: 1,
                get_all: true,
                row_limit: COMPANIES_PER_PAGE,
                row_offset: rowOffset,
            }).then(({ data: { data, has_more } }) => ({
                hasMore: has_more,
                pageParam: rowOffset,
                data: data.no_match,
            }));
        },
        [crm, getProspectExport],
    );

    // todo: create a new custom hook for scroll pagination
    // use it here and in the <ManualCompanyMatch />
    const { isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
        queryKey: ['noDomainCompanies'],
        queryFn: ({ pageParam }) => getNoDomainCompanies(pageParam),
        refetchOnMount: 'always',
        staleTime: 0,
        initialPageParam: 0,
        getNextPageParam: ({ hasMore, pageParam }) => (hasMore ? pageParam + COMPANIES_PER_PAGE : pageParam),
    });

    const fetchedCompanies = useMemo(
        () => data?.pages.map(({ data: companies }) => companies).flat() ?? [],
        [data?.pages],
    );

    const handleNextPage = useCallback(() => {
        if (!scrollContainer.current) return;

        const isScrolledToBottom =
            scrollContainer.current.scrollTop + scrollContainer.current.clientHeight >=
            scrollContainer.current.scrollHeight * 0.8; // 80% of scrollContainer has been scrolled

        const shouldRequestNextPage =
            !isFetchingNextPage && isScrolledToBottom && data?.pages[data.pages?.length - 1].hasMore;

        if (shouldRequestNextPage) {
            fetchNextPage();
        }
    }, [isFetchingNextPage, data?.pages, fetchNextPage, scrollContainer]);

    useEffect(() => {
        scrollContainer.current?.addEventListener('scroll', handleNextPage);

        return () => {
            scrollContainer.current?.removeEventListener('scroll', handleNextPage);
        };
    }, [data, scrollContainer, handleNextPage]);

    return (
        <Dialog
            open
            maxWidth="lg"
            title={`${nonMatchedCount} Need help`}
            message={`Add missing domains in ${crmName} to the following companies so that Vainu can match them.`}
            onClose={handleDialogClose}
        >
            <SortableTable<ProspectExport>
                sx={{ marginTop: 2 }}
                rowKeyField="id"
                tableHeight={500}
                data={fetchedCompanies}
                onScroll={(containerEl) => (scrollContainer.current = containerEl)}
            >
                <SortableTableColumn<ProspectExport>
                    field="target_fk"
                    sortable={false}
                    label={`${crmName} ID`}
                    width={150}
                >
                    {({ target_fk }) => (
                        <CompanyNameLink
                            toNewTab
                            href={
                                crmConnection?.account_link_template
                                    ? getCrmLink(target_fk, crmConnection?.account_link_template)
                                    : ''
                            }
                        >
                            {target_fk} <Icon type="ExternalLink" />
                        </CompanyNameLink>
                    )}
                </SortableTableColumn>
                <SortableTableColumn<ProspectExport>
                    field="match_data.company_name.0"
                    sortable={false}
                    label="Company"
                    width={200}
                >
                    {({ target_fk, match_data, prospect_info }) => (
                        <CompanyNameLink
                            toNewTab
                            href={
                                crmConnection?.account_link_template
                                    ? getCrmLink(target_fk, crmConnection?.account_link_template)
                                    : ''
                            }
                        >
                            {/* prospect_info.company_name should be used if company from the BizID */}
                            {/* be careful, sorting will not work when we enable it */}
                            {match_data?.company_name?.[0] ?? prospect_info?.company_name} <Icon type="ExternalLink" />
                        </CompanyNameLink>
                    )}
                </SortableTableColumn>
                <SortableTableColumn<ProspectExport>
                    sortable={false}
                    field="domain"
                    label="Domain"
                    width={300}
                    children={({ domain }) =>
                        isNull(domain) ? '-' : typeof domain === 'string' ? domain : domain.domain
                    }
                />
            </SortableTable>
        </Dialog>
    );
};

const CompanyNameLink = styled(Link)(({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'none',

    '& > svg': {
        display: 'none',
        width: 13,
        height: 13,
        color: palette.brandColors.tertiaryBlue,
    },

    '&:hover > svg': {
        display: 'block',
    },
}));

export { NoDomainCompaniesDialog };
