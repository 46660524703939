import React, { createContext, useContext, useMemo } from 'react';

import { Box } from '@mui/material';
import axiosFactory, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { AuthDataOrError, isErrorResponse, parseTokenData } from 'api/auth';
import { AuthenticationError, useAuthContext } from 'contexts/AuthContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';

axiosFactory.defaults.transitional = {
    ...axiosFactory.defaults.transitional,
    clarifyTimeoutError: true,
};

declare module 'axios' {
    export interface AxiosRequestConfig {
        retry?: boolean;
        /** Disables the default error handling snackbar. Set to true if you want to handle the error feedback yourself elsewhere in the UI. */
        disableErrorNotify?: boolean;
    }
}

export type ErrorResponse = Partial<{
    detail: string;
    error_code: string;
    status_code: number;
}>;

export type APIError = AxiosError<ErrorResponse>;

export const AxiosContext = createContext<AxiosInstance>(axiosFactory.create());

type AxiosProviderProps = {
    mock?: boolean;
    children?: React.ReactNode;
};

const magicHeader = 'X-Vainu-Platform-Id';

// Copied verbatim https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
const createSha256Hash = async (text: string) => {
    const msgUint8 = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
};

/**
 * Creates a "secret" (obfuscated) hash that must match with the respective hash on the backend. While not actually
 * secret, resolving this requires reverse engineering and is grounds for a contract breach.
 *
 * The result of this function must _exactly_ match with the backend function
 * _create_magic_hash @ /vainu/django/jwt_token_auth.py.
 *
 * If you want to enable this when developing with the local dev backend, set an env var
 * to a random string of your choice in two places:
 * - on frontend: REACT_APP_VAINU_PLATFORM_ID_MAGIC=something in /.env.local
 * - on backend: VAINU_PLATFORM_ID_MAGIC=something in /vainudocker/docker-env.local
 *
 * In production and staging this function gets its secret part from the GitLab variable VAINU_PLATFORM_ID_MAGIC.
 *
 * If REACT_APP_VAINU_PLATFORM_ID_MAGIC is not defined, this header is not sent.
 * On the test env, the context skips by default the whole pre-request interceptor.
 */
export const createMagicHash = async (access: string): Promise<string | undefined> => {
    try {
        const secretPart = process.env.REACT_APP_VAINU_PLATFORM_ID_MAGIC;
        if (!secretPart) {
            return undefined;
        }
        const iat = parseTokenData(access).iat;
        const origin = window.location.origin;
        const userAgent = window.navigator.userAgent;
        const magicString = [secretPart, iat, origin, userAgent].join('-');
        const magicHash = await createSha256Hash(magicString);
        return magicHash;
    } catch (error) {
        if (error instanceof SyntaxError) {
            throw new Error('Invalid token: could not parse JSON data');
        }
        throw error;
    }
};

export const AxiosProvider: React.FC<AxiosProviderProps> = ({ children, mock = process.env.NODE_ENV === 'test' }) => {
    const auth = useAuthContext();
    if (!auth) {
        throw new AuthenticationError('Axios is missing the auth context.', 'AUTH_CONTEXT_ERROR');
    }
    const { showSnackbar } = useSnackbarContext();

    const { getAuth, getImpersonateAuth, doRefresh, setAuth } = auth;

    const { Provider } = AxiosContext;

    const axios = useMemo(() => {
        const axios = axiosFactory.create({
            baseURL: process.env.REACT_APP_API_URL,
        });

        // Injects the access token to a request.
        // Also injects the magic hash that indicates the backend that the platform frontend is making the request.
        // Only does it on the authenticated host; non-authenticated hosts should be
        // listed at the top of the interceptor, or by making the requests with the plain axios object.
        axios.interceptors.request.use(async (config) => {
            // Skip authorization check when the url contains `filter.vainu.io`
            if (config.url?.includes('filter.vainu.io') || mock) {
                return config;
            }

            // If authorization is already provided, do not replace it.
            if (config.headers?.Authorization) {
                return config;
            }

            const impersonateAuth = getImpersonateAuth();
            if (impersonateAuth?.access) {
                const magicHash = await createMagicHash(impersonateAuth.access);
                if (magicHash) {
                    config.headers.set(magicHeader, magicHash);
                }
                config.headers.set('Authorization', `Bearer ${impersonateAuth.access}`);
                return config;
            }

            // Axios does not use the actual state of the context, but instead directly accesses the data
            // from the local storage. This is so that if one Axios instance updates the tokens,
            // others will use the newest value. This happens if the Axios instance is used
            // on a callback, e.g., with setTimeout.
            const auth = await getAuth();
            if (!auth?.access) {
                throw new AuthenticationError(
                    'No access token found. You should first log in.',
                    'NO_ACCESS_TOKEN',
                    config,
                );
            }
            const { access } = auth;

            const magicHash = await createMagicHash(access);
            if (magicHash) {
                config.headers.set(magicHeader, magicHash);
            }

            config.headers.set('Authorization', `Bearer ${access}`);
            return config;
        });

        const isAuthNeededResponse = (response: AxiosResponse | undefined) => {
            if (!response) {
                return false;
            }
            const { status, data } = response;
            // The 403 part is for the legacy API v1 which don't all return the proper 401 code. The check can hopefully be removed in the future.
            return status === 401 || (status === 403 && 'code' in data && data.code === 'token_not_valid');
        };

        // Intercept the 401 responses and try refreshing tokens and retrying the request.
        // Refreshing is tried only once.
        // The tokens are only refreshed after a 401 response, due to possible sync errors in the browser
        // server clocks, which makes expiry detection unreliable.
        // The interceptor handles both successes and failures, as the request may have different status validations.
        const interceptResponse = async (
            response: AxiosResponse<AuthDataOrError | unknown>,
            error?: AxiosError<AuthDataOrError | unknown>,
        ) => {
            const { config, request } = response;
            const axiosErrorParams = [config, request, response];
            if (isAuthNeededResponse(response)) {
                if (isErrorResponse(response.data) && response.data?.detail === 'Platform id check failed') {
                    // This triggers the login page to show the error and purge the refresh token.
                    setAuth(response.data);
                    throw new AuthenticationError('Platform id check failed', 'PLATFORM_ID_FAIL', ...axiosErrorParams);
                }

                if (config.retry) {
                    throw new AuthenticationError(
                        `Refreshed access token was invalid on request ${config.url}`,
                        'INVALID_ACCESS_TOKEN',
                        ...axiosErrorParams,
                    );
                }

                const impersonateAuth = getImpersonateAuth();
                if (impersonateAuth) {
                    throw new AuthenticationError(
                        'Impersonation access token expired.',
                        'IMPERSONATION_ACCESS_TOKEN_EXPIRED',
                        ...axiosErrorParams,
                    );
                }

                const { access } = await doRefresh();
                if (!access) {
                    throw new AuthenticationError(
                        'The access token was not given even after successful refresh.',
                        'ACCESS_TOKEN_REFRESH_FAILED',
                        ...axiosErrorParams,
                    );
                }

                // If the token refresh succeeded, retry the request.
                config.headers.set('Authorization', `Bearer ${access}`);

                const magicHash = await createMagicHash(access);
                if (magicHash) {
                    config.headers.set(magicHeader, magicHash);
                }

                // Set this attribute to check if the request has already been retried.
                config.retry = true;

                return axios.request(config);
            }
            // If the original response was error, throw. Otherwise just return.
            if (error) {
                return Promise.reject(error);
            }
            return response;
        };

        // If the response interceptor rejects or throws, the error interceptor is triggered.
        // It shows the error as a snackbar (toast) by default; a more verbose version in shown development.
        // The snackbar can be disabled by adding an option `{ disableErrorNotify: true }` in request config.
        const interceptError = (error: AxiosError<AuthDataOrError | unknown>) => {
            const { response } = error;
            if (!error.config?.disableErrorNotify && !isAuthNeededResponse(response)) {
                if (process.env.NODE_ENV === 'development') {
                    const message = response ? `${response.status} ${response.statusText}` : error.message;
                    showSnackbar(
                        <Box component="code" sx={{ fontSize: 12, '& > :first-of-type': { fontWeight: 700 } }}>
                            <div>API error</div>
                            {<div>{message}</div>}
                            <div>{error.config?.url}</div>
                        </Box>,
                        'error',
                    );
                    console.error(`API error in ${error.config?.url}: ${message}`);
                } else if (response) {
                    const message = isErrorResponse(response.data)
                        ? response.data.detail
                        : response.statusText || error.message;
                    showSnackbar(`Error encountered: ${message}`, 'error');
                }
            }
            if (response) {
                return interceptResponse(response, error);
            }
            return Promise.reject(error);
        };

        axios.interceptors.response.use(interceptResponse, interceptError);
        return axios;
    }, [getAuth, getImpersonateAuth, doRefresh, setAuth, mock, showSnackbar]);

    return <Provider value={axios}>{children}</Provider>;
};

export const useAxiosContext = () => useContext(AxiosContext);
