import React from 'react';

import { Paper, styled, useTheme } from '@mui/material';

import Typography from 'components/tokens/Typography';

import { Header, Row } from '../common/Styled';
import StyledTooltip from '../common/StyledTooltip';
import TooltipIcon from '../common/TooltipIcon';
import { localeNumber } from '../utils';

interface Props {
    numberByCountry?: { _id: string; count: number }[];
    component: React.Key;
    footerComponent?: React.ReactNode;
    header: string;
    tooltipDescription: React.ReactNode;
    countObject: { count: number | string; text: string };
}
const SummaryCard: React.FC<Props> = ({
    header,
    tooltipDescription,
    countObject,
    numberByCountry = [],
    component,
    footerComponent,
}) => {
    const theme = useTheme();

    const { count, text } = countObject;
    return (
        <Container elevation={0} key={component}>
            <div>
                <Row>
                    <Header>{header}</Header>
                    <TooltipIcon text={tooltipDescription} />
                </Row>
                <SummaryWrapper>
                    <Count color={count ? theme.palette.brandColors.green : theme.palette.brandColors.orange}>
                        {count}
                    </Count>
                    <Typography fontSize="18px" margin="4px 0px 0px 0px">
                        {text}
                    </Typography>
                </SummaryWrapper>
                {!!numberByCountry?.length && (
                    <Row justifyContent="flex-start" display="block">
                        {numberByCountry.map((each) => {
                            const { _id: country, count: countryDataCount } = each;
                            return (
                                <CountryWrapper key={country}>
                                    <StyledTooltip
                                        title={<Typography fontSize="12px">{country || 'Unknown'}</Typography>}
                                        placement="top"
                                        arrow
                                    >
                                        <span>
                                            <img
                                                style={{ marginRight: 10 }}
                                                alt=""
                                                src={`https://static.vainu.io/shared/flags/${country?.toLowerCase()}.png`}
                                            />
                                        </span>
                                    </StyledTooltip>
                                    <span>{localeNumber(countryDataCount)}</span>
                                </CountryWrapper>
                            );
                        })}
                    </Row>
                )}
                {footerComponent && <FooterWrapper>{footerComponent}</FooterWrapper>}
            </div>
        </Container>
    );
};

export default SummaryCard;

const SummaryWrapper = styled(Row)({
    '&&': {
        justifyContent: 'flex-start',
    },

    margin: '18px 0px 18px 0px',
    fontFamily: 'Roboto',
});

const Container = styled(Paper)({
    padding: '24px 24px 28px',
    border: '1px solid #E1E1E1',
    boxSizing: 'border-box',
    borderRadius: 8,
    position: 'relative',
    height: '100%',
});

const CountryWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.grey[50],
    borderRadius: 8,
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    fontSize: 12,
    display: 'inline-block',
    padding: '6px 8px 3px 8px',
    marginRight: 5,
    marginTop: 0,
    marginBottom: 4,
}));

const FooterWrapper = styled('div')({
    marginTop: 0,
    position: 'relative',
    padding: '0px',
});

const Count = styled('div')<{ color: string }>`
    font-weight: bold;
    font-family: Aeroport;
    font-size: 32px;
    color: ${(props) => props.color};
    margin-right: 8px;
`;
