import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckboxChecked: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <rect className="background" x="1.6665" y="1.66675" width="16.6667" height="16.6667" rx="3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.1665 1.66675C2.78579 1.66675 1.6665 2.78604 1.6665 4.16675V15.8334C1.6665 17.2141 2.78579 18.3334 4.1665 18.3334H15.8332C17.2139 18.3334 18.3332 17.2141 18.3332 15.8334V4.16675C18.3332 2.78604 17.2139 1.66675 15.8332 1.66675H4.1665ZM4.1665 10.8338L8.05539 14.8719L15.8332 6.79577L14.1038 5.00008L8.05539 11.2868L5.89586 9.03813L4.1665 10.8338Z"
        />
    </SvgIcon>
);

export default CheckboxChecked;
