import { useCallback } from 'react';

import { getValue, makeFieldType } from 'components/features/Filters/filters/utils';
import { FilterID, GroupOption } from 'components/features/Filters/FilterTypes';
import { SelectOption } from 'components/tokens/select-components/Select';
import { useListsContext } from 'contexts/ListsContext';

import { getFilteredOptions } from '../../common/groupUtils';
import { Slot, filterOptions, isFilterValueEmpty } from './utils/helpers';
import { isShowAddOfficeTypeButton, ValidationResult } from './utils/validation';

interface UseAddRowAction {
    options: GroupOption[];
    slots: Slot[];
    validationResult: ValidationResult[];
    onValueChange: (filterId: FilterID, slotId: number | undefined) => void;
    locationOptionsCount: number;
}

const ADD_SLOT_BUTTON_LABELS = {
    [FilterID.prospect_address_count]: 'Number of offices',
    [FilterID.country_count]: 'Number of countries',
};

export function useAddRowAction({
    options,
    slots,
    validationResult,
    onValueChange,
    locationOptionsCount,
}: UseAddRowAction) {
    const { database } = useListsContext();

    const renderAddRowAction = useCallback(
        (index: number | undefined) => {
            const filteredOptions = getFilteredOptions({ options, database });
            const slot = index === undefined ? undefined : slots[index];
            const rowOptions = filterOptions(filteredOptions, slot);
            let isInnerAddRowLabel = false;

            if (index !== undefined && !slot) {
                return null;
            }

            let label = '';

            if (slot?.type !== 'location') {
                if (!slot?.type) {
                    label = 'Location';
                } else {
                    label = ADD_SLOT_BUTTON_LABELS[slot?.type];
                }
            } else if (slot?.type === 'location') {
                isInnerAddRowLabel = true;
                label = database === 'DOMAIN_DATA_BASIC' ? 'Country' : 'City, map, etc.';
            }

            if (index !== undefined && validationResult[index]?.status !== 'complete') {
                if (slot?.type === 'location') {
                    const lastFilter = slot?.values[slot?.values.length - 1];
                    if (lastFilter && lastFilter.id !== FilterID.location_type) {
                        const lastFilterValue = getValue(makeFieldType(lastFilter.id), lastFilter.value);

                        // Do not show the add filter button if last location filter is empty
                        if (isFilterValueEmpty(lastFilterValue)) {
                            return null;
                        }
                    }
                }

                return {
                    rowOptions,
                    label,
                    isInnerAddRowLabel,
                    handleValueChange: (option: SelectOption) => onValueChange(option.value as FilterID, slot?.id),
                };
            }

            if (index === undefined) {
                const showOTButton = isShowAddOfficeTypeButton(slots, locationOptionsCount);

                if (!slots.length || showOTButton) {
                    return {
                        rowOptions: [{ value: FilterID.location_type, label: '' }],
                        label: 'Location type',
                        isInnerAddRowLabel,
                        handleValueChange: (option: SelectOption) => onValueChange(option.value as FilterID, undefined),
                    };
                }
            }

            return null;
        },
        [locationOptionsCount, database, slots, options, validationResult, onValueChange],
    );

    return renderAddRowAction;
}
