import { Tooltip } from '@mui/material';

import { List } from 'api/types/List';
import { Trigger } from 'api/types/Trigger';
import { ListMenuItemProps } from 'components/templates/ListMenu/ListMenuItem';
import { Link } from 'components/tokens/Link';
import { isUploaded } from 'contexts/ListsContext';

export type ListAction =
    | 'duplicate'
    | 'convertToStatic'
    | 'showInactive'
    | 'favorite'
    | 'share'
    | 'delete'
    | 'remove'
    | 'migrate';

export const getTargetList = (
    props: {
        showInactiveCompanies?: boolean;
        shareList?: boolean;
        convertToStaticList?: boolean;
        isOwner?: boolean;
        isLegacy?: boolean;
        activeTriggers?: Trigger[];
        hasMigrationErrors?: boolean;
        hideMigrate?: boolean;
    } | void,
): ListMenuItemProps<ListAction>[] => {
    const list: ListMenuItemProps<ListAction>[] = [];
    const activeTriggers = props?.activeTriggers;
    const subtitle2 = activeTriggers?.length ? (
        <>
            <Tooltip
                title={
                    <ul>
                        {activeTriggers.map((trigger) => (
                            <li key={trigger.id}>&middot; {trigger.name}</li>
                        ))}
                    </ul>
                }
            >
                <Link href="#" sx={{ color: 'text-dark' }} onClick={(e) => e.preventDefault()}>
                    {activeTriggers.length} trigger{activeTriggers.length > 1 ? 's' : ''}
                </Link>
            </Tooltip>{' '}
            will be paused.
        </>
    ) : undefined;

    if (!props?.isLegacy) {
        list.push({ action: 'duplicate', label: 'Duplicate', icon: 'Duplicate' });
        props?.shareList &&
            list.push({
                action: 'share',
                label: 'Share',
                icon: 'ShareOutline',
                confirmationContent: {
                    title: 'Share',
                    subtitle: '',
                },
            });
        props?.convertToStaticList &&
            list.push({ action: 'convertToStatic', label: 'Convert to Static', icon: 'Lock' });
        props?.showInactiveCompanies &&
            list.push({ action: 'showInactive', label: 'Show inactive companies', icon: 'Show' });
    }
    props?.isOwner
        ? list.push({
              action: 'delete',
              label: 'Delete',
              icon: 'Remove',
              confirmationContent: {
                  title: 'Delete list?',
                  subtitle: '$listName will be permanently deleted.',
                  subtitle2,
                  confirmLabel: 'Delete',
                  cancelLabel: 'Cancel',
                  isConfirmDestructive: true,
              },
          })
        : list.push({
              action: 'remove',
              label: 'Remove',
              icon: 'OffOutlineClose',
              confirmationContent: {
                  title: 'Remove shared list?',
                  subtitle: 'You will lose access to $listName. Other team members will still be able to access it.',
                  confirmLabel: 'Remove',
                  cancelLabel: 'Cancel',
              },
          });
    if (!props?.hideMigrate && props?.isLegacy && props?.isOwner) {
        list.push({
            action: 'migrate',
            label: 'Migrate',
            icon: 'ArrowShortRight',
            confirmationContent: props.hasMigrationErrors
                ? {
                      title: "Some of the filters can't be migrated",
                      subtitle: 'Are you sure you want to migrate? Some filters will be lost.',
                      confirmLabel: 'Migrate',
                  }
                : undefined,
        });
    }
    return list;
};

export function getStaticListOptions(lists: List[], excludedListId?: string) {
    return lists
        .filter((list) => isUploaded(list) && list.id !== excludedListId)
        .map((list) => ({
            action: `static-list-${list.id}`,
            label: list.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'accent', numeric: true }));
}
