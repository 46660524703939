import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Plus = (props) => (
    <SvgIcon {...props}>
        <path d="M13 13V19H11V13H5V11H11V5H13V11H19V13H13Z" />
    </SvgIcon>
);

export default Plus;
