import React from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useParams } from 'react-router-dom';

import { MainPath, SettingsPath } from 'components/beta/Platform/Router/routePaths';
import MainListItem from 'components/tokens/MainListItem';
import Typography from 'components/tokens/Typography';

type SettingTab = {
    id: SettingsPath;
    name: string;
    type?: 'admin' | 'basic';
};

export type SettingsMenuProps = {
    isAdmin: boolean;
    hasApiPermission: boolean;
};

const settingsTabs: SettingTab[] = [
    { id: SettingsPath.Team, name: 'Profile & Team', type: 'basic' },
    { id: SettingsPath.Usage, name: 'Usage' },
    { id: SettingsPath.APIKeys, name: 'API Access' },
];

export const SettingsMenu: React.FC<SettingsMenuProps> = ({ isAdmin, hasApiPermission }) => {
    const { '*': activeTab } = useParams<{ '*': string }>();

    const filterBasedOnConditions = (tab: SettingTab) => {
        return !((tab.type === 'admin' && !isAdmin) || (tab.id === SettingsPath.APIKeys && !hasApiPermission));
    };

    return (
        <Box>
            <Typography weight="bold" color="dark">
                Categories
            </Typography>
            <List sx={{ marginTop: 1.5 }}>
                {settingsTabs.filter(filterBasedOnConditions).map((tab) => (
                    <MainListItem key={tab.id} to={`/${MainPath.Settings}/${tab.id}`} selected={activeTab === tab.id}>
                        {tab.name}
                    </MainListItem>
                ))}
            </List>
        </Box>
    );
};

export default SettingsMenu;
