import React from 'react';

import Box from '@mui/material/Box';

import { PasswordParams } from 'api/userProfile';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';

interface ChangePasswordProps {
    passwordFields: PasswordParams;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    errorMessage?: Partial<PasswordParams>;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ onChange, errorMessage, passwordFields }) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gap: 1,
                gridTemplateColumns: '1fr 1fr',
                '&> span': {
                    alignSelf: 'center',
                },
                '@media (max-width: 576px)': {
                    gridTemplateColumns: '1fr',
                },
            }}
        >
            <Typography variant="small">Your current password:</Typography>
            <TextField
                type="password"
                autoComplete="current-password"
                name="current_password"
                value={passwordFields.current_password}
                onChange={onChange}
                compact
                placeholder=""
                sx={{ width: '100%' }}
                required
                status={errorMessage?.current_password ? 'error' : 'default'}
                errorText={errorMessage?.current_password}
            />
            <Typography variant="small">New password:</Typography>

            <TextField
                type="password"
                autoComplete="new-password"
                name="password1"
                value={passwordFields.password1}
                onChange={onChange}
                required
                compact
                placeholder=""
                sx={{ width: '100%' }}
                status={errorMessage?.password1 ? 'error' : 'default'}
                errorText={errorMessage?.password1}
            />

            <Typography variant="small">Confirm new password:</Typography>

            <TextField
                type="password"
                autoComplete="new-password"
                name="password2"
                value={passwordFields.password2}
                onChange={onChange}
                required
                compact
                placeholder=""
                sx={{ width: '100%' }}
                status={errorMessage?.password2 ? 'error' : 'default'}
                errorText={errorMessage?.password2}
            />
            {errorMessage?.non_field_errors && (
                <Typography variant="small" sx={{ color: 'error.main', gridColumn: '1/3' }}>
                    {errorMessage.non_field_errors}
                </Typography>
            )}
        </Box>
    );
};

export default ChangePassword;
