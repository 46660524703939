import React from 'react';

import { DynamicToStaticListAsyncProcess } from 'api/types/UserAsyncProcess';
import Icon from 'components/tokens/Icon';

import { AsyncProcessNotificationProps } from '../NotificationItem';
import NotificationItemContent from '../NotificationItemContent';
import { progress } from '../utils';
import { NotificationStateBasedData } from './CRMBulkExport';

const getStateBasedData = (notification: DynamicToStaticListAsyncProcess): NotificationStateBasedData => {
    switch (notification.state) {
        case 'process':
            return {
                title: `Adding ${notification.meta_data.total} companies onto your static list “${notification.meta_data.name}”.`,
                progress: progress(notification.meta_data.current, notification.meta_data.total),
                date: notification.created,
                hoverText: 'In progress',
            };
        case 'stopped':
            return {
                title: 'Process stopped',
                content: `The process was stopped. No companies were added onto your static list “${notification.meta_data.name}”.`,
                date: notification.finished,
                error: true,
                hoverText: 'Stopped',
            };
        case 'completed':
            return {
                title: '✔️ Companies added',
                content: `${notification.meta_data.total} companies successfully added onto your static list “${notification.meta_data.name}”.`,
                date: notification.finished,
                hoverText: 'In progress',
            };
        default:
            return {
                title: 'Process failed',
                content: `Creating static list failed. Please retry or contact support for details.`,
                date: notification.finished,
                hoverText: 'Failed',
            };
    }
};
const DynamicToStaticList: React.FC<AsyncProcessNotificationProps<DynamicToStaticListAsyncProcess>> = ({
    notification,
    handleMarkAsSeen,
}) => {
    const notificationContent = getStateBasedData(notification);

    if (!notification.state || !notification.meta_data) return null;

    return (
        <NotificationItemContent
            icon={<Icon type="Lock" />}
            seen={notification.seen}
            state={notification.state}
            {...notificationContent}
            handleMarkAsSeen={handleMarkAsSeen}
        />
    );
};

export default DynamicToStaticList;
