import { memo } from 'react';

import { Grid, Box } from '@mui/material';
import { range } from 'utilities';

import { TabChangeHandler } from 'components/modules/profiles/Profile';
import { TabSlug } from 'components/modules/profiles/Profile/tabs';
import { AreaChart, BarChart } from 'components/templates/charts';
import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { formatDate } from 'utilities/date';

import NumberBox, { NumberBoxSkeleton } from '../NumberBox';
import { FinancialDataPoint } from '../types';

export interface FinancialsOverviewProps {
    fiscalPeriodEnd?: string;
    revenue?: FinancialDataPoint;
    employees?: FinancialDataPoint;
    ebit?: FinancialDataPoint;
    // groupRevenue?: ProspectFinancialGroupDataPoint;
    // groupEmployees?: ProspectFinancialGroupDataPoint;
    skeleton?: boolean;
    onGoToClick?: TabChangeHandler;
}

export const FinancialsOverview: React.FC<FinancialsOverviewProps> = memo(
    ({
        fiscalPeriodEnd,
        revenue,
        employees,
        ebit,
        // groupRevenue,
        // groupEmployees,
        skeleton = false,
        onGoToClick,
    }) => {
        if (skeleton) {
            return (
                <Frame padding="large">
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}
                    >
                        <Typography variant="body1" weight="bold" color="dark" sx={{ lineHeight: '40px' }}>
                            Financials
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {/* {range(3).map((index) => (
                        <Grid key={index} item xs={12} md={4}>
                            <NumberBoxSkeleton height={105} />
                        </Grid>
                    ))} */}
                        {range(3).map((index) => (
                            <Grid key={index} item xs={12} md={4}>
                                <NumberBoxSkeleton height={291} />
                            </Grid>
                        ))}
                    </Grid>
                </Frame>
            );
        }
        if (!revenue && !employees && !ebit) {
            return null;
        }
        // const numGroupBoxes = (groupRevenue ? 1 : 0) + (groupEmployees ? 1 : 0);
        // const groupCols = numGroupBoxes === 0 ? 0 : numGroupBoxes === 1 ? 12 : 6;
        const numBoxes = (revenue ? 1 : 0) + (employees ? 1 : 0) + (ebit ? 1 : 0);
        const cols = numBoxes === 3 ? 4 : 6;
        return (
            <Frame padding="large">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="body1" weight="bold" color="dark">
                        Financials
                    </Typography>
                    {fiscalPeriodEnd !== undefined && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                variant="caption"
                                color="subtle"
                                sx={{ marginRight: 1, fontStyle: 'italic', '& .date': { fontWeight: 500 } }}
                            >
                                {'Last fiscal year end: '}
                                <span className="date">{formatDate(fiscalPeriodEnd, 'long')}</span>
                            </Typography>
                            {onGoToClick && (
                                <Button
                                    onClick={() => onGoToClick(TabSlug.Financials, true)}
                                    variant="tertiary"
                                    startIcon={<Icon type="CircleRight" fontSize="inherit" />}
                                />
                            )}
                        </Box>
                    )}
                </Box>
                <Grid container spacing={2}>
                    {/* {groupCols !== 0 && (
                        <>
                            {groupRevenue !== undefined && (
                                <Grid item xs={12} md={groupCols}>
                                    <NumberBox
                                        flat
                                        background="grey"
                                        label="Group revenue"
                                        value={groupRevenue.value}
                                        estimate={groupRevenue.estimate}
                                        valueStyle="currency"
                                        currency={groupRevenue.currency}
                                    />
                                </Grid>
                            )}
                            {groupEmployees !== undefined && (
                                <Grid item xs={12} md={groupCols}>
                                    <NumberBox
                                        flat
                                        background="grey"
                                        label="Group employees"
                                        value={groupEmployees.value}
                                        estimate={groupEmployees.estimate}
                                        valueStyle="integer"
                                    />
                                </Grid>
                            )}
                        </>
                    )} */}
                    {revenue !== undefined && (
                        <Grid item xs={12} md={cols}>
                            <NumberBox
                                flat
                                label="Revenue"
                                value={revenue.value}
                                valueStyle="currency"
                                change={revenue.change}
                                currency={revenue.currency}
                                reportYear={revenue.year}
                                chart={
                                    revenue.data !== undefined && (
                                        <BarChart
                                            data={revenue.data}
                                            height={150}
                                            max={3}
                                            cutFromStart
                                            numberStyle="currency"
                                            currency={revenue.currency}
                                        />
                                    )
                                }
                            />
                        </Grid>
                    )}
                    {employees !== undefined && (
                        <Grid item xs={12} md={cols}>
                            <NumberBox
                                flat
                                label="Employees"
                                value={employees.value}
                                valueStyle="integer"
                                change={employees.change}
                                reportYear={employees.year}
                                chart={
                                    employees.data !== undefined && (
                                        <BarChart data={employees.data} height={150} max={3} cutFromStart />
                                    )
                                }
                            />
                        </Grid>
                    )}
                    {ebit !== undefined && (
                        <Grid item xs={12} md={cols}>
                            <NumberBox
                                flat
                                label="EBIT (%)"
                                value={ebit.value}
                                valueStyle="percent"
                                change={ebit.change}
                                reportYear={ebit.year}
                                chart={
                                    ebit.data !== undefined && (
                                        <AreaChart
                                            data={ebit.data}
                                            height={150}
                                            variant="posneg"
                                            max={3}
                                            numberStyle="percent"
                                            cutFromStart
                                        />
                                    )
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </Frame>
        );
    },
);

export default FinancialsOverview;
