import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';

import { ArrowRightAlt } from '@mui/icons-material';
import { Grid, Tooltip, Autocomplete, TextField, CircularProgress, Button, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { assertArray } from 'utilities';

import { getCompaniesFromMultipleNordicDBs } from 'api/search_companies';
import { organizationProfileType } from 'api/types/Organization';
import { getHQ } from 'components/features/Locations/utils';
import { getFullLocation } from 'components/templates/CompanyCard/utils';
import { useAxiosContext } from 'contexts/AxiosContext';
import { NordicDatabase } from 'contexts/ListsContext';
import { usePermissionContext } from 'contexts/PermissionContext';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { OrganizationResult, OrganizationResultAPIResponse } from 'contexts/types/FilterCompanies';
import useDebounce from 'hooks/useDebounce';

import { CRMLogoAlt } from '../../common/CRMLogo';
import { UnderlineField } from '../../common/OnboardingCommon';
import LogoSvg from '../../svg/LogoSvg';
import RedirectToSvgIcon from '../../svg/RedirectToSvg';
import { ProspectExport } from '../../types';
import { CrmName } from '../../types/Crm';
import { getCRMName } from '../../utils';
import ProspectOption, { ProspectOptionProps } from './ProspectOption';

type Props = Omit<React.ComponentProps<typeof Grid>, 'onSubmit'> & {
    crm: CrmName;
    onSubmit: (data: CompanyMap) => Promise<void>;
    list: ProspectExport[];
    error?: string;
    isLoading?: boolean;
    hasMore?: boolean;
    loadMore?: () => void;
    editDisabled?: boolean;
};

export type CompanyMap = {
    [targetFk: string]: ProspectOptionProps;
};

const CompanyMatchForm: React.FC<Props> = ({
    crm,
    onSubmit,
    list,
    error,
    isLoading,
    hasMore,
    loadMore,
    editDisabled,
}) => {
    const axios = useAxiosContext();
    const scrollContainer = useRef<HTMLDivElement | null>(null);

    const { countryPermissions } = usePermissionContext();
    const [matchedObject, setMatchedObject] = useState<CompanyMap>();
    const [activeRow, setActiveRow] = useState<string | null>(null);
    const [focusedCompany, setFocusedCompany] = useState<string>('');
    const [options, setOptions] = React.useState<Partial<ProspectOptionProps>[]>([]);
    const [searchKeyword, setSearchkeyword] = useState<string>('');
    const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
    const [isResetClicked, setIsResetClicked] = useState(false);

    const { isAccountAdmin, connections } = useContext(PermissionContext);

    const crmConnection = connections?.find((each) => each?.crm_name?.toLowerCase() === crm);
    const crmBaseUrl = crmConnection?.instance?.url ?? '';

    useEffect(() => {
        if (debouncedSearchKeyword.trim()) {
            getCompaniesFromMultipleNordicDBs(axios, {
                companyName: debouncedSearchKeyword,
                // manual matching form is only available for Nordic connector
                databases: countryPermissions.filter((country) => country !== 'DOMAIN_DATA_BASIC') as NordicDatabase[],
            }).then(({ results }) => {
                const response: OrganizationResult[] = (results as OrganizationResultAPIResponse[]).map((profile) => ({
                    ...profile,
                    profileType: organizationProfileType,
                    id: profile.business_id,
                }));

                setOptions(transformProspectSearchResponse(response));
            });
        }
    }, [axios, countryPermissions, debouncedSearchKeyword]);

    useEffect(() => {
        if (isResetClicked) {
            const timer = setTimeout(() => {
                setIsResetClicked(false);
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [isResetClicked]);

    const handleNextPage = useCallback(() => {
        if (!scrollContainer.current) return;

        const isScrolledToBottom =
            scrollContainer.current.scrollTop + scrollContainer.current.clientHeight >=
            scrollContainer.current.scrollHeight * 0.8; // 80% of scrollContainer has been scrolled

        const shouldRequestNextPage = hasMore && isScrolledToBottom;

        if (shouldRequestNextPage) {
            loadMore?.();
        }
    }, [hasMore, loadMore]);

    useEffect(() => {
        const container = scrollContainer.current;

        container?.addEventListener('scroll', handleNextPage);

        return () => {
            container?.removeEventListener('scroll', handleNextPage);
        };
    }, [scrollContainer, handleNextPage]);

    const handleSubmit = async () => {
        await onSubmit(matchedObject as CompanyMap);
        handleReset();
    };

    const handleReset = () => {
        setMatchedObject({} as CompanyMap);
        setIsResetClicked(true);
        handleClose();
    };

    const handleSelect = (targetFk: string, data: ProspectOptionProps) => {
        if (!data) {
            const copyMatchedObject = matchedObject || {};
            delete copyMatchedObject[targetFk];
            setMatchedObject(copyMatchedObject);
        } else {
            setMatchedObject({
                ...(matchedObject || {}),
                [targetFk]: data,
            });
        }
        handleClose();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchkeyword(value);
    };

    const handleClose = () => {
        setActiveRow(null);
        setOptions([]);
        setSearchkeyword('');
    };

    const getCrmLink = (targetFk: string) => {
        try {
            return crmConnection?.account_link_template?.replace('{}', targetFk) ?? '';
        } catch (e) {
            return '';
        }
    };

    const getCrmShortLink = (companyTargetFkId: string) => {
        switch (crm as string) {
            case 'hubspot':
                return `https://app.hubspot.com/.../company/${companyTargetFkId}/`;
            case 'salesforce':
            case 'salesforcev3':
            case 'salesforcesandbox':
                return `${crmBaseUrl}/${companyTargetFkId}`;
            case 'dynamics':
                const url = `${crmBaseUrl}/main.aspx...id=${companyTargetFkId}`.replace(/([^:])(\/\/+)/g, '$1/');
                return url;
            case 'pipedrive':
                let base_url_no_slash;
                if (crmBaseUrl?.slice(-1) === '/') {
                    base_url_no_slash = crmBaseUrl.slice(0, -1);
                } else {
                    base_url_no_slash = crmBaseUrl;
                }
                return `${base_url_no_slash}/organization/${companyTargetFkId}`;
            default:
                return `${crmBaseUrl}...${companyTargetFkId}`;
        }
    };

    const sortByCompanyName = (a: ProspectExport, b: ProspectExport) => {
        const aCompanyName = getCompanyName(a);
        const bCompanyName = getCompanyName(b);
        if (!aCompanyName) {
            return 1;
        }
        if (!bCompanyName) {
            return -1;
        }
        return 0;
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={7}>
                    <Header>{getCRMName(crm)}</Header>
                    <SubHeader>Help match remaining companies</SubHeader>
                </Grid>
                <Grid item xs={5}>
                    <Header>Vainu</Header>
                    <SubHeader>Find a match from Vainu to stay updated. </SubHeader>
                </Grid>
                <Wrapper ref={scrollContainer} key={`manual-matching-${isResetClicked}`}>
                    <div style={{ padding: 4 }}>
                        <Grid container spacing={1}>
                            {list.sort(sortByCompanyName).map((company, i) => {
                                const companyName = getCompanyName(company);
                                const crmLink = getCrmLink(company.target_fk);
                                const crmShortUrl = getCrmShortLink(company.target_fk);
                                const last_10_chars = `...${crmLink.slice(crmLink.length - 10).replace(/\/$/, '')}`;
                                const showCrmLink = company.id === focusedCompany;
                                const defaultOptions =
                                    company.match_candidates?.map((candidate) => {
                                        return {
                                            value: candidate.vid,
                                            label: candidate.company_name,
                                            company_name: candidate.company_name,
                                            logo: '',
                                            visiting_city: candidate.visiting_city,
                                            domain: candidate.domain,
                                            country: candidate.country,
                                            business_id: candidate.business_id,
                                        } as ProspectOptionProps;
                                    }) ?? [];
                                const loading =
                                    company.id === activeRow &&
                                    !!searchKeyword &&
                                    options?.length === 0 &&
                                    defaultOptions?.length === 0;
                                return (
                                    <Grid
                                        item
                                        key={company.id + i}
                                        xs={12}
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{
                                            opacity: !activeRow || activeRow === company.id ? 1 : 0.4,
                                            transition: 'opacity 200ms',
                                        }}
                                    >
                                        <Grid item xs={5}>
                                            <Tooltip
                                                title={crmShortUrl}
                                                arrow
                                                placement="top-start"
                                                enterDelay={1000}
                                                enterNextDelay={1000}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            fontSize: 12,
                                                            color: '#fff',
                                                            background: '#0A0A0A',
                                                            padding: '8px',
                                                            boxShadow:
                                                                '0px 1px 2px rgba(10, 10, 10, 0.1), 0px 4px 12px rgba(10, 10, 10, 0.15)',
                                                            borderRadius: '4px',
                                                        },
                                                    },
                                                    arrow: {
                                                        sx: {
                                                            color: '#0A0A0A',
                                                            left: '20px !important',
                                                        },
                                                    },
                                                }}
                                            >
                                                <CRMLink
                                                    show={showCrmLink}
                                                    onMouseEnter={() => setFocusedCompany(company.id)}
                                                    onMouseLeave={() => setFocusedCompany('')}
                                                >
                                                    <UnderlineField
                                                        label={
                                                            <>
                                                                {showCrmLink && crmLink ? (
                                                                    <a
                                                                        href={crmLink}
                                                                        // eslint-disable-next-line react/jsx-no-target-blank
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {companyName || last_10_chars}
                                                                        <RedirectToSvgIcon
                                                                            style={{ marginLeft: '10px' }}
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    companyName || last_10_chars
                                                                )}
                                                            </>
                                                        }
                                                    />
                                                </CRMLink>
                                            </Tooltip>
                                        </Grid>
                                        {activeRow === company.id && (
                                            <Grid item xs="auto">
                                                <CRMLogoAlt name={crm} width={15} height={15} />
                                            </Grid>
                                        )}
                                        <Grid item xs="auto">
                                            <TwoArrow htmlColor="#b2c0c8" />
                                        </Grid>

                                        {activeRow === company.id && (
                                            <Grid item xs="auto">
                                                <LogoSvg width={15} height={24} />
                                            </Grid>
                                        )}
                                        <Grid item xs={5}>
                                            <Autocomplete
                                                sx={{ width: 300 }}
                                                open={company.id === activeRow}
                                                componentsProps={{
                                                    clearIndicator: {
                                                        sx: {
                                                            height: 38,
                                                            padding: '0px !important',
                                                        },
                                                    },
                                                }}
                                                onOpen={() => {
                                                    setActiveRow(company.id);
                                                }}
                                                onClose={handleClose}
                                                getOptionLabel={(option: ProspectOptionProps) => {
                                                    return option.label;
                                                }}
                                                renderOption={(d, x = {} as ProspectOptionProps) => {
                                                    return <ProspectOption key={x.value} {...d} data={x} />;
                                                }}
                                                isOptionEqualToValue={(option, value) => {
                                                    return option.value === value.value;
                                                }}
                                                onChange={(e, data) => {
                                                    e.preventDefault();
                                                    if (data) {
                                                        handleSelect(company.target_fk, data);
                                                    }
                                                }}
                                                disabled={editDisabled}
                                                options={
                                                    (options?.length
                                                        ? options
                                                        : defaultOptions) as ProspectOptionProps[]
                                                }
                                                loading={loading}
                                                noOptionsText={<NoOptionText>No options</NoOptionText>}
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            placeholder={
                                                                company.id === activeRow
                                                                    ? 'What name do they go by?'
                                                                    : ''
                                                            }
                                                            label=""
                                                            onChange={handleChange}
                                                            InputLabelProps={{
                                                                sx: {
                                                                    root: {
                                                                        '& input::placeholder': {
                                                                            fontSize: '11px',
                                                                            textTransform: 'uppercase',
                                                                            fontWeight: 500,
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                sx: {
                                                                    input: {
                                                                        fontSize: 15,
                                                                    },
                                                                },
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <div>
                            <Grid container justifyContent="center">
                                {isLoading && (
                                    <CircularProgress
                                        value={100}
                                        thickness={1.8}
                                        size={50}
                                        data-testid="list-loading-indicator"
                                    />
                                )}
                            </Grid>
                        </div>
                    </div>

                    {/* CTA button */}
                    <ButtonWrapper>
                        <div className="wrapper">
                            <DiscardButton
                                color="primary"
                                variant="outlined"
                                size="large"
                                disabled={!!error || isEmpty(matchedObject) || !isAccountAdmin}
                                onClick={handleReset}
                            >
                                Discard changes
                            </DiscardButton>
                            <SaveButton
                                color="primary"
                                variant="contained"
                                size="large"
                                disabled={!!error || isEmpty(matchedObject) || !isAccountAdmin}
                                onClick={handleSubmit}
                            >
                                {isLoading ? <CircularProgress color="inherit" size={26} /> : 'Save changes'}
                            </SaveButton>
                        </div>
                    </ButtonWrapper>
                </Wrapper>
            </Grid>
        </div>
    );
};

const transformProspectSearchResponse = (data: OrganizationResult[]) => {
    return data.map((prospect) => ({
        value: prospect.id,
        label: prospect.name,
        company_name: prospect.name,
        logo: prospect.logo_url,
        visiting_city: getFullLocation(getHQ(assertArray(prospect.business_units))),
        domain: prospect.website,
        business_id: prospect.id,
    }));
};

const TwoArrow = ({ htmlColor }: { htmlColor: string }) => {
    return (
        <span style={{ position: 'relative' }}>
            <ArrowRightAlt htmlColor={htmlColor} style={{ fontSize: 20 }} />
            <ArrowRightAlt
                style={{ transform: 'rotate(180deg)', position: 'absolute', left: 0, fontSize: 20 }}
                htmlColor={htmlColor}
            />
        </span>
    );
};

const getCompanyName = (company: ProspectExport): string => {
    if (typeof company?.match_data?.company_name === 'string') {
        return company?.match_data?.company_name;
    }
    return company?.match_data?.company_name?.find((each: string) => !!each) ?? '';
};

export default CompanyMatchForm;

const Header = styled('div')({
    fontSize: '14px',
    color: '#46525C',
    fontWeight: 500,
});

const SubHeader = styled('div')({
    color: '#8C9BA8',
    fontSize: '12px',
    marginBottom: 10,
});

const NoOptionText = styled('div')({
    fontSize: 12,
    textAlign: 'center',
});

const CRMLink = styled('div')<{ show: boolean }>(({ show }) =>
    show
        ? {
              color: '#2F80ED',
              display: 'flex',
              fontSize: 14,
          }
        : {},
);

const ButtonWrapper = styled('div')({
    marginTop: 30,
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: '24px 40px 32px 40px',
    width: '100%',
    zIndex: 999,
    '& .wrapper': { display: 'flex', float: 'right', paddingRight: 8 },
    background: '#F9F9F9',
    boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px -11px 32px -8px rgba(137, 137, 137, 0.1), inset 0px 1px 1px #DBE1E5',
});

const ActionButton = styled(Button)({
    textTransform: 'uppercase',
    padding: 16,
    maxHeight: 50,
    marginLeft: 10,
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 16,
});

const SaveButton = styled(ActionButton)({
    background: '#000000',
    color: '#fff',
    '&.Mui-disabled ': {
        color: 'rgba(0, 0, 0, 0.26)',
        background: 'rgba(0, 0, 0, 0.12)',
    },
});

const DiscardButton = styled(ActionButton)({
    background: '#fff',
    color: '#0A0A0A',
    border: '1px solid #0A0A0A',
    '&.Mui-disabled ': {
        color: 'rgba(0, 0, 0, 0.26)',
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
});

const Wrapper = styled('div')`
    overflow-y: scroll;
    display: grid;
    grid-auto-rows: min-content;
    height: 35vh;
    width: 100%;
`;
