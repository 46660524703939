import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { isEmpty } from 'lodash';

import { useCompany } from 'api/company';
import { Company } from 'api/types/company';
import { SignalAPIResponse } from 'api/types/signals';
import ConnectorDrawerWithButton from 'components/modules/connectors/common/ConnectorDrawerWithButton';
import useConnectorsApi from 'components/modules/connectors/hooks/useConnectorsApi';
import { TabEnum } from 'components/modules/connectors/types';
import { CRMObject, CRMObjectFields, CrmName, FieldMapping } from 'components/modules/connectors/types/Crm';
import SourceToDestination from 'components/templates/SourceToDestination';
import Button from 'components/tokens/Button';
import Chip from 'components/tokens/Chip';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { Database, useListsContext } from 'contexts/ListsContext';
import { useTrigger, Destination, LeadPreview } from 'contexts/TriggerContext';
import { enabledCRMs } from 'utilities/triggers';

function capitalize(str: string) {
    if (!str) {
        return '';
    }
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`;
}

const placeHolderData = {
    business_id: '',
    link: '',
    visiting_address: '',
    visiting_postal: '',
    visiting_city: '',
};

const fetchTemplate = async (
    axios: AxiosInstance,
    relation: { db: Database; key: string },
    lead: number,
    trigger: string,
    lead_type: string,
) => {
    const payload = {
        message_type: 'trigger_leads',
        'relation.db': relation.db,
        'relation.key': relation.key,
        lead: lead.toString(),
        lead_type,
        trigger,
    };
    const { data } = await axios.get(`/mapi/message_queue_template/?${new URLSearchParams(payload).toString()}`);
    return data;
};

const StyledNotesContainer = styled('div')`
    width: 100%;
    min-height: 100px;
    background: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 5px;
`;

const StyledLink = styled('a')`
    color: #0a0a0a;
    text-decoration: underline;
    font-size: 14px;
    text-overflow: ellipsis;
`;

const StyledLineContainer = styled('div')`
    text-overflow: ellipsis;
    height: 16px;
    overflow: hidden;
`;

type RendererFunction = (v: string[]) => JSX.Element;

const NotesRenderer: RendererFunction = (notes) => {
    return (
        <StyledNotesContainer>
            {notes.map((note, idx) => (
                <Typography variant="body2" color="subtle" key={idx}>
                    {note}
                </Typography>
            ))}
        </StyledNotesContainer>
    );
};

const LinkRenderer: RendererFunction = ([link]) => {
    return (
        <StyledLineContainer>
            <StyledLink href={link} target="_blank">
                {link}
            </StyledLink>
        </StyledLineContainer>
    );
};

interface Props {
    open: boolean;
    onClose: () => void;
    data: LeadPreview | null;
    selectedDestination?: Destination | null;
}

const PreviewModal: React.FC<Props> = ({ data, open = true, onClose = () => {}, selectedDestination }) => {
    const { database = 'DOMAIN_DATA_BASIC' } = useListsContext();
    const [state, , { activeCRMIntegrationSettings }] = useTrigger();
    const axios = useAxiosContext();
    // todo: migrate to getCRMAccountSettings()
    const { getCRMAccountSettingsLegacy } = useConnectorsApi();
    const [selectedObject, setSelectedObject] = useState<CRMObject | undefined>(undefined);
    const [openDataMappingDrawer, setOpenDataMappingDrawer] = useState<{ tab?: TabEnum; object?: CRMObject }>();

    const { id: triggerID = '', destinations, name } = state;
    const mainCRMdestination = selectedDestination?.system
        ? selectedDestination
        : destinations.find((i) => i?.crm_objects?.length);

    useEffect(() => {
        setSelectedObject(mainCRMdestination?.crm_objects?.[0]);
    }, [mainCRMdestination?.crm_objects]);

    const mainCRMObject = selectedObject || mainCRMdestination?.crm_objects?.[0];
    const crmName = mainCRMdestination?.system?.toLowerCase() as CrmName;

    const mainCRMIntegrationSettings = activeCRMIntegrationSettings.find(
        (i) => i.integration_target?.toLowerCase() === crmName,
    );
    const { data: crmFields } = useQuery({
        queryKey: ['crm-config', crmName],

        queryFn: () => getCRMAccountSettingsLegacy(crmName).then((res) => res.data?.objects),
        staleTime: Infinity,
        gcTime: 1000 * 3600,
        enabled: enabledCRMs.includes(crmName),
    });

    const CRMFieldMapping = mainCRMIntegrationSettings?.field_mapping?.filter((i) => i.target_object === mainCRMObject);

    const { company, lead } = data || {};

    const { data: companyData = placeHolderData } = useCompany(
        company?.business_id || '',
        {
            basic: true,
            contacts: true,
        },
        !!company?.business_id,
    );

    const { data: template } = useQuery({
        queryKey: [`template`, company?.business_id],
        queryFn: () =>
            fetchTemplate(
                axios,
                { db: database, key: company?.business_id || '' },
                lead?.id ? parseInt(lead.id, 10) : 0,
                triggerID,
                lead?.dynamic_values ? 'GeneratedLead' : 'Lead',
            ),
        enabled: !!company?.business_id && !!lead?.id,
        refetchOnWindowFocus: false,
    });

    const fieldMappingData = getFieldMappingData(
        { ...(data || {}), company: { ...companyData, id: 0 } },
        // TODO: Fix me please!
        mainCRMObject as CRMObject,
        template,
        name || '',
        CRMFieldMapping,
        crmFields as Record<CRMObject, CRMObjectFields>,
    );
    return (
        <Dialog
            open={open}
            onBackdropClick={onClose}
            fullWidth
            maxWidth="sm"
            BackdropProps={{
                invisible: true,
            }}
        >
            <DialogTitle>
                <SourceToDestination target={crmName} source="trigger" />
                <Typography variant="h6">Preview & edit</Typography>
                <Typography variant="small">
                    Here you’re able to see & edit how the events are shown at the destinations.
                </Typography>
            </DialogTitle>
            <DialogContent>
                {mainCRMdestination?.crm_objects?.length ?? 0 <= 1 ? null : (
                    <Box sx={{ display: 'flex', gap: 0.5, paddingY: 1 }}>
                        {mainCRMdestination?.crm_objects?.map((item: CRMObject, index: number) => (
                            <Chip
                                key={item}
                                color="default"
                                label={item}
                                clickable
                                onClick={() => {
                                    setSelectedObject(item);
                                }}
                                state={
                                    selectedObject === item || (index === 0 && !selectedObject) ? 'active' : 'default'
                                }
                            />
                        ))}
                    </Box>
                )}
                {fieldMappingData.map(({ title, value, renderer }) => (
                    <Grid container wrap="nowrap" key={title} sx={{ paddingY: 1 }}>
                        <Grid item xs={4}>
                            <Typography variant="body2">{title}:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            {renderer ? (
                                renderer(getFieldMappingValue(value))
                            ) : (
                                <Typography variant="body2">{value}</Typography>
                            )}
                        </Grid>
                    </Grid>
                ))}
                <Grid container justifyContent="flex-end">
                    <ConnectorDrawerWithButton
                        crm={crmName}
                        tab={openDataMappingDrawer?.tab || 'mapping'}
                        object={openDataMappingDrawer?.object}
                        handleClick={() =>
                            setOpenDataMappingDrawer({
                                tab: 'mapping',
                                object: selectedObject,
                            })
                        }
                        handleClose={() => setOpenDataMappingDrawer({})}
                        open={!isEmpty(openDataMappingDrawer?.object)}
                        buttonText="Edit data mapping"
                        buttonProps={{ disableRipple: true, variant: 'tertiary', size: 'small' }}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="flat" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

function getFieldMappingValue(value: string | string[]): string[] {
    return Array.isArray(value) ? value : [value];
}

export default PreviewModal;

const getFieldMappingData = (
    data: Partial<LeadPreview>,
    mainCRMObject: CRMObject,
    template: { context: { triggering_event: string; title: string; vainu_date: string; note: string } },
    name: string,
    CRMFieldMapping?: FieldMapping<CRMObject>[],
    crmFields?: Record<CRMObject, CRMObjectFields>,
) => {
    // items later in array have higher priority if multiple mappings to same target field
    const potentialExportObject: Record<string, { value: string | string[]; renderer: null | RendererFunction }> = {};
    const mainObjectArray = [{ title: 'Sending as', value: capitalize(mainCRMObject), renderer: null }];
    const getLabel = (name: string, object: CRMObject) => {
        return crmFields?.[object]?.fields?.find((i) => i.value === name)?.label || name;
    };

    const pickData = ({ source_field, target_field, target_object, source_object }: FieldMapping<CRMObject>) => {
        const label = getLabel(target_field, target_object);
        if (source_field === 'trigger_details') {
            const value = template?.context?.note?.split('\n') || [];
            if (value.length) {
                potentialExportObject[label] = { value, renderer: NotesRenderer };
            }
            return null;
        }
        if (source_field === 'trigger_date') {
            const value = template?.context?.vainu_date;
            if (value) {
                potentialExportObject[label] = { value, renderer: null };
            }
            return null;
        }
        if (source_field === 'trigger_events') {
            const value = template?.context?.triggering_event;
            if (value) {
                potentialExportObject[label] = { value, renderer: null };
            }
            return null;
        }
        if (source_field === 'trigger_profile') {
            const value = name;
            if (value) {
                potentialExportObject[label] = { value, renderer: null };
            }
            return null;
        }
        if (source_object === 'Prospect') {
            const value = data?.company?.[source_field as keyof Company] || null;
            if (value) {
                potentialExportObject[label] = { value, renderer: validateUrl(value) ? LinkRenderer : null };
            }
            return null;
        }
        if (source_object === 'Lead') {
            let value = data?.lead?.[source_field as keyof SignalAPIResponse] || null;
            if (source_field === 'title') {
                value = template?.context?.title;
            }
            if (value) {
                const stringValue = typeof value === 'string' ? value : '';
                potentialExportObject[label] = {
                    value: stringValue,
                    renderer: validateUrl(stringValue) ? LinkRenderer : null,
                };
            }
            return null;
        }
        if (source_object === 'Contact') {
            return null;
        }
        if (source_field) {
            potentialExportObject[label] = {
                value: source_field,
                renderer: validateUrl(source_field) ? LinkRenderer : null,
            };
        }
    };
    CRMFieldMapping?.forEach(pickData);
    const titles = Object.keys(potentialExportObject);
    titles.sort((a, b) => (a > b ? -1 : 1));
    const rest = titles.map((title) => ({
        title,
        ...potentialExportObject[title],
    }));
    return [...mainObjectArray, ...rest];
};

const validateUrl = (url: string) => {
    if (!url) {
        return false;
    }
    return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        url,
    );
};
