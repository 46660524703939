import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import Typography from 'components/tokens/Typography';

export const NotFound: React.FC = () => (
    <Box sx={{ display: 'flex', height: '60%', justifyContent: 'center', alignItems: 'center', padding: 4 }}>
        <Box>
            <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
                We’re a bit at a loss
            </Typography>
            <Typography sx={{ marginBottom: 2 }}>
                We couldn’t find the page you were looking for. If you think this is an error, please{' '}
                {/* eslint-disable */}
                <a target="_blank" href="https://help.vainu.app/">
                    contact the support
                </a>
                .{/* eslint-enable */}
            </Typography>
            <Typography weight="semibold">
                <Link to="/">Go to start</Link>
            </Typography>
        </Box>
    </Box>
);

export default NotFound;
