import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import QueryString from 'qs';

import { AsyncProcess, GenericAsyncProcess, UserAsyncProcess } from './types/UserAsyncProcess';

export const getAsyncProcesses = <T extends GenericAsyncProcess = AsyncProcess>(
    axios: AxiosInstance,
    params?: AxiosRequestConfig['params'],
) => {
    return axios.get<T[]>('/api/poll_async_processes/', {
        params,
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
    });
};

export const getAccountWideAsyncProcesses = <T extends GenericAsyncProcess = AsyncProcess>(
    axios: AxiosInstance,
    params?: AxiosRequestConfig['params'],
) => {
    return axios.get<T[]>('/api/poll_async_processes/account/', {
        params,
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
    });
};

export type UpdateAsyncProcessData = {
    seen?: boolean;
    state?: 'stopped';
};

export type UpdateAsyncProcessResponse = Pick<UserAsyncProcess, 'id' | 'job_id' | 'seen'>;

export const updateAsyncProcess = (
    axios: AxiosInstance,
    jobId: string,
    data: UpdateAsyncProcessData,
): Promise<AxiosResponse<UpdateAsyncProcessResponse>> => {
    return axios.patch(`/api/poll_async_processes/${jobId}/`, data);
};

export const stopAsyncProcessByProcessId = (axios: AxiosInstance, processId: string) => {
    return axios.post(`/api/poll_async_processes/${processId}/stop/`);
};
