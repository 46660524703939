import React, { useState } from 'react';

import { Skeleton, SxProps, useTheme } from '@mui/material';

import Link from 'components/tokens/Link';
import { TextTruncateScrollable } from 'components/tokens/TextTruncate';

import { getLinesToShow } from './companyDescriptionUtils';

export type CompanyDescriptionProps = {
    description?: string;
    wide?: boolean;
    skeleton?: boolean;
    sx?: SxProps;
};

export const CompanyDescription: React.VFC<CompanyDescriptionProps> = ({
    description,
    wide = true,
    skeleton = false,
    sx,
}) => {
    const [expanded, setExpanded] = useState(false);
    const theme = useTheme();

    const lineCount = getLinesToShow(expanded, wide);

    if (skeleton) {
        const fontSize = typeof theme.typography.small.fontSize === 'number' ? theme.typography.small.fontSize : 14;
        const lineHeight =
            typeof theme.typography.small.lineHeight === 'number' ? theme.typography.small.lineHeight : 1.4;
        const skeletonHeight = fontSize * lineHeight * lineCount;
        return (
            <Skeleton
                variant="rounded"
                sx={{
                    height: skeletonHeight,
                    width: '100%',
                    ...sx,
                }}
            />
        );
    }

    if (!description) {
        return null;
    }

    return (
        <TextTruncateScrollable
            text={description}
            component={expanded ? 'div' : 'p'}
            sx={{
                width: '100%',
                ...sx,
            }}
            line={lineCount}
            isScrollable={expanded}
            textTruncateChild={
                <Link onClick={() => setExpanded(!expanded)}>{expanded ? 'Read less' : 'Read more'}</Link>
            }
        />
    );
};

export default CompanyDescription;
