import { format } from 'date-fns';

import { List } from 'api/types/List';
import { isSharedWithYou, isUploaded, UPLOAD_FILE_VALUE } from 'contexts/ListsContext';

import { ListCategory } from './ManageLists';

export const filterListsByType = (lists: List[], type: ListCategory) => {
    if (type === 'shared-legacy') {
        return lists.filter((i) => i.type === 'legacy' && isSharedWithYou(i));
    }
    if (type === 'static-legacy') {
        return lists.filter((i) => i.type === 'legacy' && i.includes_static_list && !isSharedWithYou(i));
    }
    if (type === 'dynamic-legacy') {
        return lists.filter((i) => i.type === 'legacy' && !i.includes_static_list && !isSharedWithYou(i));
    }
    if (type === 'shared') {
        return lists.filter((i) => i.type !== 'legacy' && isSharedWithYou(i));
    }
    if (type === UPLOAD_FILE_VALUE) {
        return lists.filter((i) => i.type !== 'legacy' && isUploaded(i) && !isSharedWithYou(i));
    }
    return lists.filter((i) => i.type !== 'legacy' && !isSharedWithYou(i) && !isUploaded(i));
};

export function getDateTimeFromObjectId(objectId: string) {
    return format(new Date(parseInt(objectId.substring(0, 8), 16) * 1000), 'P');
}

export function isSharedTargetList(targetGroup: List) {
    return ['edit', 'view'].includes(targetGroup.privileges.current);
}

export function isReadonlyPermission(targetGroup: List) {
    return targetGroup.privileges.current === 'view';
}
