import React, { useState } from 'react';

import { stopAsyncProcessByProcessId } from 'api/asyncProcesses';
import { AsyncProcessMetaDataListDataV3, ListDataV3AsyncProcess } from 'api/types/UserAsyncProcess';
import Icon from 'components/tokens/Icon';
import { useAxiosContext } from 'contexts/AxiosContext';

import AdditionalNotificationDialog from '../AdditionalNotificationDialog';
import { AsyncProcessNotificationProps } from '../NotificationItem';
import NotificationItemContent from '../NotificationItemContent';
import { NotificationStateBasedData } from './CRMBulkExport';

const getStateBasedData = (notification: ListDataV3AsyncProcess): NotificationStateBasedData => {
    switch (notification.state) {
        case 'accepted':
        case 'process':
            return {
                title: `Preparing ${
                    notification.meta_data.requested_format
                        ? `${notification.meta_data.requested_format === 'json' ? 'JSON' : 'CSV'} of `
                        : ''
                }"${getTitle(notification.meta_data)}"`,
                date: notification.created,
                hoverText: 'In progress',
            };
        case 'stopped':
            return {
                title: getTitle(notification.meta_data),
                content: 'Process stopped',
                date: notification.finished,
                hoverText: 'Stopped',
            };
        case 'completed':
            return {
                title: getTitle(notification.meta_data),
                content: 'File is ready to be downloaded',
                date: notification.finished,
                hoverText: 'Download',
            };
        default:
            return {
                title: 'Error occurred',
                error: true,
                content: "Couldn't get the data ready to download",
                date: notification.finished,
            };
    }
};

const getTitle = (meta_data: Partial<AsyncProcessMetaDataListDataV3>) => meta_data.list_name || 'list';

const CSVDownload: React.FC<AsyncProcessNotificationProps<ListDataV3AsyncProcess>> = ({
    notification,
    handleMarkAsSeen,
}) => {
    const [isErrorDialogOpened, setErrorDialogOpened] = useState(false);

    const notificationContent = getStateBasedData(notification);
    const axios = useAxiosContext();

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        switch (notification.state) {
            case 'completed':
                return window.open(
                    notification.download_link || `/mapi/download_async_result/${notification.job_id}`,
                    '_blank',
                );
            case 'failure':
                return setErrorDialogOpened(true);
            default:
                return null;
        }
    };

    if (!notification.state || !notification.meta_data) return null;

    return (
        <>
            <NotificationItemContent
                icon={<Icon type={notification.meta_data.requested_format === 'json' ? 'Code' : 'Excel2019'} />}
                {...notificationContent}
                seen={notification.seen}
                state={notification.state}
                onClick={(e) => handleClick(e)}
                handleMarkAsSeen={handleMarkAsSeen}
                progressVariant={notification.progress_max ? 'determinate' : 'indeterminate'}
                progress={
                    notification.progress_max ? ((notification.progress || 0) / notification.progress_max) * 100 : 0
                }
                inProgressText={
                    notification.progress_max
                        ? `${notification.progress || 0}/${notification.progress_max} processed`
                        : 'Depending on the size of the list, this may take a while'
                }
                onCancel={() => stopAsyncProcessByProcessId(axios, notification.id)}
            />
            <AdditionalNotificationDialog
                isOpened={isErrorDialogOpened}
                title={notificationContent.title}
                errorMessage={notificationContent.content}
                notificationType="CSVDownload"
                onClose={() => setErrorDialogOpened(false)}
            />
        </>
    );
};

export default CSVDownload;
