import React, { useCallback, useEffect, useRef, useState } from 'react';

type ScopeWidth = number;
type FullTextWidth = number;

export type TextTruncationProps = {
    text: string;
    line?: number;
    /** Checks if the container should change to scrollable if the text is longer that the `line` */
    isScrollable?: boolean;
    /** Only html element should be used like `span` or `a`. Styling should be done without adding padding/margin. */
    textTruncateChild?: React.ReactElement;
    showTooltip?: boolean;
    textContainerRef: React.MutableRefObject<HTMLElement | null>;
};

const PRECISION = 0.0001;
const MAX_CALCULATE_TIMES = 10;
const TRUNCATE_TEXT = '...';

const isEqual = (n1: number, n2: number) => Math.abs(n1 - n2) < PRECISION;

export const useTextTruncation = ({
    text,
    line = 1,
    textTruncateChild,
    showTooltip,
    isScrollable,
    textContainerRef,
}: TextTruncationProps): [
    React.ReactNode,
    { fullTextWidth: FullTextWidth; scopeWidth: ScopeWidth; remainingDisplayLine: number },
] => {
    const [scopeWidth, setScopeWidth] = useState(0);
    const [canvasFont, setCanvasFont] = useState('');
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    const rafId = useRef(0);
    const fullTextWidth = useRef(0);
    let displayLine = line;

    const measureWidth = (text: string) => {
        return canvasContext?.measureText(text).width || 0;
    };

    const handleResize = useCallback(() => {
        if (rafId.current !== 0) {
            window.cancelAnimationFrame(rafId.current);
        }
        rafId.current = window.requestAnimationFrame(() => {
            if (textContainerRef.current) {
                const style = window.getComputedStyle(textContainerRef.current);
                const font = [style.fontWeight, style.fontStyle, style.fontSize, style.fontFamily].join(' ');

                setCanvasFont(font);
                setScopeWidth(textContainerRef.current.getBoundingClientRect().width);
            }
        });
    }, [textContainerRef]);

    useEffect(() => {
        if (textContainerRef.current) {
            const style = window.getComputedStyle(textContainerRef.current);
            const font = [style.fontWeight, style.fontStyle, style.fontSize, style.fontFamily].join(' ');

            setCanvasFont(font);
            setScopeWidth(textContainerRef.current.getBoundingClientRect().width);
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.cancelAnimationFrame(rafId.current);
        };
    }, [handleResize, rafId, textContainerRef]);

    const getRenderText = () => {
        if (!canvasContext) {
            return;
        }
        // return if display:none
        if (scopeWidth === 0) {
            return null;
        }

        const docFragment = document.createDocumentFragment();
        docFragment.appendChild(canvas);
        canvasContext.font = canvasFont;

        fullTextWidth.current = measureWidth(text);

        // return if all of text can be displayed
        if (scopeWidth > fullTextWidth.current || isEqual(scopeWidth, fullTextWidth.current)) {
            return text;
        }

        let childText = '';
        if (textTruncateChild) {
            childText = textTruncateChild.props.children;
        }
        let currentPos = 1;
        const maxTextLength = text.length;
        let startPos = 0;
        let truncatedText = '';
        let splitPos = 0;
        let width = 0;
        let isPrevLineWithoutSpace = false;
        let lastPos = 0;
        let lastSpaceIndex = -1;
        let ext = '';
        let loopCnt = 0;

        while (displayLine-- > 0) {
            ext = displayLine ? '' : showTooltip ? TRUNCATE_TEXT : TRUNCATE_TEXT + (childText ? ` ${childText}` : '');
            while (currentPos <= maxTextLength) {
                truncatedText = text.substring(startPos, startPos + currentPos);
                /** When displayLine is `0`, the calculation of the text width should be done with out
                 * the `childText` to prevent the unnecessary addition of e.g `Read more` text */
                width = measureWidth(truncatedText + (displayLine === 0 ? '' : ext));

                if (width < scopeWidth) {
                    splitPos = text.indexOf(' ', currentPos + 1);
                    if (splitPos === -1) {
                        currentPos += 1;
                    } else {
                        currentPos = splitPos;
                    }
                } else {
                    do {
                        if (loopCnt++ >= MAX_CALCULATE_TIMES) {
                            break;
                        }

                        truncatedText = text.substring(startPos, startPos + currentPos);
                        if (!displayLine) {
                            currentPos--;
                        }
                        if (truncatedText[truncatedText.length - 1] === ' ') {
                            truncatedText = text.substring(startPos, startPos + currentPos - 1);
                        }
                        lastSpaceIndex = truncatedText.lastIndexOf(' ');
                        if (lastSpaceIndex > -1) {
                            currentPos = lastSpaceIndex;
                            if (displayLine) {
                                currentPos++;
                            }
                            truncatedText = text.substring(startPos, startPos + currentPos);
                        } else {
                            currentPos--;
                            truncatedText = text.substring(startPos, startPos + currentPos);
                        }

                        width = measureWidth(truncatedText + ext);
                    } while ((width > scopeWidth || isEqual(width, scopeWidth)) && truncatedText.length > 0);
                    startPos += currentPos;
                    break;
                }
            }

            if (currentPos >= maxTextLength) {
                startPos = maxTextLength;
                break;
            }

            const isCurrentLineWithoutSpace = text.slice(lastPos, startPos + currentPos).indexOf(' ') === -1;

            if (!isPrevLineWithoutSpace && isCurrentLineWithoutSpace) {
                isPrevLineWithoutSpace = isCurrentLineWithoutSpace;
            }
            lastPos = currentPos + 1;
        }

        if (startPos === maxTextLength && !isScrollable) {
            return text;
        }

        if (isScrollable) {
            /**
             * To set the max height of the scrollbar to correct value when all
             * the text is been displayed
             */
            if (displayLine === -1) {
                displayLine++;
            }
            return (
                <>
                    {`${text} `}
                    {textTruncateChild}
                </>
            );
        }

        return (
            <>
                {`${text.slice(0, startPos) + TRUNCATE_TEXT} `}
                {!showTooltip && textTruncateChild}
            </>
        );
    };

    return [getRenderText(), { fullTextWidth: fullTextWidth.current, scopeWidth, remainingDisplayLine: displayLine }];
};
