import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import Menu from '@mui/material/Menu';
import { isEmpty } from 'lodash';
import { toLower } from 'utilities';

import OverflowContainer from 'components/layout/OverflowContainer';

import { LabelWithTooltip } from './LabelWithTooltip';
import { BaseValue, ChangeValue, SubOption } from './NestedSelectTypes';
import { StyledMenuItem } from './styled';
import { appendNextParent } from './utils';

type SubMenuProps = {
    depth: number;
    open: boolean;
    parentAnchorEle: Record<string, HTMLElement | null>;
    label: string;
    value: string;
    options: SubOption[];
    selectValue: ({
        label,
        value,
        option,
        parent,
    }: {
        label: string;
        value: string;
        option: SubOption;
        parent?: BaseValue;
    }) => void;
    selectedValue: ChangeValue;
    selectedObjectNestedKeyList: string[];
    disableOption?: (option: SubOption) => boolean;
};

/** The min-height of the MenuItem from MUI is 40. If the min-height is changed,
 *  change this value to match the min-height. */
const LIST_ITEM_MIN_HEIGHT = 40;
const MENU_PADDING_Y = 16;
const MAX_OPTIONS_VISIBLE = 10;

export const SubMenu: React.FC<SubMenuProps> = React.memo(
    ({
        depth,
        open,
        parentAnchorEle,
        label,
        value,
        options,
        selectValue,
        selectedValue,
        selectedObjectNestedKeyList,
        disableOption = (option: SubOption) => false,
    }) => {
        const [currentAnchorEle, setCurrentAnchorEle] = useState<Record<string, EventTarget | HTMLElement | null>>({});
        const [parentRef, setParentRef] = useState<HTMLElement | null>(null);
        const [focusedObject, setFocusedObject] = useState('');
        const submenuRef = useRef<Record<string, HTMLElement | null>>({});
        const optionsLength = options.length > 10 ? MAX_OPTIONS_VISIBLE : options.length;

        const openLeft = () => {
            const parentBoundingClientRect = parentRef?.getBoundingClientRect && parentRef?.getBoundingClientRect();
            if (!parentBoundingClientRect) {
                return false;
            }
            const xPosition = parentBoundingClientRect.width + parentBoundingClientRect.x;
            return xPosition > window.innerWidth - 300;
        };

        useEffect(() => {
            if (Boolean(parentAnchorEle[label + value]) && isEmpty(parentRef)) {
                const timerId = setTimeout(
                    () => {
                        setParentRef(parentAnchorEle[label + value]);
                    },
                    100 + depth * 100,
                );

                return () => clearTimeout(timerId);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [parentAnchorEle[label + value]]);

        const handleMouseEnter = (e: MouseEvent, key: string = '') => {
            e.stopPropagation();
            setCurrentAnchorEle({
                [key]: e.currentTarget,
            });
            setFocusedObject(key);
        };
        const handleMouseLeave = (e: MouseEvent, key: string = '') => {
            e.stopPropagation();
            setCurrentAnchorEle({
                [key]: null,
            });
        };

        return (
            <Menu
                style={{ pointerEvents: 'none' }}
                id={`simple-menu-${label}${value}`}
                open={open && !!parentRef}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: openLeft() ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: openLeft() ? 'right' : 'left',
                }}
                PaperProps={{
                    elevation: 3,
                    style: {
                        marginLeft: openLeft() ? 0 : 12,
                        maxHeight: LIST_ITEM_MIN_HEIGHT * MAX_OPTIONS_VISIBLE + MENU_PADDING_Y,
                        overflow: 'hidden',
                        minWidth: 200,
                    },
                }}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                anchorEl={parentRef}
            >
                <OverflowContainer
                    mode="vertical"
                    sx={{
                        height: LIST_ITEM_MIN_HEIGHT * optionsLength + MENU_PADDING_Y,
                        overflowY: 'auto',
                        pointerEvents: 'auto',
                    }}
                    verticalSize={60}
                >
                    <div
                        style={{
                            padding: MENU_PADDING_Y / 2,
                        }}
                    >
                        {options?.map((eachOption: SubOption) => {
                            const {
                                value: subVal,
                                label: subLabel,
                                options: subOption,
                                description,
                                example,
                                endAdornment,
                            } = eachOption;
                            const isSelected =
                                (toLower(subVal) === toLower(selectedValue?.value) &&
                                    toLower(subLabel) === toLower(selectedValue?.label)) ||
                                selectedObjectNestedKeyList.includes(subLabel + subVal);

                            const isParentAndSelected =
                                Boolean(parentAnchorEle[label + value]) &&
                                toLower(subVal) === toLower(selectedValue?.parent?.value || '') &&
                                toLower(subLabel) === toLower(selectedValue?.parent?.label || '');

                            const currentAnchor = {
                                ...currentAnchorEle,
                                ...(isSelected && !currentAnchorEle[subLabel + subVal]
                                    ? {
                                          [subLabel + subVal]: submenuRef.current[subLabel + subVal],
                                      }
                                    : {}),
                            };

                            const isMenuSelected =
                                isSelected || isParentAndSelected || Boolean(currentAnchor[subLabel + subVal]);

                            const subMenuToOpen =
                                focusedObject && focusedObject === subLabel + subVal && !!subOption?.length
                                    ? true
                                    : isMenuSelected && !!subOption?.length && !focusedObject;

                            const handleMenuItemClick = (e: React.MouseEvent) => {
                                e.stopPropagation();
                                if (disableOption(eachOption)) {
                                    return;
                                }
                                if (!subOption?.length) {
                                    selectValue({
                                        label: subLabel,
                                        value: subVal,
                                        option: eachOption,
                                        parent: { label, value },
                                    });
                                    setCurrentAnchorEle({});
                                }
                            };
                            return (
                                <StyledMenuItem
                                    id={`sub-menu-item-${subLabel}${subVal}`}
                                    key={subLabel + subVal}
                                    value={subVal}
                                    selected={isMenuSelected}
                                    onClick={handleMenuItemClick}
                                    disabled={disableOption(eachOption)}
                                    style={{ pointerEvents: 'auto' }}
                                    onScroll={(e) => {
                                        e.stopPropagation();
                                    }}
                                    onMouseEnter={(e) => handleMouseEnter(e, subLabel + subVal)}
                                    onMouseLeave={(e) => handleMouseLeave(e, subLabel + subVal)}
                                    ref={(el) => {
                                        if (el) {
                                            submenuRef.current[subLabel + subVal] = el;
                                        }
                                    }}
                                >
                                    <LabelWithTooltip
                                        label={subLabel}
                                        isOption={!!subOption?.length}
                                        description={description}
                                        example={example}
                                        endAdornment={endAdornment}
                                    />
                                    <SubMenu
                                        depth={depth + 1}
                                        open={subMenuToOpen}
                                        label={subLabel}
                                        value={subVal}
                                        options={subOption?.length ? subOption : []}
                                        selectValue={({ label: l, value: v, option, parent }) => {
                                            if (parent) {
                                                const nestedParent = appendNextParent(parent, {
                                                    value,
                                                    label,
                                                });
                                                selectValue({ label: l, value: v, option, parent: nestedParent });
                                                return;
                                            }
                                            selectValue({ label: l, value: v, option, parent });
                                        }}
                                        selectedValue={selectedValue}
                                        parentAnchorEle={submenuRef.current}
                                        selectedObjectNestedKeyList={selectedObjectNestedKeyList}
                                    />
                                </StyledMenuItem>
                            );
                        })}
                    </div>
                </OverflowContainer>
            </Menu>
        );
    },
);
