import React from 'react';

import { Box, Skeleton, SxProps, Tooltip } from '@mui/material';
import { range } from 'utilities';

import Link from 'components/tokens/Link';
import Tag from 'components/tokens/Tag';

export type TagProps = {
    label: string;
    id: string | number;
};

type TagsProps = {
    tags: TagProps[];
    sx?: SxProps;
    cutoff?: number;
    loading?: boolean;
    onClick?: (tag: TagProps, event: React.MouseEvent<HTMLSpanElement>) => void;
};

export const IndustryTags: React.FC<TagsProps> = ({ tags, sx, cutoff = 4, loading = false, onClick }) => {
    const shown = tags.slice(0, cutoff);
    const hidden = tags.slice(cutoff);

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                justifyContent: 'center',
                ...sx,
            }}
        >
            {tags.length === 0 && loading ? (
                range(4).map((index) => (
                    <Skeleton
                        key={index}
                        variant="rounded"
                        width={[72, 112, 96, 80][index]}
                        sx={{ display: 'inline-block', height: 22.6, padding: '5px' }}
                    />
                ))
            ) : (
                <>
                    {shown.map((tag) => (
                        <Tooltip disableInteractive arrow title={tag.label} key={tag.id}>
                            <Tag
                                sx={{
                                    flex: 1,
                                    maxWidth: 'fit-content',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                label={tag.label}
                                onClick={onClick ? (event) => onClick(tag, event) : undefined}
                            />
                        </Tooltip>
                    ))}
                    {hidden.length > 0 && (
                        <Tooltip
                            disableInteractive
                            arrow
                            title={hidden.map((tag) => (
                                <p key={tag.label}>
                                    <Link onClick={onClick ? (event) => onClick(tag, event) : undefined}>
                                        {tag.label}
                                    </Link>
                                </p>
                            ))}
                        >
                            <Tag label={`+${hidden.length}`} />
                        </Tooltip>
                    )}
                </>
            )}
        </Box>
    );
};

export default IndustryTags;
