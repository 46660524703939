import { memo, useState } from 'react';

import { Box } from '@mui/material';

import { TabContentGridSection } from 'components/modules/profiles/Profile/tabs';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

import FacebookPlugin from './FacebookPlugin';
import { FeedError } from './FeedError';
import { FacebookContainer, PluginWrapper, TwitterContainer } from './styled';
import TwitterPlugin from './TwitterPlugin';

export type SocialNetworkFeedsProps = {
    companyName?: string;
    twitterLink?: string;
    twitterHandle?: string;
    facebookLink?: string;
    facebookHandle?: string;
};

export const SocialNetworkFeeds: React.VFC<SocialNetworkFeedsProps> = memo(
    ({ companyName, twitterLink, twitterHandle, facebookLink, facebookHandle }) => {
        const [twitterLinkError, setTwitterLinkError] = useState(false);
        const [facebookLinkError, setFacebookLinkError] = useState(false);

        const malformed_facebook_link = ['/privacy'].some((item) => {
            if (facebookLink === undefined) return true;
            return facebookLink.includes(item);
        });

        if (malformed_facebook_link) {
            facebookLink = '';
        }

        const socialNetworkObject: Record<string, (value: boolean) => void> = {
            facebook: setFacebookLinkError,
            twitter: setTwitterLinkError,
        };

        /**
         * After the initialization of facebook plugin, it doesn't provide any callback/promise
         * to determine if the request was successful or not. Due to this limitation, the `handleLinkError`
         * function is currently used for Twitter widget only. If someone figures this out then s/he can
         * follow twitter's code for facebook implementation.
         * @param {string} socialNetworkName - 'facebook' or 'twitter'
         * @param {boolean} value
         */
        const handleLinkError = (socialNetworkName: string, value: boolean) => {
            socialNetworkObject[socialNetworkName](value);
        };

        return (
            <TabContentGridSection columns={2}>
                <PluginWrapper padding="large" elevation={1}>
                    <Typography variant="body1" weight="bold" marginBottom={3}>
                        Facebook{' '}
                        {facebookLink && (
                            <Link
                                href={facebookLink}
                                toNewTab="nofollow"
                                underline="hover"
                                sx={{ color: 'secondary.main' }}
                            >
                                {facebookHandle}
                            </Link>
                        )}
                    </Typography>

                    <FacebookContainer>
                        {facebookLink ? (
                            facebookLinkError ? (
                                <FeedError type="facebook" mode="error" companyName={companyName} />
                            ) : (
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    <FacebookPlugin
                                        link={facebookLink}
                                        /**
                                         * Eventhough onLinkError is passed, it doesn't do anything since
                                         * the error handling of facebook is not clear.
                                         */
                                        onLinkError={handleLinkError}
                                    />
                                </Box>
                            )
                        ) : (
                            <FeedError type="facebook" mode="empty" companyName={companyName} />
                        )}
                    </FacebookContainer>
                </PluginWrapper>
                <PluginWrapper padding="large" elevation={1}>
                    <Typography variant="body1" weight="bold" marginBottom={3}>
                        Twitter{' '}
                        {twitterLink && (
                            <Link
                                href={
                                    twitterLinkError
                                        ? `https://twitter.com/search?q=${companyName}&f=user`
                                        : twitterLink
                                }
                                toNewTab="nofollow"
                                sx={{ color: 'secondary.main' }}
                                underline="hover"
                            >
                                {twitterHandle}
                            </Link>
                        )}
                    </Typography>
                    <TwitterContainer>
                        {twitterLink ? (
                            twitterLinkError ? (
                                <FeedError type="twitter" mode="error" companyName={companyName} />
                            ) : (
                                <TwitterPlugin link={twitterLink} onLinkError={handleLinkError} />
                            )
                        ) : (
                            <FeedError type="twitter" mode="empty" companyName={companyName} />
                        )}
                    </TwitterContainer>
                </PluginWrapper>
            </TabContentGridSection>
        );
    },
);

export default SocialNetworkFeeds;
