import { useContext } from 'react';

import { MessageQueueContext } from './MessageQueueContext';

function useMessageQueueContext() {
    const context = useContext(MessageQueueContext);
    if (context === undefined) {
        throw new Error('useMessageQueue must be used within a MessageQueueProvider');
    }
    return context;
}

export { useMessageQueueContext };
