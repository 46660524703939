import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Line: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M6 11L6 13L18 13L18 11L6 11Z" />
    </SvgIcon>
);

export default Line;
