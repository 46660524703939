import React, { useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { styled, Typography } from '@mui/material';

import { CRMImportAsyncProcess } from 'api/types/UserAsyncProcess';

import { CrmName, CrmCompanyObject } from '../types/Crm';
import { getCRMName } from '../utils';
import { getCompanyLikeObjectForCrm, getObjectPluralForm } from '../utils/CRMTargetText';

interface Props {
    isDomainConnector: boolean;
    crm: CrmName;
    matchedCount: number;
    addedAndMatched: number;
    missingFromDB: number;
    latestJob: CRMImportAsyncProcess;
    markJobSeen: () => void;
}

const MatchingSuccessBanner: React.FC<Props> = ({
    isDomainConnector,
    crm,
    matchedCount,
    addedAndMatched,
    missingFromDB,
    latestJob,
    markJobSeen,
}) => {
    const crmAccountObject = getCompanyLikeObjectForCrm(crm);

    const { header, content } = useMemo(
        () =>
            getBannerData({
                isDomainConnector,
                crm,
                matchedCount,
                addedAndMatched,
                missingFromDB,
                crmAccountObject,
            }),
        [isDomainConnector, crm, matchedCount, addedAndMatched, missingFromDB, crmAccountObject],
    );

    if (!crmAccountObject) return null;

    if (crmAccountObject && latestJob?.id && latestJob?.seen && latestJob?.state === 'completed') {
        return null;
    }

    return (
        <BannerWrapper>
            <StyledCloseIcon onClick={markJobSeen} />
            <StyledTypography gutterBottom color="textPrimary">
                {header}
            </StyledTypography>
            <Typography gutterBottom color="textPrimary" fontSize="12px">
                {content}
            </Typography>
        </BannerWrapper>
    );
};

const getBannerData = ({
    isDomainConnector,
    crm,
    matchedCount,
    addedAndMatched,
    missingFromDB,
    crmAccountObject,
}: {
    isDomainConnector: boolean;
    crm: CrmName;
    matchedCount: number;
    addedAndMatched: number;
    missingFromDB: number;
    crmAccountObject: CrmCompanyObject | null;
}) => {
    const crmObjectPlural = getObjectPluralForm(crm, crmAccountObject as CrmCompanyObject);
    const crmName = getCRMName(crm);

    if (isDomainConnector && missingFromDB > 0 && addedAndMatched === 0) {
        return {
            header: 'Matching is done! 🎉',
            content: (
                <>
                    We found{' '}
                    <b>
                        {missingFromDB} {crmObjectPlural}
                    </b>{' '}
                    that aren't in our database yet. Don’t worry, we are adding them already and we’ll match them
                    automatically for you!
                    <br />
                    <br />
                    <p>This process can take all night, so we recommend checking your results tomorrow.</p>
                </>
            ),
        };
    }

    if (isDomainConnector && addedAndMatched > 0) {
        return {
            header: 'Missing companies are matched now! 🎉',
            content: (
                <>
                    We{' '}
                    <b>
                        added {addedAndMatched} missing {crmObjectPlural}
                    </b>{' '}
                    to our database and matched them with your {crmName}.
                </>
            ),
        };
    }

    return {
        header: (
            <>
                <span>Done.</span> Your {crmObjectPlural} are ready! 🎉
            </>
        ),
        content: (
            <>
                We matched{' '}
                <b>
                    {matchedCount} {crmObjectPlural}
                </b>{' '}
                from your {crmName} with Vainu.
                <br />
                <br />
                Now you and your team can start enriching your {crmName} {crmObjectPlural} with Vainu data and keep the
                selected properties updated automatically. Also, you can export new prospects from Vainu without any
                duplicates.
            </>
        ),
    };
};

const BannerWrapper = styled('div')({
    position: 'relative',
    marginTop: '32px',
    padding: '24px',
    background: '#EDF8F6',
    border: '1px solid rgba(7, 153, 146, 0.5);',
    borderRadius: 16,
});

const StyledTypography = styled(Typography)(({ theme: { palette } }) => ({
    color: palette.text.strong,
    fontSize: 18,
    fontWeight: 'bold',
    '& span': {
        color: palette.success.main,
    },
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme: { palette } }) => ({
    position: 'absolute',
    right: 18,
    top: 18,
    color: palette.grey[500],
    fontSize: 14,
    cursor: 'pointer',
}));

export { MatchingSuccessBanner };
