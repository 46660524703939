import { ReactNode } from 'react';

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import uploadListBusinessIdsImg from 'src/images/upload-list-businessids.png';
import uploadListDomainsImg from 'src/images/upload-list-domains.png';

import { StyledLinearProgress } from 'components/templates/ProgressBar/styled';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

interface UploadableProps {
    onUpload: React.ChangeEventHandler<HTMLInputElement>;
}

interface DefaultViewProps extends UploadableProps {
    isDomain?: boolean;
    onClose: () => void;
}

const UploadListItem = ({ children }: { children: ReactNode }) => {
    return (
        <ListItem sx={{ padding: 0 }}>
            <ListItemIcon sx={{ minWidth: 0, paddingRight: '8px' }}>
                <Icon type="CheckThick" color="success.main" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={children} />
        </ListItem>
    );
};

export const DefaultView = ({ isDomain, onClose, onUpload }: DefaultViewProps) => (
    <Wrapper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <Typography sx={{ fontSize: 24 }} weight="bold">
                    Upload a list of companies
                </Typography>
                <List dense={true} sx={{ marginTop: '20px' }}>
                    <UploadListItem>Supported formats: CSV and XLS/XLSX (Microsoft Excel)</UploadListItem>
                    <UploadListItem>
                        {isDomain
                            ? 'Add company domains in separate rows in one column'
                            : 'Add business IDs in separate rows in one column'}
                    </UploadListItem>
                    <UploadListItem>Leave first row empty because we don’t scan it</UploadListItem>
                    <UploadListItem>Make sure the file only has one sheet/tab</UploadListItem>
                </List>
            </div>
            <Box
                component="img"
                sx={{
                    width: 255,
                }}
                src={isDomain ? uploadListDomainsImg : uploadListBusinessIdsImg}
                alt="upload an excel / csv file with business ids, domains or company names"
            />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="flat" onClick={onClose} sx={{ height: 40 }}>
                Close
            </Button>

            <Button component="label" sx={{ height: 40 }}>
                Upload
                <input
                    accept=".xlsx, .xls, .csv, .xml, .ods"
                    style={{ display: 'none' }}
                    type="file"
                    onChange={onUpload}
                />
            </Button>
        </Box>
    </Wrapper>
);

export interface ErrorMessage {
    errorMsg?: string;
    fileName?: string;
}

interface ErrorViewProps extends UploadableProps {
    onClose: () => void;
    errors: ErrorMessage;
}
export const ErrorView = ({ onClose, onUpload, errors }: ErrorViewProps) => (
    <Wrapper>
        <Typography color={'subtle'} sx={{ fontSize: 14 }}>
            {`Something went wrong with the file: ${errors.fileName}`}
        </Typography>
        <Typography color={'subtle'} sx={{ fontSize: 12, padding: 1 }}>
            {errors.errorMsg}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="flat" onClick={onClose} sx={{ height: 40 }}>
                Close
            </Button>
            <Button component="label" sx={{ height: 40 }}>
                Upload
                <input
                    accept=".xlsx, .xls, .csv, .xml, .ods"
                    style={{ display: 'none' }}
                    type="file"
                    onChange={onUpload}
                />
            </Button>
        </Box>
    </Wrapper>
);

interface ProcessValues {
    progress?: number;
    current: number;
    total: number;
}
interface ProcessingViewProps {
    values: ProcessValues;
}
export const ProcessingView = ({ values }: ProcessingViewProps) => (
    <Wrapper>
        <Typography weight="semibold" sx={{ fontSize: 14 }}>
            Processing file...
        </Typography>
        {values.total > 0 ? (
            <Typography
                color={'subtle'}
                sx={{ fontSize: 12, textAlign: 'right' }}
            >{`${values.current} / ${values.total}`}</Typography>
        ) : null}
        {values.total > 0 ? (
            <StyledLinearProgress variant="determinate" value={values.progress || 0} />
        ) : (
            <StyledLinearProgress />
        )}
        <Typography color={'subtle'} sx={{ fontSize: 14 }}>
            It might take a few minutes before the companies appear in your list. Try refreshing the page if they won’t
            appear.
        </Typography>
        {/* FIXME: The backend does not support proper async process cancellation, only marking
                jobs as stopped, while they will actually keep processing normally.
                In case of list uploading this might confuse the user, as the "stopped" job will eventually
                add all the rows to a list.
                The button here is kept in case proper cancellation is implemented later. */}
        {/* <Button variant="flat" onClick={() => cancelUpload(ongoingProcess?.id)} sx={{ width: 150 }}>
                Cancel upload
            </Button> */}
    </Wrapper>
);

interface ProcessingViewDoneProps extends UploadableProps {
    onClose: () => void;
    values: ProcessValues;
}
export const ProcessingViewDone = ({ values, onUpload, onClose }: ProcessingViewDoneProps) => (
    <Wrapper>
        <Typography weight="semibold" sx={{ fontSize: 14 }}>
            Processing done
        </Typography>
        {values.total > 0 ? (
            <Typography
                color={'subtle'}
                sx={{ fontSize: 12, textAlign: 'right' }}
            >{`${values.current} / ${values.total}`}</Typography>
        ) : null}
        <StyledLinearProgress variant="determinate" value={100} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="flat" onClick={onClose} sx={{ height: 40 }}>
                Close
            </Button>
            <Button component="label" sx={{ height: 40 }}>
                Upload more
                <input
                    accept=".xlsx, .xls, .csv, .xml, .ods"
                    style={{ display: 'none' }}
                    type="file"
                    onChange={onUpload}
                />
            </Button>
        </Box>
    </Wrapper>
);

const Wrapper = styled(Paper)`
    padding: 32px 24px;
    width: 700px;
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 20px;
    border-radius: 8px;
`;
