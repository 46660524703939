import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import { SelectOption } from 'components/tokens/select-components/Select';
import VirtualizedSelect, {
    SelectOption as VirtualizedSelectOption,
} from 'components/tokens/select-components/VirtualizedSelect';

import {
    GroupValues,
    FilterConfig,
    FilterDefinition,
    Operator,
    OperatorValue,
    SubGroupValues,
    FilterProps,
    NodeValue,
} from '../../../FilterTypes';
import { useOperator } from '../../common/useOperator';
import FilterTemplate from '../../FilterTemplate';
import { formatOperatorValue, valueArrayToText } from '../../utils';

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

export const getFilterDefinition = <T,>(
    config: FilterConfig<T>,
    component: React.FunctionComponent<FilterProps<T>>,
): FilterDefinition<T> =>
    ({
        [config.id]: {
            id: config.id,
            group: GroupValues.web_profile,
            subGroup: SubGroupValues.technology,
            label: config.label,
            fields: config.fields,
            outsideGroup: true,
            render: (props: FilterProps<T>) => React.createElement<FilterProps<T>>(component, { ...props }, null),
            valueToText: valueArrayToText(config.fields[0]),
            config,
            createFilter: () => config.initValue,
        },
    }) as FilterDefinition<T>;

export interface TechnologyTemplateProps<T> extends FilterProps<T> {
    dbField: keyof T;
    options: SelectOption[];
}

export const TechnologyFilterTemplate = <T,>({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
    dbField,
    options,
}: TechnologyTemplateProps<T>) => {
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const optionsToDisable = getIds(sameFiltersState);
    const disabledOptions = options.filter((option) => optionsToDisable.includes(option.value as number));

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: dbField,
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;
                    const selectedOptions = options.filter(
                        (item) => Array.isArray(value) && value.includes(item.value as number),
                    );
                    return (
                        <VirtualizedSelect
                            placeholder={value?.length ? 'Or...' : 'Select'}
                            width="100%"
                            options={options}
                            value={selectedOptions}
                            disabledOptions={disabledOptions}
                            onValueChange={(values) => {
                                onChange((values as VirtualizedSelectOption[]).map((val) => val.value));
                            }}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
        sameFiltersState,
    };
    return <FilterTemplate {...props} />;
};

/** crawl the state and find all ids */
function getIds(state: NodeValue[] | NodeValue | number): number[] {
    if (Array.isArray(state)) {
        return state.flatMap((value) => getIds(value));
    } else if (typeof state === 'number') {
        return [state];
    }
    return Object.values(state).flatMap((value) => getIds(value));
}
