import { useState } from 'react';

import { Box, Tooltip } from '@mui/material';
import { assertArray } from 'utilities';

import { BasicDataDomainData, BasicDataOrganization } from 'api/profileData';
import { ProfileData, ProfileType } from 'api/types/Organization';
import CompanyGridView from 'components/features/lists/CompanyGridView';
import CompanyListView from 'components/features/lists/CompanyListView';
import { CRMExport, CrmMenu, useCRMExport } from 'components/modules/connectors/crm-export';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { TabSlug } from 'components/modules/profiles/Profile/tabs';
import Pagination from 'components/templates/Pagination';
import ResultViewHeader from 'components/templates/ResultViewHeader';
import { ViewType } from 'components/templates/ResultViewHeader/types';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { Database, useListsContext } from 'contexts/ListsContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import useAddToStaticList from 'hooks/useAddToStaticList';
import useSimpleSelection from 'hooks/useSimpleSelection';

import { ListExportDialogWrapper } from '../lists/ListExportDialog';

const ITEMS_PER_PAGE = 20;

export type SimilarCompaniesProps = {
    data: ProfileData[] | undefined;
    isLoading: boolean;
    currentCompanyData: BasicDataDomainData | BasicDataOrganization;
    hideActions?: boolean;
    hideCrmIndicator?: boolean;
    customActions?: React.ReactNode;
};

export const SimilarCompanies: React.FC<SimilarCompaniesProps> = ({
    data,
    isLoading,
    currentCompanyData,
    hideActions,
    hideCrmIndicator,
    customActions,
}) => {
    const { database } = useListsContext();
    const { hasProductPermission } = usePermissionContext();
    const { addToList } = useAddToStaticList();
    const [exportState, { handleOpenExportDialog, handleCloseExportDialog }] = useCRMExport();

    const isCrmExportEnabled = hasProductPermission(Permission.CrmBulkExport);

    const [activeView, setActiveView] = useState<ViewType>('grid');
    const [page, setPage] = useState(1);

    const companyIds = assertArray(data).map((profile) => profile.id);
    const { selected, isAllSelected, select, unselect, toggleAllSelected } = useSimpleSelection(companyIds);

    const selectedCompaniesCount = selected.length;

    const handleAddMenuAction = async (action: string) => {
        addToList(action, selected);
    };

    const offset = (page - 1) * ITEMS_PER_PAGE;
    const pageData = data?.slice(offset, offset + ITEMS_PER_PAGE) || [];

    const onOpenProfile = (profileType: ProfileType, profileId: string | undefined) => {
        const path = `/${profileType === 'domain' ? 'domain' : 'company'}/${profileId}/${TabSlug.Overview}`;
        window.open(path, '_blank');
    };

    const handleCompaniesSelectionExport = (crm: CrmName) => {
        handleOpenExportDialog({
            selectionType: 'selection',
            exportType: 'company',
            crm,
            database: database as Database,
            companyIds: selected.length ? selected : companyIds,
            defaultVainuTags: [`Similar to ${currentCompanyData.name}`],
        });
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <Typography weight="bold">Similar companies (beta)</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Typography variant="tiny" color="subtle">
                            Results are based on our AI model that compares company websites{' '}
                        </Typography>
                        <Tooltip title="Note: the model only analyzes company activity and industry, not other attributes such as location or size.">
                            <span>
                                <Icon
                                    type="InfoCircleOutline"
                                    fontSize="mini"
                                    color="text.subtle"
                                    sx={{ display: 'block' }}
                                />
                            </span>
                        </Tooltip>
                    </Box>
                </Box>

                {customActions || (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
                        <ListExportDialogWrapper
                            totalCount={data?.length || 0}
                            loadingCount={isLoading}
                            companyIds={companyIds}
                            usageCount={data?.length || 0}
                            loadingUsageCount={isLoading}
                            buttonText="Download companies"
                            query={{ '?ALL': [{ '?IN': { business_id: companyIds } }] }}
                            filename={`similar to ${currentCompanyData.name}`}
                        />

                        {isCrmExportEnabled && (
                            <CrmMenu
                                anchorButton={
                                    <Button startIcon={<Icon type="Export" />} variant="tertiary" size="small">
                                        Send
                                    </Button>
                                }
                                handleClick={(crmName) => handleCompaniesSelectionExport(crmName)}
                            />
                        )}
                    </Box>
                )}
            </Box>

            <Box>
                <ResultViewHeader
                    companiesCount={data?.length || 0}
                    selectedCompaniesCount={selectedCompaniesCount}
                    toggleSelectAllCompanies={toggleAllSelected}
                    onViewChange={setActiveView}
                    view={activeView}
                    loadingCount={isLoading}
                    finalCount={true}
                    availableViews={['grid', 'list']}
                    hideSort
                    onAddMenuAction={handleAddMenuAction}
                    hideActions={hideActions}
                />
                <Box sx={{ paddingTop: 2.5 }}>
                    {activeView === 'grid' ? (
                        <CompanyGridView
                            loading={isLoading}
                            companies={pageData}
                            onOpenProfile={onOpenProfile}
                            onSelectProfile={(id) => select(id)}
                            onUnselectProfile={(id) => unselect(id)}
                            selectedCompanies={selected}
                            allCompaniesSelected={isAllSelected}
                            vainuSegment={`Similar to ${currentCompanyData.name}`}
                            hideActions={hideActions}
                            hideCrmIndicator={hideCrmIndicator}
                        />
                    ) : activeView === 'list' ? (
                        <CompanyListView
                            tableData={pageData}
                            onOpenProfile={onOpenProfile}
                            onSelectProfile={(id) => select(id)}
                            onUnselectProfile={(id) => unselect(id)}
                            selectedCompanies={selected}
                            allCompaniesSelected={isAllSelected}
                            vainuSegment={`Similar to ${currentCompanyData.name}`}
                            hideActions={hideActions}
                            hideCrmIndicator={hideCrmIndicator}
                        />
                    ) : null}
                </Box>

                {data?.length && (
                    <Box
                        sx={{
                            marginTop: 5,
                        }}
                    >
                        <Pagination
                            page={page}
                            count={Math.ceil(data.length / ITEMS_PER_PAGE)}
                            onPageChange={(_event, page) => setPage(page)}
                        />
                    </Box>
                )}
            </Box>

            <CRMExport {...exportState} onClose={handleCloseExportDialog} />
        </>
    );
};

export default SimilarCompanies;
