import ContentView from 'components/layout/ContentView';
import { SkeletonList } from 'components/layout/Skeletons/SkeletonList';
import { SkeletonResult } from 'components/layout/Skeletons/SkeletonResult';
import { useListsContext } from 'contexts/ListsContext';

import FilterResultsView from './FilterResults/FilterResultsView';
import LandingPage from './FilterResults/LandingPage';
import Lists from './Lists/Lists';

const Profiles: React.FC = () => {
    const { dbLists, createNewList, isInitialLoading, database } = useListsContext();

    return (
        <ContentView
            left={isInitialLoading ? <SkeletonList /> : <Lists />}
            right={
                isInitialLoading ? (
                    <SkeletonResult />
                ) : dbLists.length === 0 ? (
                    <LandingPage
                        onClick={() =>
                            createNewList(
                                database === 'DOMAIN_DATA_BASIC' ? 'dynamic-domain-list' : 'dynamic-organization-list',
                            )
                        }
                    />
                ) : (
                    <FilterResultsView />
                )
            }
        />
    );
};

export default Profiles;
