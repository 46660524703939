import React from 'react';

import { Box, List } from '@mui/material';
import { useParams, Outlet } from 'react-router-dom';

import ContentView from 'components/layout/ContentView';
import MainListItem from 'components/tokens/MainListItem';
import { Typography } from 'components/tokens/Typography';

import { ConnectorsAppConfig } from '../types';
import { getCRMName } from '../utils';
import { ConnectorRoute } from './utils';

interface NavigationOption {
    active: boolean;
    route: ConnectorRoute;
    label: string;
}

interface ConnectorsNavMenuProps {
    connectedCRMs: ConnectorsAppConfig[];
}

const ConnectorsNavigationMenu: React.FC<ConnectorsNavMenuProps> = ({ connectedCRMs }) => {
    const { '*': currentRoute } = useParams<{ '*': ConnectorRoute }>();
    const { crmName } = useParams();

    return (
        <ContentView
            left={
                <Box sx={{ display: 'grid', gap: 5 }}>
                    <Navigation
                        title="Connected"
                        options={
                            connectedCRMs.length > 0
                                ? connectedCRMs.map((crm) => ({
                                      active: crmName === crm.id,
                                      route: `connections/${crm.id}`,
                                      label: getCRMName(crm.id),
                                  }))
                                : []
                        }
                    />
                    <Navigation
                        title="Connect with Vainu"
                        options={[
                            { active: currentRoute === 'crms', route: 'crms', label: 'CRMs' },
                            { active: currentRoute === 'api', route: 'api', label: 'API' },
                        ]}
                    />
                </Box>
            }
            right={<Outlet />}
        />
    );
};

type NavigationProps = {
    title: string;
    options: NavigationOption[];
};

const Navigation: React.FC<NavigationProps> = ({ title, options }) => {
    // todo: non-semantic HTML here. <p> cannot be used as a child of <ul>
    return (
        <List>
            <Typography weight="bold" marginBottom={2}>
                {title}
            </Typography>
            {!options.length && (
                <MainListItem disabled>
                    <i>No Connections yet</i>
                </MainListItem>
            )}
            {options.map((item) => (
                <MainListItem key={item.route} selected={item.active} to={item.route}>
                    {item.label}
                </MainListItem>
            ))}
        </List>
    );
};

export { ConnectorsNavigationMenu };
