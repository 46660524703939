import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Communication: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 10.48V6C18 4.9 17.1 4 16 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H16C17.1 20 18 19.1 18 18V13.52L22 17.5V6.5L18 10.48ZM16 9.69V18H4V6H16V9.69ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12ZM12.78 13.58C13.52 13.9 14 14.62 14 15.43V16H6V15.43C6 14.62 6.48 13.9 7.22 13.58C8.07 13.21 9.01 13 10 13C10.99 13 11.93 13.21 12.78 13.58Z"
        />
    </SvgIcon>
);

export default Communication;
