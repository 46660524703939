import { FC } from 'react';

import Checkbox from 'components/tokens/Checkbox';

import { CellProps } from './TableComponent';

const SelectionCell: FC<CellProps> = ({ row, setSelectedRowIds, selectedRowIds }) => {
    const delivered = row.delivered;
    return delivered ? (
        <Checkbox disabled checked={false} />
    ) : (
        <Checkbox
            onChange={(e) => setSelectedRowIds?.((ids) => ({ ...ids, [row.id]: e.target.checked }))}
            checked={!!selectedRowIds?.[row.id]}
        />
    );
};

export default SelectionCell;
