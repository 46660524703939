import { IconType } from 'components/tokens/Icon';

export const vehicleTypeIcons: Record<VehicleCategoryTop, IconType> = {
    TWO_WHEELED: 'VehicleOther',
    MOTORCYCLE: 'VehicleMoto',
    TRI_OR_QUADRICYCLE: 'VehicleOther',
    CARS: 'VehicleCar',
    VAN: 'VehicleVan',
    LORRY: 'VehicleTruck',
    BUS: 'VehicleBus',
    TRACTOR: 'VehicleTractor',
    TOWABLE: 'VehicleTrailer',
    OTHER: 'VehicleOther',
};

export type VehicleCategoryTop =
    | 'TWO_WHEELED'
    | 'MOTORCYCLE'
    | 'TRI_OR_QUADRICYCLE'
    | 'OTHER'
    | 'CARS'
    | 'BUS'
    | 'VAN'
    | 'LORRY'
    | 'TOWABLE'
    | 'TRACTOR';

export const vehicleCategoryTopLabels = {
    TWO_WHEELED: 'Two-wheel powered vehicle',
    MOTORCYCLE: 'Motorcycle',
    TRI_OR_QUADRICYCLE: 'Powered tricycle or quadricycle',
    OTHER: 'Other vehicles',
    CARS: 'Passenger cars',
    BUS: 'Bus',
    VAN: 'Van',
    LORRY: 'Lorry',
    TOWABLE: 'Trailer and towed equipment',
    TRACTOR: 'Tractor',
};
