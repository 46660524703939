import Button, { ButtonProps } from 'components/tokens/Button';

const TriggerButton = ({ sx, ...rest }: ButtonProps) => {
    return (
        <Button
            variant="secondary"
            size="small"
            sx={{ minHeight: 24, height: 24, fontSize: 13, backgroundColor: 'common.white', ...sx }}
            {...rest}
        />
    );
};

export default TriggerButton;
