import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';
import { makeUniqueName } from 'utilities';

// import { stopAsyncProcessByProcessId } from 'api/asyncProcesses';
import { ImportListAsyncProcess, isImportListAsyncProcess, UserAsyncProcess } from 'api/types/UserAsyncProcess';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useFilterCompaniesContext } from 'contexts/FilterCompaniesContext/FilterCompaniesContext';
import { useListsContext } from 'contexts/ListsContext';
import useAsyncProcess from 'hooks/useAsyncProcess';

import asyncProcessAtom from '../store/asyncProcessAtom';

interface FileUploadContextValue {
    fileUploadProcessIds: string[];
    fileUploadProcesses: ImportListAsyncProcess[];
    setFileUploadProcessIds: (id: string[]) => void;
    uploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
    cancelUpload: (processId: string | undefined) => void;
}

const FileUploadContext = React.createContext<FileUploadContextValue>({} as FileUploadContextValue);

type Props = { children: React.ReactNode };

export const FileUploadProvider = ({ children }: Props) => {
    const [fileUploadProcessIds, setFileUploadProcessIds] = useState<string[]>([]);
    const [fileUploadProcesses, setFileUploadProcesses] = useState<ImportListAsyncProcess[]>([]);
    const asyncProcesses = useRecoilValue(asyncProcessAtom);
    const { refetchResults } = useFilterCompaniesContext();
    const axios = useAxiosContext();
    const { modifyList, selectedList, refetchList, dbLists, database } = useListsContext();
    const [, { pollUserAsyncProcess }] = useAsyncProcess();

    const listNames = useMemo(() => {
        return dbLists.map((list) => list.name);
    }, [dbLists]);

    const ongoingProcesses = useMemo(() => {
        const isIdMatch = (process: UserAsyncProcess) => {
            return fileUploadProcessIds.includes(process.id) || fileUploadProcessIds.includes(process.job_id);
        };
        const _ongoingProcesses = fileUploadProcessIds?.length
            ? asyncProcesses.filter(isImportListAsyncProcess).filter((process) => isIdMatch(process))
            : asyncProcesses.filter(isImportListAsyncProcess).filter((process) => process.state === 'process');
        return _ongoingProcesses;
    }, [fileUploadProcessIds, asyncProcesses]);

    const refetchResultsForLists = useMemo(() => {
        const _refetchResultsForLists: string[] = [];
        fileUploadProcesses.forEach((currentStateProcess) => {
            const updatedProcess = ongoingProcesses.find(
                (polledProcess) => polledProcess.id === currentStateProcess.id,
            );
            if (updatedProcess) {
                if (
                    currentStateProcess.state === 'process' &&
                    updatedProcess.state === 'completed' &&
                    currentStateProcess?.meta_data?.target_group_id
                ) {
                    // just finished processes could invalidate static list caches, trigger data refetch
                    _refetchResultsForLists.push(currentStateProcess?.meta_data?.target_group_id);
                }
            }
        });
        return _refetchResultsForLists;
    }, [ongoingProcesses, fileUploadProcesses]);

    useEffect(() => {
        const updatedFileUploadProcessIds = [...fileUploadProcessIds];
        ongoingProcesses.forEach((process) => {
            if (
                !(
                    updatedFileUploadProcessIds.includes(process.id) ||
                    updatedFileUploadProcessIds.includes(process.job_id)
                )
            ) {
                updatedFileUploadProcessIds.push(process.id);
            }
        });
        if (updatedFileUploadProcessIds.length !== fileUploadProcessIds.length) {
            setFileUploadProcessIds(updatedFileUploadProcessIds);
        }
        refetchResultsForLists.forEach((listId) => {
            refetchList(listId);
            refetchResults(listId);
        });
        if (ongoingProcesses?.length || (!ongoingProcesses?.length && fileUploadProcesses?.length)) {
            setFileUploadProcesses(ongoingProcesses);
        }
    }, [
        asyncProcesses,
        refetchResults,
        ongoingProcesses,
        refetchResultsForLists,
        refetchList,
        fileUploadProcessIds,
        fileUploadProcesses?.length,
    ]);

    const uploadFile = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (!file || !selectedList) {
                return;
            }
            const name = selectedList.name?.toLowerCase()?.startsWith('new list')
                ? makeUniqueName(file.name, listNames)
                : null;
            modifyList(
                {
                    id: selectedList.id,
                    country: database,
                    ...{ ...(name && { name }) },
                },
                selectedList.type,
            );
            const formData = new FormData();
            formData.append('spread_sheet_data', file);

            const response = await axios.post(
                `api/v3/lists/${database === 'DOMAIN_DATA_BASIC' ? 'domains' : 'organizations'}/static/${
                    selectedList.id
                }/import_spreadsheet/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );
            if (![200, 201, 202].includes(response?.status)) {
                // handle errorW
                console.error(response?.statusText);
                return;
            }
            const asyncProcessId = response.data.job_id;
            pollUserAsyncProcess({ jobId: asyncProcessId });
            const ongoingProcessIds = ongoingProcesses.filter((i) => i.state === 'process').map((i) => i.id);
            setFileUploadProcessIds([...ongoingProcessIds, asyncProcessId]);
        },
        [selectedList, database, listNames, modifyList, axios, pollUserAsyncProcess, ongoingProcesses],
    );

    const cancelUpload = useCallback(
        (processId: string | undefined) => {
            // FIXME: The backend does not support proper async process cancellation, only marking
            // jobs as stopped, while they will actually keep processing normally.
            // In case of list uploading this might confuse the user, as the "stopped" job will eventually
            // add all the rows to a list.
            // The function here is kept in case proper cancellation is implemented later.
            throw new Error('List upload cancellation is not yet implemented.');
            // if (processId) {
            //     stopAsyncProcessByProcessId(axios, processId);
            // }
        },
        [
            /*axios*/
        ],
    );

    const value = useMemo(
        () => ({
            fileUploadProcessIds,
            setFileUploadProcessIds,
            fileUploadProcesses,
            cancelUpload,
            uploadFile,
        }),
        [cancelUpload, fileUploadProcessIds, fileUploadProcesses, uploadFile],
    );
    return <FileUploadContext.Provider value={value}>{children}</FileUploadContext.Provider>;
};

export const useFileUploadContext = () => useContext(FileUploadContext);
