import { useState } from 'react';

import { Box, SxProps } from '@mui/material';

import { ExternalReferencesStatsPayload } from 'api/externalReferences';
import { Bucket, Stats } from 'api/filterCompanies';
import { SettingsDrawer } from 'components/modules/connectors/common/SettingsDrawer';
import Skeleton from 'components/modules/connectors/skeletons';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { getCRMName } from 'components/modules/connectors/utils';
import { CRMIcon } from 'components/templates/CompanyCardCRM';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

type Props = {
    crmName: CrmName;
    data: Stats[] | undefined;
    statsPayload: ExternalReferencesStatsPayload;
};

const DataCoverage: React.FC<Props> = ({ crmName, data, statsPayload }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {data && !data.some((row) => row.id.startsWith('coverage:')) && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Icon type="InfoCircleOutline" fontSize="mini" sx={{ display: 'inline-block' }} />
                    <Typography variant="body2">Rerun analysis to see data coverage</Typography>
                </Box>
            )}
            <table
                style={{
                    fontSize: 14,
                    fontWeight: 500,
                }}
            >
                <thead>
                    <tr>
                        <td colSpan={2} />
                        <td style={{ textAlign: 'right', paddingRight: 5 }}>
                            <IconChip>
                                <Icon type={CRMIcon[crmName] || 'CRM'} sx={{ fontSize: 12, display: 'block' }} />
                            </IconChip>
                        </td>
                        <td style={{ textAlign: 'right', paddingLeft: 4, paddingRight: 8 }}>
                            <IconChip>
                                <Icon type={CRMIcon[crmName] || 'CRM'} sx={{ fontSize: 12, display: 'block' }} />
                                <Icon type="Plus" sx={{ fontSize: 12, display: 'block' }} />
                                <Icon type="Vainu" sx={{ fontSize: 12, display: 'block' }} />
                            </IconChip>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {(
                        data ||
                        (statsPayload.stats.map((statRow) => ({
                            id: statRow.id,
                            label: statRow.label,
                            documents_scanned: 0,
                            result: [],
                            meta: null,
                        })) as Stats[])
                    )
                        .filter((row) => row.id.startsWith('coverage:'))
                        .map((row) => {
                            const counts = getCounts(row.result[0]?.buckets);

                            const total = row.documents_scanned;

                            const inCrmPercent = counts != null ? Math.round((counts.crm / total) * 100) : null;
                            const inCrmAndVainuPercent =
                                counts != null ? Math.round((counts.both / total) * 100) : null;
                            return (
                                <tr key={row.id}>
                                    <td style={{ textAlign: 'right', paddingLeft: 16 }}>{row.label}</td>
                                    <td
                                        width="100%"
                                        style={{
                                            padding: '4px 16px',
                                        }}
                                    >
                                        {row ? (
                                            <Box
                                                sx={{
                                                    height: 20,
                                                    backgroundColor: 'grey.50',
                                                    borderRadius: 1,
                                                    position: 'relative',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        height: '100%',
                                                        backgroundColor: 'brandColors.tertiaryBlue100',
                                                        width: `${inCrmAndVainuPercent}%`,
                                                        borderRadius: 1,
                                                        border: '2px dotted',
                                                        borderColor: 'brandColors.tertiaryBlue',
                                                    }}
                                                />

                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        backgroundColor: 'grey.400',
                                                        width: `${inCrmPercent}%`,
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            </Box>
                                        ) : (
                                            <Skeleton
                                                variant="rectangular"
                                                sx={{
                                                    height: 20,
                                                    width: '100%',
                                                    borderRadius: 1,
                                                }}
                                            />
                                        )}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {inCrmPercent != null ? <>{inCrmPercent}%</> : <Skeleton width={27} />}
                                    </td>
                                    <td style={{ textAlign: 'right', paddingRight: 16 }}>
                                        {inCrmAndVainuPercent != null ? (
                                            <Typography
                                                variant="inherit"
                                                fontWeight="inherit"
                                                sx={{ color: 'brandColors.tertiaryBlue' }}
                                            >
                                                {inCrmAndVainuPercent}%
                                            </Typography>
                                        ) : (
                                            <Skeleton width={27} sx={{ marginLeft: 'auto' }} />
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <LegendItem dotSx={{ backgroundColor: 'grey.400' }} label={`${getCRMName(crmName)}'s coverage`} />
                <LegendItem
                    dotSx={{
                        backgroundColor: 'brandColors.tertiaryBlue100',
                        border: '2px dotted',
                        borderColor: 'brandColors.tertiaryBlue',
                    }}
                    label="Coverage if combined with Vainu"
                />
            </Box>

            <Button sx={{ alignSelf: 'center' }} onClick={() => setIsDrawerOpen(true)}>
                Manage data to increase coverage
            </Button>

            <SettingsDrawer
                crm={crmName}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                defaultTab="mapping"
            />
        </Box>
    );
};

export default DataCoverage;

const IconChip = ({ children }: { children: React.ReactNode }) => (
    <Box
        sx={{
            display: 'inline-flex',
            gap: '2px',
            borderRadius: 4,
            padding: 0.5,
            border: '1px solid',
            borderColor: 'grey.400',
            backgroundColor: 'grey.50',
        }}
    >
        {children}
    </Box>
);

const LegendItem = ({ dotSx, label }: { dotSx: SxProps; label: React.ReactNode }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Box
            sx={{
                width: 14,
                height: 14,
                borderRadius: '50%',
                ...dotSx,
            }}
        />

        <Typography variant="caption" color="subtle">
            {label}
        </Typography>
    </Box>
);

const getCounts = (buckets: Bucket[] | undefined) =>
    buckets?.reduce(
        (prev, next) => {
            const value = next.value as Record<string, boolean>;
            let crm = prev.crm;
            let both = prev.both;
            if (Object.keys(value).some((key) => key.endsWith('_exists_in_crm') && value[key])) {
                crm += next.count;
            }
            if (Object.values(value).some(Boolean)) {
                both += next.count;
            }
            return {
                crm,
                both,
            };
        },
        { crm: 0, both: 0 },
    );
