import React, { useState } from 'react';

import { PopperPlacementType, SxProps } from '@mui/material';

import { List } from 'api/types/List';
import ShareList from 'components/features/lists/ShareList';
import Button from 'components/tokens/Button';
import Dialog from 'components/tokens/Dialog';
import Typography from 'components/tokens/Typography';
import { useTrigger } from 'contexts/TriggerContext';
import { ListAction, getTargetList } from 'contexts/utilities/listsUtils';

import ListMenu from '../../templates/ListMenu';
import { ConfirmationContent } from '../../templates/PopoverWithAction/PopoverContent';

export interface TargetListMenuProps {
    anchorElement: React.ReactElement;
    list: List;
    showInactiveCompanies?: boolean;
    shareList?: boolean;
    convertToStaticList?: boolean;
    isOwner?: boolean;
    /** If set to `true`, the list menu closes when `onMouseLeave` event occurs on the anchor element */
    closeOnMouseLeave?: boolean;
    /** Placement for the Popper component wrt to the anchor element. Default is `bottom-start` */
    popperPlacement?: PopperPlacementType;
    sx?: SxProps;
    onClick: (action: ListAction, fields?: Partial<List>) => void;
    hideMigrate?: boolean;
}

type DialogContent = ConfirmationContent & { action: ListAction; menuName?: string };

export const TargetListMenu: React.FC<TargetListMenuProps> = ({
    anchorElement,
    list,
    showInactiveCompanies = false,
    shareList = false,
    convertToStaticList = false,
    isOwner = false,
    closeOnMouseLeave,
    popperPlacement,
    sx,
    onClick,
    hideMigrate,
}) => {
    const [, , { triggers, updateTrigger }] = useTrigger();
    const [dialogContent, setDialogContent] = useState<DialogContent | undefined>(undefined);

    const activeTriggers = triggers.filter(
        (trigger) => trigger.status === 'active' && trigger.workflow_settings.target_group_source === list.id,
    );

    const isLegacy = list.type === 'legacy';

    const items = getTargetList({
        showInactiveCompanies,
        shareList,
        convertToStaticList,
        isOwner,
        activeTriggers,
        isLegacy,
        hasMigrationErrors: isLegacy && !!list.converted_query?.errors,
        hideMigrate,
    });

    const handleListAction = (action: ListAction, confirmContent: ConfirmationContent) => {
        setDialogContent({
            ...confirmContent,
            action: action,
            menuName: list.name,
        });
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <ListMenu
                anchorElement={anchorElement}
                eleUid="target-list-dropdown"
                popperPlacement={popperPlacement}
                closeOnMouseLeave={closeOnMouseLeave}
                items={items}
                sx={sx}
                onClick={(action, content) => {
                    if (content) {
                        handleListAction(action, content);
                    } else {
                        onClick(action);
                    }
                }}
            />
            {!!dialogContent ? (
                dialogContent.action === 'share' ? (
                    <ShareList
                        open={!!dialogContent}
                        title={`${dialogContent.title} ${list.name}`}
                        list={list}
                        onClose={() => setDialogContent(undefined)}
                        onSubmit={(fields) => onClick(dialogContent.action, fields)}
                    />
                ) : (
                    <Dialog
                        PaperProps={{
                            sx: {
                                minWidth: 420,
                            },
                        }}
                        open={!!dialogContent}
                        title={dialogContent.title}
                        cancelLabel={dialogContent.cancelLabel}
                        onClose={() => {
                            setDialogContent(undefined);
                        }}
                        customizedConfirm={
                            <Button
                                variant={dialogContent.isConfirmDestructive ? 'critical' : 'primary'}
                                onClick={() => {
                                    onClick(dialogContent.action);
                                    activeTriggers.map((trigger) =>
                                        updateTrigger(trigger.id || '', {
                                            status: 'disabled',
                                            workflow_settings: {
                                                ...trigger.workflow_settings,
                                                target_group_source: null,
                                            },
                                        }),
                                    );
                                }}
                            >
                                {dialogContent.confirmLabel}
                            </Button>
                        }
                    >
                        <Typography
                            variant="small"
                            color="subtle"
                            sx={{
                                '&>span': {
                                    color: 'text.strong',
                                    fontWeight: '700',
                                },
                            }}
                        >
                            {dialogContent.subtitle.includes('$listName')
                                ? (() => {
                                      const [start, end] = dialogContent.subtitle.split('$listName');
                                      return (
                                          <>
                                              {start}
                                              <span>{dialogContent.menuName}</span>
                                              {end}
                                          </>
                                      );
                                  })()
                                : dialogContent.subtitle}
                        </Typography>

                        {dialogContent.subtitle2 && (
                            <Typography variant="small" color="subtle" component="div" sx={{ marginTop: 2 }}>
                                {dialogContent.subtitle2}
                            </Typography>
                        )}
                    </Dialog>
                )
            ) : null}
        </div>
    );
};

export default TargetListMenu;
