import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Paper, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { AxiosError } from 'axios';
import {
    add,
    differenceInDays,
    differenceInMonths,
    eachMonthOfInterval,
    endOfMonth,
    format,
    startOfMonth,
    startOfDay,
    startOfYear,
    sub,
} from 'date-fns';
import { useRecoilState } from 'recoil';

import {
    getCreditUsage,
    getMatchedCount,
    USAGE_IS_ENABLED,
    useUsageInfo,
    getTotalUpdatedCount,
    getMatchedHistory,
} from 'api/account';
import { CreditUsage, DateRange, CRMUsageHistory } from 'api/types/Account';
import ConnectorDrawerWithButton from 'components/modules/connectors/common/ConnectorDrawerWithButton';
import { crmsConnectConfig } from 'components/modules/connectors/landing/utils';
import Skeleton from 'components/modules/connectors/skeletons';
import { TabEnum } from 'components/modules/connectors/types';
import { AreaChart, ChartDataPoint, ChartCategoryDataPoint } from 'components/templates/charts';
import { CRMIcon } from 'components/templates/CompanyCardCRM';
import Button from 'components/tokens/Button';
import { FeedbackBanner } from 'components/tokens/FeedbackBanner/FeedbackBanner';
import { Icon } from 'components/tokens/Icon';
import TimeFrameSelect from 'components/tokens/select-components/TimeFrameSelect/TimeFrameSelect';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { usePermissionContext } from 'contexts/PermissionContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import reshapedAccountAtom from 'store/reshapedAccountAtom';
import { formatDate } from 'utilities/date';

import HistoryTable from './HistoryTable';
import PricingDialog from './PricingDialog';
import TierProgress from './TierProgress';

type UsageProps = {
    isMock?: boolean;
};

type TierData = { count: number; tierLimit: number };
type DownloadData = { counts: Record<string, number>; tierLimit: number; graphData: ChartDataPoint[] };
type CRMHistory = { matchedGraphData: ChartDataPoint[]; updatedGraphData: ChartDataPoint[] };

const CURRENT_PERIOD_ACTION = 'current_contract';

export const Usage: React.FC<UsageProps> = ({ isMock = false }) => {
    const theme = useTheme();
    const axios = useAxiosContext();
    const [reshapedAccount] = useRecoilState(reshapedAccountAtom);
    const { hasIntegration } = usePermissionContext();
    const { showSnackbar } = useSnackbarContext();

    const connectedApps = useMemo(
        () => crmsConnectConfig.filter(({ integration }) => hasIntegration(integration)),
        [hasIntegration],
    );

    const [selectedTimespan, setSelectedTimespan] = useState<string | Date[]>(CURRENT_PERIOD_ACTION);
    const [dateRange, setDateRange] = useState<string[]>([]);
    const [matchData, setMatchData] = useState<TierData | null>(null);
    const [updatedData, setUpdatedData] = useState<TierData | null>(null);
    const [downloadData, setDownloadData] = useState<DownloadData | null>(null);
    const [matchedHistoryData, setMatchedHistoryData] = useState<CRMHistory | null>(null);
    const [isPricingDialogOpen, setIsPricingDialogOpen] = useState(false);
    const [openedConnectorTab, setOpenedConnectorTab] = useState<TabEnum | null>(null);

    const abortController = useRef<AbortController | null>(null);

    const { data: usageData } = useUsageInfo({
        enabled: USAGE_IS_ENABLED || isMock,
    });

    const fetchMatchData = useCallback(async () => {
        if (selectedTimespan !== 'current_contract') {
            return;
        }

        try {
            const { data } = await getMatchedCount(axios, abortController.current?.signal);
            const currentMatchedCompanies = data.current_matched_companies ?? 0;
            setMatchData({
                count: currentMatchedCompanies,
                tierLimit: usageData?.matched_companies_quota || getTierLimit(currentMatchedCompanies),
            });
        } catch (e) {
            showSnackbar('Something went wrong');
        }
    }, [axios, selectedTimespan, showSnackbar, usageData?.matched_companies_quota]);

    const fetchUpdatedData = useCallback(async () => {
        if (selectedTimespan !== 'current_contract') {
            return;
        }
        try {
            const { data } = await getTotalUpdatedCount(axios, abortController.current?.signal);
            const currentUpdatedCompanies = data.total_updated_companies ?? 0;
            setUpdatedData({
                count: currentUpdatedCompanies,
                tierLimit: usageData?.updated_companies_quota || getTierLimit(currentUpdatedCompanies),
            });
        } catch (e) {
            showSnackbar('Something went wrong');
        }
    }, [axios, selectedTimespan, showSnackbar, usageData?.updated_companies_quota]);

    const fetchDownloadData = useCallback(
        async (dates: string[], isMonthly = false, currentPeriod: null | DateRange) => {
            try {
                const response = await getCreditUsage(
                    axios,
                    reshapedAccount.accountId,
                    {
                        after: currentPeriod ? currentPeriod.after : dates[0],
                        before: currentPeriod ? currentPeriod.before : dates[dates.length - 1],
                    },
                    abortController.current?.signal,
                );

                const graphData = getGraphData(dates, response.data, isMonthly);
                setDownloadData({
                    graphData: graphData.data,
                    counts: graphData.counts,
                    tierLimit: usageData?.exported_companies_quota || getTierLimit(graphData.counts.export),
                });
            } catch (e) {
                const error = e as AxiosError;

                if (error.message !== 'canceled') {
                    showSnackbar('Something went wrong');
                }
            }
        },
        [axios, reshapedAccount.accountId, showSnackbar, usageData?.exported_companies_quota],
    );

    const fetchMatchedHistoryData = useCallback(
        async (dates: string[], isMonthly = false, currentPeriod: null | DateRange) => {
            try {
                const response = await getMatchedHistory(
                    axios,
                    {
                        since: currentPeriod ? currentPeriod.after : dates[0],
                        before: currentPeriod ? currentPeriod.before : dates[dates.length - 1],
                    },
                    abortController.current?.signal,
                );
                setMatchedHistoryData({
                    matchedGraphData: formatMatchedHistoryData(dates, response.data, isMonthly),
                    updatedGraphData: formatUpdatedHistoryData(dates, response.data, isMonthly),
                });
            } catch (e) {
                const error = e as AxiosError;

                if (error.message !== 'canceled') {
                    showSnackbar('Something went wrong');
                }
            }
        },
        [axios, showSnackbar],
    );

    const fetchData = useCallback(async () => {
        setMatchData(null);
        setDownloadData(null);
        setMatchedHistoryData(null);

        abortController.current?.abort();

        if (!reshapedAccount.accountId) {
            return;
        }
        const dates: string[] = [];

        let isMonthly = false;
        let currentPeriod: null | DateRange = null;

        if (selectedTimespan === 'current_contract') {
            if (!usageData && USAGE_IS_ENABLED) {
                return;
            }

            const start = usageData?.current_usage_period_start_date
                ? new Date(usageData?.current_usage_period_start_date)
                : startOfYear(new Date());

            eachMonthOfInterval({
                start: new Date(start),
                end: new Date(),
            }).forEach((i) => {
                dates.push(formatDate(i));
            });
            isMonthly = true;
            currentPeriod = {
                after: formatDate(start),
                before: formatDate(new Date()),
            };
            setDateRange([currentPeriod.after, formatDate(add(new Date(), { days: 1 }))]);
        } else if (Array.isArray(selectedTimespan)) {
            const end = selectedTimespan[1] || new Date();

            const dayDiff = differenceInDays(end, selectedTimespan[0]) + 1;
            if (dayDiff > 90) {
                const monthDiff = differenceInMonths(end, selectedTimespan[0]);
                for (let i = 0; i < monthDiff; i++) {
                    dates.push(formatDate(add(selectedTimespan[0], { months: i })));
                }
                dates.push(formatDate(selectedTimespan[1]));
                isMonthly = true;
            } else {
                for (let i = 0; i < dayDiff; i++) {
                    dates.push(formatDate(sub(end, { days: i })));
                }
                dates.reverse();
            }
            setDateRange([dates[0], formatDate(add(end, { days: 1 }))]);
        } else {
            let dateCount = 7;
            if (selectedTimespan === 'last_30d') {
                dateCount = 30;
            }

            for (let i = 0; i < dateCount; i++) {
                dates.push(formatDate(sub(new Date(), { days: i })));
            }
            dates.reverse();
            setDateRange([dates[0], formatDate(add(new Date(), { days: 1 }))]);
        }

        abortController.current = new AbortController();

        fetchMatchData();
        fetchUpdatedData();
        fetchDownloadData(dates, isMonthly, currentPeriod);
        fetchMatchedHistoryData(dates, isMonthly, currentPeriod);
    }, [
        selectedTimespan,
        fetchDownloadData,
        fetchMatchedHistoryData,
        fetchMatchData,
        fetchUpdatedData,
        reshapedAccount.accountId,
        usageData,
    ]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const showPricing = () => setIsPricingDialogOpen(true);

    const downloadDataPoints = getChartData(theme);

    const options = useMemo(() => {
        const currentPeriodStart = usageData?.current_usage_period_start_date;
        const currentPeriodLabel = currentPeriodStart
            ? `Current contract period, ${format(new Date(currentPeriodStart), 'd MMM, yyyy')} - ${format(
                  new Date(),
                  'd MMM, yyyy',
              )}`
            : null;
        return [
            {
                action: CURRENT_PERIOD_ACTION,
                label: currentPeriodLabel ?? 'Current contract period',
            },
            {
                action: 'last_30d',
                label: 'Last 30 days',
            },
            {
                action: 'last_7d',
                label: 'Last 7 days',
            },
        ];
    }, [usageData?.current_usage_period_start_date]);

    const updatesEnabled = updatedData && updatedData.count > 0;

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h3" color="dark" sx={{ marginBottom: '4px' }}>
                    Usage
                </Typography>
                <Typography color="subtle">Includes all your databases</Typography>
            </Grid>

            <Grid item container justifyContent="space-between" alignItems="center">
                <TimeFrameSelect
                    value={selectedTimespan}
                    onChange={(value) => setSelectedTimespan(value)}
                    options={options}
                    variant="tertiary"
                />

                <Button variant="flat" size="small" onClick={showPricing}>
                    See pricing
                </Button>
            </Grid>

            <Grid item>
                <Paper variant="outlined" sx={{ padding: 3 }}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Typography variant="h4" color="dark">
                                Companies in your CRM
                            </Typography>
                        </Grid>

                        {selectedTimespan === 'current_contract' && (
                            <Grid item container justifyContent="space-between" alignItems="center" wrap="nowrap">
                                <Typography variant="h5" color="dark">
                                    Matched
                                </Typography>
                                <Grid item container gap={1} width="auto">
                                    {connectedApps.map((app) => (
                                        <ConnectorDrawerWithButton
                                            key={app.id}
                                            crm={app.id}
                                            tab={openedConnectorTab || 'matching'}
                                            handleClick={() => setOpenedConnectorTab('matching')}
                                            openCompanyMatchingAdvanceMenu
                                            handleClose={() => setOpenedConnectorTab(null)}
                                            open={!!openedConnectorTab}
                                            buttonText="Go to settings"
                                            buttonProps={{
                                                variant: 'tertiary',
                                                startIcon: CRMIcon[app.id] && <Icon type={CRMIcon[app.id]} />,
                                            }}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                        {selectedTimespan !== 'current_contract' && (
                            <Grid item container justifyContent="space-between" alignItems="center" wrap="nowrap">
                                <Typography variant="h5" color="dark">
                                    Matched
                                </Typography>
                            </Grid>
                        )}
                        {matchData && matchData.count > matchData?.tierLimit ? (
                            <Grid item>
                                <FeedbackBanner label="Your team’s usage is currently over quota. In a few days, you will receive an invoice for the difference and see your new quota." />
                            </Grid>
                        ) : null}

                        {selectedTimespan === 'current_contract' && (
                            <Grid item>
                                <TierProgress
                                    title="Current"
                                    tooltip=""
                                    onShowPricingClick={showPricing}
                                    segments={
                                        matchData ? [{ used: matchData.count, color: 'usage.matching' }] : undefined
                                    }
                                    limit={matchData?.tierLimit}
                                    color="usage.matching"
                                    hideProgress={selectedTimespan !== 'current_contract'}
                                />
                            </Grid>
                        )}

                        <Grid item>
                            <Typography variant="subtitle1" color="dark" weight="normal">
                                Over time
                            </Typography>

                            <Paper variant="outlined" sx={{ padding: 3, marginTop: 1 }}>
                                {matchedHistoryData ? (
                                    <AreaChart
                                        data={matchedHistoryData?.matchedGraphData ?? []}
                                        numberStyle="decimal"
                                        style={{
                                            strokeWidth: 3,
                                        }}
                                        showLastDataPointMarker={selectedTimespan === 'current_contract'}
                                        xAxisInterval={
                                            matchedHistoryData?.matchedGraphData?.length &&
                                            matchedHistoryData?.matchedGraphData?.length > 20
                                                ? 2
                                                : 0
                                        }
                                        dataPoints={[downloadDataPoints[2]]}
                                    />
                                ) : (
                                    <Skeleton variant="rectangular" width="100%" height={200} />
                                )}
                            </Paper>
                        </Grid>

                        {updatesEnabled && (
                            <Grid item container justifyContent="space-between" alignItems="center" wrap="nowrap">
                                <Typography variant="h5" color="dark">
                                    Updated
                                </Typography>
                            </Grid>
                        )}

                        {updatedData && updatedData.count > updatedData?.tierLimit ? (
                            <Grid item>
                                <FeedbackBanner label="Your team’s usage is currently over quota. In a few days, you will receive an invoice for the difference and see your new quota." />
                            </Grid>
                        ) : null}

                        {updatesEnabled && selectedTimespan === 'current_contract' && (
                            <Grid item>
                                <TierProgress
                                    title="Total"
                                    tooltip=""
                                    onShowPricingClick={showPricing}
                                    segments={
                                        updatedData ? [{ used: updatedData.count, color: 'usage.updated' }] : undefined
                                    }
                                    limit={updatedData?.tierLimit}
                                    color="usage.updated"
                                    hideProgress={selectedTimespan !== 'current_contract'}
                                />
                            </Grid>
                        )}

                        {updatesEnabled && (
                            <Grid item>
                                <Typography variant="subtitle1" color="dark" weight="normal">
                                    Over time
                                </Typography>

                                <Paper variant="outlined" sx={{ padding: 3, marginTop: 1 }}>
                                    {matchedHistoryData ? (
                                        <AreaChart
                                            data={matchedHistoryData?.updatedGraphData ?? []}
                                            numberStyle="decimal"
                                            style={{
                                                strokeWidth: 3,
                                            }}
                                            showLastDataPointMarker={selectedTimespan === 'current_contract'}
                                            xAxisInterval={
                                                matchedHistoryData?.updatedGraphData?.length &&
                                                matchedHistoryData?.updatedGraphData?.length > 20
                                                    ? 2
                                                    : 0
                                            }
                                            dataPoints={[downloadDataPoints[3]]}
                                        />
                                    ) : (
                                        <Skeleton variant="rectangular" width="100%" height={200} />
                                    )}
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>

            <Grid item>
                <Paper variant="outlined" sx={{ padding: 3 }}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Typography variant="h4" color="dark">
                                Downloaded companies
                            </Typography>
                        </Grid>

                        {downloadData && downloadData.counts.export > downloadData?.tierLimit ? (
                            <Grid item>
                                <FeedbackBanner label="Your team’s usage is currently over quota. In a few days, you will receive an invoice for the difference and see your new quota." />
                            </Grid>
                        ) : null}

                        <Grid item>
                            <TierProgress
                                tooltip=""
                                onShowPricingClick={showPricing}
                                limit={downloadData?.tierLimit}
                                color="usage.download"
                                hideProgress={selectedTimespan !== 'current_contract'}
                                segments={
                                    downloadData
                                        ? [{ used: downloadData.counts.export, color: 'usage.download' }]
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item>
                            <Typography variant="subtitle1" color="dark" weight="normal">
                                Over time
                            </Typography>

                            <Paper variant="outlined" sx={{ padding: 3, marginTop: 1 }}>
                                {downloadData ? (
                                    <AreaChart
                                        data={downloadData.graphData}
                                        numberStyle="decimal"
                                        style={{
                                            strokeWidth: 3,
                                        }}
                                        showLastDataPointMarker={selectedTimespan === 'current_contract'}
                                        xAxisInterval={downloadData.graphData.length > 20 ? 2 : 0}
                                        dataPoints={[downloadDataPoints[0]]}
                                    />
                                ) : (
                                    <Skeleton variant="rectangular" width="100%" height={200} />
                                )}
                            </Paper>
                        </Grid>

                        <Grid item>
                            <Typography variant="subtitle1" color="dark" weight="normal">
                                History
                            </Typography>

                            <HistoryTable dateRange={dateRange} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item>
                <Paper variant="outlined" sx={{ padding: 3 }}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Typography variant="h4" color="dark">
                                Companies exported via API
                            </Typography>
                        </Grid>

                        <Grid item>
                            <TierProgress
                                tooltip=""
                                onShowPricingClick={showPricing}
                                color="usage.downloadAccent"
                                limit={downloadData?.tierLimit}
                                hideProgress
                                segments={
                                    downloadData
                                        ? [{ used: downloadData.counts.api, color: 'usage.downloadAccent' }]
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item>
                            <Typography variant="subtitle1" color="dark" weight="normal">
                                Over time
                            </Typography>

                            <Paper variant="outlined" sx={{ padding: 3, marginTop: 1 }}>
                                {downloadData ? (
                                    <AreaChart
                                        data={downloadData.graphData}
                                        numberStyle="decimal"
                                        style={{
                                            strokeWidth: 3,
                                        }}
                                        showLastDataPointMarker={selectedTimespan === 'current_contract'}
                                        xAxisInterval={downloadData.graphData.length > 20 ? 2 : 0}
                                        dataPoints={[downloadDataPoints[1]]}
                                    />
                                ) : (
                                    <Skeleton variant="rectangular" width="100%" height={200} />
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <PricingDialog open={isPricingDialogOpen} onClose={() => setIsPricingDialogOpen(false)} />
        </Grid>
    );
};

export default Usage;

function getTierLimit(count: number) {
    return Math.floor(count / 1000) * 1000 + 1000;
}

function getGraphData(dates: string[], usageData: CreditUsage, isMonthly: boolean) {
    const endOfCurrentMonth = endOfMonth(new Date()).toISOString();
    const dataByDateMap = Object.fromEntries(
        dates.map((date) => [
            date,
            {
                api: isMonthly && date > endOfCurrentMonth ? undefined : 0,
                export: isMonthly && date > endOfCurrentMonth ? undefined : 0,
                label: format(new Date(date), isMonthly ? 'MMM' : 'MMM d'),
            },
        ]),
    );

    const cumulativeCount: Record<string, number> = {
        api: 0,
        export: 0,
        value: 0,
    };

    if (isMonthly) {
        dates.forEach((date) => {
            const firstDayOfMonth = formatDate(startOfMonth(new Date(date)));
            Object.entries(usageData.usage).forEach(([usageDate, usage]) => {
                if (formatDate(startOfMonth(new Date(usageDate))) === firstDayOfMonth) {
                    usage.forEach((item) => {
                        if (item.source === 'vainu-platform-csv-export') {
                            cumulativeCount.export += item.result_count;
                            cumulativeCount.value += item.result_count;
                        } else if (item.source === 'api') {
                            cumulativeCount.api += item.result_count;
                            cumulativeCount.value += item.result_count;
                        }
                    });
                }
            });
            if (dataByDateMap[date].api !== undefined) {
                dataByDateMap[date].api = cumulativeCount.api;
            }
            if (dataByDateMap[date].export !== undefined) {
                dataByDateMap[date].export = cumulativeCount.export;
            }
        });
    } else {
        dates.forEach((date) => {
            usageData.usage[`${date}T00:00:00+00:00`]?.forEach((item) => {
                if (item.source === 'vainu-platform-csv-export') {
                    cumulativeCount.export += item.result_count;
                    cumulativeCount.value += item.result_count;
                } else if (item.source === 'api') {
                    cumulativeCount.api += item.result_count;
                    cumulativeCount.value += item.result_count;
                }
            });
            dataByDateMap[date].api = cumulativeCount.api;
            dataByDateMap[date].export = cumulativeCount.export;
        });
    }

    return {
        counts: cumulativeCount,
        data: Object.values(dataByDateMap).map((point) => ({
            ...point,
            value: point.api == null || point.export == null ? undefined : point.api + point.export,
        })),
    };
}

const formatMatchedHistoryData = (
    dates: string[],
    data: CRMUsageHistory[],
    isMonthly: boolean,
): ChartCategoryDataPoint[] => {
    if (isMonthly) {
        const mapping = new Map<string, number>();
        const currentMonth = startOfMonth(new Date());
        const startOfToday = startOfDay(new Date());
        const todayIsFirstDayOfMonth = startOfToday === currentMonth;
        dates.forEach((date) => {
            const dateObject = startOfMonth(new Date(date));
            // do not show current month if it is the first day of current month, since we do not have value for today yet
            const continueToAddValue =
                (todayIsFirstDayOfMonth && dateObject < currentMonth) ||
                (!todayIsFirstDayOfMonth && dateObject <= currentMonth);
            if (continueToAddValue) {
                const firstDayOfMonth = formatDate(dateObject);
                mapping.set(firstDayOfMonth, 0);
            }
        });
        // use the maximum value for the month value
        const monthlyData = data.reduce((acc, { time, value }) => {
            const firstDayOfMonth = formatDate(startOfMonth(new Date(time)));
            const max = acc.get(firstDayOfMonth) || 0;
            acc.set(firstDayOfMonth, Math.max(max, value));

            return acc;
        }, mapping);

        return Array.from(monthlyData, ([time, value]) => ({
            value,
            label: format(new Date(time), 'MMM'),
        }));
    }
    const mapping = new Map<string, number>();
    const startOfToday = startOfDay(new Date());
    dates.forEach((date) => {
        // do not show future dates
        const dateObject = new Date(date);
        if (dateObject < startOfToday) {
            mapping.set(format(dateObject, 'MMM d'), 0);
        }
    });
    data.forEach(({ time, value }) => {
        mapping.set(format(new Date(time), 'MMM d'), value);
    });
    return Array.from(mapping, ([label, value]) => ({
        value,
        label,
    }));
};

const formatUpdatedHistoryData = (
    dates: string[],
    data: CRMUsageHistory[],
    isMonthly: boolean,
): ChartCategoryDataPoint[] => {
    // use maximum value even if matched count would decrease
    const matchedHistoryData = formatMatchedHistoryData(dates, data, isMonthly);
    let maxValue = 0;
    return matchedHistoryData.map(({ value, label }) => {
        if (value && value > maxValue) {
            maxValue = value;
        }
        return {
            value: value && value > maxValue ? value : maxValue,
            label,
        };
    });
};

const getChartData = (theme: Theme) => [
    {
        dataKey: 'export',
        color: theme.palette.usage.download,
        label: 'Export',
        fillColor: 'url(#export-gradient)',
        gradientDef: (
            <defs>
                <linearGradient id="export-gradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={0} stopColor={theme.palette.usage.download} stopOpacity={0.2} />
                    <stop offset={1} stopColor={theme.palette.usage.download} stopOpacity={0} />
                </linearGradient>
            </defs>
        ),
    },
    {
        dataKey: 'api',
        color: theme.palette.usage.downloadAccent,
        label: 'API',
        fillColor: 'url(#api-gradient)',
        gradientDef: (
            <defs>
                <linearGradient id="api-gradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={0} stopColor={theme.palette.usage.downloadAccent} stopOpacity={0.2} />
                    <stop offset={1} stopColor={theme.palette.usage.downloadAccent} stopOpacity={0} />
                </linearGradient>
            </defs>
        ),
    },
    {
        dataKey: 'value',
        color: theme.palette.usage.matchedHistory,
        label: 'Matched',
        fillColor: 'url(#matchedHistory-gradient)',
        gradientDef: (
            <defs>
                <linearGradient id="matchedHistory-gradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={0} stopColor={theme.palette.usage.matchedHistory} stopOpacity={0.2} />
                    <stop offset={1} stopColor={theme.palette.usage.matchedHistory} stopOpacity={0} />
                </linearGradient>
            </defs>
        ),
    },
    {
        dataKey: 'value',
        color: theme.palette.usage.updatedHistory,
        label: 'Updated',
        fillColor: 'url(#updatedHistory-gradient)',
        gradientDef: (
            <defs>
                <linearGradient id="updatedHistory-gradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={0} stopColor={theme.palette.usage.updatedHistory} stopOpacity={0.2} />
                    <stop offset={1} stopColor={theme.palette.usage.updatedHistory} stopOpacity={0} />
                </linearGradient>
            </defs>
        ),
    },
];
