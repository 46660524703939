import React, { useContext, useEffect, useRef } from 'react';

import { Button, Menu, MenuItem, Switch, Typography, styled } from '@mui/material';

import Icon from 'components/tokens/Icon';
import PulseDotAnimation from 'components/tokens/PulseDotAnimation';

import { ConnectorTabContext } from '../context/ConnectorTabContext';
import { CrmName } from '../types/Crm';
import { getCRMName } from '../utils';

interface Props {
    isAutoSyncEnabled: boolean;
    isAccountAdmin: boolean;
    crm: CrmName;
    handleAutoSyncChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleFullImportSync: () => void;
}

const AdvanceDropdown: React.FC<Props> = ({
    isAutoSyncEnabled,
    isAccountAdmin,
    crm,
    handleAutoSyncChange,
    handleFullImportSync,
}) => {
    const { openCompanyMatchingAdvanceMenu = false, closeCompanyMatchingAdvanceMenu } =
        useContext(ConnectorTabContext) || {};

    const anchorRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        if (openCompanyMatchingAdvanceMenu && !anchorEl && isAutoSyncEnabled !== undefined) {
            setAnchorEl(anchorRef.current);
        }
    }, [anchorEl, openCompanyMatchingAdvanceMenu, isAutoSyncEnabled]);

    const hanldeDropdownOpen = (event: React.MouseEvent) => {
        event.preventDefault();
        setAnchorEl(anchorRef.current);
    };

    const handleDropdownClose = (e: Event | React.SyntheticEvent) => {
        e.preventDefault();
        setAnchorEl(null);
        if (closeCompanyMatchingAdvanceMenu) {
            closeCompanyMatchingAdvanceMenu();
        }
    };

    return (
        <>
            <AnchorButton
                ref={anchorRef}
                aria-controls="customized-menu"
                aria-haspopup="true"
                disableRipple
                disabled={!isAccountAdmin}
                onClick={hanldeDropdownOpen}
            >
                <Icon type="MoreHorizontal" />
                {!isAutoSyncEnabled && <PulseDotAnimation color="rgba(242, 151, 17, 0.8)" />}
            </AnchorButton>
            <Menu
                id="customized-menu"
                sx={{
                    '.MuiMenu-paper': {
                        boxShadow: '0px 20px 36px -8px rgba(10, 10, 10, 0.1), 0px 1px 1px rgba(10, 10, 10, 0.05)',
                        borderRadius: '8px',
                        marginTop: '10px',
                        paddingTop: '10px',
                    },
                }}
                keepMounted
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleDropdownClose}
            >
                <StyledMenuItem disableRipple>
                    <div>
                        <Typography gutterBottom color="textPrimary" fontSize="14px" fontWeight={500}>
                            Keep matching companies
                        </Typography>
                        <SyncAdvanceDescription color="textSecondary">
                            <CompanyMatchingStatus isSyncEnabled={isAutoSyncEnabled}>
                                Company matching is {isAutoSyncEnabled ? 'on' : 'off'}.{' '}
                            </CompanyMatchingStatus>
                            Vainu keeps track of your {getCRMName(crm)} Companies and automatically checks for new ones
                        </SyncAdvanceDescription>
                    </div>
                    <StyledSwitch
                        disabled={!isAccountAdmin}
                        checked={isAutoSyncEnabled}
                        color="primary"
                        onChange={handleAutoSyncChange}
                    />
                </StyledMenuItem>
                <StyledMenuItem disableRipple>
                    <div>
                        <Typography gutterBottom color="textPrimary" fontSize="14px" fontWeight={500}>
                            Need to start again?
                        </Typography>
                        <SyncAdvanceDescription color="textSecondary">
                            If something went wrong, restart company matching from the beginning
                        </SyncAdvanceDescription>
                    </div>
                    <RestartButton
                        data-testid="restart-sync"
                        disableRipple
                        disabled={!isAccountAdmin}
                        onClick={handleFullImportSync}
                    >
                        Restart
                    </RestartButton>
                </StyledMenuItem>
            </Menu>
        </>
    );
};

const AnchorButton = styled(Button)(({ theme: { palette } }) => ({
    padding: 0,
    background: palette.menu.hover,
    border: `1px solid ${palette.grey[200]}`,
    borderRadius: '4px',
}));

const RestartButton = styled(AnchorButton)({
    padding: '0 12px',
});

const SyncAdvanceDescription = styled(Typography)({
    fontSize: '12px',
    width: '250px',
    whiteSpace: 'initial',
});

export default AdvanceDropdown;

const StyledSwitch = styled(Switch)(({ theme: { palette } }) => ({
    '&.MuiSwitch-root': {
        marginLeft: '10px',
    },
    '&.MuiSwitch-root .Mui-checked': {
        color: palette.success.main,
        '&.MuiSwitch-track': {
            backgroundColor: palette.success.main,
            border: `2px solid ${palette.success.main}`,
        },
    },
    '&.MuiSwitch-root .MuiSwitch-track': {
        backgroundColor: palette.common.white,
        border: '1px solid #979797',
    },
}));

// todo: it should not be <li>
const StyledMenuItem = styled(MenuItem)({
    '&.MuiMenuItem-root': {
        marginBottom: 10,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

const CompanyMatchingStatus = styled('span')<{ isSyncEnabled: boolean }>(({ theme, isSyncEnabled }) => ({
    color: isSyncEnabled ? theme.palette.success.main : theme.palette.error.main,
    fontWeight: 700,
}));
