import React, { useEffect, useRef, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { createPortal } from 'react-dom';

import { useThrottle } from 'hooks/useThrottle';

import Fade, { FadeProps } from './Fade';
import ScrollHint from './ScrollHint';

export type OverflowContainerProps = {
    children?: React.ReactElement;
    /** Sx props for the container. Ignored if containerEl is used. */
    sx?: SxProps<Theme>;
    /** Use to position the fade into a scrolling container defined by a ref, instead of
     * this component creating its own wrapper. Uses a portal.
     *
     * The scroll container must be inside an element that has position: relative in order
     * to prevent the fade from overlapping the scrollbars. The container itself must NOT have
     * position: relative.  */
    containerEl?: HTMLElement | null;
    headerPadding?: number;
    leftPadding?: number;
    mode?: 'both' | 'horizontal' | 'vertical';
    /** size of the horizontal shadows in pixels
     *
     * number to set both left and right, [number, number] to set individually
     */
    horizontalSize?: number | [left: number, right: number];
    /** size of the vertical shadows in pixels
     *
     * number to set both top and bottom, [number, number] to set individually
     */
    verticalSize?: number | [top: number, bottom: number];
    scrollHint?: boolean;
    /** Use custom box-shadow instead of linear-gradient */
    shadows?: { top?: string; left?: string; right?: string; bottom?: string };
    color?: FadeProps['color'];
    onScroll?: (el: HTMLElement) => void;
};

export const OverflowContainer: React.FC<OverflowContainerProps> = ({
    children,
    sx,
    containerEl,
    headerPadding = 0,
    leftPadding = 0,
    mode = 'both',
    horizontalSize: horizontalSizeProp = 96,
    verticalSize: verticalSizeProp = 72,
    scrollHint,
    shadows,
    color,
    onScroll,
}) => {
    const [[leftFadePhase, rightFadePhase, scrollbarWidth, horizontalFadeHeight], setHorizontalFadePos] = useState<
        [number, number, number, number]
    >([0, 0, 0, 0]);
    const [[topFadePhase, bottomFadePhase, scrollbarHeight, verticalFadeWidth], setVerticalFadePos] = useState<
        [number, number, number, number]
    >([0, 0, 0, 0]);

    // The scrolling hint is only shown initially and disappears permanently after scrolling.
    const [showHint, setShowHint] = useState<boolean | undefined>(undefined);
    const resizeObserver = useRef<ResizeObserver | null>(null);
    const ownRef = useRef<HTMLElement | null>(null);

    const horizontalEnabled = mode === 'both' || mode === 'horizontal';
    const verticalEnabled = mode === 'both' || mode === 'vertical';

    const horizontalSize =
        typeof horizontalSizeProp === 'number' ? [horizontalSizeProp, horizontalSizeProp] : horizontalSizeProp;
    const verticalSize = typeof verticalSizeProp === 'number' ? [verticalSizeProp, verticalSizeProp] : verticalSizeProp;

    const handleOverflowStatus = useThrottle((containerEl: HTMLElement, showHint: boolean | undefined) => {
        const el = containerEl ?? ownRef.current;
        if (el) {
            const { offsetWidth, clientWidth, scrollWidth, scrollLeft } = el;
            const { offsetHeight, clientHeight, scrollHeight, scrollTop } = el;

            if (horizontalEnabled && clientWidth < scrollWidth) {
                const scrollbarWidth = offsetWidth - clientWidth;
                const newLeftFadePhase = Math.min(scrollLeft - horizontalSize[0], 0);
                const newRightFadePhase = Math.min(scrollWidth - clientWidth - scrollLeft - horizontalSize[1], 0);
                setHorizontalFadePos([newLeftFadePhase, newRightFadePhase, scrollbarWidth, clientHeight]);
            } else {
                setHorizontalFadePos([0, 0, 0, 0]);
            }
            if (verticalEnabled && clientHeight < scrollHeight) {
                const scrollbarHeight = offsetHeight - clientHeight;
                const newTopFadePhase = Math.min(scrollTop - verticalSize[0], 0);
                const newBottomFadePhase = Math.min(scrollHeight - clientHeight - scrollTop - verticalSize[1], 0);
                setVerticalFadePos([newTopFadePhase, newBottomFadePhase, scrollbarHeight, clientWidth]);
                if (scrollTop === 0) {
                    if (showHint === undefined) {
                        setShowHint(true);
                    }
                } else {
                    setShowHint(false);
                }
            } else {
                setVerticalFadePos([0, 0, 0, 0]);
                setShowHint(false);
            }
        }
    }, 67);
    // ^Restricted to modest 15fps

    useEffect(() => {
        const el = containerEl ?? ownRef.current;
        if (el) {
            const handler = () => handleOverflowStatus(el, showHint);
            window.addEventListener('resize', handler);
            // window.setTimeout(handler, 1);
            handleOverflowStatus(el, showHint);
            el.addEventListener('scroll', handler);
            if (window.ResizeObserver) {
                resizeObserver.current = new ResizeObserver(handler);
                resizeObserver.current.observe(el);
            }
            return () => {
                window.removeEventListener('resize', handler);
                el.removeEventListener('scroll', handler);
                if (resizeObserver.current) {
                    resizeObserver.current.unobserve(el);
                }
            };
        }
    }, [handleOverflowStatus, showHint, containerEl, ownRef, children]);

    const fades = (
        <>
            {horizontalEnabled && (
                <>
                    <Fade
                        position="left"
                        width={horizontalSize[0]}
                        height={horizontalFadeHeight}
                        scrollbarGutter={scrollbarWidth}
                        headerPadding={headerPadding}
                        leftPadding={leftPadding}
                        phase={leftFadePhase}
                        shadow={shadows?.left}
                        color={color}
                    />

                    <Fade
                        position="right"
                        width={horizontalSize[1]}
                        height={horizontalFadeHeight}
                        scrollbarGutter={scrollbarWidth}
                        headerPadding={headerPadding}
                        leftPadding={leftPadding}
                        phase={rightFadePhase}
                        shadow={shadows?.right}
                        color={color}
                    />
                </>
            )}
            {verticalEnabled && (
                <>
                    <Fade
                        position="top"
                        height={verticalSize[0]}
                        width={verticalFadeWidth}
                        scrollbarGutter={scrollbarHeight}
                        headerPadding={headerPadding}
                        leftPadding={leftPadding}
                        phase={topFadePhase}
                        shadow={shadows?.top}
                        color={color}
                    />

                    <Fade
                        position="bottom"
                        height={verticalSize[1]}
                        width={verticalFadeWidth}
                        scrollbarGutter={scrollbarHeight}
                        headerPadding={headerPadding}
                        leftPadding={leftPadding}
                        phase={bottomFadePhase}
                        shadow={shadows?.bottom}
                        color={color}
                    />
                    {scrollHint && <ScrollHint opacity={showHint ? 1 : 0} />}
                </>
            )}
        </>
    );

    if (containerEl) {
        return (
            <>
                {createPortal(fades, containerEl)}
                {children}
            </>
        );
    }
    if (containerEl === null) {
        return null;
    }
    return (
        <Box sx={{ position: 'relative', overflow: 'hidden', ...sx }}>
            <Box
                sx={{ overflow: 'auto', height: '100%' }}
                ref={ownRef}
                onScroll={() =>
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onScroll?.(ownRef.current!)
                }
            >
                {children}
                {fades}
            </Box>
        </Box>
    );
};

export default OverflowContainer;
