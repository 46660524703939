import { useState, useMemo, useEffect, useCallback } from 'react';

import { useRecoilValue } from 'recoil';
import { assertArray, filterArrayBySearch, isNotNil } from 'utilities';

import { fetchList } from 'api/lists';
import { Team } from 'api/types/Account';
import { List } from 'api/types/List';
import { DialogProps } from 'components/tokens/Dialog';
import { useAxiosContext } from 'contexts/AxiosContext';
import reshapedAccountAtom from 'store/reshapedAccountAtom';

import { ListDialog } from '../common/ListDialog';
import SharedListContent from './components/SharedListContent';
import { Permission, UserRow } from './types';

interface ShareListProps extends Omit<DialogProps, 'onSubmit'> {
    list: List;
    onSubmit: (props: { permissions_full: string[]; permissions_readonly: string[] }) => void;
}

export const ShareList: React.FC<ShareListProps> = ({ title, open = false, list, onClose, onSubmit }) => {
    const [searchText, setSearchText] = useState('');
    const [allReadOnlyProfileIds, setAllReadOnlyProfileIds] = useState<string[]>([]);
    const [allReadWriteProfileIds, setAllReadWriteProfileIds] = useState<string[]>([]);
    const { users: accountUsers } = useRecoilValue(reshapedAccountAtom);
    const [listOwner, setListOwner] = useState<Team | undefined>(() =>
        accountUsers.find((user) => user.email === list.owner),
    );
    const axios = useAxiosContext();

    const updatePermissions = useCallback(async () => {
        const { data } = await fetchList(axios, list.type, list.id);

        setListOwner(accountUsers.find((user) => user.email === data.owner));

        setAllReadOnlyProfileIds(
            assertArray(data.permissions_readonly)
                .map((email) => accountUsers.find((user) => user.email === email)?.id)
                .filter(isNotNil),
        );

        setAllReadWriteProfileIds(
            assertArray(data.permissions_full)
                .map((email) => accountUsers.find((user) => user.email === email)?.id)
                .filter(isNotNil),
        );
    }, [accountUsers, axios, list.id, list.type]);

    useEffect(() => {
        updatePermissions();
    }, [updatePermissions]);

    const usersWithoutListOwner = useMemo(
        () => accountUsers.filter((user) => user.user !== listOwner?.user),
        [accountUsers, listOwner?.user],
    );

    const users = useMemo(() => {
        if (!listOwner) {
            return [...usersWithoutListOwner];
        }
        return [listOwner, ...usersWithoutListOwner];
    }, [listOwner, usersWithoutListOwner]);
    const filteredUsers = useMemo(() => filterArrayBySearch(users, searchText, ['email']), [searchText, users]);
    const userRows: UserRow[] = useMemo(
        () =>
            filteredUsers.map((user) => {
                return {
                    id: user.id,
                    email: user.email,
                    user: user.user,
                    read_write: 'read_write',
                    read_only: 'read_only',
                    not_shared: 'not_shared',
                };
            }),
        [filteredUsers],
    );

    const handleUserPermissionChange = (profileId: string, type: Permission) => {
        let newReadOnlyProfileIds = [...allReadOnlyProfileIds];
        let newReadWriteProfileIds = [...allReadWriteProfileIds];
        if (type === 'read_only') {
            newReadOnlyProfileIds.push(profileId);
            newReadWriteProfileIds = newReadWriteProfileIds.filter((id) => id !== profileId);
        } else if (type === 'read_write') {
            newReadOnlyProfileIds = newReadOnlyProfileIds.filter((id) => id !== profileId);
            newReadWriteProfileIds.push(profileId);
        } else {
            newReadOnlyProfileIds = newReadOnlyProfileIds.filter((id) => id !== profileId);
            newReadWriteProfileIds = newReadWriteProfileIds.filter((id) => id !== profileId);
        }
        setAllReadOnlyProfileIds(newReadOnlyProfileIds);
        setAllReadWriteProfileIds(newReadWriteProfileIds);
    };

    const handleAccountLevelPermissionChange = (readIds: string[], readWriteIds: string[]) => {
        setAllReadOnlyProfileIds(readIds);
        setAllReadWriteProfileIds(readWriteIds);
    };

    if (!listOwner) {
        return null;
    }

    return (
        <ListDialog
            open={open}
            onClose={onClose}
            title={title}
            message=""
            cancelLabel="Cancel"
            confirmLabel="Save"
            maxWidth="md"
            fullWidth
            onSubmit={(e) => {
                onSubmit({
                    permissions_full: allReadWriteProfileIds
                        .map((profileId) => accountUsers.find((user) => user.id === profileId)?.email)
                        .filter(isNotNil),
                    permissions_readonly: allReadOnlyProfileIds
                        .map((profileId) => accountUsers.find((user) => user.id === profileId)?.email)
                        .filter(isNotNil),
                });
                onClose(e);
            }}
        >
            <SharedListContent
                listOwner={listOwner}
                totalUsersCount={users.length}
                usersWithoutListOwner={usersWithoutListOwner}
                userRows={userRows}
                allReadOnlyProfileIds={allReadOnlyProfileIds}
                allReadWriteProfileIds={allReadWriteProfileIds}
                searchText={searchText}
                onSearchChange={setSearchText}
                onAccountLevelPermissionChange={handleAccountLevelPermissionChange}
                onUserPermissionChange={handleUserPermissionChange}
            />
        </ListDialog>
    );
};

export default ShareList;
