import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PlayCircleFilled = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C6.47967 21.994 2.00606 17.5204 2 12V11.8C2.10993 6.30455 6.63459 1.92797 12.1307 2.0009C17.6268 2.07382 22.0337 6.5689 21.9978 12.0654C21.9619 17.5618 17.4966 21.9989 12 22ZM10 7.50002V16.5L16 12L10 7.50002Z" />
    </SvgIcon>
);

export default PlayCircleFilled;
