import { useMemo } from 'react';

import { styled } from '@mui/material';

import { IntegrationKey } from 'api/types/ApplicationStore';
import { Typography } from 'components/tokens/Typography';

import { CrmName } from '../../types/Crm';
import { CRMConnectTile } from '../CRMConnectTile';
import { crmsConnectConfig } from '../utils';

type ChooseCRMLandingPageProps = {
    checkIsConnected: (integration: IntegrationKey) => boolean;
    checkIsPurchased: (crm: CrmName) => boolean;
};

const ChooseCRMLandingPage: React.FC<ChooseCRMLandingPageProps> = ({ checkIsConnected, checkIsPurchased }) => {
    const crmConnectPages = useMemo(() => crmsConnectConfig.filter((crm) => crm.id !== 'salesforcesandbox'), []);

    return (
        <Container>
            <Typography variant="h2" textAlign="center" color="dark">
                Choose your CRM
            </Typography>
            <Typography variant="subtitle2" textAlign="center">
                Connect with Vainu and save your time to make better deals!
            </Typography>
            <CRMTilesGrid>
                {crmConnectPages.map(({ id, integration }) => (
                    <CRMConnectTile
                        key={id}
                        isConnected={checkIsConnected(integration)}
                        isPurchased={checkIsPurchased(id)}
                        crm={id}
                    />
                ))}
            </CRMTilesGrid>
        </Container>
    );
};

const Container = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    margin: '0 auto',
    padding: '5rem 0',
    borderRadius: '8px',
    backgroundColor: palette.brandColors.white,
}));

const CRMTilesGrid = styled('div')({
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px 24px',
    margin: '2rem auto',
});

export { ChooseCRMLandingPage };
