import SocialNetworkFeeds from 'components/features/SocialNetwork/Feeds';
import SocialNetworkHeader from 'components/features/SocialNetwork/Header';
import {
    getSocialNetworksHeaderFromCompanyData,
    getSocialNetworkFeedsFromCompanyData,
} from 'components/features/SocialNetwork/utils';

import { TabContent, CompanyTabProps } from '..';

export const SocialNetworkTab: React.VFC<CompanyTabProps> = ({ data }) => {
    return (
        <TabContent>
            <SocialNetworkHeader socialNetworks={getSocialNetworksHeaderFromCompanyData(data)} />
            <SocialNetworkFeeds {...getSocialNetworkFeedsFromCompanyData(data)} />
        </TabContent>
    );
};

export default SocialNetworkTab;
