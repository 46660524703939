import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Refresh: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M11.995 4.00001C7.83623 3.99432 4.36667 7.17599 4.01302 11.3197C3.65936 15.4634 6.53958 19.187 10.6392 19.8862C14.7388 20.5853 18.6903 18.0267 19.73 14H17.649C16.6318 16.8771 13.617 18.5324 10.6435 17.8465C7.66992 17.1605 5.68491 14.3519 6.03082 11.3199C6.37673 8.28792 8.94335 5.99856 11.995 6.00001C13.5846 6.00234 15.1064 6.64379 16.218 7.78002L13 11H20V4.00001L17.649 6.35002C16.1527 4.84464 14.1175 3.99873 11.995 4.00001Z" />
    </SvgIcon>
);

export default Refresh;
