import React, { useMemo, useState } from 'react';

import { Tooltip } from '@mui/material';

import Icon, { IconType } from 'components/tokens/Icon';
import Tag from 'components/tokens/Tag';
import { TextField } from 'components/tokens/TextField';

import { CRMPropertiesMenuOption } from '../types/Crm';
import { DescriptionListItem, DescriptionTerm, DescriptionDefinition } from './MappingInfoTooltip';
import { Options } from './Options';

type MenuProps = {
    anchorElement: React.ReactElement;
    options: CRMPropertiesMenuOption[];
    onSelect: (item: CRMPropertiesMenuOption) => void;
};

// Why I didn't use existing Menu and ListMenu components:
// - each Menu & ListMenu item should have 'action' and 'onClick' properties. This requirement looks redundant;
// - each Menu & ListMenu item always renders string using a 'label' property;
// - Menu and ListMenu's items don't truncate long string values;

const renderOption = (crmProperty: CRMPropertiesMenuOption) => (
    <Tooltip
        placement="right"
        componentsProps={{
            tooltip: {
                sx: {
                    width: '200px',
                    padding: '8px',
                    borderRadius: '8px',
                },
            },
        }}
        title={
            <>
                {crmProperty.disabled && (
                    <Tag
                        sx={{ marginBottom: '8px' }}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon type="CheckBig" sx={{ width: '12px', height: '12px' }} />
                                Added
                            </div>
                        }
                    />
                )}
                <dl>
                    <DescriptionListItem>
                        <DescriptionTerm>API name</DescriptionTerm>
                        <DescriptionDefinition>{crmProperty.value}</DescriptionDefinition>
                    </DescriptionListItem>
                    <DescriptionListItem>
                        <DescriptionTerm>Field type</DescriptionTerm>
                        <DescriptionDefinition>{crmProperty.type}</DescriptionDefinition>
                    </DescriptionListItem>
                </dl>
            </>
        }
    >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {crmProperty.label}
            {crmProperty.disabled && <Icon fontSize="small" type="CheckBig" />}
        </div>
    </Tooltip>
);

const CRMPropertiesMenu: React.FC<MenuProps> = ({ anchorElement, options, onSelect }) => {
    const [searchValue, setSearchValue] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const isOpen = Boolean(anchorEl);

    const filteredOptions = useMemo(() => {
        const compareToSearchValue = (value: string) => {
            return value.toLowerCase().includes(searchValue.toLowerCase());
        };

        return options.filter((option) => compareToSearchValue(option.label) || compareToSearchValue(option.value));
    }, [searchValue, options]);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSearchValue('');
    };

    return (
        <>
            <anchorElement.type {...anchorElement.props} aria-expanded={isOpen} onClick={handleOpen} />
            <Options<CRMPropertiesMenuOption>
                data-testid="crm-properties-menu"
                anchorEl={anchorEl}
                options={filteredOptions}
                topAddon={
                    <TextField
                        compact
                        autoFocus
                        placeholder="Search..."
                        icon={searchValue.length > 0 ? 'Search' : ('' as IconType)}
                        onChange={({ target: { value } }) => setSearchValue(value)}
                        // .stopPropagation() is required to prevent MUI Menu's default autoFocus on key down
                        onKeyDown={(e) => {
                            if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
                                e.stopPropagation();
                            }
                        }}
                    />
                }
                itemToKey={(item) => item.value}
                itemToString={renderOption}
                onClick={(item) => onSelect(item)}
                onClose={handleClose}
            />
        </>
    );
};

export { CRMPropertiesMenu };
