import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PipedriveDealSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M10.0001 1.66663C12.2102 1.66663 14.3298 2.5446 15.8926 4.1074C17.4554 5.67021 18.3334 7.78982 18.3334 9.99996C18.3334 12.2101 17.4554 14.3297 15.8926 15.8925C14.3298 17.4553 12.2102 18.3333 10.0001 18.3333C7.78994 18.3333 5.67033 17.4553 4.10752 15.8925C2.54472 14.3297 1.66675 12.2101 1.66675 9.99996C1.66675 7.78982 2.54472 5.67021 4.10752 4.1074C5.67033 2.5446 7.78994 1.66663 10.0001 1.66663V1.66663ZM11.0417 4.16663H9.07508V5.56663C7.80842 5.84163 6.78342 6.64996 6.78342 7.89996C6.78342 9.39996 8.03342 10.15 9.86675 10.5833C11.5001 10.975 11.8251 11.5416 11.8251 12.1416C11.8251 12.5916 11.5084 13.3083 10.0584 13.3083C8.70841 13.3083 8.18341 12.7083 8.10841 11.9416H6.66675C6.75008 13.3583 7.81675 14.1583 9.08341 14.425V15.8333H11.0417V14.4416C12.3167 14.2 13.3334 13.4666 13.3334 12.1416C13.3334 10.3 11.7417 9.66663 10.2501 9.28329C8.76675 8.90829 8.29175 8.50829 8.29175 7.89163C8.29175 7.18329 8.95841 6.69163 10.0584 6.69163C11.2251 6.69163 11.6584 7.24163 11.7001 8.05829H13.1417C13.1274 7.46785 12.9111 6.90015 12.529 6.4498C12.1469 5.99944 11.622 5.69362 11.0417 5.58329V4.16663V4.16663Z" />
    </SvgIcon>
);
export default PipedriveDealSvg;
