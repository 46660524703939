import { useState } from 'react';

import { InputAdornment } from '@mui/material';

import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import TextField from 'components/tokens/TextField';

export type SearchFieldProps = {
    value: string;
    onChange?: (text: string) => void;
    buttonWidth?: number;
    expandedWidth?: number;
};

export const SearchField: React.VFC<SearchFieldProps> = ({
    value,
    onChange,
    buttonWidth = 32,
    expandedWidth = 160,
}) => {
    const [searchBoxHover, setSearchBoxHover] = useState(false);
    const [searchBoxFocus, setSearchBoxFocus] = useState(false);
    return (
        <TextField
            icon="Search"
            type="search"
            placeholder="Search..."
            compact
            onChange={(event) => onChange && onChange(event.target.value)}
            value={value}
            sx={{
                marginLeft: 1,
                height: 32,
                '.MuiOutlinedInput-input': { height: 32, boxSizing: 'border-box', marginLeft: '4px' },
                maxWidth: searchBoxHover || searchBoxFocus || value ? expandedWidth : buttonWidth,
                transition: 'max-width 300ms ease',
                '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
                    marginLeft: '-6px',
                    marginRight: value ? '-20px' : 0,
                },
                '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
                    marginRight: 0,
                    opacity: 1,
                },
            }}
            InputProps={{
                endAdornment: value ? (
                    <InputAdornment position="end">
                        <Button
                            size="small"
                            variant="flat"
                            startIcon={<Icon sx={{ height: 20, width: 20 }} type="CloseSmall" />}
                            onClick={() => onChange && onChange('')}
                        />
                    </InputAdornment>
                ) : undefined,
            }}
            onMouseEnter={() => setSearchBoxHover(true)}
            onMouseLeave={() => setSearchBoxHover(false)}
            onFocus={() => setSearchBoxFocus(true)}
            onBlur={() => setSearchBoxFocus(false)}
        />
    );
};

export default SearchField;
