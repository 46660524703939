import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const OffOutlineClose: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22.0001C7.95356 22.0024 4.30449 19.5659 2.75567 15.8276C1.20684 12.0893 2.06354 7.78609 4.926 4.92601C7.4533 2.39871 11.1369 1.41169 14.5893 2.33675C18.0416 3.2618 20.7382 5.9584 21.6633 9.41075C22.5883 12.8631 21.6013 16.5467 19.074 19.074C17.2015 20.955 14.6542 22.0087 12 22.0001ZM4 12.172C4.04732 16.5733 7.64111 20.1096 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.68452 16.444 4.10978 12.0425 4.08601C7.64111 4.06247 4.04732 7.59877 4 12V12.172ZM9.409 16L8 14.59L10.59 12L8 9.41001L9.41 8.00001L12 10.59L14.59 8.00001L16 9.41001L13.41 12L16 14.59L14.591 16L12 13.41L9.41 16H9.409Z" />
    </SvgIcon>
);

export default OffOutlineClose;
