import React from 'react';

import { Box } from '@mui/material';

import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import { getTechnologyIcon } from '../../utils';

export type TechnologyNameCellProps = {
    name: string;
    type?: string;
    logo_url?: string;
};

export const TechnologyNameCell: React.VFC<TechnologyNameCellProps> = ({ name, type, logo_url }) => {
    return (
        <Box sx={{ display: 'flex', columnGap: 0.5, height: 20 }}>
            {logo_url ? (
                <img height={20} width={20} src={logo_url} alt={name + type} style={{ borderRadius: '50%' }} />
            ) : (
                <Icon fontSize="small" type={getTechnologyIcon(type)} color="text.subtle" />
            )}
            <Typography variant="small" weight="semibold" paddingTop={0.25}>
                {name}
            </Typography>
        </Box>
    );
};
