import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { isNil, range } from 'utilities';

import { Operation } from 'api/types/FilterOperators';
import { UsageInfoBanner } from 'components/features/UsageInfo';
import { DraggableListItemType } from 'components/templates/DragAndDrop';
import NestedSelect from 'components/tokens/select-components/NestedSelect';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useFilterCompaniesContext } from 'contexts/FilterCompaniesContext/FilterCompaniesContext';
import { useListsContext } from 'contexts/ListsContext';

import { LABELS_BY_ITEM_ID } from '../constants';
import { fileTypeOptions, MIN_EXTRA_FILE_COUNT_TO_ZIP } from '../ListExportDialog';
import { exportList } from '../useGetListExportQuery';
import { getUniqueArgs, parseCSV } from '../utils';
import JSONPreview from './JSONPreview';
import PreviewTable from './PreviewTable';

export type PreviewStepProps = {
    order: DraggableListItemType[];
    companyIds: string[];
    selectedFileType: (typeof fileTypeOptions)[number];
    selectedFile: number;
    exportLimit: number | undefined;
    onFileTypeChange: (value: (typeof fileTypeOptions)[number]) => void;
    onSelectedFileChange: (value: number) => void;
    alwaysSelectedIds: string[];
    query?: Operation;
    exportQuota?: number;
    exportQuotaUsed?: number;
    totalCount: number;
    usageCount: number;
    loadingUsageCount: boolean;
    scheduledCount?: boolean;
};

export const PreviewStep: React.FC<PreviewStepProps> = ({
    order,
    companyIds,
    selectedFileType,
    selectedFile,
    exportLimit,
    usageCount,
    totalCount,
    onFileTypeChange,
    onSelectedFileChange,
    alwaysSelectedIds,
    query,
    exportQuota = Infinity,
    exportQuotaUsed = 0,
    loadingUsageCount = false,
    scheduledCount = false,
}) => {
    const { tab } = useFilterCompaniesContext();

    const [csvPreviewData, setCsvPreviewData] = useState<string[][]>();
    const [jsonPreviewData, setJsonPreviewData] = useState<Object>();
    const [skeletonLabels, setSkeletonLabels] = useState<string[]>([]);

    const { database } = useListsContext();
    const axios = useAxiosContext();

    const intl = useIntl();

    const isDomainDb = database === 'DOMAIN_DATA_BASIC';
    const isContactExport = tab === 'contacts';

    const skeletonLength = Math.min(15, companyIds.length, exportLimit ?? 15);

    const jsonSkeletonData = useMemo(
        () => ({
            results: range(skeletonLength).map((idx) => ({
                ...(isDomainDb ? { website: companyIds[idx] } : { business_id: companyIds[idx] }),
                ...Object.fromEntries(range(order.length - 1).map((idx) => [`__skeleton-${idx}`, null])),
            })),
        }),
        [isDomainDb, order.length, companyIds, skeletonLength],
    );

    const csvSkeletonData = useMemo(
        () => [
            skeletonLabels || order.map((column) => [column.name]),
            ...range(skeletonLength).map((idx) => [
                companyIds[idx],
                ...new Array((skeletonLabels.length || order.length) - 1).fill(null),
            ]),
        ],
        [order, companyIds, skeletonLabels, skeletonLength],
    );

    const baseFileName = 'base';

    const csvFileChips = useMemo(() => {
        const extraFiles = getUniqueArgs(order);
        if (extraFiles.length < MIN_EXTRA_FILE_COUNT_TO_ZIP) {
            return [baseFileName];
        }
        if (order.length > order.filter((field) => !isNil(field.args)).length + alwaysSelectedIds.length) {
            return [baseFileName, ...extraFiles];
        }
        return extraFiles;
    }, [alwaysSelectedIds.length, order]);

    const getPreviewData = useCallback(
        async (selectedFileType: string) => {
            setCsvPreviewData(undefined);
            setJsonPreviewData(undefined);

            let fields = order;

            if (selectedFileType === 'csv') {
                if (csvFileChips[selectedFile] === baseFileName) {
                    const extraFiles = getUniqueArgs(order);
                    if (extraFiles.length < MIN_EXTRA_FILE_COUNT_TO_ZIP) {
                        fields = order;
                    } else {
                        fields = order.filter((field) => isNil(field.args) || alwaysSelectedIds.includes(field.id));
                    }
                } else {
                    const keyFields = order.filter((field) => alwaysSelectedIds.includes(field.id));
                    const selectedFileName = csvFileChips[selectedFile];
                    const fileFields = order.filter((field) => field.args === selectedFileName);
                    fields = [...keyFields, ...fileFields];
                }
            }

            setSkeletonLabels(
                fields
                    .map(({ id, name }) => LABELS_BY_ITEM_ID[id as keyof typeof LABELS_BY_ITEM_ID] || name)
                    .flatMap((label) => label.split(',')),
            );

            const data = await exportList({
                axios,
                order: fields,
                format: selectedFileType,
                limit: 15,
                isDomainDb,
                encoding: 'utf-8',
                async: false,
                preview: true,
                query,
                database,
                isContactExport,
            });

            if (selectedFileType === 'csv') {
                const rows = parseCSV(data);
                setCsvPreviewData(rows);
            } else {
                setJsonPreviewData(data);
            }
        },
        [alwaysSelectedIds, axios, csvFileChips, database, isContactExport, isDomainDb, order, query, selectedFile],
    );

    useEffect(() => {
        getPreviewData(selectedFileType.value);
    }, [getPreviewData, selectedFileType]);

    const exportCount = Math.min(totalCount, exportLimit ?? Infinity);
    const possiblyLimitedUsageCount = Math.min(usageCount, exportLimit ?? Infinity);
    const isUncertainCount = isContactExport && exportCount < totalCount;
    return (
        <Grid container spacing={4} direction="column" sx={{ overflow: 'hidden' }}>
            <Grid item container spacing={4} sx={{ overflow: 'hidden', flexWrap: 'nowrap', flex: 1 }}>
                <Grid item xs={3}>
                    <Grid container direction="column">
                        <Typography weight="semibold">File type</Typography>

                        <NestedSelect
                            containerSx={{ width: '100%', marginY: 1 }}
                            width="100%"
                            searchable={false}
                            nestedOptions={fileTypeOptions}
                            defaultValueObject={selectedFileType}
                            onChange={onFileTypeChange}
                            openInitialDefaultSelectionOnMenuOpen={false}
                            showSelectedCheckmark
                        />

                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography>
                                <b>
                                    {intl.formatNumber(exportCount, {
                                        notation: 'compact',
                                    })}
                                </b>{' '}
                                {isContactExport ? 'contacts' : 'companies'} &middot;{' '}
                                <b>{csvPreviewData?.[0].length || skeletonLabels.length}</b> datapoints
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={9} sx={{ overflow: 'hidden' }}>
                    {selectedFileType.value === 'csv' ? (
                        <PreviewTable
                            data={csvPreviewData}
                            skeletonData={csvSkeletonData}
                            chips={csvFileChips}
                            selectedChipIndex={selectedFile}
                            onChipChange={onSelectedFileChange}
                        />
                    ) : (
                        <JSONPreview data={jsonPreviewData} skeletonData={jsonSkeletonData} />
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <UsageInfoBanner
                    showCurrentUsage
                    isUncertainCount={isUncertainCount}
                    count={possiblyLimitedUsageCount}
                    loadingCount={loadingUsageCount}
                    scheduledCount={scheduledCount}
                    quotaUsed={exportQuotaUsed}
                    currentQuota={exportQuota}
                    type="csv-export"
                />
            </Grid>
        </Grid>
    );
};
