import { FieldValues, UseFormGetValues } from 'react-hook-form';

import {
    ValueOfSingleFilterOperator,
    ValueOfCombinedExcludeOperators,
    ValueOfCombineFilterOperator,
    ValueOfMatchFilterOperator,
    ValueOfExcludeFilterOperator,
    FilterOperator,
} from 'api/types/FilterOperators';
import { IconType } from 'components/tokens/Icon';
import { SelectOption } from 'components/tokens/select-components/Select';
import { Database } from 'contexts/ListsContext';
import { getObjectValues } from 'utilities/objectUtils';

import { GroupWithPath } from './groups/types';

type UUID = string;
type LOGIC = 'ANY' | 'ALL';

export interface MappedFilter<T = unknown> {
    definition: Filter<T>;
    value: NodeValue;
    id: FilterID;
    path: string;
    groupPath: string;
    valuePath: string;
    uuid: string;
}

export function isMappedFilterLike(value: unknown): value is Pick<MappedFilter, 'value' | 'id'> {
    if (typeof value !== 'object' || value === null) {
        return false;
    }

    const hasValue = 'value' in value;
    const hasId = 'id' in value;
    return hasValue && hasId;
}

export interface MappedGroup {
    groupIds: string[];
    definition: FilterGroup;
    value?: NodeValue;
    index: number;
    path: string;
}

export type NodePath = string;
export interface RawGroup {
    path: NodePath;
    group: GroupIDValue;
    UIorder: number;
    value: NodeValue;
}

export type FieldValue = Record<ValueOfMatchFilterOperator, MatchValue<unknown>>;

export type FilterNodeValue<T = unknown> = {
    [K in ValueOfSingleFilterOperator]?: Partial<T>;
};

export type FilterNodeValueExcluded<T = unknown> = { [K in ValueOfExcludeFilterOperator]: FilterNodeValue<T> };
export type FilterNodeValueExtended<T = unknown> = FilterNodeValueExcluded | FilterNodeValue<T>;

export type GroupNodeValue<T> = { [K in ValueOfCombineFilterOperator]?: Array<FilterNodeValueExtended<T>> };

export type MatchValue<T = unknown> = { [K in GroupIDValue | FilterIDValues]?: GroupNodeValue<T> };
export type MatchValueExcluded<T = unknown> = {
    [K in ValueOfExcludeFilterOperator]: { [FilterOperator.MATCH]: MatchValue<T> };
};

export type CombinedNodeValue<T = unknown> = { [K in ValueOfCombineFilterOperator]?: Array<NodeValue<T>> };
export type MatchNode<T = unknown> = { [FilterOperator.MATCH]: MatchValue<T> };
export type MatchNodeWithExclude<T = unknown> = MatchNode<T> | MatchValueExcluded<T>;

export type NodeValue<T = unknown> = MatchNodeWithExclude | FilterNodeValueExtended<T> | CombinedNodeValue<T>;

export const isMatchNode = (value: MatchNodeWithExclude): value is MatchNode => {
    return FilterOperator.MATCH in value;
};

export const isMatchNodeWithExclude = (value: MatchNodeWithExclude): value is MatchValueExcluded => {
    return FilterOperator.NOT in value;
};

export const isGroupIDValue = (value: unknown): value is GroupIDValue => {
    return GROUP_IDS.includes(value as GroupIDValue);
};

type CustomPartial<T, K extends keyof T> = {
    [Key in keyof T as Key extends K ? never : Key]: T[Key];
} & {
    [Key in K]?: T[Key];
};

export interface GroupOption {
    label: string;
    value: string;
    databases?: Database[];
    options?: GroupOption[];
    permission?: string;
    description?: React.ReactNode;
}

export type GroupOptionRaw = CustomPartial<GroupOption, 'label'>;

export type GetFiltersFnResult = GroupWithPath | undefined;
export type GetFiltersFn = (id: string, getValues: UseFormGetValues<FieldValues>) => GetFiltersFnResult;

export interface FilterGroup {
    group: GroupValues;
    label: string;
    icon?: IconType;
    options?: GroupOption[];
    filterUUIDs: UUID[];
    logic?: Record<UUID, LOGIC>; // placeholder - logic filters
    UIorder?: number;
    match?: boolean;
    render: (props: FilterGroupProps) => JSX.Element;
    permissions?: string[];
    databases?: string[];
    createGroup?: (value: unknown) => NodeValue;
    getFilters?: GetFiltersFn;
    groupValues?: GroupValues[];
    createNewGroup?: (value: NodeValue) => NodeValue;
}

export interface FilterGroups {
    [id: UUID]: FilterGroup;
}

/** Can be typed properly once the values are known */
interface FilterStateValue<T> {
    operator: OperatorValue;
    value: T;
}

export type FilterState<T> = {
    [P in keyof T]: FilterStateValue<T[P]>;
};

export interface Filter<T> {
    group: GroupValues;
    id: FilterID;
    label: string;
    fields: Array<keyof T>;
    valueToStateCalledFields?: string[];
    outsideGroup?: boolean; // for elementmatch groups allow adding particular filter outside group
    subGroup?: string;
    selectableOnce?: boolean;
    render: <Props extends FilterProps<T> = FilterProps<T>>(props: Props) => JSX.Element;
    valueToText: (node: NodeValue, options?: SelectOption[]) => string;
    config: FilterConfig<T>;
    permissions?: string[];
    databases?: string[];
    createFilter?: () => NodeValue;
    getOperator?: (filterValue: NodeValue) => OperatorValue;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFilter = any;

export interface Filters {
    [id: UUID]: Filter<AnyFilter>;
}

export enum GroupValues {
    location_visiting_address = 'prospect_addresses',
    vainu_custom_industry = 'vainu_custom_industry',
    industry = 'industry',
    organization_size_indicators = 'organization_size_indicators',
    web_profile = 'web_profile',
    upload_list = 'upload_list', // upload csv functionality
    target_group = 'target_group', // include exclude list
    basic = 'basic',
    group_structure = 'group_structure',
    social_media = 'social_media',
    board_of_directors = 'board_of_directors',
    crm = 'crm',
    financial = 'financial',
    contacts = 'contacts',
}

export const GROUP_IDS = getObjectValues<GroupIDValue>(GroupValues);

export type GroupIDValue = `${GroupValues}`;

export enum SubGroupValues {
    technology = 'technology',
}

export type SubGroupIDValue = `${SubGroupValues}`;

export enum FilterID {
    country = 'visiting_address_country',
    staff_number_web = 'staff_number_web',
    vainu_custom_industry = 'vainu_custom_industry',
    location_type = 'location_type',
    prospect_address_count = 'prospect_address_count',
    country_count = 'facts_numeric#business_unit_countries_count',
    visiting_city = 'visiting_city',
    address = 'visiting_address_s',
    postal = 'visiting_postal',
    country_area = 'visiting_region',
    municipality = 'visiting_municipality',
    coordinates = 'visiting_coordinates',
    technology_analytics = 'analytics_urls#urls_web_tags',
    technology_crm = 'crm_urls#urls_web_tags',
    technology_marketing = 'marketing_automation_urls#urls_web_tags',
    technology_all = 'urls_web_tags',
    technology_customer_service = 'customer_service_urls#urls_web_tags',
    technology_cms = 'cms_urls#urls_web_tags',
    technology_social_media = 'social_media_urls#urls_web_tags',
    technology_e_commerce = 'e_commerce_urls#urls_web_tags',
    technology_server = 'server_urls#urls_web_tags',
    technology_web_tech = 'web_tech_urls#urls_web_tags',
    technology_email_server = 'email_server_urls#urls_web_tags',
    technology_domain_server = 'domain_server_urls#urls_web_tags',
    technology_ats = 'ats_urls#urls_web_tags',
    technology_payment = 'payment_urls#urls_web_tags',
    technology_reservation = 'reservation_urls#urls_web_tags',
    technology_advertising = 'advertising_urls#urls_web_tags',
    website_traffic_ranking = 'website_traffic_data#ranking',
    website_language = 'language_urls#urls_web_tags',
    urls_keywords = 'urls_keywords',
    website_url = 'domain',
    description_keywords = 'company_keywords',
    digitalization_index = 'digitality',
    target_group = 'target_group#_id',
    company_name = 'company_name_l',
    legal_entity = 'legal_entity',
    employer_registration_date = 'basic_data_plus#employer_registration_date',
    foundation = 'founded',
    industry_official = 'industry_codes_local',
    company_registration_date = 'registration_date',
    group_company_count = 'concern#subsidiaries',
    group_country_count = 'concern#concern_country_count',
    group_countries = 'concern#countries',
    group_is_subsidiary = 'concern#parent_rel',
    social_media_channels = 'social_media_urls#urls_web_tags',
    social_media_index = 'sociality',
    twitter_followers = 'facts_numeric#twitter_followers_count',
    board_member_count = 'facts_numeric#board_member_count',
    board_unique_surname_count = 'facts_numeric#board_surname_count',
    prospect_export_crm_system = 'prospectexport#target',
    prospect_export_crm_owner_email = 'prospectexport#owner_email',
    prospect_export_crm_won_deal_count = 'prospectexport#won_deals_count',
    prospect_export_crm_lost_deal_count = 'prospectexport#lost_deals_count',
    prospect_export_crm_open_deal_count = 'prospectexport#open_deals_count',
    staff_count_official = 'staff_number_c',
    change_in_revenue = 'facts_numeric#development_of_turnover',
    revenue = 'turn_over_c',
    revenue_local = 'turn_over_local_c',
    gross_profit = 'facts_numeric#gross_profit_local',
    net_income = 'facts_numeric#net_income_local',
    salary_costs = 'facts_numeric#employee_salary_local',
    auditor = 'facts_numeric#accounting_firm',
    accounting_period_end = 'financial_statements#account_period_end',
    ebit_margin = 'facts_numeric#profit',
    equity_ratio = 'facts_numeric#equity_to_assets_ratio',
    quick_ratio = 'facts_numeric#kr_quick_ratio_percent',
    inventory_local = 'facts_numeric#bs_inventory_local',
    accounts_receivable_local = 'facts_numeric#bs_accounts_receivable_current_local',
    cash_and_cash_equivalents_local = 'facts_numeric#bs_cash_and_cash_equivalents_local',
    financial_statement_keywords = 'financial_statement_keywords',
    total_equity_local = 'facts_numeric#bs_total_equity_local',
    bookkeeper = 'facts_numeric#bookkeeping_firm',
    long_term_liabilities_local = 'facts_numeric#bs_total_long_term_debts_local',
    recruitment_keywords = 'recruitment_keywords#keyword',
    contact_function = 'titles#classifications#functional_area',
    contact_is_decision_maker = 'titles#classifications#is_decision_maker',
    contact_info = 'contact_info',
    contact_title_keywords = 'title_keywords',
}

export const FILTERS_IDS = getObjectValues<FilterIDValues>(FilterID);

export type FilterIDValues = `${FilterID}`;

export function isFilterId(value: string): value is FilterIDValues {
    return FILTERS_IDS.includes(value as FilterIDValues);
}
export type FilterPropsDeprecated<T> = {
    filter: Filter<T>;
    uuid: string;
    groupUUID: string;
    sameFiltersState?: FilterState<T>[];
    clearFilter?: () => void;
    removeFilter: (uuid: string, groupUUID: string) => void;
    editFilter: (uuid: string, filter: Filter<T>) => void;
    saveFilterStateBeforeChanges: (uuid: string, filter: Filter<T>) => void;
};

export type FilterProps<T> = {
    key?: string;
    filter: MappedFilter<T>;
    uuid: string;
    groupUUID: string;
    sameFiltersState?: NodeValue<T>[];
    clearFilter?: () => void;
    removeFilter: (uuid: string, groupUUID: string) => void;
};

export interface ContactFilterProps<T> extends FilterProps<T> {
    activeFilter?: FilterID;
}

export interface LocationFilterProps<T = unknown> extends FilterProps<T> {
    warningLevel?: 'warn' | 'err';
    apply: (data: Pick<MappedFilter, 'value' | 'valuePath'> & { key?: string }) => void;
    filterOption: (option: unknown) => boolean;
    forceOpen?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
}

export interface FilterConfigBase<T> {
    fields: Array<keyof T>;
    id: FilterID;
    label: string;
}
export interface FilterConfigInitValues<T> {
    defaultOperator: OperatorValue;
    initValue: NodeValue<T>;
}
export type FilterConfig<T> = FilterConfigBase<T> & FilterConfigInitValues<T>;

export type FilterDefinition<T> = Partial<Record<FilterID, Filter<T>>>;

export function getFilterDefinition<T>(id: FilterID, value: Omit<Filter<T>, 'id'>) {
    return {
        [id]: {
            id: id,
            ...value,
        },
    };
}
export interface FilterGroupCallbacks {}

export interface FilterGroupCallbacks {
    applyChanges: () => void;
}

export type UpdateGroupObject = Record<string, NodeValue | undefined> | NodeValue[];
export type UpdateGroupsFn = (value: UpdateGroupObject) => void;
export interface FilterGroupProps {
    filters: React.ReactNode[];
    undoChanges: () => void;
    applyChanges: () => void;
    clearFilters: () => void;
    removeGroup: (groupId: string) => void;
    ids: string[];
    addNewGroup: (value: NodeValue) => void;
    updateGroup: (id: string, value: NodeValue) => void;
    updateGroups: UpdateGroupsFn;
    disabled?: boolean;
}

export type OperatorValue = ValueOfCombinedExcludeOperators | ValueOfSingleFilterOperator;

export type Operator = { value: OperatorValue; label: string };
export type Field<T extends string> = `${T}`;
