import React, { useCallback } from 'react';

import { Grid, Button, Tooltip, styled } from '@mui/material';

import ContactSupportDialog from '../common/ContactSupportDialog';
import TooltipIcon from '../common/TooltipIcon';
import MatchedSuccessIcon from '../svg/MatchedSuccessIcon';
import { CrmName, ProspectCountByCountryProps, CrmCompanyObject } from '../types/Crm';
import { getCRMName } from '../utils';
import { getCompanyLikeObjectForCrm, getObjectPluralForm } from '../utils/CRMTargetText';
import { MatchingCard } from './MatchingCard';

interface Props {
    isAccountAdmin: boolean;
    isDomainConnector: boolean;
    crm: CrmName;
    matchedCompanies: { count: number; country_counts?: ProspectCountByCountryProps[] };
    needUpgradeCompanies: { count: number; country_counts: ProspectCountByCountryProps[] };
    unsupportedCompanies: { count: number; country_counts: ProspectCountByCountryProps[] };
    addedAndMatchedCount: number;
    nonMatchedCount: number;
    missingFromDBCount: number;
    focusedComponent: string;
    navigateToManualMatch: () => void;
    setFocusedComponent: (component: string) => void;
    handleOpenNoDomainCompaniesDialog: () => void;
}

const MatchingSummary: React.FC<Props> = ({
    isDomainConnector,
    isAccountAdmin,
    crm,
    focusedComponent,
    matchedCompanies,
    needUpgradeCompanies,
    unsupportedCompanies,
    addedAndMatchedCount,
    nonMatchedCount,
    missingFromDBCount,
    setFocusedComponent,
    navigateToManualMatch,
    handleOpenNoDomainCompaniesDialog,
}) => {
    // TODO Connector
    const intl = { formatMessage: ({ defaultMessage }: { defaultMessage: string }) => defaultMessage };
    const crmAccountObject = getCompanyLikeObjectForCrm(crm);

    const handleGoToManualMatching = useCallback(() => {
        setFocusedComponent('');
        navigateToManualMatch();
    }, [setFocusedComponent, navigateToManualMatch]);

    return (
        <Grid container spacing={2} alignItems="stretch">
            <MatchingCard
                component="Matched with Vainu"
                titleNumber={matchedCompanies.count}
                focusedComponent={focusedComponent}
                numberByCountry={
                    isDomainConnector ? [] : (matchedCompanies.country_counts as ProspectCountByCountryProps[])
                }
                description={
                    isDomainConnector && crmAccountObject
                        ? `You can update these ${getObjectPluralForm(
                              crm,
                              crmAccountObject,
                          )} with Vainu data and keep selected data fields updated automatically.`
                        : 'These companies are matched with our database. Now your whole team can benefit from Vainu data.'
                }
                footerComponent={<StyledMatchedSuccessIcon />}
                setFocusedComponent={setFocusedComponent}
            />
            {isDomainConnector && addedAndMatchedCount > 0 && (
                <MatchingCard
                    component="Added and matched"
                    titleNumber={addedAndMatchedCount}
                    focusedComponent={focusedComponent}
                    numberByCountry={
                        isDomainConnector ? [] : (matchedCompanies.country_counts as ProspectCountByCountryProps[])
                    }
                    description={`Vainu didn't have these ${getObjectPluralForm(
                        crm,
                        crmAccountObject as CrmCompanyObject,
                    )}
                    but we added them to our database and matched them automatically for you.`}
                    setFocusedComponent={setFocusedComponent}
                />
            )}
            {!isDomainConnector && needUpgradeCompanies.count > 0 && (
                <MatchingCard
                    component="Need an upgrade"
                    titleNumber={needUpgradeCompanies.count}
                    focusedComponent={focusedComponent}
                    numberByCountry={isDomainConnector ? [] : needUpgradeCompanies.country_counts}
                    description="Gain access to companies we found from other countries, by upgrading your Vainu license."
                    footerComponent={
                        <ContactSupportDialog
                            header="Reach out to us"
                            subHeader="If you’re interested in upgrading your account with additional country data, we’d be happy to help make that happen."
                            callToAction={
                                <StyledButton fullWidth disabled={!isAccountAdmin}>
                                    Tell me how
                                </StyledButton>
                            }
                        />
                    }
                    setFocusedComponent={setFocusedComponent}
                />
            )}
            {nonMatchedCount > 0 && (
                <MatchingCard
                    component="Need help"
                    titleNumber={nonMatchedCount}
                    focusedComponent={focusedComponent}
                    numberByCountry={[]}
                    description={<NeedHelpCardDescription isDomainConnector={isDomainConnector} crm={crm} />}
                    footerComponent={
                        <StyledButton
                            fullWidth
                            disabled={!isDomainConnector ? !isAccountAdmin : false}
                            onClick={isDomainConnector ? handleOpenNoDomainCompaniesDialog : handleGoToManualMatching}
                        >
                            {isDomainConnector ? 'View companies' : 'Help match companies'}
                        </StyledButton>
                    }
                    titleIcon={
                        !isDomainConnector && (
                            <TooltipIcon
                                text={<>Learn more about our company matching process from our {helpCenterLink}.</>}
                            />
                        )
                    }
                    setFocusedComponent={setFocusedComponent}
                />
            )}
            {isDomainConnector && missingFromDBCount > 0 && (
                <MatchingCard
                    component="Missing from database"
                    titleNumber={missingFromDBCount}
                    numberByCountry={isDomainConnector ? [] : unsupportedCompanies.country_counts}
                    focusedComponent={focusedComponent}
                    description={
                        crmAccountObject &&
                        `We couldn't recognise these ${getObjectPluralForm(crm, crmAccountObject)} for some reason.`
                    }
                    setFocusedComponent={setFocusedComponent}
                />
            )}
            {!isDomainConnector && unsupportedCompanies.count > 0 && (
                <MatchingCard
                    component="Not supported"
                    titleNumber={unsupportedCompanies.count}
                    focusedComponent={focusedComponent}
                    numberByCountry={unsupportedCompanies.country_counts}
                    description={intl.formatMessage({
                        defaultMessage: 'Vainu does not support these countries at the moment.',
                    })}
                    setFocusedComponent={setFocusedComponent}
                />
            )}
        </Grid>
    );
};

type NeedHelpCardDescriptionProps = {
    isDomainConnector: boolean;
    crm: CrmName;
};

const NeedHelpCardDescription: React.FC<NeedHelpCardDescriptionProps> = ({ isDomainConnector, crm }) => {
    const crmAccountObject = getCompanyLikeObjectForCrm(crm);

    return isDomainConnector && crmAccountObject ? (
        <>
            Make sure your {getObjectPluralForm(crm, crmAccountObject)} have
            <Tooltip
                placement="top"
                title={
                    <TooltipText>
                        We use <b>company domain (website)</b> as a unique identifier to match your companies with our
                        database. Learn more from our {helpCenterLink}.
                    </TooltipText>
                }
            >
                <b style={{ color: '#0A0A0A' }}> domain information </b>
            </Tooltip>
            in {getCRMName(crm)} so that we can match them automatically for you.
        </>
    ) : (
        <>To use companies we couldn’t confidently match, help find their appropriate matches.</>
    );
};

const StyledButton = styled(Button)(({ theme: { palette, shape } }) => ({
    background: '#F9F9F9',
    border: `1px solid ${palette.border}`,
    borderRadius: shape.borderRadius,
}));

const TooltipText = styled('span')(({ theme: { typography } }) => ({
    fontWeight: typography.fontWeightRegular,
}));

const StyledMatchedSuccessIcon = styled(MatchedSuccessIcon)({
    display: 'block',
    marginLeft: 'auto',
});

const helpCenterLink = (
    <a
        style={{ color: 'white' }}
        target="_blank"
        rel="noreferrer"
        href="https://help.vainu.app/en/articles/120176-connectors-vainu-integration-overview"
    >
        help center
    </a>
);

export { MatchingSummary };
