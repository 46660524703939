import { useInfiniteQuery } from '@tanstack/react-query';

import { filterCompanies } from 'api/filterCompanies';
import { NewVehicle } from 'api/types/company';
import { Operation } from 'api/types/FilterOperators';
import { OrganizationAPIResponse } from 'api/types/Organization';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Dialog from 'components/tokens/Dialog';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useListsContext } from 'contexts/ListsContext';

type Props = {
    businessId: string;
    open: boolean;
    onClose: () => void;
    data: { title: string; query: Operation } | null;
};

const HEADER_HEIGHT = 41;
const ROW_HEIGHT = 49;

const ROWS_PER_PAGE = 20;

interface VehicleWithId extends NewVehicle {
    id: number;
    [key: string]: unknown;
}

const VehiclesDialog: React.FC<Props> = ({ businessId, open, onClose, data }) => {
    const axios = useAxiosContext();
    const { database } = useListsContext();

    const infiniteQuery = useInfiniteQuery({
        queryKey: ['vehicles', businessId, data?.query],
        queryFn: ({ pageParam = 0 }) =>
            filterCompanies(axios, {
                database: database || 'SE',
                use_filters_v2: 'true',
                query: {
                    '?ALL': [
                        {
                            '?EQ': {
                                business_id: businessId,
                            },
                        },
                    ],
                },
                fields: ['vehicles'],
                offset: pageParam,
                limit: ROWS_PER_PAGE,
                order: 'none',
                unwind_subdocument: 'vehicles',
                unwind_subdocument_query: data?.query
                    ? ({
                          '?MATCH': {
                              vehicles: data.query,
                          },
                      } as Operation)
                    : undefined,
            }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage?.length < ROWS_PER_PAGE) {
                return undefined;
            }
            return allPages.reduce((acc, cur) => acc + cur.length, 0);
        },
        select: ({ pages, pageParams }) => ({
            pages: pages.flat().map((row, idx) => ({ ...(row as OrganizationAPIResponse).vehicles?.[0], id: idx })),
            pageParams,
        }),
        enabled: !!data && !!database && open,
    });

    const { data: vehicleData, fetchNextPage, hasNextPage, isFetching, isLoading } = infiniteQuery;

    const rows = vehicleData?.pages || new Array(5).fill({});

    return (
        <Dialog onClose={onClose} open={open} title={data?.title} maxWidth="xl">
            <SortableTable<VehicleWithId>
                data={rows}
                rowKeyField="id"
                tableHeight={Math.min(HEADER_HEIGHT + ROW_HEIGHT * rows.length, 480)}
                sx={{ marginTop: 1 }}
                // hack for the scrollbar overlapping data issue on firefox
                headerCellSx={{
                    '&:last-child': {
                        paddingRight: 3,
                    },
                }}
                cellSx={{
                    '&:last-child': {
                        paddingRight: 3,
                    },
                }}
                onScroll={(el) => {
                    if (isFetching || !hasNextPage) {
                        return;
                    }
                    const scrollBottom = el.scrollHeight - el.scrollTop - el.clientHeight;

                    if (scrollBottom < el.clientHeight) {
                        fetchNextPage();
                    }
                }}
            >
                <SortableTableColumn<VehicleWithId> label="Make" field="make" loading={isLoading} skeletonWidth={60}>
                    {(row) => <b>{row.make}</b>}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId> label="Model" field="model" loading={isLoading} skeletonWidth={60}>
                    {(row) => <b>{row.model}</b>}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Insurance company"
                    field="insurer_name"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.insurer_name}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Insurance start date"
                    field="insurance_start_date"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.insurance_start_date}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Power source"
                    field="power_source"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.power_source}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Possession type"
                    field="possession_type"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.possession_type}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Ownership type"
                    field="ownership_type"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.ownership_type}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Owning company"
                    field="owner_business_id"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.owner_business_id}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="First registration date"
                    field="first_registered"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.first_registered}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Use start date"
                    field="use_start_date"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.use_start_date}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Gearing"
                    field="gear_type"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.gear_type}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Tire count"
                    field="tire_count"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => row.tire_count}
                </SortableTableColumn>

                <SortableTableColumn<VehicleWithId>
                    label="Measures (L, H, W)"
                    field="length"
                    loading={isLoading}
                    skeletonWidth={60}
                >
                    {(row) => (
                        <span>
                            {row.length}, {row.height}, {row.width}
                        </span>
                    )}
                </SortableTableColumn>
            </SortableTable>
        </Dialog>
    );
};

export default VehiclesDialog;
