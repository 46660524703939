import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Award = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2051 9.90776C19.8459 10.5868 19.9651 11.4209 20.4998 11.9699C20.8808 12.3608 21.0601 12.9091 20.9821 13.452C20.9041 13.995 20.5801 14.4698 20.1054 14.7375C19.4387 15.1118 19.0914 15.8794 19.2467 16.6318C19.3577 17.1687 19.2137 17.7267 18.8574 18.1411C18.5012 18.5563 17.974 18.7793 17.431 18.7446C16.6698 18.6962 15.9664 19.1529 15.6941 19.8705C15.4992 20.3825 15.0784 20.7742 14.5557 20.9284C14.0345 21.0834 13.4705 20.9836 13.0326 20.6585C12.4191 20.2025 11.5814 20.2025 10.9672 20.6585C10.5292 20.9829 9.96525 21.0827 9.44403 20.9284C8.92132 20.7742 8.50059 20.3832 8.30635 19.8705C8.03337 19.1529 7.33067 18.6969 6.56871 18.7446C6.02725 18.7793 5.49928 18.5563 5.1423 18.1411C4.78607 17.7267 4.64283 17.1687 4.75308 16.6318C4.90907 15.8794 4.56109 15.1118 3.89513 14.7375C3.41966 14.4706 3.09493 13.995 3.01768 13.452C2.94044 12.9091 3.11893 12.3608 3.50065 11.9699C4.03462 11.4209 4.15386 10.5861 3.79464 9.90776C3.53815 9.42456 3.52015 8.84835 3.74589 8.34926C3.97162 7.85018 4.4156 7.4857 4.94656 7.36471C5.68977 7.19532 6.23723 6.55786 6.29873 5.79108C6.34223 5.24436 6.63696 4.74906 7.09443 4.45263C7.5519 4.15621 8.12037 4.09193 8.63183 4.27947C9.34879 4.54186 10.1512 4.30367 10.611 3.69191C10.9409 3.25635 11.4539 3 11.9976 3C12.5421 3 13.0551 3.25635 13.385 3.69191C13.847 4.30291 14.6495 4.54111 15.3664 4.27871C15.8786 4.09042 16.4471 4.1547 16.9038 4.45188C17.362 4.7483 17.656 5.24361 17.7003 5.79033C17.761 6.5571 18.3092 7.19381 19.0524 7.36395C19.5834 7.48494 20.0274 7.84942 20.2531 8.34851C20.4796 8.84835 20.4616 9.4238 20.2051 9.90776ZM13.5006 10.1868L12 7.09079L10.4995 10.1868L7.09095 10.6577L9.57168 13.0418L8.96582 16.4287L12 14.8058L15.0339 16.4287L14.4284 13.0418L16.9091 10.6577L13.5006 10.1868Z"
        />
    </SvgIcon>
);

export default Award;
