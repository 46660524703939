import React from 'react';

import ExpandableRow from './ExpandableRow';

type IndustriesProps = {
    country?: string;
    industry_codes_all_values: string[];
};

const Industries: React.VFC<IndustriesProps> = ({ industry_codes_all_values }) => {
    return (
        <ExpandableRow
            rows={industry_codes_all_values.map((code, index) => (
                <div key={index}>{code}</div>
            ))}
        />
    );
};

export default Industries;
