import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { BoundsRect, Point } from '../types';
import useAnimation, { AnimationOptions, AnimationStateCallback } from '../utils/animation';
import { useClusterContext } from './ClusterContext';
import { useTransformContext } from './TransformContext';

export type ZoomAndCenterContextValue = {
    /** The zoom level towards which the animation is going. */
    zoom: number;
    /** The center towards which the animation is going. */
    center: Point;
    /** The current zoom level used to render the graph. */
    currZoom: number;
    /** The current center point used to render the graph. */
    currCenter: Point;
    /** Indicates if a drag is going on, e.g., to change the cursor. */
    dragActive: boolean;
};

export type ZoomAndCenterControlContextValue = {
    /** Pan the view to the given center point and change the zoon. Defaults to animating the change.  */
    zoomTo: (zoom: number, center: Point, animate?: boolean, options?: AnimationOptions) => void;
    /** Zoom in to the next zoom level. */
    zoomIn: (clientX: number, clientY: number) => void;
    /** Zoom out to the next zoom level. */
    zoomOut: (clientX: number, clientY: number) => void;
    /** Zoom in or out so that the given rectangle fits the svg view box. */
    zoomToBounds: (bounds: BoundsRect, animate?: boolean, options?: AnimationOptions) => void;
    /** Call when user starts to hold down the drag button. Should only be used by the PandAndZoom component. */
    startDrag: () => void;
    /** Call when user releases the drag button. Should only be used by the PandAndZoom component. */
    endDrag: () => void;
    /** An event handler to set the current mouse coordinates. Should only be used by the PandAndZoom component. */
    onDrag: (event: React.MouseEvent<HTMLElement>) => void;
    /** Use to change zoom by the mouse wheel. */
    wheelZoom: (deltaY: number, clientX: number, clientY: number) => void;
};

const ZoomAndCenterContext = createContext({} as ZoomAndCenterContextValue);
const ZoomAndCenterControlContext = createContext({} as ZoomAndCenterControlContextValue);

export type ZoomAndCenterProviderProps = {
    children: React.ReactNode;
};

const minZoom = 1;
const maxZoom = 64;
const zoomLevels = [1, 2, 4, 8, 16, 32, 64];
const reverseZoomLevels = [...zoomLevels].reverse();
const defaultZoom = 1;

// Firefox has a rounding bug in the svg element bounding boxes, which makes the hover events imprecise and
// incorrectly located. This is corrected by scaling both the view box and all the cluster geometry
// with a factor of 100.
export const renderMultiplier = 100;
const defaultViewBoxWidth = 40 * renderMultiplier;
const defaultViewBoxHeight = 40 * renderMultiplier;

export type ViewBox = {
    left: number;
    top: number;
    width: number;
    height: number;
};
export const getViewBox = (svg: SVGSVGElement | null | undefined): ViewBox => {
    if (!svg) {
        return {
            left: -defaultViewBoxWidth / 2,
            top: -defaultViewBoxHeight / 2,
            width: defaultViewBoxWidth,
            height: defaultViewBoxHeight,
        };
    }

    const { clientWidth, clientHeight } = svg;
    const aspectRatio = clientWidth / clientHeight;

    const height = defaultViewBoxHeight;
    const width = height * aspectRatio;
    const left = -width / 2;
    const top = -height / 2;

    return { left, top, width, height };
};

// A factor with which to zoom out from the exact bounds zoom. 0.8 = 20% padding. 1 = no padding.
const boundsZoomPadding = 0.8;

const getBoundsZoom = (bounds: BoundsRect, viewBox: ViewBox) => {
    const center = { x: (bounds.left + bounds.right) / 2, y: (bounds.top + bounds.bottom) / 2 };
    const zoomX = (viewBox.width / (bounds.right - bounds.left)) * defaultZoom;
    const zoomY = (viewBox.height / (bounds.bottom - bounds.top)) * defaultZoom;
    const zoom = Math.min(zoomX, zoomY) * boundsZoomPadding;

    return { center, zoom };
};

export const ZoomAndCenterProvider: React.FC<ZoomAndCenterProviderProps> = ({ children }) => {
    const { getSVGCoordinates, svgRef } = useTransformContext();

    const [dragActive, setDragActive] = useState(false);

    const { bounds } = useClusterContext();

    // These ones are the state the animation is going towards.
    const [targetZoom, setTargetZoom] = useState(1);
    const [targetCenter, setTargetCenter] = useState<Point>({ x: 0, y: 0 });
    // These are the currently rendered map state.
    const [currZoom, setCurrZoom] = useState(1);
    const [currCenter, setCurrCenter] = useState<Point>({ x: 0, y: 0 });
    const animationStarted = useRef(false);
    const animationOptions = useRef<AnimationOptions>();

    // Zooms the view to the graphs bounds, when the bounds rect changes. This should
    // only happen once after the clustering data is initially received & transformed.
    useEffect(() => {
        const { center: initialCenter, zoom: initialZoom } = bounds
            ? getBoundsZoom(bounds, getViewBox(svgRef.current))
            : { center: { x: 0, y: 0 }, zoom: defaultZoom };

        setTargetCenter(initialCenter);
        setCurrCenter(initialCenter);
        setTargetZoom(initialZoom);
        setCurrZoom(initialZoom);
    }, [bounds, svgRef]);

    const animationStateCallback: AnimationStateCallback = useCallback((zoom, center) => {
        if (zoom !== undefined) {
            setCurrZoom(zoom);
        }
        if (center !== undefined) {
            setCurrCenter(center);
        }
    }, []);

    const doAnimation = useAnimation({ animationStateCallback });

    useEffect(() => {
        if (
            (targetZoom !== currZoom || currCenter.x !== targetCenter.x || currCenter.y !== targetCenter.y) &&
            !animationStarted.current
        ) {
            animationStarted.current = true;
            doAnimation(
                { x: currCenter.x, y: currCenter.y },
                currZoom,
                targetCenter,
                targetZoom,
                animationOptions.current,
            );
        }
    }, [targetZoom, targetCenter, currZoom, currCenter, doAnimation]);

    const zoomTo = useCallback(
        (zoom: number, { x, y }: Point, animate = true, options: AnimationOptions = {}) => {
            if (!bounds) {
                throw new Error('Cannot zoom. Bounds are undefined.');
            }
            const newZoom = Math.min(maxZoom, Math.max(minZoom, zoom));
            const newX = Math.min(Math.max(x, bounds.left), bounds.right);
            const newY = Math.min(Math.max(y, bounds.top), bounds.bottom);

            if (animate) {
                animationStarted.current = false;
                animationOptions.current = options;
                setTargetZoom(newZoom);
                setTargetCenter({ x: newX, y: newY });
            } else {
                setTargetZoom(newZoom);
                setTargetCenter({ x: newX, y: newY });
                setCurrZoom(newZoom);
                setCurrCenter({ x: newX, y: newY });
            }
        },
        [bounds],
    );

    const zoomOnPoint = useCallback(
        (clientX: number, clientY: number, zoomIn: boolean, animate = true, options?: AnimationOptions) => {
            const { x, y } = getSVGCoordinates(clientX, clientY);
            const newZoom = zoomIn
                ? zoomLevels.find((zoomLevel) => zoomLevel > targetZoom) ?? maxZoom
                : reverseZoomLevels.find((zoomLevel) => zoomLevel < targetZoom) ?? minZoom;

            if (newZoom === targetZoom) return;

            // Move the center towards the point in the relation of the zoom change
            const zoomRatio = 1 - targetZoom / newZoom;
            const newCenter = {
                x: targetCenter.x + (x - targetCenter.x) * zoomRatio,
                y: targetCenter.y + (y - targetCenter.y) * zoomRatio,
            };

            zoomTo(newZoom, newCenter, animate, options);
        },
        [targetCenter, targetZoom, zoomTo, getSVGCoordinates],
    );

    const zoomIn = useCallback(
        (clientX: number, clientY: number) => zoomOnPoint(clientX, clientY, true, true, { duration: 200 }),
        [zoomOnPoint],
    );
    const zoomOut = useCallback(
        (clientX: number, clientY: number) => zoomOnPoint(clientX, clientY, false, true, { duration: 200 }),
        [zoomOnPoint],
    );

    const zoomToBounds = useCallback(
        (bounds: BoundsRect, animate = false, options: AnimationOptions = {}) => {
            const { center, zoom } = getBoundsZoom(bounds, getViewBox(svgRef.current));
            zoomTo(zoom, center, animate, options);
        },
        [zoomTo, svgRef],
    );

    const startDrag = useCallback(() => {
        setDragActive(true);
    }, []);

    const endDrag = useCallback(() => {
        setDragActive(false);
    }, []);

    const dragMove = useCallback(
        (movementX: number, movementY: number) => {
            if (dragActive) {
                // When movement is converted to svg coordinates, the coordinates are in relation to the
                // svg container top-left corner and not the origin. Svg origin offset is readded
                // to calculate the actual movement in svg coordinates.
                const movementOffset = getSVGCoordinates(movementX, movementY);
                const originOffset = getSVGCoordinates(0, 0);
                const newCenter: Point = {
                    x: targetCenter.x - movementOffset.x + originOffset.x,
                    y: targetCenter.y - movementOffset.y + originOffset.y,
                };
                zoomTo(targetZoom, newCenter, false);
            }
        },
        [dragActive, targetCenter, targetZoom, zoomTo, getSVGCoordinates],
    );

    const wheelZoom = useCallback(
        (deltaY: number, clientX: number, clientY: number) => {
            const zoomFactor = 1.003;
            const newZoom = targetZoom * Math.pow(zoomFactor, -deltaY);
            if (newZoom < maxZoom && newZoom > minZoom) {
                const zoomRatio = 1 - targetZoom / newZoom;
                const currentPoint = getSVGCoordinates(clientX, clientY);
                const newX = targetCenter.x + (currentPoint.x - targetCenter.x) * zoomRatio;
                const newY = targetCenter.y + (currentPoint.y - targetCenter.y) * zoomRatio;
                zoomTo(newZoom, { x: newX, y: newY }, true, { animationType: 'linear', duration: 300 });
            }
        },
        [targetCenter, targetZoom, zoomTo, getSVGCoordinates],
    );

    const onDrag = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            dragMove(event.movementX, event.movementY);
        },
        [dragMove],
    );

    const value: ZoomAndCenterContextValue = {
        zoom: targetZoom,
        center: targetCenter,
        currZoom,
        currCenter,
        dragActive,
    };

    const controller: ZoomAndCenterControlContextValue = useMemo(
        () => ({
            zoomTo,
            zoomIn,
            zoomOut,
            zoomToBounds,
            startDrag,
            endDrag,
            wheelZoom,
            onDrag,
        }),
        [zoomTo, zoomIn, zoomOut, zoomToBounds, startDrag, endDrag, wheelZoom, onDrag],
    );

    return (
        <ZoomAndCenterContext.Provider value={value}>
            <ZoomAndCenterControlContext.Provider value={controller}>{children}</ZoomAndCenterControlContext.Provider>
        </ZoomAndCenterContext.Provider>
    );
};

export const useZoomAndCenterContext = () => useContext(ZoomAndCenterContext);
export const useZoomAndCenterControlContext = () => useContext(ZoomAndCenterControlContext);
