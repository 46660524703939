import { styled } from '@mui/material/styles';

import Button from 'components/tokens/Button';

export const CategoryWrapper = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    height: 60,
    width: '100%',
    background: palette.background.paper,
}));

export const Category = styled(Button)<{ active?: string }>(({ theme: { palette }, active }) => ({
    background: active ? palette.field.hover : 'transparent',
    marginRight: 8,
}));

export const NotificationWrapper = styled('div')`
    padding: 0 24px;
    height: calc(100% - 70px);
    overflow-y: auto;
`;

export const NotificationSectionHeaderWrapper = styled('div')`
    margin-top: 8px;
    padding: 8px 0;
`;

export const EmptyNotificationWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60%;
`;

export const EmptyIconWrapper = styled('div')`
    position: relative;
    width: 255px;
    height: 175px;
    margin-top: -60px;
`;

export const IconWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '530px',
    marginTop: '10px',
    marginRight: '4px',
    padding: '8px',
    display: 'flex',
    borderRadius: '50px',
    cursor: 'pointer',
    top: '0',
    ':hover': {
        background: theme.palette.grey[200],
    },
}));

/**
 * NotificationItem Style
 */

export const Notification = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    flexGrow: 1,
    '&:hover': {
        background: palette.field.background,
        borderRadius: 8,
    },
}));

export const StyledLogoWrapper = styled('div')(({ theme: { palette } }) => ({
    position: 'relative',
    background: palette.field.background,
    minWidth: 40,
    height: 40,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const NotificationRight = styled('div')`
    width: 100%;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const CancelBtn = styled(Button)(({ theme: { palette } }) => ({
    borderRadius: 50,
    minHeight: 16,
    minWidth: 16,
    padding: '2px',
    '&:hover': {
        background: 'transparent!important',
        svg: {
            transform: 'scale(1.2)',
        },
    },
}));
