import React, { useCallback, useState } from 'react';

import { cloneDeep, isEmpty } from 'lodash';
import { FieldValues, get, set, useFormContext, UseFormGetValues } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import NestedSelect, { OptionProps } from 'components/tokens/select-components/NestedSelect';
import { getFirstKey } from 'utilities/objectUtils';

import { mergeKeys } from '../../common/utils';
import { filterDefinitions } from '../../filters/filterDefinitions';
import { getSameFiltersState } from '../../filters/utils';
import { TValues } from '../../filters/web-profile/website-traffic-rank/WebsiteTraffic';
import {
    FilterGroup,
    FilterGroupProps,
    FilterID,
    GetFiltersFnResult,
    GroupOption,
    GroupValues,
    MappedFilter,
    NodeValue,
} from '../../FilterTypes';
import { getGroupFilters, useFilterGroupOptions } from '../common/groupUtils';
import { useGroupActions } from '../common/useGroup';
import FilterGroupTemplate from '../FilterGroupTemplate';
import { GetFiltersResult } from '../types';

const createNewGroup = (value: NodeValue<TValues>) =>
    ({
        [FilterOperator.MATCH]: { [GroupValues.organization_size_indicators]: value },
    }) as NodeValue<TValues>;

const options: GroupOption[] = [
    {
        value: FilterID.staff_count_official,
        label: 'Number of employees (official)',
        databases: ['FI', 'NO', 'DK', 'SE'],
    },
    { value: FilterID.revenue_local, label: 'Revenue (local)', databases: ['FI', 'NO', 'DK', 'SE'] },
    { value: FilterID.revenue, label: 'Revenue (€)', databases: ['FI', 'NO', 'DK', 'SE'] },
    {
        value: FilterID.staff_number_web,
        label: 'Estimated size (~employees)',
        description: (
            <>
                Vainu uses machine learning to predict the company's size from their website, considering their
                real-time web traffic, number of offices, web technologies, key phrases on their website and more.
                <br />
                <Link
                    toNewTab
                    href="https://www.vainu.com/blog/employee-count-data/"
                    underline="always"
                    sx={{ textDecorationColor: 'inherit' }}
                >
                    Learn more
                </Link>
            </>
        ),
    },
];

const config: FilterGroup = {
    group: GroupValues.organization_size_indicators,
    label: 'Company size',
    options: options,
    filterUUIDs: [],
    logic: {},
    match: true,
    render: (props: FilterGroupProps) => <OrganizationSizeIndicatorsGroup {...props} />,
    getFilters: getFiltersById,
    createNewGroup,
};

export const organizationSizeIndicatorsGroupDefinition: Record<GroupValues.organization_size_indicators, FilterGroup> =
    {
        [GroupValues.organization_size_indicators]: config,
    };

export const OrganizationSizeIndicatorsGroup: React.FC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    removeGroup,
    ids: rawIds = [],
    addNewGroup,
    updateGroup,
    updateGroups,
    disabled = false,
}) => {
    const { getValues } = useFormContext();

    const getFilters = (): [string[], GetFiltersResult] => {
        const filteredIds: string[] = [];
        const filters: GetFiltersResult = {};
        rawIds.forEach((id) => {
            const result = getFiltersById(id, getValues);
            if (result) {
                filteredIds.push(id);
                filters[id] = result;
            }
        });
        return [filteredIds, filters];
    };

    const [ids, filterGroups] = getFilters();

    const { activeFilters, filterToHide, filteredOptions } = useFilterGroupOptions({ filterGroups, options });

    const { handleAddFilter, handleFilterRemove, keys, updateKeys } = useGroupActions({
        applyChanges,
        removeGroup,
        addNewGroup,
        updateGroup,
        createNewGroup,
        filterGroups,
        options: filteredOptions,
    });

    const [addRow, setAddRow] = useState(false);
    const [isFilterAdded, setIsFilterAdded] = useState(false);

    const handleApplyChanges = useCallback(() => {
        const updatedGroups: Record<string, NodeValue | undefined> = {};
        ids.forEach((id) => {
            const value = getValues(id);
            const filters = getFiltersById(id, getValues);
            const group = filterGroups[id];

            const filteredIds: number[] = [];
            filters?.filters.forEach((r, index) => {
                const nextOperator = getFirstKey(r.value);
                const fieldValue = r.value[nextOperator as keyof NodeValue];

                if (
                    r.id === FilterID.staff_number_web &&
                    getIsStaffNumberUndefined({ [FilterOperator.MATCH]: fieldValue })
                ) {
                    filteredIds.push(index);
                } else if (isEmpty(fieldValue)) {
                    filteredIds.push(index);
                }
            });

            if (filteredIds.length === 0) {
                updateKeys(true);
                applyChanges();
                return;
            }

            if (filters && filteredIds.length < filters.filters.length) {
                const values: NodeValue[] = getValues(group.path);

                const updatedArray = values.filter((_, i) => {
                    return !filteredIds.includes(i);
                });
                const updatedValue = cloneDeep(value);
                set(updatedValue, group.nodePath, updatedArray);
                updatedGroups[id] = updatedValue;
            } else {
                updatedGroups[id] = undefined;
            }
        });

        updateGroups?.(updatedGroups);
        updateKeys(true);
        applyChanges();
    }, [ids, updateGroups, updateKeys, applyChanges, getValues, filterGroups]);

    const renderFilters = () => {
        return ids.map((id) => {
            const filterGroup = filterGroups[id];

            return filterGroup.filters.map((filter, index) => {
                const filterDefinition = filter.definition;
                const sameFiltersState = getSameFiltersState(activeFilters, filter);

                return filterDefinition
                    ? filterDefinition.render({
                          key: keys[mergeKeys(id, filter.id)],
                          uuid: filterDefinition.id,
                          filter,
                          groupUUID: config.group,
                          removeFilter: () => handleFilterRemove(id, index),
                          sameFiltersState,
                      })
                    : null;
            });
        });
    };

    const addRowSelect = (
        <NestedSelect
            placeholder="Select"
            width="100%"
            height={40}
            containerSx={{ gridArea: 'add' }}
            nestedOptions={(filteredOptions as OptionProps[]) || []}
            slimInactiveBorder
            onChange={(value) => {
                if (Array.isArray(value)) {
                    throw new Error('should not be array value');
                }
                if (typeof value === 'number') {
                    throw new Error('should not be number value');
                }
                if (typeof value === 'string') {
                    throw new Error('should not be string value');
                }
                if (!value) {
                    return;
                }
                handleAddFilter(value.value as FilterID);
                setAddRow(false);
                setIsFilterAdded(true);
            }}
        />
    );

    const addRowButton = filteredOptions.length !== 0 && (
        <Button
            variant="tertiary"
            size="small"
            onClick={() => setAddRow(true)}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            AND
        </Button>
    );

    const renderedFilters = renderFilters();
    const props = {
        label: config.label,
        filters: renderedFilters,
        addFilter: handleAddFilter,
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        addRowSelect,
        addRowButton,
        addRow,
        isFilterAdded,
        setIsFilterAdded,
        filterToHide,
        disabled,
    };
    return <FilterGroupTemplate {...props} />;
};

export function getFiltersById(id: string, getValues: UseFormGetValues<FieldValues>): GetFiltersFnResult {
    const value = getValues(id);

    if (!value) {
        return;
    }

    if (!value[FilterOperator.MATCH]) {
        const basicFilters = getGroupFilters(id, getValues);
        return basicFilters;
    }

    return {
        filters: [
            {
                value,
                definition: filterDefinitions['staff_number_web'],
                id: 'staff_number_web',
                path: id,
            } as MappedFilter,
        ],
        path: id,
        nodePath: '',
        isUndefined: getIsStaffNumberUndefined(value),
    };
}

const getIsStaffNumberUndefined = (value: unknown) =>
    !get(
        value,
        mergeKeys(
            FilterOperator.MATCH,
            'organization_size_indicators',
            FilterOperator.ALL,
            0,
            FilterOperator.IN,
            'size_classes',
        ),
    ) &&
    !get(
        value,
        mergeKeys(
            FilterOperator.MATCH,
            'organization_size_indicators',
            FilterOperator.ALL,
            0,
            FilterOperator.NOT,
            FilterOperator.IN,
            'size_classes',
        ),
    );
