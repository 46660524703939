import React from 'react';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import OverflowContainer from 'components/layout/OverflowContainer';
import Button from 'components/tokens/Button';
import Typography from 'components/tokens/Typography';

import { useFilterGroupTemplateScroll, UseFilterGroupTemplateScrollProps } from './useFilterGroupTemplateScroll';

export interface FilterGroupTemplateProps extends UseFilterGroupTemplateScrollProps {
    filters: React.ReactNode[];
    label: string;
    addRow: boolean; // TODO: give more clear name for this parameter, not clear what it does by the name
    addRowButton: React.ReactNode;
    addRowSelect?: React.ReactNode;

    applyChanges: () => void;
    undoChanges: () => void;
    clearFilters: () => void;
    disabled?: boolean;
}

const FilterGroupTemplate: React.FC<FilterGroupTemplateProps> = ({
    filters,
    label,
    addRow = false,
    addRowButton,
    addRowSelect,
    isFilterAdded,
    setIsFilterAdded,
    applyChanges,
    undoChanges,
    clearFilters,
    disabled = false,
}) => {
    const { topWrapperRef, containerEl } = useFilterGroupTemplateScroll({ isFilterAdded, setIsFilterAdded });
    return (
        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={applyChanges}>
            <Wrapper
                role="presentation"
                aria-label={label}
                sx={{ position: 'relative', border: '1px solid', borderColor: 'grey.50' }}
                elevation={4}
            >
                {disabled && (
                    <Box
                        sx={{
                            position: 'absolute',
                            inset: 0,
                            zIndex: 1,
                            opacity: 0.1,
                            background: 'repeating-linear-gradient(-45deg, #000, #000 10px, #fff 10px, #fff 20px)',
                            borderRadius: 'inherit',
                        }}
                    />
                )}
                <Typography component="p" variant="small">
                    {label}
                </Typography>
                <TopWrapper>
                    <div ref={topWrapperRef}>
                        <OverflowContainer mode="vertical" headerPadding={0} containerEl={containerEl} />
                        <FiltersGrid rows={filters.length > 0 ? 'true' : undefined}>
                            {React.Children.map(filters, (f) => {
                                if (React.isValidElement(f)) {
                                    return f;
                                }
                                return null;
                            })}
                        </FiltersGrid>
                        <AddRowGrid>{addRow || !filters.length ? addRowSelect : addRowButton}</AddRowGrid>
                    </div>
                </TopWrapper>
                <BottomWrapper>
                    <BottomGrid>
                        <Button sx={{ gridArea: 'clear' }} variant="flat" size="small" onClick={clearFilters}>
                            Clear
                        </Button>
                        {undoChanges ? (
                            <Button
                                sx={{ gridArea: 'undo', fontStyle: 'italic', color: 'text.secondary' }}
                                variant="flat"
                                size="small"
                                onClick={undoChanges}
                            >
                                Undo changes
                            </Button>
                        ) : null}
                        <Button
                            data-testid="e2e-submit-button"
                            sx={{ gridArea: 'save' }}
                            variant="primary"
                            size="small"
                            onClick={applyChanges}
                        >
                            Apply
                        </Button>
                    </BottomGrid>
                </BottomWrapper>
            </Wrapper>
        </ClickAwayListener>
    );
};

export default FilterGroupTemplate;

export const Wrapper = styled(Paper)`
    padding: 1em 0.5em 1em 1em;
    margin: 0.5em 0 0 0.5em;
    width: 670px;
    height: 350px;
    display: grid;
    grid-template-rows: 32px 230px auto;
`;

export const TopWrapper = styled('div')`
    position: relative;
    & > div {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        max-height: 500px;
    }
`;

export const BottomWrapper = styled('div')`
    margin-top: 24px;
    padding-right: 0.5em;
`;

export const FiltersGrid = styled('div')<{ rows?: string }>(({ theme: { palette }, rows }) => ({
    display: 'grid',
    gridTemplateRows: 'auto',
    gap: 5,
    marginBottom: 8,
    ...(rows && {
        backgroundColor: palette.field.background,
        padding: 8,
        borderRadius: 8,
    }),
}));

export const AddRowGrid = styled('div')`
    display: grid;
    grid-template-columns: 50px 200px auto;
    grid-template-rows: minmax(auto, 32px);
    grid-template-areas: 'add add . clear';
`;

export const BottomGrid = styled('div')`
    display: grid;
    grid-template-columns: auto auto 100px auto auto;
    grid-template-rows: auto;
    column-gap: 32px;
    grid-template-areas: 'clear . undo . save';
`;
