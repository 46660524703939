import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import { IntegrationKey } from 'api/types/ApplicationStore';
import { WorkFlowDestination } from 'api/types/Trigger';
import { CrmName, CRMObject, IntegrationSetting } from 'components/modules/connectors/types/Crm';
import { triggerCrmExportables } from 'components/modules/connectors/utils/CRMTargetText';
import ExportTable from 'components/modules/triggers/TriggerTable/ExportTable';
import SourceToDestination from 'components/templates/SourceToDestination';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { usePermissionContext } from 'contexts/PermissionContext';
import { useTriggerState, LeadPreview } from 'contexts/TriggerContext';
import { enabledCRMs, shortenTriggerTitle, stateDestinationToDBdestination } from 'utilities/triggers';

import {
    CRMDestination,
    CRMDestinationType,
    EmailDestination,
    EmailDestinationType,
    SlackDestination,
    SlackDestinationType,
    WebhookDestination,
    WebhookDestinationType,
} from '../TriggerDestination';

interface Props {
    open: boolean;
    onClose: () => void;
    exportMessages?: (destinations: WorkFlowDestination[]) => void;
    massExport?: boolean;
    reDeliverMessage?: (destinations: WorkFlowDestination[]) => void;
    reDeliver?: boolean;
    exportDestination: string;
    exportObject?: (
        destination: WorkFlowDestination | null,
        integrationSettings: IntegrationSetting<CRMObject>,
    ) => void;
    nonTriggerExport?: boolean;
    exportPreviewRows: LeadPreview[];
}

type DestinationType = EmailDestinationType | SlackDestinationType | WebhookDestinationType | CRMDestinationType | null;

const TriggerExportModal: React.FC<Props> = ({
    open = true,
    onClose = () => {},
    exportMessages = () => {},
    massExport = true,
    reDeliverMessage = () => {},
    reDeliver = false,
    exportDestination = '',
    exportPreviewRows = [],
    nonTriggerExport = false,
    exportObject = () => {},
}) => {
    const {
        old: state,
        new: { activeCRMIntegrationSettings },
    } = useTriggerState();

    const { hasIntegration, profile } = usePermissionContext();
    const { destinations, id, name } = state;
    const [selectedDestinationObject, setSelectedDestinationObject] = useState<DestinationType>(null);

    useEffect(() => {
        setSelectedDestinationObject(null);
    }, [id]);

    const getDestinationValue = useCallback(
        (value: string) => {
            switch (value) {
                case 'email':
                    return (
                        destinations.find((i) => i.system === 'email') || {
                            system: 'email',
                            emails: [profile.userName],
                            active: true,
                            isValid: true,
                        }
                    );
                case 'slack':
                    return (
                        destinations.find((i) => i.system === 'slack') || {
                            system: 'slack',
                            slackChannel: [],
                            active: true,
                        }
                    );
                case 'webhook':
                    return (
                        destinations.find((i) => i.system === 'webhook') || {
                            system: 'webhook',
                            urls: [],
                            active: true,
                        }
                    );
                case 'pipedrive':
                case 'hubspot':
                case 'salesforce':
                case 'dynamics':
                case 'salesforcesandbox':
                    return (
                        destinations.find((i) => i.system === value) || {
                            system: value,
                            crm_objects: [Object.keys(triggerCrmExportables[value])[0]],
                            isValid: true,
                            active: true,
                        }
                    );
                default:
                    return {};
            }
        },
        [destinations, profile.userName],
    );

    useEffect(() => {
        // FIXME: Types mismatch Destination vs DestinationType
        setSelectedDestinationObject(getDestinationValue(exportDestination) as DestinationType);
    }, [exportDestination, getDestinationValue]);

    const common = ['email', 'webhook'];
    const requiresIntegration = (['slack'] as IntegrationKey[]).filter((i) => hasIntegration(i));
    const enabledDestinations = [...common, ...requiresIntegration].filter((i) => i === exportDestination);

    const CRMDestinations: IntegrationSetting<CRMObject>[] = activeCRMIntegrationSettings.reduce(
        (CRMSettings: IntegrationSetting<CRMObject>[], crmSetting: IntegrationSetting<CRMObject>) => {
            const crmname = crmSetting.integration_target.toLowerCase();
            if (exportDestination.toLowerCase() === crmname && enabledCRMs.includes(crmname)) {
                CRMSettings.push(crmSetting);
            }
            return CRMSettings;
        },
        [],
    );

    const updateDestination = (obj: DestinationType) => {
        setSelectedDestinationObject(obj);
    };

    const renderSelection = (value: string) => {
        switch (value) {
            case 'email':
                return (
                    <EmailDestination
                        destination={selectedDestinationObject as EmailDestinationType}
                        updateDestination={updateDestination}
                        hideHeader
                    />
                );
            case 'slack':
                return (
                    <SlackDestination
                        destination={selectedDestinationObject as SlackDestinationType}
                        enableDestination={hasIntegration(value)}
                        updateDestination={updateDestination}
                        hideHeader
                    />
                );
            case 'webhook':
                return (
                    <WebhookDestination
                        destination={selectedDestinationObject as WebhookDestinationType}
                        updateDestination={updateDestination}
                        hideHeader
                    />
                );
            default:
                return 'Not available';
        }
    };

    return (
        <Dialog
            open={open}
            onBackdropClick={onClose}
            fullWidth
            maxWidth="sm"
            BackdropProps={{
                invisible: true,
            }}
        >
            <DialogContent>
                <StyledClose onClick={onClose}>
                    <Icon type="CloseBig" width={15} height={15} />
                </StyledClose>
                <SourceToDestination target={exportDestination} source="trigger" />
                <StyledTitle>
                    {exportPreviewRows?.length < 2
                        ? `Send trigger "${shortenTriggerTitle(exportPreviewRows?.[0]?.lead?.title)}"`
                        : `Send ${exportPreviewRows?.length} triggers`}
                </StyledTitle>
                <Box sx={{ marginBottom: 3 }}>
                    {enabledDestinations.map((value) => (
                        <div key={value}>{renderSelection(value)}</div>
                    ))}
                    {CRMDestinations.map((crmSettings) => {
                        const fieldMapping = crmSettings?.field_mapping || [];
                        const lowerCaseName = crmSettings?.integration_target?.toLowerCase() || '';
                        return (
                            <div key={lowerCaseName}>
                                <CRMDestination
                                    target={crmSettings.integration_target}
                                    lowerCaseName={lowerCaseName as CrmName}
                                    destination={selectedDestinationObject as CRMDestinationType}
                                    crmObjects={
                                        (Object.keys(triggerCrmExportables[lowerCaseName as CrmName]) ||
                                            []) as CRMObject[]
                                    }
                                    fieldMapping={fieldMapping}
                                    updateDestination={updateDestination}
                                    hideHeader
                                    settingsAsDialog={false}
                                    triggerName={name}
                                />
                            </div>
                        );
                    })}
                </Box>
                {exportPreviewRows?.length ? (
                    <ExportTable data={exportPreviewRows} selectedDestination={selectedDestinationObject} />
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} variant="flat">
                    Cancel
                </Button>
                <Button
                    disabled={!selectedDestinationObject?.isValid}
                    onClick={() => {
                        if (!selectedDestinationObject) {
                            return;
                        }
                        const destination = stateDestinationToDBdestination(selectedDestinationObject);
                        if (destination) {
                            if (nonTriggerExport) {
                                exportObject(destination, CRMDestinations?.[0]);
                            } else if (reDeliver) {
                                reDeliverMessage([destination]);
                            } else {
                                exportMessages([destination]);
                            }
                        }
                        onClose();
                    }}
                >
                    Export
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TriggerExportModal;

const StyledTitle = styled('div')`
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.02em;
    padding: 0px 0px 8px 0px;
`;

const StyledClose = styled('div')`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
`;
