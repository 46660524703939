import React, { useMemo, useState, useEffect } from 'react';

import { styled } from '@mui/material';

import { DialogController } from 'contexts/DialogContext/DialogController';

import { useCRMIntegConfig } from '../context/CRMIntegrationConfigContext';
import {
    AllCRMPropertiesMap,
    CrmName,
    CRMObject,
    CRMPropertiesMap,
    FieldToCRMPropertyMap,
    Mapping,
    EnhancedMapping,
    VainuDatapoint,
    MappingsMap,
} from '../types/Crm';
import { CRMObjectsMenu } from './CRMObjectsMenu';
import { MappingFormDialog } from './dialogs/MappingFormDialog';
import { NewCRMPropertySuggestDialog } from './dialogs/NewCRMPropertySuggestDialog';
import { RemoveMappingDialog } from './dialogs/RemoveMappingDialog';
import { MappingsTabContent } from './MappingsTabContent';
import { getSourcePreview } from './utils';

type MappingsTabProps = { crm: CrmName };

const MappingsTab: React.FC<MappingsTabProps> = ({ crm }) => {
    const [
        { id, isLoaded, field_mappings, crmPropertiesMap, vainuDatapoints },
        { refetchMappings, refetchCRMProperties },
    ] = useCRMIntegConfig();

    const [activeCRMObjectTab, setActiveCRMObjectTab] = useState<CRMObject | ''>('');

    useEffect(() => {
        if (isLoaded) {
            setActiveCRMObjectTab(Object.keys(crmPropertiesMap)[0] as CRMObject);
        }
    }, [crm, isLoaded, crmPropertiesMap]);

    const isLoading =
        !isLoaded ||
        Object.keys(field_mappings).length === 0 ||
        Object.keys(crmPropertiesMap).length === 0 ||
        activeCRMObjectTab === '' ||
        vainuDatapoints.length === 0;

    const crmObjects = useMemo(() => (Object.keys(crmPropertiesMap) as CRMObject[]) ?? [], [crmPropertiesMap]);

    const crmPropertiesByValueMap = useMemo(() => {
        return Object.keys(crmPropertiesMap).length > 0
            ? getCRMPropertiesByObjectMap(crmPropertiesMap as CRMPropertiesMap)
            : ({} as AllCRMPropertiesMap);
    }, [crmPropertiesMap]);

    const sourceFieldToDatapointMap = useMemo(() => {
        return vainuDatapoints.reduce(
            (acc, datapoint) => ({ ...acc, [datapoint.field]: datapoint }),
            {} as Record<string, VainuDatapoint>,
        );
    }, [vainuDatapoints]);

    const enhancedMappings = useMemo(() => {
        return isLoading
            ? []
            : getEnhancedMappings(
                  (field_mappings as MappingsMap)[activeCRMObjectTab],
                  crmPropertiesByValueMap[activeCRMObjectTab],
                  sourceFieldToDatapointMap,
              );
    }, [isLoading, activeCRMObjectTab, sourceFieldToDatapointMap, field_mappings, crmPropertiesByValueMap]);

    return (
        <>
            <Container>
                <CRMObjectsMenu
                    isLoading={isLoading}
                    crmName={crm}
                    activeTab={activeCRMObjectTab}
                    tabs={crmObjects}
                    onSelect={setActiveCRMObjectTab}
                />
                <MappingsTabContent
                    isLoading={isLoading}
                    crmName={crm}
                    activeCRMObjectTab={activeCRMObjectTab}
                    crmIntegrationId={id}
                    mappings={enhancedMappings}
                    crmProperties={crmPropertiesMap[activeCRMObjectTab as CRMObject]?.fields ?? []}
                    refetchMappings={refetchMappings}
                    refetchCRMProperties={refetchCRMProperties}
                />
            </Container>
            <DialogController type="MAPPING_FORM" component={MappingFormDialog} />
            <DialogController type="REMOVE_MAPPING" component={RemoveMappingDialog} />
            <DialogController type="NEW_CRM_PROPERTY_SUGGEST" component={NewCRMPropertySuggestDialog} />
        </>
    );
};

/**
 * @param crmProperties an object with all CRM properties
 * @returns a map of CRM properties with its' `value` property as a key
 * @example {
 *     Account: { about_us: { *crmPropertyData* }, annual_revenue: { *crmPropertyData* } },
 *     Contact: { name: { *crmPropertyData* } },
 *     ...etc,
 * }
 */
const getCRMPropertiesByObjectMap = (crmProperties: CRMPropertiesMap) => {
    return Object.entries(crmProperties).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: value.fields.reduce(
                (innerAcc, crmProperty) => ({ ...innerAcc, [crmProperty.value]: crmProperty }),
                {},
            ),
        }),
        {},
    ) as AllCRMPropertiesMap;
};

/**
 * @param mappings - an array of raw mappings
 * @param crmPropertiesMap - a map with a { crmPropertyKey: CRMPropertyData } shape
 * @param previewsMap - a map with a { vainuDatapointValue: string | string[] } shape
 * @returns returns an array of mappings with additional information about the corresponding CRM property, 'mandatory' flag, etc.
 * @example [
 *   { ...rawMapping, ...additionalInfoFromCRM }
 *   { ...rawMapping, ...additionalInfoFromCRM }
 * ]
 */
const getEnhancedMappings = (
    mappings: Mapping[] = [],
    crmPropertiesMap: FieldToCRMPropertyMap,
    sourceFieldToDatapointMap: Record<string, VainuDatapoint>,
): EnhancedMapping[] => {
    return mappings.map((mapping) => ({
        ...mapping,
        crmPropertyLabel: crmPropertiesMap[mapping.target_field]?.label ?? 'MISSING CRM PROPERTY',
        mandatory: crmPropertiesMap[mapping.target_field]?.mandatory ?? false,
        crmPropertyType: crmPropertiesMap[mapping.target_field]?.type ?? 'Unknown CRM property type',
        createdByVainu: crmPropertiesMap[mapping.target_field]?.created_by_vainu,
        sources: mapping.sources.map((source) => ({
            ...source,
            preview: getSourcePreview({
                sourceField: source.source_field,
                sourceObject: source.source_object,
                selectedOptionValue: source.options.format ?? '',
                sourceFieldToDatapointMap,
            }),
        })),
    }));
};

const Container = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 10px 20px',
});

export { MappingsTab };
