import { Suspense, lazy, memo } from 'react';

import { Box, Skeleton } from '@mui/material';
import { isNotEmpty } from 'utilities';

import { TabChangeHandler } from 'components/modules/profiles/Profile';
import { TabSlug } from 'components/modules/profiles/Profile/tabs';
import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import CountryFlag from '../CountryFlag';
import { FlatAddress } from '../types';
import { getLastModifiedDate, hasCoordinates } from '../utils';

const AddressMap = lazy(() => import('./AddressMap'));

export interface LocationsMapProps {
    countries?: string[];
    company_name?: string;
    addresses: FlatAddress[];
    skeleton?: boolean;
    onGoToClick?: TabChangeHandler;
}

export const LocationsMap: React.VFC<LocationsMapProps> = memo(
    ({ countries, company_name, addresses, skeleton = false, onGoToClick }) => {
        const lastModified = getLastModifiedDate(addresses);
        const addressesWithCoordinates = addresses.filter(hasCoordinates);

        if (skeleton) {
            return (
                <Frame padding="large">
                    <Box>
                        <Typography
                            variant="body1"
                            weight="bold"
                            component="h2"
                            sx={{ lineHeight: onGoToClick ? '40px' : undefined }}
                        >
                            Office locations
                        </Typography>
                    </Box>
                    <Typography variant="small" color="subtle">
                        <Skeleton variant="text" width={160} />
                    </Typography>
                    <Skeleton variant="rounded" width="100%" height={364} sx={{ marginTop: 2 }} />
                </Frame>
            );
        }

        return (
            <Frame padding="large">
                <Box>
                    <Typography
                        variant="body1"
                        weight="bold"
                        component="h2"
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        Office locations
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {lastModified && (
                                <Typography variant="tiny" color="subtle" sx={{ fontStyle: 'italic' }}>
                                    {'Last checked: '}
                                    <Box component="span" sx={{ fontWeight: 500 }}>
                                        {lastModified}
                                    </Box>
                                </Typography>
                            )}
                            {onGoToClick && (
                                <Button
                                    sx={{ marginLeft: 1 }}
                                    variant="tertiary"
                                    startIcon={<Icon type="CircleRight" />}
                                    onClick={() => onGoToClick(TabSlug.Locations, true)}
                                />
                            )}
                        </Box>
                    </Typography>
                </Box>

                {company_name && isNotEmpty(countries) && (
                    <Typography variant="small" color="subtle">
                        {`${company_name} operates in at least `}
                        <Typography component="span" weight="bold">
                            {countries.length}
                        </Typography>
                        {countries.length === 1 ? ' country ' : ' countries '}
                        {countries.map((country) => (
                            <CountryFlag key={country} country={country} />
                        ))}
                        {'.'}
                    </Typography>
                )}

                {addressesWithCoordinates.length > 0 && (
                    <Suspense fallback={<Skeleton variant="rounded" height={362} sx={{ marginTop: 2 }} />}>
                        <AddressMap addresses={addressesWithCoordinates} />
                    </Suspense>
                )}
            </Frame>
        );
    },
);

export default LocationsMap;
