import { useCallback, useRef } from 'react';

import { Point } from '../types';
import { easeInOutCubic, linear } from './ease';

export type AnimationStateCallback = (zoom: number | undefined, center: Point | undefined) => void;

export type UseAnimationParams = {
    animationStateCallback: AnimationStateCallback;
};

export type AnimationOptions = Partial<{
    duration: number | 'auto';
    animationType: 'bicubic' | 'linear';
    fly: boolean;
}>;

const defaultAnimationOptions: Required<AnimationOptions> = {
    duration: 'auto',
    animationType: 'linear',
    fly: false,
};

const maxFrameRate = 15;
const minFrameTime = Math.floor(1000 / maxFrameRate);

/**
 * Returns functions that are used to animate the graph zooming and panning.
 * Does not render the animation by itself, but just returns the new values for rendering
 * (i.e. does the eased tweening between the animation start and end for the given time).
 *
 * Work In Progress: Would still preferably need some tweaking to make the
 * animations smoother and more natural.
 *
 * @param animationStateCallback: a function that is called with the new zoom and center
 * at each animation frame to trigger rerendering.
 */
const useAnimation = ({ animationStateCallback }: UseAnimationParams) => {
    const animationFrameID = useRef<number | undefined>(undefined);

    const doAnimation = useCallback(
        (
            origCenter: Point,
            origZoom: number,
            targetCenter: Point,
            targetZoom: number,
            options: AnimationOptions = {},
        ) => {
            const { duration, animationType, fly } = { ...defaultAnimationOptions, ...options };

            // Cancels any previous animation.
            if (animationFrameID.current) {
                window.cancelAnimationFrame(animationFrameID.current);
            }

            // Used by the animation frame to determine animation start timestamp.
            let start = 0;
            let prevFrameTime = 0;

            const distance = Math.hypot(targetCenter.x - origCenter.x, targetCenter.y - origCenter.y);
            const minDuration = 300;
            const maxDuration = 2000;
            const dur =
                duration === 'auto' ? Math.min(Math.log2(distance + 2) * minDuration * 1.2, maxDuration) : duration;
            const deltaFunction = animationType === 'linear' ? linear : easeInOutCubic;

            /**
             * The generic function that calls the requestAnimationFrame until the animation ends,
             * for the given callback that returns the new animation state for the given time delta.
             */
            const animateFrame = (getZoomAndCenterCallback: (time: number) => { zoom: number; center: Point }) => {
                const frameFn = (time: number) => {
                    if (start === 0) {
                        start = time;
                    }
                    const frameTime = time - prevFrameTime;

                    if (prevFrameTime !== 0 && frameTime < minFrameTime) {
                        animationFrameID.current = window.requestAnimationFrame(frameFn);
                    } else if (time - start < dur) {
                        prevFrameTime = time;
                        const { zoom, center } = getZoomAndCenterCallback(time);
                        animationStateCallback(zoom, center);
                        animationFrameID.current = window.requestAnimationFrame(frameFn);
                    } else {
                        animationFrameID.current = undefined;
                        animationStateCallback(targetZoom, targetCenter);
                    }
                };
                animationFrameID.current = window.requestAnimationFrame(frameFn);
            };

            // Pans and zooms the view by "flying" over to the new center.
            if (fly) {
                // Determines the zoom on the "cruise flight level". The longer the distance, the more zoomed out the animation goes.
                // If zooming in, the target zoom is used to determine the halfway zoom; the original zoom otherwise.
                const compZoom = Math.max(origZoom, targetZoom);
                const halfwayZoom = Math.max(Math.min((compZoom / Math.log2(distance + 2)) * 1.5, compZoom), 1);
                const halfwayZoomRatio = Math.log2(halfwayZoom / origZoom);
                const targetZoomRatio = Math.log2(halfwayZoom / targetZoom);

                animateFrame((time) => {
                    let zoom = 0;
                    if (time - start < dur / 2) {
                        const zoomGrowRatio = Math.pow(2, deltaFunction(time - start, 0, halfwayZoomRatio, dur / 2));
                        zoom = origZoom * zoomGrowRatio;
                    } else {
                        const zoomGrowRatio = Math.pow(
                            2,
                            deltaFunction(time - dur / 2 - start, 0, targetZoomRatio, dur / 2),
                        );
                        zoom = halfwayZoom / zoomGrowRatio;
                    }
                    const center = {
                        x: deltaFunction(time - start, origCenter.x, targetCenter.x, dur),
                        y: deltaFunction(time - start, origCenter.y, targetCenter.y, dur),
                    };

                    return { zoom, center };
                });
            } else if (origZoom === targetZoom) {
                // Pans the view by going "on the ground"
                animateFrame((time) => ({
                    zoom: targetZoom,
                    center: {
                        x: deltaFunction(time - start, origCenter.x, targetCenter.x, dur),
                        y: deltaFunction(time - start, origCenter.y, targetCenter.y, dur),
                    },
                }));
            } else {
                // Zooms towards the target.
                // The zooming jumps in power of 2; one full step is twice zoomed in.
                // Therefore, the zooming animation starts from currZoom * 2^0 and goes to targetZoom, which is currZoom * 2^targetZoomLogRatio
                const targetZoomRatio = Math.log2(targetZoom / origZoom);

                animateFrame((time) => {
                    const zoomGrowRatio = Math.pow(2, deltaFunction(time - start, 0, targetZoomRatio, dur));
                    const zoom = origZoom * zoomGrowRatio;

                    // Adjust the current center by zoomRatio so that the cursor stays on the same point it was on.
                    const zoomRatio = (1 - origZoom / zoom) / (1 - origZoom / targetZoom);

                    return {
                        zoom,
                        center: {
                            x: origCenter.x + (targetCenter.x - origCenter.x) * zoomRatio,
                            y: origCenter.y + (targetCenter.y - origCenter.y) * zoomRatio,
                        },
                    };
                });
            }
        },
        [animationStateCallback],
    );

    return doAnimation;
};

export default useAnimation;
