import React from 'react';

import { Box, Tooltip } from '@mui/material';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import Popover from 'material-ui-popup-state/HoverPopover';

import { crmLogo } from 'components/features/Notifications/NotificationItem';
import { CrmName } from 'components/modules/connectors/types/Crm';
import Icon, { IconType } from 'components/tokens/Icon';
import { Link } from 'components/tokens/Link';
import { Typography } from 'components/tokens/Typography';
import { useTrigger } from 'contexts/TriggerContext';
import { formatDate, toDateUTC } from 'utilities/date';

import { CellProps } from './TableComponent';
import { TooltipRow } from './TriggerCellTooltip';

const StatusCell: React.FC<CellProps> = ({ row, nextDeliveryLabel }) => {
    const [{ dailyLimit, notify }, , { setActivePopup }] = useTrigger();
    const popupState = usePopupState({ variant: 'popover', popupId: 'info-popover' });
    const { olderThanTrigger, olderThanTriggerChanged } = row;
    const message = row.messages?.[row.messages.length - 1];
    const state = message?.state;

    if (state === 'delivered') {
        const target = message?.linked_prospect_exports?.[0]?.target;
        const icon = crmLogo[target?.toLowerCase() as CrmName] || 'Mail';

        return (
            <>
                <div {...bindHover(popupState)}>
                    <Icon type={icon} sx={{ display: 'block' }} fontSize="small" />
                </div>
                <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ padding: 2, display: 'flex', gap: 2 }}>
                        <Box>
                            <Typography weight="semibold" sx={{ marginBottom: 1 }}>
                                Sent to {target || 'Email'}
                            </Typography>
                            {message?.processing_timestamp && (
                                <TooltipRow icon="Export">
                                    {formatDate(toDateUTC(message.processing_timestamp), 'long-datetime')}
                                </TooltipRow>
                            )}
                        </Box>

                        <Icon
                            sx={{
                                width: 36,
                                height: 36,
                            }}
                            type={icon}
                        />
                    </Box>
                </Popover>
            </>
        );
    }

    if (state === 'error') {
        return getTooltip('Error', 'Error', 'Something went wrong', 'error.main');
    }

    if (olderThanTrigger || olderThanTriggerChanged) {
        return getTooltip(
            'ErrorOutline',
            "Couldn't be delivered",
            olderThanTrigger
                ? "The event is older than the trigger and can't be automatically delivered"
                : 'There has been a change in the trigger and/or in the source list. The event is not guaranteed to be delivered',
        );
    }

    if (notify === 'none') {
        return null;
    }

    return getTooltip(
        'Calendar',
        'Awaiting delivery',
        <span>
            Based on your{' '}
            <Link
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    setActivePopup('select-delivery-time-popper');
                }}
                sx={{ color: '#b8b8b8', textDecorationColor: '#b8b8b8' }}
            >
                delivery quota
            </Link>
            , {dailyLimit} of the latest events will send {nextDeliveryLabel}.
        </span>,
    );
};

export default StatusCell;

function getTooltip(
    iconType: IconType,
    title: React.ReactNode,
    content?: React.ReactNode,
    iconColor: string = 'text.subtle',
) {
    return (
        <Tooltip
            title={
                <>
                    <Typography variant="tiny" weight="semibold" component="p" sx={{ color: 'common.white' }}>
                        {title}
                    </Typography>
                    <Typography variant="tiny" color="subtle">
                        {content}
                    </Typography>
                </>
            }
        >
            <Box>
                <Icon type={iconType} sx={{ display: 'block' }} color={iconColor} fontSize="small" />
            </Box>
        </Tooltip>
    );
}
