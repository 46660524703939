import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronUp: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M12 8.28802L5.98999 14.298L7.40399 15.713L12.004 11.113L16.604 15.713L18.011 14.298L12 8.28802Z" />
    </SvgIcon>
);

export default ChevronUp;
