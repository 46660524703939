import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowShortUp = (props) => (
    <SvgIcon {...props}>
        <path d="M13 7.83L16.59 11.41L18 10L12 4L6 10L7.41 11.41L11 7.83V20H13V7.83Z" />
    </SvgIcon>
);

export default ArrowShortUp;
