import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useParams } from 'react-router';

import { ExternalReferencesStatsPayload, getExternalReferencesStatsAsync } from 'api/externalReferences';
import { InsightsPayload } from 'api/filterCompanies';
import { useCompanyMatch } from 'components/modules/connectors/hooks/useCompanyMatch';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { mapCRMNameToTarget } from 'components/modules/connectors/utils/CRMTargetText';
import { Button } from 'components/tokens/Button';
import { ButtonTabs } from 'components/tokens/ButtonTabs';
import { Icon } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { usePermissionContext } from 'contexts/PermissionContext';
import { formatDate, toDateUTC } from 'utilities/date';

import AnalysisProgress from './AnalysisProgress';
import getStatsPayload from './getStatsPayload';
import TileGrid from './TileGrid';
import NumberTile from './Tiles/NumberTile';
import Tile from './Tiles/Tile';
import useStats from './useStats';
import { getQuery } from './utils';

export const CRM_INSIGHT_LIMIT = 200000;

const CRMInsights = () => {
    const axios = useAxiosContext();
    const { isDomainConnector } = usePermissionContext();
    const { crmName } = useParams();
    const { matchedByCountries, matchedByDomains } = useCompanyMatch();

    const matchedCount = isDomainConnector ? matchedByDomains.count : matchedByCountries.count;

    const [selectedTab, setSelectedTab] = useState<'all' | 'customers'>('all');

    const target = mapCRMNameToTarget(crmName as CrmName);
    const statsQueryUid = [target, 'default'].join('-');
    const customersQueryUid = [target, 'customers'].join('-');
    const statsPayload = useMemo(
        () =>
            getStatsPayload(isDomainConnector).map((row) => ({
                ...row,
                '?AGGREGATE': [
                    {
                        '?SELECT': {
                            path: isDomainConnector ? 'domain_match' : 'organization_match',
                        },
                    },
                    ...row['?AGGREGATE'],
                ],
            })) as InsightsPayload['stats'],

        [isDomainConnector],
    );
    const defaultPayload: ExternalReferencesStatsPayload = {
        query: getQuery(target, isDomainConnector),
        limit: CRM_INSIGHT_LIMIT,
        order: null,
        query_uid: statsQueryUid,
        stats: statsPayload,
    };

    const customersPayload: ExternalReferencesStatsPayload = {
        query: getQuery(target, isDomainConnector, true),
        limit: CRM_INSIGHT_LIMIT,
        order: null,
        query_uid: customersQueryUid,
        stats: statsPayload,
    };

    const { generateStats, processesQuery, statsQuery, cancelAnalysis } = useStats({
        queryUid: statsQueryUid,
        processType: 'external-references-stats-async-api',
    });
    const {
        generateStats: generateCustomerStats,
        processesQuery: customerProcessesQuery,
        statsQuery: customerStatsQuery,
        cancelAnalysis: cancelCustomerAnalysis,
    } = useStats({
        queryUid: customersQueryUid,
        processType: 'external-references-stats-async-api',
    });

    const { data: statsProcesses, isFetching } = processesQuery;
    const { data: customerStatsProcesses } = customerProcessesQuery;

    const handleGenerateStatsClick = async () => {
        generateStats(() => getExternalReferencesStatsAsync(axios, defaultPayload));
        generateCustomerStats(() => getExternalReferencesStatsAsync(axios, customersPayload));
    };

    const customerCount = customerStatsQuery.data?.reduce((prev, next) => Math.max(prev, next.documents_scanned), 0);

    const isAnalysisDisabled = !!statsProcesses?.inProgress || !!customerStatsProcesses?.inProgress;

    const hasNoAnalysis = !isFetching && !statsProcesses?.completed && !statsProcesses?.inProgress;

    return (
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    width: '100%',
                    gap: 1,
                }}
            >
                <Typography variant="h4" color="dark" sx={{ minWidth: 'min(500px, 100%)' }}>
                    How your organization is targeting companies
                </Typography>

                {statsProcesses?.completed?.finished && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="caption" color="subtle">
                            Last checked:{' '}
                            <span style={{ fontWeight: 500 }}>
                                {formatDate(toDateUTC(statsProcesses.completed.finished), 'long-datetime')}
                            </span>
                        </Typography>

                        <Button
                            startIcon={<Icon type="Refresh" />}
                            variant="flat"
                            onClick={handleGenerateStatsClick}
                            disabled={isAnalysisDisabled}
                        />
                    </Box>
                )}
            </Box>

            {hasNoAnalysis && (
                <Tile>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="subtitle1" color="dark" sx={{ flex: 1 }}>
                            Create a report on your companies and customers
                        </Typography>
                        <Button onClick={handleGenerateStatsClick} disabled={isAnalysisDisabled}>
                            Generate insights
                        </Button>
                    </Box>
                </Tile>
            )}

            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', opacity: hasNoAnalysis ? 0.3 : 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        width: '100%',
                        gap: 1,
                    }}
                >
                    <ButtonTabs selected={selectedTab}>
                        <Button
                            value="all"
                            startIcon={<Icon type="HubspotCompanySvg" />}
                            onClick={() => setSelectedTab('all')}
                        >
                            All
                        </Button>
                        <Button
                            value="customers"
                            startIcon={<Icon type="HubspotDealSvg" />}
                            onClick={() => setSelectedTab('customers')}
                        >
                            Customers
                        </Button>
                    </ButtonTabs>
                </Box>

                {((selectedTab === 'all' && statsProcesses?.inProgress) ||
                    (selectedTab === 'customers' && customerStatsProcesses?.inProgress)) && (
                    <AnalysisProgress
                        progress={
                            selectedTab === 'all'
                                ? statsProcesses?.inProgress?.progress
                                : customerStatsProcesses?.inProgress?.progress
                        }
                        companyCount={selectedTab === 'all' ? matchedCount : customerCount}
                        onCancel={() => {
                            cancelAnalysis();
                            cancelCustomerAnalysis();
                        }}
                    />
                )}

                <NumberTile title="Matched Companies" value={matchedCount} subtitle="Matched with Vainu data" />
                <NumberTile title="Customers" value={customerCount} subtitle="Companies with won deals" />

                {selectedTab === 'all' ? (
                    <TileGrid data={statsQuery.isFetching ? undefined : statsQuery.data} statsQuery={statsPayload} />
                ) : (
                    <TileGrid
                        data={customerStatsQuery.isFetching ? undefined : customerStatsQuery.data}
                        statsQuery={statsPayload}
                    />
                )}
            </Box>
        </Box>
    );
};

export default CRMInsights;
