import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Zapier = (props) => (
    <SvgIcon {...props}>
        <path d="M14.2499 12.0039C14.2502 12.653 14.1332 13.2967 13.9043 13.9041C13.2968 14.1328 12.6529 14.2501 12.0037 14.2502H11.9961C11.3275 14.2494 10.687 14.1272 10.0959 13.9043C9.86698 13.2969 9.74978 12.6531 9.75 12.0039V11.9961C9.74969 11.3472 9.86664 10.7035 10.0952 10.0962C10.7028 9.86701 11.3468 9.74971 11.9961 9.75H12.0037C12.653 9.74969 13.2969 9.86696 13.9044 10.0961C14.1333 10.7034 14.2503 11.3471 14.25 11.996V12.0039L14.2499 12.0039ZM20.875 10.5H15.6215L19.3361 6.7852C19.0445 6.37564 18.7193 5.99113 18.3638 5.63566V5.63538C18.0083 5.2803 17.6238 4.95537 17.2145 4.66395L13.4997 8.37877V3.1253C13.0056 3.04218 12.5055 3.00027 12.0045 3L11.9952 3C11.4856 3.00028 10.9864 3.04359 10.5 3.1253V8.37877L6.7852 4.66395C6.3758 4.95529 5.99156 5.28045 5.63651 5.63602L5.63454 5.63742C5.27964 5.99244 4.95487 6.37636 4.66359 6.7852L8.3787 10.5H3.1253C3.1253 10.5 3 11.4867 3 11.9969V12.0031C3 12.5133 3.04324 13.0133 3.1253 13.5H8.37877L4.66366 17.2148C5.24808 18.0349 5.96508 18.7519 6.7852 19.3363L10.5 15.6212V20.875C10.9935 20.9577 11.4931 20.9995 11.9935 21H12.0062C12.5066 20.9995 13.0061 20.9577 13.4996 20.875V15.6212L17.2148 19.3363C17.624 19.0448 18.0084 18.7198 18.3638 18.3646L18.3646 18.3638C18.7196 18.0083 19.0446 17.624 19.3361 17.2148L15.621 13.5H20.875C20.9568 13.0141 20.9994 12.5156 21 12.0065V11.9935C20.9994 11.4844 20.9568 10.9859 20.875 10.5Z" />
    </SvgIcon>
);

export default Zapier;
