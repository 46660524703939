import React, { useEffect, useRef, useState } from 'react';

import { Box, ClickAwayListener, Paper } from '@mui/material';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Popper from '@mui/material/Popper';
import Radio from '@mui/material/Radio';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import { bindPopper, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';

import OverflowContainer from 'components/layout/OverflowContainer';
import Button from 'components/tokens/Button';
import Tag from 'components/tokens/Tag';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';
import { isUploaded, useListsContext } from 'contexts/ListsContext';
import { ActionType, useTrigger } from 'contexts/TriggerContext';

import TriggerButton from './TriggerButton';

interface SourceProps {
    sourceValue: string;
    hasValue: boolean;
}

const SelectSourcePopper: React.FC<SourceProps> = ({ hasValue, sourceValue }) => {
    const { dbLists } = useListsContext();
    const popupState = usePopupState({ variant: 'popper', popupId: 'select-source-popper' });
    const [state, dispatch, { activePopup, setActivePopup }] = useTrigger();
    const { source = '' } = state;
    const [draftSource, setDraftSource] = useState<string | null>(source);
    const [filterByName, setFilterByName] = useState('');
    const [showErrorSnackbarMessage, setShowErrorSnackbarMessage] = useState('');
    const ref = useRef<HTMLDivElement>(null);

    const topWrapperRef = useRef<HTMLDivElement | null>(null);
    const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        setContainerEl(topWrapperRef?.current);
    }, [topWrapperRef]);

    useEffect(() => {
        if (popupState.isOpen) {
            setActivePopup(popupState.popupId);
        }
    }, [popupState, setActivePopup]);

    useEffect(() => {
        if (activePopup && popupState.popupId !== activePopup && popupState.isOpen) {
            popupState.close();
        }
    }, [activePopup, popupState]);

    useEffect(() => {
        setDraftSource(source);
    }, [source]);

    const selectList = (src: string) => {
        dispatch({ type: ActionType.SELECT_SOURCE, source: src });
    };

    const onCancel = () => {
        setFilterByName('');
        setActivePopup(undefined);
        popupState.close();
    };

    const onSave = () => {
        selectList(draftSource || '');
        setFilterByName('');
        setActivePopup(undefined);
        popupState.close();
    };

    return (
        <div ref={ref} id="trigger-source">
            <TriggerButton {...bindToggle(popupState)}>{sourceValue}</TriggerButton>
            <Popper
                {...bindPopper(popupState)}
                placement="bottom-start"
                transition
                popperOptions={{
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: true,
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 4],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={onSave}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Wrapper role="presentation">
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography color="dark">Lists:</Typography>
                                    <TextField
                                        placeholder="Search..."
                                        onChange={(e) => setFilterByName(e.target.value)}
                                        autoFocus={true}
                                        icon="Search"
                                        sx={{ width: 200 }}
                                        size="small"
                                    />
                                </Box>

                                <TopWrapper>
                                    <div ref={topWrapperRef}>
                                        <OverflowContainer
                                            mode="vertical"
                                            headerPadding={0}
                                            containerEl={containerEl}
                                        />
                                        <StyledGrid>
                                            {dbLists
                                                .filter((list) => list.type !== 'legacy')
                                                .filter(
                                                    (list) =>
                                                        !filterByName ||
                                                        (filterByName &&
                                                            (list.name || '').toLowerCase().includes(filterByName)),
                                                )
                                                .map((list) => (
                                                    <Grid
                                                        sx={{
                                                            border: '1px solid #E0E0E0',
                                                            borderRadius: '4px',
                                                            margin: '1px',
                                                            padding: '16px',
                                                        }}
                                                        onClick={() => setDraftSource(list.id)}
                                                        key={list.id}
                                                    >
                                                        <Grid
                                                            container
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <StyledFlex>
                                                                <Tag
                                                                    label={
                                                                        isUploaded(list)
                                                                            ? 'Custom list'
                                                                            : 'Dynamic list'
                                                                    }
                                                                />
                                                                {/* <DescribeList query={list.query} /> */}
                                                            </StyledFlex>

                                                            <Radio
                                                                checked={list.id === draftSource}
                                                                color="primary"
                                                                onChange={() => setDraftSource(list.id)}
                                                            />
                                                        </Grid>
                                                        <Typography
                                                            weight="bold"
                                                            sx={{ marginTop: 1, overflowWrap: 'anywhere' }}
                                                        >
                                                            {list.name}
                                                        </Typography>
                                                    </Grid>
                                                ))}
                                        </StyledGrid>
                                    </div>
                                </TopWrapper>
                                <BottomWrapper>
                                    <Grid container justifyContent="flex-end" spacing={1}>
                                        <Grid item>
                                            <Button
                                                variant="flat"
                                                onClick={() => onCancel()}
                                                sx={{ color: 'text.secondary' }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button disabled={!draftSource} onClick={() => onSave()}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </BottomWrapper>

                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    open={!!showErrorSnackbarMessage}
                                    autoHideDuration={3000}
                                    onClose={() => setShowErrorSnackbarMessage('')}
                                    message={<span>{showErrorSnackbarMessage}</span>}
                                />
                            </Wrapper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </div>
    );
};

export default SelectSourcePopper;

const StyledFlex = styled('div')`
    display: flex;
    align-items: center;
`;

const StyledGrid = styled('div')`
    height: 320px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: min-content;
    column-gap: 16px;
    row-gap: 16px;
`;

const Wrapper = styled(Paper)`
    padding: 1em 1em 1em 1em;
    margin: 0.5em 0 0 0.5em;
    width: 730px;
`;

const TopWrapper = styled('div')`
    position: relative;
    margin: 16px 0;

    & > div {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }
`;

const BottomWrapper = styled('div')`
    padding-right: 0.5em;
`;
