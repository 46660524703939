import React, { useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';

import { useSnackbarContext } from 'contexts/SnackbarContext';

import { MatchCompanyRequest } from '../../hooks/types';
import { useCompanyMatch } from '../../hooks/useCompanyMatch';
import useConnectorsApi from '../../hooks/useConnectorsApi';
import { CrmName } from '../../types/Crm';
import CompanyMatchForm, { CompanyMap } from './CompanyMatchForm';
import ManualMatchSummary from './ManualMatchSummary';
import TopBanner from './TopBanner';

interface Props {
    isAccountAdmin: boolean;
    isDomainConnector: boolean;
    crm: CrmName;
}

const COMPANIES_PER_PAGE = 10;

const ManualCompanyMatch: React.FC<Props> = ({ isAccountAdmin, isDomainConnector, crm }) => {
    const { matchCompany, getAllNonMatchProspect } = useConnectorsApi();
    const { showSnackbar } = useSnackbarContext();
    const { isLongOngoingJob, error, matchedByCountries, nonMatchedCountries, fetchMatchingResults } =
        useCompanyMatch();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        isFetchingNextPage,
        data,
        fetchNextPage,
        refetch: refetchManualMatchingCompanies,
    } = useInfiniteQuery({
        queryKey: ['manualMatchingList'],
        queryFn: ({ pageParam = 0 }) =>
            getAllNonMatchProspect({
                row_offset: pageParam,
                row_limit: COMPANIES_PER_PAGE,
                target: crm,
                sort: '-match_candidates',
                target_object: 'Account',
                ...(isDomainConnector ? { unmatched_domain: 1 } : { unmatched: 1 }),
            }).then(({ data: { data, has_more } }) => ({
                hasMore: has_more,
                pageParam: pageParam,
                data: data['-1'],
            })),
        refetchOnMount: 'always',
        staleTime: 0,
        initialPageParam: 0,
        getNextPageParam: ({ hasMore, pageParam }) => (hasMore ? pageParam + COMPANIES_PER_PAGE : pageParam),
    });

    async function matchCompanies(selectedCompanies: MatchCompanyRequest[]) {
        await Promise.all(
            selectedCompanies.map(({ target_fk, prospect_business_id }) => {
                return matchCompany({ crm_name: crm, target_fk, prospect_business_id, no_update: true });
            }),
        );
    }

    const handleSubmit = async (companiesToMatchMap: CompanyMap) => {
        if (isSubmitting) {
            return;
        }

        try {
            setIsSubmitting(true);
            const companiesToMatch = Object.entries(companiesToMatchMap).map(([target_fk, prospect]) => ({
                target_fk,
                prospect_business_id: prospect.business_id,
            }));

            await matchCompanies(companiesToMatch);

            window.analytics?.track('Connector: Matched companies manually', {
                app: crm,
                matched_companies_count: companiesToMatch.length,
            });
        } catch (e) {
            showSnackbar('😔 Oops, something went wrong, please try again or contact support', 'error');
        } finally {
            setIsSubmitting(false);
            fetchMatchingResults();
            refetchManualMatchingCompanies();
        }
    };

    const response = data?.pages[0].data;
    if (!Array.isArray(response) || response.length === 0) return null;

    return (
        <>
            <TopBanner />
            <ManualMatchSummary
                needHelpcompaniesCount={nonMatchedCountries.count}
                totalCompaniesCount={matchedByCountries.count + nonMatchedCountries.count}
            />
            {!isLongOngoingJob && (
                <CompanyMatchForm
                    item
                    editDisabled={!isAccountAdmin}
                    isLoading={isSubmitting || isFetchingNextPage}
                    hasMore={data?.pages[data.pages.length - 1].hasMore}
                    xs="auto"
                    crm={crm}
                    list={data?.pages.map(({ data: companies }) => companies).flat() ?? []}
                    error={error}
                    loadMore={debounce(fetchNextPage, 300)}
                    onSubmit={handleSubmit}
                />
            )}
        </>
    );
};

export default ManualCompanyMatch;
