import { isNotEmpty } from 'utilities';

import { useLocations, useWebProfileData, useSocialNetworks } from 'api/profileData';
import { domainProfileType } from 'api/types/Organization';
import { ProfileTab } from 'components/features/ProfileTabs';

import { domainTabs as tabs, TabSlug } from '../tabs';

/** Makes queries for all the profiles tabs and determines which tabs contain data.
 * For caching to work, the queries should be same as on the actual tabs.
 */
const useVisibleTabs = (domain: string, activeTab: TabSlug): ProfileTab[] => {
    const params = {
        key: domain,
        type: domainProfileType,
    } as const;
    const { data: webProfileData, isLoading: isLoadingWebProfileData } = useWebProfileData(params);
    const { data: locationsData, isLoading: isLoadingLocationsData } = useLocations(params);
    const { data: socialNetworksData, isLoading: isLoadingSocialNetworksData } = useSocialNetworks(params);

    return tabs
        .filter((tab) => {
            if (tab.name === activeTab) {
                return true;
            }
            switch (tab.name) {
                case TabSlug.WebProfile:
                    return isLoadingWebProfileData || isNotEmpty(webProfileData?.technology_data);
                case TabSlug.Locations:
                    return isLoadingLocationsData || isNotEmpty(locationsData?.business_units);
                case TabSlug.SocialNetworks:
                    return isLoadingSocialNetworksData || isNotEmpty(socialNetworksData?.social_media);
                default:
                    return true;
            }
        })
        .map((tab) => {
            if (tab.name === activeTab) {
                return tab;
            }
            switch (tab.name) {
                case TabSlug.WebProfile:
                    return {
                        ...tab,
                        skeleton: isLoadingWebProfileData,
                    };
                case TabSlug.Locations:
                    return {
                        ...tab,
                        skeleton: isLoadingLocationsData,
                    };
                case TabSlug.SocialNetworks:
                    return {
                        ...tab,
                        skeleton: isLoadingSocialNetworksData,
                    };
                default:
                    return tab;
            }
        });
};

export default useVisibleTabs;
