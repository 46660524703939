import React from 'react';

import { Menu, MenuItem, MenuListProps, styled } from '@mui/material';

interface BaseOption {
    disabled: boolean;
    label: string;
    value: string;
}

interface OptionsProps<T> {
    'data-testid'?: string;
    anchorEl: HTMLElement | null;
    options: T[];
    topAddon?: JSX.Element;
    itemToKey?: (option: T) => string;
    itemToString?: (option: T) => string | JSX.Element;
    onClick: (item: T) => void;
    onClose: () => void;
}

// todo: add Options to Storybook + tests
const Options = <T extends BaseOption>({
    'data-testid': dataTestId = 'menu',
    anchorEl,
    options,
    topAddon,
    itemToKey = (item) => item.value,
    itemToString = (item) => item.label,
    onClick,
    onClose,
}: OptionsProps<T>) => {
    return (
        <StyledMenu
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            MenuListProps={
                {
                    'data-testid': dataTestId,
                } as Partial<MenuListProps>
            }
        >
            {topAddon && <AddonWrapper>{topAddon}</AddonWrapper>}
            {options.length > 0 ? (
                options.map((option) => (
                    <Option
                        disableRipple
                        disabled={option.disabled}
                        key={itemToKey(option)}
                        onClick={() => {
                            // disabled option has 'pointerEvents: "auto"' to render tooltip on hover
                            if (!option.disabled) {
                                onClick(option);
                                onClose();
                            }
                        }}
                    >
                        {itemToString(option)}
                    </Option>
                ))
            ) : (
                <Option disabled>Sorry, we couldn't find that...</Option>
            )}
        </StyledMenu>
    );
};

const StyledMenu = styled(Menu)({
    '.MuiMenu-paper': {
        maxHeight: '300px',
        borderRadius: '4px',
        border: '8px solid white',

        // required to reset global style from componentOverrides.ts
        '.MuiMenuItem-root': {
            minHeight: 'initial',
        },
    },
});

const Option = styled(MenuItem)({
    display: 'block',
    width: '228px',
    height: '36px',
    lineHeight: '24px',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&.Mui-disabled': {
        pointerEvents: 'auto',
    },
});

const AddonWrapper = styled('li')(({ theme: { palette } }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 2,
    marginBottom: '8px',
    padding: 0,
    background: palette.background.paper,
}));

export { Options };
