import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const VehicleTruck: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M20.9706 12.6718H9.74388V14.782H16.5953H16.8131C17.1453 13.8729 18.0163 13.2218 19.04 13.2218C20.048 13.2218 20.91 13.8549 21.2512 14.7416C21.6733 14.6204 21.9808 14.232 21.9808 13.7718V13.682C21.9808 13.1231 21.5296 12.6718 20.9706 12.6718ZM17.3563 15.3545C17.3563 16.3265 18.1465 17.1167 19.1186 17.1167C20.0906 17.1167 20.8808 16.3265 20.8808 15.3545C20.8808 14.3824 20.0906 13.5922 19.1186 13.5922C18.1465 13.5922 17.3563 14.3824 17.3563 15.3545ZM17.8951 15.3545C17.8951 14.6788 18.4429 14.131 19.1186 14.131C19.7943 14.131 20.342 14.681 20.342 15.3545C20.342 16.028 19.7943 16.578 19.1186 16.578C18.4429 16.578 17.8951 16.028 17.8951 15.3545ZM3.57041 15.3545C3.57041 16.3265 4.36061 17.1167 5.33265 17.1167C6.30469 17.1167 7.0949 16.3265 7.0949 15.3545C7.0949 14.3824 6.30469 13.5922 5.33265 13.5922C4.36061 13.5922 3.57041 14.3824 3.57041 15.3545ZM4.10918 15.3545C4.10918 14.6788 4.65918 14.131 5.33265 14.131C6.00612 14.131 6.55612 14.681 6.55612 15.3545C6.55612 16.028 6.00612 16.578 5.33265 16.578C4.65918 16.578 4.10918 16.028 4.10918 15.3545ZM1 11.4035V14.2769C1 14.4633 1.15041 14.6137 1.33673 14.6137H3.09898C3.47163 13.7943 4.29776 13.2218 5.25633 13.2218C6.2149 13.2218 7.04102 13.7943 7.41367 14.6137H8.60122C8.78755 14.6137 8.93796 14.4633 8.93796 14.2769V7.3851C8.93796 7.19878 8.78755 7.04837 8.60122 7.04837H4.16531C3.97898 7.04837 3.69612 7.11122 3.49184 7.3851L1 11.4035ZM2.57143 10.528L4.08673 8.01367C4.20347 7.79816 4.34714 7.67694 4.86123 7.67694H7.61122C7.79755 7.67694 7.94796 7.82735 7.94796 8.01367V10.528C7.94796 10.7143 7.79755 10.8647 7.61122 10.8647H2.90816C2.72184 10.8647 2.57143 10.7143 2.57143 10.528ZM1.25816 13.2443C1.25816 13.0714 1.39959 12.93 1.57245 12.93C1.74755 12.93 1.88673 13.0714 1.88673 13.2443V13.8392C1.88673 14.012 1.74531 14.1535 1.57245 14.1535C1.39959 14.1535 1.25816 14.012 1.25816 13.8392V13.2443ZM23 12.068H9.74388V6H23V12.068Z" />
    </SvgIcon>
);

export default VehicleTruck;
