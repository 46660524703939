import React from 'react';

import { Box, alpha } from '@mui/material';
import { TooltipProps } from 'recharts';

import Typography from 'components/tokens/Typography';

export type ChartTooltipProps = TooltipProps<number, string> & { labelKey?: string };

export const ChartTooltip: React.FC<ChartTooltipProps> = ({ active, payload, formatter, labelKey = 'label' }) => {
    if (!active || payload?.[0] === undefined || payload?.[0] === null) {
        return null;
    }
    // Would be really hard to type this correctly.
    const label = payload[0].payload[labelKey];
    const total =
        payload.length > 1
            ? payload.reduce((prev, next) => (next.value == null ? 0 : prev + next.value), 0)
            : payload[0].value;
    const value = formatter && total ? formatter(total, '', {}, 0, [{}]) : total;

    if (!label) {
        return (
            <Typography
                component="div"
                variant="tiny"
                weight="semibold"
                sx={{
                    borderRadius: 1,
                    paddingLeft: 1,
                    paddingRight: 1,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                    background: (theme) => theme.palette.button.background,
                    color: (theme) => theme.palette.button.text,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {value}
            </Typography>
        );
    }
    return (
        <Typography
            component="div"
            variant="tiny"
            weight="semibold"
            sx={{
                borderRadius: 1,
                paddingLeft: 1,
                paddingRight: 1,
                background: (theme) => theme.palette.button.background,
                color: (theme) => theme.palette.button.text,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {value}
            <Box
                sx={{
                    fontWeight: 'normal',
                    borderLeft: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.button.text, 0.25),
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                    paddingLeft: 1,
                    marginLeft: 1,
                }}
            >
                {label}
            </Box>
        </Typography>
    );
};

export default ChartTooltip;
