import { CrmName } from 'components/modules/connectors/types/Crm';
import { getObjectPluralForm, getCompanyLikeObjectForCrm } from 'components/modules/connectors/utils/CRMTargetText';

export const getMatchingObjectsText = (hasLeadMatchingPermission: boolean, crm: CrmName) => {
    if (crm === 'salesforce' && hasLeadMatchingPermission) {
        return `${getObjectPluralForm(crm, getCompanyLikeObjectForCrm(crm))} and ${getObjectPluralForm(crm, 'Lead')}`;
    }

    return `${getObjectPluralForm(crm, getCompanyLikeObjectForCrm(crm))}`;
};
