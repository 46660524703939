import React from 'react';

import { FilterOperator } from 'api/types/FilterOperators';

import {
    FilterConfig,
    FilterDefinition,
    FilterID,
    FilterProps,
    GroupValues,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useChipInputFilter } from '../common/useChipInputFilter';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, makeFullPath, valueArrayToText } from '../utils';

const FIELD = 'company_keywords';

export type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.description_keywords,
    label: 'Description keywords',
    defaultOperator: FilterOperator.STARTSWITH,
    initValue: {
        [FilterOperator.STARTSWITH]: {
            [FIELD]: [],
        },
    },
};

export const descriptionKeywordsFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.web_profile,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        render: (props: FilterProps<TValues>) => <DescriptionKeywords {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const customFormatOperatorValue = (value: OperatorValue) => {
    if (value === FilterOperator.STARTSWITH) {
        return { value, label: 'is' };
    }
    return formatOperatorValue(value);
};

const operators: Operator[] = [FilterOperator.STARTSWITH, `${FilterOperator.NOT}${FilterOperator.CONTAINS}`].map(
    (value) => customFormatOperatorValue(value as OperatorValue),
);

export const DescriptionKeywords: React.FC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const props = useChipInputFilter({
        filter,
        sameFiltersState,
        uuid,
        groupUUID,
        removeFilter,
        operators,
        placeholder: 'description keywords...',
        formatOperatorValue: customFormatOperatorValue,
        valuePath: makeFullPath(filter, true),
    });

    return <FilterTemplate {...props} />;
};
