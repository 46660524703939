import { Trigger } from 'api/types/Trigger';
import { DeliveryTime, DeliveryType } from 'contexts/TriggerContext';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const getNextDeliveryLabel = (deliveryTime: DeliveryTime, notify: DeliveryType) => {
    const now = new Date();
    // first day of the week is sunday in js, convert to monday-based
    const currentDay = (now.getDay() + 6) % 7;
    const currentHour = now.getHours();
    const currentMin = now.getMinutes();

    const sortedDays = [...deliveryTime.week_days].sort();

    let deliveryDayText;
    const nextDeliveryDay =
        sortedDays.find((day) => {
            if (day === currentDay) {
                return (
                    currentHour < deliveryTime.end_hour ||
                    (currentHour === deliveryTime.end_hour && currentMin <= deliveryTime.end_min)
                );
            }
            return day > currentDay;
        }) || sortedDays[0];

    if (nextDeliveryDay === currentDay) {
        deliveryDayText = 'today';
    } else if (nextDeliveryDay === currentDay + 1) {
        deliveryDayText = 'tomorrow';
    } else {
        deliveryDayText = `on ${days[nextDeliveryDay]}`;
    }

    const startTime = `${deliveryTime.start_hour}:${minutesToString(deliveryTime.start_min)}`;

    const deliveryTimeText =
        notify === 'specific'
            ? `after ${startTime}`
            : `between ${startTime} - ${deliveryTime.end_hour}:${minutesToString(deliveryTime.end_min)}`;

    return `${deliveryDayText} ${deliveryTimeText}`;
};

const minutesToString = (value: number) => (value < 10 ? `0${value}` : value.toString());

type TriggerLike = Pick<Trigger, 'name'>;

/**
 * Generates a unique name for a trigger based on existing triggers.
 * Returns the provided name if there are no triggers with the same name prefix.
 * Otherwise, returns the name with a number suffix, ensuring uniqueness.
 * The number suffix is incremented to the next available value, ignoring any gaps in existing trigger names.
 *
 * @param triggers - An array of existing triggers.
 * @param name - The base name for the trigger. If not provided, defaults to 'Untitled trigger'.
 * @returns A unique name for the trigger.
 */
export function getTriggerName(triggers: TriggerLike[], name?: string | null | undefined) {
    const triggerName = name || 'Untitled trigger';
    const prefix = `${triggerName} `;
    const startingLength = prefix.length;
    let nextNum = 1;
    triggers.forEach((trigger) => {
        if (trigger.name.startsWith(triggerName)) {
            const numPart = trigger.name.slice(startingLength);
            const num = parseInt(numPart, 10);

            if (!isNaN(num)) {
                nextNum = Math.max(nextNum, num + 1);
            } else {
                nextNum = 2;
            }
        }
    });

    if (nextNum === 1) {
        return triggerName;
    }

    return `${prefix}${nextNum}`;
}

export const TRIGGER_NEW_COMPANY = 'new_company';
