import React from 'react';

import { styled } from '@mui/material';

import { Typography } from 'components/tokens/Typography';

type LandingContentProps = {
    title: string;
    children: React.ReactNode;
};

const LandingContent: React.FC<LandingContentProps> = ({ title, children }) => (
    <Container>
        <Typography variant="h2" fontSize={20} weight="semibold">
            {title}
        </Typography>
        {children}
    </Container>
);

const Container = styled('div')`
    position: inherit;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    margin-top: 24px;
`;

export { LandingContent };
