export const industryIconsSquare = {
    '00': 'img/industry-icons-square-80/unknown-industry-1.png',
    '01': 'img/industry-icons-square-80/wood.png',
    '02': 'img/industry-icons-square-80/forestry.png',
    '03': 'img/industry-icons-square-80/fishing.png',
    '04': 'img/industry-icons-square-80/fishing.png',
    '05': 'img/industry-icons-square-80/mining.png',
    '06': 'img/industry-icons-square-80/natural-gas.png',
    '07': 'img/industry-icons-square-80/mining.png',
    O8: 'img/industry-icons-square-80/mining.png',
    '09': 'img/industry-icons-square-80/mining.png',
    10: 'img/industry-icons-square-80/food.png',
    11: 'img/industry-icons-square-80/beverages.png',
    12: 'img/industry-icons-square-80/tobacco.png',
    13: 'img/industry-icons-square-80/textiles.png',
    14: 'img/industry-icons-square-80/textiles.png',
    15: 'img/industry-icons-square-80/textiles.png',
    16: 'img/industry-icons-square-80/wood.png',
    17: 'img/industry-icons-square-80/wood.png',
    18: 'img/industry-icons-square-80/recorded-media.png',
    19: 'img/industry-icons-square-80/manufacturing.png',
    20: 'img/industry-icons-square-80/scientific-chemicals.png',
    21: 'img/industry-icons-square-80/pharmaceutical.png',
    22: 'img/industry-icons-square-80/rubber.png',
    23: 'img/industry-icons-square-80/minerals.png',
    24: 'img/industry-icons-square-80/metal.png',
    25: 'img/industry-icons-square-80/metal.png',
    26: 'img/industry-icons-square-80/manufacturing.png',
    27: 'img/industry-icons-square-80/electrical.png',
    28: 'img/industry-icons-square-80/machinery-construction.png',
    29: 'img/industry-icons-square-80/vehicles.png',
    30: 'img/industry-icons-square-80/transport.png',
    31: 'img/industry-icons-square-80/manufacturing.png',
    32: 'img/industry-icons-square-80/manufacturing.png',
    33: 'img/industry-icons-square-80/repair.png',
    34: 'img/industry-icons-square-80/repair.png',
    35: 'img/industry-icons-square-80/water-supply.png',
    36: 'img/industry-icons-square-80/sewerage.png',
    37: 'img/industry-icons-square-80/recycle.png',
    38: 'img/industry-icons-square-80/recycle.png',
    39: 'img/industry-icons-square-80/machinery-construction.png',
    40: 'img/industry-icons-square-80/engineering.png',
    41: 'img/industry-icons-square-80/engineering.png',
    42: 'img/industry-icons-square-80/machinery-construction.png',
    43: 'img/industry-icons-square-80/repair.png',
    44: 'img/industry-icons-square-80/repair.png',
    45: 'img/industry-icons-square-80/wholesale-trade.png',
    46: 'img/industry-icons-square-80/wholesale-trade.png',
    47: 'img/industry-icons-square-80/wholesale-trade.png',
    48: 'img/industry-icons-square-80/wholesale-trade.png',
    49: 'img/industry-icons-square-80/transport.png',
    50: 'img/industry-icons-square-80/transport-water.png',
    51: 'img/industry-icons-square-80/transport-air.png',
    52: 'img/industry-icons-square-80/warehousing.png',
    53: 'img/industry-icons-square-80/postal.png',
    54: 'img/industry-icons-square-80/postal.png',
    55: 'img/industry-icons-square-80/accommondation.png',
    56: 'img/industry-icons-square-80/food.png',
    57: 'img/industry-icons-square-80/food.png',
    58: 'img/industry-icons-square-80/publishing.png',
    59: 'img/industry-icons-square-80/recorded-media.png',
    60: 'img/industry-icons-square-80/telecommunications.png',
    61: 'img/industry-icons-square-80/telecommunications.png',
    62: 'img/industry-icons-square-80/programming.png',
    63: 'img/industry-icons-square-80/information-service.png',
    64: 'img/industry-icons-square-80/financial-service.png',
    65: 'img/industry-icons-square-80/insurance.png',
    66: 'img/industry-icons-square-80/financial-service.png',
    68: 'img/industry-icons-square-80/real-estate.png',
    69: 'img/industry-icons-square-80/legal.png',
    70: 'img/industry-icons-square-80/management.png',
    71: 'img/industry-icons-square-80/architectural.png',
    72: 'img/industry-icons-square-80/scientific-chemicals.png',
    73: 'img/industry-icons-square-80/advertising.png',
    74: 'img/industry-icons-square-80/scientific-chemicals.png',
    75: 'img/industry-icons-square-80/vetenary.png',
    76: 'img/industry-icons-square-80/vetenary.png',
    77: 'img/industry-icons-square-80/rental.png',
    78: 'img/industry-icons-square-80/employment.png',
    79: 'img/industry-icons-square-80/travel.png',
    80: 'img/industry-icons-square-80/security.png',
    81: 'img/industry-icons-square-80/construction-2.png',
    82: 'img/industry-icons-square-80/employment.png',
    83: 'img/industry-icons-square-80/employment.png',
    84: 'img/industry-icons-square-80/security-2.png',
    85: 'img/industry-icons-square-80/education.png',
    86: 'img/industry-icons-square-80/health.png',
    87: 'img/industry-icons-square-80/residential-care.png',
    88: 'img/industry-icons-square-80/social-work.png',
    89: 'img/industry-icons-square-80/social-work.png',
    90: 'img/industry-icons-square-80/culture.png',
    91: 'img/industry-icons-square-80/culture.png',
    92: 'img/industry-icons-square-80/gambling.png',
    93: 'img/industry-icons-square-80/sports-2.png',
    94: 'img/industry-icons-square-80/social-work.png',
    95: 'img/industry-icons-square-80/repair.png',
    96: 'img/industry-icons-square-80/unknown-industry-1.png',
    97: 'img/industry-icons-square-80/unknown-industry-2.png',
    98: 'img/industry-icons-square-80/unknown-industry-3.png',
    99: 'img/industry-icons-square-80/unknown-industry-4.png',
};

// Rest of the url is randomised
export const unknownIndustrySquare = 'img/industry-icons-square-80/unknown-industry-';
