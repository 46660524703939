import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { FilterCompaniesResponse, filterCompanies } from 'api/filterCompanies';
import { useSimilarCompanies } from 'api/similarCompanies';
import { ProfileType, domainProfileType, organizationProfileType } from 'api/types/Organization';
import { useAxiosContext } from 'contexts/AxiosContext';
import { SortId } from 'contexts/SortCompaniesContext/SortCompaniesContext';
import {
    DomainDataResult,
    DomainDataResultAPIResponse,
    OrganizationResult,
    OrganizationResultAPIResponse,
    commonFields,
    domainDataFields,
} from 'contexts/types/FilterCompanies';

import { Cluster } from '../types';

export const pageSize = 20;
export const maxPages = 20;

export const getCompanies = (
    axios: AxiosInstance,
    ids: string[],
    type: ProfileType,
    order: SortId = '-vainu_score',
) => {
    const fields = [...commonFields, ...domainDataFields];
    const database = 'DOMAIN_DATA_BASIC';
    const query = {
        '?IN': {
            domain: ids,
        },
    };
    return filterCompanies(axios, {
        database,
        limit: pageSize,
        offset: 0,
        query,
        order,
        fields,
        use_filters_v2: 'true',
    });
};

const getPage = <T>(array: T[] = [], page: number, pageSize: number) => {
    const offset = (page - 1) * pageSize;
    return array.slice(offset, offset + pageSize);
};

// Note: the actual type should be DomainDataResult[] | OrganizationResult[],
// but TypeScript versions < 5.2.2 don't work well with those unions.
// E.g. (A[] | B[]).find() won't work, the type must be (A | B)[].
export type ClusterCompanies = (DomainDataResult | OrganizationResult)[];

export const useClusterCompanies = (
    cluster: Cluster | undefined,
    page: number,
    type: ProfileType,
    options?: Omit<
        UseQueryOptions<FilterCompaniesResponse, unknown, ClusterCompanies>,
        'queryKey' | 'queryFn' | 'select'
    >,
) => {
    const axios = useAxiosContext();
    const ids = getPage(cluster?.companies, page, pageSize).map(({ id }) => id);
    // TODO: Replace sorting later with default -vainu_score, after first fetching vainu_score for
    // the whole clustering data. Without prefetching it, the sorting jumps after receiving the data.
    return useQuery<FilterCompaniesResponse, unknown, ClusterCompanies>({
        enabled: !!cluster,
        ...options,
        queryKey: ['cluster-companies', cluster?.id, page],
        queryFn: () => getCompanies(axios, ids, type, 'none'),
        select: (data) =>
            type === domainProfileType
                ? (data as DomainDataResultAPIResponse[]).map((company) => ({
                      ...company,
                      id: company.website,
                      profileType: domainProfileType,
                  }))
                : (data as OrganizationResultAPIResponse[]).map((company) => ({
                      ...company,
                      id: company.business_id,
                      profileType: organizationProfileType,
                  })),
    });
};

export const useClusterSimilarCompanies = (cluster: Cluster | undefined /*, page: number, type: ProfileType */) => {
    const axios = useAxiosContext();
    const ids = cluster?.companies.map(({ id }) => id) ?? [];
    const database = 'DOMAIN_DATA_BASIC';
    const query = {
        '?IN': {
            domain: ids,
        },
    };
    return useSimilarCompanies(axios, database, query, undefined, undefined, { enabled: !!cluster });
};
