import PieChartTile from 'components/features/CRMInsights/Tiles/PieChartTile';

import { TileProps } from '../NewVehicles';

const OwnershipTypeTile: React.FC<TileProps> = ({ data, valueRange, valueField }) => {
    const ownershipTypeDataRow = data?.find((row) => row.id === 'ownership_type');
    const ownershipTypeData = ownershipTypeDataRow && {
        default: {
            label: 'Ownership type',
            displayLabel: 'Ownership type',
            data: ownershipTypeDataRow.result[0].buckets.map((bucket) => ({
                value: bucket[valueField] as number,
                label: bucket.value as string,
                valueLabel: valueRange ? `${bucket.count_min}-${bucket.count_max}` : (bucket[valueField] as string),
            })),
        },
    };

    return (
        <PieChartTile
            title="Ownership type"
            data={
                ownershipTypeData || {
                    skeleton: {
                        label: 'skeleton',
                        data: new Array(3).fill({
                            value: undefined,
                            label: undefined,
                        }),
                    },
                }
            }
            total={ownershipTypeData?.default.data.reduce((prev, next) => prev + (next.value as number), 0) || 0}
            sx={{ minWidth: 390, maxWidth: 650 }}
        />
    );
};

export default OwnershipTypeTile;
