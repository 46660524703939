import { useCallback, useEffect } from 'react';

import { cloneDeep, isArray, isEmpty, set } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { getFiltersFromGroup } from 'components/features/Filters/filters/utils';
import { useFilterState } from 'contexts/FilterContext';
import { getFirstKey, getObjectKeys } from 'utilities/objectUtils';

import { filterDefinitions } from '../../../filters/filterDefinitions';
import { FilterID, GroupOption, NodeValue, RawGroup } from '../../../FilterTypes';
import { useFilterGroupOptions } from '../../common/groupUtils';
import { useGroupActions, UseGroupActionsProps, useGroupNodes, UseGroupProps } from '../../common/useGroup';
import {
    getFilteredResults,
    useVainuCustomIndustryGroupActions,
} from '../VainuCustomIndustryGroup/useVainuCustomIndustryGroup';
import { isVainuCustomIndustryFilter } from '../VainuCustomIndustryGroup/utils';

interface UseIndustryGroupProps extends UseGroupProps {
    options: GroupOption[];
}
export const useIndustryGroup = ({
    applyChanges,
    removeGroup,
    addNewGroup,
    updateGroup,
    createNewGroup,
    updateGroups,
    ids,
    options,
}: Required<UseIndustryGroupProps>) => {
    const filterGroups = useGroupNodes(ids);
    const { activeFilters, filteredOptions } = useFilterGroupOptions({ filterGroups, options });

    const { handleAddFilter, handleFilterRemove, handleApplyChanges, handleFilterRemoveVCI, keys } =
        useIndustryGroupActions({
            filterGroups,
            applyChanges,
            removeGroup,
            addNewGroup,
            updateGroup,
            updateGroups,
            createNewGroup,
            options: filteredOptions,
        });

    return {
        filterGroups,
        handleAddFilter,
        handleFilterRemove,
        handleFilterRemoveVCI,
        keys,
        handleApplyChanges,
        activeFilters,
        filteredOptions,
    };
};

export const useIndustryGroupActions = ({
    applyChanges,
    removeGroup,
    addNewGroup,
    updateGroup,
    createNewGroup,
    updateGroups,
    filterGroups,
    options,
}: Required<Omit<UseGroupActionsProps, 'fieldValueValidator'>>) => {
    const { getValues } = useFormContext();
    const ids = getObjectKeys(filterGroups);

    const { handleAddFilter, handleFilterRemove, keys, updateKeys } = useGroupActions(
        {
            applyChanges,
            removeGroup,
            addNewGroup,
            updateGroup,
            createNewGroup,
            updateGroups,
            filterGroups,
            options,
        },
        { ignoreCurrentFilter: true },
    );

    const { handleAddFilter: handleAddFilterVCI, handleFilterRemove: handleFilterRemoveVCI } =
        useVainuCustomIndustryGroupActions({
            removeGroup,
            addNewGroup,
        });

    const handleAddFilterOverride = useCallback(
        (filterId: FilterID) => {
            const definition = filterDefinitions[filterId];

            if (definition?.id === FilterID.vainu_custom_industry) {
                handleAddFilterVCI();
                updateKeys(true);
            } else {
                handleAddFilter(filterId);
            }
        },
        [handleAddFilter, updateKeys, handleAddFilterVCI],
    );

    const { activeFilter, setActiveFilter } = useFilterState();

    useEffect(() => {
        if (activeFilter) {
            handleAddFilterOverride(activeFilter);
            setActiveFilter(undefined);
        }
    }, [activeFilter, handleAddFilterOverride, setActiveFilter]);

    function handleFilterRemoveVCIOverride(groupId: string) {
        handleFilterRemoveVCI(groupId);
        updateKeys(true);
    }

    // TODO: move common parts to groupUtils
    const handleApplyChanges = () => {
        const updatedGroups: Record<string, NodeValue | undefined> = {};
        ids.forEach((id) => {
            const group = filterGroups[id];
            if (isVainuCustomIndustryFilter(group)) {
                const filteredResult = getFilteredResults(group);
                if (!filteredResult?.values.length) {
                    updatedGroups[id] = undefined;
                }
                return;
            }
            const value = getValues(id);
            const filters = getFiltersFromGroup({ value, path: id } as RawGroup);

            const filteredIds: number[] = [];
            filters.forEach((r, index) => {
                const nextOperator = getFirstKey(r.value);

                if (isEmpty(r.value[nextOperator as keyof NodeValue])) {
                    filteredIds.push(index);
                }
            });

            if (filteredIds.length === 0) {
                updateKeys(true);
                applyChanges();
                return;
            }

            if (filteredIds.length < filters.length) {
                const values: NodeValue[] = getValues(group.path);

                if (isArray(values)) {
                    const updatedArray = values.filter((_, i) => {
                        return !filteredIds.includes(i);
                    });
                    const updatedValue = cloneDeep(value);
                    set(updatedValue, group.nodePath, updatedArray);
                    updatedGroups[id] = updatedValue;
                }
            } else {
                updatedGroups[id] = undefined;
            }
        });

        updateGroups?.(updatedGroups);
        updateKeys(true);
        applyChanges();
    };

    return {
        handleAddFilter: handleAddFilterOverride,
        handleFilterRemove,
        handleFilterRemoveVCI: handleFilterRemoveVCIOverride,
        keys,
        handleApplyChanges,
    };
};
