import React from 'react';

import { styled, darken, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import {
    XAxis,
    YAxis,
    Bar,
    Rectangle,
    BarChart as RechartsBarChart,
    LabelList,
    Tooltip,
    CartesianGrid,
} from 'recharts';

import { ChartDataPoint, isYearData } from '..';
import { ChartTooltip, formatTooltipValue } from '../ChartTooltip';
import ResponsiveContainer from '../utils/ResponsiveContainer';

export type ChartNumberStyle = 'decimal' | 'percent' | 'currency';

export type HorizontalBarChartProps = {
    data: ChartDataPoint[];
    numberStyle?: ChartNumberStyle;
    currency?: string;
    highlightLargest?: boolean;
    order?: 'default' | 'descending' | 'ascending';
    max?: number;
    cutFromStart?: boolean;
    labelWidth?: number;
    height?: number;
};

function findLargestValue(data: ChartDataPoint[]) {
    return data.reduce<number | undefined>((acc, { value }) => {
        if (acc !== undefined) {
            if (value !== undefined) {
                return Math.max(acc, value);
            }
            return acc;
        }
        if (value !== undefined) {
            return value;
        }
        return undefined;
    }, undefined);
}

const sortAscending = (a: ChartDataPoint, b: ChartDataPoint) =>
    a.value ? (b.value ? a.value - b.value : a.value) : b.value ? b.value : 0;
const sortDescending = (a: ChartDataPoint, b: ChartDataPoint) =>
    a.value ? (b.value ? b.value - a.value : a.value) : b.value ? b.value : 0;

const StyledRectangle = styled(Rectangle)(({ fill }) => ({
    transition: 'all 200ms ease',
    '&:hover': {
        fill: fill ? darken(fill, 0.2) : undefined,
    },
}));

export const HorizontalBarChart: React.VFC<HorizontalBarChartProps> = ({
    data,
    numberStyle = 'decimal',
    currency,
    highlightLargest,
    order = 'default',
    max,
    cutFromStart = false,
    labelWidth = 96,
    height = 180,
}) => {
    const theme = useTheme();
    const intl = useIntl();

    if (data.length === 0) {
        return <div style={{ height }} />;
    }

    const largest = findLargestValue(data);

    const defaultColor = theme.palette.chart.default;
    const commonColor = theme.palette.chart.grey1;
    const highlightColor = theme.palette.chart.default;

    const xLabelKey = isYearData(data[0]) ? 'year' : 'label';

    const sortedData = data.slice();
    if (order !== 'default') {
        sortedData.sort(order === 'descending' ? sortDescending : sortAscending);
    }
    if (max) {
        if (cutFromStart) {
            sortedData.splice(0, sortedData.length - max);
        } else {
            sortedData.splice(max);
        }
    }

    const allPositive = !data.some((point) => point.value !== undefined && point.value < 0);
    const tickCount = allPositive ? 4 : 3;

    // Note: Recharts insists that a chart that renders horizontal bars is a vertical layout.
    return (
        <div>
            <ResponsiveContainer debounce={300} width="100%" height={height}>
                <RechartsBarChart data={sortedData} layout="vertical" barCategoryGap={40}>
                    <YAxis
                        type="category"
                        dataKey={xLabelKey}
                        width={labelWidth}
                        interval={0}
                        axisLine={false}
                        tickLine={false}
                        tick={{ ...theme.typography.chartXAxisLabel }}
                        tickFormatter={(value, index) => (data[index].value === undefined ? '' : value)}
                    />
                    <CartesianGrid horizontal={false} stroke={theme.palette.chart.axis} />
                    <XAxis
                        type="number"
                        dataKey="value"
                        axisLine={false}
                        tickLine={false}
                        tick={{ ...theme.typography.chartYAxisLabel }}
                        tickCount={tickCount}
                    />
                    <Bar
                        type="linear"
                        dataKey="value"
                        barSize={9}
                        radius={[0, 10, 10, 0]}
                        fill={defaultColor}
                        isAnimationActive={false}
                        shape={
                            largest && highlightLargest
                                ? ({ fill, value, ...props }) => (
                                      <StyledRectangle
                                          fill={value >= largest ? highlightColor : commonColor}
                                          value={value}
                                          {...props}
                                      />
                                  )
                                : undefined
                        }
                    >
                        <LabelList position="right" style={{ ...theme.typography.chartValueLabel }} />
                    </Bar>
                    <Tooltip
                        cursor={false}
                        content={<ChartTooltip labelKey={xLabelKey} />}
                        formatter={formatTooltipValue(numberStyle, currency, numberStyle === 'percent' ? 2 : 0, intl)}
                        isAnimationActive={false}
                    />
                </RechartsBarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default HorizontalBarChart;
