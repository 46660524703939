import { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';

import ErrorHandler from 'components/beta/Platform/Core/ErrorHandler';
import Filters from 'components/features/Filters';
import CompanyProfilePopup from 'components/layout/CompanyProfilePopup';
import ExportedProfilesDialog from 'components/templates/CompanyCardCRM/ExportedProfilesDialog';
import { DialogController } from 'contexts/DialogContext/DialogController';
import { useListsContext } from 'contexts/ListsContext';

import ProfileSelector from '../Profile';
import { Fallback } from './Fallback';
import FilterResultsClusterProvider from './FilterResultsClusterProvider';
import { ResultsViewTopSection } from './ResultsViewTopSection';

export const FilterResultsView = () => {
    const { selectedList, dbLists, modifyList, doListAction } = useListsContext();

    /**
     * `openedProfileId` should have type of `OpenedProfileFromSearchResult`
     * (needs to be renamed to be generic) when Organization profiles are available
     */
    const [openedProfileId, setOpenedProfileId] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState('');
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setErrorMessage('');
    }, [selectedList]);

    if (dbLists.length === 0 || selectedList === undefined) {
        return null;
    }

    const handleTargetNameChange = (newName: string) => {
        const isTargetNameExists = dbLists.some((list) => list.name === newName);
        if (isTargetNameExists) {
            setErrorMessage("Please, choose a name that doesn't exist in your lists yet.");
        } else {
            modifyList({ id: selectedList.id, name: newName }, selectedList.type);
            setErrorMessage('');
        }
    };

    return (
        <div>
            <ErrorHandler fallback={<Fallback />}>
                <ResultsViewTopSection
                    list={selectedList}
                    errorMessage={errorMessage}
                    onTargetNameChange={handleTargetNameChange}
                    onListMenuAction={doListAction}
                />
                <Box sx={{ marginTop: 1, marginBottom: 3 }}>
                    <Filters />
                </Box>
                <FilterResultsClusterProvider />
                {openedProfileId !== undefined ? (
                    <CompanyProfilePopup
                        scrollRef={scrollRef}
                        open={openedProfileId !== undefined}
                        onClose={() => setOpenedProfileId(undefined)}
                    >
                        <ProfileSelector scrollRef={scrollRef} type="domain" id={openedProfileId} />
                    </CompanyProfilePopup>
                ) : null}
                <DialogController type="CRM_EXPORTED_PROFILES" component={ExportedProfilesDialog} />
            </ErrorHandler>
        </div>
    );
};

export default FilterResultsView;
