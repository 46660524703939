import React, { useRef, useEffect } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid, areEqual } from 'react-window';

import { CompanyPreview, LeadPreview } from 'contexts/TriggerContext';
import useDebounce from 'hooks/useDebounce';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import { ScrollContainerOuter } from '../ScrollContainerOuter';
import LeadCard, { LeadCardSkeleton } from './LeadCard';

const GUTTER = 40;

interface Props {
    data: LeadPreview[];
    isFetching: boolean;
    selectCompany: (id: string, company: CompanyPreview) => void;
    selectedRowIds: Record<string, boolean>;
    setSelectedRowIds: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    fetchMore: () => void;
    scrollRef?: React.RefObject<HTMLElement>;
}

const TriggerGrid: React.FC<Props> = ({
    data,
    isFetching,
    selectCompany,
    selectedRowIds,
    setSelectedRowIds,
    fetchMore,
    scrollRef,
}) => {
    const isHugeScreen = useMediaQuery('(min-width: 1800px)');
    const isMedScreen = useMediaQuery('(min-width: 1440px)');
    const loadMoreButtonRef = useRef(null);
    const shouldLoadMore = useIntersectionObserver({ target: loadMoreButtonRef, threshold: 0.1 });

    useEffect(() => {
        if (!isFetching && shouldLoadMore) {
            fetchMore();
        }
    }, [fetchMore, shouldLoadMore, isFetching]);

    const showLoadingIndicator = useDebounce(isFetching, 200);

    const validItems = data.filter((lead) => lead.isValid);
    const columnCount = isHugeScreen ? 4 : isMedScreen ? 3 : 2;
    const itemData = {
        items: validItems,
        selectedRowIds,
        setSelectedRowIds,
        selectCompany,
        isFetching: showLoadingIndicator,
        columnCount,
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <AutoSizer disableHeight style={{ minWidth: '100%', marginLeft: `${-GUTTER}px` }}>
                {({ width = 0 }) => {
                    const w = width + GUTTER;
                    return (
                        <FixedSizeGrid
                            columnCount={columnCount}
                            columnWidth={w / columnCount}
                            height={scrollRef?.current?.offsetHeight || 100}
                            rowCount={Math.ceil(validItems.length / columnCount) + (showLoadingIndicator ? 1 : 0)}
                            rowHeight={450}
                            width={w}
                            outerElementType={ScrollContainerOuter}
                            itemData={itemData}
                        >
                            {Cell}
                        </FixedSizeGrid>
                    );
                }}
            </AutoSizer>

            <div
                ref={loadMoreButtonRef}
                style={{ position: 'absolute', bottom: 0, color: 'transparent', height: 1000 }}
            >
                .
            </div>
        </Box>
    );
};

export default TriggerGrid;

type CellProps = {
    data: {
        items: LeadPreview[];
        selectCompany: (id: string, company: CompanyPreview) => void;
        selectedRowIds: Record<string, boolean>;
        setSelectedRowIds: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
        isFetching: boolean;
        columnCount: number;
    };
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
};

const Cell = React.memo(({ data, columnIndex, rowIndex, style }: CellProps) => {
    const lead = data.items[rowIndex * data.columnCount + columnIndex];

    if (!lead && !data.isFetching) {
        return null;
    }

    return (
        <Box
            sx={{
                ...style,
                left: (style.left as number) + GUTTER,
                top: (style.top as number) + GUTTER,
                width: (style.width as number) - GUTTER,
                height: (style.height as number) - GUTTER,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {lead ? (
                <LeadCard
                    lead={lead}
                    selectedRowIds={data.selectedRowIds}
                    setSelectedRowIds={data.setSelectedRowIds}
                    selectCompany={data.selectCompany}
                />
            ) : (
                <LeadCardSkeleton />
            )}
        </Box>
    );
}, areEqual);
