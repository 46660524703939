enum CombineFilterOperator {
    ALL = '?ALL',
    ANY = '?ANY',
}

enum MatchFilterOperator {
    MATCH = '?MATCH',
}

enum ExcludeFilterOperator {
    NOT = '?NOT',
}

enum SingleFilterOperator {
    GTE = '?GTE',
    LTE = '?LTE',
    GT = '?GT',
    LT = '?LT',
    IN = '?IN',
    IEXACT = '?IEXACT',
    EQ = '?EQ',
    RANGE = '?RANGE',
    YEAR = '?YEAR',
    CONTAINS = '?CONTAINS',
    ICONTAINS = '?ICONTAINS',
    STARTSWITH = '?STARTSWITH',
    ENDSWITH = '?ENDSWITH',
    GEO_WITHIN = '?GEO_WITHIN',
    GEO_WITHIN_BOX = '?GEO_WITHIN_BOX',
    GEO_WITHIN_SPHERE = '?GEO_WITHIN_SPHERE',
    EXISTS = '?EXISTS',
}

export const FilterOperator = {
    ...SingleFilterOperator,
    ...CombineFilterOperator,
    ...ExcludeFilterOperator,
    ...MatchFilterOperator,
};

type FilterOperatorValue = (typeof FilterOperator)[keyof typeof FilterOperator];
export function isFilterOperatorValue(value: string): value is FilterOperatorValue {
    if (Object.values(FilterOperator).includes(value as FilterOperatorValue)) {
        return true;
    } else {
        return false;
    }
}

export type ValueOfSingleFilterOperator = `${SingleFilterOperator}`;
export type ValueOfCombineFilterOperator = `${CombineFilterOperator}`;
export type ValueOfMatchFilterOperator = `${MatchFilterOperator}`;
export type ValueOfExcludeFilterOperator = `${ExcludeFilterOperator}`;
export type ValueOfAllOperators =
    | ValueOfSingleFilterOperator
    | ValueOfCombineFilterOperator
    | ValueOfMatchFilterOperator
    | ValueOfExcludeFilterOperator;

export type ValueOfCombinedExcludeOperators = `${ValueOfExcludeFilterOperator}${ValueOfSingleFilterOperator}`;

export type LongitudeLatitudeTuple = [number, number];

export type GeoWithinSphereValue = {
    '#coordinates': LongitudeLatitudeTuple;
    '#radius': number;
};

export type GeoWithinValue = LongitudeLatitudeTuple[];

export type GeoWithinBoxValue = [LongitudeLatitudeTuple, LongitudeLatitudeTuple];

export type GeoOperatorValue = GeoWithinSphereValue | GeoWithinValue | GeoWithinBoxValue;

export type OperationValue =
    | null
    | string
    | number
    | string[]
    | number[]
    | [number | string | null, number | string | null]
    | GeoWithinSphereValue
    | GeoWithinValue
    | GeoWithinBoxValue
    | boolean;

export type OperationDictionary = Record<string, OperationValue>;

export type SingleOperation = Partial<Record<ValueOfSingleFilterOperator, OperationDictionary>>;
export type ExcludeOperation = Record<ValueOfExcludeFilterOperator, SingleOperation>;
export type CombineOperation = Partial<
    Record<
        ValueOfCombineFilterOperator,
        Array<SingleOperation | ExcludeOperation | CombineOperation | MatchOperation | ExcludeMatchOperation>
    >
>;
export type MatchOperation = Record<ValueOfMatchFilterOperator, Record<string, CombineOperation>>;

export type ExcludeMatchOperation = Record<ValueOfExcludeFilterOperator, MatchOperation>;
export type Operation = SingleOperation | CombineOperation | ExcludeOperation | MatchOperation | ExcludeMatchOperation;

export function isExcludeMatchOperation(operation: Operation): operation is ExcludeMatchOperation {
    return (
        operation instanceof Object &&
        FilterOperator.NOT in operation &&
        FilterOperator.MATCH in operation[FilterOperator.NOT]
    );
}

export function isMatchOperation(operation: Operation): operation is ExcludeMatchOperation {
    return FilterOperator.MATCH in operation;
}
