import React, { useMemo } from 'react';

import { isNil, isObject } from 'lodash';
import { Controller, ControllerRenderProps, get, set, useFormContext, useWatch } from 'react-hook-form';

import { FilterOperator, ValueOfCombineFilterOperator } from 'api/types/FilterOperators';
import { SelectOption } from 'components/tokens/select-components/Select';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';
import { getFirstKey, getObjectKeys } from 'utilities/objectUtils';
import { Mutable } from 'utilities/types';

import { mergeKeys } from '../../common/utils';
import {
    CombinedNodeValue,
    FilterConfig,
    FilterID,
    FilterNodeValue,
    FilterProps,
    GroupValues,
    isMatchNode,
    isMatchNodeWithExclude,
    MappedFilter,
    MatchNodeWithExclude,
    MatchValue,
    NodeValue,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, getNodesByKey } from '../utils';
import { Score, SelectWithScore, useSelectedScore } from './components/SelectWithScore';
import { initValue } from './initValue';
import { SelectWithScoreDataSet, VainuCustomIndustryFilterDefinition, VainuCustomIndustryValues } from './types';
import { useVciHistogramData } from './useCustomIndustryScores';
import {
    convertToInternalRange,
    FIXED_HISTOGRAM_SIZE,
    getFixedHistogramData,
    scaleHistogramData,
    useMode,
} from './utils';

const REQUIRED_FIELDS = ['fact_vid'] as const;
const OPTIONAL_FIELDS = ['confidence_class', 'confidence_score'] as const;
const FIELDS = [...REQUIRED_FIELDS, ...OPTIONAL_FIELDS] as const;

export type IndustryFilterStateValue = {
    [Property in (typeof REQUIRED_FIELDS)[number]]: {
        value: number;
        operator: OperatorValue;
    };
} & {
    [Property in (typeof OPTIONAL_FIELDS)[number]]?: {
        value: Property extends (typeof FIELDS)[1] ? number | undefined : number[] | undefined;
        operator: OperatorValue;
    };
};

type TValues = VainuCustomIndustryValues;

export type MatchNode = MatchNodeWithExclude<TValues>;

type OnChangeHandler = ControllerRenderProps['onChange'];

const config: FilterConfig<Partial<TValues>> = {
    fields: FIELDS as Mutable<typeof FIELDS>,
    id: FilterID.vainu_custom_industry,
    label: 'Vainu Custom Industries',
    defaultOperator: FilterOperator.EQ,
    initValue,
};

const getOperatorFromFields = (fields: NodeValue[] | undefined): OperatorValue => {
    if (!fields?.length) {
        return FilterOperator.EQ;
    }

    const operator = getFirstKey(fields[0]);
    return operator === FilterOperator.NOT ? `${FilterOperator.NOT}${FilterOperator.EQ}` : FilterOperator.EQ;
};

export const vainuCustomIndustryFilterDefinition: VainuCustomIndustryFilterDefinition = {
    [config.id]: {
        id: config.id,
        group: GroupValues.vainu_custom_industry,
        label: config.label,
        fields: config.fields,
        render: (props: FilterProps<TValues>) => <VainuCustomIndustry {...props} />,
        valueToText: valueToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
        getOperator: (filterValue: CombinedNodeValue<TValues>) => {
            const arrayOperator = getFirstKey<ValueOfCombineFilterOperator>(filterValue);
            const values = filterValue[arrayOperator];
            return getOperatorFromFields(values);
        },
        filterInvalidValues: filterInvalidValues,
    },
};

const operators: Operator[] = [FilterOperator.EQ, `${FilterOperator.NOT}${FilterOperator.EQ}`].map((value) => {
    return formatOperatorValue(value as OperatorValue);
});

type Keys = Record<number, { index: number } & KeyValue>;
type KeyValue = { scores?: string; industry?: string; class?: string };

export const VainuCustomIndustry: React.VFC<FilterProps<TValues>> = ({
    filter: filterRaw,
    uuid,
    groupUUID,
    sameFiltersState = [],
    clearFilter,
    removeFilter,
}) => {
    const filter = filterRaw as unknown as MappedFilter;
    const { mode, onModeChange } = useMode();
    const { control, setValue } = useFormContext();

    const fullPath = mergeKeys(filter.path, getFirstKey<string>(filter.value));
    const fields: MatchNode[] = useWatch({ name: fullPath });

    const operator = getOperatorFromFields(fields);

    const {
        values,
        scores: initialScores,
        keys,
    } = useMemo(() => {
        return parseValues(fields);
    }, [fields]);

    const { VCIOptions } = useFilterOptionsContext();

    const getSelectOptionsToDisable = (duplicateStates: CombinedNodeValue<TValues>[]) => {
        const options = duplicateStates?.flatMap((duplicateState) => {
            const result = parseValues(duplicateState[FilterOperator.ANY] as MatchNode[]);

            return result.values.map((v) => ({ value: v }));
        });

        return options;
    };

    const optionsToDisable = getSelectOptionsToDisable(sameFiltersState as CombinedNodeValue<TValues>[]);

    const selectedOptions = VCIOptions.filter((item) => values.includes(item.value as number));

    const histogramValues = useVciHistogramData(values);

    const convertedData = useMemo(() => {
        return histogramValues.reduce<SelectWithScoreDataSet>((result, scoreData) => {
            if (!scoreData) {
                return result;
            }
            return {
                ...result,
                [scoreData.id]: {
                    data: scaleHistogramData(getFixedHistogramData(100, scoreData.histogram_data)),
                    threshold: convertToInternalRange(scoreData.thresholds[scoreData.thresholds.length - 1]),
                    rawData: scoreData,
                },
            };
        }, {});
    }, [histogramValues]);

    // TODO: Add tests
    const handleValueChange = (values: MatchValue<TValues>[], options: SelectOption[], onChange: OnChangeHandler) => {
        const newValues = options.map((option) => {
            const index = values.findIndex((value) => getNodesByKey(value as NodeValue, option.value));
            if (index >= 0) {
                return values[index];
            }
            const value = {
                [FilterOperator.MATCH]: {
                    vainu_custom_industry: {
                        [FilterOperator.ALL]: [
                            { [FilterOperator.EQ]: { fact_vid: option.value } },
                            { [FilterOperator.EQ]: { confidence_class: 1 } },
                        ],
                    },
                },
            };

            if (operator.startsWith(FilterOperator.NOT)) {
                return { [FilterOperator.NOT]: value };
            }

            return value;
        });
        onChange(newValues.length ? newValues : initValue[FilterOperator.ANY]);
    };

    const { selectedScore, handleSelectedScoreChange: onSelectedScoreChange } = useSelectedScore(initialScores);

    const handleSelectedScoreChange = (value: Score) => {
        const industry = parseInt(Object.keys(value)[0]);
        const key = keys[industry];
        const score = value[industry];

        const field = fields[key.index];
        const filterValue = getFieldValue(field);

        if (!filterValue) {
            return;
        }

        if (!score) {
            filterValue[1] = {
                [FilterOperator.EQ]: { confidence_class: 1 },
            };
            setValue(
                `${fullPath}.${mergeKeys(FilterOperator.MATCH, config.id, FilterOperator.ALL)}.${key.index}`,
                filterValue,
            );
            return;
        }

        onSelectedScoreChange(value);
        const mappedScore = score.map((v) => v / FIXED_HISTOGRAM_SIZE);

        if (key.scores) {
            set(filterValue, key.scores, mappedScore);

            setValue(
                `${fullPath}.${mergeKeys(FilterOperator.MATCH, config.id, FilterOperator.ALL)}.${key.scores}`,
                mappedScore,
            );
        } else {
            let index: number | null = null;
            if (key.class) {
                index = parseInt(key.class.split('.')[0]);
            }
            if (!isNil(index) && !Number.isNaN(index) && index >= 0) {
                filterValue[index] = {
                    [FilterOperator.RANGE]: {
                        confidence_score: mappedScore,
                    },
                };
            } else {
                filterValue.push({
                    [FilterOperator.RANGE]: {
                        confidence_score: mappedScore,
                    },
                });
            }
            setValue(
                `${fullPath}.${mergeKeys(FilterOperator.MATCH, config.id, FilterOperator.ALL)}.${key.index}`,
                filterValue,
            );
        }
    };

    const handleOperatorUpdate = (newValue: Operator) => {
        const newFields = updateOperator(newValue, fields);

        if (newValue.value.includes(FilterOperator.NOT)) {
            const v = filter.value;
            const key = getFirstKey(v);
            if (key !== FilterOperator.ALL) {
                setValue(`${filter.path}`, { [FilterOperator.ALL]: newFields });
                return;
            }
            return;
        }
        setValue(`${filter.path}`, { [FilterOperator.ANY]: newFields });
    };

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        clearFilter,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,

        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullPath}
                control={control}
                key={`${uuid}-select-value`}
                render={({ field: inputField }) => {
                    const { onChange, value, ...fieldProps } = inputField;

                    return (
                        <SelectWithScore
                            data={convertedData}
                            placeholder={selectedOptions?.length ? 'Or...' : 'Select'}
                            width="100%"
                            options={VCIOptions}
                            value={selectedOptions}
                            disabledOptions={optionsToDisable as SelectOption[]}
                            onValueChange={(rawValues) => handleValueChange(value, rawValues, onChange)}
                            onSelectedScoreChange={handleSelectedScoreChange}
                            selectedScore={selectedScore}
                            mode={mode}
                            onModeChange={onModeChange}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};

export function valueToText(field: keyof TValues, separator = ', ') {
    return (state: CombinedNodeValue<TValues>, options?: SelectOption[]) => {
        const arrayOperator = getFirstKey<ValueOfCombineFilterOperator>(state);
        const values = (state[arrayOperator] || []) as MatchNode[];

        const industries: string[] = [];

        values.forEach((value) => {
            const rawValues: FilterNodeValue<TValues>[] = getFieldValue(value);

            rawValues.forEach((rawValue) => {
                const operator = getObjectKeys(rawValue)[0];
                const value = rawValue[operator];
                if (!value) {
                    return;
                }
                const fieldKey = getObjectKeys(value)[0];
                const fieldValue = value[fieldKey];

                if (fieldKey === field) {
                    if (options) {
                        const label = options.find((option) => option.value === fieldValue)?.label;
                        if (label) {
                            industries.push(label);
                        }
                    }
                }
            });
        });
        return industries.join(separator);
    };
}

export const getFieldValue = (field: MatchNode): FilterNodeValue<TValues>[] => {
    const firstOperator = getFirstKey(field);

    const path = mergeKeys(
        firstOperator === FilterOperator.NOT ? FilterOperator.NOT : '',
        FilterOperator.MATCH,
        config.id,
        FilterOperator.ALL,
    );
    return get(field, path);
};

export const updateOperator = (newValue: Operator, fields: MatchNode[]) => {
    const newFields = fields.map((field) => {
        if (isMatchNodeWithExclude(field) && newValue.value === FilterOperator.EQ) {
            return { [FilterOperator.MATCH]: field[FilterOperator.NOT][FilterOperator.MATCH] };
        } else if (newValue.value.startsWith(FilterOperator.NOT) && isMatchNode(field)) {
            return { [FilterOperator.NOT]: { [FilterOperator.MATCH]: field[FilterOperator.MATCH] } };
        } else {
            return field;
        }
    });

    return newFields;
};

export const parseValues = (fields: MatchNode[] = []) => {
    const values: number[] = [];
    const scores: Score = {};
    const keys: Keys = {};

    fields.forEach((field, index) => {
        const rawValues = getFieldValue(field);

        let industry: number | undefined = undefined;
        let industryScores;
        const localKey: KeyValue = {};

        rawValues.forEach((rawValue, valueIndex) => {
            const operator = getObjectKeys(rawValue)[0];
            const value = rawValue[operator];
            if (!value) {
                return;
            }
            const fieldKey = getObjectKeys(value)[0];

            if (fieldKey === FIELDS[2]) {
                const fieldValue = value[fieldKey];
                if (!fieldValue) {
                    return;
                }
                industryScores = fieldValue.map((v: number) => convertToInternalRange(v));
                localKey.scores = `${valueIndex}.${operator}.${fieldKey}`;
            } else if (fieldKey === FIELDS[0]) {
                const fieldValue = value[fieldKey];
                if (!fieldValue) {
                    return;
                }
                industry = fieldValue;
                localKey.industry = `${valueIndex}.${operator}.${fieldKey}`;
            } else if (fieldKey === FIELDS[1]) {
                const fieldValue = value[fieldKey];
                if (!fieldValue) {
                    return;
                }
                localKey.class = `${valueIndex}.${operator}.${fieldKey}`;
            }
        });
        if (!industry) {
            return;
        }

        scores[industry] = industryScores;
        values.push(industry);

        keys[industry] = { index, ...localKey };
    });

    return { values, scores, keys };
};

export function filterInvalidValues(filterValue: CombinedNodeValue<TValues>) {
    if (!isObject(filterValue)) {
        return;
    }
    const arrayOperator = getFirstKey<ValueOfCombineFilterOperator>(filterValue);
    const values = filterValue[arrayOperator];
    if (!values) {
        return;
    }

    const operator = getOperatorFromFields(values);
    let path = mergeKeys(FilterOperator.MATCH, config.id, FilterOperator.ALL);
    if (operator.startsWith(FilterOperator.NOT)) {
        path = `${FilterOperator.NOT}.${path}`;
    }

    const filtered = values.filter((value) => {
        const nodes: NodeValue[] = get(value, path);
        return !nodes.some((node) => {
            const key = getFirstKey(node);
            const field = node[key as keyof NodeValue];
            const fieldName = getFirstKey(field);

            if (fieldName === config.fields[0] && !field[fieldName]) {
                return true;
            }

            return false;
        });
    });

    return { values: filtered, path: mergeKeys(arrayOperator, path) };
}
