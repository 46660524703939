import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Box, CircularProgress, SxProps } from '@mui/material';
import { uniq } from 'lodash';
import { FormattedNumber } from 'react-intl';
import { isNotEmpty, pluralize } from 'utilities';

import CompanyInsightsView from 'components/features/CompanyInsightsView/CompanyInsightsView';
import { ContactsUpgradeButton, ContactsUpgradeDialog } from 'components/features/Employees';
import { NodeValue } from 'components/features/Filters/FilterTypes';
import CompanyGridView from 'components/features/lists/CompanyGridView';
import CompanyListView from 'components/features/lists/CompanyListView';
import ContactListView from 'components/features/lists/ContactListView';
import { ListExportDialogWrapper } from 'components/features/lists/ListExportDialog';
import { CRMExport, CrmMenu, useCRMExport } from 'components/modules/connectors/crm-export';
import { CrmName } from 'components/modules/connectors/types/Crm';
import Pagination from 'components/templates/Pagination';
import ResultViewHeader from 'components/templates/ResultViewHeader';
import { ViewType } from 'components/templates/ResultViewHeader/types';
import Button from 'components/tokens/Button';
import ButtonTabs from 'components/tokens/ButtonTabs';
import Dialog from 'components/tokens/Dialog';
import Icon, { IconType } from 'components/tokens/Icon';
import { useFilterCompaniesContext } from 'contexts/FilterCompaniesContext/FilterCompaniesContext';
import { ResultObjectType, getContactGroups } from 'contexts/FilterCompaniesContext/utils';
import { useFilterState } from 'contexts/FilterContext';
import { Database, isUploaded, useListsContext } from 'contexts/ListsContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import { SortCompanyProvider } from 'contexts/SortCompaniesContext';
import { DomainDataResult, OrganizationResult } from 'contexts/types/FilterCompanies';
import useAddToStaticList from 'hooks/useAddToStaticList';

import { useNavigateToList } from '..';
import ListClustersView from './ListClustersView';

type RemoveConfirmationInfo = {
    action: () => void;
    title: string;
};

export type FilterResultsCompaniesProps = {};

const defaultView: ViewType = 'grid';

const tabs: { id: string; icon: IconType }[] = [
    {
        id: 'companies',
        icon: 'Profiles',
    },
    {
        id: 'contacts',
        icon: 'UserCircle',
    },
];

const NO_CONTACTS_FILTER_QUERY = {
    '?MATCH': {
        contacts: {
            '?ALL': [],
        },
    },
};

export const FilterResultsCompanies: React.FC<FilterResultsCompaniesProps> = () => {
    const {
        companies,
        companyCount,
        loadingCompanies,
        contacts,
        contactCount,
        loadingContacts,
        numberOfPages,
        changePage,
        page,
        changeOrderBy,
        tab,
        setTab,
    } = useFilterCompaniesContext();
    const {
        selectedList,
        dbLists,
        selectedListId,
        patchCompaniesToList,
        removeCompaniesFromList,
        database,
        selectProfile,
        allContactsSelected,
        selectedContacts,
        unselectedContacts,
        selectContact,
        unselectContact,
        unselectProfile,
        selectedCompanies,
        unselectedCompanies,
        allCompaniesSelected,
        toggleSelectAllCompanies,
        toggleSelectAllContacts,
    } = useListsContext();

    const { hasProductPermission } = usePermissionContext();
    const { refetchResults } = useFilterCompaniesContext();
    const { addToList, combineLists } = useAddToStaticList();
    const navigateToList = useNavigateToList();
    const [exportState, { handleOpenExportDialog, handleCloseExportDialog }] = useCRMExport();
    const headerRef = useRef<HTMLDivElement>(null);

    const { state } = useFilterState();
    const contactsFilter = getContactGroups(state);
    const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);

    const isCrmExportEnabled = hasProductPermission(Permission.CrmBulkExport);
    const hasContactDataPermission = hasProductPermission(Permission.ContactData);

    const isStaticList = selectedList && isUploaded(selectedList);
    const isLegacy = selectedList?.type === 'legacy';

    const count = tab === 'companies' ? companyCount : contactCount;

    const handleCompaniesSelectionExport = (crm: CrmName) => {
        handleOpenExportDialog({
            selectionType: 'selection',
            exportType: 'company',
            crm,
            database: database as Database,
            companyIds: selectedCompanies,
            defaultVainuTags: selectedList ? [selectedList.name] : [],
            contact_filter: contactsFilter ?? NO_CONTACTS_FILTER_QUERY,
        });
    };

    const handleCompaniesListExport = (crm: CrmName) => {
        if (!selectedList) {
            return;
        }
        handleOpenExportDialog({
            selectionType: 'list',
            exportType: 'company',
            ignoreContactFilter: true,
            crm,
            database: database as Database,
            listItemsCount: companyCount.count,
            list: selectedList,
            defaultVainuTags: [selectedList.name],
            contact_filter: contactsFilter ?? NO_CONTACTS_FILTER_QUERY,
            excludeCompanyIds: unselectedCompanies,
        });
    };

    const handleContactsSelectionExport = (crm: CrmName) => {
        handleOpenExportDialog({
            selectionType: 'selection',
            exportType: 'contact',
            crm,
            database: database as Database,
            contacts: selectedContacts.map((contact) => ({
                ...contact,
                businessId: contact.company.id,
            })),
            companyIds: uniq(selectedContacts.map((contact) => contact.company.id)),
        });
    };

    const handleContactsListExport = (crm: CrmName) => {
        if (!selectedList) {
            return;
        }
        handleOpenExportDialog({
            selectionType: 'list',
            exportType: 'contact',
            crm,
            database: database as Database,
            list: selectedList,
            listItemsCount: contactCount.count,
            contact_filter: contactsFilter ?? NO_CONTACTS_FILTER_QUERY,
        });
    };

    const [activeView, setActiveView] = useState<ViewType>(defaultView);
    const [removeConfirmationInfo, setRemoveConfirmationInfo] = useState<RemoveConfirmationInfo | undefined>(undefined);
    const lastTarget = useRef<string | number | null>(null);

    const availableViews = useMemo((): ViewType[] => {
        if (selectedList?.type === 'legacy') {
            return ['grid', 'list'];
        }
        // TODO: These conditions can be removed after these are implemented in the backend.
        if (database !== 'DOMAIN_DATA_BASIC') {
            return ['grid', 'list', 'insights'];
        }
        return ['grid', 'list', 'insights', 'clusters'];
    }, [selectedList, database]);

    const hideSort = activeView === 'insights' || activeView === 'clusters';

    useEffect(() => {
        if (!availableViews.includes(activeView)) {
            setActiveView(defaultView);
        }
    }, [availableViews, activeView]);

    // This clears the selection when the results/tab change. It's only a fallback if the handlers that
    // change the cluster or mode themselves don't clear the selection for some reason.
    // A layout effect is used to prevent the new list visibly rendering with the old selection count.
    useLayoutEffect(() => {
        toggleSelectAllCompanies(false);
        toggleSelectAllContacts(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedList, tab]);

    if (dbLists.length === 0 || selectedList === undefined || !availableViews.includes(activeView)) {
        return null;
    }

    const hideActions = activeView === 'clusters' || activeView === 'insights';

    const selectedCompaniesCount =
        count.count === 0
            ? 0
            : allCompaniesSelected && unselectedCompanies
              ? count.count - unselectedCompanies.length
              : selectedCompanies.length;

    const selectedContactsCount =
        count.count === 0
            ? 0
            : allContactsSelected && unselectedContacts
              ? count.count - unselectedContacts.length
              : selectedContacts.length;

    const selectCompany = (value: string, isShiftClick: boolean) => {
        if (isShiftClick && lastTarget.current) {
            const lastTargetIndex = companies.findIndex((company) => company.id === lastTarget.current);
            const targetIndex = companies.findIndex((company) => company.id === value);
            if (lastTargetIndex !== -1 && targetIndex !== -1) {
                const selected = companies
                    .slice(Math.min(lastTargetIndex, targetIndex), Math.max(lastTargetIndex, targetIndex) + 1)
                    .map((company) => company.id);
                selectProfile(selected);
            }
        } else {
            lastTarget.current = value;
            selectProfile(value);
        }
    };

    const unselectCompany = (value: string) => {
        lastTarget.current = null;
        unselectProfile(value);
    };

    const handleAddMenuAction = async (action: string) => {
        if (allCompaniesSelected) {
            combineLists(action, unselectedCompanies, selectedCompaniesCount);
        } else {
            addToList(action, selectedCompanies);
        }
        if (action === 'createNewList') {
            toggleSelectAllCompanies(false);
        }
    };

    const handleRemove = (domain: string) => {
        setRemoveConfirmationInfo({
            title: `Remove ${domain}?`,
            action: async () => {
                if (selectedList) {
                    await removeCompaniesFromList(selectedList.id, [domain]);
                    refetchResults(selectedList.id);
                    if (!allCompaniesSelected && selectedCompanies.includes(domain)) {
                        unselectProfile(domain);
                    }
                }
            },
        });
    };

    const handleRemoveMultiple = () => {
        setRemoveConfirmationInfo({
            title: `Remove ${selectedCompaniesCount} ${selectedCompaniesCount === 1 ? 'company' : 'companies'}?`,
            action: async () => {
                if (allCompaniesSelected) {
                    await patchCompaniesToList(selectedList.id, unselectedCompanies);
                } else {
                    await removeCompaniesFromList(selectedList.id, selectedCompanies);
                }
                refetchResults(selectedList.id);
                toggleSelectAllCompanies(false);
            },
        });
    };

    const handleConfirmRemove = () => {
        removeConfirmationInfo?.action();
        setRemoveConfirmationInfo(undefined);
    };

    const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        changePage(page);
    };

    const handleViewChange = (newView: ViewType) => {
        setActiveView(newView);
    };

    const isLoading = (tab === 'companies' && loadingCompanies) || (tab === 'contacts' && loadingContacts);
    const hasResults = (tab === 'companies' && isNotEmpty(companies)) || (tab === 'contacts' && isNotEmpty(contacts));
    const isListSizeLoaded = tab === 'companies' ? companyCount.final : contactCount.final;

    const showPagination =
        !isLoading && hasResults && numberOfPages != null && activeView !== 'clusters' && activeView !== 'insights';

    const resultsSx: SxProps = {
        gridColumn: 'results',
        gridRowStart: 'results-start',
        gridRowEnd: showPagination ? undefined : 'pagination-end',
    };

    const shownTabs = tabs.filter((tab) => {
        if (tab.id === 'contacts') {
            return selectedList?.type !== 'legacy' && database !== 'DOMAIN_DATA_BASIC';
        }
        return true;
    });

    const disabledExportButtonTooltipText = `Loading the full list of ${tab}. Depending on the size, this may take a few minutes.`;

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateAreas: `
                    "sidebar header"
                    "sidebar results"
                    "sidebar pagination"
                `,
                gridTemplateColumns: 'auto 1fr',
                gridTemplateRows: 'auto 1fr auto',
                rowGap: 1,
                paddingBottom: 5,
            }}
        >
            <Box ref={headerRef} sx={{ gridArea: 'header' }}>
                <SortCompanyProvider changeSort={changeOrderBy}>
                    <ResultViewHeader
                        selectedCompaniesCount={tab === 'companies' ? selectedCompaniesCount : selectedContactsCount}
                        companiesCount={count.count}
                        toggleSelectAllCompanies={
                            tab === 'companies' ? toggleSelectAllCompanies : toggleSelectAllContacts
                        }
                        onViewChange={handleViewChange}
                        view={activeView}
                        loadingCount={count.loading}
                        countError={count.status === 'error'}
                        countEta={count.eta}
                        finalCount={count.final}
                        onAddMenuAction={tab === 'companies' ? handleAddMenuAction : undefined}
                        onDelete={handleRemoveMultiple}
                        availableViews={availableViews}
                        hideViews={tab === 'contacts'}
                        hideSort={hideSort}
                        hideActions={hideActions}
                        disableBulkCheckbox={!hasContactDataPermission && tab === 'contacts'}
                        countLabel={['grid', 'list'].includes(activeView) ? 'Select all' : null}
                        extraActions={
                            activeView !== 'insights' && (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <ListExportDialogWrapper
                                        listId={selectedList?.id}
                                        usageCount={companyCount.count}
                                        loadingUsageCount={companyCount.loading}
                                        scheduledCount={companyCount.status === 'scheduled'}
                                        totalCount={count.count}
                                        loadingCount={count.loading}
                                        companyIds={companies.map((company) => company.id)}
                                        disabled={
                                            (!hasContactDataPermission && tab === 'contacts') ||
                                            !!(selectedCompaniesCount || selectedContactsCount) ||
                                            !isListSizeLoaded
                                        }
                                        disabledTooltip={!isListSizeLoaded ? disabledExportButtonTooltipText : ''}
                                        buttonText={
                                            <>
                                                Download {tab}
                                                {!isListSizeLoaded && (
                                                    <CircularProgress
                                                        size={16}
                                                        sx={{ marginLeft: '4px', color: 'icon.subtle' }}
                                                    />
                                                )}
                                            </>
                                        }
                                        query={
                                            isStaticList || isLegacy
                                                ? ({
                                                      '?ALL': [{ '?EQ': { 'target_group._id': selectedList?.id } }],
                                                  } as NodeValue)
                                                : state
                                        }
                                        filename={selectedList?.name}
                                    />
                                    {isCrmExportEnabled && selectedList && (
                                        <CrmMenu
                                            disabled={
                                                (!hasContactDataPermission && tab === 'contacts') || !isListSizeLoaded
                                            }
                                            disabledTooltip={!isListSizeLoaded ? disabledExportButtonTooltipText : ''}
                                            anchorButton={
                                                <Button
                                                    startIcon={<Icon type="Export" />}
                                                    variant="tertiary"
                                                    size="small"
                                                >
                                                    Send {tab}
                                                    {!isListSizeLoaded && (
                                                        <CircularProgress
                                                            size={16}
                                                            sx={{ marginLeft: '4px', color: 'icon.subtle' }}
                                                        />
                                                    )}
                                                </Button>
                                            }
                                            handleClick={(crmName) => {
                                                if (tab === 'contacts') {
                                                    if (selectedContacts.length > 0) {
                                                        handleContactsSelectionExport(crmName);
                                                    } else {
                                                        handleContactsListExport(crmName);
                                                    }
                                                } else {
                                                    if (selectedCompanies.length > 0) {
                                                        handleCompaniesSelectionExport(crmName);
                                                    } else {
                                                        handleCompaniesListExport(crmName);
                                                    }
                                                }
                                            }}
                                        />
                                    )}
                                    {!hasContactDataPermission && tab === 'contacts' && (
                                        <ContactsUpgradeButton onClick={() => setUpgradeDialogOpen(true)} />
                                    )}
                                </Box>
                            )
                        }
                        title={
                            <ButtonTabs
                                selected={tab}
                                onChange={(tab) => setTab(tab as ResultObjectType)}
                                sx={{ width: '100%', display: 'flex' }}
                            >
                                {shownTabs.map(({ id, icon }) => {
                                    const count = id === 'companies' ? companyCount : contactCount;
                                    const labelText =
                                        id === 'companies'
                                            ? pluralize(count.count, 'company', 'companies')
                                            : pluralize(count.count, 'contact');

                                    const label = (
                                        <>
                                            <span data-testid={`${id}-counter`}>
                                                <FormattedNumber value={count.count} />
                                            </span>
                                            {count.final === false && '+'}&nbsp;{labelText}
                                        </>
                                    );
                                    const noTabs = shownTabs.length === 1;
                                    return (
                                        <Button
                                            disabled={noTabs}
                                            key={id}
                                            value={id}
                                            startIcon={<Icon type={icon} sx={{ marginLeft: '1px' }} />}
                                            endIcon={
                                                count.final === false && <CircularProgress size={16} color="inherit" />
                                            }
                                            sx={
                                                noTabs
                                                    ? {
                                                          backgroundColor: 'none',
                                                          '&&&&': { color: 'text.strong' },
                                                      }
                                                    : {
                                                          color:
                                                              tab === id ? 'brandColors.tertiaryBlue' : 'text.strong',
                                                          ...(tab === id && {
                                                              backgroundColor: 'brandColors.tertiaryBlue100',
                                                          }),
                                                      }
                                            }
                                        >
                                            {label}
                                        </Button>
                                    );
                                })}
                            </ButtonTabs>
                        }
                        currentListId={selectedList?.id}
                        tab={tab}
                    />
                </SortCompanyProvider>
            </Box>
            {tab === 'companies' ? (
                <>
                    {activeView === 'grid' ? (
                        <CompanyGridView
                            loading={loadingCompanies}
                            companies={companies}
                            onOpenProfile={(profileType, profileId) =>
                                navigateToList(selectedListId, profileType, profileId)
                            }
                            onSelectProfile={selectCompany}
                            onUnselectProfile={unselectCompany}
                            selectedCompanies={selectedCompanies}
                            unselectedCompanies={unselectedCompanies}
                            allCompaniesSelected={allCompaniesSelected}
                            currentListId={selectedList?.id}
                            onRemoveProfile={handleRemove}
                            sx={resultsSx}
                        />
                    ) : activeView === 'list' ? (
                        <CompanyListView
                            tableData={companies}
                            loading={loadingCompanies}
                            onOpenProfile={(_profileType, profileId) =>
                                navigateToList(selectedListId, _profileType, profileId)
                            }
                            onSelectProfile={selectCompany}
                            onUnselectProfile={unselectCompany}
                            selectedCompanies={selectedCompanies}
                            unselectedCompanies={unselectedCompanies}
                            allCompaniesSelected={allCompaniesSelected}
                            currentListId={selectedList?.id}
                            onRemoveProfile={handleRemove}
                            sx={{ ...resultsSx, display: 'grid', gridTemplateColumns: 'minmax(0, 1fr)' }}
                        />
                    ) : activeView === 'insights' ? (
                        <CompanyInsightsView
                            companiesCount={companyCount.count}
                            loadingCount={companyCount.loading}
                            companies={companies as OrganizationResult[] | DomainDataResult[]}
                            loadingCompanies={loadingCompanies}
                            sx={resultsSx}
                        />
                    ) : activeView === 'clusters' ? (
                        <ListClustersView list={selectedList} sx={resultsSx} headerRef={headerRef} />
                    ) : null}
                </>
            ) : (
                <ContactListView
                    loading={loadingContacts}
                    allContactsSelected={allContactsSelected}
                    tableData={contacts}
                    selectedContacts={selectedContacts}
                    unselectedContacts={unselectedContacts}
                    sx={{ ...resultsSx, display: 'grid', gridTemplateColumns: 'minmax(0, 1fr)' }}
                    onSelectContact={selectContact}
                    onUnselectContact={unselectContact}
                    onOpenProfile={(_profileType, profileId, tabName) =>
                        navigateToList(selectedListId, _profileType, profileId, tabName)
                    }
                />
            )}

            {(activeView === 'grid' || activeView === 'list') && (
                <Box sx={{ gridArea: 'pagination', paddingTop: 2.5 }}>
                    {showPagination && (
                        <Pagination
                            page={page}
                            count={numberOfPages}
                            // showJumpToPage={false}
                            onPageChange={handlePageChange}
                            // handleJumpToPage={(showJumpToPage: boolean) => {}}
                            // handleSelectPage={(event, child) => {}}
                        />
                    )}
                </Box>
            )}
            {removeConfirmationInfo && (
                <Dialog
                    PaperProps={{
                        sx: {
                            minWidth: 420,
                        },
                    }}
                    open
                    title={removeConfirmationInfo.title}
                    cancelLabel="Cancel"
                    onClose={() => setRemoveConfirmationInfo(undefined)}
                    customizedConfirm={
                        <Button variant="primary" onClick={handleConfirmRemove}>
                            Remove
                        </Button>
                    }
                />
            )}
            <CRMExport {...exportState} onClose={handleCloseExportDialog} />
            <ContactsUpgradeDialog open={upgradeDialogOpen} onClose={() => setUpgradeDialogOpen(false)} />
        </Box>
    );
};

export default FilterResultsCompanies;
