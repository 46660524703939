import React, { useState } from 'react';

import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { isNotEmpty } from 'utilities';

import { Company } from 'api/types/company';
import CompanyGridView from 'components/features/lists/CompanyGridView';
import CompanyListView from 'components/features/lists/CompanyListView';
import { useNavigateToProfile } from 'components/modules/search';
import ExportedProfilesDialog from 'components/templates/CompanyCardCRM/ExportedProfilesDialog';
import ResultViewHeader from 'components/templates/ResultViewHeader';
import { ViewType } from 'components/templates/ResultViewHeader/types';
import { DialogController } from 'contexts/DialogContext/DialogController';
import { SortCompanyProvider } from 'contexts/SortCompaniesContext';
import { DomainDataResult, OrganizationResult } from 'contexts/types/FilterCompanies';

import SearchBannerTop from './SearchBannerTop';

export type NavbarCompanySearchType = 'company_name' | 'domain' | 'auxiliary';

export type SearchResultsProps = {
    searchTerm?: string;
    navbarDomainResults: DomainDataResult[] | OrganizationResult[];
    // for companies as a result of API call to '/mapi/autocompleteui/' but this will be replaced by API in v2/v3
    navbarCompanySearchResults?: {
        domain: Company[];
        company_name: Company[];
        auxiliary: Company[];
    };
    isLoading: boolean;
    selectedCompanies: Array<string>;
    unselectedCompanies: Array<string>;
    isAllCompaniesSelected: boolean;
    onSelectCompany?: (value: string, isShiftClick: boolean) => void;
    onUnselectCompany?: (value: string) => void;
    onHandleAddMenuAction?: (action: string) => void;
    onToggleSelectAllCompanies: (selected?: boolean) => void;
};

export const SearchResults: React.VFC<SearchResultsProps> = ({
    searchTerm,
    navbarDomainResults,
    isLoading,
    selectedCompanies,
    unselectedCompanies,
    isAllCompaniesSelected,
    onSelectCompany,
    onUnselectCompany,
    onHandleAddMenuAction,
    onToggleSelectAllCompanies,
}) => {
    const [activeView, setActiveView] = useState<ViewType>('grid');
    const navigateToProfile = useNavigateToProfile();
    /**
     * Since we are releasing Domain data using V2 API only for initial release,
     * we won't need to change searchType. Also result will be different when v2/v3
     * becomes ready for search API.
     */

    /* const [activeSearchType, setActiveSearchType] = useState<NavbarCompanySearchType>('company_name');
    
    const handleActiveSearch = (value: NavbarCompanySearchType) => {
        setActiveSearchType(value);
    }; */

    const selectedCompaniesCount = isAllCompaniesSelected
        ? navbarDomainResults.length - unselectedCompanies.length
        : selectedCompanies.length;

    return (
        <Box>
            {isLoading ? (
                <Skeleton variant="text" height={48} width="30%" sx={{ marginX: 4 }} />
            ) : (
                <SearchBannerTop
                    searchTerm={searchTerm}
                    // activeSearchType={activeSearchType}
                    // auxiliaryCount={navbarCompanySearchResults.auxiliary.length}
                    // companyNameCount={navbarCompanySearchResults.company_name.length}
                    // domainCount={navbarCompanySearchResults.domain.length}
                    // onClick={handleActiveSearch}
                />
            )}
            <Box sx={{ padding: '26px 40px 40px' }}>
                <SortCompanyProvider>
                    {isLoading || !isNotEmpty(navbarDomainResults) ? null : (
                        <ResultViewHeader
                            companiesCount={navbarDomainResults.length}
                            selectedCompaniesCount={selectedCompaniesCount}
                            onViewChange={setActiveView}
                            view={activeView}
                            toggleSelectAllCompanies={onToggleSelectAllCompanies}
                            onAddMenuAction={onHandleAddMenuAction}
                            availableViews={['grid', 'list']}
                            hideSort
                        />
                    )}
                </SortCompanyProvider>
                <Box sx={{ paddingTop: 2.5 }}>
                    {activeView === 'grid' ? (
                        <CompanyGridView
                            loading={isLoading}
                            companies={navbarDomainResults}
                            selectedCompanies={selectedCompanies}
                            unselectedCompanies={unselectedCompanies}
                            allCompaniesSelected={isAllCompaniesSelected}
                            onOpenProfile={(profileType, profileId) =>
                                navigateToProfile(profileType, profileId, searchTerm)
                            }
                            onSelectProfile={onSelectCompany}
                            onUnselectProfile={onUnselectCompany}
                        />
                    ) : (
                        <CompanyListView
                            tableData={navbarDomainResults}
                            selectedCompanies={selectedCompanies}
                            unselectedCompanies={unselectedCompanies}
                            allCompaniesSelected={isAllCompaniesSelected}
                            onOpenProfile={(profileType, profileId) =>
                                navigateToProfile(profileType, profileId, searchTerm)
                            }
                            onSelectProfile={onSelectCompany}
                            onUnselectProfile={onUnselectCompany}
                        />
                    )}
                </Box>
            </Box>
            <DialogController type="CRM_EXPORTED_PROFILES" component={ExportedProfilesDialog} />
        </Box>
    );
};

export default SearchResults;
