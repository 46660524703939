import React from 'react';

import { Controller, ControllerRenderProps, get, useFormContext, useWatch } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';
import { getFirstKey } from 'utilities/objectUtils';

import { mergeKeys } from '../../common/utils';
import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterProps,
    NodeValue,
    MatchNode,
    CombinedNodeValue,
} from '../../FilterTypes';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, valueArrayToText } from '../utils';

type OnChangeHandler = ControllerRenderProps['onChange'];

export type TValues = { size_classes: string[]; indicator_type: 'company_size_indicator_model' };

const config: FilterConfig<TValues> = {
    fields: ['size_classes', 'indicator_type'],
    id: FilterID.staff_number_web,
    label: 'Estimated size (~employees)',
    defaultOperator: FilterOperator.IN,
    initValue: {
        [FilterOperator.ALL]: [
            {
                [FilterOperator.IN]: { size_classes: undefined },
            },
            { [FilterOperator.EQ]: { indicator_type: 'company_size_indicator_model' } },
        ],
    },
};

const getOperatorFromFields = (fields: NodeValue[] | undefined): OperatorValue => {
    if (!fields?.length) {
        return FilterOperator.IN;
    }

    const operator = getFirstKey(fields[0]);
    return operator === FilterOperator.NOT ? `${FilterOperator.NOT}${FilterOperator.IN}` : FilterOperator.IN;
};

export const updateOperator = (newValue: Operator, fields: CombinedNodeValue) => {
    const value = get(fields, mergeKeys(FilterOperator.ALL, 0, FilterOperator.IN, 'size_classes'));
    const valueNot = get(
        fields,
        mergeKeys(FilterOperator.ALL, 0, FilterOperator.NOT, FilterOperator.IN, 'size_classes'),
    );

    const sizeClasses = value || valueNot;
    const sizeClassFilter = {
        [FilterOperator.IN]: { size_classes: sizeClasses },
    };

    return {
        [FilterOperator.ALL]: [
            newValue.value === FilterOperator.IN
                ? sizeClassFilter
                : {
                      [FilterOperator.NOT]: sizeClassFilter,
                  },
            { [FilterOperator.EQ]: { indicator_type: 'company_size_indicator_model' } },
        ],
    };
};

export const staffNumberWebFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.organization_size_indicators,
        label: config.label,
        fields: config.fields,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <StaffNumberWeb {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
        getOperator: (filterValue: MatchNode<TValues>) => {
            const arrayOperator = getFirstKey(filterValue);
            const values = filterValue[arrayOperator]?.organization_size_indicators?.[FilterOperator.ALL];
            return getOperatorFromFields(values);
        },
    },
};

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

const StaffNumberWeb: React.FC<FilterProps<TValues>> = ({ clearFilter, filter, uuid, groupUUID, removeFilter }) => {
    const { control, setValue } = useFormContext();
    const { companySizeIndicatorOptions } = useFilterOptionsContext();
    const fullValuePath = `${filter.path}.${FilterOperator.MATCH}.${GroupValues.organization_size_indicators}`;
    const fields: CombinedNodeValue = useWatch({ name: fullValuePath });

    const operator = getOperatorFromFields(fields[FilterOperator.ALL]);

    const handleValueChange = (options: SelectOption[], onChange: OnChangeHandler) => {
        const sizeClassFilter = {
            [FilterOperator.IN]: { size_classes: options.length ? options.map((option) => option.value) : undefined },
        };
        onChange({
            [FilterOperator.ALL]: [
                operator === FilterOperator.IN
                    ? sizeClassFilter
                    : {
                          [FilterOperator.NOT]: sizeClassFilter,
                      },
                { [FilterOperator.EQ]: { indicator_type: 'company_size_indicator_model' } },
            ],
        });
    };

    const handleOperatorUpdate = (newValue: Operator) => {
        const newFields = updateOperator(newValue, fields);
        setValue(`${fullValuePath}`, newFields);
    };

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        clearFilter,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;

                    const sizeClasses =
                        operator === FilterOperator.IN
                            ? value[FilterOperator.ALL][0][FilterOperator.IN].size_classes
                            : value[FilterOperator.ALL][0][FilterOperator.NOT][FilterOperator.IN].size_classes;

                    const selectedOptions = companySizeIndicatorOptions.filter((item) =>
                        (sizeClasses || []).includes(item.value as string),
                    );

                    return (
                        <Select
                            placeholder={selectedOptions?.length ? 'Or...' : 'Select'}
                            multiple={true}
                            width="100%"
                            options={companySizeIndicatorOptions}
                            value={selectedOptions}
                            onValueChange={(values) => handleValueChange(values as SelectOption[], onChange)}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
