export { getCategory } from './getSignalCategory';
export { isValidTrigger, isValidForSave, isActiveTrigger } from './triggerValidity';
export { stateDestinationToDBdestination, mapStateToTriggerDBObject } from './mapStateToTrigger';
export { formatMessageQueueAsLeads, pickMessageQueueType } from './formatMessageQueueAsLeads';
export { getMainCategories, dataCategories } from './categories';

export const enabledCRMs = ['pipedrive', 'hubspot', 'salesforce', 'dynamics', 'salesforcesandbox'];

export const shortenTriggerTitle = (title?: string | null) => {
    if (!title) return '';
    if (title?.length > 30) {
        return `${title.slice(0, 30)}...`;
    }
    return title;
};

export function getIntegrationImage(integration: string): string {
    const data = {
        slack: 'https://static.vainu.io/img/integration/icon-slack-big.png',
        salesforce: `img/integration/icon-salesforce-big.png`,
        dynamics: 'https://static.vainu.io/img/integration/icon-dynamics-big.png',
        hubspot: 'https://static.vainu.io/img/integration/icon-hubspot-big.png',
        upsales: 'https://static.vainu.io/img/integration/icon-upsales-big.png',
        pipedrive: 'https://static.vainu.io/img/integration/icon-pipedrive-big.png',
        superoffice: 'https://static.vainu.io/img/integration/icon-superoffice-big.png',
    };
    return (
        data[integration.toLowerCase() as keyof typeof data] ||
        'https://static.vainu.io/img/integration/Cogwheel_old.png'
    );
}
