import { useContext } from 'react';

import { DialogContext } from '../contexts/DialogContext/DialogContext';

function useDialogContext() {
    const dialogState = useContext(DialogContext);

    return dialogState;
}

export { useDialogContext };
