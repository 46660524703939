import { AxiosInstance } from 'axios';

import { PlanhatOwner } from './types/PlanhatOwner';

/**
 * This API returns whole lot of information about user. We only need planhat owner
 * data now. If needed, the type can be modified to include other necessary fields.
 */
export const fetchPlanhatCsOwner = async (axios: AxiosInstance) => {
    return (await axios.get<PlanhatOwner>(`${process.env.REACT_APP_API_URL}/mapi/analyticsdata/`)).data;
};
