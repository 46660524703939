import { AxiosInstance } from 'axios';

import { APIKey } from './types/APIKey';

export async function getAllApiKeys(axios: AxiosInstance) {
    return (await axios.get<APIKey[]>('/mapi/apikey/')).data;
}

export async function generateNewApiKey(axios: AxiosInstance, data: { name: string }) {
    return await axios.post<APIKey>('/mapi/apikey/', data);
}

export async function deleteApiKey(axios: AxiosInstance, id: string) {
    return await axios.delete(`/mapi/apikey/${id}/`);
}
