import { useMemo } from 'react';

import { formatDistanceToNow, format, parseISO } from 'date-fns';
import { assertArray, isNotEmptyObject } from 'utilities';

import { BasicData } from 'api/profileData';
import { CompanyFact } from 'api/types/company';
import { BusinessUnit, Address, isDomainData } from 'api/types/Organization';

import { BasicInfoProps } from '.';
import { getHQ } from '../Locations/utils';

export function formatURL(url: string) {
    if (typeof url !== 'string') {
        return undefined;
    }
    return (url.indexOf('http') !== 0 ? 'https://' : '') + url;
}

/**
 * @example
 * http://vainu.com/en/whats-up --> vainu.com
 * vainu.io --> vainu.io
 */
export function extractDomainName(url: string) {
    const matches = url.match(/^(?:https?:\/\/)?([^/?#]+)(?:[/?#]|$)/i);
    return matches?.[1];
}

const joinAddressParts = ({ street, city, postal_code }: Address) =>
    [street, [postal_code, city].filter(Boolean).join(' ')].filter(Boolean).join(', ');

export function formatHQAddress(hq?: BusinessUnit): string {
    if (!hq) {
        return '';
    }
    const { visiting_address, address } = hq;
    if (isNotEmptyObject(visiting_address)) {
        return joinAddressParts(visiting_address);
    }
    if (isNotEmptyObject(address)) {
        return joinAddressParts(address);
    }
    return '';
}

export function getFuzzyTime(date: string) {
    const parsedDate = parseISO(date);
    return `${formatDistanceToNow(parsedDate, { addSuffix: true })} (${format(parsedDate, 'yyyy')})`;
}

export function getTeleoperators(facts: CompanyFact[] | undefined): CompanyFact[] | undefined {
    return facts?.filter((fact) => fact.type === 'operator');
}

export function countMoreAddresses(business_units: BusinessUnit[], hq?: BusinessUnit) {
    const filteredAddresses = business_units.filter(
        (unit) => (isNotEmptyObject(unit.visiting_address) || isNotEmptyObject(unit.address)) && unit.uid !== hq?.uid,
    );
    return filteredAddresses.length;
}

export function getBasicDataFromProfileData(data: BasicData): BasicInfoProps {
    const hq = getHQ(assertArray(data.business_units));
    return {
        business_id: isDomainData(data) ? data.business_ids?.join(', ') ?? '' : data.business_id,
        company_names: data.auxiliary_names,
        email: data.email,
        foundation_date: data.founded,
        legal_entity: data.legal_entity,
        industry_codes_all_values: data.industries_verbose?.map((industry) => industry.description ?? '') ?? [],
        link: data.website,
        phone: data.tel,
        hq: hq,
        business_units: data.business_units,
        customIndustries: data.vainu_custom_industries,
        levelInGroup: 'level_in_group' in data ? data.level_in_group : null,
        status: 'official_status' in data ? data.official_status : null,
        direct_marketing_denied: 'direct_marketing_denied' in data ? data.direct_marketing_denied : undefined,
    };
}

export const useBasicDataFromProfileData = (data: BasicData): BasicInfoProps =>
    useMemo(() => getBasicDataFromProfileData(data), [data]);
