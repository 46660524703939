import { VainuObject, VainuDatapoint } from '../types/Crm';

export const getSourcePreview = ({
    sourceObject,
    sourceField,
    selectedOptionValue,
    sourceFieldToDatapointMap,
}: {
    sourceObject: VainuObject;
    sourceField: string;
    selectedOptionValue: string;
    sourceFieldToDatapointMap: Record<string, VainuDatapoint>;
}) => {
    if (sourceObject === 'Text' || sourceObject === 'Picklist') return sourceField;

    const datapointOptions = sourceFieldToDatapointMap[sourceField]?.options_p2?.[0].options;

    if (datapointOptions) {
        const selectedOption = datapointOptions.find((option) => option.value === selectedOptionValue);
        return selectedOption?.examples[0].label ?? '';
    }

    return sourceFieldToDatapointMap[sourceField]?.example ?? '';
};
