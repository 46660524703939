import React, { useState, useContext } from 'react';

import ArrowForwardIcons from '@mui/icons-material/ArrowForwardIos';
import ReplyIcon from '@mui/icons-material/Reply';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import { Grid, Typography, Button, styled } from '@mui/material';

import Icon from 'components/tokens/Icon';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';

import { Tag } from '../../../tokens/Tag';
import CompanyMatchBulbIcon from '../svg/CompanyMatchBulbIcon';
import LogoSvg from '../svg/LogoSvg';
import { CrmName, CRMObject } from '../types/Crm';
import { getCRMName } from '../utils';
import { TargetObject, getObjectPluralForm } from '../utils/CRMTargetText';
import DialogTooltip from './MoreInformationTooltipDialog';

type Props = {
    crm: CrmName;
    isAccountAdmin: boolean;
    startCompanyImport: () => void;
};

const CompanyMatchStart: React.FC<Props> = ({ crm, isAccountAdmin, startCompanyImport }) => {
    const { isDomainConnector } = useContext(PermissionContext);
    const [isImportRunning, setIsImportRunning] = useState(false);
    const crmName = getCRMName(crm);
    const companyObj = getObjectPluralForm(crm, crmTargetObject(crm, 'company'));

    return (
        <div style={{ display: 'grid' }}>
            {isAccountAdmin && (
                <div>
                    <LogoWrapper container alignItems="center" justifyContent="flex-start">
                        <Grid item xs="auto" className="logo">
                            <Icon type="HubspotCompanySvg" />
                        </Grid>
                        <Grid item xs="auto" sx={{ margin: '0px 10px' }}>
                            <ArrowForwardIcons sx={{ color: 'grey.400' }} />
                        </Grid>
                        <Grid item xs="auto" className="logo">
                            <LogoSvg width={22} height={22} />
                        </Grid>
                    </LogoWrapper>
                </div>
            )}
            <div>
                <HeaderText>Match your Companies with Vainu</HeaderText>
                <SubheaderText>
                    Keep your Company data reliable and clean by matching with our{' '}
                    <DialogTooltip
                        isTooltip={isDomainConnector}
                        header={<>🌍 Company data</>}
                        description={
                            <>
                                We use company{' '}
                                <HighlightedText style={{ fontWeight: 400 }}>domain (website)</HighlightedText> as a
                                unique identifier to match with our global company database.
                            </>
                        }
                        footerIconType={'Vainu'}
                        footerText={learnMoreText}
                        placement={'top'}
                    >
                        <b>company data</b>
                    </DialogTooltip>
                    . Matching takes roughly 5 minutes for every thousand Companies, and gives you access to extra
                    features to make the most of your {crmName} connection.
                </SubheaderText>
            </div>
            {isDomainConnector ? (
                <Grid container>
                    <ReplyIcon fontSize="small" style={{ transform: 'scaleX(-1)' }} />
                    <Typography>Send company lists to {crmName} that’ll never create duplicates</Typography>
                    <SyncIcon fontSize="small" />
                    <Typography>Ensure your Companies are kept up to date with data that matters</Typography>
                    <Icon type="Triggers" />
                    <Typography>
                        Use your {crmName} Company data to create {companyProfileListTooltip} or{' '}
                        <DialogTooltip
                            header={
                                <>
                                    {comingSoonTag}
                                    <p>⚡️ Workflow triggers</p>
                                </>
                            }
                            description={
                                <>
                                    Act on events affecting customers, and prospects by automating tasks or alerts when
                                    they happen.
                                </>
                            }
                            footerIconType={'Vainu'}
                            footerText={learnMoreText}
                            placement={'top'}
                        >
                            <HighlightedText>triggers</HighlightedText>
                        </DialogTooltip>{' '}
                        <Typography component="em">(coming soon)</Typography>
                    </Typography>
                </Grid>
            ) : (
                <ReasonContainer container>
                    <SearchIcon fontSize="small" />
                    <Typography>{`Use ${crmName} ${companyObj} data in Vainu`}</Typography>
                    <ReplyIcon fontSize="small" style={{ transform: 'scaleX(-1)' }} />
                    <Typography>{`Export company segments into ${crmName}`}</Typography>
                    <SyncIcon fontSize="small" />
                    <Typography>{`Keep company data in your ${companyObj} up to date`}</Typography>
                    <CompanyMatchBulbIcon />
                    <Typography>{`Company matching does not make any changes to your ${crmName}.`}</Typography>
                </ReasonContainer>
            )}
            {isAccountAdmin && (
                <StyledButton
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={!isAccountAdmin || isImportRunning}
                    onClick={() => {
                        startCompanyImport();
                        setIsImportRunning(true);
                    }}
                >
                    Start matching
                </StyledButton>
            )}
        </div>
    );
};

const targetObjMap: { [key in CrmName]: { [key in TargetObject]: CRMObject } } = {
    hubspot: {
        company: 'Company',
        contact: 'Contact',
        opportunity: 'Deal',
    },
    salesforce: {
        company: 'Account',
        contact: 'Contact',
        opportunity: 'Opportunity',
    },
    salesforcesandbox: {
        company: 'Account',
        contact: 'Contact',
        opportunity: 'Opportunity',
    },
    dynamics: {
        company: 'accounts',
        contact: 'contacts',
        opportunity: 'opportunities',
    },
    pipedrive: {
        company: 'organization',
        contact: 'person',
        opportunity: 'deal',
    },
};

function crmTargetObject(crm: CrmName, targetObject: TargetObject): CRMObject {
    return targetObjMap[crm]?.[targetObject] ?? null;
}

export default CompanyMatchStart;

const HeaderText = styled(Typography)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    color: theme.palette.text.strong,
}));

const SubheaderText = styled(Typography)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.text.subtle,
}));

const ReasonContainer = styled(Grid)(({ theme }) => ({
    display: 'grid',
    gridTemplate: '1fr 1fr 1fr 1fr / min-content 1fr',
    gap: '2.5em 2em',
    marginTop: '24px',
    padding: '3em',
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.strong,
    '& p': {
        fontSize: 16,
        color: theme.palette.text.strong,
    },
}));

const LogoWrapper = styled(Grid)(({ theme }) => ({
    margin: '24px 0px',
    '& .logo': {
        height: 50,
        width: 50,
        textAlign: 'center',
        borderColor: theme.palette.grey[100],
        borderRadius: '50%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'uppercase',
    padding: '14px 16px',
    float: 'right',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 16,
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    justifySelf: 'end',
    marginTop: 24,
    marginBottom: 8,
}));

const HighlightedText = styled('span')(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.info.main,
}));

const StyledLink = styled('a')(({ theme }) => ({
    color: theme.palette.text.strong,
}));

const learnMoreText = (
    <>
        Learn more from our{' '}
        <StyledLink
            href="https://help.vainu.app/en/articles/120176-connectors-vainu-integration-overview"
            target={'_blank'}
            rel="noreferrer"
        >
            help centre
        </StyledLink>
    </>
);

const comingSoonTag = (
    <Tag
        label="COMING SOON"
        sx={{ backgroundColor: 'upgrade.background', color: 'upgrade.text', border: 'none', marginBottom: '4px' }}
    />
);

const companyProfileListTooltip = (
    <DialogTooltip
        header={
            <>
                {comingSoonTag}
                <p>🏢 CRM filters</p>
            </>
        }
        description={
            <>Use the information from your CRM alongside our 100+ filters to target prospects and customers.</>
        }
        footerIconType={'Vainu'}
        footerText={learnMoreText}
        placement={'top'}
    >
        <HighlightedText>lists</HighlightedText>
    </DialogTooltip>
);
