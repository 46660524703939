import React from 'react';

import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/tokens/Button';
import { Icon } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';

type LandingHeaderProps = {
    hasGoBack?: boolean;
    title: string;
    subtitle?: string;
    cta?: JSX.Element;
    rightIcon?: JSX.Element;
};

export const LandingHeader: React.FC<LandingHeaderProps> = ({ hasGoBack = false, title, subtitle, cta, rightIcon }) => {
    const navigate = useNavigate();

    return (
        <Container>
            <div>
                {hasGoBack && (
                    <Button variant="flat" startIcon={<Icon type="ChevronBigLeft" />} onClick={() => navigate(-1)} />
                )}
                <Wrapper>
                    <Typography variant="h1" fontWeight="bold" fontSize="30px" lineHeight="3rem">
                        {title}
                    </Typography>
                    <Typography marginBottom="24px">{subtitle}</Typography>
                    {cta}
                </Wrapper>
            </div>
            <div>{rightIcon}</div>
        </Container>
    );
};

const Container = styled('div')`
    position: inherit;
    display: flex;
    justify-content: space-between;
    padding: 40px;
`;

const Wrapper = styled('div')`
    max-width: 70%;
    margin: 20px;
`;
