import { useCallback, useMemo } from 'react';

import { List } from 'api/types/List';
import { useFilterCompaniesContext } from 'contexts/FilterCompaniesContext/FilterCompaniesContext';
import { useListsContext } from 'contexts/ListsContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import { getStaticListOptions } from 'contexts/utilities/listsUtils';

const STATIC_COMPANY_ADD_LIMIT = 50000;

export type UseAddToStaticListParams = {
    /** Exclude a static list with this id from the selection of lists where to add more companies.
     * Usually the currently selected list. Has no effect if given a dynamic or legacy list id.
     * Only affects the returned `staticListOptions` value. */
    excludedListId?: List['id'];
};

export default function useAddToStaticList({ excludedListId }: UseAddToStaticListParams = {}) {
    const { refetchResults } = useFilterCompaniesContext();
    const { dbLists, selectedList, createNewList, addCompaniesToList, database, combineListToStaticList } =
        useListsContext();
    const { showSnackbar } = useSnackbarContext();

    const addToList = useCallback(
        async (action: string, companyIds: string[]) => {
            if (action.startsWith('static-list-')) {
                const listId = action.replace('static-list-', '');
                try {
                    await addCompaniesToList(listId, companyIds);
                    refetchResults(listId);
                    showSnackbar(
                        `Added ${companyIds.length === 1 ? companyIds[0] : `${companyIds.length} companies`} to ${
                            dbLists.find((list) => list.id === listId)?.name
                        }`,
                    );
                } catch (error) {
                    showSnackbar(`Oops, Something went wrong!`, 'error');
                }
            } else {
                createNewList(
                    database === 'DOMAIN_DATA_BASIC' ? 'static-domain-list' : 'static-organization-list',
                    undefined,
                    undefined,
                    companyIds,
                );
            }
        },
        [addCompaniesToList, createNewList, database, dbLists, refetchResults, showSnackbar],
    );

    const combineLists = useCallback(
        async (action: string, excludedCompanyIds?: string[], companyCount: number = 0) => {
            if (companyCount > STATIC_COMPANY_ADD_LIMIT) {
                showSnackbar(
                    `You can only add ${STATIC_COMPANY_ADD_LIMIT} companies to a custom list at a time`,
                    'error',
                );
                return;
            }
            if (!selectedList) {
                return;
            }
            if (action === 'createNewList') {
                combineListToStaticList('NEW', selectedList.id, excludedCompanyIds);
            } else if (action.startsWith('static-list-')) {
                const targetListId = action.replace('static-list-', '');
                await combineListToStaticList(targetListId, selectedList?.id);
                refetchResults(targetListId);
                showSnackbar(
                    `Added ${companyCount} companies to ${dbLists.find((list) => list.id === targetListId)?.name}`,
                );
            }
        },
        [combineListToStaticList, dbLists, refetchResults, selectedList, showSnackbar],
    );

    const staticListOptions = useMemo(
        // vainu view doesn't have dbLists
        () => (!dbLists ? [] : getStaticListOptions(dbLists, excludedListId)),
        [dbLists, excludedListId],
    );

    return { staticListOptions, addToList, combineLists };
}
