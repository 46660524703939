import React from 'react';

import { Skeleton, styled } from '@mui/material';

// todo: is it possible to move it into MappingsTable?
const MappingsTableSkeleton: React.FC = () => {
    return (
        <div role="progressbar">
            <HeaderSkeletonContainer>
                <Skeleton animation="wave" width={50} height={20} />
                <Skeleton animation="wave" width={50} height={20} />
                <Skeleton animation="wave" width={50} height={20} />
            </HeaderSkeletonContainer>
            {Array.from({ length: 10 }).map((_, index) => (
                <RowSkeletonContainer key={index}>
                    <Skeleton animation="wave" height={32} width={200} />
                    <Skeleton animation="wave" height={32} width={180} />
                    <Skeleton animation="wave" height={32} width={220} />
                    <Skeleton animation="wave" height={32} width={20} />
                    <Skeleton animation="wave" height={32} width={20} />
                </RowSkeletonContainer>
            ))}
        </div>
    );
};

const HeaderSkeletonContainer = styled('div')({
    display: 'flex',
    marginTop: '16px',
    marginBottom: '8px',

    '& > span:first-of-type': {
        marginLeft: '16px',
    },

    '& > span:nth-of-type(2)': {
        marginLeft: '190px',
    },

    '& > span:last-child': {
        marginLeft: '170px',
    },
});

const RowSkeletonContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '58px',
    marginBottom: '12px',
    padding: '8px 8px 8px 16px',
    border: '1px solid #f5f5f5',
    borderRadius: '11px',

    '& > span:nth-of-type(2)': {
        marginLeft: '40px',
    },

    '& > span:nth-of-type(3)': {
        marginLeft: '40px',
    },

    '& > span:nth-of-type(4)': {
        marginLeft: '64px',
    },

    '& > span:nth-of-type(5)': {
        marginLeft: '32px',
    },
});

export { MappingsTableSkeleton };
