import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';

import { StyledTextFieldProps, STYLED_PROPS_TO_NOT_FORWARD } from './types';

export const StyledMUITextField = styled(TextField, {
    shouldForwardProp: (prop) => !STYLED_PROPS_TO_NOT_FORWARD.includes(prop.toString()),
})<StyledTextFieldProps>(({ styleVariant, isEmpty, showIcon, theme, status, multiline }) => ({
    width: '100%',
    '& .MuiInputLabel-root': {
        fontSize: 14,
    },
    '& .MuiInputBase-root': {
        borderRadius: styleVariant === 'rounded' ? 1000 : 4,
        fontSize: 14,
        paddingLeft: multiline ? 0 : 12,
        background: isEmpty ? theme.palette.field.background : theme.palette.background.paper,
        '& fieldset': {
            border: `1px solid ${theme.palette.border}`,
        },
        '&:hover': {
            background: theme.palette.background.paper,
        },
        '&:hover fieldset': {
            border: `1px solid ${theme.palette.common.black}`,
        },
        '&.Mui-focused': {
            background: theme.palette.background.paper,
        },
        '&.Mui-focused fieldset': {
            border: `2px solid ${theme.palette.common.black}`,
        },
        '& input': {
            paddingLeft: 0,
            userSelect: 'text',
            '&::placeholder': {
                color: theme.palette.text.subtle,
                opacity: 1,
            },
        },
        '& textarea': {
            resize: 'vertical',
            userSelect: 'text',
        },
        ...(status === 'disabled' && {
            '&.Mui-disabled': {
                background: theme.palette.background.default,
            },
            '&.Mui-disabled fieldset': {
                border: `1px solid ${theme.palette.border}`,
            },
            '&:hover fieldset': {
                border: `1px solid ${theme.palette.border}`,
            },
        }),
        ...(status === 'error' && {
            '& fieldset': {
                border: `1px solid ${theme.palette.error.main}`,
            },
            '&:hover fieldset': {
                border: `1px solid ${theme.palette.error.main}`,
            },
            '&.Mui-focused fieldset': {
                border: `2px solid ${theme.palette.error.main}`,
            },
        }),
        ...(status === 'success' && {
            '& fieldset': {
                border: `1px solid ${theme.palette.success.main}`,
            },
            '&:hover fieldset': {
                border: `1px solid ${theme.palette.success.main}`,
            },
            '&.Mui-focused fieldset': {
                border: `2px solid ${theme.palette.success.main}`,
            },
        }),
    },
    '& .MuiInputAdornment-root': {
        transition: 'opacity 300ms, margin-right 300ms ease',
        opacity: showIcon ? 1 : 0,
        marginRight: showIcon ? '8px' : styleVariant === 'rounded' ? '-12px' : '-20px',
    },
    '& p': {
        marginLeft: 8,
    },
}));
