import LocationsHeader from 'components/features/Locations/Header';
// import LocationsMap from 'components/features/Locations/Map';
import LocationsTable from 'components/features/Locations/Table';
import { useAddressesFromBusinessUnits, useCountriesFromBusinessUnits } from 'components/features/Locations/utils';

import { TabContent, EmbeddedDomainTabProps } from '..';

export const LocationsTab: React.FC<EmbeddedDomainTabProps> = ({ data, loading }) => {
    const addresses = useAddressesFromBusinessUnits(data);
    const addressCountries = useCountriesFromBusinessUnits(data?.business_units);
    return (
        <TabContent>
            <LocationsHeader skeleton={loading} countries={addressCountries} addresses={addresses} />
            {/* Disabled for now; the domain business unit addresses don't yet contain any coordinates. */}
            {/* <LocationsMap skeleton={loading} countries={addressCountries} company_name={data.name} prospect_addresses={addresses} /> */}
            <LocationsTable skeleton={loading} addresses={addresses} />
        </TabContent>
    );
};

export default LocationsTab;
