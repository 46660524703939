import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Transfer: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M9 20L4 16L9 12V15H22V17H9V20ZM15 12V9H2V7H15V4L20 8L15 12Z" />
    </SvgIcon>
);

export default Transfer;
