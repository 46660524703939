import { useMemo } from 'react';

import { formatISO, max, min, parseISO, sub } from 'date-fns';
import { get } from 'lodash';
import { makeNumber, isNotEmpty } from 'utilities';

import { FinancialStatement, isBreakdown, isFinancialValue } from 'api/types/FinancialStatement';
import { ChartYearDataPoint } from 'components/templates/charts';

import { formatStatementPeriod } from '../FinancialStatements/utils';
import { isValidStatement, ValidFinancialStatement } from '../types';
import { FinancialsChartSectionProps } from './FinancialsChartSection';
import { FinancialsOverviewProps } from './FinancialsOverview';
import { FinancialDataPoint } from './types';

const getNewestStatementWithValue = (statements: ValidFinancialStatement[], fieldPath: string) =>
    statements.reduce<ValidFinancialStatement | undefined>((latest, statement) => {
        return get(statement, fieldPath) &&
            (latest === undefined || statement.accounting_period.end > latest.accounting_period.end)
            ? statement
            : latest;
    }, undefined);

const getSecondNewestStatementWithValue = (
    statements: ValidFinancialStatement[],
    newestStatement: ValidFinancialStatement,
    fieldPath: string,
) =>
    statements.reduce<ValidFinancialStatement | undefined>((latest, statement) => {
        return get(statement, fieldPath) &&
            (latest === undefined ||
                (statement.accounting_period.end > latest.accounting_period.end &&
                    statement.accounting_period.end < newestStatement.accounting_period.end))
            ? statement
            : latest;
    }, undefined);

const getValueNumber = (value: unknown): number | undefined =>
    isFinancialValue(value)
        ? makeNumber(value.value)
        : isBreakdown(value)
          ? makeNumber(value.total?.value)
          : typeof value === 'number' || typeof value === 'string'
            ? makeNumber(value)
            : undefined;

const getValueCurrencyCode = (value: unknown): string | undefined =>
    isFinancialValue(value) ? value.currency_code : isBreakdown(value) ? value.total?.currency_code : undefined;

const getValueChange = (current: number, previous: number, absolute = false) =>
    absolute ? (current - previous) * 100 : (current - previous) / Math.abs(previous);

export function getNewestValueWithChange(
    statements: ValidFinancialStatement[],
    fieldPath: string,
    absoluteChange: boolean = false,
): FinancialDataPoint | undefined {
    const newestStatement = getNewestStatementWithValue(statements, fieldPath);
    const data = get(newestStatement, fieldPath);
    const value = getValueNumber(data);
    const currency = getValueCurrencyCode(data);

    if (value === undefined) {
        return undefined;
    }

    if (newestStatement && value !== undefined) {
        const previousStatement = getSecondNewestStatementWithValue(statements, newestStatement, fieldPath);
        const previousData = get(previousStatement, fieldPath);
        const previous = getValueNumber(previousData);
        const previousCurrency = getValueCurrencyCode(previousData);

        if (previous !== undefined && currency === previousCurrency) {
            const change = getValueChange(value, previous, absoluteChange);
            return {
                key: fieldPath,
                value,
                currency,
                previous,
                change,
                year: formatStatementPeriod(newestStatement),
            };
        }

        return {
            key: fieldPath,
            value,
            currency,
            previous: undefined,
            change: undefined,
            year: formatStatementPeriod(newestStatement),
        };
    }

    return undefined;
}

export function getFinancialValuesForOverview(
    statements: ValidFinancialStatement[] | undefined,
): FinancialsOverviewProps {
    if (!isNotEmpty(statements)) {
        return {};
    }
    const [revenueChart, ebitChart, employeesChart] = getChartData(statements, [
        'income_statement.revenue',
        'income_statement.ebit',
        'employees.absolute_count',
    ]);
    const fiscalPeriodEnd = getLatestValidStatement(statements)?.accounting_period.end;
    const revenue = getNewestValueWithChange(statements, 'income_statement.revenue');
    const ebit = getNewestValueWithChange(statements, 'income_statement.ebit');
    const employees = getNewestValueWithChange(statements, 'employees.absolute_count');

    return {
        fiscalPeriodEnd,
        revenue: revenue && {
            ...revenue,
            data: revenueChart?.data,
        },
        ebit: ebit && {
            ...ebit,
            data: ebitChart?.data,
        },
        employees: employees && {
            ...employees,
            data: employeesChart?.data,
        },
    };
}

export const useFinancialValuesForOverview = (
    statements: ValidFinancialStatement[] | undefined,
): FinancialsOverviewProps => useMemo(() => getFinancialValuesForOverview(statements), [statements]);

export function getFinancialValuesForChartsSection(
    statements: ValidFinancialStatement[] | undefined,
): Partial<FinancialsChartSectionProps> {
    if (!isNotEmpty(statements)) {
        return {};
    }
    const [revenueChart, ebitChart, employeesChart] = getChartData(statements, [
        'income_statement.revenue',
        'income_statement.ebit',
        'employees.absolute_count',
    ]);
    const revenue = getNewestValueWithChange(statements, 'income_statement.revenue');
    const ebit = getNewestValueWithChange(statements, 'income_statement.ebit');
    const employees = getNewestValueWithChange(statements, 'employees.absolute_count');

    return {
        revenue: revenue && {
            ...revenue,
            data: revenueChart.data,
        },
        ebit: ebit && {
            ...ebit,
            data: ebitChart.data,
        },
        employees: employees && {
            ...employees,
            data: employeesChart?.data,
        },
    };
}

export const useFinancialValuesForChartsSection = (
    statements: ValidFinancialStatement[] | undefined,
): FinancialsOverviewProps => useMemo(() => getFinancialValuesForChartsSection(statements), [statements]);

export function getLatestValidStatement(statements: FinancialStatement[]): ValidFinancialStatement | undefined {
    const newestValidStatement = statements
        .filter(isValidStatement)
        .sort((a, b) => b.accounting_period.end.localeCompare(a.accounting_period.end))[0];
    return newestValidStatement;
}

export function filterValues(
    statements: ValidFinancialStatement[],
    fieldPath: string,
    bounds: [firstPeriodEnd: string, lastPeriodEnd: string],
): ChartYearDataPoint[] {
    const [firstPeriodEnd, lastPeriodEnd] = bounds;
    const selectedStatements = statements
        .filter(
            ({ accounting_period }) =>
                accounting_period && accounting_period.end <= lastPeriodEnd && accounting_period.end >= firstPeriodEnd,
        )
        .sort((a, b) => a.accounting_period.end.localeCompare(b.accounting_period.end));

    return selectedStatements.map((statement) => {
        const value = getValueNumber(get(statement, fieldPath));
        return {
            year: Math.max(...statement.years),
            value,
        };
    });
}

export function findStatementBounds(
    statements: ValidFinancialStatement[],
    fieldPaths: string[],
    maxYears?: number,
): [firstPeriodEnd: string, lastPeriodEnd: string] | undefined {
    const statementsWithData = statements.filter((statement) =>
        fieldPaths.some((path) => get(statement, path) != null),
    );
    if (statementsWithData.length === 0) {
        return undefined;
    }
    const lastPeriodEnd = statementsWithData.reduce(
        (acc, curr) => formatISO(max([parseISO(acc), parseISO(curr.accounting_period.end)])),
        statementsWithData[0].accounting_period.end,
    );
    const lowerBoundary = maxYears !== undefined ? sub(parseISO(lastPeriodEnd), { years: maxYears }) : 0;
    const firstPeriodEnd = statementsWithData.reduce(
        (acc, curr) => formatISO(max([min([parseISO(acc), parseISO(curr.accounting_period.end)]), lowerBoundary])),
        statementsWithData[0].accounting_period.end,
    );
    return [firstPeriodEnd, lastPeriodEnd];
}

export function getChartData(
    statements: ValidFinancialStatement[],
    fieldPaths: string[],
    maxYears?: number,
): {
    key: string;
    data?: ChartYearDataPoint[];
}[] {
    const bounds = findStatementBounds(statements, fieldPaths, maxYears);
    if (!bounds) {
        return [];
    }

    return fieldPaths.map((path) => ({
        key: path,
        data: filterValues(statements, path, bounds),
    }));
}
