import React from 'react';

import { Tooltip } from '@mui/material';

import Chip from 'components/tokens/Chip';
import { useTrigger } from 'contexts/TriggerContext';

import { getTagName } from './TriggerFilters';

type Props = {
    tag?: string;
    label: React.ReactNode;
    onDelete?: () => void;
    onClick?: () => void;
    type?: 'event' | 'new_company';
};

const TriggerFilterChip: React.FC<Props> = ({ tag, label, onDelete, onClick, type = 'event' }) => {
    const [, , { allTags }] = useTrigger();

    return (
        <Tooltip
            title={
                type === 'new_company'
                    ? 'Vainu notices a change in company data from official registries or open data sources to detect a new company in saved list.'
                    : tag
                      ? `To narrow down the type of ${getTagName(
                            allTags,
                            tag,
                        )} you’re looking for, add keywords or headline filters. Vainu will crawl company websites and news articles to detect them.`
                      : undefined
            }
            placement="top"
        >
            <Chip
                label={label}
                onClick={onClick}
                onDelete={
                    onDelete
                        ? (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onDelete();
                          }
                        : undefined
                }
                sx={{ backgroundColor: 'common.white' }}
            />
        </Tooltip>
    );
};

export default TriggerFilterChip;
