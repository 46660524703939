import React from 'react';

import { Tooltip, ButtonProps as MuiButtonProps } from '@mui/material';
import clsx from 'clsx';

import StyledMuiButton from './styled';

export type ButtonProps = Omit<MuiButtonProps, 'variant'> & {
    /**
     * `critical` variant is only used for destructive or system changing actions which is permanent.
     */
    variant?: 'primary' | 'secondary' | 'tertiary' | 'flat' | 'critical';
    /** MUI prop types would automatically handle this when href attribute is used, but unfortunately it doesn't work
     * when composing on top of the MUI Button.
     */
    target?: string;
    /** MUI prop types would automatically handle this when href attribute is used, but unfortunately it doesn't work
     * when composing on top of the MUI Button.
     */
    rel?: string;
    component?: React.ReactNode;
    tooltip?: string | null;
    enterDelay?: number;
};

export const Button: React.FC<ButtonProps> = React.forwardRef(
    ({ children, variant = 'primary', className, tooltip, enterDelay = 500, ...props }, ref) => {
        const muiVariant =
            variant === 'primary' || variant === 'critical' ? 'contained' : variant === 'flat' ? 'text' : 'outlined';
        const button = (
            <StyledMuiButton
                className={clsx(className, `variant-${variant}`, !Boolean(children) && 'noLabel')}
                variant={muiVariant}
                color="primary"
                ref={ref}
                {...props}
            >
                {typeof children === 'string' ? <span>{children}</span> : children}
            </StyledMuiButton>
        );

        if (tooltip && !props.disabled) {
            return (
                <Tooltip title={tooltip} enterDelay={enterDelay}>
                    {button}
                </Tooltip>
            );
        }
        return button;
    },
);

export default Button;
