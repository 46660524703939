import {
    OverviewTab as CompanyOverviewTab,
    VehiclesTab as CompanyVehiclesTab,
    RegistersTab as CompanyRegistersTab,
    SocialNetworkTab as CompanySocialNetworkTab,
    WebProfileTab as CompanyWebProfileTab,
} from './company-tabs';
import {
    OverviewTab as DomainOverviewTab,
    WebProfileTab as DomainWebProfileTab,
    LocationsTab as DomainLocationsTab,
    SocialNetworkTab as DomainSocialNetworkTab,
    SimilarCompaniesTab as DomainSimilarCompaniesTab,
} from './domain-tabs';
import {
    OverviewTab as EmbeddedDomainOverviewTab,
    WebProfileTab as EmbeddedDomainWebProfileTab,
    LocationsTab as EmbeddedDomainLocationsTab,
    SocialNetworkTab as EmbeddedDomainSocialNetworkTab,
    SimilarCompaniesTab as EmbeddedDomainSimilarCompaniesTab,
} from './embedded-domain-tabs';
import {
    OverviewTab as OrganizationOverviewTab,
    SignalsTab as OrganizationSignalsTab,
    LocationsTab as OrganizationLocationsTab,
    FinancialsTab as OrganizationFinancialsTab,
    WebProfileTab as OrganizationWebProfileTab,
    SocialNetworkTab as OrganizationSocialNetworkTab,
    GroupStructureTab as OrganizationGroupStructureTab,
    RegistersTab as OrganizationRegistersTab,
    EmployeesTab as OrganizationEmployeesTab,
    SimilarCompaniesTab as OrganizationSimilarCompaniesTab,
    VehiclesTab as OrganizationVehiclesTab,
} from './organization-tabs';

export enum TabSlug {
    Overview = 'overview',
    Events = 'events',
    Locations = 'locations',
    Financials = 'financials',
    Vehicles = 'vehicles',
    Employees = 'employees',
    Registers = 'registers',
    SocialNetworks = 'social-networks',
    WebProfile = 'web-profile',
    GroupStructure = 'group-structure',
    SimilarCompanies = 'similar',
}

export const isValidTabSlug = (tab: string): tab is TabSlug => Object.values<string>(TabSlug).includes(tab);

export const companyTabs = [
    { name: TabSlug.Overview, label: 'Overview', component: CompanyOverviewTab },
    { name: TabSlug.Vehicles, label: 'Vehicles', component: CompanyVehiclesTab },
    { name: TabSlug.Registers, label: 'Registers', component: CompanyRegistersTab },
    { name: TabSlug.SocialNetworks, label: 'Social networks', component: CompanySocialNetworkTab },
    { name: TabSlug.WebProfile, label: 'Web profile', component: CompanyWebProfileTab },
];

export const domainTabs = [
    { name: TabSlug.Overview, label: 'Overview', component: DomainOverviewTab },
    { name: TabSlug.SimilarCompanies, label: 'Similar companies', component: DomainSimilarCompaniesTab },
    { name: TabSlug.WebProfile, label: 'Web profile', component: DomainWebProfileTab },
    { name: TabSlug.Locations, label: 'Locations', component: DomainLocationsTab },
    { name: TabSlug.SocialNetworks, label: 'Social networks', component: DomainSocialNetworkTab },
];

export const organizationTabs = [
    { name: TabSlug.Overview, label: 'Overview', component: OrganizationOverviewTab },
    { name: TabSlug.SimilarCompanies, label: 'Similar companies', component: OrganizationSimilarCompaniesTab },
    { name: TabSlug.Events, label: 'Events', component: OrganizationSignalsTab },
    { name: TabSlug.Vehicles, label: 'Vehicles', component: OrganizationVehiclesTab },
    { name: TabSlug.Locations, label: 'Locations', component: OrganizationLocationsTab },
    { name: TabSlug.Financials, label: 'Financials', component: OrganizationFinancialsTab },
    { name: TabSlug.Employees, label: 'Employees', component: OrganizationEmployeesTab },
    { name: TabSlug.SocialNetworks, label: 'Social networks', component: OrganizationSocialNetworkTab },
    { name: TabSlug.WebProfile, label: 'Web profile', component: OrganizationWebProfileTab },
    { name: TabSlug.GroupStructure, label: 'Group structure', component: OrganizationGroupStructureTab },
    { name: TabSlug.Registers, label: 'Registers', component: OrganizationRegistersTab },
];

export const defaultTab = TabSlug.Overview;

export const embeddedDomainTabs = [
    { name: TabSlug.Overview, label: 'Overview', component: EmbeddedDomainOverviewTab },
    { name: TabSlug.SimilarCompanies, label: 'Similar companies', component: EmbeddedDomainSimilarCompaniesTab },
    { name: TabSlug.WebProfile, label: 'Web profile', component: EmbeddedDomainWebProfileTab },
    { name: TabSlug.Locations, label: 'Locations', component: EmbeddedDomainLocationsTab },
    { name: TabSlug.SocialNetworks, label: 'Social networks', component: EmbeddedDomainSocialNetworkTab },
];
