import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GroupStructure = (props) => (
    <SvgIcon {...props}>
        <path d="M19.3267 16.557V13.1572C19.3267 12.408 18.7271 11.8095 17.9778 11.8095H12.5711V8.36362C13.9289 8.07594 14.8503 6.8092 14.7056 5.42874C14.561 4.04828 13.3971 3 12.0091 3C10.6211 3 9.45728 4.04828 9.31265 5.42874C9.16802 6.8092 10.0893 8.07594 11.4472 8.36362V11.8095H6.08546C5.33621 11.8095 4.73771 12.408 4.73771 13.1572V16.5424C3.62575 16.801 2.88461 17.8525 3.01481 18.9867C3.145 20.1209 4.10512 20.9772 5.24677 20.9772C6.38842 20.9772 7.34854 20.1209 7.47874 18.9867C7.60893 17.8525 6.86779 16.801 5.75583 16.5424V13.4869C5.75583 13.1719 6.01008 12.9176 6.32508 12.9176H11.4472V16.5424C10.3519 16.8253 9.63782 17.8785 9.78044 19.0008C9.92306 20.123 10.8779 20.9642 12.0091 20.9642C13.1404 20.9642 14.0952 20.123 14.2378 19.0008C14.3804 17.8785 13.6664 16.8253 12.5711 16.5424V12.9322H17.7371C18.0374 12.9322 18.3063 13.1876 18.3063 13.5015V16.5277C17.1876 16.7548 16.417 17.7846 16.5148 18.9219C16.6126 20.0592 17.5477 20.9424 18.6887 20.9752C19.8298 21.0079 20.814 20.1798 20.9768 19.05C21.1397 17.9202 20.4294 16.8478 19.3256 16.557" />
    </SvgIcon>
);

export default GroupStructure;
