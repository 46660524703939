import { AxiosInstance } from 'axios';

import { UserData, AccountData, ProfileData } from './types/Usage';

export const getUserData = async (axios: AxiosInstance) =>
    (await axios.get<UserData>(`/mapi/ondemand-usage-data/user_data/`)).data;

export const getAccountData = async (axios: AxiosInstance) =>
    (await axios.get<AccountData>(`/mapi/ondemand-usage-data/account_data/`)).data;

export const getUserProfile = async (axios: AxiosInstance) => (await axios.get<ProfileData>(`/api/v2/profile/`)).data;
