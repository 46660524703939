import { Trigger, WorkFlowDestination } from 'api/types/Trigger';
import { Destination, State } from 'contexts/TriggerContext';

import { isValidTrigger, isActiveTrigger } from './triggerValidity';

interface Target {
    target: string;
}

function getTargetForSystem(destination: Destination): Target[] {
    const { system, crm_objects = [], emails = [], urls = [], slackChannel = [] } = destination;
    if (system === 'slack') {
        return slackChannel.map((i) => ({ target: i }));
    }
    if (system === 'email') {
        return emails.map((i) => ({ target: i }));
    }
    if (system === 'webhook') {
        return urls.map((i) => ({ target: i }));
    }
    if (['dynamics', 'pipedrive', 'salesforce', 'superoffice', 'hubspot', 'salesforcesandbox'].includes(system)) {
        return crm_objects.map((i) => ({ target: i }));
    }
    console.error('system not regonized');
    return [];
}

export function stateDestinationToDBdestination(destination: Destination): WorkFlowDestination | null {
    const { system, active, extra = {}, assignTo = [] } = destination;
    if (active) {
        return {
            destination_type: system,
            active: true,
            targets: getTargetForSystem(destination),
            extra,
            assign_to: assignTo,
        };
    }
    return null;
}

export function mapStateToTriggerDBObject(state: State): [Trigger, boolean, string[]] {
    const {
        name = '',
        queries,
        query,
        id,
        notifyType,
        notify = 'none',
        deliveryTime,
        destinations,
        dailyLimit = 0,
        source = '',
        status,
        messageContext,
        trackChanges,
        trackingLogic,
    } = state;
    const workflowSettingsDestinations: WorkFlowDestination[] = [];
    (destinations as Destination[]).forEach((destination) => {
        const dest = stateDestinationToDBdestination(destination);
        if (dest) {
            workflowSettingsDestinations.push(dest);
        }
    });
    const trigger: Trigger = {
        name,
        queries,
        query,
        id,
        notify_type: notifyType as string,
        notify,
        utc_office_hours: deliveryTime,
        workflow_settings: {
            daily_limit: dailyLimit,
            destinations: workflowSettingsDestinations,
            target_group_source: source,
            crm_source: null,
        },
        message_context: { context_items: messageContext || [] },
        status,
        tracking_logic: trackingLogic,
        track_changes: trackChanges,
    };

    const [is_valid, errors] = isValidTrigger(trigger);
    const is_active = isActiveTrigger(trigger);
    return [{ ...trigger, is_valid }, is_active, errors];
}
