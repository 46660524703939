import React, { useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';

import Icon, { IconType, IconProps } from '../Icon';
import { StyledMUITextField } from './styled';
import { Status, TextFieldProps } from './types';

type InputIconOptions = 'disabled' | 'error' | 'success';

const statusIcons: Record<InputIconOptions, IconProps> = {
    disabled: {
        type: 'Disabled',
        color: 'grey.500',
    },
    error: {
        type: 'ErrorOutline',
        color: 'error.main',
    },
    success: {
        type: 'CheckBig',
        color: 'success.main',
    },
};

export const TextField: React.FC<TextFieldProps> = React.forwardRef(
    (
        {
            multiline = false,
            compact = false,
            status = 'default',
            placeholder,
            errorText,
            icon,
            iconColor = 'text.subtle',
            variant = 'default',
            iconBehavior = 'hideOnTyping',
            InputProps,
            onFocus,
            onBlur,
            ...props
        },
        ref,
    ) => {
        const [isSelected, setIsSelected] = useState(false);
        const getAdornment = (status: Status, icon?: IconType) => {
            if (status !== 'default') {
                return {
                    endAdornment: (
                        <InputAdornment position="end" disablePointerEvents={true}>
                            <Icon sx={{ height: 20, width: 20 }} {...statusIcons[status]} />
                        </InputAdornment>
                    ),
                };
            }
            if (icon && status === 'default') {
                return {
                    startAdornment: (
                        <InputAdornment position="start" disablePointerEvents={true}>
                            <Icon sx={{ height: 20, width: 20, color: iconColor }} type={icon} />
                        </InputAdornment>
                    ),
                };
            }
        };

        const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
            setIsSelected(true);
            if (onFocus) {
                onFocus(event);
            }
        };

        const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
            setIsSelected(false);
            if (onBlur) {
                onBlur(event);
            }
        };

        return (
            <StyledMUITextField
                inputRef={ref}
                styleVariant={variant}
                isEmpty={!props.value}
                showIcon={
                    iconBehavior === 'showAlways'
                        ? true
                        : iconBehavior === 'hideOnFocus'
                          ? !isSelected && !props.value
                          : !props.value
                }
                placeholder={placeholder}
                size={compact ? 'small' : 'medium'}
                status={status}
                multiline={multiline}
                disabled={status === 'disabled'}
                error={status === 'error'}
                minRows={4}
                InputProps={{
                    ...(!multiline ? getAdornment(status, icon) : {}),
                    ...InputProps,
                }}
                helperText={status === 'error' && errorText}
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...props}
            />
        );
    },
);

export default TextField;
