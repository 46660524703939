import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import MUITextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

export const StyledSelectLabel = styled(InputLabel)({
    margin: '10px auto',
    color: '#0A0A0A',
});

export const StyledOverFlowEllipsis = styled(Grid)({
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const StyledSelectLabelWrapper = styled('span')({
    marginRight: 16,
    fontSize: 14,
    verticalAlign: 'middle',
});

export const StyledSearchListItem = styled(MenuItem)({
    padding: 0,
    background: '#fff !important',
});

export const StyledSearchField = styled(MUITextField)({
    marginBottom: 16,
    fontSize: 14,
    '& input': {
        border: '2px solid #0A0A0A',
        borderRadius: '4px',
        padding: 8,
        userSelect: 'text',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #0A0A0A',
        borderRadius: '4px',
    },
    '&:hover .MuiOutlinedInput-input': {
        color: '#3F3F3F',
    },
    '& .MuiInputLabel-outlined': {
        color: '#898989',
        fontSize: 14,
        paddingTop: 2,
    },
    '& input:focused': {
        border: '2px solid #0A0A0A',
        borderRadius: '4px',
    },
});

export const StyledDivider = styled('hr')({
    margin: '6px 8px',
    color: '#E1E1E1',
    display: 'block',
});

export const StyledOptionsNotFound = styled(MenuItem)({
    fontWeight: 'normal',
    fontSize: 14,
    color: '#898989',
    cursor: 'default',
    paddingLeft: 8,
    paddingRight: 8,
    background: '#fff !important',
});

export const StyledMenuItem = styled(MenuItem)({
    minWidth: 170,
    width: 'auto',
    color: '#3F3F3F',
    fontSize: 14,
    padding: `6px 8px`,
    '&.Mui-selected': {
        background: '#E1E1E1',
        borderRadius: 4,
    },
});

/**
 *
 *
 * LabelWithToolTip styling
 *
 *
 */

export const StyledGridItem = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    '& svg': {
        paddingTop: 0,
    },
});

export const StyledTooltipFieldExample = styled('span')({
    fontStyle: 'italic',
    color: '#898989',
    display: 'block',
});

export const StyledListCount = styled('div')({
    background: '#F9F9F9',
    border: '1px solid #E1E1E1',
    borderRadius: 33,
    width: 18,
    height: 18,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
        color: '#4F4F4F',
        fontWeight: 500,
        fontSize: 10,
        opacity: 0.8,
    },
});

export const StyledTooltip = styled(Tooltip)({
    tooltip: {
        fontSize: 12,
        color: '#fff',
        background: '#0A0A0A',
        padding: '8px',
        boxShadow: '0px 1px 2px rgba(10, 10, 10, 0.1), 0px 4px 12px rgba(10, 10, 10, 0.15)',
        borderRadius: 4,
        maxWidth: 200,
    },
    arrow: {
        color: '#0A0A0A',
    },
    popperArrow: {
        marginLeft: 2,
    },
});
