import { createContext, useContext, useState, useCallback, useMemo } from 'react';

import { Database } from './types/databases';

export type EmbeddedQueryData = Partial<{
    database: Database;
    fields: string[];
    from_request: string[];
    limit: number;
    offset: number;
    order: string;
    query: object;
    use_filters_v2: true;
}>;

export type EmbeddedQuery =
    | (EmbeddedQueryData & { endpoint: string })
    | {
          queries: Record<string, EmbeddedQueryData>;
      };

export type EmbeddedTokenContextValue = {
    token: string | null;
    query: EmbeddedQuery | null;
    setQuery: (query: EmbeddedQuery, token: string) => void;
    clearQuery: () => void;
};

export const EmbeddedQueryContext = createContext<EmbeddedTokenContextValue>({} as EmbeddedTokenContextValue);

type EmbeddedQueryProviderProps = {
    children?: React.ReactNode;
};

export const EmbeddedQueryProvider: React.FC<EmbeddedQueryProviderProps> = ({ children }) => {
    const { Provider } = EmbeddedQueryContext;

    const [query, setQuery] = useState<EmbeddedQuery | null>(null);
    const [token, setToken] = useState<string | null>(null);

    const setQueryAndToken = useCallback((query: EmbeddedQuery, token: string) => {
        setQuery(query);
        setToken(token);
    }, []);

    const clearQueryAndToken = useCallback(() => {
        setQuery(null);
        setToken(null);
    }, []);

    const value = useMemo(
        () => ({
            token,
            query,
            setQuery: setQueryAndToken,
            clearQuery: clearQueryAndToken,
        }),
        [clearQueryAndToken, query, setQueryAndToken, token],
    );

    return <Provider value={value}>{children}</Provider>;
};

export const useEmbeddedQueryContext = () => useContext(EmbeddedQueryContext);
