import { Box, Skeleton, SxProps } from '@mui/material';

import Frame, { Padding } from 'components/tokens/Frame';
import Typography from 'components/tokens/Typography';

type HeaderBoxProps = {
    icon?: React.ReactNode;
    title?: React.ReactNode;
    // titleSize?: 'medium' | 'small';
    label?: React.ReactNode;
    number?: React.ReactNode;
    padding?: Padding;
    multiline?: boolean;
    variant?: 'normal' | 'flat-grey';
    skeleton?: boolean;
    sx?: SxProps;
};

const textRowGutter: Record<Padding, number> = {
    none: 0,
    small: 0,
    medium: 0,
    large: 1.5,
    xlarge: 2,
};

export const HeaderBox: React.FC<HeaderBoxProps> = ({
    icon,
    title,
    label,
    number,
    padding = 'medium',
    multiline = false,
    variant = 'normal',
    skeleton = false,
    sx,
}) =>
    skeleton ? (
        <Skeleton variant="rounded" height={72} sx={{ backgroundColor: 'grey.100' }} />
    ) : (
        <Frame
            padding={padding}
            sx={{ display: 'flex', alignItems: 'flex-start', width: '100%', overflow: 'hidden', ...sx }}
            elevation={variant === 'flat-grey' ? 0 : 1}
            border={variant === 'flat-grey'}
            variant={variant === 'flat-grey' ? 'grey' : 'white'}
        >
            {icon && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 40,
                        width: 40,
                        minWidth: 40,
                        backgroundColor: 'common.white',
                        border: 1,
                        borderColor: 'border',
                        borderRadius: 2,
                        marginRight: 1,
                    }}
                >
                    {icon}
                </Box>
            )}
            <Box sx={{ flex: 1, minWidth: 0 }}>
                <Typography
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginBottom: textRowGutter[padding] ?? 0,
                    }}
                    component="div"
                    variant="body1"
                    weight="semibold"
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        overflow: 'hidden',
                        ...(multiline ? {} : { textOverflow: 'ellipsis', whiteSpace: 'nowrap' }),
                    }}
                    component="div"
                    variant="tiny"
                    color="subtle"
                >
                    {label}
                </Typography>
            </Box>
            {number !== undefined && (
                <Typography variant="h4" sx={{ lineHeight: 1, textAlign: 'right', paddingLeft: 1 }}>
                    {number}
                </Typography>
            )}
        </Frame>
    );

export default HeaderBox;
