import { useCallback, useRef } from 'react';

import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { filterDefinitions } from 'components/features/Filters/filters/filterDefinitions';
import { VainuCustomIndustryFilterDefinitionValue } from 'components/features/Filters/filters/Industry/types';
import { FilterID, NodeValue } from 'components/features/Filters/FilterTypes';
import { getFirstKey } from 'utilities/objectUtils';

import { UseGroupProps } from '../../common/useGroup';
import { GroupWithPath } from '../../types';
import { getNodes } from './utils';

export function useVainuCustomIndustryGroup(props: Omit<UseGroupProps, 'createNewGroup' | 'updateGroup'>) {
    const { applyChanges, removeGroup, addNewGroup, updateGroups, ids } = props;
    const { filteredIds, filters } = useGetVainuCustomIndustryFilters({ ids });

    const { handleAddFilter, handleFilterRemove } = useVainuCustomIndustryGroupActions({
        addNewGroup,
        removeGroup,
    });

    const handleApplyChanges = useCallback(() => {
        const updatedGroups: Record<string, NodeValue | undefined> = {};
        ids.forEach((id) => {
            const result = filters[id];
            const filteredResult = getFilteredResults(result);

            if (!filteredResult?.values.length) {
                updatedGroups[id] = undefined;
            }
        });

        if (!isNil(updatedGroups)) {
            updateGroups?.(updatedGroups);
        }
        applyChanges();
    }, [applyChanges, updateGroups, ids, filters]);
    return {
        filteredIds,
        filters,
        handleApplyChanges,
        handleAddFilter,
        handleFilterRemove,
    };
}

interface UseGetVainuCustomIndustryFiltersProps {
    ids: string[];
}

export function useGetVainuCustomIndustryFilters(props: UseGetVainuCustomIndustryFiltersProps) {
    const { ids: rawIds } = props;
    const { getValues } = useFormContext();
    const cache = useRef<ReturnType<typeof getNodes> | null>(null);
    const nodes = getNodes(rawIds, getValues);

    const cachedNodes: ReturnType<typeof getNodes> = {
        filteredIds: nodes.filteredIds,
        filters: {},
    };

    rawIds.forEach((id) => {
        if (nodes.filters[id]) {
            if (
                cache.current?.filters[id] &&
                getFirstKey(cache.current.filters[id].filters[0].value) ===
                    getFirstKey(nodes.filters[id]?.filters[0].value)
            ) {
                cachedNodes.filters[id] = cache.current.filters[id];
                return;
            }

            cachedNodes.filters[id] = nodes.filters[id];
        }
    });

    cache.current = cachedNodes;

    return cachedNodes;
}

type UseVainuCustomIndustryGroupActionsProps = Pick<UseGroupProps, 'removeGroup' | 'addNewGroup'>;

export function useVainuCustomIndustryGroupActions(props: UseVainuCustomIndustryGroupActionsProps) {
    const { removeGroup, addNewGroup } = props;

    const handleFilterRemove = useCallback(
        (groupId: string) => {
            removeGroup(groupId);
        },
        [removeGroup],
    );

    const handleAddFilter = useCallback(() => {
        const createFilter = filterDefinitions[FilterID.vainu_custom_industry]?.createFilter;

        if (!createFilter) {
            return;
        }

        addNewGroup(createFilter());
    }, [addNewGroup]);

    return { handleAddFilter, handleFilterRemove };
}

export function getFilteredResults(filter: GroupWithPath) {
    const filterInvalidValues = (
        filterDefinitions[FilterID.vainu_custom_industry] as VainuCustomIndustryFilterDefinitionValue
    )?.filterInvalidValues;

    if (!filterInvalidValues) {
        return;
    }

    return filterInvalidValues(filter?.filters?.[0].value);
}
