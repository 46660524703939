import { Box, SxProps } from '@mui/material';
import { assertArray } from 'utilities';

import { List } from 'api/types/List';
import { ProfileData, ProfileType, isDomainData } from 'api/types/Organization';
import { useCRMExport, CRMExport, CRMS_EXPORT_OPTIONS } from 'components/modules/connectors/crm-export';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { getProfilePath } from 'components/modules/profiles/Profile';
import { GroupLevelTag } from 'components/templates/CompanyCard';
import CrmOwnerIndicator from 'components/templates/CompanyCard/CrmOwnerIndicator';
import { getFullLocation } from 'components/templates/CompanyCard/utils';
import {
    fieldLabel,
    formatDomainPriorityFields,
    formatPriorityFields,
    getPriorityFieldsToDisplay,
} from 'components/templates/CompanyCardPriorityFields/utils';
import ListMenu from 'components/templates/ListMenu';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Tags from 'components/templates/Tags';
import Button from 'components/tokens/Button';
import Checkbox from 'components/tokens/Checkbox';
import Icon, { IconType } from 'components/tokens/Icon';
import CRMIcon from 'components/tokens/Icon/CRMIcon';
import Link from 'components/tokens/Link';
import NumberDataPoint from 'components/tokens/NumberDataPoint';
import Tag from 'components/tokens/Tag';
import { Database, isUploaded, useListsContext } from 'contexts/ListsContext';
import { usePermissionContext } from 'contexts/PermissionContext';
import { CountryDatabase } from 'contexts/types/databases';
import { DomainDataResult, OrganizationResult, ProfileResult } from 'contexts/types/FilterCompanies';
import useAddToStaticList from 'hooks/useAddToStaticList';
import { combineSx } from 'utilities/mui';

import { extractDomainName, formatURL } from '../../BasicInfo/utils';
import { getIndustryTagsFromIndustriesVerbose, getIndustryTagsFromVCI } from '../../Industries/utils';
import { getHQ } from '../../Locations/utils';
import NoFilterResults from '../CompanyGridView/NoFilterResults';
import { ContactsCount } from '../ContactsCount/ContactsCount';
import { CompanyRow } from './cells/CompanyName';

type TableData = DomainDataResult[] | OrganizationResult[] | ProfileData[];
export type CompanyListViewProps = {
    tableData: TableData;
    allCompaniesSelected?: boolean;
    selectedCompanies?: Array<string>;
    unselectedCompanies?: Array<string>;
    loading?: boolean;
    sx?: SxProps;
    onOpenProfile?: (profileType: ProfileType, profileId: string) => void;
    onSelectProfile?: (value: string, isShiftClick: boolean) => void;
    onUnselectProfile?: (value: string, isShiftClick: boolean) => void;
    onRemoveProfile?: (domain: string) => void;
    vainuSegment?: string;
    hideActions?: boolean;
    hideCrmIndicator?: boolean;
    /** If the table is showing list results, give the list id to exclude the list from the selection
     * of static lists where to add the company. */
    currentListId?: List['id'];
};

const TABLE_DATA_EMPTY: TableData = new Array(5).fill({});

export const CompanyListView: React.FC<CompanyListViewProps> = ({
    tableData,
    selectedCompanies = [],
    unselectedCompanies = [],
    allCompaniesSelected = false,
    loading = false,
    sx,
    onOpenProfile,
    onSelectProfile,
    onUnselectProfile,
    onRemoveProfile,
    // todo: pass vainuSegment to CRM export when the last export MR is merged
    vainuSegment,
    hideActions,
    hideCrmIndicator,
    currentListId,
}) => {
    const { selectedList, database } = useListsContext();
    const { hasIntegration } = usePermissionContext();
    const { staticListOptions, addToList } = useAddToStaticList({ excludedListId: currentListId });
    const [exportState, { handleOpenExportDialog, handleCloseExportDialog }] = useCRMExport();

    const exportableIntegrations = CRMS_EXPORT_OPTIONS.filter((item) => hasIntegration(item.integration));

    if (tableData.length === 0 && !loading) {
        return (
            <Box sx={combineSx({ height: '50vh' }, sx)}>
                <NoFilterResults />
            </Box>
        );
    }

    const profileType = tableData[0]?.profileType;

    const priorityFields = getPriorityFieldsToDisplay({
        isDomainDb: tableData.length ? isDomainData(tableData) : selectedList?.type.includes('domain'),
        priorityFields: ['staff_number', 'turn_over'],
        viewType: 'list',
    });

    const onSendToCrm = (crmName: CrmName, prospect: ProfileResult) => {
        handleOpenExportDialog({
            selectionType: 'selection',
            exportType: 'company',
            crm: crmName,
            database: database as Database,
            companyIds: [prospect.id],
            defaultVainuTags: selectedList ? [selectedList.name] : [],
        });
    };

    const onMenuAction = (profile: ProfileData, action: string) => {
        if (action === 'remove') {
            onRemoveProfile?.(profile.id);
        } else if (action === 'createNewList') {
            addToList(action, [profile.id]);
        } else if (action.startsWith('sendToCRM:')) {
            onSendToCrm?.(action.split(':')[1] as CrmName, profile);
        }
    };

    const crmType = exportableIntegrations.length === 1 ? exportableIntegrations[0].action : 'multiple';

    return (
        <>
            <Box sx={sx}>
                <SortableTable<ProfileData> data={loading ? TABLE_DATA_EMPTY : tableData} rowKeyField="id">
                    {[
                        !hideActions && (
                            <SortableTableColumn<ProfileData>
                                field=""
                                keyValue="selection"
                                key="selection"
                                sx={{ padding: 0.5 }}
                                headerSx={{ padding: 0 }}
                                sortable={false}
                                loading={loading}
                                skeletonWidth={38}
                            >
                                {(row) => {
                                    const isSelected = allCompaniesSelected
                                        ? !unselectedCompanies.includes(row.id)
                                        : selectedCompanies.includes(row.id);

                                    return (
                                        <Checkbox
                                            checked={isSelected}
                                            onClick={(e) => {
                                                if (isSelected) {
                                                    onUnselectProfile?.(row.id, e.shiftKey);
                                                } else {
                                                    onSelectProfile?.(row.id, e.shiftKey);
                                                }
                                            }}
                                        />
                                    );
                                }}
                            </SortableTableColumn>
                        ),
                        <SortableTableColumn<ProfileData>
                            label="Company"
                            field="name"
                            key="name"
                            sx={{ paddingLeft: 0 }}
                            headerSx={{ paddingLeft: 0, minWidth: 210 }}
                            sortable={false}
                            loading={loading}
                            skeletonWidth={120}
                        >
                            {(row) => (
                                <CompanyRow
                                    href={getProfilePath(row.profileType, row.id)}
                                    onClick={onOpenProfile && (() => onOpenProfile(row.profileType, row.id))}
                                    name={row.name}
                                    loading={loading}
                                    logo={row.logo_url}
                                />
                            )}
                        </SortableTableColumn>,
                        !hideCrmIndicator && exportableIntegrations.length !== 0 && (
                            <SortableTableColumn<ProfileData>
                                label={<CRMIcon fontSize="small" crm={crmType} type="inCrm" />}
                                field=""
                                keyValue="crm"
                                key="crm"
                                sortable={false}
                                loading={loading}
                                skeletonWidth={60}
                                sx={{ paddingY: 0.5 }}
                            >
                                {(row) => (
                                    <Box sx={{ display: 'flex', marginLeft: '2px' }}>
                                        <CrmOwnerIndicator
                                            id={row.id}
                                            profileType={row.profileType}
                                            logo={row.logo_url}
                                            name={row.name}
                                            onSendToCrm={(crm: CrmName) => onSendToCrm(crm, row)}
                                        />
                                    </Box>
                                )}
                            </SortableTableColumn>
                        ),
                        <SortableTableColumn<ProfileData>
                            label="Headquarters"
                            field="business_units"
                            key="business_units"
                            sortable={false}
                            loading={loading}
                            skeletonWidth={80}
                        >
                            {(row) => getFullLocation(getHQ(assertArray(row.business_units)))}
                        </SortableTableColumn>,
                        <SortableTableColumn<ProfileData>
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                    Domain <Icon type="ExternalLink" color="text.subtle" fontSize="mini" />
                                </Box>
                            }
                            field="website"
                            key="website"
                            sortable={false}
                            loading={loading}
                            skeletonWidth={80}
                        >
                            {(row) =>
                                row.website ? (
                                    <Link sx={{ color: 'text.body' }} href={formatURL(row.website)} toNewTab="nofollow">
                                        {extractDomainName(row.website)}
                                    </Link>
                                ) : (
                                    '-'
                                )
                            }
                        </SortableTableColumn>,
                        profileType === 'organization' && (
                            <SortableTableColumn<ProfileData>
                                label="Contacts"
                                field="contacts"
                                key="contacts"
                                sortable={false}
                                loading={loading}
                                skeletonWidth={60}
                            >
                                {(row) => {
                                    return (
                                        <ContactsCount
                                            businessId={row.id}
                                            logo={row.logo_url}
                                            companyName={row.name}
                                            country={database as CountryDatabase}
                                        />
                                    );
                                }}
                            </SortableTableColumn>
                        ),
                        profileType === 'domain' && (
                            <SortableTableColumn<ProfileData>
                                label="Vainu Custom Industries"
                                field="vainu_custom_industries"
                                key="vainu_custom_industries"
                                sortable={false}
                                loading={loading}
                                skeletonWidth={60}
                            >
                                {(row) => {
                                    const tags = getIndustryTagsFromVCI(row);
                                    return (
                                        <Tags
                                            tags={tags}
                                            cutoff={tags.length > 2 ? 1 : 2}
                                            sx={{ justifyContent: 'flex-start', maxWidth: 200 }}
                                        />
                                    );
                                }}
                            </SortableTableColumn>
                        ),
                        profileType === 'organization' && (
                            <SortableTableColumn<ProfileData>
                                label="Industries (official)"
                                field="industries_verbose"
                                key="industries_verbose"
                                sortable={false}
                                loading={loading}
                                skeletonWidth={60}
                            >
                                {(row) => {
                                    const tags = getIndustryTagsFromIndustriesVerbose(row as OrganizationResult);
                                    return (
                                        <Tags
                                            tags={tags}
                                            cutoff={tags.length > 2 ? 1 : 2}
                                            sx={{ justifyContent: 'flex-start', maxWidth: 120 }}
                                        />
                                    );
                                }}
                            </SortableTableColumn>
                        ),
                        profileType === 'organization' && (
                            <SortableTableColumn<ProfileData>
                                label="Group entity"
                                field="group_data"
                                key="group_data"
                                sortable={false}
                                loading={loading}
                                skeletonWidth={60}
                            >
                                {(row) => {
                                    const groupLevel = 'level_in_group' in row ? row.level_in_group : null;
                                    return <GroupLevelTag level={groupLevel} />;
                                }}
                            </SortableTableColumn>
                        ),
                        ...priorityFields.map((field) => (
                            <SortableTableColumn<ProfileData>
                                key={field}
                                label={fieldLabel[field]}
                                field={field}
                                sortable={false}
                                loading={loading}
                                skeletonWidth={60}
                            >
                                {(row) => {
                                    const formattedPriorityField = isDomainData(row)
                                        ? formatDomainPriorityFields(row, field)
                                        : formatPriorityFields(row, field);
                                    const groupLevel = 'level_in_group' in row ? row.level_in_group : null;

                                    return (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                gap: '6px',
                                            }}
                                        >
                                            <NumberDataPoint
                                                key={formattedPriorityField.field}
                                                variant="small"
                                                align="left"
                                                hideLabel
                                                {...formattedPriorityField}
                                            />
                                            {groupLevel === 1 &&
                                                ['staff_number', 'turn_over'].includes(
                                                    formattedPriorityField.field,
                                                ) && <Tag label="GROUP" />}
                                        </Box>
                                    );
                                }}
                            </SortableTableColumn>
                        )),
                        !hideActions && (
                            <SortableTableColumn<ProfileData>
                                label=""
                                field=""
                                keyValue="actions"
                                key="actions"
                                sortable={false}
                                loading={loading}
                                skeletonWidth={60}
                            >
                                {(row) =>
                                    !loading && (
                                        <ListMenu
                                            anchorElement={
                                                <Button
                                                    size="small"
                                                    variant="flat"
                                                    startIcon={
                                                        <Icon
                                                            fontSize="mini"
                                                            type="MoreHorizontal"
                                                            color="primary.main"
                                                        />
                                                    }
                                                />
                                            }
                                            items={[
                                                {
                                                    action: 'noop',
                                                    label: 'Add to custom list',
                                                    icon: 'ListPlus',
                                                    endIcon: 'ChevronBigRight',
                                                    endIconColor: 'grey.500',
                                                    children: staticListOptions || [],
                                                    staticOptions: [
                                                        {
                                                            action: 'createNewList',
                                                            label: 'New custom list',
                                                            icon: 'Plus',
                                                        },
                                                    ],
                                                },
                                                ...(exportableIntegrations.length
                                                    ? [
                                                          {
                                                              action: 'noop2',
                                                              label: 'Send to',
                                                              icon: 'Export' as IconType,
                                                              endIcon: 'ChevronBigRight' as IconType,
                                                              endIconColor: 'grey.500',
                                                              children: exportableIntegrations.map((item) => ({
                                                                  action: `sendToCRM:${item.action}`,
                                                                  label: item.label,
                                                                  icon: item.icon,
                                                              })),
                                                              searchable: false,
                                                          },
                                                      ]
                                                    : []),
                                                ...(selectedList && isUploaded(selectedList)
                                                    ? [
                                                          {
                                                              action: 'remove',
                                                              label: 'Remove',
                                                              icon: 'OffOutlineClose' as IconType,
                                                          },
                                                      ]
                                                    : []),
                                            ]}
                                            onClick={(action) => onMenuAction(row, action)}
                                            disablePortal={false}
                                            popperPlacement="bottom-end"
                                        />
                                    )
                                }
                            </SortableTableColumn>
                        ),
                    ]}
                </SortableTable>
            </Box>
            <CRMExport {...exportState} onClose={handleCloseExportDialog} />
        </>
    );
};

export default CompanyListView;
