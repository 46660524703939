import React from 'react';

import { Divider, MenuItem as MuiMenuItem } from '@mui/material';

import { Menu, MenuItemProps } from 'components/templates/Menu';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { useListsContext, Database } from 'contexts/ListsContext';
import { usePermissionContext } from 'contexts/PermissionContext';

export const databaseOptions: MenuItemProps<Database>[] = [
    { action: 'DOMAIN_DATA_BASIC', label: 'Global', icon: 'Globe' },
    { action: 'DK', label: 'Denmark', icon: 'FlagDenmark' },
    { action: 'FI', label: 'Finland', icon: 'FlagFinland' },
    { action: 'NO', label: 'Norway', icon: 'FlagNorway' },
    { action: 'SE', label: 'Sweden', icon: 'FlagSweden' },
];

const DatabaseNavigation: React.FC = () => {
    const { changeDatabase, database } = useListsContext();
    const { countryPermissions } = usePermissionContext();

    // When changing database, only show lists from that DB.
    const allowedDatabaseOptions = databaseOptions.filter((item) => countryPermissions.includes(item.action));
    const activeDb = allowedDatabaseOptions.find((option) => option.action === database);

    // @TODO - handle database change
    const handleMenuItemClick = (db: Database) => {
        changeDatabase(db);
    };

    if (!allowedDatabaseOptions.length) {
        return null;
    }
    return (
        <Menu
            anchorElement={
                <Button
                    variant="flat"
                    startIcon={activeDb?.icon && <Icon type={activeDb.icon} fontSize="medium" />}
                    sx={{
                        minWidth: 105,
                    }}
                >
                    <Typography variant="small" color="dark" style={{ height: 17 }}>
                        {activeDb?.label ?? '[no database selected]'}
                    </Typography>
                </Button>
            }
            items={allowedDatabaseOptions}
            sx={{ minWidth: 200 }}
            onClick={handleMenuItemClick}
            selectedAction={activeDb?.action}
        >
            <MuiMenuItem
                disableGutters
                disabled
                sx={{
                    paddingLeft: 1,
                    fontSize: 12,
                    '&.Mui-disabled': {
                        opacity: 0.9,
                    },
                }}
            >
                Choose your database
            </MuiMenuItem>
            <Divider sx={{ mt: '0!important' }} />
        </Menu>
    );
};

export default DatabaseNavigation;
