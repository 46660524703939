import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { isImportListAsyncProcess } from 'api/types/UserAsyncProcess';
import OverflowContainer from 'components/layout/OverflowContainer';
import { useFileUploadContext } from 'contexts/FileUploadContext';
import { useListsContext } from 'contexts/ListsContext';

import { FilterGroupProps } from '../../../FilterTypes';
import { ErrorMessage, DefaultView, ErrorView, ProcessingView, ProcessingViewDone } from './View';

export const UploadListGroup: React.FC<FilterGroupProps> = ({ applyChanges }) => {
    const { selectedListId, database } = useListsContext();
    const { fileUploadProcesses, /*cancelUpload,*/ uploadFile } = useFileUploadContext();

    const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);
    const [isUploadStarted, setIsUploadStarted] = useState(false);
    const topWrapperRef = useRef<HTMLDivElement | null>(null);

    const relevantCSVProcesses = fileUploadProcesses
        .filter(isImportListAsyncProcess)
        .filter((process) => process?.meta_data?.target_group_id === selectedListId);
    const ongoingProcess = relevantCSVProcesses.find((process) => process.state === 'process');

    const getOngoingProcessValues = () => {
        let progress = 0;
        let current = 0;
        let total = 0;
        if (ongoingProcess?.progress != null && ongoingProcess?.progress_max) {
            current = ongoingProcess.progress;
            total = ongoingProcess.progress_max;
            progress = (current / total) * 100;
        }
        return {
            progress,
            current,
            total,
        };
    };

    const getFinishedProcessValues = () => {
        const process = relevantCSVProcesses.find((_process) => _process.state === 'completed');
        return {
            current: process?.progress ?? 0,
            total: process?.progress_max ?? 0,
        };
    };

    const getErroneousProcessValues = (): ErrorMessage => {
        const process = relevantCSVProcesses?.[0];
        return {
            errorMsg: process?.error_msg,
            fileName: process?.meta_data?.uploaded_file_name,
        };
    };

    const onUpload: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setIsUploadStarted(true);
        uploadFile(e);
    };

    useEffect(() => {
        setContainerEl(topWrapperRef?.current);
    }, [topWrapperRef]);

    const onGoingProcessValues = getOngoingProcessValues();
    const finishedProcessValues = getFinishedProcessValues();

    const errornousProcessValues = getErroneousProcessValues();

    const renderView = () => {
        if (ongoingProcess) {
            return <ProcessingView values={onGoingProcessValues} />;
        }
        if (isUploadStarted && relevantCSVProcesses?.[0]?.state === 'completed') {
            return (
                <ProcessingViewDone
                    values={finishedProcessValues}
                    onClose={() => {
                        setIsUploadStarted(false);
                        applyChanges();
                    }}
                    onUpload={onUpload}
                />
            );
        }
        if (relevantCSVProcesses?.[0]?.state === 'failure') {
            return <ErrorView onClose={applyChanges} onUpload={onUpload} errors={errornousProcessValues} />;
        }
        return <DefaultView isDomain={database === 'DOMAIN_DATA_BASIC'} onUpload={onUpload} onClose={applyChanges} />;
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                setIsUploadStarted(false);
                applyChanges();
            }}
        >
            <Box>
                <OverflowContainer mode="vertical" headerPadding={0} containerEl={containerEl} />
                {renderView()}
            </Box>
        </ClickAwayListener>
    );
};
