import { useEffect, useState } from 'react';

import { HistogramData, Values } from 'components/templates/RangeSelect';

import { Mode } from './types';
import { HistogramDataResponse } from './useCustomIndustryScores';

export const FIXED_HISTOGRAM_SIZE = 100;

/**
 * Convert data to histogram format and fills missing values to zero
 */
export function getFixedHistogramData(length: number, data: HistogramDataResponse[]) {
    const fixedData: HistogramData = new Array(length);

    let index = 0;
    for (let i = 0; i < length; i++) {
        const step = `${i / FIXED_HISTOGRAM_SIZE}`;
        const item = data[index];

        const itemLabel = item?.score?.toString();
        if (index < data.length && itemLabel && itemLabel === step) {
            fixedData[i] = { label: itemLabel, value: item.count };
            index += 1;
        } else {
            fixedData[i] = { label: step, value: 0 };
        }
    }

    return fixedData;
}

/**
 * Scale the histogram by 2 times to look better on the plot,
 * and convert the label from 0-1 to 1-100 format
 */
export function scaleHistogramData(data: HistogramData) {
    const length = data.length;

    const scaledData: HistogramData = new Array(Math.floor(length / 2));
    let index: number = 0;

    for (let i = 0; i < length; i += 2) {
        const item1 = data[i].value || 0;

        if (i + 1 >= length) {
            scaledData[index] = {
                label: index.toString(),
                value: item1,
            };
            break;
        }

        const item2 = data[i + 1].value || 0;

        scaledData[index] = {
            label: index.toString(),
            value: (item1 + item2) / 2,
        };
        index++;
    }

    return scaledData;
}

export interface UseRangeProps {
    threshold: number | undefined;
    mode: Mode;
    value: Values | undefined;
    maxValue?: number;
}

export interface UseRangeValue {
    rangeValue: Values;
    onRangeChange: (value: Values) => void;
}

export function useRange({ threshold = 0, mode, value, maxValue = 100 }: UseRangeProps): UseRangeValue {
    const [rangeValue, setRangeValue] = useState<Values>([0, FIXED_HISTOGRAM_SIZE]);

    useEffect(() => {
        if (value) {
            setRangeValue(value);
            return;
        }
        if (mode === 'high') {
            setRangeValue([threshold, maxValue]);
        }
    }, [maxValue, value, mode, threshold]);

    return {
        rangeValue: mode === 'high' ? [threshold, maxValue] : rangeValue,
        onRangeChange: setRangeValue,
    };
}

export function useMode(defaultMode: Mode = 'high') {
    const [mode, onModeChange] = useState<Mode>(defaultMode);
    return { mode, onModeChange };
}

export function convertToInternalRange(range: number) {
    return Math.round(range * FIXED_HISTOGRAM_SIZE);
}
