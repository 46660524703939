import { useState, createContext, useCallback, useEffect, useMemo } from 'react';

import { IconButton, Snackbar } from '@mui/material';

import Icon from 'components/tokens/Icon';

type SnackbarContextValue = {
    showSnackbar: (message: React.ReactNode, type?: SnackType) => void;
};

export type SnackType = 'default' | 'error';

type Snack = {
    message: React.ReactNode;
    type: SnackType;
    key: number;
};

export const SnackbarContext = createContext({} as SnackbarContextValue);

type SnackbarProviderProps = {
    children?: React.ReactNode;
};

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const [snackPack, setSnackPack] = useState<readonly Snack[]>([]);
    const [snackMessage, setSnackMessage] = useState<Snack | undefined>(undefined);
    const [snackOpen, setSnackOpen] = useState(false);

    useEffect(() => {
        if (snackPack.length && !snackMessage) {
            // Set a new snack when we don't have an active one
            setSnackMessage({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setSnackOpen(true);
        } else if (snackPack.length && snackMessage && snackOpen) {
            // Close an active snack when a new one is added
            setSnackOpen(false);
        }
    }, [snackPack, snackMessage, snackOpen]);

    const handleSnackClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleExited = () => {
        setSnackMessage(undefined);
    };

    const showSnackbar = useCallback((message: React.ReactNode, type: SnackType = 'default') => {
        setSnackPack((prev) => [...prev, { message, type, key: new Date().getTime() }]);
    }, []);

    const value = useMemo(() => ({ showSnackbar }), [showSnackbar]);

    return (
        <SnackbarContext.Provider value={value}>
            {children}
            <Snackbar
                key={snackMessage ? snackMessage.key : undefined}
                open={snackOpen}
                ContentProps={{
                    elevation: 3,
                    sx: {
                        borderRadius: 2,
                        backgroundColor: 'button.background',
                        color: 'button.text',
                        padding: '4px 20px',
                    },
                }}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                message={snackMessage?.message}
                onClose={handleSnackClose}
                TransitionProps={{ onExited: handleExited }}
                action={
                    <IconButton size="small" sx={{ marginLeft: 1 }} onClick={handleSnackClose}>
                        <Icon
                            type={snackMessage?.type === 'error' ? 'ErrorOutline' : 'CheckBig'}
                            fontSize="mini"
                            sx={{ color: 'button.text' }}
                        />
                    </IconButton>
                }
            />
        </SnackbarContext.Provider>
    );
};
