import { CrmName } from 'components/modules/connectors/types/Crm';
import { FilterRow } from 'components/modules/triggers/TriggerFilters/TriggerFilterTypes';

interface DeliveryResult {
    timestamp: Date;
    leads_count: number;
    error: boolean;
    sent: boolean;
    _cls: string;
}

interface TriggerOfficeHoursUTC {
    start_hour: number; // min 0 max 23
    start_min: number; // min 0 max 59
    end_hour: number; // min 0 max 23
    end_min: number; // min 0 max 59
    week_days: number[]; // 0 - 6 Monday to Sunday
    timezone_name: string; // Europe/Helsinki
    timezone_offset: string; // '+02:00'
}

export type TriggerDestinationType = 'email' | 'slack' | 'webhook' | CrmName;

export const destinationTypeToTarget = {
    email: 'Email',
    slack: 'Slack',
    webhook: 'Webhook',
    pipedrive: 'Pipedrive',
    salesforce: 'Salesforce',
    salesforcesandbox: 'Salesforce',
    hubspot: 'HubSpot',
    dynamics: 'Dynamics 365',
};

interface Target {
    target: string;
}

export interface ExtraDict {
    email_subject?: string;
}

export interface WorkFlowDestination {
    active: boolean;
    destination_type: TriggerDestinationType;
    targets: Target[];
    assign_to?: string[];
    extra?: ExtraDict;
}

interface WorkFlowSettings {
    crm_source?: string | null;
    daily_limit: number | null;
    destinations: WorkFlowDestination[];
    target_group_source: string | null;
}

export type MessageContextType = 'lead' | 'data' | 'note' | 'targetgroup' | 'scoring';

interface MessageContextItem {
    context_type: MessageContextType;
    lead_tag?: number;
    context_for: string;
    data_field?: string;
    description: string;
}

interface MessageContext {
    context_items: MessageContextItem[];
}

export type TrackingLogicItem = {
    change: string;
    value: string | number;
    group?: string;
    context?: string;
};

export const MAIN_KEY_ANY = '#any';
export const MAIN_KEY_ALL = '#all';

type MainKey = typeof MAIN_KEY_ANY | typeof MAIN_KEY_ALL;

export type TrackingLogicDict = Record<string, TrackingLogicItem>;

export type TrackingLogicNestedDictItem = TrackingLogicDict[] | TrackingLogicNestedDict[];

export type TrackingLogicNestedDict = { [Property in MainKey]?: TrackingLogicNestedDictItem };

export type TrackingLogicRow = FilterRow;
export type TrackingLogic = TrackingLogicDict | TrackingLogicNestedDict; // TODO: Check connections with TrackingLogicDict and FilterRows

export const isTrackingLogicNestedDict = (value: TrackingLogic): value is TrackingLogicNestedDict => {
    if (value.hasOwnProperty(MAIN_KEY_ALL) || value.hasOwnProperty(MAIN_KEY_ANY)) {
        return true;
    }

    return false;
};
export interface Trigger {
    id?: string;
    created?: string;
    modified?: string;
    notify: string | null;
    notify_email?: string[];
    notify_sent?: boolean;
    query?: string;
    queries?: string[];
    notify_type: string;
    notify_target?: string;
    deleted?: boolean;
    target_group?: string;
    track_changes?: string[];
    user?: number;
    name: string;
    default?: boolean;
    target_group_name?: string;
    errors?: string[];
    previous_leads_count?: number;
    delivery_results?: DeliveryResult[];
    is_valid?: boolean;
    utc_office_hours: TriggerOfficeHoursUTC;
    workflow_settings: WorkFlowSettings;
    status: string;
    message_context: MessageContext;
    tracking_logic?: TrackingLogic;
    logic_changed?: string;
}
