import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FlagOutline = (props) => (
    <SvgIcon {...props}>
        <path d="M5.5 20.5C4.94772 20.5 4.5 20.0523 4.5 19.5V4.5C4.5 3.94772 4.94772 3.49998 5.5 3.49998H11.88C12.2602 3.49736 12.6089 3.7105 12.78 4.05L13.5 5.5H18.5C19.0523 5.5 19.5 5.94772 19.5 6.5V14.5C19.5 15.0523 19.0523 15.5 18.5 15.5H13.11C12.7334 15.4989 12.3895 15.2863 12.22 14.95L11.5 13.5H6.5V19.5C6.5 20.0523 6.05228 20.5 5.5 20.5ZM6.5 5.5V11.5H12.5L13.5 13.5H17.5V7.5H12.5L11.5 5.5H6.5Z" />
    </SvgIcon>
);

export default FlagOutline;
