import { FC, useMemo } from 'react';

import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { HistogramData } from 'components/templates/RangeSelect';
import { RangePickerWithHistogram } from 'components/templates/RangeSelect/components/RangePickerWithHistogram';
import { StyledMuiChip } from 'components/tokens/Chip/styled';
import Typography from 'components/tokens/Typography';
import { useListsContext } from 'contexts/ListsContext';
import { brandPalette } from 'design/theme/colors';

import { Mode } from '../types';
import { IndustryScoresResponse } from '../useCustomIndustryScores';
import { UseRangeValue } from '../utils';
import { SelectTitle } from './SelectTitle';

interface PopoverContentProps extends UseRangeValue {
    data?: { data: HistogramData; threshold: number; rawData: IndustryScoresResponse };
    onChangeMode: (mode: Mode) => void;
    mode: Mode;
    maxScore: number;
}

export const PopoverContent: FC<PopoverContentProps> = ({
    data,
    rangeValue,
    onRangeChange,
    onChangeMode,
    mode,
    maxScore,
}) => {
    const { database } = useListsContext();
    const intl = useIntl();

    const threshold = data?.threshold || 0;

    const companyCount = useMemo(() => {
        if (database !== 'DOMAIN_DATA_BASIC') {
            return null;
        }
        const [min, max] = rangeValue;
        return data?.rawData.histogram_data.reduce((prev, next) => {
            const score = next.score * 100;
            if (score >= min && score <= max) {
                return prev + next.count;
            }
            return prev;
        }, 0);
    }, [data, rangeValue, database]);

    return (
        <Paper sx={{ width: 390 }} id="range-slider">
            <SelectTitle />
            <RangePickerWithHistogram
                endLocked
                data={data?.data}
                value={rangeValue}
                onRangeChange={onRangeChange}
                noInputs={mode === 'high'}
                leftControls={
                    <ChipsContainer>
                        <Chip label="High" onClick={() => onChangeMode('high')} active={mode === 'high'} />
                        <Chip label="Custom" onClick={() => onChangeMode('custom')} active={mode === 'custom'} />
                    </ChipsContainer>
                }
                valueLabelFormat={(value) => (value === maxScore ? 'MAX' : value >= threshold ? 'High' : 'Low')}
                threshold={threshold}
                maxScore={maxScore}
            />

            {companyCount != null && (
                <Box sx={{ padding: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="small" color="subtle">
                        Approx.{' '}
                        <Typography
                            variant="small"
                            weight="semibold"
                            style={{
                                color:
                                    rangeValue[0] >= threshold ? brandPalette.tertiaryBlue : brandPalette.warningOrange,
                            }}
                        >
                            {intl.formatNumber(companyCount, {
                                notation: 'compact',
                            })}
                        </Typography>{' '}
                        companies
                    </Typography>
                </Box>
            )}
        </Paper>
    );
};

const ChipsContainer = styled('div')`
    display: flex;
    padding: 8px;

    & > div:not(:last-child) {
        margin-right: 8px;
    }
`;

export const Chip = styled(StyledMuiChip, { shouldForwardProp: (propName) => propName !== 'active' })<{
    active: boolean;
}>(({ theme: { palette }, active }) => ({
    backgroundColor: active ? palette.brandColors.tertiaryBlue : palette.field.background,
    color: active ? palette.brandColors.white : palette.primary.main,
    border: '1px solid',
    borderColor: active ? palette.brandColors.tertiaryBlue : palette.border,
    borderRadius: 20,

    '&:hover': {
        backgroundColor: active ? palette.brandColors.tertiaryBlue : palette.field.hover,
        borderColor: active ? palette.brandColors.tertiaryBlue : palette.border,
        outline: `1px solid ${active ? palette.brandColors.tertiaryBlue : palette.field.hover}`,
    },
}));
