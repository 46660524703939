import { useIntl } from 'react-intl';

import HeaderBox from 'components/templates/HeaderBox';

import { getShortTrafficRanking } from './utils';

export type WebsiteRankingProps = {
    ranking: number;
};

export const hasRanking = (ranking?: number): ranking is number => typeof ranking === 'number' && ranking > 0;

export const WebsiteRanking: React.VFC<WebsiteRankingProps> = ({ ranking }) => {
    const intl = useIntl();
    const trafficClass = getShortTrafficRanking(ranking);
    const exactRank = `${intl.formatNumber(ranking, { style: 'decimal' })} in global ranking`;
    return <HeaderBox title="Traffic ranking" label={exactRank} number={trafficClass} />;
};

export default WebsiteRanking;
