import { useEffect, useMemo, useRef } from 'react';

import { QueryFunction, QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CachedCountPayload, FilterCompaniesResponse, fetchCachedCompanyCount } from 'api/filterCompanies';
import { addIdAndType } from 'api/profileData';
import { FilterOperator, Operation } from 'api/types/FilterOperators';
import { List } from 'api/types/List';
import { DomainData } from 'api/types/Organization';
import { getGroup } from 'components/features/Filters/common/findGroup';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useFilterState } from 'contexts/FilterContext';
import { Database, isUploaded, useListsContext } from 'contexts/ListsContext';
import { OrganizationResult } from 'contexts/types/FilterCompanies';
import { toDateUTC } from 'utilities/date';

export const resultsQueryKeyPrefix = 'list-results';

export const resultsActionsConst = ['objects', 'cached-objects', 'count'] as const;
export const resultsActions = resultsActionsConst as readonly string[];
export type ResultsAction = (typeof resultsActionsConst)[number];
export type ResultObjectType = 'companies' | 'contacts';

export type ResultsQueryParams = Partial<{
    offset: number;
    limit: number;
    orderBy: string;
}>;
export type ResultsQueryKey = QueryKey &
    [
        prefix: typeof resultsQueryKeyPrefix,
        action: ResultsAction,
        objectType: ResultObjectType,
        database: Database | undefined,
        listId: List['id'] | null,
        listQuery: List['query'] | undefined,
        params?: ResultsQueryParams,
    ];

export const getQueryKeyResultObjects = (
    type: ResultObjectType,
    database: Database | undefined,
    listID: string | null,
    query: string | undefined,
    offset: number,
    limit: number,
    orderBy: string,
): ResultsQueryKey => {
    return [resultsQueryKeyPrefix, 'objects', type, database, listID, query, { offset, limit, orderBy }];
};

export const getQueryKeyCacheBatch = (
    type: ResultObjectType,
    database: Database | undefined,
    listID: string | null,
    query: string | undefined,
    offset: number,
    limit: number,
    orderBy: string,
): ResultsQueryKey => {
    return [resultsQueryKeyPrefix, 'cached-objects', type, database, listID, query, { offset, limit, orderBy }];
};

export const getQueryKeyCount = (
    type: ResultObjectType,
    database: Database | undefined,
    listID: string | null,
    query: string | undefined,
): ResultsQueryKey => {
    return [resultsQueryKeyPrefix, 'count', type, database, listID, query];
};

const MS_IN_MIN = 60e3;
const MS_IN_HOUR = 60 * MS_IN_MIN;
const MS_IN_DAY = 24 * MS_IN_HOUR;
export const getShouldRecount = (timeDiff: number, count: number) => {
    let cacheTime;
    // 100 -> 3min
    if (count < 100) {
        cacheTime = 3 * MS_IN_MIN;
    }
    // 1000 -> 30min
    else if (count < 1000) {
        cacheTime = 30 * MS_IN_MIN;
    }
    // 500K -> 24h
    else if (count < 500e3) {
        cacheTime = MS_IN_DAY;
    }
    // 4M -> 24h
    else if (count < 4e6) {
        cacheTime = 3 * MS_IN_DAY;
    }
    // 2 weeks
    else {
        cacheTime = 14 * MS_IN_DAY;
    }
    return cacheTime < timeDiff;
};

export const getContactGroups = (query: Operation): Operation => {
    const contactGroups: Operation[] = [];
    FilterOperator.ALL in query &&
        query[FilterOperator.ALL]?.forEach((group: Operation) => {
            const rawGroup = getGroup(group);
            if (rawGroup?.group === 'contacts') {
                contactGroups.push(group);
            }
        });
    return { [FilterOperator.ALL]: contactGroups };
};

export const useCompanyList = (
    queryKey: QueryKey,
    queryFn: QueryFunction<FilterCompaniesResponse>,
    enabled: boolean,
) => {
    return useQuery({
        queryKey,
        queryFn,
        staleTime: MS_IN_DAY,
        retry: (failureCount, error) => (error as AxiosError)?.response?.status === 429,
        retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
        enabled,
        select: (r) => r.map(addIdAndType) as DomainData[] | OrganizationResult[],
    });
};

export const useCompanyCount = (type: 'companies' | 'contacts' = 'companies', fallbackCount: number) => {
    const queryClient = useQueryClient();
    const axios = useAxiosContext();
    const { query } = useFilterState();
    const { selectedList, database } = useListsContext();

    const shouldRecount = useRef(false);

    const isStaticList = selectedList && isUploaded(selectedList);
    const isLegacyList = selectedList?.type === 'legacy';
    const listID = selectedList?.id;

    const queryKey = getQueryKeyCount(type, database, ((isStaticList || isLegacyList) && listID) || null, query);

    const { data, isFetching } = useQuery({
        queryKey,
        queryFn: async () => {
            let payloadQuery: Operation;
            let contactGroups;
            if (isLegacyList) {
                payloadQuery = { '?ALL': [{ '?EQ': { 'target_group._id': selectedList.id } }] };
            } else if (isStaticList) {
                payloadQuery = { '?ALL': [{ '?EQ': { 'target_group._id': listID || '' } }] };
            } else {
                payloadQuery = JSON.parse(query || '{"?ALL": []}');
            }

            if (type === 'contacts') {
                contactGroups = getContactGroups(payloadQuery);
            }

            const recount = shouldRecount.current;
            shouldRecount.current = false;

            return fetchCachedCompanyCount(axios, {
                query: payloadQuery,
                database,
                recount,
                ...(type === 'contacts'
                    ? {
                          unwind_subdocument: 'contacts',
                          unwind_subdocument_query: contactGroups,
                      }
                    : {}),
            } as CachedCountPayload);
        },
        refetchInterval: (query) => {
            if (['process', 'scheduled'].includes(query.state.data?.status || '')) {
                return 3000;
            }
            return false;
        },
        enabled:
            !!listID &&
            !!database &&
            (!!query || isStaticList || isLegacyList) &&
            (type !== 'contacts' || database !== 'DOMAIN_DATA_BASIC'),
    });

    useEffect(() => {
        if (!['process', 'scheduled'].includes(data?.status || '') && data?.time) {
            const timeDiff = new Date().getTime() - toDateUTC(data.time).getTime();
            const count = data.count || 0;

            if (getShouldRecount(timeDiff, count)) {
                shouldRecount.current = true;
                queryClient.invalidateQueries({ queryKey });
            }
        }
    }, [queryKey, queryClient, data?.status, data?.time, data?.count]);

    return useMemo(
        () => ({
            final: data?.count != null,
            loading: ['process', 'scheduled'].includes(data?.status || '') || isFetching,
            status: data?.status,
            eta: data?.eta_utc || undefined,
            count: data?.count ?? fallbackCount,
        }),
        [fallbackCount, data?.count, data?.eta_utc, data?.status, isFetching],
    );
};

export const useContactCountByCompany = (businessId: string) => {
    const queryClient = useQueryClient();
    const axios = useAxiosContext();
    const { query } = useFilterState();
    const { database } = useListsContext();

    const shouldRecount = useRef(false);

    const queryKey = useMemo(
        () => [resultsQueryKeyPrefix, 'count', 'contacts-per-company', database, businessId, query],
        [businessId, database, query],
    );

    const { data, isFetching } = useQuery({
        queryKey,
        queryFn: () => {
            const payloadQuery = JSON.parse(query || '{"?ALL": []}');

            const contactGroups = getContactGroups(payloadQuery);

            const recount = shouldRecount.current;
            shouldRecount.current = false;

            return fetchCachedCompanyCount(axios, {
                query: {
                    '?ALL': [
                        {
                            '?EQ': {
                                business_id: businessId,
                            },
                        },
                    ],
                },
                database,
                recount: recount,
                async: false,
                ...{
                    unwind_subdocument: 'contacts',
                    unwind_subdocument_query: contactGroups,
                },
            } as CachedCountPayload);
        },
        refetchInterval: (query) => {
            if (query.state.data?.count === null || query.state.data?.count === undefined) {
                return 3000;
            }
            return false;
        },
        enabled: database && database !== 'DOMAIN_DATA_BASIC' && !!businessId,
    });

    useEffect(() => {
        if (data?.time) {
            const timeDiff = new Date().getTime() - toDateUTC(data.time).getTime();

            const cacheTime = 10 * MS_IN_MIN;
            if (timeDiff > cacheTime) {
                shouldRecount.current = true;
                queryClient.invalidateQueries({ queryKey });
            }
        }
    }, [queryKey, queryClient, data?.status, data?.time, data?.count]);

    return useMemo(
        () => ({
            final: data?.count != null,
            loading: isFetching,
            status: data?.status,
            eta: data?.eta_utc || undefined,
            count: data?.count,
        }),
        [data?.count, data?.eta_utc, data?.status, isFetching],
    );
};
