import React, { useState } from 'react';

import { Box, Skeleton, Tooltip, styled } from '@mui/material';

import ObjectSvgIcon from 'components/modules/connectors/common/ObjectIcon';
import { MIN_MATCHING_DIAGRAM_ITEM_WIDTH } from 'components/modules/connectors/matching/contants';
import { DiagramItem } from 'components/modules/connectors/matching/MatchingResultsDiagram';
import { DiagramItemType } from 'components/modules/connectors/matching/utils';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { getCompanyLikeObjectForCrm, getObjectPluralForm } from 'components/modules/connectors/utils/CRMTargetText';
import Typography from 'components/tokens/Typography';

interface MatchTileContextProps {
    isLoading: boolean;
    crm: CrmName;
    matchType: 'company' | 'lead';
    total: number;
    matched: number;
    diagramConfig: DiagramItemType[];
}

// todo: would be nice to reuse MatchingResultsDiagram component, these components look pretty much similar
const OverviewMatchingResultDiagram: React.FC<MatchTileContextProps> = ({
    isLoading,
    crm,
    matchType,
    total,
    matched,
    diagramConfig,
}) => {
    const [focusedComponent, setFocusedComponent] = useState('');
    const crmObject = matchType === 'company' ? getCompanyLikeObjectForCrm(crm) : 'Lead';
    const crmObjectPlural = getObjectPluralForm(crm, crmObject);

    return (
        <Box
            data-testid={`${crm}-${matchType}-matching-diagram-container`}
            sx={{ padding: '16px', backgroundColor: 'background.default' }}
        >
            <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ObjectSvgIcon crm={crm} object={crmObject} style={{ width: 20, height: 20 }} />
                <Typography marginLeft="4px">{crmObjectPlural}</Typography>
            </Box>
            <Typography
                data-testid={`${crm}-${matchType}-matching-summary`}
                variant="number"
                sx={{ color: 'grey.400' }}
            >
                {isLoading ? (
                    <Skeleton width={120} />
                ) : total === 0 ? (
                    <Typography variant="inherit" sx={{ color: 'text.secondary' }} component="span">
                        0
                    </Typography>
                ) : (
                    <>
                        <Typography variant="inherit" sx={{ color: 'success.main' }} component="span">
                            {matched.toLocaleString()}
                        </Typography>
                        /{total.toLocaleString()}
                    </>
                )}
            </Typography>
            {isLoading ? (
                <Skeleton
                    data-testid={`${crm}-${matchType}-matching-diagram-skeleton`}
                    variant="rounded"
                    height={16}
                    width="100%"
                />
            ) : (
                total > 0 && (
                    <Box
                        data-testid={`${crm}-${matchType}-matching-diagram`}
                        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}
                    >
                        {diagramConfig.map(({ percentage, color, count, label }) => {
                            const formattedPercentage = Number(percentage.toFixed(2));
                            const optimizedWidth =
                                percentage !== 0 && percentage < MIN_MATCHING_DIAGRAM_ITEM_WIDTH
                                    ? MIN_MATCHING_DIAGRAM_ITEM_WIDTH
                                    : formattedPercentage;
                            const isTooltipOpened = focusedComponent === label;

                            return (
                                <Tooltip
                                    key={label}
                                    open={isTooltipOpened}
                                    arrow
                                    title={
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <DiagramLegendItem background={color} />
                                                <Typography variant="mini" marginLeft="4px">
                                                    {count}
                                                </Typography>
                                                &nbsp;
                                                <Typography variant="mini">{label}</Typography>
                                            </div>
                                            <Typography variant="mini" color="subtle">
                                                {formattedPercentage}% {crmObjectPlural}
                                            </Typography>
                                        </>
                                    }
                                    placement="top"
                                >
                                    <DiagramItem
                                        isTooltipOpened={isTooltipOpened}
                                        width={optimizedWidth}
                                        color={color}
                                        onMouseEnter={() => setFocusedComponent(label)}
                                        onMouseLeave={() => setFocusedComponent('')}
                                    />
                                </Tooltip>
                            );
                        })}
                    </Box>
                )
            )}
        </Box>
    );
};

export { OverviewMatchingResultDiagram };

const DiagramLegendItem = styled('div')<{ background: string }>(({ background }) => ({
    width: '3px',
    height: '12px',
    borderRadius: '2px',
    background,
}));
