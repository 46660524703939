import { useCallback, useState } from 'react';

export function useOpen(defaultValue = false) {
    const [isOpen, setIsOpen] = useState(defaultValue);

    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);
    const toggle = useCallback(() => setIsOpen((prevOpen) => !prevOpen), []);

    return {
        isOpen,
        open,
        close,
        toggle,
    };
}
