import React, { useMemo, useState, useCallback, InputHTMLAttributes, CSSProperties } from 'react';

import { styled, InputAdornment, IconButton, Divider } from '@mui/material';
import { capitalize, difference, flatMap, uniq } from 'lodash';
import { FixedSizeList } from 'react-window';

import Button from 'components/tokens/Button';
import Checkbox from 'components/tokens/Checkbox';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Tag from 'components/tokens/Tag';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';

import { TechIdToCategoryMap, TechnologiesTree } from '../../types/Crm';

interface TechnologiesTreeProps {
    limit: number;
    data: { tree: TechnologiesTree; idToCategoryMap: TechIdToCategoryMap };
    selectedValues: number[];
    onSelect: (technologyId: number[]) => void;
}

/*
Performance boost idea:

Selecting a tree item triggers the `onSelect` function, which in turn triggers a state change in RHF.
This state change causes the tree to re-render. One potential improvement is to create
own state for the `MappingsTechnologiesTree` component and use this state for all tree select operations.
However, it's not clear when we should put values from this state to the RHF state.
*/
/*
We have another tree component for the CSV download, but this tree works different way.
Need to take a look, maybe we can reuse some styled() components for these tree components.
*/
const MappingsTechnologiesTree: React.FC<TechnologiesTreeProps> = ({
    limit,
    selectedValues,
    data: { tree, idToCategoryMap },
    onSelect,
}) => {
    const [activeCategory, setActiveCategory] = useState('');
    const [searchValue, setSearchValue] = useState('');

    const allValues = useMemo(() => flatMap(tree), [tree]);
    const allValuesIds = useMemo(() => allValues.map(({ id }) => id), [allValues]);

    const isPicklistLimitReached = selectedValues.length > limit;

    const getTotalInCategoryCount = useCallback((category: string) => (tree[category] ?? []).length, [tree]);
    const getSelectedInCategoryCount = (category: string) => {
        return selectedValues.filter((item) => idToCategoryMap[item] === category).length;
    };

    const checkAllInCategorySelected = (category: string) => {
        return getSelectedInCategoryCount(category) === getTotalInCategoryCount(category);
    };
    const checkSomeInCategorySelected = (category: string) => {
        return getSelectedInCategoryCount(category) > 0 && !checkAllInCategorySelected(category);
    };

    const handleCategorySelect = useCallback((event: React.MouseEvent) => {
        const currentTarget = event.currentTarget as HTMLLabelElement;
        const nextCategory = currentTarget.dataset.itemid as string;

        setActiveCategory((prevCategory) => (prevCategory === nextCategory ? '' : nextCategory));
    }, []);

    const handleItemSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const itemId = Number(event.target.dataset.itemid);
        const isSelected = selectedValues.includes(itemId);

        onSelect(
            isSelected
                ? difference(selectedValues, [itemId])
                : [...selectedValues, Number(event.target.dataset.itemid)],
        );
    };

    const handleSelectFromCategory = (category: string) => {
        const allValuesIdsFromCategory = tree[category].map(({ id }) => id);
        const selectedInCategory = getSelectedInCategoryCount(category);

        if (selectedInCategory === 0) {
            const nextValues = uniq([...selectedValues, ...allValuesIdsFromCategory]);
            onSelect(nextValues);
        } else {
            onSelect(difference(selectedValues, allValuesIdsFromCategory));
        }
    };

    const handleSelectAll = (shouldSelectAll: boolean) => {
        onSelect(shouldSelectAll ? allValuesIds : []);
    };

    const filteredValues = useMemo(
        () => allValues.filter((value) => value.name.toLowerCase().startsWith(searchValue.toLocaleLowerCase())),
        [allValues, searchValue],
    );

    return (
        <>
            <TreeContainer data-testid="technologies_tree">
                <TreeHeader
                    categoryName={capitalize(activeCategory)}
                    selectedCount={selectedValues.length}
                    limit={limit}
                    onSearch={setSearchValue}
                />
                <TreeBody>
                    {searchValue.length > 2 ? (
                        <ColumnContainer width="100%">
                            {filteredValues.length > 0 ? (
                                <FixedSizeList
                                    height={250}
                                    itemCount={filteredValues.length}
                                    itemSize={32}
                                    width="100%"
                                >
                                    {({ index, style }) => {
                                        const listItem = filteredValues[index];

                                        return (
                                            <TreeItem
                                                checked={selectedValues.includes(listItem.id)}
                                                data-testid="search_item"
                                                itemId={listItem.id}
                                                itemLabel={
                                                    <>
                                                        <Typography variant="body2">{listItem.name}</Typography>
                                                        <Typography variant="body2" color="subtle" marginLeft="4px">
                                                            {` (${capitalize(idToCategoryMap[listItem.id])})`}
                                                        </Typography>
                                                    </>
                                                }
                                                style={style}
                                                onSelect={handleItemSelect}
                                            />
                                        );
                                    }}
                                </FixedSizeList>
                            ) : (
                                <NoSearchResultsPlaceholder searchValue={searchValue} />
                            )}
                        </ColumnContainer>
                    ) : (
                        <>
                            <ColumnContainer width="50%">
                                <MultipleSelectItem>
                                    <Checkbox
                                        checked={allValuesIds.length === selectedValues.length}
                                        indeterminate={
                                            selectedValues.length > 0 && selectedValues.length < allValuesIds.length
                                        }
                                        onClick={() => handleSelectAll(selectedValues.length === 0)}
                                    />
                                    <Typography variant="body2">
                                        {selectedValues.length === 0 ? 'Select all' : 'Deselect'}
                                    </Typography>
                                </MultipleSelectItem>
                                <FixedSizeList
                                    height={250}
                                    itemCount={Object.keys(tree).length}
                                    itemSize={32}
                                    width="100%"
                                >
                                    {({ index, style }) => {
                                        const treeCategory = Object.keys(tree)[index];

                                        return (
                                            <TreeItem
                                                data-testid="tree_category_item"
                                                active={treeCategory === activeCategory}
                                                checked={checkAllInCategorySelected(treeCategory)}
                                                indeterminate={checkSomeInCategorySelected(treeCategory)}
                                                itemId={treeCategory}
                                                itemLabel={
                                                    <>
                                                        <Typography variant="body2">
                                                            {capitalize(treeCategory)}
                                                        </Typography>
                                                        <Icon
                                                            type="ChevronRight"
                                                            color="grey.500"
                                                            sx={{ marginLeft: 'auto' }}
                                                        />
                                                    </>
                                                }
                                                style={style}
                                                onSelect={() => handleSelectFromCategory(treeCategory)}
                                                onCategorySelect={handleCategorySelect}
                                            />
                                        );
                                    }}
                                </FixedSizeList>
                            </ColumnContainer>
                            <Divider orientation="vertical" flexItem />
                            <ColumnContainer width="50%">
                                {activeCategory && (
                                    <MultipleSelectItem data-testid="select_all_in_category">
                                        <Checkbox
                                            checked={checkAllInCategorySelected(activeCategory)}
                                            indeterminate={checkSomeInCategorySelected(activeCategory)}
                                            onClick={() => handleSelectFromCategory(activeCategory)}
                                        />
                                        <Typography variant="body2">
                                            {checkSomeInCategorySelected(activeCategory) ? 'Deselect' : 'Select all'}
                                        </Typography>
                                    </MultipleSelectItem>
                                )}
                                <FixedSizeList
                                    height={250}
                                    itemCount={(tree[activeCategory] ?? []).length}
                                    itemSize={32}
                                    width="100%"
                                >
                                    {({ index, style }) => {
                                        const treeItem = tree[activeCategory][index];
                                        return (
                                            <TreeItem
                                                data-testid="tree_value_item"
                                                checked={selectedValues.includes(treeItem.id)}
                                                itemId={treeItem.id}
                                                itemLabel={treeItem.name}
                                                style={style}
                                                onSelect={handleItemSelect}
                                            />
                                        );
                                    }}
                                </FixedSizeList>
                            </ColumnContainer>
                        </>
                    )}
                </TreeBody>
            </TreeContainer>
            {/* todo: need to use form's errors instead of isPicklistLimitReached */}
            {isPicklistLimitReached && (
                <LimitWarning>
                    <Icon type="Error" sx={{ marginRight: '8px', color: 'brandColors.errorMagenta' }} />
                    <div>
                        <Typography fontSize="14px">
                            You have chosen too many technologies for a picklist field. Please reduce them to {limit} or
                            less. Alternatively, to use this selection you can change the field type.
                        </Typography>
                        <Button
                            sx={{ marginTop: '8px', '&&:hover': { background: 'inherit' } }}
                            variant="secondary"
                            onClick={() => handleSelectAll(false)}
                        >
                            Clear technologies
                        </Button>
                    </div>
                </LimitWarning>
            )}
        </>
    );
};

interface TreeItemProps {
    // required by react-window
    style: CSSProperties;
    active?: boolean;
    checked: boolean;
    indeterminate?: boolean;
    itemId: number | string;
    'data-testid'?: string;
    itemLabel: string | JSX.Element;
    onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCategorySelect?: (event: React.MouseEvent) => void;
}

const TreeItem: React.FC<TreeItemProps> = ({
    style,
    active,
    checked,
    indeterminate,
    itemId,
    itemLabel,
    onSelect,
    onCategorySelect,
    'data-testid': testId,
}) => {
    return (
        <Item
            as={onCategorySelect ? 'div' : 'label'}
            active={active}
            style={style}
            data-itemid={itemId}
            data-testid={testId}
            onClick={onCategorySelect}
        >
            <Checkbox
                disableRipple
                checked={checked}
                indeterminate={indeterminate}
                inputProps={
                    {
                        'data-itemid': itemId,
                    } as InputHTMLAttributes<HTMLInputElement>
                }
                onChange={onSelect}
            />
            {typeof itemLabel === 'string' ? <Typography variant="body2">{itemLabel}</Typography> : itemLabel}
        </Item>
    );
};

interface TreeHeaderProps {
    categoryName: string;
    selectedCount: number;
    limit: number;
    onSearch: React.Dispatch<React.SetStateAction<string>>;
}

const TreeHeader: React.FC<TreeHeaderProps> = ({ categoryName, selectedCount, limit, onSearch }) => {
    const [isSearchActive, setSearchActive] = useState(false);

    return (
        <HeaderContainer>
            <HeaderColumn>
                <StyledTag
                    variant={selectedCount > limit ? 'error' : selectedCount === 0 ? 'default' : 'black'}
                    label={limit !== Infinity ? `${selectedCount}/${limit}` : selectedCount}
                />
                <Typography variant="subtitle2" color="subtle">
                    Technologies
                </Typography>
            </HeaderColumn>
            {isSearchActive ? (
                <SearchInput onSearch={onSearch} onClose={() => setSearchActive(false)} />
            ) : (
                <HeaderColumn>
                    <Typography variant="subtitle2" color="subtle" sx={{ marginLeft: '8px' }}>
                        {categoryName}
                    </Typography>
                    <Button
                        data-testid="search_button"
                        variant="flat"
                        size="small"
                        sx={{ marginLeft: 'auto' }}
                        startIcon={<Icon type="Search" />}
                        onClick={() => setSearchActive(true)}
                    />
                </HeaderColumn>
            )}
        </HeaderContainer>
    );
};

interface SearchInputProps {
    onSearch: React.Dispatch<React.SetStateAction<string>>;
    onClose: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ onSearch, onClose }) => (
    <StyledTextField
        autoFocus
        compact
        icon="Search"
        placeholder="Search..."
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        disableRipple
                        sx={{ padding: 0 }}
                        onClick={() => {
                            onSearch('');
                            onClose();
                        }}
                    >
                        <Icon type="CloseSmall" />
                    </IconButton>
                </InputAdornment>
            ),
        }}
        onChange={(event) => onSearch(event.target.value)}
    />
);

const NoSearchResultsPlaceholder: React.FC<{ searchValue: string }> = ({ searchValue }) => {
    return (
        <SearchPlaceholderContainer data-testid="tree_search_placeholder">
            <PlaceholderIconWrapper>
                <PlaceholderIcon type="FolderMinus" viewBox="0 0 60 55" />
                <PlaceholderSearchIcon type="Search" />
            </PlaceholderIconWrapper>
            <Typography variant="body2" marginTop={2}>
                We don’t detect&nbsp;
                <Typography component="span" variant="body2" weight="bold" sx={{ wordBreak: 'break-all' }}>
                    "{searchValue.length <= 30 ? searchValue : `${searchValue.slice(0, 30)}...`}"
                </Typography>
                &nbsp;technologies yet
            </Typography>
            <Typography variant="tiny" color="subtle">
                Didn’t find what you were looking for? You can suggest technologies to us
            </Typography>
            <Link sx={{ fontSize: '12px' }} href="mailto:support@vainu.io">
                support@vainu.io
            </Link>
        </SearchPlaceholderContainer>
    );
};

const TreeContainer = styled('div')(({ theme: { palette } }) => ({
    border: `1px solid ${palette.brandColors.subtleLight}`,
    borderRadius: '5px',
}));

const HeaderContainer = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    height: '44px',
    background: palette.brandColors.cloud,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
}));

const HeaderColumn = styled('div')({
    display: 'flex',
    alignItems: 'center',
    width: '50%',
});

const StyledTextField = styled(TextField)({
    '.MuiInputBase-root': {
        height: '32px',
    },
});

const StyledTag = styled(Tag)({
    minWidth: '18px',
    height: '18px',
    marginRight: '6px',
    borderRadius: '33px',

    '& .MuiChip-label': {
        fontSize: '11px',
    },
});

const TreeBody = styled('div')({
    display: 'flex',
});

const ColumnContainer = styled('div')<{ width: string }>(({ width }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 8px 8px',
    width,
    height: '250px',
    overflow: 'auto',
}));

const SearchPlaceholderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '0 32px',
});

const PlaceholderIconWrapper = styled('div')(({ theme: { palette } }) => ({
    position: 'relative',
    width: '130px',
    height: '130px',
    background: palette.brandColors.cloud,
    borderRadius: '50%',
}));

const PlaceholderIcon = styled(Icon)(({ theme: { palette } }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70px',
    height: '70px',
    color: palette.brandColors.subtleLight,
}));

const PlaceholderSearchIcon = styled(Icon)({
    position: 'absolute',
    bottom: '-10%',
    right: '-10%',
    width: '70px',
    height: '70px',
});

const LimitWarning = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    maxWidth: '568px',
    backgroundColor: palette.brandColors.statusError100,
    borderRadius: '8px',
    padding: '16px 12px',
    marginTop: '8px',
}));

const Item = styled('label')<{ active?: boolean }>(({ theme: { palette }, active }) => ({
    display: 'flex',
    alignItems: 'center',
    flexShrink: '0',
    height: '32px',
    padding: '6px 6px 6px 4px',
    background: palette.common.white,
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    border: '1px solid transparent',

    '&:hover': {
        backgroundColor: palette.brandColors.cloud,
        border: `1px solid ${palette.border}`,
    },

    ...(active && {
        backgroundColor: palette.brandColors.cloud,
    }),
}));

const MultipleSelectItem = styled(Item)(({ theme: { palette } }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 1,
    marginBottom: '4px',
    borderBottom: `1px solid ${palette.brandColors.subtleLight}`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
}));

export { MappingsTechnologiesTree };
