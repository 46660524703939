import { useState, useCallback } from 'react';

import { Operation } from 'api/types/FilterOperators';
import { List } from 'api/types/List';
import { Contact } from 'api/types/Organization';
import { Database } from 'contexts/ListsContext';

import { CrmName } from '../types/Crm';

export type ExportableContact = Contact & { businessId: string };

interface ListExportParams {
    selectionType: 'list';
    exportType: 'company' | 'contact';
    ignoreContactFilter?: boolean; // send all companies matching `query`, even if `contact_filter` does not match
    crm: CrmName;
    database: Database;
    listItemsCount: number;
    list: List;
    contact_filter?: Operation;
    defaultVainuTags?: string[];
    excludeCompanyIds?: string[];
}

interface SelectionExportParams {
    selectionType: 'selection';
    exportType: 'company' | 'contact';
    crm: CrmName;
    database: Database;
    companyIds: string[];
    defaultVainuTags?: string[];
    contact_filter?: Operation;
    contacts?: ExportableContact[];
}

export type CRMExportParams =
    | (SelectionExportParams & {
          listItemsCount?: never;
          list?: never;
          ignoreContactFilter?: never;
          excludeCompanyIds?: never;
      })
    | (ListExportParams & { companyIds?: never; contacts?: never });
export type CRMExportState = CRMExportParams & { open: boolean };
export type CRMExportProps = CRMExportState & { onClose: () => void };

interface CRMExportHandlers {
    /**
     * Init a CRM export based on passed parameters
     * - `selectionType: 'list'` -> required params: `crm`, `database`, `list`, `listItemsCount`
     * - `selectionType: 'selection'` -> required params: `crm`, `database`, `companyIds`
     *
     * @example
     * handleOpenExportDialog({ selectionType: 'list', crm, database, list }) // list export
     * handleOpenExportDialog({ selectionType: 'selection', crm, database, companyIds: ['ID1'] }) // single export
     * handleOpenExportDialog({ selectionType: 'selection', crm, database, companyIds }) // companies mass export
     * handleOpenExportDialog({ selectionType: 'selection', crm, database, contacts, companyIds: ['ID1'] }) // contacts mass export
     */
    handleOpenExportDialog: (exportParams: CRMExportParams) => void;
    handleCloseExportDialog: () => void;
}

const initialState: CRMExportState = {
    selectionType: 'selection',
    exportType: 'company',
    open: false,
    database: 'FI',
    crm: 'hubspot',
    companyIds: [],
};

/**
 * Hook for managing CRM export.
 * How to use:
 * 1. Set CRM export state - using `handleOpenExportDialog`
 * 2. Pass CRM export state to `<CRMExport />`
 * @returns CRM export state and CRM export dialog open/close handlers
 */
function useCRMExport(): [CRMExportState, CRMExportHandlers] {
    const [exportState, setExportState] = useState<CRMExportState>(initialState);

    const handleOpenExportDialog = useCallback(
        (exportParams: CRMExportParams) => setExportState({ open: true, ...exportParams }),
        [],
    );

    const handleCloseExportDialog = useCallback(() => setExportState(initialState), []);

    return [exportState, { handleOpenExportDialog, handleCloseExportDialog }];
}

export { useCRMExport };
