import L from 'leaflet';
import { get } from 'lodash';

import { FilterOperator, GeoWithinValue } from 'api/types/FilterOperators';
import { mergeKeys } from 'components/features/Filters/common/utils';
import { getFirstKey } from 'utilities/objectUtils';

import { NodeValue, FilterNodeValueExcluded, OperatorValue } from '../../../../FilterTypes';
import { getInnerMostValueInFilterState } from '../../../utils';
import { EARTH_RADIUS_IN_METER, FIELD, FieldValue, TValues, config } from '../config';
import { GeometryLayer } from '../MapContent';

const getFieldValue = (field: NodeValue): FieldValue => {
    const firstOperator = getFirstKey(field);
    const isInverseOperator = firstOperator === FilterOperator.NOT;

    const path = mergeKeys(
        isInverseOperator
            ? `${FilterOperator.NOT}.${getFirstKey((field as FilterNodeValueExcluded)[firstOperator])}`
            : firstOperator,
        config.id,
    );

    return {
        operator: isInverseOperator
            ? `${FilterOperator.NOT}${getFirstKey((field as FilterNodeValueExcluded)[firstOperator])}`
            : (firstOperator as OperatorValue),
        geoJSON: get(field, path),
        isInverseLayer: isInverseOperator,
    };
};

export const parseGeoJSON = <T>(nodes: NodeValue<T>[]) => {
    return nodes.map((v) => {
        const { operator, geoJSON, isInverseLayer } = getFieldValue(v);
        return {
            layer: getLayerType(operator),
            geoJSON,
            isInverseLayer,
        } as GeometryLayer;
    });
};

const getLayerType = (operator: OperatorValue) => {
    switch (operator) {
        case '?GEO_WITHIN_SPHERE':
        case '?NOT?GEO_WITHIN_SPHERE':
            return 'circle';

        case '?GEO_WITHIN':
        case '?NOT?GEO_WITHIN':
            return 'polygon';

        case '?GEO_WITHIN_BOX':
        case '?NOT?GEO_WITHIN_BOX':
        default:
            return 'rectangle';
    }
};

const getLayerToOperator = (layer: 'circle' | 'polygon' | 'rectangle') => {
    switch (layer) {
        case 'circle':
            return '?GEO_WITHIN_SPHERE';
        case 'polygon':
            return '?GEO_WITHIN';
        case 'rectangle':
        default:
            return '?GEO_WITHIN_BOX';
    }
};

export const convertParsedGeoJSONToFilterValue = (parsedGeoJSON: GeometryLayer[]) => {
    const included: NodeValue[] = [];
    const excluded: NodeValue[] = [];

    parsedGeoJSON.forEach((layer) => {
        if (layer.isInverseLayer) {
            excluded.push({
                [FilterOperator.NOT]: {
                    [getLayerToOperator(layer.layer)]: {
                        [FIELD]: layer.geoJSON,
                    },
                },
            });
        } else {
            included.push({
                [getLayerToOperator(layer.layer)]: {
                    [FIELD]: layer.geoJSON,
                },
            });
        }
    });

    const filterBlocks = [];

    if (included.length) {
        filterBlocks.push({
            [FilterOperator.ANY]: included,
        });
    }
    if (excluded.length) {
        filterBlocks.push({
            [FilterOperator.ALL]: excluded,
        });
    }

    return {
        [FilterOperator.ALL]: filterBlocks,
    };
};

// stored in [long, lat] format
export const getGeometryValue = (shape: L.DrawEvents.Created): FieldValue => {
    let layer;

    switch (shape.layerType) {
        /**
         * https://www.mongodb.com/docs/manual/tutorial/calculate-distances-using-spherical-geometry-with-2d-geospatial-indexes/
         * https://leafletjs.com/reference.html#circle-radius
         * The equatorial radius of the Earth is approximately 6,378.1 kilometers.
         * Since leaflet's circle radius is in meters, we need to divide the radius by 6371000.
         * */
        case 'circle':
            layer = shape.layer as L.Circle;
            return {
                operator: FilterOperator.GEO_WITHIN_SPHERE,
                geoJSON: {
                    '#coordinates': [layer.getLatLng().lng, layer.getLatLng().lat],
                    '#radius': layer.getRadius() / EARTH_RADIUS_IN_METER,
                },
            };

        case 'polygon':
            layer = shape.layer as L.Polygon;
            return {
                operator: FilterOperator.GEO_WITHIN,
                geoJSON: layer.toGeoJSON().geometry.coordinates[0] as GeoWithinValue,
            };
        case 'rectangle':
        default:
            layer = shape.layer as L.Rectangle;
            const bound = layer.getBounds();
            return {
                operator: FilterOperator.GEO_WITHIN_BOX,
                geoJSON: [
                    [bound.getNorthEast().lng, bound.getNorthEast().lat],
                    [bound.getSouthWest().lng, bound.getSouthWest().lat],
                ],
            };
    }
};

export const getMapFilterData = (value: NodeValue<TValues>) => {
    const groupsKey = FilterOperator.ALL;
    const groups: NodeValue<TValues>[] = get(value, groupsKey);

    let includeIndex = 0;
    let excludeIndex = 1;

    if (groups?.[0]?.hasOwnProperty(FilterOperator.ALL)) {
        includeIndex = 1;
        excludeIndex = 0;
    }

    const includePath = mergeKeys(groupsKey, includeIndex, FilterOperator.ANY);
    const includeFields = getFilteredFields(value, includePath);
    const excludePath = mergeKeys(groupsKey, excludeIndex, FilterOperator.ALL);
    const excludeFields = getFilteredFields(value, excludePath);

    return {
        includeIndex,
        includePath,
        includeFields,
        excludeIndex,
        excludePath,
        excludeFields,
    };
};

const getFilteredFields = (value: NodeValue<TValues>, path: string) => {
    const fields: NodeValue[] = get(value, path) || [];

    return fields.filter((field) => {
        const coordinates = (getInnerMostValueInFilterState(field, 'visiting_coordinates') as number[]) || [];
        return coordinates.length !== 0;
    });
};
