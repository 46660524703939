import { isSitewideNotification, Notification } from 'api/types/Notification';

export const getNotificationDate = (notification: Notification) => {
    if (isSitewideNotification(notification) && notification.meta_data?.start_date)
        return new Date(notification.meta_data.start_date);
    if (notification.finished) return new Date(notification.finished);
    return new Date(notification.created);
};

export const sortNotificationsByTime = (notifications: Notification[]): Notification[] =>
    notifications.slice().sort((a, b) => getNotificationDate(b).valueOf() - getNotificationDate(a).valueOf());

/**
 * Takes a numerator and a denominator and returns the percentage number rounded down to nearest percent.
 *
 * Returns 0 if numerator or denominator are falsy.
 */
export const progress = (numerator: number | undefined, denominator: number | undefined): number =>
    (numerator && denominator && Math.floor((numerator / denominator) * 100)) || 0;
