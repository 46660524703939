import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { InputLabel as MUIInputLabel, styled, Tooltip } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { pluralizeSuffix } from 'utilities';
import { v4 as uuid } from 'uuid';

import { useProfileData } from 'api/profileData';
import { Operation } from 'api/types/FilterOperators';
import { List } from 'api/types/List';
import { CRMBulkExportAsyncProcess } from 'api/types/UserAsyncProcess';
import { ContactsUpgradeButton, ContactsUpgradeDialog } from 'components/features/Employees';
import { checkDecisionMaker } from 'components/features/Employees/utils';
import SourceToDestination from 'components/templates/SourceToDestination';
import Checkbox from 'components/tokens/Checkbox';
import ChipInput from 'components/tokens/ChipInput';
import Dialog from 'components/tokens/Dialog';
import FeedbackBanner from 'components/tokens/FeedbackBanner/FeedbackBanner';
import Icon from 'components/tokens/Icon';
import Select, {
    EnhancedAutocompleteRenderOptionState,
    SelectOption,
    MenuOption,
    MassSelectionOptions,
} from 'components/tokens/select-components/Select';
import Switch from 'components/tokens/Switch';
import Typography from 'components/tokens/Typography';
import { Database } from 'contexts/ListsContext';
import { useNotification } from 'contexts/NotificationsContext';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';
import useAsyncProcess from 'hooks/useAsyncProcess';
import { filteredCrmMassExportProcesses } from 'store/asyncProcessAtomSelectors';
import { createIntercomEvent } from 'utilities/createIntercomEvent';

import ConnectorDrawerWithButton from '../common/ConnectorDrawerWithButton';
import ObjectSvgIcon from '../common/ObjectIcon';
import { useCRMIntegConfig } from '../context/CRMIntegrationConfigContext';
import useConnectorsApi from '../hooks/useConnectorsApi';
import Skeleton from '../skeletons';
import { ProspectExport, ProspectExportInternal } from '../types';
import { ConfirmNewMassExportParams, ContactPayload, CrmName, CRMObject, MappingsMap } from '../types/Crm';
import { getCRMName } from '../utils';
import { getObjectPluralForm, mapCRMNameToTarget } from '../utils/CRMTargetText';
import { EXPORT_SIZE_LIMIT } from './constants';
import { CRMExportProps, ExportableContact } from './useCRMExport';
import {
    ContactOption,
    getContactOption,
    getCRMObjectsWithMappings,
    getExportObjects,
    getOngoingExports,
} from './utils';

type CRMExportDialogProps = Omit<CRMExportProps, 'open'>;

interface ExportForm {
    target: CrmName;
    database: Database;
    objects_to_create: SelectOption;
    alongside_objects: SelectOption[];
    contacts: ExportableContact[];
    default_tags: string[];
    assign_info: {
        attached_resources_to_company_owner: boolean;
        owner_ids: SelectOption[];
        replace_company_owner: boolean;
    };
}

const getExportFormInitialState = ({
    isFullListExport,
    exportType,
    crm,
    database,
    list,
    defaultVainuTags,
    companyIds,
    excludeCompanyIds,
    contacts,
}: {
    isFullListExport: boolean;
    exportType: 'company' | 'contact';
    crm: CrmName;
    database: Database;
    list?: List;
    defaultVainuTags: string[];
    companyIds?: string[]; // undefined for list exports
    excludeCompanyIds: string[];
    contacts: ExportableContact[];
}): ExportForm => {
    const queryKey = database === 'DOMAIN_DATA_BASIC' ? 'domain' : 'business_id';
    const defaultSendAsObject = getExportObjects(crm, exportType).map((crmObject) => ({
        label: crmObject,
        value: crmObject,
    }))[0];

    const crmCompanyObject = getExportObjects(crm, 'company').map((crmObject) => ({
        label: crmObject,
        value: crmObject,
    }))[0];

    return {
        target: crm,
        database,
        objects_to_create: defaultSendAsObject,
        alongside_objects: exportType === 'contact' ? [crmCompanyObject] : [],
        contacts,
        default_tags: defaultVainuTags,
        assign_info: {
            attached_resources_to_company_owner: true,
            owner_ids: [],
            replace_company_owner: false,
        },
        ...(isFullListExport
            ? {
                  query: {
                      '?ALL': [
                          { '?EQ': { 'target_group._id': list?.id } },
                          { '?NOT': { '?IN': { [queryKey]: excludeCompanyIds } } },
                      ],
                  },
              }
            : { query: { '?ANY': [{ '?IN': { [queryKey]: companyIds } }] } }),
    };
};

const getExportPayload = ({
    ignoreContactFilter,
    formValues,
    exportType,
    crmContactObject,
    list,
    contactFilter,
}: {
    ignoreContactFilter: boolean;
    formValues: ExportForm;
    exportType: 'company' | 'contact';
    crmContactObject: CRMObject;
    list?: List;
    contactFilter?: Operation;
}): ConfirmNewMassExportParams => {
    const { objects_to_create, alongside_objects, contacts, ...payload } = formValues;

    const objectsToCreate = [objects_to_create, ...alongside_objects].map(({ value }) => value as CRMObject);
    const hasContactObject = objectsToCreate.some((alongsideObject) => alongsideObject === crmContactObject);

    const hasContacts = hasContactObject && contacts.length > 0;
    const isContactExport = exportType === 'contact';

    const selectedContacts = contacts
        .slice(0, EXPORT_SIZE_LIMIT)
        .map((contact) => ({ business_id: contact.businessId, contact_uid: contact.uid }));

    const contact_filter =
        isContactExport || (selectedContacts.length === 0 && hasContactObject)
            ? { contact_filter: contactFilter }
            : undefined;

    return {
        ...payload,
        include_companies_that_dont_match_contact_filter: ignoreContactFilter,
        meta_data: {
            target_group_name: list?.name ?? '',
            default_vainu_tags: list?.name ? [list?.name] : [],
            objects_to_create: objectsToCreate,
        },
        assign_info: {
            ...payload.assign_info,
            owner_ids: payload.assign_info.owner_ids.map((crmUser) => crmUser.value),
        },
        objects_to_create: objectsToCreate,
        contacts: isContactExport || hasContacts ? selectedContacts : null,
        ...contact_filter,
    };
};

const CRMExport: React.FC<CRMExportProps> = ({ open, ...exportFormState }) => {
    if (!open) return null;

    return <CRMExportFormDialog {...exportFormState} />;
};

const ONGOING_EXPORTS_LIMIT = 3;

interface InProgressBadgeOverload {
    (companiesOrContacts: string[], jobId: string, hasCompanyObject?: boolean): void;
    (companiesOrContacts: ContactPayload[], jobId: string, hasCompanyObject?: boolean): void;
}

const CRMExportFormDialog: React.FC<CRMExportDialogProps> = ({
    selectionType,
    exportType,
    ignoreContactFilter = false,
    database,
    crm,
    listItemsCount,
    list,
    companyIds,
    excludeCompanyIds = [],
    defaultVainuTags = [],
    contacts = [],
    contact_filter,
    onClose,
}) => {
    const queryClient = useQueryClient();
    const { getCRMUsers, confirmNewMassExport } = useConnectorsApi();
    const [, { pollUserAsyncProcess }] = useAsyncProcess();
    const [{ field_mappings }, { refetchMappings }] = useCRMIntegConfig();
    const { setNotificationDrawerOpen } = useNotification();

    const { hasProductPermission } = usePermissionContext();
    const hasContactDataPermission = hasProductPermission(Permission.ContactData);

    const ongoingExports = getOngoingExports(
        useRecoilValue(filteredCrmMassExportProcesses({})) as CRMBulkExportAsyncProcess[],
    );

    const crmName = useMemo(() => getCRMName(crm), [crm]);

    const isSingleCompanyExport = !!companyIds && companyIds.length === 1;
    const isContactsQueryEnabled: boolean =
        isSingleCompanyExport && database !== 'DOMAIN_DATA_BASIC' && exportType !== 'contact';
    const isFullListExport = selectionType === 'list' && !!list && !!listItemsCount;

    const exportSize =
        exportType === 'contact'
            ? isFullListExport
                ? listItemsCount
                : contacts.length
            : isFullListExport
              ? listItemsCount - excludeCompanyIds.length
              : (companyIds as string[]).length;

    const isOngoingExportsLimitReached = ongoingExports.length >= ONGOING_EXPORTS_LIMIT;
    const isExportSizeLimitReached = exportSize > EXPORT_SIZE_LIMIT;

    const [isSettingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
    const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);

    useEffect(() => {
        refetchMappings(crm);
    }, [crm, refetchMappings]);

    const { crmCompanyObject, crmContactObject } = useMemo(
        () => ({
            crmCompanyObject: getExportObjects(crm, 'company')[0],
            crmContactObject: getExportObjects(crm, 'contact')[0],
        }),
        [crm],
    );

    const { sendAsOptions, alongsideOptions } = useMemo(() => {
        const companyLikeCrmObjects = getExportObjects(crm, 'company');
        const contactLikeCrmObjects = getExportObjects(crm, 'contact');
        const crmObjectsWithMappings = getCRMObjectsWithMappings(field_mappings);

        const sendAsObjects = crmObjectsWithMappings.filter((crmObject) =>
            exportType === 'company'
                ? companyLikeCrmObjects.includes(crmObject)
                : contactLikeCrmObjects.includes(crmObject),
        );

        const alongsideObjects = crmObjectsWithMappings
            .filter((crmObject) =>
                exportType === 'company'
                    ? !companyLikeCrmObjects.includes(crmObject)
                    : !contactLikeCrmObjects.includes(crmObject),
            )
            .filter((crmObject) => (database === 'DOMAIN_DATA_BASIC' ? crmObject !== crmContactObject : true));

        return {
            sendAsOptions: sendAsObjects.map((crmObject) => ({ label: crmObject, value: crmObject })),
            alongsideOptions: alongsideObjects.map((crmObject) => ({ label: crmObject, value: crmObject })),
        };
    }, [exportType, crm, database, crmContactObject, field_mappings]);

    const disabledAlongsideOptions = useMemo(
        () => (hasContactDataPermission ? [] : alongsideOptions.filter((option) => option.label === crmContactObject)),
        [hasContactDataPermission, crmContactObject, alongsideOptions],
    );

    const { data: crmUsers = [] } = useQuery({
        queryKey: [`${crm}-users`],
        queryFn: () => getCRMUsers(crm).then(({ data }) => data),
    });

    const crmUsersOptions = useMemo(() => crmUsers.map((user) => ({ value: user.id, label: user.name })), [crmUsers]);

    // fetch company's contacts if a user exports a company and selects the "Contact" object as an alongside data
    const { data: { contacts: fetchedContacts = [] } = { contacts: [] } } = useProfileData(
        { key: companyIds?.[0] ?? '', type: 'organization' },
        ['contacts'],
        { enabled: isContactsQueryEnabled },
    );

    const contactOptions = useMemo(
        () => (isSingleCompanyExport ? fetchedContacts.map((contact) => getContactOption(contact, companyIds[0])) : []),
        [isSingleCompanyExport, fetchedContacts, companyIds],
    );

    const initialFormState = useMemo(
        () =>
            getExportFormInitialState({
                isFullListExport,
                exportType,
                crm,
                database,
                list,
                defaultVainuTags,
                companyIds,
                excludeCompanyIds,
                contacts,
            }),
        [isFullListExport, exportType, crm, database, list, defaultVainuTags, companyIds, excludeCompanyIds, contacts],
    );

    const { control, watch, getValues, setValue } = useForm({ defaultValues: initialFormState });

    useEffect(() => {
        const currentCRMUser = crmUsers.find((user) => user.is_you);

        if (currentCRMUser) {
            setValue('assign_info.owner_ids', [{ label: currentCRMUser.name, value: currentCRMUser.id }]);
        }
    }, [crmUsers, setValue]);

    const handleShowOngoingExports = useCallback(() => {
        onClose();
        setNotificationDrawerOpen(true);
    }, [setNotificationDrawerOpen, onClose]);

    const addInProgressExport: InProgressBadgeOverload = useCallback(
        (companiesOrContacts, jobId, hasCompanyObject) => {
            const isContactData = (arg: string | ContactPayload): arg is ContactPayload => {
                return typeof arg !== 'string';
            };

            companiesOrContacts.forEach((item) => {
                const companyIdOrContactId = isContactData(item) ? item.business_id : item;

                const setFakeExport = (targetObject: 'Account' | 'Contact') => {
                    queryClient.setQueryData<
                        { [id: string]: Array<ProspectExport | ProspectExportInternal> } | undefined
                    >([`all-exported-prospect-to-crm-${companyIdOrContactId}-${targetObject}`], (prevExports) => {
                        if (prevExports && companyIdOrContactId) {
                            const target = mapCRMNameToTarget(crmName);
                            const newCRMBadge: ProspectExportInternal = {
                                status: 'progress',
                                target,
                                target_fk: uuid(),
                                job_id: jobId,
                                ...(isContactData(item) && {
                                    activity_log: [
                                        {
                                            u: 1,
                                            event: 'mass_export',
                                            change: null,
                                            source_objects: [{ obj_type: 'contact', obj_id: item.contact_uid }],
                                        },
                                    ],
                                    contactId: item.contact_uid,
                                }),
                            };
                            const newCRMBadges = [...(prevExports[companyIdOrContactId] || [])];

                            // add after first export of the same crm so chip orders won't change
                            newCRMBadges.splice(
                                newCRMBadges.findIndex((item) => item.target === target) || newCRMBadges.length - 1,
                                0,
                                newCRMBadge,
                            );

                            return { [companyIdOrContactId]: newCRMBadges };
                        }

                        return prevExports;
                    });
                };

                if (isContactData(item)) {
                    // don't put a fake export for a company if a user is exporting a contact without a "Company" object
                    hasCompanyObject && setFakeExport('Account');
                    setFakeExport('Contact');
                } else {
                    setFakeExport('Account');
                }
            });
        },
        [crmName, queryClient],
    );

    const handleStartExport = useCallback(async () => {
        const exportPayload = getExportPayload({
            ignoreContactFilter,
            exportType,
            formValues: getValues(),
            crmContactObject,
            list,
            contactFilter: contact_filter,
        });

        const { data } = await confirmNewMassExport(exportPayload);
        pollUserAsyncProcess({ jobId: data.job_id });

        if (!isFullListExport && companyIds && exportType === 'company') {
            addInProgressExport(companyIds, data.job_id);
        }
        if (!isFullListExport && companyIds && exportType === 'contact' && exportPayload.contacts) {
            addInProgressExport(
                exportPayload.contacts,
                data.job_id,
                exportPayload.objects_to_create.includes(crmCompanyObject),
            );
        }

        if (exportPayload.contacts || (exportPayload.objects_to_create.includes(crmContactObject) && contact_filter)) {
            createIntercomEvent({
                eventName: 'Contacts exported - CRM',
            });
        }

        onClose();
    }, [
        ignoreContactFilter,
        isFullListExport,
        crmContactObject,
        exportType,
        crmCompanyObject,
        companyIds,
        contact_filter,
        list,
        getValues,
        confirmNewMassExport,
        addInProgressExport,
        pollUserAsyncProcess,
        onClose,
    ]);

    const alongsideObjects = watch('alongside_objects');

    const exportItemsOverLimit = isFullListExport
        ? (listItemsCount as number) - EXPORT_SIZE_LIMIT
        : (exportType === 'company' ? (companyIds as string[]) : contacts)?.length - EXPORT_SIZE_LIMIT;

    const dialogHeader =
        exportType === 'contact'
            ? `Send ${pluralizeSuffix(isExportSizeLimitReached ? EXPORT_SIZE_LIMIT : exportSize, 'contact', 'contacts')} to ${crmName}`
            : `Send ${pluralizeSuffix(isExportSizeLimitReached ? EXPORT_SIZE_LIMIT : exportSize, 'company', 'companies')} to ${crmName}`;

    return (
        <>
            <Dialog
                open
                onClose={onClose}
                data-testid="crm_export_dialog"
                isSubmitDisabled={isOngoingExportsLimitReached}
                title={<SourceToDestination target={crm} />}
                onSubmit={handleStartExport}
                confirmLabel={`Send to ${crmName}`}
            >
                {/* <button onClick={() => console.log(getValues())}>Get values</button> */}
                <Typography variant="h4" sx={{ marginTop: '8px', marginBottom: '8px' }}>
                    {dialogHeader}
                </Typography>
                {isOngoingExportsLimitReached && (
                    <FeedbackBanner
                        severity="error"
                        label={`The max amount of ongoing exports is reached (${ongoingExports.length}/${ONGOING_EXPORTS_LIMIT})`}
                        action="View ongoing"
                        onActionClick={handleShowOngoingExports}
                    />
                )}
                {isExportSizeLimitReached && (
                    <FeedbackBanner
                        data-testid="export-size-limit-warning"
                        severity="warning"
                        label={
                            <Typography variant="body2" maxWidth={450}>
                                You can send only <b>{EXPORT_SIZE_LIMIT.toLocaleString()}</b>{' '}
                                {exportType === 'company' ? 'companies' : 'contacts'} at once.{' '}
                                <b>{exportItemsOverLimit.toLocaleString()}</b>{' '}
                                {exportType === 'company' ? 'companies' : 'contacts'} will not be sent.
                            </Typography>
                        }
                    />
                )}
                <DialogContentWrapper hasError={isOngoingExportsLimitReached}>
                    <InputContainer sx={{ marginTop: '28px' }}>
                        <InputLabel label="Send as:" />
                        <Controller
                            name="objects_to_create"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    data-testid="send-as-input"
                                    multiple={false}
                                    width="304px"
                                    options={sendAsOptions}
                                    value={field.value}
                                    renderOption={(props, option) => (
                                        <MenuOption {...props}>
                                            <StyledObjectSvgIcon crm={crm} object={option.label as CRMObject} />
                                            <Typography variant="small" marginLeft={1}>
                                                {option.label}
                                            </Typography>
                                        </MenuOption>
                                    )}
                                    onValueChange={(crmObject) => field.onChange(crmObject)}
                                />
                            )}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputLabel
                            label="Send more data alongside:"
                            tooltipText={`Exporting other objects will link them with the same ${
                                exportType === 'company' ? crmCompanyObject : crmContactObject
                            } and owner`}
                        />
                        <Controller
                            name="alongside_objects"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    width="304px"
                                    placeholder="Select data"
                                    options={
                                        alongsideOptions.length > 0
                                            ? alongsideOptions
                                            : new Array(4).fill({ label: '', value: 'skeleton' })
                                    }
                                    value={field.value}
                                    disableCloseOnSelect
                                    disabledOptions={disabledAlongsideOptions}
                                    renderOption={(props, option, { selected, index }) => {
                                        const key = `${option.value}_${index}`;

                                        return option.value === 'skeleton' ? (
                                            <MenuOption key={key} sx={{ padding: '5px 10px' }}>
                                                <Skeleton width={index % 2 === 0 ? 150 : 200} />
                                            </MenuOption>
                                        ) : (
                                            <MenuOption key={key} hasCheckbox {...props}>
                                                <Checkbox checked={selected} />
                                                <StyledObjectSvgIcon crm={crm} object={option.label as CRMObject} />
                                                <Typography variant="small" marginLeft={1}>
                                                    {option.label}
                                                </Typography>
                                                {props['aria-disabled'] && (
                                                    <ContactsUpgradeButton
                                                        sx={{
                                                            position: 'absolute',
                                                            right: '4px',
                                                            top: '50%',
                                                            transform: 'translateY(-50%)',
                                                            marginLeft: 1,
                                                        }}
                                                        onClick={() => setUpgradeDialogOpen(true)}
                                                    />
                                                )}
                                            </MenuOption>
                                        );
                                    }}
                                    onValueChange={(crmObjects) => field.onChange(crmObjects)}
                                />
                            )}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputLabel
                            label="Include Vainu segments:"
                            tooltipText={`Vainu Segments helps you find or segment the companies from this list in ${crmName}`}
                        />
                        <Controller
                            name="default_tags"
                            control={control}
                            render={({ field }) => (
                                <ChipInput
                                    width="304px"
                                    createLabel="Add"
                                    values={field.value}
                                    options={defaultVainuTags ? defaultVainuTags : []}
                                    placeholder="Search or create a segment"
                                    setValues={field.onChange}
                                />
                            )}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputLabel label="Assign info:" />
                        <Controller
                            name="assign_info.owner_ids"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    data-testid="assign-info-input"
                                    limitTags={1}
                                    placeholder="Select a person"
                                    width="304px"
                                    value={field.value}
                                    options={crmUsersOptions}
                                    onValueChange={(crmUsers) => field.onChange(crmUsers)}
                                />
                            )}
                        />
                    </InputContainer>
                    {alongsideObjects.some((option) => option.value === crmContactObject) && isSingleCompanyExport && (
                        <InputContainer>
                            <InputLabel label="Include contacts:" />
                            <Controller
                                name="contacts"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        addMassSelectionOptions
                                        disableCloseOnSelect
                                        placeholder="Select (optional)"
                                        width="304px"
                                        value={field.value.map((contact) => ({
                                            ...contact,
                                            label: contact.full_name ?? `${contact.first_name} ${contact.last_name}`,
                                            value: contact.uid,
                                        }))}
                                        options={contactOptions}
                                        renderOption={renderContactOptions}
                                        onValueChange={(contacts) => field.onChange(contacts)}
                                    />
                                )}
                            />
                        </InputContainer>
                    )}
                    <InputContainer sx={{ marginTop: '16px' }}>
                        <InputLabel
                            label="Overwrite current owners:"
                            tooltipText={`If some ${getObjectPluralForm(
                                crm,
                                crmCompanyObject,
                            )} already exist in ${crmName}, you can overwrite their owners with the people you've assigned here`}
                        />
                        <Controller
                            name="assign_info.replace_company_owner"
                            control={control}
                            render={({ field }) => (
                                <Switch
                                    value={field.value}
                                    onChange={(event) => field.onChange(event.target.checked)}
                                />
                            )}
                        />
                    </InputContainer>
                    <InputContainer sx={{ marginTop: '16px' }}>
                        <InputLabel
                            label={`${
                                Object.keys(field_mappings).length > 0
                                    ? (field_mappings as MappingsMap)[Object.keys(field_mappings)[0] as CRMObject]
                                          .length
                                    : 0
                            } data fields included:`}
                        />
                        <ConnectorDrawerWithButton
                            crm={crm}
                            tab="mapping"
                            open={isSettingsDrawerOpen}
                            buttonText="Manage data"
                            buttonProps={{
                                variant: 'tertiary',
                                size: 'small',
                            }}
                            handleClick={() => setSettingsDrawerOpen(true)}
                            handleClose={() => setSettingsDrawerOpen(false)}
                        />
                    </InputContainer>
                </DialogContentWrapper>
            </Dialog>
            <ContactsUpgradeDialog open={upgradeDialogOpen} onClose={() => setUpgradeDialogOpen(false)} />
        </>
    );
};

const renderContactOptions = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: SelectOption,
    state: EnhancedAutocompleteRenderOptionState,
): React.ReactNode => {
    if (option.value === MassSelectionOptions.SelectAll || option.value === MassSelectionOptions.DeselectAll) {
        const isAllSelected = state.selectedOptions === state.totalOptions;
        const isSomeSelected = state.selectedOptions > 0 && state.selectedOptions < state.totalOptions;

        return (
            <MenuOption isMassSelectOption hasCheckbox {...props} sx={{ height: '51px' }}>
                <Checkbox disableRipple checked={isAllSelected} indeterminate={isSomeSelected} />
                <Typography variant="subtitle2" sx={{ marginTop: '2px' }}>
                    {option.value === MassSelectionOptions.SelectAll && state.selectedOptions === 0
                        ? `Select all (${state.totalOptions})`
                        : `${state.selectedOptions} selected`}
                </Typography>
            </MenuOption>
        );
    }

    const contactOption = option as ContactOption;
    const contactTitles =
        contactOption.titles
            ?.filter((title) => title.title)
            .map((title) => title.title)
            .join(', ') || '';

    return (
        <MenuOption hasCheckbox {...props} sx={{ height: '51px' }}>
            <Checkbox disableRipple checked={state.selected} sx={{ alignSelf: 'flex-start' }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ContactOptionHeading>
                    <Typography variant="subtitle2">{option.label}</Typography>
                    {checkDecisionMaker(contactOption) && (
                        <ContactOptionIcon type="Award" fontSize="small" color="brandColors.tertiaryBlue" />
                    )}
                    {contactOption.metadata?.phone_available && (
                        <ContactOptionIcon type="Phone" fontSize="small" color="icon.subtle" />
                    )}
                    {contactOption.metadata?.email_available && (
                        <ContactOptionIcon type="Mail" fontSize="small" color="icon.subtle" />
                    )}
                    {contactOption.web_profiles?.some((profile) => profile.type === 'linkedin') && (
                        <ContactOptionIcon type="LinkedIn" fontSize="small" />
                    )}
                </ContactOptionHeading>
                <Typography variant="mini" color="subtle">
                    {contactTitles}
                </Typography>
            </div>
        </MenuOption>
    );
};

const DialogContentWrapper = styled('div')<{ hasError: boolean }>(({ hasError }) => ({
    width: 500,
    opacity: hasError ? 0.3 : 1,
    pointerEvents: hasError ? 'none' : 'auto',
}));

const StyledInputLabel = styled(MUIInputLabel)(({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    color: palette.text.primary,
    fontSize: '14px',
}));

interface TooltipInputLabelProps {
    label: string;
    tooltipText?: string;
}

const InputLabel: React.FC<TooltipInputLabelProps> = ({ label, tooltipText }) => {
    if (!tooltipText) {
        return <StyledInputLabel>{label}</StyledInputLabel>;
    }

    return (
        <StyledInputLabel>
            {label}
            <Tooltip title={tooltipText} placement="top" arrow>
                <span style={{ height: '16px', marginLeft: '4px' }}>
                    <Icon
                        type="HelpCircleOutline"
                        fontSize="small"
                        sx={{ cursor: 'pointer', width: '16px', height: '16px', color: 'icon.subtle' }}
                    />
                </span>
            </Tooltip>
        </StyledInputLabel>
    );
};

const InputContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
});

const StyledObjectSvgIcon = styled(ObjectSvgIcon)({
    width: '20px',
    height: '20px',
});

const ContactOptionIcon = styled(Icon)({
    marginLeft: '2px',
    width: '18px',
    height: '18px',
});

const ContactOptionHeading = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 1,
});

export { CRMExport };
