import React from 'react';

import { Controller, get, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import { useFilterOptionsContext } from 'contexts/FilterOptions/FilterOptionsContext';

import {
    FilterConfig,
    FilterDefinition,
    FilterID,
    FilterProps,
    GroupValues,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { valueArrayToText } from '../utils';

const FIELD = 'target_group#_id';

const inPath = `${FilterOperator.IN}.${FIELD}`;
const notInPath = `${FilterOperator.NOT}.${inPath}`;

export type TValues = { [FIELD]: string[] };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.target_group,
    label: 'Results',
    defaultOperator: FilterOperator.IN,
    initValue: {
        [FilterOperator.IN]: {
            [FIELD]: [],
        },
    },
};

export const includeExcludeListFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.target_group,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        render: (props: FilterProps<TValues>) => <IncludeExcludeLists {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [
    {
        value: FilterOperator.IN,
        label: 'include',
    },
    {
        value: `${FilterOperator.NOT}${FilterOperator.IN}`,
        label: 'exclude',
    },
];

const formatOperatorValue = (value: OperatorValue): Operator => {
    return { value, label: operators.find((o) => o.value === value)?.label || '' };
};

const IncludeExcludeLists: React.FC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);
    const { selectListFilterOptions } = useFilterOptionsContext();

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const optionsToDisable = sameFiltersState.flatMap(
        (sameState) => get(sameState, inPath) || get(sameState, notInPath),
    );
    const disabledOptions = selectListFilterOptions.filter((option) => optionsToDisable.includes(option.value));

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        formatOperatorValue,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;

                    const selectedOptions = selectListFilterOptions.filter((item) =>
                        value.includes(item.value as string),
                    );

                    return (
                        <Select
                            placeholder={selectedOptions?.length ? 'Or...' : 'Select'}
                            multiple={true}
                            width="100%"
                            options={selectListFilterOptions}
                            value={selectedOptions}
                            disabledOptions={disabledOptions}
                            onValueChange={(options) => onChange((options as SelectOption[]).map((o) => o.value))}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
