import React, { useContext, useCallback } from 'react';

import { Button as MuiButton, styled } from '@mui/material';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';

import { stopAsyncProcessByProcessId, updateAsyncProcess } from 'api/asyncProcesses';
import { CRMBulkExportAsyncProcess } from 'api/types/UserAsyncProcess';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { DialogController } from 'contexts/DialogContext/DialogController';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import { useDialogContext } from 'hooks/useDialogContext';
import { filteredCrmFullExportProcesses } from 'store/asyncProcessAtomSelectors';

import ViewOnlyFeedback from '../common/SettingsViewOnlyFeedback';
import { StyledCard } from '../common/Styled';
import { useCRMIntegConfig } from '../context/CRMIntegrationConfigContext';
import { useCompanyMatch } from '../hooks/useCompanyMatch';
import UpdateSkeleton from '../skeletons/UpdateSkeleton';
import { TabEnum } from '../types';
import { getCompanyLikeObjectForCrm, CRMTargetText, getObjectSingularForm } from '../utils/CRMTargetText';
import { DataUpdatesPermisssionDialog } from './DataUpdatesPermissionDialog';
import { NoUpdatesPermissionBanner } from './NoUpdatesPermissionBanner';
import RevertCard from './RevertCard';
import SummaryCard from './SummaryCard';
import UpdatesCard from './UpdatesCard';

interface UpdatesTabProps {
    setTab: React.Dispatch<React.SetStateAction<TabEnum>>;
}

const UpdatesTab: React.FC<UpdatesTabProps> = ({ setTab }) => {
    const intl = useIntl();
    const [
        { enrich_enabled, auto_syncing_enabled, crm, field_mapping, error: integrationError },
        { startFullExport, saveSyncOption },
    ] = useCRMIntegConfig();
    const { showSnackbar } = useSnackbarContext();
    const { matchedByCountries, matchedByDomains } = useCompanyMatch();
    const { isAccountAdmin, isDomainConnector, hasProductPermission } = useContext(PermissionContext);
    const axios = useAxiosContext();
    const { handleDialogOpen } = useDialogContext();
    // FIXME: This should not need type assertion from T | T[] to T.
    const latestExportJob = useRecoilValue(filteredCrmFullExportProcesses({ crm, onlyLatest: true })) as
        | CRMBulkExportAsyncProcess
        | undefined;

    const matchedCompaniesCount = isDomainConnector ? matchedByDomains.count : matchedByCountries.count;

    const handleEnrichChange = useCallback(
        async ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
            try {
                if (checked) {
                    await saveSyncOption({ enrich_enabled: true, auto_syncing_enabled: true });
                    await startFullExport({ export_source: 'auto_update' });

                    window.analytics?.track('Connector: Enabled automatic update', { app: crm });
                } else {
                    await saveSyncOption({ enrich_enabled: false });
                    window.analytics?.track('Connector: Disabled automatic update', { app: crm });
                }
            } catch (e) {
                console.error(e);
                showSnackbar('😔 Oops, something went wrong, please try again or contact support', 'error');
            }
        },
        [crm, saveSyncOption, startFullExport, showSnackbar],
    );

    const handleStopUpdate = useCallback(async () => {
        if (latestExportJob) {
            await stopAsyncProcessByProcessId(axios, latestExportJob.id);
            await updateAsyncProcess(axios, latestExportJob.job_id, { seen: true });
        }
    }, [latestExportJob, axios]);

    const handleManualUpdateStart = useCallback(
        () => startFullExport({ restart: true, export_source: 'manual_update' }),
        [startFullExport],
    );

    const handleGoToMatching = useCallback(() => setTab('matching'), [setTab]);

    const handleGoToMappings = useCallback(() => setTab('mapping'), [setTab]);

    const crmAccountObject = getCompanyLikeObjectForCrm(crm);
    const accountNameInPlural = CRMTargetText(crm, 'company');
    // prevent app crash when 'crm' or 'crmAccountObject' is undefined 😐
    const accountName = crm && crmAccountObject && getObjectSingularForm(crm, crmAccountObject);

    const hasUpdatesPermission = hasProductPermission('crm_data_update');

    /* Disable data update feature if the below condition is met */
    const dataUpdateDisabled =
        (!auto_syncing_enabled && matchedCompaniesCount === 0) || !hasUpdatesPermission || !isAccountAdmin;

    if (crm && !field_mapping?.length && !integrationError) {
        return <UpdateSkeleton />;
    }

    return (
        <>
            {!hasUpdatesPermission && (
                <NoUpdatesPermissionBanner handleUpgradeClick={() => handleDialogOpen('DATA_UPDATES_PERMISSION')} />
            )}
            <Wrapper disabled={!hasUpdatesPermission}>
                {!isAccountAdmin ? <ViewOnlyFeedback /> : null}
                <Typography variant="h4" weight="bold">
                    {accountName} Updates
                </Typography>
                <GridHalf>
                    <SummaryCard
                        header="Data fields"
                        tooltipDescription={
                            <Typography fontSize="12px">
                                Selected fields receive updated data when updates are on
                            </Typography>
                        }
                        countObject={{
                            count: field_mapping.filter((mapping) => mapping.target_object === crmAccountObject).length,
                            text: 'included for updates',
                        }}
                        component="updates-mapped"
                        footerComponent={
                            <MappingButton fullWidth onClick={handleGoToMappings}>
                                Manage company data
                            </MappingButton>
                        }
                    />
                </GridHalf>
                <GridHalf>
                    <SummaryCard
                        header={`${accountNameInPlural} to update`}
                        tooltipDescription={
                            <Typography fontSize="12px">
                                To improve the amount of {accountNameInPlural} that can receive updates, check your{' '}
                                <Typography
                                    component="span"
                                    fontSize="12px"
                                    style={{
                                        color: 'common.white',
                                        textDecoration: 'underline',
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleGoToMatching}
                                >
                                    company matching
                                </Typography>{' '}
                                results.
                            </Typography>
                        }
                        countObject={{
                            count:
                                matchedCompaniesCount > 1000
                                    ? intl.formatNumber(matchedCompaniesCount, { notation: 'compact' })
                                    : matchedCompaniesCount,
                            text: 'Matched',
                        }}
                        component="matched"
                        numberByCountry={
                            !isDomainConnector && matchedCompaniesCount ? matchedByCountries.country_counts : []
                        }
                        footerComponent={
                            !matchedCompaniesCount && (
                                <Typography>
                                    Companies need to{' '}
                                    <Typography
                                        component="span"
                                        style={{ color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}
                                        onClick={handleGoToMatching}
                                    >
                                        match
                                    </Typography>{' '}
                                    before updates can be turned on.
                                </Typography>
                            )
                        }
                    />
                </GridHalf>
                <UpdatesCard
                    isAutomaticUpdateOn={enrich_enabled && auto_syncing_enabled}
                    isCompanyMatched={matchedCompaniesCount > 0}
                    isDataUpdateDisabled={dataUpdateDisabled}
                    crm={crm}
                    latestJob={latestExportJob}
                    handleAutomaticUpdate={handleEnrichChange}
                    handleManualUpdateStart={handleManualUpdateStart}
                    handleStopManualUpdate={handleStopUpdate}
                />
                <RevertCard />
            </Wrapper>
            <DialogController type="DATA_UPDATES_PERMISSION" component={DataUpdatesPermisssionDialog} />
        </>
    );
};

export default UpdatesTab;

const MappingButton = styled(MuiButton)(({ theme: { palette, shape } }) => ({
    height: 40,
    color: palette.brandColors.body,
    background: palette.grey[50],
    fontSize: '12px',
    fontWeight: 500,
    border: `1px solid ${palette.border}`,
    borderRadius: shape.borderRadius,
}));

const Wrapper = styled(StyledCard)<{ disabled?: boolean }>(({ disabled }) => ({
    display: 'grid',
    gap: '30px',
    gridAutoColumns: '1fr 1fr',
    alignContent: 'start',
    overflow: 'scroll',

    '> *': {
        gridColumn: 'span 2',
    },

    ...(disabled && {
        opacity: 0.3,
        pointerEvents: 'none',
    }),
}));

const GridHalf = styled('div')({
    gridColumn: 'span 1',
});
