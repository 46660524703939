import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Edit = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.76083 18.743L5.25783 14.2392L16.7891 2.70715C17.1796 2.31661 17.8128 2.3166 18.2033 2.70713L21.2929 5.79668C21.6834 6.18721 21.6834 6.82038 21.2929 7.2109L9.76083 18.743ZM3.14889 20.2623C3.07817 20.6127 3.38735 20.9219 3.73778 20.8513L8.64142 19.8632L4.13842 15.3594L3.14889 20.2623Z"
        />
    </SvgIcon>
);

export default Edit;
