import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ExtraDict } from 'api/types/Trigger';
import ChipInput from 'components/tokens/ChipInput';
import Switch from 'components/tokens/Switch';
import TextField from 'components/tokens/TextField';
import { usePermissionContext } from 'contexts/PermissionContext';

function validateEmail(email: string) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export type EmailSystem = 'email';

export interface EmailDestinationType {
    system: EmailSystem;
    emails: string[];
    active: boolean;
    isValid?: boolean;
    extra?: ExtraDict;
}

export interface EmailDestinationProps {
    destination: EmailDestinationType | null;
    updateDestination: (obj: EmailDestinationType) => void;
    hideHeader?: boolean;
}

const validate = (obj: Pick<EmailDestinationType, 'emails' | 'active'>) => {
    const { active, emails } = obj;
    return !!(active && emails?.length);
};

const EmailDestination: React.FC<EmailDestinationProps> = ({ destination, updateDestination, hideHeader = false }) => {
    const { profile } = usePermissionContext();
    const [values, setValues] = useState(
        destination?.emails?.length ? destination.emails : profile.userName ? [profile.userName] : [],
    );
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [enabled, setEnabled] = useState(!!destination?.active);
    const [extra, setExtra] = useState(destination?.extra || {});

    useEffect(() => {
        if (destination?.active || destination?.extra) {
            setEnabled(destination?.active);
            setExtra(destination?.extra || {});
        }
    }, [destination?.active, destination?.extra]);

    const onValueChange = (emails: string[]) => {
        setValues(emails);
        updateDestination({
            system: 'email',
            emails,
            active: true,
            isValid: validate({ emails, active: true }),
        });
    };
    const onEnableToggle = (enable: boolean) => {
        setEnabled(enable);
        updateDestination({
            system: 'email',
            emails: values,
            active: enable,
            isValid: validate({ emails: values, active: enable }),
        });
    };
    const editExtra = (field: string, value: string) => {
        const editedExtra = { ...extra, [field]: value };
        setExtra(editedExtra);
        updateDestination({
            system: 'email',
            emails: values,
            active: true,
            extra: editedExtra,
            isValid: validate({ emails: values, active: true }),
        });
    };
    const handleValidateEmail = (email: string) => {
        if (!email) {
            return false;
        }
        const isValid = validateEmail(email);
        if (!isValid) {
            setShowErrorSnackbar(true);
        }
        return isValid;
    };

    return (
        <div>
            {hideHeader ? null : (
                <Grid
                    container
                    key={destination?.system}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ padding: '5px 0' }}
                >
                    <Typography sx={{ fontWeight: 500, fontSize: 14 }}>Email</Typography>
                    <Switch
                        checked={enabled}
                        onChange={(e) => onEnableToggle(e.target.checked)}
                        data-testid="email-toggle-switch"
                    />
                </Grid>
            )}
            {enabled ? (
                <StyledColumn>
                    <StyledRow>
                        <ChipInput
                            values={values}
                            width="300px"
                            setValues={onValueChange}
                            onValidateInput={handleValidateEmail}
                            placeholder="Email address"
                            createLabel="Add"
                        />
                    </StyledRow>
                    <StyledRow>
                        <StyledColumn>
                            <Box sx={{ height: 38, padding: '5px 0', display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 500, fontSize: 14, margin: 0 }}>Email subject</Typography>
                            </Box>
                            <TextField
                                value={extra?.email_subject || ''}
                                type="text"
                                placeholder="We have new leads for you!"
                                onChange={(e) => editExtra('email_subject', e.target.value)}
                                size="small"
                                sx={{ width: '300px' }}
                            />
                        </StyledColumn>
                    </StyledRow>
                </StyledColumn>
            ) : null}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={showErrorSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowErrorSnackbar(false)}
                message={<span>Invalid email!</span>}
            />
        </div>
    );
};

export default EmailDestination;

const StyledRow = styled('div')`
    display: flex;
`;

const StyledColumn = styled('div')`
    display: flex;
    flex-direction: column;
`;
