import React from 'react';

import { Skeleton as MUISkeleton, styled as MuiStyled, styled } from '@mui/material';
import { SkeletonProps } from '@mui/material/Skeleton';

const GenericSkeleton = (props: SkeletonProps) => <MUISkeleton variant="rectangular" {...props} />;

const Skeleton = MuiStyled(GenericSkeleton)<{ radius?: string | number; margin?: string | number }>(
    ({ radius = 7, margin }) => ({
        margin,
        borderRadius: radius,
    }),
);

export default Skeleton;

export const Container = styled('div')`
    margin-top: 23px;
    margin-bottom: 0;
`;
export const Row = styled('div')<{ margin?: string; justifyContent?: string }>`
    width: 100%;
    display: flex;
    margin: ${(props) => props.margin};
    justify-content: ${(props) => props.justifyContent};
    align-items: center;
`;
