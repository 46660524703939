import React, { useState } from 'react';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import NestedSelect from 'components/tokens/select-components/NestedSelect';

import { mergeKeys } from '../../common/utils';
import { TValues } from '../../filters/web-profile/website-traffic-rank/WebsiteTraffic';
import { FilterGroup, FilterGroupProps, FilterID, GroupOption, GroupValues, NodeValue } from '../../FilterTypes';
import { getGroupFilters, useFilterGroupOptions } from '../common/groupUtils';
import { useGroupActions, useGroupNodes } from '../common/useGroup';
import FilterGroupTemplate from '../FilterGroupTemplate';

const options: GroupOption[] = [
    {
        value: FilterID.website_traffic_ranking,
        label: 'Website traffic rank',
    },
    {
        value: FilterID.urls_keywords,
        label: 'Website keywords',
    },
    {
        value: FilterID.website_language,
        label: 'Website language',
    },
    {
        label: 'Technology',
        value: 'technology',
        options: [
            {
                value: FilterID.technology_all,
                label: 'All',
            },

            {
                value: FilterID.technology_advertising,
                label: 'Advertising',
            },
            {
                value: FilterID.technology_analytics,
                label: 'Analytics',
            },
            {
                value: FilterID.technology_ats,
                label: 'Applicant Tracking (ATS)',
            },
            {
                value: FilterID.technology_cms,
                label: 'CMS',
            },
            {
                value: FilterID.technology_crm,
                label: 'CRM',
            },
            {
                value: FilterID.technology_customer_service,
                label: 'Customer service',
            },
            {
                value: FilterID.technology_domain_server,
                label: 'Domain server',
            },
            {
                value: FilterID.technology_e_commerce,
                label: 'E-commerce',
            },
            {
                value: FilterID.technology_email_server,
                label: 'Email server',
            },
            {
                value: FilterID.technology_marketing,
                label: 'Marketing',
            },
            {
                value: FilterID.technology_payment,
                label: 'Payment',
            },
            {
                value: FilterID.technology_reservation,
                label: 'Reservation',
            },
            {
                value: FilterID.technology_server,
                label: 'Server',
            },
            {
                value: FilterID.technology_social_media,
                label: 'Social media',
                databases: ['DOMAIN_DATA_BASIC'],
            },
            {
                value: FilterID.technology_web_tech,
                label: 'Web tech',
            },
        ],
    },
    /*{ // Temporary hidden
        value: FilterID.digitalization_index,
        label: 'Digitalization index',
    },*/
    {
        value: FilterID.website_url,
        label: 'URL',
    },
    {
        value: FilterID.description_keywords,
        label: 'Description keywords',
    },
    {
        value: FilterID.recruitment_keywords,
        label: 'Recruitment keywords',
    },
];

const createNewGroup = (value: NodeValue<TValues>) =>
    ({
        [FilterOperator.MATCH]: { [GroupValues.web_profile]: { [FilterOperator.ALL]: [value] } },
    }) as NodeValue<TValues>;

const config: FilterGroup = {
    group: GroupValues.web_profile,
    label: 'Web profile',
    options,
    filterUUIDs: [],
    logic: {},
    render: (props: FilterGroupProps) => <WebProfileGroup {...props} />,
    getFilters: getGroupFilters,
    createNewGroup,
};

export const webProfileGroupDefinition: Record<GroupValues.web_profile, FilterGroup> = {
    [GroupValues.web_profile]: config,
};

export const WebProfileGroup: React.FC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    removeGroup,
    ids = [],
    addNewGroup,
    updateGroup,
    updateGroups,
    disabled = false,
}) => {
    const filterGroups = useGroupNodes(ids);
    const { activeFilters, filterToHide, filteredOptions } = useFilterGroupOptions({ filterGroups, options });

    const { handleAddFilter, handleFilterRemove, handleApplyChanges, keys } = useGroupActions({
        applyChanges,
        removeGroup,
        addNewGroup,
        updateGroup,
        updateGroups,
        createNewGroup,
        filterGroups,
        options: filteredOptions,
    });

    const renderFilters = () => {
        return ids.map((id) =>
            filterGroups[id].filters.map((filter, index) => {
                const filterDefinition = filter.definition;

                const sameFiltersState: NodeValue[] = [];
                activeFilters.forEach((activeFilter) => {
                    if (
                        (activeFilter.id === filter.id ||
                            activeFilter.definition.subGroup === filter.definition.subGroup) &&
                        activeFilter !== filter
                    ) {
                        sameFiltersState.push(activeFilter.value as NodeValue);
                    }
                });

                return filterDefinition
                    ? filterDefinition.render({
                          key: keys[mergeKeys(id, filter.id)],
                          uuid: filterDefinition.id,
                          filter,
                          groupUUID: config.group,
                          removeFilter: () => handleFilterRemove(id, index),
                          sameFiltersState,
                      })
                    : null;
            }),
        );
    };

    const [addRow, setAddRow] = useState(false);
    const [isFilterAdded, setIsFilterAdded] = useState(false);

    const addRowSelect = (
        <NestedSelect
            placeholder="Select"
            width="100%"
            height={40}
            containerSx={{ gridArea: 'add' }}
            nestedOptions={filteredOptions}
            slimInactiveBorder
            onChange={(value) => {
                if (Array.isArray(value)) {
                    throw new Error('should not be array value');
                }
                if (typeof value === 'number') {
                    throw new Error('should not be number value');
                }
                if (typeof value === 'string') {
                    throw new Error('should not be string value');
                }
                if (!value) {
                    return;
                }
                handleAddFilter(value.value as FilterID);
                setAddRow(false);
                setIsFilterAdded(true);
            }}
        />
    );
    const addRowButton = (
        <Button
            variant="tertiary"
            size="small"
            onClick={() => setAddRow(true)}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            AND
        </Button>
    );

    const renderedFilters = renderFilters();
    const props = {
        label: config.label,
        filters: renderedFilters,
        addFilter: handleAddFilter,
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        addRowSelect,
        addRowButton,
        addRow,
        isFilterAdded,
        setIsFilterAdded,
        filterToHide,
        disabled,
    };
    return <FilterGroupTemplate {...props} />;
};
