import { isArray } from 'lodash';

import { getValue } from 'components/features/Filters/filters/utils';
import { FilterID } from 'components/features/Filters/FilterTypes';

import { MatcherResult, createMatcher } from '../validation/matchers';
import { Slot, SlotStatus, getOfficeTypeValue, isSelfGroup } from './helpers';
import { getRuleShowAddOfficeTypeButton, RULE_VALIDATE_REMOVABLE_STATUSES } from './rules';

export type ValidationResult = {
    message?: string;
    isLocationTypeError?: boolean;
    status: SlotStatus;
    level?: 'warn' | 'err';
};

function isHQ(value: 'any' | string[]) {
    if (value === 'any') {
        return false;
    }

    return value.length === 1 && ['hq', 'registry_main_location'].includes(value[0]);
}

export function validateSlot(slot: Slot): ValidationResult {
    if (!slot.values || !slot.values.length) {
        if (slot.type !== 'location') {
            return { status: null };
        }
        return { status: null };
    }

    const officeType = getOfficeTypeValue(slot.values);

    if (slot.values.length === 1) {
        // If slot has only one selfgroup filter - mark it as completed
        if (isSelfGroup(slot.values[0].id)) {
            return { status: 'complete' };
        }

        // If officeType filter has value set then show a warning about country
        if (officeType !== 'any') {
            return {
                status: 'invalid',
                message: 'Specify a location for selected Location types.',
                isLocationTypeError: true,
            };
        }
    }

    let locationsCount = 0;
    slot.values.forEach((slotValue) => {
        if (slotValue.id !== FilterID.location_type) {
            const countryValues = getValue(slotValue.id, slotValue.value);

            if (
                (isArray(countryValues) && countryValues?.length) ||
                (!isArray(countryValues) && typeof countryValues === 'object')
            ) {
                locationsCount++;
            }
        }
    });

    if (isHQ(officeType) && locationsCount > 1) {
        return { status: 'invalid', message: 'Search for headquarters in only one location', level: 'warn' };
    } else if (officeType !== 'any' && locationsCount === 0) {
        return {
            status: 'invalid',
            message: 'Specify a location for selected Location types.',
            isLocationTypeError: true,
        };
    }

    return { status: null };
}

// TODO: Migrate to rules
export function validateGroup(slots: Slot[]) {
    const locationTypeSlotMap: Record<string, Slot> = {};
    const results: ValidationResult[] = [];

    slots.forEach((slot) => {
        const result = validateSlot(slot);

        if (slot.type !== 'location') {
            results.push(result);
            return;
        }

        const currentOfficeType = getOfficeTypeValue(slot.values);
        const key = isArray(currentOfficeType) ? currentOfficeType.join() : currentOfficeType;
        const existingSlot = locationTypeSlotMap[key];

        if (!existingSlot) {
            locationTypeSlotMap[key] = slot;
        } else {
            results.push({
                status: 'invalid',
                message: 'Choose another Location type',
                isLocationTypeError: true,
            });
            return;
        }

        const existingTypes = Object.keys(locationTypeSlotMap);

        const isAnyDisabled = existingTypes.length > 1;

        if (isAnyDisabled && currentOfficeType === 'any') {
            results.push({
                status: 'invalid',
                message: 'Choose another Location type',
                isLocationTypeError: true,
            });
            return;
        }

        results.push(result);
    });

    return results;
}

/**
 * 'null' is when the slot has no action available
 * 'remove' is when slot (filter group) can be removed fully
 * 'clear' is when only filters can be removed but the slot stays in place
 * 'withdraw' is like 'clear' but for range (location count/ office count/ country count) filter when it is opened
 */
type RemovableStatus = 'null' | 'remove' | 'clear' | 'withdraw';

export function getRemovableStatuses(slots: Slot[]): RemovableStatus[] {
    const matcher = createMatcher(RULE_VALIDATE_REMOVABLE_STATUSES);

    const matcherNotLocation = createMatcher({
        rule: {
            some: {
                rule: {
                    or: [
                        { priority: 2, message: 'null', rule: { property: { key: 'values.length', value: 0 } } },
                        {
                            priority: 1,
                            message: 'withdraw',
                            rule: { property: { key: 'values.length', value: 0, operator: 'gte' } },
                        },
                    ],
                },
                filter: { not: { property: { key: 'type', value: 'location' } } },
            },
        },
    });

    const locationGroups = matcher(slots);
    const notLocationGroups = matcherNotLocation(slots);

    if (isArray(locationGroups.answer)) {
        const answer2 = notLocationGroups.answer as MatcherResult[];
        return locationGroups.answer?.map((r, index) => answer2[index].message ?? r.message ?? 'null');
    }

    return slots.map(() => 'null');
}

export function isShowAddOfficeTypeButton(slots: Slot[], officeTypeOptionsCount = 2) {
    const matcher = createMatcher(getRuleShowAddOfficeTypeButton(officeTypeOptionsCount));

    const { valid } = matcher(slots);
    return valid;
}
