import { useRef, useEffect, useReducer, useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { produce, setAutoFreeze } from 'immer';

import { getAsyncProcessTypeCRMImportByCRM, getAsyncProcessTypeCRMBulkExportByCRM } from 'api/types/UserAsyncProcess';
import { useAuthContext } from 'contexts/AuthContext';
import useAsyncProcess from 'hooks/useAsyncProcess';

import useConnectorsApi from '../hooks/useConnectorsApi';
import {
    BulkDataExportSource,
    CRMAccountSettingsLegacy,
    CRMObject,
    CRMPropertiesMap,
    CrmName,
    FieldMapping,
    IntegrationOption,
    CRMIntegrationSettings,
    VainuDatapoint,
    IntegrationSetting,
    MappingsMap,
} from '../types/Crm';

setAutoFreeze(false); // immer 3.1.2 -> 9.0.6, not tested with default autofreeze on

export type CRMFieldMap = FieldMapping<CRMObject>;

export type CrmState = {
    isLoaded: boolean;
    active: boolean;
    enrich_enabled: boolean;
    auto_syncing_enabled: boolean;
    full_import_done: boolean;
    crm: CrmName;
    id: string | undefined;
    error: string | undefined;
    account: string | undefined;
    integration_target: string | undefined;
    auto_syncing_user_id: number | undefined;
    last_full_sync: string | null | undefined;
    crmPropertiesMap: CRMPropertiesMap;
    extra_settings:
        | {
              create_opportunity: number;
              objects_to_create: string[];
              pipeline?: string; // Hubspot specific
              pipeline_stage?: string; // Hubspot specific
          }
        | {};
    // field_mapping (without 's' in the end) contains old schema mappings
    // todo: migrate to field_mappings everywhere
    field_mapping: CRMFieldMap[];
    field_mappings: MappingsMap | {};
    vainuDatapoints: VainuDatapoint[];
};

type StartFullExportParams = {
    restart?: boolean;
    enrich_type?: 'automatic' | 'manual';
    export_source?: BulkDataExportSource;
};

export type Dispatch = {
    initConfig: (crm: CrmName) => Promise<void>;
    updateIntegrationSetting: (crm: CrmName) => Promise<void>;
    getCRMConfig: (crmName: CrmName) => Promise<CRMAccountSettingsLegacy>;
    startFullExport: (params?: StartFullExportParams) => Promise<void>;
    startFullImport: () => Promise<void>;
    saveSyncOption: (props: { enrich_enabled?: boolean; auto_syncing_enabled?: boolean }) => Promise<void>;
    refetchMappings: (crm?: CrmName) => Promise<void>;
    refetchCRMProperties: () => Promise<void>;
};

const initialState: CrmState = {
    isLoaded: false,
    active: false,
    enrich_enabled: false,
    auto_syncing_enabled: false,
    full_import_done: false,
    crm: '' as CrmName,
    id: undefined,
    error: undefined,
    account: undefined,
    integration_target: undefined,
    auto_syncing_user_id: undefined,
    last_full_sync: undefined,
    crmPropertiesMap: {} as CRMPropertiesMap,
    extra_settings: {},
    field_mapping: [],
    field_mappings: {},
    vainuDatapoints: [],
};

type Action =
    | { type: 'resetState' }
    | { type: 'setIsLoaded'; payload: boolean }
    | { type: 'initVainuDatapoints'; payload: VainuDatapoint[] }
    | { type: 'initCRMProperties'; payload: { crm: CrmName; crmPropertiesMap: CRMPropertiesMap } }
    | {
          type: 'initIntegrationSettings';
          payload: {
              setting: IntegrationSetting<CRMObject>;
              options: IntegrationOption;
              newSetting: CRMIntegrationSettings;
          };
      }
    | { type: 'initFailed'; payload: string }
    | { type: 'refreshMappings'; payload: MappingsMap }
    | { type: 'refreshCRMProperties'; payload: CRMPropertiesMap }
    | { type: 'saveSyncOption'; payload: { auto_syncing_enabled?: boolean; enrich_enabled?: boolean } };

const crmIntegrationReducer = produce((draft: CrmState, action: Action) => {
    switch (action.type) {
        case 'resetState':
            draft = {
                ...initialState,
                // don't reset Vainu datapoints as they are the same for all CRMs
                vainuDatapoints: draft.vainuDatapoints,
            };
            break;
        case 'setIsLoaded':
            draft.isLoaded = action.payload;
            break;
        case 'initVainuDatapoints':
            draft.vainuDatapoints = action.payload;
            break;
        case 'initCRMProperties':
            const { crm, crmPropertiesMap } = action.payload;
            draft.crm = crm;
            draft.crmPropertiesMap = crmPropertiesMap;
            draft.error = '';
            break;
        case 'initIntegrationSettings':
            const { setting, options, newSetting } = action.payload;
            draft.active = setting.active;
            draft.full_import_done = options.full_import_done;
            draft.auto_syncing_enabled = options.auto_syncing_enabled;
            draft.enrich_enabled = options.enrich_enabled;
            draft.id = setting.id;
            draft.account = setting.account;
            draft.integration_target = setting.integration_target;
            draft.auto_syncing_user_id = options.auto_syncing_user_id;
            draft.last_full_sync = options.last_full_sync;
            draft.extra_settings = setting.extra_settings;
            draft.field_mapping = setting.field_mapping;
            draft.field_mappings = newSetting.field_mappings;
            break;
        case 'initFailed':
            draft.error = action.payload;
            break;
        case 'refreshMappings':
            draft.field_mappings = action.payload;
            break;
        case 'refreshCRMProperties':
            draft.crmPropertiesMap = action.payload;
            break;
        case 'saveSyncOption':
            const { enrich_enabled, auto_syncing_enabled } = action.payload;
            draft.enrich_enabled = enrich_enabled !== undefined ? enrich_enabled : draft.enrich_enabled;
            draft.auto_syncing_enabled =
                auto_syncing_enabled !== undefined ? auto_syncing_enabled : draft.auto_syncing_enabled;

            // don't allow enrich: true; auto_sync: false
            if (enrich_enabled === true) {
                draft.auto_syncing_enabled = true;
            }
            if (auto_syncing_enabled === false && draft.enrich_enabled === true) {
                draft.enrich_enabled = false;
            }

            break;
        default:
            throw Error(`Unhandled action type: ${(action as Action).type}`);
    }
    return draft;
});

export default function useCRMIntegrationConfig(): [CrmState, Dispatch] {
    const { authStatus } = useAuthContext();
    const {
        startCRMImport,
        createFullExport,
        getIntegrationOptions,
        getCRMAccountSettings,
        saveIntegrationOptions,
        getCRMIntegrationSettings,
        getCRMAccountSettingsLegacy,
        getIntegrationSettingsForCRM,
        getVainuIntegrationSettingsFields,
    } = useConnectorsApi();

    const [state, dispatch] = useReducer(crmIntegrationReducer, { ...initialState });
    const abortController = useRef<AbortController | null>(null);

    const [, { pollUserAsyncProcess, pollMultipleUserAsyncProcesses }] = useAsyncProcess();

    const { isSuccess: isVainuDatapointsLoaded, data: vainuDatapoints } = useQuery({
        queryKey: ['vainu-datapoints'],
        queryFn: () => getVainuIntegrationSettingsFields().then(({ data }) => data),
        staleTime: Infinity,
        enabled: authStatus === 'logged-in',
    });

    // useEffect instead of useQuery's onSuccess, because onSuccess doesn't trigger when data is taken from cache.
    // possible case: we have several CRMIntegConfigProvider and the 1st one will send a request,
    // while the 2nd won't and the onSuccess won't be triggered, so cached data won't be put into the 2nd context's state
    useEffect(() => {
        if (isVainuDatapointsLoaded) {
            dispatch({ type: 'initVainuDatapoints', payload: vainuDatapoints });
        }
    }, [isVainuDatapointsLoaded, vainuDatapoints]);

    const refetchMappings = useCallback(
        async (crm?: CrmName) => {
            const { data } = await getCRMIntegrationSettings(crm ?? state.crm);
            dispatch({ type: 'refreshMappings', payload: data.field_mappings });
        },
        [state.crm, getCRMIntegrationSettings],
    );

    const refetchCRMProperties = useCallback(async () => {
        const { data } = await getCRMAccountSettings(state.crm);
        dispatch({ type: 'refreshCRMProperties', payload: data.crm_objects });
    }, [state.crm, getCRMAccountSettings]);

    const getCRMConfig = useCallback(
        async (crm: CrmName, opts?: { signal: AbortSignal }) => {
            try {
                // todo: migrate to getCRMAccountSettings()
                const { data } = await getCRMAccountSettingsLegacy(crm, opts?.signal);
                return data;
            } catch (e) {
                if (!axios.isCancel(e)) {
                    const error = e as Error & { response?: { data?: { error?: string; message?: string } } };
                    throw new Error(`${error?.response?.data?.error}: ${error?.response?.data?.message}`);
                }
                throw e;
            }
        },
        [getCRMAccountSettingsLegacy],
    );

    const updateIntegrationSetting = useCallback(
        async (crm: CrmName, opts?: { signal: AbortSignal }) => {
            // Requests order is strictly defined.
            // Pipedrive requires `getCRMIntegrationSettings` to be sent first.
            const { data: newSetting } = await getCRMIntegrationSettings(crm, opts?.signal);
            const { data: options } = await getIntegrationOptions('account', crm, opts?.signal);
            const { data: setting } = await getIntegrationSettingsForCRM(crm, opts?.signal);

            dispatch({ type: 'initIntegrationSettings', payload: { setting, options, newSetting } });
            dispatch({ type: 'setIsLoaded', payload: true });
        },
        [getIntegrationSettingsForCRM, getIntegrationOptions, getCRMIntegrationSettings],
    );

    const initConfig = useCallback(
        async (crm: CrmName) => {
            if (!crm) return;

            if (abortController.current) {
                abortController.current.abort();
            }

            abortController.current = new AbortController();

            try {
                dispatch({ type: 'resetState' });
                dispatch({ type: 'setIsLoaded', payload: false });

                const crmConfig = await getCRMConfig(crm, { signal: abortController.current.signal });
                dispatch({
                    type: 'initCRMProperties',
                    payload: { crm, crmPropertiesMap: crmConfig.objects },
                });

                await updateIntegrationSetting(crm, { signal: abortController.current.signal });

                /*  Subscribe to connectors related user async processes */
                await pollMultipleUserAsyncProcesses([
                    getAsyncProcessTypeCRMImportByCRM(crm),
                    getAsyncProcessTypeCRMBulkExportByCRM(crm),
                ]);
            } catch (e) {
                // todo: fix error type
                if (!axios.isCancel(e)) {
                    const error = e as Error & { response?: { data?: { error?: string; message?: string } } };
                    dispatch({
                        type: 'initFailed',
                        payload: error?.response?.data?.error
                            ? `${error?.response?.data?.error}: ${error?.response?.data?.message}`
                            : error.toString(),
                    });
                    throw e;
                }
            }
        },
        [getCRMConfig, updateIntegrationSetting, pollMultipleUserAsyncProcesses],
    );

    const startFullExport = useCallback(
        async ({ restart, export_source }: StartFullExportParams = {}) => {
            const {
                data: { job_id },
            } = await createFullExport({
                target: state.crm,
                enrich_type: 'automatic',
                restart,
                // Adding export source to meta_data
                // to separate manual and automatic data updates
                ...(export_source
                    ? {
                          meta_data: {
                              export_source,
                          },
                      }
                    : {}),
            });

            pollUserAsyncProcess({
                jobId: job_id,
                type: `${state.crm}-full-export`,
            });
        },
        [state.crm, createFullExport, pollUserAsyncProcess],
    );

    const startFullImport = useCallback(async () => {
        const response = await startCRMImport(state.crm);
        const jobId = response?.data?.job_id;
        if (jobId) {
            pollUserAsyncProcess({
                jobId,
                type: `${state.crm}-import`,
            });
        }
    }, [state.crm, startCRMImport, pollUserAsyncProcess]);

    const saveSyncOption = useCallback(
        async (param: { enrich_enabled?: boolean; auto_syncing_enabled?: boolean }) => {
            const { enrich_enabled, auto_syncing_enabled } = param;
            await saveIntegrationOptions({ target: state.crm, enrich_enabled, auto_syncing_enabled });
            dispatch({ type: 'saveSyncOption', payload: { enrich_enabled, auto_syncing_enabled } });
        },
        [state.crm, saveIntegrationOptions],
    );

    return [
        state,
        {
            initConfig,
            getCRMConfig,
            updateIntegrationSetting,
            startFullExport,
            startFullImport,
            saveSyncOption,
            refetchMappings,
            refetchCRMProperties,
        },
    ];
}
