import React, { useContext } from 'react';

import { IntegrationKey } from 'api/types/ApplicationStore';
import ListMenu from 'components/templates/ListMenu';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { useTriggerState } from 'contexts/TriggerContext';
import { enabledCRMs } from 'utilities/triggers';

import { icons } from '../TriggerSettings/SelectDestinationPopper';

interface Props {
    openExportModal: (value: string) => void;
    label?: string;
    allowDestinations?: string[];
}

const ExportDropDown: React.FC<Props> = ({ openExportModal, label, allowDestinations = [] }) => {
    const permissionContext = useContext(PermissionContext);
    const {
        new: { activeCRMIntegrationSettings },
    } = useTriggerState();

    const common = ['email'];

    const requiresIntegration = (['slack'] as IntegrationKey[]).filter((i) =>
        permissionContext.hasIntegration(i || ''),
    );

    const CRMS = activeCRMIntegrationSettings.reduce((CRMnames, crmSettings) => {
        if (permissionContext.hasProductPermission('crm_settings')) {
            if (enabledCRMs.includes(crmSettings.integration_target.toLocaleLowerCase())) {
                CRMnames.push(crmSettings.integration_target);
            }
        }
        return CRMnames;
    }, [] as string[]);
    let enabledDestinations: string[] = [...common, ...requiresIntegration, ...CRMS];

    if (allowDestinations?.length) {
        enabledDestinations = enabledDestinations.filter((destination) =>
            allowDestinations.includes(destination || ''),
        );
    }

    const onMenuAction = (destination: string) => {
        openExportModal(destination);
    };

    if (!enabledDestinations?.length) {
        return null;
    }

    return (
        <ListMenu
            anchorElement={
                <Button
                    size="small"
                    variant="tertiary"
                    startIcon={<Icon fontSize="mini" type="Export" color="primary.main" />}
                >
                    {label || 'Export'}
                </Button>
            }
            items={enabledDestinations.map((destination) => ({
                action: destination.toLowerCase(),
                label: `${destination[0].toUpperCase()}${destination.slice(1).toLowerCase()}`,
                icon: icons[destination.toLowerCase()],
            }))}
            onClick={onMenuAction}
            disablePortal={false}
            popperPlacement="bottom-end"
        />
    );
};

export default ExportDropDown;
