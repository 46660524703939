import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Link = (props) => (
    <SvgIcon {...props}>
        <path d="M17 17H14V15H17C18.6569 15 20 13.6569 20 12C20 10.3431 18.6569 9 17 9H14V7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17ZM10 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H10V9H7C5.34315 9 4 10.3431 4 12C4 13.6569 5.34315 15 7 15H10V17ZM17 13H7V11H17V13Z" />
    </SvgIcon>
);

export default Link;
