import React, { useEffect, useMemo, useState } from 'react';

import { Box, Skeleton, Tooltip } from '@mui/material';

import Menu, { MenuItem } from 'components/templates/Menu';
import { Button } from 'components/tokens/Button';
import { Icon } from 'components/tokens/Icon';
import { brandPalette } from 'design/theme/colors';

import { TileBase, TileData } from '../types';
import Legend from './Legend';
import Tile from './Tile';

interface StackedBarTileProps extends Omit<TileBase, 'data'> {
    data: {
        [key: string]: {
            label: string;
            displayLabel?: string;
            data: TileData[];
            tooltip?: React.ReactNode;
        };
    };
    colors?: string[];
}

export const stackedBarTileColors = [
    brandPalette.primaryGreen700,
    brandPalette.primaryGreen600,
    brandPalette.primaryGreen500,
    brandPalette.primaryGreen400,
    brandPalette.primaryGreen300,
    brandPalette.primaryGreen200,
    brandPalette.primaryGreen100,
    brandPalette.white,
];

const StackedBarTile: React.FC<StackedBarTileProps> = ({
    title,
    data,
    limit,
    total,
    colors = stackedBarTileColors,
    sx,
}) => {
    const options = useMemo(
        () =>
            Object.keys(data).map((key) => ({
                ...data[key],
                action: key,
            })),
        [data],
    );

    const [selected, setSelected] = useState<string>(options[0].action);

    useEffect(() => {
        if (!options.find((option) => option.action === selected)) {
            setSelected(options[0].action);
        }
    }, [options, selected]);

    const visibleData = data[selected]?.data;

    const selectedOption = options.find((option) => option.action === selected);

    return (
        <Tile
            title={title}
            sx={sx}
            control={
                options.length > 1 ? (
                    <Menu
                        anchorElement={
                            <Button
                                variant="secondary"
                                endIcon={<Icon fontSize="mini" type="ChevronDown" />}
                                sx={{ borderColor: 'border' }}
                            >
                                {selectedOption?.displayLabel || selectedOption?.label}
                            </Button>
                        }
                        items={[]}
                        onClick={(value) => setSelected(value)}
                        selectedAction={selected}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        sx={{ width: 230 }}
                    >
                        {options.map((option) => (
                            <Tooltip title={option.tooltip} key={option.action} placement="right">
                                <div>
                                    <MenuItem
                                        isSelected={option.action === selected}
                                        onClick={(e, action) => setSelected(action as string)}
                                        {...option}
                                    />
                                </div>
                            </Tooltip>
                        ))}
                    </Menu>
                ) : null
            }
        >
            <Box sx={{ height: 38, display: 'flex', marginBottom: 2, borderRadius: 2, overflow: 'hidden' }}>
                {!total ? (
                    <Skeleton variant="rectangular" sx={{ width: '100%', height: '100%' }} />
                ) : (
                    visibleData?.map((row, idx) => {
                        const percent = row.value ? (row.value * 100) / total : 0;
                        return (
                            <Tooltip
                                title={
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Box>
                                            <span style={{ fontWeight: 500 }}>{row.label}</span>
                                        </Box>
                                        <Box style={{ fontWeight: 400 }}>{Math.round(percent)} %</Box>
                                    </Box>
                                }
                                key={idx}
                            >
                                <Box
                                    sx={{
                                        height: '100%',
                                        background: colors[idx % colors.length],
                                        width: `${percent}%`,
                                        border:
                                            colors[idx % colors.length] === brandPalette.white
                                                ? `2px dotted ${brandPalette.middle}`
                                                : undefined,
                                    }}
                                />
                            </Tooltip>
                        );
                    })
                )}
            </Box>

            {visibleData && (
                <Legend data={limit ? visibleData.slice(0, limit) : visibleData} total={total} colors={colors} />
            )}
        </Tile>
    );
};

export default StackedBarTile;
