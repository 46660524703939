import React from 'react';

import { cloneDeep } from 'lodash';

import { FilterOperator } from 'api/types/FilterOperators';

import {
    FilterConfig,
    FilterDefinition,
    FilterID,
    GroupValues,
    LocationFilterProps,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useChipInputLocationFilter } from '../common/useChipInputFilter';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, valueArrayToText } from '../utils';

export type TValues = { visiting_postal: string[] };

const config: FilterConfig<TValues> = {
    fields: ['visiting_postal'],
    id: FilterID.postal,
    label: 'Postal Code',
    defaultOperator: FilterOperator.IN,
    initValue: {
        [FilterOperator.IN]: {
            visiting_postal: [],
        },
    },
};

export const postalCodeFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.location_visiting_address,
        label: config.label,
        fields: config.fields,
        render: (props: LocationFilterProps<TValues>) => <PostalCode {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => cloneDeep(config.initValue),
    },
};

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

export const PostalCode: React.FC<LocationFilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
    apply,
    forceOpen,
    onClose,
}) => {
    const props = useChipInputLocationFilter({
        filter,
        sameFiltersState,
        uuid,
        groupUUID,
        removeFilter,
        operators,
        placeholder: 'postal code...',
        apply,
        forceOpen,
        onBlur: onClose,
    });

    return <FilterTemplate {...props} />;
};
