import React from 'react';

import { Box, SxProps } from '@mui/material';

const countryCodeToRegionalIndicator = (code: string): string => {
    const letterBase = 0x41; // LATIN CAPITAL LETTER A
    const indicatorBase = 0x1f1e6; // REGIONAL INDICATOR SYMBOL LETTER A
    return code
        .substring(0, 2)
        .toUpperCase()
        .split('')
        .map((char) =>
            char && char >= 'A' && char <= 'Z'
                ? String.fromCodePoint((char.codePointAt(0) as number) - letterBase + indicatorBase)
                : char,
        )
        .join('');
};

export type FlagEmojiProps = {
    code: string;
    sx?: SxProps;
};

export const FlagEmoji = React.forwardRef<HTMLDivElement, FlagEmojiProps>(({ code, sx, ...props }, ref) => (
    <Box ref={ref} sx={{ display: 'inline-block', fontFamily: 'Noto Color Emoji', ...sx }} {...props}>
        {countryCodeToRegionalIndicator(code)}
    </Box>
));

export default FlagEmoji;
