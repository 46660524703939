import { getProfilePath } from 'components/modules/profiles/Profile';
import { ProfileResult } from 'contexts/types/FilterCompanies';

import { NeighborProfile } from './ProfileArrowNavigation';

export function getNeighborProfile(data: ProfileResult): NeighborProfile {
    return {
        name: data.name,
        link: getProfilePath(data.profileType, data.id),
        logo: data.logo_url,
    };
}
