import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CircleRight: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C6.47967 21.994 2.00606 17.5204 2 12V11.8C2.10993 6.30455 6.63459 1.92797 12.1307 2.0009C17.6268 2.07382 22.0337 6.5689 21.9978 12.0654C21.9619 17.5618 17.4966 21.9989 12 22ZM12 4.00002C7.58172 4.00002 4 7.58174 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.5838 16.4162 4.00498 12 4.00002ZM12 17L10.59 15.59L13.17 13H7V11H13.17L10.59 8.41002L12 7.00002L17 12L12 17Z" />
    </SvgIcon>
);

export default CircleRight;
