import { Box, SxProps } from '@mui/material';

export type PageGridProps = {
    sx?: SxProps;
    children?: React.ReactNode;
};

export const PageGrid: React.FC<PageGridProps> = ({ children, sx }) => (
    <Box
        sx={{
            width: '100%',
            maxWidth: (theme) => ({
                xs: theme.params.maxSlimContentWidth + 2 * theme.params.pageContentSpacing,
                profileSidebar: theme.params.maxContentWidth + 2 * theme.params.pageContentSpacing,
            }),
            display: 'grid',
            gridTemplateColumns: {
                xs: 'repeat(4, 1fr)',
            },
            columnGap: 5,
            rowGap: 5,
            paddingX: { xs: 3, md: 8, lg: 10 },
            ...sx,
        }}
    >
        {children}
    </Box>
);

export default PageGrid;
