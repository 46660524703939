import { useMemo } from 'react';

import { getLegacyBusinessId } from 'utilities';

import { useGroupStructure } from 'api/groupStructure';
import { useEmployees, useFinancialStatements } from 'api/profileData';
import { organizationProfileType } from 'api/types/Organization';
import { useBasicDataFromProfileData } from 'components/features/BasicInfo/utils';
import { EmployeesHeader, EmployeesTableSection } from 'components/features/Employees';
import { useOrganizationSize } from 'components/features/Employees/utils';
import { getNewestValueWithChange } from 'components/features/Financials/Charts/utilities';
import { useValidStatements } from 'components/features/Financials/utils';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';

import { OrganizationTabProps, TabContent } from '..';

export const EmployeesTab: React.FC<OrganizationTabProps> = ({ businessId, data, onTabChange }) => {
    const { hasProductPermission } = usePermissionContext();
    const hasContactDataPermission = hasProductPermission(Permission.ContactData);

    const { data: employeesData, isLoading: isLoadingEmployeesData } = useEmployees(
        {
            key: businessId,
            type: organizationProfileType,
        },
        hasContactDataPermission,
    );
    const { data: groupStructure, isLoading: isLoadingGroupStructureData } = useGroupStructure(
        getLegacyBusinessId(businessId),
    );
    const { direct_marketing_denied } = useBasicDataFromProfileData(data);
    const organizationSize = useOrganizationSize(employeesData);
    const groupSize = groupStructure?.[0]?.staff_number;
    const subsidiaryCount = groupStructure?.[0]?.subsidiaries;

    const { data: statementData } = useFinancialStatements({
        type: organizationProfileType,
        key: businessId,
    });
    const validStatements = useValidStatements(statementData?.financial_statements);
    const employeeCountOfficial = useMemo(() => {
        return getNewestValueWithChange(validStatements, 'employees.absolute_count');
    }, [validStatements]);

    return (
        <TabContent>
            <EmployeesHeader
                skeleton={isLoadingEmployeesData || isLoadingGroupStructureData}
                employeeCountWeb={organizationSize}
                employeeCountOfficial={employeeCountOfficial?.value}
                employeeCountOfficialYear={employeeCountOfficial?.year}
                groupEmployeeCount={groupSize}
                subsidiaryCount={subsidiaryCount}
                website={data.website}
                onTabChange={onTabChange}
            />
            <EmployeesTableSection
                skeleton={isLoadingEmployeesData}
                hasContactDataPermission={hasContactDataPermission}
                directMarketingDenied={direct_marketing_denied}
                businessId={businessId}
                country={employeesData?.country}
                allEmployees={employeesData?.contacts}
                companyName={data.name}
            />
        </TabContent>
    );
};

export default EmployeesTab;
