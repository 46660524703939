import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FilterNone: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z" />
    </SvgIcon>
);

export default FilterNone;
