import React from 'react';

import { Box, SxProps } from '@mui/material';

import { combineSx } from 'utilities/mui';

export type GridViewWrapperProps = {
    sx?: SxProps;
    children: React.ReactNode;
};

export const GridViewWrapper = React.forwardRef<HTMLDivElement, GridViewWrapperProps>(({ sx, children }, ref) => (
    <Box
        sx={combineSx(
            {
                display: 'grid',
                gap: '3em 2em',
                gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
            },
            sx,
        )}
        ref={ref}
    >
        {children}
    </Box>
));
