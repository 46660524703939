import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PipedriveContactSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M10 10C10.9946 10 11.9484 9.60491 12.6516 8.90165C13.3549 8.19839 13.75 7.24456 13.75 6.25C13.75 5.25544 13.3549 4.30161 12.6516 3.59835C11.9484 2.89509 10.9946 2.5 10 2.5C9.00544 2.5 8.05161 2.89509 7.34835 3.59835C6.64509 4.30161 6.25 5.25544 6.25 6.25C6.25 7.24456 6.64509 8.19839 7.34835 8.90165C8.05161 9.60491 9.00544 10 10 10V10ZM10 11.6667C7.5 11.6667 2.5 12.9667 2.5 15.5583V17.5H17.5V15.5583C17.5 12.975 12.5 11.6667 10 11.6667V11.6667Z" />
    </SvgIcon>
);
export default PipedriveContactSvg;
