import React from 'react';

import { Link as MuiLink, LinkProps as MuiLinkProps, SxProps } from '@mui/material';

export interface LinkProps extends MuiLinkProps {
    href?: JSX.IntrinsicElements['a']['href'];
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;

    /** If set true, the link always opens to the new tab, using the href prop, and the onClick callback is always
     * ignored. If you want to manually open a new tab using window.open in the onClick, set to false.
     * If set to 'nofollow', acts like when set true, but also sets the nofollow and noreferrer attributes to
     * the anchor element (use when linking to external sites).
     */
    toNewTab?: boolean | 'nofollow';

    /** If set, the link opens to the new tab, if user holds down Ctrl key or Apple Command key, using the
     * href prop, and the onClick is ignored. On non-modified clicks fires the onClick callback. */
    ctrlClickToNewTab?: boolean;
    disabled?: boolean;
    children: React.ReactNode;
    sx?: SxProps;
    disablePropagation?: boolean;
}

export const Link: React.FC<LinkProps> = React.forwardRef(
    (
        {
            onClick,
            href,
            children,
            toNewTab = false,
            ctrlClickToNewTab = true,
            disabled = false,
            disablePropagation = false,
            sx,
            ...props
        },
        ref,
    ) => {
        const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
            if (disablePropagation) event.stopPropagation();
            if (onClick && !toNewTab && (!ctrlClickToNewTab || (!event.ctrlKey && !event.metaKey))) {
                event.preventDefault();
                onClick(event);
            }
        };
        const targetProps = toNewTab
            ? {
                  target: '_blank',
                  rel: toNewTab === 'nofollow' ? 'nofollow noreferrer' : undefined,
              }
            : {};

        if (!onClick && !href) {
            return <span>{children}</span>;
        }
        return disabled ? (
            <span ref={ref} className={props.className}>
                {children}
            </span>
        ) : (
            <MuiLink
                ref={ref}
                onClick={handleClick}
                href={href}
                sx={{
                    cursor: onClick ? 'pointer' : undefined,
                    ...sx,
                }}
                {...targetProps}
                {...props}
            >
                {children}
            </MuiLink>
        );
    },
);

export default Link;
