import React, { useEffect, useState, useCallback } from 'react';

import { Drawer as MUIDrawer, Tab, Tabs, styled } from '@mui/material';

import Drawer from 'components/layout/CompanyProfilePopup';
import OverviewMenu from 'components/templates/ListMenu';
import Button from 'components/tokens/Button';
import Icon, { IconType } from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { DialogController } from 'contexts/DialogContext/DialogController';
import { useDialogContext } from 'hooks/useDialogContext';

import { DynamicsConnectionStopDialog } from '../landing/pages/ConnectionView/DynamicsConnectionStopDialog';
import { MappingsTab } from '../mappings/MappingsTab';
import { CompanyMatchTab } from '../matching';
import { TabEnum } from '../types';
import { CrmName } from '../types/Crm';
import UpdatesTab from '../updates';
import { getCRMName } from '../utils';
import { CRMDisconnectConfirmDialog } from './CRMDisconnectConfirmDialog';
import { CRMLogoAlt } from './CRMLogo';
import { Row } from './Styled';

type CRMIntegrationTileProps = React.ComponentProps<typeof MUIDrawer> & {
    connected?: boolean;
    crm: CrmName;
    defaultTab?: TabEnum;
    forceTabChangeTo?: TabEnum;
    disabledTabs?: TabEnum[];
    errorTabs?: TabEnum[];
};

type SettingsTab = {
    label: string;
    value: TabEnum;
};

const tabs: SettingsTab[] = [
    { label: 'Company matching', value: 'matching' },
    { label: 'Data management', value: 'mapping' },
    { label: 'Data updates', value: 'sync' },
];

type ContextMenuItem = {
    action: string;
    label: string;
    icon: IconType;
};

const contextMenuItems: ContextMenuItem[] = [
    { action: 'help', label: 'Visit our Help Center', icon: 'ExternalLink' },
    { action: 'disconnect', label: 'Disconnect', icon: 'Logout' },
];

const SettingsDrawer: React.FC<CRMIntegrationTileProps> = ({
    connected = false,
    open,
    crm,
    defaultTab = 'matching',
    forceTabChangeTo,
    disabledTabs = [],
    errorTabs = [],
    onClose = () => {},
}) => {
    const [tab, setTab] = useState<TabEnum>(defaultTab);
    const { handleDialogOpen, handleDialogClose } = useDialogContext();

    useEffect(() => {
        if (forceTabChangeTo && forceTabChangeTo !== tab) {
            setTab(forceTabChangeTo);
        }
    }, [tab, forceTabChangeTo]);

    const handleTabChange = (event: React.SyntheticEvent, value: TabEnum) => setTab(value);

    const handleDrawerClose = useCallback(
        (e: React.MouseEvent<Element, MouseEvent>) => {
            const closeHandler = (
                event: React.MouseEvent<Element, MouseEvent>,
                reason: 'backdropClick' | 'escapeKeyDown',
            ) => {
                onClose(event, reason);
            };

            if (crm === 'dynamics' && !connected) {
                handleDialogOpen('STOP_DYNAMICS_CONNECTION', {
                    onSubmit: () => {
                        closeHandler(e, 'backdropClick');
                        handleDialogClose();
                    },
                });
            } else {
                closeHandler(e, 'backdropClick');
            }
        },
        [connected, crm, handleDialogOpen, handleDialogClose, onClose],
    );

    const handleContextMenuClick = (action: string) => {
        if (action === 'disconnect') {
            handleDialogOpen('CRM_DISCONNECT_CONFIRM_DIALOG', { crm });
        } else {
            window.open('https://help.vainu.app/en/articles/120176-connectors-vainu-integration-overview', '_blank');
        }
    };

    return (
        <Drawer open={!!open} onClose={handleDrawerClose}>
            <DrawerHeader>
                <Row>
                    <HeaderTitle>
                        <CRMLogoAlt name={crm} height={42} width={42} />
                        <Typography variant="h4" ml={2}>
                            {getCRMName(crm)}
                        </Typography>
                    </HeaderTitle>
                    <OverviewMenu
                        anchorElement={
                            <Button variant="tertiary" size="small" startIcon={<Icon type="MoreHorizontal" />} />
                        }
                        items={contextMenuItems}
                        onClick={handleContextMenuClick}
                    />
                </Row>
                <Tabs value={tab} onChange={handleTabChange}>
                    {tabs.map((item) => (
                        <StyledTab
                            key={item.value}
                            data-testid={`main-tab-${item.value}`}
                            label={
                                <span style={{ position: 'relative' }}>
                                    {item.label}
                                    {errorTabs.includes(item.value) && <ErrorDot />}
                                </span>
                            }
                            value={item.value}
                            disabled={disabledTabs.includes(item.value)}
                        />
                    ))}
                </Tabs>
            </DrawerHeader>
            <TabContentWrapper fullWidth={tab === 'mapping'}>
                {tab === 'matching' && <CompanyMatchTab crm={crm} />}
                {tab === 'mapping' && <MappingsTab crm={crm} />}
                {tab === 'sync' && <UpdatesTab setTab={setTab} />}
            </TabContentWrapper>
            <DialogController type="STOP_DYNAMICS_CONNECTION" component={DynamicsConnectionStopDialog} />
            <DialogController type="CRM_DISCONNECT_CONFIRM_DIALOG" component={CRMDisconnectConfirmDialog} />
        </Drawer>
    );
};

const StyledTab = styled(Tab)({
    '&.MuiTab-root': {
        minWidth: 0,
        fontSize: '15px',
        textTransform: 'none',
        fontWeight: 'normal',
    },
});

const ErrorDot = styled('span')({
    position: 'absolute',
    right: -10,
    width: 8,
    top: 0,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#DF2664',
});

const DrawerHeader = styled('div')({
    padding: '32px 24px 0px',
    borderBottom: '1px solid rgba(178, 192, 200, 0.5)',
});

const HeaderTitle = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const TabContentWrapper = styled('div')<{ fullWidth: boolean }>(({ fullWidth }) => ({
    '@media (min-width: 950px)': {
        width: fullWidth ? '100%' : 800,
        margin: '0 auto',
    },
}));

export { SettingsDrawer };
