import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DynamicsTaskSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.25 2V19.5H7V18.25H4.5V3.25H17V7.88865L18.25 9.13863V2H3.25ZM18.25 9.5H17V10.75H18.25V9.5Z"
            fill="#0A0A0A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.75 9.86133V22H5.75V4.5H15.3886L20.75 9.86133ZM19.5 10.75H14.5V5.75H7V20.75H19.5V10.75ZM15.75 6.63867V9.5H18.6114L15.75 6.63867Z"
            fill="#0A0A0A"
        />
    </SvgIcon>
);
export default DynamicsTaskSvg;
