import { Tooltip, TooltipProps } from '@mui/material';

export const StyledTooltip = ({ ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        componentsProps={{
            tooltip: {
                sx: {
                    fontSize: 12,
                    padding: '8px',
                    boxShadow: '0px 1px 2px rgba(10, 10, 10, 0.1), 0px 4px 12px rgba(10, 10, 10, 0.15)',
                    borderRadius: '4px',
                },
            },
            // todo: think about the StyledTooltip usages and refactor component
            ...props.componentsProps,
        }}
    />
);

export default StyledTooltip;
