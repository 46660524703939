import React, { ReactNode } from 'react';

import {
    Dialog,
    DialogProps,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    styled,
} from '@mui/material';

import Button from 'components/tokens/Button';
import { DialogCloseButton } from 'components/tokens/Dialog/DialogCloseButton';

import AnimatedEllipsis from './AnimatedEllipsis';

const HeadingIconsWrapper = styled('div')({
    margin: '32px 24px 0px',
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'left',
    padding: 0,
    margin: '8px 24px',
}));

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.subtle,
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
}));

const StyledDialogActions = styled(DialogActions)({
    margin: '0px 24px 32px 24px',
    padding: 0,
});

export type ConfirmDialogProps = Omit<DialogProps, 'title'> & {
    warning?: boolean;
    headingIcons?: ReactNode;
    title: ReactNode;
    titleAnimatedEllipsis?: boolean;
    subTitle: ReactNode;
    extraBodyContent?: ReactNode;
    handleClose?: (e: React.SyntheticEvent) => void;
    primaryButtonClick: (e: React.SyntheticEvent) => void;
    primaryButtonText: string;
    primaryButtonDisabled?: boolean;
    secondaryButtonClick?: (e: React.SyntheticEvent) => void;
    secondaryButtonText?: string;
    tertiaryButtonClick?: (e: React.SyntheticEvent) => void;
    tertiaryButtonText?: string;
    dialogPaperClass?: string;
    customWidth?: string;
    isSmall?: boolean;
    noMargin?: boolean;
    dialogContentNoScroll?: boolean;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    warning,
    headingIcons,
    title,
    titleAnimatedEllipsis,
    subTitle,
    extraBodyContent,
    primaryButtonClick,
    secondaryButtonClick,
    tertiaryButtonClick,
    handleClose,
    primaryButtonText,
    primaryButtonDisabled,
    secondaryButtonText,
    tertiaryButtonText,
    dialogPaperClass,
    customWidth = '',
    isSmall = false,
    noMargin = false,
    dialogContentNoScroll,
    ...props
}) => {
    return (
        <Dialog
            sx={{
                '.MuiPaper-root': {
                    position: 'absolute',
                    padding: '0px',
                    textAlign: 'left',
                    borderRadius: '8px',
                    width: customWidth || (isSmall ? 438 : 615),
                },
            }}
            fullWidth
            {...props}
        >
            {handleClose && <DialogCloseButton onClick={handleClose} />}

            {headingIcons && <HeadingIconsWrapper>{headingIcons}</HeadingIconsWrapper>}
            {title && (
                <StyledDialogTitle>
                    {title}
                    {titleAnimatedEllipsis && <AnimatedEllipsis />}
                </StyledDialogTitle>
            )}

            <DialogContent
                sx={{
                    padding: 0,
                    margin: noMargin ? '0' : '8px 24px 24px 24px',
                    display: 'grid',
                    gridTemplateRows: ' auto auto',
                    overflow: dialogContentNoScroll ? 'hidden' : 'auto',
                }}
            >
                {subTitle && <StyledDialogContentText>{subTitle}</StyledDialogContentText>}
                {extraBodyContent || null}
            </DialogContent>

            {primaryButtonClick && (
                <StyledDialogActions>
                    {tertiaryButtonClick && (
                        <Button disableRipple onClick={tertiaryButtonClick} variant="flat">
                            {tertiaryButtonText}
                        </Button>
                    )}
                    {secondaryButtonClick && (
                        <Button disableRipple onClick={secondaryButtonClick} variant="flat">
                            {secondaryButtonText}
                        </Button>
                    )}
                    <Button
                        data-testid="submit-button"
                        onClick={primaryButtonClick}
                        variant={warning ? 'critical' : 'primary'}
                        disabled={primaryButtonDisabled}
                    >
                        {primaryButtonText}
                    </Button>
                </StyledDialogActions>
            )}
        </Dialog>
    );
};

export default ConfirmDialog;
