import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowLongRight = (props) => (
    <SvgIcon {...props}>
        <path d="M18.17 13L15.59 15.59L17 17L22 12L17 7L15.59 8.41L18.17 11H2V13H18.17Z" />
    </SvgIcon>
);

export default ArrowLongRight;
