import { Grid, Typography } from '@mui/material';

export const UnderlineField: React.FC<{ label: string | React.ReactNode; isUnderlined?: boolean }> = ({
    label,
    isUnderlined = true,
}) => {
    return (
        <Grid
            container
            sx={
                isUnderlined
                    ? { borderBottom: '1px solid rgba(178, 192, 200, 0.5)', padding: 5 }
                    : { borderBottom: 'inherit', padding: 5 }
            }
            style={{ padding: 0 }}
        >
            <Grid item>
                <Typography sx={{ fontSize: '14px', color: '#5A6A78', fontWeight: 400 }}>{label}</Typography>
            </Grid>
        </Grid>
    );
};
