import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import StyledTooltip from '../common/StyledTooltip';

interface Props {
    text: React.ReactNode;
    icon?: React.ReactNode;
    arrow?: boolean;
    className?: string;
    style?: Record<string, string | number>;
    color?: string;
    size?: 'inherit' | 'large' | 'medium' | 'small' | 'mini';
}
const TooltipIcon: React.FC<Props> = ({ text, icon, arrow = true, className, color, size = 'small', style = {} }) => {
    return (
        <StyledTooltip title={text} placement="top" arrow={arrow}>
            <div>
                {icon || (
                    <InfoOutlinedIcon
                        fontSize={size}
                        className={className}
                        sx={{ color: color, cursor: 'pointer', display: 'flex' }}
                        style={style}
                    />
                )}
            </div>
        </StyledTooltip>
    );
};

export default TooltipIcon;
