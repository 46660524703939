import React from 'react';

import { Box, ClickAwayListener, Paper, Popper, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import { Contact } from 'api/types/Organization';
import { StyledMenuItem } from 'components/templates/ListMenu/styled';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Tag from 'components/tokens/Tag';
import Typography from 'components/tokens/Typography';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import { brandPalette } from 'design/theme/colors';

interface ContactInfoDropdownProps {
    disabled: boolean;
    type: 'phone' | 'email';
    verificationTimestamp?: string;
    contact: Contact;
    options: string[] | undefined;
}

const ContactInfoDropdown: React.FC<ContactInfoDropdownProps> = ({
    disabled,
    type,
    verificationTimestamp,
    contact,
    options = [],
}) => {
    const { showSnackbar } = useSnackbarContext();
    const popupState = usePopupState({ variant: 'popper', popupId: `contact-${type}-${contact.uid}` });

    if (!contact.metadata?.[`${type}_available`]) {
        return <div />;
    }

    const handleClick = async (option: string) => {
        await navigator.clipboard.writeText(option);
        showSnackbar(`${contact.full_name}'s ${type === 'phone' ? 'number' : 'email'} copied to clipboard`);
    };

    return (
        <>
            <Button
                disabled={disabled}
                variant="flat"
                size="small"
                startIcon={<Icon type={type === 'phone' ? 'Phone' : 'Mail'} />}
                endIcon={<Icon type="ChevronDown" sx={{ color: 'text.subtle' }} />}
                {...bindTrigger(popupState)}
            />
            <Popper {...bindPopover(popupState)} placement="bottom-start" sx={{ zIndex: 1300 }}>
                <ClickAwayListener onClickAway={() => popupState.close()}>
                    <Paper sx={{ padding: 1 }}>
                        {options.map((option) => (
                            <StyledMenuItem
                                key={option}
                                onClick={() => handleClick(option)}
                                sx={{ '& .MuiSvgIcon-root': { marginRight: 0 } }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 1,
                                        width: '100%',
                                    }}
                                >
                                    {option}
                                    {verificationTimestamp && (
                                        <Tooltip
                                            title={
                                                <div>
                                                    <Typography variant="body2">
                                                        Verified by Vainu data researchers
                                                    </Typography>
                                                    <Typography variant="body2" color="subtle">
                                                        {format(new Date(verificationTimestamp), 'MMM dd, yyyy')}
                                                    </Typography>
                                                </div>
                                            }
                                        >
                                            <Tag
                                                label={
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        verified
                                                        <Icon
                                                            fontSize="mini"
                                                            type="CircleCheck"
                                                            color={brandPalette.primaryGreen500}
                                                        />
                                                    </div>
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                    <Icon type="Copy" color="text.subtle" fontSize="small" />
                                </Box>
                            </StyledMenuItem>
                        ))}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
};

interface ContactInfoProps {
    disabled: boolean;
    contact: Contact;
}

const ContactConnectInfo: React.FC<ContactInfoProps> = ({ disabled, contact }) => {
    const linkedinProfile = contact.web_profiles?.find((profile) => profile.type === 'linkedin');

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 54px)' }}>
            <ContactInfoDropdown
                disabled={disabled}
                contact={contact}
                type="phone"
                verificationTimestamp={contact.metadata?.fields?.phone_number?.vainu_verified}
                options={contact.phone ? [contact.phone] : undefined}
            />
            <ContactInfoDropdown
                disabled={disabled}
                contact={contact}
                type="email"
                verificationTimestamp={contact.metadata?.fields?.email?.vainu_verified}
                options={contact.email ? [contact.email] : undefined}
            />
            {linkedinProfile ? (
                <Link disabled={disabled} href={linkedinProfile.url} toNewTab="nofollow">
                    <Button
                        disabled={disabled}
                        variant="flat"
                        size="small"
                        sx={{ opacity: disabled ? 0.5 : 1 }}
                        startIcon={<Icon type="LinkedInSquare" />}
                        endIcon={<Icon type="ExternalLink" sx={{ color: 'text.subtle' }} />}
                    />
                </Link>
            ) : null}
        </Box>
    );
};

export { ContactConnectInfo };
