import { IconButton } from '@mui/material';

import ListMenu from 'components/templates/ListMenu';
import Icon from 'components/tokens/Icon';

import { TriggerAction } from '../Triggers';

export interface TriggerListMenuProps {
    onClick: (action: TriggerAction) => void;
    isActive: boolean;
    isValid: boolean;
}

const TriggerListMenu: React.FC<TriggerListMenuProps> = ({ onClick, isActive, isValid, ...props }) => {
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <ListMenu
                {...props}
                anchorElement={
                    <IconButton>
                        <Icon className="controls_icon" type="MoreHorizontal" fontSize="mini" />
                    </IconButton>
                }
                eleUid="target-list-dropdown"
                closeOnMouseLeave={true}
                items={[
                    isActive
                        ? {
                              icon: 'PauseCircleOutline',
                              label: 'Pause',
                              action: 'pause',
                          }
                        : {
                              icon: 'PlayCircleOutline',
                              label: 'Activate',
                              action: 'activate',
                              disabled: !isValid,
                          },
                    {
                        icon: 'Duplicate',
                        label: 'Duplicate',
                        action: 'duplicate',
                        disabled: !isValid,
                    },
                    {
                        icon: 'Remove',
                        label: 'Delete',
                        action: 'delete',
                    },
                ]}
                onClick={(action) => onClick(action)}
            />
        </div>
    );
};

export default TriggerListMenu;
