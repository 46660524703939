import { useEffect, useRef } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import ChipInput from 'components/tokens/ChipInput';

import { FilterProps, LocationFilterProps, Operator, OperatorValue } from '../../FilterTypes';
import { useLocationFilterValue } from '../location/useLocationFilterValue';
import { getInnerMostValueInFilterState, makeFullPath } from '../utils';
import { useOperator, useOperatorFromFilter } from './useOperator';

type UseRangeFilterProps<T> = Pick<
    FilterProps<T>,
    'filter' | 'uuid' | 'groupUUID' | 'removeFilter' | 'sameFiltersState'
> & {
    operators: Operator[];
    placeholder?: string;
    valuePath?: string;
    formatOperatorValue?: (value: OperatorValue) => Operator;
    options?: string[];
    freeSolo?: boolean;
};

export const useChipInputFilter = <TValues,>({
    filter,
    uuid,
    groupUUID,
    removeFilter,
    operators,
    placeholder = 'value...',
    formatOperatorValue,
    sameFiltersState = [],
    valuePath,
    options,
    freeSolo,
}: UseRangeFilterProps<TValues>) => {
    const { control, setError, getFieldState } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);

    const fullValuePath = valuePath || makeFullPath(filter);

    // assuming that the depth will always be 1 to flatten otherwise use `map` and `flat(depth)`
    const disabledFilterOptions = sameFiltersState.flatMap((sameState) =>
        getInnerMostValueInFilterState(sameState, filter.valuePath),
    );

    /**
     * If the field name is nested object, use `getFieldState` to access the error state.
     * Otherwise, it can be accessed using `formState: { errors }`
     */
    const duplicateError = getFieldState(fullValuePath).error;

    return {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        formatOperatorValue,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ...fieldProps } = field;
                    return (
                        <ChipInput
                            placeholder={value?.length ? 'Or...' : placeholder}
                            width="100%"
                            limitTags={2}
                            values={value}
                            disabledOptions={disabledFilterOptions as string[]}
                            setValues={onChange}
                            setFormError={(message) => {
                                setError(fullValuePath, { type: 'validate', message }, { shouldFocus: true });
                            }}
                            formErrors={duplicateError?.message}
                            options={options}
                            freeSolo={freeSolo}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
};

type UseChipInputLocationFilter<T> = UseRangeFilterProps<T> &
    Pick<LocationFilterProps, 'apply' | 'forceOpen'> & { onBlur?: () => void };
export const useChipInputLocationFilter = <TValues,>({
    filter,
    uuid,
    groupUUID,
    removeFilter,
    operators,
    placeholder = 'value...',
    formatOperatorValue,
    sameFiltersState = [],
    apply,
    forceOpen,
    onBlur,
}: UseChipInputLocationFilter<TValues>) => {
    const { operator, handleOperatorUpdate } = useOperatorFromFilter(filter, ({ key, value, valuePath }) => {
        apply({ key, value, valuePath });
    });

    const { value, onChange } = useLocationFilterValue({ filter, apply });

    // assuming that the depth will always be 1 to flatten otherwise use `map` and `flat(depth)`
    const disabledFilterOptions = sameFiltersState.flatMap((sameState) =>
        getInnerMostValueInFilterState(sameState, filter.valuePath),
    );

    /**
     * If the field name is nested object, use `getFieldState` to access the error state.
     * Otherwise, it can be accessed using `formState: { errors }`
     */
    //const duplicateError = getFieldState(fullValuePath).error;

    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (forceOpen) {
            inputRef.current?.focus();
        }
    }, [forceOpen]);

    const handleBlur = () => {
        onBlur?.();
    };

    return {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        formatOperatorValue,
        children: (
            <ChipInput
                placeholder={value?.length ? 'Or...' : placeholder}
                width="100%"
                limitTags={2}
                values={value}
                disabledOptions={disabledFilterOptions as string[]}
                setValues={onChange}
                inputRef={inputRef}
                onBlur={handleBlur}
            />
        ),
    };
};
