import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Vainu = (props) => (
    <SvgIcon {...props}>
        <path d="M18.3837 4.02611C18.424 4.04334 18.4606 4.0686 18.4914 4.10044L20.6407 6.32048C20.7547 6.43776 20.8451 6.5771 20.9068 6.73052C20.9684 6.88393 21.0001 7.04839 21 7.21447V12.3693C21 12.5352 20.9683 12.6995 20.9067 12.8528C20.845 13.0061 20.7547 13.1453 20.6407 13.2624L18.4899 15.4817C18.432 15.5409 18.3553 15.576 18.2743 15.5809C18.3559 15.5764 18.4332 15.5412 18.4915 15.4816L13.9846 20.1292C13.755 20.366 13.4436 20.499 13.119 20.4991H10.9078C10.5831 20.499 10.2718 20.366 10.0421 20.1292L8.30452 18.3364C8.24258 18.272 8.20782 18.1849 8.20782 18.0941C8.20782 18.0034 8.24258 17.9163 8.30452 17.8518L10.0108 16.0945C10.1246 15.9771 10.2596 15.8838 10.4083 15.8202C10.557 15.7566 10.7163 15.7239 10.8773 15.7239H16.5597C16.6983 15.7231 16.8336 15.6802 16.9487 15.6005C17.0638 15.5208 17.1535 15.4078 17.2066 15.2757C17.2598 15.1437 17.274 14.9983 17.2475 14.858C17.2233 14.73 17.1662 14.6114 17.0826 14.5143L15.8686 13.2616C15.7548 13.1442 15.6646 13.0049 15.6031 12.8515C15.5416 12.6981 15.51 12.5337 15.5101 12.3676V7.21367C15.5099 7.04777 15.5415 6.88346 15.603 6.73018C15.6645 6.57691 15.7548 6.43767 15.8686 6.32048L18.0218 4.10044C18.0526 4.0686 18.0892 4.04334 18.1295 4.02611C18.1698 4.00887 18.213 4 18.2566 4C18.3002 4 18.3434 4.00887 18.3837 4.02611Z" />
        <path d="M5.74731 4.00147C5.83527 4.00147 5.91967 4.03733 5.98212 4.10124L8.13222 6.32128C8.24619 6.43856 8.3366 6.5779 8.39825 6.73131C8.4599 6.88473 8.49159 7.04919 8.49148 7.21527V12.3701C8.49153 12.536 8.45981 12.7003 8.39816 12.8536C8.33651 13.0069 8.24614 13.1461 8.13222 13.2632L5.97977 15.4825C5.91722 15.5462 5.83286 15.5819 5.74496 15.5819C5.65706 15.5819 5.5727 15.5462 5.51015 15.4825L3.35848 13.2624C3.12909 13.0251 3.00019 12.7036 3 12.3684V7.21447C3.00005 6.87949 3.12899 6.55823 3.35848 6.32128L5.51249 4.10124C5.57495 4.03733 5.65934 4.00147 5.74731 4.00147Z" />
    </SvgIcon>
);

export default Vainu;
