import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DynamicsContactSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M9.99962 12.0833C8.94019 12.0833 7.90454 11.7778 7.02365 11.2055C6.14277 10.6332 5.4562 9.8198 5.05077 8.8681C4.64534 7.91641 4.53926 6.86918 4.74595 5.85887C4.95263 4.84855 5.4628 3.92051 6.21193 3.19211C6.96107 2.46372 7.91552 1.96767 8.9546 1.7667C9.99368 1.56574 11.0707 1.66888 12.0495 2.06309C13.0283 2.4573 13.8649 3.12486 14.4535 3.98137C15.0421 4.83787 15.3562 5.84485 15.3562 6.87496C15.3562 8.2563 14.7919 9.58106 13.7873 10.5578C12.7828 11.5346 11.4203 12.0833 9.99962 12.0833ZM9.99962 2.70829C9.15207 2.70829 8.32356 2.95267 7.61885 3.4105C6.91414 3.86834 6.36488 4.51909 6.04054 5.28045C5.7162 6.04181 5.63133 6.87958 5.79668 7.68784C5.96203 8.49609 6.37016 9.23852 6.96947 9.82124C7.56878 10.404 8.33234 10.8008 9.1636 10.9616C9.99487 11.1223 10.8565 11.0398 11.6395 10.7245C12.4226 10.4091 13.0918 9.87504 13.5627 9.18984C14.0336 8.50463 14.2849 7.69905 14.2849 6.87496C14.2849 5.76989 13.8334 4.71008 13.0298 3.92868C12.2261 3.14728 11.1361 2.70829 9.99962 2.70829Z" />
        <path d="M17.4989 18.3333H16.4275C16.4466 17.5075 16.2933 16.6865 15.977 15.9198C15.6607 15.1532 15.1879 14.4568 14.5872 13.8727C13.9865 13.2886 13.2703 12.8289 12.4818 12.5214C11.6933 12.2138 10.849 12.0648 9.99962 12.0833C9.15311 12.0749 8.31338 12.2309 7.52963 12.542C6.74588 12.8532 6.03385 13.3132 5.43524 13.8953C4.83663 14.4773 4.36348 15.1696 4.04349 15.9317C3.7235 16.6937 3.56311 17.5102 3.5717 18.3333H2.50038C2.49036 17.373 2.67748 16.4205 3.0508 15.5314C3.42412 14.6423 3.97614 13.8346 4.67451 13.1556C5.37289 12.4765 6.20359 11.9398 7.11797 11.5768C8.03234 11.2138 9.01202 11.0319 9.99962 11.0416C10.9892 11.0247 11.9722 11.2017 12.8899 11.5622C13.8077 11.9226 14.6413 12.4591 15.3412 13.1396C16.041 13.8201 16.5928 14.6307 16.9635 15.523C17.3342 16.4153 17.5163 17.3711 17.4989 18.3333Z" />
    </SvgIcon>
);
export default DynamicsContactSvg;
