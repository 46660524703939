import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { EmbeddedQueryProvider } from 'contexts/EmbeddedQueryContext';
import ThemeProvider from 'design/ThemeProvider';

import ErrorHandler from './Platform/Core/ErrorHandler';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60 * 60 * 1000, // 1 hour
            gcTime: 60 * 60 * 1000, // 1 hour
            retryOnMount: false, //  the query will not be retried on mount if it contains an error
            retry: 1, // failed queries will retry until the failed query count meets that number
            refetchOnWindowFocus: false,
            networkMode: 'offlineFirst',
        },
        mutations: {
            networkMode: 'offlineFirst',
        },
    },
});

export type CommonProvidersProps = {
    children: React.ReactNode;
};
/**
 * Providers that are shared between the platform and the embedded view.
 */
export const CommonProviders: React.FC<CommonProvidersProps> = ({ children }) => (
    <ErrorHandler>
        <BrowserRouter basename={process.env.BASE_URL || '/'}>
            <EmbeddedQueryProvider>
                <QueryClientProvider client={queryClient}>
                    <IntlProvider locale="en-US">
                        <ThemeProvider>
                            <CssBaseline />
                            {children}
                        </ThemeProvider>
                    </IntlProvider>
                </QueryClientProvider>
            </EmbeddedQueryProvider>
        </BrowserRouter>
    </ErrorHandler>
);
