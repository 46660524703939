import React from 'react';

import Icon, { IconProps } from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import { Row, Header } from '../common/Styled';
import Tooltip from '../common/StyledTooltip';

type Props = {
    header: React.ReactNode;
    description: React.ReactNode;
    footerIconType: IconProps['type'];
    footerText: React.ReactNode;
    children: React.ReactElement;
    placement?: 'top' | 'bottom';
    isTooltip?: boolean;
};
const MoreInformationTooltipDialog: React.FC<Props> = ({
    header,
    description,
    footerIconType,
    footerText,
    placement = 'bottom',
    children,
    isTooltip = true,
}) => {
    if (!isTooltip) {
        return children;
    }
    return (
        <Tooltip
            title={
                <>
                    <Header>{header}</Header>
                    <Typography color="default" margin="0px 0px 10px 0px">
                        {description}
                    </Typography>
                    <Row justifyContent="flex-start">
                        <Icon type={footerIconType} htmlColor="#0A0A0A" style={{ marginRight: 8 }} fontSize={'small'} />
                        <Typography color="subtle" variant="tiny">
                            {footerText}
                        </Typography>
                    </Row>
                </>
            }
            componentsProps={{
                tooltip: {
                    sx: {
                        padding: '12px 16px 12px 12px',
                        width: 247,
                        background: '#fff',
                    },
                },
                arrow: {
                    sx: {
                        color: '#fff',
                    },
                },
            }}
            placement={placement}
            arrow
        >
            {children}
        </Tooltip>
    );
};

export default MoreInformationTooltipDialog;
