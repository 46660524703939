import React from 'react';

import { Box } from '@mui/material';

import Icon, { IconType } from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import BannerLeftBoxes from './Assets/banner_left_boxes';
import BannerRightBoxes from './Assets/banner_right_boxes';
import {
    GridButton,
    GridContainer,
    GridVisual,
    StyledBoxesContainer,
    StyledFeaturesWrapper,
    StyledHeaderWrapper,
    StyledIconWrapper,
    StyledLeftBoxesContainer,
    StyledMarketingContainer,
    StyledRightBoxesContainer,
} from './styled';

export type Route = 'profiles' | 'triggers' | 'connectors';

interface MarketingBannerProps {
    onClick: () => void;
    route: Route;
}

interface BannerContent {
    title: string;
    icon: IconType;
    textContent: string;
    btnText: string;
    videoUrl: string;
    features?: React.ReactNode[];
}

const bannerContents: Record<Route, BannerContent> = {
    profiles: {
        title: 'Company Profiles',
        icon: 'Profiles',
        textContent:
            "All the information you need to find and sort your ideal target accounts and develop a meaningful understanding of a company's situation and potential value, so you truly know your accounts.",
        btnText: 'Create a search',
        videoUrl: 'https://vainu-public.s3.amazonaws.com/platform2/profiles.gif',
    },
    triggers: {
        title: 'Triggers',
        icon: 'Triggers',
        textContent:
            'Monitor your target accounts for any significant changes, and use them to trigger workflows across your business systems in real time.',
        features: [
            <span>
                <b>Schedule</b> the marketing campaigns
            </span>,
            <span>
                <b>Don't miss</b> news live
            </span>,
            <span>
                <b>Reduce grunt work</b> through automation
            </span>,
        ],
        btnText: 'Create a trigger',
        videoUrl: 'https://vainu-public.s3.amazonaws.com/platform2/profiles.gif', // get real one
    },
    connectors: {
        title: 'Connectors',
        icon: 'Connectors',
        textContent:
            'Get actionable and reliable company data where you need it most: Your CRM. With our CRM connector, integrating valuable company insights into your sales strategy and operations is a simple process.',
        btnText: 'Explore apps',
        videoUrl: 'https://vainu-public.s3.amazonaws.com/platform2/connectors.gif',
    },
};

export const MarketingBanner: React.FC<MarketingBannerProps> = ({ onClick, route }) => {
    if (!route) {
        return null;
    }

    const { title, icon, textContent, btnText, videoUrl, features } = bannerContents[route];

    return (
        <StyledMarketingContainer route={route}>
            <GridContainer route={route}>
                <StyledHeaderWrapper>
                    <StyledIconWrapper>
                        <Icon type={icon} style={{ height: 44, width: 44, top: 1, left: 4 }} />
                    </StyledIconWrapper>
                    <Typography variant="h2">{title}</Typography>
                </StyledHeaderWrapper>
                <Typography variant="subtitle1" weight="normal" style={{ gridArea: 'text' }}>
                    {textContent}
                </Typography>
                {features && (
                    <StyledFeaturesWrapper>
                        {features.map((feature, idx) => (
                            <Box key={idx} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Icon type="CircleCheckOutline" color="text.strong" />
                                <Typography>{feature}</Typography>
                            </Box>
                        ))}
                    </StyledFeaturesWrapper>
                )}
                <GridButton className="marketing-banner-button" variant="primary" size="large" onClick={onClick}>
                    {btnText}
                </GridButton>
                <GridVisual className="marketing-banner-visual" route={route}>
                    <img src={videoUrl} alt="intro-video" />
                </GridVisual>
            </GridContainer>
            <StyledBoxesContainer route={route}>
                <StyledLeftBoxesContainer>
                    <BannerLeftBoxes />
                </StyledLeftBoxesContainer>
                <StyledRightBoxesContainer>
                    <BannerRightBoxes />
                </StyledRightBoxesContainer>
            </StyledBoxesContainer>
        </StyledMarketingContainer>
    );
};

export default MarketingBanner;
