import { isReadonlyPermission, isSharedTargetList } from '../lists/ManageTargetList/utils';

// modify based on need
const computedSortValue = {
    read_only: (valueToCompute) => isReadonlyPermission(valueToCompute),
    is_shared: (valueToCompute) => isSharedTargetList(valueToCompute),
};

function getComputedSortValue(orderBy, valueToCompute) {
    return computedSortValue[orderBy] ? computedSortValue[orderBy](valueToCompute) : valueToCompute[orderBy];
}

function descendingComparator(a, b, orderBy, reverse = false) {
    const valueA = getComputedSortValue(orderBy, a);
    const valueB = getComputedSortValue(orderBy, b);

    // missing values are shown last
    if (valueB === null || valueB === undefined) {
        return reverse ? 1 : -1;
    }

    if (valueB < valueA) {
        return -1;
    }
    if (valueB > valueA) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy, true);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const getRegisters = (facts) => {
    return facts.filter((fact) => fact.type === 'register');
};
