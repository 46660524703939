import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { isNotEmpty } from 'utilities';

import { Team } from 'api/types/Account';
import NestedSelect, { ChangeValue, OptionProps } from 'components/tokens/select-components/NestedSelect';

import { Permission } from '../types';
import { getAllProfileIDsAsList } from './utils';

type CustomPermission = Permission | 'custom';

type AccountLevelPermissionProps = {
    allReadOnlyProfileIds: string[];
    allReadWriteProfileIds: string[];
    usersWithoutListOwner: Team[];
    onChange: (readOnlyIds: string[], readWriteIds: string[]) => void;
};

const permissions: OptionProps[] = [
    { value: 'custom', label: 'Custom' },
    { value: 'read_write', label: 'Can edit' },
    { value: 'read_only', label: 'Can view' },
    { value: 'not_shared', label: 'No access' },
];

export const AccountLevelPermission = ({
    usersWithoutListOwner,
    allReadOnlyProfileIds,
    allReadWriteProfileIds,
    onChange,
}: AccountLevelPermissionProps) => {
    const [permission, setPermission] = useState<ChangeValue | undefined>(undefined);

    useEffect(() => {
        let tempPermission: CustomPermission;
        if (isEmpty(allReadOnlyProfileIds) && isEmpty(allReadWriteProfileIds)) {
            tempPermission = 'not_shared';
        } else if (
            isNotEmpty(allReadOnlyProfileIds) &&
            isEmpty(allReadWriteProfileIds) &&
            allReadOnlyProfileIds.length === usersWithoutListOwner.length
        ) {
            tempPermission = 'read_only';
        } else if (
            isEmpty(allReadOnlyProfileIds) &&
            isNotEmpty(allReadWriteProfileIds) &&
            allReadWriteProfileIds.length === usersWithoutListOwner.length
        ) {
            tempPermission = 'read_write';
        } else {
            tempPermission = 'custom';
        }

        const found = permissions.find((p) => p.value === tempPermission);
        if (found) {
            setPermission({ ...found, object: found.value, key: found.label + found.value });
        }
    }, [allReadOnlyProfileIds, allReadWriteProfileIds, usersWithoutListOwner.length]);

    const handleChange = (newValue: ChangeValue) => {
        if (newValue.value !== 'custom') {
            let readWritePermissionIDs: string[] = [];
            let readOnlyPermissionIDs: string[] = [];
            if (newValue.value !== 'not_shared') {
                const allIds = getAllProfileIDsAsList(usersWithoutListOwner);
                if (newValue.value === 'read_write') {
                    readWritePermissionIDs = allIds;
                } else if (newValue.value === 'read_only') {
                    readOnlyPermissionIDs = allIds;
                }
            }
            onChange(readOnlyPermissionIDs, readWritePermissionIDs);
        }
        setPermission(newValue);
    };
    return (
        <NestedSelect
            data-testid="account-permission-select"
            containerSx={{ width: '100%' }}
            width="100%"
            height={40}
            searchable={false}
            openInitialDefaultSelectionOnMenuOpen={false}
            nestedOptions={permissions}
            defaultValueObject={permission}
            onChange={(obj) => handleChange(obj)}
        />
    );
};
