import { useLocations } from 'api/profileData';
import { organizationProfileType } from 'api/types/Organization';
import LocationsHeader from 'components/features/Locations/Header';
import LocationsMap from 'components/features/Locations/Map';
import LocationsTable from 'components/features/Locations/Table';
import { useAddressesFromBusinessUnits, useCountriesFromBusinessUnits } from 'components/features/Locations/utils';

import { TabContent, OrganizationTabProps } from '..';

export const LocationsTab: React.FC<OrganizationTabProps> = ({ businessId }) => {
    const { data: locationsData, isLoading } = useLocations({
        key: businessId,
        type: organizationProfileType,
    });

    const addresses = useAddressesFromBusinessUnits(locationsData);
    const addressCountries = useCountriesFromBusinessUnits(locationsData?.business_units);
    return (
        <TabContent>
            <LocationsHeader skeleton={isLoading} countries={addressCountries} addresses={addresses} />
            <LocationsMap
                skeleton={isLoading}
                countries={addressCountries}
                company_name={locationsData?.name}
                addresses={addresses}
            />
            <LocationsTable skeleton={isLoading} addresses={addresses} />
        </TabContent>
    );
};

export default LocationsTab;
