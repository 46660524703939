import React from 'react';

import Box from '@mui/material/Box';

import Typography from 'components/tokens/Typography';

// import Button from 'components/tokens/Button';
// import Icon from 'components/tokens/Icon';
// import Chip from 'components/tokens/Chip';
import { NavbarCompanySearchType } from './SearchResults';

type BannerTopProps = {
    activeSearchType?: NavbarCompanySearchType;
    searchTerm?: string;
    companyNameCount?: number;
    domainCount?: number;
    auxiliaryCount?: number;
    onClick?: (activeType: NavbarCompanySearchType) => void;
};
// Everything commented out for global release of v2 API
const SearchBannerTop: React.VFC<BannerTopProps> = ({
    searchTerm,
    // activeSearchType,
    // companyNameCount,
    // domainCount,
    // auxiliaryCount,
    // onClick,
}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', height: 48, marginX: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, '& > *': { marginRight: `8px!important` } }}>
                <Typography variant="small" weight="semibold">
                    Results for "{searchTerm}":
                </Typography>
                {/* <Chip
                    state={activeSearchType === 'company_name' ? 'active' : 'default'}
                    label={`Company Name (${companyNameCount})`}
                    clickable
                    onClick={() => onClick('company_name')}
                />
                <Chip
                    state={activeSearchType === 'domain' ? 'active' : 'default'}
                    label={`URL (${domainCount})`}
                    clickable
                    onClick={() => onClick('domain')}
                />
                <Chip
                    state={activeSearchType === 'auxiliary' ? 'active' : 'default'}
                    label={`Auxiliary Name (${auxiliaryCount})`}
                    clickable
                    onClick={() => onClick('auxiliary')}
                /> */}
            </Box>
            {/* <Button variant="tertiary" size="small" startIcon={<Icon type="CircleRight" />}>
                Save as list
            </Button> */}
        </Box>
    );
};

export default SearchBannerTop;
