import React, { useContext } from 'react';

import { Tooltip } from '@mui/material';

import ListMenu from 'components/templates/ListMenu';
import Icon from 'components/tokens/Icon';
import CRMIcon from 'components/tokens/Icon/CRMIcon';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';

import { CrmName } from '../types/Crm';
import { CRMS_EXPORT_OPTIONS } from './constants';

type CrmMenuProps = {
    disabled?: boolean;
    disabledTooltip?: string;
    disablePortal?: boolean;
    anchorButton: React.ReactElement;
    remainingCRMsToExport?: CrmName[];
    handleClick: (crmName: CrmName) => void;
    useGenericIcon?: boolean;
};

const CrmMenu = React.forwardRef<HTMLDivElement, CrmMenuProps>(
    (
        {
            disabled,
            disabledTooltip,
            anchorButton,
            remainingCRMsToExport = [],
            disablePortal,
            handleClick,
            useGenericIcon,
            ...props
        },
        ref,
    ) => {
        const { hasIntegration } = useContext(PermissionContext);

        // todo: filter active intergrations
        const dropdownItems =
            remainingCRMsToExport.length === 0
                ? CRMS_EXPORT_OPTIONS.filter((item) => hasIntegration(item.integration))
                : CRMS_EXPORT_OPTIONS.filter(
                      (crm) => hasIntegration(crm.integration) && remainingCRMsToExport.includes(crm.action),
                  );

        if (dropdownItems.length === 1) {
            return (
                <Tooltip title={disabled ? disabledTooltip : ''}>
                    <div>
                        {React.cloneElement(anchorButton, {
                            disabled,
                            startIcon: useGenericIcon ? (
                                <Icon type="Export" color="grey.400" />
                            ) : (
                                <CRMIcon crm={dropdownItems[0].action} type="export" />
                            ),
                            onClick: () => handleClick(dropdownItems[0].action),
                        })}
                    </div>
                </Tooltip>
            );
        }

        const anchorEl = (
            <Tooltip title={disabled ? disabledTooltip : ''}>
                <div>{React.cloneElement(anchorButton, { disabled })}</div>
            </Tooltip>
        );

        return dropdownItems ? (
            <ListMenu
                disabled={disabled}
                ref={ref}
                eleUid="export-options-dropdown"
                disablePortal={disablePortal}
                anchorElement={anchorEl}
                items={dropdownItems}
                onClick={(item) => handleClick(item)}
                {...props}
            />
        ) : null;
    },
);

export default CrmMenu;
