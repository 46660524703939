import { GenericAsyncProcess, UserAsyncProcessType } from './UserAsyncProcess';

export const SITEWIDE_NOTIFICATION_TYPE = 'sitewide_notification';

export type SitewideNotificationType = typeof SITEWIDE_NOTIFICATION_TYPE;

export const isSitewideNotification = (notification: Notification): notification is SitewideNotification =>
    notification.type === 'sitewide_notification';

export type SitewideNotification = {
    id: string;
    name: string;
    seen: boolean;
    type: SitewideNotificationType;
    created: string;
    finished: string;
    meta_data: {
        published: boolean;
        expand_content: boolean;
        open_on_load: boolean;
        start_date: string;
        end_date: string;
        hubspot_blog_post_id: number;
        hubspot_blog_name: string;
        hubspot_updated: number;
        hubspot_author_avatar: string;
        user_modified: boolean;
    };
    content: {
        language: string;
        body_text: string;
        title: string;
        cta_label: string;
        cta_url: string;
        excerpt: string;
    };
};

export type Notification = SitewideNotification | GenericAsyncProcess;

export type NotificationType = UserAsyncProcessType | SitewideNotificationType;
