import React from 'react';

import Box from '@mui/material/Box';

import { Profile as ProfileType } from 'api/types/ApplicationStore';
import { PasswordParams } from 'api/userProfile';
import Button from 'components/tokens/Button';
import Dialog from 'components/tokens/Dialog';
import TextField from 'components/tokens/TextField';
import Typography from 'components/tokens/Typography';

// import MuiSelect, { SelectOption } from './MuiSelect';
import ChangePassword from './ChangePassword';

export type ProfileProps = {
    profile: ProfileType;
    isChangePasswordDialogOpen: boolean;
    onChangePasswordDialog: (value: boolean) => void;
    passwordFields: PasswordParams;
    onClearPasswordFields: () => void;
    onSubmitPasswordChange: (e: React.SyntheticEvent) => void;
    onHandlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    passwordChangeError: Partial<PasswordParams>;
    loadingFields?: Record<string, boolean>;
    onHandleProfileChange?: (type: string, value: string, oldValue: string) => void;
};
/** Could be enabled later on */
// const userSegmentOptions: SelectOption[] = [
//     { label: 'Sales Professional', value: 'sales_professional' },
//     { label: 'Sales Director', value: 'sales_director' },
//     { label: 'Account Management', value: 'account_management' },
//     { label: 'Marketing', value: 'marketing' },
//     { label: 'Marketing Director', value: 'marketing_director' },
//     {
//         label: 'CRM and/or Marketing Automation Manager',
//         value: 'marketing_automation_manager',
//     },
//     { label: 'CEO', value: 'ceo' },
//     { label: 'Researcher', value: 'researcher' },
//     { label: 'Head of Data', value: 'head_of_data' },
//     { label: 'Data Professional', value: 'data_professional' },
//     { label: 'Other', value: 'other' },
// ];

// const currencies: SelectOption[] = [
//     { value: 'EUR', label: '€ (Euro)' },
//     { value: 'USD', label: '$ (US Dollar)' },
//     { value: 'GBP', label: '£ (Pound Sterling)' },
//     { value: 'SEK', label: 'kr (Swedish Kroner)' },
//     { value: 'NOK', label: 'kr (Norwegian Kroner)' },
//     { value: 'DKK', label: 'kr (Danish Kroner)' },
// ];

export const Profile: React.VFC<ProfileProps> = ({
    profile,
    isChangePasswordDialogOpen,
    onChangePasswordDialog,
    passwordFields,
    onHandlePasswordChange,
    onClearPasswordFields,
    onSubmitPasswordChange,
    passwordChangeError,
    // loadingFields,
    // onHandleProfileChange,
}) => {
    return (
        <Box>
            <Typography weight="bold" sx={{ marginBottom: 2 }}>
                Profile settings
            </Typography>
            <Box
                component="form"
                sx={{
                    display: 'grid',
                    gap: 1,
                    gridTemplateColumns: '1fr 1fr',
                    '&> span': {
                        alignSelf: 'center',
                    },
                    maxWidth: '100%',
                    '@media (min-width: 850px)': {
                        maxWidth: '75%',
                    },
                    '@media (max-width: 576px)': {
                        gridTemplateColumns: '1fr',
                    },
                }}
            >
                {/* The commented fields are yet to be determined as whether we will allow user to change or not. */}
                {/* <>
                        <Typography variant="small">Full Name:</Typography>
                        <TextField compact placeholder="" value={profile.fullname} />
                    </> */}
                <>
                    <Typography variant="small">Email</Typography>
                    <TextField status="disabled" compact placeholder="example@vainu.com" value={profile.userName} />
                </>
                {/* <>
                    <Typography variant="small">Role:</Typography>
                    <MuiSelect
                        loading={loadingFields['segment']}
                        type="segment"
                        options={userSegmentOptions}
                        selectedOption={profile.segment!}
                        onChange={onHandleProfileChange}
                    />
                </> */}

                {/* <>
                    <Typography variant="small">Currency:</Typography>
                    <MuiSelect
                        loading={loadingFields['currency_code']}
                        type="currency_code"
                        options={currencies}
                        selectedOption={profile.currency_code!}
                        onChange={onHandleProfileChange}
                    />
                </> */}
                <>
                    <Typography variant="small">Password:</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button variant="tertiary" size="small" onClick={() => onChangePasswordDialog(true)}>
                            Change password
                        </Button>
                        <Dialog
                            maxWidth="xs"
                            open={isChangePasswordDialogOpen}
                            // make sure that error value is empty
                            isSubmitDisabled={
                                !Object.values(passwordChangeError).every((passwordError) => !passwordError) ||
                                Object.values(passwordFields).some((field) => !field)
                            }
                            onSubmit={onSubmitPasswordChange}
                            onClose={() => {
                                onChangePasswordDialog(false);
                                onClearPasswordFields();
                            }}
                            title="Change Password"
                            message="The new password has to be at least 8 characters long. We recommend that you use a strong password with numbers, lower/upper case letters and special characters."
                            children={
                                <ChangePassword
                                    onChange={onHandlePasswordChange}
                                    errorMessage={passwordChangeError}
                                    passwordFields={passwordFields}
                                />
                            }
                            confirmLabel="Save"
                            cancelLabel="Cancel"
                        />
                    </Box>
                </>
            </Box>
        </Box>
    );
};

export default Profile;
