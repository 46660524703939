import React from 'react';

import { ButtonBase, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { Icon } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';

import { CRMLogoAlt } from '../common/CRMLogo';
import { CrmName } from '../types/Crm';
import { getCRMName } from '../utils';

type CRMConnectTileProps = {
    crm: CrmName;
    isPurchased: boolean;
    isConnected: boolean;
};

const CRMConnectTile: React.FC<CRMConnectTileProps> = ({ crm, isConnected, isPurchased }) => {
    return (
        <StyledButton disableRipple disabled={!isPurchased} component={Link} to={crm}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CRMLogoAlt name={crm as CrmName} height={60} width={60} />
                    {(isConnected || !isPurchased) && (
                        <ConnectionStatus warn={!isPurchased}>
                            {isConnected && <Icon type="CheckThick" fontSize="small" />}
                            {!isPurchased ? 'MISSING PERMISSION' : 'CONNECTED'}
                        </ConnectionStatus>
                    )}
                </div>
                <Typography weight="bold" marginTop="16px">
                    {getCRMName(crm)}
                </Typography>
            </div>
        </StyledButton>
    );
};

const ConnectionStatus = styled('div')<{ warn: boolean }>(({ theme: { palette }, warn }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    padding: '4px',
    color: warn ? palette.brandColors.warningOrange : palette.brandColors.successGreen,
    fontSize: '10px',
    fontWeight: 500,
    background: warn ? '#FFF3E5' : palette.brandColors.successGreen100,
}));

const StyledButton = styled(ButtonBase)(({ theme: { palette }, disabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '15rem',
    height: '12rem',
    padding: '24px 16px',
    borderRadius: 8,
    border: `1px solid ${palette.grey[50]}`,
    boxShadow: '0px 4px 24px 0px rgba(10, 10, 10, 0.05)',

    '&:hover': {
        boxShadow: '0px 16px 16px 0px rgba(10, 10, 10, 0.05), 0px 32px 32px 0px rgba(10, 10, 10, 0.05)',
    },

    '& > div': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },

    ...(disabled && { opacity: 0.5 }),
})) as typeof ButtonBase;

export { CRMConnectTile };
