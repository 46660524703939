import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Technology: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M6.27273 3.81818C6.27273 3.36818 5.90455 3 5.45455 3C5.00455 3 4.63636 3.36818 4.63636 3.81818V7.09091H3V12H7.90909V7.09091H6.27273V3.81818ZM9.54545 15.2727C9.54545 16.3364 10.2327 17.2364 11.1818 17.58V21H12.8182V17.58C13.7673 17.2445 14.4545 16.3445 14.4545 15.2727V13.6364H9.54545V15.2727ZM3 15.2727C3 16.3364 3.68727 17.2364 4.63636 17.58V21H6.27273V17.58C7.22182 17.2364 7.90909 16.3364 7.90909 15.2727V13.6364H3V15.2727ZM19.3636 7.09091V3.81818C19.3636 3.36818 18.9955 3 18.5455 3C18.0955 3 17.7273 3.36818 17.7273 3.81818V7.09091H16.0909V12H21V7.09091H19.3636ZM12.8182 3.81818C12.8182 3.36818 12.45 3 12 3C11.55 3 11.1818 3.36818 11.1818 3.81818V7.09091H9.54545V12H14.4545V7.09091H12.8182V3.81818ZM16.0909 15.2727C16.0909 16.3364 16.7782 17.2364 17.7273 17.58V21H19.3636V17.58C20.3127 17.2445 21 16.3445 21 15.2727V13.6364H16.0909V15.2727Z" />
    </SvgIcon>
);

export default Technology;
