import React from 'react';

import Box from '@mui/material/Box';

import Button from 'components/tokens/Button';
import ButtonTabs from 'components/tokens/ButtonTabs';
import Icon, { IconType } from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { ResultObjectType } from 'contexts/FilterCompaniesContext/utils';

import HeaderActions, { HeaderActionsProps } from './HeaderActions';
import SortMenuList from './SortMenuList';
import { ViewType } from './types';

const viewOptions: {
    type: ViewType;
    icon: IconType;
    label: string;
}[] = [
    {
        type: 'grid',
        icon: 'Grid',
        label: 'Cards',
    },
    {
        type: 'list',
        icon: 'Hamburger',
        label: 'Table',
    },
    {
        type: 'clusters',
        icon: 'Path',
        label: 'Clusters',
    },
    {
        type: 'insights',
        icon: 'Insights',
        label: 'Insights',
    },
];

export type ResultViewHeaderProps = HeaderActionsProps & {
    title?: React.ReactNode;
    onViewChange: (newView: ViewType) => void;
    view: ViewType;
    availableViews: ViewType[];
    disabledViews?: ViewType[];
    hideSort?: boolean;
    hideResultCount?: boolean;
    hideViews?: boolean;
    extraActions?: React.ReactNode;
    tabs?: React.ReactNode;
    tab?: ResultObjectType;
};

export const ResultViewHeader: React.FC<ResultViewHeaderProps> = ({
    title,
    onViewChange,
    view,
    availableViews,
    disabledViews = [],
    hideSort,
    hideResultCount,
    hideViews,
    extraActions,
    tabs,
    tab,
    ...headerActionProps
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: 40,
                // The effective gap is double this due to the invisible wrap break element taking one row.
                // There's also the visual gap due to the 32px buttons having 4px vertical room on rows,
                // making the total gap 12px, which aligns the second row nicely with the "All companies" list item.
                gap: '2px',
            }}
        >
            {tabs}
            {title && (
                <Typography weight="bold" sx={{ order: 1, lineHeight: '40px' }} component="div">
                    {title}
                </Typography>
            )}
            {!hideResultCount && (
                <Box sx={{ order: extraActions ? 4 : 1, display: 'flex', alignItems: 'center', minHeight: 40 }}>
                    <HeaderActions {...headerActionProps} />
                </Box>
            )}
            <Box sx={{ order: 2, display: 'flex', alignItems: 'center', minHeight: 40, gap: 2 }}>
                {!hideSort && <SortMenuList tab={tab} />}
                {!hideViews && (
                    <ButtonTabs selected={view} size="small">
                        {viewOptions
                            .filter((view) => availableViews.includes(view.type))
                            .map((view) => (
                                <Button
                                    key={view.type}
                                    disabled={disabledViews.includes(view.type)}
                                    value={view.type}
                                    startIcon={<Icon type={view.icon} />}
                                    onClick={() => onViewChange(view.type)}
                                >
                                    {view.label}
                                </Button>
                            ))}
                    </ButtonTabs>
                )}
            </Box>
            {!!extraActions && (
                <>
                    <Box sx={{ flexBasis: '100%', order: 3, height: 0 }} />
                    <Box sx={{ order: 5, display: 'flex', alignItems: 'center', minHeight: 40 }}>{extraActions}</Box>
                </>
            )}
        </Box>
    );
};

export default ResultViewHeader;
