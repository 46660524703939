import React from 'react';

import { Tooltip, SxProps } from '@mui/material';

import Typography, { TypographyProps } from 'components/tokens/Typography';

export interface TextOnTruncateProps extends TypographyProps {
    content: string;
    arrow?: boolean;
    maxLength: number;
    sx?: SxProps;
}

/**
 * @deprecated  Use either `TextTruncateScrollable` or `TextTruncateTooltip` instead
 */
export const TextOnTruncate: React.VFC<TextOnTruncateProps> = ({ content, maxLength, arrow = true, sx, ...props }) => {
    const isTooltip = content.length > maxLength;
    return isTooltip ? (
        <Tooltip
            title={<>{content}</>}
            placement="top"
            arrow={arrow}
            disableInteractive // This will fail WCAG success criterion. More info @ https://www.w3.org/TR/WCAG21/#content-on-hover-or-focus
        >
            <Typography sx={sx} {...props}>
                {content.slice(0, maxLength).concat('...')}
            </Typography>
        </Tooltip>
    ) : (
        <Typography sx={{ ...sx, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} {...props}>
            {content}
        </Typography>
    );
};

export default TextOnTruncate;
