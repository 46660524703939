import React from 'react';

import { Tooltip } from '@mui/material';

import { ProfileType } from 'api/types/Organization';
import { CrmMenu } from 'components/modules/connectors/crm-export';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { useAllProfileExportsWithConnectedCRM } from 'components/modules/profiles/Profile/useAllProfileExportsWithConnectedCRM';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';

import { CRMChip } from '../CompanyProfileSidebarCard/components/CRMChip';

type CrmOwnerIndicatorProps = {
    disabled?: boolean;
    id: string;
    profileType: ProfileType;
    targetObject?: 'Account' | 'Contact';
    name?: string;
    logo?: string;
    contactId?: string;
    onSendToCrm?: (crm: CrmName) => void;
};

const CrmOwnerIndicator: React.FC<CrmOwnerIndicatorProps> = ({
    disabled,
    id,
    profileType,
    name,
    logo,
    targetObject,
    contactId,
    onSendToCrm,
}) => {
    const { profileExportedToCRMs } = useAllProfileExportsWithConnectedCRM(profileType, id, targetObject);

    if (contactId) {
        const filtered = profileExportedToCRMs.filter((exportedProfiles) =>
            exportedProfiles.find((row) => row.contactId === contactId),
        );

        if (!filtered.length && onSendToCrm) {
            return <SendButton disabled={disabled} onClick={onSendToCrm} />;
        }

        return (
            <>
                {filtered.map((exportedProfiles) => (
                    <CRMChip
                        key={exportedProfiles[0].crmTarget}
                        targetObject="Contact"
                        exportedProfiles={exportedProfiles.filter((row) => row.contactId === contactId)}
                        name={name || ''}
                        logo={logo}
                        size="small"
                        chipSx={{
                            marginLeft: '-6px',
                            '&&': {
                                borderColor: 'common.white',
                                borderWidth: 3,
                            },
                        }}
                    />
                ))}
            </>
        );
    }

    if (!profileExportedToCRMs.length && onSendToCrm) {
        return <SendButton disabled={disabled} onClick={onSendToCrm} />;
    }

    return (
        <>
            {profileExportedToCRMs.map((exportedProfiles) => (
                <CRMChip
                    key={exportedProfiles[0].crmTarget}
                    exportedProfiles={exportedProfiles}
                    name={name || ''}
                    logo={logo}
                    size="small"
                    chipSx={{
                        marginLeft: '-6px',
                        '&&': {
                            borderColor: 'common.white',
                            borderWidth: 3,
                        },
                    }}
                />
            ))}
        </>
    );
};

export default CrmOwnerIndicator;

const SendButton = ({ disabled, onClick }: { disabled?: boolean; onClick: (crm: CrmName) => void }) => (
    <Tooltip title="Send to">
        <CrmMenu
            disabled={disabled}
            anchorButton={
                <Button
                    size="small"
                    variant="flat"
                    startIcon={<Icon fontSize="mini" type="Export" color="text.subtle" />}
                    sx={{ marginLeft: '-4px' }}
                />
            }
            handleClick={onClick}
            disablePortal={false}
            useGenericIcon
        />
    </Tooltip>
);
