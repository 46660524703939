import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';

import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';

import useConnectorsApi from '../hooks/useConnectorsApi';
import { CrmName, ProspectCountByCountryProps } from '../types/Crm';
import { useCRMImportSettingsHook } from './ImportSettings';
import { CompanyMatchDispatch, CompanyMatchStates } from './types';

type CompanyMatchingProviderProps = {
    crm: CrmName;
    children: ReactNode;
};

type MatchingData = { count: number };
type MatchingDataWithCountries = MatchingData & { country_counts: ProspectCountByCountryProps[] };
type MatchingResultsStateType = {
    isLoading: boolean;
    overallMatched: MatchingDataWithCountries;
    matchedByCountries: MatchingDataWithCountries;
    matchedUpgradableCountries: MatchingDataWithCountries;
    matchedUnsupportedCountries: MatchingDataWithCountries;
    needHelpCountries: MatchingData;
    matchedByDomains: MatchingData;
    addedAndMatched: MatchingData;
    missingFromDB: MatchingData;
    matchedLeads: MatchingDataWithCountries;
    unmatchedLeads: MatchingData;
};

const VAINU_SUPPORTED_COUNTRIES = ['FI', 'SE', 'NO', 'DK', 'NL'];

const initialResponse = { count: 0 };
const initialState: MatchingResultsStateType = {
    isLoading: true,
    overallMatched: { count: 0, country_counts: [] },
    matchedByCountries: { count: 0, country_counts: [] },
    matchedUpgradableCountries: { count: 0, country_counts: [] },
    matchedUnsupportedCountries: { count: 0, country_counts: [] },
    needHelpCountries: { count: 0 },
    matchedByDomains: { count: 0 },
    addedAndMatched: { count: 0 },
    missingFromDB: { count: 0 },
    matchedLeads: { count: 0, country_counts: [] },
    unmatchedLeads: { count: 0 },
};

const CompanyMatchingContext = createContext<CompanyMatchStates & CompanyMatchDispatch>(
    {} as CompanyMatchStates & CompanyMatchDispatch,
);

const CompanyMatchingProvider: React.FC<CompanyMatchingProviderProps> = ({ crm, children }) => {
    const [{ sizeIsMinumum, status, error, size }] = useCRMImportSettingsHook(crm);
    const { showSnackbar } = useSnackbarContext();
    const { getIntegrationOptions, getProspectExportCount } = useConnectorsApi();
    const { isAccountLoading, isDomainConnector, accountCountryPermissions } = useContext(PermissionContext);

    // ui state
    const [fullImportDone, setFullImportDone] = useState<boolean | null>(null);
    const [matchingResultsState, setMatchingResultsState] = useState<MatchingResultsStateType>(initialState);

    const isJobDone = fullImportDone || status === 'finished';

    useEffect(() => {
        async function init() {
            try {
                const { data } = await getIntegrationOptions('account', crm);
                if (data.full_import_done) {
                    setFullImportDone(true);
                    fetchMatchingResults();
                } else {
                    setFullImportDone(false);
                }
            } catch (e) {
                console.error(e);
                showSnackbar('😔 Oops, something went wrong, please try again or contact support', 'error');
            }
        }
        if (!isAccountLoading && !fullImportDone) {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAccountLoading, fullImportDone, showSnackbar]);

    async function fetchMatchingResults() {
        try {
            setMatchingResultsState((prev) => ({ ...prev, isLoading: true }));

            const [
                { data: overallMatched },
                { data: unmatched },
                { data: addedAndMatched } = { data: initialResponse },
                { data: needHelp } = { data: initialResponse },
                { data: matchedLeads } = { data: initialResponse },
                { data: unmatchedLeads } = { data: initialResponse },
            ] = await Promise.all(
                [
                    getProspectExportCount({
                        target: crm,
                        target_object: 'Account',
                        ...(isDomainConnector ? { matched_domain: 1 } : { matched: 1, by_country: 1 }),
                    }),
                    getProspectExportCount({
                        target: crm,
                        target_object: 'Account',
                        ...(isDomainConnector ? { unmatched_domain: 1 } : { unmatched: 1 }),
                    }),
                    // Promise.resolve() below is needed to ensure the array length stays consistent
                    isDomainConnector
                        ? getProspectExportCount({
                              target: crm,
                              target_object: 'Account',
                              matched_domain: 1,
                              activity_log__event: 'crawled_domain',
                          })
                        : Promise.resolve({ data: initialResponse }),
                    isDomainConnector
                        ? getProspectExportCount({
                              target: crm,
                              target_object: 'Account',
                              no_domain: 1,
                          })
                        : Promise.resolve({ data: initialResponse }),
                    crm === 'salesforce'
                        ? getProspectExportCount({
                              target: crm,
                              target_object: 'Lead',
                              ...(isDomainConnector ? { matched_domain: 1 } : { matched: 1, by_country: 1 }),
                          })
                        : Promise.resolve({ data: { count: 0, country_counts: [] } }),
                    crm === 'salesforce'
                        ? getProspectExportCount({
                              target: crm,
                              target_object: 'Lead',
                              ...(isDomainConnector ? { unmatched_domain: 1 } : { unmatched: 1 }),
                          })
                        : Promise.resolve({ data: initialResponse }),
                ].filter((request): request is Promise<AxiosResponse> => Boolean(request)),
            );

            const { matchedByCountries, upgradableCountries, unsupportedCountries } = getGroupedMatchedCompanies({
                isDomainConnector,
                overallMatchedByCountries: overallMatched.country_counts ?? [],
                accountCountryPermissions,
            });

            // Domain
            // Added and matched -> matched_domain=1&activity_log__event=crawled_domain
            // Matched -> matched_domain=1 minus 'Added and matched'
            // Need help -> no_domain=1
            // Missing from DB -> unmatched_domain=1 minus 'Need help'

            // BizID
            // Matched by country -> matched=1&by_country=1
            // Need help -> &unmatched=1
            // Need upgrade -> sum of by_country where country is not in permissions but country is in list of supported countries
            // Not supported -> sum of by_country where country is not in list of supported countries and not in permissions

            setMatchingResultsState({
                isLoading: false,
                // Nordic connector specific
                matchedByCountries: { count: matchedByCountries.reduce(getSum, 0), country_counts: matchedByCountries },
                matchedUpgradableCountries: {
                    count: upgradableCountries.reduce(getSum, 0),
                    country_counts: upgradableCountries,
                },
                matchedUnsupportedCountries: {
                    count: unsupportedCountries.reduce(getSum, 0),
                    country_counts: unsupportedCountries,
                },
                // Global connector specific
                matchedByDomains: { count: overallMatched.count - addedAndMatched.count },
                addedAndMatched: { count: addedAndMatched.count },
                missingFromDB: { count: unmatched.count - needHelp.count },
                // Both connectors
                needHelpCountries: { count: isDomainConnector ? needHelp.count : unmatched.count },
                overallMatched,
                matchedLeads,
                unmatchedLeads,
            });
        } catch (e) {
            console.error(e);
        }
    }

    const state: CompanyMatchStates = {
        isLoading: matchingResultsState.isLoading,
        isLongOngoingJob: !isJobDone && sizeIsMinumum,
        isInitialFullImportDone: fullImportDone,
        error,
        size,
        matchedByCountries: matchingResultsState.matchedByCountries,
        matchedUpgradableCountries: matchingResultsState.matchedUpgradableCountries,
        matchedUnsupportedCountries: matchingResultsState.matchedUnsupportedCountries,
        matchedByDomains: matchingResultsState.matchedByDomains,
        addedAndMatched: matchingResultsState.addedAndMatched,
        missingFromDB: matchingResultsState.missingFromDB,
        nonMatchedCountries: matchingResultsState.needHelpCountries,
        overallMatched: matchingResultsState.overallMatched,
        matchedLeads: matchingResultsState.matchedLeads,
        unmatchedLeads: matchingResultsState.unmatchedLeads,
    };

    const dispatch: CompanyMatchDispatch = {
        fetchMatchingResults,
    };

    return (
        <CompanyMatchingContext.Provider value={{ ...state, ...dispatch }}>{children}</CompanyMatchingContext.Provider>
    );
};

function useCompanyMatch() {
    return useContext(CompanyMatchingContext);
}

const getSum = (acc: number, { count }: ProspectCountByCountryProps) => acc + count;

const getGroupedMatchedCompanies = ({
    isDomainConnector,
    overallMatchedByCountries,
    accountCountryPermissions,
}: {
    isDomainConnector: boolean;
    overallMatchedByCountries: ProspectCountByCountryProps[];
    accountCountryPermissions: string[];
}) => {
    if (isDomainConnector) {
        return { matchedByCountries: [], upgradableCountries: [], unsupportedCountries: [] };
    }

    return overallMatchedByCountries.reduce(
        (acc, item) => {
            const hasСountryPermission = accountCountryPermissions.some(
                (countryCode) => item['_id']?.toLowerCase() === countryCode.toLowerCase(),
            );

            if (hasСountryPermission) {
                return { ...acc, matchedByCountries: [...acc.matchedByCountries, item] };
            }

            if (!hasСountryPermission && VAINU_SUPPORTED_COUNTRIES.includes(item['_id']?.toUpperCase())) {
                return { ...acc, upgradableCountries: [...acc.upgradableCountries, item] };
            }

            if (!hasСountryPermission && !VAINU_SUPPORTED_COUNTRIES.includes(item['_id']?.toUpperCase())) {
                return { ...acc, unsupportedCountries: [...acc.unsupportedCountries, item] };
            }

            return acc;
        },
        {
            matchedByCountries: [] as ProspectCountByCountryProps[],
            upgradableCountries: [] as ProspectCountByCountryProps[],
            unsupportedCountries: [] as ProspectCountByCountryProps[],
        },
    );
};

export { CompanyMatchingContext, CompanyMatchingProvider, useCompanyMatch };
