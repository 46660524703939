import DynamicsAccountSvg from '../../modules/connectors/svg/CrmObjects/DynamicsAccountSvg';
import DynamicsContactSvg from '../../modules/connectors/svg/CrmObjects/DynamicsContactSvg';
import DynamicsLeadSvg from '../../modules/connectors/svg/CrmObjects/DynamicsLeadSvg';
import DynamicsOpportunitySvg from '../../modules/connectors/svg/CrmObjects/DynamicsOpportunitySvg';
import DynamicsTaskSvg from '../../modules/connectors/svg/CrmObjects/DynamicsTaskSvg';
import HubspotCompanySvg from '../../modules/connectors/svg/CrmObjects/HubspotCompanySvg';
import HubspotContactSvg from '../../modules/connectors/svg/CrmObjects/HubspotContactSvg';
import HubspotDealSvg from '../../modules/connectors/svg/CrmObjects/HubspotDealSvg';
import HubspotNoteSvg from '../../modules/connectors/svg/CrmObjects/HubspotNoteSvg';
import HubspotTaskSvg from '../../modules/connectors/svg/CrmObjects/HubspotTaskSvg';
import PipedriveActivitySvg from '../../modules/connectors/svg/CrmObjects/PipedriveActivitySvg';
import PipedriveContactSvg from '../../modules/connectors/svg/CrmObjects/PipedriveContactSvg';
import PipedriveDealSvg from '../../modules/connectors/svg/CrmObjects/PipedriveDealSvg';
import PipedriveLeadSvg from '../../modules/connectors/svg/CrmObjects/PipedriveLeadSvg';
import PipedriveNoteSvg from '../../modules/connectors/svg/CrmObjects/PipedriveNoteSvg';
import PipedriveOrganizationSvg from '../../modules/connectors/svg/CrmObjects/PipedriveOrganizationSvg';
import SalesforceAccountSvg from '../../modules/connectors/svg/CrmObjects/SalesforceAccountSvg';
import SalesforceContactSvg from '../../modules/connectors/svg/CrmObjects/SalesforceContactSvg';
import SalesforceLeadSvg from '../../modules/connectors/svg/CrmObjects/SalesforceLeadSvg';
import SalesforceOpportunitySvg from '../../modules/connectors/svg/CrmObjects/SalesforceOpportunitySvg';
import SalesforceTaskSvg from '../../modules/connectors/svg/CrmObjects/SalesforceTaskSvg';
import Advertising from './Assets/advertising';
import Analytics from './Assets/analytics';
import ApplicationTrackingSystem from './Assets/application_tracking_system';
import ArrowDropDown from './Assets/arrow_drop_down';
import ArrowLongDown from './Assets/arrow_long_down';
import ArrowLongLeft from './Assets/arrow_long_left';
import ArrowLongRight from './Assets/arrow_long_right';
import ArrowLongUp from './Assets/arrow_long_up';
import ArrowShortDown from './Assets/arrow_short_down';
import ArrowShortLeft from './Assets/arrow_short_left';
import ArrowShortRight from './Assets/arrow_short_right';
import ArrowShortUp from './Assets/arrow_short_up';
import Award from './Assets/award';
import BarChart from './Assets/bar_chart';
import Building from './Assets/building';
import Calendar from './Assets/calendar';
import CalendarEdit from './Assets/calendar_edit';
import Cars from './Assets/cars';
import ChartDoughnut from './Assets/chart_doughnut';
import ChatSupport from './Assets/chat_support';
import CheckBig from './Assets/check_big';
import CheckBold from './Assets/check_bold';
import CheckThick from './Assets/check_thick';
import ChevronBigDown from './Assets/chevron_big_down';
import ChevronBigLeft from './Assets/chevron_big_left';
import ChevronBigRight from './Assets/chevron_big_right';
import ChevronBigUp from './Assets/chevron_big_up';
import ChevronDown from './Assets/chevron_down';
import ChevronDuoRight from './Assets/chevron_duo_right';
import ChevronLeft from './Assets/chevron_left';
import ChevronRight from './Assets/chevron_right';
import ChevronUp from './Assets/chevron_up';
import CircleCheck from './Assets/circle_check';
import CircleCheckOutline from './Assets/circle_check_outline';
import CircleChevronDown from './Assets/circle_chevron_down';
import CircleChevronLeft from './Assets/circle_chevron_left';
import CircleChevronRight from './Assets/circle_chevron_right';
import CircleChevronUp from './Assets/circle_chevron_up';
import CircleDown from './Assets/circle_down';
import CircleLeft from './Assets/circle_left';
import CircleRight from './Assets/circle_right';
import CircleUp from './Assets/circle_up';
import CloseBig from './Assets/close_big';
import CloseSmall from './Assets/close_small';
import CMS from './Assets/cms';
import Code from './Assets/code';
import Communication from './Assets/communication';
import CompanyId from './Assets/company_id';
import Connectors from './Assets/connectors';
import Copy from './Assets/copy';
import CRM from './Assets/crm';
import CustomInput from './Assets/custom_input';
import Disabled from './Assets/disabled';
import DomainServer from './Assets/domain_server';
import Donut from './Assets/donut';
import Download from './Assets/download';
import Draft from './Assets/draft';
import Dynamics from './Assets/dynamics';
import DynamicsOpportunity from './Assets/dynamics_opportunity';
import DynamicsMono from './Assets/DynamicsMono';
import ECommerce from './Assets/e_commerce';
import Edit from './Assets/edit';
import EmailServer from './Assets/email_server';
import Error from './Assets/error';
import ErrorOutline from './Assets/error_outline';
import Excel from './Assets/excel';
import Excel2019 from './Assets/excel_2019';
import Expand from './Assets/expand';
import Export from './Assets/export';
import ExportDynamics from './Assets/export_dynamics';
import ExportHubSpot from './Assets/export_hubspot';
import ExportMore from './Assets/export_more';
import ExportPipedrive from './Assets/export_pipedrive';
import ExportSalesforce from './Assets/export_salesforce';
import ExternalLink from './Assets/external_link';
import Facebook from './Assets/facebook';
import FacebookLetter from './Assets/facebook_letter';
import FacebookLetterMono from './Assets/facebook_letter_mono';
import FacebookMono from './Assets/facebook_mono';
import Filter from './Assets/filter';
import FilterNone from './Assets/filter_none';
import FlagDenmark from './Assets/flag_denmark';
import FlagFill from './Assets/flag_fill';
import FlagFinland from './Assets/flag_finland';
import FlagNorway from './Assets/flag_norway';
import FlagOutline from './Assets/flag_outline';
import FlagSweden from './Assets/flag_sweden';
import Folder from './Assets/folder';
import FolderMinus from './Assets/folder_minus';
import Globe from './Assets/globe';
import Gmail from './Assets/gmail';
import GmailMono from './Assets/gmail_mono';
import Google from './Assets/google';
import GoogleMaps from './Assets/google_maps';
import Grid from './Assets/grid';
import Group from './Assets/group';
import GroupStructure from './Assets/group_structure';
import Hamburger from './Assets/hamburger';
import Happy from './Assets/happy';
import HeartFill from './Assets/heart_fill';
import HeartOutline from './Assets/heart_outline';
import HelpCircle from './Assets/help_circle';
import HelpCircleOutline from './Assets/help_circle_outline';
import Hide from './Assets/hide';
import HomeAltOutline from './Assets/home_alt_outline';
import HubSpot from './Assets/hubspot';
import HubSpotCertification from './Assets/hubspot_certification';
import HubSpotDeal from './Assets/hubspot_deal';
import HubSpotMono from './Assets/hubspot_mono';
import HubSpotText from './Assets/hubspot_text';
import IdCard from './Assets/id_card';
import InCrmDynamics from './Assets/in_crm_dynamics';
import InCrmHubSpot from './Assets/in_crm_hubspot';
import InCrmMore from './Assets/in_crm_more';
import InCrmPipedrive from './Assets/in_crm_pipedrive';
import InCrmSalesforce from './Assets/in_crm_salesforce';
import InfoCircle from './Assets/info_circle';
import InfoCircleOutline from './Assets/info_circle_outline';
import Instagram from './Assets/instagram';
import InstagramMono from './Assets/instagram_mono';
import Judge from './Assets/judge';
import Label from './Assets/label';
import Language from './Assets/language';
import Line from './Assets/line';
import LineChartUp from './Assets/line_chart_up';
import Link from './Assets/link';
import Link02 from './Assets/link_02';
import LinkedIn from './Assets/linkedin';
import LinkedInMono from './Assets/linkedin_mono';
import LinkedInSquare from './Assets/linkedin_square';
import LinkedInSquareMono from './Assets/linkedin_square_mono';
import ListCheck from './Assets/list_check';
import ListPlus from './Assets/list_plus';
import Localisation from './Assets/localisation';
import Location from './Assets/location';
import LocationOutline from './Assets/location_outline';
import Lock from './Assets/lock';
import Logout from './Assets/log_out';
import Mail from './Assets/mail';
import MapMarker from './Assets/map_marker';
import MarketingAutomation from './Assets/marketing_automation';
import Menu from './Assets/menu';
import Minus from './Assets/minus';
import MinusCircleOutline from './Assets/minus_circle_outline';
import MoreHorizontal from './Assets/more_horizontal';
import MoreVertical from './Assets/more_vertical';
import Note from './Assets/note';
import Notification from './Assets/notification';
import NotificationActive from './Assets/notification_active';
import NotificationBellWithDot from './Assets/notification_bell_with_dot';
import NotificationDeactivated from './Assets/notification_deactivated';
import NotificationDot from './Assets/notification_dot';
import NotificationOutline from './Assets/notification_outline';
import NotificationOutlineBellWithDot from './Assets/notification_outline_bell_with_dot';
import NotificationOutlineDot from './Assets/notification_outline_dot';
import OffClose from './Assets/off_close';
import OffOutlineClose from './Assets/off_outline_close';
import OpenInModal from './Assets/open_in_modal';
import Path from './Assets/path';
import PauseCircleOutline from './Assets/pause_circle_outline';
import Payment from './Assets/payment';
import Pdf from './Assets/pdf';
import Phone from './Assets/phone';
import Pipedrive from './Assets/pipedrive';
import PipedriveDeal from './Assets/pipedrive_deal';
import PipedriveMono from './Assets/pipedrive_mono';
import PipedriveText from './Assets/pipedrive_text';
import PlayCircleFilled from './Assets/play_circle_filled';
import PlayCircleOutline from './Assets/play_circle_outline';
import Plus from './Assets/plus';
import PlusCircleOutline from './Assets/plus_circle_outline';
import PointingHand from './Assets/pointing_hand';
import Profiles from './Assets/profiles';
import Refresh from './Assets/refresh';
import Refresh02 from './Assets/refresh_02';
import Salesforce from './Assets/salesforce';
import SalesforceMono from './Assets/salesforce_mono';
import SalesforceOpportunity from './Assets/salesforce_opportunity';
import Schedule from './Assets/schedule';
import Search from './Assets/search';
import Server from './Assets/server';
import Settings from './Assets/settings';
import Share from './Assets/share';
import ShareOutline from './Assets/share_outline';
import Show from './Assets/show';
import Shrink from './Assets/shrink';
import Slack from './Assets/slack';
import SlackMono from './Assets/slack_mono';
import Slider02 from './Assets/slider_02';
import SuperOffice from './Assets/superoffice';
import SuperOfficeMono from './Assets/superoffice_mono';
import SuperOfficeMonoInverse from './Assets/superoffice_mono_inverse';
import Tag from './Assets/tag';
import TagOutline from './Assets/tag_outline';
import Technology from './Assets/technology';
import Terminal from './Assets/terminal';
import Transfer from './Assets/transfer';
import TrashFull from './Assets/trash_full';
import Triggers from './Assets/triggers';
import TriggersDot from './Assets/triggers_dot';
import TriggersUpgrade from './Assets/triggers_upgrade';
import Twitter from './Assets/twitter';
import TwitterMono from './Assets/twitter_mono';
import Upload from './Assets/upload';
import Upsales from './Assets/upsales';
import UpsalesMono from './Assets/upsales_mono';
import User from './Assets/user';
import UserCircle from './Assets/user_circle';
import UserPlus from './Assets/user_plus';
import Vainu from './Assets/vainu';
import VainuText from './Assets/vainu_text';
import VainuTextMono from './Assets/vainu_text_mono';
import VehicleBus from './Assets/vehicle_bus';
import VehicleCar from './Assets/vehicle_car';
import VehicleMoto from './Assets/vehicle_moto';
import VehicleOther from './Assets/vehicle_other';
import VehicleTractor from './Assets/vehicle_tractor';
import VehicleTrailer from './Assets/vehicle_trailer';
import VehicleTruck from './Assets/vehicle_truck';
import VehicleVan from './Assets/vehicle_van';
import Warning from './Assets/warning';
import WarningOutline from './Assets/warning_outline';
import Webhook from './Assets/webhook';
import WindowCodeBlock from './Assets/window_code_block';
import WindowTerminal from './Assets/window_terminal';
import YouTube from './Assets/youtube';
import YouTubeMono from './Assets/youtube_mono';
import Zapier from './Assets/zapier';
import ZapierMono from './Assets/zapier_mono';
import ZapierText from './Assets/zapier_text';

export const iconChoices = {
    Advertising,
    Analytics,
    ApplicationTrackingSystem,
    Add: Plus,
    Award,
    ArrowDropDown,
    ArrowLongUp,
    ArrowLongLeft,
    ArrowLongRight,
    ArrowLongDown,
    ArrowShortRight,
    ArrowShortLeft,
    ArrowShortDown,
    ArrowShortUp,
    Building,
    Calendar,
    Reservation: Calendar,
    CalendarEdit,
    BarChart,
    Cars,
    ChartDoughnut,
    ChatSupport,
    CheckBig,
    CheckThick,
    CheckBold,
    CircleCheck,
    CircleCheckOutline,
    CloseSmall,
    CloseBig,
    CMS,
    Code,
    Communication,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    ChevronBigDown,
    ChevronBigLeft,
    ChevronBigRight,
    ChevronBigUp,
    ChevronDuoRight,
    Connectors,
    Copy,
    CRM,
    CustomInput,
    Document: Draft,
    Download,
    Draft,
    Duplicate: Copy,
    Disabled,
    DomainServer,
    Dynamics,
    DynamicsMono,
    DynamicsOpportunity,
    Edit,
    Error,
    ErrorOutline,
    Excel,
    Excel2019,
    Expand,
    Export,
    ExportPipedrive,
    ExportSalesforce,
    ExportHubSpot,
    ExportMore,
    ExportDynamics,
    ExternalLink,
    CompanyId,
    FacebookLetter,
    FacebookLetterMono,
    Filter,
    FilterNone,
    FlagDenmark,
    FlagFill,
    FlagFinland,
    FlagNorway,
    FlagOutline,
    FlagSweden,
    Folder,
    FolderMinus,
    Globe,
    General: Globe,
    Gmail,
    GmailMono,
    Google,
    GoogleMaps,
    Grid,
    Group,
    GroupStructure,
    HelpCircle,
    HelpCircleOutline,
    HeartFill,
    HeartOutline,
    Hide,
    HomeAltOutline,
    HubSpot,
    HubSpotMono,
    HubSpotCertification,
    HubSpotText,
    HubSpotDeal,
    InCrmDynamics,
    InCrmHubSpot,
    InCrmPipedrive,
    InCrmSalesforce,
    InCrmMore,
    InfoCircle,
    InfoCircleOutline,
    Insights: Donut,
    Judge,
    Label,
    Line,
    LineChartUp,
    Link,
    Link02,
    LinkedIn,
    LinkedInMono,
    ListPlus,
    ListCheck,
    Location,
    LocationOutline,
    Lock,
    Logout,
    Mail,
    Minus,
    MinusCircleOutline,
    Menu,
    Hamburger,
    MoreHorizontal,
    MoreVertical,
    Note,
    Notification,
    NotificationActive,
    NotificationBellWithDot,
    NotificationDeactivated,
    NotificationDot,
    NotificationOutline,
    NotificationOutlineBellWithDot,
    NotificationOutlineDot,
    OffClose,
    OpenInModal,
    OffOutlineClose,
    OtherDeal: PipedriveDeal,
    Path,
    PauseCircleOutline,
    Pdf,
    Phone,
    Donut,
    Pipedrive,
    PipedriveMono,
    PipedriveText,
    PipedriveDeal,
    PlayCircleOutline,
    PlayCircleFilled,
    Plus,
    PlusCircleOutline,
    PointingHand,
    Profiles,
    TrashFull,
    Remove: TrashFull,
    Refresh,
    Refresh02,
    Salesforce,
    SalesforceMono,
    SalesforceOpportunity,
    Schedule,
    Search,
    Settings,
    Shrink,
    Slider02,
    Share,
    ShareOutline,
    Show,
    Slack,
    SlackMono,
    SuperOffice,
    SuperOfficeMono,
    SuperOfficeMonoInverse,
    Tag,
    TagOutline,
    Technology,
    Other: Technology,
    UnCategorized: Technology,
    Terminal,
    Triggers,
    TriggersDot,
    TriggersUpgrade,
    Upload,
    Upsales,
    UpsalesMono,
    User,
    UserCircle,
    UserPlus,
    IdCard,
    Vainu,
    VainuText,
    VainuTextMono,
    Warning,
    WarningOutline,
    Webhook,
    Zapier,
    ZapierMono,
    ZapierText,
    VehicleBus,
    VehicleCar,
    VehicleMoto,
    VehicleOther,
    VehicleTractor,
    VehicleTrailer,
    VehicleTruck,
    VehicleVan,
    CircleLeft,
    CircleRight,
    CircleUp,
    CircleDown,
    CircleChevronLeft,
    CircleChevronRight,
    CircleChevronUp,
    CircleChevronDown,
    Facebook,
    FacebookMono,
    Twitter,
    TwitterMono,
    Instagram,
    InstagramMono,
    YouTube,
    YouTubeMono,
    LinkedInSquare,
    LinkedInSquareMono,
    MapMarker,
    EmailServer,
    ECommerce,
    Happy,
    Language,
    Localisation,
    MarketingAutomation,
    Payment,
    Server,
    SocialMedia: Happy,
    Transfer,
    WindowCodeBlock,
    Programming: WindowCodeBlock,
    WindowTerminal,

    HubspotCompanySvg,
    HubspotContactSvg,
    HubspotDealSvg,
    HubspotNoteSvg,
    HubspotTaskSvg,
    SalesforceAccountSvg,
    SalesforceContactSvg,
    SalesforceLeadSvg,
    SalesforceOpportunitySvg,
    SalesforceTaskSvg,
    PipedriveOrganizationSvg,
    PipedriveContactSvg,
    PipedriveDealSvg,
    PipedriveLeadSvg,
    PipedriveNoteSvg,
    PipedriveActivitySvg,
    DynamicsAccountSvg,
    DynamicsContactSvg,
    DynamicsLeadSvg,
    DynamicsOpportunitySvg,
    DynamicsTaskSvg,
} as const;

type IconObjects = typeof iconChoices;
export type IconType = keyof IconObjects;
export const iconTypeKeys = (Object.keys(iconChoices) as IconType[]).sort();
