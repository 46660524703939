export const getObjectKeys = <T extends string | number | symbol>(object: { [K in T]?: unknown }) => {
    return Object.keys(object) as Array<T>;
};

export const getObjectEntries = <T extends Object>(object: T) => {
    return Object.entries(object) as [[keyof T, T[keyof T]]];
};

export const getObjectValues = <T extends Object>(object: Record<string | number | symbol, T>) => {
    return Object.values(object) as Array<T>;
};

export const getFirstKey = <T extends string | number | symbol>(object: { [K in T]?: unknown }) => {
    return Object.keys(object)?.[0] as T;
};

/**
 * Can be used to create an object selecting only necessary keys from original object.
 * @param object - original object
 * @param keys array of keys to select from the object. Keys must be `string` type
 * @returns an object with selected keys only
 */
export const createObjectFilter = <T extends Object, K extends keyof T>(object: T, keys: K[]) => {
    return keys.reduce(
        (acc, key) => {
            acc[key] = object[key];
            return acc;
        },
        {} as Pick<T, K>,
    );
};
