import { useCallback, useEffect, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { groupBy } from 'lodash';
import { useRecoilValue } from 'recoil';

import { ApplicationStoreConnections } from 'api/types/ApplicationStore';
import { ProfileType } from 'api/types/Organization';
import { CRMBulkExportAsyncProcess } from 'api/types/UserAsyncProcess';
import { CRMS_EXPORT_OPTIONS } from 'components/modules/connectors/crm-export';
import useConnectorsApi from 'components/modules/connectors/hooks/useConnectorsApi';
import {
    GetProspectExportParams,
    ProspectExport,
    ProfileExportedToCRM,
    ProspectExportInternal,
    ProspectExportResponse,
    ActivityLog,
} from 'components/modules/connectors/types';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { mapCRMNameToTarget } from 'components/modules/connectors/utils/CRMTargetText';
import { usePermissionContext } from 'contexts/PermissionContext';
import { filteredCrmMassExportProcesses } from 'store/asyncProcessAtomSelectors';

interface CompanyExportData {
    [id: string]: Array<ProspectExport | ProspectExportInternal>;
}

export function useAllProfileExportsWithConnectedCRM(
    type: ProfileType,
    profileId: string,
    targetObject: 'Account' | 'Contact' = 'Account',
): {
    isLoading: boolean;
    profileExportedToCRMs: ProfileExportedToCRM[][];
    connectedButNotExportedToCRMs: ApplicationStoreConnections[];
    allConnectedCRMs: ApplicationStoreConnections[];
} {
    const queryClient = useQueryClient();
    const { getProspectExport } = useConnectorsApi();
    const { connections, isAccountLoading, integrations } = usePermissionContext();
    const targets = useMemo(
        () =>
            integrations.map((integrationKey) =>
                mapCRMNameToTarget(
                    CRMS_EXPORT_OPTIONS.find((crm) => crm.integration === integrationKey)?.action || ('' as CrmName),
                ).toLowerCase(),
            ),
        [integrations],
    );

    const cachedCompanyOrContactExportData = queryClient.getQueryData([
        `all-exported-prospect-to-crm-${profileId}-${targetObject}`,
    ]) as CompanyExportData | undefined;

    const companyExportJobId = useMemo(() => {
        return cachedCompanyOrContactExportData?.[profileId]?.find(
            (companyExport): companyExport is ProspectExportInternal => companyExport.hasOwnProperty('job_id'),
        )?.job_id;
    }, [profileId, cachedCompanyOrContactExportData]);
    const isCompanyExporting = !!companyExportJobId;

    const exports = useRecoilValue(filteredCrmMassExportProcesses({})) as CRMBulkExportAsyncProcess[];
    const companyExportProcess = useMemo(
        () => exports.find((exportItem) => exportItem.job_id === companyExportJobId),
        [companyExportJobId, exports],
    );

    const params: GetProspectExportParams = {
        [type === 'domain' ? 'domain' : 'business_id']: profileId,
        get_all: true,
        target_object: targetObject,
    };

    const { data, isLoading: isCRMsLoading } = useQuery<CompanyExportData>({
        queryKey: [`all-exported-prospect-to-crm-${profileId}-${targetObject}`],
        queryFn: () => getProspectExport(params).then((r) => r.data as unknown as ProspectExportResponse['data']),
    });

    const invalidateCRMExports = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [`all-exported-prospect-to-crm-${profileId}-${targetObject}`],
        });
    }, [profileId, targetObject, queryClient]);

    const addFailedCRMExport = useCallback(() => {
        queryClient.setQueryData<{ [id: string]: Array<ProspectExport | ProspectExportInternal> } | undefined>(
            [`all-exported-prospect-to-crm-${profileId}-${targetObject}`],
            (oldData) => {
                if (oldData && profileId) {
                    return {
                        [profileId]: (oldData[profileId] || []).map((row) => {
                            if (row.status === 'progress') {
                                return { ...row, status: 'failed' };
                            }
                            return row;
                        }),
                    };
                }

                return oldData;
            },
        );

        const timeoutId = setTimeout(() => {
            queryClient.setQueryData<{ [id: string]: Array<ProspectExport | ProspectExportInternal> } | undefined>(
                [`all-exported-prospect-to-crm-${profileId}-${targetObject}`],
                (oldData) => {
                    if (oldData && profileId) {
                        return { [profileId]: oldData[profileId].filter((row) => row.status === 'failure') };
                    }

                    return oldData;
                },
            );
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [profileId, targetObject, queryClient]);

    useEffect(() => {
        if (isCompanyExporting && companyExportProcess?.state === 'completed') {
            invalidateCRMExports();
        }

        if (isCompanyExporting && companyExportProcess?.state === 'failure') {
            addFailedCRMExport();
        }
    }, [isCompanyExporting, companyExportProcess?.state, invalidateCRMExports, addFailedCRMExport]);

    return useMemo(() => {
        // `crm_name` and `prospect_export_target` are only present for crm connections
        // so  either can be used
        const connectedCRMs = connections?.filter((c) => Boolean(c.crm_name)) ?? [];

        const profileExportedToCRMs =
            data?.[profileId]
                ?.filter((p) => {
                    return targets.includes(p.target.toLowerCase());
                })
                .sort((a, b) => a.created?.localeCompare(b.created || '') || 0)
                .map((row) => {
                    const {
                        target,
                        target_fk,
                        target_object,
                        owner_email,
                        won_deals_count,
                        lost_deals_count,
                        open_deals_count,
                        match_data,
                        status,
                        activity_log,
                    } = row;

                    const exportTarget = connectedCRMs?.find(
                        ({ prospect_export_target }) => prospect_export_target?.toLowerCase() === target?.toLowerCase(),
                    );

                    return {
                        id: target_fk,
                        crmTarget: target,
                        targetObject: target_object || '',
                        ownerEmail: owner_email || '',
                        linkToCRM: exportTarget?.link_templates[targetObject]?.replace('{}', target_fk || ''),
                        wonDealCount: won_deals_count || 0,
                        lostDealCount: lost_deals_count || 0,
                        openDealCount: open_deals_count || 0,
                        accountName: match_data?.company_name?.[0] || null,
                        status,
                        contactId: targetObject === 'Contact' && activity_log ? getContactId(activity_log) : null,
                    };
                })
                // filter out old export without target_fk
                .filter((profile) => Boolean(profile.id)) ?? [];

        const connectedButNotExportedToCRMs =
            connectedCRMs.filter(
                (connectedCRM) =>
                    !profileExportedToCRMs?.some(
                        (exported) => exported.crmTarget === connectedCRM.prospect_export_target,
                    ),
            ) ?? [];

        const isLoading = isCRMsLoading || isAccountLoading;

        return {
            profileExportedToCRMs: Object.values(groupBy(profileExportedToCRMs, 'crmTarget')),
            connectedButNotExportedToCRMs,
            allConnectedCRMs: connectedCRMs,
            isLoading,
        };
    }, [connections, data, profileId, isCRMsLoading, isAccountLoading, targets, targetObject]);
}

const getContactId = (activityLog: ActivityLog[]) => {
    for (const row of activityLog) {
        if (row.source_objects) {
            for (const obj of row.source_objects) {
                if (obj.obj_type === 'contact') {
                    return obj.obj_id;
                }
            }
        }
    }
    return null;
};
