import { useMemo } from 'react';

import { uniq } from 'lodash';
import { isNil, localeCompareEmptyLast } from 'utilities';

import { BasicData, EmployeesData } from 'api/profileData';
import { Contact, ContactTitle } from 'api/types/Organization';

export const getOrganizationSize = (
    data: EmployeesData | BasicData | undefined,
): [lower: number, upper?: number | null] | undefined => {
    if (!data) {
        return undefined;
    }
    const companySizeModel = data.organization_size_indicators?.find(
        (indicator) => indicator.indicator_type === 'company_size_indicator_model',
    );
    if (companySizeModel?.value_range && !isNil(companySizeModel?.value_range?.lower_limit)) {
        return [companySizeModel.value_range.lower_limit, companySizeModel.value_range.upper_limit];
    }
};

export const getOrganizationSizeClass = (data: EmployeesData | BasicData | undefined): string | undefined => {
    if (!data) {
        return undefined;
    }
    const companySizeModel = data.organization_size_indicators?.find(
        (indicator) => indicator.indicator_type === 'company_size_indicator_model',
    );
    if (companySizeModel?.size_classes?.length) {
        return companySizeModel?.size_classes[0];
    }
};

export const useOrganizationSize = (data: EmployeesData | BasicData | undefined) =>
    useMemo(() => ({ value: getOrganizationSize(data), class: getOrganizationSizeClass(data) }), [data]);

export const getRoles = (titles?: ContactTitle[]) =>
    (
        uniq(titles?.flatMap((title) => title.classifications?.map((cls) => cls.functional_area) || []) || []).filter(
            Boolean,
        ) as string[]
    ).sort(sortRoles);

const sortRoles = (a = '', b = '') => {
    // CEO first
    if (a === 'CEO') {
        return -1;
    }
    if (b === 'CEO') {
        return 1;
    }
    // Other last
    if (a === 'Other') {
        return 1;
    }
    if (b === 'Other') {
        return -1;
    }

    return localeCompareEmptyLast(a, b);
};

type CategorizedEmployees = {
    boardMembers: Contact[];
    employees: Contact[];
};

export const checkBoardMember = (contact: Contact) => {
    return contact.titles?.some((title) =>
        title.classifications?.find((classification) => classification.functional_area === 'Board'),
    );
};

export const checkDecisionMaker = (contact: Contact) => {
    return contact.titles?.some((title) =>
        title.classifications?.find((classification) => classification.is_decision_maker),
    );
};

export const splitEmployeesByClassification = (employees: Contact[]) => {
    return [...employees]
        .sort((a, b) => {
            const aRoles = getRoles(a.titles);
            const bRoles = getRoles(b.titles);

            return sortRoles(aRoles[0], bRoles[0]);
        })
        .reduce(
            (acc, contact) => {
                const isDecisionMaker = checkDecisionMaker(contact);
                const isBoardMember = checkBoardMember(contact);

                if (isBoardMember && !isDecisionMaker) {
                    return {
                        ...acc,
                        boardMembers: [...acc.boardMembers, contact],
                    };
                }
                return {
                    ...acc,
                    employees: [...acc.employees, contact],
                };
            },
            {
                boardMembers: [],
                employees: [],
            } as CategorizedEmployees,
        );
};

export const filterContactsBySearch = (employees: Contact[], searchTerm: string, searchPhoneAndEmail = true) => {
    if (!searchTerm) {
        return employees;
    }
    const searchTermLC = searchTerm.toLowerCase();
    return employees.filter((employee) => {
        const searchFields = [
            employee.full_name,
            ...getRoles(employee.titles),
            ...(employee.titles?.map((title) => title.title ?? '') ?? []),
        ];
        if (searchPhoneAndEmail) {
            searchFields.push(employee.email, employee.phone);
        }
        const searchFieldsLC = searchFields.map((field) => field?.toLowerCase());
        return searchFieldsLC.some((field) => field?.includes(searchTermLC));
    });
};

const injectHighlightTag = (text: string | null | undefined, searchTerm: string) => {
    const foundIndex = text?.toLowerCase().indexOf(searchTerm);
    if (text && foundIndex !== undefined && foundIndex !== -1) {
        return (
            <>
                {text.substring(0, foundIndex)}
                <em>{text.substring(foundIndex, foundIndex + searchTerm.length)}</em>
                {text.substring(foundIndex + searchTerm.length)}
            </>
        );
    }
    return text;
};

export const highlightSearchTerm = (employees: Contact[], searchTerm: string, searchPhoneAndEmail = true) => {
    if (!searchTerm) {
        return employees;
    }
    const searchTermLC = searchTerm.toLowerCase();
    const searchFields = ['full_name', 'titles'];
    if (searchPhoneAndEmail) {
        searchFields.push('email', 'phone');
    }
    return employees.map(
        (employee) =>
            Object.fromEntries(
                Object.entries(employee).map(([key, value]) => {
                    if (searchFields.includes(key)) {
                        if (key === 'titles') {
                            const highlightedTitles = (value as ContactTitle[]).map((title) => ({
                                ...title,
                                title: injectHighlightTag(title.title, searchTermLC),
                            }));
                            return [key, highlightedTitles];
                        }
                        return [key, injectHighlightTag(value as string, searchTermLC)];
                    }
                    return [key, value];
                }),
            ) as Contact,
    );
};

export const getLastModificationDate = (employees: Contact[]) => {
    const date = employees.reduce(
        (acc, curr) =>
            curr.metadata?.modified && new Date(curr.metadata.modified) > acc ? new Date(curr.metadata.modified) : acc,
        new Date(0),
    );
    if (date > new Date(0)) {
        return date;
    }
    return undefined;
};

const contactSourceLabels: Record<string, string> = {
    registry: 'Official',
    web: 'Web',
};

export const formatContactSource = (employee: Contact) =>
    employee.metadata?.source_types
        ?.filter((source): source is string => !!source && typeof source === 'string')
        .map((source) => contactSourceLabels[source] ?? source.slice(0, 1).toUpperCase() + source.slice(1))
        .join(', ') ?? '';
