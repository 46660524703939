import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronBigDown: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M3.51501 8.46501L12 16.95L20.485 8.46501L19.071 7.05001L12 14.122L4.92901 7.05001L3.51501 8.46501Z" />
    </SvgIcon>
);

export default ChevronBigDown;
