import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { Lead } from 'api/types/Lead';
import ContactExport from 'components/modules/connectors/BulkExportCRM/ContactExport';
import ExportDataMappingTooltip from 'components/modules/connectors/BulkExportCRM/ExportDataMappingTooltip';
import ConnectorDrawerWithButton from 'components/modules/connectors/common/ConnectorDrawerWithButton';
import { useCRMIntegConfig } from 'components/modules/connectors/context/CRMIntegrationConfigContext';
import useConnectorsApi from 'components/modules/connectors/hooks/useConnectorsApi';
import { TabEnum } from 'components/modules/connectors/types';
import { CrmName, CRMNameAPIResponse, CRMObject, FieldMapping } from 'components/modules/connectors/types/Crm';
import { getCRMName } from 'components/modules/connectors/utils';
import { triggerCrmExportables } from 'components/modules/connectors/utils/CRMTargetText';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { ChipMultiSelect } from 'components/tokens/select-components/ChipMultiSelect';
import Select from 'components/tokens/select-components/Select';
import { Typography } from 'components/tokens/Typography';
import { enabledCRMs, shortenTriggerTitle } from 'utilities/triggers';

import SourceToDestination from '../../../templates/SourceToDestination/SourceToDestination';

const getArrayWithoutDuplicateElement = (list: CRMObject[] = []): CRMObject[] => {
    const listWithoutDuplicate = Array.from(new Set(list));
    return listWithoutDuplicate.filter((each) => !!each);
};

export interface CRMDestinationType {
    system: CrmName;
    crm_objects: string[];
    active: boolean;
    isValid?: boolean;
    assignTo?: string[];
}

type OptionType = {
    value: CRMObject;
    label: string;
};

interface CRMDestinationSettingsProps {
    destination: CRMDestinationType | null;
    onChange: (obj: CRMObject[], assign: string[]) => void;
    lowerCaseName: CrmName;
    target: CRMNameAPIResponse;
    selectedCrmObjects: CRMObject[];
    handleClose: () => void;
    open: boolean;
    options: OptionType[];
    showAsDialog?: boolean;
    fieldMapping?: FieldMapping<CRMObject>[];
    confirmBtnText?: string;
    exportLegacyLead?: boolean;
    legacyLead?: Lead;
    triggerName?: string;
}

const CRMDestinationSettings: React.FC<CRMDestinationSettingsProps> = ({
    destination,
    open,
    handleClose,
    lowerCaseName,
    selectedCrmObjects,
    onChange,
    options,
    target,
    showAsDialog = true,
    fieldMapping: defaultFieldMapping = [],
    confirmBtnText,
    exportLegacyLead = false,
    legacyLead,
    triggerName,
}) => {
    const [assignTo, setAssignTo] = useState<string[]>([]);
    const { getCRMUsers } = useConnectorsApi();
    const [openDataMappingDrawer, setOpenDataMappingDrawer] = useState<{ tab?: TabEnum; object?: CRMObject }>();

    const [crmConfigState, crmIntegrationDispatch] = useCRMIntegConfig();
    const { initConfig, updateIntegrationSetting } = crmIntegrationDispatch || {};
    const fieldMapping = crmConfigState?.field_mapping?.length ? crmConfigState.field_mapping : defaultFieldMapping;

    const hasFieldMappingForObjects = fieldMapping.reduce((prev, curr) => prev.add(curr.target_object), new Set());
    const filteredOptions = options.filter(
        (value) => isAllowedOption(lowerCaseName, value.value) && hasFieldMappingForObjects.has(value.value),
    );

    const [selectedObjects, setSelectedObjects] = useState<CRMObject[]>(
        filteredOptions[0] ? [filteredOptions[0].value] : [],
    );

    const { data: crmUsersResponse } = useQuery({
        queryKey: ['getCRMUsers', lowerCaseName],
        queryFn: () => getCRMUsers(lowerCaseName),
        staleTime: Infinity,
        gcTime: 1000 * 3600,
        enabled: enabledCRMs.includes(lowerCaseName) && !legacyLead,
    });
    let crmUsers = (crmUsersResponse?.data || []).map((user) => {
        if (user.is_you) {
            if (lowerCaseName === 'hubspot') {
                return { ...user, name: `Yourself (${user.name})`, id: user.owner_id || user.id };
            }
            return { ...user, name: `Yourself (${user.name})` };
        }
        if (lowerCaseName === 'hubspot') {
            return { ...user, id: user.owner_id || user.id };
        }
        return user;
    });
    const isYou = crmUsers.filter((a) => a.is_you);
    const restOfUsers = crmUsers.filter((a) => !a.is_you);
    restOfUsers.sort((a, b) => (a.name < b.name ? -1 : 1));
    crmUsers = [...isYou, ...restOfUsers];

    useEffect(() => {
        if (selectedCrmObjects?.length) {
            updateSelectedObjects(selectedCrmObjects);
        }
    }, [selectedCrmObjects]);

    useEffect(() => {
        if (destination?.assignTo?.length) {
            setAssignTo(destination.assignTo.filter(Boolean));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destination?.assignTo]);

    useEffect(() => {
        if (open && isEmpty(openDataMappingDrawer?.tab || '') && initConfig) {
            initConfig(lowerCaseName);
        }
    }, [open, lowerCaseName, openDataMappingDrawer, initConfig]);

    const setSubObjects = (values: CRMObject[]) => {
        if (showAsDialog) {
            updateSelectedObjects([selectedObjects[0], ...values]);
        } else {
            handleChangeInstantly([selectedObjects[0], ...values]);
        }
    };

    const setAssignToUser = (user: string[]) => {
        setAssignTo(user);
        if (!showAsDialog) {
            onChange(selectedObjects, user);
        }
    };

    const onSave = () => {
        onChange(selectedObjects, assignTo);
        handleClose();
    };
    const onCancel = () => {
        updateSelectedObjects(selectedCrmObjects);
        setAssignTo(destination?.assignTo || []);
        handleClose();
    };

    // @ts-ignore
    const suboptions = (triggerCrmExportables[lowerCaseName]?.[selectedObjects?.[0]] || []).filter((i) =>
        hasFieldMappingForObjects.has(i),
    );

    const updateSelectedObjects = (objects: CRMObject[] = []) => {
        // removing duplicate and enforcing Note object
        const uniqueSelectedObjects = getArrayWithoutDuplicateElement([...objects]);
        setSelectedObjects(uniqueSelectedObjects);
    };

    const handleChangeInstantly = (values: CRMObject[]) => {
        onChange(values, assignTo);
    };

    const handleConnectorDialogCloseCallback = () => {
        setOpenDataMappingDrawer({});
        if (updateIntegrationSetting) {
            updateIntegrationSetting(lowerCaseName);
        }
    };

    const fieldMappingFromSelectedObjs = fieldMapping?.filter(({ target_object }) => {
        return selectedObjects?.includes(target_object);
    });

    const crmUsersOptions = crmUsers.map((user) => ({
        value: user.id as string,
        label: user.name,
        icon: <Icon type="UserCircle" width={16} height={16} />,
    }));

    const tooltipText = `If the ${getCompanyObject(
        lowerCaseName,
    )} is not exported alongside or it doesn’t have an owner yet, the ${
        selectedObjects?.[0] || '{Object}'
    } will be assigned to you.`;

    const assignOptions = [
        {
            label: `${getCompanyObject(lowerCaseName)} owners`,
            value: '',
            endAdornment: (
                <Tooltip title={tooltipText}>
                    <div>
                        <Icon type="HelpCircleOutline" fontSize="small" />
                    </div>
                </Tooltip>
            ),
            tooltip: tooltipText,
            icon: <Icon type="Group" width={16} height={16} />,
        },
        ...crmUsersOptions,
    ];

    const exportSettings = (
        <div>
            {filteredOptions.length ? (
                <StyledRow>
                    <StyledTextContainer>
                        <StyledText>Send as:</StyledText>
                    </StyledTextContainer>
                    <Select
                        value={filteredOptions.find((opt) => opt.value === selectedObjects?.[0]) || ''}
                        onValueChange={(value) =>
                            showAsDialog
                                ? updateSelectedObjects([(value as OptionType).value])
                                : handleChangeInstantly([(value as OptionType).value])
                        }
                        multiple={false}
                        options={filteredOptions}
                        width="100%"
                        placeholder="Select option..."
                    />
                </StyledRow>
            ) : (
                <StyledRow>
                    <Typography>
                        Define data mapping for{' '}
                        {Object.keys(triggerCrmExportables[lowerCaseName]).map((obj, idx, arr) => (
                            <React.Fragment key={obj}>
                                {idx === arr.length - 1 ? ' or ' : idx !== 0 && ', '}
                                <Typography weight="semibold" component="span">
                                    {obj}
                                </Typography>
                            </React.Fragment>
                        ))}{' '}
                        to set up {getCRMName(lowerCaseName)} delivery.
                    </Typography>
                </StyledRow>
            )}

            {suboptions.length ? (
                <StyledRow>
                    <StyledTextContainer>
                        <StyledText>Send more data alongside:</StyledText>
                        <Tooltip
                            title={`Exporting other objects will link them with the same ${
                                selectedObjects?.[0] || '{Object}'
                            } and owner`}
                        >
                            <div>
                                <Icon
                                    type="HelpCircleOutline"
                                    fontSize="small"
                                    color="grey.500"
                                    sx={{ marginLeft: 0.5 }}
                                />
                            </div>
                        </Tooltip>
                    </StyledTextContainer>
                    <Box sx={{ flex: 1 }}>
                        <ChipMultiSelect
                            selectedOptions={selectedObjects.slice(1)}
                            onChange={(selectedObjs) => setSubObjects(selectedObjs)}
                            options={suboptions}
                            getOptionLabel={(key) => key}
                            selectSx={{ width: '100%' }}
                        />
                    </Box>
                </StyledRow>
            ) : null}
            {exportLegacyLead || !selectedObjects?.[0] ? null : (
                <StyledRow>
                    <StyledTextContainer>
                        <StyledText>Assign to:</StyledText>
                    </StyledTextContainer>
                    <ContactExport
                        crm={lowerCaseName}
                        object={selectedObjects?.[0]}
                        options={assignOptions}
                        defaultSelected={assignTo?.length ? assignTo : []}
                        onSelect={(val) => (val ? setAssignToUser(val) : setAssignToUser([]))}
                    />
                </StyledRow>
            )}
            <StyledRow>
                <DataMappingOverview
                    crm={lowerCaseName}
                    selectedObjects={selectedObjects}
                    fieldMappedObjects={fieldMappingFromSelectedObjs}
                    handleConnectorDialogCloseCallback={handleConnectorDialogCloseCallback}
                    openDataMappingDrawer={openDataMappingDrawer}
                    setOpenDataMappingDrawer={setOpenDataMappingDrawer}
                />
            </StyledRow>
        </div>
    );

    if (!showAsDialog) {
        return exportSettings;
    }

    const exportHeader = triggerName
        ? `Export settings for "${shortenTriggerTitle(triggerName)}"`
        : `Export settings for ${target}`;
    return (
        <Dialog
            open={open}
            fullWidth
            BackdropProps={{
                invisible: true,
            }}
        >
            <DialogContent>
                <StyledClose onClick={onCancel}>
                    <Icon type="CloseBig" width={15} height={15} />
                </StyledClose>
                <SourceToDestination target={lowerCaseName} source="trigger" />
                <StyledTitle>
                    {exportLegacyLead ? `Send trigger "${shortenTriggerTitle(legacyLead?.title || '')}"` : exportHeader}
                </StyledTitle>
                {exportSettings}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()} variant="flat">
                    Cancel
                </Button>
                <Button disabled={!selectedObjects?.[0]} onClick={() => onSave()}>
                    {confirmBtnText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CRMDestinationSettings;

function isAllowedOption(lowerCaseName: keyof typeof triggerCrmExportables, objectName: string) {
    return Object.keys(triggerCrmExportables[lowerCaseName] || {}).includes(objectName);
}

function getCompanyObject(crm: string): string {
    const mapping = {
        dynamics: 'Accounts',
        pipedrive: 'Organization',
        hubspot: 'Company',
        salesforce: 'Account',
        salesforcesandbox: 'Account',
    };
    return mapping?.[crm as keyof typeof mapping] || '';
}

type DataMappingOverviewProps = {
    crm: CrmName;
    selectedObjects: CRMObject[];
    fieldMappedObjects: FieldMapping<CRMObject>[];
    handleConnectorDialogCloseCallback: () => void;
    openDataMappingDrawer?: { tab?: TabEnum; object?: CRMObject };
    setOpenDataMappingDrawer: (obj: { tab?: TabEnum; object?: CRMObject }) => void;
};
const DataMappingOverview: React.FC<DataMappingOverviewProps> = ({
    crm,
    selectedObjects = [],
    fieldMappedObjects = [],
    handleConnectorDialogCloseCallback,
    openDataMappingDrawer,
    setOpenDataMappingDrawer,
}) => {
    return (
        <StyledRow>
            {selectedObjects?.length ? (
                <ExportDataMappingTooltip
                    isTooltip={isEmpty(openDataMappingDrawer)}
                    crm={crm}
                    fieldMappingFromSelectedObjs={fieldMappedObjects}
                    selectMappedObject={(obj) => {
                        setOpenDataMappingDrawer({
                            tab: 'mapping',
                            object: obj,
                        });
                    }}
                >
                    <Icon type="HelpCircleOutline" fontSize="small" color="grey.500" />
                </ExportDataMappingTooltip>
            ) : (
                <StyledText />
            )}
            <ConnectorDrawerWithButton
                crm={crm}
                tab={openDataMappingDrawer?.tab || 'mapping'}
                object={openDataMappingDrawer?.object}
                handleClick={() =>
                    setOpenDataMappingDrawer({
                        tab: 'mapping',
                        object: selectedObjects?.[0],
                    })
                }
                handleClose={() => {
                    handleConnectorDialogCloseCallback();
                    setOpenDataMappingDrawer({});
                }}
                open={!isEmpty(openDataMappingDrawer)}
                buttonText="Check data mapping"
                buttonProps={{ disableRipple: true, variant: 'tertiary', size: 'small' }}
            />
        </StyledRow>
    );
};

const StyledRow = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 0px 8px 0px;
`;

const StyledTitle = styled('div')`
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.02em;
    padding: 0px 0px 8px 0px;
`;

const StyledClose = styled('div')`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
`;

const StyledTextContainer = styled('div')`
    min-width: 200px;
    display: flex;
`;

const StyledText = styled('span')`
    font-size: 14px;
    line-height: 140%;
`;
