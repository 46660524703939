import React from 'react';

import ProfileSettings from './ProfileSettings';
import TeamSettings from './TeamSettings';

const ProfileAndTeam = () => {
    return (
        <div>
            <ProfileSettings />
            <TeamSettings />
        </div>
    );
};

export default ProfileAndTeam;
