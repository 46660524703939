const companySize = {
    id: 'company_size',
    '?AGGREGATE': [
        {
            '?FILTER_SUBDOCUMENTS': {
                path: 'organization_size_indicators',
                query: { '?EQ': { indicator_type: 'company_size_indicator_model' } },
            },
        },
        { '?GROUP': { path: 'organization_size_indicators.0.size_classes.0' } },
    ],
};

const companySizeOfficial = {
    id: 'company_size_official',
    '?AGGREGATE': [
        {
            '?GROUP': {
                path: 'financial_data.employee_count',
                // ranges same as in company_size_indicator_model
                buckets: [
                    { '?LTE': 10, label: 'micro' },
                    { '?LTE': 50, label: 'small' },
                    { '?LTE': 200, label: 'medium' },
                    { '?LTE': 1000, label: 'large' },
                    { '?GT': 1000, label: 'enterprise' },
                    { label: '' },
                ],
            },
        },
    ],
};

const trafficRank = {
    id: 'traffic_rank',
    '?AGGREGATE': [
        {
            '?GROUP': {
                path: 'website_data.website_traffic_data.ranking',
                buckets: [
                    { '?LTE': 1000, label: 'Top 1k' },
                    { '?LTE': 100000, label: 'Top 100k' },
                    { '?LTE': 500000, label: '100k-500k' },
                    { '?LTE': 2000000, label: '500k-2M' },
                    { '?LTE': 4000000, label: '2M-4M' },
                    { '?LTE': 10000000, label: '4M-10M' },
                    { label: '10M+' },
                ],
            },
        },
    ],
};

const revenue = {
    id: 'revenue',
    '?AGGREGATE': [
        {
            '?GROUP': {
                path: 'financial_data.revenue',
                buckets: [
                    { '?LT': 1e6, label: 'Under 1M €' },
                    { '?LT': 5e6, label: '1M-5M €' },
                    { '?LT': 10e6, label: '5M-10M €' },
                    { '?LT': 50e6, label: '10M-50M €' },
                    { '?GTE': 50e6, label: '50M+ €' },
                    { label: 'Not reported' },
                ],
            },
        },
    ],
};

const groupRevenue = {
    id: 'group_revenue',
    '?AGGREGATE': [
        {
            '?GROUP': {
                path: 'group_data.financial_data.revenue.value',
                buckets: [
                    { '?LT': 1e6, label: 'Under 1M €' },
                    { '?LT': 5e6, label: '1M-5M €' },
                    { '?LT': 10e6, label: '5M-10M €' },
                    { '?LT': 50e6, label: '10M-50M €' },
                    { '?GTE': 50e6, label: '50M+ €' },
                    { label: 'Not reported' },
                ],
            },
        },
    ],
};

const vciB2X = {
    id: 'vci_b2b_b2c',
    '?AGGREGATE': [
        {
            '?FILTER_SUBDOCUMENTS': {
                path: 'vainu_custom_industries',
                query: {
                    '?ALL': [
                        {
                            '?EQ': {
                                confidence_class: 'high',
                            },
                        },
                        {
                            '?IN': {
                                value: ['B2B', 'B2C'],
                            },
                        },
                        {
                            '?EQ': {
                                source_version: 'b2b_b2c_xgboost_v1',
                            },
                        },
                    ],
                },
            },
        },
        {
            '?GROUP': {
                path: 'vainu_custom_industries.*.value',
                buckets: [
                    {
                        '?EQ': 'B2B',
                    },
                    {
                        '?EQ': 'B2C',
                    },
                ],
            },
        },
    ],
};

const vci = {
    id: 'vci',
    '?AGGREGATE': [
        {
            '?FILTER_SUBDOCUMENTS': {
                path: 'vainu_custom_industries',
                query: { '?EQ': { confidence_class: 'high' } },
            },
        },
        { '?GROUP': { path: 'vainu_custom_industries.*.value' } },
    ],
};

const technologies = {
    id: 'technologies',
    '?AGGREGATE': [
        {
            '?FILTER_SUBDOCUMENTS': {
                path: 'technology_data',
                query: {
                    '?ALL': [{ '?NOT': { '?EQ': { types: 'language' } } }, { '?NOT': { '?EQ': { types: 'some' } } }],
                },
            },
        },
        { '?GROUP': { path: 'technology_data.*', group_by: ['name', 'logo_url', 'types.0'] } },
    ],
};

const hqCity = {
    id: 'hq_city',
    '?AGGREGATE': [{ '?GROUP': { path: 'visiting_address', group_by: ['city', 'country'] } }],
};

const hqCountry = {
    id: 'hq_country',
    '?AGGREGATE': [
        {
            '?FILTER_SUBDOCUMENTS': {
                path: 'business_units',
                query: { '?ALL': [{ '?EQ': { types: ['hq'] } }] },
            },
        },
        { '?GROUP': { path: 'business_units.*.address.country' } },
    ],
};

const industries = {
    id: 'industries',
    '?AGGREGATE': [
        {
            '?GROUP': {
                path: 'official_industries.0',
                group_by: ['code_2_level_descriptions.en', 'code_2_level'],
            },
        },
    ],
};

const getStatsPayload = (isDomainData: boolean) => {
    return isDomainData
        ? [companySize, trafficRank, vciB2X, vci, technologies, hqCountry]
        : [
              companySize,
              companySizeOfficial,
              revenue,
              groupRevenue,
              vciB2X,
              vci,
              industries,
              trafficRank,
              technologies,
              hqCity,
          ];
};

export default getStatsPayload;
