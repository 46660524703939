import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckboxEmpty: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <rect className="background" x="1.6665" y="1.66675" width="16.6667" height="16.6667" rx="3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3332 3.16675H4.6665C3.83808 3.16675 3.1665 3.83832 3.1665 4.66675V15.3334C3.1665 16.1618 3.83808 16.8334 4.66651 16.8334H15.3332C16.1616 16.8334 16.8332 16.1618 16.8332 15.3334V4.66675C16.8332 3.83832 16.1616 3.16675 15.3332 3.16675ZM4.6665 1.66675C3.00965 1.66675 1.6665 3.0099 1.6665 4.66675V15.3334C1.6665 16.9903 3.00965 18.3334 4.66651 18.3334H15.3332C16.99 18.3334 18.3332 16.9903 18.3332 15.3334V4.66675C18.3332 3.00989 16.99 1.66675 15.3332 1.66675H4.6665Z"
        />
    </SvgIcon>
);

export default CheckboxEmpty;
