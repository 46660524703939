import { capitalize } from 'lodash';

import { Bucket } from 'api/filterCompanies';
import { Operation } from 'api/types/FilterOperators';
import { TargetReqParam } from 'components/modules/connectors/utils/CRMTargetText';
import { Database } from 'contexts/ListsContext';
import { getObjectKeys } from 'utilities/objectUtils';

import { TileData } from './types';

export const employeeRanges: Record<string, string> = {
    micro: '1-10',
    small: '11-50',
    medium: '51-200',
    large: '201-1k',
    enterprise: '1k+',
    // Unknown
    '': '',
};

export const employeeClasses = getObjectKeys(employeeRanges);

export const getHighlightIndex = (data: TileData[]) => {
    let highest = -Infinity;
    let value: number | undefined;

    data.forEach((row, idx) => {
        if (row.ignoreForHighlight) {
            return;
        }
        if (row.value != null && row.value > highest) {
            highest = row.value;
            value = idx;
        }
    });

    return value;
};

export const getQueryUid = (
    target: TargetReqParam | `list-${string}` | undefined,
    database: Database | undefined,
    type: 'default' | 'customers' | undefined,
) => {
    if (!target || !database || !type) {
        return null;
    }

    return [target, database, type].join('-');
};

export const getQuery = (target: TargetReqParam, isDomainConnector: boolean, customersOnly?: boolean): Operation => {
    return {
        '?ALL': [
            {
                '?EQ': {
                    'prospectexport.target': target,
                },
            },
            {
                '?EQ': {
                    target_object: 'Account',
                },
            },
            ...(isDomainConnector
                ? [
                      {
                          '?MATCH': {
                              matches: {
                                  '?ALL': [
                                      {
                                          '?EQ': {
                                              db: 'GLOBAL',
                                          },
                                      },
                                      {
                                          '?EQ': {
                                              primary: true,
                                          },
                                      },
                                      {
                                          '?EQ': {
                                              found: true,
                                          },
                                      },
                                  ],
                              },
                          },
                      },
                  ]
                : [{ '?GT': { prospect: 0 } }]),
            ...(customersOnly
                ? [
                      {
                          '?GT': {
                              'prospectexport.won_deals_count': 0,
                          },
                      },
                  ]
                : []),
        ],
    };
};

export const formatCompanySizeData = (buckets: Bucket[], labelKey: string, noValueLabel: string) => {
    return buckets
        .sort(
            (a, b) =>
                employeeClasses.indexOf((a.value as string) || '') - employeeClasses.indexOf((b.value as string) || ''),
        )
        .map((bucket) => ({
            value: bucket.count,
            label: bucket[labelKey] ? capitalize(bucket[labelKey] as string) : noValueLabel,
            secondaryLabel: employeeRanges[(bucket[labelKey] as string) || ''],
            ignoreForHighlight: !bucket[labelKey],
        }));
};

export const DEFAULT_DATA = new Array(10).fill({
    value: undefined,
    label: undefined,
});

export const defaultMinWidth = 400;
