import React, { useRef } from 'react';

import { Box, ListItem, ListItemButton, ListItemButtonProps, ListItemProps, SxProps, Tooltip } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { useTextCutOff } from 'hooks/useTextCutOff';
import { combineSx } from 'utilities/mui';

export type MainListItemProps = Omit<ListItemProps, 'selected'> &
    Pick<ListItemButtonProps, 'selected' | 'disabled' | 'autoFocus'> &
    Partial<Pick<NavLinkProps, 'to'>> & {
        /** A slot to render at the left end of the item. */
        startIcon?: React.ReactNode;
        /** A slot to render at the right end of the item. */
        endIcon?: React.ReactNode;
        /** Make the endIcon appear only when you hover over the item. */
        endIconVisibleOnHover?: boolean;
        tooltip?: React.ReactNode;
        /** If set, the contents of the list item is rendered in a tooltip in case the contents overflow the item container.
         * If the tooltip prop is also set, it's rendered in the same tooltip below the item contents. */
        overflowInTooltip?: boolean;
        size?: 'small' | 'medium';
        buttonSx?: SxProps;
    };

export const MainListItem: React.FC<MainListItemProps> = ({
    children,
    selected,
    disabled,
    to,
    startIcon,
    endIcon,
    endIconVisibleOnHover = false,
    tooltip,
    overflowInTooltip = false,
    size = 'medium',
    buttonSx,
    autoFocus,
    ...props
}) => {
    const labelRef = useRef<HTMLElement | null>(null);
    const [isTextCutOff, onRefresh] = useTextCutOff(labelRef);

    const content = (
        <Box
            ref={labelRef}
            sx={{
                flexGrow: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {children}
        </Box>
    );

    const height = size === 'medium' ? 40 : 32;

    return (
        <ListItem disablePadding {...props}>
            <ListItemButton
                sx={combineSx(
                    {
                        display: 'flex',
                        justifyContent: 'space-between',
                        height,
                        color: 'text.primary',
                        fontSize: 14,
                        fontWeight: 500,
                        borderRadius: 1,
                        marginBottom: 0.5,
                        paddingX: 1.5,
                        '&.Mui-selected': {
                            backgroundColor: 'menu.selected',
                            color: 'menu.selectedText',
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: 'menu.selected',
                        },
                        '&:hover': {
                            backgroundColor: 'menu.hover',
                            '& .endIconWrapper': {
                                opacity: 1,
                            },
                        },
                    },
                    buttonSx,
                )}
                selected={selected}
                autoFocus={autoFocus}
                disabled={disabled}
                component="div"
            >
                <ListItemButton
                    disableRipple
                    component={to ? NavLink : 'div'}
                    to={to}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&.Mui-selected': {
                            backgroundColor: 'transparent',
                            color: 'transparent',
                        },
                    }}
                >
                    {!!startIcon && (
                        <Box component="span" sx={{ marginRight: 1, display: 'flex', alignItems: 'center' }}>
                            {startIcon}
                        </Box>
                    )}
                    <Tooltip
                        arrow
                        onOpen={() => onRefresh(true)}
                        placement="top"
                        title={
                            (overflowInTooltip && isTextCutOff) || tooltip ? (
                                <>
                                    {overflowInTooltip && isTextCutOff && <p>{children}</p>}
                                    {tooltip && <p>{tooltip}</p>}
                                </>
                            ) : null
                        }
                        enterDelay={800}
                        enterNextDelay={800}
                    >
                        {content}
                    </Tooltip>
                </ListItemButton>

                {!!endIcon && (
                    <Box
                        className="endIconWrapper"
                        sx={{
                            marginRight: -1,
                            marginY: -1,
                            opacity: endIconVisibleOnHover ? 0 : 1,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        component="span"
                    >
                        {endIcon}
                    </Box>
                )}
            </ListItemButton>
        </ListItem>
    );
};

export default MainListItem;
