import { useRef } from 'react';

import { Tooltip } from '@mui/material';

import Typography from 'components/tokens/Typography';
import { useTextCutOff } from 'hooks/useTextCutOff';

export type ListNameColumnProps = {
    id: string;
    name: string;
};

export const ListNameColumn: React.FC<ListNameColumnProps> = ({ id, name }) => {
    const listNameRef = useRef<HTMLElement | null>(null);
    const [isTextCutOff, onRefresh] = useTextCutOff(listNameRef);

    return (
        <Tooltip
            id={id}
            open={isTextCutOff}
            onOpen={() => onRefresh(true)}
            onClose={() => onRefresh(false)}
            title={name}
            arrow={false}
            placement="top"
            disableInteractive
        >
            <Typography
                ref={listNameRef}
                component="p"
                variant="small"
                weight="semibold"
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: 'inherit',
                }}
            >
                {name}
            </Typography>
        </Tooltip>
    );
};
