import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Excel = (props) => (
    <SvgIcon {...props}>
        <path d="M12 4L3 5.8V18.4L12 20.2V4ZM13.8 5.8V7.6H15.6V9.4H13.8V11.2H15.6V13H13.8V14.8H15.6V16.6H13.8V18.4H20.1C20.3387 18.4 20.5676 18.3052 20.7364 18.1364C20.9052 17.9676 21 17.7387 21 17.5V6.7C21 6.46131 20.9052 6.23239 20.7364 6.0636C20.5676 5.89482 20.3387 5.8 20.1 5.8H13.8ZM17.4 7.6H19.2V9.4H17.4V7.6ZM4.9584 8.7673H6.5577L7.3893 10.7608C7.4568 10.9228 7.5072 11.1118 7.5603 11.3269H7.5828C7.6125 11.1982 7.6728 11.0011 7.7637 10.7446L8.6907 8.7673H10.1487L8.4072 12.0703L10.2 15.4327H8.6439L7.6422 13.261C7.58392 13.1184 7.54371 12.969 7.5225 12.8164H7.509C7.47101 12.9726 7.42597 13.1269 7.374 13.279L6.3624 15.4336H4.8L6.654 12.0973L4.9584 8.7673ZM17.4 11.2H19.2V13H17.4V11.2ZM17.4 14.8H19.2V16.6H17.4V14.8Z" />
    </SvgIcon>
);

export default Excel;
