import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DynamicsAccountSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M16.0417 12.0833V1.66663H4.58333V3.74996H3.54167V6.87496H2.5V18.3333H17.0833V12.0833H16.0417ZM5.625 2.70829H15V12.0833H13.9583V3.74996H5.625V2.70829ZM4.58333 4.79163H12.9167V12.0833H12.6146L7.40625 6.87496H4.58333V4.79163ZM16.0417 17.2916H3.54167V7.91663H6.96875L12.1771 13.125H16.0417V17.2916Z" />
    </SvgIcon>
);
export default DynamicsAccountSvg;
