import { useRecoilValue } from 'recoil';

import { GenericAsyncProcess } from 'api/types/UserAsyncProcess';
import { filteredCrmFullExportProcesses, filteredLatestCrmImportProcess } from 'store/asyncProcessAtomSelectors';

import { TabEnum } from '../types';
import { CrmName } from '../types/Crm';

export const useConnectorTabStatus = (crm: CrmName): Record<'error', TabEnum[]> => {
    const latestExportJob = useRecoilValue(
        filteredCrmFullExportProcesses({
            crm,
            onlyLatest: true,
        }),
    ) as GenericAsyncProcess | undefined;

    const latestImportJob = useRecoilValue(
        filteredLatestCrmImportProcess({
            crm,
        }),
    ) as GenericAsyncProcess | undefined;

    function getErrorTabs() {
        const updateHasError =
            latestExportJob?.state === 'failure' && !(latestExportJob?.deleted || latestExportJob?.seen);
        const matchingHasError =
            latestImportJob?.state === 'failure' && !(latestImportJob?.deleted || latestImportJob?.seen);
        return [...(updateHasError ? ['sync'] : []), ...(matchingHasError ? ['matching'] : [])] as TabEnum[];
    }

    return {
        error: getErrorTabs(),
    };
};
