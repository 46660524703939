import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import axiosFactory, { AxiosInstance, RawAxiosRequestHeaders } from 'axios';

import { useEmbeddedQueryContext } from 'contexts/EmbeddedQueryContext';
import { Database } from 'contexts/ListsContext';
import {
    DomainDataResultAPIResponse,
    OrganizationResultAPIResponse,
    domainDataFields,
    organizationFields,
} from 'contexts/types/FilterCompanies';

import { FilterPayloadCompanies } from './filterCompanies';
import { addIdAndType } from './profileData';
import { Operation } from './types/FilterOperators';
import { DomainDataAPIResponse, ProfileData } from './types/Organization';

export type SimilarCompaniesAPIResponse = Pick<
    DomainDataAPIResponse,
    | 'name'
    | 'website'
    | 'description'
    | 'logo_url'
    | 'business_units'
    | 'vainu_custom_industries'
    | 'organization_size_indicators'
> & {
    similarity_score: number;
};

const axiosWithoutAuth = axiosFactory.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export function useSimilarCompaniesWithAxios(database: Database, query: Operation) {
    const { token } = useEmbeddedQueryContext();
    const axios = axiosWithoutAuth;
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    return useSimilarCompanies(axios, database, query, headers);
}

export type SimilarCompaniesPayload = Omit<FilterPayloadCompanies, 'use_filters_v2' | 'list' | 'order'> & {
    order: null;
    similar_domains_query?: Operation;
    similar_results_limit?: number;
    similar_results_offset?: number;
    similar_results_order: '-similarity';
};

export const getSimilarCompanies = async (
    axios: AxiosInstance,
    payload: Pick<SimilarCompaniesPayload, 'database'> & Partial<SimilarCompaniesPayload>,
    signal?: AbortSignal,
    headers?: RawAxiosRequestHeaders,
) => {
    const isDomainDb = payload.database === 'DOMAIN_DATA_BASIC';
    const requestPayload: SimilarCompaniesPayload = {
        fields: [...(isDomainDb ? domainDataFields : organizationFields), 'similarity_score', 'similarity_count'],
        limit: 1,
        offset: 0,
        order: null,
        similar_results_limit: 100,
        similar_results_offset: 0,
        similar_results_order: '-similarity',
        ...payload,
    };

    return axios
        .post<
            DomainDataResultAPIResponse[] | OrganizationResultAPIResponse[]
        >(isDomainDb ? '/api/v3/similar_domains/' : '/api/v3/similar_organizations/', requestPayload, { signal, headers })
        .then((r) => r.data.map(addIdAndType));
};

export const useSimilarCompanies = (
    axios: AxiosInstance,
    database: Database,
    query: Operation,
    headers?: RawAxiosRequestHeaders,
    filterQuery?: Operation,
    options?: Omit<UseQueryOptions<ProfileData[]>, 'queryKey' | 'queryFn'>,
) =>
    useQuery({
        ...options,
        queryKey: ['similar-companies', database, query],
        queryFn: () =>
            getSimilarCompanies(
                axios,
                {
                    database,
                    query,
                    similar_domains_query: filterQuery,
                },
                undefined,
                headers,
            ),
    });
