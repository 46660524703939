import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronBigRight: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M8.46495 20.485L16.95 12L8.46495 3.51498L7.04995 4.92898L14.122 12L7.04995 19.071L8.46495 20.485Z" />
    </SvgIcon>
);

export default ChevronBigRight;
