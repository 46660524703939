import React from 'react';

import { Box, Tooltip } from '@mui/material';

import { SignalOrganization } from 'api/types/signals/signals';
import CompanyLogo from 'components/templates/avatars/CompanyLogo';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

type RelatedCompaniesProps = {
    currentOrganizationBusinessId?: string;
    organizations?: SignalOrganization[] | null;
};

const RelatedCompanies: React.VFC<RelatedCompaniesProps> = ({ currentOrganizationBusinessId, organizations }) => {
    if (!organizations) {
        return null;
    }
    const filteredCompaniesWithoutCurrent = organizations.filter(
        (organization) => organization.business_id !== currentOrganizationBusinessId,
    );

    if (filteredCompaniesWithoutCurrent.length === 0) {
        return null;
    }

    const firstRelatedCompany = filteredCompaniesWithoutCurrent[0];
    const otherRelatedCompanies = filteredCompaniesWithoutCurrent.slice(1);

    return (
        <Box
            className="relatedCompanies"
            sx={{
                display: 'flex',
                alignItems: 'center',
                '&& > *': {
                    marginRight: 0.5,
                },
            }}
        >
            <Typography variant="tiny" weight="semibold" color="subtle">
                Also:
            </Typography>
            <CompanyLogo
                sx={{ '&&': { width: 16, height: 16 } }}
                logo={firstRelatedCompany.logo_url}
                company_name={firstRelatedCompany.name}
            />
            <Typography variant="tiny" weight="semibold">
                {firstRelatedCompany.name}
            </Typography>
            {!!otherRelatedCompanies.length && (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            {otherRelatedCompanies.map((prospect) => (
                                <Link key={prospect.business_id} onClick={() => null} sx={{ textDecoration: 'none' }}>
                                    <div>{prospect.name}</div>
                                </Link>
                            ))}
                        </div>
                    }
                >
                    <Typography variant="tiny" weight="semibold" sx={{ cursor: 'help', textDecoration: 'underline' }}>
                        + {otherRelatedCompanies.length}
                    </Typography>
                </Tooltip>
            )}
        </Box>
    );
};

export default RelatedCompanies;
