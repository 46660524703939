import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import NotificationsWrapper from 'components/beta/Platform/Header/NotificationsWrapper/NotificationsWrapper';
import PageBanner from 'components/templates/PageBanner';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { useNotification } from 'contexts/NotificationsContext';
import useAsyncProcess from 'hooks/useAsyncProcess';

import { MainPath } from '../Router/routePaths';
import AccountDropdown from './AccountDropdown';
import DatabaseNavigation from './DatabaseNavigation';
import NavSearch from './NavSearch/NavSearch';
import Template from './styled';

export type Module = Extract<MainPath, MainPath.Profiles | MainPath.Connectors | MainPath.Triggers | MainPath.Settings>;

const getCurrentModule = (pathname: string): Module | undefined => {
    switch (pathname.split('/')?.[1]) {
        case MainPath.Profiles:
        case MainPath.Search:
        case MainPath.Company:
        case MainPath.Organization:
        case MainPath.Domain:
            return MainPath.Profiles;
        case MainPath.Triggers:
            return MainPath.Triggers;
        case MainPath.Connectors:
            return MainPath.Connectors;
        case MainPath.Settings:
            return MainPath.Settings;
        case MainPath.Impersonate:
        default:
            return undefined;
    }
};

const Header = () => {
    const { pathname } = useLocation();
    const [{ asyncProcesses }] = useAsyncProcess();
    const { sitewideNotifications } = useNotification();

    const module = getCurrentModule(pathname);

    const hasUnseenNotifications: boolean = useMemo(
        () => [...asyncProcesses, ...sitewideNotifications].some(({ seen }) => !seen),
        [asyncProcesses, sitewideNotifications],
    );

    return (
        <Template.Header>
            {/* PageBanner based on path */}
            <Template.Banner>
                <PageBanner module={module} />
                {(module === MainPath.Profiles || module === MainPath.Triggers) && <DatabaseNavigation />}
                {module === MainPath.Profiles && <NavSearch />}
            </Template.Banner>
            <Template.AppBarButtons>
                <Button
                    variant="flat"
                    href="https://help.vainu.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<Icon type="HelpCircleOutline" />}
                />
                <NotificationsWrapper
                    anchorElement={
                        <Button
                            variant="flat"
                            startIcon={
                                <Icon
                                    type={
                                        hasUnseenNotifications
                                            ? 'NotificationOutlineBellWithDot'
                                            : 'NotificationOutline'
                                    }
                                />
                            }
                        />
                    }
                />
                <AccountDropdown />
            </Template.AppBarButtons>
        </Template.Header>
    );
};

export default Header;
