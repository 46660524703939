import { styled } from '@mui/material';

import Button, { ButtonProps } from 'components/tokens/Button';

export const ContactsUpgradeButton = styled((props) => (
    <Button variant="tertiary" size="small" {...props}>
        Get contact info
    </Button>
))<ButtonProps>(({ theme: { palette } }) => ({
    '&&': {
        color: palette.upgrade.text,
        backgroundColor: palette.upgrade.background,
        borderColor: palette.upgrade.border,
        '&:hover': {
            borderColor: palette.upgrade.border,
            backgroundColor: palette.upgrade.border,
        },
    },
}));
