import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DomainServer: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 12C1 6.48 5.47 2 10.99 2C16.1819 2 20.4483 5.94895 20.9505 11H18.9325C18.8874 10.6598 18.8215 10.326 18.74 10H15.36C15.4003 10.3325 15.4355 10.665 15.4606 11H13.4547C13.4259 10.6624 13.3855 10.3287 13.34 10H8.66C8.59133 10.496 8.5343 11.0036 8.51113 11.5184C8.2199 11.7381 7.96945 12.0125 7.76659 12.3116C7.27181 13.0412 7 13.9867 7 14.9722V16H4.08C4.78225 17.2143 5.78951 18.2199 7 18.919V20.0278C7 20.4398 7.04751 20.8448 7.13895 21.23C3.53245 19.7205 1 16.1549 1 12ZM14.97 8H17.92C16.96 6.35 15.43 5.07 13.59 4.44C14.19 5.55 14.65 6.75 14.97 8ZM12.91 8C12.48 6.57 11.83 5.24 11 4.04C10.17 5.24 9.52 6.57 9.09 8H12.91ZM3 12C3 12.69 3.1 13.36 3.26 14H6.64C6.56 13.34 6.5 12.68 6.5 12C6.5 11.32 6.56 10.66 6.64 10H3.26C3.1 10.64 3 11.31 3 12ZM4.08 8H7.03C7.35 6.75 7.81 5.55 8.41 4.44C6.57 5.07 5.04 6.34 4.08 8Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3333 13C10.5856 13 9.98089 13.4032 9.59623 13.908C9.21141 14.4131 9 15.0677 9 15.75V19.25C9 19.9323 9.21141 20.5869 9.59623 21.092C9.98089 21.5968 10.5856 22 11.3333 22H20.6667C21.4144 22 22.0191 21.5968 22.4038 21.092C22.7886 20.5869 23 19.9323 23 19.25V15.75C23 15.0677 22.7886 14.4131 22.4038 13.908C22.0191 13.4032 21.4144 13 20.6667 13H11.3333ZM11 15.75C11 15.4698 11.0886 15.2494 11.1871 15.1201C11.2351 15.0571 11.2776 15.0255 11.3026 15.0115C11.3148 15.0047 11.3228 15.0021 11.3265 15.0011C11.33 15.0001 11.3319 15 11.3333 15H20.6667C20.6681 15 20.67 15.0001 20.6735 15.0011C20.6772 15.0021 20.6852 15.0047 20.6974 15.0115C20.7224 15.0255 20.7649 15.0571 20.8129 15.1201C20.9114 15.2494 21 15.4698 21 15.75V19.25C21 19.5302 20.9114 19.7506 20.8129 19.8799C20.7649 19.9429 20.7224 19.9745 20.6974 19.9885C20.6852 19.9953 20.6772 19.9979 20.6735 19.9989C20.6716 19.9995 20.6702 19.9997 20.6691 19.9999C20.6681 20 20.6673 20 20.6667 20H11.3333C11.3319 20 11.33 19.9999 11.3265 19.9989C11.3228 19.9979 11.3148 19.9953 11.3026 19.9885C11.2776 19.9745 11.2351 19.9429 11.1871 19.8799C11.0886 19.7506 11 19.5302 11 19.25V15.75ZM14 18.5C13.18 18.5 12.5 17.83 12.5 17C12.5 16.17 13.18 15.5 14 15.5C14.82 15.5 15.5 16.17 15.5 17C15.5 17.83 14.83 18.5 14 18.5Z"
        />
    </SvgIcon>
);

export default DomainServer;
