import BarTile from 'components/features/CRMInsights/Tiles/BarTile';
import { DEFAULT_DATA } from 'components/features/CRMInsights/utils';

import { TileProps } from '../NewVehicles';

const AgeTile: React.FC<TileProps> = ({ data, valueRange, valueField }) => {
    const ageData = data?.find((row) => row.id === 'age');

    const highest = ageData?.result[0].buckets.reduce(
        (prev, next, idx) =>
            (next[valueField] as number) > prev.value ? { value: next[valueField] as number, idx } : prev,
        { value: 0, idx: 0 },
    );

    return (
        <BarTile
            title="Age (years)"
            data={
                ageData?.result[0].buckets
                    .filter((bucket) => bucket[valueField])
                    .map((bucket) => ({
                        value: bucket[valueField],
                        label: bucket.label as string,
                        valueLabel: valueRange ? `${bucket.count_min}-${bucket.count_max}` : bucket[valueField],
                    })) || DEFAULT_DATA
            }
            total={highest?.value || 0}
            sx={{ minWidth: 288, maxWidth: 'max(600px, 50%)' }}
            firstColumn={{ label: 'Age', width: 40 }}
            lastColumn={{ label: 'Vehicles' }}
            hidePercent
            highlightIndex={highest?.idx || 0}
            percentHeaderLabel=""
        />
    );
};

export default AgeTile;
