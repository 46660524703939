import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HubspotTaskSvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M15.6587 15.9153C13.6424 15.9153 11.6871 15.9153 9.70134 15.9153C8.29602 15.9153 6.86014 15.8848 5.45481 15.8239C4.47719 15.7935 3.74398 15.2152 3.28572 14.3934C2.33865 12.6889 1.75819 10.8931 1.72764 8.94511C1.69709 7.48411 1.69709 6.02312 1.69709 4.56213C1.69709 3.37507 2.46085 2.55327 3.68287 2.52283C7.5017 2.49239 11.3205 2.49239 15.1393 2.52283C16.3003 2.52283 17.0335 3.34464 17.064 4.50126C17.0946 6.05356 17.0029 7.6363 17.1862 9.18861C17.3084 10.4061 17.675 11.654 18.0722 12.8106C18.5305 14.1499 18.4388 14.6673 17.3695 15.5804C17.2168 15.7326 17.0946 16.0066 17.064 16.2196C16.9113 17.6806 16.3003 18.2894 14.8338 18.3198C13.398 18.3502 11.9621 18.3198 10.4957 18.3198C8.32656 18.3198 6.15747 18.3198 3.95783 18.3198C2.79691 18.3198 2.06369 17.8632 1.78874 17.011C1.63599 16.524 1.51378 15.9457 2.15535 15.7326C2.73581 15.55 2.88856 15.9761 3.10241 16.4327C3.22462 16.6762 3.74398 16.8893 4.08003 16.8893C7.22674 16.9197 10.3429 16.9197 13.4896 16.9197C13.9479 16.9197 14.4061 16.9197 14.8644 16.9197C15.5365 16.9806 15.842 16.7066 15.6587 15.9153ZM3.10241 7.54499C2.98021 9.82779 3.40792 11.928 4.56884 13.8151C4.81325 14.1803 5.42426 14.4847 5.88252 14.5151C9.33474 14.576 12.7564 14.5456 16.2086 14.5456C17.0029 14.5456 17.1251 14.2412 16.8502 13.4803C16.5141 12.5672 16.1781 11.6236 15.9948 10.68C15.7809 9.64517 15.7504 8.6103 15.6587 7.54499C11.4733 7.54499 7.34895 7.54499 3.10241 7.54499Z" />
    </SvgIcon>
);
export default HubspotTaskSvg;
