import { ReactNode, useMemo, forwardRef, memo, RefObject } from 'react';

import { useAutocomplete } from '@mui/base';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { filledInputClasses, inputBaseClasses, inputClasses, outlinedInputClasses } from '@mui/material';
import { autocompleteClasses, AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled, alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';

import { SelectOption } from 'components/tokens/select-components/Select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AutocompletePopupIndicatorProps = any;

type SetInputRefFunc = (ref: RefObject<HTMLInputElement>) => void;

type UseAutocompleteReturnValue = Omit<ReturnType<typeof useAutocomplete>, 'setAnchorEl'> & {
    setAnchorEl: SetInputRefFunc;
};

type IndustryPickerProps = {
    value: SelectOption[];
    options: SelectOption[];
    limitTags?: number;
    isExpanded: boolean;
    renderTags: (value: SelectOption[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode[];
    getLimitTagsText?: (more: number) => ReactNode;
    onChange: (event: React.SyntheticEvent, value: SelectOption[], reason: string) => void;
    disabled?: boolean;
    width?: string | number;
    placeholder?: string;
    getOptionDisabled?: (option: SelectOption) => boolean;
    setInputRef?: SetInputRefFunc;
};

export const IndustryPicker = memo(
    forwardRef<HTMLDivElement, IndustryPickerProps>(
        (
            {
                options,
                limitTags = 10,
                isExpanded,
                getLimitTagsText,
                renderTags,
                disabled = false,
                width = '200px',
                onChange,
                placeholder,
                value,
                getOptionDisabled,
                setInputRef,
            },
            ref,
        ) => {
            const {
                getRootProps,
                getInputProps,
                getTagProps,
                getListboxProps,
                getOptionProps,
                groupedOptions,
                focused,
                setAnchorEl,
                getInputLabelProps,
                popupOpen,
                anchorEl,
                focusedTag,
                id,
                getPopupIndicatorProps,
            } = useAutocomplete({
                id: 'customized-hook-demo',
                multiple: true,
                options: options,
                onChange,
                value,
                getOptionDisabled,
            }) as UseAutocompleteReturnValue;

            let hideTags = false;

            if (!focused && !isExpanded) {
                hideTags = value.length > limitTags;
            }

            const visibleValue = useMemo(() => {
                if (hideTags) {
                    return value.slice(0, limitTags);
                }

                return value ?? [];
            }, [hideTags, limitTags, value]);

            const getCustomizedTagProps: AutocompleteRenderGetTagProps = (params) => ({
                className: 'tag',
                disabled,
                ...getTagProps(params),
            });

            const startAdornment = renderTags(visibleValue, getCustomizedTagProps) ?? [];

            if (limitTags > -1) {
                const more = value?.length - limitTags;

                if (!focused && more > 0) {
                    startAdornment.push(
                        <span className="MuiAutocomplete-tag" key={startAdornment.length}>
                            {getLimitTagsText ? getLimitTagsText(more) : `+${more} |`}
                        </span>,
                    );
                }
            }

            const renderListOption = (option: SelectOption, index: number) => {
                const optionProps = getOptionProps({ option, index });

                return (
                    <li {...optionProps} className="MuiAutocomplete-option MuiAutocomplete-selected">
                        {option.label}
                    </li>
                );
            };

            const closeText = 'Close';
            const openText = 'Open';

            return (
                <div ref={ref} id="industry-picker">
                    <AutocompleteRoot
                        className={clsx({ 'MuiAutocomplete-focused': focusedTag === -1 })}
                        inputFocused={focusedTag === -1}
                        {...getRootProps()}
                    >
                        <TextField
                            InputLabelProps={{ ...getInputLabelProps(), variant: 'standard' }}
                            id={id}
                            disabled={disabled}
                            placeholder={placeholder}
                            sx={{
                                width,
                                backgroundColor: 'background.paper',
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                },
                                '.MuiOutlinedInput-root': { paddingRight: `9px!important` },
                                ...(disabled && {
                                    fieldset: { borderColor: '#e1e1e1!important' },
                                }),
                            }}
                            InputProps={{
                                ref: (ref: RefObject<HTMLInputElement>) => {
                                    setAnchorEl(ref);
                                    setInputRef && setInputRef(ref);
                                },
                                className: 'MuiAutocomplete-inputRoot',
                                size: 'small',
                                startAdornment,
                                ...{
                                    endAdornment: (
                                        <AutocompleteEndAdornment className="MuiAutocomplete-endAdornment">
                                            <AutocompletePopupIndicator
                                                {...(getPopupIndicatorProps() as AutocompletePopupIndicatorProps)}
                                                disabled={disabled}
                                                aria-label={popupOpen ? closeText : openText}
                                                title={popupOpen ? closeText : openText}
                                                className={clsx(
                                                    'MuiIconButton-sizeMedium MuiAutocomplete-popupIndicator',
                                                    { 'MuiButtonBase-popupIndicatorOpen': popupOpen },
                                                )}
                                                color="default"
                                                size="small"
                                                popupOpen={popupOpen}
                                            >
                                                <ArrowDropDownIcon />
                                            </AutocompletePopupIndicator>
                                        </AutocompleteEndAdornment>
                                    ),
                                },
                            }}
                            inputProps={{
                                className: clsx('MuiAutocomplete-input', {
                                    'MuiAutocomplete-inputFocused': focusedTag === -1,
                                }),
                                disabled,
                                ...getInputProps(),
                            }}
                        />
                    </AutocompleteRoot>
                    <AutocompletePopper
                        disablePortal={true}
                        style={{
                            width: anchorEl ? anchorEl.clientWidth : undefined,
                        }}
                        role="presentation"
                        anchorEl={anchorEl}
                        open={popupOpen}
                        className="MuiAutocomplete-popper"
                    >
                        <AutocompletePaper className="MuiAutocomplete-paper">
                            {groupedOptions.length === 0 ? (
                                <AutocompleteNoOptions
                                    className="MuiAutocomplete-noOptions"
                                    role="presentation"
                                    onMouseDown={(event) => {
                                        // Prevent input blur when interacting with the "no options" content
                                        event.preventDefault();
                                    }}
                                >
                                    No options
                                </AutocompleteNoOptions>
                            ) : null}
                            {groupedOptions.length > 0 ? (
                                <AutocompleteListbox className="MuiAutocomplete-listbox" {...getListboxProps()}>
                                    {groupedOptions.map((option, index) => {
                                        return renderListOption(option as SelectOption, index);
                                    })}
                                </AutocompleteListbox>
                            ) : null}
                        </AutocompletePaper>
                    </AutocompletePopper>
                </div>
            );
        },
    ),
);

IndustryPicker.displayName = 'IndustryPicker';

// Styles copied from material

const AutocompletePopper = styled(Popper)`
    z-index: ${({ theme }: { theme?: { zIndex: { modal: number } } }) => theme?.zIndex.modal};
    position: absolute;
`;

const AutocompletePaper = styled(Paper)`
    overflow: 'auto';
`;

const AutocompleteNoOptions = styled('div')(({ theme }) => {
    return {
        color: theme.palette.text.secondary,
        padding: '14px 16px',
    };
});

const AutocompletePopupIndicator = styled(IconButton, {
    name: 'MuiAutocomplete',
    slot: 'PopupIndicator',
    shouldForwardProp: (prop) => prop !== 'popupOpen',
})<{
    popupOpen: boolean;
}>`
    padding: 2px;
    margin-right: -2px;
    padding: 2px;
    margin-right: -2px;

    ${(props) => props.popupOpen && `transform: rotate(180deg)`};
`;

const AutocompleteEndAdornment = styled('div')`
    position: absolute;
    right: 0;
    top: calc(50% - 14px);
`;

const AutocompleteListbox = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    margin: 0,
    padding: '8px 0',
    maxHeight: '40vh',
    overflow: 'auto',
    [`& .${autocompleteClasses.option}`]: {
        minHeight: 48,
        display: 'flex',
        overflow: 'hidden',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
        paddingTop: 6,
        boxSizing: 'border-box',
        outline: '0',
        WebkitTapHighlightColor: 'transparent',
        paddingBottom: 6,
        paddingLeft: 16,
        paddingRight: 16,
        [theme.breakpoints.up('sm')]: {
            minHeight: 'auto',
        },
        [`&.${autocompleteClasses.focused}`]: {
            backgroundColor: theme.palette.action.hover,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&[aria-disabled="true"]': {
            opacity: theme.palette.action.disabledOpacity,
            pointerEvents: 'none',
        },
        [`&.${autocompleteClasses.focusVisible}`]: {
            backgroundColor: theme.palette.action.focus,
        },
        '&[aria-selected="true"]': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            [`&.${autocompleteClasses.focused}`]: {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: theme.palette.action.selected,
                },
            },
            [`&.${autocompleteClasses.focusVisible}`]: {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity,
                ),
            },
        },
    },
}));

const AutocompleteRoot = styled('div', { shouldForwardProp: (prop) => prop !== 'inputFocused' })<{
    inputFocused: boolean;
}>(({ inputFocused }) => ({
    [`& .${autocompleteClasses.tag}`]: {
        margin: 2,
        maxWidth: 'calc(100% - 4px)',
    },
    [`& .${autocompleteClasses.inputRoot}`]: {
        flexWrap: 'wrap',

        [`& .${autocompleteClasses.input}`]: {
            width: 0,
            minWidth: 30,
        },
    },
    [`& .${inputClasses.root}`]: {
        paddingBottom: 1,
        '& .MuiInput-input': {
            padding: '4px 4px 4px 0px',
        },
    },
    [`& .${inputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
        [`& .${inputClasses.input}`]: {
            padding: '2px 4px 3px 0',
        },
    },
    [`& .${outlinedInputClasses.root}`]: {
        padding: 9,
        [`& .${autocompleteClasses.input}`]: {
            padding: '7.5px 4px 7.5px 6px',
        },
        [`& .${autocompleteClasses.endAdornment}`]: {
            right: 9,
        },
    },
    [`& .${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
        padding: 6,
        [`& .${autocompleteClasses.input}`]: {
            padding: '2.5px 4px 2.5px 6px',
        },
    },
    [`& .${filledInputClasses.root}`]: {
        paddingTop: 19,
        paddingLeft: 8,
        [`& .${filledInputClasses.input}`]: {
            padding: '7px 4px',
        },
        [`& .${autocompleteClasses.endAdornment}`]: {
            right: 9,
        },
    },
    [`& .${filledInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
        paddingBottom: 1,
        [`& .${filledInputClasses.input}`]: {
            padding: '2.5px 4px',
        },
    },
    [`& .${inputBaseClasses.hiddenLabel}`]: {
        paddingTop: 8,
    },
    [`& .${autocompleteClasses.input}`]: {
        flexGrow: 1,
        textOverflow: 'ellipsis',
        opacity: 0,
        ...(inputFocused && {
            opacity: 1,
        }),
    },
}));

declare module '@mui/material/Autocomplete' {
    export interface AutocompleteClasses {
        focusVisible: string;
    }
}
