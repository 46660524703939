import React, { useState, useContext } from 'react';

import { Grid, Typography, styled } from '@mui/material';

import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';

import PersonIcon from '../svg/SettingViewOnlyPerson';

const SettingsViewOnlyFeedback: React.FC<{}> = () => {
    const permissionContext = useContext(PermissionContext);
    const { isAccountAdmin = false, accountAdmins } = permissionContext;

    /* accountAdmins: admins.map((each: any) => each.email), */

    if (isAccountAdmin) {
        return null;
    }
    return (
        <StyledGrid container spacing={0}>
            <Grid item xs={1}>
                <StyledPersonIcon />
            </Grid>
            <Grid item xs={11}>
                <Title>You only have permission to view</Title>
                <Text>
                    <CollasibleAdmins
                        prefixText="To make changes, contact: "
                        emailSeparator="; "
                        accountAdmins={accountAdmins}
                        collapseContent="Show less"
                    />
                </Text>
            </Grid>
        </StyledGrid>
    );
};

const StyledGrid = styled(Grid)({
    background: 'rgba(47, 128, 237, 0.1)',
    borderRadius: 8,
    padding: 24,
    width: 'auto',
});

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: '20px',
}));

const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.subtle,
    fontSize: 16,
    fontWeight: 'normal',
}));

const StyledPersonIcon = styled(PersonIcon)({
    marginLeft: '10px',
    marginTop: '8px',
});

export default SettingsViewOnlyFeedback;

type CollasibleAdminsProps = {
    prefixText?: React.ReactNode;
    expandContent?: React.ReactNode;
    collapseContent?: React.ReactNode;
    emailSeparator: React.ReactNode;
    accountAdmins: string[];
};
export const CollasibleAdmins: React.FC<CollasibleAdminsProps> = ({
    prefixText,
    accountAdmins = [],
    emailSeparator = '',
    expandContent,
    collapseContent,
}) => {
    const [showAllAdmins, setShowAllAdmins] = useState(false);

    const [firstAdminEmail] = accountAdmins;
    const adminCount = accountAdmins.length;
    return (
        <>
            {prefixText && <span>{prefixText}</span>}
            {!showAllAdmins && (
                <>
                    <a href={`mailto: ${firstAdminEmail}`} style={{ margin: 0 }}>
                        {firstAdminEmail}
                        {adminCount > 1 && !showAllAdmins && '...'}
                    </a>
                    <div onClick={() => setShowAllAdmins(true)} style={{ display: 'inline-block' }}>
                        <PlusMore>
                            {adminCount > 1 ? (
                                <span>
                                    {expandContent ? (
                                        <>
                                            {expandContent} <b>({adminCount - 1})</b>
                                        </>
                                    ) : (
                                        `+${adminCount - 1}`
                                    )}
                                </span>
                            ) : null}
                        </PlusMore>
                    </div>
                </>
            )}
            {showAllAdmins && (
                <>
                    <MaxEmailWrapper>
                        {accountAdmins.map((email, i) => {
                            return (
                                <React.Fragment key={email}>
                                    <a href={`mailto: ${email}`} style={{ margin: 0 }}>
                                        {email}
                                    </a>
                                    <span>{i + 1 === adminCount ? '' : `${emailSeparator} `}</span>
                                </React.Fragment>
                            );
                        })}
                    </MaxEmailWrapper>
                    <div onClick={() => setShowAllAdmins(false)} style={{ display: 'inline-block' }}>
                        <ShowLess>{collapseContent || 'Show less'}</ShowLess>
                    </div>
                </>
            )}
        </>
    );
};

const PlusMore = styled('span')({
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: '4px',
});

const ShowLess = styled('span')({
    cursor: 'pointer',
    textDecoration: 'underline',
});

const MaxEmailWrapper = styled('div')({
    maxHeight: 100,
    overflow: 'scroll',
});
