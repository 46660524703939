import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Download = (props) => (
    <SvgIcon {...props}>
        <path
            transform="translate(3.75, 1.5)"
            d="M14 20H0V18H14V20ZM7 16L1 10L2.41 8.59L6 12.17V0H8V12.17L11.59 8.59L13 10L7 16Z"
        />
    </SvgIcon>
);

export default Download;
