import { useEffect, useState } from 'react';

import { Box, Tooltip } from '@mui/material';
import { getErrorMessage } from 'utilities';

import { Icon } from 'components/tokens/Icon';
import { Typography } from 'components/tokens/Typography';
import { useSnackbarContext } from 'contexts/SnackbarContext';

import Button from '../Button';

export type BannerDetailsProps = {
    details?: string | object;
    detailsStyle?: 'text' | 'code';
};

const copyNotificationLifetime = 700; // ms

export const BannerDetails: React.FC<BannerDetailsProps> = ({ details, detailsStyle = 'text' }) => {
    const [detailsOpen, setDetailsOpen] = useState(true);
    const [showCopied, setShowCopied] = useState(false);

    const { showSnackbar } = useSnackbarContext();

    useEffect(() => {
        if (showCopied) {
            const handle = setTimeout(() => setShowCopied(false), copyNotificationLifetime);
            return () => clearTimeout(handle);
        }
    }, [showCopied]);

    const detailsContent = typeof details === 'string' ? details : JSON.stringify(details, undefined, '  ');

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(detailsContent);
            setShowCopied(true);
        } catch (error) {
            showSnackbar(`Cannot copy the message to clipboard: ${getErrorMessage(error)}`, 'error');
        }
    };

    return (
        <div style={{ width: '100%', marginBottom: '8px' }}>
            <Button
                variant="flat"
                size="small"
                startIcon={<Icon type={detailsOpen ? 'ChevronUp' : 'ChevronDown'} />}
                onClick={() => setDetailsOpen(!detailsOpen)}
            >
                {detailsOpen ? 'Hide details' : 'Show details'}
            </Button>
            {detailsOpen && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        backgroundColor: 'background.paper',
                        paddingY: 1,
                        paddingX: 1.25,
                        borderRadius: 1,
                        marginBottom: 0.5,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, minHeight: 32 }}>
                        <Typography
                            variant="tiny"
                            component="div"
                            sx={{
                                fontFamily: detailsStyle === 'code' ? 'monospace' : undefined,
                                whiteSpace: detailsStyle === 'code' ? 'pre' : undefined,
                            }}
                        >
                            {detailsContent}
                        </Typography>
                    </Box>
                    <Tooltip title="Copied!" open={showCopied}>
                        <Button
                            variant="flat"
                            size="small"
                            startIcon={<Icon type="Copy" sx={{ fontSize: 16 }} />}
                            onClick={handleCopyClick}
                        />
                    </Tooltip>
                </Box>
            )}
        </div>
    );
};

export default BannerDetails;
