import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Icon from 'components/tokens/Icon';
import { StyledMenuItem } from 'components/tokens/select-components/NestedSelect/styled';
import Switch from 'components/tokens/Switch';
import Typography from 'components/tokens/Typography';

import { FilterConfig, FilterDefinition, FilterID, FilterProps, GroupValues, NodeValue } from '../../FilterTypes';
import { getValue, makeFieldType } from '../utils';

const FIELD = makeFieldType(FilterID.contact_is_decision_maker);

export type TValues = { [FIELD]: boolean };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.contact_is_decision_maker,
    label: 'Decision maker',
    defaultOperator: FilterOperator.EQ,
    initValue: {
        [FilterOperator.EQ]: {
            [FIELD]: false,
        },
    },
};

export const contactIsDecisionMakerFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.contacts,
        label: config.label,
        fields: config.fields,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <ContactIsDecisionMaker {...props} />,
        valueToText: (node: NodeValue) => {
            const value = getValue(config.fields[0], node);
            return value ? 'Decision maker' : null;
        },
        config,
        createFilter: () => config.initValue,
    },
};

export const ContactIsDecisionMaker: React.FC<FilterProps<TValues>> = ({ filter, uuid }) => {
    const { control } = useFormContext();

    const fullValuePath = `${filter.groupPath}.${filter.path}.${filter.valuePath}`;

    return (
        <Controller
            name={fullValuePath}
            control={control}
            key={`${uuid}-input-value`}
            render={({ field }) => {
                const { onChange, value } = field;

                return (
                    <StyledMenuItem
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        onClick={() => onChange(!value)}
                    >
                        <Typography variant="body2" sx={{ flex: 1 }}>
                            Decision maker
                        </Typography>
                        <Icon type="Award" color="brandColors.tertiaryBlue" fontSize="small" />
                        <Switch
                            checked={value}
                            sx={{
                                width: 40,
                                height: 24,
                                '& .MuiSwitch-thumb': {
                                    width: 20,
                                    height: 20,
                                },
                            }}
                        />
                    </StyledMenuItem>
                );
            }}
        />
    );
};
