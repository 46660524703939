import { FC, memo } from 'react';

import { SxProps } from '@mui/material';

import Button from 'components/tokens/Button';
import { useContactCountByCompany } from 'contexts/FilterCompaniesContext/utils';
import { CountryDatabase } from 'contexts/types/databases';
import { useOpen } from 'hooks/useOpen';

import Icon from '../../../tokens/Icon/Icon';
import { ContactsDialog } from './ContactsDialog';

interface IconIndicatorProps {
    businessId: string;
    companyName?: string;
    className?: string;
    color?: string;
    logo?: string;
    country: CountryDatabase;
    sx?: SxProps;
}

export const ContactsCount: FC<IconIndicatorProps> = memo(
    ({ businessId, color, logo, country, companyName, className, sx = {} }) => {
        const { count } = useContactCountByCompany(businessId);

        const { open: openDialog, close: closeDialog, isOpen } = useOpen();

        if (!count) {
            return null;
        }

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Button
                    variant="flat"
                    size="small"
                    startIcon={<Icon type="UserCircle" color={color} fontSize="mini" />}
                    className={className}
                    onClick={(e) => {
                        openDialog();
                        e.stopPropagation();
                    }}
                    sx={{
                        color: color,
                        ...sx,
                    }}
                >
                    {count}
                </Button>
                <ContactsDialog
                    open={isOpen}
                    onClose={closeDialog}
                    businessId={businessId}
                    logo={logo}
                    companyName={companyName ?? businessId}
                    country={country}
                />
            </div>
        );
    },
);

ContactsCount.displayName = 'ContactsCount';
