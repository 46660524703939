import React from 'react';

import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { Button } from 'components/tokens/Button';
import { Typography } from 'components/tokens/Typography';

import { ReactComponent as ApiPromoImage } from '../../svg/api_promo.svg';
import { LandingTemplate } from '../template/Template';
import { LandingContent } from '../template/TemplateContent';
import { LandingHeader } from '../template/TemplateHeader';

const APILandingPage: React.FC = () => {
    return (
        <LandingTemplate isAPILanding>
            <LandingHeader
                title="Vainu’s API and Developer Hub"
                subtitle="Create custom solutions with our high-performance and easy-to-use APIs and get access to company data across your business systems"
                cta={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Button component="a" target="_blank" href="https://developers.vainu.com">
                            GO TO DEVELOPER HUB
                        </Button>
                        <Link target="_blank" to="/settings/api-access">
                            <Button variant="secondary">GO TO API ACCESS</Button>
                        </Link>
                    </Box>
                }
            />
            <LandingContent title="Vainu’s APIs">
                <APIDescriptionGrid>
                    <ApiPromoImage style={{ justifySelf: 'center' }} />
                    <TextContainer>
                        <Typography variant="h2" fontSize={18}>
                            The Data API
                        </Typography>
                        <ul>
                            <Text>
                                Use advanced filtering parameters to pinpoint and sort the companies you're looking for
                            </Text>
                            <Text>Access 300+ data fields for 70M+ companies</Text>
                            <Text>Get your hands on new datasets</Text>
                        </ul>
                        <Typography variant="h2" fontSize={18}>
                            The Integration API
                        </Typography>
                        <ul>
                            <Text>
                                Stay up to date by bringing the latest company data to platforms such as PowerBI,
                                Tableau, QuickSight, and Qlik
                            </Text>
                            <Text>
                                Display the data your teams need in any system with a custom solution or our ready-made
                                iframe solution, Vainu View
                            </Text>
                            <Text>
                                Keep track of the latest happenings in your target accounts using our Triggers endpoint
                                or webhooks
                            </Text>
                        </ul>
                    </TextContainer>
                </APIDescriptionGrid>
            </LandingContent>
        </LandingTemplate>
    );
};

const Text: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography component="li" fontSize="14px">
        {children}
    </Typography>
);

const APIDescriptionGrid = styled('div')`
    display: grid;
    grid-template: auto / 1fr 1fr;
    align-items: center;
    gap: 20px;
`;

const TextContainer = styled('div')`
    margin: 0 40px;
    ul {
        margin: 8px 0 32px 32px;
    }
`;

export { APILandingPage };
