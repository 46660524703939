import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { APIError, useAxiosContext } from 'contexts/AxiosContext';

import { organizationProfileType, OrganizationProfileType } from './types/Organization';
import { Signal, SignalAPIResponse, SignalType } from './types/signals';

export type SignalsParams = {
    signalType: SignalType;
    type?: OrganizationProfileType; // This allows only one value, 'organization', now, but is used for consistency and later extendability.
    key?: string | undefined;
    tags?: number[];
    beforeDate?: string;
    afterDate?: string;
    pageSize?: number;
};

export type SignalsQueryOperation = {
    '?IN'?: {
        business_ids?: string[];
        tags?: number[];
        prospects?: number[]; // a list of vids; not used, only documented the support here
    };
    '?LTE'?: {
        vainu_date: string;
    };
    '?GTE'?: {
        vainu_date: string;
    };
};

export type SignalsQuery = {
    '?ALL': SignalsQueryOperation[];
};

type GetSignalsParams = { signalType: SignalType; query: SignalsQuery; limit?: number; offset?: number };

export const getSignals = async (axios: AxiosInstance, { signalType, query, limit, offset }: GetSignalsParams) =>
    (
        await axios.get<SignalAPIResponse[]>(`/api/v3/signals/${signalType}/`, {
            params: { query: JSON.stringify(query), limit, offset },
        })
    ).data;

/**
 * Transforms the API response and injects the requested signal type.
 */
const addType = (data: SignalAPIResponse[], signalType: SignalType): Signal[] =>
    data.map((signal) => ({
        ...signal,
        signalType,
    }));

export function useSignals(params: SignalsParams) {
    const { signalType, key, type, tags, beforeDate, afterDate, pageSize = 20 } = params;
    const axios = useAxiosContext();

    const query: SignalsQuery = {
        '?ALL': [],
    };
    if (key && type === organizationProfileType) {
        query['?ALL'].push({
            '?IN': {
                business_ids: [key],
            },
        });
    }
    if (tags) {
        query['?ALL'].push({
            '?IN': {
                tags,
            },
        });
    }
    if (beforeDate) {
        query['?ALL'].push({
            '?LTE': {
                vainu_date: beforeDate,
            },
        });
    }
    if (afterDate) {
        query['?ALL'].push({
            '?GTE': {
                vainu_date: afterDate,
            },
        });
    }

    const requestParams: GetSignalsParams = {
        signalType,
        query,
    };
    return useInfiniteQuery<SignalAPIResponse[], APIError, Signal[], (string | SignalsParams)[], number>({
        queryKey: ['signals', params],
        queryFn: ({ pageParam }) =>
            getSignals(axios, { ...requestParams, limit: pageSize, offset: pageParam * pageSize }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage?.length < pageSize) {
                return undefined;
            }
            return allPages.length;
        },
        retry: 1,
        select: (data) => data.pages.map((page) => addType(page, params.signalType)).flat(),
    });
}
