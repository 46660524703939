import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SuperOfficeMono = (props) => (
    <SvgIcon {...props}>
        <path d="M2.58383 4.44577C2.1011 4.30055 1.61378 4.15395 1.13381 4C1.10035 4.03345 1.05575 4.07805 1 4.10036C1.12207 4.27964 1.24153 4.46285 1.3615 4.64684C1.59222 5.00068 1.82485 5.35744 2.08161 5.6949C3.27472 7.24483 4.80235 8.10343 6.80945 8.00308C7.60114 7.96962 8.39284 8.02538 9.18453 8.10343C10.2773 8.22609 11.0578 8.82822 11.5038 9.84293C12.1283 11.2479 12.0725 12.6529 11.4369 14.0356C11.3364 14.2533 11.2045 14.4522 11.06 14.67C11.0121 14.7422 10.9628 14.8164 10.9129 14.8942C10.8571 14.7827 10.846 14.7604 10.846 14.7492C10.8571 14.7046 10.8711 14.66 10.885 14.6154C10.8989 14.5708 10.9129 14.5262 10.924 14.4816C11.2474 13.3777 11.1805 12.3184 10.4557 11.3706C10.032 10.813 9.47444 10.5566 8.74965 10.7684C8.77195 11.1922 8.79425 11.6047 8.82771 12.1623C8.57736 11.9477 8.37749 11.7698 8.19572 11.608C8.09411 11.5176 7.99816 11.4322 7.90221 11.3483C7.15512 12.0619 7.03246 12.7309 7.30008 14.9834C6.39688 13.9464 6.16272 12.7978 6.26307 11.5601C6.36343 10.3113 6.8875 9.2408 7.98026 8.3822H6.06236C4.74659 10.0213 4.61279 12.9317 5.7613 14.8496C7.30008 17.4365 10.3107 17.5146 11.9387 14.9945C12.8531 13.5784 13.0872 12.0062 12.9534 10.3559C12.8419 9.02894 12.4516 7.80237 11.5261 6.80996C10.3665 5.57224 8.89461 5.02586 7.24432 5.02586C5.7836 5.02586 4.33402 4.9701 2.9179 4.54638C2.80689 4.51287 2.69548 4.47935 2.58383 4.44577Z" />
        <path d="M23 4.52406C22.331 6.8434 20.6918 8.00307 18.3168 7.99192C16.1536 7.98076 14.7374 9.79832 15.2615 11.9839C15.4399 11.5936 15.6072 11.2368 15.7967 10.8353C16.0755 11.0695 16.3097 11.2702 16.633 11.549C16.6172 11.3119 16.6014 11.1022 16.5868 10.9081C16.5705 10.6916 16.5556 10.4941 16.5438 10.3001C17.1794 9.87638 17.893 9.99904 18.406 10.6458C19.0973 11.4932 19.1308 12.4745 18.8966 13.5003C18.8738 13.6067 18.8406 13.7131 18.8042 13.8301C18.7872 13.8847 18.7694 13.9417 18.7516 14.0021C20.1009 13.1658 20.1343 10.5677 18.852 8.48255L19.0913 8.45416C19.3826 8.41945 19.6615 8.38622 19.9336 8.35989C21.6731 10.367 21.1713 14.6489 18.4617 15.5967C18.0994 15.7277 17.6998 15.7522 17.2961 15.7769C17.1158 15.7879 16.9347 15.799 16.7557 15.8197C16.5015 14.1011 15.4311 13.1151 14.3481 12.1176C14.2436 12.0213 14.139 11.9249 14.035 11.8277C13.9792 10.4674 14.1465 9.12928 14.6817 7.86926C15.3619 6.27472 16.7111 5.61683 18.2945 5.52762L18.4883 5.51417C20.0156 5.40857 21.5129 5.30505 23 4.52406Z" />
        <path d="M14.4699 20.6703C13.9831 19.9772 13.5039 19.2916 13.0274 18.61C12.4997 17.8551 11.9754 17.1051 11.4481 16.355C12.6412 15.1619 13.2656 13.7681 13.5221 12.0955C13.6438 12.1919 13.761 12.2813 13.8746 12.3681C14.0106 12.472 14.1416 12.572 14.2692 12.6753C14.3585 12.7519 14.4405 12.8358 14.5215 12.9186C14.582 12.9805 14.642 13.0418 14.704 13.099C16.2651 14.4817 16.6219 16.1431 15.8971 18.0834C15.5515 18.9866 15.1612 19.8563 14.4699 20.6703Z" />
    </SvgIcon>
);

export default SuperOfficeMono;
