import { FC } from 'react';

import { useRangePicker } from '../../hooks/useRangePicker';
import { StyledGrid, StyledSlider } from '../../styled';
import { Options, Values } from '../../types';
import { FieldGroup } from '../FieldGroup';

export interface RangePickerProps {
    value: Values;
    onRangeChange: (newValue: Values) => void;
    label?: string;
    min?: number;
    max?: number;
    step?: number;
    options?: Options;
    noInputs?: boolean;
    size?: 'small' | 'medium';
}

export const RangePicker: FC<RangePickerProps> = ({
    onRangeChange,
    value,
    options,
    min = 0,
    max = 100,
    step = 1,
    noInputs = false,
}) => {
    if (!Array.isArray(value)) {
        throw new Error(`Expected array: ${value}`);
    }

    const { onSliderChange, valueLabelFormat, sliderProps } = useRangePicker({
        options,
        min,
        max,
        step,
        onRangeChange,
        value,
    });

    return (
        <StyledGrid>
            <StyledSlider
                disableSwap
                onChange={onSliderChange}
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormat}
                {...sliderProps}
            />
            {noInputs ? null : <FieldGroup options={options} onChange={onRangeChange} value={value} />}
        </StyledGrid>
    );
};
