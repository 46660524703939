import React, { useContext } from 'react';

import { DialogTypes, DialogContext } from './DialogContext';

type DialogControllerProps = {
    type: DialogTypes;
    component: React.ElementType;
};

const DialogController: React.FC<DialogControllerProps> = ({ type, component: Component }) => {
    const { openedDialog, dialogProps, mountingPhase, handleDialogClose } = useContext(DialogContext);

    if (type !== openedDialog) return null;

    return (
        <Component
            {...dialogProps}
            isOpen={mountingPhase === 'MOUNTED' && openedDialog === type}
            handleDialogClose={handleDialogClose}
        />
    );
};

export { DialogController };
