import React from 'react';

import type { Theme } from '@mui/material';
import { useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import {
    Area,
    CartesianGrid,
    Line,
    LineChart,
    AreaChart as RechartsAreaChart,
    ReferenceDot,
    ReferenceLine,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { ChartCategoryDataPoint, ChartDataPoint, ChartYearDataPoint, isYearData } from '..';
import { ChartTooltip, formatTooltipValue } from '../ChartTooltip';
import ResponsiveContainer from '../utils/ResponsiveContainer';
import { formatAxis } from './utils';

export type ChartNumberStyle = 'decimal' | 'percent' | 'currency';
export type ChartColor = keyof Theme['palette']['brandColors'];

export type ChartDataField = keyof ChartDataPoint;

export type DataPoint = {
    dataKey: string;
    color?: string;
    fillColor?: string;
    gradientDef?: React.ReactNode;
};

export type AreaChartProps = {
    data: ChartDataPoint[];
    numberStyle?: ChartNumberStyle;
    currency?: string;
    variant?: 'default' | 'posneg';
    max?: number;
    cutFromStart?: boolean;
    height?: number;
    style?: { color?: string; fillColor?: string; strokeWidth?: number };
    showLastDataPointMarker?: boolean;
    xAxisInterval?: number;
    dataPoints?: DataPoint[];
    /** should the areas stack, default true */
    stack?: boolean;
};

export const AreaChart: React.FC<AreaChartProps> = ({
    data,
    numberStyle = 'decimal',
    currency,
    variant = 'default',
    max,
    cutFromStart = false,
    height = 180,
    style,
    showLastDataPointMarker,
    xAxisInterval = 0,
    dataPoints = [{ dataKey: 'value' }],
    stack = true,
}) => {
    const theme = useTheme();
    const intl = useIntl();

    if (data.length === 0) {
        return <div style={{ height }} />;
    }

    const cutData = data.slice();
    if (max) {
        if (cutFromStart) {
            cutData.splice(0, cutData.length - max);
        } else {
            cutData.splice(max);
        }
    }

    const numActualData = cutData.reduce((acc, curr) => acc + (curr.value !== undefined ? 1 : 0), 0);
    const xLabelKey = isYearData(cutData[0]) ? 'year' : 'label';

    const ChartComponent = numActualData === 1 ? LineChart : RechartsAreaChart;

    const allPositive = !cutData.some((point) => point.value !== undefined && point.value < 0);
    const tickCount = allPositive ? 4 : 3;

    const { color, fillColor, axisDomain, gradientDef } = formatAxis(cutData, variant, theme);
    const axisColor = theme.palette.chart.axis;

    const formatYLabel = (value: number) =>
        intl.formatNumber(value, {
            notation: 'compact',
            style: numberStyle,
            currency,
        });

    const getLastDataPointMarker = () => {
        // TODO: use findLast()
        const lastDataPoint = [...cutData].reverse().find((p) => p.value !== undefined) as ChartYearDataPoint &
            ChartCategoryDataPoint & { [k: string]: number };
        if (!lastDataPoint) {
            return null;
        }
        const value = stack
            ? dataPoints.reduce((prev, next) => prev + lastDataPoint[next.dataKey], 0)
            : lastDataPoint.value;

        return (
            <>
                <ReferenceLine
                    segment={[
                        { x: lastDataPoint[xLabelKey], y: 0 },
                        { x: lastDataPoint[xLabelKey], y: value },
                    ]}
                    stroke={theme.palette.grey[500]}
                    strokeWidth={3}
                />
                <ReferenceDot
                    x={lastDataPoint[xLabelKey]}
                    y={value}
                    r={4.5}
                    stroke={theme.palette.grey[500]}
                    strokeWidth={3}
                    fill={theme.palette.common.white}
                />
            </>
        );
    };

    return (
        <ResponsiveContainer
            sx={{
                '.recharts-cartesian-grid-vertical': {
                    strokeDasharray: '2 2',
                },
            }}
            debounce={300}
            width="100%"
            height={height}
        >
            <ChartComponent data={cutData} width={400} height={300}>
                {gradientDef}
                <XAxis
                    dataKey={xLabelKey}
                    interval={xAxisInterval}
                    axisLine={false}
                    tickLine={false}
                    tick={{ ...theme.typography.chartXAxisLabel }}
                    tickFormatter={(labelValue, index) => (cutData[index].value === undefined ? '' : labelValue)}
                />
                <CartesianGrid stroke={axisColor} />
                <YAxis
                    dataKey={dataPoints.length === 1 ? dataPoints[0].dataKey : 'value'}
                    stroke={axisColor}
                    tickLine={false}
                    tick={{ ...theme.typography.chartYAxisLabel }}
                    tickFormatter={formatYLabel}
                    tickCount={tickCount}
                    width={24}
                    domain={axisDomain}
                />
                {numActualData === 1 ? (
                    <Line
                        type="linear"
                        dataKey="value"
                        stroke={color}
                        fill={theme.palette.common.white}
                        isAnimationActive={false}
                    />
                ) : (
                    dataPoints.map((dataPoint, idx) => (
                        <React.Fragment key={dataPoint.dataKey}>
                            <Area
                                stackId={stack ? '1' : idx}
                                type="linear"
                                dataKey={dataPoint.dataKey}
                                stroke={dataPoint.color || style?.color || fillColor}
                                strokeWidth={style?.strokeWidth || 2}
                                fillOpacity={1}
                                fill={dataPoint.fillColor || style?.fillColor || fillColor}
                                activeDot={{
                                    stroke: dataPoint.color || style?.color || color,
                                    strokeWidth: 3,
                                    r: 4.5,
                                    fill: theme.palette.common.white,
                                }}
                                isAnimationActive={false}
                                connectNulls
                            />
                            {dataPoint.gradientDef}
                        </React.Fragment>
                    ))
                )}
                {showLastDataPointMarker && getLastDataPointMarker()}
                <Tooltip
                    content={<ChartTooltip labelKey={xLabelKey} />}
                    formatter={formatTooltipValue(numberStyle, currency, numberStyle === 'percent' ? 2 : 0, intl)}
                    isAnimationActive={false}
                />
            </ChartComponent>
        </ResponsiveContainer>
    );
};

export default AreaChart;
