import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { useAxiosContext } from 'contexts/AxiosContext';

import { Company } from './types/company';

type GetCompanyParams = {
    businessId: string;
    // Any truthy value goes, but 1 is the most compact one in urls.
    // Note that these are converted to strings in urls, so also 0 would be truthy.
    basic?: 1;
    urls?: 1;
    financials?: 1;
    facts?: 1;
    facts_numeric?: 1;
    contacts?: 1;
    vehicles_numeric?: 1;
    prospect_addresses?: 1;
    merge_info?: 1;
};

export type DataChoices = {
    basic?: boolean;
    urls?: boolean;
    financials?: boolean;
    facts?: boolean;
    facts_numeric?: boolean;
    contacts?: boolean;
    vehicles_numeric?: boolean;
    prospect_addresses?: boolean;
    merge_info?: boolean;
};

const staticParams = {
    query_field: 'business_id',
};

export const getCompany = async (axios: AxiosInstance, { businessId, ...params }: GetCompanyParams) =>
    (await axios.get<Company>(`/mapi/get_prospect/${businessId}/`, { params: { ...staticParams, ...params } })).data;

export function useCompany(businessId: string, dataChoices: DataChoices = { basic: true }, enabled: boolean = true) {
    const axios = useAxiosContext();
    const params: GetCompanyParams = {
        businessId,
        // Convert true to 1 and false to undefined (omitted) for url params
        ...Object.fromEntries(Object.entries(dataChoices).map(([key, value]) => [key, value ? 1 : undefined])),
    };
    return useQuery<Company>({
        queryKey: ['prospect', params],
        queryFn: () => getCompany(axios, params),
        retry: 1,
        enabled,
    });
}
