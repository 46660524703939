import React, { useEffect, useRef, useState } from 'react';

import { Box, ClickAwayListener } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Popper from '@mui/material/Popper';
import { produce } from 'immer';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Typography from 'components/tokens/Typography';
import { ActionType, useTrigger, EventKeywords } from 'contexts/TriggerContext';

import TriggerFilterKeywordGroup from './TriggerFilterKeywordGroup';
import { getTagName } from './TriggerFilters';

type Props = { tag?: string; onClose: () => void };

const TriggerFilterKeywords: React.FC<Props> = ({ tag, onClose }) => {
    const [state, dispatch, { allTags }] = useTrigger();
    const { events, trackChanges = [], trackingLogic } = state;
    const [contentRows, setContentRows] = useState<EventKeywords[]>([]);
    const [headlineRows, setHeadlineRows] = useState<EventKeywords[]>([]);
    const anchorRef = useRef<HTMLDivElement>(null);

    const event = tag ? events[tag] : undefined;

    useEffect(() => {
        setContentRows(event?.filter((row) => row.type === 'content') || []);
        setHeadlineRows(event?.filter((row) => row.type === 'headline') || []);
    }, [event]);

    const onClear = () => {
        setContentRows([]);
        setHeadlineRows([]);
    };

    const onSave = () => {
        onClose();

        if (!tag) {
            return;
        }

        const newRows = [...groupRows(contentRows), ...groupRows(headlineRows)];

        if (tag === 'any' && !newRows.length) {
            dispatch({
                type: ActionType.REPLACE_TRIGGER_FILTERS,
                events: produce(events, (events) => {
                    delete events[tag];
                    return events;
                }),
                trackChanges,
                trackingLogic,
            });
            return;
        }

        dispatch({
            type: ActionType.REPLACE_TRIGGER_FILTERS,
            events: {
                ...events,
                [tag]: newRows,
            },
            trackChanges,
            trackingLogic,
        });
    };

    const eventName = tag && getTagName(allTags, tag);

    const isMissingKeywords =
        tag === 'any' &&
        !contentRows.some((row) => row.values.length) &&
        !headlineRows.some((row) => row.values.length);

    return (
        <div ref={anchorRef}>
            <Popper
                open={!!tag}
                anchorEl={anchorRef?.current}
                placement="bottom-start"
                transition
                popperOptions={{
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: true,
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 4],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={onSave}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Card sx={{ width: 732, height: 400, display: 'flex', flexDirection: 'column' }}>
                                <CardContent
                                    sx={{ padding: 2, paddingTop: 3, overflowY: 'auto', flex: 1 }}
                                    id="triggerEventsContainer"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: 2,
                                        }}
                                    >
                                        <Typography variant="body2">{eventName}</Typography>

                                        <TriggerFilterKeywordGroup
                                            rows={contentRows}
                                            setRows={setContentRows}
                                            label="Keywords"
                                            placeholder="Type keywords..."
                                            type="content"
                                            eventName={eventName || ''}
                                        />
                                        <TriggerFilterKeywordGroup
                                            rows={headlineRows}
                                            setRows={setHeadlineRows}
                                            label="Headlines"
                                            placeholder="Type headline keywords..."
                                            type="headline"
                                            eventName={eventName || ''}
                                        />

                                        {isMissingKeywords && (
                                            <Typography variant="body2" sx={{ color: 'error.main' }}>
                                                Add keywords to save
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                                <CardActions sx={{ padding: 2 }}>
                                    <Grid container justifyContent="space-between">
                                        <Button
                                            variant="flat"
                                            onClick={() => onClear()}
                                            disabled={!contentRows.length && !headlineRows.length}
                                        >
                                            Clear
                                        </Button>
                                        <Button onClick={() => onSave()}>Apply</Button>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </div>
    );
};

export default TriggerFilterKeywords;

// ALLs and NOTs can be grouped into a single row
const groupRows = (rows: EventKeywords[]) => {
    const result: EventKeywords[] = [];

    rows.forEach((row) => {
        if (!row.values.length) {
            return;
        }
        if (row.operator !== FilterOperator.ANY) {
            const groupedRow = result.find((r) => r.operator === row.operator);
            if (groupedRow) {
                row.values.forEach((value) => {
                    if (!groupedRow.values.includes(value)) {
                        groupedRow.values.push(value);
                    }
                });
                return;
            }
        }
        result.push(row);
    });
    return result;
};
