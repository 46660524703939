type PriorityTechnologies = [category: string, technologies: [id: number, label: string, category: string][]][];

// Note: These are in the order of priorization both as categories and inside categories.
export const priorityTechnologies: PriorityTechnologies = [
    [
        'crm',
        [
            [25783, 'Salesforce', 'crm'],
            [8086853, 'Hubspot CRM', 'crm'],
            [7575927, 'Pipedrive', 'crm'],
            [76069, 'Dynamics CRM Online', 'crm'],
            [32966, 'Sugar crm', 'crm'],
        ],
    ],
    [
        'marketing',
        [
            [65, 'Hubspot', 'marketing'],
            [66, 'Marketo', 'marketing'],
            [507, 'Adobe marketing cloud', 'marketing'],
            [10837, 'Demandbase', 'marketing'],
            [7549426, 'Active Campaign', 'marketing'],
            [12905, 'Mailchimp', 'marketing'],
            [121, 'Mailgun', 'marketing'],
        ],
    ],
    [
        'analytics',
        [
            [103, 'Comscore', 'analytics'],
            [110, 'Hotjar', 'analytics'],
            [7544175, 'Segment', 'analytics'],
            [8075264, 'Leadfeeder', 'analytics'],
            [7551353, 'Amplitude', 'analytics'],
            [2, 'Google analytics', 'analytics'],
            [483, 'Facebook pixel', 'analytics'],
            [8047039, 'Adobe Analytics', 'analytics'],
        ],
    ],
    [
        'other',
        [
            [10494776, 'Ahrefs SEO tools', 'other'],
            [7546397, 'Freshdesk', 'other'],
            [7987559, 'Docusign', 'other'],
            [7988074, 'Adobe Sign', 'other'],
            [10647033, 'Postman API', 'other'],
            [10624032, 'Readme', 'other'],
            [640, 'Cloudflare', 'other'],
        ],
    ],
    [
        'chat',
        [
            [7968962, 'Drift Chat', 'chat'],
            [7020067, 'Intercom', 'chat'],
            [37403, 'Olark', 'chat'],
            [93, 'Zendesk', 'chat'],
        ],
    ],
    [
        'communication',
        [
            [31644, 'Wistia', 'communication'],
            [8022407, 'GoToWebinar', 'communication'],
            [7647486, 'Vidyard', 'communication'],
            [7010404, 'Slack', 'communication'],
            [7263484, 'ZOOM', 'communication'],
        ],
    ],
    [
        'webstore',
        [
            [83, 'Magento', 'webstore'],
            [757, 'Shopify', 'webstore'],
            [46227, 'Jetshop', 'webstore'],
        ],
    ],
    [
        'cms',
        [
            [3, 'Wordpress', 'cms'],
            [8080308, 'Hubspot CMS', 'cms'],
            [4, 'Joomla', 'cms'],
            [1, 'Drupal', 'cms'],
            [615, 'Wix', 'cms'],
        ],
    ],
    [
        'advertising',
        [
            [95, 'Doubleclick', 'advertising'],
            [7369556, 'Twitter Ads', 'advertising'],
        ],
    ],
    [
        'ats',
        [
            [7212762, 'Taleo', 'ats'],
            [7968974, 'Workday', 'ats'],
            [7198164, 'Teamtailor', 'ats'],
            [7212763, 'SuccessFactors', 'ats'],
            [7551714, 'Lever', 'ats'],
        ],
    ],
    [
        'server',
        [
            [92, 'Outlook', 'server'],
            [7356, 'Amazon web services', 'server'],
        ],
    ],
    ['mailserver', [[13218, 'Gmail', 'mailserver']]],
    [
        'payment',
        [
            [7554195, 'Apple Pay', 'payment'],
            [8047040, 'Google Pay', 'payment'],
            [7543814, 'MasterCard', 'payment'],
        ],
    ],
    [
        'some',
        [
            [58, 'Facebook', 'some'],
            [59, 'Twitter', 'some'],
            [13320, 'Instagram', 'some'],
            [60, 'Youtube', 'some'],
        ],
    ],
];

export function getPriorityTechnologies(): PriorityTechnologies {
    return priorityTechnologies.map(([category, technologies]) => [category, [...technologies]]);
}

export const categoryPriorities: Record<string, number> = {
    crm: 0,
    marketing: 1,
    analytics: 2,
    other: 3,
    chat: 4,
    communication: 5,
    webstore: 6,
    cms: 7,
    advertising: 8,
    ats: 9,
    server: 10,
    mailserver: 11,
    payment: 12,
    some: 13,
};
