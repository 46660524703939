import { formatDistanceToNow, isToday } from 'date-fns';
import { toDateUTC, formatDate as formatDateUtil } from 'utilities/date';
import * as images from './images';

export const crmLabels = {
    dynamics: 'Dynamics 365',
    hubspot: 'HubSpot',
    pipedrive: 'Pipedrive',
    salesforce: 'Salesforce',
    salesforcesandbox: 'Salesforce Sandbox',
    superoffice: 'SuperOffice',
};

const hiddenTabs = {
    signals: true,
    group: true,
    employees: true,
    logo: true,
    banner: true,
    screenshot: true,
};

export function getCRMName(name) {
    return crmLabels[name] || 'CRM';
}

export function localeNumber(number) {
    // eslint-disable-next-line eqeqeq
    if (!number && number != 0) {
        return;
    }

    var browser_locale;
    if (window?.App?.locales) {
        browser_locale = window?.App?.locales?.split(',')[0];
    }

    return number.toLocaleString(browser_locale);
}

export const PROSPECT_TAB_BLACK_LIST = {
    120975: hiddenTabs, // Alma media
    193442: hiddenTabs, // Alma talent
    121131: hiddenTabs, // Alma media partners
    1280974: hiddenTabs, // Alma talent media
    121122: hiddenTabs, // Alma Media Suomi Oy
    11910886: hiddenTabs, // Alma Career.
};

export function getLogo(company) {
    const logoDisabled = PROSPECT_TAB_BLACK_LIST[company.id]?.logo;
    // for realestate do not show logo, it is usually management companys
    if (company.form_of_company === 'AOY') {
        return null;
    }
    if (company.logo && !logoDisabled) {
        const logoUrl = company.logo.indexOf('a:') !== -1 ? company.logo.substring(2) : company.logo;
        return logoUrl;
    }
    if (company.industry_codes_local && company.industry_codes_local[0]) {
        const codeFirstDigits = company.industry_codes_local[0].substring(0, 2);
        return window.App.staticFilesPath + images.industryIconsSquare[codeFirstDigits];
    }
    const num = String(company.id).substr(-1);
    return `${window.App.staticFilesPath + images.unknownIndustrySquare + num}.png`;
}

export const formatDate = (date, preposition = '') => {
    if (!date) return '';
    const dateObj = toDateUTC(date);
    return isToday(dateObj)
        ? formatDistanceToNow(dateObj, { addSuffix: true })
        : preposition && `${preposition} ` + formatDateUtil(dateObj, 'short-datetime');
};
