import React from 'react';

import { Box, SxProps } from '@mui/material';

export type TabContentGridColumnProps = {
    sx?: SxProps;
    children?: React.ReactNode;
};

export const TabContentGridColumn: React.FC<TabContentGridColumnProps> = ({ sx, children }) => (
    <Box
        sx={{
            display: 'grid',
            gap: { xs: 1, md: 2 },
            ...sx,
        }}
    >
        {children}
    </Box>
);

export default TabContentGridColumn;
