import React, { MutableRefObject, useState } from 'react';

import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
    createFilterOptions,
    SxProps,
    TextField,
} from '@mui/material';
import { isNotEmpty, isValidString } from 'utilities';

import Chip from 'components/tokens/Chip';
import { brandPalette } from 'design/theme/colors';

import { getCommaSeparatedValues } from './utils';

type ChipVariant = 'default' | 'magenta' | 'green';

export interface ChipInputProps {
    values: string[];
    setValues: (values: string[]) => void;
    width: string | number;
    height?: string | number;
    size?: 'small' | 'medium';
    placeholder?: string;
    limitTags?: number;
    freeSolo?: boolean;
    options?: string[];
    chipVariant?: ChipVariant;
    disabledOptions?: string[];
    getOptionLabel?: (arg: string) => string;
    onValidateInput?: (arg: string) => boolean;
    name?: string;
    createLabel?: string;
    /**
     * These fields are to be used with react-hook-form (or any form library used atm)
     * since it is the single source of truth for the field and validation can only be
     * done in form level.
     */
    formErrors?: string;
    setFormError?: (message: string) => void;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
    onBlur?: () => void;
}

const filter = createFilterOptions<string>();

const ChipInput: React.FC<ChipInputProps> = ({
    values,
    setValues,
    width = '200px',
    height,
    size = 'small',
    placeholder = '...placeholder',
    limitTags = -1,
    freeSolo = true,
    options = [],
    chipVariant = 'default',
    disabledOptions = [],
    getOptionLabel = (str: string) => str,
    onValidateInput = () => true,
    formErrors,
    setFormError,
    createLabel = 'Create',
    inputRef,
    onBlur,
    ...props
}) => {
    const [helperMessage, setHelperMessage] = useState<Set<string>>(
        formErrors?.length ? new Set(formErrors.split(',')) : new Set(),
    );

    const preventInvalidValueToBeAdded = (value: string[]) => {
        // for removing `Create ""` which is added from input suggestion
        const sanitizedValue = value
            .map((val) => {
                return val.split('"').length > 1
                    ? getCommaSeparatedValues(val.split('"')[1])
                    : getCommaSeparatedValues(val);
            })
            .flat();

        if (isNotEmpty(sanitizedValue)) {
            const withoutDuplicateValue = [...new Set(sanitizedValue)];
            const matchedDisabledOptions: Set<string> = new Set();
            const valueWithDisabledOptions = withoutDuplicateValue.filter((valueToFilter) => {
                if (disabledOptions.some((disabledOption) => disabledOption === valueToFilter)) {
                    matchedDisabledOptions.add(valueToFilter);
                    return false;
                }
                return onValidateInput(valueToFilter);
            });
            setHelperMessage(matchedDisabledOptions);
            setFormError?.([...matchedDisabledOptions].join(','));

            return valueWithDisabledOptions;
        }
        return [];
    };

    const onChange = (_event: React.SyntheticEvent, value: string[]) => {
        setValues(preventInvalidValueToBeAdded(value));
    };

    const onInputChange = (_event: React.SyntheticEvent, value: string, reason: string) => {
        /**
         * This is to empty helper message when duplicate values exists in same input field
         * which doesn't trigger `onChange`
         */
        if (reason === 'reset') {
            setHelperMessage(new Set());
        } else {
            onValidateInput(value);
        }
    };

    const addInputToValues = (value: string) => {
        value && setValues(preventInvalidValueToBeAdded([...values, value]));
    };

    const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
        if (freeSolo) {
            addInputToValues((event.target as HTMLInputElement).value);
        }
        onBlur?.();
    };

    const renderTags = (value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
        value.map((option, index) => (
            <Chip label={option} size={size} {...getTagProps({ index })} sx={{ ...getChipVariant(chipVariant) }} />
        ));

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            inputRef={(ref) => {
                if (inputRef) {
                    inputRef.current = ref;
                }
            }}
            InputLabelProps={{ ...params.InputLabelProps, variant: 'outlined' }}
            placeholder={placeholder}
            error={helperMessage.size > 0}
            helperText={
                helperMessage.size > 0
                    ? `Duplicate value found: ${Array.from(helperMessage)
                          .map((item) => item)
                          .join(', ')}`
                    : undefined
            }
            sx={{
                width,
                '& .MuiInputBase-root': {
                    backgroundColor: 'background.paper',
                    fontSize: 14,
                    minHeight: height,
                },
                '& > p': {
                    marginLeft: 0,
                },
            }}
        />
    );

    return (
        <Autocomplete
            {...props}
            data-testid="chip-input-autocomplete"
            sx={{ width }}
            limitTags={limitTags}
            getLimitTagsText={(more) => `(+${more})`}
            size={size}
            disableClearable
            value={values}
            onChange={onChange}
            onBlur={handleBlur}
            clearOnBlur
            multiple
            freeSolo={freeSolo}
            options={options}
            getOptionLabel={getOptionLabel}
            onInputChange={onInputChange}
            renderTags={renderTags}
            renderInput={renderInput}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                if (freeSolo) {
                    const isExisting = options.some((option) => inputValue === option);
                    if (isValidString(inputValue) && !isExisting) {
                        filtered.push(`${createLabel} "${inputValue}"`);
                    }
                }

                return filtered;
            }}
            renderOption={(props, option) => (
                <li style={{ fontSize: 14 }} {...props}>
                    {option}
                </li>
            )}
        />
    );
};

export default ChipInput;

const getChipVariant = (variant: ChipVariant): SxProps => {
    if (variant === 'magenta') {
        return {
            color: brandPalette.errorMagenta,
            backgroundColor: brandPalette.statusError100,
            borderColor: brandPalette.statusError500,
            '& .MuiChip-deleteIcon': {
                color: brandPalette.errorMagenta,
            },
        };
    }
    if (variant === 'green') {
        return {
            color: brandPalette.green,
        };
    }
    return {
        color: brandPalette.onyx,
    };
};
