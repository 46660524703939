import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CircleDown: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM12 17L7 12L8.41 10.59L11 13.17V7H13V13.17L15.59 10.59L17 12L12 17Z" />
    </SvgIcon>
);

export default CircleDown;
