import { styled } from '@mui/material/styles';

const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    background: theme.palette.background.default,
}));

const Banner = styled('div')({
    display: 'inline-flex',
    flexGrow: 1,
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: '100%',
    paddingLeft: 24,
    gap: 16,
});

const AppBarButtons = styled('div')({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    marginLeft: 'auto',
    paddingRight: 40,
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'flex-end',
    '&> *': {
        marginLeft: 12,
    },
});

const UserControl = styled('a')({
    display: 'inline-flex',
    marginLeft: 10,
    width: 40,
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
});

const Template = {
    Header,
    Banner,
    AppBarButtons,
    UserControl,
};

export default Template;
