import React from 'react';

import { Box, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { MainPath } from 'components/beta/Platform/Router/routePaths';
import { Menu, MenuItemProps } from 'components/templates/Menu';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import { useAuthContext } from 'contexts/AuthContext';
import { usePermissionContext } from 'contexts/PermissionContext';

type AccountAction = 'settings' | 'admintools' | 'product_tour' | 'report_problem' | 'logout';

const AccountDropdown: React.FC = () => {
    const { isVainuAdmin, profile } = usePermissionContext();
    const { logout } = useAuthContext();
    const navigate = useNavigate();

    const { userName, accountname } = profile;

    const dropdownOptions: MenuItemProps<AccountAction>[] = [
        {
            action: 'settings',
            label: 'Settings',
            onClick: () => navigate(MainPath.Settings),
        },
        {
            action: 'admintools',
            label: 'Admin Tools',
            onClick: () => window.open(`${process.env.REACT_APP_API_URL}/admintools/?referrer=platform2`),
        },
        /** after global launch */
        // {
        //     action: 'product_tour',
        //     label: 'Product Tour',
        //     onClick: () => {
        //         /** Launch product tour */
        //     },
        // },
        // {
        //     action: 'report_problem',
        //     label: 'Report a Problem',
        //     onClick: () => {
        //         /** Open bug report form */
        //     },
        // },
        {
            action: 'logout',
            label: 'Log out',
            onClick: () => logout(),
        },
    ];

    const filteredOptions = dropdownOptions.filter((dropdown) => {
        if (['admintools'].includes(dropdown.action)) {
            return isVainuAdmin;
        }
        return true;
    });

    const userTooltip = (
        <>
            <div>{userName || '[no user name set]'}</div>
            <Box sx={{ color: 'tooltip.subtle' }}>{accountname || '[no account name set]'}</Box>
        </>
    );
    const anchorElement = (
        <Tooltip title={userTooltip} placement="left" enterDelay={700} enterNextDelay={700} disableInteractive>
            <Button variant="flat" startIcon={<Icon type="User" />} />
        </Tooltip>
    );

    return (
        <Menu
            anchorElement={anchorElement}
            items={filteredOptions}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        />
    );
};

export default AccountDropdown;
