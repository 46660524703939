import { useEffect, useRef, forwardRef } from 'react';

// `outerElementType` for react-window to use #scroll-container for infinite scroll
// adapted from https://github.com/bvaughn/react-window/issues/192#issuecomment-1273544325

const emptyFunction = () => {};

type DocumentPropsType = React.HTMLProps<HTMLElement>;

export const ScrollContainerOuter = forwardRef<HTMLElement, DocumentPropsType>(
    ({ onScroll, children }, forwardedRef) => {
        const containerRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const scrollContainer = document.getElementById('scroll-container');
            const scrollHandler = () => {
                if (!scrollContainer || !(onScroll instanceof Function)) {
                    return;
                }

                const { clientWidth, clientHeight, scrollLeft, scrollTop, scrollHeight, scrollWidth } = scrollContainer;

                const newScrollTop =
                    scrollTop -
                    (containerRef.current ? containerRef.current.getBoundingClientRect().top + scrollTop : 0);

                onScroll({
                    currentTarget: {
                        clientHeight,
                        clientWidth,
                        scrollLeft,
                        addEventListener: emptyFunction,
                        removeEventListener: emptyFunction,
                        dispatchEvent: () => false,
                        scrollTop: newScrollTop,
                        scrollHeight,
                        scrollWidth,
                    },
                } as unknown as React.UIEvent<HTMLElement>);
            };

            scrollContainer?.addEventListener('scroll', scrollHandler, { passive: true });

            return () => scrollContainer?.removeEventListener('scroll', scrollHandler);
        }, [onScroll]);

        if (forwardedRef != null && !(forwardedRef instanceof Function)) {
            forwardedRef.current = document.getElementById('scroll-container');
        }

        return (
            <div ref={containerRef} style={{ position: 'relative' }}>
                {children}
            </div>
        );
    },
);
