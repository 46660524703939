import { FC, useState } from 'react';

import { ClickAwayListener } from '@mui/material';

import { SelectOption, SelectProps } from 'components/tokens/select-components/Select';
import Select from 'components/tokens/select-components/Select/Select';
import usePrevious from 'hooks/usePrevious';

interface LocationFiltersSelectProps extends Omit<SelectProps<SelectOption>, 'onClose' | 'onOpen'> {
    onClose?: () => void;
    onOpen?: () => void;
    forceOpen?: boolean;
    warningLevel?: 'warn' | 'err';
}

export const LocationFiltersSelect: FC<LocationFiltersSelectProps> = ({
    onClose,
    onOpen,
    forceOpen,
    onValueChange,
    color,
    warningLevel,
    ...otherProps
}) => {
    const prevOpen = usePrevious(forceOpen);

    const [open, setOpen] = useState(forceOpen && !prevOpen);

    const handleClose = () => {
        setOpen(false);
        onClose?.();
    };

    const handleChange: LocationFiltersSelectProps['onValueChange'] = (...props) => {
        setOpen(false);
        onValueChange?.(...props);
    };

    const handleInputChange = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleClose}>
            <div>
                <Select
                    open={open}
                    onValueChange={handleChange}
                    onOpen={handleInputChange}
                    onFocus={() => setOpen(true)}
                    color={color ?? getColor(warningLevel)}
                    {...otherProps}
                />
            </div>
        </ClickAwayListener>
    );
};

export function getColor(level: string | undefined) {
    if (level === 'warn') {
        return 'warning.main';
    }
    if (level === 'err') {
        return 'error.main';
    }
    return undefined;
}
