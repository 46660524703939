import { Box } from '@mui/material';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import ChipInput from 'components/tokens/ChipInput/ChipInput';
import Icon from 'components/tokens/Icon';
import { OptionProps } from 'components/tokens/select-components/NestedSelect';
import Select, { SelectOption } from 'components/tokens/select-components/Select/Select';
import Typography from 'components/tokens/Typography';

type Props = {
    operator: string;
    values: string[];
    onValuesChange: (values: string[]) => void;
    onOperatorChange: (operator: string) => void;
    onRemove: () => void;
    label: string;
    placeholder: string;
};

const operatorOptions = [
    { label: 'contain any', value: FilterOperator.ANY },
    { label: 'contain all', value: FilterOperator.ALL },
    { label: "don't contain", value: FilterOperator.NOT },
];

const TriggerFilterRow = ({
    operator,
    values,
    onValuesChange,
    onOperatorChange,
    onRemove,
    label,
    placeholder,
}: Props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                alignSelf: 'stretch',
            }}
        >
            <Box
                sx={{
                    padding: '0 8px',
                    background: 'white',
                    border: '1px solid #e1e1e1',
                    color: 'rgba(0,0,0, 0.38)',
                    borderRadius: 1,
                    height: 38,
                    display: 'flex',
                    alignItems: 'center',
                    width: 88,
                }}
            >
                <Typography
                    variant="small"
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {label}
                </Typography>
            </Box>

            <Select
                placeholder="Select"
                multiple={false}
                width={126}
                options={operatorOptions}
                value={operatorOptions.find((option) => option.value === operator) as OptionProps}
                onValueChange={(value) => onOperatorChange((value as SelectOption).value as string)}
            />

            <ChipInput
                values={values}
                setValues={(newValues) => onValuesChange(newValues)}
                width="100%"
                placeholder={!values.length ? placeholder : operator === FilterOperator.ALL ? 'And...' : 'Or...'}
                chipVariant={operator === FilterOperator.NOT ? 'magenta' : undefined}
            />

            <Button
                startIcon={<Icon type="Remove" fontSize="small" color="text.subtle" />}
                variant="flat"
                onClick={onRemove}
            />
        </Box>
    );
};

export default TriggerFilterRow;
