import React from 'react';

const CompanyMatchBuildingIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width="124" height="104" viewBox="0 0 124 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width="109" height="97" fill="white" />
        <path
            d="M68.0003 80.3333C60.6399 80.3252 54.6751 74.3604 54.667 67V66.7333C54.8136 59.406 60.8464 53.5706 68.1746 53.6678C75.5027 53.7651 81.3786 59.7585 81.3307 67.0871C81.2828 74.4157 75.3291 80.3319 68.0003 80.3333ZM67.979 77.6667H68.0003C73.8893 77.6608 78.6593 72.8836 78.6563 66.9947C78.6534 61.1057 73.8786 56.3333 67.9897 56.3333C62.1007 56.3333 57.3259 61.1057 57.323 66.9947C57.32 72.8836 62.09 77.6608 67.979 77.6667ZM69.3337 75H66.667V72.3333H69.3337V75ZM69.3337 71H66.667C66.6249 69.2636 67.5285 67.6411 69.027 66.7627C69.907 66.088 70.667 65.5067 70.667 64.3333C70.667 62.8606 69.4731 61.6667 68.0003 61.6667C66.5276 61.6667 65.3337 62.8606 65.3337 64.3333H62.667V64.2133C62.6884 62.3079 63.7248 60.5587 65.3856 59.6245C67.0465 58.6904 69.0795 58.7133 70.719 59.6845C72.3584 60.6558 73.3551 62.4279 73.3337 64.3333C73.2382 65.772 72.4665 67.0803 71.2537 67.86C70.1573 68.5483 69.4466 69.7104 69.3337 71Z"
            fill="#0A0A0A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0883 74.9091C52.7593 72.5764 52 69.8769 52 67C52 63.5436 53.096 60.3432 54.9594 57.7273H39.7273V70H34.8182V20.9091H69.1818V51.043C70.9087 51.1691 72.5595 51.5692 74.0909 52.2002V16.2C74.0909 16.0895 74.0014 16 73.8909 16H30.1091C29.9986 16 29.9091 16.0895 29.9091 16.2V70H25V74.9091H54.0883ZM64.2727 51.4364V47.9091H59.3636V52.8182H60.5851C61.7386 52.2138 62.975 51.7461 64.2727 51.4364ZM44.6364 62.6364H49.5455V72.4546H44.6364V62.6364ZM44.6364 47.9091H39.7273V52.8182H44.6364V47.9091ZM54.4545 47.9091H49.5455V52.8182H54.4545V47.9091ZM44.6364 40.5455H39.7273V45.4545H44.6364V40.5455ZM54.4545 40.5455H49.5455V45.4545H54.4545V40.5455ZM64.2727 40.5455H59.3636V45.4545H64.2727V40.5455ZM44.6364 33.1818H39.7273V38.0909H44.6364V33.1818ZM54.4545 33.1818H49.5455V38.0909H54.4545V33.1818ZM64.2727 33.1818H59.3636V38.0909H64.2727V33.1818ZM44.6364 25.8182H39.7273V30.7273H44.6364V25.8182ZM54.4545 25.8182H49.5455V30.7273H54.4545V25.8182ZM64.2727 25.8182H59.3636V30.7273H64.2727V25.8182Z"
            fill="#0A0A0A"
        />
        <rect opacity="0.2" y="97" width="31" height="7" rx="2" fill="#349780" />
        <rect opacity="0.2" x="33" y="97" width="19" height="7" rx="2" fill="#2F80ED" />
        <rect x="54" y="97" width="48" height="7" rx="2" fill="#F29711" />
        <rect opacity="0.2" x="104" y="97" width="4" height="7" rx="2" fill="#898989" />
    </svg>
);
export default CompanyMatchBuildingIcon;
