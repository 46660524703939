import type { CompanyFact } from 'api/types/company';
import Frame from 'components/tokens/Frame';
import Tag from 'components/tokens/Tag';

export type CompanyEventsProps = {
    facts: CompanyFact[];
};

export const CompanyEvents: React.VFC<CompanyEventsProps> = ({ facts }) => {
    const events = facts.filter((fact) => fact.type === 'event');
    return (
        <Frame title="Events">
            {events.map(({ value }) => (
                <Tag key={value} sx={{ marginRight: '6px', marginBottom: 1 }} size="big" label={value} />
            ))}
        </Frame>
    );
};

export default CompanyEvents;
