import { useMemo } from 'react';

import { assertArray } from 'utilities';

import { FinancialStatement } from 'api/types/FinancialStatement';

import { isValidStatement } from './types';

export const useValidStatements = (statements: FinancialStatement[] | undefined) =>
    useMemo(() => assertArray(statements).filter(isValidStatement), [statements]);
