import { useCallback, useMemo, useState } from 'react';

const useSelection = <T,>() => {
    const [selected, setSelected] = useState<Array<T>>([]);
    const [unselected, setUnselected] = useState<Array<T>>([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    const select = useCallback(
        (value: T | Array<T>) => {
            if (isAllSelected) {
                if (Array.isArray(value)) {
                    setUnselected((unselected) => unselected.filter((i) => !value.includes(i)));
                } else {
                    setUnselected((unselected) => unselected.filter((i) => i !== value));
                }
            } else {
                if (Array.isArray(value)) {
                    setSelected((selected) => Array.from(new Set([...selected, ...value])));
                } else {
                    setSelected((selected) => [...selected, value]);
                }
            }
        },
        [isAllSelected],
    );

    const unselect = useCallback(
        (value: T) => {
            if (isAllSelected) {
                setUnselected((unselected) => [...unselected, value]);
            } else {
                setSelected((selected) => selected.filter((i) => i !== value));
            }
        },
        [isAllSelected],
    );

    const toggleAllSelected = useCallback(
        (selectedState?: boolean) => {
            setSelected([]);
            setUnselected([]);
            // If a param is not provided, select all if there is no selection; otherwise deselect all.
            const newState = selectedState != null ? selectedState : selected.length === 0 && !isAllSelected;
            setIsAllSelected(newState);
        },
        [isAllSelected, selected],
    );

    return useMemo(
        () => ({
            selected,
            unselected,
            isAllSelected,
            select,
            unselect,
            toggleAllSelected,
            setSelected,
            setUnselected,
            setIsAllSelected,
        }),
        [
            selected,
            unselected,
            isAllSelected,
            select,
            unselect,
            toggleAllSelected,
            setSelected,
            setUnselected,
            setIsAllSelected,
        ],
    );
};

export default useSelection;
