import React from 'react';

import { Tooltip, Typography, styled } from '@mui/material';

import CompanyMatchBuildingIcon from '../../svg/CompanyMatchBuildingIcon';

interface ManualMatchSummaryProps {
    needHelpcompaniesCount: number;
    totalCompaniesCount: number;
}
const ManualMatchSummary: React.FC<ManualMatchSummaryProps> = ({ needHelpcompaniesCount, totalCompaniesCount }) => {
    const needHelpPercentage = (needHelpcompaniesCount * 100) / totalCompaniesCount;
    return (
        <SummaryWrapper>
            <div>
                <Tooltip
                    title={`Need help ${needHelpPercentage?.toFixed(2)}%`}
                    placement="bottom"
                    arrow
                    componentsProps={{
                        tooltip: {
                            sx: {
                                fontSize: 12,
                                padding: '8px',
                                boxShadow: '0px 1px 2px rgba(10, 10, 10, 0.1), 0px 4px 12px rgba(10, 10, 10, 0.15)',
                                borderRadius: '4px',
                                position: 'absolute',
                                top: 20,
                                left: '-20px',
                                width: 80,
                            },
                        },
                        arrow: {
                            sx: {
                                position: 'absolute',
                                left: '28px !important',
                            },
                        },
                    }}
                >
                    <div>
                        <CompanyMatchBuildingIcon />
                    </div>
                </Tooltip>
            </div>
            <div style={{ padding: '0px 30px' }}>
                <NeedHelpTitle>
                    <span>{needHelpcompaniesCount} </span>
                    Need help
                </NeedHelpTitle>
                <NeedHelpDescription color="textSecondary">
                    To match companies with Vainu look it up from our database in the dropdown. You can view the company
                    in your CRM by clicking the name on the list.
                </NeedHelpDescription>
            </div>
        </SummaryWrapper>
    );
};
export default ManualMatchSummary;

const SummaryWrapper = styled('div')`
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    @media (max-height: 780px) {
        display: none;
    }
`;

const NeedHelpTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.text.strong,
    '& span': {
        fontFamily: 'Aeroport',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 33,
        color: theme.palette.brandColors.orange,
    },
}));

const NeedHelpDescription = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
}));
