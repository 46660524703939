import React from 'react';

import { Box } from '@mui/material';

import { WebsiteData } from 'api/types/Organization';
import Button from 'components/tokens/Button';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import { formatTechnologiesUpdateDate, formatLink } from '../utils';

export interface WebsitePreviewProps {
    // technology_data?: TechnologyData[];
    website_data?: WebsiteData;
    // prospect_addresses?: Address[];
    website?: string;
    updatedDate?: string;
}

export const WebsitePreview: React.VFC<WebsitePreviewProps> = ({
    // technology_data,
    website_data,
    // prospect_addresses,
    website,
    updatedDate,
}) => {
    if (!website_data) {
        return null;
    }

    const { updatedString, updatedColor } = formatTechnologiesUpdateDate(updatedDate);
    const websiteLink = website ? formatLink(website) : null;

    return (
        <Frame padding="large" sx={{ height: 320, '& > img': { objectFit: 'cover' } }}>
            <Box
                component="img"
                sx={{
                    display: 'block',
                    objectFit: 'cover',
                    height: 160,
                    width: '100%',
                    borderRadius: 2,
                }}
                src={website_data.screenshot_thumb_url}
                alt=""
            />
            <Box sx={{ display: 'flex', marginTop: 2 }}>
                <Icon type="Globe" sx={{ marginRight: '6px' }} />
                <Typography weight="bold">{website}</Typography>
            </Box>
            <Typography
                component="div"
                variant="tiny"
                color="subtle"
                sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginTop: 1 }}
            >
                {'Technologies updated – '}
                <Typography variant="tiny" weight="semibold" sx={{ color: updatedColor }}>
                    {updatedString}
                </Typography>
            </Typography>
            {website && websiteLink && (
                <Button
                    href={websiteLink}
                    target="_blank"
                    variant="tertiary"
                    size="small"
                    fullWidth
                    sx={{ marginTop: 2 }}
                >
                    Go to the website
                </Button>
            )}
        </Frame>
    );
};
export default WebsitePreview;
