import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';
import { NordicDatabase, useListsContext } from 'contexts/ListsContext';

import { TValuesType, getFilterConfig } from '../../FiltersBaseConfig';
import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    FilterProps,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, getInnerMostValueInFilterState, valueArrayToText } from '../utils';

const { baseConfig, getInitValueConfigDefault } = getFilterConfig(FilterID.legal_entity);
export type TValues = TValuesType<typeof baseConfig, string[]>;

const config: FilterConfig<TValues> = {
    ...baseConfig,
    ...getInitValueConfigDefault<TValues>(FilterOperator.IN, []),
};

export const legalEntityFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.basic,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        render: (props: FilterProps<TValues>) => <LegalEntity {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.IN, `${FilterOperator.NOT}${FilterOperator.IN}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);
export const LegalEntity: React.VFC<FilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
}) => {
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);
    const { database } = useListsContext();
    const { legalEntities } = useFilterOptionsContext();

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const countryBasedLegalEntities = legalEntities[database as NordicDatabase];

    const matchedFilterValues = sameFiltersState.flatMap((sameState) =>
        getInnerMostValueInFilterState(sameState, filter.id),
    );
    const disabledOptions = countryBasedLegalEntities.filter((entity) => matchedFilterValues.includes(entity.value));

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-select-value`}
                render={({ field }) => {
                    const { onChange, value, ref: _ref, ...fieldProps } = field;

                    const selectedOptions = countryBasedLegalEntities.filter((item) =>
                        value.includes(item.value as string),
                    );

                    return (
                        <Select
                            placeholder={selectedOptions?.length ? 'Or...' : 'Select'}
                            multiple={true}
                            width="100%"
                            options={countryBasedLegalEntities || []}
                            value={selectedOptions}
                            disabledOptions={disabledOptions}
                            onValueChange={(options) => onChange((options as SelectOption[]).map((o) => o.value))}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
