import React, { useEffect } from 'react';

import { PluginProps } from './TwitterPlugin';

function refreshFacebook() {
    (function (d, id) {
        const fjs = d.getElementsByTagName('script')[0];
        if (d.getElementById(id)) {
            return;
        }

        const js = d.createElement('script');
        js.id = id;
        const language_code = 'en_US';
        /**
         * Languages on Facebook use the format ll_CC, where ll is a two-letter language code, and CC is a two-letter country code. For instance, en_US represents U.S. English.
         */
        const t_src = `//connect.facebook.net/${language_code}/sdk.js#xfbml=1&version=v13.0&appId=1400487276858089`;

        js.src = t_src;
        fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'facebook-jssdk');
}

function removeFacebook() {
    delete window.FB;
    (function (d, id) {
        const fbScript = d.getElementById(id);
        const fbRoot = d.getElementById('fb-root');
        if (fbScript) {
            fbScript.parentNode?.removeChild(fbScript);
            // fbScript.remove() is not supported by IE
        }
        if (fbRoot) {
            fbRoot.parentNode?.removeChild(fbRoot);
        }
    })(document, 'facebook-jssdk');
}

const FacebookPlugin: React.VFC<PluginProps> = ({ link }) => {
    useEffect(() => {
        removeFacebook();
        refreshFacebook();

        return () => {
            removeFacebook();
        };
    }, [link]);

    return (
        <div
            className="fb-page"
            data-href={link}
            data-width="500"
            data-height="600"
            data-small-header="true"
            data-adapt-container-width="true"
            data-tabs="timeline"
            data-show-facepile="true"
            data-hide-cover="false"
        />
    );
};

export default FacebookPlugin;
