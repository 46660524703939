import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Happy: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM12 18C10.42 18.0267 8.9266 17.28 8 16C7.55008 15.3983 7.21141 14.721 7 14H17C17 14 17 14 17 14.008C16.7853 14.7252 16.4469 15.3994 16 16C15.0733 17.2799 13.5799 18.0266 12 18ZM8.5 12C7.67157 12 7 11.3284 7 10.5C7 9.67157 7.67157 9 8.5 9C9.32843 9 10 9.67157 10 10.5C10 11.3284 9.32843 12 8.5 12ZM15.493 11.986C14.6684 11.986 14 11.3176 14 10.493C14 9.66844 14.6684 9 15.493 9C16.3176 9 16.986 9.66844 16.986 10.493C16.9849 11.3171 16.3171 11.9849 15.493 11.986Z" />
    </SvgIcon>
);

export default Happy;
