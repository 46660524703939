import React from 'react';

import Button from 'components/tokens/Button';

import ExportDropDown from './ExportDropDown';
import { CellProps } from './TableComponent';

const PreviewCell: React.FC<CellProps> = ({
    setShowPreviewModal,
    setReDeliverID,
    openExportModal,
    hasCRMdestination,
    exportTable,
    row,
}) => {
    const messageQueueID = row.messages?.[0]?.id;

    const redeliver = (destinationString: string) => {
        if (messageQueueID) {
            setReDeliverID?.(messageQueueID);
            openExportModal?.(destinationString);
        }
    };

    if (hasCRMdestination) {
        return (
            <Button variant="tertiary" onClick={() => setShowPreviewModal?.(row.id)}>
                CRM preview
            </Button>
        );
    }
    if (row?.delivered && messageQueueID && !exportTable) {
        return <ExportDropDown openExportModal={(v) => redeliver(v)} label="Redeliver" />;
    }

    return null;
};

export default PreviewCell;
