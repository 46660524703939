import { Box } from '@mui/material';

import JSONItem from './JSONItem';

type JSONPreviewProps = {
    data?: Object;
    skeletonData: Object;
};

const JSONPreview = ({ data, skeletonData }: JSONPreviewProps) => {
    return (
        <Box sx={{ overflow: 'hidden', borderRadius: 3, height: '100%' }}>
            <Box
                sx={{
                    font: '14px monospace',
                    lineHeight: 2,
                    padding: 2,
                    backgroundColor: 'grey.50',
                    overflow: 'auto',
                    height: '100%',
                    '&::-webkit-scrollbar': {
                        height: 32,
                        width: 32,
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'grey.50',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'grey.200',
                        border: '13px solid',
                        borderColor: 'grey.50',
                        borderRadius: 8,
                    },
                    '&::-webkit-scrollbar-corner': {
                        backgroundColor: 'grey.50',
                    },
                    '& .row-count': {
                        color: 'text.subtle',
                        marginLeft: 1,
                    },
                    '& .null': {
                        color: 'text.subtle',
                    },
                }}
            >
                <JSONItem data={{ key: 'object', value: data || skeletonData }} />
            </Box>
        </Box>
    );
};

export default JSONPreview;
