import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PipedriveMono = (props) => (
    <SvgIcon {...props}>
        <path d="M9.90122 9.72897C9.90122 11.4164 10.7573 13.2368 12.6411 13.2368C14.0381 13.2368 15.4506 12.1461 15.4506 9.70421C15.4506 7.56329 14.3402 6.12603 12.6875 6.12603C11.3409 6.12603 9.90122 7.07204 9.90122 9.72897ZM13.3512 3C16.7289 3 19 5.67517 19 9.6573C19 13.5769 16.6075 16.3133 13.1872 16.3133C11.5565 16.3133 10.5119 15.6149 9.96578 15.1093C9.96966 15.2291 9.97224 15.3634 9.97224 15.5067V20.8219H6.47321V6.66028C6.47321 6.45439 6.40736 6.38924 6.20336 6.38924H5V3.28798H7.93609C9.28793 3.28798 9.63395 3.97598 9.6998 4.50633C10.2486 3.89129 11.3861 3 13.3512 3Z" />
    </SvgIcon>
);

export default PipedriveMono;
