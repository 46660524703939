import {
    CrmName,
    CRMObject,
    SalesforceObject,
    DynamicsObject,
    HubspotObject,
    PipedriveObject,
    CrmCompanyObject,
    HubspotContactObject,
    PipedriveContactObject,
    SalesforceContactObject,
    DynamicsContactObject,
} from '../types/Crm';

export type TargetReqParam = 'Pipedrive' | 'Salesforcev3' | 'HubSpot' | 'Dynamics';

export type TargetObject = 'company' | 'contact' | 'opportunity';

export const CRMTargetText = (crm: CrmName, target: TargetObject) => {
    if (target === 'contact') {
        switch (crm) {
            case 'dynamics':
            case 'salesforce':
            case 'hubspot':
                return 'Contacts';
            case 'pipedrive':
                return 'People';
            default:
                return 'Contacts';
        }
    }
    if (target === 'company') {
        switch (crm) {
            case 'dynamics':
            case 'salesforce':
                return 'Accounts';
            case 'hubspot':
                return 'Companies';
            case 'pipedrive':
                return 'Organizations';
            default:
                return 'Accounts';
        }
    }
    if (target === 'opportunity') {
        switch (crm) {
            case 'dynamics':
            case 'salesforce':
                return 'Opportunity';
            case 'hubspot':
            case 'pipedrive':
                return 'Deal';
            default:
                return 'Deal';
        }
    }
    return null;
};

type CRMObjectsFormsMap = {
    hubspot: Record<HubspotObject, string[]>;
    salesforce: Record<SalesforceObject, string[]>;
    salesforcesandbox: Record<SalesforceObject, string[]>;
    dynamics: Record<DynamicsObject, string[]>;
    pipedrive: Record<PipedriveObject, string[]>;
};

const crmObjectsFormsMap: CRMObjectsFormsMap = {
    hubspot: {
        Company: ['Company', 'Companies'],
        Contact: ['Contact', 'Contacts'],
        Deal: ['Deal', 'Deals'],
        Note: ['Note', 'Notes'],
        Task: ['Task', 'Tasks'],
    },
    salesforce: {
        Account: ['Account', 'Accounts'],
        Contact: ['Contact', 'Contacts'],
        Opportunity: ['Opportunity', 'Opportunities'],
        Task: ['Task', 'Tasks'],
        Lead: ['Lead', 'Leads'],
    },
    salesforcesandbox: {
        Account: ['Account', 'Accounts'],
        Contact: ['Contact', 'Contacts'],
        Opportunity: ['Opportunity', 'Opportunities'],
        Task: ['Task', 'Tasks'],
        Lead: ['Lead', 'Leads'],
    },
    dynamics: {
        leads: ['Lead', 'Leads'],
        accounts: ['Account', 'Accounts'],
        contacts: ['Contact', 'Contacts'],
        opportunities: ['Opportunity', 'Opportunities'],
        tasks: ['Task', 'Tasks'],
        annotations: ['Note', 'Notes'],
    },
    pipedrive: {
        organization: ['Organization', 'Organizations'],
        person: ['Person', 'Persons'],
        lead: ['Lead', 'Leads'],
        deal: ['Deal', 'Deals'],
        activity: ['Activity', 'Activities'],
        note: ['Note', 'Notes'],
    },
};

export const getObjectSingularForm = (crm: CrmName, crmObject: CRMObject): string => {
    return crmObjectsFormsMap[crm][crmObject as keyof (typeof crmObjectsFormsMap)[typeof crm]][0];
};

export const getObjectPluralForm = (crm: CrmName, crmObject: CRMObject): string => {
    return crmObjectsFormsMap[crm][crmObject as keyof (typeof crmObjectsFormsMap)[typeof crm]][1];
};

export const getCompanyLikeObjectForCrm = (target: CrmName): CrmCompanyObject => {
    switch (target) {
        case 'hubspot':
            return 'Company';
        case 'salesforce':
        case 'salesforcesandbox':
            return 'Account';
        case 'dynamics':
            return 'accounts';
        case 'pipedrive':
            return 'organization';
    }
};

export const getContactLikeObjectForCrm = (target: CrmName): CrmContactObject => {
    switch (target) {
        case 'hubspot':
        case 'salesforce':
        case 'salesforcesandbox':
            return 'Contact';
        case 'dynamics':
            return 'contacts';
        case 'pipedrive':
            return 'person';
    }
};

export function mapCRMNameToTarget(crm: CrmName): TargetReqParam {
    switch (crm) {
        case 'pipedrive':
            return 'Pipedrive';
        case 'salesforce':
            return 'Salesforcev3';
        case 'hubspot':
            return 'HubSpot';
        case 'dynamics':
            return 'Dynamics';
        default:
            return crm as TargetReqParam;
    }
}

type ExportableObjects = {
    dynamics: DynamicsObject[];
    pipedrive: PipedriveObject[];
    hubspot: HubspotObject[];
    salesforce: SalesforceObject[];
    salesforcesandbox: SalesforceObject[];
};

export const companyExportables: ExportableObjects = {
    dynamics: ['accounts', 'leads'],
    pipedrive: ['organization'],
    hubspot: ['Company'],
    salesforce: ['Account', 'Lead'],
    salesforcesandbox: ['Account', 'Lead'],
};

export const contactExportables: ExportableObjects = {
    dynamics: ['contacts', 'leads', 'tasks'],
    pipedrive: ['activity', 'person'],
    hubspot: ['Contact', 'Task'],
    salesforce: ['Contact', 'Lead', 'Task'],
    salesforcesandbox: ['Contact', 'Lead', 'Task'],
};

type MultiplePickExportables = {
    dynamics: Partial<Record<DynamicsObject, DynamicsObject[]>>;
    pipedrive: Partial<Record<PipedriveObject, PipedriveObject[]>>;
    hubspot: Partial<Record<HubspotObject, HubspotObject[]>>;
    salesforce: Partial<Record<SalesforceObject, SalesforceObject[]>>;
    salesforcesandbox: Partial<Record<SalesforceObject, SalesforceObject[]>>;
};
export const triggerCrmExportables: MultiplePickExportables = {
    dynamics: {
        leads: ['accounts', 'annotations'],
        opportunities: ['accounts', 'annotations'],
        tasks: ['accounts', 'opportunities', 'annotations'],
    },
    pipedrive: {
        deal: ['organization', 'note'],
        lead: ['organization', 'note'],
        activity: ['organization', 'deal', 'note'],
    },
    hubspot: {
        Deal: ['Company', 'Note'],
        Task: ['Company', 'Deal', 'Note'],
    },
    salesforce: {
        Opportunity: ['Account'],
        Task: ['Account', 'Opportunity', 'Lead'],
        Lead: ['Task'],
    },
    salesforcesandbox: {
        Opportunity: ['Account'],
        Task: ['Account', 'Opportunity', 'Lead'],
        Lead: ['Task'],
    },
};

type CrmContactObject = HubspotContactObject | PipedriveContactObject | SalesforceContactObject | DynamicsContactObject;
