import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import { getFirstKey } from 'utilities/objectUtils';

import { TValuesType, getFilterConfig } from '../../FiltersBaseConfig';
import {
    GroupValues,
    FilterID,
    FilterDefinition,
    FilterConfig,
    FilterProps,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, dateValueToText } from '../utils';
import DateFilter, { DateFilterValue } from './components/DateFilter';

const { baseConfig, getInitValueConfigDefault } = getFilterConfig(FilterID.foundation);
export type TValues = TValuesType<typeof baseConfig, DateFilterValue>;

const config: FilterConfig<TValues> = {
    ...baseConfig,
    ...getInitValueConfigDefault(FilterOperator.YEAR, new Date().getFullYear().toString()),
};

const FIELD = baseConfig.fields[0];

export const foundationFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.basic,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <Foundation {...props} />,
        valueToText: dateValueToText(config.fields[0]),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.YEAR].map((value) => formatOperatorValue(value as OperatorValue));

export const Foundation: React.VFC<FilterProps<TValues>> = ({ filter, uuid, groupUUID, removeFilter }) => {
    const { control, setValue } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);

    const fullValuePath = `${filter.groupPath}`;

    const handleDateChange = (value: DateFilterValue) => {
        let newValue = value;
        let newOperator = FilterOperator.YEAR;
        if (Array.isArray(value)) {
            newOperator = FilterOperator.RANGE;
            if (value.length === 1) {
                newValue = [value[0], null];
            }
        }

        setValue(`${filter.groupPath}`, {
            [newOperator]: {
                [FIELD]: newValue,
            },
        });
    };

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        operatorDisabled: true,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange: _onChange, value, ref: _ref, ...fieldProps } = field;
                    const operator = getFirstKey(value);

                    return <DateFilter value={value[operator][FIELD]} onChange={handleDateChange} {...fieldProps} />;
                }}
            />,
        ],
    };
    return <FilterTemplate {...props} />;
};
