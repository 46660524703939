import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WindowTerminal: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M20 21H4C2.89543 21 2 20.1046 2 19V5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V19C22 20.1046 21.1046 21 20 21ZM4 7V19H20V7H4ZM18 17H12V15H18V17ZM7.414 17L6 15.586L8.293 13.294L6 11L7.414 9.586L11.12 13.293L7.415 17H7.414Z" />
    </SvgIcon>
);

export default WindowTerminal;
