import { styled } from '@mui/material';

import Typography from 'components/tokens/Typography';

import { formatDate } from '../utils';

type AutomaticUpdateStatusProps = {
    isOn: boolean;
    jobFinishTime?: string;
};

const AutomaticUpdateStatus = ({ isOn, jobFinishTime }: AutomaticUpdateStatusProps) => (
    <>
        <Typography fontSize={14}>
            <AutomaticUpdateText success={isOn}>
                {`Automatic data updates are ${isOn ? 'on' : 'off'}.`}
            </AutomaticUpdateText>{' '}
            {isOn
                ? 'You’ll always receive the latest data in your selected fields when company information changes in Vainu.'
                : "Turn updates on and Vainu will renew outdated data from your selected fields and make sure you'll always receive the latest data when company information changes in Vainu."}
        </Typography>
        {jobFinishTime && (
            <Typography fontSize="12px" margin="8px 0px">
                <i>Last update {formatDate(jobFinishTime)}</i>
            </Typography>
        )}
    </>
);

export default AutomaticUpdateStatus;

const AutomaticUpdateText = styled('span')<{ success: boolean }>(({ success, theme }) => ({
    fontSize: '14px',
    fontWeight: 500,
    color: success ? theme.palette.success.main : theme.palette.warning.main,
}));
