import { useState } from 'react';

import { getLegacyBusinessId } from 'utilities';

import { GroupStructureEntity } from 'api/types/groupStructure';
import { ProfileDisplayMode } from 'components/modules/profiles/Profile';

import { isCompanyInSubtree } from '../utils';
import ExpandButton from './ExpandIcon';
import { Subtree, CompanyContainer, ConnectionLine, InnerPanel } from './GroupStructureSubtree.styled';
import ProfileLink from './ProfileLink';

export type GroupStructureSubtreeProps = {
    company: GroupStructureEntity;
    mode: ProfileDisplayMode;
    profileBusinessId?: string;
    showFlags: boolean;
};

export const GroupStructureSubtree: React.VFC<GroupStructureSubtreeProps> = ({
    company,
    profileBusinessId,
    showFlags,
    mode,
}) => {
    const openSubtreeInitially =
        company.company_level <= 1 || (!!profileBusinessId && isCompanyInSubtree(profileBusinessId, company));
    const [expanded, setExpanded] = useState(openSubtreeInitially);
    const { _id, daughters, company_level, is_concern, business_id } = company;

    const hasExpandButton = !!daughters?.length && company_level > 1;
    const isGroupRoot = is_concern;
    const isParent = company_level === 1 && !is_concern;
    const isActive =
        !!profileBusinessId &&
        !isGroupRoot &&
        getLegacyBusinessId(business_id) === getLegacyBusinessId(profileBusinessId);

    return (
        <Subtree isGroupRoot={isGroupRoot} isParent={isParent}>
            <CompanyContainer key={_id} isGroupRoot={isGroupRoot} className="company-container">
                {!isGroupRoot && (
                    <>
                        <ConnectionLine rounded={!hasExpandButton} />
                        {hasExpandButton ? (
                            <ExpandButton expanded={expanded} onClick={() => setExpanded(!expanded)} />
                        ) : null}
                    </>
                )}
                <ProfileLink company={company} active={isActive} showFlags={showFlags} mode={mode} />
            </CompanyContainer>
            {daughters?.length && (
                <InnerPanel isGroupRoot={isGroupRoot} in={expanded}>
                    {daughters.map((daughter) => (
                        <GroupStructureSubtree
                            key={daughter._id}
                            company={daughter}
                            showFlags={showFlags}
                            profileBusinessId={profileBusinessId}
                            mode={mode}
                        />
                    ))}
                </InnerPanel>
            )}
        </Subtree>
    );
};

export default GroupStructureSubtree;
