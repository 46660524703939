import React from 'react';

import Skeleton, { Container, Row } from './index';

const margin = '0px 0px 4px 0px';
const UpdateSkeleton = () => {
    return (
        <Container>
            <div style={{ padding: '0px 40px' }}>
                <div>
                    <Skeleton radius={4} height={48} width={289} margin={margin} />
                    <Skeleton width={542} height={14} />
                </div>
                <div style={{ marginTop: '72px' }}>
                    <Row margin="0px 0px 6px 0px" justifyContent="space-between">
                        <Skeleton radius={4} height={28} width={289} />
                        <Skeleton radius={4} height={40} width={111} />
                    </Row>
                    <Skeleton width={542} height={14} margin={margin} />
                    <Skeleton width={392} height={14} />
                </div>
                {[...Array(2).keys()].map((each) => (
                    <div style={{ marginTop: '86px' }} key={each}>
                        <Row margin="0px 0px 6px 0px" justifyContent="space-between">
                            <Skeleton radius={4} height={28} width={289} />
                            <Skeleton radius={4} height={40} width={111} />
                        </Row>
                        <Skeleton width={542} height={14} margin={margin} />
                    </div>
                ))}
            </div>
        </Container>
    );
};
export default UpdateSkeleton;
