import { getValues } from 'utilities';

export const industryRootItemsWithDirectChildren: Record<string, string[]> = {
    A: ['01', '02', '03'],
    B: ['05', '06', '07', '08', '09'],
    C: getValues(['10', '33']),
    D: ['35'],
    E: getValues(['36', '39']),
    F: getValues(['41', '43']),
    G: getValues(['45', '47']),
    H: getValues(['49', '53']),
    I: getValues(['55', '56']),
    J: getValues(['58', '63']),
    K: getValues(['64', '66']),
    L: ['68'],
    M: getValues(['69', '75']),
    N: getValues(['77', '82']),
    O: ['84'],
    P: ['85'],
    Q: getValues(['86', '88']),
    R: getValues(['90', '93']),
    S: getValues(['94', '96']),
    T: getValues(['97', '98']),
    U: ['99'],
    // X: ['00'],
};
