import React from 'react';

import { CircularProgress, useMediaQuery } from '@mui/material';

import { useCompany } from 'api/company';
import { useTitle } from 'components/beta/Platform/Router/utils';
import { getIndustryTagsFromIndustries } from 'components/features/Industries/utils';
import ProfileTabs from 'components/features/ProfileTabs';
import CompanyProfilePageLayout from 'components/layout/CompanyProfilePageLayout';
import CompanyProfileSidebarCard from 'components/templates/CompanyProfileSidebarCard';
import { Theme } from 'design/theme';

import { ProfileProps } from '..';
import { companyTabs as tabs } from '../tabs';
import { getCompanyNumberDataPoints, getImage, getBannerFallback } from '../utils';

export type CompanyProfileProps = ProfileProps & {
    businessId: string;
};

export const CompanyProfile: React.VFC<CompanyProfileProps> = ({
    businessId,
    mode = 'page',
    activeTab,
    onTabChange,
}) => {
    const { data, isLoading, isSuccess } = useCompany(businessId, {
        basic: true,
        financials: true,
        facts: true,
        prospect_addresses: true,
        urls: true,
    });
    const showSidebar = useMediaQuery<Theme>((theme) => theme.breakpoints.up('profileSidebar'));
    useTitle(data?.company_name, isLoading);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (isSuccess) {
        if (!data) {
            return null;
        }

        const visibleTabs = tabs.filter((tab) => true);
        const Content = tabs.find((tab) => tab.name === activeTab)?.component ?? null;

        return (
            <CompanyProfilePageLayout
                mode={mode}
                backgroundImage={data.banner ? getImage(data.banner) : getBannerFallback(data.vid)}
                header={
                    mode === 'popup' ? (
                        <CompanyProfileSidebarCard
                            profileType="company-legacy"
                            id={data.business_id}
                            name={data.company_name ?? ''}
                            country={data.country}
                            city={data.visiting_city}
                            logo={data.logo}
                            description={data.description ?? ''}
                            status={data.status}
                            statusClass={data.nstatus === 0 ? 'active' : 'warning'}
                            industryTags={getIndustryTagsFromIndustries(data)}
                            isGroupHQ={data.is_parent}
                            numberDataPoints={getCompanyNumberDataPoints(data)}
                            showSections={showSidebar ? 'top' : 'both'}
                            showHeaderActions
                            frame={false}
                            wide
                        />
                    ) : null
                }
                sidebar={
                    <CompanyProfileSidebarCard
                        showBottomActions={mode === 'page'}
                        profileType="company-legacy"
                        id={data.business_id}
                        name={data.company_name ?? ''}
                        country={data.country}
                        city={data.visiting_city}
                        logo={data.logo}
                        description={data.description ?? ''}
                        status={data.status}
                        statusClass={data.nstatus === 0 ? 'active' : 'warning'}
                        industryTags={getIndustryTagsFromIndustries(data)}
                        isGroupHQ={data.is_parent}
                        numberDataPoints={getCompanyNumberDataPoints(data)}
                        showSections={mode === 'popup' ? 'bottom' : 'both'}
                        wide={mode === 'page' && !showSidebar}
                    />
                }
                tabs={
                    <ProfileTabs
                        tabs={visibleTabs}
                        activeTab={activeTab}
                        onChange={onTabChange}
                        frame={mode === 'page'}
                    />
                }
                content={
                    Content && (
                        <Content
                            type="company"
                            businessId={businessId}
                            data={data}
                            mode={mode}
                            onTabChange={onTabChange}
                        />
                    )
                }
            />
        );
    }

    return null;
};

export default CompanyProfile;
