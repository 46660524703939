import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

/** This icon has different viewBox so it is better to use fontSize at least 60 to see it properly */
const VainuText: React.FC<SvgIconProps> = (props) => (
    <SvgIcon style={{ fill: '#0A0A0A' }} {...props} viewBox="0 0 67 24">
        <path d="M19.3837 4.02611C19.424 4.04334 19.4606 4.0686 19.4914 4.10044L21.6407 6.32048C21.7547 6.43776 21.8451 6.5771 21.9068 6.73052C21.9684 6.88393 22.0001 7.04839 22 7.21447V12.3693C22 12.5352 21.9683 12.6995 21.9067 12.8528C21.845 13.0061 21.7547 13.1453 21.6407 13.2624L19.4899 15.4817C19.432 15.5409 19.3553 15.576 19.2743 15.5809C19.3559 15.5764 19.4332 15.5412 19.4915 15.4816L14.9846 20.1292C14.755 20.366 14.4436 20.499 14.119 20.4991H11.9078C11.5831 20.499 11.2718 20.366 11.0421 20.1292L9.30452 18.3364C9.24258 18.272 9.20782 18.1849 9.20782 18.0941C9.20782 18.0034 9.24258 17.9163 9.30452 17.8518L11.0108 16.0945C11.1246 15.9771 11.2596 15.8838 11.4083 15.8202C11.557 15.7566 11.7163 15.7239 11.8773 15.7239H17.5597C17.6983 15.7231 17.8336 15.6802 17.9487 15.6005C18.0638 15.5208 18.1535 15.4078 18.2066 15.2757C18.2598 15.1437 18.274 14.9983 18.2475 14.858C18.2233 14.73 18.1662 14.6114 18.0826 14.5143L16.8686 13.2616C16.7548 13.1442 16.6646 13.0049 16.6031 12.8515C16.5416 12.6981 16.51 12.5337 16.5101 12.3676V7.21367C16.5099 7.04777 16.5415 6.88346 16.603 6.73018C16.6645 6.57691 16.7548 6.43767 16.8686 6.32048L19.0218 4.10044C19.0526 4.0686 19.0892 4.04334 19.1295 4.02611C19.1698 4.00887 19.213 4 19.2566 4C19.3002 4 19.3434 4.00887 19.3837 4.02611Z" />
        <path d="M6.74731 4.00147C6.83527 4.00147 6.91967 4.03733 6.98212 4.10124L9.13222 6.32128C9.24619 6.43856 9.3366 6.5779 9.39825 6.73131C9.4599 6.88473 9.49159 7.04919 9.49148 7.21527V12.3701C9.49153 12.536 9.45981 12.7003 9.39816 12.8536C9.33651 13.0069 9.24614 13.1461 9.13222 13.2632L6.97977 15.4825C6.91722 15.5462 6.83286 15.5819 6.74496 15.5819C6.65706 15.5819 6.5727 15.5462 6.51015 15.4825L4.35848 13.2624C4.12909 13.0251 4.00019 12.7036 4 12.3684V7.21447C4.00005 6.87949 4.12899 6.55823 4.35848 6.32128L6.51249 4.10124C6.57495 4.03733 6.65934 4.00147 6.74731 4.00147Z" />
        <path d="M60.9978 8.88175V12.424C60.9978 13.4046 60.5003 13.908 59.6804 13.908C58.8604 13.908 58.363 13.3869 58.363 12.3798V8.88175H57.0197V12.4152C57.0197 14.2349 58.0229 15.1624 59.6628 15.1624C61.3028 15.1624 62.341 14.2438 62.341 12.371V8.88175H60.9978Z" />
        <path d="M52.8717 12.689L50.0104 8.88171H48.7714V15.0652H50.0976V11.1343L53.0549 15.0652H54.1974V8.88171H52.8717V12.689Z" />
        <path d="M44.4489 15.0652H45.7921V8.88171H44.4489V15.0652Z" />
        <path d="M38.2245 8.83752L35.6074 15.0653H36.977L37.3423 14.1577H40.3105L40.676 15.0653H42.0801L39.4633 8.83752H38.2245ZM39.8687 13.0596H37.784L38.0184 12.477H38.0151L38.8264 10.4718L39.6377 12.477H39.6342L39.8687 13.0596Z" />
        <path d="M31.0794 13.2367L29.4831 8.8817H28L30.4689 15.1094H31.6552L34.124 8.8817H32.6759L31.0794 13.2367Z" />
    </SvgIcon>
);

export default VainuText;
