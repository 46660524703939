import React from 'react';

import { PaperProps as MuiPaperProps } from '@mui/material/Paper';
import clsx from 'clsx';

import { Typography, TypographyProps } from '../Typography';
import { StyledFrame } from './styled';

export type Elevation = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type Padding = 'none' | 'small' | 'medium' | 'large' | 'xlarge';
type Variant = 'white' | 'black' | 'grey' | 'lightgrey';
type Display = 'block' | 'inline-block';

type FrameOwnProps = {
    elevation?: Elevation;
    /** Defaults to 'large', if title is set. Otherwise defaults to 'small'. */
    padding?: Padding;
    variant?: Variant;
    display?: Display;
    border?: boolean;
};

type TitleProps = {
    title?: string;
    titleProps?: TypographyProps;
};

export type FrameProps = Omit<MuiPaperProps, 'variant' | 'elevation' | 'display'> & FrameOwnProps & TitleProps;
export type StyledFrameProps = Omit<MuiPaperProps, 'variant' | 'elevation' | 'display'> &
    Omit<Required<FrameOwnProps>, 'variant'>;

export const Frame: React.FC<FrameProps> = React.forwardRef(
    (
        {
            elevation = 1,
            padding,
            variant = 'white',
            display = 'block',
            title,
            titleProps,
            className,
            children,
            border = false,
            ...props
        },
        ref,
    ) => {
        const selectedPadding = padding ?? (title ? 'large' : 'small');
        return (
            <StyledFrame
                ref={ref}
                className={clsx(className, `variant-${variant}`, border && 'border')}
                display={display}
                elevation={elevation}
                padding={selectedPadding}
                border={border}
                {...props}
            >
                {title && (
                    <Typography variant="body1" weight="bold" component="h2" sx={{ marginBottom: 3 }} {...titleProps}>
                        {title}
                    </Typography>
                )}
                {children}
            </StyledFrame>
        );
    },
);

export default Frame;
