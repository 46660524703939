import { FC, ReactNode } from 'react';

import { InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useRangePicker } from '../../hooks/useRangePicker';
import { StyledSlider } from '../../styled';
import { HistogramData, Values } from '../../types';
import { FieldGroup } from '../FieldGroup';
import { Histogram } from '../Histogram';
import { RangePickerProps } from '../RangePicker';

export interface RangePickerWithHistogramProps extends RangePickerProps {
    data: HistogramData | undefined;
    leftControls?: ReactNode;
    disabled?: boolean;
    endLocked?: boolean;
    valueLabelFormat?: (value: number) => string;
    maxScore?: number;
    threshold?: number;
    value: Values;
}

export const RangePickerWithHistogram: FC<RangePickerWithHistogramProps> = ({
    onRangeChange,
    value,
    options,
    noInputs = false,
    min,
    max,
    step,
    data,
    leftControls,
    disabled,
    endLocked = false,
    valueLabelFormat: valueLabelFormatProp,
    threshold = 0,
    maxScore = 100,
}) => {
    if (!Array.isArray(value)) {
        throw new Error(`Expected array: ${value}`);
    }

    const { onSliderChange, valueLabelFormat, sliderProps } = useRangePicker({
        options,
        min,
        max,
        step,
        onRangeChange,
        value,
    });

    return (
        <Container>
            {data && <Histogram data={data} withTooltip={false} />}
            <SliderRow>
                <Slider
                    onChange={onSliderChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={valueLabelFormatProp || valueLabelFormat}
                    disabled={disabled}
                    className={endLocked ? 'end-locked' : undefined}
                    style={
                        {
                            '--lowConfidenceWidth': `${
                                Math.max(threshold - value[0], 0) / ((maxScore - value[0]) / 100)
                            }%`,
                        } as React.CSSProperties
                    }
                    {...sliderProps}
                />
            </SliderRow>
            <FieldsRow>
                {leftControls}
                {noInputs ? null : (
                    <FieldGroup
                        options={options}
                        onChange={onRangeChange}
                        size="small"
                        endLocked={endLocked}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        value={value}
                    />
                )}
            </FieldsRow>
        </Container>
    );
};

export const Slider = styled(StyledSlider)`
    padding: 0 0 13px;
    margin-top: -6px;

    & .MuiSlider-track {
        height: 6px;
        color: ${({ theme }) => theme.palette.brandColors.tertiaryBlue};
    }

    & .MuiSlider-track::before {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        height: 8px;
        width: var(--lowConfidenceWidth);
        background: ${({ theme }) => theme.palette.brandColors.warningOrange};
    }

    &.end-locked .MuiSlider-thumb:last-of-type {
        background: transparent;
        height: 32px;
        border: 0;
        border-right: 2px dashed ${({ theme }) => theme.palette.brandColors.tertiaryBlue};
        border-radius: 0;
        width: 4px;
        border-style: dashed;
        pointer-events: none;

        &:before,
        &:hover,
        &.Mui-active {
            box-shadow: none;
        }
    }
`;

const SliderRow = styled('div')`
    display: flex;
    align-items: center;
    position: relative;
`;

const FieldsRow = styled('div')`
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 0;
    height: 60px;
`;

const Container = styled('div')`
    padding: 20px;
`;
