import { FilterOperator } from 'api/types/FilterOperators';
import { Trigger } from 'api/types/Trigger';

import { EventFilters } from './TriggerContextTypes';

export function convertEventsToQuery(events: EventFilters): string {
    const query = {
        '?ANY': Object.keys(events).map((key) => {
            const eventKeywordRows = events[key];
            return {
                '?ALL': [
                    ...(key !== 'any'
                        ? [
                              {
                                  '?IN': { 'leads.tags': [key] },
                              },
                          ]
                        : []),
                    ...eventKeywordRows.map((eventKeyword) => {
                        if (eventKeyword.operator === FilterOperator.NOT) {
                            return {
                                [eventKeyword.operator]: getKeywordQuery(
                                    eventKeyword.values,
                                    eventKeyword.type === 'headline',
                                )[0],
                            };
                        }

                        if (eventKeyword.operator === FilterOperator.ALL) {
                            return {
                                [FilterOperator.ALL]: eventKeyword.values.map(
                                    (keyword) => getKeywordQuery([keyword], eventKeyword.type === 'headline')[0],
                                ),
                            };
                        }

                        return {
                            [eventKeyword.operator]: getKeywordQuery(
                                eventKeyword.values,
                                eventKeyword.type === 'headline',
                            ),
                        };
                    }),
                ],
            };
        }),
    };

    return JSON.stringify(query);
}

function getKeywordQuery(keywords: string[], headingOnly: boolean) {
    if (!keywords.length) {
        return [];
    }
    return headingOnly
        ? [
              {
                  '?IN': {
                      'leads.title': keywords,
                  },
              },
          ]
        : [
              {
                  '?ANY': [
                      {
                          '?IN': {
                              'leads.title': keywords,
                          },
                      },
                      {
                          '?IN': {
                              'leads.content': keywords,
                          },
                      },
                  ],
              },
          ];
}

export function convertQueryToEvents(query: string | undefined): EventFilters {
    /*
        convert query array to EventFilters object
    */

    const events = {} as EventFilters;

    if (query) {
        const filter = JSON.parse(query);

        filter['?ANY'].forEach((filterRow: { '?ALL': [] }) => {
            let key = 'any';
            let values: string[] = [];
            let operator: string = FilterOperator.ALL;
            let type: 'content' | 'headline' = 'content';
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filterRow['?ALL'].forEach((filterPart: { [k: string]: any }) => {
                if (filterPart['?IN']?.['leads.tags']) {
                    key = filterPart['?IN']['leads.tags'][0];
                } else if (filterPart['?NOT']) {
                    operator = FilterOperator.NOT;
                    type = filterPart['?NOT']['?IN'] ? 'headline' : 'content';
                    values =
                        filterPart['?NOT']['?IN']?.['leads.title'] ||
                        filterPart['?NOT']['?ANY'][0]['?IN']['leads.title'];
                } else if (filterPart['?ANY']) {
                    operator = FilterOperator.ANY;
                    type = filterPart['?ANY'][0]['?IN'] ? 'headline' : 'content';
                    values =
                        filterPart['?ANY'][0]['?IN']?.['leads.title'] ||
                        filterPart['?ANY'][0]['?ANY'][0]['?IN']['leads.title'];
                } else if (filterPart['?ALL']) {
                    operator = FilterOperator.ALL;
                    const nextPart = filterPart[operator];
                    values = [];
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    nextPart.forEach((sub: any) => {
                        if (sub['?IN']) {
                            type = 'headline';
                            values.push(...sub['?IN']['leads.title']);
                        } else if (sub['?ANY']) {
                            type = 'content';
                            values.push(...sub['?ANY'][0]['?IN']['leads.title']);
                        }
                    });
                }

                if (!events[key]) {
                    events[key] = [];
                }

                if (values.length) {
                    events[key].push({
                        operator,
                        values,
                        type,
                    });
                }
            });
        });
    }
    return events;
}

export const getDefaultNewTrigger = (): Trigger => ({
    name: 'Untitled trigger 1',
    notify_type: 'workflow_settings',
    notify: 'specific',
    queries: undefined,
    query: undefined,
    is_valid: false,
    status: 'disabled',
    workflow_settings: {
        destinations: [],
        daily_limit: 10,
        target_group_source: '',
        crm_source: '',
    },
    utc_office_hours: {
        week_days: [0, 1, 2, 3, 4, 5, 6],
        start_hour: 8,
        end_hour: 16,
        start_min: 0,
        end_min: 0,
        timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezone_offset: '',
    },
    message_context: {
        context_items: [],
    },
    tracking_logic: {},
    track_changes: [],
});
