import { useCallback, useMemo } from 'react';

import { Options, Position, Values } from '../types';
import { getOptionMapping, rangeToFilterValue, toRangeValues } from '../utils';

export interface UseRangePickerProps {
    value: Values;
    onRangeChange: (newValue: Values) => void;
    min?: number;
    max?: number;
    step?: number;
    options?: Options;
}

export const useRangePicker = ({
    onRangeChange,
    value,
    options,
    min = 0,
    max = 100,
    step = 1,
}: UseRangePickerProps) => {
    const optionMapping = useMemo(() => getOptionMapping(options), [options]);

    const optionsLength = options?.length;

    const minOption = optionsLength ? 0 : min;
    const maxOption = optionsLength ? options.length - 1 : max;

    const valueLabelFormat = useCallback(
        (value: number) => {
            if (optionsLength) {
                const filterValue = rangeToFilterValue(value, optionMapping);
                if (filterValue === -Infinity) {
                    return `<${rangeToFilterValue(value + 1, optionMapping)}`;
                } else if (filterValue === Infinity) {
                    return `>${rangeToFilterValue(value - 1, optionMapping)}`;
                }
                return filterValue;
            }
            return value;
        },
        [optionMapping, optionsLength],
    );

    const fromRangeValues = useCallback(
        (value: Array<number>, position?: Position) => {
            if (options?.length) {
                if (position === 'left') {
                    return [
                        Math.min(
                            rangeToFilterValue(value[0], optionMapping),
                            rangeToFilterValue(value[1] - 1, optionMapping),
                        ),
                        rangeToFilterValue(value[1], optionMapping),
                    ];
                }
                return [
                    rangeToFilterValue(value[0], optionMapping),
                    Math.max(
                        rangeToFilterValue(value[1], optionMapping),
                        rangeToFilterValue(value[0] + 1, optionMapping),
                    ),
                ];
            }
            if (position === 'left') {
                return [Math.min(value[0], value[1] - step), value[1]];
            }
            return [value[0], Math.max(value[1], value[0] + step)];
        },
        [options, optionMapping, step],
    );

    const onSliderChange = useCallback(
        (_e: Event, value: number | number[], activeThumb: number) => {
            if (Array.isArray(value)) {
                if (activeThumb === 0) {
                    onRangeChange(fromRangeValues(value, 'left'));
                } else {
                    onRangeChange(fromRangeValues(value));
                }
            }
        },
        [fromRangeValues, onRangeChange],
    );

    const sliderProps = useMemo(() => {
        return {
            min: minOption,
            max: maxOption,
            value: toRangeValues(value, optionMapping),
            step,
        };
    }, [value, maxOption, minOption, step, optionMapping]);

    return {
        sliderProps,
        onSliderChange,
        valueLabelFormat,
    };
};
