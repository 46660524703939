import axiosFactory, { AxiosInstance, AxiosResponse } from 'axios';

import { MainPath } from 'components/beta/Platform/Router/routePaths';

export type TokenUser = {
    id: number;
    email: string;
    is_staff: boolean;
    is_superuser: boolean;
    profile: object;
    permissions: object;
};
export type Token = {
    token_type: string;
    exp: number;
    iat: number;
    jti: string;
    user_id: number;
    user: TokenUser;
    aud: string;
    iss: string;
};

export type AuthErrorResponse = {
    detail?: string;
    code?: string;
};

export type AuthResponse = {
    access: string;
    refresh: string;
    required_actions?: Array<'ACCEPT_TERMS'>;
};

export type AuthData = AuthResponse & {
    expiresAt: number;
};

export type AuthDataOrError = AuthData | AuthErrorResponse;

export const isErrorResponse = (response: AuthDataOrError | unknown): response is AuthErrorResponse =>
    !!response && typeof response === 'object' && 'detail' in response;

export type ImpersonateAuth = {
    email: string;
    notes: string;
    access: string;
    refresh: null;
};

export type ImpersonateErrorResponse = {
    detail?: string;
    code?: string;
};

export type ImpersonateAuthOrError = ImpersonateAuth | ImpersonateErrorResponse;

export const parseTokenData = (data: string): Token => JSON.parse(atob(data.split('.')[1]));
export const parseExpiry = (data: string): number => parseTokenData(data).exp * 1000;

export const isSuccess = (response: AuthDataOrError): response is AuthData => 'access' in response;

export const isImpersonateSuccess = (response: ImpersonateAuthOrError): response is ImpersonateAuth =>
    'access' in response;

// Do not throw on 4xx responses; handle them as successful responses (only credentials were invalid).
const axios = axiosFactory.create({ validateStatus: (status) => status < 400 || status === 401 || status === 403 });

const parseResponse = (result: AxiosResponse<AuthResponse | AuthErrorResponse>): AuthDataOrError => {
    if (result.status >= 400 && 'detail' in result.data) {
        return result.data;
    }
    if (result.status === 200 && 'access' in result.data) {
        return {
            ...result.data,
            expiresAt: parseExpiry(result.data.access),
        };
    }
    throw new Error('Unknown response format when trying to authenticate.');
};

export const postAuthentication = async (username: string, password: string): Promise<AuthDataOrError> => {
    const result = await axios.post<AuthResponse | AuthErrorResponse>(
        `${process.env.REACT_APP_API_URL}/api/v2/token_authentication/`,
        {
            username,
            password,
        },
    );
    return parseResponse(result);
};

export const postRefresh = async (refreshToken: string): Promise<AuthDataOrError> => {
    const result = await axios.post<AuthResponse | AuthErrorResponse>(
        `${process.env.REACT_APP_API_URL}/api/v2/token_authentication/refresh/`,
        {
            refresh: refreshToken,
        },
    );
    return parseResponse(result);
};

export const postImpersonateAuthentication = async (
    accessToken: string,
    email: string,
    notes: string,
): Promise<ImpersonateAuthOrError> => {
    return (
        await axios.post<ImpersonateAuth>(
            `${process.env.REACT_APP_API_URL}/api/token_authentication/impersonate/`,
            {
                email,
                notes,
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
    ).data;
};

export async function getJWT(axios: AxiosInstance, lifetime: number) {
    return await axios.post<AuthResponse>(`/api/token_authentication/api/`, { lifetime });
}

export const getAzureRedirectUrl = async (): Promise<string> => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/sso/login/azuread-oauth2/json/`);
    return result.data.redirect_to;
};

export const getGoogleSSORedirectUrl = async (): Promise<string> => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/sso/login/google-openidconnect/json/`);
    return result.data.redirect_to;
};

export const authWithSsoCode = async (code: string, provider: string) => {
    const result = await axios.post<AuthResponse>(`${process.env.REACT_APP_API_URL}/api/sso_token_authentication/`, {
        code,
        provider,
        redirect_uri: `${window.origin}/${MainPath.SSO}/${provider}/`,
    });
    return {
        ...result.data,
        expiresAt: parseExpiry(result.data.access),
    };
};
