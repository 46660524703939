import type { IntlShape } from 'react-intl';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';

export type ChartNumberStyle = 'decimal' | 'percent' | 'currency';

type ValueType = string | number;
type FormatTooltipValue<T extends ValueType, V extends ValueType> = (
    style: ChartNumberStyle,
    currency: string | undefined,
    digits: number,
    intl: IntlShape,
) => Formatter<T, V>;

export const formatTooltipValue: FormatTooltipValue<string, string> =
    (style: ChartNumberStyle, currency: string | undefined, digits: number, intl: IntlShape) => (value: string) => {
        const v = typeof value === 'string' ? parseFloat(value) : value;
        return intl.formatNumber(style === 'percent' ? v / 100 : v, {
            style,
            currency,
            maximumFractionDigits: digits,
            minimumFractionDigits: digits,
        });
    };

export default formatTooltipValue;
