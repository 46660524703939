import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { initializeObjectWithArrayValues } from 'utilities';

import { getAddresses, getLegalEntities } from 'api/filter_values';
import { AddressesData } from 'api/types/Location';
import { LegalEntityTag } from 'api/types/Tag';
import { SelectOption } from 'components/tokens/select-components/Select';
import { useAxiosContext } from 'contexts/AxiosContext';
import { Database, NordicDatabase } from 'contexts/ListsContext';
import { getLegalEntityFullName } from 'utilities/profiles/constants';

const INITIAL_ADDRESSES_DATA: AddressesData = { municipalities: [], regions: [], cities: [], postal_codes: [] };
export function useAddressesData(database: Database | undefined) {
    const axios = useAxiosContext();

    const { data: addressesData = INITIAL_ADDRESSES_DATA } = useQuery({
        queryKey: [`addresses-${database}`],
        queryFn: () => getAddresses(axios, database),
        refetchOnWindowFocus: false,
        enabled: Boolean(database && database !== 'DOMAIN_DATA_BASIC'),
    });

    return useMemo(() => {
        const municipalities = addressesData.municipalities;
        const cities = addressesData.cities;
        const regions = addressesData.regions;

        const municipalitiesOptions = municipalities.map((value) => ({ value, label: value }));

        const citiesOptions = cities.map((value) => ({ value, label: value }));

        const countryAreaOptions = regions.map(({ id, label }) => ({ value: id, label }));
        return { municipalitiesOptions, citiesOptions, countryAreaOptions };
    }, [addressesData]);
}

export type CountryBasedLegalEntities = {
    [key in NordicDatabase]: SelectOption[];
};

const INITIAL_LEGAL_ENTITIES: LegalEntityTag[] = [];
export function useLegalEntities() {
    const axios = useAxiosContext();

    const { data: legalEntities = INITIAL_LEGAL_ENTITIES } = useQuery({
        queryKey: [`legal-entities`],
        queryFn: () => getLegalEntities(axios),
        refetchOnWindowFocus: false,
    });

    const countryBasedLegalEntities = useMemo(() => {
        const newFilterValues: CountryBasedLegalEntities = initializeObjectWithArrayValues('FI', 'NO', 'DK', 'SE');

        if (!!legalEntities.length) {
            for (const entity of legalEntities) {
                const country = entity[2] as NordicDatabase;
                if (!newFilterValues.hasOwnProperty(country)) {
                    newFilterValues[country] = [];
                }
                const tempEntity = {
                    label: getLegalEntityFullName(entity[0]),
                    value: entity[1],
                };
                newFilterValues[country].push(tempEntity);
                for (const key of Object.keys(newFilterValues) as NordicDatabase[]) {
                    newFilterValues[key].sort((a, b) => a.label.localeCompare(b.label));
                }
            }
        }
        return newFilterValues;
    }, [legalEntities]);

    return { countryBasedLegalEntities };
}
