import { atom } from 'recoil';

import { ReshapedAccount } from 'api/types/Account';

const reshapedAccountAtom = atom<ReshapedAccount>({
    key: 'reshapedAccountAtom',
    default: {
        accountId: '',
        userLimit: 0,
        users: [],
        invitedUsers: [],
    },
});

export default reshapedAccountAtom;
