import { Box } from '@mui/material';

import Button from 'components/tokens/Button';
import Chip from 'components/tokens/Chip';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';

import { TileData } from '../types';

type SingleDatapointProps = {
    data: TileData;
    onDetailsClick?: () => void;
};

const SingleDatapoint: React.FC<SingleDatapointProps> = ({ data, onDetailsClick }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography sx={{ fontSize: 64 }} weight="semibold">
                {data.valueLabel || data.value}
            </Typography>

            <Chip
                label={data.label}
                sx={{
                    '&, &:hover': {
                        color: 'brandColors.primaryGreen700',
                        backgroundColor: 'brandColors.primaryGreen100',
                        border: 'none',
                        outline: 0,
                    },
                }}
            />

            <Box sx={{ marginTop: 2, height: 32 }}>
                {onDetailsClick && (
                    <Button startIcon={<Icon type="Menu" />} onClick={onDetailsClick} variant="flat" size="small">
                        View details
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default SingleDatapoint;
