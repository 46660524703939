import { useEffect, useRef } from 'react';

export const usePrevious = <T,>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const usePreviousConst = <T,>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref;
};

export default usePrevious;
