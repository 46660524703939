import React, { Suspense, lazy, useEffect } from 'react';

import { Box, CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useCRMIntegConfig } from 'components/modules/connectors/context/CRMIntegrationConfigContext';
import { CompanyMatchingProvider } from 'components/modules/connectors/hooks/useCompanyMatch';
import { DynamicsInstanceSelectView } from 'components/modules/connectors/landing/pages/ConnectionView/DynamicsInstanceSelectView';
import { CrmName, isValidCrmName } from 'components/modules/connectors/types/Crm.d';
import { getCRMName } from 'components/modules/connectors/utils';
import { CRMIcon } from 'components/templates/CompanyCardCRM';
import Frame from 'components/tokens/Frame';
import Icon from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { useDialogContext } from 'hooks/useDialogContext';

import ErrorView from '../../../common/ErrorComponent';
import CRMBusinessImpactTab from './CRMBusinessImpact/CRMBusinessImpactTab';
import CRMOverviewTab from './CRMOverview/CRMOverviewTab';

export type ConnectionViewProps = {};

const CRMCompanies = lazy(() => import('components/features/CRMCompanies'));

export enum ConnectionViewTabSlug {
    Overview = 'overview',
    Insights = 'insights',
    Companies = 'companies',
    BusinessImpact = 'business-impact',
}

export type ConnectionViewTabProps = {
    crmName: CrmName;
};

export type ConnectionViewTabComponent = React.FC<ConnectionViewTabProps>;

export type OverviewTab = {
    name: ConnectionViewTabSlug;
    label: string;
    component: ConnectionViewTabComponent;
};

const overviewTabs: OverviewTab[] = [
    {
        name: ConnectionViewTabSlug.Overview,
        label: 'Overview',
        component: CRMOverviewTab,
    },
    {
        name: ConnectionViewTabSlug.Companies,
        label: 'Companies',
        component: CRMCompanies,
    },
    {
        name: ConnectionViewTabSlug.BusinessImpact,
        label: 'Business impact',
        component: CRMBusinessImpactTab,
    },
];

export const defaultTab = ConnectionViewTabSlug.Overview;
const defaultComponent = overviewTabs[0].component;

const isValidTabSlug = (tab: string | undefined): tab is ConnectionViewTabSlug =>
    overviewTabs.some(({ name }) => tab === name);
const getContentComponent = (tab: string | undefined): ConnectionViewTabComponent => {
    if (isValidTabSlug(tab)) {
        const tabData = Object.values(overviewTabs).find(({ name }) => name === tab);
        if (tabData) {
            return tabData.component;
        }
    }
    return defaultComponent;
};

export const ConnectionView: React.FC<ConnectionViewProps> = () => {
    const { crmName, tab } = useParams();
    const navigate = useNavigate();
    const [{ error }, { initConfig }] = useCRMIntegConfig();

    const handleTabChange = (_event: unknown, tabValue: string) => {
        if (Object.values(ConnectionViewTabSlug).includes(tabValue as ConnectionViewTabSlug)) {
            navigate(`../${tabValue}`);
        }
    };

    useEffect(() => {
        initConfig(crmName as CrmName);
    }, [crmName, initConfig]);

    const dynamicsNoInstanceError = crmName === 'dynamics' && error === 'Error: AUTH_INCOMPLETE: No dynamics url';

    const Content = getContentComponent(tab);
    return (
        <>
            {error && !dynamicsNoInstanceError && <ErrorBanner crm={crmName as CrmName} error={error} />}
            <Frame sx={{ marginBottom: 3, paddingX: 1 }} padding="none">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {crmName && CRMIcon[crmName] && (
                        <Icon type={CRMIcon[crmName]} sx={{ fontSize: 28, marginRight: 1 }} />
                    )}
                    <Tabs value={tab} onChange={handleTabChange}>
                        {overviewTabs
                            // Business impact feature is currently available only for HubSpot
                            .filter(
                                (tab) => !(tab.name === ConnectionViewTabSlug.BusinessImpact && crmName !== 'hubspot'),
                            )
                            .map(({ name, label }) => (
                                <Tab
                                    disabled={!!error}
                                    key={name}
                                    value={name}
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            {label}
                                            {/* This makes the label reserve horizontal space enough for the semibold label,
                                            so that its width stays the same when it's selected and the visible label turns
                                            from normal to semibold. */}
                                            <Box sx={{ height: 0, color: 'transparent', fontWeight: 500 }}>{label}</Box>
                                        </Box>
                                    }
                                    sx={{
                                        minHeight: 60,
                                        paddingY: 1.5,
                                        fontWeight: 400,
                                        '&.Mui-selected': {
                                            fontWeight: 500,
                                        },
                                    }}
                                />
                            ))}
                    </Tabs>
                </Box>
            </Frame>
            {dynamicsNoInstanceError ? (
                <DynamicsInstanceSelectView crm={crmName} onInstanceConfirm={async () => initConfig(crmName)} />
            ) : (
                <Box sx={{ marginBottom: 5 }}>
                    {isValidCrmName(crmName) && (
                        <Suspense
                            fallback={
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </Box>
                            }
                        >
                            <CompanyMatchingProvider crm={crmName}>
                                <Content crmName={crmName} />
                            </CompanyMatchingProvider>
                        </Suspense>
                    )}
                </Box>
            )}
        </>
    );
};

const ErrorBanner: React.FC<{ error: string; crm: CrmName }> = ({ error, crm }) => {
    const { handleDialogOpen } = useDialogContext();

    return (
        <Grid
            item
            xs="auto"
            sx={{
                display: 'flex',
                backgroundColor: 'brandColors.statusError100',
                marginBottom: '24px',
                padding: '20px',
                borderRadius: '8px',
            }}
        >
            <Icon type="Error" color="error.main" sx={{ marginTop: '4px' }} />
            <div style={{ marginLeft: '8px', width: '100%' }}>
                <Typography variant="h6" weight="bold">
                    We can't connect
                </Typography>
                <Typography sx={{ margin: '16px 0' }}>
                    {getCRMName(crm)} had a problem identifying you. Please try again, contact support or check our CRM
                    error guide.
                </Typography>
                <ErrorView
                    customError={{ error: '', error_msg: error }}
                    crm={crm}
                    confirmText="Disconnect"
                    cancelText="Ask support"
                    onConfirm={() => handleDialogOpen('CRM_DISCONNECT_CONFIRM_DIALOG', { crm })}
                    onCancel={() => window.HubSpotConversations?.widget?.open()}
                />
            </div>
        </Grid>
    );
};

export default ConnectionView;
