import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Typography, styled } from '@mui/material';

import CompanyMatchBulbIcon from '../../svg/CompanyMatchBulbIcon';

const TopBanner = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(true);
    return (
        <>
            {isBannerVisible && (
                <ContactRecommendBanner>
                    <Typography color="textSecondary" className="recommendation-text">
                        <CompanyMatchBulbIcon />
                        We recommend contacting us at <b>customersuccess@vainu.io</b> for assistance.
                    </Typography>
                    <div>
                        <StyledCloseIcon onClick={() => setIsBannerVisible(false)} />
                    </div>
                </ContactRecommendBanner>
            )}
        </>
    );
};
export default TopBanner;

const ContactRecommendBanner = styled('div')({
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(47, 128, 237, 0.1)',
    justifyContent: 'space-between',
    borderRadius: 8,
    fontSize: 16,
    color: '#3F3F3F',
    padding: '25px',
    margin: `0px`,
    '& a': {
        textDecoration: 'underline',
        fontWeight: 'bold',
        color: '#3F3F3F',
        margin: '0px 2px',
    },
    '& .recommendation-text': {
        display: 'flex',
        fontSize: 14,
        alignItems: 'center',
        '& b': {
            margin: '0px 2px',
        },
    },
    '& svg': {
        marginRight: 10,
    },
});

const StyledCloseIcon = styled(CloseIcon)({
    color: '#0A0A0A',
    fontSize: 14,
    cursor: 'pointer',
});
