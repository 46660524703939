import { VFC } from 'react';

import Button from 'components/tokens/Button';

import Icon from '../Icon';
import { CloseButtonWrapper } from './styled';

interface DialogCloseButtonProps {
    onClick: (event: React.MouseEvent) => void;
}

export const DialogCloseButton: VFC<DialogCloseButtonProps> = ({ onClick }) => (
    <CloseButtonWrapper>
        <Button
            data-testid="close_button"
            variant="flat"
            size="large"
            startIcon={<Icon type="CloseSmall" color="grey.500" />}
            onClick={onClick}
        />
    </CloseButtonWrapper>
);
