import { AxiosInstance } from 'axios';

export type SnackbarMessage = {
    key: string;
    message: string;
    moreInfo?: string;
    items?: string[];
};

export type PasswordParams = {
    current_password?: string;
    password1: string;
    password2: string;
    non_field_errors?: string;
};

export async function postChangePassword(axios: AxiosInstance, params: PasswordParams) {
    return await axios.post('/api/change_password/', params);
}

export async function updateProfile(axios: AxiosInstance, profileId: string, dataToUpdate: Record<string, string>) {
    return (await axios.patch(`/mapi/profile/${profileId}/`, dataToUpdate)).data;
}

export async function patchProfile(axios: AxiosInstance, dataToUpdate: unknown) {
    return (await axios.patch(`/mapi/profile/`, dataToUpdate)).data;
}
