import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const User: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M5 21H3V20C3.00441 16.1358 6.13583 13.0044 10 13H14C17.8642 13.0044 20.9956 16.1358 21 20V21H19V20C18.9967 17.2399 16.7601 15.0033 14 15H10C7.23995 15.0033 5.00331 17.2399 5 20V21ZM12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C16.9967 9.76005 14.7601 11.9967 12 12ZM12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4Z" />
    </SvgIcon>
);

export default User;
