import React from 'react';

import { cloneDeep } from 'lodash';

import { FilterOperator } from 'api/types/FilterOperators';
import ChipInput from 'components/tokens/ChipInput';
import { SelectOption } from 'components/tokens/select-components/Select';
import useFilterOptionsContext from 'contexts/FilterOptions/FilterOptionsContext';

import { TValuesType, getFilterConfig } from '../../FiltersBaseConfig';
import {
    GroupValues,
    FilterID,
    FilterConfig,
    Operator,
    OperatorValue,
    FilterDefinition,
    LocationFilterProps,
} from '../../FilterTypes';
import { useOperatorFromFilter } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, valueArrayToText, getInnerMostValueInFilterState } from '../utils';
import { LocationFiltersSelect } from './LocationFiltersSelect';
import { useLocationFilterValue } from './useLocationFilterValue';

const { baseConfig, getInitValueConfigDefault } = getFilterConfig(FilterID.visiting_city);
export type TValues = TValuesType<typeof baseConfig, string[]>;

const config: FilterConfig<TValues> = {
    ...baseConfig,
    ...getInitValueConfigDefault(FilterOperator.IN, []),
};

export const visitingCityFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.location_visiting_address,
        label: config.label,
        fields: config.fields,
        render: (props: LocationFilterProps<TValues>) => <VisitingCity {...props} />,
        valueToText: valueArrayToText(config.fields[0]),
        config,
        createFilter: () => cloneDeep(config.initValue),
    },
};

const operators: Operator[] = [
    FilterOperator.IN,
    `${FilterOperator.NOT}${FilterOperator.CONTAINS}`,
    FilterOperator.STARTSWITH,
    FilterOperator.CONTAINS,
].map((value) => formatOperatorValue(value as OperatorValue));

export const VisitingCity: React.FC<LocationFilterProps<TValues>> = ({
    filter,
    uuid,
    groupUUID,
    sameFiltersState = [],
    removeFilter,
    apply,
    forceOpen,
    onClose,
    onOpen,
    warningLevel,
}) => {
    const { citiesOptions } = useFilterOptionsContext();
    const { operator, handleOperatorUpdate } = useOperatorFromFilter(filter, ({ key, value, valuePath }) => {
        apply({ key, value, valuePath });
    });

    const { value, onChange } = useLocationFilterValue({ filter, apply });
    // assuming that the depth will always be 1 to flatten otherwise use `map` and `flat(depth)`
    const disabledFilterOptions = sameFiltersState.flatMap((sameState) =>
        getInnerMostValueInFilterState(sameState, filter.valuePath),
    );

    /**
     * If the field name is nested object, use `getFieldState` to access the error state.
     * Otherwise, it can be accessed using `formState: { errors }`
     */
    //const duplicateError = getFieldState(fullValuePath).error;

    const renderInput = () => {
        if (citiesOptions?.length) {
            const optionsToDisable = sameFiltersState.flatMap((sameState) =>
                getInnerMostValueInFilterState(sameState, filter.valuePath),
            );
            const disabledOptions = citiesOptions.filter((option) => optionsToDisable.includes(option.value));

            const selectedOptions = citiesOptions.filter((item) => value.includes(item.value as string));
            return (
                <LocationFiltersSelect
                    placeholder={selectedOptions?.length ? 'Or...' : 'Select'}
                    multiple={true}
                    width="100%"
                    options={citiesOptions}
                    disabledOptions={disabledOptions}
                    value={selectedOptions}
                    optionMatchFrom="start"
                    onValueChange={(options) => onChange((options as SelectOption[]).map((o) => o.value))}
                    forceOpen={forceOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    warningLevel={warningLevel}
                />
            );
        } else {
            return (
                <ChipInput
                    placeholder={value?.length ? 'Or...' : 'City...'}
                    width="100%"
                    limitTags={2}
                    values={value}
                    disabledOptions={disabledFilterOptions as string[]}
                    setValues={onChange}
                />
            );
        }
    };
    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: renderInput(),
    };
    return <FilterTemplate {...props} />;
};
