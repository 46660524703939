import { alpha } from '@mui/material';
import { Components } from '@mui/material/styles/components';

import { palette } from './palette';

const components: Components = {
    MuiButton: {
        defaultProps: {
            disableElevation: true,
        },
        styleOverrides: {
            root: {
                minWidth: 40,
                minHeight: 40,
                fontSize: 14,
                textTransform: 'none',
                padding: '6px 12px',
            },
            sizeLarge: {
                minWidth: 48,
                minHeight: 48,
                fontSize: 16,
                textTransform: 'uppercase',
                padding: '6px 16px',
            },
            sizeSmall: {
                minWidth: 32,
                minHeight: 32,
                fontSize: 12,
                padding: '6px 8px',
            },
        },
    },

    MuiList: {
        defaultProps: {
            disablePadding: true,
        },
    },
    MuiMenu: {
        styleOverrides: {
            paper: {
                boxShadow: '2px 2px 12px -4px rgba(0,0,0,0.18)',
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontSize: 14,
                color: palette.text.primary,
                '&&': {
                    minHeight: 40,
                },
                '&:hover': {
                    background: palette.field.background,
                },
            },
        },
    },

    MuiTextField: {
        styleOverrides: {
            root: {
                fontSize: 14,
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: { padding: 0 },
            input: { padding: '14px 12px' },
            notchedOutline: {
                borderColor: palette.border,
            },
            inputSizeSmall: {
                padding: '10px 12px',
            },
        },
    },

    MuiPaper: {
        styleOverrides: {
            root: {
                borderColor: palette.border,
            },
            elevation1: {
                boxShadow: '0px 2px 4px rgba(138, 138, 138, 0.05), 0px 2px 4px rgba(10, 10, 10, 0.05)',
            },
            elevation2: {
                boxShadow: '0px 4px 24px rgba(10, 10, 10, 0.05)',
            },
            elevation3: {
                boxShadow: '0px 4px 12px rgba(10, 10, 10, 0.15)',
            },
            elevation4: {
                boxShadow: '0px 16px 16px -8px rgba(10, 10, 10, 0.1)',
            },
            elevation5: {
                boxShadow: '0px 16px 36px -8px rgba(10, 10, 10, 0.15), 0px 1px 1px rgba(10, 10, 10, 0.1)',
            },
            elevation6: {
                boxShadow: '0px 32px 32px rgba(10, 10, 10, 0.05), 0px 16px 16px rgba(10, 10, 10, 0.05)',
            },
        },
    },

    MuiLink: {
        styleOverrides: {
            root: {
                color: 'inherit',
                '&:hover': {
                    color: palette.link,
                },
            },
        },
    },

    MuiTabs: {
        styleOverrides: {
            indicator: {
                backgroundColor: palette.text.primary,
                height: 5,
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: 0,
                color: palette.text.subtle,
                '&.Mui-selected': {
                    color: palette.text.primary,
                },
                '&&': {
                    minWidth: 80,
                },
                padding: '6px 24px',
            },
        },
    },

    MuiChip: {
        styleOverrides: {
            root: {
                fontSize: 13,
                fontWeight: 500,
                backgroundColor: palette.field.background,
            },
        },
        variants: [
            {
                props: { size: 'big' },
                style: {
                    border: `1px solid ${palette.border}`,
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: 1.4,
                    backgroundColor: palette.background.paper,
                },
            },
        ],
    },
    MuiTable: {
        styleOverrides: {
            root: {
                borderRadius: 8,
                borderCollapse: 'separate',
                border: '1px solid',
                borderColor: palette.border,
                '& > .MuiTableBody-root > .MuiTableRow-root:last-child > .MuiTableCell-root': {
                    borderBottom: 0,
                },
            },
        },
    },

    MuiTableCell: {
        styleOverrides: {
            root: {
                fontSize: 12,
                padding: '8px 8px 8px 16px',
                borderBottomColor: palette.border,
            },
            head: {
                fontWeight: 500,
                color: palette.text.subtle,
                lineHeight: 1.4,
            },
            body: {
                fontSize: 12,
            },
        },
    },
    MuiTableHead: {
        styleOverrides: {
            root: {
                background: palette.field.background,
            },
        },
    },
    MuiTableBody: {
        styleOverrides: {
            root: {
                fontSize: 12,
            },
        },
    },

    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                fontSize: 12,
                backgroundColor: palette.button.background,
                color: palette.button.text,
            },
            arrow: {
                color: palette.button.background,
            },
        },
        defaultProps: {
            arrow: true,
        },
    },

    MuiStepper: {
        styleOverrides: {
            horizontal: {
                maxWidth: 500,
                width: '100%',
                padding: '0 24px',
            },
        },
    },
    MuiStepLabel: {
        styleOverrides: {
            root: {
                '&.Mui-active': {
                    fontWeight: 500,
                },
                '&.Mui-completed': {
                    fontWeight: 500,
                },
            },
            label: {
                fontSize: 13,
            },
        },
    },
    MuiStepIcon: {
        styleOverrides: {
            root: {
                color: palette.field.background,
            },
            text: {
                fontSize: 12,
                fontWeight: 500,
            },
        },
    },

    MuiIcon: {
        defaultProps: {},
        styleOverrides: {
            root: {
                // fontSize: '24px',
                lineHeight: 1,
            },
        },
    },

    MuiSvgIcon: {
        variants: [
            {
                props: { fontSize: 'mini' },
                style: {
                    fontSize: '1rem',
                },
            },
        ],
    },

    MuiBadge: {
        styleOverrides: {
            badge: {
                fontWeight: 500,
                fontSize: 11,
            },
        },
    },

    MuiRadio: {
        defaultProps: {
            size: 'small',
        },
        styleOverrides: {
            root: {
                width: 16,
                height: 16,
                color: palette.checkbox.empty,
                '&.Mui-checked': {
                    color: palette.checkbox.checked,
                },
                '&.Mui-disabled': {
                    color: palette.checkbox.disabled,
                },
            },
        },
    },

    MuiPaginationItem: {
        styleOverrides: {
            root: {
                color: palette.common.black,
                fontWeight: 500,
            },
        },
    },

    MuiAlert: {
        styleOverrides: {
            root: {
                padding: '4px 8px',
                alignItems: 'center',
                borderRadius: 3,
            },
            icon: {
                padding: 0,
                fontSize: 14,
                marginRight: 6,
            },
            message: {
                fontSize: 10,
                fontWeight: 500,
                textTransform: 'uppercase',
                padding: 0,
            },
            standardWarning: {
                backgroundColor: palette.warning.light,
                color: palette.warning.main,
            },
            standardError: {
                backgroundColor: palette.error.light,
                color: palette.error.main,
            },
            standardInfo: {
                backgroundColor: palette.info.light,
                color: palette.info.main,
            },
            standardSuccess: {
                backgroundColor: palette.success.light,
                color: palette.success.main,
            },
        },
    },

    MuiBackdrop: {
        styleOverrides: {
            root: {
                background: alpha(palette.brandColors.onyx, 0.56),
            },
            invisible: {
                background: 'none',
            },
        },
    },
    MuiSkeleton: {
        styleOverrides: {
            root: {
                backgroundColor: palette.field.background,
            },
            rounded: {
                borderRadius: 8,
            },
        },
    },

    MuiSelect: {
        styleOverrides: {
            select: {
                fontSize: 14,
            },
        },
        defaultProps: {
            MenuProps: {
                elevation: 1,
            },
        },
    },
};

export default components;
