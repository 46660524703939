import React from 'react';

import { Box, Tooltip } from '@mui/material';

import Icon, { IconType } from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Typography from 'components/tokens/Typography';

type SectionProps = {
    icon: IconType;
    iconTitle: string;
    label?: string;
    mode: 'default' | 'compact';
    children?: React.ReactNode;
} & (
    | {
          type: 'title';
          /**
           * Only to be passed with `type = 'title'`
           */
          isExported: boolean;
          linkToCRM?: never;
      }
    | {
          type: 'link';
          /**
           * Need to pass when `type === 'link'`
           */
          linkToCRM: string | undefined;
          isExported?: never;
      }
    | {
          type: 'owner' | 'deal';
          isExported?: never;
          linkToCRM?: never;
      }
);

export const Section: React.FC<SectionProps> = (props) => {
    const { icon, label, iconTitle, type, mode, isExported, linkToCRM, children } = props;
    const typographyModeVariant = mode === 'compact' ? 'tiny' : 'small';
    const typographyVariant = type === 'title' ? 'body1' : typographyModeVariant;

    const iconSize = type === 'title' ? 'medium' : mode === 'compact' ? 'mini' : 'small';

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                gap: '6px',
            }}
        >
            <Tooltip title={iconTitle} placement="top">
                {/* to hold ref as svg doesn't hold it */}
                <span style={{ display: 'flex' }}>
                    <Icon type={icon} fontSize={iconSize} />
                </span>
            </Tooltip>
            {type === 'deal' ? (
                children
            ) : type === 'link' ? (
                linkToCRM ? (
                    <Link href={linkToCRM} toNewTab="nofollow" variant={typographyModeVariant}>
                        {label}
                    </Link>
                ) : (
                    <Typography component="p" variant={typographyVariant}>
                        No link available.
                    </Typography>
                )
            ) : (
                <Typography
                    component="p"
                    weight={type === 'title' ? 'bold' : 'normal'}
                    variant={typographyVariant}
                    sx={{
                        wordBreak: 'break-all',
                    }}
                >
                    {label}
                </Typography>
            )}
            {isExported && (
                <Icon
                    type="CircleCheckOutline"
                    fontSize="small"
                    sx={{ position: 'absolute', right: 0, color: 'success.secondary' }}
                />
            )}
        </Box>
    );
};
