import React, { ReactElement, useMemo } from 'react';

import { SxProps, Tooltip, Typography } from '@mui/material';

import Icon, { IconType } from 'components/tokens/Icon';

import { ConfirmationContent } from '../PopoverWithAction/PopoverContent';
import { StyledMenuItem } from './styled';

export type ListMenuItemProps<T> = {
    label: string;
    icon?: IconType | ReactElement;
    color?: string;
    endIcon?: IconType;
    endIconColor?: string;
    action: T;
    confirmationContent?: ConfirmationContent;
    sx?: SxProps;
    selected?: boolean;
    onClick?: (event: React.MouseEvent<HTMLLIElement>, action: T) => void;
    children?: ListMenuItemProps<T>[];
    staticOptions?: ListMenuItemProps<T>[];
    disabled?: boolean;
    /** search for children. default true */
    searchable?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TargetListMenuItem = React.forwardRef<HTMLLIElement, ListMenuItemProps<any>>(
    <T,>(
        {
            label,
            icon,
            endIcon,
            endIconColor,
            color,
            action,
            sx,
            selected = false,
            onClick,
            disabled,
        }: ListMenuItemProps<T>,
        ref: React.Ref<HTMLLIElement>,
    ) => {
        const iconComponent = useMemo(() => {
            if (typeof icon === 'string') {
                return <Icon sx={{ marginRight: 1 }} type={icon} fontSize="mini" color={color} />;
            }
            if (React.isValidElement(icon)) {
                return icon;
            }
            return null;
        }, [icon, color]);

        return (
            <StyledMenuItem
                disableGutters
                onClick={(event: React.MouseEvent<HTMLLIElement>) => onClick && onClick(event, action)}
                sx={sx}
                selected={selected}
                ref={ref}
                disabled={disabled}
            >
                {iconComponent}
                {/**
                 * Typography component with noWrap to workaround text truncate issue
                 * https://mui.com/material-ui/react-menu/#limitations
                 */}
                <Tooltip title={label} disableInteractive>
                    <Typography variant="inherit" noWrap>
                        {label}
                    </Typography>
                </Tooltip>
                {endIcon && (
                    <Icon sx={{ marginLeft: 'auto' }} type={endIcon} fontSize="mini" color={endIconColor || color} />
                )}
            </StyledMenuItem>
        );
    },
);

export default TargetListMenuItem;
