import { mapKeys, isEmpty, includes, every, map, filter, range, uniqBy, escapeRegExp } from 'lodash';
import { isNotEmpty } from 'utilities';

import { TreeRawData, RootItems, TreeNode } from './types';

const buildFlatList = (data: TreeRawData, rootItemsWithDirectChildren: RootItems, groupType?: 'dot-separated') => {
    const result: TreeNode[] = [];

    mapKeys(rootItemsWithDirectChildren, (childKeys, rootItemKey) => {
        result.push({ id: rootItemKey } as TreeNode);
        childKeys.forEach((childKey: string) => {
            if (groupType === 'dot-separated') {
                const parentId = childKey.slice(0, childKey.lastIndexOf('.'));
                result.push({ id: childKey, parentId } as TreeNode);
            } else {
                result.push({ id: childKey, parentId: rootItemKey } as TreeNode);
            }
        });
    });

    const consumedKeys = new Set();
    data.forEach((entry) => {
        const id = entry[0];
        const elementIndex = result.findIndex((obj) => obj.id === id);
        if (groupType === 'dot-separated') {
            const segments = id.split('.');
            const parentId = segments.length > 1 ? segments[segments.length - 2] : undefined;

            if (elementIndex !== -1) {
                result[elementIndex] = {
                    ...result[elementIndex],
                    label: entry[1],
                    depth: segments.length - 1,
                    tooltip: entry?.[3],
                    args: entry?.[4],
                };
            } else {
                result.push({
                    id,
                    parentId,
                    label: entry[1],
                    depth: segments.length - 1,
                    tooltip: entry?.[3],
                    args: entry?.[4],
                } as TreeNode);
            }
        } else {
            consumedKeys.add(id);
            let parentId = id.length > 2 ? id.slice(0, -1) : undefined;
            while (parentId && parentId?.length > 1 && !consumedKeys.has(parentId)) {
                parentId = parentId.slice(0, -1);
            }

            if (elementIndex !== -1) {
                result[elementIndex] = {
                    ...result[elementIndex],
                    label: entry[1],
                    depth: id.length - 1,
                    tooltip: entry?.[3],
                };
            } else {
                result.push({ id, parentId, label: entry[1], depth: id.length - 1, tooltip: entry?.[3] } as TreeNode);
            }
        }
    });
    return result;
};

export const buildTreeList = (
    data: TreeRawData,
    rootItemsWithDirectChildren: RootItems,
    groupType?: 'dot-separated',
): TreeNode[] => {
    // map data into flatList where each item has information
    // about their key/value pairs and id of their parent
    const flatList = buildFlatList(data, rootItemsWithDirectChildren, groupType);
    const tree: TreeNode[] = [];
    const childOf = {} as { [key: string]: TreeNode[] };
    // build hierarchical list from flat list
    // using items parentId information
    const parents: TreeNode[] = [];
    flatList.forEach((item) => {
        const { id, parentId } = item;
        childOf[id] = childOf[id] || [];
        item.children = childOf[id];
        parentId ? (childOf[parentId] = childOf[parentId] || []).push(item) : parents.push(item);
    });
    parents.forEach((parent) => {
        if (parent?.children?.length) {
            tree.push({
                ...parent,
                children: parent.children.filter((i) => i?.children?.length || (i?.label && i?.id)),
            });
        }
    });
    return tree;
};

// function to get all ids of items descendants
export const getChildIds = (children: TreeNode[], excludeParents = false): string[] => {
    const childIds: string[] = [];
    if (isEmpty(children)) return childIds;
    children.forEach((item) => {
        if (isNotEmpty(item.children)) {
            if (!excludeParents) {
                childIds.push(item.id);
            }
            const childResults = getChildIds(item.children, excludeParents);
            if (isNotEmpty(childResults)) {
                childResults.forEach((childId) => {
                    childIds.push(childId);
                });
            }
        } else {
            childIds.push(item.id);
        }
    });
    return childIds;
};

export const getChildItems = (children: TreeNode[], excludeParents = false): TreeNode[] => {
    const childItems: TreeNode[] = [];
    children.forEach((item) => {
        if (!excludeParents || !item.children.length) {
            childItems.push(item);
        }

        if (isEmpty(item.children)) {
            return;
        }

        const childResults = getChildItems(item.children);
        childResults.forEach((child) => {
            if (!excludeParents || !child.children.length) {
                childItems.push(child);
            }
        });
    });

    return childItems;
};

const getAllChildrenInSameDepth = (parent: TreeNode, depth: number) => {
    const children: TreeNode[] = [];
    parent.children.forEach((child) => {
        if (child.depth === depth) {
            return children.push(child);
        }
        if (child.children.length && child.depth <= depth) {
            const innerChild = getAllChildrenInSameDepth(child, depth);
            children.push(...innerChild);
        }
    });
    return children;
};

export const getParentItems = (children: TreeNode[]): TreeNode[] => {
    let parent: TreeNode[] = [...children];
    for (let index = 0; index < children.length; index++) {
        const isChild = parent.some((p) => p.parentId === children[index].id);
        const isAllChildrenSelectedInSameDepth = getAllChildrenInSameDepth(children[index], children[index].depth + 1);
        if (isChild) {
            const selectedInParentCount = children.filter((p) => p.parentId === children[index].id);
            if (selectedInParentCount.length !== isAllChildrenSelectedInSameDepth.length) {
                parent = parent.filter((p) => p.id !== children[index].id);
            } else {
                parent = parent.filter((p) => p.parentId !== children[index].id);
            }
        }
    }
    return parent;
};

export const findMatchingItems = (data: TreeNode[], term: string): { matches: string[]; paths: string[] } => {
    // ids of items that match the search term
    const matches: string[] = [];

    // ids of parent rows of matching items
    // that can be used for example to open tree hierarchy to desired
    // results after filtering
    const paths: string[] = [];
    if (isEmpty(data)) return { matches, paths };
    data.forEach((item: TreeNode) => {
        if (includes(item?.label?.toLowerCase(), term.toLowerCase())) {
            matches.push(item.id);
            const allChildIds = getChildIds(item.children);
            allChildIds.forEach((childId) => {
                matches.push(childId);
            });
        } else {
            const { matches: childResults, paths: childPaths } = findMatchingItems(item.children, term);
            if (isNotEmpty(childResults)) {
                paths.push(item.id);
                matches.push(item.id);
                childResults.forEach((childId) => {
                    matches.push(childId);
                });
            }
            if (isNotEmpty(childPaths)) {
                childPaths.forEach((childId) => {
                    paths.push(childId);
                });
            }
        }
    });

    return { matches, paths };
};

export const hasSelectedDescendants = (children: TreeNode[], selectedItems: string[], result = false): boolean => {
    if (isEmpty(selectedItems) || isEmpty(children) || result) return result;
    children.forEach((item) => {
        if (includes(selectedItems, item.id)) {
            result = true;
        }
        if (isNotEmpty(item.children)) {
            if (hasSelectedDescendants(item.children, selectedItems, result)) {
                result = true;
            }
        }
    });
    return result;
};

export const hasAllDescendantsSelected = (
    node: TreeNode,
    selectedItems: string[],
    excludeParents = false,
    disabledItems: string[] = [],
): boolean => {
    const childIds = getChildIds(node.children, excludeParents);
    return every(childIds, (childId: string) => selectedItems.includes(childId) || disabledItems.includes(childId));
};

// node: selected or deselected tree item
// selectedItems: array of currently selected items
// cb: callback function to consume result of handleToggle function e.g setState
export const handleToggle = (
    node: TreeNode,
    selectedItems: TreeNode[],
    excludeParents: boolean,
    disabledItems: string[],
    cb: (result: TreeNode[]) => void,
) => {
    const { id, children, parentId } = node;
    const childIds = getChildIds(node.children, excludeParents);
    const childItems = getChildItems(node.children, excludeParents);

    const selectedIds = map(selectedItems, (selectedItem) => selectedItem.id);

    const deselectItems = (valuesToRemove: string[]) =>
        filter(selectedItems, (item) => !valuesToRemove.includes(item.id));

    const getParentIds = (parentId?: string): string[] => {
        const parentIds: string[] = [];
        const foundParent = selectedItems.find((item) => item.id === parentId);
        if (parentId) {
            parentIds.push(parentId);
        }
        if (foundParent?.parentId) {
            parentIds.push(...getParentIds(foundParent.parentId));
        }
        return parentIds;
    };

    const handleParentItemSelection = (node: TreeNode) => {
        if (hasAllDescendantsSelected(node, selectedIds, excludeParents, disabledItems)) {
            // deselect item and its children as well as its parent
            const result = deselectItems([...childIds, id, ...getParentIds(parentId)]);
            cb(result);
        } else {
            const result = [...selectedItems, ...childItems];

            if (!excludeParents || !children) {
                result.push(node);
            }

            cb(uniqBy(result, 'id').filter((node) => !disabledItems.includes(node.id)));
        }
    };

    if (isNotEmpty(children)) {
        return handleParentItemSelection(node);
    }

    // if item is not selected add it and its children to selected items
    if (!includes(selectedIds, id)) {
        const result = [...selectedItems, ...childItems, node];
        cb(uniqBy(result, 'id').filter((node) => !disabledItems.includes(node.id)));
    } else {
        // if item is selected, deselect item and its children as well as its parent
        const result = deselectItems([...childIds, id, ...getParentIds(parentId)]);
        cb(result);
    }
};

const getValues = (values: string[]) => {
    return map(range(parseInt(values[0]), parseInt(values[1]) + 1), (v) => {
        return v.toString();
    });
};

export const industryRootItemsWithDirectChildren = {
    A: ['01', '02', '03'],
    B: ['05', '06', '07', '08', '09'],
    C: getValues(['10', '33']),
    D: ['35'],
    E: getValues(['36', '39']),
    F: getValues(['41', '43']),
    G: getValues(['45', '47']),
    H: getValues(['49', '53']),
    I: getValues(['55', '56']),
    J: getValues(['58', '63']),
    K: getValues(['64', '66']),
    L: ['68'],
    M: getValues(['69', '75']),
    N: getValues(['77', '82']),
    O: ['84'],
    P: ['85'],
    Q: getValues(['86', '88']),
    R: getValues(['90', '93']),
    S: getValues(['94', '96']),
    T: getValues(['97', '98']),
    U: ['99'],
    X: ['00'],
};

export const boldResultPart = (result: string, filterValue: string | undefined): [string[], string[]] => {
    if (typeof filterValue !== 'undefined') {
        const re = new RegExp(escapeRegExp(filterValue), 'ig');
        const results = result.replace(/- /g, '').replace(/\./g, '').split(re);
        return [results, [...result.matchAll(re)].map((x) => x[0])];
    }
    return [[], []];
};

export const getDottedRootItems = (fields: TreeRawData) => {
    const items: { [key: string]: string[] } = {};
    for (const [key] of fields) {
        const split = key.split('.');

        if (split.length < 2) {
            continue;
        }

        const [category] = split;
        if (!items[category]) {
            items[category] = [];
        }
        items[category].push(key);
    }
    return items;
};
