import React, { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { useSetRecoilState } from 'recoil';
import { isNotEmpty } from 'utilities';

import { getAccount } from 'api/account';
import { getApplicationStore } from 'api/applicationstore';
import { Account, InvitedUser, Team } from 'api/types/Account';
import { ApplicationStore, ApplicationStoreConnections, IntegrationKey, Profile } from 'api/types/ApplicationStore';
import { useAuthContext } from 'contexts/AuthContext';
import { useAxiosContext } from 'contexts/AxiosContext';
import reshapedAccountAtom from 'store/reshapedAccountAtom';

interface PermissionContextProps {
    isApplicationStoreLoading: boolean;
    isAccountLoading: boolean;
    isAccountAdmin: boolean;
    isVainuAdmin: boolean;
    isDomainConnector: boolean;
    accountAdmins: string[];
    productPermissions: string[];
    countryPermissions: string[];
    integrations: IntegrationKey[];
    accountProductPermissions: string[];
    accountCountryPermissions: string[];
    profile: Profile;
    /** Use this to check if the user has an effective permission (user or account) enabled. */
    hasProductPermission: (value: string) => boolean;
    hasIntegration: (value: IntegrationKey) => boolean;
    hasCountryPermission: (value: string) => boolean;
    hasAccountOrUserCountryPermission: (value: string) => boolean;
    /** Use this to check if the user or the account has a permission.
     * Does not check if the user has the permission disabled. In most cases you should not use this. */
    hasAccountOrUserPermission: (value: string) => boolean;
    refetchApplicationStore: () => void;
    refetchAccount: () => void;
    connections: ApplicationStoreConnections[];
}

const PermissionContext = React.createContext<PermissionContextProps>({} as PermissionContextProps);

const fetchApplicationStore = async (axios: AxiosInstance) => {
    const { data } = await getApplicationStore(axios);
    return data;
};

const fetchAccount = async (axios: AxiosInstance) => {
    const { data } = await getAccount(axios, true);
    return data;
};

const PermissionProvider = ({ children }: { children: React.ReactNode }) => {
    const axios = useAxiosContext();
    const { authStatus } = useAuthContext();
    const setReshapedAccount = useSetRecoilState(reshapedAccountAtom);
    const {
        isLoading: isApplicationStoreLoading,
        data: applicationStoreFromServer = {} as ApplicationStore,
        refetch: refetchApplicationStore,
    } = useQuery({
        queryKey: ['applicationStore'],
        queryFn: () => fetchApplicationStore(axios),
        enabled: authStatus === 'logged-in',
    });

    const {
        isLoading: isAccountLoading,
        data: accountFromServer = {} as Account,
        refetch: refetchAccount,
    } = useQuery({
        queryKey: ['account'],
        queryFn: () => fetchAccount(axios),
        enabled: authStatus === 'logged-in',
    });

    useEffect(() => {
        if (!accountFromServer.id) {
            return;
        }
        const users: Team[] = [];
        const invitedUsers: InvitedUser[] = [];

        if (isNotEmpty(accountFromServer.admins)) {
            accountFromServer.admins.forEach((admin) => {
                const tempUser: Team = { ...admin, privilege: 'admin' };
                users.push(tempUser);
            });
        }
        if (isNotEmpty(accountFromServer.users)) {
            accountFromServer.users.forEach((user) => {
                const tempUser: Team = { ...user, privilege: 'user' };
                users.push(tempUser);
            });
        }
        if (isNotEmpty(accountFromServer.invitetokens)) {
            accountFromServer.invitetokens.forEach((invite) => {
                const tempInvite: InvitedUser = { ...invite, privilege: 'pending' };
                invitedUsers.push(tempInvite);
            });
        }

        setReshapedAccount((old) => ({
            ...old,
            users,
            invitedUsers,
            userLimit: accountFromServer.userlimit,
            accountId: accountFromServer.id,
        }));
    }, [accountFromServer, setReshapedAccount]);

    const productPermissions = useMemo(() => {
        return applicationStoreFromServer?.enabledpurchase ? applicationStoreFromServer.enabledpurchase.split(',') : [];
    }, [applicationStoreFromServer?.enabledpurchase]);

    const integrations = useMemo(() => {
        const _integrations = applicationStoreFromServer?.integrations || [];
        return _integrations;
    }, [applicationStoreFromServer?.integrations]);

    const isAccountAdmin = applicationStoreFromServer?.accountadmin ?? false;

    const isVainuAdmin = applicationStoreFromServer?.isAdmin ?? false;

    const isDomainConnector = useMemo(() => productPermissions.includes('domain_connector'), [productPermissions]);

    const countryPermissions = useMemo(() => {
        const countryPermissionCodes = applicationStoreFromServer?.countryPermissionCodes || [];
        return countryPermissionCodes.map((i) => i[0]);
    }, [applicationStoreFromServer?.countryPermissionCodes]);

    const connections = useMemo(() => {
        return applicationStoreFromServer.connections;
    }, [applicationStoreFromServer.connections]);

    const accountProductPermissions = useMemo(() => {
        return accountFromServer?.enabledpurchase || [];
    }, [accountFromServer?.enabledpurchase]);

    const accountCountryPermissions = useMemo(() => {
        return accountFromServer?.countries || [];
    }, [accountFromServer?.countries]);

    const accountAdmins = useMemo(() => {
        return accountFromServer?.admins?.map((admin) => admin.email) ?? [];
    }, [accountFromServer?.admins]);

    const profile: Profile = useMemo(() => {
        return {
            userName: applicationStoreFromServer?.userName,
            accountname: applicationStoreFromServer?.accountname,
            profileid: applicationStoreFromServer?.profileid,
            country: applicationStoreFromServer?.country,
            currency_code: applicationStoreFromServer?.currency_code,
            uiLanguage: applicationStoreFromServer?.uiLanguage,
            use_advanced_ui: applicationStoreFromServer?.use_advanced_ui,
            segment: applicationStoreFromServer?.segment,
            userID: applicationStoreFromServer?.userID,
            onboarding_status: applicationStoreFromServer?.onboarding_status,
            export_limit: applicationStoreFromServer?.export_limit,
            accountadmin: applicationStoreFromServer?.accountadmin,
        };
    }, [applicationStoreFromServer]);

    const hasProductPermission = useCallback(
        (value: string) => {
            return productPermissions.includes(value);
        },
        [productPermissions],
    );

    const hasIntegration = useCallback(
        (value: IntegrationKey) => {
            return integrations.includes(value);
        },
        [integrations],
    );

    const hasCountryPermission = useCallback(
        (value: string) => {
            return countryPermissions.includes(value);
        },
        [countryPermissions],
    );

    const hasAccountOrUserCountryPermission = useCallback(
        (value: string) => {
            return countryPermissions.includes(value) || accountCountryPermissions.includes(value);
        },
        [countryPermissions, accountCountryPermissions],
    );

    const hasAccountOrUserPermission = useCallback(
        (value: string) => {
            return productPermissions.includes(value) || accountProductPermissions.includes(value);
        },
        [productPermissions, accountProductPermissions],
    );

    const value = useMemo(
        () => ({
            isApplicationStoreLoading,
            isAccountLoading,
            productPermissions: productPermissions,
            isAccountAdmin,
            isVainuAdmin,
            isDomainConnector,
            integrations,
            countryPermissions,
            accountCountryPermissions,
            profile,
            hasProductPermission,
            hasIntegration,
            hasCountryPermission,
            hasAccountOrUserPermission,
            hasAccountOrUserCountryPermission,
            accountProductPermissions,
            accountAdmins,
            refetchApplicationStore,
            refetchAccount,
            connections,
        }),
        [
            isApplicationStoreLoading,
            accountAdmins,
            accountCountryPermissions,
            accountProductPermissions,
            connections,
            countryPermissions,
            hasAccountOrUserCountryPermission,
            hasAccountOrUserPermission,
            hasCountryPermission,
            hasIntegration,
            hasProductPermission,
            integrations,
            isAccountAdmin,
            isAccountLoading,
            isDomainConnector,
            isVainuAdmin,
            productPermissions,
            profile,
            refetchAccount,
            refetchApplicationStore,
        ],
    );

    return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>;
};

export { PermissionContext, PermissionProvider };
