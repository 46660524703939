import { useTrademarks } from 'api/trademarks';
import RegisterTags from 'components/features/Registers/RegisterTags';
import LicenseTable from 'components/features/Registers/Tables/LicenseTable';
import TrademarkTable from 'components/features/Registers/Tables/TrademarkTable';

import { TabContent, CompanyTabProps } from '..';

export const RegistersTab: React.VFC<CompanyTabProps> = ({ data, businessId }) => {
    const { isSuccess, data: trademarks } = useTrademarks(businessId);

    return (
        <TabContent>
            {!!data.prospect_licenses?.length && <LicenseTable tableData={data.prospect_licenses} />}
            {isSuccess && trademarks && trademarks.length > 0 && <TrademarkTable tableData={trademarks} />}
            {data.facts && data.facts.some((fact) => fact.type === 'register') && <RegisterTags tags={data.facts} />}
        </TabContent>
    );
};

export default RegistersTab;
