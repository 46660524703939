import React from 'react';

import { styled } from '@mui/material';

import { getImage } from 'components/modules/profiles/Profile/utils';
import Avatar, { AvatarProps } from 'components/tokens/Avatar';

import LetterLogoGenerator from '../LetterLogoGenerator';

export type CompanyLogoProps = Omit<AvatarProps, 'children'> & {
    logo?: string | null;
    company_name?: string | null;
};

const StyledImg = styled('img')({
    display: 'block',
});

export const CompanyLogo: React.FC<CompanyLogoProps> = ({ logo, company_name, skeleton = false, ...props }) =>
    skeleton ? (
        <Avatar skeleton {...props} border={false} />
    ) : (
        <Avatar {...props}>
            {logo ? (
                <StyledImg src={getImage(logo)} alt={company_name ?? ''} />
            ) : (
                <LetterLogoGenerator id={company_name} name={company_name} />
            )}
        </Avatar>
    );
export default CompanyLogo;
