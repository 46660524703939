import React, { ReactNode, useMemo, useState } from 'react';

import { Box, Skeleton, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { get } from 'lodash';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import OverflowContainer from 'components/layout/OverflowContainer';

import { SortableTableColumnProps } from './SortableTableColumn';
import SortableTableHeaderCell from './SortableTableHeaderCell';
import { defaultCompareFn, getCellAlign, numberCompareFn } from './utils';

// Note: it's a base for the table data, not a database.
export type TableDataBase = Record<string, unknown>;

type Column<T extends TableDataBase> = React.ReactElement<SortableTableColumnProps<T>>;
export type DisableableColumn<T extends TableDataBase> = Column<T> | undefined | null | false;

export type SortableTableProps<T extends TableDataBase> = {
    /** Any type of array data which contains keyed fields as table columns. */
    data: T[];
    /** Only allows one type of children, &lt;SortableTableColumn&gt;. Use them to configure which columns to show. */
    children: DisableableColumn<T> | DisableableColumn<T>[];
    /** Key of data row object to sort by initially.*/
    defaultSortColumn?: keyof T;
    /** The order in which the default column is sorted, defaults to ascending. */
    defaultSortOrder?: 'asc' | 'desc';
    /** This is just used for React's array map keys. Should contain unique value for each data row.
     * Always coerced to string. */
    rowKeyField: keyof T;
    /** The locale used for default sorting comparison functions. Affects e.g. if Ä is considered A or to go after Z. Defaults to 'en'. Should be set on Nordic countries' data. */
    sortLocale?: string;
    /** Sx props to override the container element styling. */
    sx?: SxProps<Theme>;
    /** Sx props to override the table element styling. */
    tableSx?: SxProps<Theme>;
    /** Sx props to override the styling on the table body rows. */
    rowSx?: SxProps<Theme>;
    /** Sx props to override the styling on the table body rows. */
    rowSxGetter?: (row: T) => SxProps;
    /** Sx props to override the styling on the table body cells. */
    cellSx?: SxProps<Theme>;
    /** Sx props to override the the styling on the header row. */
    headerRowSx?: SxProps<Theme>;
    /** Sx props to override the the styling on the header row cells. */
    headerCellSx?: SxProps<Theme>;
    /** Virtualizes a table using react-window. */
    virtualized?: boolean;
    /** Table height. Defaults to 480 with virtualized tables. With non-virtualized tables defaults to auto size. */
    tableHeight?: number;
    /** Row height. Defaults to 48. */
    rowHeight?: number;
    onRowClick?: (event: React.MouseEvent, row: T) => void;
    onScroll?: (el: HTMLElement) => void;
    onSort?: (column: keyof T, order: 'asc' | 'desc') => void;
    noDataPlaceholder?: ReactNode;
};

type SortFieldState<T> = {
    field: keyof T;
    asc: boolean;
};

const headerHeight = 41; // Header row + 1px border

export const defaultRowHeight = 48;

export const checkboxPaddingSx: SxProps = {
    paddingY: 0,
    paddingLeft: 0.5,
    paddingRight: 0.5,
};

export const SortableTable = <T extends TableDataBase>({
    data,
    defaultSortColumn,
    defaultSortOrder = 'asc',
    rowKeyField,
    children,
    sortLocale = 'en',
    sx,
    tableSx,
    rowSx,
    rowSxGetter,
    cellSx,
    headerRowSx,
    headerCellSx,
    virtualized = false,
    tableHeight,
    rowHeight: givenRowHeight,
    onRowClick,
    onScroll = () => {},
    onSort,
    noDataPlaceholder,
}: SortableTableProps<T>): JSX.Element => {
    const [sortField, setSortField] = useState<SortFieldState<T> | undefined>(
        defaultSortColumn ? { field: defaultSortColumn, asc: defaultSortOrder === 'asc' } : undefined,
    );

    // toArray automatically strips all undefined | null | false elements
    const columns: Column<T>[] = useMemo(() => React.Children.toArray(children) as Column<T>[], [children]);

    const activeColumn = sortField
        ? columns.map((column) => column.props).find((column) => column?.field === sortField.field)
        : undefined;

    const sortedData = useMemo(() => {
        if (!activeColumn || !sortField) {
            return data;
        }
        const { compareFn, field, numeric } = activeColumn;
        const { asc } = sortField;
        const dataCopy = data.slice();
        const fn = compareFn ?? (numeric ? numberCompareFn(field) : defaultCompareFn(field, sortLocale));
        return dataCopy.sort((a, b) => (asc ? fn(a, b) : fn(b, a)));
    }, [data, activeColumn, sortField, sortLocale]);

    const handleColumnClick = (field: keyof T) => () => {
        if (sortField?.field === field) {
            const asc = !sortField.asc;
            setSortField({ field, asc });
            onSort?.(field, asc ? 'asc' : 'desc');
        } else {
            const newColumn = columns
                .map((column) => (column ? column.props : undefined))
                ?.find((column) => column?.field === field);
            const asc = newColumn?.sortDefaultAsc ?? true;
            setSortField({ field, asc });
            onSort?.(field, asc ? 'asc' : 'desc');
        }
    };

    const rowHeight = givenRowHeight ?? defaultRowHeight;

    if (virtualized) {
        const columnWidths: number[] = columns.map((column) =>
            column?.props?.width && typeof column.props.width === 'number' ? column.props.width : 0,
        );

        const rowWidth = columnWidths.reduce((acc, curr) => acc + curr, 0);

        const SortableTableVirtualHeader: React.FC<{ children?: React.ReactNode }> = ({ ...props }) => (
            <TableHead component="div" sx={{ display: 'block', position: 'sticky', height: 40, top: 0, zIndex: 1 }}>
                <TableRow component="div" sx={{ display: 'flex', width: rowWidth, ...headerRowSx }}>
                    {columns.map(({ props }) => (
                        <SortableTableHeaderCell
                            key={props.keyValue || String(props.field)}
                            active={props.field === sortField?.field}
                            asc={sortField?.asc}
                            onSortableClick={handleColumnClick(props.field)}
                            sx={headerCellSx}
                            virtualized
                            {...props}
                        />
                    ))}
                </TableRow>
            </TableHead>
        );

        const SortableTableVirtualContainer: React.FC<{ children?: React.ReactNode }> = ({
            children: containerChildren,
            ...props
        }) => (
            <div {...props}>
                <SortableTableVirtualHeader {...props} />
                <Box sx={{ position: 'relative' }}>{containerChildren}</Box>
            </div>
        );

        if (!sortedData.length && noDataPlaceholder) {
            return (
                <Table sx={{ border: 0, backgroundColor: 'common.white', ...tableSx }}>
                    <SortableTableVirtualHeader />
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={columns.length}>{noDataPlaceholder}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
        }

        // A fix for now. Could not get these types working.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const SortableTableVirtualWrapper: React.FC<any> = React.forwardRef(({ children, style, ...props }, ref) => {
            const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);
            return (
                <div style={{ position: 'relative', width: style.width, height: style.height }}>
                    <div
                        ref={setContainerEl}
                        {...props}
                        style={{ ...style, willChange: undefined, position: undefined, width: '100%', height: '100%' }}
                    >
                        <OverflowContainer containerEl={containerEl} headerPadding={40} scrollHint />
                        {children}
                    </div>
                </div>
            );
        });

        return (
            <Box
                sx={{
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: 'border',
                    borderRadius: 1,
                    overflow: 'hidden',
                    height: Math.min(tableHeight ?? Number.MAX_SAFE_INTEGER, headerHeight + data.length * rowHeight),
                    ...sx,
                }}
            >
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            outerElementType={SortableTableVirtualWrapper}
                            width={width ?? 0}
                            height={height ?? 0}
                            itemCount={data.length}
                            itemSize={rowHeight}
                            innerElementType={SortableTableVirtualContainer}
                            // This triggers a rerender of the table when sorted.
                            key={`${String(sortField?.field)}${sortField?.asc}`}
                        >
                            {({ index, style }) => {
                                const row = sortedData[index];
                                return (
                                    <TableRow
                                        component="div"
                                        onClick={onRowClick && ((event: React.MouseEvent) => onRowClick(event, row))}
                                        sx={
                                            {
                                                display: 'flex',
                                                width: rowWidth,
                                                '&:hover': {
                                                    backgroundColor: onRowClick && 'field.background',
                                                },
                                                cursor: onRowClick && 'pointer',
                                                ...rowSx,
                                                ...(rowSxGetter?.(row) || {}),
                                            } as SxProps
                                        }
                                        style={style}
                                    >
                                        {columns.map((column) => {
                                            const {
                                                field,
                                                width,
                                                align,
                                                numeric = false,
                                                checkbox,
                                                sx: columnCellSx,
                                                headerSx,
                                                children,
                                                loading,
                                                skeletonWidth,
                                                keyValue,
                                            } = column.props;
                                            const content = String(get(row, field) ?? '');
                                            const sx = {
                                                ...cellSx,
                                                ...(index === 0 ? headerSx : columnCellSx),
                                            };
                                            return (
                                                <TableCell
                                                    component="div"
                                                    className={`SortableTable-cell-${String(field)}`}
                                                    sx={{
                                                        whiteSpace: 'nowrap',
                                                        width,
                                                        height: 48,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontSize: 14,
                                                        ...(checkbox ? checkboxPaddingSx : undefined),
                                                        ...(index === data.length - 1 && { borderBottom: 0 }),
                                                        ...getCellAlign(align, numeric),
                                                        ...sx,
                                                    }}
                                                    key={keyValue || String(field)}
                                                >
                                                    {loading ? (
                                                        <Skeleton width={skeletonWidth} />
                                                    ) : children ? (
                                                        children(row, index)
                                                    ) : (
                                                        content
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            }}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </Box>
        );
    }

    return (
        <OverflowContainer
            sx={{
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: 'border',
                borderRadius: 1,
                height: tableHeight,
                overflow: 'auto',
                ...sx,
            }}
            headerPadding={40}
            scrollHint
            onScroll={onScroll}
        >
            <Table sx={{ border: 0, backgroundColor: 'common.white', ...tableSx }}>
                <TableHead>
                    <TableRow sx={headerRowSx}>
                        {columns.map(({ props: { headerSx, sx: ignoreThis, checkbox, ...props } }) => {
                            const sx = { ...headerCellSx, ...headerSx };
                            return (
                                <SortableTableHeaderCell
                                    key={props.keyValue || String(props.field)}
                                    active={props.field === sortField?.field}
                                    asc={sortField?.asc}
                                    onSortableClick={handleColumnClick(props.field)}
                                    sx={sx}
                                    {...props}
                                />
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!sortedData.length && noDataPlaceholder ? (
                        <TableRow
                            sx={{
                                whiteSpace: 'nowrap',
                                height: rowHeight,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontSize: 14,
                                ...sx,
                            }}
                        >
                            <TableCell colSpan={columns.length}>{noDataPlaceholder}</TableCell>
                        </TableRow>
                    ) : (
                        sortedData.map((row, index) => (
                            <TableRow
                                key={String(get(row, rowKeyField) || index)}
                                onClick={onRowClick && ((event: React.MouseEvent) => onRowClick(event, row))}
                                sx={
                                    {
                                        '&:hover': {
                                            backgroundColor: onRowClick && 'field.background',
                                        },
                                        cursor: onRowClick && 'pointer',
                                        ...rowSx,
                                        ...(rowSxGetter?.(row) || {}),
                                    } as SxProps
                                }
                            >
                                {columns.map(
                                    ({
                                        props: {
                                            field,
                                            keyValue,
                                            align,
                                            numeric = false,
                                            width,
                                            checkbox,
                                            sx: columnCellSx,
                                            children,
                                            loading,
                                            skeletonWidth,
                                        },
                                    }) => {
                                        const content = String(get(row, field) ?? '');
                                        const sx = { ...cellSx, ...columnCellSx };
                                        return (
                                            <TableCell
                                                className={`SortableTable-cell-${String(field)}`}
                                                key={keyValue || String(field)}
                                                sx={{
                                                    ...(checkbox ? checkboxPaddingSx : undefined),
                                                    whiteSpace: 'nowrap',
                                                    height: rowHeight,
                                                    maxWidth: width,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: 14,
                                                    ...getCellAlign(align, numeric),
                                                    ...sx,
                                                }}
                                            >
                                                {loading ? (
                                                    <Skeleton width={skeletonWidth} />
                                                ) : children ? (
                                                    children(row, index)
                                                ) : (
                                                    content
                                                )}
                                            </TableCell>
                                        );
                                    },
                                )}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </OverflowContainer>
    );
};

export default SortableTable;
