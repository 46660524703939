import React from 'react';

import Typography from 'components/tokens/Typography';

import { Header, Row } from '../common/Styled';

type Props = {
    header: React.ReactNode;
    description: React.ReactNode;
    callToAction?: React.ReactNode;
    parentStyle?: React.CSSProperties;
};

const HeaderWithDescription: React.FC<Props> = ({ header, description, callToAction, parentStyle = {} }) => {
    return (
        <div style={parentStyle}>
            <Row>
                <Header>{header}</Header>
                {callToAction && <div>{callToAction}</div>}
            </Row>
            <Typography color="subtle" margin="8px 0px 0px 0px">
                {description}
            </Typography>
        </div>
    );
};

export default HeaderWithDescription;
