import { memo } from 'react';

import { Skeleton } from '@mui/material';
import { parseISO } from 'date-fns';
import { isNotEmpty } from 'utilities';

import { Register } from 'api/types/Organization';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import NoValue from 'components/templates/SortableTable/NoValue';
import Frame from 'components/tokens/Frame';
import { formatDate } from 'utilities/date';

export interface RegistersTableProps {
    registers?: Register[];
    skeleton?: boolean;
}

export const RegistersTable: React.VFC<RegistersTableProps> = memo(({ registers, skeleton = false }) => {
    if (skeleton) {
        return (
            <Frame title="Valid registrations">
                <Skeleton variant="rounded" height={328} />
            </Frame>
        );
    }

    if (!isNotEmpty(registers)) {
        return null;
    }

    return (
        <Frame title={`Valid registrations (${registers.length})`}>
            <SortableTable<Register & { key: string }>
                data={registers.map((register) => ({
                    ...register,
                    key: `${register.register_name}${register.registration_date}`,
                }))}
                defaultSortColumn="registration_date"
                defaultSortOrder="desc"
                rowKeyField="key"
            >
                <SortableTableColumn<Register> width={200} label="Register" field="register_name" />
                <SortableTableColumn<Register> width={200} label="Status" field="status_description" />
                <SortableTableColumn<Register> width={160} label="Registration date" field="registration_date">
                    {({ registration_date }) =>
                        registration_date ? formatDate(parseISO(registration_date)) : <NoValue />
                    }
                </SortableTableColumn>
                <SortableTableColumn<Register> width={160} label="End date" field="end_date">
                    {({ end_date }) => {
                        if (end_date) {
                            const date = parseISO(end_date);
                            if (date < new Date()) {
                                return formatDate(date);
                            }
                        }
                        return <NoValue />;
                    }}
                </SortableTableColumn>
                <SortableTableColumn<Register> width={200} label="Register authority" field="authority" />
            </SortableTable>
        </Frame>
    );
});

export default RegistersTable;
