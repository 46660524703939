import React, { useMemo, useState } from 'react';

import { Box, Paper, Skeleton, Tooltip, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import { getExternalReferencesStatsAsync } from 'api/externalReferences';
import AnalysisProgress from 'components/features/CRMInsights/AnalysisProgress';
import useStats from 'components/features/CRMInsights/useStats';
import useConnectorsApi from 'components/modules/connectors/hooks/useConnectorsApi';
import { CrmName } from 'components/modules/connectors/types/Crm';
import { mapCRMNameToTarget } from 'components/modules/connectors/utils/CRMTargetText';
import { AreaChart } from 'components/templates/charts';
import Button from 'components/tokens/Button';
import Icon, { IconType } from 'components/tokens/Icon';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { usePermissionContext } from 'contexts/PermissionContext';
import { formatDate, toDateUTC } from 'utilities/date';

import { ConnectionViewTabProps } from '../ConnectionView';
import DataCoverage from './DataCoverage';
import getBusinessImpactPayload from './getBusinessImpactPayload';
import NewCompaniesDialog from './NewCompaniesDialog';
import NewDealsDialog from './NewDealsDialog';
import WonRevenueDialog from './WonRevenueDialog';

export type DealStatus = 'won' | 'open' | 'lost';

const CRMBusinessImpact: React.FC<ConnectionViewTabProps> = ({ crmName }) => {
    const { isDomainConnector, connections } = usePermissionContext();
    const { getProspectExportCount } = useConnectorsApi();

    const axios = useAxiosContext();
    const intl = useIntl();
    const theme = useTheme();

    const [openedDialog, setOpenedDialog] = useState<'new_companies' | 'new_deals' | 'won_revenue' | null>(null);

    const target = mapCRMNameToTarget(crmName as CrmName);
    const statsQueryUid = `business-impact-${target}`;

    const statsPayload = getBusinessImpactPayload(statsQueryUid || '', target, isDomainConnector);

    const { data: countData } = useQuery({
        queryKey: ['prospect-export-count'],
        queryFn: () =>
            getProspectExportCount({ target: crmName, target_object: 'Account,Opportunity' }).then((r) => r.data),
    });

    const { generateStats, processesQuery, statsQuery, cancelAnalysis } = useStats({
        queryUid: statsQueryUid,
        processType: 'external-references-stats-async-api',
    });

    const { data: statsProcesses, isFetching } = processesQuery;

    const handleGenerateStatsClick = () => {
        generateStats(() => getExternalReferencesStatsAsync(axios, statsPayload));
    };

    const progress = statsProcesses?.inProgress?.progress;

    const isAnalysisDisabled = !!statsProcesses?.inProgress;

    const hasNoAnalysis = !isFetching && !statsProcesses?.completed && !statsProcesses?.inProgress;

    const connectedCRM = connections?.find((c) => target === c.crm_name);

    const data = useMemo(() => {
        if (!statsQuery.data) {
            return null;
        }
        const createdObjects = statsQuery.data.find((row) => row.id === 'created_objects');
        const newCompanies =
            createdObjects?.result[0]?.buckets.find((bucket) => bucket.value === 'Account')?.count || 0;
        const newDeals =
            createdObjects?.result[0]?.buckets.find((bucket) => bucket.value === 'Opportunity')?.count || 0;

        const openDealsRevenue = statsQuery.data.find((row) => row.id === 'open_deals_revenue');
        const openDealsAmount = openDealsRevenue?.result[0]?.buckets[0]?.sum || 0;
        const openDealsCurrency =
            (openDealsRevenue?.result[0]?.buckets[0]?.value as { currency_code: string })?.currency_code || '';

        const wonDealsRevenue = statsQuery.data.find((row) => row.id === 'won_deals_revenue');
        const wonRevenue = wonDealsRevenue?.result[0]?.buckets[0]?.sum || 0;
        const wonRevenueCurrency =
            (wonDealsRevenue?.result[0]?.buckets[0]?.value as { currency_code: string })?.currency_code || '';

        const closedDeals = wonDealsRevenue?.result[0]?.buckets[0]?.count || 0;

        const newDealsMonthly = statsQuery.data.find((row) => row.id === 'new_deals_monthly');

        let deals = 0;
        let companies = 0;

        const opportunitiesMonthly = statsQuery.data
            .find((row) => row.id === 'new_companies_monthly')
            ?.result[0]?.buckets.map((bucket, idx) => {
                companies += bucket.count || 0;
                deals += newDealsMonthly?.result[0].buckets[idx].count || 0;
                return {
                    companies,
                    deals,
                    label: format(new Date((bucket.meta as { date: string })?.date), 'MMM'),
                    value: Math.max(companies, deals),
                };
            });

        let revenue = 0;

        const wonRevenueMonthly = statsQuery.data
            .find((row) => row.id === 'won_revenue_monthly')
            ?.result[0]?.buckets.map((bucket) => {
                revenue += bucket.count || 0;
                return {
                    value: revenue,
                    label: format(new Date((bucket.meta as { date: string })?.date), 'MMM'),
                };
            });

        return {
            newCompanies,
            newDeals,
            openDealsAmount,
            openDealsCurrency,
            wonRevenue,
            wonRevenueCurrency,
            closedDeals,
            opportunitiesMonthly,
            wonRevenueMonthly,
        };
    }, [statsQuery]);

    const handleDialogClose = () => setOpenedDialog(null);

    const chartData = getChartData(theme);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {statsProcesses?.completed?.finished && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
                    <Typography variant="caption" color="subtle">
                        Last checked:{' '}
                        <span style={{ fontWeight: 500 }}>
                            {formatDate(toDateUTC(statsProcesses.completed.finished), 'long-datetime')}
                        </span>
                    </Typography>

                    <Button
                        startIcon={<Icon type="Refresh" />}
                        variant="flat"
                        onClick={handleGenerateStatsClick}
                        disabled={isAnalysisDisabled}
                    />
                </Box>
            )}

            {hasNoAnalysis && (
                <Paper sx={{ padding: 2 }} variant="outlined">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="subtitle1" color="dark" sx={{ flex: 1 }}>
                            Create a report on Vainu's business impact
                        </Typography>
                        <Button onClick={handleGenerateStatsClick} disabled={isAnalysisDisabled}>
                            Generate analysis
                        </Button>
                    </Box>
                </Paper>
            )}

            {statsProcesses?.inProgress && (
                <AnalysisProgress progress={progress} companyCount={countData?.count} onCancel={cancelAnalysis} />
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: 2,
                    opacity: hasNoAnalysis ? 0.3 : 1,
                }}
            >
                <Column title="Sales opportunities">
                    {data?.opportunitiesMonthly ? (
                        <AreaChart
                            data={data.opportunitiesMonthly}
                            numberStyle="decimal"
                            style={{
                                strokeWidth: 3,
                            }}
                            showLastDataPointMarker
                            dataPoints={chartData.opportunities}
                            stack={false}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={200} />
                    )}

                    <Typography weight="bold">Created from Vainu</Typography>

                    <Row
                        icon="HubspotCompanySvg"
                        label="New companies"
                        color="brandColors.tertiaryBlue"
                        value={data?.newCompanies}
                        onExpand={() => setOpenedDialog('new_companies')}
                    />
                    <Row
                        icon="HubspotDealSvg"
                        label="New deals"
                        color="brandColors.secondaryPurple700"
                        value={data?.newDeals}
                        onExpand={() => setOpenedDialog('new_deals')}
                    />
                    <Row
                        icon="HubspotDealSvg"
                        label="Open deals amount"
                        color="text.strong"
                        value={
                            data?.openDealsAmount &&
                            `${intl.formatNumber(data.openDealsAmount, { notation: 'compact' })} ${
                                data.openDealsCurrency
                            }`
                        }
                        info="The total value of open deals linked to new companies or deals created from Vainu."
                    />
                </Column>

                <Column title="Closed business">
                    {data?.wonRevenueMonthly ? (
                        <AreaChart
                            data={data.wonRevenueMonthly}
                            numberStyle="decimal"
                            style={{
                                strokeWidth: 3,
                            }}
                            showLastDataPointMarker
                            dataPoints={chartData.wonRevenue}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={200} />
                    )}
                    <Typography weight="bold">Deals created from Vainu</Typography>
                    <Row
                        icon="HubspotDealSvg"
                        label="Won revenue"
                        color="brandColors.primaryGreen600"
                        value={
                            data?.wonRevenue &&
                            `${intl.formatNumber(data.wonRevenue, { notation: 'compact' })} ${data.wonRevenueCurrency}`
                        }
                        onExpand={() => setOpenedDialog('won_revenue')}
                    />
                    <Row
                        icon="HubspotDealSvg"
                        label="Closed deals"
                        color="text.strong"
                        value={data?.closedDeals}
                        info="The number of closed deals linked to new companies or deals created from Vainu."
                    />
                </Column>
            </Box>

            <Column title="Data coverage">
                <DataCoverage crmName={crmName} data={statsQuery.data} statsPayload={statsPayload} />
            </Column>

            <NewCompaniesDialog
                open={openedDialog === 'new_companies'}
                onClose={handleDialogClose}
                connectedCRM={connectedCRM}
                target={target}
            />
            <NewDealsDialog
                open={openedDialog === 'new_deals'}
                onClose={handleDialogClose}
                connectedCRM={connectedCRM}
                target={target}
            />
            <WonRevenueDialog
                open={openedDialog === 'won_revenue'}
                onClose={handleDialogClose}
                connectedCRM={connectedCRM}
                target={target}
            />
        </Box>
    );
};

export default CRMBusinessImpact;

const Column = ({ children, title }: { title: string; children: React.ReactNode }) => (
    <Paper
        sx={{
            padding: 2,
            flex: 1,
            minWidth: `max(300px, calc(50% - 16px))`,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        }}
    >
        <Typography weight="bold">{title}</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>{children}</Box>
    </Paper>
);

const Row = ({
    icon,
    label,
    value,
    color,
    info,
    onExpand,
}: {
    icon: IconType;
    label: string;
    value: React.ReactNode;
    color: string;
    info?: React.ReactNode;
    onExpand?: () => void;
}) => {
    const content = (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                padding: 1,
                paddingLeft: 2,
                borderRadius: 1,
            }}
        >
            <Icon type={icon} fontSize="small" />

            <Typography variant="body2" sx={{ flex: 1 }}>
                {label}
            </Typography>

            <Typography variant="subtitle1" fontFamily="Aeroport" sx={{ color }}>
                {value == null ? <Skeleton width={40} sx={{ backgroundColor: 'grey.100' }} /> : value}
            </Typography>

            {onExpand ? (
                <Icon type="ChevronDown" fontSize="mini" color="icon.basic" />
            ) : (
                <Tooltip title={info}>
                    <div>
                        <Icon type="InfoCircleOutline" color="text.subtle" fontSize="mini" sx={{ display: 'block' }} />
                    </div>
                </Tooltip>
            )}
        </Box>
    );

    return onExpand ? (
        <Button
            variant="flat"
            onClick={onExpand}
            sx={{ backgroundColor: 'grey.50', padding: 0, textAlign: 'left', color: 'text.primary' }}
        >
            {content}
        </Button>
    ) : (
        content
    );
};

const getChartData = (theme: Theme) => {
    return {
        wonRevenue: [
            {
                dataKey: 'value',
                color: theme.palette.brandColors.primaryGreen600,
                fillColor: 'url(#won-revenue-gradient)',
                gradientDef: (
                    <defs>
                        <linearGradient id="won-revenue-gradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset={0} stopColor={theme.palette.brandColors.primaryGreen600} stopOpacity={0.3} />
                            <stop offset={1} stopColor={theme.palette.brandColors.primaryGreen600} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                ),
            },
        ],
        opportunities: [
            {
                dataKey: 'deals',
                color: theme.palette.brandColors.secondaryPurple700,
                fillColor: 'url(#deal-gradient)',
                gradientDef: (
                    <defs>
                        <linearGradient id="deal-gradient" x1="0" y1="0" x2="0" y2="1">
                            <stop
                                offset={0}
                                stopColor={theme.palette.brandColors.secondaryPurple700}
                                stopOpacity={0.2}
                            />
                            <stop offset={1} stopColor={theme.palette.brandColors.secondaryPurple700} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                ),
            },
            {
                dataKey: 'companies',
                color: theme.palette.brandColors.tertiaryBlue,
                fillColor: 'url(#company-gradient)',
                gradientDef: (
                    <defs>
                        <linearGradient id="company-gradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset={0} stopColor={theme.palette.brandColors.tertiaryBlue} stopOpacity={0.2} />
                            <stop offset={1} stopColor={theme.palette.brandColors.tertiaryBlue} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                ),
            },
        ],
    };
};
