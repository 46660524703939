import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Notification = (props) => (
    <SvgIcon {...props}>
        <path d="M12 22C10.896 21.9946 10.0009 21.1039 9.99 20H13.99C13.9921 20.2674 13.9411 20.5325 13.84 20.78C13.5777 21.382 13.0418 21.8211 12.4 21.96H12.395H12.38H12.362H12.353C12.2368 21.9842 12.1186 21.9976 12 22ZM20 19H4V17L6 16V10.5C5.94732 9.08912 6.26594 7.68913 6.924 6.44C7.57904 5.28151 8.6987 4.45888 10 4.18V2H14V4.18C16.579 4.794 18 7.038 18 10.5V16L20 17V19Z" />
    </SvgIcon>
);

export default Notification;
