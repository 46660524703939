import { createContext, useContext, useState } from 'react';

import { InitialProfileData, ProfileID, ProfileType } from 'api/types/Organization';
import { ProfileDisplayMode } from 'components/modules/profiles/Profile';

export type CompanyProfileContextValue = {
    id: ProfileID | undefined;
    type: ProfileType | undefined;
    setProfile: ({ id, type }: { id: ProfileID | undefined; type: ProfileType | undefined }) => void;
    initialData: InitialProfileData | undefined;
    setInitialData: (data: InitialProfileData | undefined) => void;
    viewMode: ProfileDisplayMode;
};

const CompanyProfileContext = createContext({} as CompanyProfileContextValue);

export type CompanyProfileProviderProps = {
    children: React.ReactNode;
    viewMode: ProfileDisplayMode;
};

export const CompanyProfileProvider: React.FC<CompanyProfileProviderProps> = ({ children, viewMode }) => {
    const [{ id, type }, setProfile] = useState<Pick<CompanyProfileContextValue, 'id' | 'type'>>({
        id: undefined,
        type: undefined,
    });
    const [initialData, setInitialData] = useState<InitialProfileData | undefined>();

    const value: CompanyProfileContextValue = {
        id,
        type,
        setProfile,
        initialData,
        setInitialData,
        viewMode,
    };
    return <CompanyProfileContext.Provider value={value}>{children}</CompanyProfileContext.Provider>;
};

export const useCompanyProfileContext = () => useContext(CompanyProfileContext);

export default useCompanyProfileContext;
