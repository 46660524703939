import React, { useEffect, useState, useContext } from 'react';

import { Box, ClickAwayListener } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Popper from '@mui/material/Popper';
import Snackbar from '@mui/material/Snackbar';
import { anchorRef, bindPopper, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';

import { TriggerDestinationType } from 'api/types/Trigger';
import { crmLogo } from 'components/features/Notifications/NotificationItem';
import { CrmName, CRMObject } from 'components/modules/connectors/types/Crm';
import { crmLabels } from 'components/modules/connectors/utils';
import { triggerCrmExportables } from 'components/modules/connectors/utils/CRMTargetText';
import Button from 'components/tokens/Button';
import Icon, { IconType } from 'components/tokens/Icon';
import { Destination } from 'contexts//TriggerContext';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { useTrigger, ActionType } from 'contexts/TriggerContext';

import CRMDestination, { CRMDestinationType } from '../TriggerDestination/CRMDestination';
import EmailDestination, { EmailDestinationType } from '../TriggerDestination/EmailDestination';
import SlackDestination, { SlackDestinationType } from '../TriggerDestination/SlackDestination';
import TriggerButton from './TriggerButton';

export const icons: Record<string, IconType> = {
    ...crmLogo,
    slack: 'Slack',
    email: 'Mail',
};

const getIcon = (system: TriggerDestinationType) => {
    const type = icons[system];
    return <Icon type={type} key={system} sx={{ width: 16, height: 16 }} />;
};

export const getDestinationLabel = (target?: string) => {
    if (!target) {
        return '';
    }
    return crmLabels[target as keyof typeof crmLabels] || `${target[0].toUpperCase()}${target.slice(1)}`;
};

const SelectDestinationPopper: React.FC = () => {
    const popupState = usePopupState({ variant: 'popper', popupId: 'select-destination-popper' });
    const [state, dispatch, { activeCRMIntegrationSettings, activePopup, setActivePopup }] = useTrigger();
    const [editedDestination, setEditedDestination] = useState<Destination>({} as Destination);
    const [showErrorSnackbarMessage, setShowErrorSnackbarMessage] = useState('');
    const permissionContext = useContext(PermissionContext);
    const { destinations, name } = state;
    const anchor = anchorRef(popupState);

    useEffect(() => {
        if (popupState.isOpen) {
            setActivePopup(popupState.popupId);
        }
    }, [popupState, setActivePopup]);

    useEffect(() => {
        if (activePopup && popupState.popupId !== activePopup && popupState.isOpen) {
            popupState.close();
            setEditedDestination({} as Destination);
        }
        if (activePopup && popupState.popupId === activePopup && !popupState.isOpen) {
            popupState.open();
            setEditedDestination({} as Destination);
        }
    }, [activePopup, popupState]);

    const updateDestination = (obj: Destination) => {
        setEditedDestination({ ...editedDestination, [obj.system]: obj });
    };

    const onCancel = () => {
        setActivePopup(undefined);
        popupState.close();
    };
    const onSave = () => {
        let hasError = false;
        Object.values(editedDestination).forEach((destinationObj: Destination) => {
            if (!destinationObj.isValid && destinationObj.active) {
                setShowErrorSnackbarMessage(`Set value for ${getDestinationLabel(destinationObj.system)}`);
                hasError = true;
            }
        });
        if (hasError) {
            return;
        }
        Object.values(editedDestination).forEach((destinationObj: Destination) => {
            const type = destinationObj.isValid ? ActionType.EDIT_DESTINATION : ActionType.DISABLE_DESTINATION;
            dispatch({
                type,
                destination: destinationObj,
            });
        });
        setEditedDestination({} as Destination);
        setActivePopup(undefined);
        popupState.close();
    };
    const slackDestination = (destinations.find((i) => i.system === 'slack') || {
        system: 'slack',
        slackChannel: [],
        active: false,
    }) as unknown as SlackDestinationType;

    const emailDestination: EmailDestinationType = (destinations.find((i) => i.system === 'email') || {
        system: 'email',
        emails: [],
        active: false,
    }) as unknown as EmailDestinationType;

    let buttonText = '';
    let buttonIcon = null;
    if (destinations.length === 0) {
        buttonText = 'Add destination';
    } else {
        buttonText = destinations.length === 1 ? getDestinationLabel(destinations[0].system) : '';
        buttonIcon = (
            <Box sx={{ display: 'flex', gap: 1, marginRight: buttonText ? 1 : 0 }}>
                {(destinations as Destination[]).map((i) => getIcon(i.system))}
            </Box>
        );
    }

    return (
        <div id="trigger-destination" ref={anchor}>
            <TriggerButton {...bindToggle(popupState)}>
                {buttonIcon} {buttonText}
            </TriggerButton>
            <Popper
                {...bindPopper(popupState)}
                placement="bottom-start"
                transition
                popperOptions={{
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: true,
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 4],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={onSave}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Card sx={{ width: 500, display: 'flex', flexDirection: 'column' }}>
                                <CardContent
                                    sx={{
                                        padding: 2,
                                        paddingTop: 3,
                                        overflowY: 'auto',
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                    }}
                                >
                                    {permissionContext.hasIntegration('slack') && (
                                        <SlackDestination
                                            destination={slackDestination}
                                            enableDestination={permissionContext.hasIntegration('slack')}
                                            updateDestination={updateDestination}
                                        />
                                    )}
                                    <EmailDestination
                                        destination={emailDestination}
                                        updateDestination={updateDestination}
                                    />
                                    {activeCRMIntegrationSettings.map((crmSettings) => {
                                        const fieldMapping = crmSettings.field_mapping;
                                        const lowerCaseName = crmSettings.integration_target.toLowerCase() as CrmName;
                                        return (
                                            <div key={lowerCaseName}>
                                                <CRMDestination
                                                    target={crmSettings.integration_target}
                                                    lowerCaseName={lowerCaseName}
                                                    destination={
                                                        destinations.find(
                                                            (i) =>
                                                                i.system ===
                                                                crmSettings.integration_target.toLowerCase(),
                                                        ) as CRMDestinationType
                                                    }
                                                    fieldMapping={fieldMapping}
                                                    crmObjects={
                                                        (Object.keys(triggerCrmExportables[lowerCaseName]) ||
                                                            []) as CRMObject[]
                                                    }
                                                    updateDestination={updateDestination}
                                                    triggerName={name}
                                                />
                                            </div>
                                        );
                                    })}
                                </CardContent>
                                <CardActions sx={{ padding: 2 }}>
                                    <Grid container justifyContent="flex-end" spacing={1}>
                                        <Grid item>
                                            <Button variant="flat" onClick={() => onCancel()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => onSave()}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    open={!!showErrorSnackbarMessage}
                                    autoHideDuration={3000}
                                    onClose={() => setShowErrorSnackbarMessage('')}
                                    message={<span>{showErrorSnackbarMessage}</span>}
                                />
                            </Card>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </div>
    );
};

export default SelectDestinationPopper;
