import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// eslint-disable-next-line @typescript-eslint/naming-convention
const SalesforceOpportunity: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M19.5 18.5455V19.2727C19.5 19.6744 19.1642 20 18.75 20H5.25C4.83579 20 4.5 19.6744 4.5 19.2727V18.5455C4.5 18.1438 4.83579 17.8182 5.25 17.8182H18.75C19.1642 17.8182 19.5 18.1438 19.5 18.5455ZM19.5 5.45455C18.4091 5.45455 17.5566 6.60686 18.2335 7.67737L18.3015 7.77745C18.7028 8.32945 18.606 8.66182 18.3353 9.04945C17.7615 9.86909 16.9313 10.5455 15.75 10.5455C13.8585 10.5455 12.8595 9.03055 12.6518 7.74691L12.6241 7.56183C12.5818 7.23304 12.5985 6.95645 12.8918 6.63895L12.9788 6.55127C13.3508 6.20145 13.5983 5.71418 13.4625 5.13527C13.3155 4.512 12.7403 4 12 4C11.172 4 10.5 4.65164 10.5 5.45455C10.5 5.85055 10.678 6.19235 10.9334 6.46352L11.0212 6.55127C11.398 6.9066 11.4215 7.20315 11.3756 7.56183L11.3483 7.74691C11.1398 9.02982 10.1408 10.5455 8.25 10.5455C7.06875 10.5455 6.2385 9.86909 5.66475 9.04873C5.41135 8.68761 5.31018 8.37401 5.62465 7.88501L5.6985 7.77745C6.4965 6.68 5.625 5.45455 4.5 5.45455C3.672 5.45455 3 6.10618 3 6.90909C3 7.45008 3.30315 7.92836 3.75822 8.1748L3.858 8.224C4.40396 8.46721 4.48629 9.02693 4.4981 9.58193L4.5 9.80291V14.9091C4.5 15.7124 5.17157 16.3636 6 16.3636H18C18.8284 16.3636 19.5 15.7124 19.5 14.9091V9.80291C19.5 9.21835 19.5206 8.57968 20.0389 8.27677L20.142 8.224C20.652 7.99636 21 7.48873 21 6.90909C21 6.10618 20.3273 5.45455 19.5 5.45455Z" />
    </SvgIcon>
);

export default SalesforceOpportunity;
