import { Routes, Route, useNavigate } from 'react-router-dom';

import { ProfileType } from 'api/types/Organization';
import { MainPath } from 'components/beta/Platform/Router/routePaths';

import { getRouterProfileType } from '../profiles';
import Search from './Search';

export const SearchRouter: React.VFC = () => (
    <Routes>
        <Route path="/" element={<Search />} />
        <Route path="/view/:profileType/:profileId" element={<Search />} />
    </Routes>
);

export default SearchRouter;

export const useNavigateToProfile = () => {
    const navigate = useNavigate();
    return (profileType: ProfileType, profileId: string | undefined, searchTerm?: string) => {
        if (profileId) {
            navigate(`/${MainPath.Search}/view/${getRouterProfileType(profileType)}/${profileId}`, {
                state: { searchTerm },
            });
        }
    };
};

export const useNavigateToSearch = () => {
    const navigate = useNavigate();
    return (searchTerm?: string) => {
        if (searchTerm) {
            navigate(`/${MainPath.Search}/?q=${searchTerm}`);
        } else {
            navigate(`/${MainPath.Search}`);
        }
    };
};
