import { useMemo } from 'react';

import { assertArray } from 'utilities';

import { WebProfileData } from 'api/profileData';
import { Company, CompanyURL, SocialProfile } from 'api/types/company';
import { SocialMedia } from 'api/types/Organization';
import { IconType } from 'components/tokens/Icon';

import { Description } from '.';

const socialIcons: Record<string, IconType> = {
    twitter: 'Twitter',
    linkedin: 'LinkedInSquare',
    linkedincompany: 'LinkedInSquare',
    instagram: 'Instagram',
    youtube: 'YouTube',
    facebook: 'Facebook',
};

const socialNames: Record<string, string> = {
    twitter: 'Twitter',
    linkedin: 'LinkedIn',
    linkedincompany: 'LinkedIn',
    instagram: 'Instagram',
    youtube: 'YouTube',
    facebook: 'Facebook',
};

function getSocialMediaIcon(type: string | undefined): IconType {
    return socialIcons[type ?? ''] ?? 'Globe';
}

function getSocialMediaLabel(type: string | undefined): string {
    return socialNames[type ?? ''] ?? type ?? '';
}

export function getDescriptionsFromCompany({ description, urls, social_profiles }: Company): Description[] {
    let descriptions: Description[] = [];

    if (
        description &&
        !social_profiles?.some((profile) => profile.description === description) &&
        !urls?.some((url) => url.description === description)
    ) {
        descriptions = [
            {
                description,
                icon: 'Profiles',
            },
        ];
    }
    if (Array.isArray(urls)) {
        descriptions = [
            ...descriptions,
            ...urls
                .filter((url): url is CompanyURL & Required<Pick<CompanyURL, 'description'>> => !!url.description)
                .map<Description>((url) => ({ description: url.description, icon: 'Globe', iconTooltip: url.domain })),
        ];
    }
    if (Array.isArray(social_profiles)) {
        descriptions = [
            ...descriptions,
            ...social_profiles
                .filter((profile): profile is SocialProfile & Required<Pick<SocialProfile, 'description'>> =>
                    Boolean(profile.description),
                )
                .map<Description>((profile) => ({
                    description: profile.description,
                    icon: getSocialMediaIcon(profile.type_id),
                    iconTooltip: getSocialMediaLabel(profile.type_id),
                })),
        ];
    }
    return descriptions;
}

const socialMediaPriorities: Record<string, number> = {
    linkedin: 10,
    twitter: 9,
};

const getSocialMediaPriority = (type: string | undefined = ''): number => socialMediaPriorities[type] ?? 0;

const prioritizeSocialMedia = (a: SocialMedia, b: SocialMedia) =>
    getSocialMediaPriority(b.type) - getSocialMediaPriority(a.type);

export function getDescriptionsFromDomainData(data?: WebProfileData): Description[] {
    return [
        ...assertArray(data?.website_data?.websites)
            .filter((website) => !!website.description)
            .map<Description>((website) => ({
                description: website.description as string,
                icon: 'Globe',
                iconTooltip: website.domain,
            })),
        ...assertArray(data?.social_media?.filter((social_medium) => !!social_medium.description))
            .sort(prioritizeSocialMedia)
            .map<Description>((social_medium) => ({
                description: social_medium.description as string,
                icon: getSocialMediaIcon(social_medium.type),
                iconTooltip: getSocialMediaLabel(social_medium.type),
            })),
    ];
}

export const useDescriptionsFromDomainData = (data?: WebProfileData): Description[] =>
    useMemo(() => getDescriptionsFromDomainData(data), [data]);
