import React, { useCallback, useMemo } from 'react';

import Autocomplete, {
    AutocompleteChangeReason,
    AutocompleteProps,
    createFilterOptions,
    AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { UseAutocompleteProps } from '@mui/material/useAutocomplete';
import { styled } from '@mui/system';

import Checkbox from 'components/tokens/Checkbox';

import { MassSelectionOptions } from '.';

export interface SelectOption {
    label: string;
    value: string | number;
}

export type SelectOptionTypes = string | SelectOption | (string | SelectOption)[] | null;

export interface EnhancedAutocompleteRenderOptionState extends AutocompleteRenderOptionState {
    selectedOptions: number;
    totalOptions: number;
}

export type SelectProps<T> = {
    multiple?: boolean;
    addMassSelectionOptions?: boolean;
    isCheckboxOption?: boolean;
    autoFocus?: boolean;
    value: SelectOptionTypes;
    width?: string | number;
    size?: 'medium' | 'small';
    placeholder?: string;
    options: SelectOption[];
    disabledOptions?: SelectOption[];
    onValueChange: (obj: SelectOptionTypes, reason?: AutocompleteChangeReason) => void;
    color?: string;
    /**
     * Allows to choose how the option should match either match from the
     * start or any section of the text
     */
    optionMatchFrom?: 'any' | 'start';
    renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: SelectOption,
        state: EnhancedAutocompleteRenderOptionState,
    ) => React.ReactNode | undefined;
} & Omit<
    AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>,
    'value' | 'onValueChange' | 'multiple' | 'options' | 'renderInput' | 'color' | 'renderOption'
> &
    UseAutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>;

const Select: React.FC<SelectProps<SelectOption>> = ({
    value,
    multiple = true,
    addMassSelectionOptions,
    width = '250px',
    size = 'small',
    placeholder = 'Placeholder...',
    options = [],
    limitTags = 2,
    autoFocus = false,
    disabledOptions = [],
    isCheckboxOption,
    onValueChange,
    color,
    optionMatchFrom = 'any',
    renderOption,
    ...props
}) => {
    const isOptionEqualToValue = useCallback(
        (option: SelectOption, value: SelectOption) => option.value === value.value,
        [],
    );

    const onChange = useCallback(
        (event: React.SyntheticEvent, value: SelectOptionTypes, reason: AutocompleteChangeReason) => {
            if (!multiple && !addMassSelectionOptions) {
                onValueChange(value);
                return;
            }

            const massActionOption = (value as SelectOption[]).find(({ value }) => {
                return value === MassSelectionOptions.SelectAll || value === MassSelectionOptions.DeselectAll;
            });

            if (massActionOption?.value === MassSelectionOptions.SelectAll) {
                onValueChange(options);
            } else if (massActionOption?.value === MassSelectionOptions.DeselectAll) {
                onValueChange([]);
            } else {
                onValueChange(value);
            }
        },
        [addMassSelectionOptions, multiple, options, onValueChange],
    );

    const filteredOptions = useMemo(
        () => createFilterOptions<SelectOption>({ matchFrom: optionMatchFrom }),
        [optionMatchFrom],
    );

    const massSelectionOption = useMemo(
        () =>
            multiple && addMassSelectionOptions && Array.isArray(value)
                ? {
                      label: value.length === 0 ? `Select all (${options.length})` : `${value.length} selected`,
                      value:
                          value.length === options.length
                              ? MassSelectionOptions.DeselectAll
                              : MassSelectionOptions.SelectAll,
                  }
                : undefined,
        [multiple, addMassSelectionOptions, value, options.length],
    );

    const inputOptions = useMemo(
        () => (addMassSelectionOptions ? [massSelectionOption as SelectOption, ...options] : options),
        [addMassSelectionOptions, massSelectionOption, options],
    );

    return (
        <Autocomplete
            sx={{ width }}
            disableClearable
            value={value}
            limitTags={limitTags}
            getLimitTagsText={(more) => `(+${more})`}
            size={size}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={onChange}
            multiple={multiple}
            options={inputOptions}
            popupIcon={props.disabled ? null : undefined}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        InputLabelProps={{ ...params.InputLabelProps, variant: 'standard' }}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        sx={{
                            width,
                            backgroundColor: 'background.paper',
                            borderRadius: '4px',
                            '& .MuiInputBase-root': {
                                fontSize: 14,
                            },
                            '.MuiOutlinedInput-root': {
                                paddingRight: `9px!important`,
                                ...(color && {
                                    '& fieldset, &.Mui-focused fieldset': { borderColor: color },
                                    '&:hover fieldset': { borderColor: color },
                                }),
                                '&.Mui-disabled fieldset': {
                                    borderColor: 'border',
                                },
                            },
                        }}
                    />
                );
            }}
            renderOption={(optionProps, option, state) => {
                const selectedOptions = Array.isArray(value) ? value.length : 0;
                const totalOptions = options.length;
                const isMassSelectionOption =
                    option.value === MassSelectionOptions.SelectAll ||
                    option.value === MassSelectionOptions.DeselectAll;

                return renderOption ? (
                    renderOption(optionProps, option, { ...state, selectedOptions, totalOptions })
                ) : (
                    <MenuOption
                        isMassSelectOption={isMassSelectionOption}
                        {...(isMassSelectionOption && { 'data-testid': 'mass-select-option' })}
                        hasCheckbox={isCheckboxOption}
                        {...optionProps}
                    >
                        {isCheckboxOption && (
                            <Checkbox
                                checked={isMassSelectionOption ? selectedOptions === totalOptions : state.selected}
                                indeterminate={
                                    isMassSelectionOption
                                        ? selectedOptions > 0 && selectedOptions < totalOptions
                                        : false
                                }
                            />
                        )}
                        {option.label}
                    </MenuOption>
                );
            }}
            filterOptions={filteredOptions}
            getOptionDisabled={(option) =>
                disabledOptions.some((disabledOption) => disabledOption.value === option.value)
            }
            {...props}
        />
    );
};

export const MenuOption = styled('li')<{ hasCheckbox?: boolean; isMassSelectOption?: boolean }>(
    ({ hasCheckbox, isMassSelectOption, theme: { palette } }) => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        '&.MuiAutocomplete-option': {
            padding: hasCheckbox ? '0px' : '8px',
        },

        ...(isMassSelectOption && {
            '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '2.5%',
                width: '95%',
                borderBottom: `1px solid ${palette.border}`,
            },
        }),
    }),
);

export default Select;
