import { useMemo } from 'react';

import { isArray } from 'lodash';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { FilterOperator } from 'api/types/FilterOperators';
import { filterOptionsDeep } from 'components/tokens/select-components/NestedSelect/utils';
import { Database, useListsContext } from 'contexts/ListsContext';
import { usePermissionContext } from 'contexts/PermissionContext';
import { getFirstKey } from 'utilities/objectUtils';

import { getGroup } from '../../common/findGroup';
import { mergeKeys } from '../../common/utils';
import { filterDefinitions } from '../../filters/filterDefinitions';
import { getFilterGenerator, getNextArray } from '../../filters/utils';
import { FilterID, MappedFilter, GroupOption, GetFiltersFnResult } from '../../FilterTypes';
import { GetFiltersResult } from '../types';

export function getFilterToHide(filters: MappedFilter[]) {
    const filterIds: FilterID[] = [];

    for (const filterItem of filters) {
        if (filterItem?.definition?.selectableOnce) {
            filterIds.push(filterItem.id);
        }
    }
    return filterIds;
}

export function getGroupFilters(id: string, getValues: UseFormGetValues<FieldValues>): GetFiltersFnResult {
    const value = getValues(id);

    const group = getGroup(value);

    if (!group) {
        return;
    }
    const _filters: MappedFilter[] = [];

    const groupOperator = getFirstKey(value);
    if (groupOperator !== FilterOperator.MATCH) {
        for (const filter of getFilterGenerator(value)) {
            if (!filter) {
                continue;
            }

            const filterDefinition = filterDefinitions[filter.id];
            if (filterDefinition) {
                _filters.push({
                    ...filter,
                    path: id,
                    groupPath: id,
                    value,
                    valuePath: filter.path,
                    definition: filterDefinition,
                    uuid: uuid(),
                });
            }
        }

        return { filters: _filters, path: `${id}.${group?.path}`, nodePath: `${group?.path}` };
    }
    const operator = group ? Object.keys(group.value)[0] : undefined;

    const f = getNextArray(value, id);
    const nodesArray = f?.value;

    if (!isArray(nodesArray)) {
        throw new Error(
            `filters not found!\r\nnodesArray: ${JSON.stringify(nodesArray)}\r\nvalue: ${JSON.stringify(value)}`,
        );
    }

    nodesArray.forEach((filterNode, index) => {
        for (const filter of getFilterGenerator(filterNode)) {
            if (!filter) {
                return;
            }

            const filterDefinition = filterDefinitions[filter.id];
            if (filterDefinition) {
                _filters.push({
                    ...filter,
                    path: `${group?.path}.${operator}.${index}`,
                    groupPath: id,
                    value: filterNode,
                    valuePath: filter.path,
                    definition: filterDefinition,
                    uuid: uuid(),
                });
            }
        }
    });

    return {
        filters: _filters,
        path: mergeKeys(id, group?.path, operator),
        nodePath: mergeKeys(group?.path, operator),
    };
}

interface GetFilteredOptionsProps {
    options: GroupOption[];
    database?: Database;
    productPermissions?: string[];
    filterToHide?: FilterID[];
}

export function getFilteredOptions({
    options,
    filterToHide = [],
    database,
    productPermissions,
}: GetFilteredOptionsProps) {
    return filterOptionsDeep(options, (option) => {
        if (database && option.databases && !option.databases?.includes(database)) {
            return false;
        }

        if (option.permission && !productPermissions?.includes(option.permission)) {
            return false;
        }

        return !filterToHide.some((id) => id === option.value);
    });
}

export function useFilteredOptions({ options, filterToHide, database, productPermissions }: GetFilteredOptionsProps) {
    return useMemo(
        () => getFilteredOptions({ options, filterToHide, database, productPermissions }),
        [filterToHide, database, options, productPermissions],
    );
}

export function useActiveFilters(filterGroups: GetFiltersResult) {
    return useMemo(() => {
        return Object.keys(filterGroups).flatMap((i) => filterGroups[i].filters);
    }, [filterGroups]);
}

export function useFiltersToHide(activeFilters: MappedFilter[]) {
    return useMemo(() => {
        return getFilterToHide(activeFilters);
    }, [activeFilters]);
}

interface UseFilterGroupOptionsProps {
    options: GroupOption[];
    filterGroups: GetFiltersResult;
}

export function useFilterGroupOptions({ filterGroups, options }: UseFilterGroupOptionsProps) {
    const { productPermissions } = usePermissionContext();
    const { database } = useListsContext();

    const activeFilters = useActiveFilters(filterGroups);
    const filterToHide = useFiltersToHide(activeFilters);
    const filteredOptions = useFilteredOptions({ options, filterToHide, database, productPermissions });

    return { activeFilters, filterToHide, filteredOptions };
}
