import React from 'react';

import Autocomplete, {
    AutocompleteProps,
    autocompleteClasses,
    AutocompleteRenderGroupParams,
} from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { UseAutocompleteProps } from '@mui/material/useAutocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

import Typography from 'components/tokens/Typography';

export interface SelectOption {
    label: string;
    value: string | number;
}

export type SelectOptionTypes = string | SelectOption | (string | SelectOption)[] | null;

export type SelectProps<T> = {
    value: SelectOptionTypes;
    onValueChange: (obj: SelectOptionTypes) => void;
    width?: string | number;
    size?: 'medium' | 'small';
    placeholder?: string;
    multiple?: boolean;
    options: SelectOption[];
    disabledOptions?: SelectOption[];
} & Omit<
    AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>,
    'value' | 'onValueChange' | 'multiple' | 'options' | 'renderInput'
> &
    UseAutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>;

const LISTBOX_PADDING = 8;

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
        fontSize: 14,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1].label}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const VirtualizedSelect: React.FC<SelectProps<SelectOption>> = ({
    value,
    onValueChange,
    width = '200px',
    size = 'small',
    placeholder = 'Placeholder...',
    multiple = true,
    options = [],
    limitTags = 2,
    disabledOptions = [],
    ...props
}) => {
    const isOptionEqualToValue = (option: SelectOption, value: SelectOption) => {
        return option.value === value.value;
    };

    const onChange = (event: React.SyntheticEvent, value: SelectOptionTypes, reason: string) => {
        onValueChange(value);
    };
    return (
        <Autocomplete
            sx={{ width }}
            disableClearable
            disableListWrap
            value={value}
            size={size}
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={onChange}
            multiple={multiple}
            limitTags={limitTags}
            getLimitTagsText={(more) => `(+${more})`}
            options={options}
            // FIXME: feels like usage of renderOption and renderGroup is not correct according to a typings but it works
            renderOption={(props, option) => [props, option] as React.ReactNode}
            renderGroup={(params: AutocompleteRenderGroupParams) => params as unknown as React.ReactNode}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        InputLabelProps={{ ...params.InputLabelProps, variant: 'standard' }}
                        placeholder={placeholder}
                        sx={{
                            width,
                            backgroundColor: 'background.paper',
                            '& .MuiInputBase-root': {
                                fontSize: 14,
                            },
                        }}
                    />
                );
            }}
            getOptionDisabled={(option) =>
                disabledOptions.some((disabledOption) => disabledOption.value === option.value)
            }
            {...props}
        />
    );
};

export default VirtualizedSelect;
