import React, { createContext, Dispatch, SetStateAction, useCallback, useState, useMemo } from 'react';

import { SitewideNotification } from 'api/types/Notification';
import { UserAsyncProcess } from 'api/types/UserAsyncProcess';

export type NotificationContextState = {
    sitewideNotifications: SitewideNotification[];
    asyncNotifications: UserAsyncProcess[];
    isNotificationDrawerOpen: boolean;
    setSitewideNotifications: (notifications: SitewideNotification[]) => void;
    setAsyncNotifications: (notifications: UserAsyncProcess[]) => void;
    setNotificationSeen: (notificationId: string) => void;
    setNotificationDismiss: (notificationId: string) => void;
    setNotificationDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

export const NotificationContext = createContext<NotificationContextState>({} as NotificationContextState);

type NotificationProviderProps = {
    children?: React.ReactNode;
};

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
    const [sitewideNotifications, setSitewideNotifications] = useState<SitewideNotification[]>([]);
    const [asyncNotifications, setAsyncNotifications] = useState<UserAsyncProcess[]>([]);

    const [isNotificationDrawerOpen, setNotificationDrawerOpen] = useState(false);

    /* site wide notification manipulations */
    const setNotificationSeen = useCallback((notificationId: string) => {
        setSitewideNotifications((notifications) => {
            return notifications.map((siteNotification) =>
                siteNotification.id === notificationId ? { ...siteNotification, seen: true } : siteNotification,
            );
        });
    }, []);

    const setNotificationDismiss = useCallback((notificationId: string) => {
        setSitewideNotifications((notifications) => {
            return notifications.filter((siteNotification) => siteNotification.id !== notificationId);
        });
    }, []);

    const value = useMemo(
        () => ({
            sitewideNotifications,
            asyncNotifications,
            setSitewideNotifications,
            setAsyncNotifications,
            setNotificationSeen,
            setNotificationDismiss,
            isNotificationDrawerOpen,
            setNotificationDrawerOpen,
        }),
        [
            asyncNotifications,
            isNotificationDrawerOpen,
            setNotificationDismiss,
            setNotificationSeen,
            sitewideNotifications,
        ],
    );

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
