import { styled } from '@mui/material';
import GridMui from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export const GridContainer = styled(GridMui)<{ container: true }>`
    height: 100%;
    flex-direction: row;
    overflow: hidden;
`;

export const DragAndDropSection = styled('div')({ height: '100%' });

export const GridItem = styled(GridMui)(() => ({
    height: '100%',
    overflow: 'hidden',
    padding: '0 3px',
}));

export const Item = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    borderRadius: 0,
    borderLeft: '1px solid #ccc',
    height: '100%',
}));
