import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import { Box, FormControl, SxProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import { InputProps } from '@mui/material/Input';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { isEmpty } from 'lodash';
import { isNotEmpty, toLower } from 'utilities';

import Icon from 'components/tokens/Icon';
import ChevronDown from 'components/tokens/Icon/Assets/chevron_down';

import { LabelWithTooltip } from './LabelWithTooltip';
import { BaseValue, ChangeValue, DefaultValueObjectsProps, OptionProps, SubOption } from './NestedSelectTypes';
import {
    StyledDivider,
    StyledMenuItem,
    StyledOptionsNotFound,
    StyledOverFlowEllipsis,
    StyledSearchField,
    StyledSearchListItem,
    StyledSelectLabel,
    StyledSelectLabelWrapper,
} from './styled';
import { SubMenu } from './SubMenu';

type NestedSelectProps = {
    error?: boolean;
    nestedOptions: OptionProps[];
    onChange: (changeObj: ChangeValue) => void;
    fullWidth?: boolean;
    width?: number | string;
    height?: number | string;
    slimInactiveBorder?: boolean;
    placeholder?: string;
    defaultLabel?: string;
    defaultValueObject?: DefaultValueObjectsProps;
    logo?: React.ReactNode;
    searchable?: boolean;
    id?: string;
    /** Boolean value to either render or do not render parent menu's value  */
    showParentValue?: boolean;
    openInitialDefaultSelectionOnMenuOpen?: boolean;
    disableOption?: (option: OptionProps) => boolean;
    customInputClickCallback?: () => void;
    containerSx?: SxProps;
    disabled?: boolean;
    showSelectedCheckmark?: boolean;
    inputProps?: InputProps['inputProps'];
    selectSx?: SxProps;
    forgetSelected?: boolean;
    /** Return false to cancel opening */
    onOpen?: () => false | undefined;
    onClose?: () => void;
    forceOpen?: boolean;
};

export const NestedSelect: React.FC<NestedSelectProps> = ({
    error,
    nestedOptions,
    onChange,
    fullWidth = false,
    width = '200px',
    height,
    searchable = true,
    openInitialDefaultSelectionOnMenuOpen = true,
    placeholder = 'Select',
    defaultLabel,
    defaultValueObject,
    slimInactiveBorder = false,
    id = 'nested-select-1',
    showParentValue = false,
    disableOption = (option) => false,
    customInputClickCallback,
    logo,
    containerSx,
    showSelectedCheckmark,
    selectSx,
    forgetSelected = false,
    onOpen,
    onClose,
    forceOpen,
    ...props
}) => {
    const [anchorEl, setAnchorEl] = React.useState<Record<string, EventTarget | HTMLElement | null>>({});
    const [openSelect, setOpenSelect] = React.useState(openInitialDefaultSelectionOnMenuOpen);
    const [focusedObject, setFocusedObject] = React.useState('');
    const [selectedValue, setSelectedValue] = useState<ChangeValue>({} as ChangeValue);
    const [selectedObjectNestedKeyList, setSelectedObjectNestedKeyList] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const parentRef = useRef<Record<string, HTMLElement>>({});

    useEffect(() => {
        if (forceOpen) {
            setOpenSelect(true);
        }
    }, [forceOpen]);

    /**
     * getFilteredObject - Filter options by default value and object and returns filtered object tree
     * @returns {Object} - Returns an array of object
     */
    const getFilteredObject = (
        array: OptionProps[] = [],
        selectedRootObjName = '',
        selectedLabel: string,
        selectedVal: string,
    ) => {
        const fn = ({ label, value }: BaseValue) => {
            return toLower(value) === toLower(selectedVal) && toLower(label) === toLower(selectedLabel);
        };
        const filteredObjectList = array?.filter((each) => {
            const { label, value } = each;
            if (selectedRootObjName) {
                return (
                    toLower(value) === toLower(selectedRootObjName) || toLower(label) === toLower(selectedRootObjName)
                );
            }
            return true;
        });
        return filterData(filteredObjectList, fn);
    };

    const getSelectedObjectKeyValCombination = (
        obj = {} as OptionProps | SubOption,
        results: string[] = [],
        valueToFind: string,
    ): string[] => {
        const optionValue = obj?.value;
        const optionLabel = obj?.label;

        if (optionValue === valueToFind) {
            results.push(`${optionLabel}${optionValue}`);
            return results;
        }

        const innerOptions = obj?.options || [];
        if (isNotEmpty(innerOptions)) {
            for (const innerOption of innerOptions) {
                const tempResult = getSelectedObjectKeyValCombination(innerOption, results, valueToFind);
                if (isNotEmpty(tempResult)) {
                    results.unshift(`${obj.label}${obj.value}`);
                    return results;
                }
            }
        }
        return results;
    };

    const updateValuesFromSelectedTree = ({
        object,
        label: fieldLabel,
        value: fieldValue,
        key: fieldKey,
    }: DefaultValueObjectsProps) => {
        const [defaultObject]: OptionProps[] = getFilteredObject(nestedOptions, object, fieldLabel, fieldValue);

        if (defaultObject && !isEmpty(defaultObject)) {
            const nestedValues = getSelectedObjectKeyValCombination(defaultObject, [], fieldValue);
            setSelectedObjectNestedKeyList(nestedValues);
            if (forgetSelected) {
                return;
            }
            setSelectedValue({
                ...selectedValue,
                value: fieldValue,
                label: fieldLabel,
                alias: defaultObject?.alias,
                object,
                key: fieldKey,
            });
        }
    };

    const filterData = <T extends SubOption | OptionProps>(array: T[], fn: (_o: T) => boolean) => {
        return array.reduce((r: T[], o) => {
            const options = filterOptionsByLabelAndValue(o.options || []);
            if (fn(o) || options?.length) r.push({ ...o, ...(options.length && { options }) });
            return r;
        }, []);
    };

    const filterOptionsByLabelAndValue = (array: SubOption[] = []) => {
        const fn = ({ value, label }: { value: string; label: string }) => {
            return toLower(value).includes(toLower(searchValue)) || toLower(label).includes(toLower(searchValue));
        };
        return filterData(array, fn);
    };

    const filterNestedOptionsByKeyword = <T extends OptionProps | SubOption>(array: T[] = []) => {
        const fn = ({
            value,
            label,
            searchKeywords = '',
        }: {
            value: string;
            label: string;
            searchKeywords: string;
        }) => {
            return (
                toLower(value).includes(toLower(searchValue)) ||
                toLower(label).includes(toLower(searchValue)) ||
                toLower(searchKeywords).includes(toLower(searchValue))
            );
        };

        return array.reduce((r: T[], o) => {
            const options = filterNestedOptionsByKeyword(o.options || []);
            const compareObj = {
                value: o.value,
                label: o.label,
                searchKeywords: o.searchKeywords || '',
            };
            if (fn(compareObj) || options?.length) r.push({ ...o, ...(options.length && { options }) });
            return r;
        }, []);
    };

    const handleMenuClose = () => {
        setOpenSelect(false);
        setAnchorEl({});
        if (focusedObject !== selectedValue.key) {
            /** To prevent the flashing of the SubMenu on closing the menu */
            const timerId = setTimeout(() => {
                setFocusedObject(selectedValue.key);
            }, 400);
            clearTimeout(timerId);
        }
    };

    const handleMouseEnter = (e: MouseEvent, key: string = '') => {
        if (openSelect) {
            setAnchorEl({
                [key]: e.currentTarget,
            });
            setFocusedObject(key);
        }
    };

    const handleMouseLeave = (e: MouseEvent, key: string = '') => {
        if (openSelect) {
            setAnchorEl({
                [key]: null,
            });
        }
    };

    const handleSelect = (selectedObject: ChangeValue) => {
        onChange(selectedObject);
        if (!forgetSelected) {
            setSelectedValue(selectedObject);
            updateValuesFromSelectedTree({
                object: selectedObject.object,
                label: selectedObject.label,
                value: selectedObject.value,
                key: selectedObject.key,
            });
        }
        // reset
        setOpenSelect(false);
        setAnchorEl({});
        /** To prevent the flickering of the options when the Select component is closed */
        const timerId = setTimeout(() => {
            setSearchValue('');
        }, 400);

        clearTimeout(timerId);
    };

    useEffect(() => {
        // Get initially selected objects and set to state
        if (defaultValueObject && !isEmpty(defaultValueObject)) {
            updateValuesFromSelectedTree({
                ...defaultValueObject,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValueObject]);

    const filteredOptions = filterNestedOptionsByKeyword(nestedOptions);

    const renderSelectValue = (val: string) => {
        if (val === placeholder) {
            return val;
        }

        if (!val && placeholder) {
            return placeholder;
        }

        const isObjectExists = selectedValue?.object && selectedValue?.object !== selectedValue?.value;
        // @FIXME -- Make it more customizable and not dependent on single `selectedValue.object` value
        const showObjectPrefix = showParentValue ? isObjectExists && selectedValue?.object !== 'Picklist' : false;
        return (
            <Grid container alignItems="center" style={{ flexWrap: 'nowrap' }}>
                {logo && (
                    <Grid item xs="auto" style={{ display: 'flex', marginRight: 6 }}>
                        {logo}
                    </Grid>
                )}
                <StyledOverFlowEllipsis item>
                    <Tooltip
                        title={`${showObjectPrefix ? `${selectedValue?.alias || selectedValue?.object}: ` : ''}${val}`}
                    >
                        <StyledSelectLabelWrapper>
                            {`${showObjectPrefix ? `${selectedValue?.alias || selectedValue?.object}: ` : ''}${val}`}
                        </StyledSelectLabelWrapper>
                    </Tooltip>
                </StyledOverFlowEllipsis>
            </Grid>
        );
    };

    return (
        <FormControl sx={{ ...containerSx }}>
            {defaultLabel && <StyledSelectLabel>{defaultLabel}</StyledSelectLabel>}
            <Select
                value={selectedValue?.label ?? ''}
                renderValue={renderSelectValue}
                displayEmpty
                autoWidth={fullWidth}
                open={openSelect}
                IconComponent={ChevronDown}
                sx={{
                    width,
                    ...(height && { height }),
                    '.MuiSelect-icon': {
                        color: props.disabled ? '#0a0a0a80' : 'icon.basic',
                    },
                    '.MuiGrid-root:nth-of-type(2)': {
                        width: '100%',
                        span: {
                            display: 'inline-block',
                            width: '90%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    },
                    ...(error && {
                        '& .MuiOutlinedInput-notchedOutline ': {
                            borderColor: 'rgba(232, 77, 158, 1)',
                        },
                    }),
                    fontSize: 14,
                    ...selectSx,
                }}
                onOpen={(e) => {
                    if (onOpen?.() === false) {
                        return;
                    }
                    setAnchorEl({
                        main: e.target,
                    });
                    setOpenSelect(true);
                }}
                onClose={(e) => {
                    onClose?.();
                    const target = e?.target as HTMLTextAreaElement;
                    if (target?.nodeName === 'INPUT') {
                        // onClick on search field
                        return;
                    }
                    handleMenuClose();
                }}
                MenuProps={{
                    elevation: 3,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    MenuListProps: {
                        sx: {
                            padding: '6px 8px',
                        },
                    },
                    PaperProps: {
                        sx: {
                            marginTop: 0.5,
                        },
                    },
                }}
                {...props}
            >
                {/* Display search filter if searchable is true */}
                {searchable && (
                    <StyledSearchListItem
                        disableRipple
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setAnchorEl({
                                ...anchorEl,
                                main: event.currentTarget,
                            });
                        }}
                        // onKeyDown={(e) => e.stopPropagation()}
                    >
                        <StyledSearchField
                            placeholder="Search..."
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={searchValue}
                            autoComplete="off"
                            InputLabelProps={{ shrink: false }}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setAnchorEl({
                                    ...anchorEl,
                                    main: event.currentTarget,
                                });
                            }}
                            inputProps={{ 'data-testid': 'nested-menu-search' }}
                        />
                    </StyledSearchListItem>
                )}

                {filteredOptions.map((eachOption) => {
                    const {
                        value,
                        label,
                        alias,
                        options = [],
                        isCustomInput,
                        endAdornment,
                        disable,
                        description,
                        example,
                        showSeparator,
                    } = eachOption;
                    const isMenuItemSelected = toLower(value) === toLower(selectedValue?.object);
                    const currentAnchor: Record<string, EventTarget | HTMLElement | null> = {
                        ...anchorEl,
                        ...(isMenuItemSelected && !anchorEl[label + value]
                            ? {
                                  [label + value]: parentRef.current[label + value],
                              }
                            : {}),
                    };
                    const isMenuItemActive =
                        isMenuItemSelected ||
                        Boolean(currentAnchor[label + value]) ||
                        selectedObjectNestedKeyList.includes(label + value);

                    const subMenuToOpen =
                        focusedObject && focusedObject === label + value && !!options?.length
                            ? true
                            : (Boolean(currentAnchor[label + value]) || isMenuItemActive) &&
                              !!options?.length &&
                              openInitialDefaultSelectionOnMenuOpen &&
                              !focusedObject;

                    const handleMenuItemClick = (event: React.MouseEvent) => {
                        event.stopPropagation();

                        if (disable) {
                            return;
                        }

                        if (isCustomInput) {
                            customInputClickCallback?.();
                            setOpenSelect(false);
                            return;
                        }
                        if (!options?.length) {
                            handleSelect({
                                label,
                                value,
                                object: value,
                                key: label + value,
                            });
                        }
                    };
                    return (
                        <div
                            onMouseEnter={(e) => handleMouseEnter(e, label + value)}
                            onMouseLeave={(e) => handleMouseLeave(e, label + value)}
                            key={label + value}
                            ref={(el) => {
                                if (el) {
                                    parentRef.current[label + value] = el;
                                }
                            }}
                        >
                            {isCustomInput && <StyledDivider />}
                            <StyledMenuItem
                                id={`menu-item-${label}${value}`}
                                value={value}
                                selected={isMenuItemActive}
                                disabled={disableOption(eachOption)}
                                onClick={handleMenuItemClick}
                                // onScroll={(e) => {
                                //     e.stopPropagation();
                                // }}
                            >
                                <LabelWithTooltip
                                    label={alias || label}
                                    isOption={!!options?.length && !isCustomInput}
                                    description={description}
                                    example={example}
                                    endAdornment={endAdornment}
                                    count={searchValue ? options?.length : 0}
                                />
                                {/* render sub menu if options exists */}
                                <SubMenu
                                    depth={1}
                                    open={isCustomInput ? false : subMenuToOpen}
                                    parentAnchorEle={parentRef.current}
                                    label={label}
                                    value={value}
                                    options={options}
                                    disableOption={disable ? (option: OptionProps) => true : disableOption}
                                    selectValue={({ label: subLabel, value: subValue, option, parent }) => {
                                        handleSelect({
                                            label: subLabel,
                                            value: subValue,
                                            object: value,
                                            parent,
                                            key: label + value,
                                            option,
                                        });
                                    }}
                                    selectedValue={selectedValue}
                                    selectedObjectNestedKeyList={selectedObjectNestedKeyList}
                                />

                                {showSelectedCheckmark && isMenuItemSelected && (
                                    <Icon type="CheckBig" color="text.subtle" />
                                )}
                            </StyledMenuItem>
                            {showSeparator && (
                                <Box sx={{ marginY: 1, borderTop: '1px solid', borderColor: 'border' }} />
                            )}
                        </div>
                    );
                })}
                {/* Show feedback if no options are available */}
                {filteredOptions.length === 0 ? (
                    <StyledOptionsNotFound
                        onClick={(event) => {
                            event.stopPropagation();
                            setAnchorEl({
                                ...anchorEl,
                            });
                        }}
                        disableRipple
                    >
                        <span role="img" aria-label="thinking_face" style={{ marginRight: 5 }}>
                            🤔
                        </span>{' '}
                        Sorry, we couldn’t find that
                    </StyledOptionsNotFound>
                ) : null}
            </Select>
        </FormControl>
    );
};

export default NestedSelect;
