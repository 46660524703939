import { Box } from '@mui/material';

import Button from 'components/tokens/Button';
import Typography from 'components/tokens/Typography';
import { useAuthContext } from 'contexts/AuthContext';
import { getUserEmailFromToken } from 'contexts/utilities/authToken';

export const ImpersonateHeader: React.FC = () => {
    const { authState, impersonateAuthState, stopImpersonation } = useAuthContext();

    const handleStopImpersonationClick = () => {
        stopImpersonation();
    };
    const originalEmail = getUserEmailFromToken(authState?.access);

    if (!impersonateAuthState) {
        return null;
    }
    if (impersonateAuthState.access && authState?.access) {
        return (
            <Box
                sx={{
                    backgroundColor: 'warning.main',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 48,
                    paddingX: 4,
                }}
            >
                <Typography variant="small" weight="semibold">
                    You’re logged in as{' '}
                    <Typography variant="small" weight="bold">
                        {impersonateAuthState.email}
                    </Typography>
                    .{' '}
                    <Button variant="secondary" size="small" onClick={handleStopImpersonationClick}>
                        {originalEmail ? `Log out back to ${getUserEmailFromToken(authState.access)}` : 'Log out'}
                    </Button>
                </Typography>
            </Box>
        );
    }
    return null;
};

export const useShowImpersonateHeader = () => {
    const { authState, impersonateAuthState } = useAuthContext();
    return !!(impersonateAuthState?.access && authState?.access);
};

export default ImpersonateHeader;
