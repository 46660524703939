import React, { useState, useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import { TabChangeHandler } from 'components/modules/profiles/Profile';
import { TabSlug } from 'components/modules/profiles/Profile/tabs';
import Frame from 'components/tokens/Frame';
import useThrottle from 'hooks/useThrottle';

import { TabsContent } from './TabsContent';

export type ProfileTab = {
    name: TabSlug;
    label: string;
    skeleton?: boolean;
};

export type ProfileTabsProps = {
    tabs: readonly ProfileTab[];
    activeTab: TabSlug;
    locale?: string;
    frame?: boolean;
    /* Indicates if more data is still being loaded, while partial data may already be available. */
    loading?: boolean;
    onChange?: TabChangeHandler;
};

export const ProfileTabs: React.VFC<ProfileTabsProps> = ({
    tabs,
    activeTab,
    frame = true,
    loading = false,
    onChange,
}) => {
    const [[visibleTabs, overflownTabs, visibleTabCount], setTabData] = useState<
        [visibleTabs: readonly ProfileTab[], overflownTabs: readonly ProfileTab[], visibleTabCount: number]
    >([[], [], 0]);
    const resizeObserver = useRef<ResizeObserver>();
    const tabsRef = useRef<HTMLButtonElement>(null);

    const moreButtonWidth = 48;
    const throttleDelay = 67; // ms; 15 fps
    const handleResize = useThrottle(
        (tabsElement: HTMLElement, selectedTab: TabSlug, allTabs: readonly ProfileTab[]) => {
            const tabsContainer = tabsElement.parentElement;
            if (tabsContainer) {
                const tabButtons = tabsElement.getElementsByClassName('MuiTab-root');
                const containerWidth = tabsContainer.clientWidth - moreButtonWidth;
                const selectedTabId = `profileTabs-${selectedTab}`; // This is set in render.
                let fittingButtonsCount = 1; // The active button will always fit.
                let fittingButtonsWidth = tabButtons.namedItem(selectedTabId)?.clientWidth ?? 0; // Init the total width with the width of the active tab
                for (const button of tabButtons) {
                    if (button.id !== selectedTabId) {
                        if (button.clientWidth + fittingButtonsWidth > containerWidth) {
                            break;
                        }
                        // If can fit one more button, add its width to accrued total.
                        fittingButtonsCount++;
                        fittingButtonsWidth += button.clientWidth;
                    }
                }
                const selectedTabIndex = allTabs.findIndex((tab) => tab.name === selectedTab);
                if (selectedTabIndex >= fittingButtonsCount) {
                    const visibleTabs = [...allTabs.slice(0, fittingButtonsCount - 1), allTabs[selectedTabIndex]];
                    const overflownTabs = allTabs
                        .slice(fittingButtonsCount - 1)
                        .filter((tab) => tab.name !== selectedTab);
                    setTabData([visibleTabs, overflownTabs, fittingButtonsCount]);
                } else {
                    const visibleTabs = allTabs.slice(0, fittingButtonsCount);
                    const overflownTabs = allTabs.slice(fittingButtonsCount);
                    setTabData([visibleTabs, overflownTabs, fittingButtonsCount]);
                }
                // setVisibleTabCount(fittingButtonsCount);
            }
        },
        throttleDelay,
    );

    useEffect(() => {
        if (ResizeObserver) {
            resizeObserver.current = new ResizeObserver(() => {
                const tabsElement = tabsRef.current;
                if (tabsElement) {
                    const tabsContainer = tabsElement.parentElement;
                    if (tabsContainer) {
                        setTabData([tabs, [], visibleTabCount]);
                        window.requestAnimationFrame(() => {
                            handleResize(tabsElement, activeTab, tabs);
                        });
                    }
                }
            });
            if (tabsRef.current?.parentElement) {
                resizeObserver.current.observe(tabsRef.current.parentElement);
            }
            return () => resizeObserver.current?.disconnect();
        }
    }, [handleResize, activeTab, tabs, visibleTabCount]);

    const handleTabChange = (tabName: TabSlug) => {
        const tabsElement = tabsRef.current;
        if (tabsElement) {
            setTabData([tabs, [], visibleTabCount]);
            window.requestAnimationFrame(() => {
                handleResize(tabsElement, tabName, tabs);
            });
        }
        onChange?.(tabName);
    };

    const content = (
        <TabsContent
            visibleTabs={visibleTabs}
            overflownTabs={loading ? [] : overflownTabs}
            activeTab={activeTab}
            visibleTabCount={visibleTabCount}
            moreButtonWidth={moreButtonWidth}
            tabsRef={tabsRef}
            onChange={handleTabChange}
        />
    );

    return frame ? (
        <Frame sx={{ paddingX: 2 }} elevation={1} padding="none" variant="white">
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {content}
            </Box>
        </Frame>
    ) : (
        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>{content}</Box>
    );
};

export default ProfileTabs;
