import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowLongUp = (props) => (
    <SvgIcon {...props}>
        <path d="M13 5.83L15.59 8.41L17 7L12 2L7 7L8.41 8.41L11 5.83V22H13V5.83Z" />
    </SvgIcon>
);

export default ArrowLongUp;
