import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { subYears } from 'date-fns';

import { UserData, AccountData } from 'api/types/Usage';
import { getUserData, getAccountData, getUserProfile } from 'api/usageData';
import { useAxiosContext } from 'contexts/AxiosContext';
import { logSentry } from 'utilities/logUtils';

const sanitizeString = <T>(value: T) => {
    if (value && typeof value === 'string') {
        return value.slice(0, 255);
    }
    if (value && typeof value === 'number') {
        return value.toString();
    }
    return value;
};

const sanitizeInt = <T>(value: T) => {
    if (value && typeof value === 'number' && value <= -2147483648) {
        return -2147483647;
    }
    if (value && typeof value === 'number' && value >= 2147483647) {
        return 2147483646;
    }
    if (value && typeof value === 'string') {
        const integer = parseInt(value);
        if (integer) {
            if (integer <= -2147483648) {
                return -2147483647;
            }
            if (integer >= 2147483647) {
                return 2147483646;
            }
            return integer;
        }
        return 0;
    }
    return value;
};

export interface IntercomPermissionData {
    'Product - Permission - Database': string;
    'Product - Permission - Financial': string;
    'Product - Permission - VCI': boolean;
    'Product - Permission - Contacts': boolean;
    'Product - Permission - Prospect': boolean;
    'Product - Permission - Prospect - Export': string;
    'Product - Permission - Connectors': boolean;
    'Product - Permission - Connectors - Database': string;
    'Product - Permission - Connectors - CRM': string;
    'Product - Permission - Connectors - Updates'?: boolean;
    'Product - Permission - Vainu View Only': boolean;
    'Product - Permission - API': boolean;
    'Product - Triggers - Active': number;
    'Product - Connectors - Connected': string;
}

export interface IntercomCompanyData extends IntercomPermissionData {
    name?: AccountData['account_name'];
    company_id?: AccountData['account_id'];
    'Basic - Business id'?: AccountData['business_id'];
    'Basic - Planhat company id'?: AccountData['planhat_company_id'];
    'Basic - Description'?: AccountData['planhat_description'];
    'Basic - Planhat link': string;
    'Basic - Admin tools link': string;
    'Contract - Customer since'?: AccountData['customer_from'];
    'Contract - MRR'?: AccountData['mrr'];
    'Contract - Segment'?: AccountData['planhat_segment'];
    'Contract - Pricing'?: AccountData['planhat_pricing'];
    'Contract - Phase': AccountData['phase'];
    'Contract - Renewal date': AccountData['renewal_date'];
    'Contract - Reset date': AccountData['quota_reset_date'];
    'Contract - Owner': AccountData['owner'];
    'Usage - File exports - Total'?: AccountData['csv_exports'];
    'Usage - API - Total'?: AccountData['api_exports'];
    'Usage - Matched companies - Current'?: AccountData['current_matched_companies'];
    'Usage - Updated companies - Total'?: AccountData['total_updated_companies'];
    'Usage - API calls and file exports - Quota'?: AccountData['exported_companies_quota'];
    'Usage - Matched companies - Quota'?: AccountData['matched_companies_quota'];
    'Usage - Updated companies - Quota'?: AccountData['updated_companies_quota'];
    'Usage - Current period - Start date'?: AccountData['current_usage_period_start_date'];
    'Usage - UBP start date'?: AccountData['usage_based_pricing_start_date'];
    'Usage - API calls - Total'?: AccountData['api_exports'];
    'Product - Users - Quota'?: AccountData['user_limit'];
    'Product - Users - Total'?: AccountData['user_count'];
    'Product - Users - Active'?: AccountData['active_users_count'];
    'Product - Users - Invited'?: AccountData['invitations_count'];
    'Product - Custom file export limit'?: AccountData['custom_export_limit'];
    'Legacy - Custom export limit'?: AccountData['custom_export_limit'];
}

export interface IntercomUserData extends IntercomPermissionData {
    email: UserData['email'];
    user_id?: UserData['user_id'];
    name: UserData['name'];
    company?: IntercomCompanyData;
    user_hash?: string;
    'Product - Platform': string;
    'Basic - Role - Admin': UserData['is_account_admin'];
    'Basic - Social auth': string;
    'Basic - Password set': boolean;
    'Basic - Admin tools link': string;
    'Basic - Status - Active': boolean;
}

export const initializeIntercomUser = (data: IntercomUserData) => {
    if (process.env.REACT_APP_INTERCOM_APP_ID) {
        try {
            window.intercomSettings = {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                api_base: 'https://api-iam.eu.intercom.io',
                user_hash: data.user_hash,
                email: data.email,
                name: data.name,
            };
            window.Intercom('boot', window.intercomSettings);
            window.Intercom('update', data);
        } catch (err) {
            logSentry(err);
        }
    }
};

const databaseLabels: Record<string, string> = {
    DK: 'Denmark',
    FI: 'Finland',
    NO: 'Norway',
    SE: 'Sweden',
    global_data: 'Global',
};

const pdfPermissionValues: Record<string, string> = {
    financial_pdf_readonly: 'PDF read',
    financial_pdf: 'PDF search & read',
    allow_prh_statement_download: 'Statement download',
};

const exportPermissionValues: Record<string, string> = {
    can_export_saved_searches: 'Full',
    no_excel_export_allowed: 'None',
    can_export_contact_details: 'Contacts',
};

const crmPermissionValues: Record<string, string> = {
    upsales: 'Upsales',
    superoffice: 'Superoffice',
    can_use_zapier: 'Zapier',
    pipedrive: 'Pipedrive',
    dynamics: 'Dynamics',
    salesforce: 'Salesforce',
    hubspot: 'HubSpot',
    salesforcesandbox: 'SalesforceSandbox',
};

const pickPermissions = (data: AccountData | UserData): IntercomPermissionData => {
    const permissions = data.permissions;
    const integrations = data.integrations;
    const databases = data.databases.map((value) => databaseLabels[value]).filter(Boolean);
    const financialPdf = permissions.reduce((accumulator, currentValue) => {
        if (currentValue in pdfPermissionValues) {
            accumulator.push(pdfPermissionValues[currentValue]);
            return accumulator;
        }
        return accumulator;
    }, [] as Array<string>);
    const contactPermissions = permissions.reduce((accumulator, currentValue) => {
        if (currentValue in exportPermissionValues) {
            accumulator.push(exportPermissionValues[currentValue]);
            return accumulator;
        }
        return accumulator;
    }, [] as Array<string>);
    const crmPermissions = permissions.reduce((accumulator, currentValue) => {
        if (currentValue in crmPermissionValues) {
            accumulator.push(crmPermissionValues[currentValue]);
            return accumulator;
        }
        return accumulator;
    }, [] as Array<string>);
    const connectorTypesSet = new Set(
        integrations.map((value) => (value.includes('business_id') ? 'Nordic' : 'Global')),
    );
    const crmsSet = new Set(
        integrations.map((value) => {
            const crmPart = value.split('_').pop();
            if (crmPart && crmPart in crmPermissionValues) {
                return crmPermissionValues[crmPart];
            }
            return crmPart;
        }),
    );

    return {
        'Product - Permission - Database': databases.join(','),
        'Product - Permission - Financial': financialPdf.join(','),
        'Product - Permission - VCI': permissions.includes('industry_categories_beta'),
        'Product - Permission - Contacts': permissions.includes('data_catalogue_contact_details'),
        'Product - Permission - Prospect - Export': sanitizeString(contactPermissions.join(',')),
        'Product - Permission - Connectors': permissions.includes('crm_settings'),
        'Product - Permission - Connectors - Database': Array.from(connectorTypesSet).join(','),
        'Product - Permission - Connectors - CRM': sanitizeString(crmPermissions.join(',')),
        'Product - Permission - Connectors - Updates': permissions.includes('crm_data_update'),
        'Product - Permission - Prospect': permissions.includes('vainu_ui'),
        'Product - Permission - Vainu View Only': permissions.includes('read_company_cards'),
        'Product - Permission - API': data.api_permission,
        'Product - Triggers - Active': sanitizeInt(data.number_of_active_triggers),
        'Product - Connectors - Connected': sanitizeString(Array.from(crmsSet).join(',')),
        ...('connector_updates' in data && {
            'Product - Connectors - Automatic updates on': 'connector_updates' in data ? data.connector_updates : false,
        }),
        ...('connector_matching' in data && {
            'Product - Connectors - Matching on': 'connector_matching' in data ? data.connector_matching : false,
        }),
    };
};

const pickAccountData = (accountData: AccountData): IntercomCompanyData => {
    return {
        company_id: accountData.account_id,
        name: sanitizeString(accountData.account_name),
        'Basic - Business id': accountData.business_id,
        'Basic - Planhat company id': accountData.planhat_company_id,
        'Basic - Description': sanitizeString(accountData.planhat_description),
        'Basic - Admin tools link': sanitizeString(
            `${accountData.service_url || ''}/admintools/accounts/${accountData.account_id}/`,
        ),
        'Basic - Planhat link': sanitizeString(`https://app.planhat.com/profile/${accountData.planhat_company_id}`),
        'Contract - Customer since': sanitizeString(accountData.customer_from),
        'Contract - MRR': sanitizeInt(accountData.mrr),
        'Contract - Segment': sanitizeString(accountData.planhat_segment),
        'Contract - Pricing': sanitizeString(accountData.planhat_pricing ?? null),
        'Contract - Phase': sanitizeString(accountData.phase),
        'Contract - Renewal date': sanitizeString(accountData.renewal_date),
        'Contract - Reset date': sanitizeString(accountData.quota_reset_date),
        'Contract - Owner': sanitizeString(accountData.owner),
        'Usage - File exports - Total': sanitizeInt(accountData.csv_exports),
        'Usage - API calls - Total': sanitizeInt(accountData.api_exports),
        'Usage - Matched companies - Current': sanitizeInt(accountData.current_matched_companies),
        'Usage - Updated companies - Total': sanitizeInt(accountData.total_updated_companies),
        'Usage - API calls and file exports - Quota': sanitizeInt(accountData.exported_companies_quota),
        'Usage - Matched companies - Quota': sanitizeInt(accountData.matched_companies_quota),
        'Usage - Updated companies - Quota': sanitizeInt(accountData.updated_companies_quota),
        'Usage - Current period - Start date': sanitizeString(accountData.current_usage_period_start_date),
        'Usage - UBP start date': pickUBPStartDate(accountData),
        'Product - Users - Quota': sanitizeInt(accountData.user_limit),
        'Product - Users - Total': sanitizeInt(accountData.user_count),
        'Product - Users - Active': sanitizeInt(accountData.active_users_count),
        'Product - Users - Invited': sanitizeInt(accountData.invitations_count),
        'Product - Custom file export limit': sanitizeInt(accountData.custom_export_limit),
        ...pickPermissions(accountData),
    };
};

const pickUBPStartDate = (accountData: AccountData) => {
    if (accountData.usage_based_pricing_start_date) {
        return accountData.usage_based_pricing_start_date;
    }
    if (accountData.quota_reset_date) {
        return subYears(new Date(accountData.quota_reset_date), 1).toISOString();
    }
    return null;
};

const pickUserData = (userData: UserData): IntercomUserData => {
    // we cannot send user_id when user_hash is generated from email, when sending email and user_id the user_id takes preference
    return {
        email: userData.email,
        name: sanitizeString(userData.name),
        'Product - Platform': 'platform_2',
        'Basic - Role - Admin': userData.is_account_admin,
        'Basic - Social auth': sanitizeString(userData.user_social_auths.join(',')) ?? '',
        'Basic - Password set': userData.has_usable_password,
        'Basic - Admin tools link': sanitizeString(
            `${userData.service_url || ''}/admintools/users/${userData.profile_id}`,
        ),
        'Basic - Status - Active': true,
        ...pickPermissions(userData),
    };
};

export const useIntercomUser = (enabled: boolean = false) => {
    const axios = useAxiosContext();
    const {
        data: userData,
        isSuccess: userDataisSuccess,
        isError: userDataisError,
    } = useQuery({ queryKey: ['usage-user-data'], queryFn: () => getUserData(axios), enabled });
    const {
        data: accountData,
        isSuccess: accountDataisSuccess,
        isError: accountDataIsError,
    } = useQuery({ queryKey: ['usage-account-data'], queryFn: () => getAccountData(axios), enabled });

    const { data: profileData, isSuccess: userHashisSuccess } = useQuery({
        queryKey: ['intercom-user-hash'],
        queryFn: () => getUserProfile(axios),
        enabled,
    });
    const userHash = profileData?.intercom_user_auth;
    const accountType = profileData?.account_type;
    useEffect(() => {
        if (userDataisSuccess && accountDataisSuccess && userHashisSuccess) {
            const intercomData = {
                ...pickUserData(userData),
                company: pickAccountData(accountData),
                user_hash: userHash,
            };
            initializeIntercomUser(intercomData);
        } else if (userDataisSuccess && accountDataIsError && userHashisSuccess) {
            initializeIntercomUser({
                ...pickUserData(userData),
                user_hash: userHash,
            });
        }
    }, [
        userData,
        accountData,
        userHash,
        userDataisSuccess,
        userDataisError,
        accountDataisSuccess,
        accountDataIsError,
        userHashisSuccess,
    ]);
    return accountType;
};
