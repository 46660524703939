import React from 'react';

import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator, ValueOfCombineFilterOperator, ValueOfSingleFilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import ButtonTabs from 'components/tokens/ButtonTabs';
import Icon, { IconType } from 'components/tokens/Icon';
import { StyledMenuItem } from 'components/tokens/select-components/NestedSelect/styled';
import Switch from 'components/tokens/Switch';
import Typography from 'components/tokens/Typography';
import { getFirstKey } from 'utilities/objectUtils';

import {
    CombinedNodeValue,
    FilterConfig,
    FilterDefinition,
    FilterID,
    FilterNodeValue,
    FilterProps,
    GroupValues,
} from '../../FilterTypes';
import { getInnerMostValueInFilterState } from '../utils';

export type TValues = { phone: boolean; email: boolean; 'web_profiles#type': 'linkedin' };

const config: FilterConfig<TValues> = {
    fields: ['phone', 'email', 'web_profiles#type'],
    id: FilterID.contact_info,
    label: 'Contact info',
    defaultOperator: FilterOperator.EXISTS,
    initValue: {
        [FilterOperator.ANY]: [
            {
                [FilterOperator.EXISTS]: {
                    phone: false,
                },
            },
        ],
    },
};

type Option = {
    icon: IconType;
    label: string;
    operator: ValueOfSingleFilterOperator;
    field: string;
    checkedValue: boolean | string;
};

const options: Option[] = [
    {
        icon: 'Phone',
        label: 'Phone',
        operator: FilterOperator.EXISTS,
        field: 'phone',
        checkedValue: true,
    },
    {
        icon: 'Mail',
        label: 'E-mail',
        operator: FilterOperator.EXISTS,
        field: 'email',
        checkedValue: true,
    },
    {
        icon: 'LinkedIn',
        label: 'LinkedIn',
        operator: FilterOperator.EQ,
        field: 'web_profiles#type',
        checkedValue: 'linkedin',
    },
];

export const contactInfoFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.contacts,
        label: config.label,
        fields: config.fields,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <ContactInfo {...props} />,
        valueToText: (node: CombinedNodeValue) => {
            const operator = getFirstKey(node);
            const values = (node[operator] || []) as FilterNodeValue<TValues>[];

            return options
                .map((option) => {
                    const row = getInfoRow(values, option);
                    return row.value ? option.label : null;
                })
                .filter(Boolean)
                .join(operator === FilterOperator.ALL ? ' & ' : ' / ');
        },
        config,
        createFilter: () => config.initValue,
    },
};

const getInfoRow = (values: FilterNodeValue<TValues>[], option: Option) => {
    const idx = values.findIndex(
        (row) => row[option.operator] && option.field in (row[option.operator] as FilterNodeValue),
    );

    const value = idx !== -1 && getInnerMostValueInFilterState(values[idx], '');

    return { idx, value };
};

export const ContactInfo: React.FC<FilterProps<TValues>> = ({ filter, uuid }) => {
    const { control } = useFormContext();

    const fullValuePath = `${filter.groupPath}.${filter.path}`;

    return (
        <Controller
            name={fullValuePath}
            control={control}
            key={`${uuid}-input-value`}
            render={({ field }) => {
                const { onChange, value } = field;

                const operator: ValueOfCombineFilterOperator = getFirstKey(value);
                const values: FilterNodeValue<TValues>[] = value[operator];

                return (
                    <>
                        <ContactInfoMode operator={operator} onChange={onChange} values={values} />

                        {options.map((option) => (
                            <ContactInfoRow
                                key={option.field}
                                option={option}
                                values={values}
                                operator={operator}
                                onChange={onChange}
                            />
                        ))}
                    </>
                );
            }}
        />
    );
};

const ContactInfoMode = ({
    operator,
    onChange,
    values,
}: {
    operator: ValueOfCombineFilterOperator;
    onChange: (data: unknown) => void;
    values: FilterNodeValue<TValues>[];
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 1,
            }}
        >
            <Typography variant="body2">Contact has:</Typography>
            <ButtonTabs size="small" selected={operator}>
                {[FilterOperator.ANY, FilterOperator.ALL].map((op) => {
                    return (
                        <Button
                            key={op}
                            value={op}
                            onClick={() => {
                                if (operator !== op) {
                                    onChange({ [op]: values });
                                }
                            }}
                        >
                            {op === FilterOperator.ANY ? 'Any' : 'All'}
                        </Button>
                    );
                })}
            </ButtonTabs>
        </Box>
    );
};

const ContactInfoRow = ({
    option,
    values,
    operator,
    onChange,
}: {
    option: Option;
    values: FilterNodeValue<TValues>[];
    operator: ValueOfCombineFilterOperator;
    onChange: (data: unknown) => void;
}) => {
    const row = getInfoRow(values, option);

    return (
        <StyledMenuItem
            sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: 1 }}
            onClick={() => {
                if (row.idx !== -1) {
                    const newValues = [...values];
                    if (newValues.length > 1) {
                        newValues.splice(row.idx, 1);
                        onChange({ [operator]: newValues });
                    } else {
                        const newValue = row.value === option.checkedValue ? undefined : option.checkedValue;

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (newValues[row.idx] as any)[option.operator][option.field] = newValue;

                        onChange({ [operator]: newValues });
                    }
                } else {
                    onChange({
                        [operator]: [
                            // filter out falsy rows
                            ...values.filter((row) => {
                                const rowValue = getInnerMostValueInFilterState(row, '');
                                return rowValue;
                            }),
                            {
                                [option.operator]: {
                                    [option.field]: option.checkedValue,
                                },
                            },
                        ],
                    });
                }
            }}
        >
            <Typography variant="body2" sx={{ flex: 1 }}>
                {option.label}
            </Typography>
            <Icon type={option.icon} fontSize="small" />
            <Switch
                checked={row.value === option.checkedValue}
                sx={{
                    width: 40,
                    height: 24,
                    '& .MuiSwitch-thumb': {
                        width: 20,
                        height: 20,
                    },
                }}
            />
        </StyledMenuItem>
    );
};
