import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DynamicsOpportunitySvg: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M15.6409 1.66663H3.33325V18.0768H14.6153C14.8873 18.0768 15.1482 17.9688 15.3405 17.7764C15.5329 17.5841 15.6409 17.3232 15.6409 17.0512V9.10251C15.6393 8.75851 15.7717 8.42742 16.0101 8.17943C16.4343 7.73875 16.6698 7.15002 16.6666 6.53841V2.69226C16.6666 2.42025 16.5585 2.15937 16.3661 1.96703C16.1738 1.77468 15.9129 1.66663 15.6409 1.66663ZM14.6153 17.0512H4.35889V2.69226H13.5896V6.53841C13.5864 7.15002 13.8219 7.73875 14.246 8.17943C14.4844 8.42742 14.6169 8.75851 14.6153 9.10251V17.0512ZM15.6409 6.53841C15.6425 6.8824 15.5101 7.21349 15.2717 7.46148C15.2207 7.52054 15.1728 7.58216 15.1281 7.6461C15.0834 7.58216 15.0355 7.52054 14.9845 7.46148C14.7461 7.21349 14.6137 6.8824 14.6153 6.53841V2.69226H15.6409V6.53841Z" />
        <path d="M10.5127 6.79482H9.48708V10.8974H10.5127V6.79482Z" />
        <path d="M10.5127 11.923H9.48708V12.9487H10.5127V11.923Z" />
    </SvgIcon>
);
export default DynamicsOpportunitySvg;
