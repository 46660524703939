import React from 'react';

import { FilterOperator } from 'api/types/FilterOperators';
import { Values } from 'components/templates/RangeSelect';

import { FilterConfig, FilterDefinition, FilterID, FilterProps, GroupValues } from '../../FilterTypes';
import { useSimpleRangeFilter } from '../common/useSimpleRangeFilter';
import FilterTemplate from '../FilterTemplate';
import { getDefaultRange, makeFieldType, rangeValueToText } from '../utils';

const B = 1000000000;
const M = 1000000;
const K = 100;
const range = [
    1 * B,
    500 * M,
    400 * M,
    300 * M,
    200 * M,
    100 * M,
    90 * M,
    80 * M,
    70 * M,
    60 * M,
    50 * M,
    45 * M,
    40 * M,
    35 * M,
    30 * M,
    25 * M,
    20 * M,
    15 * M,
    10 * M,
    9 * M,
    8 * M,
    7 * M,
    6 * M,
    5 * M,
    4 * M,
    3 * M,
    2 * M,
    1 * M,
    900 * K,
    800 * K,
    700 * K,
    600 * K,
    500 * K,
    400 * K,
    300 * K,
    200 * K,
    150 * K,
    100 * K,
    90 * K,
    80 * K,
    70 * K,
    60 * K,
    50 * K,
    40 * K,
    30 * K,
    20 * K,
    10 * K,
    0,
];
const FIELD = makeFieldType(FilterID.salary_costs);

const rangeOptions = [...range.map((r) => r * -1)];
const defaultRange = getDefaultRange(rangeOptions);

export type TValues = { [FIELD]: Values };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.salary_costs,
    label: 'Salary costs',
    defaultOperator: FilterOperator.RANGE,
    initValue: {
        [FilterOperator.RANGE]: {
            [FIELD]: defaultRange,
        },
    },
};

/**
 * **This filter should only be selected once.**
 */
export const salaryCostsFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.financial,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <SalaryCosts {...props} />,
        valueToText: rangeValueToText(config.fields[0], rangeOptions),
        config,
        createFilter: () => config.initValue,
    },
};

export const SalaryCosts: React.VFC<FilterProps<TValues>> = ({ filter, uuid, groupUUID, removeFilter }) => {
    const props = useSimpleRangeFilter({
        filter,
        uuid,
        groupUUID,
        removeFilter,
        config,
        rangeOptions,
        defaultRange,
    });

    return <FilterTemplate {...props} />;
};
