import React, { useContext, useEffect, useRef, useState } from 'react';

import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { filteredLatestCrmImportProcess } from 'store/asyncProcessAtomSelectors';

import { SettingsDrawer } from './common/SettingsDrawer';
import { ConnectorTabContext, ConnectorTabContextProps } from './context/ConnectorTabContext';
import { CRMIntegConfigProvider, useCRMIntegConfig } from './context/CRMIntegrationConfigContext';
import { CompanyMatchingProvider, useCompanyMatch } from './hooks/useCompanyMatch';
import useConnectorsApi from './hooks/useConnectorsApi';
import { useConnectorTabStatus } from './hooks/useConnectorTabStatus';
import { SelectedConnectorsApp } from './navigation-atom';
import { CrmName } from './types/Crm';
import { setPlanhatOwnersToStorage } from './utils/planhatOwnerForAccount';

export const ConnectorDrawer: React.FC<{
    crm: CrmName;
}> = ({ crm }) => {
    return (
        <CRMIntegConfigProvider>
            <CompanyMatchingProvider crm={crm}>
                <ConnectorDrawerUnwrapped crm={crm} />
            </CompanyMatchingProvider>
        </CRMIntegConfigProvider>
    );
};
const ConnectorDrawerUnwrapped: React.FC<{
    crm: CrmName;
}> = ({ crm }) => {
    const connectorTabObjs = useContext(ConnectorTabContext) || {};
    const { isAccountLoading, isDomainConnector } = useContext(PermissionContext);
    const { fetchMatchingResults } = useCompanyMatch();
    const { openDrawer, defaultTab, onDrawerClose } = connectorTabObjs as ConnectorTabContextProps;
    const selectedConnectorsApp = useRecoilValue(SelectedConnectorsApp);
    const setSelectedConnectorsApp = useSetRecoilState(SelectedConnectorsApp);

    const { getProspectExportCount } = useConnectorsApi();
    const { error: errorTabs } = useConnectorTabStatus(crm);
    const [open, setOpen] = useState(false);

    const [{ error, crm: initedCrm }, { initConfig }] = useCRMIntegConfig();
    const { search } = useLocation();
    const navigate = useNavigate();
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

    const latestCRMImportProcess = useRecoilValue(filteredLatestCrmImportProcess({ crm }));

    const latestJob = latestCRMImportProcess;

    const latestJobIdRef = useRef('');

    useEffect(() => {
        if (!latestJob) return;

        if (!isAccountLoading && latestJob?.id !== latestJobIdRef.current && latestJob?.state === 'completed') {
            fetchMatchingResults();
            latestJobIdRef.current = latestJob?.id;
        }
    }, [isAccountLoading, latestJob, fetchMatchingResults]);

    const { crm: crmParam, connected, tab: tabFromQueryString, forceTabChange } = queryParams;
    const tab = defaultTab || tabFromQueryString;

    useEffect(() => {
        async function init() {
            try {
                if (connected) {
                    window.analytics?.track('Connector: Connected', { app: crm });
                }

                await Promise.all([
                    getProspectExportCount({
                        target: crm,
                        target_object: 'Account',
                        ...(isDomainConnector ? { matched_domain: 1 } : { matched: 1 }),
                    }),
                    initConfig(crm),
                ]);

                /* Fetch planhat cs owner data */
                setPlanhatOwnersToStorage();
            } catch (e) {
                console.error(e);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (openDrawer || (crmParam && crmParam === crm) || selectedConnectorsApp === (crm as string)) {
            setOpen(true);
        }
    }, [crm, crmParam, openDrawer, selectedConnectorsApp]);

    useEffect(() => {
        // open drawer by default
        if (forceTabChange) {
            navigate(
                {
                    search: qs.stringify({
                        ...queryParams,
                        crm: undefined,
                        tab: undefined,
                        forceTabChange: undefined,
                        object: undefined,
                    }),
                },
                { replace: true },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceTabChange]);

    function handleClose() {
        setOpen(false);
        // clean up query param that open drawer in the above useEffect, to prevent it from open again
        if (crmParam || tabFromQueryString) {
            navigate(
                {
                    search: qs.stringify({ ...queryParams, crm: undefined, tab: undefined }),
                },
                { replace: true },
            );
        }
        if (onDrawerClose) {
            onDrawerClose();
        }
        setSelectedConnectorsApp('');
    }

    return (
        <SettingsDrawer
            crm={crm}
            connected={!error}
            open={open}
            onClose={handleClose}
            disabledTabs={error || !initedCrm ? ['matching', 'mapping', 'sync'] : undefined}
            errorTabs={errorTabs?.length > 0 ? errorTabs : undefined}
            defaultTab={tab}
            forceTabChangeTo={forceTabChange ? tab : undefined}
        />
    );
};
