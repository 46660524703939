import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNotNil } from 'utilities';

import { getAsyncProcesses, stopAsyncProcessByProcessId } from 'api/asyncProcesses';
import { Stats } from 'api/filterCompanies';
import { GenericAsyncProcess } from 'api/types/UserAsyncProcess';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import useAsyncProcess from 'hooks/useAsyncProcess';

const useStats = ({ queryUid, processType }: { queryUid: string | null; processType: string }) => {
    const queryClient = useQueryClient();
    const [_, { pollUserAsyncProcess }] = useAsyncProcess();
    const { showSnackbar } = useSnackbarContext();
    const axios = useAxiosContext();

    const isCanceled = useRef(false);

    const updateStatsCache = useCallback(
        (process: GenericAsyncProcess | undefined, queryUid: string, state: 'completed' | 'in-progress') => {
            queryClient.setQueryData<GenericAsyncProcess[]>([queryUid], (oldData) => {
                const completed =
                    state === 'completed' ? process : oldData?.find((process) => process.state === 'completed');
                const inProgress =
                    state === 'in-progress' ? process : oldData?.find((process) => process.state !== 'completed');
                return [inProgress, completed].filter(isNotNil);
            });
        },
        [queryClient],
    );

    const query = useQuery({
        queryKey: [queryUid || ''],
        queryFn: () =>
            getAsyncProcesses(axios, {
                type: processType,
                state: ['completed', 'accepted', 'process', 'progress'],
                limit: 2,
                query_uid: queryUid,
            }).then((r) => r.data),
        enabled: !!queryUid,
        select: (data) => {
            const completed = data.find((process) => process.state === 'completed');
            const inProgress = data.find((process) => process.state !== 'completed');
            return { completed, inProgress };
        },
    });

    const onProcessPoll = useCallback(
        (process: GenericAsyncProcess) => {
            if (isCanceled.current || !queryUid) {
                return;
            }
            if (process.state === 'stopped') {
                updateStatsCache(undefined, queryUid, 'in-progress');
                return;
            }
            if (process.state === 'failure') {
                updateStatsCache(undefined, queryUid, 'in-progress');
                showSnackbar('Something went wrong', 'error');
            } else if (process.state === 'completed' && process.download_link) {
                updateStatsCache(process, queryUid, 'completed');
                updateStatsCache(undefined, queryUid, 'in-progress');
            } else {
                updateStatsCache(
                    {
                        ...query.data?.inProgress,
                        ...process,
                    },
                    queryUid,
                    'in-progress',
                );
            }
        },
        [query.data?.inProgress, queryUid, showSnackbar, updateStatsCache],
    );

    useEffect(() => {
        if (query.data?.inProgress) {
            pollUserAsyncProcess({
                jobId: query.data.inProgress.job_id,
                type: query.data.inProgress.type,
                onUpdate: onProcessPoll,
            });
        }
    }, [onProcessPoll, pollUserAsyncProcess, query.data?.inProgress, queryUid]);

    const generateStats = useCallback(
        async (request: () => Promise<GenericAsyncProcess>) => {
            if (queryUid) {
                isCanceled.current = false;
                const process = await request();
                updateStatsCache(process, queryUid, 'in-progress');
            }
        },
        [queryUid, updateStatsCache],
    );

    const statsQuery = useQuery<Stats[]>({
        queryKey: ['stats', query.data?.completed?.job_id, queryUid],
        queryFn: () => fetch(query.data?.completed?.download_link || '').then((r) => r.json()),
        enabled: !!query.data?.completed && !!queryUid,
    });

    const cancelAnalysis = useCallback(() => {
        isCanceled.current = true;

        if (query.data?.inProgress && queryUid) {
            stopAsyncProcessByProcessId(axios, query.data.inProgress.id);
            updateStatsCache(undefined, queryUid, 'in-progress');
        }
    }, [query.data?.inProgress, queryUid, axios, updateStatsCache]);

    return useMemo(
        () => ({
            processesQuery: query,
            generateStats,
            statsQuery,
            cancelAnalysis,
        }),
        [cancelAnalysis, generateStats, query, statsQuery],
    );
};

export default useStats;
