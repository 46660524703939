import React, { useRef } from 'react';

import { Box, Tooltip, useTheme } from '@mui/material';

import { useTextCutOff } from 'hooks/useTextCutOff';

type DescriptionCellProps = {
    description: string;
};
export const DescriptionCell: React.VFC<DescriptionCellProps> = ({ description }) => {
    const theme = useTheme();
    const descriptionRef = useRef<HTMLElement | null>(null);
    const [isTextCutOff, onRefresh] = useTextCutOff(descriptionRef);
    return (
        <Tooltip
            open={isTextCutOff}
            title={description}
            onOpen={() => onRefresh(true)}
            onClose={() => onRefresh(false)}
        >
            <Box
                ref={descriptionRef}
                sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '20rem',
                    [theme.breakpoints.down('xl')]: {
                        width: '10rem',
                    },
                }}
            >
                {description}
            </Box>
        </Tooltip>
    );
};
