import { useMemo } from 'react';

import { assertArray } from 'utilities';

import { SocialNetworksData } from 'api/profileData';
import { Company, SocialProfile } from 'api/types/company';
import { SocialMedia } from 'api/types/Organization';

import { SocialNetworkFeedsProps } from './Feeds';
import { SocialNetwork } from './Header';
import { SocialNetwork as SidebarSocialNetwork } from './SidebarCard';

export const sanitizeUrl = (url: string) => {
    return url.split('/')[0].split('?')[0];
};

const handleConfigs: Record<string, { prefix: string; bases: string[] }> = {
    twitter: {
        prefix: '@',
        bases: ['https://twitter.com/', 'https://www.twitter.com/'],
    },
    instagram: {
        prefix: '@',
        bases: ['https://instagram.com/', 'https://www.instagram.com/'],
    },
    facebook: {
        prefix: 'fb.com/',
        bases: ['https://facebook.com/', 'https://www.facebook.com/'],
    },
};

function getUserHandle(type: string, username?: string, url?: string): string {
    if (type in handleConfigs) {
        const { prefix, bases } = handleConfigs[type];
        if (username) {
            return `${prefix}${username}`;
        }
        if (url) {
            const baseFoundIndex = bases.findIndex((base) => url.startsWith(base));
            if (baseFoundIndex !== -1) {
                const baseLength = bases[baseFoundIndex].length;
                const foundUsername = sanitizeUrl(url.substring(baseLength));
                return `${prefix}${foundUsername}`;
            }
        }
        return '';
    }
    return username ?? url ?? '';
}

type NetworkType = 'facebook' | 'twitter' | 'instagram' | 'linkedin';

const networkOptions: Record<NetworkType, Pick<SocialNetwork, 'type' | 'label' | 'amountLabel' | 'icon'>> = {
    facebook: {
        type: 'facebook',
        label: 'Facebook',
        amountLabel: 'likes',
        icon: 'Facebook',
    },
    twitter: {
        type: 'twitter',
        label: 'Twitter',
        amountLabel: 'followers',
        icon: 'Twitter',
    },
    instagram: {
        type: 'instagram',
        label: 'Instagram',
        amountLabel: 'followers',
        icon: 'Instagram',
    },
    linkedin: {
        type: 'linkedin',
        label: 'LinkedIn',
        amountLabel: 'followers',
        icon: 'LinkedInSquare',
    },
};

const networkSorting: Record<NetworkType, number> = {
    facebook: 1,
    twitter: 2,
    instagram: 3,
    linkedin: 4,
};

export function getSocialNetworksHeaderFromCompanyData(data: Company): SocialNetwork[] {
    const { social_profiles } = data;
    if (!social_profiles) {
        return [];
    }
    return social_profiles
        .filter((network): network is SocialProfile & { type_id: keyof typeof networkOptions } =>
            Boolean(network?.type_id && network.type_id in networkOptions),
        )
        .sort((a, b) => networkSorting[a.type_id] - networkSorting[b.type_id])
        .map(({ type_id, link, url, username, followers }) => ({
            ...networkOptions[type_id],
            url: link ?? url,
            userHandle: getUserHandle(type_id, username, link ?? url),
            followers,
        }));
}

export function getSocialNetworkFeedsFromCompanyData(data: Company): SocialNetworkFeedsProps {
    return {
        companyName: data.company_name,
        facebookLink: data.facebook_link,
        facebookHandle: getUserHandle(
            'facebook',
            data.social_profiles?.find((profile) => profile.type_id === 'facebook')?.username,
            data.facebook_link,
        ),
        twitterLink: data.twitter_link,
        twitterHandle: getUserHandle(
            'twitter',
            data.social_profiles?.find((profile) => profile.type_id === 'twitter')?.username,
            data.twitter_link,
        ),
    };
}

const filterAndSortSocialNetworks: (
    social_media?: SocialMedia[],
    shownNetworks?: NetworkType[],
) => (SocialMedia & { type: NetworkType })[] = (social_media, shownNetworks) =>
    assertArray(social_media)
        .filter((network): network is SocialMedia & { type: NetworkType } =>
            Boolean(
                network?.type &&
                    network.type in networkOptions &&
                    (shownNetworks === undefined || shownNetworks.includes(network.type as NetworkType)),
            ),
        )
        .sort((a, b) => networkSorting[a.type] - networkSorting[b.type]);

export function getSocialNetworksHeaderFromDomainData(data: SocialNetworksData | undefined): SocialNetwork[] {
    return filterAndSortSocialNetworks(data?.social_media, ['facebook', 'twitter', 'instagram']).map(
        ({ type, url, username, followers }) => ({
            ...networkOptions[type],
            url,
            userHandle: getUserHandle(type, username, url),
            followers,
        }),
    );
}

export const useSocialNetworksHeaderFromDomainData = (data: SocialNetworksData | undefined): SocialNetwork[] =>
    useMemo(() => getSocialNetworksHeaderFromDomainData(data), [data]);

export function getSocialNetworksSidebarFromDomainData(data: SocialNetworksData | undefined): SidebarSocialNetwork[] {
    return filterAndSortSocialNetworks(data?.social_media).map(({ type, url, username, followers }) => ({
        ...networkOptions[type],
        url,
        userHandle: getUserHandle(type, username, url),
    }));
}

export const useSocialNetworksSidebarFromDomainData = (data: SocialNetworksData | undefined): SidebarSocialNetwork[] =>
    useMemo(() => getSocialNetworksSidebarFromDomainData(data), [data]);

export function getSocialNetworkFeeds(socialNetwork: SocialNetwork[]): Omit<SocialNetworkFeedsProps, 'name'> {
    if (socialNetwork.length === 0) return {};

    const twitterLink = socialNetwork.find((row) => row.type === 'twitter')?.userHandle;
    const facebookLink = socialNetwork.find((row) => row.type === 'facebook')?.url;

    return {
        twitterLink,
        facebookLink,
    };
}
