import { useMemo } from 'react';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import QueryString from 'qs';

import { UserAsyncProcess } from 'api/types/UserAsyncProcess';
import { useAxiosContext } from 'contexts/AxiosContext';

import { GetProspectExportParams, ProspectExportResponse } from '../types/';
import {
    CRMAccountSettings,
    CRMAccountSettingsLegacy,
    CRMIntegrationSettings,
    CRMObject,
    ConfirmBulkExportParams,
    ConfirmNewMassExportParams,
    CreateFullExportParams,
    CreateSingleCRMPropertyParams,
    CreateSingleCRMPropertyResponse,
    CrmName,
    DynamicsInstance,
    IntegrationMatchCountryParams,
    IntegrationOption,
    IntegrationSetting,
    Mapping,
    MatchCompanyParams,
    ProspectCountByCountryProps,
    StartCRMImportResponse,
    Technology,
    VainuDatapoint,
} from '../types/Crm';
import CrmUser from '../types/CrmUser.d';
import { mapCRMNameToTarget } from '../utils/CRMTargetText';

interface API {
    getCRMIntegrationSettings: (
        crmName: CrmName,
        signal?: AbortSignal,
    ) => Promise<AxiosResponse<CRMIntegrationSettings>>;
    // todo: remove getCRMAccountSettingsLegacy when Mappings 2.0 is done
    getCRMAccountSettingsLegacy: (
        crmName: CrmName,
        signal?: AbortSignal,
    ) => Promise<AxiosResponse<CRMAccountSettingsLegacy>>;
    getCRMAccountSettings: (crmName: CrmName) => Promise<AxiosResponse<CRMAccountSettings>>;
    createMapping: (
        integrationSettingsId: string,
        mapping: Mapping,
    ) => Promise<AxiosResponse<{ field_mapping: Mapping }>>;
    updateMapping: (integrationSettingsId: string, mapping: Mapping) => Promise<AxiosResponse<Mapping>>;
    removeMapping: (integrationSettingsId: string, mappingId: string) => Promise<AxiosResponse<Mapping>>;
    getCRMUsers: (crmName: CrmName, options?: AxiosRequestConfig) => Promise<AxiosResponse<CrmUser[]>>;
    getVainuIntegrationSettingsFields: () => Promise<AxiosResponse<VainuDatapoint[]>>;
    getActiveIntegrationSettingsOrCreateDefaults: (
        target: string,
    ) => Promise<AxiosResponse<IntegrationSetting<unknown>>>;
    getIntegrationSettingsForCRM: (
        crm: CrmName,
        signal?: AbortSignal,
    ) => Promise<AxiosResponse<IntegrationSetting<CRMObject>>>;
    saveIntegrationOptions: (reqPayload: IntegrationMatchCountryParams) => Promise<AxiosResponse<unknown>>;
    getIntegrationOptions: (
        type: 'account' | 'profile',
        target: CrmName,
        signal?: AbortSignal,
    ) => Promise<AxiosResponse<IntegrationOption>>;
    createSingleCRMProperty: (
        payload: CreateSingleCRMPropertyParams,
    ) => Promise<AxiosResponse<CreateSingleCRMPropertyResponse[]>>;
    createFullExport: (payload: CreateFullExportParams) => Promise<AxiosResponse<{ job_id: string }>>;
    connectCrm: (crm: CrmName) => Promise<AxiosResponse<{ redirect_to: 'string' }>>;
    disconnectCRM: (target: CrmName) => Promise<AxiosResponse<unknown>>;
    // initBulkExport: (data: InitBulkExportParams) => Promise<AxiosResponse<{ job_id: string }>>;
    confirmBulkExport: ({ jobId, ...data }: ConfirmBulkExportParams) => Promise<AxiosResponse<{ job_id: string }>>;
    confirmNewMassExport: (data: ConfirmNewMassExportParams) => Promise<AxiosResponse<{ job_id: string }>>;
    cancelBulkExport: (jobId: string) => Promise<AxiosResponse<UserAsyncProcess>>;
    startCRMImport: (crm: CrmName) => Promise<AxiosResponse<StartCRMImportResponse>>;
    getProspectExport: (params: GetProspectExportParams) => Promise<AxiosResponse<ProspectExportResponse>>;
    getAllNonMatchProspect: (
        queryParams: Omit<GetProspectExportParams, 'get_all' | 'prospect'>,
    ) => Promise<AxiosResponse<ProspectExportResponse>>;
    getProspectExportCount: (queryParams: GetProspectExportParams) => Promise<
        AxiosResponse<{
            count: number;
            country_counts?: ProspectCountByCountryProps[];
        }>
    >;
    matchCompany: (data: MatchCompanyParams) => Promise<AxiosResponse<void>>;
    getVainuAnalyticsData: () => Promise<AxiosResponse<unknown>>;
    getWebTechnologiesData: () => Promise<AxiosResponse<{ ALL: Technology[] }>>;
    getDynamicsInstanses: () => Promise<AxiosResponse<DynamicsInstance[]>>;
    confirmDynamicsInstance: (instance: DynamicsInstance) => Promise<AxiosResponse<unknown>>;
}

const useConnectorsApi = () => {
    const axios = useAxiosContext();

    return useMemo(() => {
        // todo: remove it when the Mappings 2.0 feature is done
        const getCRMAccountSettingsLegacy = async (crmName: CrmName, signal?: AbortSignal) => {
            return axios.get<CRMAccountSettingsLegacy>(`/mapi/${crmName}/`, {
                params: { get_all_fields: true },
                signal,
            });
        };

        const getCRMAccountSettings = async (crmName: CrmName) => {
            return axios.get<CRMAccountSettings>(`/mapi/${crmName}/`, {
                params: { get_all_fields: true, filter_legacy: true },
            });
        };

        const getCRMIntegrationSettings = async (crmName: CrmName, signal?: AbortSignal) => {
            return axios.get<CRMIntegrationSettings>(`/mapi/integration_settings_p2/${crmName}/`, { signal });
        };

        const createMapping = async (integrationSettingsId: string, mapping: Mapping) => {
            return axios.put<{ field_mapping: Mapping }>(
                `/mapi/integration_settings_p2/${integrationSettingsId}/mapping/`,
                {
                    field_mapping: mapping,
                },
            );
        };

        const updateMapping = async (integrationSettingsId: string, mapping: Mapping) => {
            return axios.put(`/mapi/integration_settings_p2/${integrationSettingsId}/mapping/${mapping.id}/`, {
                field_mapping: mapping,
            });
        };

        const removeMapping = async (integrationSettingsId: string, mappingId: string) => {
            return axios.delete(`/mapi/integration_settings_p2/${integrationSettingsId}/mapping/${mappingId}/`);
        };

        const getCRMUsers = async (crmName: CrmName, options?: AxiosRequestConfig) => {
            return axios.get<CrmUser[]>(`/mapi/crm/${crmName}/resource/users/`, options);
        };

        const getVainuIntegrationSettingsFields = async () => {
            return axios.get<VainuDatapoint[]>('/mapi/integration_settings/describe/all/', {
                params: {
                    get_all: true,
                },
            });
        };

        const getActiveIntegrationSettingsOrCreateDefaults = async (target: string) => {
            return axios.get<IntegrationSetting<unknown>>(`/mapi/in_use_integration_setting_for_crm/${target}/`);
        };

        const getIntegrationSettingsForCRM = async (crm: CrmName, signal?: AbortSignal) => {
            return axios.get<IntegrationSetting<CRMObject>>(`/mapi/in_use_integration_setting_for_crm/${crm}/`, {
                signal,
            });
        };

        const saveIntegrationOptions = async (reqPayload: IntegrationMatchCountryParams) => {
            return axios.patch('/mapi/integration_options/', reqPayload);
        };

        const getIntegrationOptions = async (type: 'account' | 'profile', target: CrmName, signal?: AbortSignal) => {
            return axios.get<IntegrationOption>(`/mapi/integration_options/${type}/${target}/`, { signal });
        };

        const createSingleCRMProperty = async ({
            crm,
            propertyName,
            propertyType,
            crmObject,
        }: CreateSingleCRMPropertyParams) => {
            return axios.post(`/mapi/crm_setup/${crm}/`, {
                actions: [
                    {
                        action: 'create_single_field_to_crm',
                        arguments: {
                            field_label: propertyName,
                            vainu_field_type: propertyType,
                            object_name: crmObject,
                        },
                    },
                ],
            });
        };

        const createFullExport = async ({ target, ...data }: CreateFullExportParams) => {
            return axios.post<{ job_id: string }>(`/mapi/crm_full_export/${target}/`, data);
        };

        const connectCrm = async (crm: CrmName) => {
            const authUrl = crm === 'salesforcesandbox' ? 'salesforce-oauth2-sandbox' : `${crm}-oauth2`;
            const baseUrl = window.location.origin;
            const redirectUrl = `${baseUrl}/connectors/connections/${crm}/overview?connected`;
            const connectionUrl = `/login/${authUrl}/json/?next=${encodeURIComponent(redirectUrl)}`;

            return axios.get(connectionUrl);
        };

        const disconnectCRM = async (target: CrmName) => {
            const authUrl = target.includes('sandbox') ? 'salesforce-oauth2-sandbox' : `${target}-oauth2`;
            return axios.post(`/disconnect/${authUrl}/`);
        };

        // const initBulkExport = (data: InitBulkExportParams) => {
        //     return axios.post<{ job_id: string }>(`/mapi/crm_bulk_export`, data);
        // };

        const confirmBulkExport = async ({ jobId, ...data }: ConfirmBulkExportParams) => {
            return axios.put<{ job_id: string }>(`/mapi/crm_bulk_export/confirm/${jobId}/`, data);
        };

        const confirmNewMassExport = async (data: ConfirmNewMassExportParams) => {
            return axios.post(`/mapi/crm_bulk_export/`, data);
        };

        const cancelBulkExport = async (jobId: string) => {
            return axios.put(`/mapi/crm_bulk_export/cancel/${jobId}/`);
        };

        const startCRMImport = async (crm: string) => {
            return axios.post(`/mapi/crm_import/${crm}/`);
        };

        const getProspectExport = async (
            params: GetProspectExportParams,
        ): Promise<AxiosResponse<ProspectExportResponse>> => {
            return axios.get(`/mapi/objects/prospect_exports/`, {
                params: {
                    target_object: 'Account',
                    ...params,
                    target: params.target ? mapCRMNameToTarget(params.target) : undefined,
                },
            });
        };

        const getAllNonMatchProspect = async (queryParams: Omit<GetProspectExportParams, 'get_all' | 'prospect'>) => {
            const params = { ...{ get_all: true, unmatched: 1, row_offset: 0, row_limit: 10 }, ...queryParams };
            return getProspectExport(params);
        };

        const getProspectExportCount = async (queryParams: GetProspectExportParams) => {
            const params = { ...{ get_all: true, count: 1, target_object: 'Account' }, ...queryParams };
            return axios.get<{
                count: number;
                country_counts?: ProspectCountByCountryProps[];
            }>(`/mapi/objects/prospect_exports/`, {
                params,
                paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
            });
        };

        const matchCompany = (data: MatchCompanyParams) => {
            return axios.post('/mapi/manual_match/', data);
        };

        const getVainuAnalyticsData = (): Promise<AxiosResponse<unknown>> => {
            return axios.get(`/mapi/analyticsdata/`);
        };

        const getWebTechnologiesData = (): Promise<AxiosResponse<{ ALL: Technology[] }>> => {
            return axios.get('https://filter.vainu.io/filtervalues/ALL_en_weighted_webtags.json');
        };

        const getDynamicsInstanses = async () => {
            return axios.get<DynamicsInstance[]>('/mapi/dynamics_url/');
        };

        const confirmDynamicsInstance = async (instance: DynamicsInstance) => {
            return axios.patch('/mapi/dynamics_url/', instance);
        };

        const supportedApis: API = {
            getCRMAccountSettingsLegacy,
            getCRMAccountSettings,
            getCRMIntegrationSettings,
            createMapping,
            updateMapping,
            removeMapping,
            getCRMUsers,
            getVainuIntegrationSettingsFields,
            getActiveIntegrationSettingsOrCreateDefaults,
            getIntegrationSettingsForCRM,
            saveIntegrationOptions,
            getIntegrationOptions,
            createSingleCRMProperty,
            createFullExport,
            connectCrm,
            disconnectCRM,
            // initBulkExport,
            confirmBulkExport,
            confirmNewMassExport,
            cancelBulkExport,
            startCRMImport,
            getProspectExport,
            getAllNonMatchProspect,
            getProspectExportCount,
            matchCompany,
            getVainuAnalyticsData,
            getWebTechnologiesData,
            getDynamicsInstanses,
            confirmDynamicsInstance,
        };

        return supportedApis;
    }, [axios]);
};

export default useConnectorsApi;
