import React from 'react';

import { Module } from 'components/beta/Platform/Header';
import { MainPath } from 'components/beta/Platform/Router/routePaths';
import Typography from 'components/tokens/Typography';

import { BannerContainer, BannerTitleWrapper } from './styled';

export interface PageBannerProps {
    module: Module | undefined;
}

const moduleToTitle: Record<Module, string> = {
    [MainPath.Profiles]: 'Company profiles',
    [MainPath.Connectors]: 'Connectors',
    [MainPath.Triggers]: 'Triggers',
    [MainPath.Settings]: 'Settings',
};

export const PageBanner: React.FC<PageBannerProps> = ({ module }) => {
    const title = module ? moduleToTitle[module] : undefined;
    return title ? (
        <BannerContainer>
            <BannerTitleWrapper className={module}>
                <Typography variant="subtitle1" weight="bold">
                    {title}
                </Typography>
            </BannerTitleWrapper>
        </BannerContainer>
    ) : null;
};

export default PageBanner;
