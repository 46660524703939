import React, { useMemo, useState } from 'react';

import { Box, Grid, Stack, Tooltip } from '@mui/material';

import { Team, InvitedUser } from 'api/types/Account';
import SortableTable, { SortableTableColumn } from 'components/templates/SortableTable';
import Button from 'components/tokens/Button';
import Chip from 'components/tokens/Chip';
import Dialog from 'components/tokens/Dialog';
import Icon from 'components/tokens/Icon';
import Switch from 'components/tokens/Switch';
import Typography from 'components/tokens/Typography';
import { formatDate } from 'utilities/date';

import { getPrivilegeLabel, isTeamUser } from './utilities';

type TeamRow = Pick<Team, 'privilege' | 'email'> & {
    user: Team | InvitedUser;
    firstName?: string;
    lastName?: string;
    lastLogin?: string;
    expiryDate?: string;
    userId: number;
};

export interface TeamTableProps {
    users: Team[];
    invitedUsers: InvitedUser[];
    userLimit: number;
    currentUser: Team;
    onChangePrivilege: (user: Team) => void;
    onDelete: (userToDelete: Team) => void;
    onRemoveInvite: (inviteUserToRemove: InvitedUser) => void;
}

export const TeamTable: React.FC<TeamTableProps> = ({
    users,
    invitedUsers,
    // userLimit,
    currentUser,
    onChangePrivilege,
    onDelete,
    onRemoveInvite,
}) => {
    const [hoveredEmail, setHoveredEmail] = useState<string | undefined>(undefined);
    const [alertOpen, setAlertOpen] = useState<string | undefined>(undefined);

    const allUsers: TeamRow[] = useMemo(() => {
        return [...users, ...invitedUsers].map((user) => ({
            email: user.email,
            privilege: user.privilege,
            firstName: isTeamUser(user) ? user.firstname : user.first_name,
            lastName: isTeamUser(user) ? user.lastname : user.last_name,
            lastLogin: isTeamUser(user) ? user.lastlogin : undefined,
            expiryDate: isTeamUser(user) ? undefined : user.expiry_date,
            userId: isTeamUser(user) ? user.user : -1,
            user,
        }));
    }, [users, invitedUsers]);

    if (currentUser.privilege !== 'admin') {
        return null;
    }

    const formatUser = (user: TeamRow) => {
        let userIcon;
        if (user.privilege === 'pending')
            userIcon = (
                <Chip
                    label="Pending"
                    icon="Mail"
                    type="square"
                    state="default"
                    size="small"
                    sx={{
                        marginLeft: '6px',
                        borderColor: '#F8CB88',
                        backgroundColor: '#FEF5E7',
                        color: '#F29711!important',
                        '& .MuiChip-icon': { color: '#F29711' },
                        '&:hover': { backgroundColor: '#FEF5E7', borderColor: '#F8CB88', outline: 'none' },
                    }}
                />
            );
        return (
            <>
                {user.email} {userIcon}
            </>
        );
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 6,
                    }}
                >
                    <Typography weight="bold">Team privileges ({allUsers.length})</Typography>
                    <Stack direction="row" spacing={1}>
                        {/* Make them available when the feature is ready to be implemented */}
                        {/* <Button variant="tertiary" size="small">
                            Microsoft sign-in
                        </Button>
                        <Button variant="tertiary" size="small" startIcon={<Icon type="UserPlus" />}>
                            Invite users
                        </Button> */}
                    </Stack>
                </Box>
            </Grid>

            <Grid item mt={4} xs>
                <SortableTable<TeamRow>
                    rowKeyField="email"
                    data={allUsers}
                    defaultSortColumn="email"
                    rowSx={{
                        height: 64,
                    }}
                    sx={{
                        borderRadius: 2,
                    }}
                    headerCellSx={{
                        fontSize: 14,
                    }}
                    headerRowSx={{
                        height: 52,
                        borderBottom: 'none',
                    }}
                    cellSx={{ fontSize: 14 }}
                >
                    <SortableTableColumn<TeamRow>
                        label="Email"
                        field="email"
                        sx={{
                            fontWeight: 500,
                            color: 'common.black',
                        }}
                    >
                        {(props) => (props.email ? formatUser(props) : '-')}
                    </SortableTableColumn>
                    <SortableTableColumn<TeamRow> width={140} label="Last login" field="lastLogin">
                        {({ lastLogin }) => (lastLogin ? formatDate(lastLogin, 'long') : '-')}
                    </SortableTableColumn>
                    <SortableTableColumn<TeamRow> width={180} label="Privileges" field="privilege">
                        {({ user, email, userId, privilege }) => {
                            const isPending = privilege === 'pending';
                            const isCurrentUser: boolean = userId === currentUser.user;

                            return (
                                <>
                                    {!isCurrentUser && (
                                        <Tooltip
                                            title={`${email} can be made an admin only after they’ve created their account.`}
                                            open={isPending && hoveredEmail === email}
                                            onOpen={() => setHoveredEmail(email)}
                                            onClose={() => setHoveredEmail(undefined)}
                                            placement="top"
                                        >
                                            {/**
                                             * By default disabled elements do not trigger user interactions so a Tooltip will not activate on
                                             * normal events like hover. To accommodate disabled elements, add a simple wrapper element, such
                                             * as a <span>. Taken from https://mui.com/material-ui/react-tooltip/#disabled-elements
                                             * */}
                                            <span>
                                                <Switch
                                                    checked={privilege === 'admin'}
                                                    onChange={() => isTeamUser(user) && onChangePrivilege(user)}
                                                    disabled={isPending}
                                                    sx={{ marginRight: 1 }}
                                                />
                                            </span>
                                        </Tooltip>
                                    )}

                                    <Chip
                                        label={isCurrentUser ? "You're an admin" : getPrivilegeLabel(privilege)}
                                        state="default"
                                        type="square"
                                        size="small"
                                    />
                                </>
                            );
                        }}
                    </SortableTableColumn>
                    <SortableTableColumn<TeamRow> width={80} sortable={false} field="user">
                        {({ user, userId, firstName, email }) => {
                            const isCurrentUser = userId === currentUser.user;
                            return (
                                <>
                                    {!isCurrentUser && (
                                        <Button
                                            size="small"
                                            variant="flat"
                                            startIcon={<Icon type="TrashFull" color="grey.500" />}
                                            onClick={() => setAlertOpen(email + firstName)}
                                        />
                                    )}
                                    <Dialog
                                        maxWidth="xs"
                                        open={alertOpen === email + firstName}
                                        onClose={() => setAlertOpen(undefined)}
                                        title={`Remove ${
                                            isTeamUser(user)
                                                ? firstName
                                                : firstName
                                                  ? `${firstName}'s  invite`
                                                  : `their invite`
                                        }?`}
                                        cancelLabel="Keep"
                                        customizedConfirm={
                                            <Button
                                                variant="critical"
                                                data-testid="test-delete-user-invite-button"
                                                onClick={() => {
                                                    isTeamUser(user) ? onDelete(user) : onRemoveInvite(user);
                                                    setAlertOpen(undefined);
                                                }}
                                            >
                                                Remove {!isTeamUser(user) ? ' invite' : ''}
                                            </Button>
                                        }
                                    >
                                        {isTeamUser(user) ? (
                                            <Typography variant="small">
                                                <Typography variant="small" weight="semibold">
                                                    {user.email}
                                                </Typography>{' '}
                                                will not be able to access their Vainu account anymore. Their Vainu
                                                lists and triggers will also be removed.
                                            </Typography>
                                        ) : (
                                            <Typography variant="small">
                                                <Typography variant="small" weight="semibold">
                                                    {user.email}
                                                </Typography>
                                                ’s invite that is expiring on {formatDate(user.expiry_date, 'long')}{' '}
                                                will no longer work. They won’t be able to create a Vainu account unless
                                                you send them a new invite.
                                            </Typography>
                                        )}
                                    </Dialog>
                                </>
                            );
                        }}
                    </SortableTableColumn>
                </SortableTable>
            </Grid>
        </Grid>
    );
};

export default TeamTable;
