import React, { useCallback } from 'react';

import { Grid, Typography, Paper, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { get } from 'lodash';

import StyledTooltip from '../common/StyledTooltip';
import { ProspectCountByCountryProps } from '../types/Crm';
import { localeNumber } from '../utils';

type MatchCategory =
    | 'Matched with Vainu'
    | 'Added and matched'
    | 'Need help'
    | 'Missing from database'
    | 'Need an upgrade'
    | 'Not supported';

const cardToColorThemePath: Record<MatchCategory, string> = {
    'Matched with Vainu': 'success.secondary',
    'Added and matched': 'checkbox.checked',
    'Need help': 'error.main',
    'Missing from database': 'primary.main',
    'Need an upgrade': 'secondary.main',
    'Not supported': 'primary.main',
};

interface MatchingCardProps {
    titleNumber: number;
    focusedComponent: string;
    component: MatchCategory;
    numberByCountry: ProspectCountByCountryProps[];
    titleIcon?: React.ReactNode;
    description: React.ReactNode;
    footerComponent?: React.ReactNode;
    setFocusedComponent: (component: string) => void;
}

const MatchingCard: React.FC<MatchingCardProps> = ({
    titleNumber,
    focusedComponent,
    component,
    numberByCountry = [],
    titleIcon,
    description,
    footerComponent,
    setFocusedComponent,
}) => {
    const { palette } = useTheme();

    const handleMouseEnter = useCallback(() => {
        setFocusedComponent(component);
    }, [component, setFocusedComponent]);

    const handleMouseLeave = useCallback(() => {
        setFocusedComponent('');
    }, [setFocusedComponent]);

    return (
        <Grid item xs={6}>
            <StyledPaper
                data-testid={`match-summary-${component}`}
                elevation={focusedComponent === component ? 5 : 0}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Header>
                    <Title>{localeNumber(titleNumber)}</Title>
                    {titleIcon}
                </Header>
                <Subtitle sx={{ color: get(palette, cardToColorThemePath[component]) }}>{component}</Subtitle>
                <Description gutterBottom>{description}</Description>
                {!!numberByCountry.length && (
                    <Grid container sx={{ marginTop: '8px' }}>
                        {numberByCountry.map(({ _id: country, count }) => {
                            return (
                                <CountryItem key={country}>
                                    <StyledTooltip arrow title={country} placement="top">
                                        <img
                                            alt={country}
                                            src={`https://static.vainu.io/shared/flags/${country?.toLowerCase()}.png`}
                                        />
                                    </StyledTooltip>
                                    {localeNumber(count)}
                                </CountryItem>
                            );
                        })}
                    </Grid>
                )}
                <Footer>{footerComponent}</Footer>
            </StyledPaper>
        </Grid>
    );
};

const StyledPaper = styled(Paper)(({ theme: { palette } }) => ({
    position: 'relative',
    minHeight: 255,
    padding: '16px 24px',
    border: `1px solid ${palette.border}`,
    borderRadius: 16,
}));

const Title = styled(Typography)(({ theme: { palette, typography } }) => ({
    fontFamily: 'Aeroport',
    fontWeight: typography.fontWeightMedium,
    fontSize: 32,
    marginBottom: '8px',
    color: palette.primary.main,
}));

const Subtitle = styled(Typography)(({ theme: { typography } }) => ({
    fontFamily: 'Roboto',
    fontWeight: typography.fontWeightMedium,
    fontSize: 14,
    marginBottom: '8px',
}));

const Description = styled(Typography)(({ theme: { palette } }) => ({
    fontFamily: 'Roboto',
    fontSize: 12,
    color: palette.text.subtle,
}));

const CountryItem = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '25px',
    marginRight: 5,
    padding: '0 10px',
    color: palette.text.subtle,
    background: '#F9F9F9',
    borderRadius: 8,
    fontWeight: 'bold',
    fontSize: 12,

    '& > img': {
        marginRight: '10px',
    },
}));

const Footer = styled('div')({
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '16px 24px',
});

const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export { MatchingCard };
