import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { isEmpty } from 'lodash';

import { FilterOperator } from 'api/types/FilterOperators';
import { FilterTag } from 'api/types/Tag';
import { NestedSelect, OptionProps } from 'components/tokens/select-components/NestedSelect';
import { Typography } from 'components/tokens/Typography';
import { useListsContext } from 'contexts/ListsContext';
import { Permission } from 'contexts/PermissionContext';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { ActionType, useTrigger, EventKeywords } from 'contexts/TriggerContext';
import { getMainCategories } from 'utilities/triggers/categories';

import TriggerFilterChip from './TriggerFilterChip';
import TriggerFilterKeywords from './TriggerFilterKeywords';

const getChipLabel = (event: EventKeywords[], name: string) => {
    const includes = event?.filter((row) => row.operator !== FilterOperator.NOT).flatMap((row) => row.values) || [];
    const excludes = event?.filter((row) => row.operator === FilterOperator.NOT).flatMap((row) => row.values) || [];

    return (
        <>
            {name}:{' '}
            {!includes.length && !excludes.length ? (
                <Typography variant="inherit" component="span" color="subtle">
                    Any
                </Typography>
            ) : (
                <Box sx={{ color: includes.length !== 0 ? '#3CB095' : 'error.main', display: 'inline' }}>
                    {event.flatMap((row) => row.values).length === 1
                        ? includes[0] || excludes[0]
                        : `${includes.length + excludes.length} keywords`}
                </Box>
            )}
        </>
    );
};

export const getTagName = (allTags: FilterTag[], tag: string) => {
    if (tag === 'any') {
        return 'Any event';
    }
    return allTags.find((i) => i[0].toString() === tag)?.[1] || '';
};

type Props = {
    sourceName?: string;
};

const TriggerFilters = ({ sourceName }: Props) => {
    const { database } = useListsContext();
    const permissionContext = useContext(PermissionContext);

    const [state, dispatch, { allTags, activePopup, setActivePopup }] = useTrigger();

    const [selectedTag, setSelectedTag] = useState<string>();

    const { events, trackChanges = [], trackingLogic } = state;
    const trackNewCompaniesInList = trackChanges.includes('new_company');

    const chipButtons = [];

    const eventTags = Object.keys(events);

    // add "any" chip manually if dialog is open and it has no keywords
    // as it won't be saved in the query
    if (selectedTag === 'any' && !events.any) {
        eventTags.push('any');
    }

    eventTags.forEach((tag) => {
        const event = events[tag];
        chipButtons.push(
            <TriggerFilterChip
                tag={tag}
                label={getChipLabel(event, getTagName(allTags, tag))}
                onClick={() => {
                    setSelectedTag(tag);
                    setActivePopup(`select-events-popper`);
                }}
                onDelete={() => {
                    dispatch({
                        type: ActionType.REMOVE_EVENT_FILTER,
                        tag,
                        name: '',
                        convertEvents: true,
                    });
                }}
            />,
        );
    });

    if (trackNewCompaniesInList) {
        chipButtons.push(
            <TriggerFilterChip
                label="Tracking new companies"
                type="new_company"
                onDelete={
                    database !== 'DOMAIN_DATA_BASIC'
                        ? () => {
                              dispatch({
                                  type: ActionType.REPLACE_TRIGGER_FILTERS,
                                  events,
                                  trackChanges: trackChanges.filter((change) => change !== 'new_company'),
                                  trackingLogic,
                              });
                          }
                        : undefined
                }
            />,
        );
    }

    if (!isEmpty(trackingLogic)) {
        chipButtons.push(<TriggerFilterChip label="Tracking data changes" />);
    }

    const hasPermission = useCallback(
        (itemPermissions: string[] | undefined | null) => {
            if (itemPermissions?.length) {
                return itemPermissions.some((itemPermission) =>
                    permissionContext.productPermissions.includes(itemPermission),
                );
            }
            return true;
        },
        [permissionContext.productPermissions],
    );

    const mainCategories: OptionProps[] = useMemo(() => {
        const countryFilters = permissionContext.countryPermissions;
        if (allTags) {
            return [
                {
                    value: 'new_company',
                    label: 'New company in a saved list',
                    alias: sourceName ? (
                        <span>
                            New company in{' '}
                            <b>{sourceName.length > 24 ? `${sourceName.slice(0, 20)}...` : sourceName}</b>
                        </span>
                    ) : undefined,
                    showSeparator: true,
                },
                ...getMainCategories(allTags, countryFilters, hasPermission([Permission.IncludeLegacyTriggers]))
                    .filter((item) => !item.disable && hasPermission(item?.permissions))
                    .map((item) => ({
                        value: item.value,
                        label: item.title,
                        options: item.children.map((subitem) => ({
                            value: subitem.value,
                            label: subitem.title,
                        })),
                    })),
            ];
        }
        return [];
    }, [permissionContext.countryPermissions, allTags, hasPermission, sourceName]);

    const onCloseKeywords = useCallback(() => {
        setSelectedTag(undefined);
        setActivePopup(undefined);
    }, [setActivePopup]);

    const width = chipButtons.length ? 30 : 100;

    return (
        <Box sx={{ display: 'flex' }}>
            <TriggerFilterKeywords
                tag={activePopup === `select-events-popper` ? selectedTag : undefined}
                onClose={onCloseKeywords}
            />

            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }} id="trigger-filters">
                {chipButtons.map((chip, idx) => (
                    <React.Fragment key={idx}>
                        {chip}
                        {idx !== chipButtons.length - 1 && (
                            <Typography variant="body2" color="subtle">
                                or
                            </Typography>
                        )}
                    </React.Fragment>
                ))}

                {database !== 'DOMAIN_DATA_BASIC' && (
                    <>
                        {chipButtons.length !== 0 && (
                            <Box
                                sx={{
                                    marginX: 1,
                                    alignSelf: 'stretch',
                                    borderLeft: '1px solid',
                                    borderColor: 'border',
                                }}
                            />
                        )}
                        <NestedSelect
                            forgetSelected
                            placeholder={chipButtons.length ? '+' : '+ Add event'}
                            width={width}
                            height={30}
                            nestedOptions={mainCategories}
                            openInitialDefaultSelectionOnMenuOpen={activePopup === `select-events-popper`}
                            slimInactiveBorder
                            onChange={(option) => {
                                const newEvents = { ...events };
                                const newTrackChanges: string[] = [...trackChanges];

                                if (option.value === 'new_company') {
                                    if (!trackChanges.includes('new_company')) {
                                        newTrackChanges.push(option.value);
                                    }
                                } else {
                                    newEvents[option.value] = newEvents[option.value] || [];

                                    setSelectedTag(option.value);
                                    setActivePopup(`select-events-popper`);
                                }

                                dispatch({
                                    type: ActionType.REPLACE_TRIGGER_FILTERS,
                                    events: newEvents,
                                    trackChanges: newTrackChanges,
                                    trackingLogic,
                                });
                            }}
                            selectSx={{
                                '.MuiSelect-icon': {
                                    display: 'none',
                                },
                                '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
                                    display: 'flex',
                                    padding: '0px',
                                    paddingRight: 0,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width,
                                    height: 30,
                                    fontWeight: 500,
                                    fontSize: 13,
                                    color: 'text.strong',
                                },
                                borderRadius: 30,
                            }}
                            inputProps={{
                                sx: {
                                    width,
                                    height: 30,
                                },
                            }}
                            aria-label="Add event"
                        />
                    </>
                )}
            </Box>
        </Box>
    );
};

export default TriggerFilters;
