import { Box } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MainPath, SettingsPath } from 'components/beta/Platform/Router/routePaths';
import APIKeys from 'components/features/settings/APIKeys';
import SettingsMenu from 'components/features/settings/SettingsMenu';
import Usage from 'components/features/settings/Usage';
import ContentView from 'components/layout/ContentView';
import NotFound from 'components/templates/NotFound';
import { Permission, usePermissionContext } from 'contexts/PermissionContext';

import ProfileAndTeam from './ProfileAndTeam';

export const Settings: React.FC = () => {
    const { profile, hasProductPermission } = usePermissionContext();
    const hasApiPermission = hasProductPermission(Permission.CanUseApi);

    return (
        <ContentView
            left={<SettingsMenu isAdmin={profile.accountadmin} hasApiPermission={hasApiPermission} />}
            right={
                <Box sx={{ paddingBottom: '40px' }}>
                    <Routes>
                        <Route index element={<Navigate to={`/${MainPath.Settings}/${SettingsPath.Team}`} replace />} />
                        <Route path={SettingsPath.Team} element={<ProfileAndTeam />} />
                        <Route path={SettingsPath.Usage} element={<Usage />} />
                        {hasApiPermission && <Route path={SettingsPath.APIKeys} element={<APIKeys />} />}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Box>
            }
        />
    );
};

export default Settings;
