import type { TypographyOptions } from '@mui/material/styles/createTypography';

import { palette } from './palette';
import params from './params';

export type ExtraTypographyVariant = 'small' | 'tiny' | 'number';
export const extraTypographyVariants: ExtraTypographyVariant[] = ['small', 'tiny', 'number'];

interface AugmentedVariants {
    buttonMedium: React.CSSProperties;
    small: React.CSSProperties;
    tiny: React.CSSProperties;
    mini: React.CSSProperties;
    number: React.CSSProperties;
    chartXAxisLabel: React.SVGProps<SVGTextElement>;
    chartYAxisLabel: React.SVGProps<SVGTextElement>;
    chartValueLabel: React.CSSProperties;
}

declare module '@mui/material/styles' {
    interface TypographyVariants extends AugmentedVariants {}
    interface TypographyVariantsOptions extends AugmentedVariants {}
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        buttonMedium: true;
        small: true;
        tiny: true;
        mini: true;
        number: true;
    }
}

const typography: TypographyOptions = {
    fontSize: 14,
    fontFamily: 'Roboto, sans-serif',
    h1: {
        fontSize: 48,
        fontWeight: 900,
        letterSpacing: '-0.02em',
        color: palette.text.strong,
    },
    h2: {
        fontSize: 40,
        fontWeight: 900,
        letterSpacing: '-0.02em',
    },
    h3: {
        fontSize: 32,
        fontWeight: 700,
        letterSpacing: '-0.02em',
    },
    h4: {
        fontSize: 24,
        fontWeight: 700,
        letterSpacing: '-0.02em',
        lineHeight: 1.4,
    },
    h5: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1.4,
    },
    subtitle1: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 1.4,
    },
    body1: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1.4,
    },
    body2: {
        fontSize: 14,
        fontWeight: 400,
    },
    button: {
        fontSize: 16,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.03m',
    },
    buttonMedium: {
        fontSize: 14,
        fontWeight: 500,
        textTransform: 'none',
        letterSpacing: '0.02em',
    },
    small: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 1.4,
    },
    tiny: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 1.4,
    },
    mini: {
        fontSize: 11,
        lineHeight: 1.3,
    },
    number: {
        fontFamily: params.brandFontFamily,
        fontSize: 32,
        fontWeight: 500,
        lineHeight: 1.1,
        color: palette.text.strong,
    },
    chartXAxisLabel: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: 12,
        fill: palette.chart.axisLabel,
    },
    chartYAxisLabel: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: 11,
        fill: palette.chart.axisLabel,
    },
    chartValueLabel: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 1.1,
        color: palette.chart.label,
    },
};

export default typography;
