import React, { useState } from 'react';

import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';
import NestedSelect from 'components/tokens/select-components/NestedSelect';
import { Permission } from 'contexts/PermissionContext';
import { getFirstKey } from 'utilities/objectUtils';

import { mergeKeys } from '../../common/utils';
import { getFiltersFromGroup, getSameFiltersState } from '../../filters/utils';
import {
    FilterGroup,
    FilterGroupProps,
    FilterID,
    GroupOption,
    GroupValues,
    NodeValue,
    RawGroup,
} from '../../FilterTypes';
import { getGroupFilters, useFilterGroupOptions } from '../common/groupUtils';
import { useGroupActions, useGroupNodes } from '../common/useGroup';
import FilterGroupTemplate from '../FilterGroupTemplate';

const options: GroupOption[] = [
    {
        value: FilterID.change_in_revenue,
        label: 'Change in revenue (%)',
    },
    /*{
                value: FilterID.gross_profit,
                label: 'Gross profit (%)',
            },*/ {
        value: FilterID.net_income,
        label: 'Net income (earnings)',
    },
    {
        value: FilterID.salary_costs,
        label: 'Salary costs',
        databases: ['FI', 'NO', 'DK'],
    },
    {
        value: FilterID.accounting_period_end,
        label: 'Accounting period end date',
    },
    /*{
                value: FilterID.auditor,
                label: 'Auditor',
            },*/
    {
        value: FilterID.accounts_receivable_local,
        label: 'Accounts receivable (local)',
    },
    {
        value: FilterID.cash_and_cash_equivalents_local,
        label: 'Cash and cash equivalents (local)',
    },
    {
        value: FilterID.financial_statement_keywords,
        label: 'Financial statement keywords',
        permission: Permission.FinancialPdfViewAndKeywordFilter,
    },
    {
        value: FilterID.total_equity_local,
        label: 'Total equity (local)',
    },
    {
        value: FilterID.long_term_liabilities_local,
        label: 'Long term liabilities (local)',
    },
    {
        value: FilterID.bookkeeper,
        label: 'Bookkeeper',
        databases: ['NO'],
    },
    {
        value: FilterID.inventory_local,
        label: 'Inventory (local)',
    },
    {
        value: FilterID.ebit_margin,
        label: 'EBIT margin',
    },
    {
        value: FilterID.equity_ratio,
        label: 'Equity ratio',
    },
    {
        value: FilterID.quick_ratio,
        label: 'Quick ratio',
    },
];

const config: FilterGroup = {
    group: GroupValues.financial,
    label: 'Financial',
    options,
    filterUUIDs: [],
    logic: {},
    render: (props: FilterGroupProps) => <FinancialGroup {...props} />,
    getFilters: getGroupFilters,
};

export const financialGroupDefinition: Record<GroupValues.financial, FilterGroup> = {
    [GroupValues.financial]: config,
};

const createNewGroup = (value: NodeValue) =>
    ({
        [FilterOperator.MATCH]: {
            [GroupValues.financial]: { [FilterOperator.ALL]: [value] },
        },
    }) as NodeValue;

export const FinancialGroup: React.FC<FilterGroupProps> = ({
    undoChanges,
    applyChanges,
    clearFilters,
    removeGroup,
    ids = [],
    addNewGroup,
    updateGroup,
    updateGroups,
    disabled = false,
}) => {
    const { getValues } = useFormContext();

    const filterGroups = useGroupNodes(ids);

    const { activeFilters, filterToHide, filteredOptions } = useFilterGroupOptions({
        options,
        filterGroups,
    });

    const { handleAddFilter, handleFilterRemove, keys } = useGroupActions({
        applyChanges,
        removeGroup,
        addNewGroup,
        updateGroup,
        updateGroups,
        createNewGroup,
        filterGroups,
        options: filteredOptions,
    });

    const renderFilters = () => {
        return ids.map((id) =>
            filterGroups[id].filters.map((filter, index) => {
                const filterDefinition = filter.definition;
                const sameFiltersState = getSameFiltersState(activeFilters, filter);

                return filterDefinition
                    ? filterDefinition.render({
                          key: keys[mergeKeys(id, filter.id)],
                          uuid: filterDefinition.id,
                          filter,
                          groupUUID: config.group,
                          removeFilter: () => handleFilterRemove(id, index),
                          sameFiltersState: sameFiltersState,
                      })
                    : null;
            }),
        );
    };

    const handleApplyChanges = () => {
        ids.forEach((id) => {
            const value = getValues(id);
            const result = getFiltersFromGroup({ value, path: id } as RawGroup);

            result.forEach((r, index) => {
                const nextOperator = getFirstKey(r.value);
                const fieldValue = r.value[nextOperator as keyof NodeValue];

                if (isEmpty(fieldValue)) {
                    handleFilterRemove(id, index);
                } else if (fieldValue[0] == null && fieldValue[1] == null) {
                    handleFilterRemove(id, index);
                }
            });
        });

        applyChanges();
    };

    const [addRow, setAddRow] = useState(false);
    const [isFilterAdded, setIsFilterAdded] = useState(false);

    const addRowSelect = (
        <NestedSelect
            placeholder="Select"
            width="100%"
            height={40}
            containerSx={{ gridArea: 'add' }}
            nestedOptions={filteredOptions || []}
            slimInactiveBorder
            onChange={(option) => {
                if (Array.isArray(option)) {
                    throw new Error('should not be array value');
                }
                if (typeof option === 'number') {
                    throw new Error('should not be number value');
                }
                if (typeof option === 'string') {
                    throw new Error('should not be string value');
                }
                if (!option) {
                    return;
                }
                handleAddFilter(option.value as FilterID);
                setAddRow(false);
                setIsFilterAdded(true);
            }}
        />
    );
    const addRowButton = (
        <Button
            variant="tertiary"
            size="small"
            onClick={() => setAddRow(true)}
            startIcon={<Icon type="Plus" sx={{ color: 'primary' }} />}
            sx={{ width: 'min-content', gridArea: 'add' }}
        >
            AND
        </Button>
    );

    const renderedFilters = renderFilters();
    const props = {
        label: config.label,
        filters: renderedFilters,
        addFilter: handleAddFilter,
        undoChanges,
        applyChanges: handleApplyChanges,
        clearFilters,
        addRowSelect,
        addRowButton,
        addRow,
        isFilterAdded,
        setIsFilterAdded,
        filterToHide,
        disabled,
    };
    return <FilterGroupTemplate {...props} />;
};
