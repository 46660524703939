import React, { forwardRef } from 'react';

import { styled } from '@mui/material';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

export type SwitchProps = Omit<MuiSwitchProps, 'icon'>;

export const Switch: React.FC<SwitchProps> = forwardRef(({ ...props }, ref) => (
    <StyledMuiSwitch ref={ref} {...props} />
));

export default Switch;

const StyledMuiSwitch = styled(MuiSwitch)(({ theme: { palette } }) => ({
    width: 45,
    height: 28,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: palette.common.white,
            '& + .MuiSwitch-track': {
                backgroundColor: palette.success.main,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: palette.success.main,
            border: `6px solid${palette.common.white}`,
        },
        '&.Mui-disabled': {
            opacity: 1,
            color: `${palette.common.white}!important`,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 24,
        height: 24,
    },
    '& .MuiSwitch-track': {
        borderRadius: 50,
        backgroundColor: palette.field.hover,
        opacity: 1,
    },
}));
