import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckBig = (props) => (
    <SvgIcon {...props}>
        <path d="M20.8389 6.69459L8.81811 18.7154L3.16125 13.0586L4.57125 11.6486L8.81811 15.8854L19.4289 5.28459L20.8389 6.69459Z" />
    </SvgIcon>
);

export default CheckBig;
