import { Box, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import FlushStorage from 'components/devhelpers/FlushStorage';
import Button from 'components/tokens/Button';
import Icon from 'components/tokens/Icon';

import { MainPath } from '../Router/routePaths';
import NavButton from './NavButton';

const Navigation: React.FC = () => {
    const { pathname } = useLocation();

    const isActive = (path: string) => pathname.substring(1).startsWith(path);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                padding: 1.5,
                '.MuiButton-root': { marginBottom: 1.5 },
            }}
        >
            <Link to="/" data-testid="e2e-nav-link-Homepage">
                <Tooltip title="Homepage" placement="right">
                    <Button variant="flat" startIcon={<Icon type="Vainu" sx={{ '&&': { fontSize: 24 } }} />} />
                </Tooltip>
            </Link>
            <NavButton label="Profiles" icon="Profiles" path={MainPath.Profiles} active={isActive(MainPath.Profiles)} />
            <NavButton label="Triggers" icon="Triggers" path={MainPath.Triggers} active={isActive(MainPath.Triggers)} />
            <NavButton
                label="Connectors"
                icon="Connectors"
                path={MainPath.Connectors}
                active={isActive(MainPath.Connectors)}
            />
            <NavButton label="Settings" icon="Slider02" path={MainPath.Settings} active={isActive(MainPath.Settings)} />

            {process.env.NODE_ENV === 'development' && <FlushStorage />}
        </Box>
    );
};

export default Navigation;
