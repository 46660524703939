import { ProfileType, domainProfileType } from 'api/types/Organization';

import { renderMultiplier } from '../contexts/ZoomAndCenterContext';
import { BoundsRect, Cluster, ClusterCompany, ClusterMap, DomainClusteringData } from '../types';
import { ClusterColors, getClusterColor } from './color';
import { makeConvexHull } from './convexHull';
import { getBoundsRect, getCentroid, getMaxX, getMaxY, getMinX, getMinY, getRightEdgeCenter } from './geometry';

const maxClusterRenderSize = 500;

/**
 * Crop out companies randomly from a cluster to meet the max size, to prevent performance
 * problems with potentially huge clusters.
 */
const limitCompanies = (companies: ClusterCompany[], maxSize: number) => {
    const clusterSize = companies.length;
    if (clusterSize <= maxSize) {
        return companies;
    }
    const keptCompanies = [...companies];
    while (keptCompanies.length > maxSize) {
        const itemToRemove = Math.floor(Math.random() * clusterSize);
        keptCompanies.splice(itemToRemove, 1);
    }
    return keptCompanies;
};

/**
 * Takes the raw data from the clustering service and injects all the data needed to
 * use the company profile components and pre-calculates the geometry data
 * for rendering and sets the cluster colors.
 */
export const makeClusters = (
    data: DomainClusteringData | undefined,
    type: ProfileType,
    colors: ClusterColors,
): { bounds: Readonly<BoundsRect> | undefined; clusters: Readonly<ClusterMap> | undefined } => {
    if (type !== domainProfileType) {
        throw new Error('Organization clustering not supported yet.');
    }

    if (!data) {
        return { clusters: undefined, bounds: undefined };
    }

    const sortedData = data.clusters.sort((a, b) => b.domains.length - a.domains.length);

    const clusterData = sortedData.map<Cluster>((cluster, index) => ({
        ...cluster,
        // FIXME: This is just to make the current placeholder name not ugly and should be just
        // removed when the clustering service starts returning a proper pretty name for clusters.
        // FIXME: (2) Now accepts both stringified Python object and a plain string,
        // while the clustering service is changed to return a plain string name.
        // After all the old clustering results with Python object names are deleted,
        // the Python object parsing can be removed.
        // DUE DATE: 2024-09-01. Ask then if the S3 bucket domain_clustering can be cleaned up
        // from results prior to 2024-03-15.
        // Note: In the Python object array a string that contains single quotes is contained in double quotes.
        name: cluster.name.startsWith('[')
            ? cluster.name.indexOf('"') === 2
                ? cluster.name.split('"')[1]
                : cluster.name.split("'")[1]
            : cluster.name,
        id: index,
        color: getClusterColor(index, colors),
        distinctColor: index <= 1,
        companies: cluster.domains.map((domain) => ({
            ...domain,
            x: domain.x * renderMultiplier,
            y: domain.y * renderMultiplier,
            cluster_id: index,
            id: domain.domain,
            profileType: type,
            name: domain.domain,
            website: domain.domain,
            clusterId: index,
        })),
        // These are just type-correct initial values; the actual values are calculated below.
        companiesSample: [],
        bounds: [],
        boundsRect: { left: 0, top: 0, right: 0, bottom: 0 },
        center: { x: 0, y: 0 },
        centroid: { x: 0, y: 0 },
        rightAnchor: { x: 0, y: 0 },
    }));

    // Calculate the ultimate bounds of all cluster data
    const minX = clusterData.reduce(
        (acc, curr) => Math.min(acc, getMinX(curr.companies)),
        clusterData[0].companies[0].x,
    );
    const minY = clusterData.reduce(
        (acc, curr) => Math.min(acc, getMinY(curr.companies)),
        clusterData[0].companies[0].y,
    );
    const maxX = clusterData.reduce(
        (acc, curr) => Math.max(acc, getMaxX(curr.companies)),
        clusterData[0].companies[0].x,
    );
    const maxY = clusterData.reduce(
        (acc, curr) => Math.max(acc, getMaxY(curr.companies)),
        clusterData[0].companies[0].y,
    );

    const bounds: BoundsRect = {
        left: minX,
        top: minY,
        right: maxX,
        bottom: maxY,
    };

    // Calculate bounds for each cluster, both the boundary path and the bounds rect, and its weighted centre (centroid).
    // Add another array of companies in a cluster that is limited to the maximum renderable size in the graph.
    clusterData.forEach((cluster) => {
        cluster.bounds = makeConvexHull(cluster.companies);
        cluster.boundsRect = getBoundsRect(cluster.bounds);
        cluster.center = {
            x: (cluster.boundsRect.left + cluster.boundsRect.right) / 2,
            y: (cluster.boundsRect.top + cluster.boundsRect.bottom) / 2,
        };
        cluster.centroid = getCentroid(cluster.bounds);
        cluster.companiesSample = limitCompanies(cluster.companies, maxClusterRenderSize);
        cluster.rightAnchor = getRightEdgeCenter(cluster.bounds);
    });

    const clusters = new Map<number, Cluster>(clusterData.map((cluster) => [cluster.id, cluster]));

    return { bounds, clusters };
};
