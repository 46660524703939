import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExportHubSpot: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 23.953C10.8908 15.8157 18.1667 14.3449 18.1667 14.3449V11.6001L24 17.0659L18.1667 22.5802V19.8354C18.1667 19.8354 13.025 19.6946 9 23.953Z"
            fill="#0A0A0A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.8206 6.56089V8.80485C18.8535 9.11348 20.4798 10.6544 20.8973 12.6678C20.9081 12.7196 20.9179 12.7715 20.927 12.8234L17.1667 9.30005V11.4382C16.837 11.2615 16.463 11.1558 16.0648 11.1418C15.7279 11.1328 15.3927 11.1913 15.0788 11.3139C14.7649 11.4366 14.4788 11.6209 14.2373 11.856C13.9958 12.091 13.8039 12.3721 13.6728 12.6826C13.5418 12.993 13.4743 13.3266 13.4743 13.6636C13.4743 14.0006 13.5418 14.3342 13.6728 14.6447C13.746 14.818 13.8382 14.9822 13.9473 15.1344C13.8764 15.1803 13.8052 15.2273 13.7337 15.2755C11.984 16.4544 10.0808 18.3162 8.85558 21.1806C8.42279 20.8946 8.13723 20.4038 8.13709 19.8462C8.13686 18.9635 8.85199 18.2476 9.73474 18.2468C9.89118 18.249 10.0463 18.2743 10.1954 18.3219L12.0386 16.4781C10.8792 14.8258 10.8494 12.6322 11.9636 10.949L5.90325 6.23318C5.61197 6.4025 5.28137 6.49245 4.94445 6.49404C4.55932 6.49354 4.18299 6.37884 3.86307 6.16443C3.54314 5.95002 3.29399 5.64555 3.14712 5.28952C3.00026 4.9335 2.96228 4.54191 3.038 4.1643C3.11371 3.78668 3.29972 3.44001 3.57249 3.16812C3.84525 2.89624 4.19253 2.71136 4.57038 2.63686C4.94824 2.56237 5.3397 2.60162 5.69525 2.74964C6.05079 2.89766 6.35446 3.1478 6.56782 3.46842C6.78119 3.78904 6.89467 4.16574 6.89392 4.55087C6.89134 4.71439 6.86844 4.87729 6.82456 5.03571L12.9789 9.82937C13.6507 9.28886 14.4534 8.93545 15.3057 8.80493V6.56089C15.0092 6.42229 14.7581 6.20232 14.5817 5.92657C14.4053 5.65082 14.3108 5.33065 14.3093 5.00331V4.95183C14.3093 3.99743 15.083 3.22374 16.0374 3.22374H16.089C17.0433 3.22374 17.817 3.99743 17.817 4.95183V5.00331C17.8155 5.33065 17.721 5.65082 17.5446 5.92657C17.3682 6.20232 17.1172 6.42229 16.8206 6.56089Z"
            fill="#FF7A59"
        />
    </SvgIcon>
);

export default ExportHubSpot;
