import { parseISO, differenceInDays } from 'date-fns';
import { IntlShape } from 'react-intl';

import { TechnologyData } from 'api/types/Organization';
import { IconType } from 'components/tokens/Icon';

import { getPriorityTechnologies, categoryPriorities } from './priorityTechnologies';

export function getWebsiteLanguages(technology_data: TechnologyData[]): string[] {
    return technology_data
        .filter((technology): technology is Required<typeof technology> =>
            Boolean(technology.name && technology.types?.includes('language')),
        )
        .map((technology) => technology.name);
}

export function formatLanguages(languages: string[], intl: IntlShape): string {
    return languages
        .map((language) => intl.formatDisplayName(language, { type: 'language' }))
        .sort()
        .join(', ');
}

export function formatCountries(countries: string[], intl: IntlShape): string {
    return countries
        .map((country) => intl.formatDisplayName(country, { type: 'region' }))
        .sort()
        .join(', ');
}

export function formatTechnologiesUpdateDate(date?: string): { updatedString: string; updatedColor: string } {
    if (!date) {
        return { updatedString: 'n/a', updatedColor: 'text.subtle' };
    }
    const now = new Date();
    const updatedDate = parseISO(date);
    const defaultDate = { updatedString: 'n/a', updatedColor: 'text.subtle' };
    // Note: isNan(updateDate) does the same, but has implicit Date-to-number conversion.
    // For clarity, an explicit conversion to number and strict NaN testing from Number is used.
    if (Number.isNaN(updatedDate.getTime())) {
        return defaultDate;
    }
    const dayDiff = differenceInDays(now, updatedDate);
    if (dayDiff < 0) {
        return defaultDate;
    }
    if (dayDiff <= 1) {
        return { updatedString: 'Today', updatedColor: 'success.main' };
    }
    if (dayDiff <= 2) {
        return { updatedString: 'Yesterday', updatedColor: 'success.main' };
    }
    if (dayDiff <= 7) {
        return { updatedString: 'This week', updatedColor: 'success.main' };
    }
    if (dayDiff <= 30) {
        return { updatedString: 'This month', updatedColor: 'success.main' };
    }
    return { updatedString: 'Over a month ago', updatedColor: 'warning.main' };
}

export function formatLink(website: string) {
    if (website.startsWith('http://') || website?.startsWith('https://')) {
        return website;
    }
    return `https://${website}`;
}

export function formatWebsiteLabel(website: string) {
    if (website.startsWith('http://')) {
        return website.substring(7);
    }
    if (website?.startsWith('https://')) {
        return website.substring(8);
    }
    return website;
}

const technologyTypeLabels: Record<string, string> = {
    advertising: 'Advertising',
    analytics: 'Analytics',
    ats: 'Applicant tracking (ATS)',
    chat: 'Chat',
    cms: 'Content management (CMS)',
    crm: 'CRM',
    communication: 'Communication',
    general: 'General',
    language: 'Language',
    localisation: 'Localisation',
    mailserver: 'Mail server',
    marketing: 'Marketing',
    nameserver: 'Name server',
    other: 'Other',
    payment: 'Payment',
    programming: 'Programming',
    reservation: 'Reservation',
    server: 'Server',
    some: 'Social media',
};

export function formatTechnologyTypes(types: string[]): string {
    return types
        .map((type) => technologyTypeLabels[type] ?? type /*type.charAt(0).toUpperCase() + type.substring(1)*/)
        .join(', ');
}

export function selectHighlightedTechnologies(
    technology_data: TechnologyData[],
    maxHighlights: number,
): TechnologyData[] {
    if (!Array.isArray(technology_data)) {
        return [];
    }

    const foundTechnologies: TechnologyData[] = [];
    // A deep copy of the priority technologies list
    const priorityTechnologies = getPriorityTechnologies();

    let currentCategory = 0;

    // This finds the most prioritized technologies for highlighting.
    // The categories are in the priority order, as well as the technologies inside categories.
    //
    // It picks a category, then tries all the technologies in that category in order
    // and finds the first one that matches. It then drops all the preceding technologies that
    // weren't found and the one that was found from the category. The remaining technologies
    // are then ones that weren't yet searched for. If no technology from the category matches
    // or a match was found on the last technology, the whole category is dropped. Then
    // the matcher moves to the next category and repeats. After it has gone through all the
    // categories, it wrappes back to the beginning and now tries to find more matches
    // from the categories that one match was already found and there are still technologies
    // to be searched. The loop ends when the max amount of matches are found or all the
    // technologies in all the categories are searched for.
    //
    // The result is the list of technologies that maximizes the number of categories to show,
    // but then fills the categories with more matches if there is room.
    while (foundTechnologies.length < maxHighlights && priorityTechnologies.length > 0) {
        const [, technologies] = priorityTechnologies[currentCategory];
        let foundTechnology: TechnologyData | undefined;
        const foundIndex = technologies.findIndex(([_id, label, _category]) => {
            const foundFromHere = technology_data.find((t) => t.name === label);
            if (foundFromHere) {
                foundTechnology = foundFromHere;
                return true;
            }
            return false;
        });
        if (foundIndex === -1) {
            // No technologies were found that are in this prioritized category. Drop the whole category.
            // As the current category is dropped, the index now points to the next category.
            priorityTechnologies.splice(currentCategory, 1);
        } else {
            // A technology was found that belongs to a prioritized category.
            // This should be non-undefined in any case, as it goes together with the foundIndex
            if (foundTechnology) {
                foundTechnologies.push(foundTechnology);
            }
            if (foundIndex === technologies.length - 1) {
                // This was the last one in this priorization category. Drop the category, move to the next category.
                priorityTechnologies.splice(currentCategory, 1);
            } else {
                // There are still some technologies in this priorization category that
                // aren't searched for. Drop the ones that weren't found and the one that
                // was found. The remaining are searched for on the next run, if there is still
                // room in the
                technologies.splice(0, foundIndex + 1);
                // Move on to the next category.
                currentCategory++;
            }
        }
        if (currentCategory > priorityTechnologies.length - 1) {
            // Go around and try finding one more technology from all remaining categories.
            currentCategory = 0;
        }
    }

    // Sort the found technologies to the prioritized category order (keeps the priority order inside categories).
    return foundTechnologies.sort(
        (a, b) => (categoryPriorities[a.types?.[0] ?? ''] ?? 1000) - (categoryPriorities[b.types?.[0] ?? ''] ?? 1000),
    );
}

const technologyIconMapping: Record<string, IconType> = {
    advertising: 'Advertising',
    analytics: 'Analytics',
    ats: 'ApplicationTrackingSystem',
    chat: 'ChatSupport',
    cms: 'CMS',
    crm: 'CRM',
    communication: 'Communication',
    general: 'General',
    language: 'Language',
    localisation: 'Localisation',
    mailserver: 'EmailServer',
    marketing: 'MarketingAutomation',
    nameserver: 'DomainServer',
    other: 'UnCategorized',
    payment: 'Payment',
    programming: 'Programming',
    reservation: 'Reservation',
    server: 'Server',
    webstore: 'ECommerce',
    some: 'SocialMedia',
};

export const getTechnologyIcon = (webTech: string | undefined): IconType => {
    return webTech && technologyIconMapping.hasOwnProperty(webTech) ? technologyIconMapping[webTech] : 'UnCategorized';
};
