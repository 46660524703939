import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CircleCheck: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path
            xmlns="http://www.w3.org/2000/svg"
            d="M5.63986 12.6536L5.30583 13.0004L5.63986 13.3473L9.63986 17.5007L10 17.8746L10.3601 17.5007L18.3601 9.19383L18.6942 8.84699L18.3601 8.50015L16.5814 6.65316L16.2211 6.27902L15.8609 6.65334L10.0003 12.7449L8.1394 10.8071L7.77928 10.4321L7.41862 10.8066L5.63986 12.6536ZM2.5 12C2.5 6.7533 6.7533 2.5 12 2.5C17.2467 2.5 21.5 6.7533 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.7533 21.5 2.5 17.2467 2.5 12Z"
        />
    </SvgIcon>
);

export default CircleCheck;
