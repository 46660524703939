import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Facebook: React.VFC<SvgIconProps> = (props) => (
    <SvgIcon style={{ fill: '#1877f2' }} {...props}>
        <path d="M2.00201 12.002C2.00318 16.9214 5.58042 21.11 10.439 21.881V14.892H7.90201V12.002H10.442V9.80198C10.3285 8.75952 10.6845 7.72058 11.4137 6.96692C12.1428 6.21325 13.1694 5.823 14.215 5.90198C14.9656 5.9141 15.7142 5.98095 16.455 6.10198V8.56098H15.191C14.7559 8.50399 14.3184 8.64771 14.0018 8.95165C13.6852 9.25559 13.5238 9.68687 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8175 21.0506 22.5021 16.2518 21.9475 10.9611C21.3929 5.67035 16.7933 1.73991 11.4808 2.01716C6.16838 2.29441 2.00286 6.68229 2.00201 12.002Z" />
    </SvgIcon>
);

export default Facebook;
