import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Stats, fetchStats } from 'api/filterCompanies';
import { Operation } from 'api/types/FilterOperators';
import Icon from 'components/tokens/Icon';
import Link from 'components/tokens/Link';
import Select, { SelectOption } from 'components/tokens/select-components/Select';
import Typography from 'components/tokens/Typography';
import { useAxiosContext } from 'contexts/AxiosContext';
import { useListsContext } from 'contexts/ListsContext';

import getPayload from './getPayload';
import AgeTile from './Tiles/AgeTile';
import CategoryTile from './Tiles/CategoryTile';
import ChangeIndexTile from './Tiles/ChangeIndexTile';
import GearingTile from './Tiles/GearingTile';
import MakeTile from './Tiles/MakeTile';
import OwnershipTypeTile from './Tiles/OwnershipTypeTile';
import OwningCompanyTile from './Tiles/OwningCompanyTile';
import UsedVehiclesTile from './Tiles/UsedVehiclesTile';
import VehiclesDialog from './Tiles/VehiclesDialog';
import { VehicleCategoryTop, vehicleCategoryTopLabels } from './vehicleTypes';

type NewVehiclesProps = {
    businessId: string;
};

export type TileProps = {
    data: Stats[] | undefined;
    businessId: string;
    valueField: string;
    valueRange?: boolean;
    onDetailsClick?: (data: { title: string; query: Operation }) => void;
};

const NewVehicles: React.FC<NewVehiclesProps> = ({ businessId }) => {
    const axios = useAxiosContext();
    const { database } = useListsContext();

    const [vehicleDetails, setVehicleDetails] = useState<{ title: string; query: Operation } | null>(null);
    const [selectedCategories, setSelectedCategories] = useState<SelectOption[]>([]);
    const [mainCategories, setMainCategories] = useState<VehicleCategoryTop[] | undefined>();

    const isFinnishDb = database === 'FI';
    const valueField = isFinnishDb ? 'count_min' : 'count';

    const insightsPayload = useMemo(
        () =>
            getPayload(
                database,
                businessId,
                isFinnishDb,
                selectedCategories.map((cat) => cat.value as VehicleCategoryTop),
            ),
        [businessId, database, isFinnishDb, selectedCategories],
    );

    const { data } = useQuery({
        queryKey: [
            'vehicles',
            database,
            businessId,
            !selectedCategories.length || selectedCategories.length === mainCategories?.length
                ? 'all'
                : selectedCategories,
        ],
        queryFn: () => insightsPayload && fetchStats(axios, insightsPayload),
        enabled: !!insightsPayload,
    });

    const categoryData = useMemo(() => data?.find((row) => row.id === 'category'), [data]);

    useEffect(() => {
        if (!categoryData?.result || mainCategories) {
            return;
        }
        const cats = categoryData.result[0].buckets
            .filter((bucket) => bucket.value)
            .map((bucket) => bucket.value as VehicleCategoryTop);
        setSelectedCategories(
            cats.map((cat) => ({
                label: vehicleCategoryTopLabels[cat],
                value: cat,
            })),
        );

        setMainCategories(cats);
    }, [categoryData?.result, mainCategories, selectedCategories]);

    return (
        <Box>
            {isFinnishDb && (
                <Box sx={{ display: 'flex', marginBottom: 3 }}>
                    <Icon sx={{ marginRight: 1 }} type="InfoCircleOutline" />
                    <Typography variant="tiny" color="subtle">
                        Displaying the specific vehicle data is restricted by law so you can only see vehicles that
                        match your search. In case your search didn't contain vehicle parameters, you can check detailed
                        vehicle info by first selecting a single vehicle class below and then selecting a single brand.{' '}
                        <Link fontWeight={500} toNewTab href="//traficom.fi" color="text.primary">
                            Source: Traficom
                        </Link>
                    </Typography>
                </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2" color="default">
                    Show for:
                </Typography>
                <Select
                    addMassSelectionOptions
                    autoHighlight
                    disableCloseOnSelect
                    isCheckboxOption
                    multiple
                    onValueChange={(selected) => setSelectedCategories(selected as SelectOption[])}
                    options={
                        mainCategories?.map((cat) => ({
                            value: cat,
                            label: vehicleCategoryTopLabels[cat] || cat,
                        })) || []
                    }
                    placeholder="Select vehicle type..."
                    value={selectedCategories}
                    width={300}
                />
            </Box>

            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', marginTop: 2 }}>
                {[
                    CategoryTile,
                    MakeTile,
                    UsedVehiclesTile,
                    ChangeIndexTile,
                    AgeTile,
                    OwningCompanyTile,
                    OwnershipTypeTile,
                    GearingTile,
                ].map((Component, idx) => (
                    <Component
                        key={idx}
                        data={data}
                        valueRange={isFinnishDb}
                        valueField={valueField}
                        businessId={businessId}
                        onDetailsClick={
                            !isFinnishDb
                                ? (details: { title: string; query: Operation }) => setVehicleDetails(details)
                                : undefined
                        }
                    />
                ))}
            </Box>

            <VehiclesDialog
                businessId={businessId}
                open={!!vehicleDetails}
                onClose={() => setVehicleDetails(null)}
                data={vehicleDetails}
            />
        </Box>
    );
};

export default NewVehicles;
