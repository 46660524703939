import React, { useState } from 'react';

import { Menu as MuiMenu, PopoverOrigin, SxProps } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { Elevation } from 'components/tokens/Frame';

import { MenuItem, MenuItemProps } from './MenuItem';

type MenuProps<Action> = {
    anchorElement: React.ReactElement;
    items: MenuItemProps<Action>[];
    selectedAction?: Action;
    sx?: SxProps;
    /** Elevation for the Paper component. Default is 3 */
    elevation?: Elevation;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    onClick?: (action: Action) => void;
    children?: React.ReactNode;
    /** Render children after menu items */
    childrenLast?: boolean;
};

/** Using `Menu` will block scrolling. If you want to retain scroll ability
 * when the menu is open, use `ListMenu` */
export const Menu = <Action,>({
    anchorElement,
    items,
    selectedAction,
    sx,
    elevation = 3,
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
    transformOrigin = { vertical: 'top', horizontal: 'left' },
    children,
    onClick,
    childrenLast,
}: MenuProps<Action>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick =
        (customClickHandler: MenuItemProps<Action>['onClick']) =>
        (event: React.MouseEvent<HTMLLIElement>, action: Action) => {
            customClickHandler?.(event, action);
            onClick?.(action);
            setAnchorEl(null);
        };

    const anchorId = uuid();
    const menuId = uuid();

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <anchorElement.type
                {...anchorElement.props}
                id={anchorId}
                onClick={handleAnchorClick}
                aria-controls={open ? menuId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    ...anchorElement.props.sx,
                    ...(open && {
                        backgroundColor: 'border',
                    }),
                }}
            />
            <MuiMenu
                id={menuId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': anchorId,
                }}
                elevation={elevation}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                PaperProps={{
                    sx: {
                        minWidth: 180,
                        marginTop: 1,
                        padding: 1,
                        ...sx,
                    },
                }}
            >
                {!childrenLast && children}
                {items.map((item) => {
                    const selectedItem = selectedAction ? selectedAction === item.action : false;
                    return (
                        <MenuItem
                            key={`menuId-${item.action}`}
                            {...item}
                            isSelected={selectedItem}
                            onClick={handleItemClick(item.onClick)}
                        />
                    );
                })}
                {childrenLast && children}
            </MuiMenu>
        </div>
    );
};

export default Menu;
