import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { ClusterCompanies, pageSize, useClusterCompanies } from '../hooks/useCompanies';
import { BoundsRect, Cluster, ClusterID, ClusterMap, ClusterCompany, DomainClusteringData } from '../types';
import { useClusterColors } from '../utils/color';
import { makeClusters } from '../utils/makeClusters';

export type DownloadStatus = 'unstarted' | 'progress' | 'complete' | 'error';

export type SelectedClusterMode = 'cluster' | 'similar-companies' | 'cluster-company-list';

export type ClusterContextValue = {
    /** The cluster data. */
    clusters: ClusterMap | undefined;
    /** Bounds rectangle of all the cluster points. */
    bounds: BoundsRect | undefined;
    page: number;
    setPage: (page: number) => void;
    numPages: number;
    pageCompanies: ClusterCompanies | undefined;
    isLoadingCompanies: boolean;
    downloadedData: DomainClusteringData | undefined;
    setDownloadedData: (data: DomainClusteringData | undefined) => void;
    downloadStatus: DownloadStatus;
    setDownloadStatus: (status: DownloadStatus) => void;
};

export type SelectedClusterContextValue = {
    /** The currently selected cluster. */
    selectedCluster: Cluster | undefined;
    /** Set the currently selected cluster. */
    setSelectedCluster: (id: ClusterID | undefined, mode?: SelectedClusterMode) => void;
    /** The mode of showing the cluster contents: the actual cluster or companies similar to it. */
    selectedClusterMode: SelectedClusterMode;
};

export type HoveredClusterContextValue = {
    /** The currently hovered cluster. */
    hoveredCluster: Cluster | undefined;
    /** Set the currently hovered cluster. Should be only used by the graph rendering component. */
    setHoveredCluster: (id: ClusterID | undefined) => void;
};

export type HoveredCompanyContextValue = {
    /** The currently hovered company dot. */
    hoveredCompany: ClusterCompany | undefined;
    /** Set the currently hovered company dot. Should be only used by the graph rendering component. */
    setHoveredCompany: (domain: string | undefined) => void;
    /** A ref to the dot that rendered the currently hovered company. Set by the graph rendering component.
     *  Use to render a tooltip next to it. */
    companyRenderRef: React.MutableRefObject<SVGElement | undefined>;
};

const ClusterContext = createContext({} as ClusterContextValue);
const SelectedClusterContext = createContext({} as SelectedClusterContextValue);
const HoveredClusterContext = createContext({} as HoveredClusterContextValue);
const HoveredCompanyContext = createContext({} as HoveredCompanyContextValue);

export type ClusterProviderProps = {
    children: React.ReactNode;
};

export const ClusterProvider: React.FC<ClusterProviderProps> = ({ children }) => {
    const [selectedCluster, setSelectedClusterState] = useState<SelectedClusterContextValue['selectedCluster']>();
    const [selectedClusterMode, setSelectedClusterMode] =
        useState<SelectedClusterContextValue['selectedClusterMode']>('cluster');
    const [hoveredCluster, setHoveredClusterState] = useState<HoveredClusterContextValue['hoveredCluster']>();
    const [hoveredCompany, setHoveredCompanyState] = useState<HoveredCompanyContextValue['hoveredCompany']>();
    const companyRenderRef = useRef<SVGElement>();

    const [page, setPage] = useState(1);
    const numPages = selectedCluster ? Math.ceil(selectedCluster.companies.length / pageSize) : 1;

    const [downloadedData, setDownloadedData] = useState<DomainClusteringData | undefined>();
    const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>('unstarted');

    const pageQuery = useClusterCompanies(selectedCluster, page, 'domain');
    const pageCompanies = pageQuery.data ?? selectedCluster?.companies;
    const isLoadingCompanies = pageQuery.isLoading;

    const clusterColors = useClusterColors();
    const clusterData = useMemo(
        () => makeClusters(downloadedData, 'domain', clusterColors),
        [downloadedData, clusterColors],
    );

    const setSelectedCluster = useCallback(
        (id: ClusterID | undefined, mode: SelectedClusterMode = 'cluster') => {
            const { clusters } = clusterData;
            if (clusters) {
                if (id === undefined) {
                    setSelectedClusterState(undefined);
                } else {
                    const cluster = clusters.get(id);
                    if (cluster) {
                        setSelectedClusterState(cluster);
                    } else {
                        setSelectedClusterState(undefined);
                    }
                }
                setSelectedClusterMode(mode);
            }
        },
        [clusterData],
    );

    const setHoveredCluster = useCallback(
        (id: ClusterID | undefined) => {
            const { clusters } = clusterData;
            if (clusters) {
                if (id === undefined) {
                    setHoveredClusterState(undefined);
                } else {
                    const cluster = clusters.get(id);
                    if (cluster) {
                        setHoveredClusterState(cluster);
                    } else {
                        setHoveredClusterState(undefined);
                    }
                }
            }
        },
        [clusterData],
    );

    const setHoveredCompany = useCallback(
        (id: string | undefined) => {
            const { clusters } = clusterData;
            if (clusters) {
                if (!id) {
                    setHoveredCompanyState(undefined);
                } else {
                    const company = Array.from(clusters)
                        .map(([_id, cluster]) => cluster.companies)
                        .flat()
                        .find((company) => company.domain === id);
                    if (company) {
                        setHoveredCompanyState(company);
                        setHoveredCluster(company.clusterId);
                    } else {
                        setHoveredCompanyState(undefined);
                    }
                }
            }
        },
        [clusterData, setHoveredCluster],
    );

    const clusterContextValue: ClusterContextValue = useMemo(
        () => ({
            ...clusterData,
            page,
            setPage,
            numPages,
            pageCompanies,
            isLoadingCompanies,
            downloadedData,
            setDownloadedData,
            downloadStatus,
            setDownloadStatus,
        }),
        [clusterData, page, numPages, pageCompanies, isLoadingCompanies, downloadedData, downloadStatus],
    );

    const selectedClusterContextValue: SelectedClusterContextValue = useMemo(
        () => ({
            selectedCluster,
            setSelectedCluster,
            selectedClusterMode,
        }),
        [selectedCluster, setSelectedCluster, selectedClusterMode],
    );

    const hoveredClusterContextValue: HoveredClusterContextValue = useMemo(
        () => ({
            hoveredCluster,
            setHoveredCluster,
        }),
        [hoveredCluster, setHoveredCluster],
    );

    const hoveredCompanyContextValue: HoveredCompanyContextValue = useMemo(
        () => ({
            hoveredCompany,
            setHoveredCompany,
            companyRenderRef,
        }),
        [hoveredCompany, setHoveredCompany, companyRenderRef],
    );

    return (
        <ClusterContext.Provider value={clusterContextValue}>
            <SelectedClusterContext.Provider value={selectedClusterContextValue}>
                <HoveredClusterContext.Provider value={hoveredClusterContextValue}>
                    <HoveredCompanyContext.Provider value={hoveredCompanyContextValue}>
                        {children}
                    </HoveredCompanyContext.Provider>
                </HoveredClusterContext.Provider>
            </SelectedClusterContext.Provider>
        </ClusterContext.Provider>
    );
};

export const useClusterContext = () => useContext(ClusterContext);
export const useSelectedClusterContext = () => useContext(SelectedClusterContext);
export const useHoveredClusterContext = () => useContext(HoveredClusterContext);
export const useHoveredCompanyContext = () => useContext(HoveredCompanyContext);
