import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterOperator } from 'api/types/FilterOperators';
import RangeSelect, { Values } from 'components/templates/RangeSelect';

import {
    FilterConfig,
    FilterDefinition,
    FilterID,
    FilterProps,
    GroupValues,
    Operator,
    OperatorValue,
} from '../../FilterTypes';
import { useOperator } from '../common/useOperator';
import FilterTemplate from '../FilterTemplate';
import { formatOperatorValue, getDefaultRange, getRangeOptions, rangeValueToText } from '../utils';

const FIELD = 'prospectexport#lost_deals_count';

const rangeOptions = getRangeOptions(50);
const defaultRange = getDefaultRange(rangeOptions);

export type TValues = { [FIELD]: Values };

const config: FilterConfig<TValues> = {
    fields: [FIELD],
    id: FilterID.prospect_export_crm_lost_deal_count,
    label: 'Lost deals (count)',
    defaultOperator: FilterOperator.RANGE,
    initValue: {
        [FilterOperator.RANGE]: {
            [FIELD]: defaultRange,
        },
    },
};

/**
 * **This filter should only be selected once.**
 */
export const lostDealCountFilterDefinition: FilterDefinition<TValues> = {
    [config.id]: {
        id: config.id,
        group: GroupValues.crm,
        label: config.label,
        fields: config.fields,
        outsideGroup: true,
        selectableOnce: true,
        render: (props: FilterProps<TValues>) => <LostDealCount {...props} />,
        valueToText: rangeValueToText(config.fields[0], rangeOptions),
        config,
        createFilter: () => config.initValue,
    },
};

const operators: Operator[] = [FilterOperator.RANGE, `${FilterOperator.NOT}${FilterOperator.RANGE}`].map((value) =>
    formatOperatorValue(value as OperatorValue),
);

export const LostDealCount: React.FC<FilterProps<TValues>> = ({ filter, uuid, groupUUID, removeFilter }) => {
    const { control } = useFormContext();
    const { operator, handleOperatorUpdate } = useOperator(filter);

    const fullValuePath = `${filter.groupPath}.${filter.valuePath}`;

    const props = {
        filter: filter.definition,
        uuid,
        groupUUID,
        removeFilter,
        operatorField: config.fields[0],
        operator,
        operators,
        updateOperator: handleOperatorUpdate,
        children: [
            <Controller
                name={fullValuePath}
                control={control}
                key={`${uuid}-input-value`}
                render={({ field }) => {
                    const { onChange, value, ...fieldProps } = field;
                    return (
                        <RangeSelect
                            label={config.label}
                            options={rangeOptions}
                            value={value || defaultRange}
                            onRangeChange={onChange}
                            {...fieldProps}
                        />
                    );
                }}
            />,
        ],
    };

    return <FilterTemplate {...props} />;
};
